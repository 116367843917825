<template>
    <div>
        <el-dialog
            :visible="danhSachDiemTruongModalVisible"
            width="90%"
            @close="closeModalDanhSachDiemTruong"
        >
            <span slot="title">
                <div>
                    {{ tenTruongHoc }}
                </div>
            </span>
            <div class="row pb-3">
                <div class="col-sm-3">
                    <label>Năm học</label>
                    <ESelectYear
                        v-model="namHoc"
                        placeholder="Chọn năm"
                        size="small"
                        :no-data-text="'Không có bản ghi nào'"
                        :no-match-text="'Không tìm thấy bản ghi nào'"
                        :width="'100%'"
                    ></ESelectYear>
                </div>
                <div class="col-sm-3">
                    <label>Mã điểm trường</label>
                    <CustomInput
                        v-model="tenDiemTruong"
                        placeholder="Nhập ..."
                        :disabled="false"
                        :clearable="false"
                        size="small"
                    >
                    </CustomInput>
                </div>
                <div class="col-sm-3">
                    <label>Tên điểm trường</label>
                    <CustomInput
                        v-model="maDiemTruong"
                        placeholder="Nhập ..."
                        :disabled="false"
                        :clearable="false"
                        size="small"
                    >
                    </CustomInput>
                </div>
                <div class="col-sm-3" style="margin-top: 28px">
                    <CustomButton
                        label="Tìm kiếm"
                        size="small"
                        class="btn-default bg-search"
                        @click="handleThongKe"
                    />
                </div>
            </div>
            <hr />

            <div class="layout-table">
                <table class="table table-bordered table-hover centered-table">
                    <thead>
                        <tr>
                            <th class="text-thead">STT</th>
                            <th class="text-thead">Phòng</th>
                            <th class="text-thead">Mã điểm trường</th>
                            <th class="text-thead">Tên điểm trường</th>
                            <th class="text-thead">Địa chỉ</th>
                            <th class="text-thead">
                                Khoảng cách tới trường chính
                            </th>
                            <th class="text-thead">Diện tích</th>
                            <th class="text-thead">Số điện thoại</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="tableData.length === 0">
                            <tr>
                                <td colspan="36" class="text-tbody">
                                    Không có bản ghi nào.
                                </td>
                            </tr>
                        </template>
                        <tr v-for="(item, index) in tableData" :key="index">
                            <td class="text-tbody">
                                {{ index + 1 }}
                            </td>
                            <td class="text-tbody">{{ tenDonVi }}</td>
                            <td class="text-tbody">
                                {{ item.maDiemTruong }}
                            </td>
                            <td class="text-tbody">
                                {{ item.tenDiemTruong }}
                            </td>

                            <td class="text-tbody">
                                {{ item.diaChi }}
                            </td>
                            <td class="text-tbody">{{ item.khoangCach }} km</td>
                            <td class="text-tbody">{{ item.dienTich }} m2</td>
                            <td class="text-tbody">
                                {{ item.dienThoai }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="layout-btn">
                <CustomButton
                    class="btn-default btn-delete"
                    label="Đóng"
                    size="small"
                    @click="closeModalDanhSachDiemTruong"
                />
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Api from '@/constants/Api'
import sendRequest from '@/services'
import ESelectYear from '@/components/ESelectYear.vue'
import { mapState } from 'vuex'
import { currentYear } from '@/utils/index'
import CustomButton from '@/components/CustomButton.vue'
import CustomInput from '@/components/CustomInput.vue'

export default {
    name: 'ModalDanhSachDiemTruong',
    props: {
        danhSachDiemTruongModalVisible: {
            type: Boolean,
            required: true
        },

        closeModalDanhSachDiemTruong: {
            type: Function,
            required: true
        },
        data_danhSachDiemTruong: {
            type: Object,
            required: true
        },
        handleSearch: Function
    },
    components: {
        CustomButton,
        ESelectYear,
        CustomInput
    },
    data() {
        return {
            selectAllChecked: false,
            dataSelectedAll: [],
            selectedItems: [],
            tenTruongHoc: '',
            tenDonVi: '',
            tableData: [],

            namHoc: '',
            tenDiemTruong: '',
            maDiemTruong: '',
            maTruongHoc: ''
        }
    },
    watch: {
        async data_danhSachDiemTruong(newValue) {
            this.tenTruongHoc = newValue.tenTruongHoc
            this.tenDonVi = newValue.tenDonVi
            this.namHoc = newValue?.ngayCapNhat.split('/').pop()
            this.maTruongHoc = Object.values([newValue?.maTruongHoc])
            await this.getDataDanhSachDiemTruong()
        }
    },
    methods: {
        ChonSoLuongBanGhi(e) {
            this.total_rows = 0
            this.soLuongBanGhiHienThi = e.soluong
        },
        checkTruocKhiTim() {
            this.trangbatdau = !this.trangbatdau
        },
        layLai(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.getDataDanhSachDiemTruong()
        },
        async getDataDanhSachDiemTruong() {
            const namHoc = this.namHoc
            const maTruongs = this.maTruongHoc

            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                namHoc: namHoc || currentYear,
                maTruongs: maTruongs,
                tenDiemTruong: this.tenDiemTruong,
                maDiemTruong: this.maDiemTruong
                // start: this.start,
                // limit: this.limit
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const response = await sendRequest(
                Api.traCuu.traCuuTruongHoc.layDiemTruongHoc,
                request_Data,
                request_Header
            )
            if (response.rc == 0) {
                this.tableData = response.rows
                this.total_rows = response.total
                loading.close()
                this.$message({
                    type: 'success',
                    message: 'Danh sách Điểm trường học'
                })
            }
            if (response.rc != 0) {
                loading.close()
                this.$message({
                    type: 'error',
                    message: `Trường học chưa có dữ liệu điểm trường năm học ${this.namHoc}`
                })
                this.tableData = []
            }
        },
        async handleDelete() {
            const namHoc = this.namHoc
            const maDiemTruongs = this.selectedItems.map(
                (item) => item.maDiemTruong
            )

            if (maDiemTruongs.length === 0) {
                this.$message({
                    type: 'warning',
                    message: 'Vui lòng chọn trường cần xóa'
                })
            }
            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                maDiemTruongs: maDiemTruongs,
                namHoc: namHoc
            }
            const response = await sendRequest(
                Api.traCuu.traCuuTruongHoc.xoaDiemTruongHoc,
                request_Data,
                request_Header
            )
            if (response.rc == 0) {
                this.$message({
                    type: 'success',
                    message: response.rd
                })
                await this.getDataDanhSachDiemTruong()
            }
            if (response.rc != 0) {
                this.$message({
                    type: 'warning',
                    message: 'Xảy ra lỗi'
                })
            }
        },
        async handleThongKe() {
            await this.getDataDanhSachDiemTruong()
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped>
.custom-dialog-title {
    font-size: 18px;
    color: black;
    display: flex;
    justify-content: space-between;
}
.layout-table {
    background: #fff;

    border-radius: 4px;

    margin-top: 10px;

    max-height: 400px; /* Đặt chiều cao tối đa là 800px */
    overflow-y: auto;
}

table thead tr {
    background: rgb(228, 235, 245);
}
.table {
    white-space: nowrap;
}
.centered-table {
    margin-left: auto;
    margin-right: auto;
}

.centered-table th,
.centered-table td {
    text-align: center;
    vertical-align: middle;
}
.table-bordered {
    background-color: #fff;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}
.table-hover tbody tr:hover {
    background-color: #f5f5f5;
}

.text-thead {
    font-size: 13px;
    text-align: center; /* Canh giữa nội dung trong cột */
    vertical-align: middle; /* Căn giữa theo chiều dọc */
}
.text-tbody {
    font-size: 13px;
}
.layout-year {
    display: flex;
}
.title {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}
hr {
    border: 1px solid gainsboro;
}
.layout-btn {
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.bg-search {
    background-color: var(--teal);
}
.btn-add {
    background-color: var(--light-green);
}
.btn-delete {
    background-color: var(--orange);
}
.btn-excel {
    background-color: #1e6050;
}
.btn-update {
    background-color: var(--yellow);
}
</style>
