<template>
    <div>
        <div v-if="authUser.parentRole === 2 || authUser.parentRole === 4">
            <RoleDepartment />
        </div>
        <div v-if="authUser.parentRole === 5">
            <RoleSchool />
        </div>
    </div>
</template>
<script>
import sendRequest from '@/services'
import RoleDepartment from './RoleDepartment/index.vue'
import RoleSchool from './RoleSchool/index.vue'
import { mapState } from 'vuex'
import Api from '@/constants/Api'
import store from '@/store'

export default {
    name: 'DashBoard',
    components: {
        RoleDepartment,
        RoleSchool
    },
    methods: {
        async getDataChonDonVi() {
            const requestParams = {
                start: 0,
                limit: 9999,
                maTinhThanh: this.authUser.ma_so,
                check: true
            }
            const requestHeaders = {
                token: this.authToken
            }
            const response = await sendRequest(
                Api.internal_api.dm_DonVi,
                null,
                requestHeaders,
                requestParams
            )
            const newData = []

            for (let i = 0; i < response.rows.length; i++) {
                const donVi = response.rows[i]

                if (donVi.tenDonVi.includes('Phòng Giáo dục và Đào tạo')) {
                    const updatedDonVi = {
                        ...donVi,
                        tenDonVi: `  ${donVi.tenDonVi.replace(
                            'Phòng Giáo dục và Đào tạo',
                            'Phòng GD&ĐT'
                        )} - [${donVi.maDonVi}]`
                    }

                    newData.push(updatedDonVi)
                } else if (donVi.tenDonVi.includes('Sở Giáo dục và Đào tạo')) {
                    const updatedDonVi = {
                        ...donVi,
                        tenDonVi: `  ${donVi.tenDonVi.replace(
                            'Sở Giáo dục và Đào tạo',
                            'Sở GD&ĐT'
                        )} - [${donVi.maDonVi}]`
                    }

                    newData.push(updatedDonVi)
                } else {
                    newData.push(donVi)
                }
            }

            store.commit('SET_DATACHONDONVI_STORE', newData)
        },
        async getData_DanhSachDanhMuc() {
            const constantTypes = {
                DM_DAN_TOC: 'DM_DAN_TOC',
                DM_TON_GIAO: 'DM_TON_GIAO',
                DM_DOI_TUONG_CHINH_SACH: 'DM_DOI_TUONG_CHINH_SACH',
                DM_NHOM_MAU: 'DM_NHOM_MAU',
                THANH_PHAN_GIA_DINH: 'THANH_PHAN_GIA_DINH',
                DM_KHU_VUC: 'DM_KHU_VUC',
                DM_LOAI_KHUYET_TAT: 'DM_LOAI_KHUYET_TAT',
                DM_VUNG_KHO_KHAN: 'DM_VUNG_KHO_KHAN', //doituonguutien
                DM_LOP_HUONG_NGHIEP_DAY_NGHE: 'DM_LOP_HUONG_NGHIEP_DAY_NGHE',
                LOAI_NHAP_HOC: 'LOAI_NHAP_HOC',
                DM_HOC_BAN_TRU: 'DM_HOC_BAN_TRU',
                DM_NGOAI_NGU: 'DM_NGOAI_NGU',
                DM_LOP_DAO_TAO_BOI_DUONG: 'DM_LOP_DAO_TAO_BOI_DUONG',
                DM_TRINH_DO_CHUYEN_MON: 'DM_TRINH_DO_CHUYEN_MON',
                DM_TRANG_THAI_CAN_BO: 'DM_TRANG_THAI_CAN_BO',
                DM_LOAI_CAN_BO: 'DM_LOAI_CAN_BO', //chucvu
                DM_MON_DAY_GV: 'DM_MON_DAY_GV',
                DM_HINH_THUC_HOP_DONG: 'DM_HINH_THUC_HOP_DONG',
                DM_TRINH_DO_DAO_TAO: 'DM_TRINH_DO_DAO_TAO',
                DM_KQ_CHUAN_NGHE_NGHIEP: 'DM_KQ_CHUAN_NGHE_NGHIEP',
                DM_NHIEM_VU_KIEM_NHIEM: 'DM_NHIEM_VU_KIEM_NHIEM',
                DM_NGACH_CC: 'DM_NGACH_CC',
                DM_BOI_DUONG_TX: 'DM_BOI_DUONG_TX',
                DM_TRINH_DO_LLCT: 'DM_TRINH_DO_LLCT',
                DM_TRINH_DO_QLGD: 'DM_TRINH_DO_QLGD',
                DM_BOI_DUONG_NVU: 'DM_BOI_DUONG_NVU',
                DM_BOI_DUONG_CBQL_COT_CAN: 'DM_BOI_DUONG_CBQL_COT_CAN',
                DM_BOI_DUONG_THAY_SACH: 'DM_BOI_DUONG_THAY_SACH',
                DM_TRINH_DO_NGOAI_NGU: 'DM_TRINH_DO_NGOAI_NGU',
                DM_NHOM_CCHI_NNGU: 'DM_NHOM_CCHI_NNGU',
                DM_LOAI_CCHI_NNGU: 'DM_LOAI_CCHI_NNGU',
                DM_KHUNG_NLUC_NNGU: 'DM_KHUNG_NLUC_NNGU',
                DM_TRINH_DO_TIN_HOC: 'DM_TRINH_DO_TIN_HOC',
                DM_CHUYEN_MON: 'DM_CHUYEN_MON',
                DM_CHUNG_CHI_TIENG_DTOC_TSO: 'DM_CHUNG_CHI_TIENG_DTOC_TSO',
                DM_DG_VIEN_CHUC: 'DM_DG_VIEN_CHUC',
                DM_GIAO_VIEN_GIOI: 'DM_GIAO_VIEN_GIOI',
                DM_GIAO_VIEN_CHU_NHIEM_GIOI: 'DM_GIAO_VIEN_CHU_NHIEM_GIOI',
                DM_GV_TONG_PTRACH_DOI_GIOI: 'DM_GV_TONG_PTRACH_DOI_GIOI',
                DM_NHOM_CAN_BO: 'DM_NHOM_CAN_BO',
                DM_LOAI_HINH_TRUONG: 'DM_LOAI_HINH_TRUONG',
                DM_MUC_DAT_CHUAN_QG_CLGD: 'DM_MUC_DAT_CHUAN_QG_CLGD',
                DM_GIAI_DOAN_KQHT: 'DM_GIAI_DOAN_KQHT',
                DM_TOT_NGHIEP: 'DM_TOT_NGHIEP',
                DM_HINH_THUC_DAO_TAO: 'DM_HINH_THUC_DAO_TAO',
                DM_VAN_BAN_CHUNG_CHI: 'DM_VAN_BAN_CHUNG_CHI',
                MA_KHOA_DAO_TAO_SU_PHAM: 'MA_KHOA_DAO_TAO_SU_PHAM',
                MA_DON_VI: 'MA_DON_VI',
                MA_DON_VI_TO_CHUC: 'MA_DON_VI_TO_CHUC',
                MA_THOI_LUONG_BOI_DUONG: 'MA_THOI_LUONG_BOI_DUONG',
                MA_LOAI_HINH_BOI_DUONG: 'MA_LOAI_HINH_BOI_DUONG',
                MA_HINH_THUC: 'MA_HINH_THUC',
                MA_KET_QUA_SU_PHAM: 'MA_KET_QUA_SU_PHAM',
                DM_KY_LUAT_GV: 'DM_KY_LUAT_GV',
                DM_TIET_HOC: 'DM_TIET_HOC',
                DM_NGANH_DAO_TAO: 'DM_NGANH_DAO_TAO',
                DM_TRINH_DO_VAN_HOA: 'DM_TRINH_DO_VAN_HOA',
                DM_TRINH_DO_QLNN: 'DM_TRINH_DO_QLNN',
                DM_KHOI: 'DM_KHOI',
                TINH_TRANG_DINH_DUONG: 'TINH_TRANG_DINH_DUONG',
                DM_LY_DO_THOI_HOC: 'DM_LY_DO_THOI_HOC'
            }

            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                constantType: null
            }
            const response = await sendRequest(
                Api.traCuu.danhMuc,
                request_Data,
                request_Header,
                null
            )

            const convertListData = (listData) =>
                listData.map((convert) => ({
                    mappingCode: convert.mappingCode,
                    constantTitle: `${convert.constantTitle} - [${convert.mappingCode}]`
                }))

            const filteredElements = Object.fromEntries(
                Object.entries(constantTypes).map(([key, value]) => [
                    value,
                    response.tableData.filter(
                        (item) => item.constantType === value
                    )
                ])
            )

            const processFilteredElements = (constantType, dataKey, storeKey) => {
                if (constantType && filteredElements[constantType].length > 0) {
                    const listDataArray = filteredElements[constantType].map(
                        (item) => item.list_data
                    )
                    const listData = [].concat(...listDataArray)
                    const convertedData = convertListData(listData)

                    if (storeKey) {
                        store.commit(storeKey, convertedData)
                    }
                }
            }

            // Sử dụng hàm processFilteredElements với constantType không bị null
            processFilteredElements(
                constantTypes.DM_DAN_TOC,
                null,
                'SET_DATACHONDANTOC_STORE'
            )
            processFilteredElements(
                constantTypes.DM_TON_GIAO,
                null,
                'SET_DATACHONTONGIAO_STORE'
            )
            // ...Thêm các trường hợp xử lý khác ở đây

            processFilteredElements(
                constantTypes.DM_DAN_TOC,
                null,
                'SET_DATACHONDANTOC_STORE'
            )
            processFilteredElements(
                constantTypes.DM_TON_GIAO,
                null,
                'SET_DATACHONTONGIAO_STORE'
            )
            processFilteredElements(
                constantTypes.DM_NHOM_MAU,
                null,
                'SET_DATACHONNHOMMAU_STORE'
            )
            processFilteredElements(
                constantTypes.THANH_PHAN_GIA_DINH,
                null,
                'SET_DATACHONTHANHPHANGIADINH_STORE'
            )
            processFilteredElements(
                constantTypes.DM_DOI_TUONG_CHINH_SACH,
                null,
                'SET_DATACHONDOITUONGCHINHSACH_STORE'
            )
            processFilteredElements(
                constantTypes.DM_KHU_VUC,
                null,
                'SET_DATACHONKHUVUC_STORE'
            )
            processFilteredElements(
                constantTypes.DM_LOAI_KHUYET_TAT,
                null,
                'SET_DATACHONLOAIKHUYETTAT_STORE'
            )
            processFilteredElements(
                constantTypes.DM_VUNG_KHO_KHAN,
                null,
                'SET_DATACHONDOITUONGUUTIEN_STORE'
            )
            processFilteredElements(
                constantTypes.DM_LOP_HUONG_NGHIEP_DAY_NGHE,
                null,
                'SET_DATACHONHUONGNGHIEPDAYNGHE_STORE'
            )
            processFilteredElements(
                constantTypes.LOAI_NHAP_HOC,
                null,
                'SET_DATACHONLOAINHAPHOC_STORE'
            )
            processFilteredElements(
                constantTypes.DM_HOC_BAN_TRU,
                null,
                'SET_DATACHONHOCBANTRU_STORE'
            )
            processFilteredElements(
                constantTypes.DM_NGOAI_NGU,
                null,
                'SET_DATACHONNGOAINGU_STORE'
            )
            processFilteredElements(
                constantTypes.DM_LOP_DAO_TAO_BOI_DUONG,
                null,
                'SET_DATACHONLOPDAOTAOBOIDUONG_STORE'
            )
            processFilteredElements(
                constantTypes.DM_TRINH_DO_CHUYEN_MON,
                null,
                'SET_DATACHONTRINHDOCHUYENMONNGHIEPVU_STORE'
            )
            processFilteredElements(
                constantTypes.DM_TRANG_THAI_CAN_BO,
                null,
                'SET_DATACHONTRANGTHAICANBO_STORE'
            )
            processFilteredElements(
                constantTypes.DM_LOAI_CAN_BO,
                null,
                'SET_DATACHONLOAICANBO_STORE'
            )
            processFilteredElements(
                constantTypes.DM_MON_DAY_GV,
                null,
                'SET_DATACHONMONDAYGIAOVIEN_STORE'
            )
            processFilteredElements(
                constantTypes.DM_HINH_THUC_HOP_DONG,
                null,
                'SET_DATACHONHINHTHUCHOPDONG_STORE'
            )
            processFilteredElements(
                constantTypes.DM_TRINH_DO_DAO_TAO,
                null,
                'SET_DATACHONTRINHDODAOTAO_STORE'
            )
            processFilteredElements(
                constantTypes.DM_KQ_CHUAN_NGHE_NGHIEP,
                null,
                'SET_DATACHONCHUANGHENGHIEP_STORE'
            )
            processFilteredElements(
                constantTypes.DM_NHIEM_VU_KIEM_NHIEM,
                null,
                'SET_DATACHONNHIEMVUKIEMNGHIEM_STORE'
            )
            processFilteredElements(
                constantTypes.DM_NGACH_CC,
                null,
                'SET_DATACHONNGANHCC_STORE'
            )
            processFilteredElements(
                constantTypes.DM_BOI_DUONG_TX,
                null,
                'SET_DATACHONBOIDUONGTX_STORE'
            )
            processFilteredElements(
                constantTypes.DM_TRINH_DO_LLCT,
                null,
                'SET_DATACHONTRINHDOLLCT_STORE'
            )
            processFilteredElements(
                constantTypes.DM_TRINH_DO_QLGD,
                null,
                'SET_DATACHONTRINHDOQLGD_STORE'
            )
            processFilteredElements(
                constantTypes.DM_BOI_DUONG_NVU,
                null,
                'SET_DATACHONBOIDUONGNGHIEPVU_STORE'
            )
            processFilteredElements(
                constantTypes.DM_BOI_DUONG_CBQL_COT_CAN,
                null,
                'SET_DATACHONBOIDUONGCBQLCOTCAN_STORE'
            )
            processFilteredElements(
                constantTypes.DM_BOI_DUONG_THAY_SACH,
                null,
                'SET_DATACHONBOIDUONGTHAYSACH_STORE'
            )
            processFilteredElements(
                constantTypes.DM_TRINH_DO_NGOAI_NGU,
                null,
                'SET_DATACHONTrinhDoNgoaiNgu_STORE'
            )
            processFilteredElements(
                constantTypes.DM_NHOM_CCHI_NNGU,
                null,
                'SET_DATACHONNHOMCCHINNGU_STORE'
            )
            processFilteredElements(
                constantTypes.DM_LOAI_CCHI_NNGU,
                null,
                'SET_DATACHONLOAICCHINNGU_STORE'
            )
            processFilteredElements(
                constantTypes.DM_KHUNG_NLUC_NNGU,
                null,
                'SET_DATACHONKHUNGNLUCNNGU_STORE'
            )
            processFilteredElements(
                constantTypes.DM_TRINH_DO_TIN_HOC,
                null,
                'SET_DATACHONTRINHDOTINHOC_STORE'
            )
            processFilteredElements(
                constantTypes.DM_CHUYEN_MON,
                null,
                'SET_DATACHONCHUYENMON_STORE'
            )
            processFilteredElements(
                constantTypes.DM_CHUNG_CHI_TIENG_DTOC_TSO,
                null,
                'SET_DATACHONCCHITIENGDTOCTSO_STORE'
            )
            processFilteredElements(
                constantTypes.DM_DG_VIEN_CHUC,
                null,
                'SET_DATACHONDGVIENCHUC_STORE'
            )
            processFilteredElements(
                constantTypes.DM_GIAO_VIEN_GIOI,
                null,
                'SET_DATACHONDANHGIAGVGIOI_STORE'
            ),
                processFilteredElements(
                    constantTypes.DM_GIAO_VIEN_CHU_NHIEM_GIOI,
                    null,
                    'SET_DATACHONDANHGIAGVCNGIOI_STORE'
                ),
                processFilteredElements(
                    constantTypes.DM_GV_TONG_PTRACH_DOI_GIOI,
                    null,
                    'SET_DATACHONTONGPHUTRACHDOIGIOI_STORE'
                )
            processFilteredElements(
                constantTypes.DM_NHOM_CAN_BO,
                null,
                'SET_DATACHONNHOMCANBO_STORE'
            )
            processFilteredElements(
                constantTypes.DM_LOAI_HINH_TRUONG,
                null,
                'SET_DATACHONLOAIHINHTRUONG_STORE'
            )
            processFilteredElements(
                constantTypes.DM_MUC_DAT_CHUAN_QG_CLGD,
                null,
                'SET_DATACHONDATCHUANQG_STORE'
            )
            processFilteredElements(
                constantTypes.DM_GIAI_DOAN_KQHT,
                null,
                'SET_DATACHONGIAIDOANKQHT_STORE'
            )
            processFilteredElements(
                constantTypes.DM_TOT_NGHIEP,
                null,
                'SET_DATACHONLOAITOTNGHIEP_STORE'
            )
            processFilteredElements(
                constantTypes.DM_HINH_THUC_DAO_TAO,
                null,
                'SET_DATACHONHINHTHUCDAOTAO_STORE'
            )
            processFilteredElements(
                constantTypes.DM_VAN_BAN_CHUNG_CHI,
                null,
                'SET_DATACHONVANBANGCHUNGCHI_STORE'
            )
            processFilteredElements(
                constantTypes.MA_KHOA_DAO_TAO_SU_PHAM,
                null,
                'SET_DATACHONKHOADAOTAOSUPHAM_STORE'
            )
            processFilteredElements(
                constantTypes.MA_DON_VI,
                null,
                'SET_DATACHONDONVIBOIDUONG_STORE'
            )
            processFilteredElements(
                constantTypes.MA_DON_VI_TO_CHUC,
                null,
                'SET_DATACHONDONVITOCHUC_STORE'
            )
            processFilteredElements(
                constantTypes.MA_THOI_LUONG_BOI_DUONG,
                null,
                'SET_DATACHONTHOILUONGBOIDUONG_STORE'
            )
            processFilteredElements(
                constantTypes.MA_LOAI_HINH_BOI_DUONG,
                null,
                'SET_DATACHONLOAIHINHBOIDUONG_STORE'
            )
            processFilteredElements(
                constantTypes.MA_HINH_THUC,
                null,
                'SET_DATACHONHINHTHUC_STORE'
            )
            processFilteredElements(
                constantTypes.MA_KET_QUA_SU_PHAM,
                null,
                'SET_DATACHONKETQUASUPHAM_STORE'
            ),
                processFilteredElements(
                    constantTypes.DM_KY_LUAT_GV,
                    null,
                    'SET_DATACHONKYLUATGV_STORE'
                ),
                processFilteredElements(
                    constantTypes.DM_TIET_HOC,
                    null,
                    'SET_DATACHONTIETHOC_STORE'
                ),
                processFilteredElements(
                    constantTypes.DM_NGANH_DAO_TAO,
                    null,
                    'SET_DATACHONNGANHDAOTAO_STORE'
                ),
                processFilteredElements(
                    constantTypes.DM_TRINH_DO_VAN_HOA,
                    null,
                    'SET_DATACHONTRINHDOVANHOA_STORE'
                ),
                processFilteredElements(
                    constantTypes.DM_TRINH_DO_QLNN,
                    null,
                    'SET_DATACHONTRINHDOQLNN_STORE'
                ),
                processFilteredElements(
                    constantTypes.DM_KHOI,
                    null,
                    'SET_DATACHONKHOI'
                ),
                processFilteredElements(
                    constantTypes.TINH_TRANG_DINH_DUONG,
                    null,
                    'SET_DATACHONTINHTRANGDINHDUONG'
                ),
                processFilteredElements(
                    constantTypes.DM_LY_DO_THOI_HOC,
                    null,
                    'SET_DATACHONLYDOTHOIHOC'
                )
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    },
    mounted() {
        this.getDataChonDonVi()
        this.getData_DanhSachDanhMuc()
    }
}
</script>

<style scoped></style>
