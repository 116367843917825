<template>
    <el-dialog
        :visible="themMoiTruongHocModalVisible"
        @close="resetFrom"
        width="90%"
        class="custom-dialog"
    >
        <span slot="title" class="custom-dialog-title">
            Thêm mới Thông tin trường học
            <hr />
        </span>
        <div class="row">
            <div class="col-3">
                <label>Tỉnh thành <span class="span">*</span></label>
                <div>
                    <el-select
                        disabled
                        v-model="selectedValue.selectedValue_tinhThanh"
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelect_tinhThanh"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label>Quận huyện <span class="span">*</span></label>
                <div>
                    <el-select
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        v-model="selectedValue.selectedValue_quanHuyen"
                        size="small"
                        style="width: 100%"
                        @input="handleSelectChange_quanHuyen"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelect_quanHuyen"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label>Phường xã <span class="span">*</span></label>
                <div>
                    <el-select
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        v-model="selectedValue.selectedValue_phuongXa"
                        size="small"
                        style="width: 100%"
                        @input="handleSelectChange_PhuongXa"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelect_phuongXa"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label>Tổ thôn</label>
                <div>
                    <el-select
                        v-model="selectedValue.selectedValue_toThon"
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        size="small"
                        :no-data-text="'Không có bản ghi nào'"
                        :no-match-text="'Không tìm thấy bản ghi nào'"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelect_toThon"
                            :key="item.maToThon"
                            :label="item.tenToThon"
                            :value="item.maToThon"
                        />
                    </el-select>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-3">
                <label>Đơn vị quản lý <span class="span">*</span></label>
                <div>
                    <el-select
                        v-model="selectedValue.selectedValue_donViquanLy"
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelect_donViQuanLy"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label>Mã trường học<span class="span">*</span></label>
                <div>
                    <el-input
                        placeholder="Nhập ..."
                        v-model="selectedValue.input_maTruongHoc"
                        size="small"
                    ></el-input>
                </div>
            </div>
            <div class="col-3">
                <label>Tên trường học <span class="span">*</span></label>
                <div>
                    <el-input
                        placeholder="Nhập ..."
                        v-model="selectedValue.input_tenTruongHoc"
                        size="small"
                    ></el-input>
                </div>
            </div>
            <div class="col-3">
                <label>Cấp học <span class="span">*</span></label>
                <div>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelect_capHoc"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenTruongHoc', 'value']"
                        v-model="selectedValue.selectedValue_capHoc"
                    />
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-3">
                <label>Nhóm cấp học</label>
                <div>
                    <el-select
                        v-model="selectedValue.selectedValue_nhomCapHoc"
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelect_nhomCapHoc"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label>Loại hình đào tạo <span class="span">*</span></label>
                <div>
                    <el-select
                        v-model="selectedValue.selectedValue_loaiHinhDaoTao"
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelect_loaiHinhDaoTao"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label>Loại hình trường<span class="span">*</span></label>
                <div>
                    <el-select
                        v-model="selectedValue.selectedValue_loaiHinhTruong"
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in this.dataChonLoaihinhtruong_Store"
                            :key="item.mappingCode"
                            :label="item.constantTitle"
                            :value="item.mappingCode"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label>Ngày thành lập</label>
                <div>
                    <el-date-picker
                        placeholder="Chọn ngày"
                        v-model="selectedValue.date_ngayThanhLap"
                        type="date"
                        style="width: 100%"
                        format="dd/MM/yyyy"
                        size="small"
                    >
                    </el-date-picker>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-3">
                <label>Chuẩn quốc gia <span class="span">*</span></label>
                <div>
                    <el-select
                        v-model="selectedValue.selectedValue_chuanQuocGia"
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in this.dataChonDatChuanQG_Store"
                            :key="item.mappingCode"
                            :label="item.constantTitle"
                            :value="item.mappingCode"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label>Website </label>
                <div>
                    <el-input
                        placeholder="Nhập"
                        v-model="selectedValue.input_webSite"
                        size="small"
                    ></el-input>
                </div>
            </div>
            <div class="col-3">
                <label>Tên hiệu trưởng</label>
                <div>
                    <el-input
                        placeholder="Nhập"
                        v-model="selectedValue.input_tenHieuTruong"
                        size="small"
                    ></el-input>
                </div>
            </div>
            <div class="col-3">
                <label>Email<span class="span">*</span></label>
                <div>
                    <el-input
                        type="email"
                        placeholder="Nhập"
                        v-model="selectedValue.input_email"
                        size="small"
                    ></el-input>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-3">
                <label>Fax</label>
                <div>
                    <el-input
                        placeholder="Nhập"
                        v-model="selectedValue.input_fax"
                        size="small"
                    ></el-input>
                </div>
            </div>
            <div class="col-3">
                <label>Địa chỉ</label>
                <div>
                    <el-input
                        placeholder="Nhập"
                        v-model="selectedValue.input_diaChi"
                        size="small"
                    ></el-input>
                </div>
            </div>

            <div class="col-3">
                <label>Trạng thái</label>
                <div>
                    <el-select
                        v-model="selectedValue.selectedValue_trangThai"
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelect_trangThai"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
        </div>
        <div class="layout-btn">
            <div>
                <CustomButton
                    label="Đóng"
                    size="small"
                    class="btn-close btn-default"
                    @click="resetFrom"
                />
            </div>
            <div style="margin-left: 10px">
                <CustomButton
                    label="Thêm mới"
                    size="small"
                    class="btn-add btn-default"
                    @click="handleCreate"
                />
            </div>
        </div>
    </el-dialog>
</template>
<script>
import ESelect from '@/components/ESelect.vue'
import CustomButton from '@/components/CustomButton.vue'

import { mapState } from 'vuex'
import sendRequest from '@/services'
import {
    ESelectGradeGroup_MockData,
    ESelectTypeOfEducation_MockData,
    ESelectStatus_MockData_TraCuuTruongHoc
} from '@/mock_data'
import Api from '@/constants/Api'
import { filterCapHocList } from '@/utils'
export default {
    name: 'ModalThemMoiTruongHoc',
    props: {
        themMoiTruongHocModalVisible: {
            type: Boolean,
            required: true
        },
        closeModalThemMoi: {
            type: Function,
            required: true
        },

        handleSearch: Function
    },

    components: { CustomButton, ESelect },
    data() {
        return {
            maTthanh: '',
            maQhuyen: '',
            maQhuyen_change: '',
            maPxa: '',
            maPxa_change: '',
            maDonVi: '',
            dataUpdate: {},
            getDataESelect: {
                ESelect_tinhThanh: [], //tinhthanh
                ESelect_quanHuyen: [], //quanhuyen
                ESelect_phuongXa: [], //phuongxa
                ESelect_toThon: [], //tothon
                ESelect_donViQuanLy: [], //donviquanly

                ESelect_capHoc: [], //caphoc
                ESelect_nhomCapHoc: ESelectGradeGroup_MockData, //nhomcaphoc
                ESelect_loaiHinhDaoTao: ESelectTypeOfEducation_MockData, //loaihinhdaotao
                ESelect_loaiHinhTruong: [], //loaihinhtruong
                ESelect_chuanQuocGia: [], //chuanquocgia
                ESelect_trangThai: ESelectStatus_MockData_TraCuuTruongHoc //trangthaihoatdong
            },
            selectedValue: {
                selectedValue_tinhThanh: '',
                selectedValue_quanHuyen: '',
                selectedValue_phuongXa: '',
                selectedValue_toThon: '',
                selectedValue_donViquanLy: '',
                input_maTruongHoc: '',
                input_tenTruongHoc: '',
                selectedValue_capHoc: [],
                selectedValue_nhomCapHoc: [],
                selectedValue_loaiHinhDaoTao: [],
                selectedValue_loaiHinhTruong: [],
                date_ngayThanhLap: '',
                selectedValue_chuanQuocGia: [],
                input_webSite: '',
                input_tenHieuTruong: '',
                input_email: '',
                input_fax: '',
                input_diaChi: '',
                selectedValue_trangThai: '1'
            }
        }
    },
    watch: {
        themMoiTruongHocModalVisible(newValue) {
            if (newValue === true) {
                this.getDataTinhThanh()
                this.getDataQuanHuyen()
                this.getDataPhuongXa()
                this.getDonViQuanLy()
            }
        }
    },
    mounted() {
        this.getDataESelect.ESelect_capHoc = filterCapHocList(
            this.authUser.capHocList
        )
    },

    methods: {
        resetFrom() {
            this.selectedValue.selectedValue_toThon = ''
            this.selectedValue.selectedValue_tinhThanh = ''
            this.selectedValue.selectedValue_quanHuyen = ''
            this.selectedValue.selectedValue_phuongXa = ''
            this.selectedValue.selectedValue_donViquanLy = ''
            this.selectedValue.input_maTruongHoc = ''
            this.selectedValue.input_tenTruongHoc = ''
            this.selectedValue.selectedValue_capHoc = ''
            this.selectedValue.selectedValue_nhomCapHoc = ''
            this.selectedValue.selectedValue_loaiHinhDaoTao = ''
            this.selectedValue.selectedValue_loaiHinhTruong = ''
            this.selectedValue.date_ngayThanhLap = ''

            this.selectedValue.selectedValue_chuanQuocGia = ''
            this.selectedValue.input_webSite = ''
            this.selectedValue.input_tenHieuTruong = ''
            this.selectedValue.input_email = ''
            this.selectedValue.input_fax = ''
            this.selectedValue.input_diaChi = ''
            this.selectedValue.selectedValue_trangThai = ''
            this.closeModalThemMoi()
        },
        handleSelectChange_quanHuyen() {
            this.getDataPhuongXa(this.selectedValue.selectedValue_quanHuyen)
            this.getDataToThon(this.selectedValue.selectedValue_quanHuyen)
            this.selectedValue.selectedValue_phuongXa = ''
            this.selectedValue.selectedValue_toThon = ''
        },
        handleSelectChange_PhuongXa() {
            this.getDataToThon(this.selectedValue.selectedValue_phuongXa)
            this.selectedValue.selectedValue_toThon = ''
        },
        async getDataTinhThanh() {
            const request_Header = {
                token: this.authToken
            }
            const request_Params = {
                start: 0,
                limit: 100
            }
            const response = await sendRequest(
                Api.traCuu.traCuuTruongHoc.getDataTinhThanh,
                null,
                request_Header,
                request_Params
            )
            const dataTinhThanh = this.convertDataToNewFormat(
                response.rows,
                'tenTinhThanh',
                'maTinhThanh'
            )
            this.getDataESelect.ESelect_tinhThanh = dataTinhThanh
            this.selectedValue.selectedValue_tinhThanh = dataTinhThanh?.find(
                (item) => item.value == this.authUser.province
            )?.value
        },
        async getDataQuanHuyen() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const request_Header = {
                token: this.authToken
            }
            const request_Params = {
                start: 0,
                limit: 999,
                maTinhThanh: this.authUser.province
            }
            const response = await sendRequest(
                Api.traCuu.traCuuTruongHoc.getDataQuanHuyen,
                null,
                request_Header,
                request_Params
            )
            if (response.code == 200) {
                loading.close()
                const dataQuanHuyen = this.convertDataToNewFormat(
                    response.rows,
                    'tenQuanHuyen',
                    'maQuanHuyen'
                )
                this.getDataESelect.ESelect_quanHuyen = dataQuanHuyen
            } else if (response.code != 200) {
                loading.close()
            }
        },
        async getDataPhuongXa(maQhuyen_change) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const maQhuyen =
                maQhuyen_change !== undefined ? maQhuyen_change : this.maQhuyen
            const request_Header = {
                token: this.authToken
            }
            const request_Params = {
                start: 0,
                limit: 999,
                maTinhThanh: this.authUser.province,
                maQuanHuyen: maQhuyen
            }
            const response = await sendRequest(
                Api.traCuu.traCuuTruongHoc.getDataPhuongXa,
                null,
                request_Header,
                request_Params
            )
            if (response.code == 200) {
                loading.close()
                const dataPhuongXa = this.convertDataToNewFormat(
                    response.rows,
                    'tenPhuongXa',
                    'maPhuongXa'
                )
                this.getDataESelect.ESelect_phuongXa = dataPhuongXa
            } else if (response.code != 200) {
                loading.close()
            }
        },
        async getDataToThon(maQhuyen_change, maPxa_change) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const maQhuyen =
                maQhuyen_change !== undefined ? maQhuyen_change : this.maQhuyen
            const maPxa = maPxa_change !== undefined ? maPxa_change : this.maPxa
            const request_Header = {
                token: this.authToken
            }
            const request_Params = {
                limit: 999,
                maPhuongXa: maPxa,

                maQuanHuyen: maQhuyen,

                maTinhThanh: this.authUser.province,
                start: 0
            }

            const response = await sendRequest(
                Api.traCuu.traCuuTruongHoc.getToThon,
                null,
                request_Header,
                request_Params
            )
            if (response.code == 200) {
                loading.close()
                this.getDataESelect.ESelect_toThon = this.convertDataToNewFormat(
                    response.rows,
                    'tenToThon',
                    'maToThon'
                )
            } else if (response.code != 200) {
                loading.close()
            }

            // console.log(response)
        },
        async getDonViQuanLy() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const request_Header = {
                token: this.authToken
            }
            const request_Params = {
                start: 0,
                limit: 999,
                maTinhThanh: this.authUser.province
            }
            const response = await sendRequest(
                Api.traCuu.traCuuTruongHoc.getDonViQuanLy,
                null,
                request_Header,
                request_Params
            )
            if (response.code == 200) {
                loading.close()
                const dataDonVi = this.convertDataToNewFormat(
                    response.rows,
                    'tenDonVi',
                    'maDonVi'
                )
                this.getDataESelect.ESelect_donViQuanLy = dataDonVi
            } else if (response.code != 200) {
                loading.close()
            }
        },

        async handleCreate() {
            if (
                !this.selectedValue.selectedValue_quanHuyen ||
                !this.selectedValue.selectedValue_phuongXa ||
                !this.selectedValue.selectedValue_donViquanLy ||
                !this.selectedValue.input_maTruongHoc ||
                !this.selectedValue.input_tenTruongHoc ||
                !this.selectedValue.selectedValue_capHoc ||
                !this.selectedValue.selectedValue_loaiHinhDaoTao ||
                !this.selectedValue.selectedValue_loaiHinhTruong ||
                !this.selectedValue.selectedValue_chuanQuocGia ||
                !this.selectedValue.input_email
            ) {
                this.$message.error(
                    ' Vui lòng kiểm tra & bổ sung thông tin bắt buộc'
                )
                return
            }

            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                capHoc: this.selectedValue.selectedValue_capHoc
                    .map((item) => item.value)
                    .reduce(
                        (accumulator, currentValue) => accumulator + currentValue,
                        ''
                    ),
                capHocs: this.selectedValue.selectedValue_capHoc.map(
                    (item) => item.value
                ),
                chiBoDang: null,
                chuanQuocGia: this.selectedValue.selectedValue_chuanQuocGia,
                diaChi: this.selectedValue.input_diaChi,
                email: this.selectedValue.input_email,
                fax: this.selectedValue.input_fax,
                idPhong: '',
                idSo: this.authUser.province,
                loaiHinhDaoTao: this.selectedValue.selectedValue_loaiHinhDaoTao,
                loaiHinhTruong: Number(
                    this.selectedValue.selectedValue_loaiHinhTruong
                ),
                ma: this.selectedValue.input_maTruongHoc,
                maDoiTac: '',
                maDonVi: this.selectedValue.selectedValue_donViquanLy,
                maNhomCapHoc: this.selectedValue.selectedValue_nhomCapHoc,
                maPhuongXa: this.selectedValue.selectedValue_phuongXa,
                maQhuyen: this.selectedValue.selectedValue_quanHuyen,
                maQuanHuyen: this.selectedValue.selectedValue_quanHuyen,
                maTinhThanh: this.selectedValue.selectedValue_tinhThanh,
                maTruongHoc: this.selectedValue.input_maTruongHoc,
                maTthanh: this.selectedValue.selectedValue_tinhThanh,
                maTthon: this.selectedValue.selectedValue_toThon,
                ngayCapNhat: this.dataUpdate.ngayCapNhat,
                ngayThanhLap: this.selectedValue.date_ngayThanhLap,

                sdt: '',
                sdtHieuTruong: '',
                ten: this.selectedValue.input_tenTruongHoc,
                tenDonVi: '',
                tenHieuTruong: this.selectedValue.input_tenHieuTruong,
                tenPhuongXa: '',
                tenQuanHuyen: '',
                tenTinhThanh: '',
                tenToThon: '',
                tenTruongHoc: this.selectedValue.input_tenTruongHoc,
                trangThai: this.selectedValue.selectedValue_trangThai,
                vungBienGioi: '',
                vungKhoKhan: '',
                website: this.selectedValue.input_webSite
            }

            this.$confirm('Thêm mới?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const response = await sendRequest(
                        Api.traCuu.traCuuTruongHoc.createTruongHoc,
                        request_Data,
                        request_Header,
                        null
                    )
                    console.log(response)
                    if (response.code == 200) {
                        this.$message({
                            type: 'success',
                            message: 'Thêm mới thành công'
                        })
                        this.$emit('handleSearch')
                        this.closeModalThemMoi()
                    }
                    if (response.code != 200) {
                        this.$message({
                            type: 'error',
                            message: response.message
                        })
                    }
                })
                .catch(() => {})
        },
        convertDataToNewFormat(dataArray, titlePropertyName, valuePropertyName) {
            return dataArray.map((item) => ({
                title: `${item[titlePropertyName]} - [${item[valuePropertyName]}]`,
                value: item[valuePropertyName]
            }))
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonLoaihinhtruong_Store() {
            return JSON.parse(localStorage.getItem('data_ChonLoaihinhtruong'))
        },
        dataChonDatChuanQG_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDatChuanQG'))
        }
    }
}
</script>
<style>
hr {
    border: 1px solid green;
}
label {
    font-size: 14px;
    font-weight: 500;
}
label .span {
    color: red;
}

.custom-date-picker {
    height: 32px;
}
.custom-date-picker::placeholder {
    color: gainsboro;

    font-size: 13px;
    background-color: transparent;
    appearance: none;
}
.layout-btn {
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
}
.btn-close {
    background-color: var(--orange);
    color: var(--btn-text-color);
}
.btn-close:hover {
    background-color: var(--orange);
    color: var(--btn-text-color);
}
.btn-add,
.btn-add:hover {
    background-color: var(--light-green);
    color: var(--btn-text-color);
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
</style>
