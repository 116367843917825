<template>
    <el-dialog
        :visible="lichSuDangNhapModalVisible"
        @close="closeModalLichSuDangNhap"
        :fullscreen="true"
    >
        <span slot="title" class="custom-dialog-title">
            Lịch sử đăng nhập hệ thống
            <hr />
        </span>
        <div class="content">
            <div class="row">
                <div class="col-md-4 input-group input-daterange">
                    <el-date-picker
                        v-model="selectedValue.startTime"
                        type="date"
                        placeholder="Ngày bắt đầu"
                        format="dd/MM/yyyy"
                        size="small"
                    >
                    </el-date-picker>
                    <div class="input-group-addon">đến</div>

                    <el-date-picker
                        v-model="selectedValue.endTime"
                        type="date"
                        placeholder="Ngày kết thúc"
                        format="dd/MM/yyyy"
                        size="small"
                    >
                    </el-date-picker>
                </div>
                <div class="col-md-3">
                    <CustomButton
                        label="Tìm kiếm"
                        size="small"
                        type="success"
                        @click="handleSearch"
                    />
                </div>
            </div>
        </div>
        <div class="layout-table">
            <table class="table table-bordered table-hover centered-table">
                <thead>
                    <tr>
                        <th class="text-thead">STT</th>
                        <th class="text-thead">Tài khoản</th>
                        <th class="text-thead">Trạng thái</th>
                        <th class="text-thead">Thời gian</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="tableData.length === 0">
                        <tr>
                            <td class="text-tbody text-center" colspan="6">
                                Không có bản ghi nào.
                            </td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td class="text-tbody text-center">
                            {{ (currentPage - 1) * limit + index + 1 }}
                        </td>
                        <td class="text-tbody text-center">
                            {{ item.username }}
                        </td>
                        <td class="text-tbody">
                            {{ item.responseMessage }}
                        </td>

                        <td class="text-tbody text-center">
                            {{ item.loginTimeString }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <CustomPagination
            v-show="total_rows > 0"
            :tongbanghi="total_rows"
            :soluonghienthi="soLuongBanGhiHienThi"
            :batdau="trangbatdau"
            @pageChange="layLai($event)"
        >
        </CustomPagination>
        <span slot="footer">
            <div class="layout-btn">
                <div>
                    <CustomButton
                        label="Đóng"
                        size="small"
                        type="info"
                        @click="closeModalLichSuDangNhap"
                    />
                </div>
            </div>
        </span>
    </el-dialog>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import { mapState } from 'vuex'
import sendRequest from '@/services'
import moment from 'moment'
import Api from '@/constants/Api'
export default {
    props: {
        lichSuDangNhapModalVisible: {
            type: Boolean,
            required: true
        },
        closeModalLichSuDangNhap: {
            type: Function,
            required: true
        }
    },

    components: { CustomButton, CustomPagination },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            soLuongBanGhiHienThi: 25,
            trangbatdau: false,

            selectedValue: {
                startTime: '',
                endTime: ''
            },
            tableData: []
        }
    },
    watch: {
        lichSuDangNhapModalVisible(newValue) {
            if (newValue === true) {
                this.handleSearch()
            }
        }
    },
    methods: {
        checkTruocKhiTim() {
            this.trangbatdau = !this.trangbatdau
        },
        layLai(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        async handleSearch() {
            const startTime = this.selectedValue.startTime
            const convertStartTime = moment(
                startTime,
                'DD/MM/YYYY',
                true
            ).isValid()
                ? moment(startTime).format('DD/MM/YYYY')
                : ''

            const endTime = this.selectedValue.endTime
            const convertEndTime = moment(endTime, 'DD/MM/YYYY', true).isValid()
                ? moment(endTime).format('DD/MM/YYYY')
                : ''

            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Data = {
                    username: this.authUser.username,
                    startTime: convertStartTime,
                    endTime: convertEndTime,
                    start: this.start,
                    limit: this.limit
                }
                const response = await sendRequest(
                    Api.user.lichSuDangNhap.danhSach,
                    request_Data,
                    request_Header
                )
                if (response.code == 200) {
                    loading.close()
                }
                this.tableData = response.data
                this.total_rows = response.total
            } catch (error) {
                loading.close()
            }
        }
    },

    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped>
hr {
    border: 1px solid green;
}
.content {
    background: #fff;
    padding: 0px 10px 0px 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}
.content label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px;
}
.content .span {
    color: red;
}
.content .title {
    margin-bottom: 5px;
    font-size: 18px;
    color: #212529;
}
.layout-btn {
    padding: 20px 10px 5px 10px;
    justify-content: space-between;
    display: flex;
    background: #fff;
}

.btn-search {
    margin-top: 15px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
}
.layout-table {
    background: #fff;
    padding: 0px 10px 5px 10px;
    border-radius: 4px;

    overflow-x: auto;
}
table thead tr {
    background: rgb(228, 235, 245);
}
.table {
    white-space: nowrap;
}
.centered-table {
    margin-left: auto;
    margin-right: auto;
}

.centered-table th {
    text-align: center;
}
.table-bordered {
    background-color: #fff;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}
.table-hover tbody tr:hover {
    background-color: #f5f5f5;
}

.text-thead {
    font-size: 13px;

    vertical-align: middle; /* Căn giữa theo chiều dọc */
}
.text-tbody {
    font-size: 13px;
    vertical-align: middle;
}
.text-center {
    text-align: center;
}

.layout-btn {
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
}
.input-group-addon {
    padding: 8px 19px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
}
</style>
