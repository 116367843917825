// validationUtils.js
import moment from 'moment'

export function validateInput(type, value) {
    switch (type) {
        case 'text':
            if (value.trim() === '') {
                return 'Vui lòng nhập thông tin'
            }
            return null // Không có lỗi

        case 'email':
            if (value.trim() === '') {
                return 'Vui lòng nhập Email '
            }
            if (!/^\S+@\S+\.\S+$/.test(value)) {
                return 'Email không đúng định dạng'
            }
            return null // Không có lỗi
        case 'phone':
            if (value.trim() === '') {
                return 'Vui lòng nhập số điện thoại'
            }
            if (!/^\d{10}$/.test(value)) {
                return 'Số điện thoại phải có 10 số'
            }
            return null // Không có lỗi

        case 'password':
            if (value.trim() === '') {
                return 'Vui lòng nhập Mật khẩu'
            }
            return null // Không có lỗi

        default:
            return 'Không hợp lệ ' // Loại input không hợp lệ
    }
}

export const customColorStackedColumnChart = (arr) => {
    const order = ['MN', 'TH', 'THCS', 'THPT', 'GDTX', 'LC12', 'LC23', 'LCK']
    const sortedArray = []

    for (const name of order) {
        const obj = arr?.find((item) => item.name === name)
        if (obj) {
            sortedArray?.push(obj)
        }
    }

    return sortedArray
}

export const convertCapHocList = (capHocList) => {
    const convertedList = capHocList.map((value) => ({
        value: String(value)
    }))
    return convertedList
}

export const currentYear = new Date().getFullYear() - 1

export const validateField = (component, field, isValid) => {
    if (
        field.value === '' ||
        field.value === null ||
        field.value === undefined ||
        field.value.length === 0
    ) {
        component[field.errorField] = field.errorMessage
        return false
    } else if (field.name === 'email') {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (!emailRegex.test(field.value)) {
            component[field.errorField] = 'Email không hợp lệ'
            return false
        } else {
            component[field.errorField] = ''
        }
    } else if (field.name === 'phone_number') {
        const phoneNumberRegex = /^(\+84|84)?\s*\d{9}$|^\d{10}$/
        if (!phoneNumberRegex.test(field.value)) {
            component[field.errorField] = 'Số điện thoại không hợp lệ'
            return false
        } else {
            component[field.errorField] = ''
        }
    } else if (
        field.name === 'realName' ||
        field.name === 'display_name' ||
        field.name === 'username'
    ) {
        if (field.value.length < 6 || field.value.length > 255) {
            component[field.errorField] = 'Vui lòng nhập từ 6 đến 255 ký tự'
            return false
        } else {
            component[field.errorField] = ''
        }
    } else if (field.name === 'password') {
        if (
            field.value === '' ||
            field.value === null ||
            field.value === undefined
        ) {
            component[field.errorField] = ''
            return isValid
        } else {
            const passwordRegex =
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
            if (!passwordRegex.test(field.value)) {
                component[field.errorField] =
                    'Mật khẩu phải có ít nhất 8 ký tự, bao gồm chữ hoa, chữ thường, chữ số và ký tự đặc biệt'
                return false
            } else {
                component[field.errorField] = ''
            }
        }
    } else if (field.name === 'password_retype') {
        const passwordValue = component['password']
        if (passwordValue !== '' && field.value !== passwordValue) {
            component[field.errorField] = 'Mật khẩu nhập lại không khớp'
            return false
        } else {
            component[field.errorField] = ''
        }
    } else {
        component[field.errorField] = ''
    }

    return isValid
}
export const changeValidate = (component, field) => {
    if (component[field.errorField]) {
        // Nếu có lỗi trước đó, kiểm tra nếu giá trị hiện tại là hợp lệ thì xóa lỗi
        if (validateField(component, field, true)) {
            component[field.errorField] = '' // Xóa lỗi
        }
    }
}

export const convertToFormattedDate = (selectedDate) => {
    return moment(selectedDate, 'DD/MM/YYYY', true).isValid()
        ? moment(selectedDate, 'DD/MM/YYYY').format('DD/MM/YYYY')
        : ''
}

import { ESelectGradeLevel_MockData } from '@/mock_data/index'
export const filterCapHocList = (authUserCapHocList) => {
    return ESelectGradeLevel_MockData.filter((item) =>
        authUserCapHocList.includes(parseInt(item.value))
    )
}

export const getValuesFromSelected = (selectedItems) => {
    return selectedItems && Array.isArray(selectedItems)
        ? selectedItems.map((item) => item.value)
        : []
}

// export const base64ToFile = (
//     base64String,
//     fileName,
//     mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
// ) => {
//     const byteCharacters = atob(base64String)
//     const byteNumbers = new Array(byteCharacters.length)

//     for (let i = 0; i < byteCharacters.length; i++) {
//         byteNumbers[i] = byteCharacters.charCodeAt(i)
//     }

//     const byteArray = new Uint8Array(byteNumbers)
//     const blob = new Blob([byteArray], { type: mimeType })

//     // Tạo đối tượng File từ blob
//     const file = new File([blob], fileName, { type: mimeType })

//     // Thêm các thuộc tính vào đối tượng File
//     const currentDate = new Date()
//     file.lastModified = currentDate.getTime()
//     file.lastModifiedDate = currentDate

//     return file
// }

export const convertBase64ToFile = (base64String) => {
    return new Promise((resolve, reject) => {
        try {
            const byteCharacters = atob(base64String)
            const byteNumbers = new Array(byteCharacters.length)

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i)
            }

            const byteArray = new Uint8Array(byteNumbers)
            const blob = new Blob([byteArray], {
                type: 'application/octet-stream'
            })

            const file = new File([blob], '', {
                type: 'application/octet-stream'
            })

            resolve(file)
        } catch (error) {
            reject(error)
        }
    })
}

export const convertTimestamp = (timestamp) => {
    if (!moment(timestamp).isValid()) {
        return ''
    }

    return moment(timestamp).format('YYYY-MM-DD HH:mm:ss')
}
