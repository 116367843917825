<template>
    <el-dialog
        :visible="themMoiVisible"
        @close="closeModalThemMoi"
        width="80%"
        class="custom-dialog"
    >
        <span slot="title" class="custom-dialog-title"
            >Thêm mới tài khoản
            <hr />
        </span>
        <div class="row">
            <div class="col-4">
                <label class="label">Đơn vị quản lý</label>
                <div>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="this.dataChonDonVi_Store"
                        :placeholder="'Chọn đơn vị'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="selectedValue.selectedValueUnitEducation"
                        @change="getDataChonTruongHoc"
                    />
                </div>
            </div>
            <div class="col-2">
                <label class="label">Cấp học</label>
                <div>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectGradeLevel_MockData"
                        :placeholder="'Chọn cấp học'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenTruongHoc', 'value']"
                        v-model="selectedValue.selectedValueGradeLevel"
                        @change="getDataChonTruongHoc"
                    />
                </div>
            </div>
            <div class="col-3">
                <label class="label">Trường học<span class="span">*</span></label>
                <div>
                    <ESelect
                        :reset="resetESelectSchool"
                        @reset-completed="handleResetSchoolCompleted"
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectSchool"
                        :placeholder="'Chọn trường học'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenTruongHoc', 'maTruongHoc']"
                        v-model="selectedValue.selectedValueSchool"
                    />
                </div>
            </div>
            <div class="col-3">
                <label class="label">Đối tác<span class="span">*</span></label>
                <div>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="this.ESelectPartner"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['title', 'value']"
                        v-model="selectedValue.selectedValuePartner"
                    />
                </div>
            </div>
        </div>
        <div class="layout-btn">
            <CustomButton
                label="Đóng"
                size="small"
                @click="closeModalThemMoi"
                class="btn-default btn-delete"
            />
            <CustomButton
                label="Thêm mới"
                class="btn-default btn-add"
                size="small"
                @click="handleThemMoi"
            />
        </div>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
import ESelect from '@/components/ESelect.vue'
import CustomButton from '@/components/CustomButton.vue'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { ESelectGradeLevel_MockData } from '@/mock_data'
export default {
    name: 'ModalDoiMauKhau',
    props: {
        themMoiVisible: {
            type: Boolean,
            required: true
        },

        closeModalThemMoi: {
            type: Function,
            required: true
        },
        ESelectPartner: {
            type: Array,
            required: true
        },

        handleSearch: Function
    },
    components: { CustomButton, ESelect },
    watch: {
        themMoiVisible(newValue) {
            if (newValue === true) {
                this.getDataChonTruongHoc()
            }
        }
    },
    data() {
        return {
            resetESelectSchool: false,
            getDataESelect: {
                ESelectUnitEducation: [], //donvi
                ESelectGradeLevel_MockData: ESelectGradeLevel_MockData, //caphoc
                ESelectSchool: [] //truonghoc,
            },
            selectedValue: {
                selectedValueUnitEducation: [], //donvi
                selectedValueGradeLevel: [], //caphoc
                selectedValueSchool: [], //truonghoc,
                selectedValuePartner: [] //doitac
            }
        }
    },

    methods: {
        handleResetSchoolCompleted() {
            this.resetESelectSchool = false
        },
        async handleThemMoi() {
            const maDoiTac = this.selectedValue.selectedValuePartner.value
            const maTruongHoc = this.convertToValueArray(
                this.selectedValue.selectedValueSchool
            )

            const truongEntities = {
                maDoiTac: maDoiTac,
                maTruongHoc: maTruongHoc
            }
            const convertTruongEntities = []

            truongEntities.maTruongHoc.forEach((maTruong) => {
                convertTruongEntities.push({
                    maDoiTac: truongEntities.maDoiTac,
                    maTruongHoc: maTruong
                })
            })
            console.log(maTruongHoc)
            if (maTruongHoc.length === 0) {
                this.$message({
                    type: 'warning',
                    message: 'Chọn trường học'
                })
                return
            }
            if (maDoiTac === undefined) {
                this.$message({
                    type: 'warning',
                    message: 'Chọn mã đối tác'
                })
                return
            }

            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                truongEntities: convertTruongEntities
            }

            this.$confirm('Xác nhận thêm mới đối tác của trường?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    })
                    const response = await sendRequest(
                        Api.heThong.quanLyHeThongDoiTacTruong.themMoiDanhSach,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        loading.close()

                        this.$message({
                            type: 'success',
                            message: 'Thêm mới thành công'
                        })
                        this.closeModalThemMoi()
                        this.$emit('handleSearch')
                    } else {
                        loading.close()
                        this.$message({
                            type: 'warning',
                            message: response.rd
                        })
                    }
                })
                .catch(() => {})
        },
        async getDataChonTruongHoc() {
            const maDonVi = this.convertToValueArray(
                this.selectedValue.selectedValueUnitEducation
            )

            const capHoc = this.convertToValueArray(
                this.selectedValue.selectedValueGradeLevel
            )

            const request_Header = {
                token: this.authToken
            }

            const request_Data = {
                capHoc: capHoc,
                maDonVi: maDonVi
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            let response = await sendRequest(
                Api.internal_api.dm_School,
                request_Data,
                request_Header,
                null
            )
            this.getDataESelect.ESelectSchool = []
            this.selectedValue.selectedValueSchool = []

            if (response.statusResponse == 0) {
                loading.close()
                this.getDataESelect.ESelectSchool = response.rows.map((item) => ({
                    ...item,
                    tenTruongHoc: `${item.tenTruongHoc} - [${item.maTruongHoc}]`
                }))
            }

            this.resetESelectSchool = true
        },
        convertToValueArray(array) {
            return array.map((item) => item.value)
        }
    },

    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        }
    }
}
</script>
<style scoped>
.label {
    font-weight: 700;
}
.row span {
    color: red;
}
label .span {
    color: red;
}
.layout-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
hr {
    border: 1px solid #7ca33f;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.bg-search,
.btn-info {
    background-color: var(--teal);
}
.btn-add {
    background-color: var(--light-green);
}

.btn-update {
    background-color: var(--yellow);
}
.btn-delete {
    background-color: var(--orange);
}
</style>
