<template>
    <div class="school">
        <div class="header">
            <div class="left">
                <div>
                    <el-icon name="s-custom" class="icon"></el-icon>
                </div>
                <div class="title">Cán bộ - Giáo viên</div>
            </div>
            <div class="right">
                <div>
                    <i class="el-icon-timer"></i>
                </div>
                <div>
                    Thời gian cập nhật:
                    <span class="time">{{ dataCanBoGiaoVien?.lastUpdate }}</span>
                    <CustomButton
                        label="Chi tiết"
                        size="small"
                        class="custom-btn"
                        @click="handleNavigate"
                    />
                </div>
            </div>
        </div>

        <div class="layout-card" v-show="dataCanBoGiaoVien !== null">
            <div class="row">
                <div class="col-md-4 mb-4">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">
                                Tổng quan
                                <span class="span">
                                    (Đang làm việc:
                                    {{ dataCanBoGiaoVien?.slCbDangLamViec }} /
                                    Tổng số {{ getTotalCanBo() }})</span
                                >
                            </div>
                        </div>
                        <div class="card-body">
                            <PieChart
                                :data_PieChart="
                                    dataCanBoGiaoVien?.tongCanBoChart || []
                                "
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">Trình độ chính</div>
                        </div>
                        <div class="card-body">
                            <StackedColumnChart
                                :data_StackedColumnChart="
                                    dataCanBoGiaoVien?.toanTruong
                                        ?.cbTrinhDoChinh || []
                                "
                                :xaxis_categories="xaxisCategories.trinhDoChinh"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">Loại hợp đồng</div>
                        </div>
                        <div class="card-body">
                            <StackedColumnChart
                                :data_StackedColumnChart="
                                    dataCanBoGiaoVien?.toanTruong?.cbHopDong || []
                                "
                                :xaxis_categories="xaxisCategories.loaiHopDong"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">Giới tính</div>
                        </div>
                        <div class="card-body">
                            <StackedColumnChart
                                :data_StackedColumnChart="
                                    dataCanBoGiaoVien?.toanTruong?.cbGioiTinh ||
                                    []
                                "
                                :xaxis_categories="xaxisCategories.gioiTinh"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">ĐỘ TUỔI</div>
                        </div>
                        <div class="card-body">
                            <StackedColumnChart
                                :data_StackedColumnChart="
                                    dataCanBoGiaoVien?.toanTruong?.cbDoTuoi || []
                                "
                                :xaxis_categories="xaxisCategories.doTuoi"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">TRẠNG THÁI</div>
                        </div>
                        <div class="card-body">
                            <StackedColumnChart
                                :data_StackedColumnChart="
                                    dataCanBoGiaoVien?.toanTruong
                                        ?.canBoTrangThai || []
                                "
                                :xaxis_categories="xaxisCategories.trangThai"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Router } from '@/constants/Router'
import CustomButton from '@/components/CustomButton.vue'
import PieChart from '@/components/PieChart.vue'
import StackedColumnChart from '@/components/StackedColumnChart.vue'

export default {
    name: 'educatorProfile',
    components: {
        CustomButton,
        PieChart,
        StackedColumnChart
    },
    data() {
        return {
            xaxisCategories: {
                trinhDoChinh: [
                    'Tiến sĩ',
                    'Thạc sĩ',
                    'Đại học',
                    'Cao đẳng',
                    'Trung cấp'
                ],
                gioiTinh: ['Nam', 'Nữ'],
                loaiHopDong: ['HĐKXĐ thời hạn', 'HĐXĐ thời hạn', 'HĐLĐ'],
                doTuoi: [
                    '<20',
                    '20-29',
                    '30-39',
                    '40-49',
                    '50-54',
                    '55-59',
                    '>=60'
                ],
                trangThai: ['Đang làm việc', 'Chuyển đến', 'Chuyển đi', 'Khác']
            }
        }
    },
    props: {
        dataCanBoGiaoVien: {
            required: true
        }
    },
    methods: {
        handleNavigate() {
            this.$router.push(Router.traCuu.traCuuCanBoGV)
        },
        getTotalCanBo() {
            return this?.dataCanBoGiaoVien?.tongCanBoChart?.reduce(
                (accumulator, currentValue) => {
                    return accumulator + currentValue
                },
                0
            )
        }
    }
}
</script>

<style scoped>
.school {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 15px;
    padding-left: 6px;
    padding-right: 6px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 10px 12px 10px;
}
.header .left,
.header .right {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #186ab2;
    font-weight: 700;
    text-transform: uppercase;
}
.header .right .time {
    color: #333;
}
.header .layout-icon {
    margin-right: 8px;
}
.header .title .span {
    color: #333;
    font-weight: 600;
}
.header .left .icon {
    margin-right: 5px;
}
.header .right .icon {
    font-size: 18px;
    color: #000000;
    font-weight: 700;
    margin-right: 5px;
}

.header .right {
    margin-left: 6px;
}

.layout-card {
    padding: 0px 10px 20px 10px;
}
.layout-card .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f2f3f8;
    height: 25px;
    color: black;
    padding: 5px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.layout-card .card-header .title {
    margin: -5px 0px 0px 10px;
    color: #000000;
    font-weight: bold;
    font-size: 15px;
}
.layout-card .card-header .title .span {
    color: #2e93fa;
}
.layout-card .card-body {
    padding: 5px;
    background: white;
    border: #f2f3f8 2px solid;
    height: 300px;
}
.custom-btn {
    font-weight: 700;
    color: #fff;
    background-color: #6aab9c;
}
</style>
