<template>
    <el-dialog
        :visible="doiMauKhauVisible"
        @close="closeModalDoiMauKhau"
        width="60%"
        class="custom-dialog"
    >
        <span slot="title" class="custom-dialog-title">Đổi mật khẩu</span>
        <div class="row">
            <div class="col-6">
                <label>Mật khẩu mới: <span>*</span></label>
                <el-input
                    placeholder="Nhập"
                    v-model="matKhauMoi"
                    show-password
                ></el-input>
            </div>
            <div class="col-6">
                <label>Nhập lại Mật khẩu mới: <span>*</span></label>
                <el-input
                    placeholder="Nhập"
                    v-model="nhapLaiMatKhauMoi"
                    show-password
                ></el-input>
            </div>
        </div>
        <div class="layout-btn">
            <CustomButton
                label="Đóng"
                size="small"
                @click="closeModalDoiMauKhau"
                class="btn-default btn-delete"
            />
            <CustomButton
                label="Thay đổi"
                class="btn-default btn-add"
                size="small"
                @click="handleChangePassword"
            />
        </div>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
import CustomButton from '@/components/CustomButton.vue'
import sendRequest from '@/services'
import Api from '@/constants/Api'
export default {
    name: 'ModalDoiMauKhau',
    props: {
        doiMauKhauVisible: {
            type: Boolean,
            required: true
        },

        closeModalDoiMauKhau: {
            type: Function,
            required: true
        },
        dataDoiMauKhau: {
            type: String,
            required: true
        },
        handleSearch: Function
    },
    components: { CustomButton },
    data() {
        return {
            matKhauMoi: '',
            nhapLaiMatKhauMoi: ''
        }
    },

    methods: {
        async handleChangePassword() {
            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                password: this.nhapLaiMatKhauMoi,
                username: this.dataDoiMauKhau
            }

            this.$confirm('Xác nhận thay đổi Mật khẩu?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const response = await sendRequest(
                        Api.heThong.quanLyNguoiDung.thayDoiMatKhau,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        this.$message({
                            type: 'success',
                            message: 'Thay đổi thành công'
                        })
                        await this.closeModalDoiMauKhau()
                        await this.handleSearch()
                    }
                    if (response.rc != 0) {
                        this.$message({
                            type: 'info',
                            message: response.rd
                        })
                    }
                })
                .catch(() => {})
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped>
.row span {
    color: red;
}
.layout-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}

.btn-add {
    background-color: var(--light-green);
}

.btn-delete {
    background-color: var(--orange);
}
</style>
