<template>
    <div>
        <CustomBreadcrumb :title="'HỆ THỐNG '" content="NHẬT KÝ HỆ THỐNG" />
        <CustomContentUI>
            <div class="row">
                <div class="col-3">
                    <label>Ngày bắt đầu</label>
                    <div>
                        <date-picker
                            :config="configDatePicker"
                            placeholder="Chọn ngày"
                            v-model="timeStart"
                            class="custom-date-picker"
                        >
                        </date-picker>
                    </div>
                </div>
                <div class="col-3">
                    <label>Ngày kết thúc</label>
                    <div>
                        <date-picker
                            :config="configDatePicker"
                            placeholder="Chọn ngày"
                            v-model="timeEnd"
                            class="custom-date-picker"
                        >
                        </date-picker>
                    </div>
                </div>
                <div class="col-3">
                    <label>Nhóm nhật ký</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="list_actionType"
                            :placeholder="'Chọn '"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['title', 'value']"
                            v-model="actionType"
                        />
                    </div>
                </div>
                <div class="col-3">
                    <label>Mức độ quan trọng</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="list_actionPriority"
                            :placeholder="'Chọn '"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['title', 'value']"
                            v-model="actionPriority"
                        />
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-3">
                    <label>Tài khoản thức hiện</label>
                    <div>
                        <el-input
                            placeholder="Nhập"
                            size="small"
                            v-model="username"
                        ></el-input>
                    </div>
                </div>
                <div class="col-3">
                    <label>Quyền tài khoản thực hiện</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="list_roleId"
                            :placeholder="'Chọn '"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['roleName', 'roleId']"
                            v-model="roleId"
                        />
                    </div>
                </div>
            </div>
            <div class="layout-btn-search">
                <CustomButton
                    label="Tìm kiếm"
                    class="btn-default bg-search"
                    size="small"
                    @click="handleSearch"
                />
            </div>
        </CustomContentUI>

        <div
            class="layout-btn"
            :style="{
                'max-width': leftBarWidth()
            }"
        >
            <div>
                <chonSoLuongBanGhi @chonXongSoLuong="ChonSoLuongBanGhi($event)" />
            </div>
        </div>
        <CustomTable>
            <template v-slot:header>
                <tr>
                    <th>STT</th>
                    <th>Tên tài khoản</th>
                    <th>Nhóm nhật ký</th>
                    <th>Trạng thái</th>
                    <th>Mức độ quan trọng</th>
                    <th>IP bị chặn</th>
                    <th>Số ngày cần đổi mật khẩu</th>
                    <th>Số ngày lưu lịch sử</th>
                    <th>Số lần tối đa được phép nhập mật khẩu sai</th>
                    <th>Thời gian cập nhật</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="11" class="text-tbody">
                            Không có bản ghi nào.
                        </td>
                    </tr>
                </template>

                <tr v-for="(row, index) in tableData" :key="index">
                    <td class="text-tbody text-center">
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td>{{ row.username }}</td>
                    <td>{{ row.actionType }}</td>
                    <td>{{ row.actionDescription }}</td>
                    <td>{{ getTitleActionPriority(row.actionPriority) }}</td>
                    <td>
                        <ul>
                            <li
                                v-for="(ip, i) in row.updateData.blockedIpList"
                                :key="i"
                            >
                                {{ ip }}
                            </li>
                        </ul>
                    </td>
                    <td>{{ row.updateData.numDayChangePass }}</td>
                    <td>{{ row.updateData.numDaySaveHis }}</td>
                    <td>{{ row.updateData.numEnterPassFail }}</td>
                    <td>{{ convertTimestamp(row.updateTime) }}</td>
                </tr>
            </template>
        </CustomTable>

        <CustomPagination
            v-show="total_rows > 0"
            :tongbanghi="total_rows"
            :soluonghienthi="soLuongBanGhiHienThi"
            :batdau="trangbatdau"
            @pageChange="layLai($event)"
        >
        </CustomPagination>
    </div>
</template>
<script>
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'
import CustomButton from '@/components/CustomButton.vue'
import chonSoLuongBanGhi from '@/components/chonSoLuongBanGhi.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import ESelect from '@/components/ESelect.vue'
import { mapState } from 'vuex'
import Api from '@/constants/Api'
import sendRequest from '@/services'
import CustomContentUI from '@/components/CustomContentUI.vue'
import CustomTable from '@/components/CustomTable.vue'
import datePicker from 'vue-bootstrap-datetimepicker'
import { convertTimestamp } from '@/utils'
import moment from 'moment'
export default {
    name: 'TraCuuKetQuaHocTapTT22',
    components: {
        ESelect,
        CustomButton,
        CustomBreadcrumb,
        chonSoLuongBanGhi,
        CustomPagination,

        CustomContentUI,
        CustomTable,
        datePicker
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            soLuongBanGhiHienThi: 25,
            trangbatdau: false,

            tableData: [],
            tableColumns: [
                { key: '1', label: 'STT' },
                { key: '2', label: 'Tên tài khoản' },
                { key: '3', label: 'Tên đăng nhập' },
                { key: '4', label: 'Email' },
                { key: '5', label: 'Vai trò' },
                { key: '6', label: 'Loại tài khoản' },
                { key: '7', label: 'Trạng thái' },
                { key: '8', label: 'Thao tác' }
            ],
            dataRole: [],

            configDatePicker: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
                showTodayButton: true,
                locale: 'vi'
            },
            timeStart: '',
            timeEnd: '',
            list_actionType: [
                {
                    title: 'USER_LOGIN',
                    value: 'USER_LOGIN'
                },
                {
                    title: 'SUPER_ADMIN_LOGIN',
                    value: 'SUPER_ADMIN_LOGIN'
                },
                {
                    title: 'ACTION_ERROR',
                    value: 'ACTION_ERROR'
                },
                {
                    title: 'USER_MANAGE',
                    value: 'USER_MANAGE'
                },
                {
                    title: 'MODIFY_CONFIG',
                    value: 'MODIFY_CONFIG'
                }
            ],
            actionType: '',

            list_actionPriority: [
                {
                    title: 'Mức 1',
                    value: '1'
                },
                {
                    title: 'Mức 2',
                    value: '2'
                },
                {
                    title: 'Mức 3',
                    value: '3'
                },
                {
                    title: 'Mức 4',
                    value: '4'
                },
                {
                    title: 'Mức 5',
                    value: '5'
                }
            ],
            actionPriority: '',
            username: '',
            list_roleId: [],
            roleId: ''
        }
    },
    methods: {
        convertTimestamp,
        getTitleActionPriority(value) {
            const action = this.list_actionPriority.find(
                (item) => item.value == value
            )
            return action ? action.title : ''
        },
        ChonSoLuongBanGhi(e) {
            this.total_rows = 0
            this.soLuongBanGhiHienThi = e.soluong
        },
        checkTruocKhiTim() {
            this.trangbatdau = !this.trangbatdau
        },
        layLai(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },

        async handleSearch() {
            const request_Header = {
                token: this.authToken
            }
            const request_Params = {
                limit: this.limit,
                actionType: this.actionType,
                actionPriority: this.actionPriority,
                timeStart: this.timeStart
                    ? moment(this.timeStart, 'DD/MM/YYYY').unix()
                    : '',
                timeEnd: this.timeEnd
                    ? moment(this.timeEnd, 'DD/MM/YYYY').unix()
                    : '',

                start: this.start,
                username: this.username,
                roleId: this.roleId
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const response = await sendRequest(
                Api.heThong.lichSuTruyCap.danhSach,
                null,
                request_Header,
                request_Params
            )
            if (response.rc == 0) {
                loading.close()
                this.$message({
                    type: 'success',
                    message: 'Danh sách tìm kiếm'
                })
                this.tableData = response.rows

                this.total_rows = response.total
            }
            if (response.rc != 0) {
                loading.close()
                this.$message({
                    type: 'warning',
                    message: response.rd
                })
                this.tableData = []
                this.total_rows = 0
            }
        },
        async getDataRole() {
            const request_Header = {
                token: this.authToken
            }

            const response = await sendRequest(
                Api.heThong.quanLyVaiTro.getRole,
                null,
                request_Header
            )
            this.list_roleId = response
        },

        leftBarWidth() {
            if (this.isCollapseLeftBar) {
                return 'calc(100vw - 105px)'
            } else {
                return 'calc(100vw - 360px)'
            }
        }
    },

    watch: {},
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        },
        ...mapState(['isCollapseLeftBar'])
    },
    mounted() {
        this.getDataRole()
        this.handleSearch()
    }
}
</script>

<style scoped>
.layout-btn-search {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.layout-btn {
    padding: 20px 10px 5px 10px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}
.text-center {
    text-align: center;
}
.text-left {
    text-align: left;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.bg-search,
.excel,
.btn-info {
    background-color: var(--teal);
}
.btn-add,
.btn-list,
.btn-import {
    background-color: var(--light-green);
}

.btn-update {
    background-color: var(--yellow);
}
.btn-delete {
    background-color: var(--orange);
}
::v-deep .el-tag.el-tag--info,
::v-deep .el-input.is-disabled .el-input__inner {
    color: black;
}
.custom-date-picker {
    height: 32px;
}
.custom-date-picker::placeholder {
    color: gainsboro;

    font-size: 13px;
    background-color: transparent;
    appearance: none;
}
</style>
