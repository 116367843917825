<template>
    <div>
        <section>
            <div class="container">
                <div
                    class="row vh-20 align-items-center justify-content-center loginbox"
                >
                    <div class="col-sm-3">
                        <img src="../assets/img/bg-login.jpg" class="bg-img" />
                    </div>
                    <div class="col-sm-2"></div>
                    <div class="col-sm-7">
                        <div>
                            <div class="row align-items-center">
                                <div class="col-sm-2 col-xs-2">
                                    <img
                                        src="../assets/img/logo_new.png"
                                        class="logo-img"
                                    />
                                </div>
                                <div class="col-sm-8 col-xs-8 title">
                                    HỆ THỐNG CƠ SỞ <br />
                                    DỮ LIỆU NGÀNH
                                </div>
                            </div>
                            <div class="mt-3">
                                <label class="label">Tài khoản</label>
                                <el-input
                                    placeholder="Nhập ..."
                                    v-model="login.input_username"
                                    size="medium"
                                    @input="updateLoginButtonState"
                                ></el-input>
                            </div>
                            <div class="mt-4">
                                <label class="label">Mật khẩu</label>
                                <el-input
                                    show-password
                                    size="medium"
                                    placeholder="Nhập ..."
                                    v-model="login.input_password"
                                    @input="updateLoginButtonState"
                                ></el-input>
                            </div>

                            <span v-if="errorMessage" class="error-message">
                                {{ errorMessage }}
                            </span>
                            <div class="captcha" v-if="failCount >= 5">
                                <div>
                                    <label>Nhập mã Captcha</label>
                                    <el-input
                                        size="small"
                                        placeholder=""
                                        v-model="login.input_captcha"
                                    ></el-input>
                                </div>
                                <div>
                                    <img :src="base64Image" alt="" />
                                </div>
                            </div>
                            <div class="mt-5" style="text-align: center">
                                <div>
                                    <CustomButton
                                        label="Đăng nhập với tài khoản thường"
                                        :disabled="isLoginDisabled"
                                        size="small"
                                        class="btn-w100 btn-login"
                                        @click="handleLogin"
                                        type="info"
                                    />
                                </div>
                                <div>
                                    <CustomButton
                                        label="Đăng nhập SSO UBND Tỉnh"
                                        size="small"
                                        type="info"
                                        class="btn-w100 btn-sso"
                                        style="margin-top: 10px"
                                        @click="handleLoginSSOV2"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <ModalDoiMatKhau
            :doiMatKhauModalVisible="doiMatKhauModalVisible"
            :closeModalDoiMatKhau="closeModalDoiMatKhau"
            @handleLogout="handleLogout"
            :noti="noti"
        />
    </div>
</template>
<script>
import store from '@/store'
import sendRequest from '@/services'
import axios from 'axios'
import Api from '@/constants/Api'
import CustomButton from '@/components/CustomButton.vue'
import ModalDoiMatKhau from '@/components/ModalDoiMatKhau.vue'

export default {
    name: 'loGin',
    components: { CustomButton, ModalDoiMatKhau },
    data() {
        return {
            isLoginDisabled: true,
            login: {
                input_username: '',
                input_password: '',
                input_remember: '',
                input_captcha: '',
                captchaToken: ''
            },
            token: '',
            base64Image: '',
            failCount: 0,
            errorMessage: '',

            currentDomain: null,

            doiMatKhauModalVisible: false,
            noti: ''
        }
    },
    mounted() {
        let currentDomain = window.location.hostname
        this.currentDomain = currentDomain
        console.log(currentDomain)
    },
    methods: {
        openModalDoiMatKhau() {
            this.doiMatKhauModalVisible = true
        },
        closeModalDoiMatKhau() {
            this.doiMatKhauModalVisible = false
        },
        handleLogout() {
            localStorage.clear()
            this.$router.push('/login')
        },
        updateLoginButtonState() {
            this.isLoginDisabled =
                this.login.input_username.trim() === '' ||
                this.login.input_password.length < 3
        },
        async getMaCaptCha() {
            const token = {
                token: this.token
            }
            const response = await sendRequest(Api.auth.getMaCaptCha, null, token)
            this.base64Image = 'data:image/png;base64,' + response.dataImg
            this.captchaToken = response.tokenCap
        },
        async handleLogin() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const requestData = {
                    username: this.login.input_username,
                    password: this.login.input_password,

                    captcha_token: this.captchaToken
                }
                if (this.login.input_captcha) {
                    requestData.captcha = this.login.input_captcha
                }

                const response = await sendRequest(Api.auth.login, requestData)
                if (response.rc === 0) {
                    this.token = response.token
                    store.commit('SET_AUTH_USER', response)
                    store.commit('SET_AUTH_TOKEN', response.token)
                } else {
                    this.errorMessage = response.rd
                }

                if (response.needChangePassword || response.passwordOutdated) {
                    this.openModalDoiMatKhau()
                    this.noti = response.rd

                    return
                }
                console.log(response)

                if (response.rc === 0 && !this.needChangePassword) {
                    setTimeout(() => {
                        this.$router.push('/')
                    }, 1000)
                }
                if (response.rc !== 0 && response.failCount >= 5) {
                    this.errorMessage = response.rd
                    this.getMaCaptCha()
                    this.failCount = response.failCount
                }
            } catch (error) {
                console.log(error)
            } finally {
                loading.close()
            }
        },
        generateString(length) {
            let characters =
                'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
            let result = ''
            const charactersLength = characters.length
            for (let i = 0; i < length; i++) {
                result += characters.charAt(
                    Math.floor(Math.random() * charactersLength)
                )
            }
            return result
        },
        objectToQueryString(obj) {
            let str = []
            for (let p in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, p)) {
                    str.push(p + '=' + obj[p])
                }
            }
            return str.join('&')
        },
        async handleLoginSSOV2() {
            let params = {
                response_type: 'code',
                client_id: '_zL4JTpnUnxcxvkcZW_8GvYKbbwa',
                redirect_uri: 'https://csdl.dienbien.edu.vn/sso-login',
                scope: 'openid',
                state: this.generateString(5)
            }
            console.log('params', params)
            let query = this.objectToQueryString(params)
            let urlLogin =
                'https://login.dienbien.gov.vn/oauth2/authorize?' + query
            window.location.href = urlLogin
        },
        async handleLoginSSO() {
            const response = await sendRequest(Api.auth.loginSSo)
            console.log(response)

            const baseUrl = `https://csdl.dienbien.edu.vn`

            const redirect_uri = '/sso/login.html'
            const postLogoutRedirectUri = '/sso/logout.html'
            let params = {
                response_type: 'code',
                Issuer: 'https://id.nentanggiaoduc.edu.vn',
                redirect_uri: `${baseUrl}${redirect_uri}`,
                postLogoutRedirectUri: `${baseUrl}${postLogoutRedirectUri}`,
                client_id: 'csdln_client',
                state: this.generateString(5),
                scope: 'openid profile offline_access esmartup',
                code_challenge: response.code_challenge,
                code_challenge_method: 'S256'
            }
            let query = this.objectToQueryString(params)
            console.log('query', query)
            let urlLogin =
                'https://id.nentanggiaoduc.edu.vn/connect/authorize?' + query
            console.log('url', urlLogin)
            window.location.href = urlLogin
        }
    },
    isLoginDisabled() {
        return (
            this.login.input_username.trim() === '' &&
            this.login.input_password.length < 3
        )
    }
}
</script>

<style scoped>
section {
    position: relative;
    width: 100%;
    height: 100vh;
    background-position: center;
    background-size: cover;
    display: flex;
    padding: 80px 15px 0;
    align-items: center;
    background: url(https://hocba.edu.vn/images/bg2.png) center/cover no-repeat,
        lightgray -215.529px -47px/129.935% 110.444% no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center; /* Dọc */
    justify-content: center; /* Ngang */
}

.title {
    text-align: center;
    color: #ac593d;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
}
.label {
    font-weight: 600;
    font-size: 16px;
}
.loginbox {
    background-color: #fff;
    padding: 15px 30px 50px 30px;
    border-radius: 20px;
}
::v-deep .el-input__inner {
    border-radius: 8px;
    height: 46px;
    border: 1.5px solid #6aab9c;
}
.btn-w100 {
    font-weight: 700;
    border-radius: 8px;
    width: 60%;
    height: 46px;
    font-size: 15px;
}
.btn-login {
    background-color: #6aab9c;
}
.btn-sso {
    background-color: #ffa554;
}
.logo-img {
    width: 180px;
    height: 100%;
    object-fit: cover;
}
::v--deep .el-input__placeholder {
    color: red;
}
.captcha {
    display: flex;
    align-items: center;
}
.captcha img {
    height: 43px;
    width: 100%;
    margin-top: 30px;
}
.error-message {
    font-style: italic;
    color: red;
    font-weight: 500;
    font-size: 13px;
}
.vertical-hr {
    height: 100%;
    border-left: 1px solid #ccc;
    margin: 0;
}
.bg-img {
    width: 400px;
    object-fit: cover;
}
@media (max-width: 767px) {
    .btn-w100 {
        width: 100%;
    }
    .bg-img {
        display: none;
    }
    .captcha img {
        height: 20px;
    }
}
</style>
