<template>
    <div>
        <div class="row content">
            <div class="col-6">
                <label>Tiêu chí báo cáo <span class="span">*</span></label>
                <div>
                    <ESelect
                        :reset="resetESelectTieuChiBaoCao"
                        @reset-completed="handleResetTieuChiBaoCaoCompleted"
                        :key="selectedValue.selectedValue_TieuChiBaoCao?.index"
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelect_TieuChiBaoCao"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['name', 'index']"
                        v-model="selectedValue.selectedValue_TieuChiBaoCao"
                        @input="handleChange_TieuChiBaoCao"
                    />
                </div>
            </div>
            <div v-if="name !== ''" class="name col-4">
                <h4>
                    Bạn đang sử dụng mẫu báo cáo: <span>{{ name }}</span>
                </h4>
            </div>
            <div class="col-12 mt-3">
                <draggable
                    class="list-group"
                    tag="div"
                    v-model="this.selectedValue.selectedValue_TieuChiBaoCao"
                    v-bind="dragOptions"
                    @start="drag = true"
                    @end="drag = false"
                >
                    <transition-group type="transition">
                        <li
                            class="list-group-item"
                            v-for="(item, index) in this.selectedValue
                                .selectedValue_TieuChiBaoCao"
                            :key="item.value"
                        >
                            <span>{{ index + 1 }}: {{ item.title }}</span>
                        </li>
                    </transition-group>
                </draggable>
            </div>
            <div class="col-md-12 mt-3">
                <el-card class="box-card">
                    <el-collapse v-model="activeNames">
                        <el-collapse-item title="Bộ lọc dữ liệu" name="1">
                            <div class="row">
                                <div class="mt-3 col-md-12">
                                    <label>Tiêu chí</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelectAdvancedSearch
                                            "
                                            :placeholder="'Chọn nội dung'"
                                            :filterable="true"
                                            :multiple="true"
                                            :collapse-tags="true"
                                            :fields="['name', 'nameCol']"
                                            v-model="
                                                selectedValue.selectedValueAdvancedSearch
                                            "
                                            @input="handleTimKiemNangCao"
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-4 mt-3"
                                    v-if="hienThiNangCao.maDonVi"
                                >
                                    <label>Đơn vị quản lý</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="this.dataChonDonVi_Store"
                                            :placeholder="'Chọn đơn vị'"
                                            :filterable="true"
                                            :multiple="true"
                                            :collapse-tags="true"
                                            :fields="['tenDonVi', 'maDonVi']"
                                            v-model="
                                                selectedValue.selectedValue_DonVi
                                            "
                                            @input="getDataChonTruongHoc"
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-2 mt-3"
                                    v-if="hienThiNangCao.capHoc"
                                >
                                    <label>Cấp học</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="getDataESelect.ESelect_CapHoc"
                                            :placeholder="'Chọn cấp học'"
                                            :filterable="true"
                                            :multiple="true"
                                            :collapse-tags="true"
                                            :fields="['tenTruongHoc', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_CapHoc
                                            "
                                            @input="getDataChonTruongHoc"
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-4 mt-3"
                                    v-if="hienThiNangCao.maTruongHoc"
                                >
                                    <label>Trường học</label>
                                    <div>
                                        <ESelect
                                            :reset="resetESelectSchool"
                                            @reset-completed="
                                                handleResetSchoolCompleted
                                            "
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_TruongHoc
                                            "
                                            :placeholder="'Chọn trường học'"
                                            :filterable="true"
                                            :multiple="true"
                                            :collapse-tags="true"
                                            :fields="['title', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_TruongHoc
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-2 mt-3"
                                    v-if="hienThiNangCao.namHoc"
                                >
                                    <label>Năm học</label>
                                    <ESelectYear
                                        v-model="
                                            selectedValue.selectedValue_NamHoc
                                        "
                                        placeholder="Chọn năm"
                                        size="small"
                                        :no-data-text="'Không có bản ghi nào'"
                                        :no-match-text="'Không tìm thấy bản ghi nào'"
                                    ></ESelectYear>
                                </div>

                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.loaiHinhDaoTao"
                                >
                                    <label>Loại hình đào tạo</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_LoaiHinhDaoTao
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="true"
                                            :collapse-tags="true"
                                            :fields="['title', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_LoaiHinhDaoTao
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.loaiHinhTruong"
                                >
                                    <label>Loại hình trường</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelectTrueOfFalse
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_LoaiHinhTruong
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.chuanQuocGia"
                                >
                                    <label>Chuẩn quốc gia</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelectTrueOfFalse
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_ChuanQuocGia
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.maVungKhoKhan"
                                >
                                    <label>Vùng khó khăn</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelectTrueOfFalse
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_VungKhoKhan
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.chuongTrinhGiaoDucCoBan"
                                >
                                    <label>Chương trình giáo dục cơ bản</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelectTrueOfFalse
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_ChuongTrinhGiaoDucCoBan
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.coHaTangTlhtPhuHopHskt"
                                >
                                    <label
                                        >Môi trường phù hợp hs khuyết tật</label
                                    >
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelectTrueOfFalse
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_MoiTruongPhuHopHSKhuyetTat
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.coLopKhongChuyen"
                                >
                                    <label>Có lớp không chuyên</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelectTrueOfFalse
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_CoLopKhongChuyen
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.congTrinhVeSinh"
                                >
                                    <label>Công trình vệ sinh</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelectTrueOfFalse
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_CongTrinhVeSinh
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.ctGdvsDoiTay"
                                >
                                    <label>Giáo dục vệ sinh đôi tay</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelectTrueOfFalse
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_GiaoDucVeSinhDoiTay
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.datChatLuongToiThieu"
                                >
                                    <label>Đạt chất lượng tối thiểu</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelectTrueOfFalse
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_DatChatLuongToiThieu
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.dayNghePhoThong"
                                >
                                    <label>Dạy nghề phổ thông</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelectTrueOfFalse
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_DayNghePhoThong
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.dienLuoi"
                                >
                                    <label>Điện lưới</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelectTrueOfFalse
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_DienLuoi
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.hoc2BuoiNgay"
                                >
                                    <label>Học 2 buổi/ngày</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelectTrueOfFalse
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_Hoc2BuoiNgay
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.hocSinhBanTru"
                                >
                                    <label>Học sinh bán trú</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelectTrueOfFalse
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_HocSinhBanTru
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.hocSinhNoiTru"
                                >
                                    <label>Học sinh nội trú</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelectTrueOfFalse
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_HocSinhNoiTru
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.hocSinhKhuyetTat"
                                >
                                    <label>Học sinh khuyết tật</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelectTrueOfFalse
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_HocSinhKhuyetTat
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.khaiThacInternetDayHoc"
                                >
                                    <label>Khai thác Internet dạy học</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelectTrueOfFalse
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_KhaiThacInternetDayHoc
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.kyNangSongGdxh"
                                >
                                    <label>Kỹ năng sống giáo dục xã hội</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelectTrueOfFalse
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_KyNangGiaoDucXaHoi
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.truongQuocTe"
                                >
                                    <label>Trường quốc tế</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelectTrueOfFalse
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_TruongQuocTe
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.vungDacBietKhoKhan"
                                >
                                    <label>Vùng đặc biệt khó khăn</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelectTrueOfFalse
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_VungDacBietKhoKhan
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.ttGdtxHuongNghiep"
                                >
                                    <label>Trung tâm hướng nghiệp</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelectTrueOfFalse
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_TrungTamHuongNghiep
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.ttNnCoVonNuocNgoai"
                                >
                                    <label
                                        >Trung tâm ngoại ngữ có vốn nước
                                        ngoài</label
                                    >
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelectTrueOfFalse
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_TrungTamNgoaiNguVonNuocNgoai
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </el-card>
            </div>
        </div>
        <div class="btn-search">
            <CustomButton
                label="Tìm kiếm"
                size="small"
                @click="handleSearch"
                class="btn-default bg-search"
            />
        </div>
        <el-card class="box-card mt-4">
            <div class="layout-btn-card">
                <div>
                    <chonSoLuongBanGhi
                        @chonXongSoLuong="ChonSoLuongBanGhi($event)"
                    />
                </div>
                <div>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="Xuất Excel"
                        placement="top-start"
                    >
                        <CustomButton
                            style="
                                background-color: #6aab9c;
                                color: #fff;
                                font-weight: 600;
                            "
                            size="small"
                            label="Tải xuống"
                            @click="handleTaiXuong"
                        />
                    </el-tooltip>

                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="Lưu lại nội dung hiển thị và bộ lọc dữ liệu"
                        placement="top-start"
                    >
                        <CustomButton
                            size="small"
                            label="Lưu Mẫu"
                            style="
                                background-color: #ffa554;
                                color: #fff;
                                font-weight: 600;
                            "
                            @click="openModalLuuMau"
                        />
                    </el-tooltip>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="Sử dụng nội dung hiển thị và bộ lọc dữ liệu có sẵn"
                        placement="top-start"
                    >
                        <CustomButton
                            style="
                                background-color: #1e6050;
                                color: #fff;
                                font-weight: 600;
                            "
                            size="small"
                            label="Tải mẫu"
                            @click="openModalTaiMau"
                        />
                    </el-tooltip>
                </div>
            </div>
            <CustomTable :maxWidth="leftBarWidth()" :columns="tableHeader">
                <template v-if="tableRows.length === 0">
                    <tr>
                        <td colspan="36" class="text-tbody">
                            Không có bản ghi nào.
                        </td>
                    </tr>
                </template>
                <tr v-for="(row, rowIndex) in tableRows" :key="rowIndex">
                    <td
                        class="text-tbody text-left"
                        v-for="(col, colIndex) in row.col"
                        :key="colIndex"
                    >
                        {{ col.value }}
                    </td>
                </tr>
            </CustomTable>
            <CustomPagination
                v-show="total_rows > 0"
                :tongbanghi="total_rows"
                :soluonghienthi="soLuongBanGhiHienThi"
                :batdau="trangbatdau"
                @pageChange="layLai($event)"
            >
            </CustomPagination>
        </el-card>
        <el-dialog
            :visible="luuMauModalVisible"
            @close="closeModalLuuMau"
            width="50%"
        >
            <span slot="title">
                Lưu mẫu
                <hr />
            </span>
            <div class="dialog">
                <label>Tên mẫu:<span class="span">*</span></label>
                <div class="mt-1">
                    <el-input
                        placeholder="Nhập tên mẫu"
                        v-model="selectedValue.input_luuMau"
                        size="small"
                    ></el-input>
                </div>
            </div>
            <div class="layout-btn">
                <CustomButton
                    label="Đóng"
                    size="small"
                    @click="closeModalLuuMau"
                    style="
                        background-color: #e14a02;
                        color: #fff;
                        font-weight: 600;
                    "
                />
                <CustomButton
                    label="Lưu lại"
                    size="small"
                    style="
                        background-color: #ffa554;
                        color: #fff;
                        font-weight: 600;
                    "
                    @click="handleLuuMau"
                />
            </div>
        </el-dialog>
        <el-dialog
            :visible="taiMauModalVisible"
            @close="closeModalTaiMau"
            width="80%"
        >
            <span slot="title">
                Mẫu có sẵn
                <hr />
            </span>
            <table class="table table-bordered table-hover centered-table">
                <thead>
                    <tr>
                        <th class="text-thead">STT</th>
                        <th class="text-thead">Tên mẫu</th>
                        <th class="text-thead">Mã</th>
                        <th class="text-thead">Thời gian tạo</th>
                        <th class="text-thead">Thao tác</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="tableDataTaiMau.length === 0">
                        <tr>
                            <td class="text-tbody text-center" colspan="9">
                                Không có bản ghi nào.
                            </td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in tableDataTaiMau" :key="index">
                        <td class="text-tbody text-center">{{ index + 1 }}</td>
                        <td class="text-tbody">{{ item.ten }}</td>
                        <td class="text-tbody text-center">{{ item.id }}</td>
                        <td class="text-tbody text-center">
                            {{ item.timeCreate }}
                        </td>
                        <td class="text-tbody text-center">
                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="Áp dụng"
                                placement="top-start"
                            >
                                <CustomButton
                                    label=""
                                    icon="el-icon-check"
                                    size="small"
                                    class="icon"
                                    type="primary"
                                    @click="handleApDung(item)"
                                />
                            </el-tooltip>
                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="Xóa"
                                placement="top-start"
                            >
                                <CustomButton
                                    type="danger"
                                    label=""
                                    icon="el-icon-delete"
                                    size="small"
                                    class="icon"
                                    @click="handleDeleteTaiMau(item.id)"
                                />
                            </el-tooltip>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="layout-btn">
                <CustomButton
                    label="Đóng"
                    size="small"
                    @click="closeModalTaiMau"
                    type="info"
                />
                <CustomButton label="Import" size="small" type="primary" />
            </div>
        </el-dialog>
    </div>
</template>
<script>
import ESelect from '@/components/ESelect.vue'
import draggable from 'vuedraggable'
import CustomButton from '@/components/CustomButton.vue'
import ESelectYear from '@/components/ESelectYear.vue'
import chonSoLuongBanGhi from '@/components/chonSoLuongBanGhi.vue'
import CustomPagination from '@/components/CustomPagination.vue'

import {
    ESelectGradeLevel_MockData,
    ESelectReportingCriteria_TruongHoc_TieuChi,
    ESelectTrueOfFalse,
    ESelectReportingCriteria_TruongHoc_TieuChiBaoCao,
    ESelectTypeOfEducation_MockData
} from '@/mock_data'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { mapState } from 'vuex'
import axios from 'axios'
import { currentYear } from '@/utils'
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'BaoCaoCanBo',
    props: {
        ESelect_DoiTac: {
            type: Array
        }
    },
    components: {
        ESelect,
        CustomButton,
        draggable,
        ESelectYear,
        CustomPagination,
        chonSoLuongBanGhi,
        CustomTable
    },
    data() {
        return {
            activeNames: ['1'],
            activeTab: 0,

            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            soLuongBanGhiHienThi: 25,
            trangbatdau: false,
            luuMauModalVisible: false,
            taiMauModalVisible: false,
            resetESelectSchool: false,
            resetESelectTieuChiBaoCao: false,
            hienThiNangCao: {
                maDonVi: false,
                capHoc: false,
                maTruongHoc: false,
                namHoc: false,
                loaiHinhDaoTao: false,
                loaiHinhTruong: false,
                chuanQuocGia: false,
                maVungKhoKhan: false,
                chuongTrinhGiaoDucCoBan: false,
                coHaTangTlhtPhuHopHskt: false,
                coLopKhongChuyen: false,
                congTrinhVeSinh: false,
                ctGdvsDoiTay: false,
                datChatLuongToiThieu: false,
                dayNghePhoThong: false,
                dienLuoi: false,
                hoc2BuoiNgay: false,
                hocSinhBanTru: false,
                hocSinhNoiTru: false,
                hocSinhKhuyetTat: false,
                khaiThacInternetDayHoc: false,
                kyNangSongGdxh: false,
                truongQuocTe: false,
                vungDacBietKhoKhan: false,
                ttGdtxHuongNghiep: false,
                ttNnCoVonNuocNgoai: false
            },
            name: '',
            tableRows: [],
            tableHeader: [],
            tableDataTaiMau: [],
            getDataESelect: {
                ESelectAdvancedSearch: ESelectReportingCriteria_TruongHoc_TieuChi,

                ESelect_TieuChiBaoCao:
                    ESelectReportingCriteria_TruongHoc_TieuChiBaoCao,

                ESelect_DonVi: [],
                ESelect_CapHoc: ESelectGradeLevel_MockData,

                ESelect_TruongHoc: [],

                ESelect_NamHoc: [],
                ESelect_LoaiHinhDaoTao: ESelectTypeOfEducation_MockData,
                ESelect_LoaiHinhTruong: [],
                ESelect_ChuanQuocGia: [],
                ESelect_VungKhoKhan: [],
                ESelectTrueOfFalse: ESelectTrueOfFalse
            },

            selectedValue: {
                selectedValueAdvancedSearch: [],
                selectedValue_TieuChiBaoCao: [],
                selectedValue_DonVi: [],
                selectedValue_CapHoc: [],
                selectedValue_TruongHoc: [],
                selectedValue_NamHoc: [],
                selectedValue_LoaiHinhDaoTao: [],

                selectedValue_LoaiHinhTruong: [],
                selectedValue_ChuanQuocGia: [],
                selectedValue_VungKhoKhan: [],
                selectedValue_ChuongTrinhGiaoDucCoBan: [],
                selectedValue_MoiTruongPhuHopHSKhuyetTat: [],
                selectedValue_CoLopKhongChuyen: [],
                selectedValue_CongTrinhVeSinh: [],
                selectedValue_GiaoDucVeSinhDoiTay: [],
                selectedValue_DatChatLuongToiThieu: [],
                selectedValue_DayNghePhoThong: [],
                selectedValue_DienLuoi: [],
                selectedValue_Hoc2BuoiNgay: [],
                selectedValue_HocSinhBanTru: [],
                selectedValue_HocSinhNoiTru: [],
                selectedValue_HocSinhKhuyetTat: [],
                selectedValue_KhaiThacInternetDayHoc: [],
                selectedValue_KyNangGiaoDucXaHoi: [],
                selectedValue_TruongQuocTe: [],
                selectedValue_VungDacBietKhoKhan: [],
                selectedValue_TrungTamHuongNghiep: [],
                selectedValue_TrungTamNgoaiNguVonNuocNgoai: [],

                input_luuMau: ''
            }
        }
    },
    methods: {
        openModalLuuMau() {
            this.luuMauModalVisible = true
        },
        closeModalLuuMau() {
            this.luuMauModalVisible = false
        },
        openModalTaiMau() {
            this.taiMauModalVisible = true
            this.getDataTableTaiMau()
        },
        closeModalTaiMau() {
            this.taiMauModalVisible = false
        },
        ChonSoLuongBanGhi(e) {
            this.total_rows = 0
            this.soLuongBanGhiHienThi = e.soluong
        },
        checkTruocKhiTim() {
            this.trangbatdau = !this.trangbatdau
        },
        layLai(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        handleResetSchoolCompleted() {
            this.resetESelectSchool = false
        },
        handleResetTieuChiBaoCaoCompleted() {
            this.resetESelectTieuChiBaoCao = false
        },
        handleTimKiemNangCao(value) {
            const selectedValues = value.map((item) => item.value)

            for (const key in this.hienThiNangCao) {
                this.hienThiNangCao[key] = selectedValues.includes(key)
            }
        },
        processAdvancedSearch(selectedValue) {
            const timkiemnangcao = this.convertToValueArray(
                selectedValue.selectedValueAdvancedSearch
            )

            const keyMappings_dataSreachList = {
                maDonVi: 'selectedValue_DonVi',
                capHoc: 'selectedValue_CapHoc',
                maTruongHoc: 'selectedValue_TruongHoc',

                loaiHinhDaoTao: 'selectedValue_LoaiHinhDaoTao',
                loaiHinhTruong: 'selectedValue_LoaiHinhTruong',
                chuanQuocGia: 'selectedValue_ChuanQuocGia',
                maVungKhoKhan: 'selectedValue_VungKhoKhan',
                chuongTrinhGiaoDucCoBan: 'selectedValue_ChuongTrinhGiaoDucCoBan',
                coHaTangTlhtPhuHopHskt:
                    'selectedValue_MoiTruongPhuHopHSKhuyetTat',
                coLopKhongChuyen: 'selectedValue_CoLopKhongChuyen',
                congTrinhVeSinh: 'selectedValue_CongTrinhVeSinh',
                ctGdvsDoiTay: 'selectedValue_GiaoDucVeSinhDoiTay',
                datChatLuongToiThieu: 'selectedValue_DatChatLuongToiThieu',
                dayNghePhoThong: 'selectedValue_DayNghePhoThong',
                dienLuoi: 'selectedValue_DienLuoi',
                hoc2BuoiNgay: 'selectedValue_Hoc2BuoiNgay',
                hocSinhBanTru: 'selectedValue_HocSinhBanTru',
                hocSinhNoiTru: 'selectedValue_HocSinhNoiTru ',
                hocSinhKhuyetTat: 'selectedValue_HocSinhKhuyetTat',
                khaiThacInternetDayHoc: 'selectedValue_KhaiThacInternetDayHoc ',
                kyNangSongGdxh: 'selectedValue_KyNangGiaoDucXaHoi',
                truongQuocTe: 'selectedValue_TruongQuocTe ',
                vungDacBietKhoKhan: 'selectedValue_VungDacBietKhoKhan',
                ttGdtxHuongNghiep: 'selectedValue_TrungTamHuongNghiep',
                ttNnCoVonNuocNgoai: 'selectedValue_TrungTamNgoaiNguVonNuocNgoai'
            }

            const dataSearchList = timkiemnangcao
                .map((key) => {
                    const valueKey = keyMappings_dataSreachList[key]
                    const inputValue = selectedValue[valueKey]

                    if (valueKey && inputValue) {
                        let value

                        if (Array.isArray(inputValue)) {
                            value = inputValue.map((item) => item.value)
                            return {
                                nameSreach: key,
                                values: value
                            }
                        } else if (typeof inputValue === 'object') {
                            value = inputValue.value
                        } else {
                            value = inputValue
                        }

                        return {
                            nameSreach: key,
                            value: value
                        }
                    }

                    return null
                })
                .filter((item) => item !== null)

            const result = dataSearchList
                .map((item) => {
                    const foundCriteria =
                        ESelectReportingCriteria_TruongHoc_TieuChi.find(
                            (criteria) => criteria.nameCol === item.nameSreach
                        )

                    return foundCriteria
                        ? { name: foundCriteria.name, ...item }
                        : null
                })
                .filter((item) => item !== null)

            return result
        },
        async getDataChonTruongHoc() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const maDonVi = this.convertToValueArray(
                this.selectedValue.selectedValue_DonVi
            )

            const capHoc = this.convertToValueArray(
                this.selectedValue.selectedValue_CapHoc
            )

            const request_Header = {
                token: this.authToken
            }

            const request_Data = {
                capHoc: capHoc,
                maDonVi: maDonVi
            }

            let response = await sendRequest(
                Api.internal_api.dm_School,
                request_Data,
                request_Header,
                null
            )

            if (response.statusResponse == 0) {
                loading.close()
                this.getDataESelect.ESelect_TruongHoc = response.rows.map(
                    (item) => ({
                        title: `${item.tenTruongHoc} - [${item.maTruongHoc}]`,
                        value: item.maTruongHoc
                    })
                )
                loading.close()
            } else {
                loading.close()
            }
        },
        async getDataTableTaiMau() {
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Data = {
                    name: '',
                    type: 3
                }
                const response = await sendRequest(
                    Api.baoCao.baoCaoDong.baoCaoCanBo.taiMau,
                    request_Data,
                    request_Header
                )

                this.tableDataTaiMau = response.rows.slice(0, 10)
            } catch (error) {
                console.log(error)
            }
        },
        async handleApDung(item) {
            const request = JSON.parse(item.request)

            const colList = request.colList.map((item) => ({
                title: item.name,
                value: item.index
            }))

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            this.$confirm('Xác nhận áp dụng mẫu đã chọn?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    this.selectedValue.selectedValue_TieuChiBaoCao = [...colList]

                    this.name = request.name
                    loading.close()
                    this.$nextTick(() => {
                        this.closeModalTaiMau()
                        this.$message({
                            type: 'success',
                            message: 'Áp dụng thành công'
                        })
                    })
                })
                .catch(() => {
                    loading.close()
                    this.$message({
                        type: 'info',
                        message: 'Hủy'
                    })
                })
        },
        handleDeleteTaiMau(id) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            this.$confirm('Xác nhận xóa?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const request_Header = {
                        token: this.authToken
                    }
                    const request_Data = {}

                    const response = await sendRequest(
                        Api.baoCao.baoCaoDong.baoCaoTruongHoc.xoaMau(id),
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        loading.close()
                        this.$message({
                            type: 'success',
                            message: 'Xóa thành công'
                        })
                        this.getDataTableTaiMau()
                    } else {
                        loading.close()
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Hủy'
                    })
                })
        },
        async handleSearch() {
            const loading = this.$loading({
                lock: true,
                text: 'Đang tổng hợp',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const selectedValue = this.selectedValue
            let requestList = this.selectedValue.selectedValue_NamHoc

            if (Array.isArray(requestList) && requestList.length === 0) {
                requestList = currentYear
            }

            const convertRequestList = [
                {
                    name: 'Năm học',
                    nameSreach: 'namHoc',
                    value: Number(requestList)
                }
            ]

            const requestLists = this.processAdvancedSearch(selectedValue)

            const colList = this.selectedValue.selectedValue_TieuChiBaoCao

            const converColList = colList
                .map((itemNew) => {
                    const matchingItem =
                        ESelectReportingCriteria_TruongHoc_TieuChiBaoCao.find(
                            (itemOld) => itemOld.name === itemNew.title
                        )

                    return matchingItem || null
                })
                .filter(Boolean)

            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Data = {
                    start: this.start,
                    limit: this.limit,
                    requestList: convertRequestList,
                    requestLists: requestLists,
                    colList: converColList
                }
                const response = await sendRequest(
                    Api.baoCao.baoCaoDong.baoCaoTruongHoc.danhSach,
                    request_Data,
                    request_Header
                )
                this.tableRows = response.tableRows
                this.tableHeader = response.tableHeaders.map((item) => ({
                    ...item,
                    key: item.index,
                    label: item.value
                }))
                this.total_rows = response.total
                loading.close()
            } catch (error) {
                loading.close()
            }
        },
        async handleTaiXuong() {
            const selectedValue = this.selectedValue
            let requestList = this.selectedValue.selectedValue_NamHoc

            if (Array.isArray(requestList) && requestList.length === 0) {
                requestList = currentYear
            }

            const convertRequestList = [
                {
                    name: 'Năm học',
                    nameSreach: 'namHoc',
                    value: Number(requestList)
                }
            ]

            const requestLists = this.processAdvancedSearch(selectedValue)

            const colList = this.selectedValue.selectedValue_TieuChiBaoCao

            const converColList = colList
                .map((itemNew) => {
                    const matchingItem =
                        ESelectReportingCriteria_TruongHoc_TieuChiBaoCao.find(
                            (itemOld) => itemOld.name === itemNew.title
                        )

                    return matchingItem || null
                })
                .filter(Boolean)

            this.$confirm('Xác nhận tải xuống?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const domain = process.env.VUE_APP_BACKEND_BASE_URL
                    const request_Data = {
                        start: this.start,
                        limit: this.limit,
                        requestList: convertRequestList,
                        requestLists: requestLists,
                        colList: converColList
                    }
                    const response = await axios.post(
                        `${domain}/csdlgd-report-fetch/BaoCaoDongExcel/exportTruongHoc`,
                        request_Data,
                        {
                            headers: {
                                token: this.authToken,
                                'Content-Type': 'application/json'
                            },
                            responseType: 'blob'
                        }
                    )

                    const blob = new Blob([response.data])
                    const blobUrl = window.URL.createObjectURL(blob)

                    const downloadLink = document.createElement('a')
                    downloadLink.href = blobUrl
                    downloadLink.download = 'BaoCaoTruongHoc.xlsx'
                    downloadLink.style.display = 'none'

                    document.body.appendChild(downloadLink)
                    downloadLink.click()

                    window.URL.revokeObjectURL(blobUrl)
                })
                .catch(() => {
                    this.$message({
                        type: 'Hủy',
                        message: 'Delete canceled'
                    })
                })
        },
        async handleLuuMau() {
            if (this.selectedValue.input_luuMau === '') {
                this.$message({
                    type: 'error',
                    message: 'Vui lòng bổ sung tên mẫu'
                })
                return
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const selectedValue = this.selectedValue
            let requestList = this.selectedValue.selectedValue_NamHoc

            if (Array.isArray(requestList) && requestList.length === 0) {
                requestList = currentYear
            }

            const convertRequestList = [
                {
                    name: 'Năm học',
                    nameSreach: 'namHoc',
                    value: Number(requestList)
                }
            ]

            const requestLists = this.processAdvancedSearch(selectedValue)

            const colList = this.selectedValue.selectedValue_TieuChiBaoCao

            const converColList = colList
                .map((itemNew) => {
                    const matchingItem =
                        ESelectReportingCriteria_TruongHoc_TieuChiBaoCao.find(
                            (itemOld) => itemOld.name === itemNew.title
                        )

                    return matchingItem || null
                })
                .filter(Boolean)

            const selectedConditions =
                this.selectedValue.selectedValueAdvancedSearch
            const hienThiBoLoc = {}
            ESelectReportingCriteria_TruongHoc_TieuChi.forEach(
                (item) => (hienThiBoLoc[item.nameCol] = false)
            )

            for (const condition of selectedConditions) {
                const matchingItem =
                    ESelectReportingCriteria_TruongHoc_TieuChi.find(
                        (item) => item.nameCol === condition.value
                    )

                if (matchingItem && matchingItem.status) {
                    hienThiBoLoc[matchingItem.nameCol] = true
                }
            }
            const isAllFalse = Object.values(hienThiBoLoc).every(
                (value) => value === false
            )
            const convertHienThiBoLoc = isAllFalse ? {} : hienThiBoLoc

            this.$confirm('Xác nhận lưu mẫu?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const request_Header = {
                        token: this.authToken
                    }
                    const request_Data = {
                        start: this.start,
                        limit: this.limit,
                        requestList: convertRequestList,
                        requestLists: requestLists,
                        colList: converColList,
                        name: this.selectedValue.input_luuMau,
                        type: 3,
                        hienThiBoLoc: convertHienThiBoLoc
                    }
                    const response = await sendRequest(
                        Api.baoCao.baoCaoDong.baoCaoTruongHoc.luuMau,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        loading.close()
                        this.closeModalLuuMau()
                        this.handleSearch()
                        this.$message({
                            type: 'success',
                            message: 'Mẫu đã được lưu lại'
                        })
                    } else {
                        loading.close()
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Hủy'
                    })
                })
        },
        handleChange_TieuChiBaoCao() {
            this.name = ''
        },
        convertToValueArray(array) {
            return array.map((item) => item.value)
        },
        leftBarWidth() {
            if (this.isCollapseLeftBar) {
                return 'calc(100vw - 85px)'
            } else {
                return 'calc(100vw - 450px)'
            }
        }
    },

    created() {
        this.selectedValue.selectedValue_TieuChiBaoCao = [
            { title: 'Năm học', value: 3 }
        ]
    },
    computed: {
        ...mapState(['isCollapseLeftBar']),
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        },
        dataChonTrangThai_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrangthaicanbo'))
        },

        dataChonLoaiHopDong_Store() {
            return JSON.parse(localStorage.getItem('data_ChonHinhthuchopdong'))
        },
        dataChonTrinhDoChuyenMonNghiepVu_Store() {
            return JSON.parse(
                localStorage.getItem('data_ChonTrinhdochuyenmonnghiepvu')
            )
        },
        dataChonKhuvuc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonKhuvuc'))
        },
        dataChonDanhgianghenghiep_Store() {
            return JSON.parse(localStorage.getItem('data_ChonChuannghenghiep'))
        },
        dataChonMondayGV_Store() {
            return JSON.parse(localStorage.getItem('data_ChonMondaygiaovien'))
        },
        dataChonDantoc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDantoc'))
        },
        dataChonTrinhdodaotao_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrinhdodaotao'))
        },
        dataChonHinhthucdaotao_Store() {
            return JSON.parse(localStorage.getItem('data_ChonHinhthucdaotao'))
        },
        dataChonChuyenNganhdaotao_Store() {
            return JSON.parse(localStorage.getItem('data_ChonNganhDaoTao'))
        },
        dataChonTrinhDoVanHoa_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrinhDoVanHoa'))
        },
        dataChonTrinhDoTinHoc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrinhDoTinHoc'))
        },
        dataChonTrinhDoNgoaingu_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrinhDoNgoaiNgu'))
        },
        dataChonTrinhDoLLCT_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrinhDoLLCT'))
        },
        dataChonTrinhDoQLNN_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrinhDoQLNN'))
        },
        dataChonTrinhDoQLGD_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrinhDoQLGD'))
        },
        dataChonMaNganh_Store() {
            return JSON.parse(localStorage.getItem('data_ChonNganhCC'))
        },
        dataChonMonDay_Store() {
            return JSON.parse(localStorage.getItem('data_ChonMondaygiaovien'))
        },
        dataChonNhiemVuKiemNghiem_Store() {
            return JSON.parse(localStorage.getItem('data_ChonNhiemvukiemnghiem'))
        },
        dataChonNgoainguChinh_Store() {
            return JSON.parse(localStorage.getItem('data_ChonNgoaingu'))
        },
        dataChonChuyenNganhDaoTaoChinh_Store() {
            return JSON.parse(localStorage.getItem('data_ChonChuyenMon'))
        },
        dataChonDGVienChuc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDGVienChuc'))
        },
        dataChonThanhphangiadinh_Store() {
            return JSON.parse(localStorage.getItem('data_ChonThanhphangiadinh'))
        },
        dragOptions() {
            return {
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost'
            }
        }
    },
    watch: {
        'selectedValue.selectedValue_DonVi': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        },
        'selectedValue.selectedValue_CapHoc': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        },
        'selectedValue.selectedValueSchoolBlock': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        }
    },
    mounted() {
        this.getDataChonTruongHoc()
    }
}
</script>
<style scoped>
.content label {
    color: black;
    font-weight: 700;
}
.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
.list-group {
    min-height: 20px;
    padding: 5px;
    border: 1px solid gainsboro;
}

.list-group-item {
    cursor: move;
    display: inline-flex !important;
    padding: 4px 10px;
}
.list-group-item span {
    font-size: 13px;
    font-weight: 500;
}
.list-group-item i {
    cursor: pointer;
}
::v-deep .el-collapse-item__header,
.name h4 {
    color: blue !important;
    font-style: italic;
    font-size: 13px;
    font-weight: 700;
    cursor: pointer;
}
.name {
    margin-top: 35px;
}
.name span {
    color: red;
}
.btn-search {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.layout-btn-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.icon {
    font-size: 14px;
}
.dialog .span {
    color: red;
}
.dialog label {
    color: #333;
    font-size: 14px;
    font-weight: 600;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.bg-search {
    background-color: var(--teal);
}
</style>
