<template>
    <div>
        <CustomBreadcrumb :title="'Tra cứu'" content="Thông tin y tế học sinh" />
        <CustomContentUI>
            <div class="row">
                <div class="col-4">
                    <label>Đơn vị quản lý</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="
                                this.authUser.role === 5 ||
                                this.authUser.role === 4
                            "
                            :data="this.dataChonDonVi_Store"
                            :placeholder="'Chọn đơn vị quản lý'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenDonVi', 'maDonVi']"
                            v-model="selectedValue.selectedValueUnitEducation"
                            @change="getDataChonTruongHoc"
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Cấp học</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectGradeLevel"
                            :placeholder="'Chọn cấp học'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenTruongHoc', 'value']"
                            v-model="selectedValue.selectedValueGradeLevel"
                            @change="getDataChonTruongHoc"
                        />
                    </div>
                </div>
                <div class="col-4">
                    <label>Trường học<span class="span">*</span></label>
                    <div>
                        <ESelect
                            :reset="resetESelectSchool"
                            @reset-completed="handleResetSchoolCompleted"
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="this.authUser.role === 5"
                            :data="getDataESelect.ESelectSchool"
                            :placeholder="'Chọn trường học'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['tenTruongHoc', 'maTruongHoc']"
                            v-model="selectedValue.selectedValueSchool"
                            @change="handleDataChonLopHoc"
                        />
                        <div v-if="err_maTruongHoc.length > 0" class="error">
                            {{ err_maTruongHoc }}
                        </div>
                    </div>
                </div>
                <div class="col-2">
                    <label>Khối học</label>
                    <div>
                        <ESelect
                            :reset="resetESelectSchoolBlock"
                            @reset-completed="handleResetSchoolBlockCompleted"
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectSchoolBlock"
                            :placeholder="'Chọn khối học'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['title', 'value']"
                            v-model="selectedValue.selectedValueSchoolBlock"
                            @change="handleDataChonLopHoc"
                        />
                    </div>
                </div>
                <div class="col-2 mt-2">
                    <label>Lớp học</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectClass"
                            :placeholder="'Chọn lớp học'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['title', 'value']"
                            v-model="selectedValue.selectedValueClass"
                            @change="handleDataChonLopHoc"
                        />
                    </div>
                </div>
                <div class="col-2 mt-2">
                    <label>Năm học<span class="span">*</span></label>
                    <ESelectYear
                        v-model="selectedValue.selectedValueYear"
                        placeholder="Chọn năm"
                        size="small"
                        :no-data-text="'Không có bản ghi nào'"
                        :no-match-text="'Không tìm thấy bản ghi nào'"
                        @change="handleDataChonLopHoc"
                    ></ESelectYear>
                </div>
            </div>
            <div class="layout-btn-download">
                <CustomButton
                    label="Lấy dữ liệu"
                    class="btn-default btn-get"
                    size="small"
                    @click="handleSearch"
                />
            </div>
        </CustomContentUI>

        <el-dialog
            :visible.sync="fileModalVisible"
            width="80%"
            title="Danh sách File"
            @close="closeModalFile"
            class="custom-dialog"
        >
            <el-table :data="tableData" border style="width: 100%" height="500">
                <el-table-column label="STT" width="50">
                    <template slot-scope="scope">
                        {{ scope.$index + 1 }}
                    </template>
                </el-table-column>
                <el-table-column label="Tên lớp" width="250">
                    <template slot-scope="scope">
                        Lớp {{ scope.row.tilte }}
                    </template>
                </el-table-column>
                <el-table-column label="Địa chỉ File">
                    <template slot-scope="scope">
                        {{ baseUrl }}{{ scope.row.url }}
                    </template>
                </el-table-column>
                <el-table-column label="Thao tác" width="200">
                    <template slot-scope="scope">
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Xem trước File"
                            placement="left-start"
                        >
                            <CustomButton
                                @click="handleOpenFile(scope.row.url)"
                                label=""
                                size="small"
                                icon="el-icon-view"
                                class="btn-default btn-view"
                            />
                        </el-tooltip>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Tải xuống"
                            placement="top"
                        >
                            <CustomButton
                                @click="handleDownLoad(scope.row.url)"
                                label=""
                                size="small"
                                icon="el-icon-download"
                                class="btn-default btn-download"
                            />
                        </el-tooltip>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Copy File"
                            placement="right-start"
                        >
                            <CustomButton
                                @click="handleCopyFile(scope.row.url)"
                                label=""
                                size="small"
                                icon=" el-icon-copy-document"
                                class="btn-default btn-update"
                            />
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer">
                <CustomButton
                    label="Đóng"
                    size="small"
                    class="btn-default btn-delete"
                    @click="closeModalFile"
                />
                <CustomButton
                    label="Tải xuống toàn bộ"
                    size="small"
                    class="btn-default btn-downall"
                    @click="handleDownLoadAllFile"
                />
            </span>
        </el-dialog>

        <ViewExcel
            :titleViewExcel="'File đã nộp'"
            :fileViewExcel="fileViewExcel"
            :closeModalViewExcel="closeModalViewExcel"
            :viewExcelModalVisible="viewExcelModalVisible"
        />
    </div>
</template>
<script>
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelectYear from '@/components/ESelectYear.vue'
import ESelect from '@/components/ESelect.vue'
import { mapState } from 'vuex'
import { ESelectSchoolBlock_MockData } from '@/mock_data'
import Api from '@/constants/Api'
import sendRequest from '@/services'
import {
    convertCapHocList,
    filterCapHocList,
    convertBase64ToFile,
    currentYear
} from '@/utils'
import { validateField } from '@/utils'
import ViewExcel from '@/components/ViewExcel.vue'
import CustomContentUI from '@/components/CustomContentUI.vue'
export default {
    name: 'ThongTinYTe',
    components: {
        ESelect,
        CustomButton,
        CustomBreadcrumb,
        ESelectYear,
        ViewExcel,
        CustomContentUI
    },
    data() {
        return {
            fileViewExcel: null,
            viewExcelModalVisible: false,
            baseUrl: process.env.VUE_APP_BACKEND_BASE_URL_DOWNLOAD_REPORT,
            fileModalVisible: false,
            resetESelectSchool: false,
            resetESelectSchoolBlock: false,
            tableData: [],
            getDataESelect: {
                ESelectUnitEducation: [], //donvi
                ESelectGradeLevel: [], //caphoc
                ESelectSchool: [], //truonghoc
                ESelectSchoolBlock: ESelectSchoolBlock_MockData, //khoihoc
                ESelectClass: [] //lophoc
            },
            selectedValue: {
                selectedValueUnitEducation: [], //donvi
                selectedValueGradeLevel: [], //caphoc
                selectedValueSchool: [], //truonghoc,
                selectedValueYear: '', //namHoc
                selectedValueSchoolBlock: [], //khoihoc
                selectedValueClass: [] //lophoc
            },
            err_maTruongHoc: ''
        }
    },
    methods: {
        closeModalViewExcel() {
            this.viewExcelModalVisible = false
        },
        handleDownLoad(url) {
            const linkDowndLoad = `https://dongbo.csdl.edu.vn${url}`
            window.open(linkDowndLoad, '_blank')
        },
        async handleDownLoadAllFile() {
            for (const item of this.tableData) {
                const url = item.url
                const linkDownload = `${this.baseUrl}https://dongbo.csdl.edu.vn${url}`

                const link = document.createElement('a')
                link.href = linkDownload

                link.download = `ten-file.xlsx`
                link.target = '_blank'

                link.click()

                await this.sleep(500)

                console.log(`Tải xuống thành công: ${linkDownload}`)
            }
        },
        sleep(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms))
        },

        // Your existing handleDownLoad and other methods here

        async handleOpenFile(url) {
            try {
                const request_Header = {
                    ...this.request_Header,
                    token: this.authToken
                }
                const request_Params = {
                    path: url
                }
                const response = await sendRequest(
                    Api.baoCao.convertBase64,
                    null,
                    request_Header,
                    request_Params
                )
                const base64 = response.base64Content
                this.viewExcelModalVisible = true

                convertBase64ToFile(base64)
                    .then((file) => {
                        this.fileViewExcel = file
                    })
                    .catch((error) => {
                        // Xử lý lỗi nếu có
                        console.error('Error converting file:', error)
                    })
            } catch (error) {
                console.log(error)
            }
        },
        handleCopyFile(url) {
            // Tạo một thẻ textarea ẩn để chứa đường dẫn file
            const textarea = document.createElement('textarea')
            textarea.value = this.baseUrl + url
            document.body.appendChild(textarea)

            // Chọn nội dung trong thẻ textarea
            textarea.select()

            try {
                // Sao chép nội dung đã chọn vào clipboard
                document.execCommand('copy')
                this.$message.success(
                    'Đã sao chép thành công: ' + this.baseUrl + url
                )
            } catch (err) {
                this.$message.error('Không thể sao chép đường dẫn')
            }

            // Xóa thẻ textarea khỏi DOM vì không cần thiết nữa
            document.body.removeChild(textarea)
        },
        closeModalFile() {
            this.fileModalVisible = false
        },
        async handleDataChonLopHoc() {
            const convertData_Caphoc = this.convertToValueArray(
                this.selectedValue.selectedValueGradeLevel
            ).map((str) => +str)

            const selectedValueSchoolValue =
                this.selectedValue.selectedValueSchool.value

            // Kiểm tra selectedValueSchoolValue có giá trị hay không
            const convertData_KhoiHoc =
                selectedValueSchoolValue !== undefined
                    ? [selectedValueSchoolValue]
                    : []

            const convertData_TruongHoc = this.convertToValueArray(
                this.selectedValue.selectedValueSchoolBlock
            )

            const convertData_Namhoc = this.selectedValue.selectedValueYear
            if (
                convertData_Caphoc.length > 0 ||
                convertData_TruongHoc.length > 0 ||
                convertData_KhoiHoc.length > 0 ||
                convertData_Namhoc !== ''
            ) {
                await this.getDataChonLopHoc(
                    convertData_Caphoc,
                    convertData_KhoiHoc,
                    convertData_TruongHoc,
                    convertData_Namhoc
                )
            }
        },
        handleResetSchoolCompleted() {
            this.resetESelectSchool = false
        },
        handleResetSchoolBlockCompleted() {
            this.resetESelectSchoolBlock = false
        },
        async handleSearch() {
            const maTruongHoc = this.selectedValue.selectedValueSchool.value
            const fieldsToValidate = [
                {
                    name: 'err_maTruongHoc',
                    value: maTruongHoc,
                    errorField: 'err_maTruongHoc',
                    errorMessage: 'Vui lòng chọn trường học'
                }
            ]
            let isValid = true

            for (const field of fieldsToValidate) {
                isValid = validateField(this, field, isValid)
            }
            if (isValid) {
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })
                const khoiHocs = this.convertToValueArray(
                    this.selectedValue.selectedValueSchoolBlock
                )

                const maLopHocs = this.convertToValueArray(
                    this.selectedValue.selectedValueClass
                )
                const { maNhomtuoiMn, capHocs } =
                    this.processSelectedValueSchoolBlock(
                        this.selectedValue.selectedValueSchoolBlock
                    )

                const request_Header = {
                    token: this.authToken
                }
                const request_Data = {
                    capHocs: capHocs,
                    khoiHocs: khoiHocs,
                    maLopHocs: maLopHocs,
                    maNhomTuoiMns: maNhomtuoiMn,
                    maSo: this.authUser.province,
                    maTruongHoc: maTruongHoc,
                    namHoc: this.selectedValue.selectedValueYear || currentYear
                }
                const response = await sendRequest(
                    Api.traCuu.thongTinYTeHocSinh.danhSachThongTinYTe,
                    request_Data,
                    request_Header
                )
                if (response.rc == 0) {
                    loading.close()
                    this.tableData = response.urlContexts
                    this.fileModalVisible = true
                } else {
                    loading.close()
                    this.$message({
                        message: response.rd,
                        type: 'error'
                    })
                }
            }
        },
        async getDataChonLopHoc(
            convertData_Caphoc,
            convertData_TruongHoc,
            convertData_KhoiHoc,
            convertData_Namhoc
        ) {
            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                capHoc: convertData_Caphoc,
                colExcels: [],
                khoiHoc: convertData_KhoiHoc,
                listMaTruongHocReq: convertData_TruongHoc,
                namHoc: convertData_Namhoc
            }
            const response = await sendRequest(
                Api.traCuu.thongTinYTeHocSinh.danhSachLopHoc,
                request_Data,
                request_Header
            )
            if (response.rc == 0) {
                this.getDataESelect.ESelectClass = response.tableData?.map(
                    (item) => ({
                        title: `Lớp ${item.tenLopHoc}`,

                        value: item.maLopHoc
                    })
                )
            } else {
                this.getDataESelect.ESelectClass = []
            }
        },
        async getDataChonTruongHoc() {
            const maDonVi = this.convertToValueArray(
                this.selectedValue.selectedValueUnitEducation
            )

            const capHoc = this.convertToValueArray(
                this.selectedValue.selectedValueGradeLevel
            )

            // Định nghĩa ánh xạ giữa capHoc và mảng kết quả
            const capHocMapping = {
                1: [1, 2, 3, 4, 5],
                2: [6, 7, 8, 9],
                3: [10, 11, 12],
                4: [13, 14, 15],
                5: [16, 17, 18],
                45: [13, 14, 15, 16, 17, 18],
                6: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
            }

            let result = []
            if (capHoc.length > 0) {
                result = capHoc
                    .flatMap((key) => capHocMapping[key] || [])
                    .map((value) =>
                        ESelectSchoolBlock_MockData.find(
                            (item) => item.value === value
                        )
                    )
            } else {
                this.resetESelectSchoolBlock = true
                this.getDataESelect.ESelectSchoolBlock = []
                this.selectedValue.selectedValueSchoolBlock = []
                result = [...ESelectSchoolBlock_MockData]
            }

            this.getDataESelect.ESelectSchoolBlock = result

            const request_Header = {
                token: this.authToken
            }

            const request_Data = {
                capHoc: capHoc,
                maDonVi: maDonVi
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            let response = await sendRequest(
                Api.internal_api.dm_School,
                request_Data,
                request_Header,
                null
            )

            if (response.statusResponse == 0) {
                loading.close()
                this.getDataESelect.ESelectSchool = response.rows.map((item) => ({
                    ...item,
                    tenTruongHoc: `${item.tenTruongHoc} - [${item.maTruongHoc}]`
                }))
            }
        },
        convertToValueArray(array) {
            return array.map((item) => item.value)
        },
        processSelectedValueSchoolBlock(selectedValues) {
            let capHocs = []
            let maNhomtuoiMn = []

            for (const item of selectedValues) {
                if (item.value >= 13 && item.value <= 18) {
                    switch (item.value) {
                        case 13:
                            maNhomtuoiMn.push('01')
                            break
                        case 14:
                            maNhomtuoiMn.push('02')
                            break
                        case 15:
                            maNhomtuoiMn.push('03')
                            break
                        case 16:
                            maNhomtuoiMn.push('04')
                            break
                        case 17:
                            maNhomtuoiMn.push('05')
                            break
                        case 18:
                            maNhomtuoiMn.push('06')
                            break
                        default:
                            maNhomtuoiMn.push(item.value)
                            break
                    }
                } else {
                    capHocs.push(item.value)
                }
            }

            return { maNhomtuoiMn, capHocs }
        }
    },
    watch: {
        'authUser.role': {
            immediate: true,
            handler(newRole, oldRole) {
                if (newRole === 5) {
                    this.selectedValue.selectedValueUnitEducation = [
                        { value: this.authUser.ma_don_vi }
                    ]
                    const convertedList = convertCapHocList(
                        this.authUser.capHocList
                    )
                    this.selectedValue.selectedValueGradeLevel = convertedList
                    this.selectedValue.selectedValueSchool = {
                        value: this.authUser.ma_truong_hoc
                    }
                }
                if (newRole === 4) {
                    this.selectedValue.selectedValueUnitEducation = [
                        { value: this.authUser.ma_don_vi }
                    ]
                }
            }
        },

        'selectedValue.selectedValueUnitEducation': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length && this.authUser.role !== 5) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        },
        'selectedValue.selectedValueGradeLevel': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length && this.authUser.role !== 5) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        }
    },
    mounted() {
        this.getDataESelect.ESelectGradeLevel = filterCapHocList(
            this.authUser.capHocList
        )
        this.getDataChonTruongHoc()
    }
}
</script>

<style scoped>
el-table th.el-table__cell > .cell {
    text-align: center;
}
.layout-btn-download {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.btn-get,
.btn-view {
    background-color: var(--teal);
}
.btn-download {
    background-color: var(--light-green);
}

.btn-update {
    background-color: var(--yellow);
}
.btn-delete {
    background-color: var(--orange);
}
.btn-downall {
    background-color: var(--dark-green);
}
.error {
    padding-top: 5px;
    color: red;
}
</style>
