<template>
    <div>
        <CustomBreadcrumb
            :title="'SÁP NHẬP VÀ GIẢI THỂ TRƯỜNG'"
            content="SÁP NHẬP TRƯỜNG"
        />
        <div class="content">
            <strong> Điều kiện thực hiện chức năng sáp nhập trường: </strong>

            <ul>
                <li>
                    Cả 2 trường cùng sát nhập phải thực hiện chuyển hồ sơ (Trường,
                    lớp, học sinh, giáo viên) từ năm học cũ lên năm học hiện tại.
                </li>
                <li>
                    Chuẩn hóa lại Lớp học, Học sinh, Giáo viên, Điểm trường đảm
                    bảo không tồn tại Tên lớp, Mã học sinh, Mã giáo viên, Mã điểm
                    trường tại 2 trường giống nhau.
                </li>
            </ul>
            <div class="row mb-4">
                <div class="col-sm-4">
                    <label>Chọn trường chính <span class="error">*</span></label>
                    <ESelect
                        :reset="resetTruongChinh"
                        @reset-completed="handleResetTruongChinh"
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="list_truong"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['title', 'value']"
                        v-model="truong_chinh"
                    />
                    <div v-if="err_maTruongChinh.length > 0" class="error">
                        {{ err_maTruongChinh }}
                    </div>
                </div>
                <div class="col-sm-4">
                    <label
                        >Chọn trường sáp nhập <span class="error">*</span></label
                    >
                    <ESelect
                        :reset="resetTruongSapNhap"
                        @reset-completed="handleResetTruongSapNhap"
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="list_truong"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['title', 'value']"
                        v-model="truong_sapNhap"
                    />
                    <div v-if="err_maTruongDuocSapNhap.length > 0" class="error">
                        {{ err_maTruongDuocSapNhap }}
                    </div>
                </div>
                <div class="col-sm-2">
                    <label>Năm học <span class="error">*</span></label>
                    <ESelectYear
                        v-model="namHoc"
                        placeholder="Chọn năm"
                        size="small"
                        :no-data-text="'Không có bản ghi nào'"
                        :no-match-text="'Không tìm thấy bản ghi nào'"
                        :width="'100%'"
                    ></ESelectYear>
                </div>
                <div class="col-sm-2" style="margin-top: 27px">
                    <CustomButton
                        label="Sáp nhập"
                        class="btn-default btn-add"
                        size="small"
                        @click="handleSapNhap"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'

import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'

import Api from '@/constants/Api'

import sendRequest from '@/services'
import { mapState } from 'vuex'
import ESelectYear from '@/components/ESelectYear.vue'
import { currentYear } from '@/utils'
import { validateField } from '@/utils'
export default {
    name: 'TraCuuTruongHoc',
    components: {
        CustomBreadcrumb,
        ESelect,
        CustomButton,
        ESelectYear
    },
    data() {
        return {
            list_truong: [],
            truong_chinh: '',
            truong_sapNhap: '',
            namHoc: '',

            err_maTruongChinh: '',
            err_maTruongDuocSapNhap: '',

            resetTruongChinh: false,
            resetTruongSapNhap: false
        }
    },
    methods: {
        handleResetTruongChinh() {
            this.resetTruongChinh = false
        },
        handleResetTruongSapNhap() {
            this.resetTruongSapNhap = false
        },
        async getDataChonTruongHoc() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })

            const request_Header = {
                token: this.authToken
            }

            const request_Data = {
                maDonVi: []
            }

            let response = await sendRequest(
                Api.internal_api.dm_School,
                request_Data,
                request_Header,
                null
            )

            if (response.statusResponse == 0) {
                loading.close()
                this.list_truong = response.rows.map((item) => ({
                    title: `${item.tenTruongHoc} - [${item.maTruongHoc}]`,
                    value: item.maTruongHoc
                }))
                loading.close()
            } else {
                loading.close()
            }
        },
        resetData() {
            this.truong_chinh = ''
            this.truong_sapNhap = ''
            this.err_maTruongChinh = ''
            this.err_maTruongDuocSapNhap = ''
            this.resetTruongChinh = true
            this.resetTruongSapNhap = true
        },
        async handleSapNhap() {
            try {
                const maTruongChinh = this.truong_chinh.value
                const maTruongDuocSapNhap = this.truong_sapNhap.value
                const fieldsToValidate = [
                    {
                        name: 'err_maTruongChinh',
                        value: maTruongChinh,
                        errorField: 'err_maTruongChinh',
                        errorMessage: 'Vui lòng chọn trường học chính'
                    },
                    {
                        name: 'err_maTruongDuocSapNhap',
                        value: maTruongDuocSapNhap,
                        errorField: 'err_maTruongDuocSapNhap',
                        errorMessage: 'Vui lòng chọn trường học sáp nhập'
                    }
                ]
                let isValid = true

                for (const field of fieldsToValidate) {
                    isValid = validateField(this, field, isValid)
                }

                if (isValid) {
                    const request_Header = {
                        token: this.authToken
                    }

                    const request_Data = {
                        maTruongChinh: maTruongChinh,
                        maTruongDuocSapNhap: maTruongDuocSapNhap,
                        namHoc: this.namHoc || currentYear
                    }
                    const response = await sendRequest(
                        Api.heThong.sapNhapGiaiThe.sapNhap,
                        request_Data,
                        request_Header,
                        null
                    )
                    if (response.rc == 0) {
                        this.$notify({
                            title: 'Sáp nhập trường học thành công',

                            type: 'success'
                        })
                        this.resetData()
                    } else {
                        this.$notify({
                            title: response.rd,

                            type: 'error'
                        })
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
    },

    mounted() {
        this.getDataChonTruongHoc()
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),

        ...mapState(['isCollapseLeftBar'])
    }
}
</script>

<style scoped>
.content {
    background: #fff;
    padding: 10px 10px 10px 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}
.content label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px;
}
.content .title {
    margin-bottom: 5px;
    font-size: 18px;
    color: #212529;
}
ul {
    list-style-type: disc;
    margin-left: 50px;
    margin-top: 5px;
}

li {
    margin-bottom: 5px;
}
.btn-add {
    background-color: var(--light-green);
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.error {
    color: red;
    padding-top: 5px;
}
</style>
