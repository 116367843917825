<template>
    <div class="school">
        <div class="header">
            <div class="left">
                <div class="layout-icon">
                    <i class="el-icon-school"></i>
                </div>
                <div class="title">Thông tin trường</div>
            </div>
            <div class="right">
                <div class="layout-icon">
                    <i class="el-icon-timer"></i>
                </div>
                <div class="title">
                    Thời gian cập nhật:
                    <span class="span">{{ dataTruongHoc.ngayCapNhat }}</span>
                </div>
            </div>
        </div>

        <div class="layout-card">
            <div class="row">
                <div class="col-md-4 mb-4">
                    <div>
                        <label>Tên trường</label>
                        <CustomInput
                            v-model="selectedValue.input_tenTruong"
                            placeholder=""
                            :disabled="true"
                            :clearable="false"
                            size="small"
                        >
                        </CustomInput>
                    </div>
                    <div class="mt-2">
                        <label>Đơn vị quản lý</label>
                        <CustomInput
                            v-model="selectedValue.input_donViQuanLy"
                            placeholder=""
                            :disabled="true"
                            :clearable="false"
                            size="small"
                        >
                        </CustomInput>
                    </div>
                    <div class="mt-2">
                        <label>Tên Quận/Huyện</label>
                        <CustomInput
                            v-model="selectedValue.input_tenQuanHuyen"
                            placeholder=""
                            :disabled="true"
                            :clearable="false"
                            size="small"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="col-md-4 mb-4">
                    <div>
                        <label>Mã trường</label>
                        <CustomInput
                            v-model="selectedValue.input_maTruong"
                            placeholder=""
                            :disabled="true"
                            :clearable="false"
                            size="small"
                        >
                        </CustomInput>
                    </div>
                    <div class="mt-2">
                        <label>Loại hình đào tạo</label>
                        <CustomInput
                            v-model="selectedValue.input_loaiHinhDaoTao"
                            placeholder=""
                            :disabled="true"
                            :clearable="false"
                            size="small"
                        >
                        </CustomInput>
                    </div>
                    <div class="mt-2">
                        <label>Tên Phường/Xã</label>
                        <CustomInput
                            v-model="selectedValue.input_tenPhuongXa"
                            placeholder=""
                            :disabled="true"
                            :clearable="false"
                            size="small"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <div>
                        <label>Cấp học</label>
                        <CustomInput
                            v-model="selectedValue.input_capHoc"
                            placeholder=""
                            :disabled="true"
                            :clearable="false"
                            size="small"
                        >
                        </CustomInput>
                    </div>
                    <div class="mt-2">
                        <label>Loại hình trường</label>
                        <CustomInput
                            v-model="selectedValue.input_loaiHinhTruong"
                            placeholder=""
                            :disabled="true"
                            :clearable="false"
                            size="small"
                        >
                        </CustomInput>
                    </div>
                    <div class="mt-2">
                        <label>Địa chỉ</label>
                        <CustomInput
                            v-model="selectedValue.input_diaChi"
                            placeholder=""
                            :disabled="true"
                            :clearable="false"
                            size="small"
                        >
                        </CustomInput>
                    </div>
                </div>
            </div>
            <el-collapse>
                <el-collapse-item name="1">
                    <template v-slot:title>
                        <CustomButton
                            type="success"
                            label="Xem thêm"
                            size="small"
                        />
                    </template>
                    <div class="row mt-2">
                        <div class="col-md-4 mb-4">
                            <div>
                                <label>SĐT</label>
                                <CustomInput
                                    v-model="selectedValue.input_sdt"
                                    placeholder=""
                                    :disabled="true"
                                    :clearable="false"
                                    size="small"
                                >
                                </CustomInput>
                            </div>
                            <div class="mt-2">
                                <label>Website</label>
                                <CustomInput
                                    v-model="selectedValue.input_website"
                                    placeholder=""
                                    :disabled="true"
                                    :clearable="false"
                                    size="small"
                                >
                                </CustomInput>
                            </div>
                            <div class="mt-2">
                                <label>Chuẩn quốc gia</label>
                                <CustomInput
                                    v-model="selectedValue.input_chuanQuocGia"
                                    placeholder=""
                                    :disabled="true"
                                    :clearable="false"
                                    size="small"
                                >
                                </CustomInput>
                            </div>
                        </div>

                        <div class="col-md-4 mb-4">
                            <div>
                                <label>Fax</label>
                                <CustomInput
                                    v-model="selectedValue.input_fax"
                                    placeholder=""
                                    :disabled="true"
                                    :clearable="false"
                                    size="small"
                                >
                                </CustomInput>
                            </div>
                            <div class="mt-2">
                                <label>Hiệu trưởng</label>
                                <CustomInput
                                    v-model="selectedValue.input_hieuTruong"
                                    placeholder=""
                                    :disabled="true"
                                    :clearable="false"
                                    size="small"
                                >
                                </CustomInput>
                            </div>
                            <div class="mt-2">
                                <label>Khu vực</label>
                                <CustomInput
                                    v-model="selectedValue.input_khuVuc"
                                    placeholder=""
                                    :disabled="true"
                                    :clearable="false"
                                    size="small"
                                >
                                </CustomInput>
                            </div>
                        </div>
                        <div class="col-md-4 mb-4">
                            <div>
                                <label>Email</label>
                                <CustomInput
                                    v-model="selectedValue.input_email"
                                    placeholder=""
                                    :disabled="true"
                                    :clearable="false"
                                    size="small"
                                >
                                </CustomInput>
                            </div>
                            <div class="mt-2">
                                <label>SĐT hiệu trưởng</label>
                                <CustomInput
                                    v-model="selectedValue.input_sdtHieuTruong"
                                    placeholder=""
                                    :disabled="true"
                                    :clearable="false"
                                    size="small"
                                >
                                </CustomInput>
                            </div>
                            <div class="mt-2">
                                <label>Chính sách vùng</label>
                                <CustomInput
                                    v-model="selectedValue.input_chinhSachVung"
                                    placeholder=""
                                    :disabled="true"
                                    :clearable="false"
                                    size="small"
                                >
                                </CustomInput>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-4 mb-4">
                            <div>
                                <el-checkbox
                                    :disabled="true"
                                    v-model="selectedValue.checkbox_chiBoDang"
                                    size="small"
                                >
                                    Có chi bộ đảng
                                </el-checkbox>
                            </div>
                            <div class="mt-2">
                                <el-checkbox
                                    :disabled="true"
                                    v-model="selectedValue.checkbox_sdMayTinh"
                                    size="small"
                                >
                                    Có sử dụng máy tính phục vụ dạy học
                                </el-checkbox>
                            </div>
                            <div class="mt-2">
                                <el-checkbox
                                    :disabled="true"
                                    v-model="
                                        selectedValue.checkbox_ktInternetDayHoc
                                    "
                                    size="small"
                                >
                                    Có khai thác internet cho dạy học
                                </el-checkbox>
                            </div>
                            <div class="mt-2">
                                <el-checkbox
                                    :disabled="true"
                                    v-model="selectedValue.checkbox_coDien"
                                    size="small"
                                >
                                    Có điện (điện lưới)
                                </el-checkbox>
                            </div>
                            <div class="mt-2">
                                <el-checkbox
                                    :disabled="true"
                                    v-model="selectedValue.checkbox_nuocSach"
                                    size="small"
                                >
                                    Có nguồn nước sạch
                                </el-checkbox>
                            </div>
                        </div>

                        <div class="col-md-4 mb-4">
                            <div>
                                <el-checkbox
                                    :disabled="true"
                                    v-model="selectedValue.checkbox_hsNoiTru"
                                    size="small"
                                >
                                    Có học sinh nội trú
                                </el-checkbox>
                            </div>
                            <div class="mt-2">
                                <el-checkbox
                                    :disabled="true"
                                    v-model="selectedValue.checkbox_nuocUong"
                                    size="small"
                                >
                                    Có nước uống
                                </el-checkbox>
                            </div>
                            <div class="mt-2">
                                <el-checkbox
                                    :disabled="true"
                                    v-model="
                                        selectedValue.checkbox_coCtVeSinhTheoGt
                                    "
                                    size="small"
                                >
                                    Có công trình vệ sinh riêng rẽ cho từng giới
                                    tính
                                </el-checkbox>
                            </div>
                            <div class="mt-2">
                                <el-checkbox
                                    :disabled="true"
                                    v-model="selectedValue.checkbox_choRuaTay"
                                    size="small"
                                >
                                    Có chỗ rửa tay thuận tiện(bao gồm nước và xà
                                    phòng)
                                </el-checkbox>
                            </div>
                            <div class="mt-2">
                                <el-checkbox
                                    :disabled="true"
                                    v-model="
                                        selectedValue.checkbox_checkbox_pbienKnsSkss
                                    "
                                    size="small"
                                >
                                    <span class="checkbox-label">
                                        Phổ biến cho CMHS về các CTGD phòng, chống
                                        HIV trên nền tảng kỹ năng sống và giáo dục
                                        sức khỏe sinh sản.
                                    </span>
                                </el-checkbox>
                            </div>
                        </div>
                        <div class="col-md-4 mb-4">
                            <div>
                                <el-checkbox
                                    :disabled="true"
                                    v-model="selectedValue.checkbox_vungDbKhoKhan"
                                    size="small"
                                >
                                    Vùng đặc biệt khó khăn
                                </el-checkbox>
                            </div>
                            <div class="mt-2">
                                <el-checkbox
                                    :disabled="true"
                                    v-model="selectedValue.checkbox_cshtChoHskt"
                                    size="small"
                                >
                                    <span class="checkbox-label">
                                        Có CS hạ tầng, TL học tập, MT học tập phù
                                        hợp và tiếp cận với HS khuyết tật
                                    </span>
                                </el-checkbox>
                            </div>
                            <div class="mt-2">
                                <el-checkbox
                                    :disabled="true"
                                    v-model="selectedValue.checkbox_ctacTuVanHd"
                                    size="small"
                                >
                                    Có bộ phận làm công tác tư vấn học đường
                                </el-checkbox>
                            </div>
                            <div class="mt-2">
                                <el-checkbox
                                    :disabled="true"
                                    v-model="
                                        selectedValue.checkbox_pcThienTaiBdkh
                                    "
                                    size="small"
                                >
                                    <span class="checkbox-label">
                                        Có KH G.Dục phòng, chống thiên tai và Ứng
                                        phó biến đổi khí hậu
                                    </span>
                                </el-checkbox>
                            </div>
                        </div>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
    </div>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import CustomInput from '@/components/CustomInput.vue'
import { ESelectGradeLevel_MockData_TraCuuTruongHoc } from '@/mock_data/index'
export default {
    name: 'schoolC',
    components: {
        CustomInput,
        CustomButton
    },
    data() {
        return {
            loaiHinhDaoTao: [
                { title: 'Công lập', value: '1' },
                { title: 'Tư thục', value: '2' },
                { title: 'GDTX', value: '3' },
                { title: 'Bán công', value: '4' },
                { title: 'Dân lập', value: '5' }
            ],
            selectedValue: {
                input_tenTruong: '',
                input_maTruong: '',
                input_capHoc: '',
                input_donViQuanLy: '',
                input_loaiHinhDaoTao: '',
                input_loaiHinhTruong: '',
                input_tenQuanHuyen: '',
                input_tenPhuongXa: '',
                input_diaChi: '',
                input_sdt: '',
                input_fax: '',
                input_email: '',
                input_website: '',
                input_hieuTruong: '',
                input_sdtHieuTruong: '',
                input_chuanQuocGia: '',
                input_khuVuc: '',
                input_chinhSachVung: '',
                checkbox_chiBoDang: false,
                checkbox_hsNoiTru: false,
                checkbox_vungDbKhoKhan: false,
                checkbox_sdMayTinh: false,
                checkbox_nuocUong: false,
                checkbox_cshtChoHskt: false,
                checkbox_ktInternetDayHoc: false,
                checkbox_coCtVeSinhTheoGt: false,
                checkbox_ctacTuVanHd: false,
                checkbox_coDien: false,
                checkbox_choRuaTay: false,
                checkbox_pcThienTaiBdkh: false,
                checkbox_pcThienTaiDichBenh: false,
                checkbox_nuocSach: false,
                checkbox_pbienKnsSkss: false
            }
        }
    },
    props: {
        dataTruongHoc: {
            type: Object,
            required: true
        }
    },
    methods: {
        getTitleFormValue(array, value) {
            const selectedTitle = array.find(
                (item) => item.value === value
            )?.title
            return selectedTitle
        },
        getTitleFormMappingCode(array, value) {
            const selectedTitle = array.find(
                (item) => item.mappingCode === value
            )?.constantTitle
            return selectedTitle
        },
        setCheckboxValue(target, newValue) {
            this.selectedValue[target] = newValue === 0 ? false : true
        }
    },
    watch: {
        dataTruongHoc(newValue) {
            this.selectedValue.input_tenTruong = newValue.tenTruongHoc
            this.selectedValue.input_maTruong = newValue.maTruongHoc
            this.selectedValue.input_capHoc = this.getTitleFormValue(
                ESelectGradeLevel_MockData_TraCuuTruongHoc,
                newValue.capHoc
            )

            this.selectedValue.input_donViQuanLy = newValue.donViQuanLy
            this.selectedValue.input_loaiHinhDaoTao = this.getTitleFormValue(
                this.loaiHinhDaoTao,
                newValue.loaiHinhDaoTao
            )

            this.selectedValue.input_loaiHinhTruong =
                this.getTitleFormMappingCode(
                    this.dataChonLoaihinhtruong_Store,
                    newValue.loaiHinhTruong
                )
            this.selectedValue.input_tenQuanHuyen = newValue.tenQuanHuyen
            this.selectedValue.input_tenPhuongXa = newValue.tenPhuongXa
            this.selectedValue.input_diaChi = newValue.diaChi

            this.selectedValue.input_sdt = newValue.dienThoai
            this.selectedValue.input_fax = newValue.fax
            this.selectedValue.input_email = newValue.email
            this.selectedValue.input_website = newValue.website
            this.selectedValue.input_hieuTruong = newValue.hieuTruong

            this.selectedValue.input_sdtHieuTruong = newValue.sdtHieuTruong

            this.selectedValue.input_chuanQuocGia = this.getTitleFormMappingCode(
                this.dataChonDatChuanQG_Store,
                newValue.chuanQuocGia?.toString()
            )
            this.selectedValue.input_khuVuc = this.getTitleFormMappingCode(
                this.dataChonKhuvuc_Store,
                newValue.khuVuc
            )
            this.selectedValue.input_chinhSachVung = newValue.chinhSachVung

            this.setCheckboxValue('checkbox_chiBoDang', newValue.chiBoDang)

            this.setCheckboxValue('checkbox_hsNoiTru', newValue.hsNoiTru)

            this.setCheckboxValue(
                'checkbox_vungDbKhoKhan',
                newValue.vungDbKhoKhan
            )

            this.setCheckboxValue('checkbox_sdMayTinh', newValue.sdMayTinh)

            this.setCheckboxValue('checkbox_nuocUong', newValue.nuocUong)

            this.setCheckboxValue('checkbox_cshtChoHskt', newValue.cshtChoHskt)

            this.setCheckboxValue(
                'checkbox_ktInternetDayHoc',
                newValue.ktInternetDayHoc
            )
            this.setCheckboxValue(
                'checkbox_coCtVeSinhTheoGt',
                newValue.coCtVeSinhTheoGt
            )

            this.setCheckboxValue('checkbox_ctacTuVanHd', newValue.ctacTuVanHd)

            this.setCheckboxValue('checkbox_coDien', newValue.coDien)

            this.setCheckboxValue('checkbox_choRuaTay', newValue.choRuaTay)

            this.setCheckboxValue(
                'checkbox_pcThienTaiBdkh',
                newValue.pcThienTaiBdkh
            )

            this.setCheckboxValue(
                'checkbox_pcThienTaiDichBenh',
                newValue.pcThienTaiDichBenh
            )

            this.setCheckboxValue('checkbox_nuocSach ', newValue.nuocSach)

            this.setCheckboxValue('checkbox_pbienKnsSkss', newValue.pbienKnsSkss)
        }
    },
    computed: {
        dataChonLoaihinhtruong_Store() {
            return JSON.parse(localStorage.getItem('data_ChonLoaihinhtruong'))
        },
        dataChonKhuvuc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonKhuvuc'))
        },
        dataChonDatChuanQG_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDatChuanQG'))
        }
    }
}
</script>

<style scoped>
::v-deep .el-input.is-disabled .el-input__inner {
    color: black !important;
    background-color: #eee !important;
}
::v-deep .el-checkbox__input.is-disabled + span.el-checkbox__label {
    color: black !important;
}

.school {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 15px;
    padding-left: 6px;
    padding-right: 6px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 10px 12px 10px;
}
.header .left,
.header .right {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #186ab2;
    font-weight: 700;
    text-transform: uppercase;
}
.header .layout-icon {
    margin-right: 8px;
}
.header .title .span {
    color: #333;
    font-weight: 600;
}
.header .left .icon,
.header .right .icon {
    font-size: 18px;
    color: #000000;
    font-weight: 700;
    margin-right: 5px;
}

.header .right {
    margin-left: 6px;
}

.layout-card {
    padding: 0px 10px 0px 10px;
}
.layout-card .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f2f3f8;
    height: 25px;
    color: black;
    padding: 5px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.layout-card .card-header .title {
    margin: -5px 0px 0px 10px;
    color: #000000;
    font-weight: bold;
    font-size: 15px;
}
.layout-card .card-body {
    padding: 5px;
    background: white;
    border: #f2f3f8 2px solid;
    height: 300px;
}
.custom-btn {
    font-weight: 700;
    color: #fff;
    background-color: #6aab9c;
}
</style>
