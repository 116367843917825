<template>
    <el-dialog
        :visible="chinhSuaModalVisible"
        @close="closeModalChinhSua"
        width="70%"
    >
        <span slot="title" class="custom-dialog-title">
            CHỈNH SỬA CHUẨN CHIỀU CAO, CÂN NẶNG
            <hr />
        </span>
        <div class="row form">
            <div class="col-md-6">
                <div class="row d-flex align-items-center justify-content-center">
                    <div class="col-md-3">
                        <label>Mã tiêu chuẩn (<span class="span">*</span>)</label>
                    </div>
                    <div class="col-md-6">
                        <el-input
                            :disabled="true"
                            placeholder="Nhập mã tiêu chuẩn ..."
                            v-model="selectedValue.input_maTieuChuan"
                            size="small"
                            style="width: 100%"
                            @blur="validateMaTieuChuan"
                            @focus="validateMaTieuChuan"
                        >
                        </el-input>
                        <div class="error-message" v-if="errors.maTieuChuan">
                            {{ errors.maTieuChuan }}
                        </div>
                    </div>
                </div>
                <div
                    class="row d-flex align-items-center justify-content-center mt-4"
                >
                    <div class="col-md-3">
                        <label>Số Tháng(<span class="span">*</span>)</label>
                    </div>
                    <div class="col-md-6">
                        <el-input
                            placeholder="Nhập số tháng ..."
                            v-model="selectedValue.input_soThang"
                            size="small"
                            style="width: 100%"
                            type="Number"
                            @blur="validateSoThang"
                            @focus="validateSoThang"
                        >
                        </el-input>
                        <div class="error-message" v-if="errors.soThang">
                            {{ errors.soThang }}
                        </div>
                    </div>
                </div>
                <div
                    class="row d-flex align-items-center justify-content-center mt-4"
                >
                    <div class="col-md-3">
                        <label>Thiếu chuẩn 1 </label>
                    </div>
                    <div class="col-md-6">
                        <el-input
                            placeholder="Nhập thiếu chuẩn 1 ..."
                            v-model="selectedValue.input_thieuChuan1"
                            size="small"
                            style="width: 100%"
                        >
                        </el-input>
                    </div>
                </div>

                <div
                    class="row d-flex align-items-center justify-content-center mt-4"
                >
                    <div class="col-md-3">
                        <label>Thiếu chuẩn 2</label>
                    </div>
                    <div class="col-md-6">
                        <el-input
                            placeholder="Nhập thiếu chuẩn 2 ..."
                            v-model="selectedValue.input_thieuChuan2"
                            size="small"
                            style="width: 100%"
                        >
                        </el-input>
                    </div>
                </div>
                <div
                    class="row d-flex align-items-center justify-content-center mt-4"
                >
                    <div class="col-md-3">
                        <label>Thiếu chuẩn 3 </label>
                    </div>
                    <div class="col-md-6">
                        <el-input
                            placeholder="Nhập thiếu chuẩn 3 ..."
                            v-model="selectedValue.input_thieuChuan3"
                            size="small"
                            style="width: 100%"
                        >
                        </el-input>
                    </div>
                </div>
                <div
                    class="row d-flex align-items-center justify-content-center mt-4"
                >
                    <div class="col-md-3">
                        <label>Trạng thái </label>
                    </div>
                    <div class="col-md-6">
                        <el-select
                            v-model="selectedValue.selectedValue_trangThai"
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                        >
                            <el-option
                                v-for="item in ESelect_trangThai"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            />
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row d-flex align-items-center justify-content-center">
                    <div class="col-md-4">
                        <label
                            >Loại tiêu chuẩn (<span class="span">*</span>)</label
                        >
                    </div>
                    <div class="col-md-6">
                        <el-select
                            v-model="selectedValue.selectedValue_loaiTieuChuan"
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            :no-data-text="'Không có bản ghi nào'"
                        >
                            <el-option
                                v-for="item in ESelect_loaiTieuChuan"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            />
                        </el-select>
                        <div class="error-message" v-if="errors.loaiTieuChuan">
                            {{ errors.loaiTieuChuan }}
                        </div>
                    </div>
                </div>
                <div
                    class="row d-flex align-items-center justify-content-center mt-4"
                >
                    <div class="col-md-4">
                        <label>Trung bình (<span class="span">*</span>)</label>
                    </div>
                    <div class="col-md-6">
                        <el-input
                            placeholder="Nhập trung bình ..."
                            v-model="selectedValue.input_trungBinh"
                            size="small"
                            style="width: 100%"
                            @blur="validateTrungBinh"
                            @focus="validateTrungBinh"
                        >
                        </el-input>
                        <div class="error-message" v-if="errors.trungBinh">
                            {{ errors.trungBinh }}
                        </div>
                    </div>
                </div>
                <div
                    class="row d-flex align-items-center justify-content-center mt-4"
                >
                    <div class="col-md-4">
                        <label>Vượt chuẩn 1 </label>
                    </div>
                    <div class="col-md-6">
                        <el-input
                            placeholder="Nhập vượt chuẩn 1 ..."
                            v-model="selectedValue.input_vuotChuan1"
                            size="small"
                            style="width: 100%"
                        >
                        </el-input>
                    </div>
                </div>
                <div
                    class="row d-flex align-items-center justify-content-center mt-4"
                >
                    <div class="col-md-4">
                        <label>Vượt chuẩn 2 </label>
                    </div>
                    <div class="col-md-6">
                        <el-input
                            placeholder="Nhập vượt chuẩn 2 ..."
                            v-model="selectedValue.input_vuotChuan2"
                            size="small"
                            style="width: 100%"
                        >
                        </el-input>
                    </div>
                </div>
                <div
                    class="row d-flex align-items-center justify-content-center mt-4"
                >
                    <div class="col-md-4">
                        <label>Vượt chuẩn 3 </label>
                    </div>
                    <div class="col-md-6">
                        <el-input
                            placeholder="Nhập vượt chuẩn 3 ..."
                            v-model="selectedValue.input_vuotChuan3"
                            size="small"
                            style="width: 100%"
                        >
                        </el-input>
                    </div>
                </div>
            </div>
        </div>
        <div class="layout-btn">
            <div>
                <CustomButton
                    label="Đóng"
                    size="small"
                    type="info"
                    @click="closeModalChinhSua"
                />
            </div>
            <div style="margin-left: 10px">
                <CustomButton
                    label="Chỉnh sửa"
                    size="small"
                    type="success"
                    @click="handleChinhSua"
                />
            </div>
        </div>
    </el-dialog>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import { mapState } from 'vuex'
import sendRequest from '@/services'
import { validateInput } from '@/utils'

import Api from '@/constants/Api'
export default {
    name: 'ModalThemMoiTruongHoc',
    props: {
        chinhSuaModalVisible: {
            type: Boolean,
            required: true
        },
        closeModalChinhSua: {
            type: Function,
            required: true
        },
        dataModalChinhSua: {
            type: Object,
            required: true
        },
        handleSearch: Function
    },

    components: { CustomButton },
    data() {
        return {
            errors: {
                maTieuChuan: null,
                soThang: null,
                loaiTieuChuan: null,
                trungBinh: null
            },
            submitted: false, // Thêm biến submitted

            selectedValue: {
                input_maTieuChuan: '',
                input_soThang: '',
                input_thieuChuan1: '',
                input_thieuChuan2: '',
                input_thieuChuan3: '',
                selectedValue_trangThai: '',
                selectedValue_loaiTieuChuan: '',
                input_trungBinh: '',
                input_vuotChuan1: '',
                input_vuotChuan2: '',
                input_vuotChuan3: ''
            },
            ESelect_loaiTieuChuan: [
                {
                    title: 'Chọn loại tiêu chuẩn',
                    value: ''
                },
                {
                    title: 'Chiều cao bé trai',
                    value: '1'
                },
                {
                    title: 'Cân nặng bé trai',
                    value: '2'
                },
                {
                    title: 'Chiều cao bé gái',
                    value: '3'
                },
                {
                    title: 'Cân nặng bé gái',
                    value: '4'
                }
            ],
            ESelect_trangThai: [
                {
                    title: 'Đang hoạt động',
                    value: 1
                },
                {
                    title: 'Ngưng hoạt động',
                    value: 0
                }
            ]
        }
    },

    methods: {
        validateMaTieuChuan() {
            if (this.submitted) {
                this.errors.maTieuChuan = validateInput(
                    'text',
                    this.selectedValue.input_maTieuChuan
                )
            }
        },
        validateSoThang() {
            if (this.submitted) {
                this.errors.soThang = validateInput(
                    'text',
                    this.selectedValue.input_soThang
                )
            }
        },
        validateLoaiTieuChuan() {
            if (this.submitted) {
                if (this.selectedValue.selectedValue_loaiTieuChuan === '') {
                    this.errors.loaiTieuChuan = 'Vui lòng chọn loại tiêu chuẩn'
                } else {
                    this.errors.loaiTieuChuan = ''
                }
            }
        },

        validateTrungBinh() {
            if (this.submitted) {
                this.errors.trungBinh = validateInput(
                    'text',
                    this.selectedValue.input_trungBinh
                )
            }
        },
        handleChinhSua() {
            this.submitted = true
            this.validateMaTieuChuan()
            this.validateSoThang()
            this.validateLoaiTieuChuan()
            this.validateTrungBinh()
            // Kiểm tra nếu không có lỗi thì tiếp tục thực hiện hành động xác thực
            if (
                !this.errors.maTieuChuan &&
                !this.errors.soThang &&
                !this.errors.loaiTieuChuan &&
                !this.errors.trungBinh
            ) {
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })

                this.$confirm('Xác nhận chỉnh sửa ?', 'Thông báo', {
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy',
                    type: 'warning'
                })
                    .then(async () => {
                        const request_Header = {
                            token: this.authToken
                        }
                        const request_Data = {
                            ma: this.selectedValue.input_maTieuChuan,
                            loai: this.selectedValue.selectedValue_loaiTieuChuan,
                            thang: this.selectedValue.input_soThang,
                            standardSub1: this.selectedValue.input_vuotChuan1,
                            standardSub2: this.selectedValue.input_vuotChuan2,
                            standardSub3: this.selectedValue.input_vuotChuan3,
                            standard0: this.selectedValue.input_trungBinh,
                            standard1: this.selectedValue.input_thieuChuan1,
                            standard2: this.selectedValue.input_thieuChuan2,
                            standard3: this.selectedValue.input_thieuChuan3,
                            trangThai: this.selectedValue.selectedValue_trangThai
                        }
                        const response = await sendRequest(
                            Api.superAdmin.chuanChieuCaoCanNang
                                .chinhSuaChuanChieuCaoCanNang,
                            request_Data,

                            request_Header
                        )
                        if (response.code == 200) {
                            this.$message({
                                type: 'success',
                                message: 'Chỉnh sửa thành công'
                            })
                            this.closeModalChinhSua()
                            this.$emit('handleSearch')
                        } else {
                            loading.close()
                            this.$message({
                                type: 'error',
                                message: response.message
                            })
                        }
                    })
                    .catch(() => {
                        loading.close()
                    })
            }
        }
    },
    watch: {
        dataModalChinhSua(newValue) {
            this.selectedValue.input_maTieuChuan = newValue.maChuanChieuCaoCanNang
            this.selectedValue.input_soThang = newValue.thang
            this.selectedValue.input_trungBinh = newValue.standard0
            this.selectedValue.input_thieuChuan1 = newValue.standard1
            this.selectedValue.input_thieuChuan2 = newValue.standard2
            this.selectedValue.input_thieuChuan3 = newValue.standard3
            this.selectedValue.input_vuotChuan1 = newValue.standardSub1
            this.selectedValue.input_vuotChuan2 = newValue.standardSub2
            this.selectedValue.input_vuotChuan3 = newValue.standardSub3
            this.selectedValue.selectedValue_loaiTieuChuan =
                newValue.loaiTieuChuan.toString()
            this.selectedValue.selectedValue_trangThai = newValue.trangThai
        },
        'selectedValue.input_maTieuChuan': function (newValue) {
            this.validateMaTieuChuan()
        },
        'selectedValue.input_soThang': function (newValue) {
            this.validateSoThang()
        },
        'selectedValue.selectedValue_loaiTieuChuan': function (newValue) {
            this.validateLoaiTieuChuan()
        },
        'selectedValue.input_trungBinh': function (newValue) {
            this.validateTrungBinh()
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped>
hr {
    border: 1px solid green;
}
.form label {
    font-weight: bold;
}
.form .span {
    color: red;
}
.form .error-message {
    font-style: italic;
    color: red;
    margin-top: 5px;
}
.custom-date-picker {
    height: 32px;
}
.custom-date-picker::placeholder {
    color: gainsboro;

    font-size: 13px;
    background-color: transparent;
    appearance: none;
}
.layout-btn {
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
}
</style>
