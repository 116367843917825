<template>
    <el-dialog
        :visible="chinhSuaModalVisible"
        :fullscreen="true"
        @close="closeModalChinhsua"
    >
        <span slot="title" class="custom-dialog-title">
            CHỈNH SỬA YÊU CẦU NỘP THỐNG KÊ THEO BIỂU MẪU
            <hr />
        </span>
        <div class="row">
            <div class="col-4">
                <label>Đơn vị quản lý <span style="color: red">*</span></label>
                <div>
                    <ESelect
                        :key="selectedValue.selectedValueUnitEducation.length"
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="this.dataChonDonVi_Store"
                        :placeholder="'Chọn đơn vị quản lý'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="selectedValue.selectedValueUnitEducation"
                        @change="getDataChonTruongHoc"
                    />
                    <span v-if="err_donViNopList.length > 0" class="error">{{
                        err_donViNopList
                    }}</span>
                </div>
            </div>
            <div class="col-4">
                <label>Cấp học <span style="color: red">*</span></label>
                <div>
                    <ESelect
                        :key="selectedValue.selectedValueGradeLevel.length"
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectGradeLevel_MockData"
                        :placeholder="'Chọn cấp học'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenTruongHoc', 'value']"
                        v-model="selectedValue.selectedValueGradeLevel"
                        @change="getDataChonTruongHoc"
                    />
                    <span v-if="err_capHocNopList.length > 0" class="error">{{
                        err_capHocNopList
                    }}</span>
                </div>
            </div>
            <div class="col-4">
                <label>Trường học <span style="color: red">*</span></label>
                <div>
                    <ESelect
                        :key="selectedValue.selectedValueSchool.length"
                        :reset="resetESelectSchool"
                        @reset-completed="handleResetCompleted"
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectSchool"
                        :placeholder="'Chọn trường học'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['title', 'value']"
                        :value="selectedValue.selectedValueSchool"
                    />
                    <span v-if="err_maTruongHocList.length > 0" class="error">{{
                        err_maTruongHocList
                    }}</span>
                </div>
            </div>
        </div>

        <div class="row mt-4 d-flex justify-content-center align-items-center">
            <div class="col-3">
                <label>Tên báo cáo</label>
                <div>
                    <el-input
                        placeholder="Nhập tên báo cáo ..."
                        v-model="selectedValue.selectedNameReport"
                        size="small"
                    ></el-input>
                    <span v-if="err_tenBaoCao.length > 0" class="error">{{
                        err_tenBaoCao
                    }}</span>
                </div>
            </div>

            <div class="col-3">
                <label>Loại báo cáo</label>
                <div>
                    <ESelect
                        :key="selectedValue.selectedValueReportModal.value"
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectReportModal_MockData"
                        :placeholder="'Chọn loại báo cáo'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['tenBaoCao', 'value']"
                        v-model="selectedValue.selectedValueReportModal"
                    />
                    <span v-if="err_loaiBaoCao.length > 0" class="error">{{
                        err_loaiBaoCao
                    }}</span>
                </div>
            </div>
        </div>
        <div class="row mt-4 d-flex justify-content-center align-items-center">
            <div class="col-sm-6">
                <label>Thời gian áp dụng <span style="color: red">*</span></label>
            </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center">
            <div class="col-6">
                <div class="row">
                    <div class="col-sm-5">
                        <el-date-picker
                            v-model="selectedValue.selectedValueStartDate"
                            type="date"
                            placeholder="Ngày bắt đầu"
                            style="width: 100%"
                            format="dd/MM/yyyy"
                            size="small"
                            @input="updateEndDate"
                        >
                        </el-date-picker>
                        <span v-if="err_ngayApDung.length > 0" class="error">{{
                            err_ngayApDung
                        }}</span>
                    </div>
                    <div class="col-sm-2">
                        <CustomButton
                            label="Đến"
                            size="small"
                            style="width: 100%"
                            class="btn-date"
                        />
                    </div>
                    <div class="col-sm-5">
                        <el-date-picker
                            v-model="selectedValue.selectedValueEndDate"
                            type="date"
                            placeholder="Ngày kết thúc"
                            style="width: 100%"
                            format="dd/MM/yyyy"
                            size="small"
                        >
                        </el-date-picker>
                        <span v-if="err_ngayKetThuc.length > 0" class="error">{{
                            err_ngayKetThuc
                        }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="layout-btn">
            <CustomButton
                label="Đóng"
                size="small"
                type="info"
                @click="closeModalChinhsua"
            />
            <CustomButton
                label="Chỉnh sửa"
                size="small"
                type="warning"
                @click="handleUpdate"
            />
        </div>
    </el-dialog>
</template>
<script>
import moment from 'moment'
import ESelect from '@/components/ESelect.vue'
import { mapState } from 'vuex'
import CustomButton from '@/components/CustomButton.vue'
import { ESelectGradeLevel_MockData, ESelectReport_MockData } from '@/mock_data'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { validateField } from '@/utils'
export default {
    name: 'ModalLstQuaTrinhLuong',
    props: {
        chinhSuaModalVisible: {
            type: Boolean,
            required: true
        },

        closeModalChinhsua: {
            type: Function,
            required: true
        },
        dataModalUpdate: {
            type: Object,
            required: true
        }
    },

    components: { CustomButton, ESelect },
    data() {
        return {
            resetESelectSchool: false,
            getDataESelect: {
                ESelectUnitEducation: [], //donvi
                ESelectGradeLevel_MockData: ESelectGradeLevel_MockData, //caphoc
                ESelectSchool: [], //truonghoc
                ESelectReportModal_MockData: ESelectReport_MockData //loaibaocao
            },

            selectedValue: {
                selectedValueUnitEducation: [], //donvi
                selectedValueGradeLevel: [], //caphoc
                selectedValueSchool: [], //truonghoc
                selectedNameReport: '', //tenbaocao
                selectedValueReportModal: {}, //loaibaocao
                selectedValueStartDate: '', //ngaybatdau
                selectedValueEndDate: '' //ngayketthuc
            },
            err_donViNopList: '',

            err_capHocNopList: '',
            err_maTruongHocList: '',
            err_loaiBaoCao: '',

            err_tenBaoCao: '',

            err_ngayApDung: '',

            err_ngayKetThuc: ''
        }
    },
    watch: {
        dataModalUpdate(newValue) {
            this.getDataChonTruongHoc()
            const newValueSelectedValueUnitEducation = newValue.donViNopList.map(
                (item) => ({
                    value: item.maDonVi,
                    title: item.tenDonVi
                })
            )
            this.selectedValue.selectedValueUnitEducation = [
                ...newValueSelectedValueUnitEducation
            ]
            const newValueSelectedValueGradeLevel =
                ESelectGradeLevel_MockData.filter((item) =>
                    newValue.capHocNop.includes(item.value)
                ).map(({ tenTruongHoc, value }) => ({
                    title: tenTruongHoc,
                    value
                }))

            this.selectedValue.selectedValueGradeLevel = [
                ...newValueSelectedValueGradeLevel
            ]

            const newValueSelectedValueSchool = newValue.truongHocNopList.map(
                (item) => ({
                    value: item.maTruongHoc,
                    title: item.tenTruongHoc
                })
            )
            this.selectedValue.selectedValueSchool = [
                ...newValueSelectedValueSchool
            ]
            this.selectedValue.selectedNameReport = newValue.tenBaoCao

            const newValueSelectedValueReportModal = {
                value: newValue.loaiBaoCao.toString()
            }
            this.selectedValue.selectedValueReportModal = {
                ...newValueSelectedValueReportModal
            }

            this.selectedValue.selectedValueStartDate = moment(
                newValue.ngayApDung,
                'DD/MM/YYYY'
            ).toDate()
            this.selectedValue.selectedValueEndDate = moment(
                newValue.ngayKetThuc,
                'DD/MM/YYYY'
            ).toDate()
        }
    },

    methods: {
        resetErr() {
            this.err_capHocNopList = ''
            this.err_donViNopList = ''
            this.err_loaiBaoCao = ''
            this.err_maTruongHocList = ''
            this.err_ngayApDung = ''
            this.err_ngayKetThuc = ''
            this.err_tenBaoCao = ''
        },
        updateEndDate() {
            if (this.selectedValue.selectedValueStartDate) {
                const newEndDate = moment(
                    this.selectedValue.selectedValueStartDate
                ).add(10, 'days')
                this.selectedValue.selectedValueEndDate = newEndDate
            }
        },
        handleResetCompleted() {
            this.resetESelectSchool = false
        },

        async handleUpdate() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })

            const id = this.dataModalUpdate.id
            const donViNopList = this.convertToValueArray(
                this.selectedValue.selectedValueUnitEducation
            )

            const capHocNopList = this.convertToValueArray(
                this.selectedValue.selectedValueGradeLevel
            ).map((item) => Number(item))
            const maTruongHocList = this.convertToValueArray(
                this.selectedValue.selectedValueSchool
            )
            const loaiBaoCao = parseInt(
                this.selectedValue.selectedValueReportModal.value
            )
            const tenBaoCao = this.selectedValue.selectedNameReport
            const ngayApDung = moment(
                this.selectedValue.selectedValueStartDate
            ).format('DD/MM/YYYY HH:mm:ss')

            const ngayKetThuc = moment(
                this.selectedValue.selectedValueEndDate
            ).format('DD/MM/YYYY HH:mm:ss')
            const fieldsToValidate = [
                {
                    name: 'err_donViNopList',
                    value: donViNopList,
                    errorField: 'err_donViNopList',
                    errorMessage: 'Vui lòng chọn đơn vị quản lý'
                },
                {
                    name: 'err_capHocNopList',
                    value: capHocNopList,
                    errorField: 'err_capHocNopList',
                    errorMessage: 'Vui lòng chọn cấp học'
                },
                {
                    name: 'err_maTruongHocList',
                    value: maTruongHocList,
                    errorField: 'err_maTruongHocList',
                    errorMessage: 'Vui lòng chọn trường học'
                },
                {
                    name: 'err_loaiBaoCao',
                    value: this.selectedValue.selectedValueReportModal.value,
                    errorField: 'err_loaiBaoCao',
                    errorMessage: 'Vui lòng chọn loại báo cáo'
                },
                {
                    name: 'err_tenBaoCao',
                    value: tenBaoCao,
                    errorField: 'err_tenBaoCao',
                    errorMessage: 'Vui lòng nhập tên báo cáo '
                },
                {
                    name: 'err_ngayApDung',
                    value: this.selectedValue.selectedValueStartDate,
                    errorField: 'err_ngayApDung',
                    errorMessage: 'Vui lòng chọn ngày bắt đầu'
                },
                {
                    name: 'err_ngayKetThuc',
                    value: this.selectedValue.selectedValueEndDate,
                    errorField: 'err_ngayKetThuc',
                    errorMessage: 'Vui lòng chọn ngày kết thúc'
                }
            ]
            let isValid = true

            for (const field of fieldsToValidate) {
                isValid = validateField(this, field, isValid)
            }
            if (isValid) {
                await this.$confirm(
                    'Bạn có chắc chắn muốn Chỉnh sửa không?',
                    'Xác nhận',
                    {
                        confirmButtonText: 'Đồng ý',
                        cancelButtonText: 'Hủy',
                        type: 'warning'
                    }
                )

                const request_Header = {
                    token: this.authToken
                }
                const request_Data = {
                    id: id,
                    capHocNopList: capHocNopList,
                    donViNopList: donViNopList,
                    loaiBaoCao: loaiBaoCao,
                    maTruongHocList: maTruongHocList,
                    ngayApDung: ngayApDung,
                    ngayKetThuc: ngayKetThuc,
                    tenBaoCao: tenBaoCao
                }
                const response = await sendRequest(
                    Api.baoCao.nopThongKeTheoBieuMau.updateNopBieuMau,
                    request_Data,
                    request_Header
                )
                if (response.rc == 0) {
                    setTimeout(() => {
                        loading.close()
                        this.$message({
                            message: 'Chỉnh sửa dữ liệu thành công',
                            type: 'success'
                        })
                        this.resetErr()
                        location.reload()
                    }, 1000)
                } else {
                    loading.close()
                    this.$message({
                        message: 'Chỉnh sửa không hợp lệ',
                        type: 'warning'
                    })
                }
            }

            loading.close()
        },

        async getDataChonTruongHoc() {
            const maDonVi = this.convertToValueArray(
                this.selectedValue.selectedValueUnitEducation
            )

            const capHoc = this.convertToValueArray(
                this.selectedValue.selectedValueGradeLevel
            )?.map(Number)
            if (capHoc.length !== 0 || maDonVi.length !== 0) {
                this.resetESelectSchool = true
            }
            const request_Header = {
                token: this.authToken
            }

            const request_Data = {
                capHoc: capHoc,
                maDonVi: maDonVi
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            let response = await sendRequest(
                Api.internal_api.dm_School,
                request_Data,
                request_Header,
                null
            )

            if (response.statusResponse == 0) {
                loading.close()
                this.getDataESelect.ESelectSchool = response.rows.map((item) => ({
                    title: `${item.tenTruongHoc} - [${item.maTruongHoc}]`,
                    value: item.maTruongHoc
                }))
            }
        },
        convertToValueArray(array) {
            return array.map((item) => item.value)
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        }
    }
    // mounted() {
    //     this.getDataChonTruonghoc()
    // }
}
</script>
<style scoped>
.input-group-addon {
    padding: 8px 19px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
}
.layout-btn {
    margin-top: 130px;
    display: flex;
    justify-content: flex-end;
}
.col-sm-5 {
    max-width: 45%;
    flex: 0 0 45%;
}
.col-sm-2 {
    max-width: 10%;
    flex: 0 0 10%;
    padding-left: 0px;
    padding-right: 0px;
}
.btn-date {
    background-color: #80808073;
    color: #fff;
}
</style>
