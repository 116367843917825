var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CustomBreadcrumb',{attrs:{"title":'BÁO CÁO',"content":'BÁO CÁO ĐƠN VỊ'}}),_c('div',{staticClass:"layout-table"},[_c('div',[_c('nav',[_c('div',{staticClass:"nav nav-tabs"},_vm._l((_vm.dataBaoCaoChoSoTable),function(tab,index){return _c('a',{key:index,class:{
                            'nav-item': true,
                            'nav-link': true,
                            active: _vm.activeTab === index,
                            'custom-a': _vm.activeTab !== index
                        },on:{"click":function($event){return _vm.toggleTab(index)}}},[_vm._v(" "+_vm._s(tab.category)+" ")])}),0)])]),_c('div',{staticClass:"report-content"},[_c('table',{staticClass:"table table-bordered table-hover centered-table"},[_vm._m(0),_c('tbody',_vm._l((_vm.dataBaoCaoChoSoTable[
                            _vm.activeTab
                        ]?.children),function(data,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-tbody text-center"},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('td',{staticClass:"text-tbody text-center"},[_vm._v(" "+_vm._s(data.maBaoCao)+" ")]),_c('td',{staticClass:"text-tbody"},[_vm._v(_vm._s(data.tenBaoCao))]),_c('td',{staticClass:"text-tbody text-center"},[_c('CustomButton',{staticClass:"btn-default btn-view",attrs:{"label":"Xem","size":"small"},on:{"click":function($event){return _vm.openModalView(
                                        data.collectionName,
                                        index,
                                        data
                                    )}}})],1)])}),0)])]),_c('el-dialog',{attrs:{"visible":_vm.viewModalVisible,"width":"70%"},on:{"update:visible":function($event){_vm.viewModalVisible=$event},"close":_vm.closeModalView}},[_c('span',{staticClass:"custom-dialog-title",attrs:{"slot":"title"},slot:"title"},[(
                        _vm.dataBaoCaoChoSoTable[_vm.activeTab] &&
                        _vm.dataBaoCaoChoSoTable[_vm.activeTab].category
                    )?_c('span',[_vm._v(" "+_vm._s(_vm.dataBaoCaoChoSoTable[_vm.activeTab].category)+" - "+_vm._s(_vm.reportName)+" "),_c('hr')]):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4",staticStyle:{"margin-top":"30px"}},[_c('label',[_vm._v(" Đơn vị quản lý "),(this.authUser.role === 2)?_c('span',{staticClass:"span"},[_vm._v(" * ")]):_vm._e()]),_c('div',[_c('ESelect',{staticStyle:{"width":"100%"},attrs:{"reset":_vm.resetESelectUnitEducation,"no-match-text":"Không tìm thấy bản ghi nào","no-data-text":"danh sách lựa chọn trống","clearable":true,"disabled":this.authUser.role === 4 ||
                                this.authUser.role === 5,"data":this.dataChonDonVi_Store,"placeholder":'Chọn đơn vị',"filterable":true,"multiple":true,"collapse-tags":true,"fields":['tenDonVi', 'maDonVi'],"value":_vm.selectedValue.selectedValueUnitEducation},on:{"reset-completed":_vm.handleResetUnitEducationCompleted,"change":function($event){return _vm.handleESelectChange(
                                    'ESelectUnitEducation',
                                    ...$event
                                )}}})],1)]),_c('div',{staticClass:"col-4",staticStyle:{"margin-top":"30px"}},[_c('label',[_vm._v("Trường học")]),_c('div',[_c('ESelect',{staticStyle:{"width":"100%"},attrs:{"reset":_vm.resetESelectSchool,"no-match-text":"Không tìm thấy bản ghi nào","no-data-text":"danh sách lựa chọn trống","clearable":true,"disabled":this.authUser.role === 5,"data":_vm.getDataESelect.ESelectSchool,"placeholder":'Chọn trường học',"filterable":true,"multiple":true,"collapse-tags":true,"fields":['tenTruongHoc', 'maTruongHoc'],"value":_vm.selectedValue.selectedValueSchool},on:{"reset-completed":_vm.handleResetSchoolCompleted,"change":function($event){return _vm.handleESelectChange(
                                    'ESelectSchool_MockData',
                                    ...$event
                                )}}})],1)]),_c('div',{staticClass:"col-4 mt-4"},[_c('label',[_vm._v("Năm học "),_c('span',{staticClass:"span"},[_vm._v("*")])]),_c('div',[_c('ESelectYear',{attrs:{"placeholder":"Chọn năm","size":"small","no-data-text":'Không có bản ghi nào',"no-match-text":'Không tìm thấy bản ghi nào',"width":'100%'},model:{value:(_vm.selectedValue.selectedValueSchoolYear),callback:function ($$v) {_vm.$set(_vm.selectedValue, "selectedValueSchoolYear", $$v)},expression:"selectedValue.selectedValueSchoolYear"}})],1)]),(
                        [
                            'BcDanhHieuHocSinh',
                            'BcDiemTrungBinhMon',
                            'BcTinhHinhHanhKiemCuaHocSinh',
                            'BcSuPhatTrienCuaTre',
                            'BcTinhHinhHocLucCuaHocSinh',
                            'BcHocLucHanhKiemTheoDiaPhuong',
                            'BcSoHocSinhLenLopLuuBanThiLai',
                            'BcPhanLoaiDiemSoTheoMonHocKhoiLop',
                            'BcSuPhatTrienCuaTre'
                        ].includes(_vm.collectionNameInViewModal)
                    )?_c('div',{staticClass:"col-4 mt-4"},[_c('label',[_vm._v("Học kỳ"),_c('span',{staticClass:"span"},[_vm._v("*")])]),_c('div',[_c('ESelect',{staticStyle:{"width":"100%"},attrs:{"reset":_vm.resetESelectSemester,"no-match-text":"Không tìm thấy bản ghi nào","no-data-text":"danh sách lựa chọn trống","clearable":true,"disabled":false,"data":_vm.getDataESelect.ESelectSemester,"placeholder":'Chọn năm ',"filterable":true,"multiple":false,"collapse-tags":true,"fields":['tenHocKy', 'value']},on:{"reset-completed":_vm.handleResetSemesterCompleted},model:{value:(_vm.selectedValue.selectedValueSemester),callback:function ($$v) {_vm.$set(_vm.selectedValue, "selectedValueSemester", $$v)},expression:"selectedValue.selectedValueSemester"}})],1)]):_vm._e()]),_c('div',{staticClass:"layout-btn"},[_c('div',{staticClass:"padding"},[_c('CustomButton',{staticClass:"btn-view-report",attrs:{"label":"Xem báo cáo","size":"small"},on:{"click":function($event){return _vm.handleModalView()}}})],1),_c('div',{staticClass:"padding"},[_c('CustomButton',{staticClass:"btn-download",attrs:{"label":"Tải xuống","size":"small"},on:{"click":function($event){return _vm.handleDownloadXlsxFile()}}})],1)]),_c('div',{staticClass:"layout-btn_close"},[_c('CustomButton',{staticClass:"btn-close-modal",attrs:{"size":"small","label":"Đóng"},on:{"click":_vm.closeModalView}})],1)])],1),_c('ViewExcel',{attrs:{"titleViewExcel":'File đã nộp',"fileViewExcel":_vm.fileViewExcel,"closeModalViewExcel":_vm.closeModalViewExcel,"viewExcelModalVisible":_vm.viewExcelModalVisible}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"text-thead text-center"},[_vm._v("STT")]),_c('th',{staticClass:"text-thead text-center"},[_vm._v("Mã báo cáo")]),_c('th',{staticClass:"text-thead"},[_vm._v("Tên báo cáo")]),_c('th',{staticClass:"text-thead text-center"},[_vm._v("Thao tác")])])])
}]

export { render, staticRenderFns }