<template>
    <div>
        <div ref="chartContainer"></div>
    </div>
</template>

<script>
import ApexCharts from 'apexcharts'

export default {
    props: {
        data_PieChart: {
            type: Array,
            required: true,
            default: () => []
        },
        xaxis_categories: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    mounted() {
        this.renderChart()
    },

    watch: {
        data_PieChart(newData) {
            this.renderChart()
        }
    },

    methods: {
        renderChart() {
            const data = this.data_PieChart

            const total = data.reduce((acc, val) => acc + val, 0)
            const formatTotal = total ? total.toLocaleString() : ''

            const labels =
                this.xaxis_categories.length > 0 ? this.xaxis_categories : []
            const options = {
                series: data,
                chart: {
                    type: 'donut',
                    height: 300
                },
                labels: labels,
                dataLabels: {
                    enabled: true,
                    formatter(val, opts) {
                        const name = opts.w.globals.labels[opts.seriesIndex]
                        if (labels.includes(name)) {
                            return [name, val.toFixed(2) + '%']
                        } else {
                            return ''
                        }
                    }
                },

                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        startAngle: 0,
                        endAngle: 360,

                        offsetX: 0,
                        offsetY: 0,
                        customScale: 1,
                        dataLabels: {
                            offset: 0,
                            minAngleToShowLabel: 10
                        },
                        donut: {
                            size: '45%',
                            background: 'transparent',
                            labels: {
                                show: true,

                                total: {
                                    show: true,
                                    showAlways: true,
                                    label: 'Tổng',
                                    fontSize: '16px',
                                    color: '#000',
                                    formatter: function () {
                                        return formatTotal
                                    }
                                }
                            }
                        }
                    }
                },
                colors: this.getColors(),

                legend: {
                    show: this.data_PieChart.every((value) => value === 0)
                        ? false
                        : true,
                    showForNullSeries: true,
                    showForZeroSeries: true
                }
            }

            const chartContainer = this.$refs.chartContainer

            if (chartContainer) {
                if (this.chart) {
                    // Nếu biểu đồ đã tồn tại, hủy render trước khi tạo lại
                    this.chart.destroy()
                }

                this.chart = new ApexCharts(chartContainer, options)
                this.chart.render()
            }
        },
        getColors() {
            const categoryCount = this.xaxis_categories.length
            return this.colors.slice(0, categoryCount)
        }
    },

    data() {
        return {
            colors: [
                '#E14A02',
                '#FFA554',
                '#8DAF88',
                '#FFDFA5',
                '#325434',
                '#7D5510',
                '#FFFF00',
                '#19070B'
            ],
            chart: null // Lưu trữ đối tượng biểu đồ để hủy render khi cần thiết
        }
    }
}
</script>

<style scoped></style>
