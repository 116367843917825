<template>
    <el-dialog
        :visible="doiMatKhauModalVisible"
        @close="closeModalDoiMatKhau"
        width="60%"
    >
        <span slot="title" class="custom-dialog-title">
            Đổi mật khẩu
            <hr />
        </span>
        <div class="content">
            <div class="noti" v-if="noti">
                <marquee>
                    Thông báo: <span>{{ noti }}</span>
                </marquee>
            </div>
            <div class="row mt-3">
                <div class="col-md-12">
                    <label>Mật khẩu hiện tại <span class="span">*</span></label>
                    <div>
                        <el-input
                            placeholder="Nhập mật khẩu hiện tại"
                            v-model="selectedValue.matKhauHienTai"
                            width="100%"
                            size="small"
                            type="Password"
                            show-password
                        ></el-input>
                    </div>
                </div>
                <div class="col-md-12 mt-4">
                    <label>Mật khẩu mới <span class="span">*</span></label>
                    <div>
                        <el-input
                            placeholder="Nhập mật khẩu mới"
                            v-model="selectedValue.matKhauMoi"
                            width="100%"
                            size="small"
                            type="Password"
                            show-password
                        ></el-input>
                    </div>
                </div>
                <div class="col-md-12 mt-4">
                    <label
                        >Nhập lại mật khẩu mới <span class="span">*</span></label
                    >
                    <div>
                        <el-input
                            placeholder="Nhập lại mật khẩu mới"
                            v-model="selectedValue.nhapLaiMatKhauMoi"
                            width="100%"
                            size="small"
                            type="Password"
                            show-password
                        ></el-input>
                    </div>
                </div>
            </div>
        </div>

        <div class="layout-btn">
            <div>
                <CustomButton
                    label="Đóng"
                    size="small"
                    type="info"
                    @click="closeModalDoiMatKhau"
                />
            </div>
            <div style="margin-left: 10px">
                <CustomButton
                    label="Lưu lại"
                    size="small"
                    type="danger"
                    @click="handleDoiMatKhau"
                />
            </div>
        </div>
    </el-dialog>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'

import { mapState } from 'vuex'
import sendRequest from '@/services'

import Api from '@/constants/Api'
export default {
    props: {
        doiMatKhauModalVisible: {
            type: Boolean,
            required: true
        },
        closeModalDoiMatKhau: {
            type: Function,
            required: true
        },
        noti: {
            type: String,
            default: ''
        },
        handleLogout: Function
    },

    components: { CustomButton },
    data() {
        return {
            selectedValue: {
                matKhauHienTai: '',
                matKhauMoi: '',
                nhapLaiMatKhauMoi: ''
            }
        }
    },

    methods: {
        handleDoiMatKhau() {
            const fields = [
                {
                    value: this.selectedValue.matKhauHienTai,
                    name: 'Mật khẩu hiện tại'
                },
                { value: this.selectedValue.matKhauMoi, name: 'Mật khẩu mới' },
                {
                    value: this.selectedValue.nhapLaiMatKhauMoi,
                    name: 'Nhập lại mật khẩu mới'
                }
            ]

            for (const field of fields) {
                if (!field.value) {
                    this.$message({
                        message: `Vui lòng nhập ${field.name}`,
                        type: 'error'
                    })
                    return
                }
            }
            if (
                this.selectedValue.matKhauMoi !==
                this.selectedValue.nhapLaiMatKhauMoi
            ) {
                this.$message({
                    message: 'Vui lòng nhập lại đúng mật khẩu.',
                    type: 'error'
                })
                return
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            this.$confirm('Xác nhận thay đổi mật khẩu ?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const request_Header = {
                        token: this.authToken
                    }
                    const request_Data = {
                        password: this.selectedValue.matKhauMoi,
                        username: this.authUser.username,
                        oldPassword: this.selectedValue.matKhauHienTai
                    }
                    const response = await sendRequest(
                        Api.user.doiMatKhau,
                        request_Data,

                        request_Header
                    )
                    if (response.rc == 0) {
                        this.$message({
                            type: 'success',
                            message: 'Thay đổi mật khẩu thành công'
                        })
                        loading.close()
                        this.closeModalDoiMatKhau()
                        this.$emit('handleLogout')
                    }
                })
                .catch((error) => {
                    if (error.response?.data.rd) {
                        this.$message({
                            type: 'error',
                            message: error.response?.data.rd
                        })
                    }
                    loading.close()
                })
        }
    },

    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped>
hr {
    border: 1px solid green;
}
.content {
    background: #fff;
    padding: 0px 10px 0px 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}
.content .noti {
    color: blue;
    font-weight: 600;
    font-size: 14px;
}
.content .noti span {
    color: red;
}
.content label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px;
}
.content .span {
    color: red;
}
.content .title {
    margin-bottom: 5px;
    font-size: 18px;
    color: #212529;
}
.layout-btn {
    padding: 20px 10px 5px 10px;
    justify-content: flex-end;
    display: flex;
    background: #fff;
}
::v-deep .el-dialog__body {
    padding: 0px 20px 30px 20px;
}
</style>
