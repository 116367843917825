<template>
    <div>
        <el-dialog
            :visible="themMoiDiemTruongModalVisible"
            @close="closeModal"
            width="50%"
        >
            <div slot="title" class="header-modal">Thêm mới điểm trường</div>
            <hr />

            <div class="row dflex align-items-center mt-4">
                <div class="col-sm-4 color-black">
                    Tên điểm trường <span class="error">*</span>
                </div>
                <div class="col-sm-8">
                    <CustomInput
                        v-model="tenDiemTruong"
                        placeholder="Nhập ..."
                        :disabled="false"
                        :clearable="false"
                        size="small"
                    >
                    </CustomInput>
                    <span v-if="err_tenDiemTruong.length > 0" class="error">{{
                        err_tenDiemTruong
                    }}</span>
                </div>
            </div>

            <div class="row dflex align-items-center mt-4">
                <div class="col-sm-4 color-black">Địa chỉ</div>
                <div class="col-sm-8">
                    <CustomInput
                        v-model="diaChi"
                        placeholder="Nhập ..."
                        :disabled="false"
                        :clearable="false"
                        size="small"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="row dflex align-items-center mt-4">
                <div class="col-sm-4 color-black">
                    Khoảng cách tới trường chính (km)
                </div>
                <div class="col-sm-8">
                    <CustomInput
                        v-model="khoangCach"
                        placeholder="Nhập ..."
                        :disabled="false"
                        :clearable="false"
                        size="small"
                        type="number"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="row dflex align-items-center mt-4">
                <div class="col-sm-4 color-black">Điện thoại</div>
                <div class="col-sm-8">
                    <CustomInput
                        v-model="sdt"
                        placeholder="Nhập ..."
                        :disabled="false"
                        :clearable="false"
                        size="small"
                        type="number"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="row dflex align-items-center mt-4">
                <div class="col-sm-4 color-black">Phương tiện đi lại</div>
                <div class="col-sm-8">
                    <CustomInput
                        v-model="phuongTien"
                        placeholder="Nhập ..."
                        :disabled="false"
                        :clearable="false"
                        size="small"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="row dflex align-items-center mt-4">
                <div class="col-sm-4 color-black">Diện tích (m2)</div>
                <div class="col-sm-8">
                    <CustomInput
                        v-model="dienTich"
                        placeholder="Nhập ..."
                        :disabled="false"
                        :clearable="false"
                        size="small"
                        type="number"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="row dflex align-items-center mt-4">
                <div class="col-sm-4 color-black">Tỉnh thành</div>
                <div class="col-sm-8">
                    <ESelect
                        :reset="resetTinh"
                        @reset-completed="handleResetTinh"
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :data="list_tinh"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['tenTinhThanh', 'maTinhThanh']"
                        v-model="tinh"
                        @input="handleChangeTinhThanh"
                    />
                </div>
            </div>
            <div class="row dflex align-items-center mt-4">
                <div class="col-sm-4 color-black">Quận huyện</div>
                <div class="col-sm-8">
                    <ESelect
                        :reset="resetHuyen"
                        @reset-completed="handleResetHuyen"
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :data="list_huyen"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="huyen"
                    />
                </div>
            </div>
            <div class="row dflex align-items-center mt-4">
                <div class="col-sm-4 color-black">Mô tả</div>
                <div class="col-sm-8">
                    <CustomInput
                        v-model="moTa"
                        placeholder="Nhập ..."
                        :disabled="false"
                        :clearable="false"
                        size="small"
                        type="textarea"
                        :autosize="{ minRows: 3, maxRows: 6 }"
                    >
                    </CustomInput>
                </div>
            </div>

            <div class="layout-btn">
                <CustomButton
                    label="Đóng"
                    size="small"
                    @click="closeModal"
                    class="btn-close btn-default"
                />
                <CustomButton
                    label="Lưu"
                    size="small"
                    class="btn-import btn-add"
                    @click="handleCreate"
                />
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import CustomButton from '@/components/CustomButton.vue'
import Api from '@/constants/Api'
import sendRequest from '@/services'
import CustomInput from '@/components/CustomInput.vue'
import { validateField } from '@/utils'
import { currentYear } from '@/utils'
import ESelect from '@/components/ESelect.vue'
export default {
    props: {
        themMoiDiemTruongModalVisible: {
            type: Boolean,
            required: true
        },

        closeModalThemMoiDiemTruong: {
            type: Function,
            required: true
        },
        selectedValueSchoolYear: {
            type: String
        },
        getDataDiemTruong: Function
    },

    components: { CustomButton, CustomInput, ESelect },
    data() {
        return {
            list_tinh: [],
            list_huyen: [],
            resetTinh: false,
            resetHuyen: false,
            maTruong: '',
            maDiemTruong: '',
            tenDiemTruong: '',
            namHoc: '',
            diaChi: '',
            khoangCach: '',
            dienTich: '',
            sdt: '',
            phuongTien: '',
            tinh: '',
            huyen: '',

            moTa: '',
            err_tenDiemTruong: ''
        }
    },
    mounted() {
        this.getDataTinhThanh()
    },
    watch: {
        tinh: {
            handler(newVal) {
                if (newVal === undefined || newVal === '') {
                    this.resetHuyen = true
                    this.huyen = ' '
                    this.list_huyen = ''
                }
            },
            deep: true
        }
    },
    methods: {
        handleResetHuyen() {
            this.resetHuyen = false
        },
        handleResetTinh() {
            this.resetTinh = false
        },
        async getDataTinhThanh() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    start: 0,
                    limit: 1000
                }
                const response = await sendRequest(
                    Api.superAdmin.tinhThanh.danhSach,
                    null,
                    request_Header,
                    request_Params
                )

                this.list_tinh = response.rows.map((item) => ({
                    ...item,
                    tenTinhThanh: `${item.tenTinhThanh} - [${item.maTinhThanh}]`
                }))
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },
        async handleChangeTinhThanh() {
            const maTinhThanh = this.tinh?.value

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    maTinhThanh: maTinhThanh,
                    check: true,
                    start: 0,
                    limit: 9999
                }
                const response = await sendRequest(
                    Api.internal_api.dm_DonVi,
                    null,
                    request_Header,
                    request_Params
                )
                if (response.code === 200) {
                    loading.close()
                    this.list_huyen = response.rows
                }
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },
        closeModal() {
            this.closeModalThemMoiDiemTruong()
            this.err_tenDiemTruong = ''
            this.tenDiemTruong = ''
            this.diaChi = ''
            this.khoangCach = ''
            this.dienTich = ''
            this.sdt = ''
            this.phuongTien = ''
            this.resetHuyen = true
            this.huyen = ''
            this.tinh = ''
            this.resetTinh = true

            this.moTa = ''
        },
        async handleCreate() {
            try {
                const fieldsToValidate = [
                    {
                        name: 'err_tenDiemTruong',
                        value: this.tenDiemTruong,
                        errorField: 'err_tenDiemTruong',
                        errorMessage: 'Vui lòng nhập tên điểm trường'
                    }
                ]
                let isValid = true

                for (const field of fieldsToValidate) {
                    isValid = validateField(this, field, isValid)
                }
                if (isValid) {
                    const tinhThanh = this.tinh?.value
                        ? this.tinh?.value
                        : this.authUser.ma_so
                    const quanHuyen = this.huyen?.value
                        ? this.huyen?.value
                        : this.authUser.ma_phong
                    const request_Data = {
                        maTruongHoc: this.authUser.ma_truong_hoc,
                        namHoc: this.selectedValueSchoolYear || currentYear,
                        tenDiemTruong: this.tenDiemTruong,
                        diaChi: this.diaChi,
                        khoangCach: this.khoangCach,
                        dienTich: this.dienTich,
                        dienThoai: this.sdt,
                        phuongTien: this.phuongTien,
                        tinhThanh: tinhThanh,
                        quanHuyen: quanHuyen,
                        moTa: this.moTa
                    }
                    const request_Header = {
                        token: this.authToken
                    }
                    const response = await sendRequest(
                        Api.traCuu.traCuuTruongHoc.createDiemTruong,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        this.$notify({
                            title: 'Success',
                            message: 'Thêm điểm trường thành công',
                            type: 'success'
                        })

                        this.$emit('getDataDiemTruong')
                        this.closeModal()
                    } else {
                        this.$notify.error({
                            title: 'Error',
                            message: 'Thêm điểm trường thất bại'
                        })
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped>
.layout-btn {
    display: flex;
    justify-content: flex-end;
}
.btn-add {
    background-color: var(--light-green);
    color: var(--btn-text-color);
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.error {
    color: red;
}
.color-black {
    color: black;
    font-weight: 600;
}
.header {
    display: flex;
    justify-content: center;
}
.header-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
}
hr {
    border: 1px solid #8080807d;
    margin-bottom: 30px;
}
::v-deep .el-dialog__header {
    padding: 20px 20px 0px !important;
}
::v-deep .el-dialog__body {
    padding: 0px 20px 20px 20px !important;
}
</style>
