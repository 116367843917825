<template>
    <div>
        <el-dialog
            :show-close="false"
            :visible="importModalVisible"
            @close="closeModalImport"
            width="60%"
        >
            <span slot="title">
                Nhập thông tin quận huyện từ excel
                <hr />
            </span>

            <div class="modal-body clearfix">
                <div class="row">
                    <div class="col-lg-3">
                        <label class="pull-right">Chọn File</label>
                    </div>
                    <div class="col-lg-9">
                        <div
                            style="display: inline-flex; position: relative"
                            role="alert"
                        >
                            <div
                                v-if="selectedFile"
                                style="position: absolute; top: 0; right: 0"
                            >
                                <a
                                    href="#"
                                    title="Xóa file đã chọn"
                                    class="xoafile"
                                    @click.prevent="clearFile()"
                                    >x</a
                                >
                            </div>
                            <label class="btn btn-default btn-file">
                                <i class="el-icon-upload"></i>
                                <span> Chọn file</span>

                                <input
                                    ref="file_upload"
                                    multiple="false"
                                    name="file"
                                    style="display: none"
                                    type="file"
                                    @change="handleFileChange"
                                    aria-invalid="false"
                                />
                            </label>
                            <label
                                v-if="selectedFile"
                                class="btn btn-default btn-file"
                            >
                                <span>
                                    <CustomButton
                                        :label="`File đã chọn: ${selectedFile.name}`"
                                        size="small"
                                    />
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-lg-3">
                        <label class="pull-right">Vui lòng chọn file mẫu</label>
                    </div>
                    <div class="col-lg-9" style="">
                        <label>
                            <a tittle="download file template">
                                <b class="template">
                                    <i @click="DownLoadFileTemplate"
                                        >File template mẫu.</i
                                    >
                                </b>
                            </a>
                        </label>
                        <br />
                        <span>
                            <b>Ghi chú:</b>
                            <i
                                ><span>Vui lòng lựa chọn tệp dạng *.xlsx</span></i
                            ></span
                        >
                    </div>
                </div>
            </div>
            <div class="layout-btn-import">
                <CustomButton
                    label="Đóng"
                    size="small"
                    @click="closeModalImport"
                    class="btn-close btn-default"
                />
                <CustomButton
                    label="Tải lên"
                    size="small"
                    @click="handleImport"
                    class="btn-import btn-default"
                />
            </div>
        </el-dialog>
        <el-dialog
            :visible="fileErrorModalVisible"
            @close="closeModalFileError"
            width="50%"
            :show-close="false"
        >
            <span slot="title">
                Có lỗi trong quá trình import file
                <hr />
            </span>
            <div class="title-error">
                File import có một số thông tin lỗi. Bạn hãy khắc phục để thực
                hiện import.
            </div>
            <div class="btn-error">
                <CustomButton
                    label="Hủy"
                    size="small"
                    @click="closeModalFileError"
                    type="info"
                />
                <CustomButton
                    label="Tải xuống"
                    size="small"
                    @click="DownLoadFileError"
                    type="primary"
                />
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import CustomButton from '@/components/CustomButton.vue'
import Api from '@/constants/Api'
import sendRequest from '@/services'
import axios from 'axios'

export default {
    props: {
        importModalVisible: {
            type: Boolean,
            required: true
        },

        closeModalImport: {
            type: Function,
            required: true
        }
    },

    components: { CustomButton },
    data() {
        return {
            selectedFile: null,
            fileErrorModalVisible: false,
            fileError: null
        }
    },

    methods: {
        closeModalFileError() {
            this.fileErrorModalVisible = false
            this.closeModalImport()
        },
        DownLoadFileTemplate() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            this.$confirm('Xác nhận tải xuống?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })

                .then(async () => {
                    const domain = process.env.VUE_APP_BACKEND_BASE_URL
                    const response = await axios.get(
                        `${domain}/internal-api/dmQuanHuyen/downloadTemplate`,

                        {
                            headers: {
                                token: this.authToken,
                                'Content-Type': 'application/json'
                            },
                            responseType: 'blob' // Set responseType là 'blob'
                        }
                    )

                    const blob = new Blob([response.data])
                    const blobUrl = window.URL.createObjectURL(blob)

                    const downloadLink = document.createElement('a')
                    downloadLink.href = blobUrl
                    downloadLink.download = 'Template_Quan_Huyen.xlsx'
                    downloadLink.style.display = 'none'

                    document.body.appendChild(downloadLink)
                    downloadLink.click()

                    window.URL.revokeObjectURL(blobUrl)
                    loading.close()
                })
                .catch((error) => {
                    loading.close()
                    if (error.response?.data.rd) {
                        this.$message({
                            type: 'error',
                            message: error.response?.data.rd
                        })
                    }
                })
        },
        handleFileChange(event) {
            const file = event.target.files[0]
            if (file && file.name.endsWith('.xlsx')) {
                this.selectedFile = file
            } else {
                this.clearFile()

                this.selectedFile = null

                this.$message({
                    type: 'error',
                    message:
                        'Vui lòng chọn file excel. Vui lòng chọn tệp dạng .xlsx file.'
                })
            }
        },
        clearFile() {
            this.selectedFile = null

            const fileInput = this.$refs.fileInput
            if (fileInput) {
                fileInput.value = ''
            }
        },
        async handleImport() {
            if (this.selectedFile === null) {
                this.$message({
                    type: 'error',
                    message: 'Vui lòng thêm file import'
                })
                return
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })

            const request_Header = {
                'Content-Type': 'multipart/form-data',
                token: this.authToken
            }

            this.$confirm('Xác nhận Import File?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const formData = new FormData()
                    formData.append('file', this.selectedFile)

                    const response = await sendRequest(
                        Api.superAdmin.quanHuyen.importExcel,
                        formData,
                        request_Header,
                        null
                    )
                    if (response.code == 0) {
                        loading.close()
                        this.$message({
                            type: 'success',
                            message: 'Import thành công'
                        })
                        this.closeModalImport()
                    }
                    if (response.code == 1) {
                        loading.close()
                        this.$message({
                            type: 'success',
                            message: response.message
                        })
                        this.fileErrorModalVisible = true
                        this.fileError = response.urlLoi
                    }
                    if (response.code == 2) {
                        loading.close()
                        this.$message({
                            type: 'error',
                            message: response.message
                        })
                    }
                })
                .catch((error) => {
                    loading.close()
                    if (error.response?.data.rd) {
                        this.$message({
                            type: 'error',
                            message: error.response?.data.rd
                        })
                    }
                })
        },
        async DownLoadFileError() {
            const fileUrl = this.fileError
            window.open(
                `${process.env.VUE_APP_BACKEND_BASE_URL}${fileUrl}`,
                '_blank'
            )
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped>
.title-file {
    font-size: 14px;
    color: black;
}
.layout-btn-file {
    padding: 5px 10px 5px 10px;
    cursor: pointer;
    background-color: #87cefa;
    border-radius: 4px;
}
.xoafile {
    color: black;
    border: 1px solid black;
    padding: 0 4px;
    margin: 0;
    border-radius: 50%;
    background: snow;
    font-weight: 500;
}
.layout-btn-import {
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
}
.title-error {
    display: flex;
    justify-content: center;
    color: red;
    font-size: 15px;
}
.btn-error {
    display: flex;
    justify-content: flex-end;
    margin-top: 45px;
}
.template {
    color: var(--teal);
}
.template :hover {
    text-decoration: underline;
    cursor: pointer;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.btn-import {
    background-color: var(--light-green);
}
.btn-close {
    background-color: var(--orange);
}
.btn-file {
    background-color: var(--teal);
}
</style>
