<template>
    <el-dialog
        :visible="lstQuaTrinhDaoTaoBoiDuongVisible"
        @close="closeModallstQuaTrinhDaoTaoBoiDuong"
        width="90%"
        class="custom-dialog"
    >
        <span slot="title" class="custom-dialog-title"
            >QUÁ TRÌNH ĐÀO TẠO BỒI DƯỠNG</span
        >
        <div class="layout-table">
            <div class="title">Quá trình bồi dưỡng</div>
            <table class="table table-bordered centered-table mt-2">
                <thead>
                    <tr>
                        <th class="text-thead">STT</th>
                        <th class="text-thead" style="width: 250px">
                            Tên trường<span class="span">*</span>
                        </th>
                        <th class="text-thead">
                            Chuyên ngành đào tạo, bồi dưỡng<span class="span"
                                >*</span
                            >
                        </th>
                        <th class="text-thead">
                            Từ ngày - đến ngày<span class="span">*</span>
                        </th>
                        <th class="text-thead">
                            Hình thức<span class="span">*</span>
                        </th>
                        <th class="text-thead">
                            Văn bằng, chứng chỉ<span class="span">*</span>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-if="lstQuaTrinhDaoTaoBoiDuong.length === 0">
                        <td colspan="6">Danh sách trống</td>
                    </tr>
                    <tr v-for="(item, i) in lstQuaTrinhDaoTaoBoiDuong" :key="i">
                        <td>
                            {{ i + 1 }}
                        </td>
                        <td>
                            <el-input
                                :disabled="true"
                                placeholder="Please input"
                                v-model="item.TenTruong"
                                size="small"
                            >
                            </el-input>
                        </td>
                        <td>
                            <el-select
                                :disabled="true"
                                size="small"
                                style="width: 100%"
                                v-model="item.MaNhomChuyenNganh"
                                filterable
                                collapse-tags
                                placeholder="Chọn"
                                no-data-text="Không có dữ liệu"
                                no-match-text="Không có dữ liệu phù hợp"
                            >
                                <el-option
                                    v-for="item in dataChonChuyenMon_Store"
                                    :key="item.mappingCode"
                                    :label="item.constantTitle"
                                    :value="item.mappingCode"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <td>
                            <div style="display: flex">
                                <el-date-picker
                                    style="width: 90%"
                                    :disabled="true"
                                    v-model="item.TuThangNam"
                                    type="date"
                                    placeholder="Pick a day"
                                    format="dd-MM-yyyy"
                                    size="small"
                                >
                                </el-date-picker>
                                <div class="input-group-addon">đến</div>
                                <el-date-picker
                                    style="width: 90%"
                                    :disabled="true"
                                    v-model="item.DenThangnam"
                                    type="date"
                                    placeholder="Pick a day"
                                    format="dd-MM-yyyy"
                                    size="small"
                                >
                                </el-date-picker>
                            </div>
                        </td>
                        <td>
                            <el-select
                                :disabled="true"
                                size="small"
                                style="width: 100%"
                                v-model="item.MaHinhThucDaoTao"
                                filterable
                                collapse-tags
                                placeholder="Chọn"
                                no-data-text="Không có dữ liệu"
                                no-match-text="Không có dữ liệu phù hợp"
                            >
                                <el-option
                                    v-for="item in dataChonHinhthucdaotao_Store"
                                    :key="item.mappingCode"
                                    :label="item.constantTitle"
                                    :value="item.mappingCode"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <td>
                            <el-select
                                :disabled="true"
                                size="small"
                                style="width: 100%"
                                v-model="item.MaVbChungChi"
                                filterable
                                collapse-tags
                                placeholder="Chọn"
                                no-data-text="Không có dữ liệu"
                                no-match-text="Không có dữ liệu phù hợp"
                            >
                                <el-option
                                    v-for="item in dataChonVanbangchungchi_Store"
                                    :key="item.mappingCode"
                                    :label="item.constantTitle"
                                    :value="item.mappingCode"
                                >
                                </el-option>
                            </el-select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="hr">
            <hr />
        </div>
        <div class="layout-table" style="margin-top: 30px">
            <div class="title">Quá trình bồi dưỡng nâng cao năng lực sư phạm</div>
            <table class="table table-bordered centered-table mt-2">
                <thead>
                    <tr>
                        <th class="text-thead">STT</th>
                        <th class="text-thead">Khóa đào tạo bồi dưỡng</th>
                        <th class="text-thead">Đơn vị bồi dưỡng</th>
                        <th class="text-thead">Đơn vị tổ chức</th>
                        <th class="text-thead">Thời lượng bồi dưỡng</th>
                        <th class="text-thead">Loại hình bồi dưỡng</th>
                        <th class="text-thead">Hình thức</th>
                        <th class="text-thead">Kết quả đạt được</th>
                    </tr>
                </thead>

                <tbody>
                    <tr
                        v-if="
                            dataQuaTrinhBoiDuongNangCaoNangLucSuPham.length === 0
                        "
                    >
                        <td colspan="8">Danh sách trống</td>
                    </tr>
                    <tr
                        v-for="(
                            item, index
                        ) in dataQuaTrinhBoiDuongNangCaoNangLucSuPham"
                        :key="index"
                    >
                        <td>{{ index + 1 }}</td>
                        <td>
                            <el-select
                                :disabled="true"
                                size="small"
                                style="width: 100%"
                                v-model="item.MaKhoaDaoTao"
                                filterable
                                collapse-tags
                                placeholder="Chọn"
                                no-data-text="Không có dữ liệu"
                                no-match-text="Không có dữ liệu phù hợp"
                            >
                                <el-option
                                    v-for="item in dataChonKhoadaotaosupham_Store"
                                    :key="item.mappingCode"
                                    :label="item.constantTitle"
                                    :value="item.mappingCode"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <td>
                            <el-select
                                :disabled="true"
                                size="small"
                                style="width: 100%"
                                v-model="item.MaDonViBoiDuong"
                                filterable
                                collapse-tags
                                placeholder="Chọn"
                                no-data-text="Không có dữ liệu"
                                no-match-text="Không có dữ liệu phù hợp"
                            >
                                <el-option
                                    v-for="item in dataChonDonViBoiDuong_Store"
                                    :key="item.mappingCode"
                                    :label="item.constantTitle"
                                    :value="item.mappingCode"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <td>
                            <el-select
                                :disabled="true"
                                size="small"
                                style="width: 100%"
                                v-model="item.MaDonViToChuc"
                                filterable
                                collapse-tags
                                placeholder="Chọn"
                                no-data-text="Không có dữ liệu"
                                no-match-text="Không có dữ liệu phù hợp"
                            >
                                <el-option
                                    v-for="item in dataChonDonViTochuc_Store"
                                    :key="item.mappingCode"
                                    :label="item.constantTitle"
                                    :value="item.mappingCode"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <td>
                            <el-select
                                :disabled="true"
                                size="small"
                                style="width: 100%"
                                v-model="item.MaThoiLuongBoiDuong"
                                filterable
                                collapse-tags
                                placeholder="Chọn"
                                no-data-text="Không có dữ liệu"
                                no-match-text="Không có dữ liệu phù hợp"
                            >
                                <el-option
                                    v-for="item in dataChonThoiLuongboiduong_Store"
                                    :key="item.mappingCode"
                                    :label="item.constantTitle"
                                    :value="item.mappingCode"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <td>
                            <el-select
                                :disabled="true"
                                size="small"
                                style="width: 100%"
                                v-model="item.MaLoaiHinhBoiDuong"
                                filterable
                                collapse-tags
                                placeholder="Chọn"
                                no-data-text="Không có dữ liệu"
                                no-match-text="Không có dữ liệu phù hợp"
                            >
                                <el-option
                                    v-for="item in dataChonLoaihinhboiduong_Store"
                                    :key="item.mappingCode"
                                    :label="item.constantTitle"
                                    :value="item.mappingCode"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <td>
                            <el-select
                                :disabled="true"
                                size="small"
                                style="width: 100%"
                                v-model="item.MaHinhThuc"
                                filterable
                                collapse-tags
                                placeholder="Chọn"
                                no-data-text="Không có dữ liệu"
                                no-match-text="Không có dữ liệu phù hợp"
                            >
                                <el-option
                                    v-for="item in dataChonHinhthuc_Store"
                                    :key="item.mappingCode"
                                    :label="item.constantTitle"
                                    :value="item.mappingCode"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <td>
                            <el-select
                                :disabled="true"
                                size="small"
                                style="width: 100%"
                                v-model="item.MaKetQua"
                                filterable
                                collapse-tags
                                placeholder="Chọn"
                                no-data-text="Không có dữ liệu"
                                no-match-text="Không có dữ liệu phù hợp"
                            >
                                <el-option
                                    v-for="item in dataChonKetqua_Store"
                                    :key="item.mappingCode"
                                    :label="item.constantTitle"
                                    :value="item.mappingCode"
                                >
                                </el-option>
                            </el-select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="hr">
            <hr />
        </div>
        <div class="layout-table" style="margin-top: 30px">
            <div class="title">
                Quá trình bồi dưỡng nâng cao năng lực ngoại ngữ
            </div>
            <table class="table table-bordered centered-table mt-2">
                <thead>
                    <tr>
                        <th class="text-thead">STT</th>
                        <th class="text-thead">Khóa đào tạo bồi dưỡng</th>
                        <th class="text-thead">Đơn vị bồi dưỡng</th>
                        <th class="text-thead">Đơn vị tổ chức</th>
                        <th class="text-thead">Thời lượng bồi dưỡng</th>
                        <th class="text-thead">Loại hình bồi dưỡng</th>
                        <th class="text-thead">Hình thức</th>
                        <th class="text-thead">Kết quả đạt được</th>
                        <th class="text-thead">Đơn vị tổ chức thi</th>
                    </tr>
                </thead>

                <tbody>
                    <tr
                        v-if="
                            dataQuaTrinhBoiDuongNangCaoNangLucNgoaiNgu.length ===
                            0
                        "
                    >
                        <td colspan="9">Danh sách trống</td>
                    </tr>
                    <tr
                        v-for="(
                            item, index
                        ) in dataQuaTrinhBoiDuongNangCaoNangLucNgoaiNgu"
                        :key="index"
                    >
                        <td>{{ index + 1 }}</td>
                        <td>
                            <el-select
                                :disabled="true"
                                size="small"
                                style="width: 100%"
                                v-model="item.MaKhoaDaoTao"
                                filterable
                                collapse-tags
                                placeholder="Chọn"
                                no-data-text="Không có dữ liệu"
                                no-match-text="Không có dữ liệu phù hợp"
                            >
                                <el-option
                                    v-for="item in dataChonKhoadaotaosupham_Store"
                                    :key="item.mappingCode"
                                    :label="item.constantTitle"
                                    :value="item.mappingCode"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <td>
                            <el-select
                                :disabled="true"
                                size="small"
                                style="width: 100%"
                                v-model="item.MaDonViBoiDuong"
                                filterable
                                collapse-tags
                                placeholder="Chọn"
                                no-data-text="Không có dữ liệu"
                                no-match-text="Không có dữ liệu phù hợp"
                            >
                                <el-option
                                    v-for="item in dataChonDonViBoiDuong_Store"
                                    :key="item.mappingCode"
                                    :label="item.constantTitle"
                                    :value="item.mappingCode"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <td>
                            <el-select
                                :disabled="true"
                                size="small"
                                style="width: 100%"
                                v-model="item.MaDonViToChuc"
                                filterable
                                collapse-tags
                                placeholder="Chọn"
                                no-data-text="Không có dữ liệu"
                                no-match-text="Không có dữ liệu phù hợp"
                            >
                                <el-option
                                    v-for="item in dataChonDonViTochuc_Store"
                                    :key="item.mappingCode"
                                    :label="item.constantTitle"
                                    :value="item.mappingCode"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <td>
                            <el-select
                                :disabled="true"
                                size="small"
                                style="width: 100%"
                                v-model="item.MaThoiLuongBoiDuong"
                                filterable
                                collapse-tags
                                placeholder="Chọn"
                                no-data-text="Không có dữ liệu"
                                no-match-text="Không có dữ liệu phù hợp"
                            >
                                <el-option
                                    v-for="item in dataChonThoiLuongboiduong_Store"
                                    :key="item.mappingCode"
                                    :label="item.constantTitle"
                                    :value="item.mappingCode"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <td>
                            <el-select
                                :disabled="true"
                                size="small"
                                style="width: 100%"
                                v-model="item.MaLoaiHinhBoiDuong"
                                filterable
                                collapse-tags
                                placeholder="Chọn"
                                no-data-text="Không có dữ liệu"
                                no-match-text="Không có dữ liệu phù hợp"
                            >
                                <el-option
                                    v-for="item in dataChonLoaihinhboiduong_Store"
                                    :key="item.mappingCode"
                                    :label="item.constantTitle"
                                    :value="item.mappingCode"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <td>
                            <el-select
                                :disabled="true"
                                size="small"
                                style="width: 100%"
                                v-model="item.MaHinhThuc"
                                filterable
                                collapse-tags
                                placeholder="Chọn"
                                no-data-text="Không có dữ liệu"
                                no-match-text="Không có dữ liệu phù hợp"
                            >
                                <el-option
                                    v-for="item in dataChonHinhthuc_Store"
                                    :key="item.mappingCode"
                                    :label="item.constantTitle"
                                    :value="item.mappingCode"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <td>
                            <el-select
                                :disabled="true"
                                size="small"
                                style="width: 100%"
                                v-model="item.MaKetQua"
                                filterable
                                collapse-tags
                                placeholder="Chọn"
                                no-data-text="Không có dữ liệu"
                                no-match-text="Không có dữ liệu phù hợp"
                            >
                                <el-option
                                    v-for="item in dataChonKetqua_Store"
                                    :key="item.mappingCode"
                                    :label="item.constantTitle"
                                    :value="item.mappingCode"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <td>
                            <el-select
                                :disabled="true"
                                size="small"
                                style="width: 100%"
                                v-model="item.MaDonViToChucThi"
                                filterable
                                collapse-tags
                                placeholder="Chọn"
                                no-data-text="Không có dữ liệu"
                                no-match-text="Không có dữ liệu phù hợp"
                            >
                                <el-option
                                    v-for="item in dataChonDonViBoiDuong_Store"
                                    :key="item.mappingCode"
                                    :label="item.constantTitle"
                                    :value="item.mappingCode"
                                >
                                </el-option>
                            </el-select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div style="display: flex; justify-content: flex-end">
            <CustomButton
                label="Đóng"
                size="small"
                @click="closeModallstQuaTrinhDaoTaoBoiDuong"
                class="btn-default btn-delete"
            />
        </div>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
import CustomButton from '@/components/CustomButton.vue'
import sendRequest from '@/services'
import Api from '@/constants/Api'
export default {
    name: 'ModalLstQuaTrinhLuong',
    props: {
        lstQuaTrinhDaoTaoBoiDuongVisible: {
            type: Boolean,
            required: true
        },

        closeModallstQuaTrinhDaoTaoBoiDuong: {
            type: Function,
            required: true
        },
        lstQuaTrinhDaoTaoBoiDuong: {
            type: Array,
            required: true
        },
        id_modalChiTietCanboGv: {
            type: Number
        }
    },
    components: { CustomButton },

    data() {
        return {
            dataQuaTrinhBoiDuongNangCaoNangLucSuPham: [],
            dataQuaTrinhBoiDuongNangCaoNangLucNgoaiNgu: []
        }
    },
    watch: {
        lstQuaTrinhDaoTaoBoiDuongVisible(newValue) {
            if (newValue === true) {
                this.getQuaTrinhBoiDuongNangCaoNangLucSuPham(
                    this.id_modalChiTietCanboGv
                )
                this.getQuaTrinhBoiDuongNangCaoNangLucNgoaiNgu(
                    this.id_modalChiTietCanboGv
                )
            }
        }
    },
    methods: {
        async getQuaTrinhBoiDuongNangCaoNangLucSuPham(id_modalChiTietCanboGv) {
            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                id: id_modalChiTietCanboGv,
                type: 2
            }
            const response = await sendRequest(
                Api.traCuu.traCuuCanBoGiaoVien.getLstQuaTrinh,
                request_Data,
                request_Header
            )
            this.dataQuaTrinhBoiDuongNangCaoNangLucSuPham = response.rows
        },

        async getQuaTrinhBoiDuongNangCaoNangLucNgoaiNgu(id_modalChiTietCanboGv) {
            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                id: id_modalChiTietCanboGv,
                type: 1
            }
            const response = await sendRequest(
                Api.traCuu.traCuuCanBoGiaoVien.getLstQuaTrinh,
                request_Data,
                request_Header
            )
            this.dataQuaTrinhBoiDuongNangCaoNangLucNgoaiNgu = response.rows
        }
    },
    computed: {
        // GV1000717782
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonChuyenMon_Store() {
            return JSON.parse(localStorage.getItem('data_ChonChuyenMon'))
        },
        dataChonHinhthucdaotao_Store() {
            return JSON.parse(localStorage.getItem('data_ChonHinhthucdaotao'))
        },
        dataChonVanbangchungchi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonVanbangchungchi'))
        },
        dataChonKhoadaotaosupham_Store() {
            return JSON.parse(localStorage.getItem('data_ChonKhoadaotaosupham'))
        },
        dataChonDonViBoiDuong_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonviboiduong'))
        },
        dataChonDonViTochuc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonvitochuc'))
        },
        dataChonThoiLuongboiduong_Store() {
            return JSON.parse(localStorage.getItem('data_ChonThoiluongboiduong'))
        },
        dataChonLoaihinhboiduong_Store() {
            return JSON.parse(localStorage.getItem('data_ChonLoaihinhboiduong'))
        },
        dataChonHinhthuc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonHinhthuc'))
        },
        dataChonKetqua_Store() {
            return JSON.parse(localStorage.getItem('data_ChonKetquasupham'))
        }
    }
}
</script>
<style scoped>
::v-deep .el-input.is-disabled .el-input__inner,
::v-deep .el-textarea.is-disabled .el-textarea__inner,
::v-deep .el-input.is-disabled .el-input__inner {
    background-color: #eee !important;
    color: black !important;
}
::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
    border-color: black !important;
    background-color: #eee !important;
}
.layout-table {
    background: #fff;
    margin: 0 auto;
    overflow-x: auto;
}
.layout-table .span {
    color: red;
}
table thead tr {
    background: rgb(228, 235, 245);
}
.title {
    font-weight: 900;
    font-size: 13px;
}
.table {
    white-space: nowrap;
    overflow-x: auto;
}
.centered-table {
    margin-left: auto;
    margin-right: auto;
}

.centered-table th,
.centered-table td {
    text-align: center;
    vertical-align: middle;
}
.table-bordered {
    background-color: #fff;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}
.table-hover tbody tr:hover {
    background-color: #f5f5f5;
}

.text-thead {
    font-size: 13px;
    text-align: center; /* Canh giữa nội dung trong cột */
    vertical-align: middle; /* Căn giữa theo chiều dọc */
}

.text-tbody {
    font-size: 13px;
}

.input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
}
.btn-close {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
.hr {
    display: flex;
    justify-content: center;
}
hr {
    border: 1px solid red;
    width: 50%;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}

.btn-delete {
    background-color: var(--orange);
}
</style>
