<template>
    <div>
        <el-dialog
            :visible="deleteModalVisible"
            @close="closeModalDelete"
            class="custom-dialog"
            width="70%"
        >
            <span slot="title">
                XÓA DỮ LIỆU
                <hr />
                <div class="row">
                    <div class="col-4">
                        <label>Chọn năm học</label>
                        <div>
                            <el-input
                                :disabled="true"
                                placeholder="Chọn năm"
                                size="small"
                                v-model="year"
                            ></el-input>
                        </div>
                    </div>
                    <div class="col-4">
                        <label>Chọn học kỳ</label>
                        <div>
                            <el-select
                                :disabled="true"
                                size="small"
                                collapse-tags
                                style="width: 100%"
                                placeholder="Chọn"
                                v-model="selectedValue.selectedValueSemester"
                            >
                                <el-option
                                    v-for="item in getDataESelect.ESelectSemester_MockData"
                                    :key="item.value"
                                    :label="item.tenHocKy"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="layout-table mt-4">
                    <table
                        class="table table-bordered table-hover centered-table"
                    >
                        <thead class="sticky-header">
                            <tr>
                                <th class="text-thead">STT</th>
                                <th class="text-thead">Mã trường học</th>
                                <th class="text-thead">Tên trường học</th>
                                <th class="text-thead">Dữ liệu xóa</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="dataModalDelete.length === 0">
                                <tr>
                                    <td colspan="8" class="text-tbody">
                                        Không có bản ghi nào.
                                    </td>
                                </tr>
                            </template>
                            <tr
                                v-for="(item, index) in dataModalDelete"
                                :key="index"
                            >
                                <td class="text-tbody">
                                    {{ index + 1 }}
                                </td>
                                <td class="text-tbody">
                                    {{ item.maTruong }}
                                </td>
                                <td class="text-tbody text-left">
                                    {{ item.tenTruong }}
                                </td>
                                <td class="text-tbody text-left">
                                    {{ getDuLieuXoa(item.buocDays) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </span>
            <div style="margin-top: 70px"></div>
            <hr />
            <div class="layout-table-modal">
                <CustomButton
                    label="Đóng"
                    size="small"
                    style="
                        background-color: #e14a02;
                        color: #fff;
                        font-weight: 600;
                    "
                    @click="closeModalDelete"
                />
                <CustomButton
                    label="Xóa"
                    size="small"
                    style="
                        background-color: #e14a02;
                        color: #fff;
                        font-weight: 600;
                    "
                    @click="handleDelete"
                />
            </div>
        </el-dialog>
        <el-dialog
            :visible="successDeleteModalVisible"
            @close="closeModalSuccessDeleteModal"
            class="custom-dialog"
            width="70%"
        >
            <span slot="title"
                >Thông báo
                <div class="layout-table mt-4">
                    <table
                        class="table table-bordered table-hover centered-table"
                    >
                        <thead class="sticky-header">
                            <tr>
                                <th class="text-thead">STT</th>
                                <th class="text-thead">Mã trường</th>
                                <th class="text-thead">Tên trường</th>
                                <th class="text-thead">Dữ liệu</th>
                                <th class="text-thead">Nội dung</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template
                                v-if="tableDataModalDeleteSuccess.length === 0"
                            >
                                <tr>
                                    <td colspan="8" class="text-tbody">
                                        Không có bản ghi nào.
                                    </td>
                                </tr>
                            </template>
                            <tr
                                v-for="(
                                    item, index
                                ) in tableDataModalDeleteSuccess"
                                :key="index"
                            >
                                <td class="text-tbody">
                                    {{ index + 1 }}
                                </td>
                                <td class="text-tbody">
                                    {{ item.maTruong }}
                                </td>
                                <td class="text-tbody">
                                    {{ item.tenTruong }}
                                </td>
                                <td class="text-tbody">
                                    {{ getDuLieuXoa(item.buocDays) }}
                                </td>
                                <td class="text-tbody">
                                    {{ item.message }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </span>
            <div style="margin-top: 70px"></div>
            <hr />
            <div class="layout-table-modal">
                <CustomButton
                    label="Đóng"
                    size="small"
                    style="
                        background-color: #e14a02;
                        color: #fff;
                        font-weight: 600;
                    "
                    @click="closeModalSuccessDeleteModal"
                />
            </div>
        </el-dialog>
    </div>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import Api from '@/constants/Api'
import sendRequest from '@/services'

import { mapState } from 'vuex'
import {
    ESelectSemester_MockData_KetChuyenDuLieu,
    ESelectDataSynchronization_ALL
} from '@/mock_data'
export default {
    props: {
        deleteModalVisible: {
            type: Boolean,
            required: true
        },
        dataModalDelete: {
            type: Array,
            required: true
        },
        closeModalDelete: {
            type: Function,
            required: true
        },
        selectedValueSchoolYear: {
            type: String,
            required: true
        },
        selectedValueSemester: {
            type: Object,
            required: true
        },

        handleSearch: Function
    },

    components: { CustomButton },
    data() {
        return {
            year: '',
            successDeleteModalVisible: false,
            getDataESelect: {
                ESelectShoolYear: '', //namhoc
                ESelectSemester_MockData: ESelectSemester_MockData_KetChuyenDuLieu //hocky
            },
            selectedValue: {
                selectedValueSchoolYear: '2023', //namhoc
                selectedValueSemester: 1 //hocky
            },
            tableDataModalDeleteSuccess: []
        }
    },

    watch: {
        selectedValueSemester(newValues) {
            this.selectedValue.selectedValueSemester = newValues.value
        },
        selectedValueSchoolYear(newValues) {
            const year = parseFloat(newValues)
            this.year = `${year}-${year + 1}`
            this.selectedValue.selectedValueSchoolYear = newValues
        }
    },
    methods: {
        async closeModalSuccessDeleteModal() {
            this.$nextTick(async () => {
                await this.closeModalDelete()
                await this.$emit('handleSearch')
                this.successDeleteModalVisible = false
            })
        },
        handleDelete() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const request_Header = {
                token: this.authToken
            }
            const deleteTruongs = this.dataModalDelete
            if (deleteTruongs.length === 0) {
                loading.close()
                this.$message({
                    type: 'error',
                    message: 'Vui lòng chọn dữ liệu cần xóa'
                })
                return
            }
            const request_Data = {
                hocKy: this.selectedValue.selectedValueSemester,
                namHoc: this.selectedValue.selectedValueSchoolYear,

                deleteTruongs: deleteTruongs
            }
            this.$confirm('Xác nhận xóa?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const response = await sendRequest(
                        Api.ketChuyenDuLieuLenBo.ketChuyenDuLieu.xoaDuLieu,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        loading.close()
                        this.successDeleteModalVisible = true
                        this.$notify({
                            title: 'Success',
                            message: 'Thao tác thành công',
                            type: 'success'
                        })
                        this.tableDataModalDeleteSuccess = response.rows
                    } else {
                        this.$message({
                            type: 'error',
                            message: response?.data.rd
                        })
                        loading.close()
                    }
                })
                .catch((error) => {
                    loading.close()
                    if (error.response?.data.rd) {
                        this.$message({
                            type: 'error',
                            message: error.response?.data.rd
                        })
                    }
                })
        },
        getDuLieuXoa(buocDays) {
            const keysToFind = buocDays.map(String)
            const foundNames = ESelectDataSynchronization_ALL.filter((item) =>
                keysToFind.includes(item.key)
            ).map((item) => item.name)

            return foundNames.join(', ')
        }
    },

    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped>
hr {
    border: 1px solid gray;
}
.layout-table-modal {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}
.layout-table {
    max-height: 300px;
    overflow-y: auto;
}
.sticky-header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    height: 40px; /* Đặt chiều cao cố định cho phần header */
}
table thead tr {
    background: rgb(228, 235, 245);
}
.table {
    white-space: nowrap;
}

.centered-table {
    margin-left: auto;
    margin-right: auto;
}

.centered-table th,
.centered-table td {
    text-align: center;
    vertical-align: middle;
}
.table-bordered {
    background-color: #fff;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}
.table-hover tbody tr:hover {
    background-color: #f5f5f5;
}

.text-thead {
    font-size: 13px;
    text-align: center; /* Canh giữa nội dung trong cột */
    vertical-align: middle; /* Căn giữa theo chiều dọc */
}
.text-tbody {
    font-size: 13px;
}
.text-left {
    text-align: left;
}
</style>
