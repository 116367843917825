<template>
    <div>
        <CustomBreadcrumb :title="'BÁO CÁO'" :content="'BÁO CÁO ĐƠN VỊ'" />

        <div class="layout-table">
            <div>
                <nav>
                    <div class="nav nav-tabs">
                        <a
                            v-for="(tab, index) in dataBaoCaoChoSoTable"
                            :key="index"
                            :class="{
                                'nav-item': true,
                                'nav-link': true,
                                active: activeTab === index,
                                'custom-a': activeTab !== index
                            }"
                            @click="toggleTab(index)"
                        >
                            {{ tab.category }}
                        </a>
                    </div>
                </nav>
            </div>
            <div class="report-content">
                <table class="table table-bordered table-hover centered-table">
                    <thead>
                        <tr>
                            <th class="text-thead text-center">STT</th>
                            <th class="text-thead text-center">Mã báo cáo</th>
                            <th class="text-thead">Tên báo cáo</th>

                            <th class="text-thead text-center">Thao tác</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(data, index) in dataBaoCaoChoSoTable[
                                activeTab
                            ]?.children"
                            :key="index"
                        >
                            <td class="text-tbody text-center">
                                {{ index + 1 }}
                            </td>
                            <td class="text-tbody text-center">
                                {{ data.maBaoCao }}
                            </td>
                            <td class="text-tbody">{{ data.tenBaoCao }}</td>

                            <td class="text-tbody text-center">
                                <CustomButton
                                    label="Xem"
                                    size="small"
                                    class="btn-default btn-view"
                                    @click="
                                        openModalView(
                                            data.collectionName,
                                            index,
                                            data
                                        )
                                    "
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- ************ Modal View ************ -->

            <el-dialog
                :visible.sync="viewModalVisible"
                width="70%"
                @close="closeModalView"
            >
                <span slot="title" class="custom-dialog-title">
                    <span
                        v-if="
                            dataBaoCaoChoSoTable[activeTab] &&
                            dataBaoCaoChoSoTable[activeTab].category
                        "
                    >
                        {{ dataBaoCaoChoSoTable[activeTab].category }} -
                        {{ reportName }}
                        <hr />
                    </span>
                </span>
                <div class="row">
                    <div class="col-4" style="margin-top: 30px">
                        <label>
                            Đơn vị quản lý
                            <span v-if="this.authUser.role === 2" class="span">
                                *
                            </span>
                        </label>
                        <div>
                            <ESelect
                                :reset="resetESelectUnitEducation"
                                @reset-completed="
                                    handleResetUnitEducationCompleted
                                "
                                style="width: 100%"
                                no-match-text="Không tìm thấy bản ghi nào"
                                no-data-text="danh sách lựa chọn trống"
                                :clearable="true"
                                :disabled="
                                    this.authUser.role === 4 ||
                                    this.authUser.role === 5
                                "
                                :data="this.dataChonDonVi_Store"
                                :placeholder="'Chọn đơn vị'"
                                :filterable="true"
                                :multiple="true"
                                :collapse-tags="true"
                                :fields="['tenDonVi', 'maDonVi']"
                                :value="selectedValue.selectedValueUnitEducation"
                                @change="
                                    handleESelectChange(
                                        'ESelectUnitEducation',
                                        ...$event
                                    )
                                "
                            />
                        </div>
                    </div>
                    <div class="col-4" style="margin-top: 30px">
                        <label>Trường học</label>
                        <div>
                            <ESelect
                                :reset="resetESelectSchool"
                                @reset-completed="handleResetSchoolCompleted"
                                style="width: 100%"
                                no-match-text="Không tìm thấy bản ghi nào"
                                no-data-text="danh sách lựa chọn trống"
                                :clearable="true"
                                :disabled="this.authUser.role === 5"
                                :data="getDataESelect.ESelectSchool"
                                :placeholder="'Chọn trường học'"
                                :filterable="true"
                                :multiple="true"
                                :collapse-tags="true"
                                :fields="['tenTruongHoc', 'maTruongHoc']"
                                :value="selectedValue.selectedValueSchool"
                                @change="
                                    handleESelectChange(
                                        'ESelectSchool_MockData',
                                        ...$event
                                    )
                                "
                            />
                        </div>
                    </div>

                    <div class="col-4 mt-4">
                        <label>Năm học <span class="span">*</span></label>
                        <div>
                            <ESelectYear
                                v-model="selectedValue.selectedValueSchoolYear"
                                placeholder="Chọn năm"
                                size="small"
                                :no-data-text="'Không có bản ghi nào'"
                                :no-match-text="'Không tìm thấy bản ghi nào'"
                                :width="'100%'"
                            ></ESelectYear>
                        </div>
                    </div>
                    <div
                        class="col-4 mt-4"
                        v-if="
                            [
                                'BcDanhHieuHocSinh',
                                'BcDiemTrungBinhMon',
                                'BcTinhHinhHanhKiemCuaHocSinh',
                                'BcSuPhatTrienCuaTre',
                                'BcTinhHinhHocLucCuaHocSinh',
                                'BcHocLucHanhKiemTheoDiaPhuong',
                                'BcSoHocSinhLenLopLuuBanThiLai',
                                'BcPhanLoaiDiemSoTheoMonHocKhoiLop',
                                'BcSuPhatTrienCuaTre'
                            ].includes(collectionNameInViewModal)
                        "
                    >
                        <label>Học kỳ<span class="span">*</span></label>
                        <div>
                            <ESelect
                                :reset="resetESelectSemester"
                                @reset-completed="handleResetSemesterCompleted"
                                style="width: 100%"
                                no-match-text="Không tìm thấy bản ghi nào"
                                no-data-text="danh sách lựa chọn trống"
                                :clearable="true"
                                :disabled="false"
                                :data="getDataESelect.ESelectSemester"
                                :placeholder="'Chọn năm '"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['tenHocKy', 'value']"
                                v-model="selectedValue.selectedValueSemester"
                            />
                        </div>
                    </div>
                </div>
                <div class="layout-btn">
                    <div class="padding">
                        <CustomButton
                            label="Xem báo cáo"
                            size="small"
                            class="btn-view-report"
                            @click="handleModalView()"
                        />
                    </div>
                    <div class="padding">
                        <CustomButton
                            label="Tải xuống"
                            size="small"
                            class="btn-download"
                            @click="handleDownloadXlsxFile()"
                        />
                    </div>
                </div>
                <div class="layout-btn_close">
                    <CustomButton
                        size="small"
                        @click="closeModalView"
                        label="Đóng"
                        class="btn-close-modal"
                    />
                </div>
            </el-dialog>
        </div>

        <ViewExcel
            :titleViewExcel="'File đã nộp'"
            :fileViewExcel="fileViewExcel"
            :closeModalViewExcel="closeModalViewExcel"
            :viewExcelModalVisible="viewExcelModalVisible"
        />
    </div>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'
import ESelect from '@/components/ESelect.vue'
import ESelectYear from '@/components/ESelectYear.vue'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { mapState } from 'vuex'
import {
    ESelectGradeLevel_MockData_BaoCaoEQMS,
    ESelectSemester_MockData_BaoCaoDonVi
} from '@/mock_data'
import ViewExcel from '@/components/ViewExcel.vue'
import {
    convertBase64ToFile,
    convertCapHocList,
    currentYear
} from '@/utils/index'
export default {
    name: 'BaoCaoDonVi',
    components: {
        CustomButton,
        CustomBreadcrumb,
        ESelect,
        ESelectYear,
        ViewExcel
    },
    data() {
        return {
            fileViewExcel: null,
            viewExcelModalVisible: false,

            activeTab: 0, // Index của tab đầu tiên được chọn mặc định,
            viewModalVisible: false,
            viewExcelVisible: false,
            dataViewExcel: '',
            collectionNameInViewModal: null,
            reportName: null,
            capHocInViewModal: null,
            resetESelectSchool: false,
            resetESelectUnitEducation: false,
            resetESelectGradeLevel: false,
            resetESelectSemester: false,

            request_Data: {
                capHoc: null,
                collectionName: null,
                maDonVis: [],
                maNhomCapHocs: [],
                maTruongs: [],
                namHoc: null
            },
            request_Params: {
                tenBaoCao: null,
                namHoc: null
            },
            request_Header: {
                Authorization: ''
            },

            dataBaoCaoChoSoTable: [],
            getDataESelect: {
                ESelectUnitEducation: [], //donvi
                ESelectSchool: [], //truonghoc
                ESelectGradeLevel_MockData_BaoCaoEQMS:
                    ESelectGradeLevel_MockData_BaoCaoEQMS, //caphoc_baoCaoEQMS
                ESelectShoolYear: null, //namhoc
                ESelectSemester: ESelectSemester_MockData_BaoCaoDonVi //hocky
            },

            selectedValue: {
                selectedValueUnitEducation: [], //donvu
                selectedValueSchool: [], //truonghoc
                selectedValueGradeLevel: [], //caphoc
                selectedValueSchoolYear: [], //namhoc
                selectedValueSemester: [] //hocky
            }
        }
    },
    methods: {
        closeModalViewExcel() {
            this.viewExcelModalVisible = false
        },
        async openModalView(collectionName, index, item) {
            this.reportName = item.tenBaoCao
            this.collectionNameInViewModal = collectionName // Gán giá trị collectionName cho biến mới

            this.viewModalVisible = true
        },
        closeViewExcel() {
            this.viewExcelVisible = false
        },
        handleResetSchoolCompleted() {
            this.resetESelectSchool = false
        },
        handleResetUnitEducationCompleted() {
            this.resetESelectUnitEducation = false
        },
        handleResetGradeLevelCompleted() {
            this.resetESelectGradeLevel = false
        },
        handleResetSemesterCompleted() {
            this.resetESelectSemester = false
        },
        closeModalView() {
            if (this.authUser.role === 2) {
                this.resetESelectUnitEducation = true
                this.selectedValue.selectedValueUnitEducation = []
                this.resetESelectGradeLevel = true
                this.selectedValue.selectedValueGradeLevel = []
                this.selectedValue.selectedValueSchool = []
                this.resetESelectSchool = true

                this.selectedValue.selectedValueSemester = []
                this.resetESelectSemester = true
            }

            this.viewModalVisible = false
        },

        async toggleTab(index) {
            this.activeTab = index
            this.capHocInViewModal = this.dataBaoCaoChoSoTable[index].capHoc
            if (this.authUser.role === 2) {
                this.selectedValue.selectedValueUnitEducation = []
            }
            await this.getDataESelectSchool()
        },
        async handleModalView() {
            try {
                const collectionName = this.collectionNameInViewModal
                const capHoc = this.capHocInViewModal

                const maDonVis = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueUnitEducation,
                    'selectedValueUnitEducation'
                )

                const maTruongs = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueSchool,
                    'selectedValueSchool'
                )
                const maNhomCapHocs = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueGradeLevel,
                    'selectedGradeLevel'
                )

                const hocky = this.selectedValue.selectedValueSemester.value

                const namHoc_Update = this.selectedValue.selectedValueSchoolYear
                if (maDonVis.length === 0 && this.authUser.role === 2) {
                    this.$message({
                        message: 'Vui lòng chọn Đơn vị Tìm kiếm',
                        type: 'warning'
                    })
                    return
                }
                const request_Header = {
                    token: this.authToken
                }
                const request_Data = {
                    ...this.request_Data,
                    capHoc: capHoc,
                    collectionName: collectionName,
                    maDonVis: maDonVis,
                    maNhomCapHocs: maNhomCapHocs,
                    maTruongs: maTruongs,
                    namHoc: namHoc_Update || currentYear,
                    hocky: hocky
                }
                const response = await sendRequest(
                    Api.baoCao.baoCaoChoSo.xemBaoCao,
                    request_Data,
                    request_Header,
                    null
                )
                if (response.rc == 0) {
                    const loading = this.$loading({
                        lock: true,
                        text: 'Đang tổng hợp',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    })

                    this.viewExcelModalVisible = true

                    convertBase64ToFile(response.fileContent)
                        .then((file) => {
                            this.fileViewExcel = file
                        })
                        .catch((error) => {
                            // Xử lý lỗi nếu có
                            console.error('Error converting file:', error)
                        })

                    loading.close()
                } else {
                    this.$message({
                        message: 'Lỗi',
                        type: 'error'
                    })
                }
            } catch (error) {
                console.log(error)
            }
        },
        async handleDownloadXlsxFile() {
            try {
                const collectionName = this.collectionNameInViewModal
                const capHoc = this.capHocInViewModal

                const maDonVis = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueUnitEducation,
                    'selectedValueUnitEducation'
                )

                const maTruongs = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueSchool,
                    'selectedValueSchool'
                )
                const maNhomCapHocs = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueGradeLevel,
                    'selectedGradeLevel'
                )

                const namHoc_Update = this.selectedValue.selectedValueSchoolYear
                if (maDonVis.length === 0 && this.authUser.role === 2) {
                    this.$message({
                        message: 'Vui lòng chọn Đơn vị Tìm kiếm',
                        type: 'warning'
                    })
                    return
                }
                const request_Header = {
                    token: this.authToken
                }
                const request_Data = {
                    ...this.request_Data,
                    capHoc: capHoc,
                    collectionName: collectionName,
                    maDonVis: maDonVis,
                    maNhomCapHocs: maNhomCapHocs,
                    maTruongs: maTruongs,
                    namHoc: namHoc_Update || currentYear
                }
                const response = await sendRequest(
                    Api.baoCao.baoCaoChoSo.xemBaoCao,
                    request_Data,
                    request_Header,
                    null
                )

                if (response.rc == 0) {
                    const loading = this.$loading({
                        lock: true,
                        text: 'Đang tổng hợp',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    })

                    const linkDowndLoad = `https://dongbo.csdl.edu.vn/${response.urlContext}`
                    console.log(linkDowndLoad)

                    loading.close()
                    this.$confirm(`Xác nhận tải xuống ?`, 'Thông báo', {
                        confirmButtonText: 'Đồng ý',
                        cancelButtonText: 'Hủy'
                    })
                        .then(() => {
                            window.open(linkDowndLoad, '_blank')
                        })
                        .catch(() => {
                            // Xử lý khi người dùng từ chối tải xuống
                        })
                }
            } catch (error) {
                console.log(error)
            }
        },

        async getDataBaoCaoChoSoTable() {
            try {
                const request_Header = {
                    ...this.request_Header,
                    token: this.authToken
                }
                const request_Params = {
                    ...this.request_Params,
                    namHoc: 2019,
                    tenBaoCao: 'baoCaoChoSo'
                }
                const response = await sendRequest(
                    Api.baoCao.baoCaoChoSo.danhSachBaoCao,
                    null,
                    request_Header,
                    request_Params
                )

                this.dataBaoCaoChoSoTable = response
                if (response.length > 0) {
                    this.capHocInViewModal = response[0].capHoc
                }
            } catch (error) {
                console.log(error)
            }
        },
        async getDataESelectSchool() {
            try {
                const maDonVi = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueUnitEducation,
                    'selectedValueUnitEducation'
                )

                const request_Header = {
                    ...this.request_Header,
                    token: this.authToken
                }
                const request_Data = {
                    capHoc: [this.capHocInViewModal],
                    maDonVi: maDonVi
                }

                const response = await sendRequest(
                    Api.internal_api.dm_School,
                    request_Data,
                    request_Header,
                    null
                )

                this.getDataESelect.ESelectSchool = response.rows.map((item) => ({
                    ...item,
                    tenTruongHoc: `${item.tenTruongHoc} - [${item.maTruongHoc}]`
                }))
            } catch (error) {
                console.log(error)
            }
        },
        handleESelectChange(source, ...selectedOptions) {
            switch (source) {
                case 'ESelectUnitEducation':
                    this.selectedValue.selectedValueUnitEducation =
                        selectedOptions
                    this.resetESelectSchool = true
                    this.selectedValue.selectedValueSchool = []
                    this.getDataESelectSchool()
                    break
                case 'ESelectSchool_MockData':
                    this.selectedValue.selectedValueSchool = selectedOptions
                    break
                case 'ESelectGradeLevel_MockData':
                    this.selectedValue.selectedValueGradeLevel = selectedOptions
                    break
            }
        },
        customhandleESelectedChange(options, valueType) {
            switch (valueType) {
                case 'selectedValueUnitEducation':
                case 'selectedValueSchool':
                case 'selectedGradeLevel':
                    return options.map((option) => option.value)
            }
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        },
        dataChonTruongHoc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTruongHoc'))
        },
        updatedESelectSchool() {
            return this.dataChonTruongHoc_Store
        }
    },
    mounted() {
        this.getDataBaoCaoChoSoTable().then(() => {
            this.getDataESelectSchool()
        })

        // console.log(this.getDataESelect.ESelectSchool)
    },
    watch: {
        'authUser.role': {
            immediate: true,
            handler(newRole, oldRole) {
                if (newRole === 4) {
                    this.selectedValue.selectedValueUnitEducation = [
                        {
                            value: this.authUser.ma_phong
                        }
                    ]
                }
                if (newRole === 5) {
                    this.selectedValue.selectedValueUnitEducation = [
                        {
                            value: this.authUser.ma_don_vi
                        }
                    ]

                    this.selectedValue.selectedValueSchool = [
                        {
                            value: this.authUser.ma_truong_hoc
                        }
                    ]
                }
            }
        }
    }
}
</script>
<style scoped>
.layout-table {
    background: #fff;
    padding: 20px 10px 20px 10px;
    border-radius: 4px;
}
.report-content {
    display: flex;

    align-items: center;
}

.report-content table {
    margin: 0 auto;
}

.report-content td {
    vertical-align: middle;
}
.layout-table .active,
.layout-table .active:hover {
    background: #6aab9c;
    color: #fff;

    font-size: 13px;
}
.layout-table .custom-a {
    border: none !important;
    font-size: 13px !important;
    background: #f3f5f7;
}
.layout-table .custom-a:hover {
    cursor: pointer;
    background-color: #f8f9fa;
}

table thead tr {
    background: #6aab9c;
    color: #fff;

    font-size: 18px;
}
.table {
    white-space: nowrap;
}
.centered-table {
    margin-left: auto;
    margin-right: auto;
}

.text-center {
    text-align: center;
}

.table-bordered {
    background-color: #fff;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}
.table-hover tbody tr:hover {
    background-color: #f5f5f5;
}

.text-thead {
    font-size: 13px;
}
.text-thead span {
    color: #da1313;
}
.text-tbody {
    font-size: 13px;
}
label .span {
    color: red;
}
.layout-btn {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}
.layout-btn .padding {
    padding: 20px;
}
.layout-btn_close {
    display: flex;
    justify-content: flex-end;
}
hr {
    border: 1px solid #aac77e;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}

.btn-view {
    background-color: var(--teal);
}
.btn-download {
    background-color: #325434;
    color: #fff;
    font-weight: 600;
}
.btn-close-modal {
    background-color: #e14a02;
    color: #fff;
    font-weight: 600;
}
.btn-view-report {
    background-color: #6aab9c;
    color: #fff;
    font-weight: 600;
}
::v-deep .el-tag.el-tag--info {
    color: black !important;
}
</style>
