<template>
    <div>
        <CustomBreadcrumb :title="'Tra cứu'" content="THÔNG TIN ĐIỂM DANH" />
        <CustomContentUI>
            <div class="row">
                <div class="col-sm-2">
                    <label>Loại báo cáo <span class="error">*</span></label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectReportType"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['title', 'value']"
                            v-model="selectedValue.selectedValueReportType"
                            @input="changeReportType"
                        />
                        <span v-if="err_type.length > 0" class="error">{{
                            err_type
                        }}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <label>Đơn vị quản lý <span class="error">*</span></label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="
                                this.authUser.role === 5 ||
                                this.authUser.role === 4
                            "
                            :data="this.dataChonDonVi_Store"
                            :placeholder="'Chọn đơn vị'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['tenDonVi', 'maDonVi']"
                            v-model="selectedValue.selectedValueUnitEducation"
                            @change="getDataChonTruongHoc"
                        />
                        <span v-if="err_maDonVi.length > 0" class="error">{{
                            err_maDonVi
                        }}</span>
                    </div>
                </div>
                <div class="col-sm-2">
                    <label>Cấp học <span class="error">*</span></label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectGradeLevel"
                            :placeholder="'Chọn cấp học'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['tenTruongHoc', 'value']"
                            v-model="selectedValue.selectedValueGradeLevel"
                            @change="getDataChonTruongHoc"
                        />
                        <span v-if="err_capHoc.length > 0" class="error">{{
                            err_capHoc
                        }}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <label>Trường học </label>
                    <div>
                        <ESelect
                            :reset="resetESelectSchool"
                            @reset-completed="handleResetSchoolCompleted"
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="valueChangeReportType === '1'"
                            :data="getDataESelect.ESelectSchool"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['title', 'value']"
                            v-model="selectedValue.selectedValueSchool"
                        />
                    </div>
                </div>

                <div class="col-sm-2 mt-3">
                    <label>Năm học<span class="span">*</span></label>
                    <ESelectYear
                        v-model="selectedValue.selectedValueYear"
                        placeholder="Chọn năm"
                        size="small"
                        :no-data-text="'Không có bản ghi nào'"
                        :no-match-text="'Không tìm thấy bản ghi nào'"
                        :width="'100%'"
                    ></ESelectYear>
                </div>
                <div
                    class="col-sm-2 mt-3"
                    v-if="
                        valueChangeReportType === '1' ||
                        valueChangeReportType === '2'
                    "
                >
                    <label>Ngày điểm danh<span class="span">*</span></label>
                    <div>
                        <el-date-picker
                            v-model="selectedValue.selectedValueDate"
                            type="date"
                            placeholder="Ngày"
                            style="width: 100%"
                            format="dd/MM/yyyy"
                            size="small"
                        >
                        </el-date-picker>
                    </div>
                    <span v-if="err_date.length > 0" class="error">{{
                        err_date
                    }}</span>
                </div>
                <div class="col-sm-2 mt-3" v-if="valueChangeReportType === '3'">
                    <label>Tuần học<span class="span">*</span></label>
                    <div>
                        <el-date-picker
                            v-model="selectedValue.selectedValueWeek"
                            type="week"
                            format="Tuần WW"
                            size="small"
                            placeholder="Tuần"
                        >
                        </el-date-picker>
                    </div>
                    <span v-if="err_week.length > 0" class="error">{{
                        err_week
                    }}</span>
                </div>
                <div class="col-sm-2 mt-3" v-if="valueChangeReportType === '4'">
                    <label>Tháng học<span class="span">*</span></label>
                    <div>
                        <el-date-picker
                            v-model="selectedValue.selectedValueMonth"
                            type="month"
                            format="MM/yyyy"
                            size="small"
                            placeholder="Tháng"
                        >
                        </el-date-picker>
                    </div>
                    <span v-if="err_month.length > 0" class="error">{{
                        err_month
                    }}</span>
                </div>
                <div class="col-sm-2 mt-3" v-if="valueChangeReportType === '5'">
                    <label>Học kỳ<span class="span">*</span></label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectSemester"
                            :placeholder="'Chọn học kỳ'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['tenHocKy', 'value']"
                            v-model="selectedValue.selectedValueSemester"
                        />
                        <span v-if="err_hocKy.length > 0" class="error">{{
                            err_hocKy
                        }}</span>
                    </div>
                </div>
            </div>
            <div class="btn">
                <CustomButton
                    label="Tải xuống"
                    class="btn-default btn-down"
                    size="small"
                    @click="handleDownload"
                />
            </div>
        </CustomContentUI>
    </div>
</template>
<script>
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelectYear from '@/components/ESelectYear.vue'
import ESelect from '@/components/ESelect.vue'
import moment from 'moment'
import { mapState } from 'vuex'
import { ESelectSemester_MockData_TraCuuKQHTTT22 } from '@/mock_data'
import Api from '@/constants/Api'
import sendRequest from '@/services'
import {
    convertCapHocList,
    currentYear,
    filterCapHocList,
    validateField
} from '@/utils'
import axios from 'axios'
import CustomContentUI from '@/components/CustomContentUI.vue'
export default {
    name: 'ThongTinDiemDanh',
    components: {
        ESelect,
        CustomButton,
        CustomBreadcrumb,
        ESelectYear,
        CustomContentUI
    },
    data() {
        return {
            resetESelectSchool: false,
            getDataESelect: {
                ESelectReportType: [
                    {
                        title: 'Sở theo ngày',
                        value: '1'
                    },
                    {
                        title: 'Phòng theo ngày',
                        value: '2'
                    },
                    {
                        title: 'Phòng theo tuần',
                        value: '3'
                    },
                    {
                        title: 'Phòng theo tháng',
                        value: '4'
                    },
                    {
                        title: 'Phòng theo học kỳ',
                        value: '5'
                    }
                ], //loaibaocao
                ESelectUnitEducation: [], //donvi
                ESelectGradeLevel: [], //caphoc
                ESelectSchool: [], //truonghoc
                ESelectSemester: ESelectSemester_MockData_TraCuuKQHTTT22 //hocky
            },
            selectedValue: {
                selectedValueReportType: [],
                selectedValueUnitEducation: [], //donvi
                selectedValueGradeLevel: [], //caphoc
                selectedValueSchool: [], //truonghoc,
                selectedValueYear: '',
                selectedValueDate: '',
                selectedValueWeek: '',
                selectedValueMonth: '',
                selectedValueSemester: '' //hocky
            },
            valueChangeReportType: '',

            err_type: '',
            err_maDonVi: '',
            err_capHoc: '',
            err_date: '',
            err_month: '',
            err_week: '',
            err_hocKy: ''
        }
    },
    methods: {
        handleResetSchoolCompleted() {
            this.resetESelectSchool = false
        },
        resetErr() {
            this.err_capHoc = ''
            this.err_hocKy = ''
            this.err_maDonVi = ''
            this.err_ngayBatDau = ''
            this.err_type = ''
        },
        changeReportType() {
            this.valueChangeReportType =
                this.selectedValue.selectedValueReportType?.value
            this.selectedValue.selectedValueGradeLevel = ''
            this.selectedValue.selectedValueSchool = ''
            // this.resetESelectSchool = true
            this.selectedValue.selectedValueYear = ''
            this.selectedValue.selectedValueDate = ''
            this.selectedValue.selectedValueWeek = ''
            this.selectedValue.selectedValueMonth = ''
            this.selectedValue.selectedValueSemester = '' //hocky
            this.resetErr()
        },
        async handleDownload() {
            const type = this.selectedValue.selectedValueReportType.value
            const maDonVi = this.selectedValue.selectedValueUnitEducation.value
            const capHoc = this.selectedValue.selectedValueGradeLevel.value
            const namHoc = this.selectedValue.selectedValueYear
            const date = moment(this.selectedValue.selectedValueDate).format(
                'DD/MM/YYYY'
            )

            const month = moment(this.selectedValue.selectedValueMonth).format(
                'DD/MM/YYYY'
            )
            const week = moment(this.selectedValue.selectedValueWeek).format(
                'DD/MM/YYYY'
            )
            let ngayBatDau

            switch (this.valueChangeReportType) {
                case '1':
                case '2':
                    ngayBatDau = date
                    break
                case '3':
                    ngayBatDau = week
                    break
                case '4':
                    ngayBatDau = month
                    break
                default:
                    ngayBatDau = null
            }

            const hocKy = this.selectedValue.selectedValueSemester
            const maTruongHocs = this.selectedValue.selectedValueSchool.value
            const fieldsToValidate = [
                {
                    name: 'err_type',
                    value: type,
                    errorField: 'err_type',
                    errorMessage: 'Vui lòng chọn loại báo cáo'
                },
                {
                    name: 'err_maDonVi',
                    value: maDonVi,
                    errorField: 'err_maDonVi',
                    errorMessage: 'Vui lòng chọn mã đơn vị quản lý'
                },
                {
                    name: 'err_capHoc',
                    value: capHoc,
                    errorField: 'err_capHoc',
                    errorMessage: 'Vui lòng chọn cấp học'
                },

                (this.valueChangeReportType === '1' ||
                    this.valueChangeReportType === '2') && {
                    name: 'err_date',
                    value: date,
                    errorField: 'err_date',
                    errorMessage: 'Vui lòng chọn ngày'
                },
                this.valueChangeReportType === '4' && {
                    name: 'err_month',
                    value: month,
                    errorField: 'err_month',
                    errorMessage: 'Vui lòng chọn tháng'
                },
                this.valueChangeReportType === '3' && {
                    name: 'err_week',
                    value: week,
                    errorField: 'err_week',
                    errorMessage: 'Vui lòng chọn tuần'
                },

                this.valueChangeReportType === '5' && {
                    name: 'err_hocKy',
                    value: hocKy,
                    errorField: 'err_hocKy',
                    errorMessage: 'Vui lòng chọn học kỳ '
                }
            ].filter(Boolean)
            let isValid = true

            for (const field of fieldsToValidate) {
                isValid = validateField(this, field, isValid)
            }
            if (isValid) {
                this.$confirm('Xác nhận tải xuống?', 'Thông báo', {
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy',
                    type: 'warning'
                })

                    .then(async () => {
                        const request_Data = {
                            capHoc: capHoc,
                            hocKy: hocKy,
                            maDonVi: maDonVi,
                            maSo: this.authUser.ma_so,
                            maTruongHocs: maTruongHocs,
                            namHoc: namHoc || currentYear,
                            ngayBatDau: ngayBatDau,
                            type: type
                        }
                        const domain = process.env.VUE_APP_BACKEND_BASE_URL
                        const response = await axios.post(
                            `${domain}/csdlgd-report-api/bc-diem-danh/bao-cao`,
                            request_Data,
                            {
                                headers: {
                                    token: this.authToken,
                                    'Content-Type': 'application/json'
                                },
                                responseType: 'blob' // Set responseType là 'blob'
                            }
                        )

                        const blob = new Blob([response.data])
                        const blobUrl = window.URL.createObjectURL(blob)

                        const downloadLink = document.createElement('a')
                        downloadLink.href = blobUrl
                        downloadLink.download = 'ThongTinDiemDanh.xlsx'
                        downloadLink.style.display = 'none'

                        document.body.appendChild(downloadLink)
                        downloadLink.click()

                        window.URL.revokeObjectURL(blobUrl)
                    })
                    .catch((error) => {})
            }
        },
        async getDataChonTruongHoc() {
            // Kiểm tra và khởi tạo mảng maDonVi
            const maDonVi = this.selectedValue.selectedValueUnitEducation.value
                ? [this.selectedValue.selectedValueUnitEducation.value]
                : []

            // Kiểm tra và khởi tạo mảng capHoc
            const capHoc = this.selectedValue.selectedValueGradeLevel.value
                ? [this.selectedValue.selectedValueGradeLevel.value]
                : []

            const request_Header = {
                token: this.authToken
            }

            const request_Data = {
                capHoc: capHoc,
                maDonVi: maDonVi
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            let response = await sendRequest(
                Api.internal_api.dm_School,
                request_Data,
                request_Header,
                null
            )

            if (response.statusResponse == 0) {
                loading.close()
                this.getDataESelect.ESelectSchool = response.rows.map((item) => ({
                    value: item.maTruongHoc,
                    title: `${item.tenTruongHoc} - [${item.maTruongHoc}]`
                }))
            }
        },
        convertToValueArray(array) {
            return array.map((item) => item.value)
        }
    },
    watch: {
        'selectedValue.selectedValueReportType': {
            handler(newVal, oldVal) {
                if (
                    oldVal.length !== newVal.length ||
                    oldVal?.value !== newVal?.value
                ) {
                    this.resetESelectSchool = true
                    this.selectedValue.selectedValueSchool = ''
                }
            },
            deep: true
        },

        'selectedValue.selectedValueUnitEducation': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        },
        'selectedValue.selectedValueGradeLevel': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        },
        'authUser.role': {
            immediate: true,
            handler(newRole, oldRole) {
                if (newRole === 5) {
                    this.selectedValue.selectedValueUnitEducation = {
                        value: this.authUser.ma_don_vi
                    }

                    const convertedList = convertCapHocList(
                        this.authUser.capHocList
                    )
                    this.selectedValue.selectedValueGradeLevel = convertedList
                    this.selectedValue.selectedValueSchool = {
                        value: this.authUser.ma_truong_hoc
                    }
                }
                if (newRole === 4) {
                    this.selectedValue.selectedValueUnitEducation = {
                        value: this.authUser.ma_don_vi
                    }
                    this.getDataESelect.ESelectReportType = [
                        {
                            title: 'Phòng theo ngày',
                            value: '2'
                        },
                        {
                            title: 'Phòng theo tuần',
                            value: '3'
                        },
                        {
                            title: 'Phòng theo tháng',
                            value: '4'
                        },
                        {
                            title: 'Phòng theo học kỳ',
                            value: '5'
                        }
                    ]
                }
                this.getDataESelect.ESelectGradeLevel = filterCapHocList(
                    this.authUser.capHocList
                )
            }
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        }
    },
    mounted() {
        this.getDataESelect.ESelectGradeLevel = filterCapHocList(
            this.authUser.capHocList
        )
        this.getDataChonTruongHoc()
    }
}
</script>

<style scoped>
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.btn-down {
    background-color: var(--light-green);
}
.bg-search {
    background-color: var(--teal);
}

.btn {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.error {
    color: red;
    font-style: italic;
}
::v-deep .el-input.is-disabled .el-input__inner,
::v-deep .el-tag.el-tag--info {
    background-color: #eee !important;
    color: black !important;
}
</style>
