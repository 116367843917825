<template>
    <div>
        <CustomBreadcrumb :title="'HỆ THỐNG '" content="QUẢN LÝ NGƯỜI DÙNG" />
        <CustomContentUI>
            <div class="row">
                <div class="col-3">
                    <label>Sở giáo dục</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="true"
                            :data="getDataESelect.ESelect_tinhThanh"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenDonVi', 'maDonVi']"
                            v-model="selectedValue.selectedValueProvince"
                        />
                    </div>
                </div>
                <div class="col-3">
                    <label>Phòng giáo dục</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="
                                this.authUser.role === 4 ||
                                this.authUser.role === 5
                            "
                            :data="getDataESelect.ESelectEducationDepartment"
                            :placeholder="'Chọn '"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['tenDonVi', 'maDonVi']"
                            v-model="
                                selectedValue.selectedValueEducationDepartment
                            "
                        />
                    </div>
                </div>
                <div class="col-3">
                    <label>Vai trò<span class="span">*</span></label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectRole"
                            :placeholder="'Chọn '"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['title', 'value']"
                            v-model="selectedValue.selectedValueRole"
                        />
                    </div>
                </div>
                <div class="col-3">
                    <label>Trạng thái</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectStatus"
                            :placeholder="'Chọn '"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['title', 'value']"
                            v-model="selectedValue.selectedValueStatus"
                        />
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-3">
                    <label>Tên tài khoản</label>
                    <div>
                        <el-input
                            placeholder="Nhập"
                            size="small"
                            v-model="selectedValue.input_accountName"
                        ></el-input>
                    </div>
                </div>
                <div class="col-3">
                    <label>Email:</label>
                    <div>
                        <el-input
                            placeholder="Nhập"
                            size="small"
                            v-model="selectedValue.input_email"
                        ></el-input>
                    </div>
                </div>
                <div class="col-3">
                    <label>Tên đăng nhập</label>
                    <div>
                        <el-input
                            placeholder="Nhập"
                            size="small"
                            v-model="selectedValue.input_userName"
                        ></el-input>
                    </div>
                </div>
            </div>
            <div class="layout-btn-search">
                <CustomButton
                    label="Tìm kiếm"
                    class="btn-default bg-search"
                    size="small"
                    @click="handleSearch"
                />
            </div>
        </CustomContentUI>

        <div
            class="layout-btn"
            :style="{
                'max-width': leftBarWidth()
            }"
        >
            <div>
                <chonSoLuongBanGhi @chonXongSoLuong="ChonSoLuongBanGhi($event)" />
            </div>
            <div>
                <CustomButton
                    label="Thêm mới"
                    size="small"
                    class="btn-default btn-add"
                    @click="handleOpenModalThemMoi"
                />
            </div>
        </div>
        <CustomTable :maxWidth="leftBarWidth()" :columns="tableColumns">
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="11" class="text-tbody">
                            Không có bản ghi nào.
                        </td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td class="text-tbody text-center">
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-tbody text-left">{{ item.hoTen }}</td>
                    <td class="text-tbody text-left">{{ item.username }}</td>
                    <td class="text-tbody text-left">{{ item.email }}</td>
                    <td class="text-tbody text-left">
                        {{ getRole(item.role) }}
                    </td>
                    <td class="text-tbody text-center">
                        <span v-if="item.isSso === true"> Tài khoản SSO </span>
                        <span v-if="item.isSso === false">
                            Tài khoản thường
                        </span>
                    </td>
                    <td class="text-tbody text-center">
                        <span v-if="item.trangThai == 1"> Đang hoạt động </span>
                        <span v-if="item.trangThai == 0"> Ngưng hoạt động </span>
                    </td>
                    <td class="text-tbody text-left">
                        <span v-if="item.isSso === true">
                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="Tài khoản sso. Không thể thay đổi."
                                placement="left"
                            >
                                <CustomButton
                                    label=""
                                    size="small"
                                    icon=" el-icon-thumb"
                                    class="btn-default btn-info"
                                    @click="handleOpenMsgSSO"
                                />
                            </el-tooltip>
                        </span>

                        <span v-if="item.isSso === false">
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="getTooltipChinhSua(item)"
                                placement="left"
                            >
                                <CustomButton
                                    label=""
                                    class="btn-default btn-update"
                                    size="small"
                                    icon="el-icon-edit-outline"
                                    @click="handleOpenModalChinhSua(item)"
                                    :disabled="item.role === authUser.role"
                                />
                            </el-tooltip>

                            <el-tooltip
                                v-if="item.trangThai == 1"
                                class="item"
                                effect="dark"
                                :content="getTooltipTrangThai(item)"
                                placement="top"
                            >
                                <CustomButton
                                    label=""
                                    class="btn-default btn-delete"
                                    size="small"
                                    icon="el-icon-lock"
                                    @click="
                                        handleTrangThai(
                                            item.username,
                                            item.trangThai
                                        )
                                    "
                                    :disabled="item.role === authUser.role"
                                />
                            </el-tooltip>
                            <el-tooltip
                                v-if="item.trangThai == 0"
                                class="item"
                                effect="dark"
                                content="Mở Khóa"
                                placement="top"
                            >
                                <CustomButton
                                    label=""
                                    class="btn-default btn-add"
                                    size="small"
                                    icon="el-icon-unlock"
                                    @click="
                                        handleTrangThai(
                                            item.username,
                                            item.trangThai
                                        )
                                    "
                                    :disabled="item.role === authUser.role"
                                />
                            </el-tooltip>

                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="getTooltipDoiMatKhau(item)"
                                placement="right-start"
                            >
                                <CustomButton
                                    label=""
                                    class="btn-default"
                                    style="background-color: #ffa554"
                                    size="small"
                                    icon="el-icon-key"
                                    @click="
                                        handleOpenModalDoiMatKhau(item.username)
                                    "
                                    :disabled="item.role === authUser.role"
                                />
                            </el-tooltip>
                        </span>
                    </td>
                </tr>
            </template>
        </CustomTable>

        <CustomPagination
            v-show="total_rows > 0"
            :tongbanghi="total_rows"
            :soluonghienthi="soLuongBanGhiHienThi"
            :batdau="trangbatdau"
            @pageChange="layLai($event)"
        >
        </CustomPagination>
        <ModalDoiMauKhau
            :doiMauKhauVisible="doiMauKhauVisible"
            :closeModalDoiMauKhau="closeModalDoiMauKhau"
            :dataDoiMauKhau="dataDoiMauKhau"
            @handleSearch="handleSearch"
        />
        <ModalThemMoi
            :themMoiVisible="themMoiVisible"
            :closeModalThemMoi="closeModalThemMoi"
            :ESelectRole="getDataESelect.ESelectRole"
            :dataThemMoi="dataThemMoi"
            @handleSearch="handleSearch"
            :ESelect_tinhThanh="getDataESelect.ESelect_tinhThanh"
        />
        <ModalChinhSua
            :chinhSuaVisible="chinhSuaVisible"
            :closeModalChinhSua="closeModalChinhSua"
            :dataChinhSua="dataChinhSua"
            :ESelectRole="getDataESelect.ESelectRole"
            @handleSearch="handleSearch"
            :ESelect_tinhThanh="getDataESelect.ESelect_tinhThanh"
        />
    </div>
</template>
<script>
import ModalDoiMauKhau from './ModalDoiMauKhau.vue'
import ModalThemMoi from './ModalThemMoi.vue'
import ModalChinhSua from './ModalChinhSua.vue'
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'
import CustomButton from '@/components/CustomButton.vue'
import chonSoLuongBanGhi from '@/components/chonSoLuongBanGhi.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import ESelect from '@/components/ESelect.vue'
import { mapState } from 'vuex'
import { ESelectStatus_QuanLyNguoiDung } from '@/mock_data'
import Api from '@/constants/Api'
import sendRequest from '@/services'
import CustomContentUI from '@/components/CustomContentUI.vue'
import CustomTable from '@/components/CustomTable.vue'
export default {
    name: 'TraCuuKetQuaHocTapTT22',
    components: {
        ESelect,
        CustomButton,
        CustomBreadcrumb,
        chonSoLuongBanGhi,
        CustomPagination,
        ModalDoiMauKhau,
        ModalThemMoi,
        ModalChinhSua,
        CustomContentUI,
        CustomTable
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            soLuongBanGhiHienThi: 25,
            trangbatdau: false,

            dataDoiMauKhau: '',
            doiMauKhauVisible: false,

            themMoiVisible: false,
            dataThemMoi: [],
            chinhSuaVisible: false,
            dataChinhSua: {},
            tableData: [],
            tableColumns: [
                { key: '1', label: 'STT' },
                { key: '2', label: 'Tên tài khoản' },
                { key: '3', label: 'Tên đăng nhập' },
                { key: '4', label: 'Email' },
                { key: '5', label: 'Vai trò' },
                { key: '6', label: 'Loại tài khoản' },
                { key: '7', label: 'Trạng thái' },
                { key: '8', label: 'Thao tác' }
            ],
            dataRole: [],
            getDataESelect: {
                ESelect_tinhThanh: [], //tinhthanh
                ESelectUnitEducation: [], //donvi
                ESelectEducationDepartment: [], //phonggiaoduc
                ESelectRole: [], //vaitro
                ESelectStatus: ESelectStatus_QuanLyNguoiDung //trangthai
            },
            selectedValue: {
                selectedValueProvince: [],
                selectedValueUnitEducation: [], //donvi
                selectedValueEducationDepartment: [], //phonggiaoduc
                selectedValueRole: [], //vaitro
                selectedValueStatus: [], //trangthai,
                input_accountName: '', //tentaikhoan
                input_email: '', //email
                input_userName: '' //tendangnhap
            }
        }
    },
    methods: {
        ChonSoLuongBanGhi(e) {
            this.total_rows = 0
            this.soLuongBanGhiHienThi = e.soluong
        },
        checkTruocKhiTim() {
            this.trangbatdau = !this.trangbatdau
        },
        layLai(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        getTooltipChinhSua(item) {
            if (item.role !== this.authUser.role) {
                return 'Chỉnh sửa'
            } else {
                return 'Vai trò tương đương. Không được phép thực hiện'
            }
        },
        getTooltipTrangThai(item) {
            if (item.role !== this.authUser.role) {
                return 'Khóa'
            } else {
                return 'Vai trò tương đương. Không được phép thực hiện'
            }
        },
        getTooltipDoiMatKhau(item) {
            if (item.role !== this.authUser.role) {
                return 'Đổi mật khẩu'
            } else {
                return 'Vai trò tương đương. Không được phép thực hiện'
            }
        },
        getRole(e) {
            if (e == 2) {
                return 'Admin sở - [2]'
            }

            const matchedRole = this.dataRole.find((role) => role.value == e)

            return matchedRole ? matchedRole.title : ''
        },

        async getDataESelect_PhongGiaoDuc() {
            const request_Header = {
                token: this.authToken
            }
            const request_Params = {
                limit: 9999,
                loaiDonVi: 2,
                maTinhThanh: this.authUser.province,
                start: 0
            }
            const response = await sendRequest(
                Api.internal_api.dm_DonVi,
                null,
                request_Header,
                request_Params
            )
            this.getDataESelect.ESelectEducationDepartment = response.rows.map(
                (item) => ({
                    tenDonVi: `${item.tenDonVi} - [${item.maDonVi}]`,
                    maDonVi: item.maDonVi
                })
            )
        },
        async getDataESelect_VaiTro() {
            const request_Header = {
                token: this.authToken
            }
            const request_Data = {}
            const response = await sendRequest(
                Api.heThong.quanLyNguoiDung.vaiTro,
                request_Data,
                request_Header
            )
            const dataRole = response.map((item) => ({
                title: `${item.roleName} - [${item.roleId}]`,
                value: item.roleId
            }))
            this.getDataESelect.ESelectRole = dataRole
            this.dataRole = dataRole
        },
        handleOpenModalThemMoi() {
            this.themMoiVisible = true
        },
        closeModalThemMoi() {
            this.themMoiVisible = false
        },
        handleOpenModalDoiMatKhau(item) {
            this.dataDoiMauKhau = item
            this.doiMauKhauVisible = true
        },
        closeModalDoiMauKhau() {
            this.doiMauKhauVisible = false
        },
        handleOpenModalChinhSua(item) {
            this.dataChinhSua = item
            this.chinhSuaVisible = true
        },
        closeModalChinhSua() {
            this.chinhSuaVisible = false
        },
        async handleTrangThai(username, trangThai) {
            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                username: username,
                trangThai: trangThai ? 0 : 1
            }

            this.$confirm(
                'Xác nhận thay đổi trạng thái tài khoản?',
                'Thông báo',
                {
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy',
                    type: 'warning'
                }
            )
                .then(async () => {
                    const loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    })
                    const response = await sendRequest(
                        Api.heThong.quanLyNguoiDung.thayDoiTrangThai,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        loading.close()

                        await this.handleSearch()
                        this.$message({
                            type: 'success',
                            message: 'Thay đổi thành công'
                        })
                    } else {
                        loading.close()
                        this.$message({
                            type: 'info',
                            message: response.rd
                        })
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Hủy'
                    })
                })
        },
        async handleSearch() {
            const maDonVi = this.selectedValue.selectedValueUnitEducation?.value

            const maPhong =
                this.selectedValue.selectedValueEducationDepartment?.value
            const role = this.selectedValue.selectedValueRole?.value
            const trangThai = this.selectedValue.selectedValueStatus?.value

            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                email: this.selectedValue.input_email,
                hoTen: this.selectedValue.input_accountName,
                limit: this.limit,
                maDonVi: maDonVi,
                maPhong: maPhong,
                role: role,
                start: this.start,
                trangThai: trangThai,
                username: this.selectedValue.input_userName
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const response = await sendRequest(
                Api.heThong.quanLyNguoiDung.danhSachQuanLyNguoiDung,
                request_Data,
                request_Header
            )
            if (response.rc == 0) {
                loading.close()
                this.$message({
                    type: 'success',
                    message: 'Danh sách tìm kiếm'
                })
                this.tableData = response.tableData
                this.dataThemMoi = response.tableData
                this.total_rows = response.totalRows
            }
            if (response.rc != 0) {
                loading.close()
                this.$message({
                    type: 'warning',
                    message: response.rd
                })
                this.tableData = []
                this.total_rows = 0
            }
        },
        handleOpenMsgSSO() {
            this.$message({
                type: 'warning',
                message:
                    'Để thay đổi thông tin của tài khoản SSO vui lòng liên hệ với admin để được hỗ trợ.'
            })
        },
        leftBarWidth() {
            if (this.isCollapseLeftBar) {
                return 'calc(100vw - 105px)'
            } else {
                return 'calc(100vw - 360px)'
            }
        },
        async getDataESelectTinhThanh() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    start: 0,
                    limit: 100
                }
                const response = await sendRequest(
                    Api.superAdmin.quanHuyen.getTinhThanh,
                    null,
                    request_Header,
                    request_Params
                )

                loading.close()
                this.getDataESelect.ESelect_tinhThanh = response.rows.map(
                    (item) => ({
                        ...item,
                        tenDonVi: `${item.tenTinhThanh} - [${item.maTinhThanh}]`,
                        maDonVi: item.maTinhThanh
                    })
                )
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        }
    },

    watch: {
        'authUser.role': {
            immediate: true,
            handler(newRole, oldRole) {
                if (newRole === 4) {
                    this.selectedValue.selectedValueEducationDepartment = {
                        value: this.authUser.ma_don_vi
                    }
                }

                this.selectedValue.selectedValueProvince = [
                    {
                        value: this.authUser.ma_so
                    }
                ]
            }
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        },
        ...mapState(['isCollapseLeftBar'])
    },
    mounted() {
        this.getDataESelect_PhongGiaoDuc()
        this.getDataESelect_VaiTro()
        this.getDataESelectTinhThanh()
        this.handleSearch()
    }
}
</script>

<style scoped>
.layout-btn-search {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.layout-btn {
    padding: 20px 10px 5px 10px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}
.text-center {
    text-align: center;
}
.text-left {
    text-align: left;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.bg-search,
.excel,
.btn-info {
    background-color: var(--teal);
}
.btn-add,
.btn-list,
.btn-import {
    background-color: var(--light-green);
}

.btn-update {
    background-color: var(--yellow);
}
.btn-delete {
    background-color: var(--orange);
}
::v-deep .el-tag.el-tag--info,
::v-deep .el-input.is-disabled .el-input__inner {
    color: black;
}
</style>
