<template>
    <div>
        <CustomBreadcrumb :title="'Tra cứu'" content="Tra cứu Trường học" />
        <CustomContentUI>
            <div class="row">
                <div class="col-3">
                    <label>Đơn vị quản lý</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="
                                this.authUser.role === 5 ||
                                this.authUser.role === 4
                            "
                            :data="this.dataChonDonVi_Store"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['tenDonVi', 'maDonVi']"
                            v-model="selectedValue.selectedValueUnitEducation"
                        />
                    </div>
                </div>
                <div class="col-3">
                    <label>Cấp học</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectGradeLevel"
                            :placeholder="'Chọn '"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['tenTruongHoc', 'value']"
                            v-model="selectedValue.selectedValueGradeLevel"
                        />
                    </div>
                </div>
                <div class="col-3">
                    <label>Nhóm cấp học</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectGradeGroup"
                            :placeholder="'Chọn '"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['title', 'value']"
                            v-model="selectedValue.selectedValueGradeGroup"
                        />
                    </div>
                </div>
                <div class="col-3">
                    <label>Trạng thái hoạt động</label>
                    <div>
                        <ESelectOne
                            style="width: 100%"
                            :clearable="false"
                            :disabled="false"
                            v-model="selectedValue.selectedValueStatus"
                            :data="getDataESelect.ESelectStatus"
                            placeholder="Chọn "
                            :filterable="true"
                            :collapseTags="false"
                            :fields="['title', 'value']"
                            :no-data-text="'Không có bản ghi nào'"
                            :no-match-text="'Không tìm thấy bản ghi nào'"
                        />
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-3">
                    <label>Mã trường học</label>

                    <CustomInput
                        placeholder="Nhập ..."
                        v-model="selectedValue.input_maTruongHoc"
                        :disabled="false"
                        :clearable="false"
                        :show-close-icon="true"
                        size="small"
                    >
                    </CustomInput>
                </div>
                <div class="col-3">
                    <label>Tên trường học</label>

                    <CustomInput
                        placeholder="Nhập ..."
                        v-model="selectedValue.input_tenTruongHoc"
                        :disabled="false"
                        :clearable="false"
                        :show-close-icon="true"
                        size="small"
                    >
                    </CustomInput>
                </div>
                <div class="col-3">
                    <label>Loại hình đào tạo</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectTypeOfEducation"
                            :placeholder="'Chọn '"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['title', 'value']"
                            v-model="selectedValue.selectedValueTypeOfEducation"
                        />
                    </div>
                </div>
            </div>
            <div class="btn-search">
                <CustomButton
                    label="Tìm kiếm"
                    class="btn-default bg-search"
                    size="small"
                    @click="handleSearch"
                />
                <CustomButton
                    :disabled="tableData.length === 0"
                    class="btn-default"
                    label="Xuất Excel"
                    size="small"
                    @click="handleExportExcel"
                    style="background-color: #1e6050"
                />
            </div>
        </CustomContentUI>

        <div
            class="layout-btn"
            :style="{
                'max-width': leftBarWidth()
            }"
        >
            <div>
                <chonSoLuongBanGhi @chonXongSoLuong="ChonSoLuongBanGhi($event)" />
            </div>
            <div>
                <CustomButton
                    label="Import thành lập"
                    size="small"
                    class="btn-default btn-import"
                    @click="handleOpenModalImport"
                />
                <CustomButton
                    label="Thêm mới"
                    size="small"
                    class="btn-default btn-add"
                    @click="handleOpenModalThemMoi"
                />
            </div>
        </div>
        <CustomTable :maxWidth="leftBarWidth()" :columns="tableColumns">
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="11" class="text-tbody">
                            Không có bản ghi nào.
                        </td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td class="text-tbody">
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-tbody">
                        {{ item.maTruongHoc }}
                    </td>
                    <td class="text-tbody text-left">
                        {{ item.tenTruongHoc }}
                    </td>
                    <td class="text-tbody">
                        {{ getTenCapHoc(item.capHoc) }}
                    </td>

                    <td class="text-tbody text-left">
                        {{ getTenNhomCapHoc(item.maNhomCapHoc) }}
                    </td>

                    <td class="text-tbody text-left">
                        {{ item.tenDonVi }}
                    </td>

                    <td class="text-tbody text-left">
                        {{ item.tenQuanHuyen }}
                    </td>

                    <td class="text-tbody">
                        {{ item.tenTinhThanh }}
                    </td>

                    <td class="text-tbody">
                        {{ getLoaiHinhDaoTao(item.loaiHinhDaoTao) }}
                    </td>

                    <td class="text-tbody">
                        {{ item.ngayThanhLap }}
                    </td>

                    <td
                        v-bind:class="[
                            'text-tbody',
                            'text-left',
                            getCssClassByTrangThai(item.trangThai)
                        ]"
                    >
                        {{ getTenTrangThai(item.trangThai) }}
                    </td>

                    <td class="text-tbody">
                        <CustomButton
                            label="Danh sách"
                            class="btn-default btn-list"
                            @click="handleLayDiemDanhSachTruong(item)"
                            size="small"
                        />
                    </td>
                    <td class="text-tbody text-left">
                        <CustomButton
                            label=""
                            icon="el-icon-info"
                            size="medium"
                            class="btn-default btn-info custom-icon"
                            @click="handleDisabledTruongHoc(item)"
                        />
                        <CustomButton
                            @click="handleUpdateModal(item)"
                            label=""
                            icon="el-icon-edit-outline"
                            class="btn-default btn-update custom-icon"
                            size="medium"
                        />
                        <span
                            style="margin-left: 10px"
                            v-if="item.trangThai !== -1"
                        >
                            <CustomButton
                                label=""
                                icon="el-icon-delete"
                                @click="handleDelete(item.maTruongHoc)"
                                size="medium"
                                class="btn-default btn-delete custom-icon"
                            />
                        </span>
                    </td>
                </tr>
            </template>
        </CustomTable>

        <CustomPagination
            v-show="total_rows > 0"
            :tongbanghi="total_rows"
            :soluonghienthi="soLuongBanGhiHienThi"
            :batdau="trangbatdau"
            @pageChange="layLai($event)"
        >
        </CustomPagination>
        <ModalImport
            :importModalVisible="importModalVisible"
            :closeModalImport="closeModalImport"
        />
        <ModalDanhSachDiemTruong
            :danhSachDiemTruongModalVisible="danhSachDiemTruongModalVisible"
            :closeModalDanhSachDiemTruong="closeModalDanhSachDiemTruong"
            :data_danhSachDiemTruong="data_danhSachDiemTruong"
        />
        <ModalUpdateThongTinTruongHoc
            :chiTietTruongHocModalVisible="chiTietTruongHocModalVisible"
            :closeModalTruongHoc="closeModalTruongHoc"
            :data_updateTruongHoc="data_updateTruongHoc"
            @handleSearch="handleSearch"
        />
        <DisabledModalThongTinTruonghoc
            :disabledTruongHocModalVisible="disabledTruongHocModalVisible"
            :closeModalDisabledTruongHoc="closeModalDisabledTruongHoc"
            :data_chiTietTruongHoc="data_chiTietTruongHoc"
        />
        <ModalThemMoiTruongHoc
            :themMoiTruongHocModalVisible="themMoiTruongHocModalVisible"
            :closeModalThemMoi="closeModalThemMoi"
            @handleSearch="handleSearch"
        />
    </div>
</template>
<script>
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'
import ModalImport from './ModalImport.vue'
import ModalThemMoiTruongHoc from './ModalThemMoiTruongHoc.vue'
import DisabledModalThongTinTruonghoc from './DisabledModalThongTinTruonghoc.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import chonSoLuongBanGhi from '@/components/chonSoLuongBanGhi.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import Api from '@/constants/Api'
import {
    ESelectGradeLevel,
    ESelectGradeGroup_MockData,
    ESelectStatus_MockData_TraCuuTruongHoc,
    ESelectTypeOfEducation_MockData,
    ESelectGradeLevel_MockData
} from '@/mock_data'
import sendRequest from '@/services'
import { mapState } from 'vuex'
import ModalDanhSachDiemTruong from './DanhSachDiemTruong/index.vue'
import ModalUpdateThongTinTruongHoc from './ModalUpdateThongTinTruongHoc.vue'
import axios from 'axios'
import { convertCapHocList, filterCapHocList } from '@/utils'
import ESelectOne from '@/components/ESelectOne.vue'
import CustomContentUI from '@/components/CustomContentUI.vue'
import CustomInput from '@/components/CustomInput.vue'
import CustomTable from '@/components/CustomTable.vue'
export default {
    name: 'TraCuuTruongHoc',
    components: {
        CustomBreadcrumb,
        ESelect,
        CustomButton,
        CustomPagination,
        chonSoLuongBanGhi,
        ModalDanhSachDiemTruong,
        ModalUpdateThongTinTruongHoc,
        DisabledModalThongTinTruonghoc,
        ModalThemMoiTruongHoc,
        ModalImport,
        ESelectOne,
        CustomContentUI,
        CustomInput,
        CustomTable
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            soLuongBanGhiHienThi: 25,
            trangbatdau: false,

            tableData: [],
            tableColumns: [
                { key: '1', label: 'STT' },
                { key: '2', label: 'Mã trường học' },
                { key: '3', label: 'Tên trường học' },
                { key: '4', label: 'Cấp học' },
                { key: '5', label: 'Nhóm cấp học' },

                { key: '6', label: 'Đơn vị quản lý' },
                { key: '7', label: 'Quận huyện' },
                { key: '8', label: 'Tỉnh thành' },
                { key: '9', label: 'Loại hình đào tạo' },
                { key: '10', label: 'Ngày thành lập' },
                { key: '11', label: 'Trạng thái' },
                { key: '12', label: 'Điểm trường' },
                { key: '13', label: 'Thao tác' }
            ],
            importModalVisible: false,
            chiTietTruongHocModalVisible: false,
            data_updateTruongHoc: {},
            data_chiTietTruongHoc: {},
            danhSachDiemTruongModalVisible: false,
            data_danhSachDiemTruong: {},
            disabledTruongHocModalVisible: false,
            themMoiTruongHocModalVisible: false,
            getDataESelect: {
                ESelectUnitEducation: [], //donvi
                ESelectGradeLevel: [], //caphoc
                ESelectGradeGroup: ESelectGradeGroup_MockData, //nhomcaphoc
                ESelectStatus: [
                    {
                        title: 'Tất cả',
                        value: ''
                    },
                    {
                        title: 'Đang hoạt động',
                        value: '1'
                    },
                    {
                        title: 'Ngừng hoạt động',
                        value: '0'
                    },
                    {
                        title: 'Đã xóa',
                        value: '-1'
                    }
                ], //trangthai
                ESelectTypeOfEducation: ESelectTypeOfEducation_MockData //loahinhdaotao
            },

            selectedValue: {
                selectedValueUnitEducation: [], //donvi
                selectedValueGradeLevel: [], //caphoc,
                selectedValueGradeGroup: [], //nhomcaphoc
                selectedValueStatus: { value: '1' }, //trangthai
                input_maTruongHoc: '', //matruonghoc
                input_tenTruongHoc: '', //tentruonghoc
                selectedValueTypeOfEducation: [] //loaihinhdaotao
            },
            list_tenTrangThai: [
                {
                    title: 'Giải thể',
                    value: '2'
                },
                {
                    title: 'Đang hoạt động',
                    value: '1'
                },
                {
                    title: 'Ngừng hoạt động',
                    value: '0'
                },
                {
                    title: 'Đã xóa',
                    value: '-1'
                }
            ]
        }
    },
    methods: {
        closeModalImport() {
            this.importModalVisible = false
        },
        closeModalDanhSachDiemTruong() {
            this.danhSachDiemTruongModalVisible = false
        },
        closeModalTruongHoc() {
            this.chiTietTruongHocModalVisible = false
        },
        closeModalDisabledTruongHoc() {
            this.disabledTruongHocModalVisible = false
        },
        closeModalThemMoi() {
            this.themMoiTruongHocModalVisible = false
        },
        getTenCapHoc(value) {
            const item = ESelectGradeLevel_MockData.find(
                (item) => item.value == value
            )
            if (item) {
                return item.tenTruongHoc
            } else {
                return 'Liên cấp ' + value
            }
        },
        getLoaiHinhDaoTao(value) {
            const item = ESelectTypeOfEducation_MockData.find(
                (item) => item.value == value
            )
            if (item) {
                return item.title
            } else {
                return ''
            }
        },
        getTenNhomCapHoc(value) {
            const item = ESelectGradeGroup_MockData.find(
                (item) => item.value == value
            )
            if (item) {
                return item.title
            } else {
                return ''
            }
        },

        getTenTrangThai(value) {
            const item = this.list_tenTrangThai.find(
                (item) => item.value == value.toString()
            )
            if (item) {
                return item.title
            } else {
                return ''
            }
        },
        getCssClassByTrangThai(value) {
            switch (value.toString()) {
                case '1':
                    return 'active'
                case '2':
                    return 'terminated'
                case '0':
                    return 'stopped'
                case '-1':
                    return 'deleted'
                default:
                    return ''
            }
        },
        ChonSoLuongBanGhi(e) {
            this.total_rows = 0
            this.soLuongBanGhiHienThi = e.soluong
        },
        checkTruocKhiTim() {
            this.trangbatdau = !this.trangbatdau
        },
        layLai(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        handleOpenModalImport() {
            this.importModalVisible = true
        },
        handleOpenModalThemMoi() {
            this.themMoiTruongHocModalVisible = true
        },
        async handleUpdateModal(item) {
            this.data_updateTruongHoc = item

            this.chiTietTruongHocModalVisible = true
        },
        async handleDisabledTruongHoc(item) {
            this.data_chiTietTruongHoc = item
            this.disabledTruongHocModalVisible = true
        },
        async handleDelete(id) {
            const request_Header = {
                token: this.authToken
            }
            const request_Params = {
                ma: id
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })

            this.$confirm('Xác nhận xóa bản ghi đã chọn?', 'Thông báo', {
                confirmButtonText: 'Xóa',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const response = await sendRequest(
                        Api.traCuu.traCuuTruongHoc.xoaTruongHoc,
                        null,
                        request_Header,
                        request_Params
                    )
                    if (response.code == 200) {
                        loading.close()
                        this.$message({
                            type: 'success',
                            message: 'Xóa thành công'
                        })
                        await this.handleSearch()
                    }
                    if (response.code != 200) {
                        loading.close()
                        this.$message({
                            type: 'warning',
                            message: 'Xóa thất bại'
                        })
                    }
                })
                .catch(() => {
                    loading.close()
                })
        },
        async handleLayDiemDanhSachTruong(item) {
            this.data_danhSachDiemTruong = item
            this.danhSachDiemTruongModalVisible = true
        },
        async handleSearch() {
            const request_Header = {
                token: this.authToken
            }

            const request_Params = {
                caphoc: this.selectedValue.selectedValueGradeLevel?.value,
                limit: this.limit,
                loaihinhdaotao:
                    this.selectedValue.selectedValueTypeOfEducation?.value,
                ma: this.selectedValue.input_maTruongHoc,
                maNhomCapHoc: this.selectedValue.selectedValueGradeGroup?.value,
                madonvi: this.selectedValue.selectedValueUnitEducation?.value,
                matinhthanh: this.authUser.province,
                start: this.start,
                ten: this.selectedValue.input_tenTruongHoc,
                trangthai: this.selectedValue.selectedValueStatus?.value
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const response = await sendRequest(
                Api.traCuu.traCuuTruongHoc.danhSachTruongHoc,
                null,
                request_Header,
                request_Params
            )

            if (response.code == 200) {
                loading.close()
                this.$message({
                    type: 'success',
                    message: 'Danh sách tìm kiếm'
                })
                this.tableData = response.rows
                this.total_rows = response.total
            }
            if (response.code != 200) {
                loading.close()
                this.$message({
                    type: 'warning',
                    message: response.rd
                })
                this.tableData = []
            }
        },
        async handleExportExcel() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })

            this.$confirm('Xác nhận tải xuống?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })

                .then(async () => {
                    const axiosConfig = {
                        method: 'POST', // Hoặc 'GET' tùy thuộc vào yêu cầu của bạn
                        url: 'https://ioc-api.csdl.edu.vn/internal-api/dmTruongHoc/dowloadExcel',
                        headers: {
                            token: this.authToken,
                            'Content-Type': 'application/json'
                        },
                        params: {
                            start: 0,
                            limit: 10000,
                            caphoc: this.selectedValue.selectedValueGradeLevel
                                ?.value,

                            loaihinhdaotao:
                                this.selectedValue.selectedValueTypeOfEducation
                                    ?.value,
                            ma: this.selectedValue.input_maTruongHoc,
                            maNhomCapHoc:
                                this.selectedValue.selectedValueGradeGroup?.value,
                            madonvi:
                                this.selectedValue.selectedValueUnitEducation
                                    ?.value,
                            matinhthanh: this.authUser.province,

                            ten: this.selectedValue.input_tenTruongHoc,
                            trangthai:
                                this.selectedValue.selectedValueStatus?.value
                        },
                        responseType: 'blob' // Set responseType là 'blob'
                    }

                    const response = await axios(axiosConfig)

                    const blob = new Blob([response.data])
                    const blobUrl = window.URL.createObjectURL(blob)

                    const downloadLink = document.createElement('a')
                    downloadLink.href = blobUrl
                    downloadLink.download = 'tracuutruonghoc.xlsx'
                    downloadLink.style.display = 'none'

                    document.body.appendChild(downloadLink)
                    downloadLink.click()

                    window.URL.revokeObjectURL(blobUrl)
                    loading.close()
                })
                .catch((error) => {
                    loading.close()
                    if (error.response?.data.rd) {
                        this.$message({
                            type: 'error',
                            message: error.response?.data.rd
                        })
                    }
                })
        },
        selectedValueStatus() {
            return
        },
        leftBarWidth() {
            if (this.isCollapseLeftBar) {
                return 'calc(100vw - 125px)'
            } else {
                return 'calc(100vw - 360px)'
            }
        }
    },
    watch: {
        'authUser.role': {
            immediate: true,
            handler(newRole, oldRole) {
                if (newRole === 5) {
                    this.selectedValue.selectedValueUnitEducation = {
                        value: this.authUser.ma_don_vi
                    }

                    const convertedList = convertCapHocList(
                        this.authUser.capHocList
                    )
                    this.selectedValue.selectedValueGradeLevel = convertedList
                    this.selectedValue.selectedValueSchool = {
                        value: this.authUser.ma_truong_hoc
                    }
                }
                if (newRole === 4) {
                    this.selectedValue.selectedValueUnitEducation = {
                        value: this.authUser.ma_don_vi
                    }
                }
                this.getDataESelect.ESelectGradeLevel = filterCapHocList(
                    this.authUser.capHocList
                )
            }
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        },
        ...mapState(['isCollapseLeftBar'])
    },
    mounted() {
        this.handleSearch()
    }
}
</script>

<style scoped>
.layout-btn-add {
    padding: 20px 10px 5px 10px;
    border-radius: 4px 4px 0px 0px;
    margin: 0 auto;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
}
.layout-btn-search {
    margin-top: 15px;
    margin-bottom: 5px;
}

.text-left {
    text-align: left;
}
.btn-search {
    margin-top: 20px;
    justify-content: center;
    display: flex;
}
.layout-btn {
    padding: 20px 10px 5px 10px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}
.text-tbody .custom-icon {
    padding: 8px 9px 8px 15px !important;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.bg-search,
.excel,
.btn-info {
    background-color: var(--teal);
}
.btn-add,
.btn-list,
.btn-import {
    background-color: var(--light-green);
}

.btn-update {
    background-color: var(--yellow);
}
.btn-delete {
    background-color: var(--orange);
}
.terminated {
    color: #e14a02; /* Màu cam đậm */
}

.stopped {
    color: #ffa554; /* Màu cam nhạt */
}

.deleted {
    color: black; /* Màu đen */
}
.active {
    color: #107c41; /* Màu xanh đậm */
}
</style>
