<template>
    <el-dialog
        :visible="themMoiVisible"
        @close="handleResetFormThemMoi"
        width="80%"
        class="custom-dialog"
    >
        <span slot="title" class="custom-dialog-title"
            >Thêm mới tài khoản
            <hr />
        </span>
        <div class="row">
            <div class="col-3">
                <label>Tên tài khoản <span>*</span></label>
                <el-input
                    size="small"
                    placeholder="Nhập ..."
                    v-model="selectedValue.input_tenTaiKhoan"
                ></el-input>
            </div>
            <div class="col-3">
                <label>Tên đăng nhập <span>*</span></label>
                <el-input
                    size="small"
                    placeholder="Nhập ..."
                    v-model="selectedValue.input_tenDangNhap"
                ></el-input>
            </div>
            <div class="col-3">
                <label>Email <span>*</span></label>
                <el-input
                    size="small"
                    placeholder="Nhập ..."
                    v-model="selectedValue.input_email"
                ></el-input>
            </div>
            <div class="col-3">
                <label> Số điện thoại <span>*</span></label>
                <el-input
                    size="small"
                    placeholder="Nhập ..."
                    v-model="selectedValue.input_soDienThoai"
                ></el-input>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-3">
                <label>Vai trò <span>*</span></label>
                <div>
                    <el-select
                        filterable
                        clearable
                        v-model="selectedValue.selectedValue_vaiTro"
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                        @change="handleChange_Vaitro"
                    >
                        <el-option
                            v-for="item in this.ESelectRole"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>

            <div v-if="change_vaiTro === 20 || change_vaiTro === 2" class="col-3">
                <label>Đơn vị quản lý <span>*</span></label>
                <div>
                    <el-select
                        filterable
                        :disabled="change_vaiTro !== 2"
                        clearable
                        v-model="selectedValue.selectedValue_donViQuanLy"
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in ESelect_tinhThanh"
                            :key="item.maDonVi"
                            :label="item.tenDonVi"
                            :value="item.maDonVi"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div v-if="change_vaiTro !== 2 || change_vaiTro !== 20" class="col-3">
                <label>Đơn vị <span>*</span></label>
                <div>
                    <el-select
                        filterable
                        clearable
                        v-model="selectedValue.selectedValue_donVi"
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                        @change="getDataChonTruongHoc"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelectEducationDepartment"
                            :key="item.maDonVi"
                            :label="item.tenDonVi"
                            :value="item.maDonVi"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label
                    >Cấp học
                    <span v-if="change_vaiTro == 5" class="error">*</span></label
                >
                <div>
                    <ESelect
                        :reset="resetESelectSchool"
                        @reset-completed="handleResetSchoolCompleted"
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :data="getDataESelect.ESelectGradeLevel"
                        :placeholder="'Chọn trường học'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenTruongHoc', 'value']"
                        v-model="selectedValue.selectedValue_capHoc"
                        @change="getDataChonTruongHoc"
                    />
                </div>
            </div>
            <div v-if="change_vaiTro == 5" class="col-3">
                <label
                    >Trường học
                    <span v-if="change_vaiTro == 5" class="error">*</span></label
                >
                <div>
                    <el-select
                        filterable
                        clearable
                        size="small"
                        v-model="selectedValue.selectedValue_truongHoc"
                        style="width: 100%"
                        placeholder="Chọn"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelectSchool"
                            :key="item.maTruongHoc"
                            :label="item.tenTruongHoc"
                            :value="item.maTruongHoc"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <div class="layout-btn">
            <CustomButton
                label="Đóng"
                size="small"
                @click="handleResetFormThemMoi"
                class="btn-default btn-delete"
            />
            <CustomButton
                label="Thêm mới"
                class="btn-default btn-add"
                size="small"
                @click="handleAddNew"
            />
        </div>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
import ESelect from '@/components/ESelect.vue'
import CustomButton from '@/components/CustomButton.vue'
import sendRequest from '@/services'
import Api from '@/constants/Api'

import { filterCapHocList } from '@/utils'
export default {
    name: 'ModalDoiMauKhau',
    props: {
        themMoiVisible: {
            type: Boolean,
            required: true
        },

        closeModalThemMoi: {
            type: Function,
            required: true
        },
        ESelectRole: { type: Array, required: true }, //vaitro
        handleSearch: Function,
        dataThemMoi: { type: Array, required: true },
        ESelect_tinhThanh: { type: Array }
    },
    components: { CustomButton, ESelect },
    data() {
        return {
            resetESelectSchool: false,

            getDataESelect: {
                ESelectEducationDepartment: [],
                ESelectGradeLevel: [], //caphoc,
                ESelectSchool: [] //truonghoc
            },

            selectedValue: {
                input_tenTaiKhoan: '',
                input_tenDangNhap: '',
                input_email: '',
                input_soDienThoai: '',
                selectedValue_vaiTro: '',
                selectedValue_donViQuanLy: '',
                selectedValue_donVi: '',
                selectedValue_capHoc: [],
                selectedValue_truongHoc: ''
            },
            change_vaiTro: ''
        }
    },

    methods: {
        async getDataESelect_PhongGiaoDuc() {
            const request_Header = {
                token: this.authToken
            }
            const request_Params = {
                limit: 9999,

                maTinhThanh: this.authUser.province,
                start: 0
            }
            const response = await sendRequest(
                Api.internal_api.dm_DonVi,
                null,
                request_Header,
                request_Params
            )
            this.getDataESelect.ESelectEducationDepartment = response.rows.map(
                (item) => ({
                    tenDonVi: `${item.tenDonVi} - [${item.maDonVi}]`,
                    maDonVi: item.maDonVi
                })
            )
        },
        handleResetSchoolCompleted() {
            this.resetESelectSchool = false
        },

        handleResetFormThemMoi() {
            this.resetESelectSchool = true

            this.selectedValue.input_tenTaiKhoan = ''
            this.selectedValue.input_tenDangNhap = ''
            this.selectedValue.input_email = ''
            this.selectedValue.input_soDienThoai = ''

            this.selectedValue.selectedValue_vaiTro = ''
            this.selectedValue.selectedValue_donViQuanLy = ''
            this.change_vaiTro = ''
            if (this.authUser.role !== 4) {
                this.selectedValue.selectedValue_donVi = ''
            }
            this.selectedValue.selectedValue_truongHoc = ''

            this.closeModalThemMoi()
        },
        handleChange_Vaitro() {
            this.change_vaiTro = this.selectedValue.selectedValue_vaiTro
            this.resetESelectCapHoc = true
            this.selectedValue.selectedValue_capHoc = []
            if (this.authUser.role !== 4) {
                this.selectedValue.selectedValue_donVi = ''
            }
            this.selectedValue.selectedValue_truongHoc = ''
            this.selectedValue.selectedValue_donViQuanLy = this.authUser.province

            this.getDataChonTruongHoc()
        },
        async getDataChonTruongHoc() {
            const maDonVi = this.selectedValue.selectedValue_donVi
                ? [this.selectedValue.selectedValue_donVi]
                : []

            const capHoc = this.selectedValue.selectedValue_capHoc.map((item) =>
                parseInt(item.value)
            )

            const request_Header = {
                token: this.authToken
            }

            const request_Data = {
                capHoc: capHoc,
                maDonVi: maDonVi
            }

            if (this.change_vaiTro == 5) {
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })
                const response = await sendRequest(
                    Api.internal_api.dm_School,
                    request_Data,
                    request_Header,
                    null
                )
                if (response.statusResponse == 0) {
                    loading.close()
                    this.getDataESelect.ESelectSchool = response.rows.map(
                        (item) => ({
                            ...item,
                            tenTruongHoc: `${item.tenTruongHoc} - [${item.maTruongHoc}]`
                        })
                    )
                    this.selectedValue.selectedValue_truongHoc = ''
                } else {
                    loading.close()
                }
            }
        },

        async handleAddNew() {
            const hoTen = this.selectedValue.input_tenTaiKhoan
            const username = this.selectedValue.input_tenDangNhap
            const email = this.selectedValue.input_email
            const sdt = this.selectedValue.input_soDienThoai
            const role = this.selectedValue.selectedValue_vaiTro
            const maDonVi = this.selectedValue.selectedValue_donVi
            const capHocList = this.selectedValue.selectedValue_capHoc.map(
                (item) => parseInt(item)
            )
            const maTruongHoc = this.selectedValue.selectedValue_truongHoc

            const request_Header = {
                token: this.authToken
            }
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            const phoneRegex = /^[0-9]{10}$/

            const validations = [
                {
                    condition: hoTen === '',
                    message: 'Vui lòng bổ sung Tên tài khoản'
                },
                {
                    condition: username === '',
                    message: 'Vui lòng bổ sung Tên đăng nhập'
                },
                { condition: email === '', message: 'Vui lòng bổ sung Email' },
                {
                    condition: !email.match(emailRegex),
                    message: 'Email không hợp lệ'
                },
                {
                    condition: sdt === '',
                    message: 'Vui lòng bổ sung Số điện thoại'
                },
                {
                    condition: !sdt.match(phoneRegex),
                    message: 'Số điện thoại không hợp lệ'
                },
                { condition: role === '', message: 'Vui lòng bổ sung vai trò' },
                {
                    condition:
                        (role == 5 || role == 40 || role == 4) && maDonVi === '',
                    message: 'Vui lòng bổ sung đơn vị'
                },
                {
                    condition:
                        (role == 4 || role == 40 || role == 5) &&
                        capHocList.length === 0,
                    message: 'Vui lòng bổ sung cấp học'
                },

                {
                    condition: role == 5 && maTruongHoc === '',
                    message: 'Vui lòng bổ sung trường học'
                }
            ]

            for (const { condition, message } of validations) {
                if (condition) {
                    this.$message({
                        type: 'warning',
                        message: message
                    })
                    return
                }
            }

            const request_Data = {
                capHocList: capHocList,
                email: email,
                hoTen: hoTen,
                maDonVi:
                    maDonVi ||
                    this.authUser.ma_don_vi ||
                    this.selectedValue.selectedValue_donViQuanLy,
                maTruongHoc: maTruongHoc,
                parentRole: this.authUser.parentRole,
                role: role,
                roleType: 1,
                sdt: sdt,
                trangThai: 1,
                username: username
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            this.$confirm('Xác nhận thêm mới?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const response = await sendRequest(
                        Api.heThong.quanLyNguoiDung.themMoiNguoiDung,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        loading.close()
                        this.$message({
                            type: 'success',
                            message: 'Thêm mới thành công'
                        })
                        await this.closeModalThemMoi()
                        await this.handleSearch()
                    }
                })
                .catch((error) => {
                    loading.close()
                    if (error.response?.data.rd) {
                        this.$message({
                            type: 'error',
                            message: error.response?.data.rd
                        })
                    }
                })
        }
    },
    mounted() {
        this.getDataESelect.ESelectGradeLevel = filterCapHocList(
            this.authUser.capHocList
        )
        this.getDataESelect_PhongGiaoDuc()
    },
    watch: {
        'authUser.role': {
            immediate: true,
            handler(newRole, oldRole) {
                if (newRole === 4) {
                    this.selectedValue.selectedValueEducationDepartment = {
                        value: this.authUser.ma_don_vi
                    }
                    this.selectedValue.selectedValue_donVi =
                        this.authUser.ma_phong
                }
            }
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        }
    }
}
</script>
<style scoped>
.row span {
    color: red;
}
.layout-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
hr {
    border: 1px solid #7ca33f;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}

.btn-add {
    background-color: var(--light-green);
}

.btn-delete {
    background-color: var(--orange);
}
::v-deep .el-input.is-disabled .el-input__inner {
    color: black !important;
}
</style>
