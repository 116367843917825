<template>
    <div class="school">
        <div class="header">
            <div class="left">
                <div>
                    <i class="el-icon-user-solid icon"></i>
                </div>
                <div class="title">Phân loại cán bộ</div>
            </div>
            <div class="right">
                <CustomButton
                    label="Chi tiết"
                    size="small"
                    class="custom-btn"
                    @click="handleNaviagte"
                />
            </div>
        </div>

        <div class="layout-card">
            <div class="row">
                <div class="col-md-4 mb-4">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">Phân loại cán bộ theo chức vụ</div>
                            <div>
                                <CustomButton
                                    type="text"
                                    icon="el-icon-refresh-right"
                                    label=""
                                    size="medium"
                                    @click="
                                        handleRefresh_DataBieuDoTongQuan_PhanLoaiCanBo
                                    "
                                />
                            </div>
                        </div>
                        <div class="card-body">
                            <PieChartCustom
                                :data_PieChart="dataBieuDoTongQuan_PhanLoaiCanBo"
                                :xaxis_categories="xaxisCategories.phanLoaiCanBo"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">Độ tuổi</div>
                            <div>
                                <CustomButton
                                    type="text"
                                    icon="el-icon-refresh-right"
                                    label=""
                                    size="medium"
                                    @click="
                                        handleRefresh_DataBieuDoDoTuoi_PhanLoaiCanBo
                                    "
                                />
                            </div>
                        </div>
                        <div class="card-body">
                            <StackedColumnChart
                                :data_StackedColumnChart="
                                    dataBieuDoDoTuoi_PhanLoaiCanBo
                                "
                                :xaxis_categories="xaxisCategories.doTuoi"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">Loại hình đào tạo</div>
                            <div>
                                <CustomButton
                                    type="text"
                                    icon="el-icon-refresh-right"
                                    label=""
                                    size="medium"
                                    @click="
                                        handleRefresh_DataBieuDoLoaiHinhDaotao_PhanLoaiCanBo
                                    "
                                />
                            </div>
                        </div>
                        <div class="card-body">
                            <StackedColumnChart
                                :data_StackedColumnChart="
                                    dataBieuDoLoaiHinhDaotao_PhanLoaiCanBo
                                "
                                :xaxis_categories="xaxisCategories.loaiHinhDaoTao"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">Trạng thái</div>
                            <div>
                                <CustomButton
                                    type="text"
                                    icon="el-icon-refresh-right"
                                    label=""
                                    size="medium"
                                    @click="
                                        handleRefresh_DataBieuDoTrangThai_PhanLoaiCanBo
                                    "
                                />
                            </div>
                        </div>
                        <div class="card-body">
                            <StackedColumnChart
                                :data_StackedColumnChart="
                                    convert_dataBieuDoTrangThai_PhanLoaiCanBo
                                "
                                :xaxis_categories="xaxisCategories.trangThai"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import { Router } from '@/constants/Router'

import StackedColumnChart from '@/components/StackedColumnChart.vue'
import { Icon } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import PieChartCustom from '@/components/PieChartCustom.vue'
export default {
    name: 'teacherClassification',
    components: {
        CustomButton,

        StackedColumnChart,
        PieChartCustom
    },
    data() {
        return {
            xaxisCategories: {
                phanLoaiCanBo: ['Cán bộ quản lý', 'Giáo viên', 'Nhân viên'],
                doTuoi: [
                    '<20',
                    '20-29',
                    '30-39',
                    '40-49',
                    '50-54',
                    '55-59',
                    '>=60'
                ],

                loaiHinhDaoTao: ['Công lập', 'Tư Thục', 'Dân lập'],
                trangThai: ['Đang làm', 'Chuyển đến', 'Chuyển đi', 'Khác']
            },
            convert_dataBieuDoTrangThai_PhanLoaiCanBo: []
        }
    },
    props: {
        dataBieuDoTongQuan_PhanLoaiCanBo: {
            type: Array,
            required: true
        },
        refresh_dataBieuDoTongQuan_PhanLoaiCanBo: Function,

        dataBieuDoDoTuoi_PhanLoaiCanBo: {
            type: Array,
            required: true
        },
        refresh_dataBieuDoDoTuoi_PhanLoaiCanBo: Function,

        dataBieuDoLoaiHinhDaotao_PhanLoaiCanBo: {
            type: Array,
            required: true
        },
        refresh_dataBieuDoLoaiHinhDaotao_PhanLoaiCanBo: Function,

        dataBieuDoTrangThai_PhanLoaiCanBo: {
            type: Array,
            required: true
        },
        refresh_dataBieuDoTrangThai_PhanLoaiCanBo: Function
    },
    methods: {
        handleRefreshDataWithLoading(emitterFunction) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            emitterFunction()
            loading.close()
        },
        handleRefresh_DataBieuDoTongQuan_PhanLoaiCanBo() {
            this.handleRefreshDataWithLoading(() => {
                this.$emit('refresh_dataBieuDoTongQuan_PhanLoaiCanBo')
            })
        },
        handleRefresh_DataBieuDoDoTuoi_PhanLoaiCanBo() {
            this.handleRefreshDataWithLoading(() => {
                this.$emit('refresh_dataBieuDoDoTuoi_PhanLoaiCanBo')
            })
        },
        handleRefresh_DataBieuDoLoaiHinhDaotao_PhanLoaiCanBo() {
            this.handleRefreshDataWithLoading(() => {
                this.$emit('refresh_dataBieuDoLoaiHinhDaotao_PhanLoaiCanBo')
            })
        },
        handleRefresh_DataBieuDoTrangThai_PhanLoaiCanBo() {
            this.handleRefreshDataWithLoading(() => {
                this.$emit('refresh_dataBieuDoTrangThai_PhanLoaiCanBo')
            })
        },
        handleNaviagte() {
            this.$router.push(Router.traCuu.traCuuCanBoGV)
        }
    },

    watch: {
        dataBieuDoTrangThai_PhanLoaiCanBo(newValue) {
            this.convert_dataBieuDoTrangThai_PhanLoaiCanBo = newValue.map(
                (item) => {
                    item.data = item.data.slice(0, 4)
                    return item
                }
            )
        }
    }
}
</script>

<style scoped>
.school {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 15px;
    padding-left: 6px;
    padding-right: 6px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 10px 12px 10px;
}
.header .left {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #2e8bc0;
    font-weight: 700;
    text-transform: uppercase;
}
.header .left .icon {
    font-size: 18px;
    color: #2e8bc0;
    font-weight: 700;
    margin-right: 5px;
}
.header .right {
    margin-left: 6px;
}

.layout-card {
    padding: 0px 10px 20px 10px;
}
.layout-card .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f2f3f8;
    height: 25px;
    color: black;
    padding: 5px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.layout-card .card-header .title {
    margin: -5px 0px 0px 10px;
    color: #000000;
    font-weight: 700;
    font-size: 15px;
}
.layout-card .card-body {
    padding: 5px;
    background: white;
    border: #f2f3f8 2px solid;
    height: 300px;
}
.custom-btn {
    font-weight: 700;
    color: #fff;
    background-color: #6aab9c;
}
::v-deep .el-icon-user-solid:before {
    font-size: 18px;
    color: #2e8bc0;
}
</style>
