<template>
    <div>
        <CustomBreadcrumb
            :title="' KẾT CHUYỂN DỮ LIỆU LÊN BỘ'"
            content="LỊCH SỬ ĐẨY DỮ LIỆU LÊN BỘ"
        />
        <CustomContentUI>
            <div class="row">
                <div class="col-4">
                    <label>Đơn vị quản lý</label>
                    <div>
                        <span v-if="authUser.role === 4 || authUser.role === 5">
                            <CustomInput
                                v-model="textQuanLyDonVi"
                                placeholder=""
                                :disabled="true"
                                :clearable="false"
                                size="small"
                            >
                            </CustomInput>
                        </span>
                        <span v-else>
                            <ESelect
                                style="width: 100%"
                                no-match-text="Không tìm thấy bản ghi nào"
                                no-data-text="danh sách lựa chọn trống"
                                :clearable="true"
                                :disabled="false"
                                :data="this.dataChonDonVi_Store"
                                :placeholder="'Chọn đơn vị'"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['tenDonVi', 'maDonVi']"
                                :value="selectedValue.selectedValueUnitEducation"
                                @change="
                                    handleESelectChange(
                                        'ESelectUnitEducation',
                                        $event
                                    )
                                "
                            />
                        </span>
                    </div>
                </div>
                <div class="col-2">
                    <label>Chọn cấp học</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectGradeLevel"
                            :placeholder="'Chọn cấp học'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['tenTruongHoc', 'value']"
                            :value="selectedValue.selectedValueGradeLevel"
                            @change="
                                handleESelectChange('ESelectGradeLevel', $event)
                            "
                        />
                    </div>
                </div>
                <div class="col-4">
                    <label>Chọn trường học</label>
                    <div>
                        <ESelect
                            :reset="resetESelectSchool"
                            @reset-completed="handleResetCompleted"
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="authUser.role === 5"
                            :data="getDataESelect.ESelectSchool"
                            :placeholder="'Chọn trường học'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenTruongHoc', 'maTruongHoc']"
                            v-model="selectedValue.selectedValueSchool"
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Năm học <span class="span">*</span></label>
                    <div>
                        <ESelectYear
                            v-model="selectedValue.selectedValueSchoolYear"
                            placeholder="Chọn năm"
                            size="small"
                            :no-data-text="'Không có bản ghi nào'"
                            :no-match-text="'Không tìm thấy bản ghi nào'"
                        ></ESelectYear>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-2">
                    <label>Học kỳ <span class="span">*</span> </label>
                    <div>
                        <ESelectOne
                            :clearable="false"
                            :disabled="false"
                            :value="selectedValue.selectedValueSemester"
                            :data="getDataESelect.ESelectSemester_MockData"
                            placeholder="Chọn học kỳ"
                            :filterable="true"
                            :collapseTags="false"
                            :fields="['tenHocKy', 'value']"
                            :no-data-text="'Không có bản ghi nào'"
                            :no-match-text="'Không tìm thấy bản ghi nào'"
                            @change="
                                handleESlectOneChange(
                                    'ESelectSemester_MockData',
                                    $event
                                )
                            "
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Giai đoạn</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectStage_MockData"
                            :placeholder="'Chọn giai đoạn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['tenGiaiDoan', 'value']"
                            :value="selectedValue.selectedValueStage"
                            @change="
                                handleESelectChange(
                                    'ESelectStage_MockData',
                                    $event
                                )
                            "
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Đối tác </label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectBusinessPartner_MockData"
                            :placeholder="'Chọn đối tác'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['tenDoiTac', 'maDoiTac']"
                            :value="selectedValue.selectedValueBusinessPartner"
                            @change="
                                handleESelectChange(
                                    'ESelectBusinessPartner',
                                    $event
                                )
                            "
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Loại dữ liệu</label>
                    <div>
                        <div>
                            <ESelect
                                style="width: 100%"
                                no-match-text="Không tìm thấy bản ghi nào"
                                no-data-text="danh sách lựa chọn trống"
                                :clearable="true"
                                :disabled="false"
                                :data="getDataESelect.ESelectDataKind_MockData"
                                :placeholder="'Chọn loại dữ liệu'"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['name', 'id']"
                                :value="selectedValue.selectedValueDataKind"
                                @change="
                                    handleESelectChange('ESelectDataKind', $event)
                                "
                            />
                        </div>
                    </div>
                </div>
                <div class="col-2">
                    <label>Trạng thái</label>
                    <div>
                        <div>
                            <ESelect
                                style="width: 100%"
                                no-match-text="Không tìm thấy bản ghi nào"
                                no-data-text="danh sách lựa chọn trống"
                                :clearable="true"
                                :disabled="false"
                                :data="getDataESelect.ESelectStatus_MockData"
                                :placeholder="'Chọn trạng thái'"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['name', 'id']"
                                :value="selectedValue.selectedValueStatus"
                                @change="
                                    handleESelectChange('ESelectStatus', $event)
                                "
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="layout-btn-search">
                <div class="row d-flex justify-content-center align-items-center">
                    <CustomButton
                        label="Tìm kiếm"
                        size="small"
                        class="btn-default bg-search"
                        @click="handleSearch"
                    />

                    <CustomButton
                        :disabled="tableData.length === 0"
                        label="Xuất Excel"
                        size="small"
                        class="btn-default btn-import"
                        @click="handleExportExcel"
                    />
                </div>
            </div>
        </CustomContentUI>

        <div class="layout-btn-table">
            <div>
                <chonSoLuongBanGhi @chonXongSoLuong="ChonSoLuongBanGhi($event)" />
            </div>
            <div>
                <div class="col-1">
                    <CustomButton
                        label="Đẩy lại"
                        size="small"
                        class="btn-default btn-up"
                        @click="handlePushAgain"
                    />
                </div>
            </div>
        </div>
        <CustomTable :maxWidth="leftBarWidth()">
            <template v-slot:header>
                <tr>
                    <th class="text-thead" rowspan="2">STT</th>
                    <th class="text-thead" rowspan="2">Trường học</th>
                    <th class="text-thead" rowspan="2">Mã trường</th>
                    <th class="text-thead" rowspan="2">Cấp học</th>
                    <th class="text-thead" rowspan="2">Đối tác</th>
                    <th class="text-thead" rowspan="2">Năm Học</th>
                    <th class="text-thead" rowspan="2">Học kỳ</th>
                    <th class="text-thead" rowspan="2">Giai đoạn</th>
                    <th class="text-thead" rowspan="2">Loại dữ liệu</th>
                    <th class="text-thead" rowspan="4">Thời gian đẩy</th>
                    <th class="text-thead" rowspan="2">Trạng thái</th>
                    <th class="text-thead" colspan="4">Bản ghi</th>
                    <th class="text-thead" rowspan="2">Xem lỗi</th>
                    <th class="text-thead" rowspan="2">Trạng thái đồng bộ</th>
                    <th class="text-thead" rowspan="2">
                        <input
                            type="checkbox"
                            id="checkbox-all"
                            v-model="selectAll"
                            @change="handleSelectAll"
                            :disabled="tableData.length === 0"
                        />
                    </th>
                </tr>
                <tr>
                    <th class="text-thead">Nhận từ QLNT</th>
                    <th class="text-thead">
                        Đẩy lên bộ <span class="note">*</span>
                    </th>

                    <th class="text-thead">Thành công</th>
                    <th class="text-thead">Thất bại</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21" class="text-tbody">
                            Không có bản ghi nào.
                        </td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td class="text-tbody">
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>
                    <td class="text-tbody text-left">{{ item.tenTruong }}</td>
                    <td class="text-tbody">{{ item.maTruong }}</td>
                    <td class="text-tbody">{{ getCapHoc(item.capHoc) }}</td>
                    <td class="text-tbody">{{ item.maDoiTac }}</td>
                    <td class="text-tbody">
                        {{ item.namHoc }}-{{ parseInt(item.namHoc) + 1 }}
                    </td>

                    <td class="text-tbody">
                        <span v-if="item.hocKy == 1">Học kỳ I</span>
                        <span v-if="item.hocKy == 2">Học kỳ II</span>
                        <span v-if="item.hocKy == 3">Cả năm</span>
                    </td>
                    <td class="text-tbody">
                        <span v-if="item.maGiaiDoan == 'GK1'">Giữa kỳ I</span>
                        <span v-if="item.maGiaiDoan == 'GK2'">Giữa kỳ II</span>
                        <span v-if="item.maGiaiDoan == 'CK1'">Cuối kỳ I</span>
                        <span v-if="item.maGiaiDoan == 'CK2'">Cuối kỳ II</span>
                    </td>
                    <td class="text-tbody text-left">
                        {{ getBuocHienTai(item.buocHienTai) }}
                    </td>
                    <td class="text-tbody">{{ item.timeStart }}</td>
                    <td class="text-tbody text-left">
                        {{ getTrangThaiDay(item.trangThai) }}
                    </td>
                    <td class="text-tbody">
                        {{ item.banGhiNhan }}
                    </td>
                    <td class="text-tbody">
                        {{ item.banGhiGui }}
                    </td>
                    <td class="text-tbody">
                        {{ item.tongThanhCong }}
                    </td>
                    <td class="text-tbody">
                        {{ item.tongThatBai + item.tongHopLoi }}
                    </td>
                    <td class="text-tbody">
                        <div v-if="item.tongThatBai + item.tongHopLoi > 0">
                            <CustomButton
                                label=""
                                type="primary"
                                size="small"
                                icon="el-icon-info"
                                @click="
                                    handleModalInfo(item.messageId, item.id, item)
                                "
                            />
                        </div>
                    </td>
                    <td class="text-tbody text-left">
                        <span v-if="item.error"
                            >{{ item.error }} / {{ item.errorDescription }}</span
                        >
                    </td>
                    <td class="text-tbody">
                        <input
                            type="checkbox"
                            :id="'checkbox-' + index"
                            :checked="isSelected(item.id)"
                            @click="handleCheckboxClick(item.id)"
                        />
                    </td>
                </tr>
            </template>
        </CustomTable>

        <CustomPagination
            v-show="total_rows > 0"
            :tongbanghi="total_rows"
            :soluonghienthi="soLuongBanGhiHienThi"
            :batdau="trangbatdau"
            @pageChange="layLai($event)"
        >
        </CustomPagination>
        <div v-if="tableData.length > 0" class="layout-note">
            <div class="row">
                <div class="col-md-12">
                    <p><b>Ghi chú:</b></p>
                    <div>
                        <span>*</span> : Số lượng bản ghi KQHT đẩy lên Bộ là kết
                        quả của học sinh có trạng thái đang học.
                    </div>
                </div>
            </div>
        </div>
        <!-- ************ Modal Info************ -->
        <el-dialog
            :visible.sync="infoModalVisible"
            @close="closeModalInfo"
            class="custom-dialog"
            :fullscreen="true"
        >
            <span slot="title" class="custom-dialog-title">
                Đẩy dữ liệu {{ getBuocHienTai(titleModal?.buocHienTai) }} -
                Trường: {{ titleModal?.tenTruong }} - [{{ titleModal?.maTruong }}]
                - Năm học: {{ titleModal?.namHoc }} -
                {{ titleModal?.namHoc + 1 }}
                <hr />
            </span>
            <el-tabs type="border-card">
                <el-tab-pane label="1. Lỗi đẩy lên bô">
                    <label
                        style="
                            padding-left: 20px;
                            padding-top: 7px;
                            font-weight: 70;
                        "
                        for=""
                        ><i
                            style="color: red"
                            class="fas fa-exclamation-triangle"
                        ></i>
                        <i
                            >Để xử lý lỗi, vui lòng tra cứu
                            <span style="color: blue; font-weight: 700"
                                >Danh mục lỗi đẩy lên Bộ</span
                            >
                            theo "<span style="font-weight: 700">Mã lỗi</span> "
                            và "<span style="font-weight: 700">Mô tả</span>" để
                            xem nguyên nhân và cách xử lý!</i
                        ></label
                    >
                    <table
                        class="table table-bordered table-hover centered-table"
                    >
                        <thead>
                            <tr>
                                <th class="text-thead">STT</th>
                                <th class="text-thead">
                                    Tên
                                    <span v-if="titleModal?.buocHienTai == '1'">
                                        Trường học
                                    </span>
                                    <span
                                        v-if="
                                            titleModal?.buocHienTai == '2' ||
                                            titleModal?.buocHienTai == '14'
                                        "
                                        >Giáo viên
                                    </span>
                                    <span v-else>Học sinh</span>
                                </th>
                                <th class="text-thead">
                                    Mã
                                    <span v-if="titleModal?.buocHienTai == '1'">
                                        Trường học
                                    </span>
                                    <span
                                        v-if="
                                            titleModal?.buocHienTai == '2' ||
                                            titleModal?.buocHienTai == '14'
                                        "
                                        >Giáo viên
                                    </span>
                                    <span v-else>Học sinh</span>
                                </th>
                                <th class="text-thead">Trạng thái</th>
                                <th class="text-thead">Mã lỗi</th>
                                <th class="text-thead">Mô tả</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(
                                    itemModal, index
                                ) in tableDataModalLoiDayLenBo"
                                :key="index"
                            >
                                <td
                                    v-if="
                                        itemModal.errorDescription !==
                                        'Không có lỗi'
                                    "
                                >
                                    {{ index + 1 }}
                                </td>
                                <td
                                    class="text-left"
                                    v-if="
                                        itemModal.errorDescription !==
                                        'Không có lỗi'
                                    "
                                >
                                    {{ itemModal.name }}
                                </td>
                                <td
                                    v-if="
                                        itemModal.errorDescription !==
                                        'Không có lỗi'
                                    "
                                >
                                    {{ itemModal.clientId }}
                                </td>

                                <td
                                    v-if="
                                        itemModal.errorDescription !==
                                        'Không có lỗi'
                                    "
                                >
                                    Thất bại
                                </td>

                                <td
                                    class="text-left"
                                    v-if="
                                        itemModal.errorDescription !==
                                        'Không có lỗi'
                                    "
                                >
                                    {{ itemModal.error }}
                                </td>

                                <td
                                    class="text-left"
                                    v-if="
                                        itemModal.errorDescription !==
                                        'Không có lỗi'
                                    "
                                >
                                    {{ itemModal.errorDescription }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </el-tab-pane>
                <el-tab-pane label="2. Lỗi đồng bộ">
                    <table
                        class="table table-bordered table-hover centered-table"
                    >
                        <thead>
                            <tr>
                                <th class="text-thead">STT</th>
                                <th class="text-thead">Mã lỗi</th>
                                <th class="text-thead">Tên đối tượng</th>
                                <th class="text-thead">clientId</th>
                                <th class="text-thead">Mô tả lỗi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template
                                v-if="tableDataModalLoiDongBo?.length === 0"
                            >
                                <tr>
                                    <td colspan="6" class="text-tbody">
                                        Không có bản ghi nào.
                                    </td>
                                </tr>
                            </template>
                            <tr
                                v-for="(
                                    itemModal, index
                                ) in tableDataModalLoiDongBo"
                                :key="index"
                            >
                                <td class="text-tbody">
                                    {{ index + 1 }}
                                </td>
                                <td class="text-tbody">
                                    {{ itemModal.error }}
                                </td>
                                <td class="text-tbody">
                                    {{ itemModal.name }}
                                </td>
                                <td class="text-tbody">
                                    {{ itemModal.clientId }}
                                </td>
                                <td class="text-tbody">
                                    {{ itemModal.errorDescription }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </el-tab-pane>
                <el-tab-pane label="3. Danh mục lỗi">
                    <div class="layout-table-modal">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>STT</th>
                                    <th>Mã lỗi</th>
                                    <th>Mô tả của bộ</th>
                                    <th>Nguyên nhân</th>
                                    <th>Cách xử lý</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(
                                        itemModal, index
                                    ) in tableDataModalDanhMucLoi"
                                    :key="index"
                                >
                                    <td>
                                        {{ index + 1 }}
                                    </td>
                                    <td>
                                        {{ itemModal.errorCode }}
                                    </td>
                                    <td>
                                        {{ itemModal.errorDescription }}
                                    </td>
                                    <td>
                                        {{ itemModal.rootCause }}
                                    </td>
                                    <td>
                                        {{ itemModal.solution }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="layout-btn-modal">
                        <CustomButton
                            label="Đóng"
                            size="small"
                            class="btn-delete btn-default"
                            @click="closeModalInfo"
                        />
                    </div>
                </el-tab-pane>
            </el-tabs>
        </el-dialog>
    </div>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'
import ESelectYear from '@/components/ESelectYear.vue'
import ESelectOne from '@/components/ESelectOne.vue'
import chonSoLuongBanGhi from '@/components/chonSoLuongBanGhi.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import {
    ESelectGradeLevel,
    ESelectSemester_MockData,
    ESelectStage_MockData,
    ESelectBusinessPartner_MockData,
    ESelectDataKind_MockData,
    ESelectStatus_MockData
} from '@/mock_data'
import Api from '@/constants/Api'
import sendRequest from '@/services'
import { mapState } from 'vuex'
import axios from 'axios'
import CustomInput from '@/components/CustomInput.vue'
import { convertCapHocList, currentYear, filterCapHocList } from '@/utils'
import CustomContentUI from '@/components/CustomContentUI.vue'
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'LichSuDayDuLieuLenBo',
    components: {
        ESelect,
        CustomButton,
        CustomBreadcrumb,
        ESelectOne,
        ESelectYear,
        chonSoLuongBanGhi,
        CustomPagination,
        CustomInput,
        CustomContentUI,
        CustomTable
    },
    data() {
        return {
            showAlert: false,
            textQuanLyDonVi: '',
            selectAll: false, // Trạng thái chọn tất cả checkbox
            selectedIds: [], // Mảng lưu trữ item.id của các checkbox được chọ
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            soLuongBanGhiHienThi: 25,
            trangbatdau: false,

            infoModalVisible: false,
            resetESelectSchool: false,

            request_Data: {
                hocKy: null,
                limit: 25,
                loaiYeuCau: null,
                maCapHocs: [],
                maDoiTacs: [],
                maTruongs: [],
                maDonVis: [],
                maGiaiDoanKqht: null,

                namHoc: null,
                start: 0,
                trangThai: null
            },
            tableData: [],
            titleModal: null,
            tableDataModalLoiDongBo: [],
            tableDataModalDanhMucLoi: [],
            tableDataModalLoiDayLenBo: [],
            getDataESelect: {
                ESelectUnitEducation: [], //donvi
                ESelectGradeLevel: [], //caphoc
                ESelectSchool: [], //truonghoc
                ESelectShoolYear: [], //namhoc
                ESelectSemester_MockData: ESelectSemester_MockData, //hocky
                ESelectStage_MockData: ESelectStage_MockData, //giaidoan
                ESelectBusinessPartner_MockData: ESelectBusinessPartner_MockData, //doitac
                ESelectDataKind_MockData: ESelectDataKind_MockData, //loaidulieu
                ESelectStatus_MockData: ESelectStatus_MockData //trangthai
            },

            selectedValue: {
                selectedValueUnitEducation: [], //donvi
                selectedValueGradeLevel: [], //caphoc
                selectedValueSchool: [], //truonghoc
                selectedValueSchoolYear: [], //namhoc
                selectedValueSemester: {
                    tenHocKy: 'Học Kỳ 1',
                    value: '1'
                }, //hocky
                selectedValueStage: [], //giaidoan
                selectedValueBusinessPartner: [], //doitac
                selectedValueDataKind: [], //loaidulieu
                selectedValueStatus: [] //trangthai
            },
            DataGetLoaiDuLoai: [
                { name: 'Trường học', key: '1' },
                { name: 'Cán bộ', key: '2' },
                { name: 'Lớp học', key: '3' },
                { name: 'Học sinh', key: '4' },
                { name: 'KQ học tập', key: '5' },
                { name: 'HT c.trình MN', key: '7' },
                { name: 'Xét tốt nghiệp', key: '8' },
                { name: 'Nhập điểm thi lại', key: '9' },
                { name: 'ĐG chuẩn nghề nghiệp', key: '14' }
            ]
        }
    },
    methods: {
        closeModalInfo() {
            this.infoModalVisible = false
            this.tableDataModalDanhMucLoi = []
            this.tableDataModalLoiDayLenBo = []
            this.tableDataModalLoiDongBo = []
        },
        handleCheckboxClick(itemId) {
            const index = this.selectedIds.indexOf(itemId)

            if (index === -1) {
                this.selectedIds.push(itemId)
            } else {
                this.selectedIds.splice(index, 1)
            }

            // Kiểm tra nếu tất cả các checkbox đã được chọn, thì cập nhật giá trị của selectAll
            this.selectAll = this.selectedIds.length === this.tableData.length
        },
        handleSelectAll() {
            // Cập nhật giá trị của tất cả các checkbox trong vòng lặp dựa trên giá trị của selectAll
            if (this.selectAll) {
                this.selectedIds = this.tableData.map((item) => item.id)
            } else {
                this.selectedIds = []
            }
        },
        isSelected(itemId) {
            // Kiểm tra xem itemId có nằm trong selectedIds hay không
            return this.selectedIds.includes(itemId)
        },
        ChonSoLuongBanGhi(e) {
            this.total_rows = 0
            this.soLuongBanGhiHienThi = e.soluong
        },
        checkTruocKhiTim() {
            this.trangbatdau = !this.trangbatdau
        },
        layLai(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        getTrangThaiDay(loai) {
            var res = loai
            switch (loai) {
                case 0:
                    res = 'Chờ đẩy'
                    break
                case 1:
                    res = 'Đang đẩy'
                    break
                case 2:
                    res = 'Đẩy thành công'
                    break
                case 3:
                    res = 'Đẩy lỗi'
                    break
                case 4:
                    res = 'Cần đẩy lại'
                    break
                case 5:
                    res = 'Thiếu mật khẩu kết chuyển'
                    break
                case 9:
                    res = 'Chờ tổng hợp'
                    break
                case 10:
                    res = 'Đang tổng hợp'
                    break
                case 11:
                    res = 'Tổng hợp xong'
                    break
                case 12:
                    res = 'Tổng hợp lỗi'
                    break
                case 13:
                    res = 'Hoàn thành'
                    break
                case 6:
                    res = 'Tài khoản kết chuyển không chính xác'
                    break
                default:
                    res = 'Chưa rõ'
            }
            return res
        },
        getBuocHienTai(buocHienTai) {
            const foundItem = this.DataGetLoaiDuLoai.find(
                (item) => item.key === String(buocHienTai)
            )
            return foundItem ? foundItem.name : ''
        },

        getCapHoc(loai) {
            var res = loai
            switch (loai) {
                case 1:
                    res = 'Cấp 1'
                    break
                case 2:
                    res = 'Cấp 2'
                    break
                case 3:
                    res = 'Cấp 3'
                    break
                case 4:
                    res = 'Nhà trẻ'
                    break
                case 5:
                    res = 'Mẫu giáo'
                    break
                case 6:
                    res = 'GDTX'
                    break
                case 12:
                    res = 'Liên cấp 1+2'
                    break
                case 13:
                    res = 'Liên cấp 1+3'
                    break
                case 23:
                    res = 'Liên cấp 2+3'
                    break
                case 123:
                    res = 'Liên cấp 1+2+3'
                    break
                default:
                    res = loai
            }
            return res
        },

        handleResetCompleted() {
            this.resetESelectSchool = false
        },
        async handleModalInfo(messageId, id, titleModal) {
            try {
                this.titleModal = titleModal
                this.infoModalVisible = true
                const request_Header = {
                    token: this.authToken
                }
                const request_Data = {
                    messageId: messageId,
                    idBcQuaTrinh: id
                }
                const response = await sendRequest(
                    Api.ketChuyenDuLieuLenBo.lichSuKetChuyen.chiTietLoiDongBo,
                    request_Data,
                    request_Header,
                    null
                )
                this.tableDataModalLoiDongBo =
                    response.item.errTransForm?.body?.result?.items?.item

                this.tableDataModalLoiDayLenBo =
                    response?.item?.response?.body?.result?.items?.item
            } catch (error) {
                console.log(error)
            }
        },
        async getDataModalTableDanhMucLoi() {
            const request_Header = {
                token: this.authToken
            }
            const response = await sendRequest(
                Api.ketChuyenDuLieuLenBo.lichSuKetChuyen.danhMucLoi,
                null,
                request_Header,
                null
            )

            this.tableDataModalDanhMucLoi = response.rows
        },
        handleESlectOneChange(source, selectedOptions) {
            switch (source) {
                case 'ESelectSemester_MockData':
                    this.selectedValue.selectedValueSemester = selectedOptions
                    break
            }
        },
        handleESelectChange(source, ...selectedOptions) {
            switch (source) {
                case 'ESelectUnitEducation':
                    this.selectedValue.selectedValueUnitEducation =
                        selectedOptions
                    if (this.authUser.role !== 5) {
                        this.resetESelectSchool = true
                        this.selectedValue.selectedValueSchool = []
                        this.getDataESelectSchool()
                    }
                    break
                case 'ESelectGradeLevel':
                    this.selectedValue.selectedValueGradeLevel = selectedOptions
                    if (this.authUser.role !== 5) {
                        this.resetESelectSchool = true
                        this.selectedValue.selectedValueSchool = []
                        this.getDataESelectSchool()
                    }
                    break
                case 'ESelectSchool_MockData':
                    this.selectedValue.selectedValueSchool = selectedOptions
                    break
                case 'ESelectEducationDataPush_MockData':
                    this.selectedValue.selectedValueChooseDataType =
                        selectedOptions
                    break
                case 'ESelectStage_MockData':
                    this.selectedValue.selectedValueStage = selectedOptions
                    break
                case 'ESelectBusinessPartner':
                    this.selectedValue.selectedValueBusinessPartner =
                        selectedOptions
                    break
                case 'ESelectDataKind':
                    this.selectedValue.selectedValueDataKind = selectedOptions
                    break
                case 'ESelectStatus':
                    this.selectedValue.selectedValueStatus = selectedOptions
            }
        },
        async handleSearch() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const maDonVis = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueUnitEducation,
                    'selectedValueUnitEducation'
                )

                const maCapHocs = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueGradeLevel,
                    'selectedValueGradeLevel'
                )

                const maTruongs = this.selectedValue.selectedValueSchool.map(
                    (item) => item.value
                )

                const namHoc = this.selectedValue.selectedValueSchoolYear

                const hocKy = parseInt(
                    this.selectedValue.selectedValueSemester.value
                )

                const maGiaiDoanKqht = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueStage,
                    'selectedValueStage'
                ).join()
                const maDoiTacs = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueBusinessPartner,
                    'selectedValueBusinessPartner'
                )
                const loaiYeuCau = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueDataKind,
                    'selectedValueDataKind'
                ).join('')
                const trangThai = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueStatus,
                    'selectedValueStatus'
                ).join()
                const start = this.start
                const limit = this.limit
                const request_Header = {
                    token: this.authToken
                }
                const request_Data = {
                    hocKy: hocKy,
                    limit: limit,
                    loaiYeuCau: loaiYeuCau || null,
                    maCapHocs: maCapHocs,
                    maDoiTacs: maDoiTacs,
                    maDonVis: maDonVis,
                    maTruongs: maTruongs,
                    maGiaiDoanKqht: maGiaiDoanKqht || null,

                    namHoc: namHoc || currentYear,
                    start: start,
                    trangThai: trangThai || null
                }

                const response = await sendRequest(
                    Api.ketChuyenDuLieuLenBo.lichSuKetChuyen
                        .danhSachLichSuKetChuyen,
                    request_Data,
                    request_Header
                )

                if (response.code == 200) {
                    setTimeout(() => {
                        loading.close()

                        this.tableData = response.rows
                        this.total_rows = response.total
                    }, 1000)
                } else {
                    setTimeout(() => {
                        loading.close()
                        this.$message({
                            message: response.message,
                            type: 'error'
                        })
                        this.total_rows = 0
                        this.tableData = []
                    }, 1000)
                }
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },
        async handleExportExcel() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const maDonVis = this.customhandleESelectedChange(
                this.selectedValue.selectedValueUnitEducation,
                'selectedValueUnitEducation'
            )

            const maCapHocs = this.customhandleESelectedChange(
                this.selectedValue.selectedValueGradeLevel,
                'selectedValueGradeLevel'
            )

            const maTruongs = this.selectedValue.selectedValueSchool.map(
                (item) => item.value
            )

            const namHoc = this.selectedValue.selectedValueSchoolYear

            const hocKy = parseInt(this.selectedValue.selectedValueSemester.value)

            const maGiaiDoanKqht = this.customhandleESelectedChange(
                this.selectedValue.selectedValueStage,
                'selectedValueStage'
            ).join()
            const maDoiTacs = this.customhandleESelectedChange(
                this.selectedValue.selectedValueBusinessPartner,
                'selectedValueBusinessPartner'
            )
            const loaiYeuCau = this.customhandleESelectedChange(
                this.selectedValue.selectedValueDataKind,
                'selectedValueDataKind'
            ).join('')
            const trangThai = this.customhandleESelectedChange(
                this.selectedValue.selectedValueStatus,
                'selectedValueStatus'
            ).join()

            this.$confirm('Xác nhận tải xuống?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })

                .then(async () => {
                    const domain = process.env.VUE_APP_BACKEND_BASE_URL
                    const requestData = {
                        hocKy: hocKy,
                        maGiaiDoanKqht: maGiaiDoanKqht,

                        namHoc: namHoc || currentYear,
                        trangThai: trangThai,
                        loaiYeuCau: loaiYeuCau,
                        maDonVis: maDonVis,
                        maCapHocs: maCapHocs,
                        maTruongs: maTruongs,
                        maDoiTacs: maDoiTacs
                    }

                    const response = await axios.post(
                        `${domain}/csdlgd-push/Export/dayDuLieuLenBo`,
                        requestData,
                        {
                            headers: {
                                token: this.authToken,
                                'Content-Type': 'application/json'
                            },
                            responseType: 'blob' // Set responseType là 'blob'
                        }
                    )

                    const blob = new Blob([response.data])
                    const blobUrl = window.URL.createObjectURL(blob)

                    const downloadLink = document.createElement('a')
                    downloadLink.href = blobUrl
                    downloadLink.download = 'lichsudaydulieulenbo.xlsx'
                    downloadLink.style.display = 'none'

                    document.body.appendChild(downloadLink)
                    downloadLink.click()

                    window.URL.revokeObjectURL(blobUrl)
                    loading.close()
                })
                .catch((error) => {
                    loading.close()
                    if (error.response?.data.rd) {
                        this.$message({
                            type: 'error',
                            message: error.response?.data.rd
                        })
                    }
                })
        },
        async handlePushAgain() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const selectedIds = this.selectedIds

                if (selectedIds.length === 0) {
                    loading.close()
                    this.$message({
                        message: 'Vui lòng chọn dữ liệu trong bảng',
                        type: 'error'
                    })
                    return
                }

                const request_Header = {
                    token: this.authToken
                }
                const request_Data = {
                    listId: selectedIds
                }
                const response = await sendRequest(
                    Api.ketChuyenDuLieuLenBo.lichSuKetChuyen.updateDayLai,
                    request_Data,
                    request_Header,
                    null
                )

                if (response.rc == 0) {
                    this.$message({
                        type: 'success',
                        message:
                            'Yêu cầu đẩy dữ liệu đang ở trạng thái chờ tổng hợp dữ liệu',
                        customClass: 'custom-message'
                    })

                    this.handleSearch()
                }
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },

        async getDataESelectSchool() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const maDonVi = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueUnitEducation,
                    'selectedValueUnitEducation'
                )

                const capHoc = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueGradeLevel,
                    'selectedValueGradeLevel'
                )

                const request_Header = {
                    token: this.authToken
                }

                const request_Data = {
                    capHoc: capHoc,
                    maDonVi: maDonVi
                }
                const response = await sendRequest(
                    Api.internal_api.dm_School,
                    request_Data,
                    request_Header,
                    null
                )
                this.getDataESelect.ESelectSchool = response.rows.map((item) => ({
                    ...item,
                    tenTruongHoc: `${item.tenTruongHoc} - [${item.maTruongHoc}]`
                }))
                loading.close()
            } catch (error) {
                loading.close()
            }
        },
        async getDataESelectStage() {
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Data = {
                    trangThai: 1
                }
                const request_Params = {
                    start: 0,
                    limit: 9999
                }
                const response = await sendRequest(
                    Api.ketChuyenDuLieuLenBo.lichSuKetChuyen.duLieuDoiTac,
                    request_Data,
                    request_Header,
                    request_Params
                )
                this.getDataESelect.ESelectBusinessPartner_MockData =
                    response.rows
            } catch (error) {
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },
        customhandleESelectedChange(options, valueType) {
            switch (valueType) {
                case 'selectedValueUnitEducation':
                case 'selectedValueSchool':
                case 'selectedValueStage':
                case 'selectedValueBusinessPartner':
                case 'selectedValueDataKind':
                case 'selectedValueStatus':
                    return options.map((option) => option.value)
                case 'selectedValueGradeLevel':
                    return options.map((option) => Number(option.value))
            }
        },
        leftBarWidth() {
            if (this.isCollapseLeftBar) {
                return 'calc(100vw - 125px)'
            } else {
                return 'calc(100vw - 340px)'
            }
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        },
        ...mapState(['isCollapseLeftBar']),
        ...mapState({
            dataTo_KetChuyenDuLieu: (state) =>
                state.data_Navigate_KetChuyenDuLieu.lichSuDayDuLieuLenBo
        })
    },

    watch: {
        'authUser.role': {
            immediate: true,
            handler(newRole, oldRole) {
                if (newRole === 5) {
                    this.selectedValue.selectedValueUnitEducation = [
                        { value: this.authUser.ma_don_vi }
                    ]
                    this.textQuanLyDonVi = this.dataChonDonVi_Store
                        .filter((item) => item.maDonVi == this.authUser.ma_don_vi)
                        .map((item) => item.tenDonVi)
                        ?.shift()
                        ?.trim()

                    const convertedList = convertCapHocList(
                        this.authUser.capHocList
                    )
                    this.selectedValue.selectedValueGradeLevel = convertedList
                    this.selectedValue.selectedValueSchool = [
                        {
                            value: this.authUser.ma_truong_hoc
                        }
                    ]
                }
                if (newRole === 4) {
                    this.selectedValue.selectedValueUnitEducation = [
                        { value: this.authUser.ma_don_vi }
                    ]
                    this.textQuanLyDonVi = this.dataChonDonVi_Store
                        .map((item) => item.tenDonVi)
                        ?.shift()
                        ?.trim()
                }
                this.getDataESelect.ESelectGradeLevel = filterCapHocList(
                    this.authUser.capHocList
                )
            }
        },
        dataTo_KetChuyenDuLieu: {
            immediate: true,
            handler(newValues) {
                if (newValues?.maTruongHoc && newValues) {
                    this.selectedValue.selectedValueSchoolYear =
                        newValues?.namHoc.toString()
                    this.selectedValue.selectedValueSchool = [
                        { value: newValues.maTruongHoc }
                    ]
                }
            }
        }
    },
    mounted() {
        this.getDataESelectSchool()
        this.getDataESelectStage()
        this.getDataModalTableDanhMucLoi()
    }
}
</script>
<style scoped>
.layout-btn-table {
    padding: 20px 0px 5px 10px;
    justify-content: space-between;
    display: flex;
    background: #fff;
    max-width: calc(100vw - 330px);
    margin: 0 auto;
    overflow-x: auto;
}
.layout-btn-search {
    margin-top: 15px;
    margin-bottom: 5px;
}

.layout-note {
    padding: 10px 10px 10px 10px;
    margin-top: 20px;
}
.layout-note b {
    color: blue;
    font-size: 14px;
}
.layout-note span {
    color: red;
}

.custom-list {
    font-weight: bold;
    list-style-type: none;
}

.custom-list li {
    color: green;
    font-weight: bold;
    font-size: 12px;
}

.custom-list li span {
    color: red;
    font-weight: bold;
}

.custom-list li .small-text {
    font-size: 12px;
    color: blue;
    font-weight: 400;
}
.layout-table-modal {
    overflow-x: auto;
}
.layout-btn-modal {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}

.bg-search {
    background-color: var(--teal);
    color: var(--btn-text-color);
}

.btn-add,
.btn-import,
.btn-import:hover {
    background-color: var(--light-green);
}

.btn-up {
    background-color: var(--yellow);
}
.btn-delete {
    background-color: var(--orange);
}
::v-deep .el-input.is-disabled .el-input__inner,
::v-deep .el-tag.el-tag--info {
    color: black !important;
}
</style>
