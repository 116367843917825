<template>
    <div class="card-main">
        <div class="row">
            <div class="col-md-3 col-sm-6">
                <div class="layout-card-title">
                    <div class="title">
                        <span>{{ authUser?.display_name }}</span>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <ChangeTrackerItem
                    :title="'Giáo viên'"
                    :data="dataThongKeTangGiam.bienDongGv"
                    :status="dataThongKeTangGiam.statusBdGv"
                    :percent="dataThongKeTangGiam.percentBienDongGv"
                />
            </div>
            <div class="col-md-3 col-sm-6">
                <ChangeTrackerItem
                    :title="'Học sinh'"
                    :data="dataThongKeTangGiam.bienDongHs"
                    :status="dataThongKeTangGiam.statusBdHs"
                    :percent="dataThongKeTangGiam.percentBienDongHs"
                />
            </div>
        </div>
    </div>
</template>
<script>
import ChangeTrackerItem from '@/components/ChangeTrackerItem.vue'

import { mapState } from 'vuex'
export default {
    name: 'CardRoleSchool',
    components: {
        ChangeTrackerItem
    },
    props: {
        dataThongKeTangGiam: {
            type: Object,
            required: true
        }
    },
    data() {
        return {}
    },
    methods: {},
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped>
.card-main {
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 10px;
}

.layout-card-title {
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.title {
    background: #ac593d;
    border-radius: 10px;
    padding: 10px 5px 10px 10px;
    color: white;
    font-size: 18px !important;
    height: 110px;
}
</style>
