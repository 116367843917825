<template>
    <div id="chart" class="chart-container">
        <div id="chart">
            <apexchart
                type="line"
                :height="300"
                :options="chartOptions"
                :series="series"
            ></apexchart>
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
    name: 'EducationalCompetenceChart',
    components: {
        apexchart: VueApexCharts
    },
    props: {
        series: {
            type: Array,
            default: () => []
        },
        categories: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            chartOptions: {
                chart: {
                    height: 350,
                    type: 'line',
                    dropShadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2
                    },
                    toolbar: {
                        show: false
                    }
                },
                colors: [
                    '#E14A02',
                    '#FFA554',
                    '#8DAF88',
                    '#FFDFA5',
                    '#325434',
                    '#7D5510'
                ],
                dataLabels: {
                    enabled: true,
                    formatter: function (val, opts) {
                        return val.toLocaleString()
                    },
                    style: {
                        fontSize: '12px',
                        fontWeight: 'bold'
                    }
                },
                stroke: {
                    curve: 'smooth'
                },
                title: {
                    align: 'left'
                },
                grid: {
                    padding: {
                        left: 13.5,
                        right: 0
                    },
                    xaxis: {
                        lines: {
                            show: false
                        }
                    }
                },
                markers: {
                    size: 1
                },
                xaxis: {
                    categories: this.categories
                },
                yaxis: {
                    axisTicks: {
                        show: true
                    },
                    axisBorder: {
                        show: true
                    },
                    labels: {
                        hideOverlappingLabels: false
                    }
                },
                noData: {
                    text: 'Biểu đồ chưa có dữ liệu',
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '14px',
                        fontFamily: undefined
                    }
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'center'
                }
            }
        }
    }
}
</script>

<style scoped></style>
