<template>
    <el-card>
        <div class="avatar-container">
            <div class="block">
                <el-avatar
                    shape="square"
                    :size="170"
                    :src="avatar ? avatar : squareUrl"
                >
                </el-avatar>
            </div>
        </div>
        <div class="text-container">
            <div>Mã định danh bộ GD&ĐT:</div>
            <span>{{ moetCode }}</span>
        </div>
    </el-card>
</template>

<script>
export default {
    props: {
        moetCode: String,
        avatar: String
    },
    data() {
        return {
            sizeList: ['large', 'medium', 'small'],
            squareUrl:
                'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png'
        }
    }
}
</script>

<style scoped>
.avatar-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-container {
    text-align: center;
    color: blue;
}
</style>
