import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        data_Navigate_KetChuyenDuLieu: {
            lichSuTiepNhanDuLieu: null,
            lichSuDayDuLieuLenBo: null,
            traCuuCanBoGV: null,
            traCuuHocSinh: null,
            traCuuLopHoc: null,
            traCuuKQHT: null
        },

        isCollapseLeftBar: false,

        auth: {
            user: null,
            token: null
        },
        dataChonDonVi_Store: [],
        dataChonDanToc_Stote: [],
        dataChonTonGiao_Store: [],
        dataChonNhomMau_Store: [],
        dataChonThanhphangiadinh_Store: [],
        dataChonKhuVuc_Store: [],
        dataChonLoaikhuyetat_Store: [],
        dataChonDoituongchinhsach_Store: [],
        dataChonDoituonguutien_Store: [],
        dataChonHuongnghiepdaynghe_Store: [],
        dataChonLoaiNhapHoc_Store: [],
        dataChonHocBanTru_Store: [],
        dataChonNgoaiNgu_Store: [],
        dataChonLopDaoTaoBoiDuong_Store: [],
        dataChonTrinhDoChuyenMonNghiepVu: [],
        dataChonTrangthaicanbo: [],
        dataChonLoaicanbo: [],
        dataChonMondaygiaovien: [],
        dataChonHinhthuchopdong: [],
        dataChonTrinhdodaotao: [],
        dataChonChuannghenghiep: [],
        dataChonNhiemVuKiemNghiem: [],
        dataChonNganhCC: [],
        dataChonBoiDuongTX: [],
        dataChonTrinhDoLLCT: [],
        dataChonTrinhDoQLGD: [],
        dataChonBoiDuongNghiepVu: [],
        dataChonBoiDuongCBQLCotCan: [],
        dataChonBoiDuongThaySach: [],
        dataChonTrinhDoNgoaiNgu: [],
        dataChonNhomCChiNNgu: [],
        dataChonLoaiCChiNNgu: [],
        dataChonKhungNLucNNgu: [],
        dataChonTrinhDoTinHoc: [],
        dataChonChuyenMon: [],
        dataChonCChiTiengDTocTSo: [],
        dataChonDGVienChuc: [],
        dataChonDanhGiaGVGioi: [],
        dataChonGVCNGIOI: [],
        dataChonTongPhuTrachDoiGioi: [],
        dataChonNhomCanBo: [],
        dataChonLoaiHinhTruong: [],
        dataChonDatChuanQG: [],
        dataChonGiaiDoanKQHT: [],
        dataChonLoaiTotNghiep: [],
        dataChonHinhThucDaoTao: [],
        dataChonVanBangChungChi: [],
        dataChonKhoaDaoTaoSuPham: [],
        dataChonDonViBoiDuong: [],
        dataChonDonViToChuc: [],
        dataChonThoiLuongBoiDuong: [],
        dataChonLoaihinhboiduong: [],
        dataChonHinhThuc: [],
        dataChonKetQuaSuPham: [],
        dataChonKyLuatGV: [],
        dataChonTietHoc: [],
        dataChonNganhDaoTao: [],
        dataChonTrinhDoVanHoa: [],
        dataChonTrinhDoQLNN: [],
        dataCHonKhoi: [],
        dataChonTinhTrangDinhDuong: [],
        dataChonLyDoThoiHoc: []
    },
    getters: {},
    mutations: {
        setIsCollapse(state, newValue) {
            state.isCollapseLeftBar = newValue
        },

        // <!-- ************ Auth ************ -->
        SET_AUTH_USER(state, user) {
            state.auth.user = user

            localStorage.setItem('user', JSON.stringify(user))
        },
        SET_AUTH_TOKEN(state, token) {
            state.auth.token = token

            localStorage.setItem('token', token)
        },

        //load lại trang thì vẫn còn thông tin User, Token
        RESTORE_AUTH_USER(state, user) {
            state.auth.user = user
        },
        RESTORE_AUTH_TOKEN(state, token) {
            state.auth.token = token
        },

        // <!-- ************ data Chọn đơn vị ************ -->
        SET_DATACHONDONVI_STORE(state, dataChonDonVi) {
            state.dataChonDonVi_Store = dataChonDonVi
            localStorage.setItem('data_ChonDonVi', JSON.stringify(dataChonDonVi))
        },
        RESTORE_DATA_CHONDONVI(state, data_ChonDonVi) {
            state.dataChonDonVi_Store = data_ChonDonVi
        },

        // <!-- ************ data Chọn dân tộc ************ -->
        SET_DATACHONDANTOC_STORE(state, dataChonDantoc) {
            state.dataChonDanToc_Stote = dataChonDantoc
            localStorage.setItem(
                'data_ChonDantoc',
                JSON.stringify(dataChonDantoc)
            )
        },
        RESTORE_DATA_CHONDANTOC(state, data_ChonDantoc) {
            state.dataChonDanToc_Stote = data_ChonDantoc
        },

        // <!-- ************ data Chọn tôn giáo ************ -->
        SET_DATACHONTONGIAO_STORE(state, dataChonTongiao) {
            state.dataChonTonGiao_Store = dataChonTongiao
            localStorage.setItem(
                'data_ChonTongiao',
                JSON.stringify(dataChonTongiao)
            )
        },
        RESTORE_DATA_CHONTONGIAO(state, data_ChonTongiao) {
            state.dataChonTonGiao_Store = data_ChonTongiao
        },

        // <!-- ************ data Chọn nhóm máu ************ -->
        SET_DATACHONNHOMMAU_STORE(state, dataChonNhommau) {
            state.dataChonNhomMau_Store = dataChonNhommau
            localStorage.setItem(
                'data_ChonNhommau',
                JSON.stringify(dataChonNhommau)
            )
        },
        RESTORE_DATA_CHONNHOMMAU(state, data_ChonNhommau) {
            state.dataChonNhomMau_Store = data_ChonNhommau
        },
        // <!-- ************ data Chọn thành phần gia đình ************ -->
        SET_DATACHONTHANHPHANGIADINH_STORE(state, dataChonThanhphangiadinh) {
            state.dataChonThanhphangiadinh_Store = dataChonThanhphangiadinh
            localStorage.setItem(
                'data_ChonThanhphangiadinh',
                JSON.stringify(dataChonThanhphangiadinh)
            )
        },
        RESTORE_DATA_CHONTHANHPHANGIADINH(state, data_ChonThanhphangiadinh) {
            state.dataChonThanhphangiadinh_Store = data_ChonThanhphangiadinh
        },
        // <!-- ************ data Chọn khu vực ************ -->
        SET_DATACHONKHUVUC_STORE(state, dataChonKhuvuc) {
            state.dataChonKhuVuc_Store = dataChonKhuvuc
            localStorage.setItem(
                'data_ChonKhuvuc',
                JSON.stringify(dataChonKhuvuc)
            )
        },
        RESTORE_DATA_CHONKHUVUC(state, data_ChonKhuvuc) {
            state.dataChonKhuVuc_Store = data_ChonKhuvuc
        },

        // <!-- ************ data Chọn loại khuyết tật ************ -->
        SET_DATACHONLOAIKHUYETTAT_STORE(state, dataChonLoaikhuyettat) {
            state.dataChonLoaikhuyetat_Store = dataChonLoaikhuyettat
            localStorage.setItem(
                'data_ChonLoaikhuyettat',
                JSON.stringify(dataChonLoaikhuyettat)
            )
        },
        RESTORE_DATA_CHONLOAIKHUYETTAT(state, data_ChonLoaikhuyettat) {
            state.dataChonLoaikhuyetat_Store = data_ChonLoaikhuyettat
        },

        // <!-- ************ data Chọn đối tượng chính sách ************ -->
        SET_DATACHONDOITUONGCHINHSACH_STORE(state, dataChonDoituongchinhsach) {
            state.dataChonDoituongchinhsach_Store = dataChonDoituongchinhsach
            localStorage.setItem(
                'data_ChonDoituongchinhsach',
                JSON.stringify(dataChonDoituongchinhsach)
            )
        },
        RESTORE_DATA_CHONDOITUONGCHINHSACH(state, data_ChonDoituongchinhsach) {
            state.dataChonDoituongchinhsach_Store = data_ChonDoituongchinhsach
        },

        // <!-- ************ data Chọn đối tượng ưu tiên ************ -->
        SET_DATACHONDOITUONGUUTIEN_STORE(state, dataChonDoituonguutien) {
            state.dataChonDoituonguutien_Store = dataChonDoituonguutien
            localStorage.setItem(
                'data_ChonDoituonguutien',
                JSON.stringify(dataChonDoituonguutien)
            )
        },
        RESTORE_DATA_CHONDOITUONGUUTIEN(state, data_ChonDoituonguutien) {
            state.dataChonDoituonguutien_Store = data_ChonDoituonguutien
        },

        // <!-- ************ data Chọn hướng nghiệp dậy nghề ************ -->
        SET_DATACHONHUONGNGHIEPDAYNGHE_STORE(state, dataChonhuongnghiepdaynghe) {
            state.dataChonHuongnghiepdaynghe_Store = dataChonhuongnghiepdaynghe
            localStorage.setItem(
                'data_ChonHuongnghiepdaynghe',
                JSON.stringify(dataChonhuongnghiepdaynghe)
            )
        },
        RESTORE_DATA_CHONHUONGNGHIEPDAYNGHE(
            state,
            data_ChonDoihuongnghiepdaynghe
        ) {
            state.dataChonHuongnghiepdaynghe_Store =
                data_ChonDoihuongnghiepdaynghe
        },
        // <!-- ************ data Chọn Loại nhập học ************ -->
        SET_DATACHONLOAINHAPHOC_STORE(state, dataChonloainhaphoc) {
            state.dataChonLoaiNhapHoc_Store = dataChonloainhaphoc
            localStorage.setItem(
                'data_ChonLoainhaphoc',
                JSON.stringify(dataChonloainhaphoc)
            )
        },
        RESTORE_DATA_CHONLOAINHAPHOC(state, data_ChonLoainhaphoc) {
            state.dataChonLoaiNhapHoc_Store = data_ChonLoainhaphoc
        },
        // <!-- ************ data Chọn học bán trú ************ -->
        SET_DATACHONHOCBANTRU_STORE(state, dataChonhocbantru) {
            state.dataChonHocBanTru_Store = dataChonhocbantru
            localStorage.setItem(
                'data_ChonHocbantru',
                JSON.stringify(dataChonhocbantru)
            )
        },
        RESTORE_DATA_CHONHOCBANTRU(state, data_Chonhocbantru) {
            state.dataChonHocBanTru_Store = data_Chonhocbantru
        },

        // <!-- ************ data Chọn Ngoại ngữ ************ -->
        SET_DATACHONNGOAINGU_STORE(state, dataChonngoaingu) {
            state.dataChonNgoaiNgu_Store = dataChonngoaingu
            localStorage.setItem(
                'data_ChonNgoaingu',
                JSON.stringify(dataChonngoaingu)
            )
        },
        RESTORE_DATA_CHONNGOAINGU(state, data_Chonngoaingu) {
            state.dataChonNgoaiNgu_Store = data_Chonngoaingu
        },

        // <!-- ************ data Chọn Lớp đào tạo bồi dưỡng ************ -->
        SET_DATACHONLOPDAOTAOBOIDUONG_STORE(state, dataChonLopdaotaoboiduong) {
            state.dataChonLopDaoTaoBoiDuong_Store = dataChonLopdaotaoboiduong
            localStorage.setItem(
                'data_ChonLopdaotaoboiduong',
                JSON.stringify(dataChonLopdaotaoboiduong)
            )
        },
        RESTORE_DATA_CHONLOPDAOTAOBOIDUONG(state, data_ChoLopdaotaoboiduong) {
            state.dataChonLopDaoTaoBoiDuong_Store = data_ChoLopdaotaoboiduong
        },

        // <!-- ************ data Chọn Trình độ chuyên môn nghiệp vụ ************ -->
        SET_DATACHONTRINHDOCHUYENMONNGHIEPVU_STORE(
            state,
            dataChonTrinhdochuyenmonnghiepvu
        ) {
            state.dataChonTrinhDoChuyenMonNghiepVu =
                dataChonTrinhdochuyenmonnghiepvu
            localStorage.setItem(
                'data_ChonTrinhdochuyenmonnghiepvu',
                JSON.stringify(dataChonTrinhdochuyenmonnghiepvu)
            )
        },
        RESTORE_DATA_CHONTRINHDOCHUYENMONNGHIEPVU(
            state,
            data_ChonTrinhdochuyenmonnghiepvu
        ) {
            state.dataChonTrinhDoChuyenMonNghiepVu =
                data_ChonTrinhdochuyenmonnghiepvu
        },

        // <!-- ************ data Chọn Trạng thái cán bộ ************ -->
        SET_DATACHONTRANGTHAICANBO_STORE(state, dataChonTrangthaicanbo) {
            state.dataChonTrangthaicanbo = dataChonTrangthaicanbo
            localStorage.setItem(
                'data_ChonTrangthaicanbo',
                JSON.stringify(dataChonTrangthaicanbo)
            )
        },
        RESTORE_DATA_CHONTRANGTHAICANBO(state, data_ChonTrangthaicanbo) {
            state.dataChonTrangthaicanbo = data_ChonTrangthaicanbo
        },

        // <!-- ************ data Chọn Loại cán bộ (chức vụ) ************ -->
        SET_DATACHONLOAICANBO_STORE(state, dataChonLoaicanbo) {
            state.dataChonLoaicanbo = dataChonLoaicanbo
            localStorage.setItem(
                'data_ChonLoaicanbo',
                JSON.stringify(dataChonLoaicanbo)
            )
        },
        RESTORE_DATA_CHONLOAICANBO(state, data_ChonLoaicanbo) {
            state.dataChonLoaicanbo = data_ChonLoaicanbo
        },

        // <!-- ************ data Chọn môn dạy giáo viên ************ -->
        SET_DATACHONMONDAYGIAOVIEN_STORE(state, dataChonMondaygiaovien) {
            state.dataChonMondaygiaovien = dataChonMondaygiaovien
            localStorage.setItem(
                'data_ChonMondaygiaovien',
                JSON.stringify(dataChonMondaygiaovien)
            )
        },
        RESTORE_DATA_CHONMONDAYGIAOVIEN(state, data_ChonMondaygiaovien) {
            state.dataChonMondaygiaovien = data_ChonMondaygiaovien
        },

        // <!-- ************ data Chọn hình thức hợp đồng ************ -->
        SET_DATACHONHINHTHUCHOPDONG_STORE(state, dataChonHinhthuchopdong) {
            state.dataChonHinhthuchopdong = dataChonHinhthuchopdong
            localStorage.setItem(
                'data_ChonHinhthuchopdong',
                JSON.stringify(dataChonHinhthuchopdong)
            )
        },
        RESTORE_DATA_CHONHINHTHUCHOPDONG(state, data_Chonhinhthuchopdong) {
            state.dataChonHinhthuchopdong = data_Chonhinhthuchopdong
        },

        // <!-- ************ data Chọn trình độ đào tạo ************ -->
        SET_DATACHONTRINHDODAOTAO_STORE(state, dataChonTrinhdodaotao) {
            state.dataChonTrinhdodaotao = dataChonTrinhdodaotao
            localStorage.setItem(
                'data_ChonTrinhdodaotao',
                JSON.stringify(dataChonTrinhdodaotao)
            )
        },
        RESTORE_DATA_CHONTRINHDODAOTAO(state, data_ChonTrinhdodaotao) {
            state.dataChonTrinhdodaotao = data_ChonTrinhdodaotao
        },

        // <!-- ************ data Chọn chuẩn nghề nghiệp ************ -->
        SET_DATACHONCHUANGHENGHIEP_STORE(state, dataChonChuannghenghiep) {
            state.dataChonChuannghenghiep = dataChonChuannghenghiep
            localStorage.setItem(
                'data_ChonChuannghenghiep',
                JSON.stringify(dataChonChuannghenghiep)
            )
        },
        RESTORE_DATA_CHONCHUANNGHENGHIEP(state, data_ChonChuannghenghiep) {
            state.dataChonChuannghenghiep = data_ChonChuannghenghiep
        },

        // <!-- ************ data Chọn Nhiệm vụ kiêm nghiệm ************ -->
        SET_DATACHONNHIEMVUKIEMNGHIEM_STORE(state, dataChonNhiemVuKiemNghiem) {
            state.dataChonNhiemVuKiemNghiem = dataChonNhiemVuKiemNghiem
            localStorage.setItem(
                'data_ChonNhiemvukiemnghiem',
                JSON.stringify(dataChonNhiemVuKiemNghiem)
            )
        },
        RESTORE_DATA_CHONNHIEMVUKIEMNGHIEM(state, data_ChonNhiemvukiemnghiem) {
            state.dataChonNhiemVuKiemNghiem = data_ChonNhiemvukiemnghiem
        },

        // <!-- ************ data Chọn Ngành CC ************ -->
        SET_DATACHONNGANHCC_STORE(state, dataChonNganhCC) {
            state.dataChonNganhCC = dataChonNganhCC
            localStorage.setItem(
                'data_ChonNganhCC',
                JSON.stringify(dataChonNganhCC)
            )
        },
        RESTORE_DATA_CHONNGANHCC(state, data_ChonNganhCC) {
            state.dataChonNganhCC = data_ChonNganhCC
        },

        // <!-- ************ data Chọn Bồi dưỡng TX ************ -->
        SET_DATACHONBOIDUONGTX_STORE(state, dataChonBoiDuongTX) {
            state.dataChonBoiDuongTX = dataChonBoiDuongTX
            localStorage.setItem(
                'data_ChonBoiDuongTX',
                JSON.stringify(dataChonBoiDuongTX)
            )
        },
        RESTORE_DATA_CHONBOIDUONGTX(state, data_ChonBoiDuongTX) {
            state.dataChonBoiDuongTX = data_ChonBoiDuongTX
        },

        // <!-- ************ data Chọn Trình độ LLCT ************ -->
        SET_DATACHONTRINHDOLLCT_STORE(state, dataChonTrinhDoLLCT) {
            state.dataChonTrinhDoLLCT = dataChonTrinhDoLLCT
            localStorage.setItem(
                'data_ChonTrinhDoLLCT',
                JSON.stringify(dataChonTrinhDoLLCT)
            )
        },
        RESTORE_DATA_CHONTRINHDOLLCT(state, data_ChonTrinhDoLLCT) {
            state.dataChonTrinhDoLLCT = data_ChonTrinhDoLLCT
        },

        // <!-- ************ data Chọn Trình độ QLGD ************ -->
        SET_DATACHONTRINHDOQLGD_STORE(state, dataChonTrinhDoQLGD) {
            state.dataChonTrinhDoQLGD = dataChonTrinhDoQLGD
            localStorage.setItem(
                'data_ChonTrinhDoQLGD',
                JSON.stringify(dataChonTrinhDoQLGD)
            )
        },
        RESTORE_DATA_CHONTRINHDOQLGD(state, data_ChonTrinhDoQLGD) {
            state.dataChonTrinhDoQLGD = data_ChonTrinhDoQLGD
        },

        // <!-- ************ data Chọn Bồi dưỡng nghiệp vụ ************ -->
        SET_DATACHONBOIDUONGNGHIEPVU_STORE(state, dataChonBoiDuongNghiepVu) {
            state.dataChonBoiDuongNghiepVu = dataChonBoiDuongNghiepVu
            localStorage.setItem(
                'data_ChonBoiDuongNghiepVu',
                JSON.stringify(dataChonBoiDuongNghiepVu)
            )
        },
        RESTORE_DATA_CHONBOIDUONGNGHIEPVU(state, data_ChonBoiDuongNghiepVu) {
            state.dataChonBoiDuongNghiepVu = data_ChonBoiDuongNghiepVu
        },

        // <!-- ************ data Chọn Bồi dưỡng CBQL Cốt cán ************ -->
        SET_DATACHONBOIDUONGCBQLCOTCAN_STORE(state, dataChonBoiDuongCBQLCotCan) {
            state.dataChonBoiDuongCBQLCotCan = dataChonBoiDuongCBQLCotCan
            localStorage.setItem(
                'data_ChonBoiDuongCBQLCotCan',
                JSON.stringify(dataChonBoiDuongCBQLCotCan)
            )
        },
        RESTORE_DATA_CHONBOIDUONGCBQLCOTCAN(state, data_ChonBoiDuongCBQLCotCan) {
            state.dataChonBoiDuongCBQLCotCan = data_ChonBoiDuongCBQLCotCan
        },

        // <!-- ************ data Chọn Bồi dưỡng thay sách ************ -->
        SET_DATACHONBOIDUONGTHAYSACH_STORE(state, dataChonBoiDuongThaySach) {
            state.dataChonBoiDuongThaySach = dataChonBoiDuongThaySach
            localStorage.setItem(
                'data_ChonBoiDuongThaySach',
                JSON.stringify(dataChonBoiDuongThaySach)
            )
        },
        RESTORE_DATA_CHONBOIDUONGTHAYSACH(state, data_ChonBoiDuongThaySach) {
            state.dataChonBoiDuongThaySach = data_ChonBoiDuongThaySach
        },

        // <!-- ************ data Chọn Bồi dưỡng thay sách ************ -->
        SET_DATACHONTrinhDoNgoaiNgu_STORE(state, dataChonTrinhDoNgoaiNgu) {
            state.dataChonTrinhDoNgoaiNgu = dataChonTrinhDoNgoaiNgu
            localStorage.setItem(
                'data_ChonTrinhDoNgoaiNgu',
                JSON.stringify(dataChonTrinhDoNgoaiNgu)
            )
        },
        RESTORE_DATA_CHONTrinhDoNgoaiNgu(state, data_ChonTrinhDoNgoaiNgu) {
            state.dataChonTrinhDoNgoaiNgu = data_ChonTrinhDoNgoaiNgu
        },

        // <!-- ************ data Chọn Nhóm chứng chỉ ngoại ngữ ************ -->
        SET_DATACHONNHOMCCHINNGU_STORE(state, dataChonNhomCChiNNgu) {
            state.dataChonNhomCChiNNgu = dataChonNhomCChiNNgu
            localStorage.setItem(
                'data_ChonNhomCChiNNgu',
                JSON.stringify(dataChonNhomCChiNNgu)
            )
        },
        RESTORE_DATA_CHONNHOMCCHINNGU(state, data_ChonNhomCChiNNgu) {
            state.dataChonNhomCChiNNgu = data_ChonNhomCChiNNgu
        },

        // <!-- ************ data Chọn Loại chứng chỉ ngoại ngữ ************ -->
        SET_DATACHONLOAICCHINNGU_STORE(state, dataChonLoaiCChiNNgu) {
            state.dataChonLoaiCChiNNgu = dataChonLoaiCChiNNgu
            localStorage.setItem(
                'data_ChonLoaiCChiNNgu',
                JSON.stringify(dataChonLoaiCChiNNgu)
            )
        },
        RESTORE_DATA_CHONLOAICCHINNGU(state, data_ChonLoaiCChiNNgu) {
            state.dataChonLoaiCChiNNgu = data_ChonLoaiCChiNNgu
        },

        // <!-- ************ data Chọn Khung năng lực ngoại ngữ ************ -->
        SET_DATACHONKHUNGNLUCNNGU_STORE(state, dataChonKhungNLucNNgu) {
            state.dataChonKhungNLucNNgu = dataChonKhungNLucNNgu
            localStorage.setItem(
                'data_ChonKhungNLucNNgu',
                JSON.stringify(dataChonKhungNLucNNgu)
            )
        },
        RESTORE_DATA_CHONKHUNGNLUCNNGU(state, data_ChonKhungNLucNNgu) {
            state.dataChonKhungNLucNNgu = data_ChonKhungNLucNNgu
        },

        // <!-- ************ data Chọn Trình độ tin học ************ -->
        SET_DATACHONTRINHDOTINHOC_STORE(state, dataChonTrinhDoTinHoc) {
            state.dataChonTrinhDoTinHoc = dataChonTrinhDoTinHoc
            localStorage.setItem(
                'data_ChonTrinhDoTinHoc',
                JSON.stringify(dataChonTrinhDoTinHoc)
            )
        },
        RESTORE_DATA_CHONTRINHDOTINHOC(state, data_ChonTrinhDoTinHoc) {
            state.dataChonKhungNLucNNgu = data_ChonTrinhDoTinHoc
        },

        // <!-- ************ data Chọn Chuyên môn  ************ -->
        SET_DATACHONCHUYENMON_STORE(state, dataChonChuyenMon) {
            state.dataChonChuyenMon = dataChonChuyenMon
            localStorage.setItem(
                'data_ChonChuyenMon',
                JSON.stringify(dataChonChuyenMon)
            )
        },
        RESTORE_DATA_CHONCHUYENMON(state, data_ChonChuyenMon) {
            state.dataChonChuyenMon = data_ChonChuyenMon
        },

        // <!-- ************ data Chọn Chứng chỉ tiếng dân tộc thiểu số  ************ -->
        SET_DATACHONCCHITIENGDTOCTSO_STORE(state, dataChonCChiTiengDTocTSo) {
            state.dataChonCChiTiengDTocTSo = dataChonCChiTiengDTocTSo
            localStorage.setItem(
                'data_ChonCChiTiengDTocTSo',
                JSON.stringify(dataChonCChiTiengDTocTSo)
            )
        },
        RESTORE_DATA_CHONCCHITIENGDTOCTSO(state, data_ChonCChiTiengDTocTSO) {
            state.dataChonCChiTiengDTocTSo = data_ChonCChiTiengDTocTSO
        },

        // <!-- ************ data Chọn Đánh giá viên chức  ************ -->
        SET_DATACHONDGVIENCHUC_STORE(state, dataChonDGVienChuc) {
            state.dataChonDGVienChuc = dataChonDGVienChuc
            localStorage.setItem(
                'data_ChonDGVienChuc',
                JSON.stringify(dataChonDGVienChuc)
            )
        },
        RESTORE_DATA_CHONDGVIENCHUC(state, data_ChonDGVienChuc) {
            state.dataChonDGVienChuc = data_ChonDGVienChuc
        },

        // <!-- ************ data Chọn Đánh giá GV Giỏi  ************ -->
        SET_DATACHONDANHGIAGVGIOI_STORE(state, dataChonDanhGiaGVGioi) {
            state.dataChonDanhGiaGVGioi = dataChonDanhGiaGVGioi
            localStorage.setItem(
                'data_ChonDanhGiaGVGioi',
                JSON.stringify(dataChonDanhGiaGVGioi)
            )
        },
        RESTORE_DATA_CHONDANHGIAGVGIOI(state, data_ChonDanhGiaGVGioi) {
            state.dataChonDanhGiaGVGioi = data_ChonDanhGiaGVGioi
        },

        // <!-- ************ data Chọn Đánh giá GVCN Giỏi  ************ -->
        SET_DATACHONDANHGIAGVCNGIOI_STORE(state, dataChonGVCNGIOI) {
            state.dataChonGVCNGIOI = dataChonGVCNGIOI
            localStorage.setItem(
                'data_ChonGVCNGioi',
                JSON.stringify(dataChonGVCNGIOI)
            )
        },
        RESTORE_DATA_CHONDANHGIAGVCNGIOI(state, data_ChonGVCNGioi) {
            state.dataChonGVCNGIOI = data_ChonGVCNGioi
        },

        // <!-- ************ data Chọn Tổng phụ trách đội Giỏi  ************ -->
        SET_DATACHONTONGPHUTRACHDOIGIOI_STORE(
            state,
            dataChonTongPhuTrachDoiGioi
        ) {
            state.dataChonTongPhuTrachDoiGioi = dataChonTongPhuTrachDoiGioi
            localStorage.setItem(
                'data_ChonTongPhuTrachDoiGioi',
                JSON.stringify(dataChonTongPhuTrachDoiGioi)
            )
        },
        RESTORE_DATA_CHONTONGPHUTRACHDOIGIOI(
            state,
            data_ChonTongPhuTrachDoiGioi
        ) {
            state.dataChonDanhGiaGVGioi = data_ChonTongPhuTrachDoiGioi
        },

        // <!-- ************ data Chọn Nhóm cán bộ  ************ -->
        SET_DATACHONNHOMCANBO_STORE(state, dataChonNhomCanBo) {
            state.dataChonNhomCanBo = dataChonNhomCanBo
            localStorage.setItem(
                'data_ChonNhomcanbo',
                JSON.stringify(dataChonNhomCanBo)
            )
        },
        RESTORE_DATA_CHONNHOMCANBO(state, data_ChonNhomcanbo) {
            state.dataChonNhomCanBo = data_ChonNhomcanbo
        },

        // <!-- ************ data Chọn Loại hình trường  ************ -->
        SET_DATACHONLOAIHINHTRUONG_STORE(state, dataChonLoaihinhtruong) {
            state.dataChonLoaiHinhTruong = dataChonLoaihinhtruong
            localStorage.setItem(
                'data_ChonLoaihinhtruong',
                JSON.stringify(dataChonLoaihinhtruong)
            )
        },
        RESTORE_DATA_CHONLOAIHINHTRUONG(state, data_ChonLoaihinhtruong) {
            state.dataChonLoaiHinhTruong = data_ChonLoaihinhtruong
        },

        // <!-- ************ data Chọn Đạt chuẩn quốc gia ************ -->
        SET_DATACHONDATCHUANQG_STORE(state, dataChonDatchuanQG) {
            state.dataChonDatChuanQG = dataChonDatchuanQG
            localStorage.setItem(
                'data_ChonDatChuanQG',
                JSON.stringify(dataChonDatchuanQG)
            )
        },
        RESTORE_DATA_CHONDATCHUANQG(state, data_ChonDatChuanQG) {
            state.dataChonDatChuanQG = data_ChonDatChuanQG
        },

        // <!-- ************ data Chọn Giai đoạn KQHT ************ -->
        SET_DATACHONGIAIDOANKQHT_STORE(state, dataChonGiaiDoanKQHT) {
            state.dataChonGiaiDoanKQHT = dataChonGiaiDoanKQHT
            localStorage.setItem(
                'data_ChonGiaiDoanKqht',
                JSON.stringify(dataChonGiaiDoanKQHT)
            )
        },
        RESTORE_DATA_CHONGIAIDOANKQHT(state, data_ChonGiaiDoanKqht) {
            state.dataChonGiaiDoanKQHT = data_ChonGiaiDoanKqht
        },

        // <!-- ************ data Chọn Loại tốt nghiêp ************ -->
        SET_DATACHONLOAITOTNGHIEP_STORE(state, dataChonLoaiTotNghiep) {
            state.dataChonLoaiTotNghiep = dataChonLoaiTotNghiep
            localStorage.setItem(
                'data_ChonLoaitotnghiep',
                JSON.stringify(dataChonLoaiTotNghiep)
            )
        },
        RESTORE_DATA_CHONLOAITOTNGHIEP(state, data_ChonLoaitotnghiep) {
            state.dataChonGiaiDoanKQHT = data_ChonLoaitotnghiep
        },

        // <!-- ************ data Chọn Hình thức đào tạo ************ -->
        SET_DATACHONHINHTHUCDAOTAO_STORE(state, dataChonHinhThucDaoTao) {
            state.dataChonHinhThucDaoTao = dataChonHinhThucDaoTao
            localStorage.setItem(
                'data_ChonHinhthucdaotao',
                JSON.stringify(dataChonHinhThucDaoTao)
            )
        },
        RESTORE_DATA_CHONHINHTHUCDAOTAO(state, data_ChonHinhthucdaotao) {
            state.dataChonHinhThucDaoTao = data_ChonHinhthucdaotao
        },

        // <!-- ************ data Chọn Văn bằng chứng chỉ ************ -->
        SET_DATACHONVANBANGCHUNGCHI_STORE(state, dataChonVanBangChungChi) {
            state.dataChonVanBangChungChi = dataChonVanBangChungChi
            localStorage.setItem(
                'data_ChonVanbangchungchi',
                JSON.stringify(dataChonVanBangChungChi)
            )
        },
        RESTORE_DATA_CHONVANBANGCHUNGCHI(state, data_ChonVanbangchungchi) {
            state.dataChonVanBangChungChi = data_ChonVanbangchungchi
        },

        // <!-- ************ data Chọn Khóa đào tạo sư phạm ************ -->
        SET_DATACHONKHOADAOTAOSUPHAM_STORE(state, dataChonKhoaDaoTaoSuPham) {
            state.dataChonKhoaDaoTaoSuPham = dataChonKhoaDaoTaoSuPham
            localStorage.setItem(
                'data_ChonKhoadaotaosupham',
                JSON.stringify(dataChonKhoaDaoTaoSuPham)
            )
        },
        RESTORE_DATA_CHONKHOADAOTAOSUPHAM(state, data_ChonKhoadaotaosupham) {
            state.dataChonKhoaDaoTaoSuPham = data_ChonKhoadaotaosupham
        },

        // <!-- ************ data Chọn đơn vị bồi dưỡng ************ -->
        SET_DATACHONDONVIBOIDUONG_STORE(state, dataChonDonViBoiDuong) {
            state.dataChonDonViBoiDuong = dataChonDonViBoiDuong
            localStorage.setItem(
                'data_ChonDonviboiduong',
                JSON.stringify(dataChonDonViBoiDuong)
            )
        },
        RESTORE_DATA_CHONDONVIBOIDUONG(state, data_ChonDonviboiduong) {
            state.dataChonDonViBoiDuong = data_ChonDonviboiduong
        },

        // <!-- ************ data Chọn đơn vị tổ chức ************ -->
        SET_DATACHONDONVITOCHUC_STORE(state, dataChonDonViToChuc) {
            state.dataChonDonViToChuc = dataChonDonViToChuc
            localStorage.setItem(
                'data_ChonDonvitochuc',
                JSON.stringify(dataChonDonViToChuc)
            )
        },
        RESTORE_DATA_CHONDONVITOCHUC(state, data_ChonDonvitochuc) {
            state.dataChonDonViToChuc = data_ChonDonvitochuc
        },

        // <!-- ************ data Chọn thời lượng bồi dưỡng ************ -->
        SET_DATACHONTHOILUONGBOIDUONG_STORE(state, dataChonThoiLuongBoiDuong) {
            state.dataChonThoiLuongBoiDuong = dataChonThoiLuongBoiDuong
            localStorage.setItem(
                'data_ChonThoiluongboiduong',
                JSON.stringify(dataChonThoiLuongBoiDuong)
            )
        },
        RESTORE_DATA_CHONTHOILUONGBOIDUONG(state, data_ChonThoiluongboiduong) {
            state.dataChonThoiLuongBoiDuong = data_ChonThoiluongboiduong
        },

        // <!-- ************ data Chọn loại hình bồi dưỡng ************ -->
        SET_DATACHONLOAIHINHBOIDUONG_STORE(state, dataChonLoaihinhboiduong) {
            state.dataChonLoaihinhboiduong = dataChonLoaihinhboiduong
            localStorage.setItem(
                'data_ChonLoaihinhboiduong',
                JSON.stringify(dataChonLoaihinhboiduong)
            )
        },
        RESTORE_DATA_CHONLOAIHINHBOIDUONG(state, data_ChonLoaihinhboiduong) {
            state.dataChonLoaihinhboiduong = data_ChonLoaihinhboiduong
        },

        // <!-- ************ data Chọn hình thức ************ -->
        SET_DATACHONHINHTHUC_STORE(state, dataChonHinhThuc) {
            state.dataChonHinhThuc = dataChonHinhThuc
            localStorage.setItem(
                'data_ChonHinhthuc',
                JSON.stringify(dataChonHinhThuc)
            )
        },
        RESTORE_DATA_CHONHINHTHUC(state, data_ChonHinhthuc) {
            state.dataChonHinhThuc = data_ChonHinhthuc
        },

        // <!-- ************ data Chọn kết quả sư phạm ************ -->
        SET_DATACHONKETQUASUPHAM_STORE(state, dataChonKetQuaSuPham) {
            state.dataChonKetQuaSuPham = dataChonKetQuaSuPham
            localStorage.setItem(
                'data_ChonKetquasupham',
                JSON.stringify(dataChonKetQuaSuPham)
            )
        },
        RESTORE_DATA_CHONKETQUASUPHAM(state, data_ChonKetquasupham) {
            state.dataChonKetQuaSuPham = data_ChonKetquasupham
        },

        // <!-- ************ data Chọn kỷ luật giáo viên ************ -->
        SET_DATACHONKYLUATGV_STORE(state, dataChonKyLuatGV) {
            state.dataChonKyLuatGV = dataChonKyLuatGV
            localStorage.setItem(
                'data_ChonKyluatGV',
                JSON.stringify(dataChonKyLuatGV)
            )
        },
        RESTORE_DATA_CHONKYLUATGV(state, data_ChonKyluatGV) {
            state.dataChonKyLuatGV = data_ChonKyluatGV
        },

        // <!-- ************ data Chọn tiết học ************ -->
        SET_DATACHONTIETHOC_STORE(state, dataChonTietHoc) {
            state.dataChonTietHoc = dataChonTietHoc
            localStorage.setItem(
                'data_ChonTietHoc',
                JSON.stringify(dataChonTietHoc)
            )
        },
        RESTORE_DATA_CHONTIETHOC(state, data_ChonTietHoc) {
            state.dataChonTietHoc = data_ChonTietHoc
        },

        // <!-- ************ data Chọn ngành đào tạo ************ -->
        SET_DATACHONNGANHDAOTAO_STORE(state, dataChonNganhDaoTao) {
            state.dataChonNganhDaoTao = dataChonNganhDaoTao
            localStorage.setItem(
                'data_ChonNganhDaoTao',
                JSON.stringify(dataChonNganhDaoTao)
            )
        },
        RESTORE_DATA_CHONNGANHDAOTAO(state, data_ChonNganhDaoTao) {
            state.dataChonNganhDaoTao = data_ChonNganhDaoTao
        },
        // <!-- ************ data Chọn trình độ văn hóa ************ -->
        SET_DATACHONTRINHDOVANHOA_STORE(state, dataChonTrinhDoVanHoa) {
            state.dataChonTrinhDoVanHoa = dataChonTrinhDoVanHoa
            localStorage.setItem(
                'data_ChonTrinhDoVanHoa',
                JSON.stringify(dataChonTrinhDoVanHoa)
            )
        },
        RESTORE_DATA_CHONTRINHDOVANHOA(state, data_ChonTrinhDoVanHoa) {
            state.dataChonTrinhDoVanHoa = data_ChonTrinhDoVanHoa
        },
        // <!-- ************ data Chọn trình độ quản lý nhà nước ************ -->
        SET_DATACHONTRINHDOQLNN_STORE(state, dataChonTrinhDoQLNN) {
            state.dataChonTrinhDoQLNN = dataChonTrinhDoQLNN
            localStorage.setItem(
                'data_ChonTrinhDoQLNN',
                JSON.stringify(dataChonTrinhDoQLNN)
            )
        },
        RESTORE_DATA_CHONTRINHDOQLNN(state, data_ChonTrinhDoQLNN) {
            state.dataChonTrinhDoQLNN = data_ChonTrinhDoQLNN
        },
        // <!-- ************ data Chọn khối học ************ -->
        SET_DATACHONKHOI(state, dataCHonKhoi) {
            state.dataCHonKhoi = dataCHonKhoi
            localStorage.setItem('data_ChonKhoiHoc', JSON.stringify(dataCHonKhoi))
        },
        RESTORE_DATA_CHONKHOI(state, data_ChonKhoi) {
            state.dataCHonKhoi = data_ChonKhoi
        },
        // <!-- ************ data Chọn tình trạng dinh dưỡng ************ -->
        SET_DATACHONTINHTRANGDINHDUONG(state, dataChonTinhTrangDinhDuong) {
            state.dataChonTinhTrangDinhDuong = dataChonTinhTrangDinhDuong
            localStorage.setItem(
                'data_ChonTinhTrangDinhDuong',
                JSON.stringify(dataChonTinhTrangDinhDuong)
            )
        },
        RESTORE_DATA_CHONTINHTRANGDINHDUONG(state, data_ChonTinhTrangDinhDuong) {
            state.dataChonTinhTrangDinhDuong = data_ChonTinhTrangDinhDuong
        },
        // <!-- ************ data Chọn lý do thôi học ************ -->
        SET_DATACHONLYDOTHOIHOC(state, dataChonLyDoThoiHoc) {
            state.dataChonLyDoThoiHoc = dataChonLyDoThoiHoc
            localStorage.setItem(
                'data_ChonLyDoThoiHoc',
                JSON.stringify(dataChonLyDoThoiHoc)
            )
        },
        RESTORE_DATA_CHONLYDOTHOIHOC(state, data_ChonLyDoThoiHoc) {
            state.dataChonLyDoThoiHoc = data_ChonLyDoThoiHoc
        },

        // <!-- ************ Dùng để truyền data từ Kết chuyển dữ liệu sang các màn khác ************ -->
        setData_LichSuTiepNhanDuLieu(state, item) {
            state.data_Navigate_KetChuyenDuLieu.lichSuTiepNhanDuLieu = item
        },
        setData_LichSuDayDuLieuLenBo(state, item) {
            state.data_Navigate_KetChuyenDuLieu.lichSuDayDuLieuLenBo = item
        },
        setData_traCuuCanBoGV(state, item) {
            state.data_Navigate_KetChuyenDuLieu.traCuuCanBoGV = item
        },
        setData_traCuuHocSinh(state, item) {
            state.data_Navigate_KetChuyenDuLieu.traCuuHocSinh = item
        },
        setData_traCuuLopHoc(state, item) {
            state.data_Navigate_KetChuyenDuLieu.traCuuLopHoc = item
        },
        setData_traCuuKQHT(state, item) {
            state.data_Navigate_KetChuyenDuLieu.traCuuKQHT = item
        }
    },
    actions: {
        updateData_LichSuTiepNhanDuLieu({ commit }, item) {
            commit('setData_LichSuTiepNhanDuLieu', item)
        },
        updateData_LichSuDayDuLieuLenBo({ commit }, item) {
            commit('setData_LichSuDayDuLieuLenBo', item)
        },
        updateData_traCuuCanBoGV({ commit }, item) {
            commit('setData_traCuuCanBoGV', item)
        },
        updateData_traCuuHocSinh({ commit }, item) {
            commit('setData_traCuuHocSinh', item)
        },
        updateData_traCuuLopHoc({ commit }, item) {
            commit('setData_traCuuLopHoc', item)
        },
        updateData_traCuuKQHT({ commit }, item) {
            commit('setData_traCuuKQHT', item)
        }
    },

    modules: {}
})
