<template>
    <div>
        <CustomBreadcrumb
            :title="'Tra cứu'"
            content="TRA CỨU KẾT QUẢ HỌC TẬP THÔNG TƯ 22"
        />
        <CustomContentUI>
            <div class="row">
                <div class="col-3">
                    <label>Đơn vị quản lý</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="
                                this.authUser.role === 5 ||
                                this.authUser.role === 4
                            "
                            :data="this.dataChonDonVi_Store"
                            :placeholder="'Chọn đơn vị'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['tenDonVi', 'maDonVi']"
                            v-model="selectedValue.selectedValueUnitEducation"
                            @change="getDataChonTruongHoc"
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Cấp học</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectGradeLevel_MockData"
                            :placeholder="'Chọn cấp học'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['tenTruongHoc', 'value']"
                            v-model="selectedValue.selectedValueGradeLevel"
                            @change="getDataChonTruongHoc"
                        />
                    </div>
                </div>
                <div class="col-3">
                    <label>Trường học<span class="span">*</span></label>
                    <div>
                        <ESelect
                            :reset="resetESelectSchool"
                            @reset-completed="handleResetSchoolCompleted"
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectSchool"
                            :placeholder="'Chọn trường học'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['title', 'value']"
                            v-model="selectedValue.selectedValueSchool"
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Học kỳ<span class="span">*</span></label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectSemester"
                            :placeholder="'Chọn học kỳ'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['tenHocKy', 'value']"
                            v-model="selectedValue.selectedValueSemester"
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Năm học<span class="span">*</span></label>
                    <ESelectYear
                        v-model="selectedValue.selectedValueYear"
                        placeholder="Chọn năm"
                        size="small"
                        :no-data-text="'Không có bản ghi nào'"
                        :no-match-text="'Không tìm thấy bản ghi nào'"
                    ></ESelectYear>
                </div>
            </div>
            <div class="layout-btn-download">
                <CustomButton
                    label="Tải xuống"
                    class="btn-default btn-down"
                    size="small"
                    @click="handleDownLoad"
                />
            </div>
        </CustomContentUI>
    </div>
</template>
<script>
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelectYear from '@/components/ESelectYear.vue'
import ESelect from '@/components/ESelect.vue'
import { mapState } from 'vuex'
import {
    ESelectGradeLevel_MockData,
    ESelectSemester_MockData_TraCuuKQHTTT22
} from '@/mock_data'
import Api from '@/constants/Api'
import sendRequest from '@/services'
import { convertCapHocList, currentYear } from '@/utils'
import CustomContentUI from '@/components/CustomContentUI.vue'
export default {
    name: 'TraCuuKetQuaHocTapTT22',
    components: {
        ESelect,
        CustomButton,
        CustomBreadcrumb,
        ESelectYear,
        CustomContentUI
    },
    data() {
        return {
            resetESelectSchool: false,
            getDataESelect: {
                ESelectUnitEducation: [], //donvi
                ESelectGradeLevel_MockData: ESelectGradeLevel_MockData, //caphoc
                ESelectSchool: [], //truonghoc
                ESelectSemester: ESelectSemester_MockData_TraCuuKQHTTT22 //hocky
            },
            selectedValue: {
                selectedValueUnitEducation: [], //donvi
                selectedValueGradeLevel: [], //caphoc
                selectedValueSchool: [], //truonghoc,
                selectedValueYear: '',
                selectedValueSemester: [] //hocky
            }
        }
    },
    methods: {
        handleResetSchoolCompleted() {
            this.resetESelectSchool = false
        },
        async handleDownLoad() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const selectedSemester = this.selectedValue.selectedValueSemester
            const selectedValueSchool = this.selectedValue.selectedValueSchool

            const selectedGradeLevel = this.selectedValue.selectedValueGradeLevel
            const caphocValue = selectedGradeLevel
                ? +selectedGradeLevel.value
                : ''

            const selectedYear = this.selectedValue.selectedValueYear
            const namHoc = selectedYear ? +selectedYear : currentYear
            if (!selectedValueSchool) {
                loading.close()
                this.$message({
                    type: 'error',
                    message: 'Vui lòng chọn Mã trường học'
                })
                return
            }
            if (!selectedSemester) {
                loading.close()
                this.$message({
                    type: 'error',
                    message: 'Vui lòng chọn học kỳ'
                })
                return
            }

            const request_Header = {
                token: this.authToken
            }

            const request_Data = {
                capHoc: caphocValue,
                hocKy: +selectedSemester.value,
                maTruongHoc: selectedValueSchool.value,
                namHoc: namHoc
            }
            const response = await sendRequest(
                Api.traCuu.traCuuKQHTTT22.downLoadExcel,
                request_Data,
                request_Header
            )

            if (response.rc == 0) {
                loading.close()
                const domain = process.env.VUE_APP_BACKEND_BASE_URL
                const fileUrl = response.item
                window.open(`${domain}/${fileUrl}`, '_blank')
            } else {
                loading.close()
                this.$message({
                    type: 'error',
                    message: response.rd
                })
            }
        },
        async getDataChonTruongHoc() {
            // Kiểm tra và khởi tạo mảng maDonVi
            const maDonVi = this.selectedValue.selectedValueUnitEducation.value
                ? [this.selectedValue.selectedValueUnitEducation.value]
                : []

            // Kiểm tra và khởi tạo mảng capHoc
            const capHoc = this.selectedValue.selectedValueGradeLevel.value
                ? [this.selectedValue.selectedValueGradeLevel.value]
                : []

            const request_Header = {
                token: this.authToken
            }

            const request_Data = {
                capHoc: capHoc,
                maDonVi: maDonVi
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            let response = await sendRequest(
                Api.internal_api.dm_School,
                request_Data,
                request_Header,
                null
            )

            if (response.statusResponse == 0) {
                loading.close()
                this.getDataESelect.ESelectSchool = response.rows.map((item) => ({
                    value: item.maTruongHoc,
                    title: `${item.tenTruongHoc} - [${item.maTruongHoc}]`
                }))
            }
        },
        convertToValueArray(array) {
            return array.map((item) => item.value)
        }
    },
    watch: {
        'authUser.role': {
            immediate: true,
            handler(newRole, oldRole) {
                this.resetESelectSchool = false
                if (newRole === 5) {
                    this.selectedValue.selectedValueUnitEducation = {
                        value: this.authUser.ma_don_vi
                    }

                    const convertedList = convertCapHocList(
                        this.authUser.capHocList
                    )
                    this.selectedValue.selectedValueGradeLevel = convertedList
                    this.selectedValue.selectedValueSchool = {
                        value: this.authUser.ma_truong_hoc
                    }
                }
                if (newRole === 4) {
                    this.selectedValue.selectedValueUnitEducation = {
                        value: this.authUser.ma_don_vi
                    }
                }
            }
        },

        'selectedValue.selectedValueUnitEducation': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        },
        'selectedValue.selectedValueGradeLevel': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        }
    },
    mounted() {
        this.getDataChonTruongHoc()
    }
}
</script>

<style scoped>
.layout-table {
    background: #fff;
    padding: 20px 10px 5px 10px;
    border-radius: 4px;
    max-width: calc(100vw - 330px);
    margin: 0 auto;
    overflow-x: auto;
}
.hide-td {
    display: none;
}
table thead tr {
    background: rgb(228, 235, 245);
}
.table {
    white-space: nowrap;
}
.centered-table {
    margin-left: auto;
    margin-right: auto;
}

.centered-table th,
.centered-table td {
    text-align: center;
    vertical-align: middle;
}
.table-bordered {
    background-color: #fff;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}
.table-hover tbody tr:hover {
    background-color: #f5f5f5;
}

.text-thead {
    font-size: 13px;
    text-align: center; /* Canh giữa nội dung trong cột */
    vertical-align: middle; /* Căn giữa theo chiều dọc */
}
.text-tbody {
    font-size: 13px;
}
.layout-btn-download {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.btn-down {
    background-color: var(--teal);
}
</style>
