<template>
    <el-dialog
        :visible="themMoiModalVisible"
        @close="closeModalThemMoi"
        width="80%"
    >
        <span slot="title" class="custom-dialog-title">
            THÊM PHƯỜNG XÃ
            <hr />
        </span>
        <div class="row form">
            <div class="col-md-4">
                <label>Tỉnh thành (<span class="span">*</span>)</label>

                <ESelect
                    style="width: 100%"
                    no-match-text="Không tìm thấy bản ghi nào"
                    no-data-text="danh sách lựa chọn trống"
                    :clearable="true"
                    :disabled="true"
                    :data="ESelect_tinhThanh"
                    :placeholder="'Chọn '"
                    :filterable="true"
                    :multiple="false"
                    :collapse-tags="true"
                    :fields="['title', 'value']"
                    v-model="selectedValue.selectedValue_tinhThanh"
                />
            </div>
            <div class="col-md-4">
                <label>Quận huyện (<span class="span">*</span>)</label>
                <ESelect
                    :reset="resetESelect"
                    @reset-completed="handleResetCompleted"
                    style="width: 100%"
                    no-match-text="Không tìm thấy bản ghi nào"
                    no-data-text="danh sách lựa chọn trống"
                    :clearable="true"
                    :disabled="false"
                    :data="getDataESelect.ESelect_quanHuyen"
                    :placeholder="'Chọn '"
                    :filterable="true"
                    :multiple="false"
                    :collapse-tags="true"
                    :fields="['title', 'value']"
                    v-model="selectedValue.selectedValue_quanHuyen"
                />
                <span v-if="err_maQuanHuyen.length > 0" class="error">{{
                    err_maQuanHuyen
                }}</span>
            </div>

            <div class="col-md-4">
                <label>Mã phường xã (<span class="span">*</span>)</label>

                <el-input
                    placeholder="Nhập ..."
                    v-model="selectedValue.input_maPhuongXa"
                    size="small"
                    style="width: 100%"
                >
                </el-input>
                <span v-if="err_maPhuongXa.length > 0" class="error">{{
                    err_maPhuongXa
                }}</span>
            </div>
            <div class="col-md-4 mt-4">
                <label>Tên phường xã (<span class="span">*</span>)</label>

                <el-input
                    placeholder="Nhập ..."
                    v-model="selectedValue.input_tenPhuongXa"
                    size="small"
                    style="width: 100%"
                >
                </el-input>
                <span v-if="err_tenPhuongXa.length > 0" class="error">{{
                    err_tenPhuongXa
                }}</span>
            </div>
            <div class="col-md-4 mt-4">
                <label>Số lượng tổ </label>

                <el-input
                    placeholder="Nhập ..."
                    v-model="selectedValue.input_slTo"
                    size="small"
                    style="width: 100%"
                    type="Number"
                >
                </el-input>
            </div>
        </div>
        <div class="layout-btn">
            <div>
                <CustomButton
                    label="Đóng"
                    size="small"
                    class="btn-close btn-default"
                    @click="closeModalThemMoi"
                />
            </div>
            <div style="margin-left: 10px">
                <CustomButton
                    label="Thêm mới"
                    size="small"
                    class="btn-add btn-default"
                    @click="handleThemMoi"
                />
            </div>
        </div>
    </el-dialog>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import { mapState } from 'vuex'
import sendRequest from '@/services'
import ESelect from '@/components/ESelect.vue'

import Api from '@/constants/Api'
import { validateField } from '@/utils'
export default {
    name: 'ModalThemMoiTruongHoc',
    props: {
        themMoiModalVisible: {
            type: Boolean,
            required: true
        },
        closeModalThemMoi: {
            type: Function,
            required: true
        },
        ESelect_tinhThanh: {
            type: Array,
            required: true
        },
        handleSearch: Function
    },

    components: { CustomButton, ESelect },
    data() {
        return {
            resetESelect: false,
            getDataESelect: {
                ESelect_tinhThanh: [],
                ESelect_quanHuyen: []
            },
            selectedValue: {
                input_tenPhuongXa: '',
                input_maPhuongXa: '',
                input_slTo: '',
                selectedValue_trangThai: '',
                selectedValue_tinhThanh: '',
                selectedValue_quanHuyen: ''
            },
            err_maQuanHuyen: '',
            err_maPhuongXa: '',
            err_tenPhuongXa: ''
        }
    },

    methods: {
        resetErr() {
            this.err_maPhuongXa = ''
            this.err_maQuanHuyen = ''
            this.err_tenPhuongXa = ''
        },
        handleResetCompleted() {
            this.resetESelect = false
        },

        async getDataESelectQuanHuyen() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    start: 0,
                    limit: 999,
                    maTinhThanh: this.selectedValue.selectedValue_tinhThanh?.value
                }
                const response = await sendRequest(
                    Api.superAdmin.phuongXa.getQuanHuyen,
                    null,
                    request_Header,
                    request_Params
                )

                loading.close()
                this.getDataESelect.ESelect_quanHuyen = response.rows.map(
                    (item) => ({
                        ...item,
                        title: `${item.tenQuanHuyen} - [${item.maQuanHuyen}]`,
                        value: item.maQuanHuyen
                    })
                )
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },

        handleThemMoi() {
            const maTinhThanh = this.selectedValue.selectedValue_tinhThanh?.value
            const maPhuongXa = this.selectedValue.input_maPhuongXa
            const tenPhuongXa = this.selectedValue.input_tenPhuongXa
            const maQuanHuyen = this.selectedValue.selectedValue_quanHuyen?.value
            const slTo = this.selectedValue.input_slTo
            const fieldsToValidate = [
                {
                    name: 'err_maQuanHuyen',
                    value: maQuanHuyen,
                    errorField: 'err_maQuanHuyen',
                    errorMessage: 'Vui lòng chọn mã quận huyện'
                },
                {
                    name: 'err_maPhuongXa',
                    value: maPhuongXa,
                    errorField: 'err_maPhuongXa',
                    errorMessage: 'Vui lòng nhập mã phường xã'
                },

                {
                    name: 'err_tenPhuongXa',
                    value: tenPhuongXa,
                    errorField: 'err_tenPhuongXa',
                    errorMessage: 'Vui lòng nhập tên phường xã'
                }
            ]
            let isValid = true

            for (const field of fieldsToValidate) {
                isValid = validateField(this, field, isValid)
            }
            if (isValid) {
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })
                this.$confirm('Xác nhận thêm mới ?', 'Thông báo', {
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy',
                    type: 'warning'
                })
                    .then(async () => {
                        const request_Header = {
                            token: this.authToken
                        }
                        const request_Data = {
                            ten: tenPhuongXa,
                            maTinhThanh: maTinhThanh,
                            ma: maPhuongXa,
                            maQuanHuyen: maQuanHuyen,
                            slTo: slTo
                        }
                        const response = await sendRequest(
                            Api.superAdmin.phuongXa.themPhuongXa,
                            request_Data,

                            request_Header
                        )
                        if (response.code == 200) {
                            this.$message({
                                type: 'success',
                                message: 'Thêm mới thành công'
                            })
                            this.resetErr()
                            this.closeModalThemMoi()
                            this.$emit('handleSearch')
                        } else {
                            loading.close()
                            this.$message({
                                type: 'error',
                                message: response.message
                            })
                        }
                    })
                    .catch(() => {
                        loading.close()
                    })
            }
        }
    },
    created() {
        this.selectedValue.selectedValue_tinhThanh = {
            value: this.authUser.ma_so
        }
    },
    watch: {},
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    },
    mounted() {
        this.getDataESelectQuanHuyen()
    }
}
</script>
<style scoped>
hr {
    border: 1px solid green;
}
.form label {
    font-weight: bold;
}
.form .span {
    color: red;
}
.form .error-message {
    font-style: italic;
    color: red;
    margin-top: 5px;
}

.layout-btn {
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
}
.btn-close {
    background-color: var(--orange);
    color: var(--btn-text-color);
}
.btn-close:hover {
    background-color: var(--orange);
    color: var(--btn-text-color);
}
.btn-add,
.btn-add:hover {
    background-color: var(--light-green);
    color: var(--btn-text-color);
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.error {
    font-style: italic;
    color: red;
}
</style>
