<template>
    <el-dialog
        :visible="chinhSuaFileBieuMauModalVisible"
        @close="closeModalChinhSuaFileBieuMauBaoCao"
        :fullscreen="true"
    >
        <span slot="title">
            THÊM FILE CHO BÁO CÁO
            <span class="title">
                {{ dataModalChinhSuaFileBieuMau.tenBaoCao }}</span
            >
            <hr />
            <div class="row d-flex justify-content-center align-items-center">
                <div class="">
                    <label
                        class="btn btn-default button-file btn-file layout-file"
                        tabindex="0"
                        id="lblFile"
                    >
                        <i class="fa fa-cloud-upload"></i>
                        Chọn File (<span style="color: red">*</span>)
                        <div v-if="!tenFile">
                            <small>
                                <em>Tổng dung lượng không quá 10MB</em>
                            </small>
                            <br />
                            <small>
                                <em>Định dạng file hợp lệ: *.xlsx </em>
                            </small>
                        </div>
                        <div v-if="tenFile">
                            <small>
                                <em
                                    >File đã chọn:
                                    <b>{{ tenFile }}</b>
                                </em>
                            </small>
                            <br />
                        </div>
                        <input
                            ref="file_upload"
                            multiple="false"
                            name="file"
                            style="display: none"
                            type="file"
                            @change="onFileChange"
                            aria-invalid="false"
                        />
                    </label>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-12">
                    <h6>Hướng dẫn thêm file biểu mẫu hợp lệ:</h6>
                    <div class="text-center">
                        <div style="">
                            <img
                                src="../../../assets/img/excelupdate.png"
                                alt="Hình ảnh"
                                width="80%"
                                title="Hình ảnh hướng dẫn"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </span>
        <div class="layout-btn">
            <CustomButton
                label="Đóng"
                size="small"
                type="danger"
                @click="closeModalChinhSuaFileBieuMauBaoCao"
            />
            <CustomButton
                label="Thêm "
                size="small"
                @click="handleAddFile"
                type="primary"
            />
        </div>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
import CustomButton from '@/components/CustomButton.vue'

import sendRequest from '@/services'
import Api from '@/constants/Api'
export default {
    name: 'ModalLstQuaTrinhLuong',
    props: {
        chinhSuaFileBieuMauModalVisible: {
            type: Boolean,
            required: true
        },

        closeModalChinhSuaFileBieuMauBaoCao: {
            type: Function,
            required: true
        },
        dataModalChinhSuaFileBieuMau: {
            type: Object,
            required: true
        }
    },
    watch: {
        dataModalChinhSuaFileBieuMau(newValues) {
            console.log(newValues)
        }
    },
    components: { CustomButton },
    data() {
        return {
            selected_file: null,
            tenFile: '',
            resetESelectSchool: false
        }
    },

    methods: {
        async handleAddFile() {
            const selected_file = this.selected_file
            if (!selected_file) {
                this.$message({
                    message: 'File chưa chọn',
                    type: 'warning'
                })
                return
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const userId = this.authUser.username

                const roleId = this.authUser.role

                const maSo = this.authUser.ma_so
                const request_Header = {
                    'Content-Type': 'multipart/form-data',
                    token: this.authToken
                }
                await this.$confirm('Bạn có muốn thêm File', 'Xác nhận', {
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy',
                    type: 'warning'
                })
                const formData = new FormData()
                formData.append('file', selected_file)
                formData.append('id', this.dataModalChinhSuaFileBieuMau.id)
                formData.append('userId', userId)
                formData.append('roleId', roleId)
                formData.append('maSo', maSo)
                formData.append('maDonVi', maSo)

                const response = await sendRequest(
                    Api.baoCao.nopThongKeTheoBieuMau.themMoiFile,
                    formData,
                    request_Header
                )

                if (response.rc == 0) {
                    setTimeout(() => {
                        loading.close()
                        this.$message({
                            message: 'Thêm mới File thành công',
                            type: 'success'
                        })
                    }, 1000)
                    this.openModalConFirm()
                }
                loading.close()
            } catch (error) {
                loading.close()
                console.log(error)
            }
        },
        onFileChange(e) {
            const files = e.target.files
            this.tenFile = ''

            this.selected_file = files[0]

            if (files && files[0]) {
                this.file_name = e.target.files[0].name
                this.tenFile = this.file_name
            }
        },
        openModalConFirm() {
            this.$confirm(
                'Import file thành công. Tiến hành làm mới dữ liệu?',
                'Warning',
                {
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy',
                    type: 'warning'
                }
            )
                .then(() => {
                    location.reload()
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Hủy'
                    })
                })
        }
    },

    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        }
    }
}
</script>
<style scoped>
.title {
    font-size: 24px;
    font-weight: 500;
}
hr {
    border: 1px solid #c0c4cc;
    padding: 0;
    margin: 15px 0px 0px 0px;
}
.layout-file {
    background-color: var(--teal);
    border-radius: 8px;
    padding: 5px 5px 5px 5px;
}
</style>
