var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"school"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"left"},[_c('div',[_c('el-icon',{staticClass:"icon",attrs:{"name":"s-custom"}})],1),_c('div',{staticClass:"title"},[_vm._v("Học sinh")])]),_c('div',{staticClass:"right"},[_c('CustomButton',{staticClass:"custom-btn",attrs:{"label":"Chi tiết","size":"small"},on:{"click":_vm.handleNaviagte}})],1)]),_c('div',{staticClass:"layout-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 mb-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"title"},[_vm._v("Tổng quan")]),_c('div',[_c('CustomButton',{attrs:{"type":"text","icon":"el-icon-refresh-right","label":"","size":"medium"},on:{"click":_vm.handleRefresh_DataBieuDoTongQuan_HocSinh}})],1)]),_c('div',{staticClass:"card-body"},[_c('PieChart',{attrs:{"data_PieChart":_vm.dataBieuDoTongQuan_HocSinh.slice(0, 5)}})],1)])]),_c('div',{staticClass:"col-md-4 mb-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"title"},[_vm._v("Trạng thái")]),_c('div',[_c('CustomButton',{attrs:{"type":"text","icon":"el-icon-refresh-right","label":"","size":"medium"},on:{"click":_vm.handleRefresh_DataBieuDoTrangThai_HocSinh}})],1)]),_c('div',{staticClass:"card-body"},[_c('StackedColumnChart',{attrs:{"data_StackedColumnChart":_vm.customColorStackedColumnChart(
                                    _vm.convert_dataBieuDoTrangThai_HocSinh
                                ),"xaxis_categories":_vm.xaxisCategories.trangThai}})],1)])]),_c('div',{staticClass:"col-md-4 mb-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"title"},[_vm._v("Giới tính")]),_c('div',[_c('CustomButton',{attrs:{"type":"text","icon":"el-icon-refresh-right","label":"","size":"medium"},on:{"click":_vm.handleRefresh_DataBieuDoGioiTinh_HocSinh}})],1)]),_c('div',{staticClass:"card-body"},[_c('StackedColumnChart',{attrs:{"data_StackedColumnChart":_vm.customColorStackedColumnChart(
                                    _vm.dataBieuDoGioiTinh_HocSinh
                                ),"xaxis_categories":_vm.xaxisCategories.gioiTinh}})],1)])]),_c('div',{staticClass:"col-md-4 mb-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"title"},[_vm._v("Loại hình đào tạo")]),_c('div',[_c('CustomButton',{attrs:{"type":"text","icon":"el-icon-refresh-right","label":"","size":"medium"},on:{"click":_vm.handleRefresh_DataBieuDoLoaiHinhDaoTao_HocSinh}})],1)]),_c('div',{staticClass:"card-body"},[_c('StackedColumnChart',{attrs:{"data_StackedColumnChart":_vm.customColorStackedColumnChart(
                                    _vm.dataBieuDoLoaiHinhDaoTao_HocSinh
                                ),"xaxis_categories":_vm.xaxisCategories.loaiHinhDaoTao}})],1)])]),_c('div',{staticClass:"col-md-4 mb-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"title"},[_vm._v("Khu vực")]),_c('div',[_c('CustomButton',{attrs:{"type":"text","icon":"el-icon-refresh-right","label":"","size":"medium"},on:{"click":_vm.handleRefresh_DataBieuDoKhuVuc_HocSinh}})],1)]),_c('div',{staticClass:"card-body"},[_c('StackedColumnChart',{attrs:{"data_StackedColumnChart":_vm.customColorStackedColumnChart(
                                    _vm.dataBieuDoKhuVuc_HocSinh
                                ),"xaxis_categories":_vm.xaxisCategories.khuVuc}})],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }