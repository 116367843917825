<template>
    <div>
        <div class="chart-container">
            <div ref="chartContainer"></div>
        </div>
    </div>
</template>

<script>
import ApexCharts from 'apexcharts'

export default {
    data() {
        return {
            colorMapping: {
                // MN: '#2E93fA',
                // TH: '#66DA26',
                // THCS: '#546E7A',
                // THPT: '#E91E63',
                // GDTX: '#FF9800',
                // LC12: '#F781F3',
                // LC23: '#FFFF00',
                // LCK: '#19070B'
                MN: '#E14A02',
                TH: '#FFA554',
                THCS: '#8DAF88',
                THPT: '#FFDFA5',
                GDTX: '#325434',
                LC: '#7D5510'

                // ... Các tên khác và màu sắc tương ứng
            },
            nameMapping: {
                NV: 'Nhân viên',
                QL: 'Cán bộ quản lý',
                GV: 'Giáo viên'
            }
        }
    },
    mounted() {
        this.renderChart()
    },
    watch: {
        data_StackedColumnChart: {
            deep: true,
            handler(newData) {
                const convertName = newData.map((item) => {
                    const newName = this.nameMapping[item.name] || item.name
                    item.name = newName
                    return item
                })

                this.updateChart(convertName)
            }
        }
    },

    methods: {
        renderChart() {
            const options = {
                series: this.data_StackedColumnChart.map((item) => ({
                    name: item.name,
                    data: item.data
                })),
                chart: {
                    type: 'bar',
                    height: 300,
                    stacked: true,
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val, opts) {
                        return val.toLocaleString()
                    },
                    style: {
                        fontSize: '12px',
                        fontWeight: 'bold'
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 10,
                        dataLabels: {
                            total: {
                                enabled: true,
                                style: {
                                    fontSize: '12px',
                                    fontWeight: 900
                                },
                                formatter: function (val) {
                                    if (val !== undefined) {
                                        return val.toLocaleString()
                                    }
                                    return val
                                }
                            }
                        }
                    }
                },
                noData: {
                    text: 'Biểu đồ chưa có dữ liệu',
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '14px',
                        fontFamily: undefined
                    }
                },
                xaxis: {
                    categories: [],
                    axisTicks: {
                        show: true
                    },
                    axisBorder: {
                        show: true
                    },
                    labels: {
                        hideOverlappingLabels: false
                    }
                },
                yaxis: {
                    axisTicks: {
                        show: true
                    },
                    axisBorder: {
                        show: true
                    },
                    labels: {
                        hideOverlappingLabels: true
                    }
                },
                tooltip: {
                    enabled: true,
                    fillSeriesColor: false,
                    theme: 'dark',
                    style: {
                        fontSize: '12px',
                        fontFamily: undefined
                    },
                    onDatasetHover: {
                        highlightDataSeries: false
                    }
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'center'
                },
                grid: {
                    padding: {
                        left: 13.5,
                        right: 0
                    },
                    xaxis: {
                        lines: {
                            show: false
                        }
                    }
                },
                fill: {
                    opacity: 1
                },
                colors: [
                    // '#2E93fA',
                    // '#66DA26',
                    // '#546E7A',
                    // '#E91E63',
                    // '#FF9800',
                    // '#F781F3',
                    // '#FFFF00',
                    // '#19070B',
                    '#E14A02',
                    '#FFA554',
                    '#8DAF88',
                    '#FFDFA5',
                    '#325434',
                    '#7D5510'
                ]
            }

            const chartContainer = this.$refs.chartContainer
            this.chart = new ApexCharts(chartContainer, options)
            this.chart.render()
        },
        updateChart(newData) {
            if (this.chart) {
                this.chart.updateSeries(
                    newData.map((item) => ({
                        name: item.name,
                        data: item.data,
                        color: this.colorMapping[item.name] || null
                    }))
                )
                this.chart.updateOptions({
                    xaxis: {
                        categories: this.xaxis_categories || []
                    }
                })
            }
        }
    },
    props: {
        data_StackedColumnChart: {
            type: Array,
            required: true
        },
        xaxis_categories: {
            type: Array
        }
    }
}
</script>

<style scoped>
.chart-container {
    width: 100%;
}
</style>
