<template>
    <div class="school">
        <div class="header">
            <div class="left">
                <div>
                    <el-icon name="school" class="icon"></el-icon>
                </div>
                <div class="title">Thống kê theo giai đoạn</div>
            </div>
            <div class="right">
                <!-- <CustomButton label="Chi tiết" size="small" class="custom-btn" /> -->
            </div>
        </div>

        <div class="layout-card">
            <div class="row">
                <div class="col-md-4 mb-4">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">Thống kê lớp học</div>
                            <div>
                                <CustomButton
                                    type="text"
                                    icon="el-icon-refresh-right"
                                    label=""
                                    size="medium"
                                    @click="
                                        handleRefresh_dataBieuDoThongKeTheoNam_LopHoc
                                    "
                                />
                            </div>
                        </div>
                        <div class="card-body">
                            <ChartBar
                                :chart-data="data_lopHoc"
                                :chart-categories="categories"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">Thống kê giáo viên</div>
                            <div>
                                <CustomButton
                                    type="text"
                                    icon="el-icon-refresh-right"
                                    label=""
                                    size="medium"
                                    @click="
                                        handleRefresh_dataBieuDoThongKeTheoNam_GiaoVien
                                    "
                                />
                            </div>
                        </div>
                        <div class="card-body">
                            <ChartBar
                                :chart-data="data_giaoVien"
                                :chart-categories="categories"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">Thống kê học sinh</div>
                            <div>
                                <CustomButton
                                    type="text"
                                    icon="el-icon-refresh-right"
                                    label=""
                                    size="medium"
                                    @click="
                                        handleRefresh_dataBieuDoThongKeTheoNam_HocSinh
                                    "
                                />
                            </div>
                        </div>
                        <div class="card-body">
                            <ChartBar
                                :chart-data="data_hocSinh"
                                :chart-categories="categories"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mb-6">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">Học sinh học lực</div>
                            <div>
                                <CustomButton
                                    type="text"
                                    icon="el-icon-refresh-right"
                                    label=""
                                    size="medium"
                                    @click="
                                        handleRefresh_dataBieuDoThongKe_HocSinh_HocLuc
                                    "
                                />
                            </div>
                        </div>
                        <div class="card-body">
                            <EducationalCompetenceChart
                                :series="dataBieuDoHocSinhHocLuc"
                                :categories="categories"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mb-6">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">Trình độ giáo viên</div>
                            <div>
                                <CustomButton
                                    type="text"
                                    icon="el-icon-refresh-right"
                                    label=""
                                    size="medium"
                                    @click="
                                        handleRefresh_dataBieuDoThongKe_GiaoVien_TrinhDo
                                    "
                                />
                            </div>
                        </div>
                        <div class="card-body">
                            <EducationalCompetenceChart
                                :series="dataBieuDoGiaoVienTrinhDo"
                                :categories="categories"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import Vue from 'vue'
import { Icon } from 'element-ui'
import { Router } from '@/constants/Router'
import 'element-ui/lib/theme-chalk/index.css'
import ChartBar from '@/components/ChartBar.vue'
import EducationalCompetenceChart from '@/components/EducationalCompetenceChart.vue'
export default {
    name: 'StatisticsByYear',
    components: {
        'el-icon': Icon,
        CustomButton,
        ChartBar,
        EducationalCompetenceChart
    },
    data() {
        return {
            categories: [
                '2019-2020',
                '2020-2021',
                '2021-2022',
                '2022-2023',
                '2023-2024'
            ],
            data_lopHoc: [],
            data_giaoVien: [],
            data_hocSinh: [],
            xaxisCategories: {
                chatLuongDaoTao: [
                    'C.Lượng tối thiểu',
                    'Chuẩn mức 1',
                    'Chuẩn mức 2'
                ],
                loaiHinhDaoTao: ['Công lập', 'Tư thục', 'Dân lập']
            }
        }
    },
    props: {
        dataBieuDoThongKeTheoNam_LopHoc: {
            type: Array,
            required: true
        },
        dataBieuDoThongKeTheoNam_GiaoVien: {
            type: Array,
            required: true
        },
        dataBieuDoThongKeTheoNam_HocSinh: {
            type: Array,
            required: true
        },
        dataBieuDoHocSinhHocLuc: {
            type: Array,
            required: true
        },
        dataBieuDoGiaoVienTrinhDo: {
            type: Array,
            required: true
        }
    },
    watch: {
        dataBieuDoThongKeTheoNam_LopHoc(newVal) {
            this.convertData(newVal, 'data_lopHoc')
        },
        refresh_dataBieuDoThongKeTheoNam_LopHoc: Function,
        dataBieuDoThongKeTheoNam_GiaoVien(newVal) {
            this.convertData(newVal, 'data_giaoVien')
        },
        refresh_dataBieuDoThongKeTheoNam_GiaoVien: Function,
        dataBieuDoThongKeTheoNam_HocSinh(newVal) {
            this.convertData(newVal, 'data_hocSinh')
        },
        refresh_dataBieuDoThongKeTheoNam_HocSinh: Function,

        refresh_dataBieuDoHocSinhHocLuc: Function,
        refresh_dataBieuDoGiaoVienTrinhDo: Function,

        dataBieuDoHocSinhHocLuc(newVal) {
            console.log(newVal)
        }
    },
    methods: {
        handleRefresh_dataBieuDoThongKeTheoNam_LopHoc() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            this.$emit('refresh_dataBieuDoThongKeTheoNam_LopHoc')
            loading.close()
        },
        handleRefresh_dataBieuDoThongKeTheoNam_GiaoVien() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            this.$emit('refresh_dataBieuDoThongKeTheoNam_GiaoVien')
            loading.close()
        },
        handleRefresh_dataBieuDoThongKeTheoNam_HocSinh() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            this.$emit('refresh_dataBieuDoThongKeTheoNam_HocSinh')
            loading.close()
        },
        handleRefresh_dataBieuDoThongKe_HocSinh_HocLuc() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            this.$emit('refresh_dataBieuDoHocSinhHocLuc')
            loading.close()
        },
        handleRefresh_dataBieuDoThongKe_GiaoVien_TrinhDo() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            this.$emit('refresh_dataBieuDoGiaoVienTrinhDo')
            loading.close()
        },
        convertData(newVal, targetData) {
            const convertedData = newVal.reduce(
                (result, item) => {
                    result[targetData].push(...item.data)
                    return result
                },
                { [targetData]: [] }
            )

            Vue.set(this, targetData, convertedData[targetData])
        }
    }
}
</script>

<style scoped>
.school {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 15px;
    padding-left: 6px;
    padding-right: 6px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 10px 12px 10px;
}
.header .left {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #2e8bc0;
    font-weight: 700;
    text-transform: uppercase;
}
.header .left .icon {
    font-size: 18px;
    color: #000000;
    font-weight: 700;
    margin-right: 5px;
}
.header .right {
    margin-left: 6px;
}

.layout-card {
    padding: 0px 10px 60px 10px;
}
.layout-card .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f2f3f8;
    height: 25px;
    color: black;
    padding: 5px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.layout-card .card-header .title {
    margin: -5px 0px 0px 10px;
    color: #000000;
    font-weight: 700;
    font-size: 15px;
}
.layout-card .card-body {
    padding: 5px;
    background: white;
    border: #f2f3f8 2px solid;
    height: 300px;
}
.custom-btn {
    font-weight: 700;
    color: #fff;
    background-color: #6aab9c;
}
::v-deep .el-icon-school:before {
    font-size: 18px;
    color: #2e8bc0;
    content: '\e756';
}
</style>
