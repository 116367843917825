<template>
    <div class="mb-4">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '' }"
                ><span class="main">TỔNG QUAN</span>
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                <span class="span">{{ title }} </span>
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                <span class="span">{{ content }}</span>
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        content: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>
span .span {
    color: #6aab9c;
    font-weight: 600;

    cursor: pointer;
}
</style>
