<template>
    <el-dialog
        :visible="chinhSuaVisible"
        @close="closeModalChinhSua"
        fullscreen
        class="custom-dialog"
    >
        <span slot="title" class="custom-dialog-title"
            >Chỉnh sửa thông tin vai trò
            <hr />
        </span>
        <div class="row">
            <div class="col-4">
                <label>Tên vai trò: <span>*</span></label>
                <div>
                    <el-input
                        :disabled="true"
                        size="small"
                        placeholder="Nhập ..."
                        v-model="selectedValue.input_tenVaiTro"
                    ></el-input>
                </div>
            </div>
            <div class="col-4">
                <label>Vai trò gốc: <span>*</span></label>
                <div>
                    <el-select
                        :disabled="true"
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        clearable
                        v-model="selectedValue.selectedValue_vaiTroGoc"
                        placeholder="Chọn"
                        size="small"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelectRole"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="col-4">
                <label>Mô tả: <span>*</span></label>
                <div>
                    <el-input
                        size="small"
                        placeholder="Nhập ..."
                        v-model="selectedValue.input_moTa"
                    ></el-input>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-xs-12 col-sm-6">
                <label class="typo__label"
                    >Phân quyền chức năng<span class="red">*</span></label
                >
                <div
                    v-if="data_PhanQuyenChucNang"
                    class="bd Dts-scoll"
                    :style="{ maxHeight: maxHeight + 'px' }"
                >
                    <v-jstree
                        :data="data_PhanQuyenChucNang"
                        show-checkbox="show-checkbox"
                        multiple="multiple"
                        allow-batch="allow-batch"
                        whole-row="whole-row"
                        @item-click="handle_PhanQuyenChucNang"
                    ></v-jstree>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6">
                <label class="typo__label"
                    >Phân quyền báo cáo <span class="red">*</span></label
                >
                <div
                    class="bd Dts-scoll"
                    v-if="data_PhanQuyenBaoCao"
                    :style="{ height: maxHeight + 'px' }"
                >
                    <v-jstree
                        :data="data_PhanQuyenBaoCao"
                        show-checkbox="show-checkbox"
                        multiple="multiple"
                        allow-batch="allow-batch"
                        whole-row="whole-row"
                        @item-click="handle_PhanQuyenBaoCao"
                    ></v-jstree>
                </div>
            </div>
        </div>
        <div class="layout-btn">
            <CustomButton
                label="Đóng"
                size="small"
                class="btn-default btn-delete"
                @click="closeModalChinhSua"
            />
            <CustomButton
                label="Chỉnh sửa"
                class="btn-default btn-update"
                size="small"
                @click="handleUpdate"
            />
        </div>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
import CustomButton from '@/components/CustomButton.vue'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { ESelectRole } from '@/mock_data'
import VJstree from 'vue-jstree'
export default {
    name: 'ModalDoiMauKhau',
    props: {
        chinhSuaVisible: {
            type: Boolean,
            required: true
        },

        closeModalChinhSua: {
            type: Function,
            required: true
        },
        data_chinhSua: {
            type: Object,
            required: true
        },
        getDataTableRole: Function
    },
    components: { VJstree, CustomButton },
    watch: {
        async data_chinhSua(newValue) {
            this.selectedValue.input_tenVaiTro = newValue.roleName
            this.selectedValue.selectedValue_vaiTroGoc = newValue.parentRole
            this.selectedValue.input_moTa = newValue.description
            this.roleUpdate = newValue.roleId

            await this.getDataChinhSua(newValue.roleId)
        }
    },
    data() {
        return {
            maxHeight: window.innerHeight,
            data_PhanQuyenChucNang: [],
            data_PhanQuyenBaoCao: [],
            dataChinhSua: [],
            getDataESelect: {
                ESelectRole: ESelectRole
            },
            selectedValue: {
                input_tenVaiTro: '',
                selectedValue_vaiTroGoc: '',
                input_moTa: ''
            },
            roleUpdate: 0
        }
    },

    methods: {
        async handleUpdate() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Data = {
                    roleDto: {
                        roleName: this.selectedValue.input_tenVaiTro,
                        parentRole: this.selectedValue.selectedValue_vaiTroGoc,
                        description: this.selectedValue.input_moTa,
                        maSo: this.authUser.ma_so
                    },
                    listReport: this.data_PhanQuyenBaoCao,
                    listMenu: this.data_PhanQuyenChucNang,
                    roleUpdate: this.roleUpdate
                }

                this.$confirm('Xác nhận chỉnh sửa vai trò?', 'Thông báo', {
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy',
                    type: 'warning'
                })
                    .then(async () => {
                        const response = await sendRequest(
                            Api.heThong.quanLyVaiTro.updateDataChinhSua,
                            request_Data,
                            request_Header
                        )
                        if (response.rc == 0) {
                            loading.close()

                            this.$message({
                                type: 'success',
                                message: 'Chỉnh sửa thành công'
                            })
                            this.closeModalChinhSua()
                            this.$emit('getDataTableRole')
                        } else {
                            loading.close()
                            this.$message({
                                type: 'warning',
                                message: response.rd
                            })
                        }
                    })
                    .catch(() => {
                        loading.close()
                    })
            } catch (error) {
                console.log(error)
            }
        },
        handle_PhanQuyenChucNang(node) {
            let allCheck = []
            if (node && node.parentItem && node.parentItem.children) {
                allCheck = node.parentItem.children.filter((obj) => obj.selected)
            }
            if (node && node.model && node.model.selected) {
                node.parentItem.selected = true
            } else {
                if (!allCheck.length) {
                    node.parentItem.selected = false
                }
            }
        },
        handle_PhanQuyenBaoCao(node) {
            let allCheck = []
            if (node && node.parentItem && node.parentItem.children) {
                allCheck = node.parentItem.children.filter((obj) => obj.selected)
            }
            if (node && node.model && node.model.selected) {
                node.parentItem.selected = true
            } else {
                if (!allCheck.length) {
                    node.parentItem.selected = false
                }
            }
        },
        async getDataChinhSua(newValue) {
            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                roleId: newValue
            }
            const response = await sendRequest(
                Api.heThong.quanLyVaiTro.getDataChinhSua,
                request_Data,
                request_Header
            )
            this.data_PhanQuyenChucNang = response.item.menu
            this.data_PhanQuyenBaoCao = response.item.baoCaoRoleResponses
        }
    },

    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        }
    },
    mounted() {
        this.maxHeight -= 260
    }
}
</script>
<style scoped>
.row span {
    color: red;
}
.layout-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
hr {
    border: 1px solid #7ca33f;
}

.bd {
    border: 1px solid slategrey;

    overflow-y: scroll;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}

.btn-add {
    background-color: var(--light-green);
}

.btn-update {
    background-color: var(--yellow);
}
.btn-delete {
    background-color: var(--orange);
}
</style>
