<template>
    <el-dialog
        :visible="chinhSuaModalVisible"
        @close="closeModalChinhSua"
        width="80%"
    >
        <span slot="title" class="custom-dialog-title">
            CHỈNH SỬA TỔ THÔN
            <hr />
        </span>
        <div class="row form">
            <div class="col-md-4">
                <label>Tỉnh thành (<span class="span">*</span>)</label>

                <ESelect
                    :key="selectedValue.selectedValue_tinhThanh?.value"
                    style="width: 100%"
                    no-match-text="Không tìm thấy bản ghi nào"
                    no-data-text="danh sách lựa chọn trống"
                    :clearable="true"
                    :disabled="true"
                    :data="ESelect_tinhThanh"
                    :placeholder="'Chọn '"
                    :filterable="true"
                    :multiple="false"
                    :collapse-tags="true"
                    :fields="['title', 'value']"
                    v-model="selectedValue.selectedValue_tinhThanh"
                    @input="handleChangeTinhThanh"
                />
            </div>
            <div class="col-md-4">
                <label>Quận huyện (<span class="span">*</span>)</label>
                <ESelect
                    :key="selectedValue.selectedValue_quanHuyen?.value"
                    :reset="resetESelect_quanHuyen"
                    @reset-completed="handleResetCompleted_quanHuyen"
                    style="width: 100%"
                    no-match-text="Không tìm thấy bản ghi nào"
                    no-data-text="danh sách lựa chọn trống"
                    :clearable="true"
                    :disabled="false"
                    :data="getDataESelect.ESelect_quanHuyen"
                    :placeholder="'Chọn '"
                    :filterable="true"
                    :multiple="false"
                    :collapse-tags="true"
                    :fields="['title', 'value']"
                    v-model="selectedValue.selectedValue_quanHuyen"
                    @input="handleChangeQuanHuyen"
                />
                <span v-if="err_maQuanHuyen.length > 0" class="error">{{
                    err_maQuanHuyen
                }}</span>
            </div>
            <div class="col-md-4">
                <label>Phường xã (<span class="span">*</span>)</label>
                <ESelect
                    :key="selectedValue.selectedValue_phuongXa?.value"
                    :reset="resetESelect_phuongXa"
                    @reset-completed="handleResetCompleted_phuongXa"
                    style="width: 100%"
                    no-match-text="Không tìm thấy bản ghi nào"
                    no-data-text="danh sách lựa chọn trống"
                    :clearable="true"
                    :disabled="false"
                    :data="getDataESelect.ESelect_phuongXa"
                    :placeholder="'Chọn '"
                    :filterable="true"
                    :multiple="false"
                    :collapse-tags="true"
                    :fields="['title', 'value']"
                    v-model="selectedValue.selectedValue_phuongXa"
                />
                <span v-if="err_maPhuongXa.length > 0" class="error">{{
                    err_maPhuongXa
                }}</span>
            </div>

            <div class="col-md-4 mt-4">
                <label>Mã tổ thôn (<span class="span">*</span>)</label>

                <el-input
                    placeholder="Nhập mã tổ thôn ..."
                    v-model="selectedValue.input_maToThon"
                    size="small"
                    style="width: 100%"
                    :disabled="true"
                >
                </el-input>
            </div>
            <div class="col-md-4 mt-4">
                <label>Tên tổ thôn (<span class="span">*</span>)</label>

                <el-input
                    placeholder="Nhập tên tổ thôn ..."
                    v-model="selectedValue.input_tenToThon"
                    size="small"
                    style="width: 100%"
                >
                </el-input>
                <span v-if="err_tenToThon.length > 0" class="error">{{
                    err_tenToThon
                }}</span>
            </div>
        </div>
        <div class="layout-btn">
            <div>
                <CustomButton
                    label="Đóng"
                    size="small"
                    class="btn-close btn-default"
                    @click="closeModalChinhSua"
                />
            </div>
            <div style="margin-left: 10px">
                <CustomButton
                    label="Chỉnh sửa"
                    size="small"
                    class="btn-update btn-default"
                    @click="handleChinhSua"
                />
            </div>
        </div>
    </el-dialog>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import { mapState } from 'vuex'
import sendRequest from '@/services'
import ESelect from '@/components/ESelect.vue'

import Api from '@/constants/Api'
import { ESelectStatus_SuperAdmin } from '@/mock_data'
import { validateField } from '@/utils'
export default {
    name: 'ModalThemMoiTruongHoc',
    props: {
        chinhSuaModalVisible: {
            type: Boolean,
            required: true
        },
        closeModalChinhSua: {
            type: Function,
            required: true
        },
        dataModalChinhSua: {
            type: Object,
            required: true
        },
        ESelect_tinhThanh: {
            type: Array,
            required: true
        },
        handleSearch: Function
    },

    components: { CustomButton, ESelect },
    data() {
        return {
            resetESelect_phuongXa: false,
            resetESelect_quanHuyen: false,
            getDataESelect: {
                ESelect_tinhThanh: [],
                ESelect_quanHuyen: [],
                ESelect_phuongXa: [],
                ESelect_TrangThai: ESelectStatus_SuperAdmin
            },

            selectedValue: {
                input_tenToThon: '',
                input_maToThon: '',
                selectedValue_trangThai: '',
                selectedValue_tinhThanh: '',
                selectedValue_quanHuyen: '',
                selectedValue_phuongXa: ''
            },
            change_tinhThanh: '',
            trangThai: '',
            err_maQuanHuyen: '',
            err_maPhuongXa: '',
            err_maToThon: '',
            err_tenToThon: ''
        }
    },
    watch: {
        dataModalChinhSua(newValue) {
            this.getDataESelectQuanHuyen()
            this.getDataESelectPhuongXa()
            this.selectedValue.selectedValue_tinhThanh = {
                value: newValue.maTinhthanh
            }

            this.selectedValue.selectedValue_quanHuyen = {
                value: newValue.maQuanHuyen
            }

            this.selectedValue.selectedValue_phuongXa = {
                value: newValue.maPhuongXa
            }

            this.selectedValue.input_maToThon = newValue.maToThon
            this.selectedValue.input_tenToThon = newValue.tenToThon
            this.trangThai = newValue.trangThai
        }
    },
    methods: {
        handleResetCompleted_quanHuyen() {
            this.resetESelect_quanHuyen = false
        },
        handleResetCompleted_phuongXa() {
            this.resetESelect_phuongXa = false
        },
        async handleChangeTinhThanh() {
            this.resetESelect_phuongXa = true
            this.resetESelect_quanHuyen = true
            const change_tinhThanh =
                this.selectedValue.selectedValue_tinhThanh?.value !== undefined
                    ? this.selectedValue.selectedValue_tinhThanh?.value
                    : ''

            await this.getDataESelectQuanHuyen(change_tinhThanh)
            this.change_tinhThanh = change_tinhThanh
        },

        async getDataESelectQuanHuyen(change_tinhThanh) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    start: 0,
                    limit: 999,
                    maTinhThanh: change_tinhThanh
                }
                const response = await sendRequest(
                    Api.superAdmin.toThon.getQuanHuyen,
                    null,
                    request_Header,
                    request_Params
                )

                loading.close()
                this.getDataESelect.ESelect_quanHuyen = response.rows.map(
                    (item) => ({
                        ...item,
                        title: `${item.tenQuanHuyen} - [${item.maQuanHuyen}]`,
                        value: item.maQuanHuyen
                    })
                )
                if (change_tinhThanh === '') {
                    this.getDataESelect.ESelect_quanHuyen = []
                }
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },
        async handleChangeQuanHuyen() {
            this.resetESelect_phuongXa = true
            const change_quanHuyen =
                this.selectedValue.selectedValue_quanHuyen?.value !== undefined
                    ? this.selectedValue.selectedValue_quanHuyen?.value
                    : ''

            this.getDataESelectPhuongXa(change_quanHuyen)
        },
        async getDataESelectPhuongXa(change_quanHuyen) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    start: 0,
                    limit: 999,
                    maTinhThanh: this.change_tinhThanh,
                    maQuanHuyen: change_quanHuyen
                }
                const response = await sendRequest(
                    Api.superAdmin.toThon.getPhuongXa,
                    null,
                    request_Header,
                    request_Params
                )

                loading.close()
                this.getDataESelect.ESelect_phuongXa = response.rows.map(
                    (item) => ({
                        ...item,
                        title: `${item.tenPhuongXa} - [${item.maPhuongXa}]`,
                        value: item.maPhuongXa
                    })
                )
                if (change_quanHuyen === '') {
                    this.getDataESelect.ESelect_phuongXa = []
                }
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },

        handleChinhSua() {
            const maQuanHuyen = this.selectedValue.selectedValue_quanHuyen?.value
            const maPhuongXa = this.selectedValue.selectedValue_phuongXa?.value
            const maToThon = this.selectedValue.input_maToThon
            const tenToThon = this.selectedValue.input_tenToThon

            const fieldsToValidate = [
                {
                    name: 'err_maQuanHuyen',
                    value: maQuanHuyen,
                    errorField: 'err_maQuanHuyen',
                    errorMessage: 'Vui lòng chọn mã quận huyện'
                },
                {
                    name: 'err_maPhuongXa',
                    value: maPhuongXa,
                    errorField: 'err_maPhuongXa',
                    errorMessage: 'Vui lòng chọn mã phường xã'
                },

                {
                    name: 'err_tenToThon',
                    value: tenToThon,
                    errorField: 'err_tenToThon',
                    errorMessage: 'Vui lòng nhập tên tổ thôn'
                }
            ]
            let isValid = true

            for (const field of fieldsToValidate) {
                isValid = validateField(this, field, isValid)
            }

            if (isValid) {
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })
                this.$confirm('Xác nhận chỉnh sửa ?', 'Thông báo', {
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy',
                    type: 'warning'
                })
                    .then(async () => {
                        const request_Header = {
                            token: this.authToken
                        }
                        const request_Data = {
                            maPhuongXa: maPhuongXa,

                            maToThon: maToThon,
                            tenToThon: tenToThon,
                            trangThai: this.trangThai
                        }
                        const response = await sendRequest(
                            Api.superAdmin.toThon.chinhSuaToThon,
                            request_Data,

                            request_Header
                        )
                        if (response.code == 200) {
                            this.$message({
                                type: 'success',
                                message: 'Chỉnh sửa thành công'
                            })
                            this.closeModalChinhSua()
                            this.$emit('handleSearch')
                        } else {
                            loading.close()
                            this.$message({
                                type: 'error',
                                message: response.message
                            })
                        }
                    })
                    .catch(() => {
                        loading.close()
                    })
            }
        }
    },

    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped>
::v-deep .el-input.is-disabled .el-input__inner {
    background-color: #eee !important;
    color: black !important;
}
hr {
    border: 1px solid green;
}
.form label {
    font-weight: bold;
}
.form .span {
    color: red;
}
.form .error-message {
    font-style: italic;
    color: red;
    margin-top: 5px;
}

.layout-btn {
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
}
.btn-close,
.btn-close:hover {
    background-color: var(--orange);
    color: var(--btn-text-color);
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.btn-update,
.btn-update:hover {
    color: var(--btn-text-color);
    background-color: var(--yellow);
}
</style>
