<template>
    <div>
        <CustomBreadcrumb
            :title="'TIẾP NHẬN DỮ LIỆU QLNT'"
            :content="'CẤU HÌNH LỊCH ĐỒNG BỘ CẤP SỞ'"
        />
        <CustomContentUI>
            <div class="title">Năm học</div>
            <div class="row">
                <div class="col-3 d-flex justify-content-between">
                    <div>
                        <ESelectYear
                            v-model="selectedValueSchoolYear"
                            placeholder="Chọn năm"
                            size="small"
                            :no-data-text="'Không có bản ghi nào'"
                            :no-match-text="'Không tìm thấy bản ghi nào'"
                        ></ESelectYear>
                    </div>
                    <div>
                        <CustomButton
                            label="Tìm kiếm"
                            @click="handleClickSearch"
                            size="small"
                            class="bg-search btn-default"
                        />
                    </div>
                </div>
            </div>
        </CustomContentUI>

        <div class="btn-addNew">
            <CustomButton
                label="Thêm mới"
                size="small"
                class="btn-add btn-default"
                @click="openModalAddNew"
            />
        </div>
        <CustomTable :columns="tableColumns">
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="7" class="text-tbody">
                            Không có bản ghi nào.
                        </td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td class="text-tbody">{{ index + 1 }}</td>
                    <td class="text-tbody">
                        {{ item.namHoc }} - {{ item.namHoc + 1 }}
                    </td>
                    <td class="text-tbody">{{ item.dauNamTuNgay }}</td>
                    <td class="text-tbody">{{ item.dauNamToiNgay }}</td>
                    <td class="text-tbody">{{ item.updatedTime }}</td>
                    <td class="text-tbody">
                        <span v-if="item.status === 1" class="text-status-on">
                            Đang hoạt động
                        </span>

                        <span v-if="item.status === 0" class="text-status-off"
                            >Ngưng hoạt động</span
                        >
                    </td>
                    <td class="text-tbody">
                        <span>
                            <span v-if="item.status === 1">
                                <CustomButton
                                    icon="el-icon-lock"
                                    label="Khóa"
                                    size="small"
                                    class="btn-default btn-lock"
                                    @click="openModalLockUp"
                                />
                            </span>
                            <span v-if="item.status === 0">
                                <CustomButton
                                    icon="el-icon-lock"
                                    label="Mở Khóa"
                                    size="small"
                                    class="btn-unlock btn-default"
                                    @click="openModalLockUp"
                                />
                            </span>
                            <el-dialog
                                :visible.sync="lockUpModalVisible"
                                width="30%"
                                title="Xác nhận thay đổi trạng thái"
                                @close="closeModalLockUp"
                                class="custom-dialog"
                            >
                                <div class="custom-dialog-body">
                                    <span>
                                        <CustomButton
                                            label="Hủy"
                                            size="small"
                                            @click="closeModalLockUp"
                                        />
                                        <CustomButton
                                            label="Đồng ý"
                                            size="small"
                                            type="primary"
                                            @click="
                                                handleClickChangeStatus(item.id)
                                            "
                                        />
                                    </span>
                                </div>
                            </el-dialog>
                        </span>
                        <span class="btn-update">
                            <CustomButton
                                icon="el-icon-edit-outline"
                                label="Chỉnh sửa"
                                size="small"
                                class="btn-default btn-update"
                                @click="openModalUpdate(item)"
                            />
                        </span>
                    </td>
                </tr>
            </template>
        </CustomTable>
        <ModalThemMoi
            :addNewVisible="addNewVisible"
            :closeModalAddNew="closeModalAddNew"
            :selectedValueSchoolYear="selectedValueSchoolYear"
            @getDataTable="getDataTable"
        />
        <ModalChinhSua
            :updateVisible="updateVisible"
            :closeModalUpdate="closeModalUpdate"
            :selectedValueSchoolYear="selectedValueSchoolYear"
            @getDataTable="getDataTable"
            :dataModalUpdate="dataModalUpdate"
        />
    </div>
</template>
<script>
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'
import ModalChinhSua from './ModalChinhSua.vue'
import ModalThemMoi from './ModalThemMoi.vue'
import ESelectYear from '@/components/ESelectYear.vue'
import CustomButton from '@/components/CustomButton.vue'
import { mapState } from 'vuex'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { currentYear } from '@/utils'
import CustomContentUI from '@/components/CustomContentUI.vue'
import CustomTable from '@/components/CustomTable.vue'
export default {
    name: 'capSoCauHinhLichDongBo',
    components: {
        CustomButton,
        ESelectYear,
        CustomBreadcrumb,
        ModalThemMoi,
        ModalChinhSua,
        CustomContentUI,
        CustomTable
    },
    data() {
        return {
            addNewVisible: false,
            lockUpModalVisible: false, // Biến điều khiển hiển thị modal Khóa
            updateVisible: false,
            dataModalUpdate: {},
            tableColumns: [
                { key: '1', label: 'STT' },
                { key: '2', label: 'Năm học' },
                { key: '3', label: 'Ngày bắt đầu' },
                { key: '4', label: 'Ngày kết thúc' },
                { key: '5', label: 'Ngày cập nhật' },
                { key: '6', label: 'Trạng thái' },
                { key: '7', label: 'Thao tác' }
            ],
            tableData: [],

            selectedValueSchoolYear: null,
            request_Data: {
                ids: null,
                status: null
            },
            request_Params: {
                idSo: null,
                limit: 25,
                namHoc: 2022,
                start: 0,
                status: null
            },

            requestHeaders: {
                Authorization: ''
            }
        }
    },
    methods: {
        openModalLockUp() {
            this.lockUpModalVisible = true
        },
        closeModalLockUp() {
            this.lockUpModalVisible = false
        },
        openModalUpdate(item) {
            this.dataModalUpdate = item
            this.updateVisible = true
        },
        closeModalUpdate() {
            this.updateVisible = false
        },
        openModalAddNew() {
            this.addNewVisible = true
        },
        closeModalAddNew() {
            this.addNewVisible = false
        },
        async getDataTable() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                this.requestHeaders = {
                    token: this.authToken
                }

                this.request_Params = {
                    ...this.request_Params,
                    idSo: this.authUser.province,
                    namHoc: this.selectedValueSchoolYear || currentYear
                }

                const response = await sendRequest(
                    Api.tiepNhanDuLieuQLNT.cauHinhLichDongBo.capSo,
                    null,
                    this.requestHeaders,
                    this.request_Params
                )

                if (response.rc == 0) {
                    loading.close()
                    this.tableData = response.tableData
                } else if (response.rc == 2) {
                    loading.close()
                    this.$message({
                        message: response.rd,
                        type: 'error'
                    })
                    this.tableData = response.tableData
                }
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },

        async handleClickSearch() {
            try {
                const request_Params_Update = {
                    ...this.request_Params,
                    namHoc: this.selectedValueSchoolYear
                }
                this.request_Params = request_Params_Update
                await this.getDataTable()
            } catch (error) {
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },

        async handleClickChangeStatus(id) {
            try {
                const loading = this.$loading({
                    lock: true,
                    text: 'Đang thay đổi trạng thái',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })

                const formatId = [id]
                this.request_Data = {
                    ...this.request_Data,
                    idSo: this.authUser.province,
                    status: 0,
                    ids: formatId,
                    namHoc: this.selectedValueSchoolYear || currentYear
                }
                this.requestHeaders = {
                    token: this.authToken
                }

                const response = await sendRequest(
                    Api.tiepNhanDuLieuQLNT.cauHinhLichDongBo.toggleStatus,
                    this.request_Data,
                    this.requestHeaders,
                    null
                )

                await this.getDataTable()
                if (response.rc == 0) {
                    loading.close()
                    this.$message({
                        message: 'Mở khóa cấu hình lịch đồng bộ cấp Sở',
                        type: 'success'
                    })
                    this.lockUpModalVisible = false
                }
            } catch (error) {
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    },
    mounted() {
        this.getDataTable()

        this.selectedValueSchoolYear = String(currentYear)
    }
}
</script>

<style scoped>
.custom-button-no-hover {
    pointer-events: none; /* Ngăn chặn các sự kiện chuột */
}
.layout-btn {
    margin-top: 30px;
    margin-bottom: 5px;
}

.text-tbody .span {
    display: flex;
    font-size: 12px;
    margin-bottom: 5px;
}

.text-tbody .padding {
    padding: 0px 10px 20px 10px;
}
.text-tbody .custom-date-picker {
    height: 28px;
}

.text-tbody .custom-date-picker::placeholder {
    color: gainsboro;

    font-size: 13px;
    background-color: transparent;
    appearance: none;
}
.text-tbody .input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
}
.text-tbody hr {
    margin-top: 20px;
    border: 1px solid #e5e5e5;
}
.text-tbody .custom-btn {
    margin-right: 10px;
}
.text-tbody .btn {
    display: flex;

    padding: 0 !important;
    justify-content: flex-end;
    margin-top: 20px;
}
.custom-dialog-body {
    padding: 10px 0px 20px 0px;
}
.text-body-span {
    font-size: 12px;
}
.btn-addNew {
    background: #fff;
    display: flex;
    justify-content: flex-end;
    padding: 10px 10px 0px 0px;
}

.btn {
    margin-right: 10px;
}
.btn-unlock,
.btn-lock {
    margin-right: 10px;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.bg-search {
    background-color: var(--teal);
}
.btn-add,
.btn-import,
.btn-unlock {
    background-color: var(--light-green);
}

.btn-update {
    background-color: var(--yellow);
}
.btn-delete,
.btn-lock {
    background-color: var(--orange);
}
</style>
