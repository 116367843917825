<!-- CustomTable.vue -->
<template>
    <div class="content">
        <slot></slot>
    </div>
</template>

<script>
export default {}
</script>

<style scoped>
.content {
    background: #fff;
    padding: 10px 10px 10px 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}
.content label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px;
}
.content .title {
    margin-bottom: 5px;
    font-size: 18px;
    color: #212529;
}
.content span {
    color: red;
}
label .color-red {
    color: red;
}
.hide-td {
    display: none;
}
</style>
