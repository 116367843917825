<template>
    <el-dialog
        :visible="lstKhenThuongVisible"
        @close="closeModalLstKhenThuong"
        width="80%"
        class="custom-dialog"
    >
        <span slot="title" class="custom-dialog-title"
            >Thông tin khen thưởng</span
        >

        <table class="table table-bordered table-hover centered-table">
            <thead>
                <tr>
                    <th class="text-thead" style="width: 50px">STT</th>
                    <th class="text-thead">Khen thưởng <span>*</span></th>
                    <th class="text-thead">Nội dung</th>
                    <th class="text-thead">Cấp khen thưởng</th>
                    <th class="text-thead">Số quyết định</th>
                    <th class="text-thead">Ngày khen thưởng</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="lstKhenThuong.length === 0">
                    <td colspan="6">Danh sách trống</td>
                </tr>
                <tr v-for="(item, index) in lstKhenThuong" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>
                        <el-select
                            :disabled="true"
                            size="small"
                            style="width: 100%"
                            v-model="item.maKhenThuongGv"
                            filterable
                            collapse-tags
                            placeholder="Chọn"
                            no-data-text="Không có dữ liệu"
                            no-match-text="Không có dữ liệu phù hợp"
                        >
                            <el-option
                                v-for="item in fakeDataSelect"
                                :key="item.mappingCode"
                                :label="item.constantTitle"
                                :value="item.mappingCode"
                            >
                            </el-option>
                        </el-select>
                    </td>
                    <td>
                        <el-input
                            :disabled="true"
                            v-model="item.noiDung"
                            size="small"
                        >
                        </el-input>
                    </td>

                    <td>
                        <el-input
                            :disabled="true"
                            v-model="item.capKhenThuong"
                            size="small"
                        >
                        </el-input>
                    </td>
                    <td>
                        <el-input
                            :disabled="true"
                            v-model="item.soQuyetDinhKhenThuong"
                            size="small"
                        >
                        </el-input>
                    </td>
                    <td>
                        <el-date-picker
                            :disabled="true"
                            v-model="item.ngayKhenThuong"
                            type="date"
                            placeholder="Chọn ngày"
                            format="dd-MM-yyyy"
                            size="small"
                        >
                        </el-date-picker>
                    </td>
                </tr>
            </tbody>
        </table>
        <div style="display: flex; justify-content: flex-end">
            <CustomButton
                label="Đóng"
                size="small"
                @click="closeModalLstKhenThuong"
                class="btn-default btn-delete"
            />
            <!-- GV1000717782 -->
        </div>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
import CustomButton from '@/components/CustomButton.vue'
export default {
    name: 'ModalLstQuaTrinhLuong',
    props: {
        lstKhenThuongVisible: {
            type: Boolean,
            required: true
        },

        closeModalLstKhenThuong: {
            type: Function,
            required: true
        },
        lstKhenThuong: {
            type: Array,
            required: true
        }
    },
    components: { CustomButton },
    data() {
        return {
            fakeDataSelect: [
                {
                    constantTitle: 'Bằng khen - [01]',
                    mappingCode: '01'
                },
                {
                    constantTitle: 'Giấy khen - [02]',
                    mappingCode: '02'
                }
            ]
        }
    },

    methods: {},
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonNgoaiNgu_Store() {
            return JSON.parse(localStorage.getItem('data_ChonNgoaingu'))
        },
        dataChonTrinhDoNgoaiNgu_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrinhDoNgoaiNgu'))
        }
    }
}
</script>
<style scoped>
::v-deep .el-input.is-disabled .el-input__inner,
::v-deep .el-textarea.is-disabled .el-textarea__inner,
::v-deep .el-input.is-disabled .el-input__inner {
    background-color: #eee !important;
    color: black !important;
}
::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
    border-color: black !important;
    background-color: #eee !important;
}
table thead tr {
    background: rgb(228, 235, 245);
}
.table {
    white-space: nowrap;
}
.centered-table {
    margin-left: auto;
    margin-right: auto;
}

.centered-table th,
.centered-table td {
    text-align: center;
    vertical-align: middle;
}
.table-bordered {
    background-color: #fff;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}
.table-hover tbody tr:hover {
    background-color: #f5f5f5;
}

.text-thead {
    font-size: 13px;
    text-align: center; /* Canh giữa nội dung trong cột */
    vertical-align: middle; /* Căn giữa theo chiều dọc */
}
.text-thead span {
    color: red;
}
.text-tbody {
    font-size: 13px;
}
.btn-close {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}

.btn-delete {
    background-color: var(--orange);
}
</style>
