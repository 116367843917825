<template>
    <div class="home">
        <div class="select-dashboard">
            <div class="row">
                <div class="col-md-4 col-12">
                    <label class="label">Đơn vị quản lý</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="this.authUser.role === 4"
                        :data="getDataESelect.ESelectUnitEducation"
                        :placeholder="'Chọn đơn vị quản lý'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="selectedValue.selectedValueUnitEducation"
                        @input="getDataChonTruongHoc"
                    />
                </div>
                <div class="col-md-2 col-6">
                    <label class="label">Cấp học</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectGradeLevel"
                        :placeholder="'Chọn cấp học'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenTruongHoc', 'value']"
                        v-model="selectedValue.selectedValueGradeLevel"
                        @input="getDataChonTruongHoc"
                    />
                </div>
                <div class="col-md-3 col-6">
                    <label class="label">Trường học</label>
                    <ESelect
                        :reset="resetESelectSchool"
                        @reset-completed="handleResetCompleted"
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectSchool"
                        :placeholder="'Chọn trường học'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenTruongHoc', 'maTruongHoc']"
                        v-model="selectedValue.selectedValueSchool"
                    />
                </div>
                <div class="col-md-2 col-6">
                    <label class="label">Năm học</label>
                    <ESelectYear
                        :width="'100%'"
                        v-model="selectedValue.selectedValueSchoolYear"
                        placeholder="Chọn năm"
                        size="small"
                        :no-data-text="'Không có bản ghi nào'"
                        :no-match-text="'Không tìm thấy bản ghi nào'"
                    ></ESelectYear>
                </div>
                <div class="col-md-1 col-6">
                    <CustomButton
                        style="width: 100%"
                        label="Thống kê"
                        size="small"
                        class="custom-btn"
                        @click="handleThongKe"
                    />
                </div>
            </div>
        </div>

        <!-- ************ Số liệu tăng giảm ************ -->
        <div class="card-main">
            <div class="row">
                <div class="col-md-4 col-sm-6">
                    <ChangeTrackerItem
                        :title="'Trường học'"
                        :data="dataThongKeTangGiam.dataThongKeTruongHoc.amount"
                        :status="dataThongKeTangGiam.dataThongKeTruongHoc.status"
                        :percent="
                            dataThongKeTangGiam.dataThongKeTruongHoc.percent
                        "
                    />
                </div>
                <div class="col-md-4 col-sm-6">
                    <ChangeTrackerItem
                        :title="'Giáo viên'"
                        :data="dataThongKeTangGiam.dataThongKeGiaoVien.amount"
                        :status="dataThongKeTangGiam.dataThongKeGiaoVien.status"
                        :percent="dataThongKeTangGiam.dataThongKeGiaoVien.percent"
                    />
                </div>
                <div class="col-md-4 col-sm-6">
                    <ChangeTrackerItem
                        :title="'Học sinh'"
                        :data="dataThongKeTangGiam.dataThongKeHocSinh.amount"
                        :status="dataThongKeTangGiam.dataThongKeHocSinh.status"
                        :percent="dataThongKeTangGiam.dataThongKeHocSinh.percent"
                    />
                </div>
            </div>
        </div>

        <!-- ************ Trường học ************ -->

        <School
            :dataBieuDoTongQuan_TruongHoc="
                getDataBieuDoTruongHoc.dataBieuDoTongQuan_TruongHoc
            "
            @refresh_dataBieuDoTongQuan_TruongHoc="
                getDataBieuDoTongQuan_TruongHoc
            "
            :dataBieuDoChatLuongDaoTao_TruongHoc="
                getDataBieuDoTruongHoc.dataBieuDoChatLuongDaoTao_TruongHoc
            "
            @refresh_dataBieuDoChatLuongDaoTao_TruongHoc="
                getDataBieuDoChatLuongDaoTao_TruongHoc
            "
            :dataBieuDoLoaiHinhDaoTao_TruongHoc="
                getDataBieuDoTruongHoc.dataBieuDoLoaiHinhDaoTao_TruongHoc
            "
            @refresh_dataBieuDoLoaiHinhDaoTao_TruongHoc="
                getDataBieuDoLoaiHinhDaoTao_TruongHoc
            "
        />

        <!-- ************ Cán bộ-Giáo viên-Nhân viên ************ -->
        <EducatorProfile
            :dataBieuDoTongQuan_CBGVNV="
                getDataBieuDoCanBoGiaoVienNhanVien.dataBieuDoTongQuan_CBGVNV
            "
            @refresh_dataBieuDoTongQuan_CBGVNV="getDataBieuDoTongQuan_CBGVNV"
            :dataBieuDoTrinhDoChinh_CBGVNV="
                getDataBieuDoCanBoGiaoVienNhanVien.dataBieuDoTrinhDoChinh_CBGVNV
            "
            @refresh_dataBieuDoTrinhDoChinh_CBGVNV="
                getDataBieuDoTrinhDoChinh_CBGVNV
            "
            :dataBieuDoGioiTinh_CBGVNV="
                getDataBieuDoCanBoGiaoVienNhanVien.dataBieuDoGioiTinh_CBGVNV
            "
            @refresh_dataBieuDoGioiTinh_CBGVNV="getDataBieuDoGioiTinh_CBGVNV"
            :dataBieuDoLoaiHopDong_CBGVNV="
                getDataBieuDoCanBoGiaoVienNhanVien.dataBieuDoLoaiHopDong_CBGVNV
            "
            @refresh_dataBieuDoLoaiHopDong_CBGVNV="
                getDataBieuDoLoaiHopDong_CBGVNV
            "
            :dataBieuDoTongQuan_PhanLoaiCanBo="
                getDataBieuDoPhanLoaiCanBo.dataBieuDoTongQuan_PhanLoaiCanBo
            "
            @refresh_dataBieuDoTongQuan_PhanLoaiCanBo="
                getDataBieuDoTongQuan_PhanLoaiCanBo
            "
            :dataBieuDoDoTuoi_PhanLoaiCanBo="
                getDataBieuDoPhanLoaiCanBo.dataBieuDoDoTuoi_PhanLoaiCanBo
            "
            @refresh_dataBieuDoDoTuoi_PhanLoaiCanBo="
                getDataBieuDoDoTuoi_PhanLoaiCanBo
            "
            :dataBieuDoLoaiHinhDaotao_PhanLoaiCanBo="
                getDataBieuDoPhanLoaiCanBo.dataBieuDoLoaiHinhDaotao_PhanLoaiCanBo
            "
            @refresh_dataBieuDoLoaiHinhDaotao_PhanLoaiCanBo="
                getDataBieuDoLoaiHinhDaoTao_PhanLoaiCanBo
            "
            :dataBieuDoTrangThai_PhanLoaiCanBo="
                getDataBieuDoPhanLoaiCanBo.dataBieuDoTrangThai_PhanLoaiCanBo
            "
            @refresh_dataBieuDoTrangThai_PhanLoaiCanBo="
                getDataBieuDoTrangThai_PhanLoaiCanBo
            "
        />

        <!-- ************ Phân loại cán bộ ************ -->
        <!-- <TeacherClassification
            :dataBieuDoTongQuan_PhanLoaiCanBo="
                getDataBieuDoPhanLoaiCanBo.dataBieuDoTongQuan_PhanLoaiCanBo
            "
            @refresh_dataBieuDoTongQuan_PhanLoaiCanBo="
                getDataBieuDoTongQuan_PhanLoaiCanBo
            "
            :dataBieuDoDoTuoi_PhanLoaiCanBo="
                getDataBieuDoPhanLoaiCanBo.dataBieuDoDoTuoi_PhanLoaiCanBo
            "
            @refresh_dataBieuDoDoTuoi_PhanLoaiCanBo="
                getDataBieuDoDoTuoi_PhanLoaiCanBo
            "
            :dataBieuDoLoaiHinhDaotao_PhanLoaiCanBo="
                getDataBieuDoPhanLoaiCanBo.dataBieuDoLoaiHinhDaotao_PhanLoaiCanBo
            "
            @refresh_dataBieuDoLoaiHinhDaotao_PhanLoaiCanBo="
                getDataBieuDoLoaiHinhDaoTao_PhanLoaiCanBo
            "
            :dataBieuDoTrangThai_PhanLoaiCanBo="
                getDataBieuDoPhanLoaiCanBo.dataBieuDoTrangThai_PhanLoaiCanBo
            "
            @refresh_dataBieuDoTrangThai_PhanLoaiCanBo="
                getDataBieuDoTrangThai_PhanLoaiCanBo
            "
        /> -->
        <!-- ************ Học sinh ************ -->
        <Student
            :dataBieuDoTongQuan_HocSinh="
                getDataBieuDoHocSinh.dataBieuDoTongQuan_HocSinh
            "
            @refresh_dataBieuDoTongQuan_HocSinh="getDataBieuDoTongQuan_HocSinh"
            :dataBieuDoTrangThai_HocSinh="
                getDataBieuDoHocSinh.dataBieuDoTrangThai_HocSinh
            "
            @refresh_dataBieuDoTrangThai_HocSinh="getDataBieuDoTrangThai_HocSinh"
            :dataBieuDoGioiTinh_HocSinh="
                getDataBieuDoHocSinh.dataBieuDoGioiTinh_HocSinh
            "
            @refresh_dataBieuDoGioiTinh_HocSinh="getDataBieuDoGioiTinh_HocSinh"
            :dataBieuDoLoaiHinhDaoTao_HocSinh="
                getDataBieuDoHocSinh.dataBieuDoLoaiHinhDaoTao_HocSinh
            "
            @refresh_dataBieuDoLoaiHinhDaoTao_HocSinh="
                getDataBieuDoLoaiHinhDaoTao_HocSinh
            "
            :dataBieuDoKhuVuc_HocSinh="
                getDataBieuDoHocSinh.dataBieuDoKhuVuc_HocSinh
            "
            @refresh_dataBieuDoKhuVuc_HocSinh="getDataBieuDoKhuVuc_HocSinh"
        />

        <!-- ************ Phổ điểm ************ -->
        <GradeScale
            :dataBieuDoPhoDiemHKI_PhoDiem="
                getDataBieuDoPhoDiem.dataBieuDoPhoDiemHKI_PhoDiem
            "
            @refresh_dataBieuDoPhoDiemHKI_PhoDiem="
                getDataBieuDoPhoDiemHocKyI_PhoDiem
            "
            :dataBieuDoPhoDiemHKII_PhoDiem="
                getDataBieuDoPhoDiem.dataBieuDoPhoDiemHKII_PhoDiem
            "
            @refresh_dataBieuDoPhoDiemHKII_PhoDiem="
                getDataBieuDoPhoDiemHocKyII_PhoDiem
            "
            :dataBieuDoDanhGiaHocSinhKyI_PhoDiem="
                getDataBieuDoPhoDiem.dataBieuDoDanhGiaHocSinhKyI_PhoDiem
            "
            @refresh_dataBieuDoDanhGiaHocSinhKyI_PhoDiem="
                getDataBieuDoDanhGiaHocSinh_PhoDiem(1)
            "
            :dataBieuDoDanhGiaHocSinhKyII_PhoDiem="
                getDataBieuDoPhoDiem.dataBieuDoDanhGiaHocSinhKyII_PhoDiem
            "
            @refresh_dataBieuDoDanhGiaHocSinhKyII_PhoDiem="
                getDataBieuDoDanhGiaHocSinh_PhoDiem(2)
            "
            :dataBieuDoDanhGiaHocSinhCaNam_PhoDiem="
                getDataBieuDoPhoDiem.dataBieuDoDanhGiaHocSinhCaNam_PhoDiem
            "
            @refresh_dataBieuDoDanhGiaHocSinhKyCaNam_PhoDiem="
                getDataBieuDoDanhGiaHocSinh_PhoDiem(3)
            "
        />
        <!-- ************ Thống kê theo năm ************ -->

        <StatisticsByYear
            :selectedValueSchoolYear="selectedValue.selectedValueSchoolYear"
            :dataBieuDoThongKeTheoNam_LopHoc="
                getDataBieuDoThongKeTheoNam.dataBieuLopHocTheoNam
            "
            @refresh_dataBieuDoThongKeTheoNam_LopHoc="
                getDataBieuDoThongKeTheoNam_LopHoc
            "
            :dataBieuDoThongKeTheoNam_GiaoVien="
                getDataBieuDoThongKeTheoNam.dataBieuDoGiaoVienTheoNam
            "
            @refresh_dataBieuDoThongKeTheoNam_GiaoVien="
                getDataBieuDoThongKeTheoNam_GiaoVien
            "
            :dataBieuDoThongKeTheoNam_HocSinh="
                getDataBieuDoThongKeTheoNam.dataBieuDoHocSinhTheoNam
            "
            @refresh_dataBieuDoThongKeTheoNam_HocSinh="
                getDataBieuDoThongKeTheoNam_HocSinh
            "
            :dataBieuDoHocSinhHocLuc="
                getDataBieuDoThongKeTheoNam.dataBieuDoHocSinhHocLuc
            "
            @refresh_dataBieuDoHocSinhHocLuc="getDataBieuDoThongKe_HocSinh_HocLuc"
            :dataBieuDoGiaoVienTrinhDo="
                getDataBieuDoThongKeTheoNam.dataBieuDoGiaoVienTrinhDo
            "
            @refresh_dataBieuDoGiaoVienTrinhDo="
                getDataBieuDoThongKe_GiaoVien_TrinhDo
            "
        />
    </div>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import ESelectYear from '@/components/ESelectYear.vue'
import { mapState } from 'vuex'
import ChangeTrackerItem from '@/components/ChangeTrackerItem.vue'
import Student from './Student.vue'
import EducatorProfile from './EducatorProfile.vue'
import School from './School.vue'
import TeacherClassification from './TeacherClassification.vue'
import GradeScale from './GradeScale.vue'
import StatisticsByYear from './StatisticsByYear.vue'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { ESelectGradeLevel_MockData } from '@/mock_data/index'
import 'element-ui/lib/theme-chalk/index.css'
import { currentYear, getValuesFromSelected } from '@/utils'

export default {
    name: 'DashBoard',
    components: {
        CustomButton,
        Student,
        EducatorProfile,
        // TeacherClassification,
        GradeScale,
        ESelect,
        ESelectYear,
        ChangeTrackerItem,
        StatisticsByYear,
        School
    },
    data() {
        return {
            xaxisCategories: {
                chatLuongDaoTao: ['C.Lượng ', 'Chuẩn mức 1', 'Chuẩn mức 2'],
                loaiHinhDaoTao: ['Công lập', 'Tư thục', 'Dân lập']
            },
            resetESelectSchool: false,
            requestHeaders: {
                'X-ROLE-ID': '',
                'X-MA-DON-VI': '',
                'X-MA-TRUONG': '',
                Authorization: ''
            },
            requestParams_Select: {
                start: 0,
                limit: 9999,
                maTinhThanh: null,
                check: true
            },
            requestData: {
                capHocs: [],
                maDonVis: [],
                maSo: null,
                maTruongs: [],
                type: null,
                namHoc: null
            },

            getDataESelect: {
                ESelectUnitEducation: [], //chondonvi
                ESelectGradeLevel: [], // chon cap hoc
                ESelectSchool: [], //chontruonghoc
                ESelectShoolYear_MockData: [] //chonnamhoc
            },

            selectedValue: {
                selectedValueUnitEducation: [], //chondonvi
                selectedValueGradeLevel: [], // choncaphoc
                selectedValueSchool: [], //chontruonghoc
                selectedValueSchoolYear: '' //chonnamhoc
            },
            dataThongKeTangGiam: {
                dataThongKeTruongHoc: {},
                dataThongKeGiaoVien: {},
                dataThongKeHocSinh: {}
            },
            getDataBieuDoTruongHoc: {
                dataBieuDoTongQuan_TruongHoc: [],
                dataBieuDoChatLuongDaoTao_TruongHoc: [],
                dataBieuDoLoaiHinhDaoTao_TruongHoc: []
            },
            getDataBieuDoHocSinh: {
                dataBieuDoTongQuan_HocSinh: [],
                dataBieuDoTrangThai_HocSinh: [],
                dataBieuDoGioiTinh_HocSinh: [],
                dataBieuDoLoaiHinhDaoTao_HocSinh: [],
                dataBieuDoKhuVuc_HocSinh: []
            },
            getDataBieuDoCanBoGiaoVienNhanVien: {
                dataBieuDoTongQuan_CBGVNV: [],
                dataBieuDoTrinhDoChinh_CBGVNV: [],
                dataBieuDoGioiTinh_CBGVNV: [],
                dataBieuDoLoaiHopDong_CBGVNV: []
            },
            getDataBieuDoPhanLoaiCanBo: {
                dataBieuDoTongQuan_PhanLoaiCanBo: [],
                dataBieuDoDoTuoi_PhanLoaiCanBo: [],
                dataBieuDoLoaiHinhDaotao_PhanLoaiCanBo: [],
                dataBieuDoTrangThai_PhanLoaiCanBo: []
            },
            getDataBieuDoThongKeTheoNam: {
                dataBieuLopHocTheoNam: [],
                dataBieuDoGiaoVienTheoNam: [],
                dataBieuDoHocSinhTheoNam: [],
                dataBieuDoHocSinhHocLuc: [],
                dataBieuDoGiaoVienTrinhDo: []
            },
            getDataBieuDoPhoDiem: {
                dataBieuDoPhoDiemHKI_PhoDiem: [],
                dataBieuDoPhoDiemHKII_PhoDiem: [],
                dataBieuDoDanhGiaHocSinhKyI_PhoDiem: [],
                dataBieuDoDanhGiaHocSinhKyII_PhoDiem: [],
                dataBieuDoDanhGiaHocSinhCaNam_PhoDiem: []
            }
        }
    },

    methods: {
        handleResetCompleted() {
            this.resetESelectSchool = false
        },
        //callAPi

        async getDataChonDonVi() {
            this.requestParams_Select.maTinhThanh = this.authUser.ma_so
            this.requestHeaders = {
                token: this.authToken
            }
            const response = await sendRequest(
                Api.internal_api.dm_DonVi,
                null,
                this.requestHeaders,
                this.requestParams_Select
            )
            const newData = []

            for (let i = 0; i < response.rows.length; i++) {
                const donVi = response.rows[i]

                if (donVi.tenDonVi.includes('Phòng Giáo dục và Đào tạo')) {
                    const updatedDonVi = {
                        ...donVi,
                        tenDonVi: `  ${donVi.tenDonVi.replace(
                            'Phòng Giáo dục và Đào tạo',
                            'Phòng GD&ĐT'
                        )} - [${donVi.maDonVi}]`
                    }

                    newData.push(updatedDonVi)
                } else if (donVi.tenDonVi.includes('Sở Giáo dục và Đào tạo')) {
                    const updatedDonVi = {
                        ...donVi,
                        tenDonVi: `  ${donVi.tenDonVi.replace(
                            'Sở Giáo dục và Đào tạo',
                            'Sở GD&ĐT'
                        )} - [${donVi.maDonVi}]`
                    }

                    newData.push(updatedDonVi)
                } else {
                    newData.push(donVi)
                }
            }
            this.getDataESelect.ESelectUnitEducation = newData
        },

        async getDataChonTruongHoc() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const maDonVis_Update = getValuesFromSelected(
                this.selectedValue.selectedValueUnitEducation
            )

            const capHocs_Update = getValuesFromSelected(
                this.selectedValue.selectedValueGradeLevel
            )
            const request_Data = {
                capHoc: capHocs_Update,
                maDonVi: maDonVis_Update
            }
            this.requestHeaders = {
                token: this.authToken
            }
            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_Data,
                this.requestHeaders,
                null
            )
            this.getDataESelect.ESelectSchool = response.rows.map((item) => ({
                ...item,
                tenTruongHoc: `${item.tenTruongHoc} - [${item.maTruongHoc}]`
            }))
            loading.close()
        },
        async getDataThongKeTangGiam(type, dataKey) {
            this.requestHeaders = {
                token: this.authToken
            }

            const maDonVis_Update = getValuesFromSelected(
                this.selectedValue.selectedValueUnitEducation
            )
            const capHocs_Update = getValuesFromSelected(
                this.selectedValue.selectedValueGradeLevel
            )
            const maTruongs_Update = getValuesFromSelected(
                this.selectedValue.selectedValueSchool
            )
            this.requestData = {
                capHocs: capHocs_Update,
                maDonVis: maDonVis_Update,
                maTruongs: maTruongs_Update,
                maSo: this.authUser.province,
                type: type,
                namHoc: this.selectedValue.selectedValueSchoolYear || currentYear
            }
            const response = await sendRequest(
                Api.thongKeTangGiam,
                this.requestData,
                this.requestHeaders
            )
            this.dataThongKeTangGiam[dataKey] = response.item
        },
        async customGetDataBieuDoThongKeTheoNam(apiEndpoint) {
            const maDonVis_Update = getValuesFromSelected(
                this.selectedValue.selectedValueUnitEducation
            )
            const capHocs_Update = getValuesFromSelected(
                this.selectedValue.selectedValueGradeLevel
            )
            const maTruongs_Update = getValuesFromSelected(
                this.selectedValue.selectedValueSchool
            )

            this.requestHeaders = {
                token: this.authToken
            }

            this.requestData = {
                capHocs: capHocs_Update,
                maDonVis: maDonVis_Update,
                maTruongs: maTruongs_Update,
                maSo: this.authUser.province,
                namHoc: this.selectedValue.selectedValueSchoolYear || currentYear
            }

            const response = await sendRequest(
                apiEndpoint,
                this.requestData,
                this.requestHeaders
            )
            return response.item.listData
        },

        async getDataBieuDoThongKeTheoNam_LopHoc() {
            const data = await this.customGetDataBieuDoThongKeTheoNam(
                Api.bieuDoThongKeTheoNam.bieuDoLopHocTheoNam
            )
            this.getDataBieuDoThongKeTheoNam.dataBieuLopHocTheoNam = data
        },
        async getDataBieuDoThongKeTheoNam_GiaoVien() {
            const data = await this.customGetDataBieuDoThongKeTheoNam(
                Api.bieuDoThongKeTheoNam.bieuDoGiaoVienTheoNam
            )
            this.getDataBieuDoThongKeTheoNam.dataBieuDoGiaoVienTheoNam = data
        },

        async getDataBieuDoThongKeTheoNam_HocSinh() {
            const data = await this.customGetDataBieuDoThongKeTheoNam(
                Api.bieuDoThongKeTheoNam.bieuDoHocSinhTheoNam
            )
            this.getDataBieuDoThongKeTheoNam.dataBieuDoHocSinhTheoNam = data
        },
        async getDataBieuDoThongKe_HocSinh_HocLuc() {
            const data = await this.customGetDataBieuDoThongKeTheoNam(
                Api.bieuDoThongKeTheoNam.bieuDoHocSinhHocLuc
            )
            this.getDataBieuDoThongKeTheoNam.dataBieuDoHocSinhHocLuc = data
        },
        async getDataBieuDoThongKe_GiaoVien_TrinhDo() {
            const data = await this.customGetDataBieuDoThongKeTheoNam(
                Api.bieuDoThongKeTheoNam.bieuDoGiaoVienTrinhDo
            )
            this.getDataBieuDoThongKeTheoNam.dataBieuDoGiaoVienTrinhDo = data
        },

        async getDataBieuDoTongQuan_TruongHoc() {
            await this.customGetDataBieuDoTruongHoc(
                Api.bieuDoTruongHoc.bieuDoTongQuan,
                'dataBieuDoTongQuan_TruongHoc',
                'bieudoTron'
            )
        },

        async getDataBieuDoChatLuongDaoTao_TruongHoc() {
            await this.customGetDataBieuDoTruongHoc(
                Api.bieuDoTruongHoc.bieuDoChatLuongDaoTao,
                'dataBieuDoChatLuongDaoTao_TruongHoc',
                'bieudoCot'
            )
        },
        async getDataBieuDoLoaiHinhDaoTao_TruongHoc() {
            await this.customGetDataBieuDoTruongHoc(
                Api.bieuDoTruongHoc.bietDoLoaiHinhDaoTao,
                'dataBieuDoLoaiHinhDaoTao_TruongHoc',
                'bieudoCot'
            )
        },
        async getDataBieuDoTongQuan_HocSinh() {
            await this.customGetDataBieuDoHocSinh(
                Api.bieuDoHocSinh.bieuDoTongQuan,
                'dataBieuDoTongQuan_HocSinh',
                'bieudoTron'
            )
        },
        async getDataBieuDoTrangThai_HocSinh() {
            await this.customGetDataBieuDoHocSinh(
                Api.bieuDoHocSinh.bieuDoTrangThai,
                'dataBieuDoTrangThai_HocSinh',
                'bieudoCot'
            )
        },
        async getDataBieuDoGioiTinh_HocSinh() {
            await this.customGetDataBieuDoHocSinh(
                Api.bieuDoHocSinh.bieuDoGioiTinh,
                'dataBieuDoGioiTinh_HocSinh',
                'bieudoCot'
            )
        },
        async getDataBieuDoLoaiHinhDaoTao_HocSinh() {
            await this.customGetDataBieuDoHocSinh(
                Api.bieuDoHocSinh.bieuDoLoaiHinhDaoTao,
                'dataBieuDoLoaiHinhDaoTao_HocSinh',
                'bieudoCot'
            )
        },
        async getDataBieuDoKhuVuc_HocSinh() {
            await this.customGetDataBieuDoHocSinh(
                Api.bieuDoHocSinh.bieuDoKhuVuc,
                'dataBieuDoKhuVuc_HocSinh',
                'bieudoCot'
            )
        },
        async getDataBieuDoTongQuan_CBGVNV() {
            await this.customGetDataCanBoGiaoVienNhanVien(
                Api.bieuDoCanBoGiaoVienNhanVien.bieuDoTongQuan,
                'dataBieuDoTongQuan_CBGVNV',
                'bieudoTron'
            )
        },
        async getDataBieuDoTrinhDoChinh_CBGVNV() {
            await this.customGetDataCanBoGiaoVienNhanVien(
                Api.bieuDoCanBoGiaoVienNhanVien.bieuDoTrinhDoChinh,
                'dataBieuDoTrinhDoChinh_CBGVNV',
                'bieudoCot'
            )
        },
        async getDataBieuDoGioiTinh_CBGVNV() {
            await this.customGetDataCanBoGiaoVienNhanVien(
                Api.bieuDoCanBoGiaoVienNhanVien.bieuDoGioiTinh,
                'dataBieuDoGioiTinh_CBGVNV',
                'bieudoCot'
            )
        },
        async getDataBieuDoLoaiHopDong_CBGVNV() {
            await this.customGetDataCanBoGiaoVienNhanVien(
                Api.bieuDoCanBoGiaoVienNhanVien.bieuDoLoaiHopDong,
                'dataBieuDoLoaiHopDong_CBGVNV',
                'bieudoCot'
            )
        },
        async getDataBieuDoTongQuan_PhanLoaiCanBo() {
            await this.customGetDataPhanLoaiCanBo(
                Api.bieuDoPhanLoaiCanBo.bieuDoTongQuan,
                'dataBieuDoTongQuan_PhanLoaiCanBo',
                'bieudoTron'
            )
        },
        async getDataBieuDoDoTuoi_PhanLoaiCanBo() {
            await this.customGetDataPhanLoaiCanBo(
                Api.bieuDoPhanLoaiCanBo.bieuDoDoTuoi,
                'dataBieuDoDoTuoi_PhanLoaiCanBo',
                'bieudoCot'
            )
        },
        async getDataBieuDoLoaiHinhDaoTao_PhanLoaiCanBo() {
            await this.customGetDataPhanLoaiCanBo(
                Api.bieuDoPhanLoaiCanBo.bieuDoLoaiHinhDaoTao,
                'dataBieuDoLoaiHinhDaotao_PhanLoaiCanBo',
                'bieudoCot'
            )
        },
        async getDataBieuDoTrangThai_PhanLoaiCanBo() {
            await this.customGetDataPhanLoaiCanBo(
                Api.bieuDoPhanLoaiCanBo.bieuDoTrangThai,
                'dataBieuDoTrangThai_PhanLoaiCanBo',
                'bieudoCot'
            )
        },

        async handleThongKe() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                this.getDataThongKeTangGiam(3, 'dataThongKeTruongHoc')
                this.getDataThongKeTangGiam(2, 'dataThongKeGiaoVien')
                this.getDataThongKeTangGiam(1, 'dataThongKeHocSinh')

                this.getDataBieuDoTongQuan_TruongHoc()
                this.getDataBieuDoChatLuongDaoTao_TruongHoc()
                this.getDataBieuDoLoaiHinhDaoTao_TruongHoc()

                this.getDataBieuDoTongQuan_HocSinh()
                this.getDataBieuDoTrangThai_HocSinh()
                this.getDataBieuDoGioiTinh_HocSinh()
                this.getDataBieuDoLoaiHinhDaoTao_HocSinh()
                this.getDataBieuDoKhuVuc_HocSinh()

                this.getDataBieuDoTongQuan_CBGVNV()
                this.getDataBieuDoTrinhDoChinh_CBGVNV()
                this.getDataBieuDoGioiTinh_CBGVNV()
                this.getDataBieuDoLoaiHopDong_CBGVNV()

                this.getDataBieuDoTongQuan_PhanLoaiCanBo()
                this.getDataBieuDoDoTuoi_PhanLoaiCanBo()
                this.getDataBieuDoLoaiHinhDaoTao_PhanLoaiCanBo()
                this.getDataBieuDoTrangThai_PhanLoaiCanBo()

                this.getDataBieuDoThongKeTheoNam_LopHoc()
                this.getDataBieuDoThongKeTheoNam_GiaoVien()
                this.getDataBieuDoThongKeTheoNam_HocSinh()
                this.getDataBieuDoThongKe_HocSinh_HocLuc()
                this.getDataBieuDoThongKe_GiaoVien_TrinhDo()

                this.getDataBieuDoDanhGiaHocSinh_PhoDiem(1)
                this.getDataBieuDoDanhGiaHocSinh_PhoDiem(2)
                this.getDataBieuDoDanhGiaHocSinh_PhoDiem(3)
                this.getDataBieuDoPhoDiemHocKyI_PhoDiem()
                this.getDataBieuDoPhoDiemHocKyII_PhoDiem()

                loading.close()
            } catch (error) {
                loading.close()
                console.log(error)
            }
        },

        async customGetDataBieuDoTruongHoc(apiEndpoint, dataKey, responseKey) {
            const maDonVis_Update = getValuesFromSelected(
                this.selectedValue.selectedValueUnitEducation
            )

            const capHocs_Update = getValuesFromSelected(
                this.selectedValue.selectedValueGradeLevel
            )
            const maTruongs_Update = getValuesFromSelected(
                this.selectedValue.selectedValueSchool
            )
            this.requestHeaders = {
                token: this.authToken
            }

            this.requestData = {
                capHocs: capHocs_Update,
                maDonVis: maDonVis_Update,
                maTruongs: maTruongs_Update,
                maSo: this.authUser.province,
                namHoc: this.selectedValue.selectedValueSchoolYear || currentYear
            }

            const response = await sendRequest(
                apiEndpoint,
                this.requestData,
                this.requestHeaders
            )
            switch (responseKey) {
                case 'bieudoTron':
                    this.getDataBieuDoTruongHoc[dataKey] = response.item.listValue
                    break
                case 'bieudoCot':
                    this.getDataBieuDoTruongHoc[dataKey] = response.item.listData
                    break
            }
        },
        async customGetDataBieuDoHocSinh(apiEndpoint, dataKey, responseKey) {
            const maDonVis_Update = getValuesFromSelected(
                this.selectedValue.selectedValueUnitEducation
            )

            const capHocs_Update = getValuesFromSelected(
                this.selectedValue.selectedValueGradeLevel
            )
            const maTruongs_Update = getValuesFromSelected(
                this.selectedValue.selectedValueSchool
            )
            this.requestHeaders = {
                token: this.authToken
            }

            this.requestData = {
                capHocs: capHocs_Update,
                maDonVis: maDonVis_Update,
                maTruongs: maTruongs_Update,
                maSo: this.authUser.province,
                namHoc: this.selectedValue.selectedValueSchoolYear || currentYear
            }
            const response = await sendRequest(
                apiEndpoint,
                this.requestData,
                this.requestHeaders
            )
            switch (responseKey) {
                case 'bieudoTron':
                    this.getDataBieuDoHocSinh[dataKey] = response.item.listValue
                    break
                case 'bieudoCot':
                    this.getDataBieuDoHocSinh[dataKey] = response.item.listData
                    break
            }
        },
        async customGetDataCanBoGiaoVienNhanVien(
            apiEndpoint,
            dataKey,
            responseKey
        ) {
            const maDonVis_Update = getValuesFromSelected(
                this.selectedValue.selectedValueUnitEducation
            )

            const capHocs_Update = getValuesFromSelected(
                this.selectedValue.selectedValueGradeLevel
            )
            const maTruongs_Update = getValuesFromSelected(
                this.selectedValue.selectedValueSchool
            )
            this.requestHeaders = {
                token: this.authToken
            }

            this.requestData = {
                capHocs: capHocs_Update,
                maDonVis: maDonVis_Update,
                maTruongs: maTruongs_Update,
                maSo: this.authUser.province,
                namHoc: this.selectedValue.selectedValueSchoolYear || currentYear
            }
            const response = await sendRequest(
                apiEndpoint,
                this.requestData,
                this.requestHeaders
            )
            switch (responseKey) {
                case 'bieudoTron':
                    this.getDataBieuDoCanBoGiaoVienNhanVien[dataKey] =
                        response.item.listValue
                    break
                case 'bieudoCot':
                    this.getDataBieuDoCanBoGiaoVienNhanVien[dataKey] =
                        response.item.listData
                    break
            }
        },
        async customGetDataPhanLoaiCanBo(apiEndpoint, dataKey, responseKey) {
            const maDonVis_Update = getValuesFromSelected(
                this.selectedValue.selectedValueUnitEducation
            )

            const capHocs_Update = getValuesFromSelected(
                this.selectedValue.selectedValueGradeLevel
            )
            const maTruongs_Update = getValuesFromSelected(
                this.selectedValue.selectedValueSchool
            )
            this.requestHeaders = {
                token: this.authToken
            }

            this.requestData = {
                capHocs: capHocs_Update,
                maDonVis: maDonVis_Update,
                maTruongs: maTruongs_Update,
                maSo: this.authUser.province,
                namHoc: this.selectedValue.selectedValueSchoolYear || currentYear
            }
            const response = await sendRequest(
                apiEndpoint,
                this.requestData,
                this.requestHeaders
            )

            switch (responseKey) {
                case 'bieudoTron':
                    this.getDataBieuDoPhanLoaiCanBo[dataKey] =
                        response.item.listValue
                    break
                case 'bieudoCot':
                    this.getDataBieuDoPhanLoaiCanBo[dataKey] =
                        response.item.listData
                    break
            }
        },
        async getDataBieuDoDanhGiaHocSinh_PhoDiem(type) {
            const maDonVis_Update = getValuesFromSelected(
                this.selectedValue.selectedValueUnitEducation
            )

            const capHocs_Update = getValuesFromSelected(
                this.selectedValue.selectedValueGradeLevel
            )
            const maTruongs_Update = getValuesFromSelected(
                this.selectedValue.selectedValueSchool
            )
            this.requestHeaders = {
                token: this.authToken
            }

            this.requestData = {
                capHocs: capHocs_Update,
                maDonVis: maDonVis_Update,
                maTruongs: maTruongs_Update,
                maSo: this.authUser.province,
                hocKy: type,
                namHoc: this.selectedValue.selectedValueSchoolYear || currentYear
            }
            const response = await sendRequest(
                Api.bieuDoPhoDiem.bieuDoDanhGiaHocSinh,
                this.requestData,
                this.requestHeaders
            )

            switch (type) {
                case 1:
                    this.getDataBieuDoPhoDiem.dataBieuDoDanhGiaHocSinhKyI_PhoDiem =
                        response.item.listData

                    break
                case 2:
                    this.getDataBieuDoPhoDiem.dataBieuDoDanhGiaHocSinhKyII_PhoDiem =
                        response.item.listData
                    break
                case 3:
                    this.getDataBieuDoPhoDiem.dataBieuDoDanhGiaHocSinhCaNam_PhoDiem =
                        response.item.listData
                    break
                default:
                    break
            }
        },
        async getDataBieuDoPhoDiemHocKyI_PhoDiem() {
            const maDonVis_Update = getValuesFromSelected(
                this.selectedValue.selectedValueUnitEducation
            )

            const capHocs_Update = getValuesFromSelected(
                this.selectedValue.selectedValueGradeLevel
            )
            const maTruongs_Update = getValuesFromSelected(
                this.selectedValue.selectedValueSchool
            )
            this.requestHeaders = {
                token: this.authToken
            }

            this.requestData = {
                capHocs: capHocs_Update,
                maDonVis: maDonVis_Update,
                maTruongs: maTruongs_Update,
                maSo: this.authUser.province,
                hocKy: 1,
                namHoc: this.selectedValue.selectedValueSchoolYear || currentYear,
                type: null
            }
            const response = await sendRequest(
                Api.bieuDoPhoDiem.bieuDoPhoDiemHocKy,
                this.requestData,
                this.requestHeaders
            )
            this.getDataBieuDoPhoDiem.dataBieuDoPhoDiemHKI_PhoDiem =
                response.item.listData
        },
        async getDataBieuDoPhoDiemHocKyII_PhoDiem() {
            const maDonVis_Update = getValuesFromSelected(
                this.selectedValue.selectedValueUnitEducation
            )

            const capHocs_Update = getValuesFromSelected(
                this.selectedValue.selectedValueGradeLevel
            )
            const maTruongs_Update = getValuesFromSelected(
                this.selectedValue.selectedValueSchool
            )
            this.requestHeaders = {
                token: this.authToken
            }

            this.requestData = {
                capHocs: capHocs_Update,
                maDonVis: maDonVis_Update,
                maTruongs: maTruongs_Update,
                maSo: this.authUser.province,
                hocKy: 2,
                namHoc: this.selectedValue.selectedValueSchoolYear || currentYear,
                type: null
            }
            const response = await sendRequest(
                Api.bieuDoPhoDiem.bieuDoPhoDiemHocKy,
                this.requestData,
                this.requestHeaders
            )
            this.getDataBieuDoPhoDiem.dataBieuDoPhoDiemHKII_PhoDiem =
                response.item.listData
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        },
        activeThemeIcon() {
            return this.$store.state.activeThemeIcon
        }
    },
    watch: {
        'authUser.role': {
            immediate: true,
            handler(newRole, oldRole) {
                if (newRole === 4) {
                    this.selectedValue.selectedValueUnitEducation = [
                        { value: this.authUser.ma_don_vi }
                    ]
                }

                const capHocList = ESelectGradeLevel_MockData.filter((item) =>
                    this.authUser.capHocList.includes(parseInt(item.value))
                )

                this.getDataESelect.ESelectGradeLevel = capHocList
            }
        },
        'selectedValue.selectedValueUnitEducation': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        },
        'selectedValue.selectedValueGradeLevel': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        }
    },
    mounted() {
        this.getDataChonDonVi()
        this.getDataChonTruongHoc()

        this.handleThongKe()

        // giá trị mặc định của chọn năm học

        this.selectedValue.selectedValueSchoolYear = String(currentYear)
    }
}
</script>
<style scoped>
.content {
    height: 400px;
    overflow-y: scroll;
}

.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
}

.back-to-top.show {
    display: block;
}
/* CSS select dashboard */
.select-dashboard {
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 15px;
}

.select-dashboard .row {
    margin-left: 6px;
    margin-right: 6px;
}

.select-dashboard .col-12,
.select-dashboard .col-6 {
    padding: 12px 6px 12px 6px;
}
.card-main {
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 10px;
}
.custom-btn {
    background-color: #6aab9c;
    color: white;
    margin-top: 30px;
}
.label {
    font-weight: 600;
}
::v-deep .el-tag.el-tag--info {
    color: black;
}
/* CSS select dashboard */
</style>
