<template>
    <div>
        <CustomBreadcrumb
            :title="'TIẾP NHẬN DỮ LIỆU QLNT'"
            content="QUẢN LÝ HỆ THỐNG ĐỐI TÁC TRƯỜNG"
        />
        <CustomContentUI>
            <div class="row">
                <div class="col-4">
                    <label>Đơn vị quản lý</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="authUser.role === 4"
                            :data="this.dataChonDonVi_Store"
                            :placeholder="'Chọn đơn vị'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenDonVi', 'maDonVi']"
                            v-model="selectedValue.selectedValueUnitEducation"
                            @change="getDataChonTruongHoc"
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Cấp học</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectGradeLevel"
                            :placeholder="'Chọn cấp học'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenTruongHoc', 'value']"
                            v-model="selectedValue.selectedValueGradeLevel"
                            @change="getDataChonTruongHoc"
                        />
                    </div>
                </div>
                <div class="col-3">
                    <label>Trường học</label>
                    <div>
                        <ESelect
                            :reset="resetESelectSchool"
                            @reset-completed="handleResetSchoolCompleted"
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectSchool"
                            :placeholder="'Chọn trường học'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenTruongHoc', 'maTruongHoc']"
                            v-model="selectedValue.selectedValueSchool"
                        />
                    </div>
                </div>
                <div class="col-3">
                    <label>Đối tác</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectPartner"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['title', 'value']"
                            v-model="selectedValue.selectedValuePartner"
                        />
                    </div>
                </div>
            </div>
            <div class="layout-btn-search">
                <CustomButton
                    label="Tìm kiếm"
                    class="btn-default bg-search"
                    size="small"
                    @click="handleSearch"
                />
            </div>
        </CustomContentUI>

        <div class="layout-btn">
            <div>
                <chonSoLuongBanGhi @chonXongSoLuong="ChonSoLuongBanGhi($event)" />
            </div>
            <div>
                <CustomButton
                    label="Xóa"
                    size="small"
                    class="btn-default btn-delete"
                    @click="handleDeleteOnOfAll"
                />
                <CustomButton
                    label="Thêm mới"
                    size="small"
                    class="btn-default btn-add"
                    @click="handleOpenModalThemMoi"
                />
            </div>
        </div>

        <CustomTable>
            <template v-slot:header>
                <tr>
                    <th class="text-thead">STT</th>
                    <th class="text-thead">Mã trường</th>
                    <th class="text-thead">Tên trường</th>
                    <th class="text-thead">Đơn vị</th>
                    <th class="text-thead">Đối tác</th>
                    <th class="text-thead">Sửa</th>
                    <th class="text-thead">
                        <input
                            type="checkbox"
                            v-model="selectAllChecked"
                            @change="handleSelectAll"
                            :disabled="tableData.length === 0"
                        />
                    </th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td class="text-tbody text-center" colspan="9">
                            Không có bản ghi nào.
                        </td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td class="text-tbody text-center">
                        {{ (start / limit) * limit + index + 1 }}
                    </td>

                    <td class="text-tbody text-center">
                        {{ item.maTruongHoc }}
                    </td>
                    <td class="text-tbody text-left">
                        {{ item.tenTruongHoc }}
                    </td>
                    <td class="text-tbody text-left">
                        {{ item.tenDonVi }}
                    </td>
                    <td class="text-tbody text-center">
                        {{ item.tenDoiTac }}
                    </td>
                    <td class="text-tbody text-center">
                        <CustomButton
                            label=""
                            size="small"
                            class="btn-default btn-update"
                            icon="el-icon-edit-outline"
                            @click="handleOpenModalChinhSua(item)"
                        />
                        <CustomButton
                            label=""
                            size="small"
                            class="btn-default btn-delete"
                            icon="el-icon-delete"
                            @click="handleDelete(item.id)"
                        />
                    </td>
                    <td class="text-tbody text-center">
                        <input
                            type="checkbox"
                            :checked="item.isChecked"
                            @change="toggleCheckbox(item)"
                        />
                    </td>
                </tr>
            </template>
        </CustomTable>

        <div class="pagination">
            <CustomPagination
                v-show="total_rows > 0"
                :tongbanghi="total_rows"
                :soluonghienthi="soLuongBanGhiHienThi"
                :batdau="trangbatdau"
                @pageChange="layLai($event)"
            >
            </CustomPagination>
        </div>
        <ModalThemMoi
            :themMoiVisible="themMoiVisible"
            :closeModalThemMoi="closeModalThemMoi"
            @handleSearch="handleSearch"
            :ESelectPartner="getDataESelect.ESelectPartner"
        />
        <ModalChinhSua
            :chinhSuaVisible="chinhSuaVisible"
            :closeModalChinhSua="closeModalChinhSua"
            :dataChinhSua="dataChinhSua"
            :ESelectPartner="getDataESelect.ESelectPartner"
            @handleSearch="handleSearch"
        />
    </div>
</template>
<script>
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'
import ModalThemMoi from './ModalThemMoi.vue'
import ModalChinhSua from './ModalChinhSua.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import chonSoLuongBanGhi from '@/components/chonSoLuongBanGhi.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import { mapState } from 'vuex'
import { ESelectGradeLevel, ESelectGradeLevel_MockData } from '@/mock_data'
import Api from '@/constants/Api'
import sendRequest from '@/services'
import CustomContentUI from '@/components/CustomContentUI.vue'
import CustomTable from '@/components/CustomTable.vue'
export default {
    name: 'ThongTinYTe',
    components: {
        ESelect,
        CustomButton,
        CustomBreadcrumb,
        chonSoLuongBanGhi,
        CustomPagination,
        ModalThemMoi,
        ModalChinhSua,
        CustomContentUI,
        CustomTable
    },
    data() {
        return {
            resetESelectSchool: false,

            selectAllChecked: false,
            selectedItems: [],

            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            soLuongBanGhiHienThi: 25,
            trangbatdau: false,

            themMoiVisible: false,

            chinhSuaVisible: false,
            dataChinhSua: {},

            tableData: [],
            getDataESelect: {
                ESelectUnitEducation: [], //donvi
                ESelectGradeLevel: [], //caphoc
                ESelectSchool: [], //truonghoc,
                ESelectPartner: [] //doitac
            },
            selectedValue: {
                selectedValueUnitEducation: [], //donvi
                selectedValueGradeLevel: [], //caphoc
                selectedValueSchool: [], //truonghoc,
                selectedValuePartner: [] //doitac
            }
        }
    },

    methods: {
        handleResetSchoolCompleted() {
            this.resetESelectSchool = false
        },
        toggleCheckbox(item) {
            this.selectAllChecked = false
            item.isChecked = !item.isChecked
            if (item.isChecked) {
                this.selectedItems.push(item) // Thêm item vào mảng selectedItems khi checkbox được chọn
            } else {
                const index = this.selectedItems.indexOf(item)
                if (index > -1) {
                    this.selectedItems.splice(index, 1) // Xóa item khỏi mảng selectedItems khi checkbox bị bỏ chọn
                }
            }
        },

        handleSelectAll() {
            this.tableData.forEach((item) => {
                item.isChecked = this.selectAllChecked // Đặt trạng thái của tất cả các checkbox bằng với checkbox "checkboxAll"
                if (this.selectAllChecked && !this.selectedItems.includes(item)) {
                    this.selectedItems.push(item) // Thêm tất cả các item vào mảng selectedItems khi chọn tất cả
                } else if (!this.selectAllChecked) {
                    this.selectedItems = [] // Xóa tất cả các item khỏi mảng selectedItems khi bỏ chọn tất cả
                }
            })
        },
        handleOpenModalThemMoi() {
            this.themMoiVisible = true
        },
        closeModalThemMoi() {
            this.themMoiVisible = false
        },
        handleOpenModalChinhSua(item) {
            this.dataChinhSua = item
            this.chinhSuaVisible = true
        },
        closeModalChinhSua() {
            this.chinhSuaVisible = false
        },
        ChonSoLuongBanGhi(e) {
            this.total_rows = 0
            this.soLuongBanGhiHienThi = e.soluong
        },
        checkTruocKhiTim() {
            this.trangbatdau = !this.trangbatdau
        },
        layLai(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        async handleDeleteOnOfAll() {
            if (this.selectedItems.length === 0) {
                this.$message({
                    type: 'warning',
                    message: 'Vui lòng chọn dữ liệu trong bảng cần xóa'
                })
                return
            }
            const ids = this.selectedItems.map((item) => item.id)
            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                ids: ids
            }

            this.$confirm('Xác nhận xóa đối tác của trường?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    })
                    const response = await sendRequest(
                        Api.heThong.quanLyHeThongDoiTacTruong.xoaDanhSach,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        loading.close()

                        await this.handleSearch()
                        this.tableData = this.tableData.filter(
                            (item) => !item.isChecked
                        )

                        this.selectedItems = []
                        this.selectAllChecked = false
                        this.$message({
                            type: 'success',
                            message: 'Xóa thành công'
                        })
                    } else {
                        loading.close()
                        this.$message({
                            type: 'info',
                            message: response.rd
                        })
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Hủy'
                    })
                })
        },
        async handleDelete(item) {
            const ids = [].concat(item)
            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                ids: ids
            }

            this.$confirm('Xác nhận xóa đối tác của trường?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    })
                    const response = await sendRequest(
                        Api.heThong.quanLyHeThongDoiTacTruong.xoaDanhSach,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        loading.close()

                        await this.handleSearch()
                        this.$message({
                            type: 'success',
                            message: 'Xóa thành công'
                        })
                    } else {
                        loading.close()
                        this.$message({
                            type: 'info',
                            message: response.rd
                        })
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Hủy'
                    })
                })
        },

        async handleSearch() {
            const maDoiTac = this.selectedValue.selectedValuePartner.value || ''
            const maDonVis =
                this.convertToValueArray(
                    this.selectedValue.selectedValueUnitEducation
                ) || []
            const maTruongs =
                this.convertToValueArray(
                    this.selectedValue.selectedValueSchool
                ) || []

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Data = {
                    limit: this.limit,
                    maDoiTac: maDoiTac,
                    maDonVis: maDonVis,
                    maTruongs: maTruongs,
                    start: this.start
                }

                const response = await sendRequest(
                    Api.heThong.quanLyHeThongDoiTacTruong
                        .danhSachHeThongDoiTacTruong,
                    request_Data,
                    request_Header
                )
                if (response.rc == 0) {
                    loading.close()
                    this.$message({
                        type: 'success',
                        message: 'Danh sách tìm kiếm'
                    })
                    this.tableData = response.rows.map((item) => {
                        return { ...item, isChecked: false }
                    })
                    this.total_rows = response.total
                } else {
                    this.tableData = response.rows
                    this.total_rows = 0
                }
            } catch (error) {
                loading.close()
            }
        },
        async getDoiTac() {
            const request_Header = {
                token: this.authToken
            }
            const request_Params = {
                start: 0,
                limit: 9999
            }
            const response = await sendRequest(
                Api.heThong.quanLyHeThongDoiTacTruong.getDanhSachDoiTac,
                null,
                request_Header,
                request_Params
            )
            this.getDataESelect.ESelectPartner = response.rows.map((item) => ({
                title: `${item.tenDoiTac} - [${item.maDoiTac}]`,
                value: item.maDoiTac
            }))
        },
        async getDataChonTruongHoc() {
            const maDonVi = this.convertToValueArray(
                this.selectedValue.selectedValueUnitEducation
            )

            const capHoc = this.convertToValueArray(
                this.selectedValue.selectedValueGradeLevel
            )

            const request_Header = {
                token: this.authToken
            }

            const request_Data = {
                capHoc: capHoc,
                maDonVi: maDonVi
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            let response = await sendRequest(
                Api.internal_api.dm_School,
                request_Data,
                request_Header,
                null
            )
            this.getDataESelect.ESelectSchool = []
            this.selectedValue.selectedValueSchool = []

            if (response.statusResponse == 0) {
                loading.close()
                this.getDataESelect.ESelectSchool = response.rows.map((item) => ({
                    ...item,
                    tenTruongHoc: `${item.tenTruongHoc} - [${item.maTruongHoc}]`
                }))
            }

            this.resetESelectSchool = true
        },
        convertToValueArray(array) {
            return array.map((item) => item.value)
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        }
    },
    watch: {
        'authUser.role': {
            immediate: true,
            handler(newRole, oldRole) {
                if (newRole === 4) {
                    this.selectedValue.selectedValueUnitEducation = [
                        { value: this.authUser.ma_don_vi }
                    ]
                }
                const capHocList = ESelectGradeLevel_MockData.filter((item) =>
                    this.authUser.capHocList.includes(parseInt(item.value))
                )

                this.getDataESelect.ESelectGradeLevel = capHocList
            }
        }
    },
    mounted() {
        this.getDataChonTruongHoc()
        this.getDoiTac()
        this.handleSearch()
    }
}
</script>

<style scoped>
.layout-btn-search {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.layout-btn {
    padding: 20px 10px 5px 10px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}
.text-center {
    text-align: center;
}
.pagination {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 50px;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.bg-search,
.btn-info {
    background-color: var(--teal);
}
.btn-add {
    background-color: var(--light-green);
}

.btn-update {
    background-color: var(--yellow);
}
.btn-delete {
    background-color: var(--orange);
}
.text-left {
    text-align: left;
}
</style>
