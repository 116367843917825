<template>
    <div>
        <CustomBreadcrumb :title="'HỆ THỐNG'" content="Cấu hình địa chỉ IP" />

        <CustomTable>
            <template v-slot:header>
                <tr>
                    <th class="text-thead">STT</th>
                    <th class="text-thead">Thao tác</th>
                    <th class="text-thead">Ngày tạo</th>
                    <th class="text-thead">Ngày cập nhật</th>
                    <th class="text-thead">Số ngày cần đổi mật khẩu</th>
                    <th class="text-thead">
                        Số lần tối đa được phép nhập mật khẩu sai
                    </th>
                    <th class="text-thead">Số ngày lưu lịch sử</th>
                    <th class="text-thead">Thời gian chờ ( phút )</th>
                    <th class="text-thead">Danh sách các ip bị chặn</th>
                </tr>
            </template>
            <template v-slot:body>
                <!-- <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="11" class="text-tbody">
                            Không có bản ghi nào.
                        </td>
                    </tr>
                </template> -->
                <tr>
                    <td class="text-tbody text-center">1</td>
                    <td class="text-tbody text-center">
                        <el-button
                            class="btn-default btn-update"
                            size="small"
                            icon="el-icon-edit-outline"
                            @click="handleOpenModalChinhSua"
                        >
                        </el-button>
                    </td>
                    <td class="text-tbody text-center">
                        {{ convertTimestamp(tableData?.createdAt) }}
                    </td>
                    <td class="text-tbody text-center">
                        {{ convertTimestamp(tableData?.updatedAt) }}
                    </td>
                    <td class="text-tbody text-center">
                        {{ tableData?.numDayChangePass }}
                    </td>
                    <td class="text-tbody text-center">
                        {{ tableData?.numEnterPassFail }}
                    </td>
                    <td class="text-tbody text-center">
                        {{ tableData?.numDaySaveHis }}
                    </td>
                    <td class="text-tbody text-center">
                        {{ authUser?.timeoutUser }}
                    </td>

                    <td class="text-tbody text-center">
                        <ul>
                            <template
                                v-if="
                                    tableData?.blockedIpList &&
                                    tableData?.blockedIpList.length > 0
                                "
                            >
                                <ul>
                                    <li
                                        v-for="(
                                            ip, index
                                        ) in tableData?.blockedIpList"
                                        :key="index"
                                    >
                                        {{ ip }}
                                    </li>
                                </ul>
                            </template>
                            <template v-else> Không có IP nào bị chặn. </template>
                        </ul>
                    </td>
                </tr>
            </template>
        </CustomTable>

        <ModalChinhSua
            :closeModalChinhSua="closeModalChinhSua"
            :chinhSuaVisible="chinhSuaVisible"
            :data_chinhSua="data_chinhSua"
            @getDataTable="getDataTable"
        />
    </div>
</template>
<script>
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'
import CustomButton from '@/components/CustomButton.vue'
import ModalChinhSua from './ModalChinhSua.vue'
import { mapState } from 'vuex'
import Api from '@/constants/Api'
import sendRequest from '@/services'
import CustomTable from '@/components/CustomTable.vue'
import { convertTimestamp } from '@/utils'
export default {
    name: 'CauHinhDiaChiIp',
    components: {
        // CustomButton,
        CustomBreadcrumb,

        ModalChinhSua,
        CustomTable
    },
    data() {
        return {
            chinhSuaVisible: false,
            data_chinhSua: {},
            tableData: {}
        }
    },

    methods: {
        convertTimestamp,
        handleOpenModalChinhSua() {
            this.data_chinhSua = this.tableData ? this.tableData : {}
            this.chinhSuaVisible = true
        },
        closeModalChinhSua() {
            this.chinhSuaVisible = false
        },

        async getDataTable() {
            const request_Header = {
                token: this.authToken
            }

            const response = await sendRequest(
                Api.heThong.cauHinhDiaChiIp.danhSach,
                null,
                request_Header
            )
            this.tableData = response.item
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        }
    },
    mounted() {
        this.getDataTable()
    }
}
</script>

<style scoped>
.text-left {
    text-align: left;
}

.layout-btn {
    padding: 20px 10px 5px 10px;
    background: #fff;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
}
.text-center {
    text-align: center;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.bg-search,
.btn-info {
    background-color: var(--teal);
}
.btn-add {
    background-color: var(--light-green);
}

.btn-update {
    background-color: var(--yellow);
}
.btn-delete {
    background-color: var(--orange);
}
</style>
