<template>
    <div>
        <CustomBreadcrumb
            :title="'BÁO CÁO'"
            :content="'NỘP THỐNG KÊ THEO BIỂU MẪU'"
        />
        <CustomContentUI>
            <div class="row">
                <div class="col-3">
                    <label>Tên báo cáo</label>
                    <div>
                        <CustomInput
                            v-model="formValue"
                            placeholder="Nhập tên báo cáo ..."
                            :disabled="false"
                            :clearable="false"
                            size="small"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="col-3">
                    <label>Loại báo cáo</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="ESelectReport_MockData"
                            :placeholder="'Chọn loại báo cáo'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['tenBaoCao', 'value']"
                            v-model="selectedValueReport"
                        />
                    </div>
                </div>
            </div>
            <div class="layout-btn">
                <CustomButton
                    label="Tìm kiếm"
                    size="small"
                    @click="handleSearch"
                    class="btn-search btn-default"
                />
            </div>
        </CustomContentUI>

        <div
            class="layout-table"
            :style="{
                'max-width': leftBarWidth()
            }"
        >
            <div class="layout-table-btn">
                <div>
                    <chonSoLuongBanGhi
                        @chonXongSoLuong="ChonSoLuongBanGhi($event)"
                    />
                </div>
                <div v-if="authUser.role !== 5">
                    <CustomButton
                        label="Tùy chọn hiển thị"
                        size="small"
                        class="btn-show btn-default"
                        @click="handleDisplayView()"
                    />
                    <CustomButton
                        label="Thêm yêu cầu"
                        type="success"
                        size="small"
                        @click="OpenModalThemYeuCau"
                        style="
                            background-color: #6aab9c;
                            color: #fff;
                            font-weight: 600;
                        "
                    />
                </div>
            </div>

            <div class="layout-checkbox" v-if="showCheckboxList">
                <input v-model="showCheckbox_TenBaoCao" type="checkbox" />
                <span>Tên báo cáo</span>

                <input v-model="showCheckbox_TrangThai" type="checkbox" />
                <span>Trạng thái</span>

                <input v-model="showCheckbox_DonViYeuCau" type="checkbox" />
                <span>Đơn vị yêu cầu</span>

                <input v-model="showCheckbox_TruongHocNop" type="checkbox" />
                <span>Trường học nộp</span>

                <input v-model="showCheckbox_FileBieuMau" type="checkbox" />
                <span>File biểu mẫu</span>

                <input v-model="showCheckbox_NgayBatDau" type="checkbox" />
                <span>Ngày bắt đầu</span>

                <input v-model="showCheckbox_NgayKetThuc" type="checkbox" />
                <span>Ngày kết thúc</span>

                <input v-model="showCheckbox_SoTruongDaNop" type="checkbox" />
                <span>Số trường đã nộp</span>
            </div>
            <div class="report-content">
                <table
                    v-if="authUser.role !== 5"
                    class="table table-bordered table-hover centered-table"
                >
                    <thead>
                        <tr>
                            <th class="text-thead">STT</th>
                            <th class="text-thead" v-if="showCheckbox_TenBaoCao">
                                Tên báo cáo
                            </th>
                            <th
                                class="text-thead"
                                v-if="showCheckbox_DonViYeuCau"
                            >
                                Đơn vị yêu cầu
                            </th>
                            <th
                                class="text-thead"
                                v-if="showCheckbox_FileBieuMau"
                            >
                                File biểu mẫu
                            </th>
                            <th class="text-thead" v-if="showCheckbox_NgayBatDau">
                                Bắt đầu
                            </th>
                            <th
                                class="text-thead"
                                v-if="showCheckbox_NgayKetThuc"
                            >
                                Kết thúc
                            </th>
                            <th
                                class="text-thead text-center"
                                v-if="showCheckbox_TrangThai"
                            >
                                Trạng thái
                            </th>
                            <th
                                v-if="showCheckbox_TruongHocNop"
                                class="text-thead"
                            >
                                Trường học nộp
                            </th>
                            <th
                                class="text-thead"
                                v-if="showCheckbox_SoTruongDaNop"
                            >
                                Số trường đã nộp
                            </th>
                            <th class="text-thead text-center">Thao tác</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(data, index) in tableData" :key="index">
                            <td class="text-tbody text-center">
                                {{ (start / limit) * limit + index + 1 }}
                            </td>
                            <td class="text-tbody" v-if="showCheckbox_TenBaoCao">
                                {{ data.tenBaoCao }}
                            </td>
                            <td v-if="showCheckbox_DonViYeuCau">
                                {{ data.tenDonViTao }}
                            </td>
                            <td
                                class="text-tbody text-center"
                                v-if="showCheckbox_FileBieuMau"
                            >
                                <span>
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        content="Tải File"
                                        placement="top"
                                    >
                                        <CustomButton
                                            class="btn-file btn-default"
                                            :disabled="data.filePath == null"
                                            label=""
                                            size="small"
                                            icon="el-icon-download"
                                            @click="
                                                handleTaiXuongFileBieuMau(
                                                    data.filePath
                                                )
                                            "
                                        />
                                    </el-tooltip>
                                </span>
                                <span>
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        content="Chỉnh sửa"
                                        placement="top"
                                    >
                                        <CustomButton
                                            label=""
                                            size="small"
                                            icon="el-icon-upload2"
                                            @click="
                                                handleOpenModalChinhSuaFileBieuMau(
                                                    data
                                                )
                                            "
                                            class="btn-default btn-edit"
                                        />
                                    </el-tooltip>
                                </span>
                            </td>
                            <td v-if="showCheckbox_NgayBatDau">
                                {{ data.ngayApDung }}
                            </td>
                            <td v-if="showCheckbox_NgayKetThuc">
                                {{ data.ngayKetThuc }}
                            </td>
                            <td
                                class="text-tbody text-center"
                                v-if="showCheckbox_TrangThai"
                            >
                                <span v-if="data.trangThai === 2" class="black">
                                    Ngừng hoạt động
                                </span>
                                <span v-if="data.trangThai === 1" class="black">
                                    Đang hoạt động
                                </span>
                            </td>
                            <td
                                v-if="showCheckbox_TruongHocNop"
                                class="text-tbody text-center"
                            >
                                <CustomButton
                                    label="Chi tiết"
                                    size="small"
                                    @click="
                                        handleModalXemChiTiet(
                                            data.truongHocNopList
                                        )
                                    "
                                    style="
                                        background-color: #6aab9c;
                                        color: #fff;
                                        font-weight: 600;
                                    "
                                />
                            </td>
                            <td
                                class="text-tbody text-center"
                                v-if="showCheckbox_SoTruongDaNop"
                            >
                                {{ data.countSchoolDone }} /
                                {{ data.countSchoolTotal }} trường
                            </td>
                            <td class="text-tbody text-center">
                                <el-dropdown>
                                    <el-button
                                        size="small"
                                        style="
                                            background-color: #0097b2;
                                            color: #fff;
                                            font-weight: 600;
                                        "
                                    >
                                        Thao tác
                                        <i
                                            class="el-icon-arrow-down el-icon--right"
                                        >
                                        </i>
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item>
                                            <CustomButton
                                                label="Tổng hợp"
                                                size="small"
                                                type="text"
                                                icon="el-icon-download"
                                                @click="handleSynthetic(data.id)"
                                            />
                                        </el-dropdown-item>
                                        <el-dropdown-item
                                            v-if="data.trangThai === 1"
                                        >
                                            <CustomButton
                                                label="Khóa"
                                                size="small"
                                                type="text"
                                                icon="el-icon-lock"
                                                @click="handleLockUp(data.id)"
                                            />
                                        </el-dropdown-item>
                                        <el-dropdown-item
                                            v-if="data.trangThai === 2"
                                        >
                                            <CustomButton
                                                label="Mở Khóa"
                                                size="small"
                                                type="text"
                                                icon="el-icon-unlock"
                                                @click="handleUnLock(data.id)"
                                            />
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <CustomButton
                                                label="Chỉnh sửa"
                                                size="small"
                                                type="text"
                                                icon="el-icon-edit-outline"
                                                @click="
                                                    handleOpenModalChinhsua(
                                                        data.id
                                                    )
                                                "
                                            />
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <CustomButton
                                                label="Xóa"
                                                size="small"
                                                type="text"
                                                icon="el-icon-delete"
                                                v-loading.fullscreen.lock="
                                                    fullscreenLoading
                                                "
                                                @click="handleDelete(data.id)"
                                            />
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table
                    v-else
                    class="table table-bordered table-hover centered-table"
                >
                    <thead>
                        <tr>
                            <th class="text-thead">STT</th>
                            <th class="text-thead">Tên báo cáo</th>
                            <th class="text-thead">Đơn vị yêu cầu</th>
                            <th class="text-thead">File biểu mẫu</th>
                            <th class="text-thead">Bắt đầu</th>
                            <th class="text-thead">Kết thúc</th>
                            <th
                                class="text-thead text-center"
                                v-if="showCheckbox_TrangThai"
                            >
                                Trạng thái báo cáo
                            </th>
                            <th
                                v-if="showCheckbox_TruongHocNop"
                                class="text-thead"
                            >
                                Trạng thái nộp
                            </th>

                            <th class="text-thead text-center">Thao tác</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(data, index) in tableData" :key="index">
                            <td class="text-tbody text-center">
                                {{ (start / limit) * limit + index + 1 }}
                            </td>
                            <td class="text-tbody">
                                {{ data.tenBaoCao }}
                            </td>
                            <td>
                                {{ data.tenDonViTao }}
                            </td>
                            <td class="text-tbody text-center">
                                <span>
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        content="Tải File"
                                        placement="top"
                                    >
                                        <CustomButton
                                            class="btn-default btn-down-file"
                                            :disabled="data.filePath == null"
                                            label=""
                                            size="small"
                                            icon="el-icon-download"
                                            @click="
                                                handleTaiXuongFileBieuMau(
                                                    data.filePath
                                                )
                                            "
                                        />
                                    </el-tooltip>
                                </span>
                            </td>
                            <td>
                                {{ data.ngayApDung }}
                            </td>
                            <td>
                                {{ data.ngayKetThuc }}
                            </td>
                            <td class="text-tbody text-center">
                                <span v-if="data.trangThai === 2" class="black">
                                    Ngừng hoạt động
                                </span>
                                <span class="black" v-if="data.trangThai === 1">
                                    Đang hoạt động
                                </span>
                            </td>

                            <td class="text-tbody text-center">
                                <span
                                    class="black"
                                    v-if="data.daNopBaoCao === false"
                                >
                                    Chưa nộp
                                </span>
                                <span class="black" v-else>Đã nộp</span>
                            </td>
                            <td class="text-tbody text-center">
                                <CustomButton
                                    v-if="data.daNopBaoCao === true"
                                    label="Tải File đã nộp"
                                    size="small"
                                    @click="handleTaiFileDaNop(data)"
                                    class="btn-down-file btn-default"
                                />
                                <CustomButton
                                    v-if="data.daNopBaoCao === true"
                                    label="Xem File đã nộp"
                                    size="small"
                                    @click="handleXemFileDaNop(data)"
                                    class="btn-down-file btn-default"
                                    style="background-color: #1e6050"
                                />

                                <CustomButton
                                    label="Nộp dữ liệu"
                                    size="small"
                                    @click="
                                        handleOpenModalChinhSuaFileBieuMauRoleTruong(
                                            data
                                        )
                                    "
                                    class="btn-up btn-default"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <CustomPagination
            v-show="total_rows > 0"
            :tongbanghi="total_rows"
            :soluonghienthi="soLuongBanGhiHienThi"
            :batdau="trangbatdau"
            @pageChange="layLai($event)"
        >
        </CustomPagination>
        <ModalThemYeuCau
            :themYeuCauModalVisible="themYeuCauModalVisible"
            :closeModalThemYeuCau="closeModalThemYeuCau"
        />

        <ModalChinhSuaFileBieuMau
            :chinhSuaFileBieuMauModalVisible="chinhSuaFileBieuMauModalVisible"
            :closeModalChinhSuaFileBieuMauBaoCao="
                closeModalChinhSuaFileBieuMauBaoCao
            "
            :dataModalChinhSuaFileBieuMau="dataModalChinhSuaFileBieuMau"
        />

        <ModalChinhSuaFileBieuMauRoleTruong
            :chinhSuaFileBieuMauRoleTruongModalVisible="
                chinhSuaFileBieuMauRoleTruongModalVisible
            "
            :closeModalChinhSuaFileBieuMauBaoCaoRoleTruong="
                closeModalChinhSuaFileBieuMauBaoCaoRoleTruong
            "
            :dataModalChinhSuaFileBieuMauRoleTruong="
                dataModalChinhSuaFileBieuMauRoleTruong
            "
        />
        <ModalXemChiTiet
            :xemChitietModalVisible="xemChitietModalVisible"
            :closeModalXemchitiet="closeModalXemchitiet"
            :dataModalXemChitiet="dataModalXemChitiet"
        />

        <ModalChinhSua
            :chinhSuaModalVisible="chinhSuaModalVisible"
            :closeModalChinhsua="closeModalChinhsua"
            :dataModalUpdate="dataModalUpdate"
        />

        <ViewExcel
            :titleViewExcel="'File đã nộp'"
            :fileViewExcel="fileViewExcel"
            :closeModalViewExcel="closeModalViewExcel"
            :viewExcelModalVisible="viewExcelModalVisible"
        />
    </div>
</template>
<script>
import ViewExcel from '@/components/ViewExcel.vue'
import ESelect from '@/components/ESelect.vue'
import CustomButton from '@/components/CustomButton.vue'
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import chonSoLuongBanGhi from '@/components/chonSoLuongBanGhi.vue'
import { ESelectReport_MockData } from '@/mock_data'
import { mapState } from 'vuex'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import ModalThemYeuCau from './ModalThemYeuCau.vue'
import ModalChinhSuaFileBieuMau from './ModalChinhSuaFileBieuMau.vue'
import ModalXemChiTiet from './ModalXemChiTiet.vue'
import ModalChinhSua from './ModalChinhSua.vue'
import ModalChinhSuaFileBieuMauRoleTruong from './ModalChinhSuaFileBieuMauRoleTruong.vue'
import axios from 'axios'
import CustomInput from '@/components/CustomInput.vue'
import CustomContentUI from '@/components/CustomContentUI.vue'

export default {
    name: 'NopThongKeTheoBieuMau',
    components: {
        CustomButton,
        ESelect,
        CustomBreadcrumb,
        CustomPagination,
        chonSoLuongBanGhi,
        ModalThemYeuCau,
        ModalChinhSuaFileBieuMau,
        ModalXemChiTiet,
        ModalChinhSua,
        ModalChinhSuaFileBieuMauRoleTruong,
        ViewExcel,
        CustomInput,
        CustomContentUI
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            soLuongBanGhiHienThi: 25,
            trangbatdau: false,

            fullscreenLoading: false,

            showCheckbox_TenBaoCao: true,
            showCheckbox_TrangThai: true,
            showCheckbox_DonViYeuCau: false,
            showCheckbox_TruongHocNop: true,
            showCheckbox_FileBieuMau: true,
            showCheckbox_NgayBatDau: false,
            showCheckbox_NgayKetThuc: false,
            showCheckbox_SoTruongDaNop: true,

            themYeuCauModalVisible: false,

            xemChitietModalVisible: false,
            dataModalXemChitiet: [],

            chinhSuaModalVisible: false,
            dataModalUpdate: {},

            chinhSuaFileBieuMauModalVisible: false,
            dataModalChinhSuaFileBieuMau: {},

            chinhSuaFileBieuMauRoleTruongModalVisible: false,
            dataModalChinhSuaFileBieuMauRoleTruong: {},

            checkList: [], // Giá trị được chọn của checkbox
            showCheckboxList: false, // Trạng thái hiển thị checkbox

            tableData: [],
            fileViewExcel: null,
            viewExcelModalVisible: false,
            formValue: '', // value input
            ESelectReport_MockData: ESelectReport_MockData, // data select
            selectedValueReport: [] //value select
        }
    },

    methods: {
        closeModalViewExcel() {
            this.viewExcelModalVisible = false
        },
        ChonSoLuongBanGhi(e) {
            this.total_rows = 0
            this.soLuongBanGhiHienThi = e.soluong
        },
        checkTruocKhiTim() {
            this.trangbatdau = !this.trangbatdau
        },
        layLai(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        handleDisplayView() {
            this.showCheckboxList = !this.showCheckboxList // Hiển thị checkbox khi click vào nút
        },

        OpenModalThemYeuCau() {
            this.themYeuCauModalVisible = true
        },

        closeModalThemYeuCau() {
            this.themYeuCauModalVisible = false
        },
        handleOpenModalChinhSuaFileBieuMau(data) {
            this.dataModalChinhSuaFileBieuMau = data
            this.chinhSuaFileBieuMauModalVisible = true
        },
        closeModalChinhSuaFileBieuMauBaoCao() {
            this.chinhSuaFileBieuMauModalVisible = false
        },
        handleModalXemChiTiet(truongHocNopList) {
            this.xemChitietModalVisible = true
            this.dataModalXemChitiet = truongHocNopList
        },
        closeModalXemchitiet() {
            this.xemChitietModalVisible = false
        },
        handleOpenModalChinhSuaFileBieuMauRoleTruong(item) {
            this.dataModalChinhSuaFileBieuMauRoleTruong = item
            this.chinhSuaFileBieuMauRoleTruongModalVisible = true
        },
        closeModalChinhSuaFileBieuMauBaoCaoRoleTruong() {
            this.chinhSuaFileBieuMauRoleTruongModalVisible = false
        },
        async handleOpenModalChinhsua(id) {
            await this.getDataModalUpdate(id)
            this.chinhSuaModalVisible = true
        },
        closeModalChinhsua() {
            this.chinhSuaModalVisible = false
        },

        async getDataModalUpdate(id) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    id: id
                }
                const response = await sendRequest(
                    Api.baoCao.nopThongKeTheoBieuMau.getDuLieu,
                    null,
                    request_Header,
                    request_Params
                )
                if (response.rc == 0) {
                    loading.close()
                    this.dataModalUpdate = response.item
                } else {
                    loading.close()
                    this.$message({
                        message: response.rd,
                        type: 'error'
                    })
                }
            } catch (error) {
                loading.close()
                console.log(error)
            }
        },
        async handleTaiFileDaNop(data) {
            this.$confirm('Xác nhận tải xuống?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            })
                .then(async () => {
                    const request_Data = {
                        bmTruongId: data.id
                    }
                    const domain = process.env.VUE_APP_BACKEND_BASE_URL
                    const response = await axios.post(
                        `${domain}/csdlgd-admin/nop-bieu-mau/download-truong`,
                        request_Data,
                        {
                            headers: {
                                token: this.authToken,
                                'Content-Type': 'application/json'
                            },
                            responseType: 'blob'
                        }
                    )

                    const blob = new Blob([response.data])
                    const blobUrl = window.URL.createObjectURL(blob)

                    const downloadLink = document.createElement('a')
                    downloadLink.href = blobUrl
                    downloadLink.download = 'FileDaNop.xlsx'
                    downloadLink.style.display = 'none'

                    document.body.appendChild(downloadLink)
                    downloadLink.click()

                    window.URL.revokeObjectURL(blobUrl)
                })
                .catch(() => {})
        },
        async handleXemFileDaNop(data) {
            const request_Data = {
                bmTruongId: data.id
            }
            const domain = process.env.VUE_APP_BACKEND_BASE_URL
            const response = await axios.post(
                `${domain}/csdlgd-admin/nop-bieu-mau/download-truong`,
                request_Data,
                {
                    headers: {
                        token: this.authToken,
                        'Content-Type': 'application/json'
                    },
                    responseType: 'blob'
                }
            )

            const blob = new Blob([response.data])
            console.log(blob)
            // const blobUrl = window.URL.createObjectURL(blob)
            this.viewExcelModalVisible = true
            this.fileViewExcel = new File(
                [blob],

                {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
            )
            console.log(this.fileViewExcel)
        },
        async handleTaiXuongFileBieuMau(xlsxFile) {
            const loading = this.$loading({
                lock: true,
                text: 'Đang tải xuống',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })

            const linkDowndLoad = `https://dongbo.csdl.edu.vn/${xlsxFile}`

            loading.close()
            this.$confirm(`Xác nhận tải xuống?`, 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            })
                .then(() => {
                    window.open(linkDowndLoad, '_blank')
                })
                .catch(() => {})
        },
        // xử lí việc mở khóa, khóa, xóa
        async handleAction(id, confirmMessage, successMessage, type) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                await this.$confirm(confirmMessage, 'Xác nhận', {
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy',
                    type: 'warning'
                })

                const request_Header = {
                    token: this.authToken
                }

                const request_Data = {
                    id: id,
                    type: type
                }

                const response = await sendRequest(
                    Api.baoCao.nopThongKeTheoBieuMau.thaoTacDuLieu,
                    request_Data,
                    request_Header,
                    null
                )

                if (response.rc == 0) {
                    setTimeout(() => {
                        loading.close()
                        this.$message({
                            message: successMessage,
                            type: 'success'
                        })
                    }, 1000)

                    this.handleSearch()
                }
            } catch (error) {
                loading.close()
                console.log(error)
            }
        },
        async handleSynthetic(id) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                await this.$confirm('Bạn có muốn tổng hợp dữ liệu', 'Xác nhận', {
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy',
                    type: 'warning'
                })

                const request_Header = {
                    token: this.authToken
                }

                const request_Data = {}
                const request_Params = {
                    id: id
                }
                const response = await sendRequest(
                    Api.baoCao.nopThongKeTheoBieuMau.tongHopDuLieu,
                    request_Data,
                    request_Header,
                    request_Params
                )

                if (response.rc == 0) {
                    setTimeout(() => {
                        loading.close()
                        this.$message({
                            message: 'Tổng hợp thành công',
                            type: 'success'
                        })
                    }, 1000)

                    this.handleSearch()
                } else {
                    loading.close()
                    this.$message({
                        type: 'error',
                        message: response.rd
                    })
                }
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },
        async handleLockUp(id) {
            const confirmMessage = 'Bạn có chắc chắn muốn Khóa không?'
            const successMessage = 'Khóa thành công'
            const type = 2

            await this.handleAction(id, confirmMessage, successMessage, type)
        },

        async handleUnLock(id) {
            const confirmMessage = 'Bạn có chắc chắn muốn Mở Khóa không?'
            const successMessage = 'Mở Khóa thành công'
            const type = 3

            await this.handleAction(id, confirmMessage, successMessage, type)
        },

        async handleDelete(id) {
            const confirmMessage = 'Bạn có chắc chắn muốn Xóa không?'
            const successMessage = 'Xóa thành công'
            const type = 1

            await this.handleAction(id, confirmMessage, successMessage, type)
        },
        async handleSearch() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const tenBaoCao = this.formValue

                const loaiBaoCao = this.selectedValueReport.value
                    ? Number(this.selectedValueReport.value)
                    : ''

                const request_Header = {
                    token: this.authToken
                }

                const request_Data = {
                    tenBaoCao: tenBaoCao,
                    loaiBaoCao: loaiBaoCao,
                    start: 0,
                    limit: 25
                }
                const response = await sendRequest(
                    Api.baoCao.nopThongKeTheoBieuMau.danhSachBieuMau,
                    request_Data,
                    request_Header
                )

                if (response.rc == 0) {
                    loading.close()
                    this.$message({
                        message: 'Lấy dữ liệu thành công',
                        type: 'success'
                    })
                    this.tableData = response.tableData
                    this.total_rows = response.totalRows
                } else {
                    loading.close()
                }
            } catch (error) {
                loading.close()
                console.log(error)
            }
        },
        leftBarWidth() {
            if (this.isCollapseLeftBar) {
                return 'calc(100vw - 125px)'
            } else {
                return 'calc(100vw - 360px)'
            }
        }
    },
    computed: {
        ...mapState(['isCollapseLeftBar']),
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        }
    },

    mounted() {
        this.handleSearch()
    }
}
</script>
<style scoped>
.layout-btn {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 5px;
}

.layout-table {
    background: #fff;
    padding: 20px 10px 20px 10px;
    border-radius: 4px;
    overflow-x: auto;
}
.layout-table-btn {
    display: flex;
    justify-content: space-between;
    padding: 5px 0px 20px 0px;
}
.layout-checkbox {
    margin-bottom: 10px;
    display: flex;
}
.layout-checkbox span {
    font-size: 13px;
    margin-right: 20px;
    margin-left: 5px;
}
.layout-checkbox input {
    cursor: pointer;
}
.report-content {
    display: flex;

    align-items: center;
}

.report-content table {
    margin: 0 auto;
}

.report-content td {
    vertical-align: middle;
}
.layout-table .active {
    background: rgb(228, 235, 245);
}
.layout-table .no-underline {
    border: 0 !important;
}
.layout-table .no-underline:hover {
    cursor: pointer;
    background-color: #f8f9fa;
}

table thead tr {
    background: #6aab9c;
    color: #fff;
}
.table {
    white-space: nowrap;
}
.centered-table {
    margin-left: auto;
    margin-right: auto;
}

.table-bordered {
    background-color: #fff;
}
.text-center {
    text-align: center;
}
.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}
.table-hover tbody tr:hover {
    background-color: #f5f5f5;
}

.text-thead {
    font-size: 13px;
}
.text-thead span {
    color: #da1313;
}
.text-tbody {
    font-size: 13px;
}
.custom-dialog-title {
    font-size: 22px;
    text-align: center;
    display: flex;
    margin: 0;
    padding: 0;
}
hr {
    border: 1px solid green;
    margin-bottom: 30px;
}
.custom-date-picker {
    height: 32px;
}

.custom-date-picker::placeholder {
    color: gainsboro;

    font-size: 13px;
    background-color: transparent;
    appearance: none;
}
.input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
}
.btn-file {
    margin-right: 10px;

    background-color: #8daf88;
}
.danger {
    color: #fff;
    background-color: #f56c6c;
    border-color: #f56c6c;
}
.success {
    background-color: #67c23a;
    border-color: #67c23a;
    color: #fff;
}
::v-deep .el-icon-download:before,
i.el-icon-upload2:before {
    font-weight: 700;
}
.black {
    color: black;
    font-weight: 700;
}
.btn-up {
    background-color: #6aab9c;
    color: #fff;
    font-weight: 600;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.btn-down-file {
    background-color: #8daf88;
    color: #fff;
    font-weight: 600;
}
.btn-search {
    background-color: #6aab9c;
}
.btn-show {
    background-color: #ffa554;
}
.btn-edit {
    background-color: #6aab9c;
}
</style>
