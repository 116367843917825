<template>
    <div>
        <CustomBreadcrumb
            :title="' KẾT CHUYỂN DỮ LIỆU LÊN BỘ'"
            content="KẾT CHUYỂN DỮ LIỆU"
        />
        <CustomContentUI>
            <div class="row">
                <div class="col-4">
                    <label>Đơn vị quản lý</label>

                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="
                                this.authUser.role === 5 ||
                                this.authUser.role === 4
                            "
                            :data="this.dataChonDonVi_Store"
                            :placeholder="'Chọn đơn vị'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenDonVi', 'maDonVi']"
                            :value="selectedValue.selectedValueUnitEducation"
                            @change="
                                handleESelectChange(
                                    'ESelectUnitEducation',
                                    ...$event
                                )
                            "
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Cấp học</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectGradeLevel"
                            :placeholder="'Chọn cấp học'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenTruongHoc', 'value']"
                            :value="selectedValue.selectedValueGradeLevel"
                            @change="
                                handleESelectChange(
                                    'ESelectGradeLevel',
                                    ...$event
                                )
                            "
                        />
                    </div>
                </div>
                <div class="col-4">
                    <label>Trường học</label>
                    <div>
                        <ESelect
                            :reset="resetESelectSchool"
                            @reset-completed="handleResetCompleted"
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="this.authUser.role === 5"
                            :data="getDataESelect.ESelectSchool"
                            :placeholder="'Chọn trường học'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenTruongHoc', 'maTruongHoc']"
                            :value="selectedValue.selectedValueSchool"
                            @change="
                                handleESelectChange(
                                    'ESelectSchool_MockData',
                                    ...$event
                                )
                            "
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Năm học<span class="span">*</span></label>
                    <div>
                        <ESelectYear
                            v-model="selectedValue.selectedValueSchoolYear"
                            placeholder="Chọn năm"
                            size="small"
                            :no-data-text="'Không có bản ghi nào'"
                            :no-match-text="'Không tìm thấy bản ghi nào'"
                        ></ESelectYear>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-2">
                    <label>Học kỳ<span class="span">*</span></label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectSemester_MockData"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['tenHocKy', 'value']"
                            v-model="selectedValue.selectedValueSemester"
                            @change="handleChangeSemester"
                        />
                    </div>
                </div>
                <div class="col-4">
                    <label>Thời gian nhận từ QLNT cuối</label>
                    <div class="input-group input-daterange">
                        <el-date-picker
                            v-model="selectedValue.selectedValueStartDate"
                            type="date"
                            placeholder="Ngày bắt đầu"
                            style="width: 43%"
                            format="dd/MM/yyyy"
                            size="small"
                            @input="updateEndDate"
                        >
                        </el-date-picker>
                        <div class="input-group-addon">đến</div>

                        <el-date-picker
                            v-model="selectedValue.selectedValueEndDate"
                            type="date"
                            placeholder="Ngày kết thúc"
                            style="width: 43%"
                            format="dd/MM/yyyy"
                            size="small"
                        >
                        </el-date-picker>
                    </div>
                </div>
                <div class="col-4" v-if="this.authUser.role !== 5">
                    <label
                        >Mã trường bổ sung
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Nhập danh sách mã trường, các mã trường được ngăn cách với nhau bằng dấu phẩy (,)."
                            placement="top"
                        >
                            <i style="color: blue" class="el-icon-info"></i>
                        </el-tooltip>
                    </label>
                    <textarea
                        class="form-control"
                        v-model="selectedValue.selectedValueAdditionalSchoolCode"
                        style="height: 30px"
                        rows="3"
                    ></textarea>
                </div>
                <div class="col-2">
                    <label
                        >Cấp học đẩy dữ liệu
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Chỉ sử dụng với trường liên cấp: sẽ chỉ đẩy dữ liệu của các cấp học được chọn của trường."
                            placement="top"
                        >
                            <i style="color: blue" class="el-icon-info"></i>
                        </el-tooltip>
                    </label>
                    <div>
                        <div>
                            <ESelect
                                style="width: 100%"
                                no-match-text="Không tìm thấy bản ghi nào"
                                no-data-text="danh sách lựa chọn trống"
                                :clearable="true"
                                :disabled="false"
                                :data="
                                    getDataESelect.ESelectEducationDataPush_MockData
                                "
                                :placeholder="'Chọn cấp học đẩy dữ liệu'"
                                :filterable="true"
                                :multiple="true"
                                :collapse-tags="true"
                                :fields="['tenTruongHoc', 'value']"
                                :value="
                                    selectedValue.selectedValueEducationDataPush
                                "
                                @change="
                                    handleESelectChange(
                                        'ESelectEducationDataPush_MockData',
                                        ...$event
                                    )
                                "
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="layout-btn-search">
                <CustomButton
                    label="Tìm kiếm"
                    size="small"
                    @click="handleSearch"
                    class="btn-default bg-search"
                />

                <CustomButton
                    label="Xuất Excel"
                    size="small"
                    class="btn-default btn-import"
                    @click="handleExportExcel"
                    :disabled="tableData.length === 0"
                />
            </div>
        </CustomContentUI>

        <div
            class="layout-btn-table"
            :style="{
                'max-width': leftBarWidth()
            }"
        >
            <div>
                <chonSoLuongBanGhi @chonXongSoLuong="ChonSoLuongBanGhi($event)" />
            </div>
            <div>
                <CustomButton
                    label="Xóa"
                    size="small"
                    @click="handleOpenModalDelete"
                    class="btn-default btn-delete"
                />

                <CustomButton
                    label="Đẩy lên bộ"
                    size="small"
                    @click="handleOpenModalDayLenBo"
                    class="btn-default btn-up"
                />
            </div>
        </div>
        <div
            class="layout-table"
            :style="{
                'max-width': leftBarWidth()
            }"
        >
            <table class="table table-bordered centered-table">
                <thead>
                    <tr>
                        <th class="text-thead" rowspan="2">STT</th>
                        <th class="text-thead" rowspan="2">Trường học</th>
                        <th class="text-thead" rowspan="2">Đối tác</th>
                        <th class="text-thead" rowspan="2">Năm học</th>
                        <th class="text-thead" rowspan="2">Ngày nhận</th>
                        <th class="text-thead" rowspan="2">Trường duyệt</th>
                        <th class="text-thead" rowspan="2">Phòng duyệt</th>
                        <th class="text-thead" colspan="5">Dữ liệu đã nộp</th>
                        <th class="text-thead" rowspan="4">
                            <label>Chọn loại dữ liệu</label>
                            <div class="mt-3">
                                <el-select
                                    size="small"
                                    multiple
                                    collapse-tags
                                    style="width: 250px"
                                    placeholder="Chọn"
                                    v-model="selectedValueChooseDataType"
                                >
                                    <el-option
                                        v-for="item in getDataESelect.ESelectChooseDataType_MockData"
                                        :key="item.key"
                                        :label="item.name"
                                        :value="item.key"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                        </th>
                        <th class="text-thead" rowspan="2">Thao tác</th>
                    </tr>
                    <tr>
                        <th class="text-thead">Cán bộ</th>
                        <th class="text-thead">Học sinh</th>
                        <th class="text-thead">Lớp học</th>
                        <th class="text-thead">KQ giữa kỳ</th>
                        <th class="text-thead">KQ cuối kỳ</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="tableData.length === 0">
                        <tr>
                            <td colspan="21" class="text-tbody">
                                Không có bản ghi nào.
                            </td>
                        </tr>
                    </template>
                    <tr
                        v-for="(item, index) in tableData"
                        :key="index"
                        :class="{
                            'background-statusAction':
                                item.statusAction === 1 && authUser.role === 4
                        }"
                    >
                        <td class="text-tbody">
                            {{ (start / limit) * limit + index + 1 }}
                        </td>
                        <td class="text-tbody text-left">
                            {{ item.tenTruongHoc }}
                            -
                            {{ item.maTruongHoc }}
                        </td>
                        <td class="text-tbody">
                            {{ item.maDoiTac }}
                        </td>
                        <td class="text-tbody">
                            {{ item.namHoc }}-{{ item.namHoc + 1 }}
                        </td>
                        <td class="text-tbody">
                            {{ item.ngayNopTuQlntCuoi }}
                        </td>
                        <td class="text-tbody">
                            {{ item.ngayTruongDuyet }}
                        </td>
                        <td class="text-tbody">
                            {{ item.ngayPhongDuyet }}
                        </td>
                        <td class="text-tbody navigate">
                            <span
                                @click="handleNavigate_CanBoGV(item)"
                                v-html="item.countGvStr"
                            ></span>
                        </td>
                        <td class="text-tbody navigate">
                            <span
                                @click="handleNavigate_HocSinh(item)"
                                v-html="item.countHsStr"
                            ></span>
                        </td>
                        <td class="text-tbody navigate">
                            <span
                                @click="handleNavigate_LopHoc(item)"
                                v-html="item.countLhStr"
                            ></span>
                        </td>
                        <td class="text-tbody navigate">
                            <span
                                @click="handleNavigate_KQHT(item)"
                                v-html="item.countKqGiuaKy"
                            ></span>
                        </td>
                        <td class="text-tbody navigate">
                            <span
                                @click="handleNavigate_KQHT(item)"
                                v-html="item.ketQuaLienCap"
                            ></span>
                        </td>
                        <td class="text-tbody">
                            <el-select
                                size="small"
                                multiple
                                collapse-tags
                                style="width: 100%"
                                placeholder="Chọn"
                                v-model="selectedValueAnother[index]"
                            >
                                <el-option
                                    v-for="item in item.thongTinBuocDays"
                                    :key="item.key"
                                    :label="item.name"
                                    :value="item.key"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <td class="text-tbody">
                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="Kiểm tra dữ liệu"
                                placement="top"
                            >
                                <CustomButton
                                    size="small"
                                    class="btn-default btn-teal"
                                    label=""
                                    icon="el-icon-eleme"
                                    @click="handleKiemTraDuLieu(item)"
                                />
                            </el-tooltip>
                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="Xem lịch sử"
                                placement="top"
                            >
                                <CustomButton
                                    size="small"
                                    class="btn-default btn-teal"
                                    label=""
                                    icon="el-icon-tickets"
                                    @click="handleXemLichSu(item)"
                                />
                            </el-tooltip>

                            <el-tooltip
                                v-if="authUser.role === 4"
                                class="item"
                                effect="dark"
                                content="Duyệt"
                                placement="top"
                            >
                                <CustomButton
                                    size="small"
                                    class="btn-default btn-import"
                                    label=""
                                    icon="el-icon-position"
                                    @click="handleDuyetRolePhong(item)"
                                />
                            </el-tooltip>
                            <el-tooltip
                                v-if="authUser.role === 5"
                                class="item"
                                effect="dark"
                                content="Duyệt lên cấp trên"
                                placement="top"
                            >
                                <CustomButton
                                    size="small"
                                    class="btn-default btn-import"
                                    label=""
                                    icon="el-icon-position"
                                    @click="handleDuyetRoleTruong(item)"
                                />
                            </el-tooltip>

                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="Từ chối"
                                placement="top"
                                v-if="authUser.role !== 5"
                            >
                                <CustomButton
                                    size="small"
                                    class="btn-default btn-delete"
                                    label=""
                                    icon="el-icon-remove-outline"
                                    @click="handleTuChoi(item)"
                                />
                            </el-tooltip>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <CustomPagination
            v-show="total_rows > 0"
            :tongbanghi="total_rows"
            :soluonghienthi="soLuongBanGhiHienThi"
            :batdau="trangbatdau"
            @pageChange="layLai($event)"
        >
        </CustomPagination>
        <div v-if="tableData.length > 0" class="layout-note">
            <div class="row">
                <div class="col-md-12">
                    <p><b>Ghi chú:</b></p>

                    <div class="custom-list">
                        <span style="color: red">*</span>
                        <span>
                            NT:
                            <i class="small-text">Nhà trẻ;</i>
                        </span>

                        <span>
                            MG:
                            <i class="small-text">Mẫu giáo;</i>
                        </span>

                        <span>
                            TH:
                            <i class="small-text">Tiểu học;</i>
                        </span>

                        <span>
                            THCS:
                            <i class="small-text">Trung học cơ sở;</i>
                        </span>

                        <span>
                            THPT:
                            <i class="small-text">Trung học phổ thông</i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <ModalDelete
            :deleteModalVisible="deleteModalVisible"
            :closeModalDelete="closeModalDelete"
            :dataModalDelete="dataModalDelete"
            :selectedValueSchoolYear="selectedValue.selectedValueSchoolYear"
            :selectedValueSemester="selectedValue.selectedValueSemester"
            @handleSearch="handleSearch"
        />
        <ModalDayLenBo
            :dayLenBoModalVisible="dayLenBoModalVisible"
            :closeModalDayLenBo="closeModalDayLenBo"
            :dataModalDayLenBo="dataModalDayLenBo"
            :selectedValueSchoolYear="selectedValue.selectedValueSchoolYear"
            :selectedValueSemester="selectedValue.selectedValueSemester"
            @handleSearch="handleSearch"
        />
    </div>
</template>
<script>
import chonSoLuongBanGhi from '@/components/chonSoLuongBanGhi.vue'
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import ModalDelete from './ModalDelete.vue'
import ModalDayLenBo from './ModalDayLenBo.vue'
import ESelectYear from '@/components/ESelectYear.vue'
import ESelect from '@/components/ESelect.vue'
import moment from 'moment'
import CustomButton from '@/components/CustomButton.vue'
import { Router } from '@/constants/Router'
import {
    ESelectSemester_MockData_KetChuyenDuLieu,
    ESelectDataSynchronization_HKI,
    ESelectDataSynchronization_HKII,
    ESelectDataSynchronization_CN
} from '@/mock_data'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { mapState } from 'vuex'
import store from '@/store'
import axios from 'axios'
import { convertCapHocList, currentYear, filterCapHocList } from '@/utils'
import CustomContentUI from '@/components/CustomContentUI.vue'
export default {
    name: 'KetChuyenDuLieu',
    components: {
        ESelect,
        CustomButton,
        CustomBreadcrumb,
        ESelectYear,
        CustomPagination,
        chonSoLuongBanGhi,
        ModalDelete,
        ModalDayLenBo,
        CustomContentUI
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            soLuongBanGhiHienThi: 25,
            trangbatdau: false,

            resetESelectSchool: false,
            deleteModalVisible: false,
            dataModalDelete: [],
            dayLenBoModalVisible: false,
            dataModalDayLenBo: [],

            request_Header: {
                Authorization: ''
            },

            request_Data: {
                capHocList: [],
                endDate: null,
                hocKy: null,
                limit: 25,
                lstCapHoc: [],
                maDonViList: [],
                maTruongHoc: [],
                matruongHocList: [],
                namHoc: null,
                showOnlyIncompleteSchool: false,
                start: 0,
                startDate: null
            },
            tableData: [],

            getDataESelect: {
                ESelectUnitEducation: [], //donvi
                ESelectGradeLevel: [], //caphoc
                ESelectSchool: [], //truonghoc
                ESelectShoolYear: '', //namhoc
                ESelectSemester_MockData:
                    ESelectSemester_MockData_KetChuyenDuLieu, //hocky

                ESelectEducationDataPush_MockData: [], //caphocdaydulieu

                ESelectChooseDataType_MockData: [], //chonloaidulieu

                ESelectAnother_MockData: [] //chonloaidulieu_trongTable
            },

            selectedValue: {
                selectedValueUnitEducation: [], //donvi
                selectedValueGradeLevel: [], //caphoc
                selectedValueSchool: [], //truonghoc
                selectedValueSchoolYear: '', //namhoc
                selectedValueSemester: {}, //hocky
                selectedValueStartDate: '', //ngaybatdau
                selectedValueEndDate: '', //ngayketthuc
                selectedValueAdditionalSchoolCode: '', //mabosung

                selectedValueEducationDataPush: [] //caphocdaydulieu
            },
            selectedValueChooseDataType: '', //chonloaidulieu
            selectedValueAnother: [] // chonloaidulieu trong vòng lặp
        }
    },
    created() {
        this.selectedValue.selectedValueSemester = {
            tenHocKy: 'Học Kỳ 1',
            value: 1
        }

        this.getDataESelect.ESelectChooseDataType_MockData =
            ESelectDataSynchronization_HKI
    },
    methods: {
        handleOpenModalDelete() {
            this.deleteModalVisible = true
        },
        closeModalDelete() {
            this.deleteModalVisible = false
        },
        handleOpenModalDayLenBo() {
            this.dayLenBoModalVisible = true
        },
        closeModalDayLenBo() {
            this.dayLenBoModalVisible = false
        },
        updateEndDate() {
            if (this.selectedValue.selectedValueStartDate) {
                const newEndDate = moment(
                    this.selectedValue.selectedValueStartDate
                ).add(10, 'days')
                this.selectedValue.selectedValueEndDate =
                    newEndDate.format('YYYY-MM-DD')
            }
        },
        ChonSoLuongBanGhi(e) {
            this.total_rows = 0
            this.soLuongBanGhiHienThi = e.soluong
        },
        layLai(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        checkTruocKhiTim() {
            this.trangbatdau = !this.trangbatdau
        },
        getLoaiDonVi(e) {
            if (e == 1) {
                return 'Sở'
            }
            if (e == 2) {
                return 'Phòng'
            }
            if (e == 3) {
                return 'Trường'
            }
        },
        handleResetCompleted() {
            this.resetESelectSchool = false
        },

        handleESelectChange(source, ...selectedOptions) {
            switch (source) {
                case 'ESelectUnitEducation':
                    this.selectedValue.selectedValueUnitEducation =
                        selectedOptions
                    this.resetESelectSchool = true
                    this.selectedValue.selectedValueSchool = []
                    this.getDataESelectSchool()
                    break
                case 'ESelectGradeLevel':
                    this.selectedValue.selectedValueGradeLevel = selectedOptions
                    if (this.authUser.role !== 5) {
                        this.resetESelectSchool = true
                        this.selectedValue.selectedValueSchool = []
                        this.getDataESelectSchool()
                    }
                    break
                case 'ESelectSchool_MockData':
                    this.selectedValue.selectedValueSchool = selectedOptions

                    break
                case 'ESelectEducationDataPush_MockData':
                    this.selectedValue.selectedValueEducationDataPush =
                        selectedOptions
                    break
            }
        },
        handleNavigate_CanBoGV(item) {
            store.dispatch('updateData_traCuuCanBoGV', item)
            this.$router.push(Router.traCuu.traCuuCanBoGV)
        },
        handleNavigate_HocSinh(item) {
            store.dispatch('updateData_traCuuHocSinh', item)
            this.$router.push(Router.traCuu.traCuuHocSinh)
        },
        handleNavigate_LopHoc(item) {
            store.dispatch('updateData_traCuuLopHoc', item)
            this.$router.push(Router.traCuu.traCuuLopHoc)
        },
        handleNavigate_KQHT(item) {
            store.dispatch('updateData_traCuuKQHT', item)
            this.$router.push(Router.traCuu.traCuuKetQuaHocTap)
        },
        handleKiemTraDuLieu(item) {
            store.dispatch('updateData_LichSuTiepNhanDuLieu', item)
            this.$router.push(Router.tiepNhanDuLieuQLNT.lichSuTiepNhanDuLieu)
        },
        handleXemLichSu(item) {
            store.dispatch('updateData_LichSuDayDuLieuLenBo', item)
            this.$router.push(Router.ketChuyenDuLieuLenBo.lichSuDayDuLieuLenBo)
        },
        handleDuyetRolePhong(item) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const request_Header = {
                token: this.authToken
            }

            const request_Data = {}
            const request_Params = { statusAction: 1, idUpdate: item.id }
            this.$confirm(
                `Xác duyệt lên cấp trên: Trường ${item.tenTruongHoc}. (Mã ${item.maTruongHoc})?`,
                {
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy',
                    type: 'warning'
                }
            )
                .then(async () => {
                    const response = await sendRequest(
                        Api.ketChuyenDuLieuLenBo.ketChuyenDuLieu.dayLenBo,
                        request_Data,
                        request_Header,
                        request_Params
                    )
                    if (response.rc == 0) {
                        loading.close()

                        await this.$notify({
                            title: 'Duyệt thành công',

                            type: 'success'
                        })

                        await this.handleSearch()
                    }
                })
                .catch((error) => {
                    loading.close()
                    if (error.response?.data.rd) {
                        this.$message({
                            type: 'error',
                            message: error.response?.data.rd
                        })
                    }
                })
        },
        handleDuyetRoleTruong(item) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const request_Header = {
                token: this.authToken
            }

            const request_Data = {}
            const request_Params = { statusAction: 1, idUpdate: item.id }
            this.$confirm(
                `Xác duyệt lên cấp trên: Trường ${item.tenTruongHoc}. (Mã ${item.maTruongHoc})?`,
                {
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy',
                    type: 'warning'
                }
            )
                .then(async () => {
                    const response = await sendRequest(
                        Api.ketChuyenDuLieuLenBo.ketChuyenDuLieu.dayLenPhong,
                        request_Data,
                        request_Header,
                        request_Params
                    )
                    if (response.rc == 0) {
                        loading.close()

                        await this.$notify({
                            title: 'Duyệt thành công',

                            type: 'success'
                        })

                        await this.handleSearch()
                    }
                })
                .catch((error) => {
                    loading.close()
                    if (error.response?.data.rd) {
                        this.$message({
                            type: 'error',
                            message: error.response?.data.rd
                        })
                    }
                })
        },
        handleTuChoi(item) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const request_Header = {
                token: this.authToken
            }

            const request_Params = {
                statusAction: -2,
                idUpdate: item.id
            }
            this.$confirm(
                `Xác nhận từ chối duyệt: ${item.tenTruongHoc}. (Mã ${item.maTruongHoc})`,
                {
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy',
                    type: 'warning'
                }
            )
                .then(async () => {
                    const response = await sendRequest(
                        Api.ketChuyenDuLieuLenBo.ketChuyenDuLieu.tuChoi,
                        null,
                        request_Header,
                        request_Params
                    )
                    if (response.rc == 0) {
                        await this.$notify({
                            message: 'Từ chối thành công',
                            type: 'success'
                        })
                        await loading.close()

                        await this.handleSearch()
                    } else {
                        loading.close()
                    }
                })
                .catch((error) => {
                    loading.close()
                    if (error.response?.data.rd) {
                        this.$message({
                            type: 'error',
                            message: error.response?.data.rd
                        })
                    }
                })
        },

        async handleSearch() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const maDonViList = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueUnitEducation,
                    'selectedValueUnitEducation'
                )

                const capHocList = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueGradeLevel,
                    'selectedValueGradeLevel'
                )
                const maboSung =
                    this.selectedValue.selectedValueAdditionalSchoolCode

                const matruongHocList = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueSchool,
                    'selectedValueSchool'
                )

                let updateMaTruongHocList = []

                if (maboSung) {
                    updateMaTruongHocList = matruongHocList.concat(maboSung)
                } else {
                    updateMaTruongHocList = matruongHocList
                }

                const namHoc = this.selectedValue.selectedValueSchoolYear || 2023
                const hocKy = parseInt(
                    this.selectedValue.selectedValueSemester?.value
                )
                if (isNaN(hocKy)) {
                    loading.close()
                    this.$message({
                        type: 'error',
                        message: 'Vui lòng chọn Học Kỳ'
                    })
                    return
                }
                let formattedStartDate = ''
                let formattedEndDate = ''
                if (this.selectedValue.selectedValueStartDate) {
                    formattedStartDate = moment(
                        this.selectedValue.selectedValueStartDate
                    ).format('DD/MM/YYYY')
                }

                if (this.selectedValue.selectedValueEndDate) {
                    formattedEndDate = moment(
                        this.selectedValue.selectedValueEndDate
                    ).format('DD/MM/YYYY')
                }

                const lstCapHoc = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueEducationDataPush,
                    'selectedValueEducationDataPush'
                )

                const request_Header = {
                    token: this.authToken
                }

                const request_Data = {
                    capHocList: capHocList,
                    endDate: formattedEndDate,
                    hocKy: hocKy,
                    limit: this.limit,
                    lstCapHoc: lstCapHoc,
                    maDonViList: maDonViList,
                    maTruongHoc: '',
                    matruongHocList: updateMaTruongHocList,
                    namHoc: namHoc,
                    showOnlyIncompleteSchool: false,
                    start: this.start,
                    startDate: formattedStartDate
                }

                const response = await sendRequest(
                    Api.ketChuyenDuLieuLenBo.ketChuyenDuLieu
                        .danhSachKetChuyenDuLieu,
                    request_Data,
                    request_Header,
                    null
                )
                if (response.rc == 0) {
                    loading.close()

                    this.tableData = response.tableData
                    this.total_rows = response.totalRows
                    this.selectedValueChooseDataType = ''
                    this.selectedItems = []
                    this.selectedValueAnother = []
                } else {
                    loading.close()
                    this.$message({
                        message: 'Danh sách dữ liệu trống',
                        type: 'error'
                    })
                    this.tableData = response.tableData
                    this.total_rows = response.totalRows
                    this.selectedValueChooseDataType = ''
                    this.selectedItems = []
                }
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
                this.selectedValueChooseDataType = ''
                this.selectedItems = []
            }
        },
        async handleExportExcel() {
            const maDonViList = this.customhandleESelectedChange(
                this.selectedValue.selectedValueUnitEducation,
                'selectedValueUnitEducation'
            )

            const capHocList = this.customhandleESelectedChange(
                this.selectedValue.selectedValueGradeLevel,
                'selectedValueGradeLevel'
            )
            const matruongHocList = this.customhandleESelectedChange(
                this.selectedValue.selectedValueSchool,
                'selectedValueSchool'
            )

            const namHoc =
                this.selectedValue.selectedValueSchoolYear || currentYear
            const hocKy = parseInt(this.selectedValue.selectedValueSemester.value)

            if (isNaN(hocKy)) {
                this.$message({
                    type: 'error',
                    message: 'Vui lòng chọn Học Kỳ'
                })
                return
            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            this.$confirm('Xác nhận tải xuống?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })

                .then(async () => {
                    const requestData = {
                        namHoc: namHoc || currentYear,
                        hocKy: hocKy,
                        maDonViList: maDonViList,
                        matruongHocList: matruongHocList,

                        capHocList: capHocList
                    }
                    const domain = process.env.VUE_APP_BACKEND_BASE_URL
                    const response = await axios.post(
                        `${domain}/csdlgd-admin/ketChuyenDuLieu/excel`,
                        requestData,
                        {
                            headers: {
                                token: this.authToken,
                                'Content-Type': 'application/json'
                            },
                            responseType: 'blob' // Set responseType là 'blob'
                        }
                    )

                    const blob = new Blob([response.data])
                    const blobUrl = window.URL.createObjectURL(blob)

                    const downloadLink = document.createElement('a')
                    downloadLink.href = blobUrl
                    downloadLink.download = 'ketchuyendulieu.xlsx'
                    downloadLink.style.display = 'none'

                    document.body.appendChild(downloadLink)
                    downloadLink.click()

                    window.URL.revokeObjectURL(blobUrl)
                    loading.close()
                })
                .catch((error) => {
                    loading.close()
                    if (error.response?.data.rd) {
                        this.$message({
                            type: 'error',
                            message: error.response?.data.rd
                        })
                    }
                })
        },
        async getDataESelectSchool() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const maDonVi = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueUnitEducation,
                    'selectedValueUnitEducation'
                )

                const capHoc = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueGradeLevel,
                    'selectedValueGradeLevel'
                )

                const request_Header = {
                    token: this.authToken
                }

                const request_Data = {
                    capHoc: capHoc,
                    maDonVi: maDonVi
                }
                const response = await sendRequest(
                    Api.internal_api.dm_School,
                    request_Data,
                    request_Header,
                    null
                )
                this.getDataESelect.ESelectSchool = response.rows.map((item) => ({
                    ...item,
                    tenTruongHoc: `${item.tenTruongHoc} - [${item.maTruongHoc}]`
                }))
                loading.close()
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },
        customhandleESelectedChange(options, valueType) {
            switch (valueType) {
                case 'selectedValueUnitEducation':
                case 'selectedValueSchool':
                    return options.map((option) => option.value)
                case 'selectedValueGradeLevel':
                case 'selectedValueEducationDataPush':
                    return options.map((option) => Number(option.value))
            }
        },
        leftBarWidth() {
            if (this.isCollapseLeftBar) {
                return 'calc(100vw - 105px)'
            } else {
                return 'calc(100vw - 360px)'
            }
        },
        async handleChangeSemester() {
            if (this.selectedValue.selectedValueSemester.value == 1) {
                this.getDataESelect.ESelectChooseDataType_MockData =
                    ESelectDataSynchronization_HKI

                this.selectedValueChooseDataType = ''
                this.selectedItems = []
            }
            if (this.selectedValue.selectedValueSemester.value == 2) {
                this.getDataESelect.ESelectChooseDataType_MockData =
                    ESelectDataSynchronization_HKII
                this.selectedValueChooseDataType = ''
                this.selectedItems = []
            }
            if (this.selectedValue.selectedValueSemester.value == 3) {
                this.getDataESelect.ESelectChooseDataType_MockData =
                    ESelectDataSynchronization_CN

                this.selectedValueChooseDataType = ''
                this.selectedItems = []
            }
            await this.handleSearch()
        }
    },
    watch: {
        'authUser.role': {
            immediate: true,
            handler(newRole, oldRole) {
                if (newRole === 5) {
                    this.selectedValue.selectedValueUnitEducation = [
                        { value: this.authUser.ma_don_vi }
                    ]
                    const convertedList = convertCapHocList(
                        this.authUser.capHocList
                    )
                    this.selectedValue.selectedValueGradeLevel = convertedList
                    this.selectedValue.selectedValueSchool = [
                        { value: this.authUser.ma_truong_hoc }
                    ]
                }
                if (newRole === 4) {
                    this.selectedValue.selectedValueUnitEducation = [
                        { value: this.authUser.ma_don_vi }
                    ]
                }
                this.getDataESelect.ESelectGradeLevel = filterCapHocList(
                    this.authUser.capHocList
                )
                this.getDataESelect.ESelectEducationDataPush_MockData =
                    filterCapHocList(this.authUser.capHocList)
            }
        },
        selectedValueChooseDataType(newSelectedValue) {
            this.selectedValueAnother = new Array(this.tableData.length).fill(
                newSelectedValue
            )
        },
        selectedValueAnother: {
            deep: true,
            handler(newValues) {
                const newArray = newValues.map((selectedValue, index) => {
                    const item = this.tableData[index]

                    // Kiểm tra nếu selectedValue không phải mảng rỗng
                    const buocDays =
                        Array.isArray(selectedValue) && selectedValue.length > 0
                            ? selectedValue.map((str) => parseInt(str))
                            : null

                    if (buocDays !== null) {
                        return {
                            tenTruong: item.tenTruongHoc,
                            buocDays: buocDays,
                            maTruong: item.maTruongHoc,
                            show_truong_da_xoa: false
                        }
                    } else {
                        return null // Bỏ qua các phần tử có buocDays rỗng
                    }
                })

                // Lọc bỏ các phần tử null (có buocDays rỗng)
                const filteredArray = newArray.filter((item) => item !== null)
                this.dataModalDelete = filteredArray

                this.dataModalDayLenBo = filteredArray
            }
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        },
        ...mapState(['isCollapseLeftBar'])
    },
    mounted() {
        this.handleSearch()
        this.getDataESelectSchool()
    }
}
</script>
<style scoped>
.background-statusAction {
    background-color: #d6ffd8;
}

.layout-btn-table {
    padding: 20px 10px 5px 10px;
    justify-content: space-between;
    background-color: #fff;
    display: flex;
}
.layout-btn-search {
    margin-top: 15px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
}
.layout-table {
    background: #fff;
    padding: 20px 10px 5px 10px;

    margin: 0 auto;
    overflow-x: auto;
}
table thead tr {
    background: #6aab9c;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
}
.table {
    white-space: nowrap;
}
.centered-table {
    margin-left: auto;
    margin-right: auto;
}

.centered-table th,
.centered-table td {
    text-align: center;
    vertical-align: middle;
}

.table-bordered {
    background-color: #fff;
}
.text-left {
    text-align: left !important;
}
.table-bordered th,
.table-bordered td {
    text-align: center;
    border-width: 0 0 1px 1px;
    border-style: solid;
    border-color: #dedede;
}
.table-bordered th {
    padding: 9px 7px;
}
.table-bordered td {
    padding: 10px;
}
.table-hover tbody tr:hover {
    background-color: #f5f5f5 !important;
}

.text-thead {
    font-size: 15px;
    text-align: center; /* Canh giữa nội dung trong cột */
    vertical-align: middle; /* Căn giữa theo chiều dọc */
}
.text-tbody {
    font-size: 15px;
}
.navigate {
    color: #409eff;
}
.navigate :hover {
    cursor: pointer;
    text-decoration: underline;
}
.layout-note {
    padding: 10px 10px 10px 10px;
    margin-top: 20px;
}
.custom-list {
    font-weight: 700;
    list-style-type: none;
}

.custom-list .small-text {
    font-size: 13px;

    color: #333;
    font-weight: 400;
}

.input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.btn-teal,
.bg-search {
    background-color: var(--teal);
}
.btn-add,
.btn-import,
.btn-import:hover {
    background-color: var(--light-green);
}

.btn-up {
    background-color: var(--yellow);
}
.btn-delete {
    background-color: var(--orange);
}
::v-deep .el-tag.el-tag--info {
    color: black;
}
</style>
