<template>
    <el-dialog
        :visible="viewExcelModalVisible"
        :fullscreen="true"
        @close="closeModalViewExcel"
    >
        <div slot="title" class="title"></div>
        <div class="container-fluid">
            <ul
                v-if="workbook != null"
                class="nav nav-tabs"
                style="margin-top: 40px"
            >
                <li
                    role="presentation"
                    v-for="(sheet, index) in workbook.sheets"
                    :key="index"
                    v-bind:class="{ active: index == sheetIndex }"
                >
                    <a
                        class="sheet-name"
                        href="#"
                        @click="tabClicked($event, index)"
                        >{{ sheet.name }}</a
                    >
                </li>
            </ul>

            <div id="tableHost"></div>
        </div>
        <div slot="footer">
            <CustomButton
                label="Đóng"
                size="small"
                class="btn-close btn-default"
                @click="closeModalViewExcel"
            />
        </div>
    </el-dialog>
</template>

<script>
import CustomButton from './CustomButton.vue'
import '@grapecity/wijmo.styles/wijmo.css'
import * as wjcCore from '@grapecity/wijmo'
import * as wjcXlsx from '@grapecity/wijmo.xlsx'

export default {
    components: {
        CustomButton
    },
    props: {
        titleViewExcel: {
            type: String
        },
        viewExcelModalVisible: {
            type: Boolean
        },
        closeModalViewExcel: Function,
        fileViewExcel: {
            type: File
        }
    },
    watch: {
        fileViewExcel: function (newFile) {
            this._loadWorkbook(newFile)
        }
    },
    data() {
        return {
            workbook: null,
            sheetIndex: -1
        }
    },

    methods: {
        tabClicked(e, index) {
            e.preventDefault()
            this._drawSheet(index)
        },

        _drawSheet(sheetIndex) {
            let drawRoot = document.getElementById('tableHost')
            drawRoot.textContent = ''
            this.sheetIndex = sheetIndex
            this._drawWorksheet(this.workbook, sheetIndex, drawRoot, 200, 100)
        },

        _loadWorkbook(file) {
            let reader = new FileReader()

            reader.onload = (e) => {
                let workbook = new wjcXlsx.Workbook()
                workbook.loadAsync(reader.result, (result) => {
                    this.workbook = result
                    this._drawSheet(this.workbook.activeWorksheet || 0)
                })
            }

            if (file) {
                reader.readAsDataURL(file)
            }
        },

        _drawWorksheet(workbook, sheetIndex, rootElement, maxRows, maxColumns) {
            if (
                !workbook ||
                !workbook.sheets ||
                sheetIndex < 0 ||
                workbook.sheets.length == 0
            ) {
                return
            }

            sheetIndex = Math.min(sheetIndex, workbook.sheets.length - 1)

            if (maxRows == null) {
                maxRows = 200
            }

            if (maxColumns == null) {
                maxColumns = 100
            }

            // Namespace and XlsxConverter shortcuts.
            let sheet = workbook.sheets[sheetIndex],
                defaultRowHeight = 20,
                defaultColumnWidth = 60,
                tableEl = document.createElement('table')

            tableEl.border = '1'
            tableEl.style.borderCollapse = 'collapse'

            let maxRowCells = 0
            for (let r = 0; sheet.rows && r < sheet.rows.length; r++) {
                if (sheet.rows[r] && sheet.rows[r].cells) {
                    maxRowCells = Math.max(
                        maxRowCells,
                        sheet.rows[r].cells.length
                    )
                }
            }
            // add columns
            let columns = sheet.columns || [],
                invisColCnt = columns.filter(
                    (col) => col.visible === false
                ).length

            if (sheet.columns) {
                maxRowCells = Math.min(
                    Math.max(maxRowCells, columns.length),
                    maxColumns
                )

                for (let c = 0; c < maxRowCells; c++) {
                    let col = columns[c]
                    if (col && !col.visible) {
                        continue
                    }

                    let colEl = document.createElement('col')

                    tableEl.appendChild(colEl)
                    let colWidth = defaultColumnWidth + 'px'
                    if (col) {
                        this._importStyle(colEl.style, col.style)
                        if (col.autoWidth) {
                            colWidth = ''
                        } else if (col.width != null) {
                            colWidth = col.width + 'px'
                        }
                    }
                    colEl.style.width = colWidth
                }
            }

            // generate rows
            let rowCount = Math.min(maxRows, sheet.rows.length)
            for (let r = 0; sheet.rows && r < rowCount; r++) {
                let row = sheet.rows[r],
                    cellsCnt = 0 // including colspan

                if (row && !row.visible) {
                    continue
                }

                let rowEl = document.createElement('tr')
                tableEl.appendChild(rowEl)

                if (row) {
                    this._importStyle(rowEl.style, row.style)
                    if (row.height != null) {
                        rowEl.style.height = row.height + 'px'
                    }

                    for (let c = 0; row.cells && c < row.cells.length; c++) {
                        let cell = row.cells[c],
                            cellEl = document.createElement('td'),
                            col = columns[c]

                        if (col && !col.visible) {
                            continue
                        }

                        cellsCnt++

                        rowEl.appendChild(cellEl)
                        if (cell) {
                            this._importStyle(cellEl.style, cell.style)
                            let value = cell.value

                            if (!(value == null || value !== value)) {
                                // TBD: check for NaN should be eliminated
                                if (
                                    wjcCore.isString(value) &&
                                    value.charAt(0) == "'"
                                ) {
                                    value = value.substr(1)
                                }
                                let netFormat = ''
                                if (cell.style && cell.style.format) {
                                    netFormat = wjcXlsx.Workbook.fromXlsxFormat(
                                        cell.style.format
                                    )[0]
                                }
                                let fmtValue = netFormat
                                    ? wjcCore.Globalize.format(value, netFormat)
                                    : value
                                cellEl.innerHTML = wjcCore.escapeHtml(fmtValue)
                            }

                            if (cell.colSpan && cell.colSpan > 1) {
                                cellEl.colSpan = this._getVisColSpan(
                                    columns,
                                    c,
                                    cell.colSpan
                                )
                                cellsCnt += cellEl.colSpan - 1
                                c += cell.colSpan - 1
                            }

                            if (cell.note) {
                                wjcCore.addClass(cellEl, 'cell-note')
                                cellEl.title = cell.note.text
                            }
                        }
                    }
                }

                // pad with empty cells
                let padCellsCount = maxRowCells - cellsCnt - invisColCnt
                for (let i = 0; i < padCellsCount; i++) {
                    rowEl.appendChild(document.createElement('td'))
                }

                if (!rowEl.style.height) {
                    rowEl.style.height = defaultRowHeight + 'px'
                }
            }

            // do it at the end for performance
            rootElement.appendChild(tableEl)
        },

        _getVisColSpan(columns, startFrom, colSpan) {
            let res = colSpan

            for (
                let i = startFrom;
                i < columns.length && i < startFrom + colSpan;
                i++
            ) {
                let col = columns[i]
                if (col && !col.visible) {
                    res--
                }
            }

            return res
        },

        _importStyle(cssStyle, xlsxStyle) {
            if (!xlsxStyle) {
                return
            }

            if (xlsxStyle.fill) {
                if (xlsxStyle.fill.color) {
                    cssStyle.backgroundColor = xlsxStyle.fill.color
                }
            }

            if (xlsxStyle.hAlign && xlsxStyle.hAlign != wjcXlsx.HAlign.Fill) {
                cssStyle.textAlign =
                    wjcXlsx.HAlign[xlsxStyle.hAlign].toLowerCase()
            }

            let font = xlsxStyle.font
            if (font) {
                if (font.family) {
                    cssStyle.fontFamily = font.family
                }
                if (font.bold) {
                    cssStyle.fontWeight = 'bold'
                }
                if (font.italic) {
                    cssStyle.fontStyle = 'italic'
                }
                if (font.size != null) {
                    cssStyle.fontSize = font.size + 'px'
                }
                if (font.underline) {
                    cssStyle.textDecoration = 'underline'
                }
                if (font.color) {
                    cssStyle.color = font.color
                }
            }
        }
    }
}
</script>

<style scoped>
.container-fluid {
    width: 100%;
    overflow: auto;
}
.title {
    color: black;
    font-weight: 600;
}
::v-deep .el-dialog__header {
    padding: 20px 30px 0px 30px;
}
::v-deep .el-dialog__body {
    padding: 0px 20px 20px 20px;
}
::v-deep .cell-note {
    position: relative;
}

::v-deep .cell-note:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    border-left: 7px solid transparent;
    border-top: 7px solid red;
}
::v-deep .el-dialog__body {
    word-break: normal;
}
.btn-close {
    background-color: var(--orange);
    color: var(--btn-text-color);
}

.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.sheet-name {
    color: black;
    font-weight: 600;
    padding: 10px;
}
</style>
