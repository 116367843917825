<template>
    <el-dialog :visible="addNewVisible" @close="closeModalAddNew" width="50%">
        <span slot="title"
            >THÊM MỚI CẤU HÌNH NĂM HỌC {{ selectedValueSchoolYear }} -
            {{ parseInt(selectedValueSchoolYear) + 1 }}
            <hr />
        </span>
        <span>Thời gian đẩy dữ liệu:</span>

        <div class="input-group input-daterange mt-2">
            <el-date-picker
                v-model="startDate"
                type="date"
                placeholder="Ngày bắt đầu"
                style="width: 45%"
                format="dd/MM/yyyy"
                @input="updateEndDate"
            >
            </el-date-picker>
            <div class="input-group-addon">đến</div>
            <el-date-picker
                v-model="endDate"
                type="date"
                placeholder="Ngày kết thúc"
                style="width: 45%"
                format="dd/MM/yyyy"
            >
            </el-date-picker>
        </div>
        <div class="layout-btn">
            <CustomButton
                label="Đóng"
                class="btn-close btn-default"
                @click="closeModalAddNew"
                size="small"
            />
            <CustomButton
                label="Thêm mới"
                class="btn-add btn-default"
                size="small"
                @click="handleAddNew"
            />
        </div>
    </el-dialog>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import Api from '@/constants/Api'
import sendRequest from '@/services'
import moment from 'moment'
import { mapState } from 'vuex'
export default {
    props: {
        addNewVisible: {
            type: Boolean,
            required: true
        },

        closeModalAddNew: {
            type: Function,
            required: true
        },
        selectedValueSchoolYear: {
            type: String,
            default: null // Cho phép giá trị null
        },
        getDataTable: Function
    },
    components: { CustomButton },
    data() {
        return {
            startDate: '',
            endDate: ''
        }
    },

    methods: {
        updateEndDate() {
            if (this.startDate) {
                const newEndDate = moment(this.startDate).add(10, 'days')
                this.endDate = newEndDate.format('YYYY-MM-DD')
            }
        },
        handleAddNew() {
            let formattedStartDate = ''
            let formattedEndDate = ''

            if (this.startDate) {
                formattedStartDate = moment(this.startDate).format('DD/MM/YYYY')
            }

            if (this.endDate) {
                formattedEndDate = moment(this.endDate).format('DD/MM/YYYY')
            }
            if (formattedStartDate === '' && formattedEndDate === '') {
                this.$message({
                    type: 'error',
                    message: 'Vui lòng bổ sung thời hạn tiếp nhận dữ liệu'
                })
                return
            }
            const loading = this.$loading({
                lock: true,
                text: 'Đang thay đổi trạng thái',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const request_Header = { token: this.authToken }
            const request_Data = {
                dauNamTuNgay: formattedStartDate,
                dauNamToiNgay: formattedEndDate,
                namHoc: this.selectedValueSchoolYear
            }
            this.$confirm('Xác nhận thêm mới dữ liệu?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const response = await sendRequest(
                        Api.tiepNhanDuLieuQLNT.cauHinhLichDongBo.themMoiNgay,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        loading.close()
                        this.$message({
                            type: 'success',
                            message: 'Thêm mới thành công'
                        })
                        this.closeModalAddNew()
                        this.$emit('getDataTable')
                    }
                })
                .catch((error) => {
                    loading.close()
                    if (error.response?.data.rd) {
                        this.$message({
                            type: 'error',
                            message: error.response?.data.rd
                        })
                    }
                })
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped>
hr {
    border: 1px solid #ababc0;
}

.input-group-addon {
    padding: 10px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.btn-add {
    background-color: var(--light-green);
}

.btn-close {
    background-color: var(--orange);
}
</style>
