<template>
    <div>
        <el-dialog
            :visible="xemChitietModalVisible"
            width="80%"
            @close="closeModalXemchitiet"
            title="Chi tiết"
        >
            <hr />
            <div>
                <nav>
                    <div class="nav nav-tabs">
                        <a class="nav-item nav-link custom-nav">
                            Trường học được yêu cầu
                        </a>
                    </div>
                </nav>
            </div>
            <div class="report-content">
                <table class="table table-bordered table-hover centered-table">
                    <thead class="sticky-header">
                        <tr>
                            <th class="text-thead">STT</th>
                            <th class="text-thead">Tên trường</th>
                            <th class="text-thead text-center">Mã trường</th>
                            <th class="text-thead text-center">Trạng thái nộp</th>
                            <th class="text-thead text-center">File đã nộp</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(item, index) in dataModalXemChitiet"
                            :key="index"
                        >
                            <td class="text-tbody text-center">
                                {{ index + 1 }}
                            </td>
                            <td class="text-tbody-span">
                                {{ item.tenTruongHoc }}
                            </td>
                            <td class="text-tbody text-center">
                                {{ item.maTruongHoc }}
                            </td>
                            <th class="text-tbody text-center">
                                <span v-if="item.trangThai === 0">Chưa nộp</span>
                                <span v-if="item.trangThai === 1">Đã nộp</span>
                            </th>
                            <th class="text-thead text-center">
                                <span v-if="item.trangThai === 0"></span>
                                <span v-if="item.trangThai === 1">
                                    <CustomButton
                                        label="Xem trước File"
                                        type="success"
                                        @click="handleXemFileDaNop(item)"
                                        size="small"
                                    />
                                    <CustomButton
                                        label="Tải xuống"
                                        type="primary"
                                        @click="handleTaiFileDaNop(item)"
                                        size="small"
                                    />
                                </span>
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="margin-top-100"></div>
            <hr />
            <div class="layout-btn-xemchitiet">
                <CustomButton
                    label="Đóng"
                    size="small"
                    type="info"
                    @click="closeModalXemchitiet"
                />
            </div>
        </el-dialog>
        <ViewExcel
            :titleViewExcel="'File đã nộp'"
            :fileViewExcel="fileViewExcel"
            :closeModalViewExcel="closeModalViewExcel"
            :viewExcelModalVisible="viewExcelModalVisible"
        />
    </div>
</template>
<script>
import { mapState } from 'vuex'
import CustomButton from '@/components/CustomButton.vue'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import axios from 'axios'
import ViewExcel from '@/components/ViewExcel.vue'
export default {
    name: 'ModalLstQuaTrinhLuong',
    props: {
        xemChitietModalVisible: {
            type: Boolean,
            required: true
        },

        closeModalXemchitiet: {
            type: Function,
            required: true
        },
        dataModalXemChitiet: {
            type: Array,
            required: true
        }
    },

    components: { CustomButton, ViewExcel },
    data() {
        return { fileViewExcel: null, viewExcelModalVisible: false }
    },
    methods: {
        closeModalViewExcel() {
            this.viewExcelModalVisible = false
        },
        async handleXemFileDaNop(data) {
            const request_Data = {
                bmTruongId: data.id
            }
            const domain = process.env.VUE_APP_BACKEND_BASE_URL
            const response = await axios.post(
                `${domain}/csdlgd-admin/nop-bieu-mau/download-truong`,
                request_Data,
                {
                    headers: {
                        token: this.authToken,
                        'Content-Type': 'application/json'
                    },
                    responseType: 'blob'
                }
            )

            const blob = new Blob([response.data])
            console.log(blob)
            // const blobUrl = window.URL.createObjectURL(blob)
            this.viewExcelModalVisible = true
            this.fileViewExcel = new File(
                [blob],

                {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
            )
            console.log(this.fileViewExcel)
        },
        async handleTaiFileDaNop(item) {
            this.$confirm('Xác nhận tải xuống?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            })
                .then(async () => {
                    const request_Data = {
                        bmTruongId: item.id
                    }
                    const domain = process.env.VUE_APP_BACKEND_BASE_URL
                    const response = await axios.post(
                        `${domain}/csdlgd-admin/nop-bieu-mau/download-truong`,
                        request_Data,
                        {
                            headers: {
                                token: this.authToken,
                                'Content-Type': 'application/json'
                            },
                            responseType: 'blob'
                        }
                    )

                    const blob = new Blob([response.data])
                    const blobUrl = window.URL.createObjectURL(blob)

                    const downloadLink = document.createElement('a')
                    downloadLink.href = blobUrl
                    downloadLink.download = 'FileDaNop.xlsx'
                    downloadLink.style.display = 'none'

                    document.body.appendChild(downloadLink)
                    downloadLink.click()

                    window.URL.revokeObjectURL(blobUrl)
                })
                .catch(() => {})
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        }
    }
}
</script>
<style scoped>
.report-content {
    max-height: 300px;
    overflow-y: auto;
}

.sticky-header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    height: 40px; /* Đặt chiều cao cố định cho phần header */
}
.custom-nav {
    color: black;
    background-color: #f3f5f7;
    font-size: 15px;
}
.margin-top-100 {
    margin-top: 100px;
}
.layout-btn-xemchitiet {
    display: flex;
    justify-content: flex-end;
}

table thead tr {
    background: #f3f5f7;
}
.table {
    white-space: nowrap;
}
.centered-table {
    margin-left: auto;
    margin-right: auto;
}

.table-bordered {
    background-color: #fff;
}
.text-center {
    text-align: center;
}
.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}
.table-hover tbody tr:hover {
    background-color: #f5f5f5;
}

.text-thead {
    font-size: 13px;
}
.text-thead span {
    color: #da1313;
}
.text-tbody {
    font-size: 13px;
}
.text-center {
    text-align: center;
}
</style>
