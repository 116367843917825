<template>
    <el-dialog
        :visible="chinhSuaModalVisible"
        @close="closeModalChinhSua"
        width="80%"
    >
        <span slot="title" class="custom-dialog-title">
            CHỈNH SỬA ĐƠN VỊ
            <hr />
        </span>
        <div class="row form">
            <div class="col-md-6">
                <div class="row d-flex align-items-center">
                    <div class="col-3 title">
                        Mã đơn vị (<span class="span">*</span>)
                    </div>
                    <div class="col-9">
                        <el-input
                            placeholder="Nhập mã đơn vị ..."
                            v-model="selectedValue.input_maDonVi"
                            size="small"
                            style="width: 100%"
                            @blur="validateMaDonVi"
                            @focus="validateMaDonVi"
                            :disabled="true"
                        >
                        </el-input>
                        <div class="error-message" v-if="errors.maDonVi">
                            {{ errors.maDonVi }}
                        </div>
                    </div>
                </div>

                <div class="row d-flex align-items-center mt-4">
                    <div class="col-3 title">
                        Loại đơn vị (<span class="span">*</span>)
                    </div>
                    <div class="col-9">
                        <el-select
                            v-model="selectedValue.selectedValue_loaiDonVi"
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            @input="handleChange_loaiDonVi"
                        >
                            <el-option
                                v-for="item in getDataESelect.ESelect_loaiDonVi"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            />
                        </el-select>
                        <div class="error-message" v-if="errors.loaiDonVi">
                            {{ errors.loaiDonVi }}
                        </div>
                    </div>
                </div>
                <div
                    v-if="selectedValue.selectedValue_loaiDonVi === 2"
                    class="row d-flex align-items-center mt-4"
                >
                    <div class="col-3 title">
                        Đơn vị cha (<span class="span">*</span>)
                    </div>
                    <div class="col-9">
                        <el-select
                            v-model="selectedValue.selectedValue_donViCha"
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                        >
                            <el-option
                                v-for="item in getDataESelect.ESelect_donViCha"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            />
                        </el-select>
                        <div class="error-message" v-if="errors.donViCha">
                            {{ errors.donViCha }}
                        </div>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-4">
                    <div class="col-3 title">
                        Email đơn vị (<span class="span">*</span>)
                    </div>
                    <div class="col-9">
                        <el-input
                            placeholder="Nhập email đơn vị ..."
                            v-model="selectedValue.input_emailDonVi"
                            size="small"
                            style="width: 100%"
                            @focus="validateEmailDonVi"
                            @blur="validateEmailDonVi"
                        ></el-input>
                        <div class="error-message" v-if="errors.emailDonVi">
                            {{ errors.emailDonVi }}
                        </div>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-4">
                    <div class="col-3 title">Website đơn vị</div>
                    <div class="col-9">
                        <el-input
                            placeholder="Nhập website đơn vị ..."
                            v-model="selectedValue.input_webSiteDonVi"
                            size="small"
                            style="width: 100%"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-4">
                    <div class="col-3 title">
                        Tỉnh thành (<span class="span">*</span>)
                    </div>
                    <div class="col-9">
                        <el-select
                            v-model="selectedValue.selectedValue_tinhThanh"
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            @input="handleChange_tinhThanh"
                            :no-data-text="'Không có bản ghi nào'"
                        >
                            <el-option
                                v-for="item in dataTinhThanh"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            />
                        </el-select>
                        <div class="error-message" v-if="errors.tinhThanh">
                            {{ errors.tinhThanh }}
                        </div>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-4">
                    <div class="col-3 title">
                        Xã phường (<span class="span">*</span>)
                    </div>
                    <div class="col-9">
                        <el-select
                            v-model="selectedValue.selectedValue_xaPhuong"
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            :no-data-text="'Không có bản ghi nào'"
                            @input="handleChange_phuongXa"
                        >
                            <el-option
                                v-for="item in getDataESelect.ESelect_xaPhuong"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            />
                        </el-select>
                        <div class="error-message" v-if="errors.xaPhuong">
                            {{ errors.xaPhuong }}
                        </div>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-4">
                    <div class="col-3 title">
                        Trạng thái (<span class="span">*</span>)
                    </div>
                    <div class="col-9">
                        <el-select
                            v-model="selectedValue.selectedValue_trangThai"
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            :no-data-text="'Không có bản ghi nào'"
                            @input="handleChange_trangThai"
                        >
                            <el-option
                                v-for="item in getDataESelect.ESelect_TrangThai"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            />
                        </el-select>
                        <div class="error-message" v-if="errors.trangThai">
                            {{ errors.trangThai }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row d-flex align-items-center">
                    <div class="col-3 title">
                        Tên đơn vị (<span class="span">*</span>)
                    </div>
                    <div class="col-9">
                        <el-input
                            placeholder="Nhập tên đơn vị ..."
                            v-model="selectedValue.input_tenDonVi"
                            size="small"
                            style="width: 100%"
                        >
                        </el-input>
                        <div class="error-message" v-if="errors.tenDonVi">
                            {{ errors.tenDonVi }}
                        </div>
                    </div>
                </div>

                <div class="row d-flex align-items-center mt-4">
                    <div class="col-3 title">
                        Đơn vị quản lý (<span class="span">*</span>)
                    </div>
                    <div class="col-9">
                        <el-input
                            placeholder="Nhập tên đơn vị ..."
                            v-model="selectedValue.input_donViQuanLy"
                            size="small"
                            style="width: 100%"
                        >
                        </el-input>
                        <div class="error-message" v-if="errors.donViQuanLy">
                            {{ errors.donViQuanLy }}
                        </div>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-4">
                    <div class="col-3 title">
                        SĐT đơn vị (<span class="span">*</span>)
                    </div>
                    <div class="col-9">
                        <el-input
                            placeholder="Nhập email đơn vị ..."
                            v-model="selectedValue.input_soDienThoaiDonVi"
                            size="small"
                            style="width: 100%"
                            @focus="validatePhoneDonVi"
                            @blur="validatePhoneDonVi"
                        >
                        </el-input>
                        <div class="error-message" v-if="errors.phoneDonVi">
                            {{ errors.phoneDonVi }}
                        </div>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-4">
                    <div class="col-3 title">
                        Địa chỉ đơn vị(<span class="span">*</span>)
                    </div>
                    <div class="col-9">
                        <el-input
                            placeholder="Nhập địa chỉ đơn vị ..."
                            v-model="selectedValue.input_diaChiDonVi"
                            size="small"
                            style="width: 100%"
                        >
                        </el-input>
                        <div class="error-message" v-if="errors.diaChiDonVi">
                            {{ errors.diaChiDonVi }}
                        </div>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-4">
                    <div class="col-3 title">
                        Quận huyện(<span class="span">*</span>)
                    </div>
                    <div class="col-9">
                        <el-select
                            v-model="selectedValue.selectedValue_quanHuyen"
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            @input="handleChange_quanHuyen"
                            :no-data-text="'Không có bản ghi nào'"
                        >
                            <el-option
                                v-for="item in getDataESelect.ESelect_quanHuyen"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            />
                        </el-select>
                        <div class="error-message" v-if="errors.quanHuyen">
                            {{ errors.quanHuyen }}
                        </div>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-4">
                    <div class="col-3 title">Tổ thôn</div>
                    <div class="col-9">
                        <el-select
                            v-model="selectedValue.selectedValue_toThon"
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            :no-data-text="'Không có bản ghi nào'"
                        >
                            <el-option
                                v-for="item in getDataESelect.ESelect_toThon"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            />
                        </el-select>
                    </div>
                </div>
            </div>
        </div>
        <div class="layout-btn">
            <div>
                <CustomButton
                    label="Đóng"
                    size="small"
                    type="info"
                    @click="closeModalChinhSua"
                />
            </div>
            <div style="margin-left: 10px">
                <CustomButton
                    label="Chỉnh sửa"
                    size="small"
                    type="success"
                    @click="handleChinhSua"
                />
            </div>
        </div>
    </el-dialog>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import { mapState } from 'vuex'
import sendRequest from '@/services'
import { validateInput } from '@/utils'
import {
    ESelectUnitType_DonVi,
    ESelectStatus_SuperAdmin
} from '@/mock_data/index'
import Api from '@/constants/Api'
export default {
    name: 'ModalChinhSuaTruongHoc',
    props: {
        chinhSuaModalVisible: {
            type: Boolean,
            required: true
        },
        closeModalChinhSua: {
            type: Function,
            required: true
        },
        dataTinhThanh: {
            type: Array,
            required: true
        },
        dataModalChinhSua: {
            type: Object,
            required: true
        },
        handleSearch: Function
    },

    components: { CustomButton },
    data() {
        return {
            errors: {
                maDonVi: null,
                loaiDonVi: null,
                donViCha: null,
                emailDonVi: null,
                tinhThanh: null,
                xaPhuong: null,
                trangThai: null,
                tenDonVi: null,
                donViQuanLy: null,
                phoneDonVi: null,
                diaChiDonVi: null,
                quanHuyen: null,
                toThon: null
            },
            submitted: false, // Thêm biến submitted
            getDataESelect: {
                ESelect_loaiDonVi: ESelectUnitType_DonVi,
                ESelect_donViCha: [],
                ESelect_tinhThanh: [],
                ESelect_quanHuyen: [],
                ESelect_xaPhuong: [],
                ESelect_TrangThai: [
                    {
                        title: 'Chọn',
                        value: ''
                    },

                    {
                        title: 'Đang hoạt động',
                        value: 1
                    },
                    {
                        title: 'Ngưng hoạt động',
                        value: 0
                    }
                ],
                ESelect_toThon: []
            },
            selectedValue: {
                input_maDonVi: '',
                selectedValue_loaiDonVi: '',
                selectedValue_donViCha: '',
                input_emailDonVi: '',
                input_webSiteDonVi: '',
                selectedValue_tinhThanh: '',
                selectedValue_xaPhuong: '',
                selectedValue_trangThai: '',
                input_tenDonVi: '',
                input_donViQuanLy: '',
                input_soDienThoaiDonVi: '',
                input_diaChiDonVi: '',
                selectedValue_quanHuyen: '',
                selectedValue_toThon: ''
            },
            selectedValue_change: {
                change_loaiDonVi: '',
                change_tinhThanh: '',
                change_quanHuyen: '',
                change_phuongXa: ''
            }
        }
    },

    methods: {
        handleChange_loaiDonVi() {
            this.validateLoaiDonVi()
            this.selectedValue_change.change_loaiDonVi =
                this.selectedValue.selectedValue_loaiDonVi
            if (this.selectedValue_change.change_loaiDonVi === 2) {
                this.getDataDonViCha()
            } else {
                this.selectedValue.selectedValue_donViCha = ''
                this.getDataESelect.ESelect_donViCha = []
            }
        },
        async getDataDonViCha() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    start: 0,
                    limit: 999,
                    loaiDonVi: 1
                }

                const response = await sendRequest(
                    Api.superAdmin.getDonViCha,
                    null,
                    request_Header,
                    request_Params
                )
                this.getDataESelect.ESelect_donViCha = this.mapData(response, {
                    title: 'tenDonVi',
                    value: 'maDonVi'
                })

                loading.close()
            } catch (error) {
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },
        handleChange_tinhThanh() {
            this.validateTinhThanh()
            this.selectedValue_change.change_tinhThanh =
                this.selectedValue.selectedValue_tinhThanh
            if (this.selectedValue_change.change_tinhThanh === '') {
                this.getDataESelect.ESelect_quanHuyen = []
                this.selectedValue.selectedValue_quanHuyen = ''
                this.getDataESelect.ESelect_xaPhuong = []
                this.selectedValue.selectedValue_xaPhuong = ''
                this.getDataESelect.ESelect_toThon = []
                this.selectedValue.selectedValue_toThon = ''
            } else {
                this.getDataQuanHuyen()
            }
        },
        async getDataQuanHuyen() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    start: 0,
                    limit: 999,
                    maTinhThanh: this.selectedValue_change.change_tinhThanh
                }

                const response = await sendRequest(
                    Api.superAdmin.getQuanHuyen,
                    null,
                    request_Header,
                    request_Params
                )
                this.getDataESelect.ESelect_quanHuyen = this.mapData(response, {
                    title: 'tenQuanHuyen',
                    value: 'maQuanHuyen'
                })

                loading.close()
            } catch (error) {
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },
        handleChange_quanHuyen() {
            this.selectedValue_change.change_quanHuyen =
                this.selectedValue.selectedValue_quanHuyen
            if (this.selectedValue_change.change_quanHuyen === '') {
                this.getDataESelect.ESelect_xaPhuong = []
                this.selectedValue.selectedValue_xaPhuong = ''
                this.getDataESelect.ESelect_toThon = []
                this.selectedValue.selectedValue_toThon = ''
            } else {
                this.getDataPhuongXa()
            }
        },
        async getDataPhuongXa() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    start: 0,
                    limit: 999,
                    maQuanHuyen: this.selectedValue_change.change_quanHuyen
                }

                const response = await sendRequest(
                    Api.superAdmin.getPhuongXa,
                    null,
                    request_Header,
                    request_Params
                )
                this.getDataESelect.ESelect_xaPhuong = this.mapData(response, {
                    title: 'tenPhuongXa',
                    value: 'maPhuongXa'
                })

                loading.close()
            } catch (error) {
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },
        handleChange_phuongXa() {
            this.validatePhuongXa()
            this.selectedValue_change.change_phuongXa =
                this.selectedValue.selectedValue_xaPhuong
            if (this.selectedValue_change.change_phuongXa === '') {
                this.getDataESelect.ESelect_toThon = []
                this.selectedValue.selectedValue_toThon = ''
            } else {
                this.getDataToThon()
            }
        },
        async getDataToThon() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    start: 0,
                    limit: 999,
                    maPhuongXa: this.selectedValue_change.change_phuongXa
                }

                const response = await sendRequest(
                    Api.superAdmin.getToThon,
                    null,
                    request_Header,
                    request_Params
                )
                this.getDataESelect.ESelect_toThon = this.mapData(response, {
                    title: 'tenToThon',
                    value: 'maToThon'
                })

                loading.close()
            } catch (error) {
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },
        handleChange_trangThai() {
            this.validateTrangThai()
        },
        handleChinhSua() {
            this.submitted = true
            this.validateMaDonVi()
            this.validateLoaiDonVi()
            this.validateEmailDonVi()
            if (this.selectedValue.selectedValue_loaiDonVi === 2) {
                this.validateDonViCha()
            }
            this.validateTinhThanh()
            this.validatePhuongXa()
            this.validateTrangThai()
            this.validateTenDonVi()
            this.validateDonViQuanLy()
            this.validatePhoneDonVi()
            this.validateDiaChiDonVi()
            this.validateQuanHuyen()
            // Kiểm tra nếu không có lỗi thì tiếp tục thực hiện hành động xác thực
            if (
                !this.errors.maDonVi &&
                !this.errors.loaiDonVi &&
                !this.errors.emailDonVi &&
                !this.errors.tinhThanh &&
                !this.errors.xaPhuong &&
                !this.errors.trangThai &&
                !this.errors.tenDonVi &&
                !this.errors.donViQuanLy &&
                !this.errors.phoneDonVi &&
                !this.errors.diaChiDonVi &&
                !this.errors.quanHuyen &&
                (!(this.selectedValue.selectedValue_loaiDonVi === 2) ||
                    !this.errors.donViCha)
            ) {
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })

                this.$confirm('Xác nhận chỉnh sửa bản ghi ?', 'Thông báo', {
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy',
                    type: 'warning'
                })
                    .then(async () => {
                        const request_Header = {
                            token: this.authToken
                        }
                        const request_Data = {
                            ma: this.selectedValue.input_maDonVi,
                            ten: this.selectedValue.input_tenDonVi,
                            maDonViCha:
                                this.selectedValue.selectedValue_donViCha || null,
                            trangThai: this.selectedValue.selectedValue_trangThai,
                            maToThon:
                                this.selectedValue.selectedValue_toThon || null,
                            maPhuongXa: this.selectedValue.selectedValue_xaPhuong,
                            maQuanHuyen:
                                this.selectedValue.selectedValue_quanHuyen,
                            maTinhThanh:
                                this.selectedValue.selectedValue_tinhThanh,
                            website:
                                this.selectedValue.input_webSiteDonVi || null,
                            soDienThoai:
                                this.selectedValue.input_soDienThoaiDonVi,
                            email: this.selectedValue.input_emailDonVi,
                            loaiDonVi: this.selectedValue.selectedValue_loaiDonVi,
                            donViQuanLy: this.selectedValue.input_donViQuanLy,
                            address: this.selectedValue.input_diaChiDonVi
                        }
                        const response = await sendRequest(
                            Api.superAdmin.donVi.suaDonVi,
                            request_Data,

                            request_Header
                        )
                        if (response.code == 200) {
                            this.$message({
                                type: 'success',
                                message: 'Chỉnh sửa  thành công'
                            })
                            this.closeModalChinhSua()
                            this.$emit('handleSearch')
                        } else {
                            loading.close()
                            this.$message({
                                type: 'error',
                                message: response.message
                            })
                        }
                    })
                    .catch(() => {
                        loading.close()
                    })
            }
        },
        validateMaDonVi() {
            if (this.submitted) {
                this.errors.maDonVi = validateInput(
                    'text',
                    this.selectedValue.input_maDonVi
                )
            }
        },

        validateLoaiDonVi() {
            if (this.submitted) {
                if (!this.selectedValue.selectedValue_loaiDonVi) {
                    this.errors.loaiDonVi = 'Vui lòng chọn loại đơn vị'
                } else {
                    this.errors.loaiDonVi = ''
                }
            }
        },
        validateEmailDonVi() {
            if (this.submitted) {
                this.errors.emailDonVi = validateInput(
                    'email',
                    this.selectedValue.input_emailDonVi
                )
            }
        },
        validateDonViCha() {
            if (this.submitted) {
                if (!this.selectedValue.selectedValue_donViCha) {
                    this.errors.donViCha = 'Vui lòng chọn loại đơn vị cha'
                } else {
                    this.errors.donViCha = ''
                }
            }
        },
        validateTinhThanh() {
            if (this.submitted) {
                if (!this.selectedValue.selectedValue_tinhThanh) {
                    this.errors.tinhThanh = 'Vui lòng chọn tỉnh thành'
                } else {
                    this.errors.tinhThanh = ''
                }
            }
        },
        validatePhuongXa() {
            if (this.submitted) {
                if (!this.selectedValue.selectedValue_xaPhuong) {
                    this.errors.xaPhuong = 'Vui lòng chọn phường xã'
                } else {
                    this.errors.xaPhuong = ''
                }
            }
        },
        validateTrangThai() {
            if (this.submitted) {
                if (this.selectedValue.selectedValue_trangThai === '') {
                    this.errors.trangThai = 'Vui lòng chọn trạng thái'
                } else {
                    this.errors.trangThai = ''
                }
            }
        },
        validateTenDonVi() {
            if (this.submitted) {
                this.errors.tenDonVi = validateInput(
                    'text',
                    this.selectedValue.input_tenDonVi
                )
            }
        },
        validateDonViQuanLy() {
            if (this.submitted) {
                this.errors.donViQuanLy = validateInput(
                    'text',
                    this.selectedValue.input_donViQuanLy
                )
            }
        },

        validatePhoneDonVi() {
            if (this.submitted) {
                this.errors.phoneDonVi = validateInput(
                    'phone',
                    this.selectedValue.input_soDienThoaiDonVi
                )
            }
        },
        validateDiaChiDonVi() {
            if (this.submitted) {
                this.errors.diaChiDonVi = validateInput(
                    'text',
                    this.selectedValue.input_diaChiDonVi
                )
            }
        },
        validateQuanHuyen() {
            if (this.submitted) {
                if (!this.selectedValue.selectedValue_quanHuyen) {
                    this.errors.quanHuyen = 'Vui lòng chọn quận huyện'
                } else {
                    this.errors.quanHuyen = ''
                }
            }
        },
        mapData(response, property) {
            return response.rows.map((item) => ({
                ...item,
                title: `${item[property.title]} - [${item[property.value]}]`,
                value: item[property.value]
            }))
        }
    },
    watch: {
        dataModalChinhSua(newValue) {
            this.selectedValue.input_maDonVi = newValue.maDonVi
            this.selectedValue.selectedValue_loaiDonVi = newValue.loaiDonVi

            this.selectedValue.selectedValue_donViCha = newValue.maDonViCha
            this.selectedValue.input_emailDonVi = newValue.email
            this.selectedValue.input_webSiteDonVi = newValue.website

            this.selectedValue.selectedValue_tinhThanh = newValue.maTinhThanh
            this.selectedValue.selectedValue_xaPhuong = newValue.maPhuongXa
            this.selectedValue.selectedValue_trangThai = newValue.trangThai
            this.selectedValue.input_tenDonVi = newValue.tenDonVi
            this.selectedValue.input_donViQuanLy = newValue.donViQuanLy
            this.selectedValue.input_soDienThoaiDonVi = newValue.sdt
            this.selectedValue.input_diaChiDonVi = newValue.address
            this.selectedValue.selectedValue_quanHuyen = newValue.maQuanHuyen
            this.selectedValue.selectedValue_toThon = newValue.maToThon
            this.getDataDonViCha()
            this.getDataQuanHuyen()
            this.getDataPhuongXa()
            this.getDataToThon()
        },
        'selectedValue.input_maDonVi': function (newValue) {
            this.validateMaDonVi()
        },
        'selectedValue.selectedValue_loaiDonVi': function (newValue) {
            this.validateLoaiDonVi()
        },
        'selectedValue.input_emailDonVi': function (newValue) {
            this.validateEmailDonVi()
        },
        'selectedValue.selectedValue_donViCha': function (newValue) {
            this.validateDonViCha()
        },
        'selectedValue.selectedValue_tinhThanh': function (newValue) {
            this.validateTinhThanh()
        },
        'selectedValue.selectedValue_xaPhuong': function (newValue) {
            this.validatePhuongXa()
        },
        'selectedValue.selectedValue_trangThai': function (newValue) {
            this.validateTrangThai()
        },
        'selectedValue.input_tenDonVi': function (newValue) {
            this.validateTenDonVi()
        },
        'selectedValue.input_donViQuanLy': function (newValue) {
            this.validateDonViQuanLy()
        },
        'selectedValue.input_soDienThoaiDonVi': function (newValue) {
            this.validatePhoneDonVi()
        },
        'selectedValue.input_diaChiDonVi': function (newValue) {
            this.validateDiaChiDonVi()
        },
        'selectedValue.selectedValue_quanHuyen': function (newValue) {
            this.validateQuanHuyen()
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped>
::v-deep .el-input.is-disabled .el-input__inner {
    color: black !important;
}
hr {
    border: 1px solid green;
}
.form .title {
    font-weight: bold;
}
.form .title .span {
    color: red;
}
.form .error-message {
    font-style: italic;
    color: red;
    margin-top: 5px;
}
.custom-date-picker {
    height: 32px;
}
.custom-date-picker::placeholder {
    color: gainsboro;

    font-size: 13px;
    background-color: transparent;
    appearance: none;
}
.layout-btn {
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
}
</style>
