<template>
    <el-dialog :visible="themMoiModalVisible" @close="resetModal" width="70%">
        <span slot="title" class="custom-dialog-title">
            Thêm mới gói cước
            <hr />
        </span>
        <div class="row form">
            <div class="col-md-6">
                <div class="row d-flex align-items-center">
                    <div class="col-2 title">Giáo viên</div>
                    <div class="col-8">
                        <el-input
                            placeholder="Nhập giáo viên ..."
                            v-model="selectedValue.input_maGiaoVien"
                            size="small"
                            style="width: 100%"
                        >
                        </el-input>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row d-flex align-items-center">
                    <div class="col-2 title">Gói cước</div>
                    <div class="col-8">
                        <el-input
                            placeholder="Nhập gói cước ..."
                            v-model="selectedValue.input_goiCuoc"
                            size="small"
                            style="width: 100%"
                        >
                        </el-input>
                    </div>
                </div>
            </div>
        </div>
        <div class="layout-btn">
            <div>
                <CustomButton
                    label="Đóng"
                    size="small"
                    type="info"
                    @click="resetModal"
                />
            </div>
            <div style="margin-left: 10px">
                <CustomButton
                    label="Thêm mới"
                    size="small"
                    type="success"
                    @click="handleThemMoi"
                />
            </div>
        </div>
    </el-dialog>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import { mapState } from 'vuex'
import sendRequest from '@/services'

import Api from '@/constants/Api'
export default {
    name: 'ModalThemMoiTruongHoc',
    props: {
        themMoiModalVisible: {
            type: Boolean,
            required: true
        },
        closeModalThemMoi: {
            type: Function,
            required: true
        },

        handleSearch: Function
    },

    components: { CustomButton },
    data() {
        return {
            selectedValue: {
                input_maGiaoVien: '',
                input_goiCuoc: ''
            }
        }
    },

    methods: {
        resetModal() {
            this.closeModalThemMoi()
            this.selectedValue.input_goiCuoc = ''
            this.selectedValue.input_maGiaoVien = ''
        },
        handleThemMoi() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })

            this.$confirm('Xác nhận thêm mới ?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const request_Header = {
                        token: this.authToken
                    }
                    const request_Data = {
                        maGiaoVien: this.selectedValue.input_maGiaoVien,
                        goiCuoc: this.selectedValue.input_goiCuoc
                    }
                    const response = await sendRequest(
                        Api.superAdmin.quanLyGoiCuocCA.themMoiQuanLyGoiCuocCA,
                        request_Data,

                        request_Header
                    )
                    if (response.rc == 0) {
                        this.$message({
                            type: 'success',
                            message: 'Thêm mới thành công'
                        })
                        this.closeModalThemMoi()
                        this.$emit('handleSearch')
                    } else {
                        loading.close()
                        this.$message({
                            type: 'error',
                            message: response.rd
                        })
                    }
                })
                .catch(() => {
                    loading.close()
                })
        }
    },

    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style>
hr {
    border: 1px solid green;
}
.form .title {
    font-weight: bold;
}
.form .title .span {
    color: red;
}
.form .error-message {
    font-style: italic;
    color: red;
    margin-top: 5px;
}

.layout-btn {
    display: flex;
    margin-top: 50px;
    justify-content: flex-end;
}
</style>
