<template>
    <div class="school">
        <div class="header">
            <div class="left">
                <div class="icon">
                    <i class="el-icon-s-home"></i>
                </div>
                <div class="title">Thông tin điểm trường học</div>
            </div>
        </div>
        <div class="layout-header">
            <div>
                <ChonSoLuongBanGhi @chonXongSoLuong="ChonSoLuongBanGhi($event)" />
            </div>
            <div>
                <CustomButton
                    label="Thêm mới"
                    size="small"
                    class="btn-default btn-add"
                    @click="openModalThemMoiDiemTruong"
                />
                <CustomButton
                    class="btn-default btn-excel"
                    label="Xuất Excel"
                    size="small"
                    @click="handleExportExcel"
                />
                <CustomButton
                    label="Xóa"
                    size="small"
                    class="btn-default btn-delete"
                    @click="handleDelete"
                />
            </div>
        </div>
        <div class="layout-table">
            <table class="table table-bordered table-hover centered-table">
                <thead>
                    <tr>
                        <th class="text-thead text-center">
                            <input
                                type="checkbox"
                                v-model="selectAllChecked"
                                @change="handleSelectAll"
                                :disabled="tableData.length === 0"
                            />
                        </th>
                        <th class="text-thead">STT</th>
                        <th class="text-thead">Mã điểm trường</th>
                        <th class="text-thead">Tên điểm trường</th>
                        <th class="text-thead">Địa chỉ</th>
                        <th class="text-thead">
                            Khoảng cách tới trường chính (km)
                        </th>
                        <th class="text-thead">Số điện thoại</th>
                        <th class="text-thead">Phương tiện đi lại</th>
                        <th class="text-thead">Diện tích</th>
                        <th class="text-thead">Mô tả</th>
                        <th class="text-thead">Thao tác</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="tableData.length === 0">
                        <tr>
                            <td class="text-tbody text-center" colspan="9">
                                Không có bản ghi nào.
                            </td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td class="text-tbody text-center">
                            <input
                                type="checkbox"
                                :checked="item.isChecked"
                                @change="toggleCheckbox(item)"
                            />
                        </td>
                        <td class="text-tbody text-center">{{ index + 1 }}</td>
                        <td class="text-tbody">{{ item.maDiemTruong }}</td>
                        <td class="text-tbody">{{ item.tenDiemTruong }}</td>
                        <td class="text-tbody">{{ item.diaChi }}</td>
                        <td class="text-tbody text-center">
                            {{ item.khoangCach }} km
                        </td>
                        <td class="text-tbody">{{ item.dienThoai }}</td>
                        <td class="text-tbody">{{ item.phuongTien }}</td>
                        <td class="text-tbody text-center">
                            {{ item.dienTich }} m2
                        </td>
                        <td class="text-tbody">{{ item.moTa }}</td>
                        <td class="text-tbody text-center">
                            <CustomButton
                                label="Sửa"
                                size="small"
                                class="btn-default btn-update"
                                @click="openModalChinhSuaDiemTruong(item)"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <CustomPagination
                v-show="total_rows > 0"
                :tongbanghi="total_rows"
                :soluonghienthi="soLuongBanGhiHienThi"
                :batdau="trangbatdau"
                @pageChange="layLai($event)"
            >
            </CustomPagination>
            <ModalThemMoi
                @getDataDiemTruong="getDataDiemTruong"
                :selectedValueSchoolYear="selectedValueSchoolYear"
                :themMoiDiemTruongModalVisible="themMoiDiemTruongModalVisible"
                :closeModalThemMoiDiemTruong="closeModalThemMoiDiemTruong"
            />
            <ModalChinhSua
                :dataChinhSua="dataChinhSua"
                @getDataDiemTruong="getDataDiemTruong"
                :chinhSuaDiemTruongModalVisible="chinhSuaDiemTruongModalVisible"
                :closeModalChinhSuaDiemTruong="closeModalChinhSuaDiemTruong"
            />
        </div>
    </div>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import ModalChinhSua from './ModalChinhSua.vue'
import ModalThemMoi from './ModalThemMoi.vue'
import { currentYear } from '@/utils'
import { mapState } from 'vuex'
import ChonSoLuongBanGhi from '@/components/chonSoLuongBanGhi.vue'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomPagination from '@/components/CustomPagination.vue'
import axios from 'axios'
export default {
    name: 'educatorProfile',
    components: {
        CustomButton,
        ModalChinhSua,
        ModalThemMoi,
        ChonSoLuongBanGhi,
        CustomPagination
    },
    data() {
        return {
            selectAllChecked: false,
            dataSelectedAll: [],
            selectedItems: [],

            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            soLuongBanGhiHienThi: 25,
            trangbatdau: false,

            tableData: [],
            themMoiDiemTruongModalVisible: false,
            chinhSuaDiemTruongModalVisible: false,
            dataChinhSua: {}
        }
    },

    props: {
        selectedValueSchoolYear: {
            type: String
        },
        dataDiemTruong: {
            type: Array,
            default: function () {
                return []
            }
        }
    },

    mounted() {
        this.getDataDiemTruong()
    },
    methods: {
        toggleCheckbox(item) {
            this.selectAllChecked = false
            item.isChecked = !item.isChecked
            if (item.isChecked) {
                this.selectedItems.push(item)
            } else {
                const index = this.selectedItems.indexOf(item)
                if (index > -1) {
                    this.selectedItems.splice(index, 1)
                }
            }
        },

        handleSelectAll() {
            this.tableData.forEach((item) => {
                item.isChecked = this.selectAllChecked
                if (this.selectAllChecked && !this.selectedItems.includes(item)) {
                    this.selectedItems.push(item)
                } else if (!this.selectAllChecked) {
                    this.selectedItems = []
                }
            })
        },
        async handleExportExcel() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })

            this.$confirm('Xác nhận tải xuống?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })

                .then(async () => {
                    const requestData = {
                        maTruongs: [this.authUser.ma_truong_hoc],
                        namHoc: this.selectedValueSchoolYear || currentYear
                    }
                    const domain = process.env.VUE_APP_BACKEND_BASE_URL
                    const response = await axios.post(
                        `${domain}/csdlgd-admin/ExportExcel/exportDiemTruong`,
                        requestData,
                        {
                            headers: {
                                token: this.authToken,
                                'Content-Type': 'application/json'
                            },
                            responseType: 'blob' // Set responseType là 'blob'
                        }
                    )

                    const blob = new Blob([response.data])
                    const blobUrl = window.URL.createObjectURL(blob)

                    const downloadLink = document.createElement('a')
                    downloadLink.href = blobUrl
                    downloadLink.download = 'ThongTinDiemTruong.xlsx'
                    downloadLink.style.display = 'none'

                    document.body.appendChild(downloadLink)
                    downloadLink.click()

                    window.URL.revokeObjectURL(blobUrl)
                    loading.close()
                })
                .catch((error) => {
                    loading.close()
                    if (error.response?.data.rd) {
                        this.$message({
                            type: 'error',
                            message: error.response?.data.rd
                        })
                    }
                })
        },
        handleDelete() {
            if (this.selectedItems.length === 0) {
                this.$notify.error({
                    title: 'Error',
                    message: 'Chọn ít nhất 1 bản ghi'
                })
                return
            }

            const maDiemTruongs = this.selectedItems.map(
                (item) => item.maDiemTruong
            )
            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                maDiemTruongs: maDiemTruongs,

                namHoc: this.selectedValueSchoolYear || currentYear
            }

            this.$confirm('Xác nhận xóa các bản ghi đã chọn?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    })
                    const response = await sendRequest(
                        Api.traCuu.traCuuTruongHoc.deleteDiemTruong,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        loading.close()
                        this.tableData = this.tableData.filter(
                            (item) => !item.isChecked
                        )

                        this.selectedItems = []
                        this.dataSelectedAll = []
                        this.selectAllChecked = false
                        await this.getDataDiemTruong()
                        this.$message({
                            type: 'success',
                            message: 'Xóa thành công'
                        })
                    } else {
                        loading.close()
                        this.$message({
                            type: 'error',
                            message: response.rd
                        })
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    })
                })
        },
        async getDataDiemTruong() {
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Data = {
                    start: this.start,
                    limit: this.limit,
                    maTruongs: [this.authUser.ma_truong_hoc],
                    namHoc: this.selectedValueSchoolYear || currentYear
                }
                const response = await sendRequest(
                    Api.roleSchool.dataDiemTruong,
                    request_Data,
                    request_Header
                )

                this.tableData = response.rows.map((item) => {
                    return { ...item, isChecked: false }
                })
                console.log(this.tableData)
                this.total_rows = response.total
            } catch (error) {
                console.log(error)
            }
        },
        ChonSoLuongBanGhi(e) {
            this.total_rows = 0
            this.soLuongBanGhiHienThi = e.soluong
        },
        checkTruocKhiTim() {
            this.trangbatdau = !this.trangbatdau
        },
        layLai(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage

            this.getDataDiemTruong()
        },
        openModalThemMoiDiemTruong() {
            this.themMoiDiemTruongModalVisible = true
        },
        closeModalThemMoiDiemTruong() {
            this.themMoiDiemTruongModalVisible = false
        },
        openModalChinhSuaDiemTruong(item) {
            this.dataChinhSua = item
            this.chinhSuaDiemTruongModalVisible = true
        },
        closeModalChinhSuaDiemTruong() {
            this.chinhSuaDiemTruongModalVisible = false
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>

<style scoped>
.school {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 15px;
    padding-left: 6px;
    padding-right: 6px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 10px 12px 10px;
}
.header .left,
.header .right {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #186ab2;
    font-weight: 700;
    text-transform: uppercase;
}
.header .right .time {
    color: #333;
}
.header .layout-icon {
    margin-right: 8px;
}
.header .title .span {
    color: #333;
    font-weight: 600;
}
.header .left .icon {
    margin-right: 5px;
}
.header .right .icon {
    font-size: 18px;
    color: #000000;
    font-weight: 700;
    margin-right: 5px;
}

.header .right {
    margin-left: 6px;
}

.layout-table {
    background: #fff;
    padding: 20px 10px 5px 10px;
    border-radius: 4px;

    overflow-x: auto;
}
table thead tr {
    background: rgb(228, 235, 245);
}
.table {
    white-space: nowrap;
}
.centered-table {
    margin-left: auto;
    margin-right: auto;
}

.centered-table th {
    text-align: center;
}
.table-bordered {
    background-color: #fff;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}
.table-hover tbody tr:hover {
    background-color: #f5f5f5;
}

.text-thead {
    font-size: 13px;

    vertical-align: middle; /* Căn giữa theo chiều dọc */
}
.text-tbody {
    font-size: 13px;
    vertical-align: middle;
}
.text-center {
    text-align: center;
}
.custom-btn {
    background-color: #2e93fa;
    color: white;
    margin-left: 10px;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.bg-search {
    background-color: var(--teal);
}
.btn-add {
    background-color: var(--light-green);
}
.btn-delete {
    background-color: var(--orange);
}
.btn-excel {
    background-color: #1e6050;
}
.btn-update {
    background-color: var(--yellow);
}
.layout-header {
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
}
</style>
