<template>
    <el-dialog
        :visible="giaiTheModalVisible"
        @close="closeModalGiaiThe"
        width="50%"
    >
        <span slot="title">
            Giải thể trường học
            <hr />
        </span>
        <strong>
            Thao tác giải thể trường học sẽ xóa các thông tin liên quan sau
        </strong>

        <ul>
            <li>Hồ sơ lớp học</li>
            <li>Hồ sơ nhân sự</li>
            <li>Hồ sơ học sinh</li>
            <li>Đánh giá chuẩn nghề nghiệp nhân sự</li>
            <li>Các quá trình nhân sự</li>
            <li>Báo cáo EMIS</li>
        </ul>
        <strong class="color-red">
            Lưu ý: Trường giải thể sẽ không khôi phục lại được dữ liệu
        </strong>

        <div style="margin-top: 10px; display: flex; justify-content: center">
            <CustomButton
                label="Hủy"
                size="small"
                class="btn-close btn-default"
                @click="closeModalGiaiThe"
            />
            <CustomButton
                label="Giải thể"
                size="small"
                class="btn-add btn-default"
                @click="handleGiaiThe"
            />
        </div>
    </el-dialog>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import { mapState } from 'vuex'
import sendRequest from '@/services'
import ESelect from '@/components/ESelect.vue'

import Api from '@/constants/Api'
export default {
    name: 'ModalGiaiThe',
    props: {
        giaiTheModalVisible: {
            type: Boolean,
            required: true
        },
        closeModalGiaiThe: {
            type: Function,
            required: true
        },
        data_GiaiThe: {
            type: Object,
            required: true
        },
        handleSearch: Function
    },

    components: { CustomButton },
    data() {
        return {
            list_hocKy: [
                {
                    name: 'Học kỳ I',
                    value: 1
                },
                { name: 'Học kỳ II', value: 2 }
            ],
            hocKy: ''
        }
    },

    methods: {
        async handleGiaiThe() {
            const request_Header = {
                token: this.authToken
            }

            const request_Params = {
                maTruongHoc: this.data_GiaiThe.maTruongHoc
            }

            const response = await sendRequest(
                Api.heThong.sapNhapGiaiThe.giaiThe,
                null,
                request_Header,
                request_Params
            )
            if (response.rc == 0) {
                this.$notify.success({
                    title: 'Giải thể trường học thành công'
                })
                this.closeModalGiaiThe()
                this.$emit('handleSearch')
            } else {
                this.$notify.error({
                    title: response.rd
                })
            }
        }
    },
    watch: {
        data_GiaiThe(newValue) {
            console.log(newValue)
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped>
.el-dialog__body {
    padding: 10px 20px;
}
hr {
    border: 1px solid gainsboro;
}
ul {
    list-style-type: disc;
    margin-left: 50px;
    margin-top: 5px;
}

li {
    margin-bottom: 5px;
}
.layout-btn {
    display: flex;
    justify-content: center;
}
.color-red {
    color: red;
}
.btn-close {
    background-color: var(--orange);
    color: var(--btn-text-color);
}
.btn-close:hover {
    background-color: var(--orange);
    color: var(--btn-text-color);
}
.btn-add,
.btn-add:hover {
    background-color: var(--light-green);
    color: var(--btn-text-color);
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
</style>
