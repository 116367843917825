<template>
    <el-dialog
        :visible="themMoiModalVisible"
        @close="closeModalThemMoi"
        :fullscreen="true"
        width="80%"
    >
        <span slot="title" class="custom-dialog-title">
            Thêm mới câu hỏi
            <hr />
        </span>
        <div class="row">
            <div class="col-md-12">
                <label>Nội dung câu hỏi <span>*</span></label>
                <el-input
                    placeholder="Nhập nội dung câu hỏi ..."
                    v-model="cauHoi"
                ></el-input>
            </div>
            <div class="col-md-12 mt-4">
                <label>Nội dung câu trả lời</label>
            </div>
            <div class="col-md-12">
                <ckeditor
                    v-model="traLoi"
                    :config="editorConfig"
                    :editor-url="editorUrl"
                >
                </ckeditor>
            </div>
        </div>
        <div class="layout-btn">
            <div>
                <CustomButton
                    label="Đóng"
                    size="small"
                    type="info"
                    @click="closeModalThemMoi"
                />
            </div>
            <div style="margin-left: 10px">
                <CustomButton
                    label="Thêm mới"
                    size="small"
                    type="success"
                    @click="handleThemMoi"
                />
            </div>
        </div>
    </el-dialog>
</template>
<script>
import Vue from 'vue'
import CustomButton from '@/components/CustomButton.vue'
import { mapState } from 'vuex'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import ckeditor from 'ckeditor4-vue'
Vue.use(ckeditor)
export default {
    name: 'ModalThemMoiTruongHoc',
    props: {
        themMoiModalVisible: {
            type: Boolean,
            required: true
        },
        closeModalThemMoi: {
            type: Function,
            required: true
        },
        getDataDanhSachCauHoi: Function
    },

    components: { CustomButton },
    data() {
        return {
            traLoi: '<p>Nhập nội dung câu trả lời </p>',
            cauHoi: '',
            thongtin: {},
            editorUrl: 'https://cdn.ckeditor.com/4.14.1/full-all/ckeditor.js',
            editorConfig: {
                toolbarGroups: [
                    {
                        name: 'document',
                        groups: ['mode', 'document', 'doctools']
                    },
                    { name: 'clipboard', groups: ['clipboard', 'undo'] },
                    {
                        name: 'editing',
                        groups: ['find', 'selection', 'spellchecker', 'editing']
                    },
                    { name: 'forms', groups: ['forms'] },
                    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                    {
                        name: 'paragraph',
                        groups: [
                            'list',
                            'indent',
                            'blocks',
                            'align',
                            'bidi',
                            'paragraph'
                        ]
                    },
                    { name: 'links', groups: ['links'] },
                    { name: 'insert', groups: ['insert'] },
                    { name: 'styles', groups: ['styles'] },
                    { name: 'colors', groups: ['colors'] },
                    { name: 'tools', groups: ['tools'] },
                    { name: 'others', groups: ['others'] },
                    { name: 'about', groups: ['about'] }
                ],
                removeButtons:
                    'NewPage,Print,Save,Templates,Replace,Find,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,Anchor,Flash,Smiley,PageBreak,ShowBlocks,About,Language,Iframe'
            }
        }
    },

    methods: {
        handleThemMoi() {
            if (this.cauHoi === '') {
                this.$message({
                    type: 'error',
                    message: 'Vui lòng nhập câu hỏi'
                })
                return
            }
            if (this.traLoi === '') {
                this.$message({
                    type: 'error',
                    message: 'Vui lòng nhập câu trả lời'
                })
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })

            this.$confirm('Xác nhận thêm mới ?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const request_Header = {
                        token: this.authToken
                    }
                    const request_Data = {
                        cauHoi: this.cauHoi,
                        traLoi: this.traLoi
                    }
                    const response = await sendRequest(
                        Api.superAdmin.quanLyCauHoiThuongGap.themCauHoi,
                        request_Data,

                        request_Header
                    )
                    if (response.rc == 0) {
                        loading.close()
                        this.$message({
                            type: 'success',
                            message: 'Thêm mới thành công'
                        })
                        this.closeModalThemMoi()
                        this.$emit('getDataDanhSachCauHoi')
                    } else {
                        loading.close()
                        this.$message({
                            type: 'error',
                            message: response.message
                        })
                    }
                })
                .catch(() => {
                    loading.close()
                })
        }
    },

    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped>
hr {
    border: 1px solid green;
}
.form .title {
    font-weight: bold;
}

.layout-btn {
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
}
</style>
