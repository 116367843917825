var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"school"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"left"},[_c('div',[_c('el-icon',{staticClass:"icon",attrs:{"name":"s-custom"}})],1),_c('div',{staticClass:"title"},[_vm._v("Cán bộ - Giáo viên")])]),_c('div',{staticClass:"right"},[_vm._m(0),_c('div',[_vm._v(" Thời gian cập nhật: "),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.dataCanBoGiaoVien?.lastUpdate))]),_c('CustomButton',{staticClass:"custom-btn",attrs:{"label":"Chi tiết","size":"small"},on:{"click":_vm.handleNavigate}})],1)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataCanBoGiaoVien !== null),expression:"dataCanBoGiaoVien !== null"}],staticClass:"layout-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 mb-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"title"},[_vm._v(" Tổng quan "),_c('span',{staticClass:"span"},[_vm._v(" (Đang làm việc: "+_vm._s(_vm.dataCanBoGiaoVien?.slCbDangLamViec)+" / Tổng số "+_vm._s(_vm.getTotalCanBo())+")")])])]),_c('div',{staticClass:"card-body"},[_c('PieChart',{attrs:{"data_PieChart":_vm.dataCanBoGiaoVien?.tongCanBoChart || []}})],1)])]),_c('div',{staticClass:"col-md-4 mb-4"},[_c('div',{staticClass:"card"},[_vm._m(1),_c('div',{staticClass:"card-body"},[_c('StackedColumnChart',{attrs:{"data_StackedColumnChart":_vm.dataCanBoGiaoVien?.toanTruong
                                    ?.cbTrinhDoChinh || [],"xaxis_categories":_vm.xaxisCategories.trinhDoChinh}})],1)])]),_c('div',{staticClass:"col-md-4 mb-4"},[_c('div',{staticClass:"card"},[_vm._m(2),_c('div',{staticClass:"card-body"},[_c('StackedColumnChart',{attrs:{"data_StackedColumnChart":_vm.dataCanBoGiaoVien?.toanTruong?.cbHopDong || [],"xaxis_categories":_vm.xaxisCategories.loaiHopDong}})],1)])]),_c('div',{staticClass:"col-md-4 mb-4"},[_c('div',{staticClass:"card"},[_vm._m(3),_c('div',{staticClass:"card-body"},[_c('StackedColumnChart',{attrs:{"data_StackedColumnChart":_vm.dataCanBoGiaoVien?.toanTruong?.cbGioiTinh ||
                                [],"xaxis_categories":_vm.xaxisCategories.gioiTinh}})],1)])]),_c('div',{staticClass:"col-md-4 mb-4"},[_c('div',{staticClass:"card"},[_vm._m(4),_c('div',{staticClass:"card-body"},[_c('StackedColumnChart',{attrs:{"data_StackedColumnChart":_vm.dataCanBoGiaoVien?.toanTruong?.cbDoTuoi || [],"xaxis_categories":_vm.xaxisCategories.doTuoi}})],1)])]),_c('div',{staticClass:"col-md-4 mb-4"},[_c('div',{staticClass:"card"},[_vm._m(5),_c('div',{staticClass:"card-body"},[_c('StackedColumnChart',{attrs:{"data_StackedColumnChart":_vm.dataCanBoGiaoVien?.toanTruong
                                    ?.canBoTrangThai || [],"xaxis_categories":_vm.xaxisCategories.trangThai}})],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"el-icon-timer"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"title"},[_vm._v("Trình độ chính")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"title"},[_vm._v("Loại hợp đồng")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"title"},[_vm._v("Giới tính")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"title"},[_vm._v("ĐỘ TUỔI")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"title"},[_vm._v("TRẠNG THÁI")])])
}]

export { render, staticRenderFns }