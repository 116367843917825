<template>
    <el-dialog
        :visible="themMoiModalVisible"
        @close="closeModalThemMoi"
        width="60%"
    >
        <span slot="title" class="custom-dialog-title">
            THÊM DÂN TỘC MỚI
            <hr />
        </span>
        <div class="row form">
            <div class="col-md-12">
                <div class="row d-flex align-items-center justify-content-center">
                    <div class="col-md-2">
                        <label>Mã dân tộc (<span class="span">*</span>)</label>
                    </div>
                    <div class="col-md-6">
                        <el-input
                            placeholder="Nhập mã dân tộc ..."
                            v-model="selectedValue.input_maDanToc"
                            size="small"
                            style="width: 100%"
                            @blur="validateMaDanToc"
                            @focus="validateMaDanToc"
                        >
                        </el-input>
                        <div class="error-message" v-if="errors.maDanToc">
                            {{ errors.maDanToc }}
                        </div>
                    </div>
                </div>
                <div
                    class="row d-flex align-items-center justify-content-center mt-4"
                >
                    <div class="col-md-2">
                        <label>Tên dân tộc (<span class="span">*</span>)</label>
                    </div>
                    <div class="col-md-6">
                        <el-input
                            placeholder="Nhập tên dân tộc ..."
                            v-model="selectedValue.input_tenDanToc"
                            size="small"
                            style="width: 100%"
                            @blur="validateTenDanToc"
                            @focus="validateTenDanToc"
                        >
                        </el-input>
                        <div class="error-message" v-if="errors.tenDanToc">
                            {{ errors.tenDanToc }}
                        </div>
                    </div>
                </div>
                <div
                    class="row d-flex align-items-center justify-content-center mt-4"
                >
                    <div class="col-md-2">
                        <label>Loại dân tộc (<span class="span">*</span>)</label>
                    </div>
                    <div class="col-md-6">
                        <el-select
                            v-model="selectedValue.selectedValue_loaiDanToc"
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                        >
                            <el-option
                                v-for="item in ESelect_loaiDanToc"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            />
                        </el-select>
                        <div class="error-message" v-if="errors.loaiDanToc">
                            {{ errors.loaiDanToc }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="layout-btn">
            <div>
                <CustomButton
                    label="Đóng"
                    size="small"
                    type="info"
                    @click="closeModalThemMoi"
                />
            </div>
            <div style="margin-left: 10px">
                <CustomButton
                    label="Thêm mới"
                    size="small"
                    type="success"
                    @click="handleThemMoi"
                />
            </div>
        </div>
    </el-dialog>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import { mapState } from 'vuex'
import sendRequest from '@/services'
import { validateInput } from '@/utils'

import Api from '@/constants/Api'
export default {
    name: 'ModalThemMoiTruongHoc',
    props: {
        themMoiModalVisible: {
            type: Boolean,
            required: true
        },
        closeModalThemMoi: {
            type: Function,
            required: true
        },

        handleSearch: Function
    },

    components: { CustomButton },
    data() {
        return {
            errors: {
                maDanToc: null,
                tenDanToc: null,
                loaiDanToc: null
            },
            submitted: false, // Thêm biến submitted
            ESelect_loaiDanToc: [
                {
                    title: 'Loại dân tộc',
                    value: ''
                },
                {
                    title: 'Thiểu số',
                    value: 1
                },
                {
                    title: 'Đa số',
                    value: 2
                }
            ],
            selectedValue: {
                input_maDanToc: '',
                input_tenDanToc: '',
                selectedValue_loaiDanToc: ''
            }
        }
    },

    methods: {
        validateMaDanToc() {
            if (this.submitted) {
                this.errors.maDanToc = validateInput(
                    'text',
                    this.selectedValue.input_maDanToc
                )
            }
        },
        validateTenDanToc() {
            if (this.submitted) {
                this.errors.tenDanToc = validateInput(
                    'text',
                    this.selectedValue.input_tenDanToc
                )
            }
        },
        validateLoaiDanToc() {
            if (this.submitted) {
                if (this.selectedValue.selectedValue_loaiDanToc === '') {
                    this.errors.loaiDanToc = 'Vui lòng loại dân tộc'
                } else {
                    this.errors.loaiDanToc = ''
                }
            }
        },
        handleThemMoi() {
            this.submitted = true
            this.validateMaDanToc()
            this.validateTenDanToc()
            this.validateLoaiDanToc()
            // Kiểm tra nếu không có lỗi thì tiếp tục thực hiện hành động xác thực
            if (
                !this.errors.maDanToc &&
                !this.errors.tenDanToc &&
                !this.errors.loaiDanToc
            ) {
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })

                this.$confirm('Xác nhận thêm mới ?', 'Thông báo', {
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy',
                    type: 'warning'
                })
                    .then(async () => {
                        const request_Header = {
                            token: this.authToken
                        }
                        const request_Data = {
                            maDanToc: this.selectedValue.input_maDanToc,
                            tenDanToc: this.selectedValue.input_tenDanToc,
                            loaiDanToc:
                                this.selectedValue.selectedValue_loaiDanToc,
                            trangThai: 1
                        }
                        const response = await sendRequest(
                            Api.superAdmin.danToc.themDanToc,
                            request_Data,

                            request_Header
                        )
                        if (response.code == 200) {
                            this.$message({
                                type: 'success',
                                message: 'Thêm mới thành công'
                            })
                            this.closeModalThemMoi()
                            this.$emit('handleSearch')
                        } else {
                            loading.close()
                            this.$message({
                                type: 'error',
                                message: response.message
                            })
                        }
                    })
                    .catch(() => {
                        loading.close()
                    })
            }
        }
    },
    watch: {
        'selectedValue.input_maDanToc': function (newValue) {
            this.validateMaDanToc()
        },
        'selectedValue.input_tenDanToc': function (newValue) {
            this.validateTenDanToc()
        },
        'selectedValue.selectedValue_loaiDanToc': function (newValue) {
            this.validateLoaiDanToc()
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped>
hr {
    border: 1px solid green;
}
.form label {
    font-weight: bold;
}
.form .span {
    color: red;
}
.form .error-message {
    font-style: italic;
    color: red;
    margin-top: 5px;
}
.custom-date-picker {
    height: 32px;
}
.custom-date-picker::placeholder {
    color: gainsboro;

    font-size: 13px;
    background-color: transparent;
    appearance: none;
}
.layout-btn {
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
}
</style>
