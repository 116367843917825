<template>
    <div>
        <el-dialog
            :visible="chiTietCanboGvModalVisible"
            @close="closeModalCanboGv"
            width="60%"
            class="custom-dialog"
            :fullscreen="true"
        >
            <span slot="title" class="custom-dialog-title">
                Chỉnh sửa Cán bộ giáo viên
            </span>
            <div class="row">
                <div class="col-12 col-sm-6 col-lg-2">
                    <CardInfo
                        :moetCode="dataModal.moetCode"
                        :avatar="dataModal.avatar"
                    />
                </div>
                <div class="col-12 col-sm-6 col-lg-5">
                    <div class="row d-flex align-items-center">
                        <div class="col-4 title">Họ tên<span>*</span></div>
                        <div class="col-8">
                            <el-input
                                placeholder="Nhập ..."
                                v-model="selectedValue.input_hoTen"
                                size="small"
                            ></el-input>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">Ngày sinh <span>*</span></div>
                        <div class="col-8">
                            <date-picker
                                :config="configDatePicker"
                                placeholder="Chọn ngày"
                                v-model="selectedValue.date_ngaySinh"
                                class="custom-date-picker"
                            >
                            </date-picker>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">Giới tính <span>*</span></div>
                        <div class="col-8">
                            <el-select
                                v-model="selectedValue.selectedValue_gioiTinh"
                                placeholder="Chọn"
                                size="small"
                                filterable
                                collapse-tags
                                clearable
                                style="width: 100%"
                            >
                                <el-option
                                    v-for="item in getDataESelect.ESelect_gioiTinh"
                                    :key="item.value"
                                    :label="item.title"
                                    :value="item.value"
                                />
                            </el-select>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">
                            Trạng thái, cán bộ <span>*</span>
                        </div>
                        <!-- <div class="col-8">
                        <el-select
                            v-model="selectedValue.selectedValue_trangThaiCB"
                            placeholder="Chọn"
                                size="small"
                                filterable
                                collapse-tags
                                clearable
                                style="width: 100%;"
                        >
                            <el-option
                                v-for="item in this.dataChonTrangthaicanbo_Store"
                                :key="item.mappingCode"
                                :label="item.constantTitle"
                                :value="item.mappingCode"
                            />
                        </el-select>
                    </div> -->
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">Là tuyển mới</div>
                        <div class="col-8">
                            <el-checkbox
                                v-model="selectedValue.checkbox_laTuyenMoi"
                            />
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">CMTND/TCC <span>*</span></div>
                        <div class="col-8">
                            <el-input
                                placeholder="Nhập ..."
                                v-model="selectedValue.input_CMTND_TCC"
                                size="small"
                            ></el-input>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">Ngày cấp</div>
                        <div class="col-8">
                            <date-picker
                                :config="configDatePicker"
                                placeholder="Chọn ngày"
                                v-model="selectedValue.date_ngayCap"
                                class="custom-date-picker"
                            >
                            </date-picker>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">Email</div>
                        <div class="col-8">
                            <el-input
                                placeholder="Nhập ..."
                                v-model="selectedValue.input_email"
                                size="small"
                            ></el-input>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-5">
                    <div class="row d-flex align-items-center">
                        <div class="col-4 title">Mã cán bộ</div>
                        <div class="col-8">
                            <el-input
                                :disabled="true"
                                placeholder="Nhập ..."
                                v-model="selectedValue.input_maCanBo"
                                size="small"
                            ></el-input>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">Dân tộc <span>*</span></div>
                        <div class="col-8">
                            <el-select
                                v-model="selectedValue.selectedValue_danToc"
                                placeholder="Chọn"
                                size="small"
                                filterable
                                collapse-tags
                                clearable
                                style="width: 100%"
                            >
                                <el-option
                                    v-for="item in this.dataChonDanToc_Store"
                                    :key="item.mappingCode"
                                    :label="item.constantTitle"
                                    :value="item.mappingCode"
                                />
                            </el-select>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">Tôn giáo <span>*</span></div>
                        <div class="col-8">
                            <el-select
                                v-model="selectedValue.selectedValue_tonGiao"
                                placeholder="Chọn"
                                size="small"
                                filterable
                                collapse-tags
                                clearable
                                style="width: 100%"
                            >
                                <el-option
                                    v-for="item in this.dataChonTonGiao_Store"
                                    :key="item.mappingCode"
                                    :label="item.constantTitle"
                                    :value="item.mappingCode"
                                />
                            </el-select>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">Nơi sinh</div>
                        <div class="col-8">
                            <el-input
                                type="textarea"
                                :autosize="{ minRows: 2, maxRows: 4 }"
                                placeholder="Nhập ..."
                                v-model="selectedValue.input_noiSinh"
                            >
                            </el-input>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">Quê quán</div>
                        <div class="col-8">
                            <el-input
                                type="textarea"
                                :autosize="{ minRows: 2, maxRows: 4 }"
                                placeholder="Nhập ..."
                                v-model="selectedValue.input_queQuan"
                            >
                            </el-input>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">Số sổ BHXH</div>
                        <div class="col-8">
                            <el-input
                                placeholder="Nhập ..."
                                v-model="selectedValue.input_soSoBHXH"
                                size="small"
                            ></el-input>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">Đoàn viên</div>
                        <div class="col-8">
                            <el-checkbox
                                v-model="selectedValue.checkbox_doanVien"
                            />
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">Đảng viên</div>
                        <div class="col-8">
                            <el-checkbox
                                v-model="selectedValue.checkbox_dangVien"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-top: 30px">
                <el-tabs type="card">
                    <el-tab-pane label="I. Vị trí việc làm">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-lg-4">
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Loại cán bộ <span>*</span>
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_loaiCanBo
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonLoaiCanBo_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">Môn dạy</div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_monDay
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonMonDay_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Môn dạy kiêm nhiệm
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_monDayKiemNghiem
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonMonDay_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">Nhóm chức vụ</div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_nhomChucVu
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonChucVu_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Hình thức hợp đồng
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_hinhThucHopDong
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonHinhThucHopDong_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">Ngày tuyển</div>
                                    <div class="col-7">
                                        <date-picker
                                            :config="configDatePicker"
                                            placeholder="Chọn ngày"
                                            v-model="selectedValue.date_ngayTuyen"
                                            class="custom-date-picker"
                                        >
                                        </date-picker>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Cơ quan tuyển dụng
                                    </div>
                                    <div class="col-7">
                                        <el-input
                                            placeholder="Nhập ..."
                                            v-model="
                                                selectedValue.input_coQuanTuyenDung
                                            "
                                            size="small"
                                        ></el-input>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Nghề nghiệp khi được tuyển dụng
                                    </div>
                                    <div class="col-7">
                                        <el-input
                                            placeholder="Nhập ..."
                                            v-model="
                                                selectedValue.input_ngheNghiepKhiDucTuyenDung
                                            "
                                            size="small"
                                        ></el-input>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-6 col-lg-4">
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Nhiệm vụ kiêm nhiệm
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_nhiemVuKiemNghiem
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonNhiemVuKiemNghiem_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Số tiết thực dạy trên tuần
                                    </div>
                                    <div class="col-7">
                                        <el-input
                                            placeholder="Nhập ..."
                                            v-model="
                                                selectedValue.input_soTietDayThucTrenTuan
                                            "
                                            size="small"
                                        ></el-input>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Số tiết thực kiêm nhiệm/tuần
                                    </div>
                                    <div class="col-7">
                                        <el-input
                                            placeholder="Nhập ..."
                                            v-model="
                                                selectedValue.input_soTietThucNghiemTrenTuan
                                            "
                                            size="small"
                                        ></el-input>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Đã tập huấn dạy kỹ năng sống (HIV,
                                        SKSS...)
                                    </div>
                                    <div class="col-7">
                                        <el-checkbox
                                            v-model="
                                                selectedValue.checkbox_daTapHuanKyNangSong
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Dạy HSKT học hòa nhập
                                    </div>
                                    <div class="col-7">
                                        <el-checkbox
                                            v-model="
                                                selectedValue.checkbox_dayHSKTHocHoaNhap
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Đang dạy 1 buổi/ngày
                                    </div>
                                    <div class="col-7">
                                        <el-checkbox
                                            v-model="
                                                selectedValue.checkbox_dangDay1BuoiNgay
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Đang dạy 2 buổi/ngày
                                    </div>
                                    <div class="col-7">
                                        <el-checkbox
                                            v-model="
                                                selectedValue.checkbox_dangDay2BuoiNgay
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Th.gia CT b.dưỡng T.xuyên
                                    </div>
                                    <div class="col-7">
                                        <el-checkbox
                                            v-model="
                                                selectedValue.checkbox_thamGiaCanBoBoiDuong
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">Ngạch/Hạng</div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_ngach_hang
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonNganhCC_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Th.gia CT b.dưỡng T.xuyên
                                    </div>
                                    <div class="col-7">
                                        <el-checkbox
                                            v-model="
                                                selectedValue.checkbox_coCCboiDuongTCCD
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="II. Phụ cấp">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-lg-6">
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-4 title">
                                        Mức phụ cấp thu hút nghề (%)
                                    </div>
                                    <div class="col-8">
                                        <el-input-number
                                            v-model="
                                                selectedValue.input_mucPhuCapThuHutNghe
                                            "
                                            controls-position="right"
                                            :precision="2"
                                            :step="0.01"
                                            :max="100"
                                            :min="0"
                                        >
                                        </el-input-number>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-4 title">
                                        Mức phụ cấp thâm niên (%)
                                    </div>
                                    <div class="col-8">
                                        <el-input-number
                                            v-model="
                                                selectedValue.input_mucPhuCapThamNien
                                            "
                                            controls-position="right"
                                            :precision="2"
                                            :step="0.01"
                                            :max="100"
                                            :min="0"
                                        >
                                        </el-input-number>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-4 title">
                                        Mức phụ cấp ưu đãi nghề (%)
                                    </div>
                                    <div class="col-8">
                                        <el-input-number
                                            v-model="
                                                selectedValue.input_mucPhuCapUuDaiNghe
                                            "
                                            controls-position="right"
                                            :precision="2"
                                            :step="0.01"
                                            :max="100"
                                            :min="0"
                                        >
                                        </el-input-number>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-4 title">
                                        Mức phụ cấp chức vụ lãnh đạo (%)
                                    </div>
                                    <div class="col-8">
                                        <el-input-number
                                            v-model="
                                                selectedValue.input_mucPhuCapChucVuLanhDao
                                            "
                                            controls-position="right"
                                            :precision="2"
                                            :step="0.01"
                                            :max="100"
                                            :min="0"
                                        >
                                        </el-input-number>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-4 title">
                                        Diễn biến quá trình lương
                                    </div>
                                    <div class="col-8">
                                        <CustomButton
                                            label="Chi tiết"
                                            size="small"
                                            class="btn-default btn-info"
                                            @click="
                                                handleOpenModalLstQuaTrinhLuong
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-6">
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-3 title">Bậc lương</div>
                                    <div class="col-9">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_bacLuong
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in getDataESelect.ESelect_bacLuong"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-3 title">
                                        Phần trăm vượt khung (%)
                                    </div>
                                    <div class="col-9">
                                        <el-input-number
                                            v-model="
                                                selectedValue.input_phamTramVuotKhung
                                            "
                                            controls-position="right"
                                            :precision="2"
                                            :step="0.01"
                                            :max="100"
                                            :min="0"
                                        >
                                        </el-input-number>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-3 title">Hệ số lương</div>
                                    <div class="col-9">
                                        <el-input-number
                                            v-model="
                                                selectedValue.input_heSoLuong
                                            "
                                            controls-position="right"
                                            :precision="2"
                                            :step="0.01"
                                            :max="100"
                                            :min="0"
                                        >
                                        </el-input-number>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-3 title">Ngày hưởng</div>
                                    <div class="col-4">
                                        <date-picker
                                            :config="configDatePicker"
                                            placeholder="Chọn ngày"
                                            v-model="selectedValue.date_ngayHuong"
                                            class="custom-date-picker"
                                        >
                                        </date-picker>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="III. Đào tạo, bồi dưỡng">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-lg-4">
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        KQ B.dưỡng t.xuyên
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_KQBoiDuongThuongXuyen
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonBoiDuongTX_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        T.độ c.môn n.vụ *
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_trinhDoChuyenMonNghiepVu
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonTrinhDoChuyenMonNV_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">T.độ LLCT</div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_trinhDoLLCT
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonTrinhDoLLCT_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">T.độ quản lý GD</div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_trinhDoQuanLyGD
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonTrinhDoQLGD_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Th.gia BD nghiệp vụ QLGD
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_thamGiaBoiDuongNghiepVuQLGD
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonBoiDuongNghiepVu_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Th.gia BD CBQL/GV cốt cán
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_thamGiaBoiDuongCBGVCotCan
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonBoiDuongCBQLCotCan_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Th.gia BD thay sách
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_thamGiaBoiDuongThaySach
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonBoiDuongThaySach_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">Ngoại ngữ chính</div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_ngoaiNguChinh
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonNgoainguchinh_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        T.độ Đ.tạo N.Ngữ
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_trinhDoDaoTaoNgoaiNgu
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonTrinhDoNgoaingu_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Nhóm C.Chỉ N.Ngữ
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_nhomChungChiNgoaiNgu
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonNhomCChiNNgu_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Loại C.Chỉ N.Ngữ
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_loaiChungChiNgoaiNgu
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonLoaiCChiNNgu_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">Điểm N.Ngữ</div>
                                    <div class="col-6 d-flex">
                                        <div>
                                            <el-input-number
                                                style="width: 90%"
                                                size="small"
                                                v-model="
                                                    selectedValue.input_diemNgoaiNgu
                                                "
                                                controls-position="right"
                                                :precision="2"
                                                :step="0.01"
                                                :max="100"
                                                :min="0"
                                            >
                                            </el-input-number>
                                        </div>
                                        <div>
                                            <CustomButton
                                                label="Chi tiết"
                                                class="btn-default btn-info"
                                                size="small"
                                                @click="
                                                    handleOpenModalLstQuaTrinhNgoaiNgu
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Khung N.Lực N.Ngữ
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_khungNangLucNgoaiNgu
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonKhungNLucNNgu_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">T.độ tin học</div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_trinhDoTinHoc
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonTrinhDoTinHoc_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Chuyên ngành chính
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_chuyenNganhChinh
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonChuyenMon_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">Trình độ chính</div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_trinhDoChinh
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this.getDataESelect
                                                    .ESelect_trinhDoChinh"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">Cơ sở đào tạo</div>
                                    <div class="col-7">
                                        <el-input
                                            placeholder="Nhập ..."
                                            v-model="
                                                selectedValue.input_coSoDaoTao
                                            "
                                            size="small"
                                        ></el-input>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Chuyên ngành khác
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_chuyenNganhKhac
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonChuyenMon_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">Trình độ khác</div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_trinhDoKhac
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this.getDataESelect
                                                    .ESelect_trinhDoKhac"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        G.viên có c.chỉ tiếng d.tộc t.số
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_giaoVienCoChungChiTiengDanToc
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonCChiDTocTSo_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Quá trình Đ.tạo B.dưỡng
                                    </div>
                                    <div class="col-7">
                                        <CustomButton
                                            label="Chi tiết"
                                            size="small"
                                            class="btn-default btn-info"
                                            @click="
                                                handleOpenModalLstQuaTrinhDaoTaoBoiDuong
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="IV. Đánh giá, phân loại">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-lg-4">
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Đánh giá viên chức/ công chức
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_danhGiaCongChucVienChuc
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonDGVienChuc_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Đ.giá chuẩn NN CBQL/GV
                                    </div>
                                    <div class="col-7">
                                        <CustomButton
                                            label=" Chi tiết"
                                            size="small"
                                            @click="
                                                handleOpenModalDanhGiaChuanNN_CBQL_GV
                                            "
                                            class="btn-default btn-info"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Giáo viên dạy giỏi
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_giaoVienDayGioi
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonDanhGiaGVGioi_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Giáo viên chủ nhiệm giỏi
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_giaoVienChuNhiemDayGioi
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonGVCNGioi_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Tổng phụ trách Đội giỏi
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_tongPhuTrachGioi
                                            "
                                            placeholder="Chọn"
                                            size="small"
                                            filterable
                                            collapse-tags
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonTongPhuTrachDoiGioi_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Danh hiệu phong tặng
                                    </div>
                                    <div class="col-7">
                                        <el-input
                                            placeholder="Nhập ..."
                                            v-model="
                                                selectedValue.input_danhHieuPhongtang
                                            "
                                            size="small"
                                        ></el-input>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">Khen thưởng</div>
                                    <div class="col-7">
                                        <CustomButton
                                            size="small"
                                            class="btn-default btn-info"
                                            @click="handleOpenModalLstKhenThuong"
                                            label="Chi tiết"
                                        />
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">Kỷ luật</div>
                                    <div class="col-7">
                                        <CustomButton
                                            size="small"
                                            class="btn-default btn-info"
                                            @click="handleOpenModalLstKyLuat"
                                            label="Chi tiết"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="layout-btn">
                <CustomButton
                    label="Đóng"
                    size="small"
                    class="btn-default btn-delete"
                    @click="closeModalCanboGv"
                />
                <CustomButton
                    label="Chỉnh sửa"
                    size="small"
                    class="btn-default btn-update"
                    @click="handleUpdate"
                />
            </div>
        </el-dialog>
        <ModalLstQuaTrinhLuong
            :lstQuaTrinhLuongModalVisible="lstQuaTrinhLuongModalVisible"
            :closeModalLstQuaTrinhLuong="closeModalLstQuaTrinhLuong"
            :lstQuaTrinhLuong="lstQuaTrinhLuong"
        />
        <ModalLstQuaTrinhNgoaiNgu
            :lstQuaTrinhNgoaiNguVisible="lstQuaTrinhNgoaiNguVisible"
            :closeModalLstQuaTrinhNgoaiNgu="closeModalLstQuaTrinhNgoaiNgu"
            :lstQuaTrinhNgoaiNgu="lstQuaTrinhNgoaiNgu"
        />
        <ModalLstQuaTrinhDaoTaoBoiDuong
            :lstQuaTrinhDaoTaoBoiDuongVisible="lstQuaTrinhDaoTaoBoiDuongVisible"
            :closeModallstQuaTrinhDaoTaoBoiDuong="
                closeModallstQuaTrinhDaoTaoBoiDuong
            "
            :lstQuaTrinhDaoTaoBoiDuong="lstQuaTrinhDaoTaoBoiDuong"
            :id_modalChiTietCanboGv="id_modalChiTietCanboGv"
        />
        <ModalDanhGiaChuanNN_CBQL_GV
            :danhGiaChuanNN_CBQL_GVVisible="danhGiaChuanNN_CBQL_GVVisible"
            :closeModalDanhGiaChuanNN_CBQL_GV="closeModalDanhGiaChuanNN_CBQL_GV"
            :danhGiaChuanNN_CBQL_GV="danhGiaChuanNN_CBQL_GV"
        />
        <ModalLstKhenThuong
            :lstKhenThuong="lstKhenThuong"
            :lstKhenThuongVisible="lstKhenThuongVisible"
            :closeModalLstKhenThuong="closeModalLstKhenThuong"
        />
        <ModalLstKyLuat
            :lstKyLuat="lstKyLuat"
            :lstKyLuatVisible="lstKyLuatVisible"
            :closeModalLstKyLuat="closeModalLstKyLuat"
        />
    </div>
</template>
<script>
import CardInfo from '@/components/CardInfo.vue'
import datePicker from 'vue-bootstrap-datetimepicker'
import ModalLstQuaTrinhLuong from './ModalLstQuaTrinhLuong.vue'
import ModalLstQuaTrinhNgoaiNgu from './ModalLstQuaTrinhNgoaiNgu.vue'
import ModalLstQuaTrinhDaoTaoBoiDuong from './ModalLstQuaTrinhDaoTaoBoiDuong.vue'
import ModalDanhGiaChuanNN_CBQL_GV from './ModalDanhGiaChuanNN_CBQL_GV.vue'
import ModalLstKhenThuong from './ModalLstKhenThuong.vue'
import ModalLstKyLuat from './ModalLstKyLuat.vue'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import CustomButton from '@/components/CustomButton.vue'
import Api from '@/constants/Api'
import sendRequest from '@/services'
import { mapState } from 'vuex'
import {
    ESelectGender_MockData_ModalChiTietHocSinh,
    ESelectTypeOfCadre_TraCuuCanBoGV_MockData,
    ESelectwage_TraCuuCanBoGV_MockData,
    ESlectMainLevel_MockData
} from '@/mock_data'
export default {
    name: 'ModalChitietCBGV',
    props: {
        chiTietCanboGvModalVisible: {
            type: Boolean,
            required: true
        },

        closeModalCanboGv: {
            type: Function,
            required: true
        },
        id_modalChiTietCanboGv: {
            type: Number,
            required: true
        },
        handleSearch: Function
    },
    components: {
        datePicker,
        CustomButton,
        ModalLstQuaTrinhLuong,
        ModalLstQuaTrinhNgoaiNgu,
        ModalLstQuaTrinhDaoTaoBoiDuong,
        ModalDanhGiaChuanNN_CBQL_GV,
        ModalLstKhenThuong,
        ModalLstKyLuat,
        CardInfo
    },
    data() {
        return {
            dataModal: {},
            lstQuaTrinhLuong: [],
            lstQuaTrinhLuongModalVisible: false,
            lstQuaTrinhNgoaiNgu: [],
            lstQuaTrinhNgoaiNguVisible: false,
            lstQuaTrinhDaoTaoBoiDuong: [],
            lstQuaTrinhDaoTaoBoiDuongVisible: false,
            danhGiaChuanNN_CBQL_GV: {},
            danhGiaChuanNN_CBQL_GVVisible: false,
            lstKhenThuong: [],
            lstKhenThuongVisible: false,
            lstKyLuat: [],
            lstKyLuatVisible: false,

            configDatePicker: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
                showTodayButton: true,
                locale: 'vi'
            },
            getDataESelect: {
                ESelect_gioiTinh: ESelectGender_MockData_ModalChiTietHocSinh, //gioitinh
                ESelect_trangThaiCB: [], //trangthaiCB
                ESelect_danToc: [], //dantoc
                ESelect_tonGiao: [], //tongiao
                ESelect_loaiCanbo: [], //loaicanbo
                ESelect_monDay: [], //monday
                ESelect_monDayKiemNghiem: [], //mondaykiemnghiem
                ESelect_chucVu: [], //chucvu
                ESelect_hinhThucHopDong: [], //hinhthuchopdong
                ESelect_nhiemVuKiemNghiem: [], //nhiemvukiemnghiem
                ESelect_ngach_hang: [], //ngach/hang
                ESelect_bacLuong: ESelectwage_TraCuuCanBoGV_MockData, //bacluong
                ESelect_KQBoiDuongThuongXuyen: [], //KQBoiduongthuongxuyen
                ESelect_trinhDoChuyenMonNghiepVu: [], //trinhdochuyenmonnghiepvu
                ESelect_trinhDoLLCT: [], //trinhdoLLCT
                ESelect_trinhDoQLGD: [], //trinhdoQLGD
                ESelect_thamGiaBDNghiepVuQLGD: [], //thamgiaBDnghiepvuQLGD
                ESelect_thamGiaBDCBQL_GVCotCan: [], //thamgiaBDCBQL_GVCotCan
                ESelect_thamGiaBDThaySach: [], //thamgianBDThaySach
                ESelect_ngoaiNguChinh: [], //ngoainguchinh
                ESelect_trinhDoDaoTaoNgoaiNgu: [], //trinhdodaotaongoaingu
                ESelect_nhomChungChiNgoaiNgu: [], //nhomchungchingoaingu
                ESelect_loaiChungChiNgoaiNgu: [], //loaichungchingoaingu
                ESelect_khungNhanLucNgoaiNgu: [], //khungnhanlucngoaingu
                ESelect_trinhDoTinHoc: [], //trinhdotinhoc
                ESelect_chuyenNganhChinh: [], //chuyennganhchinh
                ESelect_trinhDoChinh: ESlectMainLevel_MockData, //trinhdochinh
                ESelect_chuyenNganhKhac: [], //chuyennganhkhac
                ESelect_trinhDoKhac: ESlectMainLevel_MockData, //trinhdokhac
                ESelect_giaoVienCoChungChiTiengDanToc: [], //giaoviencochungchitiendantoc
                ESelect_vienChucCongChuc: [], //danhgiavienchuccongchuc
                ESelect_giaoVienDayGioi: [], //giaoviendaygioi
                ESelect_giaoVienChuNhiemGioi: [], //giaovienchunhiemgioi
                ESelect_tongPhuTrachDoiGioi: [] //tongphutrachdoigioi
            },
            selectedValue: {
                // <!-- ************ Hồ sơ học sinh  ************ -->
                input_hoTen: '', //hoten
                date_ngaySinh: '', //ngaysinh
                selectedValue_gioiTinh: [], //gioitinh
                selectedValue_trangThaiCB: [], //trangthaiCB
                date_ngayTrangThai: '', //ngayTrangthai
                checkbox_laTuyenMoi: false, //latuyenmoi
                input_CMTND_TCC: '', //CMTND/TCC
                date_ngayCap: '', //ngayCap
                input_email: '', //email
                input_maCanBo: '', //maCanBo
                selectedValue_danToc: [], //dantoc
                selectedValue_tonGiao: [], //tongiao
                input_noiSinh: '', //noisinh
                input_queQuan: '', //quequan
                input_soSoBHXH: '', //soSoBHXH
                checkbox_doanVien: '', //doanvien
                checkbox_dangVien: '', //dangvien
                // <!-- ************ Vị trí việc làm  ************ -->
                selectedValue_loaiCanBo: [], //loaiCanbo
                selectedValue_monDay: [], //monday
                selectedValue_monDayKiemNghiem: [], //mondaykiemnghiem
                selectedValue_nhomChucVu: [], //nhomchucvu
                selectedValue_hinhThucHopDong: [], //hinhthuchopdong
                date_ngayTuyen: '', //ngayTuyen
                input_coQuanTuyenDung: '', //coquantuyendung
                input_ngheNghiepKhiDucTuyenDung: '', //nghenghiepkhiduoctuyendung
                selectedValue_nhiemVuKiemNghiem: [], //nhiemvukiemnghiem
                input_soTietDayThucTrenTuan: '', //sotietdaythuctrentuan
                input_soTietThucNghiemTrenTuan: '', //sotietThucNghiemtrentuan
                checkbox_daTapHuanKyNangSong: false, //dataphuankynangsong
                checkbox_dayHSKTHocHoaNhap: false, //dayHSKThochoanhap
                checkbox_dangDay1BuoiNgay: false, //dangday1buoitrenngay
                checkbox_dangDay2BuoiNgay: false, //dangday2buoitrenngay
                checkbox_thamGiaCanBoBoiDuong: false, //thamgiacanboboiduong
                selectedValue_ngach_hang: [], //ngach/hang
                checkbox_coCCboiDuongTCCD: false, //coCCboiduongTCCD

                // <!-- ************ Phụ cấp ************ -->
                input_mucPhuCapThuHutNghe: '',
                input_mucPhuCapThamNien: '',
                input_mucPhuCapUuDaiNghe: '',
                input_mucPhuCapChucVuLanhDao: '',
                selectedValue_bacLuong: [],
                input_phamTramVuotKhung: '',
                input_heSoLuong: '',
                date_ngayHuong: '',

                // <!-- ************ Đào tạo, bồi dưỡng ************ -->
                selectedValue_KQBoiDuongThuongXuyen: [],
                selectedValue_trinhDoChuyenMonNghiepVu: [],
                selectedValue_trinhDoLLCT: [],
                selectedValue_trinhDoQuanLyGD: [],
                selectedValue_thamGiaBoiDuongNghiepVuQLGD: [],
                selectedValue_thamGiaBoiDuongCBGVCotCan: [],
                selectedValue_thamGiaBoiDuongThaySach: [],
                selectedValue_ngoaiNguChinh: [],
                selectedValue_trinhDoDaoTaoNgoaiNgu: [],
                selectedValue_nhomChungChiNgoaiNgu: [],
                selectedValue_loaiChungChiNgoaiNgu: [],
                input_diemNgoaiNgu: '',
                selectedValue_khungNangLucNgoaiNgu: [],
                selectedValue_trinhDoTinHoc: [],
                selectedValue_chuyenNganhChinh: [],
                selectedValue_trinhDoChinh: [],
                input_coSoDaoTao: '',
                selectedValue_chuyenNganhKhac: [],
                selectedValue_trinhDoKhac: [],
                selectedValue_giaoVienCoChungChiTiengDanToc: [],

                // <!-- ************ Đánh giá, phân loại ************ -->
                selectedValue_danhGiaCongChucVienChuc: [],
                selectedValue_giaoVienDayGioi: [],
                selectedValue_giaoVienChuNhiemDayGioi: [],
                selectedValue_tongPhuTrachGioi: [],
                input_danhHieuPhongtang: ''
            }
        }
    },

    watch: {
        async id_modalChiTietCanboGv(newValue) {
            await this.getDataThongTinCanboGv(newValue)
        }
    },
    methods: {
        handleOpenModalLstQuaTrinhLuong() {
            this.lstQuaTrinhLuongModalVisible = true
        },
        closeModalLstQuaTrinhLuong() {
            this.lstQuaTrinhLuongModalVisible = false
        },
        handleOpenModalLstQuaTrinhNgoaiNgu() {
            this.lstQuaTrinhNgoaiNguVisible = true
        },
        closeModalLstQuaTrinhNgoaiNgu() {
            this.lstQuaTrinhNgoaiNguVisible = false
        },

        handleOpenModalLstQuaTrinhDaoTaoBoiDuong() {
            this.lstQuaTrinhDaoTaoBoiDuongVisible = true
        },
        closeModallstQuaTrinhDaoTaoBoiDuong() {
            this.lstQuaTrinhDaoTaoBoiDuongVisible = false
        },
        handleOpenModalDanhGiaChuanNN_CBQL_GV() {
            this.danhGiaChuanNN_CBQL_GVVisible = true
        },
        closeModalDanhGiaChuanNN_CBQL_GV() {
            this.danhGiaChuanNN_CBQL_GVVisible = false
        },
        handleOpenModalLstKhenThuong() {
            this.lstKhenThuongVisible = true
        },
        closeModalLstKhenThuong() {
            this.lstKhenThuongVisible = false
        },
        handleOpenModalLstKyLuat() {
            this.lstKyLuatVisible = true
        },
        closeModalLstKyLuat() {
            this.lstKyLuatVisible = false
        },
        async getDataThongTinCanboGv(newValue) {
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    canBoTheoNamId: newValue
                }
                const response = await sendRequest(
                    Api.traCuu.traCuuCanBoGiaoVien.chitietCanboGv,
                    null,
                    request_Header,
                    request_Params
                )
                this.dataModal = response
                this.lstQuaTrinhLuong = JSON.parse(response.lstQuaTrinhLuong)
                this.lstQuaTrinhNgoaiNgu = JSON.parse(
                    response.lstQuaTrinhNgoaiNgu
                )
                this.lstQuaTrinhDaoTaoBoiDuong = JSON.parse(
                    response.lstQuaTrinhDaoTaoBoiDuong
                )
                this.danhGiaChuanNN_CBQL_GV = response
                this.lstKhenThuong = response.lstKhenThuongs
                this.lstKyLuat = response.lstKyLuats
                // <!-- ************ Hồ sơ học sinh  ************ -->
                this.selectedValue.input_hoTen = response.tenGiaoVien
                this.selectedValue.date_ngaySinh = response.ngaySinh
                this.selectedValue.selectedValue_gioiTinh =
                    response.gioiTinh === 0 ? 0 : 1
                const convertSelectedValue_trangThaiCB =
                    this.convertSelectedValue_dataStore(
                        this.dataChonTrangthaicanbo_Store,
                        response.trangThai
                    )
                this.selectedValue.selectedValue_trangThaiCB =
                    convertSelectedValue_trangThaiCB
                this.selectedValue.checkbox_laTuyenMoi = response.tuyenMoi
                this.selectedValue.input_CMTND_TCC = response.soCmnd
                this.selectedValue.date_ngayCap = response.ngayCapCmnd
                this.selectedValue.input_email = response.email
                this.selectedValue.input_maCanBo = response.maGiaoVien

                this.selectedValue.selectedValue_danToc =
                    this.convertSelectedValue_dataStore(
                        this.dataChonDanToc_Store,
                        response.maDanToc
                    )

                this.selectedValue.selectedValue_tonGiao =
                    this.convertSelectedValue_dataStore(
                        this.dataChonTonGiao_Store,
                        response.maTonGiao
                    )
                this.selectedValue.input_noiSinh = response.noiSinh
                this.selectedValue.input_queQuan = response.queQuan
                this.selectedValue.input_soSoBHXH = response.soSoBhxh
                this.selectedValue.checkbox_doanVien =
                    response.doanVien === 0 ? false : true
                this.selectedValue.checkbox_dangVien =
                    response.dangVien === 0 ? false : true

                // <!-- ************ Vị trí việc làm  ************ -->
                this.selectedValue.selectedValue_loaiCanBo =
                    this.convertSelectedValue_dataStore(
                        this.dataChonLoaiCanBo_Store,
                        response.loaiCanBo
                    )
                this.selectedValue.selectedValue_monDay =
                    this.convertSelectedValue_dataStore(
                        this.dataChonMonDay_Store,
                        response.maMonHoc
                    )
                this.selectedValue.selectedValue_monDayKiemNghiem =
                    this.convertSelectedValue_dataStore(
                        this.dataChonMonDay_Store,
                        response.monDayKiemNhiem
                    )
                this.selectedValue.selectedValue_nhomChucVu =
                    this.convertSelectedValue_dataStore(
                        this.dataChonChucVu_Store,
                        response.chucVu
                    )
                this.selectedValue.selectedValue_hinhThucHopDong =
                    this.convertSelectedValue_dataStore(
                        this.dataChonHinhThucHopDong_Store,
                        response.loaiHopDong
                    )
                this.selectedValue.date_ngayTuyen = response.ngayTuyenDung
                this.selectedValue.input_coQuanTuyenDung =
                    response.coQuanTuyenDung
                this.selectedValue.input_ngheNghiepKhiDucTuyenDung =
                    response.ngheNghiepTuyenDung
                this.selectedValue.selectedValue_nhiemVuKiemNghiem =
                    this.convertSelectedValue_dataStore(
                        this.dataChonNhiemVuKiemNghiem_Store,
                        response.nhiemVuKiemNhiem
                    )
                this.selectedValue.input_soTietDayThucTrenTuan =
                    response.soTietThucDay1Tuan
                this.selectedValue.input_soTietThucNghiemTrenTuan =
                    response.soTietKiemNhiem1Tuan
                this.selectedValue.checkbox_daTapHuanKyNangSong =
                    response.tapHuanKnsSkss === 0 ? false : true
                this.selectedValue.checkbox_dayHSKTHocHoaNhap =
                    response.dayHsktHocHoaNhap === 0 ? false : true
                this.selectedValue.checkbox_dangDay1BuoiNgay =
                    response.day1BuoiTrenNgay === 0 ? false : true
                this.selectedValue.checkbox_dangDay2BuoiNgay =
                    response.day2BuoiTrenNgay === 0 ? false : true
                this.selectedValue.checkbox_thamGiaCanBoBoiDuong =
                    response.thamGiaChuongTrinhBoiDuong
                this.selectedValue.selectedValue_ngach_hang =
                    this.convertSelectedValue_dataStore(
                        this.dataChonNganhCC_Store,
                        response.maNgach
                    )
                this.selectedValue.checkbox_coCCboiDuongTCCD =
                    response.coCcBdChucDanhNgheNghiep

                // <!-- ************ Phụ cấp  ************ -->
                this.selectedValue.input_mucPhuCapThuHutNghe =
                    response.mucPhuCapThuHut
                this.selectedValue.input_mucPhuCapThamNien =
                    response.mucPhuCapThamNien
                this.selectedValue.input_mucPhuCapUuDaiNghe =
                    response.mucPhuCapUuDai
                this.selectedValue.input_mucPhuCapChucVuLanhDao =
                    response.mucPhuCapLanhDao
                this.selectedValue.selectedValue_bacLuong =
                    this.convertSelectedValue_dataStore(
                        ESelectwage_TraCuuCanBoGV_MockData,
                        response.bacLuong
                    )
                const quaTrinhLuongs = response.quaTrinhLuongs
                this.selectedValue.input_phamTramVuotKhung = quaTrinhLuongs.map(
                    (item) => item.phanTramVuotKhung
                )
                this.selectedValue.input_heSoLuong = quaTrinhLuongs.map(
                    (item) => item.heSoLuong
                )
                this.selectedValue.date_ngayHuong = quaTrinhLuongs
                    ?.map((item) => item.ngayThangNam)
                    ?.pop()

                // <!-- ************ Đào tạo, bồi dưỡng  ************ -->
                this.selectedValue.selectedValue_KQBoiDuongThuongXuyen =
                    this.convertSelectedValue_dataStore(
                        this.dataChonBoiDuongTX_Store,
                        response.kqBdTx
                    )
                this.selectedValue.selectedValue_trinhDoChuyenMonNghiepVu =
                    this.convertSelectedValue_dataStore(
                        this.dataChonTrinhDoChuyenMonNV_Store,
                        response.trinhDoChuyenMonCaoNhat
                    )
                this.selectedValue.selectedValue_trinhDoLLCT =
                    this.convertSelectedValue_dataStore(
                        this.dataChonTrinhDoLLCT_Store,
                        response.trinhDoLyLuanChinhTri
                    )

                this.selectedValue.selectedValue_trinhDoQuanLyGD =
                    this.convertSelectedValue_dataStore(
                        this.dataChonTrinhDoQLGD_Store,
                        response.trinhDoQuanLyGiaoDuc
                    )
                this.selectedValue.selectedValue_thamGiaBoiDuongNghiepVuQLGD =
                    this.convertSelectedValue_dataStore(
                        this.dataChonBoiDuongNghiepVu_Store,
                        response.maBoiDuongNvu
                    )
                this.selectedValue.selectedValue_thamGiaBoiDuongCBGVCotCan =
                    this.convertSelectedValue_dataStore(
                        this.dataChonBoiDuongCBQLCotCan_Store,
                        response.maBoiDuongCbqlCotCan
                    )
                this.selectedValue.selectedValue_thamGiaBoiDuongThaySach =
                    this.convertSelectedValue_dataStore(
                        this.dataChonBoiDuongThaySach_Store,
                        response.maBoiDuongThaySach
                    )
                this.selectedValue.selectedValue_ngoaiNguChinh =
                    this.convertSelectedValue_dataStore(
                        this.dataChonNgoainguchinh_Store,
                        response.monNgoaiNguChinh
                    )
                this.selectedValue.selectedValue_trinhDoDaoTaoNgoaiNgu =
                    this.convertSelectedValue_dataStore(
                        this.dataChonTrinhDoNgoaingu_Store,
                        response.trinhDoNgoaiNgu
                    )
                this.selectedValue.selectedValue_nhomChungChiNgoaiNgu =
                    this.convertSelectedValue_dataStore(
                        this.dataChonNhomCChiNNgu_Store,
                        response.maNhomCchiNngu
                    )
                this.selectedValue.selectedValue_loaiChungChiNgoaiNgu =
                    this.convertSelectedValue_dataStore(
                        this.dataChonLoaiCChiNNgu_Store,
                        response.maLoaiCchiNngu
                    )
                this.selectedValue.input_diemNgoaiNgu = response.diemNgoaiNgu
                this.selectedValue.selectedValue_khungNangLucNgoaiNgu =
                    this.convertSelectedValue_dataStore(
                        this.dataChonKhungNLucNNgu_Store,
                        response.maKhungNlucNngu
                    )
                this.selectedValue.selectedValue_trinhDoTinHoc =
                    this.convertSelectedValue_dataStore(
                        this.dataChonTrinhDoTinHoc_Store,
                        response.trinhDoTinHoc
                    )
                this.selectedValue.selectedValue_chuyenNganhChinh =
                    this.convertSelectedValue_dataStore(
                        this.dataChonChuyenMon_Store,
                        response.chuyenNganhDaoTaoChinh
                    )
                this.selectedValue.selectedValue_trinhDoChinh =
                    this.convertSelectedValue_dataStore(
                        ESlectMainLevel_MockData,
                        response.trinhDoDaoTaoCnChinh
                    )
                this.selectedValue.input_coSoDaoTao = response.tenCsDaoTaoBd
                this.selectedValue.selectedValue_chuyenNganhKhac =
                    this.convertSelectedValue_dataStore(
                        this.dataChonChuyenMon_Store,
                        response.chuyenNganhDaoTaoKhac
                    )
                this.selectedValue.selectedValue_trinhDoKhac =
                    this.convertSelectedValue_dataStore(
                        ESlectMainLevel_MockData,
                        response.trinhDoDaoTaoCnKhac
                    )
                this.selectedValue.selectedValue_giaoVienCoChungChiTiengDanToc =
                    this.convertSelectedValue_dataStore(
                        this.dataChonCChiDTocTSo_Store,
                        response.maChungChiTiengDtocTso
                    )
                // <!-- ************ Đánh giá, phân loại  ************ -->
                this.selectedValue.selectedValue_danhGiaCongChucVienChuc =
                    this.convertSelectedValue_dataStore(
                        this.dataChonDGVienChuc_Store,
                        response.danhGiaVienChuc
                    )
                this.selectedValue.selectedValue_giaoVienDayGioi =
                    this.convertSelectedValue_dataStore(
                        this.dataChonDanhGiaGVGioi_Store,
                        response.gvDayGioi
                    )
                this.selectedValue.selectedValue_giaoVienChuNhiemDayGioi =
                    this.convertSelectedValue_dataStore(
                        this.dataChonGVCNGioi_Store,
                        response.maGiaoVienChuNhiemGioi
                    )
                this.selectedValue.selectedValue_tongPhuTrachGioi =
                    this.convertSelectedValue_dataStore(
                        this.dataChonTongPhuTrachDoiGioi_Store,
                        response.maGvTongPtrachDoiGioi
                    )
                this.selectedValue.input_danhHieuPhongtang =
                    response.danhHieuPhongTang
            } catch (error) {
                console.log(error)
            }
        },
        async handleUpdate() {
            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                updateGiaoVienRequest: {
                    anhChanDung: this.dataModal.anhChanDung,
                    anhThe: this.dataModal.anhThe,
                    avatar: this.dataModal.avatar,
                    bacLuong: this.selectedValue.selectedValue_bacLuong,
                    canBoTheoNamDanhGiaKqChuanNgheNghiepEntities:
                        this.dataModal
                            .canBoTheoNamDanhGiaKqChuanNgheNghiepEntities,
                    canBoTheoNamId: this.dataModal.canBoTheoNamId,
                    canBoTheoNamQtbdNcnlNgoainguList:
                        this.dataModal.canBoTheoNamQtbdNcnlNgoainguList,
                    canBoTheoNamQtbdNcnlSuphamList:
                        this.dataModal.canBoTheoNamQtbdNcnlSuphamList,
                    capHoc: this.dataModal.capHoc,
                    capKhenThuong: this.dataModal.capKhenThuong,
                    capKyLuat: this.dataModal.capKyLuat,
                    chucVu: this.selectedValue.selectedValue_nhomChucVu,
                    chuyenNganhDaoTaoChinh:
                        this.selectedValue.selectedValue_chuyenNganhChinh,
                    chuyenNganhDaoTaoKhac:
                        this.selectedValue.selectedValue_chuyenNganhKhac,
                    chuyenTrachDoi: this.dataModal.chuyenTrachDoi,
                    coCcBdChucDanhNgheNghiep:
                        this.selectedValue.checkbox_coCCboiDuongTCCD,
                    coQuanTuyenDung: this.selectedValue.input_coQuanTuyenDung,
                    dangVien: this.convertCheckboxValueToNumber(
                        this.selectedValue.checkbox_dangVien
                    ),
                    danhGiaVienChuc:
                        this.selectedValue.selectedValue_danhGiaCongChucVienChuc,
                    danhHieuPhongTang: this.selectedValue.input_danhHieuPhongtang,
                    day1BuoiTrenNgay: this.convertCheckboxValueToNumber(
                        this.selectedValue.checkbox_dangDay1BuoiNgay
                    ),
                    day2BuoiTrenNgay: this.convertCheckboxValueToNumber(
                        this.selectedValue.checkbox_dangDay2BuoiNgay
                    ),
                    dayHsktHocHoaNhap: this.convertCheckboxValueToNumber(
                        this.selectedValue.checkbox_dayHSKTHocHoaNhap
                    ),
                    dayNhomLop: this.dataModal.dayNhomLop,
                    dayNhomLopKiemNhiem: this.dataModal.dayNhomLopKiemNhiem,
                    dchiThtru: this.dataModal.dchiThtru,
                    dchiTmtru: this.dataModal.dchiTmtru,
                    dgKqChuanNgheNghiep: this.dataModal.dgKqChuanNgheNghiep,
                    diemNgoaiNgu: this.selectedValue.input_diemNgoaiNgu,
                    doanVien: this.convertCheckboxValueToNumber(
                        this.selectedValue.checkbox_doanVien
                    ),
                    doiVien: this.dataModal.doiVien,
                    dongBoLoId: this.dataModal.dongBoLoId,
                    dongBoThuTu: this.dataModal.dongBoThuTu,
                    email: this.selectedValue.input_email,
                    gioiTinh: this.selectedValue.selectedValue_gioiTinh,
                    gvDayGioi: this.selectedValue.selectedValue_giaoVienDayGioi,
                    hash: this.dataModal.hash,
                    heSo: this.dataModal.heSo,
                    heSoLuong: this.dataModal.heSoLuong,
                    hinhThucDaoTao: this.dataModal.hinhThucDaoTao,
                    hoTenBo: this.dataModal.hoTenBo,
                    hoTenMe: this.dataModal.hoTenMe,
                    hoTenVoChong: this.dataModal.hoTenVoChong,
                    kqBdTx: this.selectedValue
                        .selectedValue_KQBoiDuongThuongXuyen,
                    loaiCanBo: this.selectedValue.selectedValue_loaiCanBo,
                    loaiGiaoVien: this.dataModal.loaiGiaoVien,
                    loaiHopDong: this.selectedValue.selectedValue_hinhThucHopDong,
                    lopHocs: this.dataModal.lopHocs,
                    lstKhenThuong: this.dataModal.lstKhenThuong,
                    lstKhenThuongs: this.dataModal.lstKhenThuongs,
                    lstKyLuat: this.dataModal.lstKyLuat,
                    lstKyLuats: this.dataModal.lstKyLuats,
                    lstNhiemVuKiemNhiem: this.dataModal.lstNhiemVuKiemNhiem,
                    lstQuaTrinhBdncnlNgoaiNgu:
                        this.dataModal.lstQuaTrinhBdncnlNgoaiNgu,
                    lstQuaTrinhBdncnlSuPham:
                        this.dataModal.lstQuaTrinhBdncnlSuPham,
                    lstQuaTrinhDaoTaoBoiDuong:
                        this.dataModal.lstQuaTrinhDaoTaoBoiDuong,
                    lstQuaTrinhLuong: this.dataModal.lstQuaTrinhLuong,
                    lstQuaTrinhNgoaiNgu: this.dataModal.lstQuaTrinhNgoaiNgu,
                    lyDoNghiViec: this.dataModal.lyDoNghiViec,
                    maBoiDuongCbqlCotCan:
                        this.selectedValue
                            .selectedValue_thamGiaBoiDuongCBGVCotCan,
                    maBoiDuongNvu:
                        this.selectedValue
                            .selectedValue_thamGiaBoiDuongNghiepVuQLGD,
                    maBoiDuongThaySach:
                        this.selectedValue.selectedValue_thamGiaBoiDuongThaySach,
                    maChungChiTiengDtocTso:
                        this.selectedValue
                            .selectedValue_giaoVienCoChungChiTiengDanToc,
                    maChuyenNganhDaoTao: this.dataModal.maChuyenNganhDaoTao,
                    maDanToc: this.selectedValue.selectedValue_danToc,
                    maDoiTac: this.dataModal.maDoiTac,
                    maGiaoVien: this.dataModal.maGiaoVien,
                    maGiaoVienChuNhiemGioi:
                        this.selectedValue.selectedValue_giaoVienChuNhiemDayGioi,
                    maGvTongPtrachDoiGioi:
                        this.selectedValue.selectedValue_tongPhuTrachGioi,
                    maKhenThuongGiaoVien: this.dataModal.maKhenThuongGiaoVien,
                    maKhungNlucNngu:
                        this.selectedValue.selectedValue_khungNangLucNgoaiNgu,
                    maKyLuatGiaoVien: this.dataModal.maKyLuatGiaoVien,
                    maLoaiCchiNngu:
                        this.selectedValue.selectedValue_loaiChungChiNgoaiNgu,
                    maLoiSync: this.dataModal.maLoiSync,
                    maMonHoc: this.selectedValue.selectedValue_monDay,
                    maNgach: this.selectedValue.selectedValue_ngach_hang,
                    maNhomCchiNngu:
                        this.selectedValue.selectedValue_nhomChungChiNgoaiNgu,
                    maPxaThtru: this.dataModal.maPxaThtru,
                    maPxaTmtru: this.dataModal.maPxaTmtru,
                    maQhuyenThtru: this.dataModal.maQhuyenThtru,
                    maQhuyenTmtru: this.dataModal.maQhuyenTmtru,
                    maTinhThanh: this.dataModal.maTinhThanh,
                    maTonGiao: this.selectedValue.selectedValue_tonGiao,
                    maTruongHoc: this.dataModal.maTruongHoc,
                    maTthanhThtru: this.dataModal.maTthanhThtru,
                    maTthanhTmtru: this.dataModal.maTthanhTmtru,
                    maTthonThtru: this.dataModal.maTthonThtru,
                    maTthonTmtru: this.dataModal.maTthanhTmtru,
                    maVanBanChungChiDaoTao: this.dataModal.maVanBanChungChiDaoTao,
                    moetCode: this.dataModal.moetCode,
                    monDayChinh: this.dataModal.monDayChinh,
                    monDayKiemNhiem:
                        this.selectedValue.selectedValue_monDayKiemNghiem,
                    monNgoaiNguChinh:
                        this.selectedValue.selectedValue_ngoaiNguChinh,
                    mucPhuCapLanhDao:
                        this.selectedValue.input_mucPhuCapChucVuLanhDao,
                    mucPhuCapThamNien: this.selectedValue.input_mucPhuCapThamNien,
                    mucPhuCapThuHut: this.selectedValue.input_mucPhuCapThuHutNghe,
                    mucPhuCapUuDai: this.selectedValue.input_mucPhuCapUuDaiNghe,
                    namGioiCapHuyen: this.dataModal.namGioiCapHuyen,
                    namGioiCapTinh: this.dataModal.namGioiCapTinh,
                    namGioiCapTruong: this.dataModal.namGioiCapTruong,
                    namHoc: this.dataModal.namHoc,
                    namSinh: this.dataModal.namSinh,
                    namVaoTruong: this.dataModal.namVaoTruong,
                    ngachHang: this.dataModal.ngachHang,
                    ngayBatDauDaoTao: this.dataModal.ngayBatDauDaoTao,
                    ngayCapCmnd: this.selectedValue.date_ngayCap,
                    ngayCapNhat: this.dataModal.ngayCapNhat,
                    ngayChuyenDen: this.dataModal.ngayChuyenDen,
                    ngayHuong: this.dataModal.ngayHuong,
                    ngayKetThucDaoTao: this.dataModal.ngayKetThucDaoTao,
                    ngayKhenThuong: this.dataModal.ngayKhenThuong,
                    ngayKyLuat: this.dataModal.ngayKyLuat,
                    ngaySinh: this.selectedValue.date_ngaySinh,
                    ngaySinhBo: this.dataModal.ngaySinhBo,
                    ngaySinhMe: this.dataModal.ngaySinhMe,
                    ngaySinhVoChong: this.dataModal.ngaySinhVoChong,
                    ngayTrangThai: this.dataModal.ngayTrangThai,
                    ngayTuyenDung: this.selectedValue.date_ngayTuyen,
                    ngayVaoBienChe: this.dataModal.ngayVaoBienChe,
                    ngayVaoDang: this.dataModal.ngayVaoDang,
                    ngayVaoDoan: this.dataModal.ngayVaoDoan,
                    ngayVaoDoi: this.dataModal.ngayVaoDoi,
                    ngayVaoNghe: this.dataModal.ngayVaoNghe,
                    ngheNghiepBo: this.dataModal.ngheNghiepBo,
                    ngheNghiepMe: this.dataModal.ngheNghiepMe,
                    ngheNghiepTuyenDung:
                        this.selectedValue.input_ngheNghiepKhiDucTuyenDung,
                    ngheNghiepVoChong: this.dataModal.ngheNghiepVoChong,
                    nhiemVuKiemNhiem:
                        this.selectedValue.selectedValue_nhiemVuKiemNghiem,
                    nhomMau: this.dataModal.nhomMau,
                    noiCapCmnd: this.dataModal.noiCapCmnd,
                    noiDungKhenThuong: this.dataModal.noiDungKhenThuong,
                    noiLamViecBo: this.dataModal.noiLamViecBo,
                    noiLamViecMe: this.dataModal.noiLamViecMe,
                    noiLamViecVoChong: this.dataModal.noiLamViecVoChong,
                    noiSinh: this.selectedValue.input_noiSinh,
                    noiVaoDang: this.dataModal.noiVaoDang,
                    noiVaoDoan: this.dataModal.noiVaoDoan,
                    noiVaoDoi: this.dataModal.noiVaoDoi,
                    qlntCode: this.selectedValue.qlntCode,
                    quaTrinhDaoTaoBoiDuongs:
                        this.dataModal.quaTrinhDaoTaoBoiDuongs,
                    quaTrinhLuongs: [
                        {
                            ngayThangNam: this.selectedValue.date_ngayHuong,
                            maNgach: this.dataModal.maNgach,
                            maBacLuong: this.dataModal.maBacLuong,
                            heSoLuong: this.selectedValue.input_heSoLuong
                        }
                    ],
                    quaTrinhNgoaiNgus: this.dataModal.quaTrinhNgoaiNgus,
                    queQuan: this.selectedValue.input_queQuan,
                    reqMesId: this.dataModal.reqMesId,
                    soCmnd: this.selectedValue.input_CMTND_TCC,
                    soDienThoai: this.dataModal.soDienThoai,
                    soDienThoaiBo: this.dataModal.soDienThoaiBo,
                    soDienThoaiCoDinh: this.dataModal.soDienThoaiCoDinh,
                    soDienThoaiDiDong: this.dataModal.soDienThoaiDiDong,
                    soDienThoaiMe: this.dataModal.soDienThoaiMe,
                    soDienThoaiVoChong: this.dataModal.soDienThoaiVoChong,
                    soQuyetDinhKhenThuong: this.dataModal.soQuyetDinhKhenThuong,
                    soQuyetDinhKyLuat: this.dataModal.soQuyetDinhKyLuat,
                    soSoBhxh: this.selectedValue.input_soSoBHXH,
                    soTietKiemNhiem1Tuan:
                        this.selectedValue.input_soTietThucNghiemTrenTuan,
                    soTietThucDay1Tuan:
                        this.selectedValue.input_soTietDayThucTrenTuan,
                    sync: this.dataModal.sync,
                    syncLanDay: this.dataModal.syncLanDay,
                    syncMaDoiTac: this.dataModal.syncMaDoiTac,
                    syncMaPhongGd: this.dataModal.syncMaPhongGd,
                    syncMaSoGd: this.dataModal.syncMaSoGd,
                    syncMaTruongHoc: this.dataModal.syncMaTruongHoc,
                    syncNgayCapNhat: this.dataModal.syncNgayCapNhat,
                    syncVersion: this.dataModal.syncVersion,
                    tapHuanKnsSkss: this.convertCheckboxValueToNumber(
                        this.selectedValue.checkbox_daTapHuanKyNangSong
                    ),
                    tenBacLuong: this.dataModal.tenBacLuong,
                    tenCanBo: this.selectedValue.input_hoTen,
                    tenCsDaoTaoBd: this.selectedValue.input_coSoDaoTao,
                    tenGiaoVien: this.selectedValue.input_hoTen,
                    tenGvDayGioi: this.dataModal.tenGvDayGioi,
                    tenKqBdTx: this.dataModal.tenKqBdTx,
                    tenLoaiCanBo: this.dataModal.tenLoaiCanBo,
                    tenNhiemVuKiemNhiem: this.dataModal.tenNhiemVuKiemNhiem,
                    tenTrangThai: this.dataModal.tenTrangThai,
                    tenTrinhDoDaoTaoCnChinh:
                        this.dataModal.tenTrinhDoDaoTaoCnChinh,
                    tenTrinhDoDaoTaoCnKhac: this.dataModal.tenTrinhDoDaoTaoCnKhac,
                    thamGiaBdTxuyen: this.dataModal.thamGiaBdTxuyen,
                    thamGiaChuongTrinhBoiDuong:
                        this.selectedValue.checkbox_thamGiaCanBoBoiDuong,
                    thanhPhanGiaDinh: this.dataModal.thanhPhanGiaDinh,
                    tinhTrangHonNhan: this.dataModal.tinhTrangHonNhan,
                    tinhTrangSucKhoe: this.dataModal.tinhTrangSucKhoe,
                    trangThai: this.selectedValue.selectedValue_trangThaiCB,
                    trinhDoChuyenMonCaoNhat:
                        this.selectedValue.selectedValue_trinhDoChuyenMonNghiepVu,
                    trinhDoDTCNChinh: this.dataModal.trinhDoDTCNChinh,
                    trinhDoDTCNKhac: this.dataModal.trinhDoDTCNKhac,
                    trinhDoDaoTao: this.dataModal.trinhDoDaoTao,
                    trinhDoDaoTaoCnChinh:
                        this.selectedValue.selectedValue_trinhDoChinh,
                    trinhDoDaoTaoCnKhac:
                        this.selectedValue.selectedValue_trinhDoKhac,
                    trinhDoGvien: this.dataModal.trinhDoGvien,
                    trinhDoLyLuanChinhTri:
                        this.selectedValue.selectedValue_trinhDoLLCT,
                    trinhDoNgoaiNgu:
                        this.selectedValue.selectedValue_trinhDoDaoTaoNgoaiNgu,
                    trinhDoQuanLyGiaoDuc:
                        this.selectedValue.selectedValue_trinhDoQuanLyGD,
                    trinhDoQuanLyNhaNuoc: this.dataModal.trinhDoQuanLyNhaNuoc,
                    trinhDoTinHoc: this.selectedValue.selectedValue_trinhDoTinHoc,
                    trinhDoVanHoa: this.dataModal.trinhDoVanHoa,
                    tuDanhGiaKqChuanNgheNghiep:
                        this.dataModal.tuDanhGiaKqChuanNgheNghiep,
                    tuyenMoi: this.selectedValue.checkbox_laTuyenMoi,
                    vanBanChungChiDaoTao: this.dataModal.vanBanChungChiDaoTao,
                    vuotKhung: this.dataModal.vuotKhung
                }
            }

            this.$confirm('Xác nhận cập nhật bản ghi đã chọn?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    })
                    const response = await sendRequest(
                        Api.traCuu.traCuuCanBoGiaoVien.updateCanBoGv,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        loading.close()
                        this.$message({
                            type: 'success',
                            message: 'Cập nhật thành công'
                        })
                        this.$emit('handleSearch')

                        await this.closeModalCanboGv() // Gọi lại hàm closeModalChitietHocSinh
                    }
                    if (response.rc != 0) {
                        loading.close()
                        this.$message({
                            type: 'error',
                            message: 'Cập nhật thất bại'
                        })
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Update canceled'
                    })
                })
        },
        convertSelectedValue_dataStore(dataStore, mappingCode) {
            return dataStore
                ?.filter((item) => item.mappingCode == mappingCode)
                .map((item) => item.mappingCode)
                .pop()
        },
        convertCheckboxValueToNumber(checkboxValue) {
            return checkboxValue ? 1 : 0
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        },

        dataChonTrangthaicanbo_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrangthaicanbo'))
        },
        dataChonDanToc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDantoc'))
        },
        dataChonTonGiao_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTongiao'))
        },
        dataChonMonDay_Store() {
            return JSON.parse(localStorage.getItem('data_ChonMondaygiaovien'))
        },
        dataChonChucVu_Store() {
            return JSON.parse(localStorage.getItem('data_ChonLoaicanbo'))
        },
        dataChonHinhThucHopDong_Store() {
            return JSON.parse(localStorage.getItem('data_ChonHinhthuchopdong'))
        },
        dataChonNhiemVuKiemNghiem_Store() {
            return JSON.parse(localStorage.getItem('data_ChonNhiemvukiemnghiem'))
        },
        dataChonNganhCC_Store() {
            return JSON.parse(localStorage.getItem('data_ChonNganhCC'))
        },
        dataChonBoiDuongTX_Store() {
            return JSON.parse(localStorage.getItem('data_ChonBoiDuongTX'))
        },
        dataChonTrinhDoChuyenMonNV_Store() {
            return JSON.parse(
                localStorage.getItem('data_ChonTrinhdochuyenmonnghiepvu')
            )
        },
        dataChonTrinhDoLLCT_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrinhDoLLCT'))
        },
        dataChonTrinhDoQLGD_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrinhDoQLGD'))
        },
        dataChonBoiDuongNghiepVu_Store() {
            return JSON.parse(localStorage.getItem('data_ChonBoiDuongNghiepVu'))
        },
        dataChonBoiDuongCBQLCotCan_Store() {
            return JSON.parse(localStorage.getItem('data_ChonBoiDuongCBQLCotCan'))
        },
        dataChonBoiDuongThaySach_Store() {
            return JSON.parse(localStorage.getItem('data_ChonBoiDuongThaySach'))
        },
        dataChonNgoainguchinh_Store() {
            return JSON.parse(localStorage.getItem('data_ChonNgoaingu'))
        },
        dataChonTrinhDoNgoaingu_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrinhDoNgoaiNgu'))
        },
        dataChonNhomCChiNNgu_Store() {
            return JSON.parse(localStorage.getItem('data_ChonNhomCChiNNgu'))
        },
        dataChonLoaiCChiNNgu_Store() {
            return JSON.parse(localStorage.getItem('data_ChonLoaiCChiNNgu'))
        },
        dataChonKhungNLucNNgu_Store() {
            return JSON.parse(localStorage.getItem('data_ChonKhungNLucNNgu'))
        },
        dataChonTrinhDoTinHoc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrinhDoTinHoc'))
        },
        dataChonChuyenMon_Store() {
            return JSON.parse(localStorage.getItem('data_ChonChuyenMon'))
        },
        dataChonCChiDTocTSo_Store() {
            return JSON.parse(localStorage.getItem('data_ChonCChiTiengDTocTSo'))
        },
        dataChonDGVienChuc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDGVienChuc'))
        },
        dataChonDanhGiaGVGioi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDanhGiaGVGioi'))
        },
        dataChonGVCNGioi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonGVCNGioi'))
        },
        dataChonTongPhuTrachDoiGioi_Store() {
            return JSON.parse(
                localStorage.getItem('data_ChonTongPhuTrachDoiGioi')
            )
        },
        dataChonLoaiCanBo_Store() {
            return JSON.parse(localStorage.getItem('data_ChonNhomcanbo'))
        }
    }
}
</script>

<style scoped>
.title {
    font-weight: 700;
    font-size: 13px;
}
.title span {
    color: red;
}

.custom-date-picker {
    height: 32px;
}
.custom-date-picker::placeholder {
    color: gainsboro;

    font-size: 13px;
    background-color: transparent;
    appearance: none;
}

.row {
    margin: 0 auto;
}
.layout-btn {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.btn-info {
    background-color: var(--teal);
}
.btn-update {
    background-color: var(--yellow);
}
.btn-delete {
    background-color: var(--orange);
}
</style>
