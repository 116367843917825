<template>
    <div>
        <CustomBreadcrumb :title="'Tra cứu'" content="Tra cứu học sinh" />
        <CustomContentUI>
            <div class="row">
                <div class="col-4">
                    <label>Đơn vị quản lý</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="
                                this.authUser.role === 5 ||
                                this.authUser.role === 4
                            "
                            :data="this.dataChonDonVi_Store"
                            :placeholder="'Chọn đơn vị'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenDonVi', 'maDonVi']"
                            v-model="selectedValue.selectedValueUnitEducation"
                            @input="getDataChonTruongHoc"
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Cấp học</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="this.authUser.role === 5"
                            :data="getDataESelect.ESelectGradeLevel"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenTruongHoc', 'value']"
                            v-model="selectedValue.selectedValueGradeLevel"
                            @input="getDataChonTruongHoc"
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Khối học/Nhóm lớp</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectSchoolBlock"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['title', 'value']"
                            v-model="selectedValue.selectedValueSchoolBlock"
                        />
                    </div>
                </div>
                <div class="col-4">
                    <label>Trường học</label>
                    <div>
                        <ESelect
                            :reset="resetESelectSchool"
                            @reset-completed="handleResetSchoolCompleted"
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="this.authUser.role === 5"
                            :data="getDataESelect.ESelectSchool"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['title', 'value']"
                            v-model="selectedValue.selectedValueSchool"
                        />
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-2">
                    <label>Từ khóa tìm kiếm</label>
                    <CustomInput
                        placeholder="Nhập ..."
                        v-model="selectedValue.input_tuKhoaTimKiem"
                        :disabled="false"
                        :clearable="false"
                        :show-close-icon="true"
                        size="small"
                    >
                    </CustomInput>
                </div>
                <div class="col-2">
                    <label>Mã định danh công dân</label>

                    <CustomInput
                        placeholder="Nhập ..."
                        size="small"
                        v-model="selectedValue.input_maDinhDanhCongDan"
                        :disabled="false"
                        :clearable="false"
                        :show-close-icon="true"
                    >
                    </CustomInput>
                </div>
                <div class="col-2">
                    <label>Tên lớp học</label>

                    <CustomInput
                        placeholder="Nhập ..."
                        size="small"
                        v-model="selectedValue.input_tenLopHoc"
                        :disabled="false"
                        :clearable="false"
                        :show-close-icon="true"
                    >
                    </CustomInput>
                </div>
                <div class="col-2">
                    <label>Giới tính</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectGender"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['title', 'value']"
                        v-model="selectedValue.selectedValueGender"
                    />
                </div>
                <div class="col-2">
                    <label>Trạng thái</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectStatus"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['title', 'value']"
                        v-model="selectedValue.selectedValueStatus"
                    />
                </div>
                <div class="col-2">
                    <label>Năm học<span class="span">*</span></label>
                    <ESelectYear
                        v-model="selectedValue.selectedValueYear"
                        placeholder="Chọn"
                        size="small"
                        :no-data-text="'Không có bản ghi nào'"
                        :no-match-text="'Không tìm thấy bản ghi nào'"
                    ></ESelectYear>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-4">
                    <label>Nội dung hiển thị <span class="span">*</span></label>

                    <ESelectSearch
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectDisplayContent"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['title', 'key', 'value']"
                        v-model="selectedValue.selectedValueDisplayContent"
                    />
                </div>
                <div class="col-4">
                    <label>Tìm kiếm nâng cao</label>

                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectAdvancedSearch"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueAdvancedSearch"
                        @input="handleTimKiemNangCao"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-2 mt-2" v-if="hienThiNangCao.ngaySinh">
                    <label>Ngày sinh</label>
                    <date-picker
                        :config="configDatePicker"
                        placeholder="Chọn ngày"
                        v-model="selectedValue.date_ngaySinh"
                        class="custom-date-picker"
                    >
                    </date-picker>
                </div>
                <div class="col-2 mt-2" v-if="hienThiNangCao.noiSinh">
                    <label>Nơi sinh</label>
                    <el-input
                        size="small"
                        placeholder="Nhập ..."
                        v-model="selectedValue.input_noiSinh"
                    ></el-input>
                </div>
                <div class="col-2 mt-2" v-if="hienThiNangCao.maDanToc">
                    <label>Dân tộc</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="dataChonDanToc_Store"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['constantTitle', 'mappingCode']"
                        v-model="selectedValue.selectedValueEthnicDirectory"
                    />
                </div>
                <div class="col-2 mt-2" v-if="hienThiNangCao.maTonGiao">
                    <label>Tôn giáo</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="dataChonTonGiao_Store"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['constantTitle', 'mappingCode']"
                        v-model="selectedValue.selectedValueReligion"
                    />
                </div>
                <div class="col-2 mt-2" v-if="hienThiNangCao.dchiThtru">
                    <label>Địa chỉ thường trú</label>
                    <el-input
                        size="small"
                        placeholder="Nhập ..."
                        v-model="selectedValue.input_diaChiThuongTru"
                    ></el-input>
                </div>
                <div class="col-2 mt-2" v-if="hienThiNangCao.dchiTmtru">
                    <label>Địa chỉ tạm trú</label>
                    <el-input
                        size="small"
                        placeholder="Nhập ..."
                        v-model="selectedValue.input_diaChiTamTru"
                    ></el-input>
                </div>

                <div class="col-2 mt-2" v-if="hienThiNangCao.maKhuVuc">
                    <label>Khu vực</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="dataChonKhuvuc_Store"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['constantTitle', 'mappingCode']"
                        v-model="selectedValue.selectedValueArea"
                    />
                </div>
                <div class="col-2 mt-2" v-if="hienThiNangCao.loaiNhapHoc">
                    <label>Loại nhập học</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectLearningType"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueLearningType"
                    />
                </div>
                <div class="col-2 mt-2" v-if="hienThiNangCao.maLyDoThoiHoc">
                    <label>Lý do thôi học</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectReasonForLeavingSchool"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="
                            selectedValue.selectedValueReasonForLeavingSchool
                        "
                    />
                </div>
                <div class="col-2 mt-2" v-if="hienThiNangCao.doiVien">
                    <label>Đội viên</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectTeamMemberSChool"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueTeamMemberSChool"
                    />
                </div>
                <div class="col-2 mt-2" v-if="hienThiNangCao.doanVien">
                    <label>Đoàn viên</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelecTunionMembersSchool"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueTunionMembersSchool"
                    />
                </div>
                <div class="col-2 mt-2" v-if="hienThiNangCao.khuyetTat">
                    <label>Khuyết tật</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectDisabilities"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueDisabilities"
                    />
                </div>

                <div class="col-2 mt-2" v-if="hienThiNangCao.lopGhep">
                    <label>Lớp ghép</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectCompoundClass"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueCompoundClass"
                    />
                </div>
                <div class="col-2 mt-2" v-if="hienThiNangCao.lopChuyen">
                    <label>Lớp chuyên</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectSpecializedClass"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueSpecializedClass"
                    />
                </div>
                <div class="col-2 mt-2" v-if="hienThiNangCao.danTocThieuSo">
                    <label>Dân tộc thiểu số</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectEthnicMinority"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueEthnicMinority"
                    />
                </div>
                <div class="col-2 mt-2" v-if="hienThiNangCao.hoNgheo">
                    <label>Hộ nghèo</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectPoorHouseholds"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValuePoorHouseholds"
                    />
                </div>
                <div class="col-2 mt-2" v-if="hienThiNangCao.moetCode">
                    <label>Mã định danh</label>
                    <el-input
                        size="small"
                        placeholder="Nhập ..."
                        v-model="selectedValue.input_maDinhDanh"
                    ></el-input>
                </div>
                <div class="col-2 mt-2" v-if="hienThiNangCao.maDtuongCsach">
                    <label>Đối tượng chính sách</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="dataChonDoituongchinhsach_Store"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['constantTitle', 'mappingCode']"
                        v-model="selectedValue.selectedValuePolicyObject"
                    />
                </div>

                <div class="col-2 mt-2" v-if="hienThiNangCao.isKhamSkDky">
                    <label>Khám sức khỏe định kỳ</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectPeriodicHealthExamination"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="
                            selectedValue.selectedValuePeriodicHealthExamination
                        "
                    />
                </div>
                <div class="col-2 mt-2" v-if="hienThiNangCao.benhMat">
                    <label>Bệnh về mắt</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectEyeDisease"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueEyeDisease"
                    />
                </div>
                <div class="col-2 mt-2" v-if="hienThiNangCao.bietBoiKy1">
                    <label>Biết bơi kỳ I</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectSwim1"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueSwim1"
                    />
                </div>
                <div class="col-2 mt-2" v-if="hienThiNangCao.bietBoiKy2">
                    <label>Biết bơi kỳ II</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectSwim2"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueSwim2"
                    />
                </div>
            </div>
            <div class="layout-btn-search">
                <CustomButton
                    label="Tìm kiếm"
                    size="small"
                    class="btn-default bg-search"
                    @click="handleSearch"
                />

                <CustomButton
                    :disabled="tableData.length === 0"
                    label="Xuất Excel"
                    size="small"
                    class="btn-default"
                    @click="handleExportExcel"
                    style="background-color: #1e6050"
                />
            </div>
        </CustomContentUI>

        <div
            class="layout-btn-add"
            :style="{
                'max-width': leftBarWidth()
            }"
        >
            <div>
                <chonSoLuongBanGhi @chonXongSoLuong="ChonSoLuongBanGhi($event)" />
            </div>

            <div>
                <CustomButton
                    label="Đẩy lên bộ"
                    size="small"
                    class="btn-default btn-up"
                    @click="handleDayDuLieu"
                />
                <CustomButton
                    label="Xóa"
                    size="small"
                    class="btn-default btn-delete"
                    @click="handleDelete"
                />

                <CustomButton
                    label="Xóa mã định danh Bộ GD&ĐT"
                    size="small"
                    class="btn-default btn-delete"
                    @click="handleXoaMaDinhDanh"
                />
            </div>
        </div>

        <CustomTable :max-width="leftBarWidth()">
            <template v-slot:header>
                <tr>
                    <th class="text-thead">
                        <input
                            type="checkbox"
                            v-model="selectAllChecked"
                            @change="handleSelectAll"
                            :disabled="tableData.length === 0"
                        />
                    </th>
                    <th class="text-thead">STT</th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(1)">
                        Mã trường
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(2)">
                        Trường học
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(3)">
                        Khối học/Nhóm lớp
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(4)">
                        Lớp học
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(5)">
                        Mã học sinh
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(6)">
                        Mã định danh bộ GD&ĐT
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(7)">
                        Họ tên
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(8)">
                        Ngày sinh
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(9)">
                        Giới tính
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(10)"
                    >
                        Dân tộc
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(11)"
                    >
                        Trạng thái
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(12)"
                    >
                        CMTND/TCC
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(13)"
                    >
                        Ngày cấp CMTND/TCC
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(14)"
                    >
                        Nơi cấp CMTND/TCC
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(15)"
                    >
                        SĐT liên hệ
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(16)"
                    >
                        Email
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(17)"
                    >
                        Số điện thoại mẹ
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(18)"
                    >
                        Địa chỉ thường trú
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(19)"
                    >
                        Địa chỉ tạm trú
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(20)"
                    >
                        Nơi sinh
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(21)"
                    >
                        Hộ nghèo
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(22)"
                    >
                        Đối tượng chính sách
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(23)"
                    >
                        Số điện thoại bố
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(24)"
                    >
                        Họ tên bố
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(25)"
                    >
                        Họ tên mẹ
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(26)"
                    >
                        Mã định danh công dân
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(28)"
                    >
                        Khám sức khỏe định kỳ
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(33)"
                    >
                        Chiều cao kì I
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(34)"
                    >
                        Chiều cao kì II
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(35)"
                    >
                        Cân nặng kì I
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(36)"
                    >
                        Cân nặng kì II
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(37)"
                    >
                        Bệnh về mắt
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(38)"
                    >
                        Biết bơi kỳ I
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(39)"
                    >
                        Biết bơi kỳ II
                    </th>
                    <th class="text-thead">Thao tác</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21" class="text-tbody">
                            Không có bản ghi nào.
                        </td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td class="text-tbody">
                        <input
                            type="checkbox"
                            :checked="item.isChecked"
                            @change="toggleCheckbox(item)"
                        />
                    </td>
                    <td class="text-tbody">
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td
                        class="text-tbody text-left"
                        v-if="defaultDisplayTable.includes(1)"
                    >
                        {{ item.maTruongHoc }}
                    </td>
                    <td
                        class="text-tbody text-left"
                        v-if="defaultDisplayTable.includes(2)"
                    >
                        {{ item.tenTruongHoc }}
                    </td>
                    <td class="text-tbody" v-if="defaultDisplayTable.includes(3)">
                        {{ item.khoiHoc }}
                    </td>
                    <td class="text-tbody" v-if="defaultDisplayTable.includes(4)">
                        {{ item.tenLopHoc }}
                    </td>
                    <td
                        @click="handleOpenDisabledModalChitiet(item)"
                        class="text-tbody-btn"
                        v-if="defaultDisplayTable.includes(5)"
                    >
                        {{ item.maHocSinh }}
                    </td>
                    <td class="text-tbody" v-if="defaultDisplayTable.includes(6)">
                        {{ item.moetCode }}
                    </td>
                    <td
                        class="text-tbody-btn text-left"
                        @click="handleOpenDisabledModalChitiet(item)"
                        v-if="defaultDisplayTable.includes(7)"
                    >
                        {{ item.hoTen }}
                    </td>
                    <td class="text-tbody" v-if="defaultDisplayTable.includes(8)">
                        {{ item.ngaySinh }}
                    </td>
                    <td class="text-tbody" v-if="defaultDisplayTable.includes(9)">
                        {{ item.gioiTinh }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(10)"
                    >
                        {{ item.maDanToc }}
                    </td>
                    <td
                        class="text-tbody text-left"
                        v-if="defaultDisplayTable.includes(11)"
                    >
                        {{ item.trangThai }}
                    </td>
                    <td
                        class="text-tbody text-left"
                        v-if="defaultDisplayTable.includes(12)"
                    >
                        {{ item.soCmnd }}
                    </td>
                    <td
                        class="text-tbody text-left"
                        v-if="defaultDisplayTable.includes(13)"
                    >
                        {{ item.ngayCapCmnd }}
                    </td>
                    <td
                        class="text-tbody text-left"
                        v-if="defaultDisplayTable.includes(14)"
                    >
                        {{ item.noiCapCmnd }}
                    </td>
                    <td
                        class="text-tbody text-left"
                        v-if="defaultDisplayTable.includes(15)"
                    >
                        {{ item.sdt }}
                    </td>

                    <td
                        class="text-tbody text-left"
                        v-if="defaultDisplayTable.includes(16)"
                    >
                        {{ item.email }}
                    </td>
                    <td
                        class="text-tbody text-left"
                        v-if="defaultDisplayTable.includes(17)"
                    >
                        {{ item.soDienThoaiMe }}
                    </td>
                    <td
                        class="text-tbody text-left"
                        v-if="defaultDisplayTable.includes(18)"
                    >
                        {{ item.dchiThtru }}
                    </td>
                    <td
                        class="text-tbody text-left"
                        v-if="defaultDisplayTable.includes(19)"
                    >
                        {{ item.dchiTmtru }}
                    </td>
                    <td
                        class="text-tbody text-left"
                        v-if="defaultDisplayTable.includes(20)"
                    >
                        {{ item.noiSinh }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(21)"
                    >
                        {{ item.hoNgheo }}
                    </td>
                    <td
                        class="text-tbody text-left"
                        v-if="defaultDisplayTable.includes(22)"
                    >
                        {{ item.maDtuongCsach }}
                    </td>
                    <td
                        class="text-tbody text-left"
                        v-if="defaultDisplayTable.includes(23)"
                    >
                        {{ item.soDienThoaiBo }}
                    </td>
                    <td
                        class="text-tbody text-left"
                        v-if="defaultDisplayTable.includes(24)"
                    >
                        {{ item.hoTenBo }}
                    </td>
                    <td
                        class="text-tbody text-left"
                        v-if="defaultDisplayTable.includes(25)"
                    >
                        {{ item.hoTenMe }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(26)"
                    >
                        {{ item.citizenId }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(27)"
                    >
                        {{ item.isKhamSkDky }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(28)"
                    >
                        {{ item.isKhamSkDky }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(33)"
                    >
                        {{ item.chieuCao }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(34)"
                    >
                        {{ item.chieuCaoKy2 }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(35)"
                    >
                        {{ item.canNang }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(36)"
                    >
                        {{ item.canNangKy2 }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(37)"
                    >
                        {{ item.benhMat }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(38)"
                    >
                        {{ item.isBietBoiKy1 }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(39)"
                    >
                        {{ item.isBietBoiKy2 }}
                    </td>

                    <td>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Chỉnh sửa"
                            placement="top"
                        >
                            <CustomButton
                                label=""
                                size="small"
                                icon="el-icon-edit-outline"
                                @click="handleOpenModalChitiet(item)"
                                class="btn-default btn-update"
                            />
                        </el-tooltip>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Chuyển trường"
                            placement="top"
                        >
                            <CustomButton
                                label=""
                                size="small"
                                icon="el-icon-connection"
                                @click="handleOpenModalChuyenTruong(item)"
                                class="btn-default bg-search"
                            />
                        </el-tooltip>
                    </td>
                </tr>
            </template>
        </CustomTable>
        <CustomPagination
            v-show="total_rows > 0"
            :tongbanghi="total_rows"
            :soluonghienthi="soLuongBanGhiHienThi"
            :batdau="trangbatdau"
            @pageChange="layLai($event)"
        >
        </CustomPagination>

        <ModalChiTietHocSinh
            :chiTietHocSinhModalVisible="chiTietHocSinhModalVisible"
            :closeModalChitietHocSinh="closeModalChitietHocSinh"
            :dataHocSinhTheoNamId_chitiet="dataHocSinhTheoNamId_chitiet"
            :ESelectProvince="getDataESelect.ESelectProvince"
        />
        <DisabledModalChitiet
            :disabledModalVisible="disabledModalVisible"
            :closeDisabledModal="closeDisabledModal"
            :dataHocSinhTheoNamId_disabled="dataHocSinhTheoNamId_disabled"
        />
        <ModalChuyenTruong
            :id_chuyenTruong="id_chuyenTruong"
            :data_modalChuyenTruong="data_modalChuyenTruong"
            :modalChuyenTruongVisible="modalChuyenTruongVisible"
            :closeModalChuyenTruong="closeModalChuyenTruong"
            :ESelectProvince="getDataESelect.ESelectProvince"
        />
    </div>
</template>
<script>
import store from '@/store'
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'
import DisabledModalChitiet from './DisabledModal.vue'
import chonSoLuongBanGhi from '@/components/chonSoLuongBanGhi.vue'
import ESelectYear from '@/components/ESelectYear.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import ESelect from '@/components/ESelect.vue'
import ESelectSearch from '@/components/ESelectSearch.vue'
import CustomButton from '@/components/CustomButton.vue'
import datePicker from 'vue-bootstrap-datetimepicker'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import ModalChiTietHocSinh from './ModalChiTietHocSinh.vue'
import ModalChuyenTruong from './ModalChuyenTruong.vue'
import {
    ESelectSchoolBlock_MockData,
    ESelectGender_MockData,
    ESelectStatus_MockData_Tracuuhocsinh,
    ESelectDisplayContent_MockData_TraCuuhocsinh,
    ESelectAdvancedSearch_MockData,
    ESelectArea_MockData,
    ESelectLearningType_MockData,
    ESelectreasonForLeavingSchool_MockData,
    ESlectYesOfNo_MockData,
    ESelectTrueOfFalse,
    ESelectEyeDisease_MockData
} from '@/mock_data'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { mapState } from 'vuex'
import axios from 'axios'
import { convertCapHocList, currentYear, filterCapHocList } from '@/utils'
import ViewExcel from '@/components/ViewExcel.vue'
import CustomContentUI from '@/components/CustomContentUI.vue'
import CustomInput from '@/components/CustomInput.vue'
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'KetChuyenDuLieu',
    components: {
        ESelect,
        ESelectSearch,
        CustomButton,
        CustomBreadcrumb,
        ESelectYear,
        CustomPagination,
        chonSoLuongBanGhi,
        ModalChiTietHocSinh,
        DisabledModalChitiet,
        ModalChuyenTruong,
        datePicker,
        // ViewExcel
        CustomContentUI,
        CustomInput,
        CustomTable
    },
    data() {
        return {
            fileViewExcel: null,
            file: null,
            defaultDisplayTable:
                ESelectDisplayContent_MockData_TraCuuhocsinh.slice(0, 11).map(
                    (item) => item.value
                ),
            selectAllChecked: false,
            dataSelectedAll: [],
            selectedItems: [],
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            soLuongBanGhiHienThi: 25,
            trangbatdau: false,
            chiTietHocSinhModalVisible: false,
            disabledModalVisible: false,
            modalChuyenTruongVisible: false,
            data_modalChuyenTruong: {},
            id_chuyenTruong: 0,

            dataHocSinhTheoNamId_disabled: 0,
            dataHocSinhTheoNamId_chitiet: 0,

            dataTenLopHoc_ModalChiTietHocSinh: '',
            configDatePicker: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
                showTodayButton: true,
                locale: 'vi'
            },
            hienThiNangCao: {
                ngaySinh: false,
                noiSinh: false,
                maDanToc: false,
                maTonGiao: false,
                maLopHocCn: false,
                dchiThtru: false,
                dchiTmtru: false,
                maKhuVuc: false,
                gioiTinh: false,
                loaiNhapHoc: false,
                maLyDoThoiHoc: false,
                doiVien: false,
                doanVien: false,
                khuyetTat: false,
                lopGhep: false,
                lopChuyen: false,
                danTocThieuSo: false,
                hoNgheo: false,
                moetCode: false,
                maDtuongCsach: false,
                isKhamSkDky: false,
                benhMat: false,
                bietBoiKy1: false,
                bietBoiKy2: false
            },

            resetESelectSchool: false,
            resetESelectSchoolBlock: false,
            tableData: [],
            request_Data: {
                capHocs: [],
                colExcels: [],
                dataSearchList: [],
                gioiTinh: '',
                keyword: '',
                lstKhoiHoc: [],
                maCuDan: '',
                maPhongs: [],
                maTruongHocs: [],
                namHoc: '',
                tenLopHoc: '',
                trangThai: ''
            },

            getDataESelect: {
                ESelectProvince: [], //tinh thanh
                ESelectUnitEducation: [], //donvi
                ESelectGradeLevel: [], //caphoc
                ESelectSchoolBlock: ESelectSchoolBlock_MockData, //khoihoc/nhomlop
                ESelectSchool: [], //truonghoc
                // input_tuKhoaTimKiem
                //input_maDinhDanhCongDan
                //  input_tenLopHoc
                ESelectGender: ESelectGender_MockData, //gioitinh,
                ESelectStatus: ESelectStatus_MockData_Tracuuhocsinh, //trangtai
                //namhoc
                ESelectDisplayContent:
                    ESelectDisplayContent_MockData_TraCuuhocsinh,
                //noidunghienthi,
                ESelectAdvancedSearch: ESelectAdvancedSearch_MockData, //timkiemnangcao
                //ngaysinh
                //noisinh
                ESelectEthnicDirectory: [], //danhmucdantoc
                ESelectReligion: [], //tongiao
                //diachithuongtru
                //diachitamtru
                ESelectArea: [], //khuvuc
                ESelectLearningType: ESelectLearningType_MockData, //loainhaphoc,
                ESelectReasonForLeavingSchool:
                    ESelectreasonForLeavingSchool_MockData, //lidothoihoc
                ESelectTeamMemberSChool: ESlectYesOfNo_MockData, //doivien
                ESelecTunionMembersSchool: ESlectYesOfNo_MockData, //doanvien
                ESelectDisabilities: ESlectYesOfNo_MockData, //khuyettat
                ESelectCompoundClass: ESlectYesOfNo_MockData, //lopghep
                ESelectSpecializedClass: ESelectTrueOfFalse, //lopchuyen
                ESelectEthnicMinority: ESelectTrueOfFalse, //dantocthieuso
                ESelectPoorHouseholds: ESelectTrueOfFalse, //hongheo
                //madinhdanh
                ESelectPolicyObject: [], //doituongchinhsach
                ESelectPeriodicHealthExamination: ESelectTrueOfFalse, //khamsuckhoedinhky
                ESelectEyeDisease: ESelectEyeDisease_MockData, // benhvemat
                ESelectSwim1: ESelectTrueOfFalse, //bietboiky1
                ESelectSwim2: ESelectTrueOfFalse //bietboiky2
            },

            selectedValue: {
                selectedValueUnitEducation: [], //donvi
                selectedValueGradeLevel: [], //caphoc
                selectedValueSchoolBlock: [], //khoihoc/nhomlop
                selectedValueSchool: [], //truonghoc
                input_tuKhoaTimKiem: '', //tukhoatimkiem
                input_maDinhDanhCongDan: '', //madinhdanhcongdan
                input_tenLopHoc: '', //tenlophoc,
                selectedValueGender: [], //gioitinh
                selectedValueStatus: [], //trangthai
                selectedValueYear: '', //namhoc,
                selectedValueDisplayContent:
                    ESelectDisplayContent_MockData_TraCuuhocsinh.slice(0, 11), //noidunghienthi

                selectedValueAdvancedSearch: [], //timkiemnangcao
                date_ngaySinh: '', //ngaysinh
                input_noiSinh: '', //noisinh
                selectedValueEthnicDirectory: [], //danhmucdantoc
                selectedValueReligion: [], //tongiao
                input_diaChiThuongTru: '', //diachithuongtru
                input_diaChiTamTru: '', //diachitamtru
                selectedValueArea: [], //khuvuc,
                selectedValueLearningType: [], //loainhaphoc,
                selectedValueReasonForLeavingSchool: [], //lidothoihoc
                selectedValueTeamMemberSChool: [], //doivien
                selectedValueTunionMembersSchool: [], //doanvien
                selectedValueDisabilities: [], //khuyettat
                selectedValueCompoundClass: [], //lopghep
                selectedValueSpecializedClass: [], //lopchuyen
                selectedValueEthnicMinority: [], //dantocthieuso
                selectedValuePoorHouseholds: [], //hongheo
                input_maDinhDanh: '', //madinhdanh
                selectedValuePolicyObject: [], //doituongchinhsach
                selectedValuePeriodicHealthExamination: [], //khamsuckhoedinhky
                selectedValueEyeDisease: [], //benhvemat
                selectedValueSwim1: [], //bietboiky1
                selectedValueSwim2: [] //bietboiky2
            }
        }
    },
    methods: {
        onChange(event) {
            this.file = event.target.files ? event.target.files[0] : null
        },
        handleTimKiemNangCao(value) {
            const selectedValues = value.map((item) => item.value)

            for (const key in this.hienThiNangCao) {
                this.hienThiNangCao[key] = selectedValues.includes(key)
            }
        },
        toggleCheckbox(item) {
            this.selectAllChecked = false
            item.isChecked = !item.isChecked
            if (item.isChecked) {
                this.selectedItems.push(item) // Thêm item vào mảng selectedItems khi checkbox được chọn
            } else {
                const index = this.selectedItems.indexOf(item)
                if (index > -1) {
                    this.selectedItems.splice(index, 1) // Xóa item khỏi mảng selectedItems khi checkbox bị bỏ chọn
                }
            }
        },

        handleSelectAll() {
            this.tableData.forEach((item) => {
                item.isChecked = this.selectAllChecked // Đặt trạng thái của tất cả các checkbox bằng với checkbox "checkboxAll"
                if (this.selectAllChecked && !this.selectedItems.includes(item)) {
                    this.selectedItems.push(item) // Thêm tất cả các item vào mảng selectedItems khi chọn tất cả
                } else if (!this.selectAllChecked) {
                    this.selectedItems = [] // Xóa tất cả các item khỏi mảng selectedItems khi bỏ chọn tất cả
                }
            })
        },

        closeModalChitietHocSinh() {
            this.chiTietHocSinhModalVisible = false
        },
        closeModalChuyenTruong() {
            this.modalChuyenTruongVisible = false
        },
        closeDisabledModal() {
            this.disabledModalVisible = false
        },
        handleResetSchoolCompleted() {
            this.resetESelectSchool = false
        },
        handleResetSchoolBlockCompleted() {
            this.resetESelectSchoolBlock = false
        },
        ChonSoLuongBanGhi(e) {
            this.total_rows = 0
            this.soLuongBanGhiHienThi = e.soluong
        },
        checkTruocKhiTim() {
            this.trangbatdau = !this.trangbatdau
        },
        layLai(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        async getDataChonTruongHoc() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const maDonVi = this.convertToValueArray(
                this.selectedValue.selectedValueUnitEducation
            )

            const capHoc = this.convertToValueArray(
                this.selectedValue.selectedValueGradeLevel
            )

            const request_Header = {
                token: this.authToken
            }

            const request_Data = {
                capHoc: capHoc,
                maDonVi: maDonVi
            }

            let response = await sendRequest(
                Api.internal_api.dm_School,
                request_Data,
                request_Header,
                null
            )

            if (response.statusResponse == 0) {
                loading.close()
                this.getDataESelect.ESelectSchool = response.rows.map((item) => ({
                    title: `${item.tenTruongHoc} - [${item.maTruongHoc}]`,
                    value: item.maTruongHoc
                }))
            }
            loading.close()
        },

        async handleOpenModalChitiet(item) {
            this.dataHocSinhTheoNamId_chitiet = item.hocSinhTheoNamId

            this.chiTietHocSinhModalVisible = true
        },
        async handleOpenModalChuyenTruong(item) {
            this.id_chuyenTruong = item.hocSinhTheoNamId
            this.data_modalChuyenTruong = item
            this.modalChuyenTruongVisible = true
        },
        async handleOpenDisabledModalChitiet(item) {
            this.dataHocSinhTheoNamId_disabled = item.hocSinhTheoNamId

            this.disabledModalVisible = true
        },
        async handleDelete() {
            if (this.selectedItems.length === 0) {
                this.$message({
                    type: 'warning',
                    message: 'Vui lòng chọn học sinh cần xóa'
                })
                return
            }

            const namHoc = this.selectedValue.selectedValueYear
            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                listMa: this.selectedItems.map((item) => item.maHocSinh),
                hocSinhTheoNamIds: this.selectedItems.map(
                    (item) => item.hocSinhTheoNamId
                ),
                namHoc: namHoc || currentYear
            }

            this.$confirm('Xác nhận xóa các bản ghi đã chọn?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const response = await sendRequest(
                        Api.traCuu.traCuuHocSinh.xoaHocSinh,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        this.tableData = this.tableData.filter(
                            (item) => !item.isChecked
                        )
                        this.selectedItems = []
                        this.selectAllChecked = false

                        this.$message({
                            type: 'success',
                            message: 'Xóa học sinh thành công'
                        })
                    } else {
                        this.$message({
                            type: 'info',
                            message: response.rd
                        })
                    }
                })
                .catch(() => {})
        },
        async getDataTinhThanh() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    start: 0,
                    limit: 1000
                }
                const response = await sendRequest(
                    Api.superAdmin.tinhThanh.danhSach,
                    null,
                    request_Header,
                    request_Params
                )

                this.getDataESelect.ESelectProvince = response.rows.map(
                    (item) => ({
                        ...item,
                        tenTinhThanh: `${item.tenTinhThanh} - [${item.maTinhThanh}]`
                    })
                )
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },
        async handleDayDuLieu() {
            if (this.selectedItems.length === 0) {
                this.$message({
                    type: 'warning',
                    message: 'Vui lòng chọn bản ghi cần đẩy lại thông tin'
                })
                return
            }
            const listNopRieng = this.selectedItems.map((item) => item.maHocSinh)

            const namHoc = this.selectedValue.selectedValueYear
            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                hocKy: 1,
                listNopRieng: listNopRieng,
                namHoc: namHoc || currentYear
            }

            this.$confirm('Xác nhận đẩy lại thông tin?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const response = await sendRequest(
                        Api.traCuu.traCuuHocSinh.dayDuLieu,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        this.$message({
                            type: 'success',
                            message:
                                'Hệ thống đang bắt đầu đẩy dữ liệu lên bộ. Theo dõi quá trình đẩy ở chức năng Lịch sử đẩy dữ liệu lên Bộ.'
                        })
                    } else {
                        this.$message({
                            type: 'info',
                            message: response.rd
                        })
                    }
                })
                .catch(() => {})
        },
        async handleXoaMaDinhDanh() {
            if (this.selectedItems.length === 0) {
                this.$message({
                    type: 'warning',
                    message: 'Vui lòng chọn ít nhất 1 học sinh'
                })
                return
            }
            const lstId = this.selectedItems.map((item) => item.hocSinhTheoNamId)

            const namHoc = this.selectedValue.selectedValueYear
            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                loaiDuLieu: 3,
                lstId: lstId,
                namHoc: namHoc || currentYear
            }

            this.$confirm(
                'Xác nhận xóa mã định danh các học sinh đã chọn?',
                'Thông báo',
                {
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy',
                    type: 'warning'
                }
            )
                .then(async () => {
                    const response = await sendRequest(
                        Api.traCuu.traCuuHocSinh.xoaMaDinhDanhLenBo,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        this.tableData = this.tableData.filter(
                            (item) => !item.isChecked
                        )

                        this.selectedItems = []
                        this.selectAllChecked = false
                        this.$message({
                            type: 'success',
                            message: 'Xóa Mã định danh Bộ GĐ&ĐT thành công'
                        })
                    } else {
                        this.$message({
                            type: 'info',
                            message: response.rd
                        })
                    }
                })
                .catch(() => {})
        },
        async handleExportExcel() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const maPhongs = this.convertToValueArray(
                this.selectedValue.selectedValueUnitEducation
            )

            const maTruongHocs = this.convertToValueArray(
                this.selectedValue.selectedValueSchool
            )

            const lstkhoiHoc = this.convertToValueArray(
                this.selectedValue.selectedValueSchoolBlock
            )

            const keyword = this.selectedValue.input_tuKhoaTimKiem
            const tenLopHoc = this.selectedValue.input_tenLopHoc
            const gioiTinh = this.selectedValue.selectedValueGender.value
            const trangThai = this.selectedValue.selectedValueStatus.value
            const namHoc = this.selectedValue.selectedValueYear

            const noidunghienthi = this.selectedValue.selectedValueDisplayContent
            const defaultDisplayTable = noidunghienthi.map((item) => item.value)
            this.defaultDisplayTable = defaultDisplayTable
            const convertColExcels =
                this.selectedValue.selectedValueDisplayContent.map((item) => ({
                    ...item,
                    name: item.title,
                    stt: item.value
                }))
            const timkiemnangcao = this.convertToValueArray(
                this.selectedValue.selectedValueAdvancedSearch
            )

            const keyMappings_dataSreachList = {
                ngaySinh: 'date_ngaySinh',
                noiSinh: 'input_noiSinh',
                dchiThtru: 'input_diaChiThuongTru',
                dchiTmtru: 'input_diaChiTamTru',
                maDanToc: 'selectedValueEthnicDirectory',
                maTonGiao: 'selectedValueReligion',
                maKhuVuc: 'selectedValueArea',
                loaiNhapHoc: 'selectedValueLearningType',
                maLyDoThoiHoc: 'selectedValueReasonForLeavingSchool',
                doiVien: 'selectedValueTeamMemberSChool',
                doanVien: 'selectedValueTunionMembersSchool',
                khuyetTat: 'selectedValueDisabilities',
                lopGhep: 'selectedValueCompoundClass',
                lopChuyen: 'selectedValueSpecializedClass',
                danTocThieuSo: 'selectedValueEthnicMinority',
                hoNgheo: 'selectedValuePoorHouseholds',
                maDinhDanh: 'input_maDinhDanh',
                maDtuongCsach: 'selectedValuePolicyObject',
                isKhamSkDky: 'selectedValuePeriodicHealthExamination',
                benhMat: 'selectedValueEyeDisease',
                bietBoiKy1: 'selectedValueSwim1',
                bietBoiKy2: 'selectedValueSwim2'
                // Thêm các ánh xạ key - giá trị tương ứng ở đây
            }

            const dataSearchList = timkiemnangcao
                .map((key) => {
                    const valueKey = keyMappings_dataSreachList[key]
                    const inputValue = this.selectedValue[valueKey]
                    if (valueKey && inputValue) {
                        let value
                        if (Array.isArray(inputValue)) {
                            value = inputValue.map((item) => item.value)
                            return {
                                key: key,
                                values: value
                            }
                        } else if (typeof inputValue === 'object') {
                            value = inputValue.value
                        } else {
                            value = inputValue
                        }
                        return {
                            key: key,
                            value: value
                        }
                    }
                    return null
                })
                .filter((item) => item !== null)
            this.$confirm('Xác nhận tải xuống?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })

                .then(async () => {
                    const requestData = {
                        capHocs: lstkhoiHoc,
                        gioiTinh: gioiTinh,
                        keyword: keyword,
                        maTruongHocs: maTruongHocs,
                        maPhongs: maPhongs,
                        dataSreachList: dataSearchList,
                        namHoc: namHoc || currentYear,
                        start: 0,
                        limit: 50000,
                        countInPage: 5000,
                        trangThai: trangThai,
                        lstKhoiHoc: lstkhoiHoc,
                        tenLopHoc: tenLopHoc,
                        colExcels: convertColExcels
                    }
                    const domain = process.env.VUE_APP_BACKEND_BASE_URL
                    const response = await axios.post(
                        `${domain}/csdlgd-admin/ExportExcel/HocSinh`,
                        requestData,
                        {
                            headers: {
                                token: this.authToken,
                                'Content-Type': 'application/json'
                            },
                            responseType: 'blob' // Set responseType là 'blob'
                        }
                    )

                    const blob = new Blob([response.data])
                    const blobUrl = window.URL.createObjectURL(blob)

                    const downloadLink = document.createElement('a')
                    downloadLink.href = blobUrl
                    downloadLink.download = 'DanhSachHocSinh.xlsx'
                    downloadLink.style.display = 'none'

                    document.body.appendChild(downloadLink)
                    downloadLink.click()

                    window.URL.revokeObjectURL(blobUrl)
                    this.fileViewExcel = new File(
                        [blob],

                        {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        }
                    )

                    loading.close()
                })
                .catch((error) => {
                    loading.close()
                    if (error.response?.data.rd) {
                        this.$message({
                            type: 'error',
                            message: error.response?.data.rd
                        })
                    }
                })
        },
        async handleSearch() {
            const maPhongs = this.convertToValueArray(
                this.selectedValue.selectedValueUnitEducation
            )

            const maTruongHocs = this.convertToValueArray(
                this.selectedValue.selectedValueSchool
            )

            const lstkhoiHoc = this.convertToValueArray(
                this.selectedValue.selectedValueSchoolBlock
            )

            const keyword = this.selectedValue.input_tuKhoaTimKiem
            const tenLopHoc = this.selectedValue.input_tenLopHoc
            const gioiTinh = this.selectedValue.selectedValueGender.value
            const trangThai = this.selectedValue.selectedValueStatus.value
            const namHoc = this.selectedValue.selectedValueYear

            const noidunghienthi = this.selectedValue.selectedValueDisplayContent
            const defaultDisplayTable = noidunghienthi.map((item) => item.value)
            this.defaultDisplayTable = defaultDisplayTable
            const convertColExcels =
                this.selectedValue.selectedValueDisplayContent.map((item) => ({
                    ...item,
                    name: item.title,
                    stt: item.value
                }))

            const timkiemnangcao = this.convertToValueArray(
                this.selectedValue.selectedValueAdvancedSearch
            )

            const keyMappings_dataSreachList = {
                ngaySinh: 'date_ngaySinh',
                noiSinh: 'input_noiSinh',
                dchiThtru: 'input_diaChiThuongTru',
                dchiTmtru: 'input_diaChiTamTru',
                maDanToc: 'selectedValueEthnicDirectory',
                maTonGiao: 'selectedValueReligion',
                maKhuVuc: 'selectedValueArea',
                loaiNhapHoc: 'selectedValueLearningType',
                maLyDoThoiHoc: 'selectedValueReasonForLeavingSchool',
                doiVien: 'selectedValueTeamMemberSChool',
                doanVien: 'selectedValueTunionMembersSchool',
                khuyetTat: 'selectedValueDisabilities',
                lopGhep: 'selectedValueCompoundClass',
                lopChuyen: 'selectedValueSpecializedClass',
                danTocThieuSo: 'selectedValueEthnicMinority',
                hoNgheo: 'selectedValuePoorHouseholds',
                maDinhDanh: 'input_maDinhDanh',
                maDtuongCsach: 'selectedValuePolicyObject',
                isKhamSkDky: 'selectedValuePeriodicHealthExamination',
                benhMat: 'selectedValueEyeDisease',
                bietBoiKy1: 'selectedValueSwim1',
                bietBoiKy2: 'selectedValueSwim2'
                // Thêm các ánh xạ key - giá trị tương ứng ở đây
            }

            const dataSearchList = timkiemnangcao
                .map((key) => {
                    const valueKey = keyMappings_dataSreachList[key]
                    const inputValue = this.selectedValue[valueKey]
                    if (valueKey && inputValue) {
                        let value
                        if (Array.isArray(inputValue)) {
                            value = inputValue.map((item) => item.value)
                            return {
                                key: key,
                                values: value
                            }
                        } else if (typeof inputValue === 'object') {
                            value = inputValue.value
                        } else {
                            value = inputValue
                        }
                        return {
                            key: key,
                            value: value
                        }
                    }
                    return null
                })
                .filter((item) => item !== null)

            const start = this.start
            const limit = this.limit
            const capHocs = this.convertToValueArray(
                this.selectedValue.selectedValueGradeLevel
            )

            const maCuDan = this.selectedValue.input_maDinhDanhCongDan

            const request_Header = {
                token: this.authToken
            }

            const request_Data = {
                colExcels: convertColExcels,
                capHocs: capHocs,
                maCuDan: maCuDan || null,
                start: start,
                limit: limit,
                dataSearchList: dataSearchList,

                gioiTinh: gioiTinh,
                keyword: keyword,
                lstKhoiHoc: lstkhoiHoc,

                maPhongs: maPhongs,
                maTruongHocs: maTruongHocs,
                namHoc: namHoc || currentYear,
                tenLopHoc: tenLopHoc,
                trangThai: trangThai
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const response = await sendRequest(
                Api.traCuu.traCuuHocSinh.danhSachTraCuuHocSinh,
                request_Data,
                request_Header
            )

            if (response.rc == 0) {
                loading.close()
                this.tableData = response.tableData.map((item) => {
                    return { ...item, isChecked: false }
                })
                this.total_rows = response.totalRows
            }
            if (response.rc != 0) {
                loading.close()
                this.$message({
                    type: 'info',
                    message: response.rd
                })
                this.tableData = []
                this.total_rows = 0
            }
        },
        convertToValueArray(array) {
            return array.map((item) => item.value)
        },
        leftBarWidth() {
            if (this.isCollapseLeftBar) {
                return 'calc(100vw - 105px)'
            } else {
                return 'calc(100vw - 340px)'
            }
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        },
        dataChonKhuvuc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonKhuvuc'))
        },
        dataChonDoituongchinhsach_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDoituongchinhsach'))
        },
        dataChonDanToc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDantoc'))
        },
        dataChonTonGiao_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTongiao'))
        },
        ...mapState(['isCollapseLeftBar']),
        ...mapState({
            dataTo_KetChuyenDuLieu: (state) =>
                state.data_Navigate_KetChuyenDuLieu.traCuuHocSinh
        })
    },
    watch: {
        'authUser.role': {
            immediate: true,
            handler(newRole, oldRole) {
                if (newRole === 5) {
                    this.selectedValue.selectedValueUnitEducation = [
                        { value: this.authUser.ma_don_vi }
                    ]
                    const convertedList = convertCapHocList(
                        this.authUser.capHocList
                    )
                    this.selectedValue.selectedValueGradeLevel = convertedList
                    this.selectedValue.selectedValueSchool = [
                        { value: this.authUser.ma_truong_hoc }
                    ]
                }
                if (newRole === 4) {
                    this.selectedValue.selectedValueUnitEducation = [
                        { value: this.authUser.ma_don_vi }
                    ]
                }
            }
        },
        dataTo_KetChuyenDuLieu: {
            immediate: true,
            handler(newValues) {
                if (newValues?.maTruongHoc && newValues) {
                    this.selectedValue.selectedValueYear =
                        newValues?.namHoc.toString()
                    this.selectedValue.selectedValueSchool = [
                        { value: newValues.maTruongHoc }
                    ]
                }
            }
        },
        'selectedValue.selectedValueUnitEducation': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        },
        'selectedValue.selectedValueGradeLevel': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        },
        'selectedValue.selectedValueSchoolBlock': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        }
    },
    mounted() {
        this.getDataESelect.ESelectGradeLevel = filterCapHocList(
            this.authUser.capHocList
        )
        this.getDataTinhThanh()
        this.getDataChonTruongHoc()

        this.handleSearch()
    }
}
</script>
<style scoped>
.success-bg {
    color: #fff;
    background-color: #67c23a;
    border-color: #67c23a;
}
.warning-bg {
    color: #fff;
    background-color: #e6a23c;
    border-color: #e6a23c;
}
.layout-btn-add {
    border-radius: 4px 4px 0px 0px;
    padding: 20px 10px 5px 10px;

    background-color: #fff;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}
.layout-btn-search {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 5px;
}
.text-left {
    text-align: left;
}
.text-tbody-btn {
    font-size: 13px;
    cursor: pointer;
    color: #337ab7;
}
.custom-date-picker {
    height: 32px;
}
.custom-date-picker::placeholder {
    color: gainsboro;

    font-size: 13px;
    background-color: transparent;
    appearance: none;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.bg-search,
.excel {
    background-color: var(--teal);
}
.btn-add {
    background-color: var(--light-green);
}
.btn-up {
    background-color: var(--yellow);
}
.btn-update {
    background-color: var(--yellow);
}

.btn-delete {
    background-color: var(--orange);
}
</style>
