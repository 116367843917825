<template>
    <el-dialog
        :visible="modalChuyenTruongVisible"
        @close="closeModalChuyenTruong"
        width="40%"
    >
        <div slot="title" class="header-modal">
            Chuyển công tác - Cán bộ {{ data_modalChuyenTruong.tenCanBo }}
        </div>
        <hr />
        <div>
            <div class="row">
                <div class="col-sm-3">
                    <el-radio disabled v-model="radio" :label="1"
                        >Trường chuyển đến</el-radio
                    >
                </div>
            </div>
            <div class="row layout mt-4">
                <div class="col-sm-3">Tỉnh thành</div>
                <div class="col-sm-9">
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :data="getDataESelect.ESelectProvince"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['tenTinhThanh', 'maTinhThanh']"
                        v-model="selectedValue.selectedValueProvince"
                        @change="handleChangeTinhThanh"
                    />
                </div>
            </div>

            <div class="row layout mt-4">
                <div class="col-sm-3">Đơn vị quản lý</div>
                <div class="col-sm-9">
                    <ESelect
                        :reset="resetESelectUnitEducation"
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="
                            maTinhThanh === undefined || maTinhThanh === ''
                        "
                        :data="getDataESelect.ESelectUnitEducation"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="selectedValue.selectedValueUnitEducation"
                        @input="getDataChonTruongHoc"
                    />
                </div>
            </div>

            <div class="row layout mt-4">
                <div class="col-sm-3">Cấp học</div>
                <div class="col-sm-9">
                    <ESelect
                        :reset="resetESelectGradeLevel"
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="
                            maTinhThanh === undefined || maTinhThanh === ''
                        "
                        :data="getDataESelect.ESelectGradeLevel_MockData"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenTruongHoc', 'value']"
                        v-model="selectedValue.selectedValueGradeLevel"
                        @input="getDataChonTruongHoc"
                    />
                </div>
            </div>

            <div class="row layout mt-4">
                <div class="col-sm-3">
                    Trường học <span class="error">*</span>
                </div>
                <div class="col-sm-9">
                    <ESelect
                        :reset="resetESelectSchool"
                        @reset-completed="handleResetSchoolCompleted"
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="
                            maTinhThanh === undefined || maTinhThanh === ''
                        "
                        :data="getDataESelect.ESelectSchool"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['title', 'value']"
                        v-model="selectedValue.selectedValueSchool"
                    />
                    <span
                        v-if="
                            err_maTruongHoc.length > 0 &&
                            (maTinhThanh !== undefined || maTinhThanh !== '')
                        "
                        class="error"
                        >{{ err_maTruongHoc }}</span
                    >
                </div>
            </div>

            <div class="row layout mt-4">
                <div class="col-sm-3">Lý do chuyển trường</div>
                <div class="col-sm-9">
                    <CustomInput
                        v-model="selectedValue.selectedValue_lyDoChuyenTruong"
                        placeholder="Nhập ..."
                        :disabled="false"
                        :clearable="false"
                        size="small"
                        type="textarea"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="row layout mt-4">
                <div class="col-sm-3">
                    Ngày chuyển <span class="error">*</span>
                </div>
                <div class="col-sm-9">
                    <el-date-picker
                        v-model="selectedValue.date"
                        type="date"
                        placeholder="Ngày chuyển"
                        style="width: 100%"
                        format="dd/MM/yyyy"
                        size="small"
                    >
                    </el-date-picker>
                    <span
                        v-if="
                            err_date.length > 0 &&
                            (maTinhThanh !== undefined || maTinhThanh !== '')
                        "
                        class="error"
                        >{{ err_date }}</span
                    >
                </div>
            </div>
        </div>
        <span slot="footer">
            <CustomButton
                @click="closeModalChuyenTruong"
                label="Đóng"
                size="small"
                class="btn-default btn-close-modal"
            />
            <CustomButton
                label="Xác nhận"
                size="small"
                class="btn-default btn-add"
                @click="handleChuyenTruong"
            />
        </span>
    </el-dialog>
</template>
<script>
import ESelect from '@/components/ESelect.vue'

import {
    ESelectGradeLevel_MockData,
    ESelectSchoolBlock_MockData
} from '@/mock_data'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { mapState } from 'vuex'
import CustomButton from '@/components/CustomButton.vue'
import CustomInput from '@/components/CustomInput.vue'
import { convertToFormattedDate } from '@/utils/index'
import moment from 'moment'
import { validateField } from '@/utils'
export default {
    name: 'ModalChuyenTruong',
    components: {
        ESelect,
        CustomButton,
        CustomInput
    },
    props: {
        modalChuyenTruongVisible: {
            type: Boolean,
            required: true
        },

        closeModalChuyenTruong: {
            type: Function,
            required: true
        },
        data_modalChuyenTruong: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            radio: 1,
            resetESelectSchool: false,
            resetESelectGradeLevel: false,
            resetESelectUnitEducation: false,

            getDataESelect: {
                ESelectProvince: [],
                ESelectUnitEducation: [], //donvi
                ESelectGradeLevel_MockData: ESelectGradeLevel_MockData, //caphoc
                ESelectSchoolBlock: ESelectSchoolBlock_MockData, //khoihoc/nhomlop
                ESelectSchool: [] //truonghoc
            },

            selectedValue: {
                selectedValueProvince: [],
                selectedValueUnitEducation: [], //donvi
                selectedValueGradeLevel: [], //caphoc
                selectedValueSchoolBlock: [], //khoihoc/nhomlop
                selectedValueSchool: [], //truonghoc
                selectedValue_lyDoChuyenTruong: '',
                date: ''
            },
            maTinhThanh: '',

            err_maTruongHoc: '',
            err_date: ''
        }
    },
    mounted() {
        this.getCurrentDate()
        this.getDataTinhThanh()
        // this.getDataChonTruongHoc()
    },
    methods: {
        resetErr() {
            this.err_maTruongHoc = ''
            this.err_date = ''
        },
        getCurrentDate() {
            this.selectedValue.date = new Date()
        },

        handleResetSchoolCompleted() {
            this.resetESelectSchool = false
        },
        handleResetESelectUnitEducation() {
            this.resetESelectUnitEducation = false
        },
        handleResetESelectGradeLevel() {
            this.resetESelectGradeLevel = false
        },
        convertToValueArray(array) {
            return array.map((item) => item.value)
        },
        async handleChuyenTruong() {
            try {
                const maTruongHoc = this.selectedValue.selectedValueSchool?.value
                const formattedDate = convertToFormattedDate(
                    this.selectedValue.date
                )
                const namHoc = moment(formattedDate, 'DD/MM/YYYY').format('YYYY')
                const fieldsToValidate = [
                    {
                        name: 'err_maTruongHoc',
                        value: maTruongHoc,
                        errorField: 'err_maTruongHoc',
                        errorMessage: 'Vui lòng chọn trường học'
                    },
                    {
                        name: 'err_date',
                        value: this.selectedValue.date,
                        errorField: 'err_date',
                        errorMessage: 'Vui lòng chọn ngày chuyển trường'
                    }
                ]
                let isValid = true

                for (const field of fieldsToValidate) {
                    isValid = validateField(this, field, isValid)
                }
                if (isValid) {
                    const loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    })
                    const request_Header = {
                        token: this.authToken
                    }
                    const request_Data = {
                        maGiaoVien: this.data_modalChuyenTruong.maGiaoVien,
                        maTruongHoc: maTruongHoc,
                        namHoc: namHoc,
                        ngayChuyen: formattedDate,
                        lyDo: this.selectedValue.selectedValue_lyDoChuyenTruong
                    }
                    const response = await sendRequest(
                        Api.traCuu.traCuuCanBoGiaoVien.chuyenTruong,
                        request_Data,
                        request_Header
                    )
                    if (response.rc === 0) {
                        loading.close()
                        this.$notify({
                            title: 'Chuyển trường thành công',

                            type: 'success'
                        })
                        this.resetErr()
                        this.closeModalChuyenTruong()
                    } else {
                        this.$notify({
                            title: 'Chuyển trường thành lỗi',

                            type: 'error'
                        })
                        loading.close()
                    }
                }
            } catch (error) {
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },

        async getDataTinhThanh() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    start: 0,
                    limit: 1000
                }
                const response = await sendRequest(
                    Api.superAdmin.tinhThanh.danhSach,
                    null,
                    request_Header,
                    request_Params
                )

                this.getDataESelect.ESelectProvince = response.rows.map(
                    (item) => ({
                        ...item,
                        tenTinhThanh: `${item.tenTinhThanh} - [${item.maTinhThanh}]`
                    })
                )
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },
        async handleChangeTinhThanh() {
            this.maTinhThanh = this.selectedValue.selectedValueProvince?.value

            const maTinhThanh = this.selectedValue.selectedValueProvince?.value

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    maTinhThanh: maTinhThanh,
                    check: true,
                    start: 0,
                    limit: 9999
                }
                const response = await sendRequest(
                    Api.internal_api.dm_DonVi,
                    null,
                    request_Header,
                    request_Params
                )
                if (response.code === 200) {
                    loading.close()
                    this.getDataESelect.ESelectUnitEducation = response.rows
                }
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },

        async getDataChonTruongHoc() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const maDonVi = this.convertToValueArray(
                this.selectedValue.selectedValueUnitEducation
            )

            const capHoc = this.convertToValueArray(
                this.selectedValue.selectedValueGradeLevel
            )

            const request_Header = {
                token: this.authToken
            }

            const request_Data = {
                capHoc: capHoc,
                maDonVi: maDonVi
            }

            let response = await sendRequest(
                Api.internal_api.dm_School,
                request_Data,
                request_Header,
                null
            )

            if (response.statusResponse == 0) {
                loading.close()
                this.getDataESelect.ESelectSchool = response.rows.map((item) => ({
                    title: `${item.tenTruongHoc} - [${item.maTruongHoc}]`,
                    value: item.maTruongHoc
                }))
            }
            loading.close()
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        },
        ...mapState(['isCollapseLeftBar'])
    },

    watch: {
        maTinhThanh: {
            handler(newVal) {
                if (newVal === undefined || newVal === '') {
                    this.resetESelectUnitEducation = true
                    this.getDataESelect.ESelectUnitEducation = []
                    this.getDataESelect.ESelectGradeLevel_MockData = []
                    this.resetESelectGradeLevel = true
                    this.getDataESelect.ESelectSchool = []
                    this.resetESelectSchool = true
                }
            },
            deep: true
        },

        'selectedValue.selectedValueUnitEducation': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        },
        'selectedValue.selectedValueGradeLevel': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        },
        'selectedValue.selectedValueSchoolBlock': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        }
    }
}
</script>
<style scoped>
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.bg-search,
.excel {
    background-color: var(--teal);
}
.btn-add {
    background-color: var(--light-green);
}

.btn-up {
    background-color: var(--yellow);
}
.btn-update {
    background-color: var(--yellow);
}
.btn-delete {
    background-color: var(--orange);
}
.btn-close-modal {
    background-color: var(--orange);
}
.layout,
.header-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
}
hr {
    border: 1px solid #8080807d;
    margin-bottom: 30px;
}

::v-deep .el-dialog__header {
    padding: 20px 20px 0px !important;
}
::v-deep .el-dialog__body {
    padding: 0px 20px 20px 20px !important;
}
::v-deep .el-radio__input.is-disabled .el-radio__inner,
.el-radio__input.is-disabled.is-checked .el-radio__inner {
    background: gray;
}

::v-deep .el-radio__input.is-checked .el-radio__inner {
    border-color: gray;
}
::v-deep .el-radio__input.is-disabled + span.el-radio__label {
    color: black;
    font-weight: 600;
}
</style>
