<template>
    <div>
        <CustomBreadcrumb
            :title="'Tra cứu'"
            content="TRA CỨU CÁN BỘ, GIÁO VIÊN"
        />
        <CustomContentUI>
            <div class="row">
                <div class="col-4">
                    <label>Đơn vị quản lý</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="
                                this.authUser.role === 5 ||
                                this.authUser.role === 4
                            "
                            :data="this.dataChonDonVi_Store"
                            :placeholder="'Chọn đơn vị'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenDonVi', 'maDonVi']"
                            v-model="selectedValue.selectedValueUnitEducation"
                            @change="getDataChonTruongHoc"
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Cấp học</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="this.authUser.role === 5"
                            :data="getDataESelect.ESelectGradeLevel"
                            :placeholder="'Chọn cấp học'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenTruongHoc', 'value']"
                            v-model="selectedValue.selectedValueGradeLevel"
                            @change="getDataChonTruongHoc"
                        />
                    </div>
                </div>
                <div class="col-4">
                    <label>Trường học</label>
                    <div>
                        <ESelect
                            :disabled="this.authUser.role === 5"
                            :reset="resetESelectSchool"
                            @reset-completed="handleResetSchoolCompleted"
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :data="getDataESelect.ESelectSchool"
                            :placeholder="'Chọn trường học'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['title', 'value']"
                            v-model="selectedValue.selectedValueSchool"
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Năm học<span class="span">*</span></label>
                    <ESelectYear
                        v-model="selectedValue.selectedValueYear"
                        placeholder="Chọn năm"
                        size="small"
                        :no-data-text="'Không có bản ghi nào'"
                        :no-match-text="'Không tìm thấy bản ghi nào'"
                    ></ESelectYear>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-4">
                    <label>Từ khóa</label>

                    <CustomInput
                        placeholder="Tên/mã/số CMND, ..."
                        v-model="selectedValue.input_tuKhoa"
                        :disabled="false"
                        :clearable="false"
                        :show-close-icon="true"
                        size="small"
                    >
                    </CustomInput>
                </div>
                <div class="col-4">
                    <label>T.Độ chuyên môn nghiệp vụ</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="
                                getDataESelect.ESelectProfessionalQualifications
                            "
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['constantTitle', 'mappingCode']"
                            v-model="
                                selectedValue.selectedValueProfessionalQualifications
                            "
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Giới tính</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectGender"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['title', 'value']"
                            v-model="selectedValue.selectedValueGender"
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Loại cán bộ</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectTypeOfCadre"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['title', 'value']"
                            v-model="selectedValue.selectedValueTypeOfCadre"
                        />
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-4">
                    <label>Nội dung hiển thị <span class="span">*</span></label>
                    <ESelectSearch
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectDisplayContent"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['title', 'key', 'value']"
                        v-model="selectedValue.selectedValueDisplayContent"
                    />
                </div>
                <div class="col-4">
                    <label>Tìm kiếm nâng cao</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectAdvancedSearch"
                        :placeholder="'Chọn nội dung'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueAdvancedSearch"
                        @input="handleTimKiemNangCao"
                    />
                </div>
                <div class="col-2">
                    <label>Trạng thái</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="this.dataChonTrangthaicanbo_Store"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['constantTitle', 'mappingCode']"
                            v-model="selectedValue.selectedValueStatus"
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2 mt-2" v-if="hienThiNangCao.ngaySinh">
                    <label>Ngày sinh</label>
                    <date-picker
                        :config="configDatePicker"
                        placeholder="Chọn ngày"
                        v-model="selectedValue.date_ngaySinh"
                        class="custom-date-picker"
                        :clear-button="true"
                    >
                    </date-picker>
                </div>
                <div class="col-2 mt-2" v-if="hienThiNangCao.doTuoi">
                    <label>Độ tuổi</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectAge"
                        :placeholder="'Chọn nội dung'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['title', 'value']"
                        v-model="selectedValue.selectedValueAge"
                    />
                </div>
                <div class="col-2 mt-2" v-if="hienThiNangCao.maTonGiao">
                    <label>Tôn giáo</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="this.dataChonTongiao_Store"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['constantTitle', 'mappingCode']"
                            v-model="selectedValue.selectedValueReligion"
                        />
                    </div>
                </div>
                <div class="col-2 mt-2" v-if="hienThiNangCao.chucVu">
                    <label>Chức vụ</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="this.dataChonChucvu_Store"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['constantTitle', 'mappingCode']"
                            v-model="selectedValue.selectedValuePosition"
                        />
                    </div>
                </div>
                <!-- <div class="col-2 mt-2" v-if="hienThiNangCao.dchiThtru">
                    <label>Địa chỉ thường trú</label>
                    <el-input
                        placeholder="Nhập địa chỉ thường trú ..."
                        v-model="selectedValue.input_diaChiThuongTru"
                        size="small"
                    ></el-input>
                </div> -->
                <div class="col-2 mt-2" v-if="hienThiNangCao.chucVu">
                    <label>Khu vực</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="this.dataChonKhuvuc_Store"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['constantTitle', 'mappingCode']"
                            v-model="selectedValue.selectedValueArea"
                        />
                    </div>
                </div>
                <div class="col-2 mt-2" v-if="hienThiNangCao.dangVien">
                    <label>Đảng viên</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectPartisan"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['name', 'value']"
                            v-model="selectedValue.selectedValuePartisan"
                        />
                    </div>
                </div>

                <div class="col-2 mt-2" v-if="hienThiNangCao.bacLuong">
                    <label>Bậc lương</label>
                    <el-input
                        placeholder="Bậc lương ..."
                        v-model="selectedValue.input_bacLuong"
                        size="small"
                    ></el-input>
                </div>
                <div class="col-2 mt-2" v-if="hienThiNangCao.heSoLuong">
                    <label>Hệ số lương</label>
                    <el-input
                        placeholder="Hệ số lương ..."
                        v-model="selectedValue.input_heSoLuong"
                        size="small"
                    ></el-input>
                </div>
                <div class="col-2 mt-2" v-if="hienThiNangCao.moetCode">
                    <label>Mã định danh bộ GDĐT</label>
                    <el-input
                        placeholder="Mã định danh bộ GDĐT ..."
                        v-model="selectedValue.input_maDinhDanh"
                        size="small"
                    ></el-input>
                </div>
                <div class="col-2 mt-2" v-if="hienThiNangCao.maMonHoc">
                    <label>Môn dạy</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="this.dataChonMondayGV_Store"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['constantTitle', 'mappingCode']"
                            v-model="selectedValue.selectedValuesubject"
                        />
                    </div>
                </div>
                <div class="col-2 mt-2" v-if="hienThiNangCao.maDanToc">
                    <label>Dân tộc</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="this.dataChonDantoc_Store"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['constantTitle', 'mappingCode']"
                            v-model="selectedValue.selectedValueNation"
                        />
                    </div>
                </div>
                <div class="col-4 mt-2" v-if="hienThiNangCao.loaiHopDong">
                    <label>Hình thức hợp đồng</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="this.dataChonHinhthuchopdong_Store"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['constantTitle', 'mappingCode']"
                            v-model="selectedValue.selectedValueContractForm"
                        />
                    </div>
                </div>
                <!-- <div class="col-2 mt-2" v-if="hienThiNangCao.soDienThoaiDiDong">
                    <label>Số điện thoại</label>
                    <el-input
                        placeholder="Số điện thoại ..."
                        v-model="selectedValue.input_soDienThoai"
                        size="small"
                    ></el-input>
                </div> -->
                <div
                    class="col-4 mt-2"
                    v-if="hienThiNangCao.trinhDoDaoTaoCnChinh"
                >
                    <label>Trình độ đào tạo chuyên ngành chính</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="this.dataChonTrinhdodaotao_Store"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['constantTitle', 'mappingCode']"
                            v-model="selectedValue.selectedValueDegreeTraining"
                        />
                    </div>
                </div>
                <div class="col-4 mt-2" v-if="hienThiNangCao.dgKqChuanNgheNghiep">
                    <label>Đánh giá chuẩn nghề nghiệp</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="this.dataChonDanhgianghenghiep_Store"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['constantTitle', 'mappingCode']"
                            v-model="
                                selectedValue.selectedValueProfessionalStandardAssessment
                            "
                        />
                    </div>
                </div>
            </div>
            <div class="btn-search">
                <CustomButton
                    @click="handleSearch"
                    label="Tìm kiếm"
                    size="small"
                    class="btn-default bg-search"
                />
                <CustomButton
                    :disabled="this.tableData.length === 0"
                    label="Xuất Excel"
                    size="small"
                    class="btn-default"
                    @click="handleExportExcel"
                    style="background-color: #1e6050"
                />
            </div>
        </CustomContentUI>

        <div
            class="layout-btn"
            :style="{
                'max-width': leftBarWidth()
            }"
        >
            <div>
                <chonSoLuongBanGhi @chonXongSoLuong="ChonSoLuongBanGhi($event)" />
            </div>
            <div>
                <CustomButton
                    label="Đẩy dữ liệu"
                    size="small"
                    class="btn-default btn-up"
                    @click="handleDayDuLieu"
                />
                <CustomButton
                    label="Xóa"
                    size="small"
                    class="btn-default btn-delete"
                    @click="handleDelete"
                />
                <CustomButton
                    label="Xóa mã định danh Bộ GD&ĐT"
                    size="small"
                    class="btn-default btn-delete"
                    @click="handleXoaMaDinhDanh"
                />
            </div>
        </div>
        <CustomTable :max-width="leftBarWidth()">
            <template v-slot:header>
                <tr>
                    <th class="text-thead">
                        <input
                            type="checkbox"
                            v-model="selectAllChecked"
                            @change="handleSelectAll"
                            :disabled="tableData.length === 0"
                        />
                    </th>
                    <th class="text-thead">STT</th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(0)">
                        Tên trường
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(1)">
                        Mã trường
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(2)">
                        Mã cán bộ
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(3)">
                        Mã định danh bộ GD&ĐT
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(4)">
                        Họ tên
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(5)">
                        Ngày sinh
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(6)">
                        Giới tính
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(7)">
                        Trạng thái
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(8)">
                        Dân tộc
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(9)">
                        Loại cán bộ
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(10)"
                    >
                        Vị trí làm việc
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(11)"
                    >
                        Hình thức hợp đồng
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(12)"
                    >
                        T.Độ chuyên môn nghiệp vụ
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(13)"
                    >
                        Môn dạy
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(14)"
                    >
                        Số điện thoại
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(16)"
                    >
                        Quê quán
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(17)"
                    >
                        Nơi sinh
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(18)"
                    >
                        Năm sinh
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(19)"
                    >
                        Số CMND
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(20)"
                    >
                        Hình thức đào tạo
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(21)"
                    >
                        Độ tuổi
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(22)"
                    >
                        Trình độ đào tạo
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(23)"
                    >
                        Đánh giá chuẩn nghề nghiệp
                    </th>
                    <th class="text-thead">Phân công giảng dạy</th>
                    <th class="text-thead">Thao tác</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21" class="text-tbody">
                            Không có bản ghi nào.
                        </td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td class="text-tbody">
                        <input
                            type="checkbox"
                            :checked="item.isChecked"
                            @change="toggleCheckbox(item)"
                        />
                    </td>
                    <td class="text-tbody">
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td
                        class="text-tbody text-left"
                        v-if="defaultDisplayTable.includes(0)"
                    >
                        {{ item.tenTruongHoc }}
                    </td>
                    <td class="text-tbody" v-if="defaultDisplayTable.includes(1)">
                        {{ item.maTruongHoc }}
                    </td>
                    <td class="text-tbody" v-if="defaultDisplayTable.includes(2)">
                        <span
                            style="color: #337ab7; cursor: pointer"
                            @click="handleOpenDisabledModalChiTiet(item)"
                        >
                            {{ item.maGiaoVien }}
                        </span>
                    </td>
                    <td class="text-tbody" v-if="defaultDisplayTable.includes(3)">
                        {{ item.moetCode }}
                    </td>

                    <td
                        class="text-tbody text-left"
                        v-if="defaultDisplayTable.includes(4)"
                    >
                        <span
                            style="color: #337ab7; cursor: pointer"
                            @click="handleOpenDisabledModalChiTiet(item)"
                        >
                            {{ item.tenCanBo }}
                        </span>
                    </td>
                    <td class="text-tbody" v-if="defaultDisplayTable.includes(5)">
                        {{ item.ngaySinh }}
                    </td>
                    <td class="text-tbody" v-if="defaultDisplayTable.includes(6)">
                        {{ item.gioiTinh }}
                    </td>
                    <td class="text-tbody" v-if="defaultDisplayTable.includes(7)">
                        {{ item.trangThai }}
                    </td>
                    <td class="text-tbody" v-if="defaultDisplayTable.includes(8)">
                        {{ item.maDanToc }}
                    </td>
                    <td class="text-tbody" v-if="defaultDisplayTable.includes(9)">
                        {{ item.loaiCanBo }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(10)"
                    >
                        {{ item.chucVu }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(11)"
                    >
                        {{ item.loaiHopDong }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(12)"
                    >
                        {{ item.maTrinhDoChuyenMonCaoNhat }}
                    </td>
                    <td
                        class="text-tbody text-left"
                        v-if="defaultDisplayTable.includes(13)"
                    >
                        {{ item.maMonHoc }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(14)"
                    >
                        {{ item.soDienThoaiDiDong }}
                    </td>
                    <td
                        class="text-tbody text-left"
                        v-if="defaultDisplayTable.includes(16)"
                    >
                        {{ item.queQuan }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(17)"
                    >
                        {{ item.noiSinh }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(18)"
                    >
                        {{ item.namSinh }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(19)"
                    >
                        {{ item.soCmnd }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(20)"
                    >
                        {{ item.hinhThucDaoTao }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(21)"
                    >
                        {{ item.doTuoi }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(22)"
                    >
                        {{ item.trinhDoDaoTaoCnChinh }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(23)"
                    >
                        {{ item.dgKqChuanNgheNghiep }}
                    </td>

                    <td class="text-tbody">
                        <span v-if="item.loaiCanBo != 'Nhân viên'">
                            <CustomButton
                                label=""
                                size="small"
                                icon=" el-icon-info"
                                class="btn-default btn-info"
                                @click="handleOpenModalPhanCongGiangDay(item)"
                            />
                        </span>
                        <span v-if="item.loaiCanBo == 'Nhân viên'"> </span>
                    </td>
                    <td class="text-tbody">
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Chỉnh sửa"
                            placement="top"
                        >
                            <CustomButton
                                label=""
                                size="small"
                                icon="el-icon-edit-outline"
                                @click="handleOpenModalChitiet(item)"
                                class="btn-default btn-update"
                            />
                        </el-tooltip>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Chuyển trường"
                            placement="top"
                        >
                            <CustomButton
                                label=""
                                size="small"
                                icon="el-icon-connection"
                                @click="handleOpenModalChuyenTruong(item)"
                                class="btn-default bg-search"
                            />
                        </el-tooltip>
                    </td>
                </tr>
            </template>
        </CustomTable>

        <CustomPagination
            v-show="total_rows > 0"
            :tongbanghi="total_rows"
            :soluonghienthi="soLuongBanGhiHienThi"
            :batdau="trangbatdau"
            @pageChange="layLai($event)"
        >
        </CustomPagination>
        <el-dialog
            :visible="deleteErrorModalVisible"
            @close="closeModalDeleteError"
            width="60%"
            class="custom-dialog"
        >
            <span slot="title" class="custom-dialog-title"> Thông báo </span>
            <div style="color: red">
                Không thể xóa. Cán bộ cần xóa đang là giáo viên chủ nhiệm của lớp
                :
            </div>
            <CustomTable>
                <template v-slot:header>
                    <tr>
                        <th class="text-thead">STT</th>
                        <th class="text-thead">Mã giáo viên</th>
                        <th class="text-thead">Mã lớp chủ nhiệm</th>
                        <th class="text-thead">Tên lớp</th>
                    </tr>
                </template>
                <template v-slot:body>
                    <tr
                        v-for="(data, index) in dataModalDeleteError"
                        :key="index"
                    >
                        <td class="text-tbody">
                            {{ index + 1 }}
                        </td>
                        <td class="text-tbody">
                            {{ data.maGvcn }}
                        </td>
                        <td class="text-tbody">
                            {{ data.maLopHoc }}
                        </td>
                        <td class="text-tbody">
                            {{ data.tenLopHoc }}
                        </td>
                    </tr>
                </template>
            </CustomTable>
        </el-dialog>

        <ModalChiTietCanbogiaovien
            :chiTietCanboGvModalVisible="chiTietCanboGvModalVisible"
            :closeModalCanboGv="closeModalCanboGv"
            :id_modalChiTietCanboGv="id_modalChiTietCanboGv"
            @handleSearch="handleSearch"
        />
        <DisabledModalChitietCanbogiaovien
            :chiTietCanboGvDisabledModalVisible="
                chiTietCanboGvDisabledModalVisible
            "
            :closeDisabledModalCanboGv="closeDisabledModalCanboGv"
            :id_DisabledModalChiTietCanboGv="id_DisabledModalChiTietCanboGv"
        />
        <ModalPhanCongGiangDay
            :phanCongGiangDayModalVisible="phanCongGiangDayModalVisible"
            :closeModalPhanCongGiangDay="closeModalPhanCongGiangDay"
            :data_modalPhanCongGiangDay="data_modalPhanCongGiangDay"
            :selectedValueYear="selectedValue.selectedValueYear"
        />
        <ModalChuyenTruong
            :data_modalChuyenTruong="data_modalChuyenTruong"
            :modalChuyenTruongVisible="modalChuyenTruongVisible"
            :closeModalChuyenTruong="closeModalChuyenTruong"
        />
    </div>
</template>
<script>
import ModalChiTietCanbogiaovien from './ModalChitietCanbogiaovien/ModalChitietCanbogiaovien.vue'
import DisabledModalChitietCanbogiaovien from './ModalChitietCanbogiaovien/DisabledModalChitietCanbogiaovien.vue'
import ModalPhanCongGiangDay from './ModalChitietCanbogiaovien/ModalPhanCongGiangDay.vue'
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'
import chonSoLuongBanGhi from '@/components/chonSoLuongBanGhi.vue'
import ESelectYear from '@/components/ESelectYear.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import ESelect from '@/components/ESelect.vue'
import ESelectSearch from '@/components/ESelectSearch.vue'
import CustomButton from '@/components/CustomButton.vue'
import axios from 'axios'

import {
    ESelectDisplayContent_MockData_TraCuuCanBoGiaoVien,
    ESelectGender_MockData,
    ESelectTypeOfCadre_MockData,
    ESelectAdvancedSearch_MockData_TraCuuCanBoGiaoVien,
    ESelectAge_MockData,
    ESlectYesOfNo_MockData,
    ESelectProfessionalQualifications_TraCuuCBGV,
    ESelectTrueOfFalse
} from '@/mock_data'
import datePicker from 'vue-bootstrap-datetimepicker'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { mapState } from 'vuex'
import { convertCapHocList, currentYear, filterCapHocList } from '@/utils'
import ModalChuyenTruong from './ModalChitietCanbogiaovien/ModalChuyenTruong.vue'
import CustomInput from '@/components/CustomInput.vue'
import CustomContentUI from '@/components/CustomContentUI.vue'
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'TraCuuCanBoGiaoVien',
    components: {
        ModalChiTietCanbogiaovien,
        DisabledModalChitietCanbogiaovien,
        ModalPhanCongGiangDay,
        CustomBreadcrumb,
        ESelect,
        ESelectYear,
        CustomButton,
        ESelectSearch,
        chonSoLuongBanGhi,
        CustomPagination,
        datePicker,
        ModalChuyenTruong,
        CustomInput,
        CustomContentUI,
        CustomTable
    },
    data() {
        return {
            defaultDisplayTable:
                ESelectDisplayContent_MockData_TraCuuCanBoGiaoVien.slice(
                    0,
                    8
                ).map((item) => item.value),
            selectAllChecked: false,
            selectedItems: [],
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            soLuongBanGhiHienThi: 25,
            trangbatdau: false,
            configDatePicker: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
                showTodayButton: true,
                locale: 'vi'
            },
            hienThiNangCao: {
                ngaySinh: false,
                doTuoi: false,
                maTonGiao: false,
                chucVu: false,
                dchiThtru: false,
                maKhuVuc: false,
                dangVien: false,
                bacLuong: false,
                heSoLuong: false,
                moetCode: false,
                maMonHoc: false,
                maDanToc: false,
                loaiHopDong: false,
                soDienThoaiDiDong: false,
                trinhDoDaoTaoCnChinh: false,
                dgKqChuanNgheNghiep: false
            },
            chiTietCanboGvModalVisible: false,
            id_modalChiTietCanboGv: 0,
            chiTietCanboGvDisabledModalVisible: false,
            id_DisabledModalChiTietCanboGv: 0,
            deleteErrorModalVisible: false,
            phanCongGiangDayModalVisible: false,
            data_modalPhanCongGiangDay: {},
            modalChuyenTruongVisible: false,
            data_modalChuyenTruong: {},
            resetESelectSchool: false,

            tableData: [],
            dataModalDeleteError: [],
            request_Data: {
                capHoc: [],
                colExcels: [],
                dataSearchList: [],
                keySearch: '',
                khoiHoc: [],
                listMaDonViReq: [],
                listMaTruongHocReq: [],
                maNhomLopMn: '',
                namHoc: '',
                start: 0,
                limit: 25,
                tenGvcn: ''
            },

            getDataESelect: {
                ESelectUnitEducation: [], //donvi
                ESelectGradeLevel: [], //caphoc
                ESelectSchool: [], //truonghoc
                ESelectProfessionalQualifications:
                    ESelectProfessionalQualifications_TraCuuCBGV, //trinhdochuyenmonnghiepvu
                ESelectGender: ESelectGender_MockData, //gioitinh
                ESelectTypeOfCadre: ESelectTypeOfCadre_MockData, //loaicanbo
                ESelectStatus: [], //trangthai
                ESelectDisplayContent:
                    ESelectDisplayContent_MockData_TraCuuCanBoGiaoVien, //noidunghienthi
                ESelectAdvancedSearch:
                    ESelectAdvancedSearch_MockData_TraCuuCanBoGiaoVien, //timkiemnangcao
                ESelectAge: ESelectAge_MockData, //dotuoi
                ESelectReligion: [], //tongiao
                ESelectPosition: [], //chucvu

                ESelectArea: [], //khuvuc
                ESelectPartisan: ESlectYesOfNo_MockData, //dangvien
                ESelectsubject: [], //monday
                ESelectnation: [], //dantoc
                ESelectContractForm: [], //hinhthuchopdong
                ESelectDegreeTraining: [], //trinhdodaotao
                ESelectProfessionalStandardAssessment: [] //danhgiachuannghenghiep
            },

            selectedValue: {
                selectedValueUnitEducation: [], //donvi
                selectedValueGradeLevel: [], //caphoc
                selectedValueSchool: [], //truonghoc
                selectedValueYear: '', //namHoc
                input_tuKhoa: '', //tuKhoa
                selectedValueProfessionalQualifications: [], //trinhdochuyenmonnghiepvu
                selectedValueGender: [], //gioitinh
                selectedValueTypeOfCadre: [], //loaicanbo
                selectedValueStatus: [], //trangthai
                selectedValueDisplayContent:
                    ESelectDisplayContent_MockData_TraCuuCanBoGiaoVien.slice(
                        0,
                        8
                    ), //noidunghienthi
                selectedValueAdvancedSearch: [], //timkiemnangcao
                date_ngaySinh: '', //ngaySinh
                selectedValueAge: [], //dotuoi
                selectedValueReligion: [], //tongiao
                selectedValuePosition: [], //chucvu
                input_diaChiThuongTru: '', //diachithuongtru
                selectedValueArea: [], //khuvuc
                selectedValuePartisan: [], //dangvien
                input_bacLuong: '', //bacluong
                input_heSoLuong: '', //heSoLuong
                input_maDinhDanh: '', //maDinhDanh
                selectedValuesubject: [], //monday
                selectedValueNation: [], //dantoc
                selectedValueContractForm: [], //hinhthuchopdong
                input_soDienThoai: '', //soDienThoai
                selectedValueDegreeTraining: [], //trinhdodaotao
                selectedValueProfessionalStandardAssessment: [] //danhgiachuannghenghiep
            }
        }
    },
    methods: {
        handleTimKiemNangCao(value) {
            const selectedValues = value.map((item) => item.value)

            for (const key in this.hienThiNangCao) {
                this.hienThiNangCao[key] = selectedValues.includes(key)
            }
        },
        toggleCheckbox(item) {
            this.selectAllChecked = false
            item.isChecked = !item.isChecked
            if (item.isChecked) {
                this.selectedItems.push(item) // Thêm item vào mảng selectedItems khi checkbox được chọn
            } else {
                const index = this.selectedItems.indexOf(item)
                if (index > -1) {
                    this.selectedItems.splice(index, 1) // Xóa item khỏi mảng selectedItems khi checkbox bị bỏ chọn
                }
            }
        },

        handleSelectAll() {
            this.tableData.forEach((item) => {
                item.isChecked = this.selectAllChecked // Đặt trạng thái của tất cả các checkbox bằng với checkbox "checkboxAll"
                if (this.selectAllChecked && !this.selectedItems.includes(item)) {
                    this.selectedItems.push(item) // Thêm tất cả các item vào mảng selectedItems khi chọn tất cả
                } else if (!this.selectAllChecked) {
                    this.selectedItems = [] // Xóa tất cả các item khỏi mảng selectedItems khi bỏ chọn tất cả
                }
            })
        },
        ChonSoLuongBanGhi(e) {
            this.total_rows = 0
            this.soLuongBanGhiHienThi = e.soluong
        },
        checkTruocKhiTim() {
            this.trangbatdau = !this.trangbatdau
        },
        layLai(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        closeModalCanboGv() {
            this.chiTietCanboGvModalVisible = false
        },
        closeDisabledModalCanboGv() {
            this.chiTietCanboGvDisabledModalVisible = false
        },
        closeModalDeleteError() {
            this.deleteErrorModalVisible = false
        },
        closeModalPhanCongGiangDay() {
            this.phanCongGiangDayModalVisible = false
        },
        async handleOpenModalChuyenTruong(item) {
            this.data_modalChuyenTruong = item
            this.modalChuyenTruongVisible = true
        },
        closeModalChuyenTruong() {
            this.modalChuyenTruongVisible = false
        },
        handleResetSchoolCompleted() {
            this.resetESelectSchool = false
        },

        async handleDelete() {
            if (this.selectedItems.length === 0) {
                this.$message({
                    type: 'warning',
                    message: 'Vui lòng chọn học sinh cần xóa'
                })
                return
            }

            const listMa = this.selectedItems.map((item) => item.maGiaoVien)
            const canBoTheoNamIds = this.selectedItems.map(
                (item) => item.canBoTheoNamId
            )

            const namHoc = this.selectedValue.selectedValueYear
            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                listMa: listMa,
                canBoTheoNamIds: canBoTheoNamIds,
                namHoc: namHoc || currentYear
            }

            this.$confirm('Xác nhận xóa các bản ghi đã chọn?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    })
                    const response = await sendRequest(
                        Api.traCuu.traCuuCanBoGiaoVien.xoaCanBoGv,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        loading.close()
                        this.tableData = this.tableData.filter(
                            (item) => !item.isChecked
                        )
                        this.selectAllChecked = false
                        this.selectedItems = []
                        await this.handleSearch()
                        this.$message({
                            type: 'success',
                            message: 'Xóa thành công'
                        })
                    } else if (response.rc == 999) {
                        loading.close()
                        this.dataModalDeleteError = response.rows
                        this.deleteErrorModalVisible = true
                    } else {
                        loading.close()
                        this.selectAllChecked = false
                        this.tableData = this.tableData.filter(
                            (item) => !item.isChecked
                        )

                        this.selectedItems = []
                        this.$message({
                            type: 'info',
                            message: response.rd
                        })
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    })
                })
        },
        async handleXoaMaDinhDanh() {
            if (this.selectedItems.length === 0) {
                this.$message({
                    type: 'warning',
                    message: 'Vui lòng chọn ít nhất 1 học sinh'
                })
                return
            }
            const lstId = this.selectedItems.map((item) => item.canBoTheoNamId)

            const namHoc = this.selectedValue.selectedValueYear
            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                loaiDuLieu: 1,
                lstId: lstId,
                namHoc: namHoc || currentYear
            }

            this.$confirm(
                'Xác nhận xóa mã định danh các học sinh đã chọn?',
                'Thông báo',
                {
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy',
                    type: 'warning'
                }
            )
                .then(async () => {
                    const response = await sendRequest(
                        Api.traCuu.traCuuHocSinh.xoaMaDinhDanhLenBo,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        this.tableData = this.tableData.filter(
                            (item) => !item.isChecked
                        )

                        this.selectedItems = []
                        this.selectAllChecked = false
                        this.$message({
                            type: 'success',
                            message: 'Xóa Mã định danh Bộ GĐ&ĐT thành công'
                        })
                        await this.handleSearch()
                    } else {
                        this.$message({
                            type: 'info',
                            message: response.rd
                        })
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    })
                })
        },
        async handleDayDuLieu() {
            if (this.selectedItems.length === 0) {
                this.$message({
                    type: 'warning',
                    message: 'Vui lòng chọn ít nhất 1 học sinh'
                })
                return
            }
            const findCommonElement = (arr) => {
                if (arr.every((item) => item === arr[0])) {
                    return arr[0]
                } else {
                    return []
                }
            }
            const listNopRieng = this.selectedItems
                .map((item) => `${item.maGiaoVien}`)
                .toString()

            const maTruongHoc = findCommonElement(
                this.selectedItems?.map((item) => item.maTruongHoc)
            )

            const namHoc = this.selectedValue.selectedValueYear
            const request_Header = {
                token: this.authToken
            }

            const request_Data = {
                hocKy: 1,
                namHoc: namHoc || currentYear,
                truongCanDayRequests: [
                    {
                        maTruong: maTruongHoc,
                        buocDays: [2],
                        listNopRieng: listNopRieng
                    }
                ]
            }

            this.$confirm(
                'Xác nhận xóa mã định danh các học sinh đã chọn?',
                'Thông báo',
                {
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy',
                    type: 'warning'
                }
            )
                .then(async () => {
                    const response = await sendRequest(
                        Api.traCuu.traCuuHocSinh.dayDuLieu,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        this.tableData = this.tableData.filter(
                            (item) => !item.isChecked
                        )

                        this.selectedItems = []
                        this.selectAllChecked = false
                        this.$message({
                            type: 'success',
                            message: response.rd
                        })
                        await this.handleSearch()
                    } else {
                        this.$message({
                            type: 'info',
                            message: response.rd
                        })
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    })
                })
        },
        handleOpenModalChitiet(item) {
            this.id_modalChiTietCanboGv = item.canBoTheoNamId

            this.chiTietCanboGvModalVisible = true
        },
        handleOpenDisabledModalChiTiet(item) {
            this.id_DisabledModalChiTietCanboGv = item.canBoTheoNamId
            this.chiTietCanboGvDisabledModalVisible = true
        },
        handleOpenModalPhanCongGiangDay(item) {
            this.data_modalPhanCongGiangDay = item
            this.phanCongGiangDayModalVisible = true
        },
        async handleExportExcel() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const noidunghienthi = this.selectedValue.selectedValueDisplayContent
            const defaultDisplayTable = noidunghienthi.map((item) => item.value)
            this.defaultDisplayTable = defaultDisplayTable
            const colExcels = noidunghienthi.map((item) => ({
                key: item.key,
                stt: item.value,
                name: item.title
            }))

            const timkiemnangcao = this.convertToValueArray(
                this.selectedValue.selectedValueAdvancedSearch
            )

            const keyMappings_dataSreachList = {
                ngaySinh: 'date_ngaySinh',
                doTuoi: 'selectedValueAge',
                maTonGiao: 'selectedValueReligion',
                chucVu: 'selectedValuePosition',
                dchiThtru: 'input_diaChiThuongTru',
                maKhuVuc: 'selectedValueArea',
                dangVien: 'selectedValuePartisan',
                bacLuong: 'input_bacLuong',
                heSoLuong: 'input_heSoLuong',
                moetCode: 'input_maDinhDanh',
                maMonHoc: 'selectedValuesubject',
                maDanToc: 'selectedValueNation',
                loaiHopDong: 'selectedValueContractForm',
                soDienThoaiDiDong: 'input_soDienThoai',
                trinhDoDaoTaoCnChinh: 'selectedValueDegreeTraining',
                dgKqChuanNgheNghiep: 'selectedValueProfessionalStandardAssessment'
                // Thêm các ánh xạ key - giá trị tương ứng ở đây
            }

            const dataSearchList = timkiemnangcao
                .map((key) => {
                    const valueKey = keyMappings_dataSreachList[key]
                    const inputValue = this.selectedValue[valueKey]
                    if (valueKey && inputValue) {
                        let value
                        if (Array.isArray(inputValue)) {
                            value = inputValue.map((item) => item.value)
                            return {
                                key: key,
                                values: value
                            }
                        } else if (typeof inputValue === 'object') {
                            value = inputValue.value
                        } else {
                            value = inputValue
                        }
                        return {
                            key: key,
                            value: value
                        }
                    }
                    return null
                })
                .filter((item) => item !== null)

            const loaiCanBo =
                this.selectedValue.selectedValueTypeOfCadre?.value || null
            const namHoc =
                this.selectedValue.selectedValueYear || new Date().getFullYear()
            const gioiTinh = this.selectedValue.selectedValueGender?.value || null
            const keyword = this.selectedValue.input_tuKhoa || null

            const capTruong = this.convertToValueArray(
                this.selectedValue.selectedValueGradeLevel
            )?.map(Number)

            const trangThai =
                +this.selectedValue.selectedValueStatus?.value || null

            const maDonVis = this.convertToValueArray(
                this.selectedValue.selectedValueUnitEducation
            )
            const maTruongs = this.convertToValueArray(
                this.selectedValue.selectedValueSchool
            )
            const maTrinhDoChuyenMonCaoNhat = this.convertToValueArray(
                this.selectedValue.selectedValueProfessionalQualifications
            )?.map(Number)
            this.$confirm('Xác nhận tải xuống?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })

                .then(async () => {
                    const requestData = {
                        loaiCanBo: loaiCanBo,
                        namHoc: namHoc,
                        gioiTinh: gioiTinh,
                        limit: 50000,
                        start: 0,
                        keyword: keyword,

                        capTruong: capTruong,
                        maTruongs: maTruongs,
                        dataSreachList: dataSearchList,
                        trangThai: trangThai,

                        maTrinhDoChuyenMonCaoNhat: maTrinhDoChuyenMonCaoNhat,
                        colExcels: colExcels,
                        maDonVis: maDonVis
                    }
                    const domain = process.env.VUE_APP_BACKEND_BASE_URL
                    const response = await axios.post(
                        `${domain}/csdlgd-admin/ExportExcel/GiaoVien`,
                        requestData,
                        {
                            headers: {
                                token: this.authToken,
                                'Content-Type': 'application/json'
                            },
                            responseType: 'blob' // Set responseType là 'blob'
                        }
                    )

                    const blob = new Blob([response.data])
                    const blobUrl = window.URL.createObjectURL(blob)

                    const downloadLink = document.createElement('a')
                    downloadLink.href = blobUrl
                    downloadLink.download = 'DanhSachCanBoGiaoVien.xlsx'
                    downloadLink.style.display = 'none'

                    document.body.appendChild(downloadLink)
                    downloadLink.click()

                    window.URL.revokeObjectURL(blobUrl)
                    loading.close()
                })
                .catch((error) => {
                    loading.close()
                    if (error.response?.data.rd) {
                        this.$message({
                            type: 'error',
                            message: error.response?.data.rd
                        })
                    }
                })
        },
        async handleSearch() {
            const noidunghienthi = this.selectedValue.selectedValueDisplayContent
            const defaultDisplayTable = noidunghienthi.map((item) => item.value)
            this.defaultDisplayTable = defaultDisplayTable
            const colExcels = noidunghienthi.map((item) => ({
                key: item.key,
                stt: item.value,
                name: item.title
            }))

            const timkiemnangcao = this.convertToValueArray(
                this.selectedValue.selectedValueAdvancedSearch
            )

            const keyMappings_dataSreachList = {
                ngaySinh: 'date_ngaySinh',
                doTuoi: 'selectedValueAge',
                maTonGiao: 'selectedValueReligion',
                chucVu: 'selectedValuePosition',
                dchiThtru: 'input_diaChiThuongTru',
                maKhuVuc: 'selectedValueArea',
                dangVien: 'selectedValuePartisan',
                bacLuong: 'input_bacLuong',
                heSoLuong: 'input_heSoLuong',
                moetCode: 'input_maDinhDanh',
                maMonHoc: 'selectedValuesubject',
                maDanToc: 'selectedValueNation',
                loaiHopDong: 'selectedValueContractForm',
                soDienThoaiDiDong: 'input_soDienThoai',
                trinhDoDaoTaoCnChinh: 'selectedValueDegreeTraining',
                dgKqChuanNgheNghiep: 'selectedValueProfessionalStandardAssessment'
                // Thêm các ánh xạ key - giá trị tương ứng ở đây
            }

            const dataSearchList = timkiemnangcao
                .map((key) => {
                    const valueKey = keyMappings_dataSreachList[key]
                    const inputValue = this.selectedValue[valueKey]
                    if (valueKey && inputValue) {
                        let value
                        if (Array.isArray(inputValue)) {
                            value = inputValue.map((item) => item.value)
                            return {
                                key: key,
                                values: value
                            }
                        } else if (typeof inputValue === 'object') {
                            value = inputValue.value
                        } else {
                            value = inputValue
                        }
                        return {
                            key: key,
                            value: value
                        }
                    }
                    return null
                })
                .filter((item) => item !== null)
            const request_Header = {
                token: this.authToken
            }
            const loaiCanBo =
                this.selectedValue.selectedValueTypeOfCadre?.value || null
            const namHoc = this.selectedValue.selectedValueYear || currentYear
            const gioiTinh = this.selectedValue.selectedValueGender?.value || null
            const keyword = this.selectedValue.input_tuKhoa || null

            const capTruong = this.convertToValueArray(
                this.selectedValue.selectedValueGradeLevel
            )?.map(Number)

            const trangThai =
                +this.selectedValue.selectedValueStatus?.value || null

            const maDonVis = this.convertToValueArray(
                this.selectedValue.selectedValueUnitEducation
            )
            const maTruongs = this.convertToValueArray(
                this.selectedValue.selectedValueSchool
            )
            console.log(this.selectedValue.selectedValueSchool)
            const maTrinhDoChuyenMonCaoNhat = this.convertToValueArray(
                this.selectedValue.selectedValueProfessionalQualifications
            )?.map(Number)
            const start = this.start
            const limit = this.limit
            const request_Data = {
                start: start,
                limit: limit,
                loaiCanBo: loaiCanBo,
                namHoc: namHoc,
                dataSreachList: dataSearchList,
                gioiTinh: gioiTinh,
                keyword: keyword,
                maChuyenNganhDaoTao: null,
                colExcels: colExcels,
                capTruong: capTruong,
                trangThai: trangThai,
                maTruongs: maTruongs,
                maTrinhDoChuyenMonCaoNhat: maTrinhDoChuyenMonCaoNhat,
                maDonVis: maDonVis
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const response = await sendRequest(
                Api.traCuu.traCuuCanBoGiaoVien.danhSachCanBoGv,
                request_Data,
                request_Header,
                null
            )
            if (response.rc == 0) {
                loading.close()
                this.$message({
                    type: 'success',
                    message: 'Danh sách tìm kiếm'
                })
                this.tableData = response.tableData.map((item) => {
                    return { ...item, isChecked: false }
                })
                this.total_rows = response.totalRows
            } else {
                loading.close()
                this.$message({
                    type: 'warning',
                    message: response.rd
                })
                this.tableData = []
                this.total_rows = 0
            }
        },
        async getDataChonTruongHoc() {
            const maDonVi = this.convertToValueArray(
                this.selectedValue.selectedValueUnitEducation
            )

            const capHoc = this.convertToValueArray(
                this.selectedValue.selectedValueGradeLevel
            )?.map(Number)

            const request_Header = {
                token: this.authToken
            }

            const request_Data = {
                capHoc: capHoc,
                maDonVi: maDonVi
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            let response = await sendRequest(
                Api.internal_api.dm_School,
                request_Data,
                request_Header,
                null
            )

            if (response.statusResponse == 0) {
                loading.close()
                this.getDataESelect.ESelectSchool = response.rows.map((item) => ({
                    title: `${item.tenTruongHoc} - [${item.maTruongHoc}]`,
                    value: item.maTruongHoc
                }))
            }
        },
        convertToValueArray(array) {
            return array.map((item) => item.value)
        },
        leftBarWidth() {
            if (this.isCollapseLeftBar) {
                return 'calc(100vw - 105px)'
            } else {
                return 'calc(100vw - 340px)'
            }
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        },
        dataChonTrinhDoChuyenMonNghiepVu_Store() {
            return JSON.parse(
                localStorage.getItem('data_ChonTrinhdochuyenmonnghiepvu')
            )
        },
        dataChonTrangthaicanbo_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrangthaicanbo'))
        },
        dataChonTongiao_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTongiao'))
        },
        dataChonChucvu_Store() {
            return JSON.parse(localStorage.getItem('data_ChonLoaicanbo'))
        },
        dataChonKhuvuc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonKhuvuc'))
        },
        dataChonMondayGV_Store() {
            return JSON.parse(localStorage.getItem('data_ChonMondaygiaovien'))
        },
        dataChonDantoc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDantoc'))
        },
        dataChonHinhthuchopdong_Store() {
            return JSON.parse(localStorage.getItem('data_ChonHinhthuchopdong'))
        },
        dataChonTrinhdodaotao_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrinhdodaotao'))
        },

        dataChonDanhgianghenghiep_Store() {
            return JSON.parse(localStorage.getItem('data_ChonChuannghenghiep'))
        },
        ...mapState({
            dataTo_KetChuyenDuLieu: (state) =>
                state.data_Navigate_KetChuyenDuLieu.traCuuCanBoGV
        }),
        ...mapState(['isCollapseLeftBar'])
    },
    watch: {
        'authUser.role': {
            immediate: true,
            handler(newRole, oldRole) {
                if (newRole === 5) {
                    this.selectedValue.selectedValueUnitEducation = [
                        { value: this.authUser.ma_don_vi }
                    ]
                    const convertedList = convertCapHocList(
                        this.authUser.capHocList
                    )
                    this.selectedValue.selectedValueGradeLevel = convertedList
                    this.selectedValue.selectedValueSchool = [
                        { value: this.authUser.ma_truong_hoc }
                    ]
                }
                if (newRole === 4) {
                    this.selectedValue.selectedValueUnitEducation = [
                        { value: this.authUser.ma_don_vi }
                    ]
                }
            }
        },
        dataTo_KetChuyenDuLieu: {
            immediate: true,
            handler(newValues) {
                if (newValues?.maTruongHoc && newValues) {
                    this.selectedValue.selectedValueYear =
                        newValues?.namHoc.toString()
                    this.selectedValue.selectedValueSchool = [
                        { value: newValues.maTruongHoc }
                    ]
                }
            }
        },
        'selectedValue.selectedValueUnitEducation': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        },
        'selectedValue.selectedValueGradeLevel': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        }
    },
    mounted() {
        this.getDataESelect.ESelectGradeLevel = filterCapHocList(
            this.authUser.capHocList
        )
        this.getDataChonTruongHoc()
        this.handleSearch()
    }
}
</script>
<style scoped>
.layout-btn-add {
    padding: 20px 10px 5px 10px;

    background-color: #fff;
    display: flex;
    justify-content: flex-end;
}
.layout-btn-search {
    margin-top: 15px;
    margin-bottom: 5px;
}

.custom-date-picker {
    height: 32px;
}
.custom-date-picker::placeholder {
    color: gainsboro;

    font-size: 13px;
    background-color: transparent;
    appearance: none;
}
.btn-search {
    margin-top: 20px;
    justify-content: center;
    display: flex;
}
.layout-btn {
    padding: 20px 10px 5px 10px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}

.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.bg-search,
.excel,
.btn-info {
    background-color: var(--teal);
}
.btn-add {
    background-color: var(--light-green);
}
.btn-up {
    background-color: var(--yellow);
}
.btn-update {
    background-color: var(--yellow);
}
.btn-delete {
    background-color: var(--orange);
}
</style>
