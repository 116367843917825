<template>
    <div>
        <CustomContentUI>
            <div class="row">
                <div class="col-sm-3">
                    <label>Đơn vị quản lý</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="
                                this.authUser.role === 5 ||
                                this.authUser.role === 4
                            "
                            :data="this.dataChonDonVi_Store"
                            :placeholder="'Chọn đơn vị'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenDonVi', 'maDonVi']"
                            v-model="selectedValue.selectedValueUnitEducation"
                            @input="getDataChonTruongHoc"
                        />
                    </div>
                </div>
                <div class="col-sm-3">
                    <label>Cấp học</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="this.authUser.role === 5"
                            :data="getDataESelect.ESelectGradeLevel"
                            :placeholder="'Chọn cấp học'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenTruongHoc', 'value']"
                            v-model="selectedValue.selectedValueGradeLevel"
                            @input="getDataChonTruongHoc"
                        />
                    </div>
                </div>
                <div class="col-sm-3">
                    <label>Trường học</label>
                    <div>
                        <ESelect
                            :disabled="this.authUser.role === 5"
                            :reset="resetESelectSchool"
                            @reset-completed="handleResetSchoolCompleted"
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :data="getDataESelect.ESelectSchool"
                            :placeholder="'Chọn trường học'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['title', 'value']"
                            v-model="selectedValue.selectedValueSchool"
                        />
                    </div>
                </div>
                <div class="col-sm-3">
                    <label>Năm học</label>
                    <ESelectYear
                        v-model="selectedValue.year"
                        placeholder="Chọn năm"
                        size="small"
                        :no-data-text="'Không có bản ghi nào'"
                        :no-match-text="'Không tìm thấy bản ghi nào'"
                        :width="'100%'"
                    ></ESelectYear>
                </div>
            </div>
            <div class="btn-search">
                <CustomButton
                    class="bg-search btn-default"
                    label="Tìm kiếm"
                    size="small"
                    @click="handleSearch"
                />
            </div>
        </CustomContentUI>

        <CustomTable :columns="tableColumns">
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td class="text-tbody text-center" colspan="6">
                            Không có bản ghi nào.
                        </td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td class="text-tbody text-center">
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-tbody text-left">
                        {{ item.tenTruong }}
                    </td>
                    <td class="text-tbody text-center">
                        {{ item.maTruong }}
                    </td>

                    <td class="text-tbody text-center">
                        <span v-if="item.status === 0" class="text-lock">
                            Chưa chốt
                        </span>
                        <span v-if="item.status === 1" class="text-unlock">
                            Đã chốt
                        </span>
                    </td>
                    <td class="text-tbody text-center">
                        <span v-if="item.status === 1">
                            <CustomButton
                                class="btn-default bg-search"
                                label="Mở"
                                size="small"
                                @click="handleUnLock(item)"
                            />
                        </span>
                        <span v-if="item.status === 0">
                            <CustomButton
                                class="btn-default btn-delete"
                                label="Khóa"
                                size="small"
                                @click="handleLock(item)"
                            />
                        </span>
                    </td>
                </tr>
            </template>
        </CustomTable>
        <CustomPagination
            v-show="total_rows > 0"
            :tongbanghi="total_rows"
            :soluonghienthi="soLuongBanGhiHienThi"
            :batdau="trangbatdau"
            @pageChange="layLai($event)"
        >
        </CustomPagination>
    </div>
</template>
<script>
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomPagination from '@/components/CustomPagination.vue'
import { mapState } from 'vuex'

import CustomButton from '@/components/CustomButton.vue'
import { convertCapHocList, currentYear, filterCapHocList } from '@/utils/index'
import ESelectYear from '@/components/ESelectYear.vue'
import ESelect from '@/components/ESelect.vue'
import CustomContentUI from '@/components/CustomContentUI.vue'
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'ChotTheoTruong',
    components: {
        CustomButton,
        CustomPagination,
        ESelect,
        ESelectYear,
        CustomContentUI,
        CustomTable
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            soLuongBanGhiHienThi: 25,
            trangbatdau: false,

            tableData: [],
            tableColumns: [
                { key: '1', label: 'STT' },
                { key: '2', label: 'Tên trường' },
                { key: '3', label: 'Mã trường' },
                { key: '4', label: 'Trạng thái' },
                { key: '5', label: 'Thao tác' }
            ],
            resetESelectSchool: false,
            getDataESelect: {
                ESelectUnitEducation: [], //donvi
                ESelectGradeLevel: [], //caphoc

                ESelectSchool: [] //truonghoc
            },

            selectedValue: {
                selectedValueUnitEducation: [], //donvi
                selectedValueGradeLevel: [], //caphoc

                selectedValueSchool: [], //truonghoc

                year: ''
            }
        }
    },
    methods: {
        handleResetSchoolCompleted() {
            this.resetESelectSchool = false
        },
        checkTruocKhiTim() {
            this.trangbatdau = !this.trangbatdau
        },
        layLai(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        convertToValueArray(array) {
            return array.map((item) => item.value)
        },
        async getDataChonTruongHoc() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const maDonVi = this.convertToValueArray(
                this.selectedValue.selectedValueUnitEducation
            )

            const capHoc = this.convertToValueArray(
                this.selectedValue.selectedValueGradeLevel
            )

            const request_Header = {
                token: this.authToken
            }

            const request_Data = {
                capHoc: capHoc,
                maDonVi: maDonVi
            }

            let response = await sendRequest(
                Api.internal_api.dm_School,
                request_Data,
                request_Header,
                null
            )

            if (response.statusResponse == 0) {
                loading.close()
                this.getDataESelect.ESelectSchool = response.rows.map((item) => ({
                    title: `${item.tenTruongHoc} - [${item.maTruongHoc}]`,
                    value: item.maTruongHoc
                }))
                loading.close()
            } else {
                loading.close()
            }
        },
        async handleToggleLock(item, trangThai) {
            try {
                const request_Header = {
                    token: this.authToken
                }

                const request_Data = {
                    maPhong: item.maPhong,
                    maTruongs: [item.maTruong],
                    namHoc: item.namHoc,
                    trangThai: trangThai
                }

                const response = await sendRequest(
                    Api.heThong.chotDuLieuBaoCao.chotTheoTruong.moKhoa,
                    request_Data,
                    request_Header
                )

                if (response.rc == 0) {
                    this.handleSearch()
                }
            } catch (error) {
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },
        // Sử dụng hàm chung trong handleUnLock
        async handleUnLock(item) {
            await this.handleToggleLock(item, 0)
        },
        // Sử dụng hàm chung trong handleLock
        async handleLock(item) {
            await this.handleToggleLock(item, 1)
        },
        async handleSearch() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const maPhongs = this.convertToValueArray(
                this.selectedValue.selectedValueUnitEducation
            )
            const capHocs = this.convertToValueArray(
                this.selectedValue.selectedValueGradeLevel
            )
            const maTruongs = this.convertToValueArray(
                this.selectedValue.selectedValueSchool
            )
            const namHoc = Number(this.selectedValue.year) || currentYear
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Data = {
                    start: this.start,
                    limit: this.limit,
                    maSo: this.authUser.ma_so,

                    maPhongs: maPhongs,
                    maTruongs: maTruongs,
                    capHocs: capHocs,
                    namHoc: namHoc
                }
                const response = await sendRequest(
                    Api.heThong.chotDuLieuBaoCao.chotTheoTruong.danhSach,
                    request_Data,
                    request_Header
                )

                loading.close()
                this.tableData = response.rows
                this.total_rows = response.total
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        }
    },
    watch: {
        'selectedValue.selectedValueUnitEducation': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        },
        'selectedValue.selectedValueGradeLevel': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        },
        'selectedValue.selectedValueSchoolBlock': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        },
        'authUser.role': {
            immediate: true,
            handler(newRole, oldRole) {
                // if (newRole === 5) {
                //     this.selectedValue.selectedValueUnitEducation = {
                //         value: this.authUser.ma_don_vi
                //     }

                //     const convertedList = convertCapHocList(
                //         this.authUser.capHocList
                //     )
                //     this.selectedValue.selectedValueGradeLevel = convertedList
                //     this.selectedValue.selectedValueSchool = {
                //         value: this.authUser.ma_truong_hoc
                //     }
                // }
                if (newRole === 4) {
                    this.selectedValue.selectedValueUnitEducation = [
                        {
                            value: this.authUser.ma_don_vi
                        }
                    ]
                }
                this.getDataESelect.ESelectGradeLevel = filterCapHocList(
                    this.authUser.capHocList
                )
            }
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        }
    },
    mounted() {
        this.getDataESelect.ESelectGradeLevel = filterCapHocList(
            this.authUser.capHocList
        )
        this.getDataChonTruongHoc()
        this.handleSearch()
    }
}
</script>

<style scoped>
.text-left {
    text-align: left;
}

.btn-search {
    margin-top: 20px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
}

.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.bg-search {
    background-color: var(--teal);
}

.btn-update {
    background-color: var(--yellow);
}
.btn-delete {
    background-color: var(--orange);
}
.text-unlock {
    color: #1e6050;
}
.text-lock {
    color: var(--orange);
}
::v-deep .el-tag.el-tag--info {
    color: black;
}
</style>
