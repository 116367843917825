<template>
    <div>
        <el-dialog
            :visible="chiTietCanboGvDisabledModalVisible"
            @close="closeDisabledModalCanboGv"
            width="60%"
            class="custom-dialog"
            :fullscreen="true"
        >
            <span slot="title" class="custom-dialog-title">
                CHI TIẾT HỒ SƠ CÁN BỘ
            </span>
            <div class="row">
                <div class="col-12 col-sm-6 col-lg-2">
                    <CardInfo
                        :moetCode="dataModal.moetCode"
                        :avatar="dataModal.avatar"
                    />
                </div>
                <div class="col-12 col-sm-6 col-lg-5">
                    <div class="row d-flex align-items-center">
                        <div class="col-4 title">Họ tên<span>*</span></div>
                        <div class="col-8">
                            <el-input
                                style="width: 100%"
                                :disabled="true"
                                placeholder=""
                                v-model="selectedValue.input_hoTen"
                                size="small"
                            ></el-input>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">Ngày sinh <span>*</span></div>
                        <div class="col-8">
                            <date-picker
                                :disabled="true"
                                :config="configDatePicker"
                                placeholder="Chọn ngày"
                                v-model="selectedValue.date_ngaySinh"
                                class="custom-date-picker"
                            >
                            </date-picker>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">Giới tính <span>*</span></div>
                        <div class="col-8">
                            <el-select
                                v-model="selectedValue.selectedValue_gioiTinh"
                                style="width: 100%"
                                :disabled="true"
                                placeholder=""
                                size="small"
                            >
                                <el-option
                                    v-for="item in getDataESelect.ESelect_gioiTinh"
                                    :key="item.value"
                                    :label="item.title"
                                    :value="item.value"
                                />
                            </el-select>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">Trạng thái <span>*</span></div>
                        <div class="col-8">
                            <el-select
                                v-model="selectedValue.selectedValue_trangThaiCB"
                                style="width: 100%"
                                :disabled="true"
                                placeholder=""
                                size="small"
                            >
                                <el-option
                                    v-for="item in this
                                        .dataChonTrangthaicanbo_Store"
                                    :key="item.mappingCode"
                                    :label="item.constantTitle"
                                    :value="item.mappingCode"
                                />
                            </el-select>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">Là tuyển mới</div>
                        <div class="col-8">
                            <el-checkbox
                                :disabled="true"
                                v-model="selectedValue.checkbox_laTuyenMoi"
                            />
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">CMTND/TCC <span>*</span></div>
                        <div class="col-8">
                            <el-input
                                style="width: 100%"
                                :disabled="true"
                                placeholder=""
                                v-model="selectedValue.input_CMTND_TCC"
                                size="small"
                            ></el-input>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">Ngày cấp</div>
                        <div class="col-8">
                            <date-picker
                                :disabled="true"
                                :config="configDatePicker"
                                placeholder="Chọn ngày"
                                v-model="selectedValue.date_ngayCap"
                                class="custom-date-picker"
                            >
                            </date-picker>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">Email</div>
                        <div class="col-8">
                            <el-input
                                style="width: 100%"
                                :disabled="true"
                                placeholder=""
                                v-model="selectedValue.input_email"
                                size="small"
                            ></el-input>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-5">
                    <div class="row d-flex align-items-center">
                        <div class="col-4 title">Mã cán bộ</div>
                        <div class="col-8">
                            <el-input
                                style="width: 100%"
                                :disabled="true"
                                placeholder=""
                                v-model="selectedValue.input_maCanBo"
                                size="small"
                            ></el-input>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">Dân tộc</div>
                        <div class="col-8">
                            <el-select
                                style="width: 100%"
                                :disabled="true"
                                placeholder=""
                                v-model="selectedValue.selectedValue_danToc"
                                size="small"
                            >
                                <el-option
                                    v-for="item in this.dataChonDanToc_Store"
                                    :key="item.mappingCode"
                                    :label="item.constantTitle"
                                    :value="item.mappingCode"
                                />
                            </el-select>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">Tôn giáo</div>
                        <div class="col-8">
                            <el-select
                                v-model="selectedValue.selectedValue_tonGiao"
                                style="width: 100%"
                                :disabled="true"
                                placeholder=""
                                size="small"
                            >
                                <el-option
                                    v-for="item in this.dataChonTonGiao_Store"
                                    :key="item.mappingCode"
                                    :label="item.constantTitle"
                                    :value="item.mappingCode"
                                />
                            </el-select>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">Nơi sinh</div>
                        <div class="col-8">
                            <el-input
                                type="textarea"
                                :autosize="{ minRows: 2, maxRows: 4 }"
                                style="width: 100%"
                                :disabled="true"
                                placeholder=""
                                v-model="selectedValue.input_noiSinh"
                            >
                            </el-input>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">Quê quán</div>
                        <div class="col-8">
                            <el-input
                                style="width: 100%"
                                :disabled="true"
                                placeholder=""
                                type="textarea"
                                :autosize="{ minRows: 2, maxRows: 4 }"
                                v-model="selectedValue.input_queQuan"
                            >
                            </el-input>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">Số sổ BHXH</div>
                        <div class="col-8">
                            <el-input
                                style="width: 100%"
                                :disabled="true"
                                placeholder=""
                                v-model="selectedValue.input_soSoBHXH"
                                size="small"
                            ></el-input>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">Đoàn viên</div>
                        <div class="col-8">
                            <el-checkbox
                                :disabled="true"
                                v-model="selectedValue.checkbox_doanVien"
                            />
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-4 title">Đảng viên</div>
                        <div class="col-8">
                            <el-checkbox
                                :disabled="true"
                                v-model="selectedValue.checkbox_dangVien"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-top: 30px">
                <el-tabs type="card">
                    <el-tab-pane label="I. Vị trí việc làm">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-lg-4">
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Loại cán bộ <span>*</span>
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_loaiCanBo
                                            "
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonLoaiCanBo_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">Môn dạy</div>
                                    <div class="col-7">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_monDay
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonMonDay_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Môn dạy kiêm nhiệm
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_monDayKiemNghiem
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonMonDay_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">Nhóm chức vụ</div>
                                    <div class="col-7">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_nhomChucVu
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonChucVu_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Hình thức hợp đồng
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_hinhThucHopDong
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonHinhThucHopDong_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">Ngày tuyển</div>
                                    <div class="col-7">
                                        <date-picker
                                            :disabled="true"
                                            :config="configDatePicker"
                                            placeholder="Chọn ngày"
                                            v-model="selectedValue.date_ngayTuyen"
                                            class="custom-date-picker"
                                        >
                                        </date-picker>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Cơ quan tuyển dụng
                                    </div>
                                    <div class="col-7">
                                        <el-input
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.input_coQuanTuyenDung
                                            "
                                            size="small"
                                        ></el-input>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Nghề nghiệp khi được tuyển dụng
                                    </div>
                                    <div class="col-7">
                                        <el-input
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.input_ngheNghiepKhiDucTuyenDung
                                            "
                                            size="small"
                                        ></el-input>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-6 col-lg-4">
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Nhiệm vụ kiêm nhiệm
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_nhiemVuKiemNghiem
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonNhiemVuKiemNghiem_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Số tiết thực dạy trên tuần
                                    </div>
                                    <div class="col-7">
                                        <el-input
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.input_soTietDayThucTrenTuan
                                            "
                                            size="small"
                                        ></el-input>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Số tiết thực kiêm nhiệm/tuần
                                    </div>
                                    <div class="col-7">
                                        <el-input
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.input_soTietThucNghiemTrenTuan
                                            "
                                            size="small"
                                        ></el-input>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Đã tập huấn dạy kỹ năng sống (HIV,
                                        SKSS...)
                                    </div>
                                    <div class="col-7">
                                        <el-checkbox
                                            :disabled="true"
                                            v-model="
                                                selectedValue.checkbox_daTapHuanKyNangSong
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Dạy HSKT học hòa nhập
                                    </div>
                                    <div class="col-7">
                                        <el-checkbox
                                            :disabled="true"
                                            v-model="
                                                selectedValue.checkbox_dayHSKTHocHoaNhap
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Đang dạy 1 buổi/ngày
                                    </div>
                                    <div class="col-7">
                                        <el-checkbox
                                            :disabled="true"
                                            v-model="
                                                selectedValue.checkbox_dangDay1BuoiNgay
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Đang dạy 2 buổi/ngày
                                    </div>
                                    <div class="col-7">
                                        <el-checkbox
                                            :disabled="true"
                                            v-model="
                                                selectedValue.checkbox_dangDay2BuoiNgay
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Th.gia CT b.dưỡng T.xuyên
                                    </div>
                                    <div class="col-7">
                                        <el-checkbox
                                            :disabled="true"
                                            v-model="
                                                selectedValue.checkbox_thamGiaCanBoBoiDuong
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">Ngạch/Hạng</div>
                                    <div class="col-7">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_ngach_hang
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonNganhCC_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Có CC bồi dưỡng TCCD
                                    </div>
                                    <div class="col-7">
                                        <el-checkbox
                                            :disabled="true"
                                            v-model="
                                                selectedValue.checkbox_coCCboiDuongTCCD
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="II. Phụ cấp">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-lg-6">
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-4 title">
                                        Mức phụ cấp thu hút nghề (%)
                                    </div>
                                    <div class="col-8">
                                        <el-input-number
                                            :disabled="true"
                                            v-model="
                                                selectedValue.input_mucPhuCapThuHutNghe
                                            "
                                            controls-position="right"
                                            :precision="2"
                                            :step="0.01"
                                            :max="100"
                                            :min="0"
                                        >
                                        </el-input-number>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-4 title">
                                        Mức phụ cấp thâm niên (%)
                                    </div>
                                    <div class="col-8">
                                        <el-input-number
                                            :disabled="true"
                                            v-model="
                                                selectedValue.input_mucPhuCapThamNien
                                            "
                                            controls-position="right"
                                            :precision="2"
                                            :step="0.01"
                                            :max="100"
                                            :min="0"
                                        >
                                        </el-input-number>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-4 title">
                                        Mức phụ cấp ưu đãi nghề (%)
                                    </div>
                                    <div class="col-8">
                                        <el-input-number
                                            v-model="
                                                selectedValue.input_mucPhuCapUuDaiNghe
                                            "
                                            controls-position="right"
                                            :precision="2"
                                            :step="0.01"
                                            :max="100"
                                            :min="0"
                                        >
                                        </el-input-number>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-4 title">
                                        Mức phụ cấp chức vụ lãnh đạo (%)
                                    </div>
                                    <div class="col-8">
                                        <el-input-number
                                            :disabled="true"
                                            v-model="
                                                selectedValue.input_mucPhuCapChucVuLanhDao
                                            "
                                            controls-position="right"
                                            :precision="2"
                                            :step="0.01"
                                            :max="100"
                                            :min="0"
                                        >
                                        </el-input-number>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-4 title">
                                        D.biến q.trình lương
                                    </div>
                                    <div class="col-8">
                                        <CustomButton
                                            label=" + Chi tiết"
                                            size="small"
                                            type="primary"
                                            @click="
                                                handleOpenModalLstQuaTrinhLuong
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-6">
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-3 title">Bậc lương</div>
                                    <div class="col-9">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_bacLuong
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in getDataESelect.ESelect_bacLuong"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-3 title">
                                        Phần trăm vượt khung (%)
                                    </div>
                                    <div class="col-9">
                                        <el-input-number
                                            :disabled="true"
                                            v-model="
                                                selectedValue.input_phamTramVuotKhung
                                            "
                                            controls-position="right"
                                            :precision="2"
                                            :step="0.01"
                                            :max="100"
                                            :min="0"
                                        >
                                        </el-input-number>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-3 title">Hệ số lương</div>
                                    <div class="col-9">
                                        <el-input-number
                                            :disabled="true"
                                            v-model="
                                                selectedValue.input_heSoLuong
                                            "
                                            controls-position="right"
                                            :precision="2"
                                            :step="0.01"
                                            :max="100"
                                            :min="0"
                                        >
                                        </el-input-number>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-3 title">Ngày hưởng</div>
                                    <div class="col-4">
                                        <date-picker
                                            :disabled="true"
                                            :config="configDatePicker"
                                            placeholder="Chọn ngày"
                                            v-model="selectedValue.date_ngayHuong"
                                            class="custom-date-picker"
                                        >
                                        </date-picker>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="III. Đào tạo, bồi dưỡng">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-lg-4">
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        KQ B.dưỡng t.xuyên
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_KQBoiDuongThuongXuyen
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonBoiDuongTX_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        T.độ c.môn n.vụ *
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_trinhDoChuyenMonNghiepVu
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonTrinhDoChuyenMonNV_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">T.độ LLCT</div>
                                    <div class="col-7">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_trinhDoLLCT
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonTrinhDoLLCT_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">T.độ quản lý GD</div>
                                    <div class="col-7">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_trinhDoQuanLyGD
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonTrinhDoQLGD_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Th.gia BD nghiệp vụ QLGD
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_thamGiaBoiDuongNghiepVuQLGD
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonBoiDuongNghiepVu_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Th.gia BD CBQL/GV cốt cán
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_thamGiaBoiDuongCBGVCotCan
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonBoiDuongCBQLCotCan_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Th.gia BD thay sách
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_thamGiaBoiDuongThaySach
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonBoiDuongThaySach_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">Ngoại ngữ chính</div>
                                    <div class="col-7">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_ngoaiNguChinh
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonNgoainguchinh_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        T.độ Đ.tạo N.Ngữ
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_trinhDoDaoTaoNgoaiNgu
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonTrinhDoNgoaingu_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Nhóm C.Chỉ N.Ngữ
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_nhomChungChiNgoaiNgu
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonNhomCChiNNgu_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Loại C.Chỉ N.Ngữ
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_loaiChungChiNgoaiNgu
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonLoaiCChiNNgu_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">Điểm N.Ngữ</div>
                                    <div class="col-6 d-flex">
                                        <div>
                                            <el-input-number
                                                :disabled="true"
                                                style="width: 90%"
                                                size="small"
                                                v-model="
                                                    selectedValue.input_diemNgoaiNgu
                                                "
                                                controls-position="right"
                                                :precision="2"
                                                :step="0.01"
                                                :max="100"
                                                :min="0"
                                            >
                                            </el-input-number>
                                        </div>
                                        <div>
                                            <CustomButton
                                                label="Chi tiết"
                                                type="primary"
                                                size="small"
                                                @click="
                                                    handleOpenModalLstQuaTrinhNgoaiNgu
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Khung N.Lực N.Ngữ
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_khungNangLucNgoaiNgu
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonKhungNLucNNgu_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">T.độ tin học</div>
                                    <div class="col-7">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_trinhDoTinHoc
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonTrinhDoTinHoc_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Chuyên ngành chính
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_chuyenNganhChinh
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonChuyenMon_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">Trình độ chính</div>
                                    <div class="col-7">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_trinhDoChinh
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this.getDataESelect
                                                    .ESelect_trinhDoChinh"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">Cơ sở đào tạo</div>
                                    <div class="col-7">
                                        <el-input
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.input_coSoDaoTao
                                            "
                                            size="small"
                                        ></el-input>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Chuyên ngành khác
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_chuyenNganhKhac
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonChuyenMon_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">Trình độ khác</div>
                                    <div class="col-7">
                                        <el-select
                                            v-model="
                                                selectedValue.selectedValue_trinhDoKhac
                                            "
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this.getDataESelect
                                                    .ESelect_trinhDoKhac"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        G.viên có c.chỉ tiếng d.tộc t.số
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_giaoVienCoChungChiTiengDanToc
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonCChiDTocTSo_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Quá trình Đ.tạo B.dưỡng
                                    </div>
                                    <div class="col-7">
                                        <CustomButton
                                            label=" + Chi tiết"
                                            size="small"
                                            type="primary"
                                            @click="
                                                handleOpenModalLstQuaTrinhDaoTaoBoiDuong
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="IV. Đánh giá, phân loại">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-lg-4">
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Đánh giá viên chức/ công chức
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_danhGiaCongChucVienChuc
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonDGVienChuc_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Đ.giá chuẩn NN CBQL/GV
                                    </div>
                                    <div class="col-7">
                                        <CustomButton
                                            label=" + Chi tiết"
                                            size="small"
                                            @click="
                                                handleOpenModalDanhGiaChuanNN_CBQL_GV
                                            "
                                            type="primary"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Giáo viên dạy giỏi
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_giaoVienDayGioi
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonDanhGiaGVGioi_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Giáo viên chủ nhiệm giỏi
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_giaoVienChuNhiemDayGioi
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonGVCNGioi_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Tổng phụ trách Đội giỏi
                                    </div>
                                    <div class="col-7">
                                        <el-select
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.selectedValue_tongPhuTrachGioi
                                            "
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in this
                                                    .dataChonTongPhuTrachDoiGioi_Store"
                                                :key="item.mappingCode"
                                                :label="item.constantTitle"
                                                :value="item.mappingCode"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">
                                        Danh hiệu phong tặng
                                    </div>
                                    <div class="col-7">
                                        <el-input
                                            style="width: 100%"
                                            :disabled="true"
                                            placeholder=""
                                            v-model="
                                                selectedValue.input_danhHieuPhongtang
                                            "
                                            size="small"
                                        ></el-input>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">Khen thưởng</div>
                                    <div class="col-7">
                                        <CustomButton
                                            size="small"
                                            type="primary"
                                            @click="handleOpenModalLstKhenThuong"
                                            label="+ Chi tiết"
                                        />
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-3">
                                    <div class="col-5 title">Kỷ luật</div>
                                    <div class="col-7">
                                        <CustomButton
                                            size="small"
                                            type="primary"
                                            @click="handleOpenModalLstKyLuat"
                                            label="+ Chi tiết"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="layout-btn">
                <CustomButton
                    label="Đóng"
                    size="small"
                    class="btn-default btn-delete"
                    @click="closeDisabledModalCanboGv"
                />
            </div>
        </el-dialog>
        <ModalLstQuaTrinhLuong
            :lstQuaTrinhLuongModalVisible="lstQuaTrinhLuongModalVisible"
            :closeModalLstQuaTrinhLuong="closeModalLstQuaTrinhLuong"
            :lstQuaTrinhLuong="lstQuaTrinhLuong"
        />
        <ModalLstQuaTrinhNgoaiNgu
            :lstQuaTrinhNgoaiNguVisible="lstQuaTrinhNgoaiNguVisible"
            :closeModalLstQuaTrinhNgoaiNgu="closeModalLstQuaTrinhNgoaiNgu"
            :lstQuaTrinhNgoaiNgu="lstQuaTrinhNgoaiNgu"
        />
        <ModalLstQuaTrinhDaoTaoBoiDuong
            :lstQuaTrinhDaoTaoBoiDuongVisible="lstQuaTrinhDaoTaoBoiDuongVisible"
            :closeModallstQuaTrinhDaoTaoBoiDuong="
                closeModallstQuaTrinhDaoTaoBoiDuong
            "
            :lstQuaTrinhDaoTaoBoiDuong="lstQuaTrinhDaoTaoBoiDuong"
            :id_DisabledModalChiTietCanboGv="id_DisabledModalChiTietCanboGv"
        />
        <ModalDanhGiaChuanNN_CBQL_GV
            :danhGiaChuanNN_CBQL_GVVisible="danhGiaChuanNN_CBQL_GVVisible"
            :closeModalDanhGiaChuanNN_CBQL_GV="closeModalDanhGiaChuanNN_CBQL_GV"
            :danhGiaChuanNN_CBQL_GV="danhGiaChuanNN_CBQL_GV"
        />
        <ModalLstKhenThuong
            :lstKhenThuong="lstKhenThuong"
            :lstKhenThuongVisible="lstKhenThuongVisible"
            :closeModalLstKhenThuong="closeModalLstKhenThuong"
        />
        <ModalLstKyLuat
            :lstKyLuat="lstKyLuat"
            :lstKyLuatVisible="lstKyLuatVisible"
            :closeModalLstKyLuat="closeModalLstKyLuat"
        />
    </div>
</template>
<script>
import CardInfo from '@/components/CardInfo.vue'
import datePicker from 'vue-bootstrap-datetimepicker'
import ModalLstQuaTrinhLuong from './ModalLstQuaTrinhLuong.vue'
import ModalLstQuaTrinhNgoaiNgu from './ModalLstQuaTrinhNgoaiNgu.vue'
import ModalLstQuaTrinhDaoTaoBoiDuong from './ModalLstQuaTrinhDaoTaoBoiDuong.vue'
import ModalDanhGiaChuanNN_CBQL_GV from './ModalDanhGiaChuanNN_CBQL_GV.vue'
import ModalLstKhenThuong from './ModalLstKhenThuong.vue'
import ModalLstKyLuat from './ModalLstKyLuat.vue'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import CustomButton from '@/components/CustomButton.vue'
import Api from '@/constants/Api'
import sendRequest from '@/services'
import { mapState } from 'vuex'
import {
    ESelectGender_MockData_ModalChiTietHocSinh,
    ESelectTypeOfCadre_TraCuuCanBoGV_MockData,
    ESelectwage_TraCuuCanBoGV_MockData,
    ESlectMainLevel_MockData
} from '@/mock_data'
export default {
    name: 'ModalChitietCBGV',
    props: {
        chiTietCanboGvDisabledModalVisible: {
            type: Boolean,
            required: true
        },

        closeDisabledModalCanboGv: {
            type: Function,
            required: true
        },
        id_DisabledModalChiTietCanboGv: {
            type: Number,
            required: true
        }
    },
    components: {
        datePicker,
        CustomButton,
        ModalLstQuaTrinhLuong,
        ModalLstQuaTrinhNgoaiNgu,
        ModalLstQuaTrinhDaoTaoBoiDuong,
        ModalDanhGiaChuanNN_CBQL_GV,
        ModalLstKhenThuong,
        ModalLstKyLuat,
        CardInfo
    },
    data() {
        return {
            dataModal: {},
            lstQuaTrinhLuong: [],
            lstQuaTrinhLuongModalVisible: false,
            lstQuaTrinhNgoaiNgu: [],
            lstQuaTrinhNgoaiNguVisible: false,
            lstQuaTrinhDaoTaoBoiDuong: [],
            lstQuaTrinhDaoTaoBoiDuongVisible: false,
            danhGiaChuanNN_CBQL_GV: {},
            danhGiaChuanNN_CBQL_GVVisible: false,
            lstKhenThuong: [],
            lstKhenThuongVisible: false,
            lstKyLuat: [],
            lstKyLuatVisible: false,

            configDatePicker: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
                showTodayButton: true,
                locale: 'vi'
            },
            getDataESelect: {
                ESelect_gioiTinh: ESelectGender_MockData_ModalChiTietHocSinh, //gioitinh
                ESelect_trangThaiCB: [], //trangthaiCB
                ESelect_danToc: [], //dantoc
                ESelect_tonGiao: [], //tongiao
                ESelect_loaiCanbo: [], //loaicanbo
                ESelect_monDay: [], //monday
                ESelect_monDayKiemNghiem: [], //mondaykiemnghiem
                ESelect_chucVu: [], //chucvu
                ESelect_hinhThucHopDong: [], //hinhthuchopdong
                ESelect_nhiemVuKiemNghiem: [], //nhiemvukiemnghiem
                ESelect_ngach_hang: [], //ngach/hang
                ESelect_bacLuong: ESelectwage_TraCuuCanBoGV_MockData, //bacluong
                ESelect_KQBoiDuongThuongXuyen: [], //KQBoiduongthuongxuyen
                ESelect_trinhDoChuyenMonNghiepVu: [], //trinhdochuyenmonnghiepvu
                ESelect_trinhDoLLCT: [], //trinhdoLLCT
                ESelect_trinhDoQLGD: [], //trinhdoQLGD
                ESelect_thamGiaBDNghiepVuQLGD: [], //thamgiaBDnghiepvuQLGD
                ESelect_thamGiaBDCBQL_GVCotCan: [], //thamgiaBDCBQL_GVCotCan
                ESelect_thamGiaBDThaySach: [], //thamgianBDThaySach
                ESelect_ngoaiNguChinh: [], //ngoainguchinh
                ESelect_trinhDoDaoTaoNgoaiNgu: [], //trinhdodaotaongoaingu
                ESelect_nhomChungChiNgoaiNgu: [], //nhomchungchingoaingu
                ESelect_loaiChungChiNgoaiNgu: [], //loaichungchingoaingu
                ESelect_khungNhanLucNgoaiNgu: [], //khungnhanlucngoaingu
                ESelect_trinhDoTinHoc: [], //trinhdotinhoc
                ESelect_chuyenNganhChinh: [], //chuyennganhchinh
                ESelect_trinhDoChinh: ESlectMainLevel_MockData, //trinhdochinh
                ESelect_chuyenNganhKhac: [], //chuyennganhkhac
                ESelect_trinhDoKhac: ESlectMainLevel_MockData, //trinhdokhac
                ESelect_giaoVienCoChungChiTiengDanToc: [], //giaoviencochungchitiendantoc
                ESelect_vienChucCongChuc: [], //danhgiavienchuccongchuc
                ESelect_giaoVienDayGioi: [], //giaoviendaygioi
                ESelect_giaoVienChuNhiemGioi: [], //giaovienchunhiemgioi
                ESelect_tongPhuTrachDoiGioi: [] //tongphutrachdoigioi
            },
            selectedValue: {
                // <!-- ************ Hồ sơ học sinh  ************ -->
                input_hoTen: '', //hoten
                date_ngaySinh: '', //ngaysinh
                selectedValue_gioiTinh: [], //gioitinh
                selectedValue_trangThaiCB: [], //trangthaiCB
                date_ngayTrangThai: '', //ngayTrangthai
                checkbox_laTuyenMoi: false, //latuyenmoi
                input_CMTND_TCC: '', //CMTND/TCC
                date_ngayCap: '', //ngayCap
                input_email: '', //email
                input_maCanBo: '', //maCanBo
                selectedValue_danToc: [], //dantoc
                selectedValue_tonGiao: [], //tongiao
                input_noiSinh: '', //noisinh
                input_queQuan: '', //quequan
                input_soSoBHXH: '', //soSoBHXH
                checkbox_doanVien: '', //doanvien
                checkbox_dangVien: '', //dangvien
                // <!-- ************ Vị trí việc làm  ************ -->
                selectedValue_loaiCanBo: [], //loaiCanbo
                selectedValue_monDay: [], //monday
                selectedValue_monDayKiemNghiem: [], //mondaykiemnghiem
                selectedValue_nhomChucVu: [], //nhomchucvu
                selectedValue_hinhThucHopDong: [], //hinhthuchopdong
                date_ngayTuyen: '', //ngayTuyen
                input_coQuanTuyenDung: '', //coquantuyendung
                input_ngheNghiepKhiDucTuyenDung: '', //nghenghiepkhiduoctuyendung
                selectedValue_nhiemVuKiemNghiem: [], //nhiemvukiemnghiem
                input_soTietDayThucTrenTuan: '', //sotietdaythuctrentuan
                input_soTietThucNghiemTrenTuan: '', //sotietThucNghiemtrentuan
                checkbox_daTapHuanKyNangSong: false, //dataphuankynangsong
                checkbox_dayHSKTHocHoaNhap: false, //dayHSKThochoanhap
                checkbox_dangDay1BuoiNgay: false, //dangday1buoitrenngay
                checkbox_dangDay2BuoiNgay: false, //dangday2buoitrenngay
                checkbox_thamGiaCanBoBoiDuong: false, //thamgiacanboboiduong
                selectedValue_ngach_hang: [], //ngach/hang
                checkbox_coCCboiDuongTCCD: false, //coCCboiduongTCCD

                // <!-- ************ Phụ cấp ************ -->
                input_mucPhuCapThuHutNghe: '',
                input_mucPhuCapThamNien: '',
                input_mucPhuCapUuDaiNghe: '',
                input_mucPhuCapChucVuLanhDao: '',
                selectedValue_bacLuong: [],
                input_phamTramVuotKhung: '',
                input_heSoLuong: '',
                date_ngayHuong: '',

                // <!-- ************ Đào tạo, bồi dưỡng ************ -->
                selectedValue_KQBoiDuongThuongXuyen: [],
                selectedValue_trinhDoChuyenMonNghiepVu: [],
                selectedValue_trinhDoLLCT: [],
                selectedValue_trinhDoQuanLyGD: [],
                selectedValue_thamGiaBoiDuongNghiepVuQLGD: [],
                selectedValue_thamGiaBoiDuongCBGVCotCan: [],
                selectedValue_thamGiaBoiDuongThaySach: [],
                selectedValue_ngoaiNguChinh: [],
                selectedValue_trinhDoDaoTaoNgoaiNgu: [],
                selectedValue_nhomChungChiNgoaiNgu: [],
                selectedValue_loaiChungChiNgoaiNgu: [],
                input_diemNgoaiNgu: '',
                selectedValue_khungNangLucNgoaiNgu: [],
                selectedValue_trinhDoTinHoc: [],
                selectedValue_chuyenNganhChinh: [],
                selectedValue_trinhDoChinh: [],
                input_coSoDaoTao: '',
                selectedValue_chuyenNganhKhac: [],
                selectedValue_trinhDoKhac: [],
                selectedValue_giaoVienCoChungChiTiengDanToc: [],

                // <!-- ************ Đánh giá, phân loại ************ -->
                selectedValue_danhGiaCongChucVienChuc: [],
                selectedValue_giaoVienDayGioi: [],
                selectedValue_giaoVienChuNhiemDayGioi: [],
                selectedValue_tongPhuTrachGioi: [],
                input_danhHieuPhongtang: ''
            }
        }
    },

    watch: {
        async id_DisabledModalChiTietCanboGv(newValue) {
            await this.getDataThongTinCanboGv(newValue)
        }
    },
    methods: {
        handleOpenModalLstQuaTrinhLuong() {
            this.lstQuaTrinhLuongModalVisible = true
        },
        closeModalLstQuaTrinhLuong() {
            this.lstQuaTrinhLuongModalVisible = false
        },
        handleOpenModalLstQuaTrinhNgoaiNgu() {
            this.lstQuaTrinhNgoaiNguVisible = true
        },
        closeModalLstQuaTrinhNgoaiNgu() {
            this.lstQuaTrinhNgoaiNguVisible = false
        },

        handleOpenModalLstQuaTrinhDaoTaoBoiDuong() {
            this.lstQuaTrinhDaoTaoBoiDuongVisible = true
        },
        closeModallstQuaTrinhDaoTaoBoiDuong() {
            this.lstQuaTrinhDaoTaoBoiDuongVisible = false
        },
        handleOpenModalDanhGiaChuanNN_CBQL_GV() {
            this.danhGiaChuanNN_CBQL_GVVisible = true
        },
        closeModalDanhGiaChuanNN_CBQL_GV() {
            this.danhGiaChuanNN_CBQL_GVVisible = false
        },
        handleOpenModalLstKhenThuong() {
            this.lstKhenThuongVisible = true
        },
        closeModalLstKhenThuong() {
            this.lstKhenThuongVisible = false
        },
        handleOpenModalLstKyLuat() {
            this.lstKyLuatVisible = true
        },
        closeModalLstKyLuat() {
            this.lstKyLuatVisible = false
        },
        async getDataThongTinCanboGv(newValue) {
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    canBoTheoNamId: newValue
                }
                const response = await sendRequest(
                    Api.traCuu.traCuuCanBoGiaoVien.chitietCanboGv,
                    null,
                    request_Header,
                    request_Params
                )
                this.dataModal = response
                this.lstQuaTrinhLuong = JSON.parse(response.lstQuaTrinhLuong)
                this.lstQuaTrinhNgoaiNgu = JSON.parse(
                    response.lstQuaTrinhNgoaiNgu
                )
                this.lstQuaTrinhDaoTaoBoiDuong = JSON.parse(
                    response.lstQuaTrinhDaoTaoBoiDuong
                )
                this.danhGiaChuanNN_CBQL_GV = response
                this.lstKhenThuong = response.lstKhenThuongs
                this.lstKyLuat = response.lstKyLuats
                // <!-- ************ Hồ sơ học sinh  ************ -->
                this.selectedValue.input_hoTen = response.tenGiaoVien
                this.selectedValue.date_ngaySinh = response.ngaySinh
                this.selectedValue.selectedValue_gioiTinh =
                    response.gioiTinh === 0 ? 0 : 1
                const convertSelectedValue_trangThaiCB =
                    this.convertSelectedValue_dataStore(
                        this.dataChonTrangthaicanbo_Store,
                        response.trangThai
                    )
                this.selectedValue.selectedValue_trangThaiCB =
                    convertSelectedValue_trangThaiCB
                this.selectedValue.checkbox_laTuyenMoi = response.tuyenMoi
                this.selectedValue.input_CMTND_TCC = response.soCmnd
                this.selectedValue.date_ngayCap = response.ngayCapCmnd
                this.selectedValue.input_email = response.email
                this.selectedValue.input_maCanBo = response.maGiaoVien

                this.selectedValue.selectedValue_danToc =
                    this.convertSelectedValue_dataStore(
                        this.dataChonDanToc_Store,
                        response.maDanToc
                    )

                this.selectedValue.selectedValue_tonGiao =
                    this.convertSelectedValue_dataStore(
                        this.dataChonTonGiao_Store,
                        response.maTonGiao
                    )
                this.selectedValue.input_noiSinh = response.noiSinh
                this.selectedValue.input_queQuan = response.queQuan
                this.selectedValue.input_soSoBHXH = response.soSoBhxh
                this.selectedValue.checkbox_doanVien =
                    response.doanVien === 0 ? false : true
                this.selectedValue.checkbox_dangVien =
                    response.dangVien === 0 ? false : true

                // <!-- ************ Vị trí việc làm  ************ -->
                this.selectedValue.selectedValue_loaiCanBo =
                    this.convertSelectedValue_dataStore(
                        this.dataChonLoaiCanBo_Store,
                        response.loaiCanBo
                    )
                this.selectedValue.selectedValue_monDay =
                    this.convertSelectedValue_dataStore(
                        this.dataChonMonDay_Store,
                        response.maMonHoc
                    )
                this.selectedValue.selectedValue_monDayKiemNghiem =
                    this.convertSelectedValue_dataStore(
                        this.dataChonMonDay_Store,
                        response.monDayKiemNhiem
                    )
                this.selectedValue.selectedValue_nhomChucVu =
                    this.convertSelectedValue_dataStore(
                        this.dataChonChucVu_Store,
                        response.chucVu
                    )
                this.selectedValue.selectedValue_hinhThucHopDong =
                    this.convertSelectedValue_dataStore(
                        this.dataChonHinhThucHopDong_Store,
                        response.loaiHopDong
                    )
                this.selectedValue.date_ngayTuyen = response.ngayTuyenDung
                this.selectedValue.input_coQuanTuyenDung =
                    response.coQuanTuyenDung
                this.selectedValue.input_ngheNghiepKhiDucTuyenDung =
                    response.ngheNghiepTuyenDung
                this.selectedValue.selectedValue_nhiemVuKiemNghiem =
                    this.convertSelectedValue_dataStore(
                        this.dataChonNhiemVuKiemNghiem_Store,
                        response.nhiemVuKiemNhiem
                    )
                this.selectedValue.input_soTietDayThucTrenTuan =
                    response.soTietThucDay1Tuan
                this.selectedValue.input_soTietThucNghiemTrenTuan =
                    response.soTietKiemNhiem1Tuan
                this.selectedValue.checkbox_daTapHuanKyNangSong =
                    response.tapHuanKnsSkss === 0 ? false : true
                this.selectedValue.checkbox_dayHSKTHocHoaNhap =
                    response.dayHsktHocHoaNhap === 0 ? false : true
                this.selectedValue.checkbox_dangDay1BuoiNgay =
                    response.day1BuoiTrenNgay === 0 ? false : true
                this.selectedValue.checkbox_dangDay2BuoiNgay =
                    response.day2BuoiTrenNgay === 0 ? false : true
                this.selectedValue.checkbox_thamGiaCanBoBoiDuong =
                    response.thamGiaChuongTrinhBoiDuong
                this.selectedValue.selectedValue_ngach_hang =
                    this.convertSelectedValue_dataStore(
                        this.dataChonNganhCC_Store,
                        response.maNgach
                    )
                this.selectedValue.checkbox_coCCboiDuongTCCD =
                    response.coCcBdChucDanhNgheNghiep

                // <!-- ************ Phụ cấp  ************ -->
                this.selectedValue.input_mucPhuCapThuHutNghe =
                    response.mucPhuCapThuHut
                this.selectedValue.input_mucPhuCapThamNien =
                    response.mucPhuCapThamNien
                this.selectedValue.input_mucPhuCapUuDaiNghe =
                    response.mucPhuCapUuDai
                this.selectedValue.input_mucPhuCapChucVuLanhDao =
                    response.mucPhuCapLanhDao
                this.selectedValue.selectedValue_bacLuong =
                    this.convertSelectedValue_dataStore(
                        ESelectwage_TraCuuCanBoGV_MockData,
                        response.bacLuong
                    )
                const quaTrinhLuongs = response.quaTrinhLuongs
                this.selectedValue.input_phamTramVuotKhung = quaTrinhLuongs.map(
                    (item) => item.phanTramVuotKhung
                )
                this.selectedValue.input_heSoLuong = quaTrinhLuongs.map(
                    (item) => item.heSoLuong
                )
                this.selectedValue.date_ngayHuong = quaTrinhLuongs
                    ?.map((item) => item.ngayThangNam)
                    ?.pop()

                // <!-- ************ Đào tạo, bồi dưỡng  ************ -->
                this.selectedValue.selectedValue_KQBoiDuongThuongXuyen =
                    this.convertSelectedValue_dataStore(
                        this.dataChonBoiDuongTX_Store,
                        response.kqBdTx
                    )
                this.selectedValue.selectedValue_trinhDoChuyenMonNghiepVu =
                    this.convertSelectedValue_dataStore(
                        this.dataChonTrinhDoChuyenMonNV_Store,
                        response.trinhDoChuyenMonCaoNhat
                    )
                this.selectedValue.selectedValue_trinhDoLLCT =
                    this.convertSelectedValue_dataStore(
                        this.dataChonTrinhDoLLCT_Store,
                        response.trinhDoLyLuanChinhTri
                    )

                this.selectedValue.selectedValue_trinhDoQuanLyGD =
                    this.convertSelectedValue_dataStore(
                        this.dataChonTrinhDoQLGD_Store,
                        response.trinhDoQuanLyGiaoDuc
                    )
                this.selectedValue.selectedValue_thamGiaBoiDuongNghiepVuQLGD =
                    this.convertSelectedValue_dataStore(
                        this.dataChonBoiDuongNghiepVu_Store,
                        response.maBoiDuongNvu
                    )
                this.selectedValue.selectedValue_thamGiaBoiDuongCBGVCotCan =
                    this.convertSelectedValue_dataStore(
                        this.dataChonBoiDuongCBQLCotCan_Store,
                        response.maBoiDuongCbqlCotCan
                    )
                this.selectedValue.selectedValue_thamGiaBoiDuongThaySach =
                    this.convertSelectedValue_dataStore(
                        this.dataChonBoiDuongThaySach_Store,
                        response.maBoiDuongThaySach
                    )
                this.selectedValue.selectedValue_ngoaiNguChinh =
                    this.convertSelectedValue_dataStore(
                        this.dataChonNgoainguchinh_Store,
                        response.monNgoaiNguChinh
                    )
                this.selectedValue.selectedValue_trinhDoDaoTaoNgoaiNgu =
                    this.convertSelectedValue_dataStore(
                        this.dataChonTrinhDoNgoaingu_Store,
                        response.trinhDoNgoaiNgu
                    )
                this.selectedValue.selectedValue_nhomChungChiNgoaiNgu =
                    this.convertSelectedValue_dataStore(
                        this.dataChonNhomCChiNNgu_Store,
                        response.maNhomCchiNngu
                    )
                this.selectedValue.selectedValue_loaiChungChiNgoaiNgu =
                    this.convertSelectedValue_dataStore(
                        this.dataChonLoaiCChiNNgu_Store,
                        response.maLoaiCchiNngu
                    )
                this.selectedValue.input_diemNgoaiNgu = response.diemNgoaiNgu
                this.selectedValue.selectedValue_khungNangLucNgoaiNgu =
                    this.convertSelectedValue_dataStore(
                        this.dataChonKhungNLucNNgu_Store,
                        response.maKhungNlucNngu
                    )
                this.selectedValue.selectedValue_trinhDoTinHoc =
                    this.convertSelectedValue_dataStore(
                        this.dataChonTrinhDoTinHoc_Store,
                        response.trinhDoTinHoc
                    )
                this.selectedValue.selectedValue_chuyenNganhChinh =
                    this.convertSelectedValue_dataStore(
                        this.dataChonChuyenMon_Store,
                        response.chuyenNganhDaoTaoChinh
                    )
                this.selectedValue.selectedValue_trinhDoChinh =
                    this.convertSelectedValue_dataStore(
                        ESlectMainLevel_MockData,
                        response.trinhDoDaoTaoCnChinh
                    )
                this.selectedValue.input_coSoDaoTao = response.tenCsDaoTaoBd
                this.selectedValue.selectedValue_chuyenNganhKhac =
                    this.convertSelectedValue_dataStore(
                        this.dataChonChuyenMon_Store,
                        response.chuyenNganhDaoTaoKhac
                    )
                this.selectedValue.selectedValue_trinhDoKhac =
                    this.convertSelectedValue_dataStore(
                        ESlectMainLevel_MockData,
                        response.trinhDoDaoTaoCnKhac
                    )
                this.selectedValue.selectedValue_giaoVienCoChungChiTiengDanToc =
                    this.convertSelectedValue_dataStore(
                        this.dataChonCChiDTocTSo_Store,
                        response.maChungChiTiengDtocTso
                    )
                // <!-- ************ Đánh giá, phân loại  ************ -->
                this.selectedValue.selectedValue_danhGiaCongChucVienChuc =
                    this.convertSelectedValue_dataStore(
                        this.dataChonDGVienChuc_Store,
                        response.danhGiaVienChuc
                    )
                this.selectedValue.selectedValue_giaoVienDayGioi =
                    this.convertSelectedValue_dataStore(
                        this.dataChonDanhGiaGVGioi_Store,
                        response.gvDayGioi
                    )
                this.selectedValue.selectedValue_giaoVienChuNhiemDayGioi =
                    this.convertSelectedValue_dataStore(
                        this.dataChonGVCNGioi_Store,
                        response.maGiaoVienChuNhiemGioi
                    )
                this.selectedValue.selectedValue_tongPhuTrachGioi =
                    this.convertSelectedValue_dataStore(
                        this.dataChonTongPhuTrachDoiGioi_Store,
                        response.maGvTongPtrachDoiGioi
                    )
                this.selectedValue.input_danhHieuPhongtang =
                    response.danhHieuPhongTang
                console.log(response)
            } catch (error) {
                console.log(error)
            }
        },

        convertSelectedValue_dataStore(dataStore, mappingCode) {
            return dataStore
                ?.filter((item) => item.mappingCode == mappingCode)
                .map((item) => item.mappingCode)
                .pop()
        },
        convertCheckboxValueToNumber(checkboxValue) {
            return checkboxValue ? 1 : 0
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        },

        dataChonTrangthaicanbo_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrangthaicanbo'))
        },
        dataChonDanToc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDantoc'))
        },
        dataChonTonGiao_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTongiao'))
        },
        dataChonMonDay_Store() {
            return JSON.parse(localStorage.getItem('data_ChonMondaygiaovien'))
        },
        dataChonChucVu_Store() {
            return JSON.parse(localStorage.getItem('data_ChonLoaicanbo'))
        },
        dataChonHinhThucHopDong_Store() {
            return JSON.parse(localStorage.getItem('data_ChonHinhthuchopdong'))
        },
        dataChonNhiemVuKiemNghiem_Store() {
            return JSON.parse(localStorage.getItem('data_ChonNhiemvukiemnghiem'))
        },
        dataChonNganhCC_Store() {
            return JSON.parse(localStorage.getItem('data_ChonNganhCC'))
        },
        dataChonBoiDuongTX_Store() {
            return JSON.parse(localStorage.getItem('data_ChonBoiDuongTX'))
        },
        dataChonTrinhDoChuyenMonNV_Store() {
            return JSON.parse(
                localStorage.getItem('data_ChonTrinhdochuyenmonnghiepvu')
            )
        },
        dataChonTrinhDoLLCT_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrinhDoLLCT'))
        },
        dataChonTrinhDoQLGD_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrinhDoQLGD'))
        },
        dataChonBoiDuongNghiepVu_Store() {
            return JSON.parse(localStorage.getItem('data_ChonBoiDuongNghiepVu'))
        },
        dataChonBoiDuongCBQLCotCan_Store() {
            return JSON.parse(localStorage.getItem('data_ChonBoiDuongCBQLCotCan'))
        },
        dataChonBoiDuongThaySach_Store() {
            return JSON.parse(localStorage.getItem('data_ChonBoiDuongThaySach'))
        },
        dataChonNgoainguchinh_Store() {
            return JSON.parse(localStorage.getItem('data_ChonNgoaingu'))
        },
        dataChonTrinhDoNgoaingu_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrinhDoNgoaiNgu'))
        },
        dataChonNhomCChiNNgu_Store() {
            return JSON.parse(localStorage.getItem('data_ChonNhomCChiNNgu'))
        },
        dataChonLoaiCChiNNgu_Store() {
            return JSON.parse(localStorage.getItem('data_ChonLoaiCChiNNgu'))
        },
        dataChonKhungNLucNNgu_Store() {
            return JSON.parse(localStorage.getItem('data_ChonKhungNLucNNgu'))
        },
        dataChonTrinhDoTinHoc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrinhDoTinHoc'))
        },
        dataChonChuyenMon_Store() {
            return JSON.parse(localStorage.getItem('data_ChonChuyenMon'))
        },
        dataChonCChiDTocTSo_Store() {
            return JSON.parse(localStorage.getItem('data_ChonCChiTiengDTocTSo'))
        },
        dataChonDGVienChuc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDGVienChuc'))
        },
        dataChonDanhGiaGVGioi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDanhGiaGVGioi'))
        },
        dataChonGVCNGioi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonGVCNGioi'))
        },
        dataChonTongPhuTrachDoiGioi_Store() {
            return JSON.parse(
                localStorage.getItem('data_ChonTongPhuTrachDoiGioi')
            )
        },
        dataChonLoaiCanBo_Store() {
            return JSON.parse(localStorage.getItem('data_ChonNhomcanbo'))
        }
    }
}
</script>

<style scoped>
::v-deep .el-input.is-disabled .el-input__inner,
::v-deep .el-textarea.is-disabled .el-textarea__inner,
::v-deep .el-input.is-disabled .el-input__inner {
    background-color: #eee !important;
    color: black !important;
}
::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
    border-color: black !important;
    background-color: #eee !important;
}
.title {
    font-weight: 700;
    font-size: 13px;
}
.title span {
    color: red;
}

.custom-date-picker {
    height: 32px;
}
.custom-date-picker::placeholder {
    color: gainsboro;

    font-size: 13px;
    background-color: transparent;
    appearance: none;
}

.row {
    margin: 0 auto;
}
.layout-btn {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}

.btn-delete {
    background-color: var(--orange);
}
</style>
