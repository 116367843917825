<template>
    <el-dialog
        :visible="xemDiemModalVisible"
        @close="closeModalXemDiem"
        width="80%"
    >
        <span slot="title">
            Điểm chi tiết môn
            <hr />
        </span>
        <div class="row">
            <div class="col-md-6">
                <label>Học kỳ 1</label>
                <div class="layout-table">
                    <table
                        class="table table-bordered table-hover centered-table"
                    >
                        <thead>
                            <tr>
                                <th class="text-thead">STT</th>
                                <th class="text-thead">Môn học</th>
                                <th class="text-thead">Loại điểm</th>
                                <th class="text-thead">Điểm số</th>
                                <th class="text-thead">Nhận xét</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="tableDataHocKy1.length === 0">
                                <tr>
                                    <td
                                        class="text-tbody text-center"
                                        colspan="9"
                                    >
                                        Không có bản ghi nào.
                                    </td>
                                </tr>
                            </template>
                            <tr
                                v-for="(item, index) in tableDataHocKy1"
                                :key="index"
                            >
                                <td class="text-tbody text-center">
                                    {{ index + 1 }}
                                </td>
                                <td class="text-tbody text-center">
                                    {{ item.tenMonHoc }}
                                </td>
                                <td class="text-tbody">
                                    {{ item.loaiDiem }}
                                </td>
                                <td class="text-tbody">
                                    {{ item.diemSo }}
                                </td>
                                <td class="text-tbody">
                                    {{ item.nhanXetCuaGv }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-6">
                <label>Học kỳ 2</label>
                <div class="layout-table">
                    <table
                        class="table table-bordered table-hover centered-table"
                    >
                        <thead>
                            <tr>
                                <th class="text-thead">STT</th>
                                <th class="text-thead">Môn học</th>
                                <th class="text-thead">Loại điểm</th>
                                <th class="text-thead">Điểm số</th>
                                <th class="text-thead">Nhận xét</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="tableDataHocK2.length === 0">
                                <tr>
                                    <td
                                        class="text-tbody text-center"
                                        colspan="9"
                                    >
                                        Không có bản ghi nào.
                                    </td>
                                </tr>
                            </template>
                            <tr
                                v-for="(item, index) in tableDataHocK2"
                                :key="index"
                            >
                                <td class="text-tbody text-center">
                                    {{ index + 1 }}
                                </td>
                                <td class="text-tbody text-center">
                                    {{ item.tenMonHoc }}
                                </td>
                                <td class="text-tbody">
                                    {{ item.loaiDiem }}
                                </td>
                                <td class="text-tbody">
                                    {{ item.diemSo }}
                                </td>
                                <td class="text-tbody">
                                    {{ item.nhanXetCuaGv }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="layout-btn">
            <div>
                <CustomButton
                    label="Đóng"
                    size="small"
                    class="btn-default btn-delete"
                    @click="closeModalXemDiem"
                />
            </div>
        </div>
    </el-dialog>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import { mapState } from 'vuex'
import sendRequest from '@/services'

import Api from '@/constants/Api'
export default {
    name: 'ModalThemMoiTruongHoc',
    props: {
        xemDiemModalVisible: {
            type: Boolean,
            required: true
        },
        closeModalXemDiem: {
            type: Function,
            required: true
        },

        dataModalXemDiem: {
            type: Object,
            required: true
        }
    },

    components: { CustomButton },
    data() {
        return {
            tableDataHocKy1: [],
            tableDataHocK2: []
        }
    },

    methods: {
        async getDataXemDiem() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Data = {
                    maHocSinh: this.dataModalXemDiem.maHocSinh,
                    namHoc: this.dataModalXemDiem.namHoc,
                    hocKy: this.dataModalXemDiem.hocKy
                }
                const response = await sendRequest(
                    Api.traCuu.traCuuKQHT.xemDiem,
                    request_Data,
                    request_Header
                )
                this.tableDataHocKy1 = response.ki1
                this.tableDataHocK2 = response.ki2
                loading.close()
            } catch (error) {
                loading.close()
            }
        }
    },
    watch: {
        xemDiemModalVisible(newValues) {
            if (newValues === true) {
                this.getDataXemDiem()
            }
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped>
hr {
    border: 1px solid green;
}
table thead tr {
    background: rgb(228, 235, 245);
}
.layout-table {
    max-height: 400px;
    overflow-y: auto;
}

.table {
    white-space: nowrap;
}
.centered-table {
    margin-left: auto;
    margin-right: auto;
}

.centered-table th {
    text-align: center;
}
.table-bordered {
    background-color: #fff;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}
.table-hover tbody tr:hover {
    background-color: #f5f5f5;
}

.text-thead {
    font-size: 13px;

    vertical-align: middle; /* Căn giữa theo chiều dọc */
}
.text-tbody {
    font-size: 13px;
    vertical-align: middle;
}
.text-center {
    text-align: center;
}
.layout-btn {
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}

.btn-delete {
    background-color: var(--orange);
}
</style>
