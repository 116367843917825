<template>
    <div>
        <CustomBreadcrumb :title="' BÁO CÁO'" content="BÁO CÁO ĐỘNG" />

        <el-tabs type="border-card" @tab-click="handleClick">
            <el-tab-pane label="Báo cáo cán bộ">
                <BaoCaoCanBo :ESelect_DoiTac="ESelect_DoiTac" />
            </el-tab-pane>
            <el-tab-pane label="Báo cáo học sinh">
                <BaoCaoHocSinh :ESelect_DoiTac="ESelect_DoiTac" />
            </el-tab-pane>
            <el-tab-pane label="Báo cáo trường học">
                <BaoCaoTruongHoc :ESelect_DoiTac="ESelect_DoiTac" />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'
import BaoCaoCanBo from './BaoCaoCanBo.vue'
import BaoCaoHocSinh from './BaoCaoHocSinh'
import BaoCaoTruongHoc from './BaoCaoTruongHoc'
import { mapState } from 'vuex'
import sendRequest from '@/services'
import Api from '@/constants/Api'

export default {
    name: 'BaoCaoDong',
    components: {
        CustomBreadcrumb,
        BaoCaoCanBo,
        BaoCaoHocSinh,
        BaoCaoTruongHoc
    },
    data() {
        return {
            activeTab: 0, // Index của tab đầu tiên được chọn mặc định
            ESelect_DoiTac: []
        }
    },
    methods: {
        handleClick(tab, event) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            if (tab.index) {
                this.activeTab = tab.index
                loading.close()
            }
        },
        async getMaDoiTac() {
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    start: 0,
                    limit: 9999
                }
                const response = await sendRequest(
                    Api.baoCao.baoCaoDong.duLieuDoiTac,
                    null,
                    request_Header,
                    request_Params
                )

                this.ESelect_DoiTac = response.rows.map((item) => ({
                    title: `${item.tenDoiTac} - [${item.maDoiTac}]`,
                    value: item.maDoiTac
                }))
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {
        this.getMaDoiTac()
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped></style>
