<template>
    <el-dialog
        :visible="dislabelChiTietLopHocModalVisible"
        @close="closeDislabelModalChitietLopHoc"
        :fullscreen="true"
    >
        <span slot="title"> Thông tin lớp học </span>
        <div class="row">
            <div class="col-6 align-items-center">
                <div class="row">
                    <div class="col-4 title">Khối học</div>
                    <div class="col-8">
                        <el-select
                            v-model="selectedValue.selectedValue_khoiHoc"
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            :disabled="true"
                        >
                            <el-option
                                v-for="item in getDataESelect.ESelect_khoiHoc"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            />
                        </el-select>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-4 title">
                        Tên lớp học <span class="span">*</span>
                    </div>
                    <div class="col-8">
                        <el-input
                            :disabled="true"
                            size="small"
                            placeholder=""
                            style="width: 100%"
                            v-model="selectedValue.input_tenLop"
                        ></el-input>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-4 title">Mã lớp</div>
                    <div class="col-8">
                        <el-input
                            size="small"
                            style="width: 100%"
                            placeholder=""
                            v-model="selectedValue.input_maLop"
                            :disabled="true"
                        ></el-input>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-4 title">Mã định danh</div>
                    <div class="col-8">
                        <el-input
                            size="small"
                            style="width: 100%"
                            placeholder=""
                            v-model="selectedValue.input_maDinhDanh"
                            :disabled="true"
                        ></el-input>
                    </div>
                </div>
                <div
                    v-if="
                        dataUpdate.capHoc == 1 ||
                        dataUpdate.capHoc == 2 ||
                        dataUpdate.capHoc == 3
                    "
                    class="row mt-3"
                >
                    <div class="col-4 title">Số buổi học/tuần</div>
                    <div class="col-8">
                        <el-select
                            v-model="
                                selectedValue.selectedValue_soBuoiHocTrenTuan
                            "
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            :disabled="true"
                        >
                            <el-option
                                v-for="item in getDataESelect.ESelect_soBuoiHocTrenTuan"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            />
                        </el-select>
                    </div>
                </div>
                <div
                    v-if="
                        dataUpdate.capHoc == 1 ||
                        dataUpdate.capHoc == 2 ||
                        dataUpdate.capHoc == 3
                    "
                    class="row mt-3"
                >
                    <div class="col-4 title">Số tiết học/tuần</div>
                    <div class="col-8">
                        <el-select
                            v-model="
                                selectedValue.selectedValue_soTietHocTrenTuan
                            "
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            :disabled="true"
                        >
                            <el-option
                                v-for="item in this.dataChonTietHoc_Store"
                                :key="item.mappingCode"
                                :label="item.constantTitle"
                                :value="item.mappingCode"
                            />
                        </el-select>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-4 title">Điểm trường</div>
                    <div class="col-8">
                        <el-select
                            v-model="selectedValue.selectedValue_diemTruong"
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            :disabled="true"
                        >
                            <el-option
                                v-for="item in getDataESelect.ESelect_diemTruong"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            />
                        </el-select>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-4 title">Buổi học</div>
                    <div class="col-8">
                        <el-select
                            v-model="selectedValue.selectedValue_buoiHoc"
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            :disabled="true"
                        >
                            <el-option
                                v-for="item in getDataESelect.ESelect_buoiHoc"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            />
                        </el-select>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-4 title">Có trẻ khuyết tật</div>
                    <div class="col-8">
                        <el-checkbox
                            v-model="selectedValue.checkbox_coTreKhuyetTat"
                            :disabled="true"
                        />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-4 title">Lớp ghép</div>
                    <div class="col-8">
                        <el-checkbox
                            v-model="selectedValue.checkbox_lopGhep"
                            :disabled="true"
                        />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-4 title">Ghép vào lớp</div>
                    <div class="col-8">
                        <el-input
                            size="small"
                            style="width: 100%"
                            placeholder=""
                            v-model="selectedValue.input_ghepVaoLop"
                            :disabled="true"
                        ></el-input>
                    </div>
                </div>
                <div
                    v-if="dataUpdate.capHoc == 2 || dataUpdate.capHoc == 3"
                    class="row mt-3"
                >
                    <div class="col-4 title">Lớp chuyên</div>
                    <div class="col-8">
                        <el-checkbox
                            v-model="selectedValue.checkbox_lopChuyen"
                            :disabled="true"
                        />
                    </div>
                </div>
                <div
                    v-if="dataUpdate.capHoc == 2 || dataUpdate.capHoc == 3"
                    class="row mt-3"
                >
                    <div class="col-4 title">Phân ban</div>
                    <div class="col-8">
                        <el-select
                            v-model="selectedValue.selectedValue_phanBan"
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            :disabled="true"
                        >
                            <el-option
                                v-for="item in getDataESelect.ESelect_phanBan"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            />
                        </el-select>
                    </div>
                </div>
                <div
                    v-if="
                        dataUpdate.capHoc == 1 ||
                        dataUpdate.capHoc == 2 ||
                        dataUpdate.capHoc == 3
                    "
                    class="row mt-3"
                >
                    <div class="col-4 title">Lớp VNEN</div>
                    <div class="col-8">
                        <el-checkbox
                            v-model="selectedValue.checkbox_lopVNEN"
                            :disabled="true"
                        />
                    </div>
                </div>
                <div
                    v-if="
                        dataUpdate.capHoc == 1 ||
                        dataUpdate.capHoc == 2 ||
                        dataUpdate.capHoc == 3
                    "
                    class="row mt-3"
                >
                    <div class="col-4 title">Đủ thiết bị dạy học Tiếng Việt</div>
                    <div class="col-8">
                        <el-checkbox
                            v-model="selectedValue.checkbox_thietBiDhocTviet"
                            :disabled="true"
                        />
                    </div>
                </div>
                <div
                    v-if="
                        dataUpdate.capHoc == 1 ||
                        dataUpdate.capHoc == 2 ||
                        dataUpdate.capHoc == 3
                    "
                    class="row mt-3"
                >
                    <div class="col-4 title">Đủ thiết bị dạy học Toán</div>
                    <div class="col-8">
                        <el-checkbox
                            v-model="selectedValue.checkbox_thietBiDhocToan"
                            :disabled="true"
                        />
                    </div>
                </div>
                <div
                    v-if="
                        dataUpdate.capHoc == 1 ||
                        dataUpdate.capHoc == 2 ||
                        dataUpdate.capHoc == 3
                    "
                    class="row mt-3"
                >
                    <div class="col-4 title">Có Đ.diện cha, mẹ hs lớp</div>
                    <div class="col-8">
                        <el-checkbox
                            v-model="selectedValue.checkbox_daiDienChaMeLop"
                            :disabled="true"
                        />
                    </div>
                </div>
                <div
                    v-if="
                        dataUpdate.capHoc == 1 ||
                        dataUpdate.capHoc == 2 ||
                        dataUpdate.capHoc == 3
                    "
                    class="row mt-3"
                >
                    <div class="col-4 title">Có Đ.diện cha, mẹ hs trường</div>
                    <div class="col-8">
                        <el-checkbox
                            v-model="selectedValue.checkbox_daiDienChaMeTruong"
                            :disabled="true"
                        />
                    </div>
                </div>

                <div
                    v-if="
                        dataUpdate.capHoc == 1 ||
                        dataUpdate.capHoc == 2 ||
                        dataUpdate.capHoc == 3
                    "
                    class="row mt-3"
                >
                    <div class="col-4 title">Học sinh học nghề</div>
                    <div class="col-8">
                        <el-checkbox
                            v-model="selectedValue.checkbox_coHocSinhHocNghe"
                            :disabled="true"
                        />
                    </div>
                </div>
                <div
                    v-if="
                        dataUpdate.capHoc == 1 ||
                        dataUpdate.capHoc == 2 ||
                        dataUpdate.capHoc == 3
                    "
                    class="row mt-3"
                >
                    <div class="col-4 title">Kiểu lớp</div>
                    <div class="col-8">
                        <el-select
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            v-model="selectedValue.selectedValue_kieuLop"
                            :disabled="true"
                        >
                            <el-option
                                v-for="item in getDataESelect.ESelect_kieuLop"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            />
                        </el-select>
                    </div>
                </div>
                <div
                    v-if="
                        dataUpdate.capHoc == 1 ||
                        dataUpdate.capHoc == 2 ||
                        dataUpdate.capHoc == 3
                    "
                    class="row mt-3"
                >
                    <div class="col-4 title">Bộ sách giáo khoa sử dụng</div>
                    <div class="col-8">
                        <el-select
                            v-model="
                                selectedValue.selectedValue_boSachGiaoKhoaSuDung
                            "
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            :disabled="true"
                        >
                            <el-option
                                v-for="item in getDataESelect.ESelect_boSachGiaoKhoaSuDung"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            />
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="col-6 align-items-center">
                <div class="row">
                    <div class="col-4 title">Giáo viên chủ nhiệm</div>
                    <div class="col-8">
                        <el-select
                            v-model="selectedValue.selectedValue_giaoVienChuNhiem"
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            :disabled="true"
                        >
                            <el-option
                                v-for="item in getDataESelect.ESelect_giaoVienChuNhiem"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            />
                        </el-select>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-4 title">Mã GVCN đầu năm</div>
                    <div class="col-8">
                        <el-input
                            size="small"
                            style="width: 100%"
                            placeholder=""
                            v-model="selectedValue.input_maGvcn"
                            :disabled="true"
                        ></el-input>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-4 title">Mã GVCN cuối năm</div>
                    <div class="col-8">
                        <el-input
                            size="small"
                            style="width: 100%"
                            placeholder=""
                            v-model="selectedValue.input_maGvcnCn"
                            :disabled="true"
                        ></el-input>
                    </div>
                </div>
                <div
                    v-if="
                        dataUpdate.capHoc == 1 ||
                        dataUpdate.capHoc == 2 ||
                        dataUpdate.capHoc == 3
                    "
                    class="row mt-3"
                >
                    <div class="col-4 title">Học ngoại ngữ 1</div>
                    <div class="col-8">
                        <el-select
                            v-model="selectedValue.selectedValue_HocNgoaiNgu1"
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            :disabled="true"
                        >
                            <el-option
                                v-for="item in this.dataChonNgoaingu_Store"
                                :key="item.mappingCode"
                                :label="item.constantTitle"
                                :value="item.mappingCode"
                            />
                        </el-select>
                    </div>
                </div>
                <div
                    v-if="
                        dataUpdate.capHoc == 1 ||
                        dataUpdate.capHoc == 2 ||
                        dataUpdate.capHoc == 3
                    "
                    class="row mt-3"
                >
                    <div class="col-4 title">Chương trình học (NN1)</div>
                    <div class="col-8">
                        <el-select
                            v-model="
                                selectedValue.selectedValue_ChuongTrinhHocNgoaiNgu1
                            "
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            :disabled="true"
                        >
                            <el-option
                                v-for="item in getDataESelect.ESelect_ChuongTrinhHocNgoaiNgu1"
                                :key="item.mappingCode"
                                :label="item.constantTitle"
                                :value="item.mappingCode"
                            />
                        </el-select>
                    </div>
                </div>
                <div
                    v-if="
                        dataUpdate.capHoc == 1 ||
                        dataUpdate.capHoc == 2 ||
                        dataUpdate.capHoc == 3
                    "
                    class="row mt-3"
                >
                    <div class="col-4 title">Số tiết NN/tuần (NN1)</div>
                    <div class="col-8">
                        <el-select
                            v-model="
                                selectedValue.selectedValue_soTietNgoaiNgu1TrenTuan
                            "
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            :disabled="true"
                        >
                            <el-option
                                v-for="item in getDataESelect.ESelect_soTietNgoaiNgu1TrenTuan"
                                :key="item.mappingCode"
                                :label="item.constantTitle"
                                :value="item.mappingCode"
                            />
                        </el-select>
                    </div>
                </div>
                <div
                    v-if="
                        dataUpdate.capHoc == 1 ||
                        dataUpdate.capHoc == 2 ||
                        dataUpdate.capHoc == 3
                    "
                    class="row mt-3"
                >
                    <div class="col-4 title">Học ngoại ngữ 2</div>
                    <div class="col-8">
                        <el-select
                            v-model="selectedValue.selectedValue_HocNgoaiNgu2"
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            :disabled="true"
                        >
                            <el-option
                                v-for="item in this.dataChonNgoaingu_Store"
                                :key="item.mappingCode"
                                :label="item.constantTitle"
                                :value="item.mappingCode"
                            />
                        </el-select>
                    </div>
                </div>
                <div
                    v-if="
                        dataUpdate.capHoc == 1 ||
                        dataUpdate.capHoc == 2 ||
                        dataUpdate.capHoc == 3
                    "
                    class="row mt-3"
                >
                    <div class="col-4 title">Chương trình học (NN2)</div>
                    <div class="col-8">
                        <el-select
                            v-model="
                                selectedValue.selectedValue_ChuongTrinhHocNgoaiNgu2
                            "
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            :disabled="true"
                        >
                            <el-option
                                v-for="item in getDataESelect.ESelect_ChuongTrinhHocNgoaiNgu2"
                                :key="item.mappingCode"
                                :label="item.constantTitle"
                                :value="item.mappingCode"
                            />
                        </el-select>
                    </div>
                </div>
                <div
                    v-if="
                        dataUpdate.capHoc == 1 ||
                        dataUpdate.capHoc == 2 ||
                        dataUpdate.capHoc == 3
                    "
                    class="row mt-3"
                >
                    <div class="col-4 title">Số tiết NN/tuần (NN2)</div>
                    <div class="col-8">
                        <el-select
                            v-model="
                                selectedValue.selectedValue_soTietNgoaiNgu2TrenTuan
                            "
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            :disabled="true"
                        >
                            <el-option
                                v-for="item in getDataESelect.ESelect_soTietNgoaiNgu2TrenTuan"
                                :key="item.mappingCode"
                                :label="item.constantTitle"
                                :value="item.mappingCode"
                            />
                        </el-select>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-4 title">Bán trú</div>
                    <div class="col-8">
                        <el-checkbox
                            v-model="selectedValue.checkbox_banTru"
                            :disabled="true"
                        />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-4 title">Học bán trú</div>
                    <div class="col-8">
                        <el-select
                            v-model="selectedValue.selectedValue_hocBanTru"
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            :disabled="true"
                        >
                            <el-option
                                v-for="item in this.dataChonHocBanTru_Store"
                                :key="item.mappingCode"
                                :label="item.constantTitle"
                                :value="item.mappingCode"
                            />
                        </el-select>
                    </div>
                </div>
                <div
                    v-if="dataUpdate.capHoc == 4 || dataUpdate.capHoc == 5"
                    class="row mt-3"
                >
                    <div class="col-4 title">C.trình giáo dục MN mới</div>
                    <div class="col-8">
                        <el-checkbox
                            v-model="selectedValue.checkbox_ctrinhGdmnMoi"
                            :disabled="true"
                        />
                    </div>
                </div>
                <div
                    v-if="dataUpdate.capHoc == 4 || dataUpdate.capHoc == 5"
                    class="row mt-3"
                >
                    <div class="col-4 title">C.trình chăm sóc giáo dục</div>
                    <div class="col-8">
                        <el-checkbox
                            v-model="selectedValue.checkbox_ctrinhCsocGduc"
                            :disabled="true"
                        />
                    </div>
                </div>
                <div
                    v-if="
                        dataUpdate.capHoc == 1 ||
                        dataUpdate.capHoc == 2 ||
                        dataUpdate.capHoc == 3
                    "
                    class="row mt-3"
                >
                    <div class="col-4 title">Lớp chuyên biệt</div>
                    <div class="col-8">
                        <el-checkbox
                            v-model="selectedValue.checkbox_lopChuyenBiet"
                            :disabled="true"
                        />
                    </div>
                </div>
                <div
                    v-if="dataUpdate.capHoc == 2 || dataUpdate.capHoc == 3"
                    class="row mt-3"
                >
                    <div class="col-4 title">
                        Hình thức đào tạo học tập - GDTX
                    </div>
                    <div class="col-8">
                        <el-select
                            v-model="selectedValue.selectedValue_hinhThucHocTap"
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            :disabled="true"
                        >
                            <el-option
                                v-for="item in getDataESelect.ESelect_hinhThucHocTap"
                                :key="item.mappingCode"
                                :label="item.constantTitle"
                                :value="item.mappingCode"
                            />
                        </el-select>
                    </div>
                </div>
                <div
                    class="row mt-3"
                    v-if="dataUpdate.capHoc == 2 || dataUpdate.capHoc == 3"
                >
                    <div class="col-4 title">Đào tạo, bồi dưỡng</div>
                    <div class="col-8">
                        <el-select
                            v-model="selectedValue.selectedValue_daoTaoBoiDuong"
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            :disabled="true"
                        >
                            <el-option
                                v-for="item in this
                                    .dataChonLopDaotaoboiduong_Store"
                                :key="item.mappingCode"
                                :label="item.constantTitle"
                                :value="item.mappingCode"
                            />
                        </el-select>
                    </div>
                </div>
                <div
                    v-if="dataUpdate.capHoc == 2 || dataUpdate.capHoc == 3"
                    class="row mt-3"
                >
                    <div class="col-4 title">Hướng nghiệp dạy nghề</div>
                    <div class="col-8">
                        <el-select
                            v-model="
                                selectedValue.selectedValue_huongnghiepdaynghe
                            "
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            :disabled="true"
                        >
                            <el-option
                                v-for="item in this
                                    .dataChonHuongnghiepdaynghe_Store"
                                :key="item.mappingCode"
                                :label="item.constantTitle"
                                :value="item.mappingCode"
                            />
                        </el-select>
                    </div>
                </div>
            </div>
        </div>

        <div class="layout-btn">
            <CustomButtonVue
                @click="this.closeDislabelModalChitietLopHoc"
                label="Đóng"
                style="background-color: #e14a02; font-weight: 600; color: #fff"
                size="small"
            />
        </div>
    </el-dialog>
</template>
<script>
import {
    ESelectlessonsPerWeek_MockData,
    ESelectDivision_MockData,
    ESelectClassType_MockData,
    ESelectSetOfTextbooks_MockData,
    ESelectForeignLanguageProgram_MockData,
    ESelectNumberOfForeignLanguageLessons_MockData,
    ESelectLearningForm_MockData,
    ESelectLesson,
    ESelectSchoolBlock_MockData
} from '@/mock_data'
import CustomButtonVue from '@/components/CustomButton.vue'
import sendRequest from '@/services'
import { mapState } from 'vuex'
import Api from '@/constants/Api'
export default {
    name: 'ModalChitietLopHoc',
    props: {
        dislabelChiTietLopHocModalVisible: {
            type: Boolean,
            required: true
        },
        closeDislabelModalChitietLopHoc: {
            type: Function,
            required: true
        },
        id_dislabelModalChiTietLopHoc: {
            type: String,
            required: true
        },
        handleSearch: Function
    },
    components: { CustomButtonVue },
    data() {
        return {
            namHoc_giaoVienChuNhiem: '',
            maTruong_GiaoVienChuNhiem: '',
            dataUpdate: {},

            getDataESelect: {
                ESelect_khoiHoc: ESelectSchoolBlock_MockData, //khoihoc
                ESelect_soBuoiHocTrenTuan: ESelectlessonsPerWeek_MockData, //sobuoihoctrentuan
                ESelect_soTietHocTrenTuan: [], //sotiethoctrentuan
                ESelect_diemTruong: [], //diemtruong
                ESelect_buoiHoc: ESelectLesson, //buoihoc
                ESelect_phanBan: ESelectDivision_MockData, //phanBan
                ESelect_kieuLop: ESelectClassType_MockData, //kieuLop
                ESelect_boSachGiaoKhoaSuDung: ESelectSetOfTextbooks_MockData, //Bộ sách giáo khoa sử dụng

                ESelect_giaoVienChuNhiem: [], //giaovienchunhiem
                ESelect_HocNgoaiNgu1: [], //hocngoaingu1
                ESelect_ChuongTrinhHocNgoaiNgu1:
                    ESelectForeignLanguageProgram_MockData, //chuongtrinhhocngoaingu1
                ESelect_soTietNgoaiNgu1TrenTuan:
                    ESelectNumberOfForeignLanguageLessons_MockData, //sotietngoaingu1trentuan
                ESelect_HocNgoaiNgu2: [], //hocngoaingu2
                ESelect_ChuongTrinhHocNgoaiNgu2:
                    ESelectForeignLanguageProgram_MockData, //chuongtrinhhocngoaingu2
                ESelect_soTietNgoaiNgu2TrenTuan:
                    ESelectNumberOfForeignLanguageLessons_MockData, //sotietngoaingu2trentuan

                ESelect_hocBanTru: [], //hocBanTru
                checkbox_chuyenBiet: false, //chuyenbiet
                ESelect_hinhThucHocTap: ESelectLearningForm_MockData, //  Hình thức học tập
                ESelect_daoTaoBoiDuong: [], //Đào tạo, bồi dưỡng
                ESelect_huongnghiepdaynghe: [] //Hướng nghiệp dạy nghề
            },

            selectedValue: {
                selectedValue_khoiHoc: '', //khoihoc

                input_tenLop: '', //tenlop
                input_maLop: '', //maLop
                input_maDinhDanh: '', //maDinhDanh
                selectedValue_soBuoiHocTrenTuan: '', //sobuoihoctrentuan

                selectedValue_soTietHocTrenTuan: '', //sotiethoctrentuan
                selectedValue_diemTruong: '', //diemTruong
                selectedValue_buoiHoc: '', //buoihoc
                selectedValue_phanBan: '', //phanBan
                selectedValue_kieuLop: '', //kieuLop
                selectedValue_boSachGiaoKhoaSuDung: '', //Bộ sách giáo khoa sử dụng
                selectedValue_hinhThucHocTap: '', //  Hình thức học tập
                selectedValue_daoTaoBoiDuong: '', //Đào tạo, bồi dưỡng
                selectedValue_huongnghiepdaynghe: '', //Hướng nghiệp dạy nghề

                selectedValue_giaoVienChuNhiem: '', //giaovienchunhiem
                input_maGvcn: '',
                input_maGvcnCn: '',
                selectedValue_HocNgoaiNgu1: '', //hocngoaingu1
                selectedValue_ChuongTrinhHocNgoaiNgu1: '', //chuongtrinhhocngoaingu1
                selectedValue_soTietNgoaiNgu1TrenTuan: '', //sotietngoaingu1trentuan
                selectedValue_HocNgoaiNgu2: '', //hocngoaingu2
                selectedValue_ChuongTrinhHocNgoaiNgu2: '', //chuongtrinhhocngoaingu2
                selectedValue_soTietNgoaiNgu2TrenTuan: '', //sotietngoaingu2trentuan
                selectedValue_hocBanTru: '', //hocBanTru
                checkbox_ctrinhGdmnMoi: false, //ctrinhGdmnMoi
                checkbox_ctrinhCsocGduc: false, //ctrinhCsocGduc
                checkbox_lopChuyenBiet: false, //lopchuyenbiet
                checkbox_banTru: false, //banTru
                checkbox_coTreKhuyetTat: false, //cotrekhuyetat
                checkbox_lopGhep: false, //lopGhep
                input_ghepVaoLop: '', //ghepVaoLop
                checkbox_lopVNEN: false, //lopVNEN
                checkbox_thietBiDhocTviet: false, //duTBDH_TiengViet
                checkbox_thietBiDhocToan: false, //duTBDH_Toan
                checkbox_daiDienChaMeLop: false, //daidienchamelop
                checkbox_daiDienChaMeTruong: false, //daidienchametruong
                checkbox_lopChuyen: false, //lopChuyen
                checkbox_coHocSinhHocNghe: false, //hocsinhhocnghe
                checkbox_coDDienChaMeHSLop: false, //Có Đ.diện cha, mẹ hs lớp
                checkbox_coDDienChaMeHSTruong: false //Có Đ.diện cha, mẹ hs trường
            }
        }
    },
    watch: {
        async id_dislabelModalChiTietLopHoc(newValue) {
            await this.getDataThongTinChitietLopHoc(newValue)
        }
    },
    methods: {
        async getDataThongTinChitietLopHoc(newValue) {
            const request_Header = {
                token: this.authToken
            }
            const request_Params = {
                id: newValue
            }
            try {
                const response = await sendRequest(
                    Api.traCuu.traCuuLopHoc.thongTinChiTietLophoc,
                    null,
                    request_Header,
                    request_Params
                )

                this.namHoc_giaoVienChuNhiem = response.namHoc
                this.maTruong_GiaoVienChuNhiem = response.maTruongHoc

                this.selectedValue.selectedValue_khoiHoc = response.khoiHoc
                this.selectedValue.input_tenLop = response.tenLopHoc
                this.selectedValue.input_maLop = response.maLopHoc
                this.selectedValue.input_maDinhDanh = response.moetCode
                this.selectedValue.selectedValue_diemTruong =
                    response.maDiemTruong
                const convertSelectedValue_soBuoiHocTrenTuan =
                    ESelectlessonsPerWeek_MockData.find(
                        (item) => item.value === response.soBuoiTrenTuan
                    )
                this.selectedValue.selectedValue_soBuoiHocTrenTuan =
                    convertSelectedValue_soBuoiHocTrenTuan?.value
                this.selectedValue.selectedValue_soTietHocTrenTuan =
                    response.maTietHoc

                this.selectedValue.checkbox_lopGhep =
                    response.lopGhep === 0 ? false : true
                this.selectedValue.input_ghepVaoLop = response.maLopGhep
                this.selectedValue.checkbox_lopChuyen =
                    response.lopChuyen === 0 ? false : true
                this.selectedValue.checkbox_lopVNEN =
                    response.lopVnen === 0 ? false : true
                this.selectedValue.checkbox_thietBiDhocTviet =
                    response.thietBiDhocTviet
                this.selectedValue.checkbox_thietBiDhocToan =
                    response.thietBiDhocToan
                this.selectedValue.checkbox_daiDienChaMeLop =
                    response.daiDienChaMeLop
                this.selectedValue.checkbox_daiDienChaMeTruong =
                    response.daiDienChaMeTruong
                this.selectedValue.checkbox_ctrinhCsocGduc =
                    response.ctrinhCsocGduc
                this.selectedValue.checkbox_ctrinhGdmnMoi = response.ctrinhGdmnMoi
                this.selectedValue.checkbox_lopChuyenBiet = response.lopChuyenBiet

                this.selectedValue.checkbox_coTreKhuyetTat =
                    response.lopCoTreKhuyetTat
                const convertValue = (dataArray, value) => {
                    const item = dataArray.find((item) => item.value === value)
                    return item ? item.value : null
                }
                this.selectedValue.selectedValue_buoiHoc = convertValue(
                    ESelectLesson,
                    response.buoiHoc
                )
                this.selectedValue.selectedValue_phanBan = convertValue(
                    ESelectDivision_MockData,
                    response.phanBan
                )
                this.selectedValue.checkbox_coHocSinhHocNghe =
                    response.coHocSinhHocNghe
                this.selectedValue.selectedValue_kieuLop = convertValue(
                    ESelectClassType_MockData,
                    response.maKieuLop
                )
                this.selectedValue.selectedValue_boSachGiaoKhoaSuDung =
                    convertValue(
                        ESelectSetOfTextbooks_MockData,
                        response.maBoSgkSuDung
                    )
                await this.getDataESelect_giaoVienChuNhiem()
                await this.getESelectDiemTruong()
                this.selectedValue.selectedValue_giaoVienChuNhiem = convertValue(
                    this.getDataESelect.ESelect_giaoVienChuNhiem,
                    response.maGvcn
                )
                this.selectedValue.input_maGvcn = response.maGvcn
                this.selectedValue.input_maGvcnCn = response.maGvcnCn
                // Hàm dùng chung để lấy giá trị từ mảng dựa vào điều kiện
                const convertValue_mappingCode = (dataArray, conditionValue) => {
                    const foundItem = dataArray.find(
                        (item) => item.mappingCode === conditionValue
                    )
                    return foundItem?.mappingCode || null
                }

                this.selectedValue.selectedValue_HocNgoaiNgu1 =
                    convertValue_mappingCode(
                        this.dataChonNgoaingu_Store,
                        response.maNn1
                    )

                this.selectedValue.selectedValue_ChuongTrinhHocNgoaiNgu1 =
                    convertValue_mappingCode(
                        ESelectForeignLanguageProgram_MockData,
                        response.maChuongTrinhHocNn1
                    )

                this.selectedValue.selectedValue_soTietNgoaiNgu1TrenTuan =
                    convertValue_mappingCode(
                        ESelectNumberOfForeignLanguageLessons_MockData,
                        response.maSoTietNgoaiNgu1
                    )
                this.selectedValue.selectedValue_HocNgoaiNgu2 =
                    convertValue_mappingCode(
                        this.dataChonNgoaingu_Store,
                        response.maNn2
                    )

                this.selectedValue.selectedValue_ChuongTrinhHocNgoaiNgu2 =
                    convertValue_mappingCode(
                        ESelectForeignLanguageProgram_MockData,
                        response.maChuongTrinhHocNn2
                    )

                this.selectedValue.selectedValue_soTietNgoaiNgu2TrenTuan =
                    convertValue_mappingCode(
                        ESelectNumberOfForeignLanguageLessons_MockData,
                        response.maSoTietNgoaiNgu2
                    )
                this.selectedValue.checkbox_banTru =
                    response.lopBanTru === 0 ? false : true
                this.selectedValue.selectedValue_hocBanTru =
                    convertValue_mappingCode(
                        this.dataChonHocBanTru_Store,
                        response.maHocBanTru
                    )
                this.selectedValue.selectedValue_hinhThucHocTap =
                    convertValue_mappingCode(
                        ESelectLearningForm_MockData,
                        response.maHthucDtaoHtap
                    )
                this.selectedValue.selectedValue_daoTaoBoiDuong =
                    convertValue_mappingCode(
                        this.dataChonLopDaotaoboiduong_Store,
                        response.maLopDtaoBduong
                    )
                this.selectedValue.selectedValue_huongnghiepdaynghe =
                    convertValue_mappingCode(
                        this.dataChonHuongnghiepdaynghe_Store,
                        response.maLopHnghiepDayNghe
                    )
                this.dataUpdate = response
                console.log(this.dataUpdate.capHoc)
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        },
        async getDataESelect_giaoVienChuNhiem() {
            const request_Header = {
                token: this.authToken
            }
            const request_Params = {
                namHoc: this.namHoc_giaoVienChuNhiem,
                maTruong: this.maTruong_GiaoVienChuNhiem
            }
            const response = await sendRequest(
                Api.traCuu.traCuuLopHoc.danhSachGiaoVienChuNhiem,
                null,
                request_Header,
                request_Params
            )
            this.getDataESelect.ESelect_giaoVienChuNhiem = response.rows.map(
                (item) => ({
                    value: item.maGiaoVien,
                    title: `${item.tenCanBo} - [${item.maGiaoVien}]`
                })
            )
        },
        async getESelectDiemTruong() {
            const request_Header = {
                token: this.authToken
            }
            const request_Params = {
                namHoc: this.namHoc_giaoVienChuNhiem,
                maTruong: this.maTruong_GiaoVienChuNhiem
            }
            const response = await sendRequest(
                Api.traCuu.traCuuLopHoc.diemTruongTheoNam,
                null,
                request_Header,
                request_Params
            )
            this.getDataESelect.ESelect_diemTruong = response.rows.map(
                (item) => ({
                    value: item.maDiemTruong,
                    title: `${item.tenDiemTruong} - [${item.maDiemTruong}]`
                })
            )
        }
    },
    computed: {
        convertedValue_checkbox_lopGhep() {
            return this.selectedValue.checkbox_lopGhep ? 1 : 0
        },
        convertedValue_checkbox_lopChuyen() {
            return this.selectedValue.checkbox_lopChuyen ? 1 : 0
        },
        convertedValue_checkbox_banTru() {
            return this.selectedValue.checkbox_banTru ? 1 : 0
        },
        convertedValue_checkbox_lopVNEN() {
            return this.selectedValue.checkbox_lopVNEN ? 1 : 0
        },
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        },
        dataChonNgoaingu_Store() {
            return JSON.parse(localStorage.getItem('data_ChonNgoaingu'))
        },
        dataChonHocBanTru_Store() {
            return JSON.parse(localStorage.getItem('data_ChonHocbantru'))
        },
        dataChonLopDaotaoboiduong_Store() {
            return JSON.parse(localStorage.getItem('data_ChonLopdaotaoboiduong'))
        },
        dataChonHuongnghiepdaynghe_Store() {
            return JSON.parse(localStorage.getItem('data_ChonHuongnghiepdaynghe'))
        },
        dataChonTietHoc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTietHoc'))
        }
    }
}
</script>
<style scoped>
::v-deep .el-input.is-disabled .el-input__inner,
::v-deep .el-textarea.is-disabled .el-textarea__inner,
::v-deep .el-input.is-disabled .el-input__inner {
    background-color: #eee !important;
    color: black !important;
}
::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
    border-color: black !important;
    background-color: #eee !important;
}

.title {
    font-weight: 700;
    font-size: 13px;
}

.title .span {
    color: red;
}
.row {
    margin: 0 auto;
}
.layout-btn {
    margin-top: 100px;
    display: flex;
    justify-content: flex-end;
}
</style>
