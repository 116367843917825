var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"school"},[_c('div',{staticClass:"header"},[_vm._m(0),_c('div',{staticClass:"right"},[_c('CustomButton',{staticClass:"custom-btn",attrs:{"label":"Chi tiết","size":"small"},on:{"click":_vm.handleNaviagte}})],1)]),_c('div',{staticClass:"layout-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 mb-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"title"},[_vm._v("Tổng quan")]),_c('div',[_c('CustomButton',{attrs:{"type":"text","icon":"el-icon-refresh-right","label":"","size":"medium"},on:{"click":_vm.handleRefresh_DataBieuDoTongQuan_CBGV}})],1)]),_c('div',{staticClass:"card-body"},[_c('PieChart',{attrs:{"data_PieChart":_vm.dataBieuDoTongQuan_CBGVNV.slice(0, 5)}})],1)])]),_c('div',{staticClass:"col-md-4 mb-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"title"},[_vm._v("Trình độ chính")]),_c('div',[_c('CustomButton',{attrs:{"type":"text","icon":"el-icon-refresh-right","label":"","size":"medium"},on:{"click":_vm.handleRefresh_DataBieuDoTrinhDoChinh_CBGVNV}})],1)]),_c('div',{staticClass:"card-body"},[_c('StackedColumnChart',{attrs:{"data_StackedColumnChart":_vm.customColorStackedColumnChart(
                                    _vm.dataBieuDoTrinhDoChinh_CBGVNV
                                ),"xaxis_categories":_vm.xaxisCategories.trinhDoChinh}})],1)])]),_c('div',{staticClass:"col-md-4 mb-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"title"},[_vm._v("Giới tính")]),_c('div',[_c('CustomButton',{attrs:{"type":"text","icon":"el-icon-refresh-right","label":"","size":"medium"},on:{"click":_vm.handleRefresh_DataBieuDoGioiTinh_CBGVNV}})],1)]),_c('div',{staticClass:"card-body"},[_c('StackedColumnChart',{attrs:{"data_StackedColumnChart":_vm.customColorStackedColumnChart(
                                    _vm.dataBieuDoGioiTinh_CBGVNV
                                ),"xaxis_categories":_vm.xaxisCategories.gioiTinh}})],1)])]),_c('div',{staticClass:"col-md-4 mb-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"title"},[_vm._v("Phân loại cán bộ theo chức vụ")]),_c('div',[_c('CustomButton',{attrs:{"type":"text","icon":"el-icon-refresh-right","label":"","size":"medium"},on:{"click":_vm.handleRefresh_DataBieuDoTongQuan_PhanLoaiCanBo}})],1)]),_c('div',{staticClass:"card-body"},[_c('PieChartCustom',{attrs:{"data_PieChart":_vm.dataBieuDoTongQuan_PhanLoaiCanBo,"xaxis_categories":_vm.xaxisCategories.phanLoaiCanBo}})],1)])]),_c('div',{staticClass:"col-md-4 mb-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"title"},[_vm._v("Loại hợp đồng")]),_c('div',[_c('CustomButton',{attrs:{"type":"text","icon":"el-icon-refresh-right","label":"","size":"medium"},on:{"click":_vm.handleRefresh_DataBieuDoLoaiHopDong_CBGVNV}})],1)]),_c('div',{staticClass:"card-body"},[_c('StackedColumnChart',{attrs:{"data_StackedColumnChart":_vm.customColorStackedColumnChart(
                                    _vm.dataBieuDoLoaiHopDong_CBGVNV
                                ),"xaxis_categories":_vm.xaxisCategories.loaiHopDong}})],1)])]),_c('div',{staticClass:"col-md-4 mb-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"title"},[_vm._v("Độ tuổi")]),_c('div',[_c('CustomButton',{attrs:{"type":"text","icon":"el-icon-refresh-right","label":"","size":"medium"},on:{"click":_vm.handleRefresh_DataBieuDoDoTuoi_PhanLoaiCanBo}})],1)]),_c('div',{staticClass:"card-body"},[_c('StackedColumnChart',{attrs:{"data_StackedColumnChart":_vm.dataBieuDoDoTuoi_PhanLoaiCanBo,"xaxis_categories":_vm.xaxisCategories.doTuoi}})],1)])]),_c('div',{staticClass:"col-md-4 mb-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"title"},[_vm._v("Loại hình đào tạo")]),_c('div',[_c('CustomButton',{attrs:{"type":"text","icon":"el-icon-refresh-right","label":"","size":"medium"},on:{"click":_vm.handleRefresh_DataBieuDoLoaiHinhDaotao_PhanLoaiCanBo}})],1)]),_c('div',{staticClass:"card-body"},[_c('StackedColumnChart',{attrs:{"data_StackedColumnChart":_vm.dataBieuDoLoaiHinhDaotao_PhanLoaiCanBo,"xaxis_categories":_vm.xaxisCategories.loaiHinhDaoTao}})],1)])]),_c('div',{staticClass:"col-md-4 mb-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"title"},[_vm._v("Trạng thái")]),_c('div',[_c('CustomButton',{attrs:{"type":"text","icon":"el-icon-refresh-right","label":"","size":"medium"},on:{"click":_vm.handleRefresh_DataBieuDoTrangThai_PhanLoaiCanBo}})],1)]),_c('div',{staticClass:"card-body"},[_c('StackedColumnChart',{attrs:{"data_StackedColumnChart":_vm.convert_dataBieuDoTrangThai_PhanLoaiCanBo,"xaxis_categories":_vm.xaxisCategories.trangThai}})],1)])])]),_c('div',{staticClass:"row"})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',[_c('i',{staticClass:"el-icon-s-check icon"})]),_c('div',{staticClass:"title"},[_vm._v("Cán bộ - Giáo viên - Nhân viên")])])
}]

export { render, staticRenderFns }