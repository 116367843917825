<template>
    <div>
        <CustomBreadcrumb
            :title="' KẾT CHUYỂN DỮ LIỆU LÊN BỘ'"
            content="MẬT KHẨU KẾT CHUYỂN"
        />
        <CustomContentUI>
            <div class="row">
                <div class="col-5">
                    <label>Đơn vị quản lý</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="this.dataChonDonVi_Store"
                            :placeholder="'Chọn đơn vị'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenDonVi', 'maDonVi']"
                            :value="selectedValue.selectedValueUnitEducation"
                            @change="
                                handleESelectChange(
                                    'ESelectUnitEducation',
                                    ...$event
                                )
                            "
                        />
                    </div>
                </div>
                <div class="col-3">
                    <label>Cấp học</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectGradeLevel_MockData"
                            :placeholder="'Chọn cấp học'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenTruongHoc', 'value']"
                            :value="selectedValue.selectedValueGradeLevel"
                            @change="
                                handleESelectChange(
                                    'ESelectGradeLevel_MockData',
                                    ...$event
                                )
                            "
                        />
                    </div>
                </div>
                <div class="col-4">
                    <label>Trường học</label>
                    <div>
                        <ESelect
                            :reset="resetESelectSchool"
                            @reset-completed="handleResetCompleted"
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectSchool"
                            :placeholder="'Chọn trường học'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenTruongHoc', 'maTruongHoc']"
                            :value="selectedValue.selectedValueSchool"
                            @change="
                                handleESelectChange(
                                    'ESelectSchool_MockData',
                                    ...$event
                                )
                            "
                        />
                    </div>
                </div>
            </div>
            <div class="row mt-4 d-flex justify-content-center">
                <CustomButton
                    label="Tìm kiếm"
                    size="small"
                    class="btn-default bg-search"
                    @click="handleSearch"
                />
            </div>
        </CustomContentUI>

        <div class="layout-header">
            <div>
                <chonSoLuongBanGhi @chonXongSoLuong="ChonSoLuongBanGhi($event)" />
            </div>
            <div class="btn-layout">
                <div>
                    <CustomButton
                        label="Nhập theo biểu mẫu"
                        size="small"
                        class="btn-import btn-default"
                        @click="openModalImport"
                    />
                </div>
                <div style="margin-left: 10px">
                    <CustomButton
                        label="Thêm mới"
                        size="small"
                        class="btn-default btn-add"
                        @click="openModalAddNew"
                    />
                </div>
            </div>
        </div>

        <CustomTable :maxWidth="leftBarWidth()" :columns="tableColumns">
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="8" class="text-tbody">
                            Không có bản ghi nào.
                        </td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td class="text-tbody">
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>
                    <td class="text-tbody">{{ item.tenDangNhap }}</td>
                    <td class="text-tbody">{{ item.maTruong }}</td>
                    <td class="text-tbody text-left">{{ item.tenTruong }}</td>
                    <td class="text-tbody">{{ item.tenTinhThanh }}</td>
                    <td class="text-tbody text-left">{{ item.tenDonvi }}</td>
                    <td class="text-tbody">{{ getCapHoc(item.capHoc) }}</td>
                    <td>
                        <CustomButton
                            icon="el-icon-edit-outline"
                            size="small"
                            label="Sửa"
                            class="btn-default btn-update"
                            @click="openModalEdit(item)"
                        />
                    </td>
                </tr>
            </template>
        </CustomTable>
        <CustomPagination
            v-show="total_rows > 0"
            :tongbanghi="total_rows"
            :soluonghienthi="soLuongBanGhiHienThi"
            :batdau="trangbatdau"
            @pageChange="layLai($event)"
        >
        </CustomPagination>
        <ModalImport
            :importModalVisible="importModalVisible"
            :closeModalImport="closeModalImport"
        />
        <ModalThemMoi
            :addNewModalVisible="addNewModalVisible"
            :closeModalAddNew="closeModalAddNew"
            @handleSearch="handleSearch"
        />
        <ModalUpdate
            :editModalVisible="editModalVisible"
            :closeModalEdit="closeModalEdit"
            :dataModalEdit="dataModalEdit"
            @handleSearch="handleSearch"
        />
    </div>
</template>
<script>
import ESelect from '@/components/ESelect.vue'
import CustomButton from '@/components/CustomButton.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'
import chonSoLuongBanGhi from '@/components/chonSoLuongBanGhi.vue'
import { ESelectGradeLevel_MockData } from '@/mock_data'
import ModalThemMoi from './ModalThemMoi.vue'
import ModalUpdate from './ModalUpdate.vue'
import ModalImport from './ModalImport.vue'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { mapState } from 'vuex'
import CustomContentUI from '@/components/CustomContentUI.vue'
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'MatKhauKetChuyen',
    components: {
        ESelect,
        CustomButton,
        CustomBreadcrumb,
        chonSoLuongBanGhi,
        CustomPagination,
        ModalThemMoi,
        ModalUpdate,
        ModalImport,
        CustomContentUI,
        CustomTable
    },
    data() {
        return {
            resetESelectSchool: false,

            addNewModalVisible: false,
            editModalVisible: false,
            dataModalEdit: {},
            importModalVisible: false,

            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            soLuongBanGhiHienThi: 25,
            trangbatdau: false,
            request_Params: {
                start: null,
                limit: null
            },
            tableData: [],
            tableColumns: [
                { key: '1', label: 'STT' },
                { key: '2', label: 'Tên đăng nhập' },
                { key: '3', label: 'Mã trường' },
                { key: '4', label: 'Trường học' },
                { key: '5', label: 'Tỉnh thành' },
                { key: '6', label: 'Đơn vị quản lý' },
                { key: '7', label: 'Cấp học' },
                { key: '8', label: 'Sửa' }
            ],
            request_Header: {
                Authorization: ''
            },
            request_Data: {
                capHoc: null,
                limit: 25,
                maDonVi: null,
                maTinhThanh: null,
                maTruongHoc: null,
                start: 0
            },
            getDataESelect: {
                ESelectUnitEducation: [], //donvi
                ESelectGradeLevel_MockData: ESelectGradeLevel_MockData, //caphoc
                ESelectSchool: [] //truonghoc
            },

            selectedValue: {
                selectedValueUnitEducation: [], //donvi
                selectedValueGradeLevel: [], //caphoc
                selectedValueSchool: [] //truonghoc
            }
        }
    },
    methods: {
        openModalAddNew() {
            this.addNewModalVisible = true
        },

        closeModalAddNew() {
            this.addNewModalVisible = false
        },
        openModalEdit(item) {
            this.dataModalEdit = item

            this.editModalVisible = true
        },
        closeModalEdit() {
            this.editModalVisible = false
        },
        openModalImport() {
            this.importModalVisible = true
        },
        closeModalImport() {
            this.importModalVisible = false
        },
        ChonSoLuongBanGhi(e) {
            this.total_rows = 0
            this.soLuongBanGhiHienThi = e.soluong
        },
        checkTruocKhiTim() {
            this.trangbatdau = !this.trangbatdau
        },
        layLai(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        getCapHoc(loai) {
            var res = parseInt(loai)
            switch (res) {
                case 1:
                    res = 'Tiểu học'
                    break
                case 2:
                    res = 'THCS'
                    break
                case 3:
                    res = 'THPT'
                    break
                case 4:
                    res = 'Nhà trẻ'
                    break
                case 5:
                    res = 'Mẫu giáo'
                    break
                case 12:
                    res = 'PTCS'
                    break
                case 1245:
                    res = 'MN-PTCS'
                    break
                case 145:
                    res = 'MN-TH'
                    break
                case 23:
                    res = 'Trung học'
                    break
                case 123:
                    res = 'Phổ thông'
                    break
                case 12345:
                    res = 'MN-PT'
                    break
                case 45:
                    res = 'Mầm non'
                    break
                case 6:
                    res = 'GDTX'
                    break
                default:
                    res = loai
            }
            return res
        },
        handleResetCompleted() {
            this.resetESelectSchool = false
        },

        handleESelectChange(source, ...selectedOptions) {
            switch (source) {
                case 'ESelectUnitEducation':
                    this.selectedValue.selectedValueUnitEducation =
                        selectedOptions

                    this.resetESelectSchool = true
                    this.selectedValue.selectedValueSchool = []
                    this.getDataESelectSchool()
                    break
                case 'ESelectGradeLevel_MockData':
                    this.selectedValue.selectedValueGradeLevel = selectedOptions

                    this.resetESelectSchool = true
                    this.selectedValue.selectedValueSchool = []
                    this.getDataESelectSchool()
                    break
                case 'ESelectSchool_MockData':
                    this.selectedValue.selectedValueSchool = selectedOptions
                    break
            }
        },

        async handleSearch() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const maDonVi = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueUnitEducation,
                    'selectedValueUnitEducation'
                ).join('')

                const maTruongHoc = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueSchool,
                    'selectedValueSchool'
                ).join('')

                const capHoc = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueGradeLevel,
                    'selectedValueGradeLevel'
                ).join('')

                const maTinhThanh = this.authUser.province
                const start = this.start
                const limit = this.limit
                const request_Header = {
                    token: this.authToken
                }
                const request_Data = {
                    ...this.request_Data,
                    start: start,
                    limit: limit,
                    maTinhThanh: maTinhThanh,
                    maDonVi: maDonVi,
                    maTruongHoc: maTruongHoc,
                    capHoc: capHoc
                }

                const response = await sendRequest(
                    Api.ketChuyenDuLieuLenBo.matKhauKetChuyen
                        .danhSachMatKetChuyen,
                    request_Data,
                    request_Header,
                    null
                )
                if (response.code == 200) {
                    setTimeout(() => {
                        loading.close()
                        this.$message({
                            message: 'Danh sách dữ liệu',
                            type: 'success'
                        })
                    }, 1000)
                    this.tableData = response.rows
                    this.total_rows = response.total
                } else {
                    loading.close()
                    this.$message({
                        message: response.message,
                        type: 'error'
                    })
                }
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },

        async getDataESelectSchool() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const maDonVi = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueUnitEducation,
                    'selectedValueUnitEducation'
                )

                const capHoc = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueGradeLevel,
                    'selectedValueGradeLevel'
                )

                const request_Header = {
                    token: this.authToken
                }

                const request_Data = {
                    capHoc: capHoc,
                    maDonVi: maDonVi
                }
                const response = await sendRequest(
                    Api.internal_api.dm_School,
                    request_Data,
                    request_Header,
                    null
                )
                this.getDataESelect.ESelectSchool = response.rows.map((item) => ({
                    ...item,
                    tenTruongHoc: `${item.tenTruongHoc} - [${item.maTruongHoc}]`
                }))

                loading.close()
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },

        customhandleESelectedChange(options, valueType) {
            switch (valueType) {
                case 'selectedValueUnitEducation':
                case 'selectedValueSchool':
                    return options.map((option) => option.value)
                case 'selectedValueGradeLevel':
                    return options.map((option) => Number(option.value))
            }
        },
        leftBarWidth() {
            if (this.isCollapseLeftBar) {
                return 'calc(100vw - 100px)'
            } else {
                return 'calc(100vw - 340px)'
            }
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        },
        ...mapState(['isCollapseLeftBar'])
    },
    mounted() {
        this.getDataESelectSchool()
    }
}
</script>
<style scoped>
.layout-header {
    padding: 20px 10px 5px 10px;
    background: #fff;
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.btn-layout {
    display: flex;
}

.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.bg-search {
    background-color: var(--teal);
}
.btn-add,
.btn-import {
    background-color: var(--light-green);
}

.btn-update {
    background-color: var(--yellow);
}
.btn-delete {
    background-color: var(--orange);
}
</style>
