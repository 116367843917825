<template>
    <div>
        <el-dialog
            :visible="dayLenBoModalVisible"
            @close="closeModalDayLenBo"
            class="custom-dialog"
            width="70%"
        >
            <span slot="title">
                ĐẨY DỮ LIỆU
                <hr />
                <div class="row">
                    <div class="col-4">
                        <label>Chọn năm học</label>
                        <div>
                            <el-input
                                :disabled="true"
                                placeholder="Chọn năm"
                                size="small"
                                v-model="year"
                            ></el-input>
                        </div>
                    </div>
                    <div class="col-4">
                        <label>Chọn học kỳ</label>
                        <div>
                            <el-select
                                :disabled="true"
                                size="small"
                                collapse-tags
                                style="width: 100%"
                                placeholder="Chọn"
                                v-model="selectedValue.selectedValueSemester"
                            >
                                <el-option
                                    v-for="item in getDataESelect.ESelectSemester_MockData"
                                    :key="item.value"
                                    :label="item.tenHocKy"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="layout-table mt-4">
                    <table
                        class="table table-bordered table-hover centered-table"
                    >
                        <thead class="sticky-header">
                            <tr>
                                <th class="text-thead">STT</th>
                                <th class="text-thead">Mã trường học</th>
                                <th class="text-thead">Tên trường học</th>
                                <th class="text-thead">Dữ liệu đẩy</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="dataModalDayLenBo.length === 0">
                                <tr>
                                    <td colspan="8" class="text-tbody">
                                        Không có bản ghi nào.
                                    </td>
                                </tr>
                            </template>
                            <tr
                                v-for="(item, index) in dataModalDayLenBo"
                                :key="index"
                            >
                                <td class="text-tbody">
                                    {{ index + 1 }}
                                </td>
                                <td class="text-tbody">
                                    {{ item.maTruong }}
                                </td>
                                <td class="text-tbody text-left">
                                    {{ item.tenTruong }}
                                </td>
                                <td class="text-tbody text-left">
                                    {{ getDuLieuXoa(item.buocDays) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </span>
            <div style="margin-top: 70px"></div>
            <hr />
            <div class="layout-table-modal">
                <CustomButton
                    label="Đóng"
                    style="
                        background-color: #e14a02;
                        color: #fff;
                        font-weight: 600;
                    "
                    size="small"
                    @click="closeModalDayLenBo"
                />
                <CustomButton
                    label="Đẩy"
                    size="small"
                    style="
                        background-color: #ffa554;
                        color: #fff;
                        font-weight: 600;
                    "
                    @click="handleDayLenBo"
                />
            </div>
        </el-dialog>
    </div>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import Api from '@/constants/Api'
import sendRequest from '@/services'

import { mapState } from 'vuex'
import {
    ESelectSemester_MockData_KetChuyenDuLieu,
    ESelectDataSynchronization_ALL
} from '@/mock_data'
export default {
    props: {
        dayLenBoModalVisible: {
            type: Boolean,
            required: true
        },
        dataModalDayLenBo: {
            type: Array,
            required: true
        },
        closeModalDayLenBo: {
            type: Function,
            required: true
        },
        selectedValueSchoolYear: {
            type: String,
            required: true
        },
        selectedValueSemester: {
            type: Object,
            required: true
        },

        handleSearch: Function
    },

    components: { CustomButton },
    data() {
        return {
            year: '',
            getDataESelect: {
                ESelectShoolYear: '', //namhoc
                ESelectSemester_MockData: ESelectSemester_MockData_KetChuyenDuLieu //hocky
            },
            selectedValue: {
                selectedValueSchoolYear: '2023', //namhoc
                selectedValueSemester: 1 //hocky
            }
        }
    },

    watch: {
        selectedValueSemester(newValues) {
            this.selectedValue.selectedValueSemester = newValues.value
        },
        selectedValueSchoolYear(newValues) {
            const year = parseFloat(newValues)
            this.year = `${year}-${year + 1}`
            this.selectedValue.selectedValueSchoolYear = newValues
        }
    },
    methods: {
        handleDayLenBo() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const request_Header = {
                token: this.authToken
            }
            const truongCanDayRequests = this.dataModalDayLenBo.map((item) => ({
                maTruong: item.maTruong,
                buocDays: item.buocDays
            }))
            if (truongCanDayRequests.length === 0) {
                loading.close()
                this.$message({
                    type: 'error',
                    message: 'Vui lòng chọn dữ liệu cần đẩy'
                })
                return
            }
            const request_Data = {
                hocKy: this.selectedValue.selectedValueSemester,
                namHoc: this.selectedValue.selectedValueSchoolYear,

                truongCanDayRequests: truongCanDayRequests,
                lstCapHoc: []
            }
            this.$confirm('Xác nhận đẩy?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const response = await sendRequest(
                        Api.ketChuyenDuLieuLenBo.ketChuyenDuLieu.dayDuLieu,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        loading.close()

                        this.$message({
                            type: 'success',
                            message:
                                'Hệ thống đang bắt đầu đẩy dữ liệu lên Bộ. Vui lòng theo dõi tiến trình ở chức năng Lịch sử đẩy dữ liệu lên Bộ'
                        })
                        await this.closeModalDayLenBo()
                        await this.$emit('handleSearch')
                    } else {
                        loading.close()
                        this.$message({
                            type: 'error',
                            message: response?.rd
                        })
                    }
                })
                .catch((error) => {
                    loading.close()
                    if (error.response?.data.rd) {
                        this.$message({
                            type: 'error',
                            message: error.response?.data.rd
                        })
                    }
                })
        },
        getDuLieuXoa(buocDays) {
            const keysToFind = buocDays.map(String)
            const foundNames = ESelectDataSynchronization_ALL.filter((item) =>
                keysToFind.includes(item.key)
            ).map((item) => item.name)

            return foundNames.join(', ')
        }
    },

    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped>
hr {
    border: 1px solid gray;
}
.layout-table-modal {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}
.layout-table {
    max-height: 300px;
    overflow-y: auto;
}
.sticky-header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    height: 40px; /* Đặt chiều cao cố định cho phần header */
}
table thead tr {
    background: rgb(228, 235, 245);
}
.table {
    white-space: nowrap;
}

.centered-table {
    margin-left: auto;
    margin-right: auto;
}

.centered-table th,
.centered-table td {
    text-align: center;
    vertical-align: middle;
}
.table-bordered {
    background-color: #fff;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}
.table-hover tbody tr:hover {
    background-color: #f5f5f5;
}

.text-thead {
    font-size: 13px;
    text-align: center; /* Canh giữa nội dung trong cột */
    vertical-align: middle; /* Căn giữa theo chiều dọc */
}
.text-tbody {
    font-size: 13px;
}
.text-left {
    text-align: left;
}
</style>
