<template>
    <el-dialog
        :visible="chiTietKQHTModalVisible"
        @close="closeModalChitietKQHT"
        width="60%"
        class="custom-dialog"
        :fullscreen="true"
    >
        <span slot="title" class="custom-dialog-title">
            THÔNG TIN KẾT QUẢ HỌC TẬP
            <hr />
        </span>
        <div class="row">
            <div class="col-12 col-sm-6 col-lg-4">
                <div class="row d-flex align-items-center">
                    <div class="col-6 title">Họ và tên</div>
                    <div class="col-6">
                        <el-input
                            class="custom-disabled-input"
                            :disabled="true"
                            v-model="selectedValue.input_hoTen"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Mã học sinh</div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_maHocSinh"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Mã định danh Bộ GD & ĐT</div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_maDinhDanh"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Trạng thái</div>
                    <div class="col-6">
                        <el-select
                            style="width: 100%"
                            disabled
                            v-model="selectedValue.selectedValue_trangThai"
                            placeholder=""
                            size="small"
                        >
                            <el-option
                                v-for="item in getDataESelect.ESelect_trangThai"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            />
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-4">
                <div class="row d-flex align-items-center">
                    <div class="col-3 title">Lớp học</div>
                    <div class="col-9">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_lopHoc"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-3 title">Năm học</div>
                    <div class="col-9">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.selectedValue_namHoc"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-3 title">Học kỳ</div>
                    <div class="col-9">
                        <el-select
                            style="width: 100%"
                            disabled
                            v-model="selectedValue.selectedValue_hocKy"
                            placeholder=""
                            size="small"
                        >
                            <el-option
                                v-for="item in getDataESelect.ESelect_hocKy"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            />
                        </el-select>
                    </div>
                </div>
                <div
                    v-if="capHoc == 1"
                    class="row d-flex align-items-center mt-3"
                >
                    <div class="col-3 title">Giai đoạn</div>
                    <div class="col-9">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_giaiDoan"
                            size="small"
                        ></el-input>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-4">
                <div class="row d-flex align-items-center">
                    <div class="col-4 title">Số ngày nghỉ</div>
                    <div class="col-8">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_soNgayNghi"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Nhận xét GVCN</div>
                    <div class="col-8">
                        <el-input
                            :disabled="true"
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 4 }"
                            v-model="selectedValue.input_nhanXetGVCN"
                        >
                        </el-input>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="capHoc == 2 || capHoc == 3" class="row mt-5">
            <div class="col-12 col-sm-8 col-lg-8">
                <el-table
                    :data="tableData_cap2_cap3"
                    border
                    style="width: 100%"
                    size="small"
                >
                    <el-table-column label="STT" width="50" align="center">
                        <template slot-scope="scope">
                            <div class="centered-cell">
                                {{ scope.$index + 1 }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Môn học" width="150">
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.tenMonHoc }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Điểm số" width="120">
                        <template slot-scope="scope">
                            <div class="centered-cell">
                                {{ scope.row.diemSo }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Điểm đánh giá" width="120">
                        <template slot-scope="scope">
                            <div class="centered-cell">
                                {{ scope.row.diemDanhGia }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Điểm thi lại" width="120">
                        <template slot-scope="scope">
                            <div class="centered-cell">
                                {{ scope.row.diemThiLai }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Điểm đánh giá lại" width="120">
                        <template slot-scope="scope">
                            <div class="centered-cell">
                                {{ scope.row.diemDanhGiaLai }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Nhận xét">
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.nhanXetCuaGv }}
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="col-12 col-sm-4 col-lg-4">
                <div class="row d-flex align-items-center">
                    <div class="col-4 title">Khen thưởng</div>
                    <div class="col-8">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_khenThuong"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Kỷ luật</div>
                    <div class="col-8">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_kyLuat"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Lên lớp</div>
                    <div class="col-8">
                        <el-checkbox
                            :disabled="true"
                            v-model="selectedValue.checkbox_lenLop"
                        />
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Rèn luyện lại</div>
                    <div class="col-8">
                        <el-checkbox
                            :disabled="true"
                            v-model="selectedValue.checkbox_renLuyenLai"
                        />
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Thi lại</div>
                    <div class="col-8">
                        <el-checkbox
                            :disabled="true"
                            v-model="selectedValue.checkbox_thiLai"
                        />
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Dự xét tốt nghiệp</div>
                    <div class="col-8">
                        <el-checkbox
                            :disabled="true"
                            v-model="selectedValue.checkbox_duXetTotNghiep"
                        />
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Tốt nghiệp</div>
                    <div class="col-8">
                        <el-checkbox
                            :disabled="true"
                            v-model="selectedValue.checkbox_totNghiep"
                        />
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Loại Tốt nghiệp</div>
                    <div class="col-8">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_loaiTotNghiep"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Được miễn thi</div>
                    <div class="col-8">
                        <el-checkbox
                            :disabled="true"
                            v-model="selectedValue.checkbox_duocMienThi"
                        />
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Lý do miễn thi</div>
                    <div class="col-8">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_lyDoMienThi"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Được đặc cách</div>
                    <div class="col-8">
                        <el-checkbox
                            :disabled="true"
                            v-model="selectedValue.checkbox_duocDacCach"
                        />
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Chứng chỉ nghề</div>
                    <div class="col-8">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_chungChiNghe"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Loại chứng chỉ nghề</div>
                    <div class="col-8">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_loaiChungChiNghe"
                            size="small"
                        ></el-input>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="capHoc == 2 || capHoc == 3" class="row mt-5">
            <div class="col-12 col-sm-6 col-lg-4">
                <div class="row d-flex align-items-center">
                    <div class="col-6 title">Điểm tổng kết (TT58)</div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_diemTongKet_TT58"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Học lực (TT58)</div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_hocLuc_TT58"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Hạnh kiểm (TT58)</div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_hanhKiem_TT58"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Danh hiệu</div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_danhHieu"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Kết quả học tập (TT22)</div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_ketQuaHocTap_TT22"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Kết quả rèn luyện (TT22)</div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_ketQuaRenLuyen_TT22"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Khen thưởng cấp Huyện trở lên</div>
                    <div class="col-6">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 4 }"
                            :disabled="true"
                            v-model="selectedValue.input_khenThuongCapHuyenTroLen"
                            size="small"
                        ></el-input>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-4">
                <div class="row d-flex align-items-center">
                    <div class="col-6 title">
                        Trung bình môn sau thi lại (TT58)
                    </div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="
                                selectedValue.input_trungBinhMonSauThiLai_TT58
                            "
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Học lực sau thi lại (TT58)</div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_hocLucSauThiLai_TT58"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Hạnh kiểm sau thi lại (TT58)</div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_hanhKiemSauThiLai_TT58"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">
                        Kết quả học tập sau thi lại (TT22)
                    </div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_KQHTSauThiLai_TT22"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">
                        Kết quả rèn luyện sau rèn luyện lại (TT22)
                    </div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_KQRLSauRenLuyenLai_TT22"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Đánh giá bổ sung sau thi lại</div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_danhGiaBoSungSauThiLai"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Lên lớp sau thi lại</div>
                    <div class="col-6">
                        <el-checkbox
                            :disabled="true"
                            v-model="selectedValue.checkbox_lenLopSauThiLai"
                        />
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-4">
                <div class="row d-flex align-items-center">
                    <div class="col-6 title">Năng lực (VNEN)</div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_nangLucVNEN"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Phẩm chất (VNEN)</div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_phamChatVNEN"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Kết quả học tập (VNEN)</div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_ketQuaHocTapVNEN"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Đánh giá (VNEN)</div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_danhGiaVNEN"
                            size="small"
                        ></el-input>
                    </div>
                </div>

                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Nhận xét NLPC (VNEN)</div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_nhanXetNLPCVNEN"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Danh hiệu thi đua (VNEN)</div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_danhHieuThiDuaVNEN"
                            size="small"
                        ></el-input>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="capHoc == 1" class="row mt-5">
            <div class="col-12 col-sm-6 col-lg-4">
                <div class="title">Môn học, hoạt động giáo dục</div>
                <div class="table-responsive mt-2">
                    <table
                        class="table table-bordered table-hover centered-table"
                    >
                        <thead style="background: #f5f7fa">
                            <tr class="text-center">
                                <th class="text-center title">STT</th>
                                <th class="text-center title">Môn học</th>
                                <th class="text-center title">Điểm số</th>
                                <th class="text-center title">Đánh giá</th>
                                <th class="text-center title">Nhận xét</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center">1</td>
                                <td>Tiếng việt</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.tvDiem }}
                                </td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.tvDgtx }}
                                </td>
                                <td>
                                    <span>{{ kqhtHocSinh_cap1.tvNx }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">2</td>
                                <td>Toán</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.toDiem }}
                                </td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.toDgtx }}
                                </td>
                                <td>
                                    <span>{{ kqhtHocSinh_cap1.toNx }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">3</td>
                                <td>Tự nhiên và xã hội</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.txDiem }}
                                </td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.txDgtx }}
                                </td>
                                <td>
                                    <span>{{ kqhtHocSinh_cap1.txNx }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">4</td>
                                <td>Ngoại ngữ</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.nnDiem }}
                                </td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.nnDgtx }}
                                </td>
                                <td>
                                    <span>{{ kqhtHocSinh_cap1.nnNx }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">5</td>
                                <td>TH-CN (Tin học)</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.thDiem }}
                                </td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.thDgtx }}
                                </td>
                                <td>
                                    <span>{{ kqhtHocSinh_cap1.thNx }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">6</td>
                                <td>Tiếng dân tộc</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.dtDiem }}
                                </td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.dtDgtx }}
                                </td>
                                <td>
                                    <span>{{ kqhtHocSinh_cap1.dtNx }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">7</td>
                                <td>Đạo đức</td>
                                <td class="text-center">
                                    <span></span>
                                </td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.ddDgtx }}
                                </td>
                                <td>
                                    <span>{{ kqhtHocSinh_cap1.ddNx }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">8</td>
                                <td>Âm nhạc</td>
                                <td class="text-center">
                                    <span></span>
                                </td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.anDgtx }}
                                </td>
                                <td>
                                    <span>{{ kqhtHocSinh_cap1.anNx }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">9</td>
                                <td>Mỹ thuật</td>
                                <td class="text-center">
                                    <span></span>
                                </td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.mtDgtx }}
                                </td>
                                <td>
                                    <span>{{ kqhtHocSinh_cap1.mtNx }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">10</td>
                                <td>Thủ công</td>
                                <td class="text-center">
                                    <span></span>
                                </td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.tcDgtx }}
                                </td>
                                <td>
                                    <span>{{ kqhtHocSinh_cap1.tcNx }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">11</td>
                                <td>
                                    <span v-if="kqhtHocSinh_cap1.khoiHoc == 1">
                                        Giáo dục thể chất
                                    </span>
                                    <span v-else>Thể dục</span>
                                </td>
                                <td class="text-center">
                                    <span></span>
                                </td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.tdDgtx }}
                                </td>
                                <td>
                                    <span>{{ kqhtHocSinh_cap1.tdNx }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">12</td>
                                <td>Khoa học</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.khDiem }}
                                </td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.khDgtx }}
                                </td>
                                <td>
                                    <span>{{ kqhtHocSinh_cap1.khNx }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">13</td>
                                <td>Lịch sử & địa lý</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.ldDiem }}
                                </td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.ldDgtx }}
                                </td>
                                <td>
                                    <span>{{ kqhtHocSinh_cap1.ldNx }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">14</td>
                                <td>Kỹ thuật</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.ktDiem }}
                                </td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.ktDgtx }}
                                </td>
                                <td>
                                    <span>{{ kqhtHocSinh_cap1.ktNx }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">15</td>
                                <td>Hoạt động trải nghiệm</td>
                                <td><span></span></td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.hdtnDgtx }}
                                </td>
                                <td>
                                    <span>{{ kqhtHocSinh_cap1.hdtnNx }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">16</td>
                                <td>TH-CN (Công nghệ)</td>
                                <td><span></span></td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.cnDiem }}
                                </td>
                                <td>
                                    <span>{{ kqhtHocSinh_cap1.cnDgtx }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-5">
                <div class="title">Năng lực, phẩm chất (TT27)</div>
                <div class="table-responsive mt-2">
                    <table class="table table-bordered">
                        <thead style="background: #f5f7fa">
                            <tr class="text-center">
                                <th class="text-center title">STT</th>
                                <th class="text-center title" colspan="3">
                                    Năng lực, phẩm chất
                                </th>
                                <th class="text-center title">Đánh giá</th>
                            </tr>
                        </thead>
                        <tbody v-if="1 == 2">
                            <tr>
                                <td class="text-center">1</td>
                                <td>Tự phục vụ, tự quản</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.nlTpvtq }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">2</td>
                                <td>Hợp tác</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.nlHt }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">3</td>
                                <td>Tự học, giải quyết vấn đề</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.nlThgqvd }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">4</td>
                                <td>Chăm học, chăm làm</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.pcChcl }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">5</td>
                                <td>Tự tin, trách nhiệm</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.pcTttn }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">6</td>
                                <td>Trung thực, kỉ luật</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.pcTtkl }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">7</td>
                                <td>Đoàn kết, yêu thương</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.pcDkyt }}
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td class="text-center">1</td>
                                <td class="text-center" rowspan="10">
                                    Năng lực cốt lõi
                                </td>
                                <td class="text-center" rowspan="3">
                                    Năng lực chung
                                </td>
                                <td>Tự chủ và tự học</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.nlTuChuTuHoc }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">2</td>
                                <td>Giao tiếp và hợp tác</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.nlGiaoTiepHopTac }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">3</td>
                                <td>Giải quyết vấn đề và sáng tạo</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.nlGquyetVdeSangTao }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">4</td>
                                <td class="text-center" rowspan="7">
                                    Năng lực đặc thù
                                </td>
                                <td>Ngôn ngữ</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.nlNgonNgu }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">5</td>
                                <td>Tính toán</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.nlTinhToan }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">6</td>
                                <td>Khoa học</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.nlKhoaHoc }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">7</td>
                                <td>Công nghệ</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.nlCongNghe }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">8</td>
                                <td>Tin học</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.nlTinHoc }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">9</td>
                                <td>Thẩm mĩ</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.nlThamMi }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">10</td>
                                <td>Thể chất</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.nlTheChat }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">11</td>
                                <td class="text-center" rowspan="5" colspan="2">
                                    Phẩm chất chủ yếu
                                </td>
                                <td>Yêu nước</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.pcYeuNuoc }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">12</td>
                                <td>Nhân ái</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.pcNhanAi }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">13</td>
                                <td>Chăm chỉ</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.pcChamChi }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">14</td>
                                <td>Trung thực</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.pcTrungThuc }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">15</td>
                                <td>Trách nhiệm</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.pcTrachNhiem }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3">
                <div class="title">Năng lực, phẩm chất TT22</div>
                <div class="table-responsive mt-2">
                    <table class="table table-bordered">
                        <thead style="background: #f5f7fa">
                            <tr class="text-center">
                                <th class="text-center title">STT</th>
                                <th class="text-center title" colspan="2">
                                    Năng lực, phẩm chất
                                </th>
                                <th class="text-center title">Đánh giá</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center">1</td>
                                <td class="text-center" rowspan="3">Năng lực</td>
                                <td>Tự phục vụ, tự quản</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.nlTpvtq }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">2</td>
                                <td>Hợp tác</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.nlHt }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">3</td>
                                <td>Tự học và giải quyết vấn đề</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.nlThgqvd }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">4</td>
                                <td class="text-center" rowspan="4">Phẩm chất</td>
                                <td>Chăm học, Chăm làm</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.pcChcl }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">5</td>
                                <td>Tự tin, Trách nhiệm</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.pcTttn }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">6</td>
                                <td>Trung thực, Kỉ luật</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.pcTtkl }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">7</td>
                                <td>Đoàn kết, Yêu thương</td>
                                <td class="text-center">
                                    {{ kqhtHocSinh_cap1.pcDkyt }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-if="capHoc == 1" class="row mt-5">
            <div class="col-12 col-sm-6 col-lg-4">
                <div class="row d-flex align-items-center">
                    <div class="col-6 title">Lên lớp</div>
                    <div class="col-6">
                        <el-checkbox
                            :disabled="true"
                            v-model="selectedValue.checkbox_lenLop"
                        />
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Gửi hiệu trưởng cho lên lớp</div>
                    <div class="col-6">
                        <el-checkbox
                            :disabled="true"
                            v-model="
                                selectedValue.checkbox_guiHieuTruongChoLenLop
                            "
                        />
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Hoàn thành chương trình lớp học</div>
                    <div class="col-6">
                        <el-checkbox
                            :disabled="true"
                            v-model="
                                selectedValue.checkbox_hoanThanhChuongTrinhLopHoc
                            "
                        />
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">
                        Hoàn thành chương trình tiểu học
                    </div>
                    <div class="col-6">
                        <el-checkbox
                            :disabled="true"
                            v-model="
                                selectedValue.checkbox_hoanThanhChuongTrinhTieuHoc
                            "
                        />
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Đánh giá kết quả giáo dục</div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_danhGiaKetQuaGiaoDuc"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Đánh giá bổ sung</div>
                    <div class="col-6">
                        <el-checkbox
                            :disabled="true"
                            v-model="selectedValue.checkbox_danhGiaBoSung"
                        />
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Lý do đánh giá bổ sung</div>
                    <div class="col-6">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 4 }"
                            :disabled="true"
                            v-model="selectedValue.input_lyDoDanhGiaBoSung"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Nội dung khen thưởng</div>
                    <div class="col-6">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 4 }"
                            :disabled="true"
                            v-model="selectedValue.input_noiDungKhenThuong"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Khen thưởng cấp Huyện trở lên</div>
                    <div class="col-6">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 4 }"
                            :disabled="true"
                            v-model="selectedValue.input_khenThuongCapHuyenTroLen"
                            size="small"
                        ></el-input>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-4">
                <div class="row d-flex align-items-center">
                    <div class="col-6 title">Khen thưởng cuối năm</div>
                    <div class="col-6">
                        <el-checkbox
                            :disabled="true"
                            v-model="selectedValue.checkbox_khenThuongCuoiNam"
                        />
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Khen thưởng đột xuất</div>
                    <div class="col-6">
                        <el-checkbox
                            :disabled="true"
                            v-model="selectedValue.checkbox_khenThuongDotXuat"
                        />
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Danh hiệu</div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_danhHieu"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">
                        Nhận xét môn học & hoạt động giáo dục
                    </div>
                    <div class="col-6">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 4 }"
                            :disabled="true"
                            v-model="
                                selectedValue.input_nhanXetMonHocVaHoatDongGiaoDuc
                            "
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Nhận xét phẩm chất chủ yếu</div>
                    <div class="col-6">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 4 }"
                            :disabled="true"
                            v-model="selectedValue.input_nhanXetPhamChatChuYeu"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Nhận xét năng lực chung</div>
                    <div class="col-6">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 4 }"
                            :disabled="true"
                            v-model="selectedValue.input_nhanXetNangLucChung"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Nhận xét năng lực đặc thù</div>
                    <div class="col-6">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 4 }"
                            :disabled="true"
                            v-model="selectedValue.input_nhanXetNangLucDacThu"
                            size="small"
                        ></el-input>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-4">
                <div class="row d-flex align-items-center">
                    <div class="col-6 title">Năng lực (VNEN)</div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_nangLucVNEN"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Phẩm chất (VNEN)</div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_phamChatVNEN"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Kết quả học tập (VNEN)</div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_ketQuaHocTapVNEN"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Đánh giá (VNEN)</div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_danhGiaVNEN"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Danh hiệu thi đua (VNEN)</div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_danhHieuThiDuaVNEN"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-6 title">Nhận xét NLPC (VNEN)</div>
                    <div class="col-6">
                        <el-input
                            :disabled="true"
                            v-model="selectedValue.input_nhanXetNLPCVNEN"
                            size="small"
                        ></el-input>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="capHoc == 4 || capHoc == 5 || capHoc == 45" class="row mt-5">
            <div class="col-12">
                <div class="table-responsive">
                    <table
                        class="table table-bordered"
                        style="white-space: normal"
                    >
                        <thead style="background: #f5f7fa">
                            <tr class="text-center">
                                <th class="text-center title">
                                    Khám sức khỏe định kỳ
                                </th>
                                <th class="text-center title">
                                    Theo dõi biểu đồ cân nặng
                                </th>
                                <th class="text-center title">
                                    Kênh tăng trưởng cân nặng
                                </th>
                                <th class="text-center title">
                                    Theo dõi biểu đồ chiều cao
                                </th>
                                <th class="text-center title">
                                    Suy dinh dưỡng thể thấp còi
                                </th>
                                <th class="text-center title">
                                    Suy dinh dưỡng thể còi cọc
                                </th>
                                <th class="text-center title">
                                    Trẻ bị phổi, ỉa chảy, hô hấp
                                </th>
                                <th class="text-center title">
                                    Trẻ bị bệnh béo phì
                                </th>
                                <th class="text-center title">
                                    Trẻ được làm quen tin học
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_khamSucKhoeDinhKy
                                        "
                                    />
                                </td>
                                <td class="text-center">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_theoDoiBieuDoCanNang
                                        "
                                    />
                                </td>
                                <td class="text-center">
                                    {{
                                        kqhtHocSinh_manNon_mauGiao.tenKenhTtruongCnang
                                    }}
                                </td>
                                <td class="text-center">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_theoDoiBieuDoChieuCao
                                        "
                                    />
                                </td>
                                <td class="text-center">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_suyDinhDuongTheThapCoi
                                        "
                                    />
                                </td>
                                <td class="text-center">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_suyDinhDuongTheCoiCoc
                                        "
                                    />
                                </td>
                                <td class="text-center">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_treBiPhoiIaChayHoHap
                                        "
                                    />
                                </td>
                                <td class="text-center">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_treBiBenhBeoPhi
                                        "
                                    />
                                </td>
                                <td class="text-center">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_treDuocLamQuenTinHoc
                                        "
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-4">
                <div class="row d-flex align-items-center mt-5">
                    <div class="col-6 title">Hoàn thành chương trình mầm non</div>
                    <div class="col-5">
                        <el-checkbox
                            :disabled="true"
                            v-model="
                                selectedValue.checkbox_hoanThanhChuongTrinhManNon
                            "
                        />
                    </div>
                </div>
            </div>
        </div>
        <div style="display: flex; justify-content: flex-end; margin-top: 100px">
            <CustomButton
                label="Đóng"
                size="small"
                @click="closeModalChitietKQHT"
                class="btn-default btn-delete"
            />
        </div>
    </el-dialog>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import Api from '@/constants/Api'
import sendRequest from '@/services'
import { mapState } from 'vuex'
import {
    ESelectStatus_MockData_TraCuuKQHT,
    ESelectSemester_MockData_TraCuuKQHT
} from '@/mock_data'
export default {
    name: 'ModalChitietLopHoc',
    props: {
        chiTietKQHTModalVisible: {
            type: Boolean,
            required: true
        },

        closeModalChitietKQHT: {
            type: Function,
            required: true
        },
        id_modal: {
            type: Number,
            required: true
        }
    },
    components: { CustomButton },
    data() {
        return {
            kqhtHocSinh_cap1: {},
            kqhtHocSinh_manNon_mauGiao: {},
            tableData_cap2_cap3: [],
            capHoc: '',
            getDataESelect: {
                ESelect_trangThai: ESelectStatus_MockData_TraCuuKQHT,
                ESelect_hocKy: ESelectSemester_MockData_TraCuuKQHT
            },
            selectedValue: {
                // <!-- ************ dùng chung cho các các học ************ -->
                input_hoTen: '',
                input_maHocSinh: '',
                input_maDinhDanh: '',
                selectedValue_trangThai: '',
                input_lopHoc: '',
                selectedValue_namHoc: '',
                selectedValue_hocKy: '',
                input_giaiDoan: '',
                input_soNgayNghi: '',
                input_nhanXetGVCN: '',

                // <!-- ************ cấp 2 và cấp 3 ************ -->
                input_khenThuong: '',
                input_kyLuat: '',
                checkbox_lenLop: false, //dùng cho cả cấp 1
                checkbox_renLuyenLai: false,
                checkbox_thiLai: false,
                checkbox_duXetTotNghiep: false,
                checkbox_totNghiep: false,
                input_loaiTotNghiep: '',
                checkbox_duocMienThi: false,
                input_lyDoMienThi: '',
                checkbox_duocDacCach: false,

                input_chungChiNghe: '',
                input_loaiChungChiNghe: '',

                input_diemTongKet_TT58: '',
                input_hocLuc_TT58: '',
                input_hanhKiem_TT58: '',
                input_danhHieu: '', //dùng chung cho cả cấp 1
                input_ketQuaHocTap_TT22: '',
                input_ketQuaRenLuyen_TT22: '',
                input_khenThuongCapHuyenTroLen: '', //dùng cho cả cấp 1

                input_trungBinhMonSauThiLai_TT58: '',
                input_hocLucSauThiLai_TT58: '',
                input_hanhKiemSauThiLai_TT58: '',
                input_KQHTSauThiLai_TT22: '',
                input_KQRLSauRenLuyenLai_TT22: '',
                input_danhGiaBoSungSauThiLai: '',
                checkbox_lenLopSauThiLai: '',

                // <!-- ************ cấp 1 cấp 2 và cấp 3 ************ -->
                input_nangLucVNEN: '',
                input_phamChatVNEN: '',
                input_ketQuaHocTapVNEN: '',
                input_danhGiaVNEN: '',
                input_danhHieuThiDuaVNEN: '',
                input_nhanXetNLPCVNEN: '',

                // <!-- ************ cấp 1 ************ -->
                //checkbox_lenLop
                checkbox_guiHieuTruongChoLenLop: false,
                checkbox_hoanThanhChuongTrinhLopHoc: false,
                checkbox_hoanThanhChuongTrinhTieuHoc: false,
                input_danhGiaKetQuaGiaoDuc: '',
                checkbox_danhGiaBoSung: false,
                input_lyDoDanhGiaBoSung: '',
                input_noiDungKhenThuong: '',
                // input_khenThuongCapHuyenTroLen

                checkbox_khenThuongCuoiNam: false,
                checkbox_khenThuongDotXuat: false,
                // input_danhHieu : '',
                input_nhanXetMonHocVaHoatDongGiaoDuc: '',
                input_nhanXetPhamChatChuYeu: '',
                input_nhanXetNangLucChung: '',
                input_nhanXetNangLucDacThu: '',

                // <!-- ************ cấp Mầm non (cấp 4) và cấp Mẫu giáo (cấp 5) ************ -->
                checkbox_khamSucKhoeDinhKy: false,
                checkbox_theoDoiBieuDoCanNang: false,
                // checkbox_kenhTangTruongCanNang: false,
                checkbox_theoDoiBieuDoChieuCao: false,
                checkbox_suyDinhDuongTheThapCoi: false,
                checkbox_suyDinhDuongTheCoiCoc: false,
                checkbox_treBiPhoiIaChayHoHap: false,
                checkbox_treBiBenhBeoPhi: false,
                checkbox_treDuocLamQuenTinHoc: false,
                checkbox_hoanThanhChuongTrinhManNon: false
            }
        }
    },
    // components: { CustomButtonVue },
    watch: {
        async id_modal(newValue) {
            await this.getDataChiTietKQHT(newValue)
        }
    },
    methods: {
        async getDataChiTietKQHT(newValue) {
            const request_Header = {
                token: this.authToken
            }
            const request_Params = {
                id: newValue
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const response = await sendRequest(
                Api.traCuu.traCuuKQHT.chiTietKQHT,
                null,
                request_Header,
                request_Params
            )
            if (response.rc == 0) {
                loading.close()
                const data = response.kqhtHocSinh
                this.capHoc = data.capHoc

                console.log(this.capHoc)
                // <!-- ************ các cấp học dùng chung ************ -->
                this.selectedValue.input_hoTen = data.hoTen
                this.selectedValue.input_maHocSinh = data.maHocSinh
                this.selectedValue.input_maDinhDanh = data.moetCode
                this.selectedValue.selectedValue_trangThai =
                    this.convertSelectedValue_MockData(
                        ESelectStatus_MockData_TraCuuKQHT,
                        data.trangThai
                    )

                this.selectedValue.input_lopHoc = data.tenLopHoc
                this.selectedValue.selectedValue_namHoc = `${data.namHoc} - ${
                    data.namHoc + 1
                }`
                this.selectedValue.selectedValue_hocKy =
                    this.convertSelectedValue_MockData(
                        ESelectSemester_MockData_TraCuuKQHT,
                        data.hocKy
                    )
                this.selectedValue.input_soNgayNghi = data.soNgayNghi
                this.selectedValue.input_nhanXetGVCN = data.nhanXet
                this.selectedValue.input_giaiDoan = data.tenGiaiDoan

                // <!-- ************ Mần non (cấp 4) và Mẫu giáo (cấp 5)  ************ -->
                this.kqhtHocSinh_manNon_mauGiao = data
                this.updateCheckboxValue(
                    'checkbox_khamSucKhoeDinhKy',
                    data.isKhamSkDky
                )
                this.updateCheckboxValue(
                    'checkbox_theoDoiBieuDoCanNang',
                    data.isTheoDoiBdoCnang
                )
                this.updateCheckboxValue(
                    'checkbox_theoDoiBieuDoChieuCao',
                    data.isTheoDoiBdoCcao
                )
                this.updateCheckboxValue(
                    'checkbox_suyDinhDuongTheThapCoi',
                    data.isSuyDduongTheTcoi
                )
                // this.updateCheckboxValue('checkbox_suyDinhDuongTheCoiCoc',data) //api chưa có
                this.updateCheckboxValue(
                    'checkbox_treBiPhoiIaChayHoHap',
                    data.isPhoiIchayHhap
                )
                this.updateCheckboxValue(
                    'checkbox_treBiBenhBeoPhi',
                    data.isBeoPhi
                )
                this.updateCheckboxValue(
                    'checkbox_treDuocLamQuenTinHoc',
                    data.isLamQuenThoc
                )
                this.updateCheckboxValue(
                    'checkbox_hoanThanhChuongTrinhManNon',
                    data.isHoanThanhChuongTrinhMamMon
                )

                // <!-- ************ cấp 1  ************ -->
                this.kqhtHocSinh_cap1 = data
                this.updateCheckboxValue(
                    'checkbox_guiHieuTruongChoLenLop',
                    data.isGuiHtruongChoLenLop
                )
                this.updateCheckboxValue(
                    'checkbox_hoanThanhChuongTrinhLopHoc',
                    data.isHthanhCtrinhLhoc
                )
                this.updateCheckboxValue(
                    'checkbox_hoanThanhChuongTrinhTieuHoc',
                    data.isHoanThanhChuongTrinhTieuHoc
                )
                this.selectedValue.input_danhGiaKetQuaGiaoDuc =
                    data.danhGiaKQGiaoDuc
                this.updateCheckboxValue(
                    'checkbox_danhGiaBoSung',
                    data.isDanhGiaBoSung
                )
                this.selectedValue.input_lyDoDanhGiaBoSung =
                    data.lyDoDanhGiaBoSung
                this.selectedValue.input_noiDungKhenThuong = data.ndungKthuong
                this.updateCheckboxValue(
                    'checkbox_khenThuongCuoiNam',
                    data.isKthuongCnam
                )

                this.selectedValue.checkbox_khenThuongDotXuat =
                    data.isKthuongDxuat

                this.selectedValue.input_nhanXetMonHocVaHoatDongGiaoDuc =
                    data.nxMonHocVaHDGD
                this.selectedValue.input_nhanXetPhamChatChuYeu =
                    data.nxPhamChatChuYeu
                this.selectedValue.input_nhanXetNangLucChung = data.nxNangLucChung
                this.selectedValue.input_nhanXetNangLucDacThu =
                    data.nxNangLucDacThu
                this.selectedValue.input_nangLucVNEN = data.nangLuc
                this.selectedValue.input_phamChatVNEN = data.phamChat
                this.selectedValue.input_ketQuaHocTapVNEN = data.ketQuaHocTapVnen
                this.selectedValue.input_danhGiaVNEN = data.danhGiaVnen
                this.selectedValue.input_danhHieuThiDuaVNEN =
                    data.danhHieuThiDuaVnen
                this.selectedValue.input_nhanXetNLPCVNEN =
                    data.nhanXetNangLucPhamChat
                // <!-- ************ cấp 2 và cấp 3 ************ -->
                this.tableData_cap2_cap3 = data.listDiemTKM
                this.selectedValue.input_khenThuong = data.khenThuong
                this.selectedValue.input_kyLuat = data.kyLuat
                this.updateCheckboxValue('checkbox_lenLop', data.isLenLop)
                this.updateCheckboxValue(
                    'checkbox_renLuyenLai',
                    data.isRenLuyenLai
                )
                this.updateCheckboxValue('checkbox_thiLai', data.isThiLai)
                this.updateCheckboxValue(
                    'checkbox_duXetTotNghiep',
                    data.isDuXetTotNghiep
                )
                this.updateCheckboxValue('checkbox_totNghiep', data.isTotNghiep)
                this.selectedValue.input_loaiTotNghiep = data.loaiTotNghiep
                this.selectedValue.checkbox_duocMienThi = data.isMien
                this.selectedValue.input_lyDoMienThi = data.lyDoMien
                this.updateCheckboxValue('checkbox_duocDacCach', data.isDacCach)
                this.selectedValue.input_chungChiNghe = data.chungChiNghe
                this.selectedValue.input_loaiChungChiNghe = data.loaiChungChiNghe

                this.selectedValue.input_diemTongKet_TT58 = data.diemTongKet
                this.selectedValue.input_hocLuc_TT58 = data.tenHocLuc
                this.selectedValue.input_hanhKiem_TT58 = data.tenHanhKiem
                this.selectedValue.input_danhHieu = data.tenDanhHieu
                this.selectedValue.input_ketQuaHocTap_TT22 = data.kqHocTap
                this.selectedValue.input_ketQuaRenLuyen_TT22 = data.kqRenLuyen
                this.selectedValue.input_khenThuongCapHuyenTroLen =
                    data.khenThuongCapHuyen

                this.selectedValue.input_trungBinhMonSauThiLai_TT58 =
                    data.diemTongKetSauThiLai
                this.selectedValue.input_hocLucSauThiLai_TT58 =
                    data.maHocLucSauThiLai
                this.selectedValue.input_hanhKiemSauThiLai_TT58 =
                    data.maHanhKiemSauThiLai
                this.selectedValue.input_KQHTSauThiLai_TT22 =
                    data.kqHocTapSauThiLai
                this.selectedValue.input_KQRLSauRenLuyenLai_TT22 =
                    data.kqRenLuyenSauThiLai
                this.selectedValue.input_danhGiaBoSungSauThiLai =
                    data.danhGiaBoSungSauThiLai
                this.selectedValue.checkbox_lenLopSauThiLai =
                    data.isLenLopSauThiLai
            }
            if (response.rc != 0) {
                loading.close()
                this.$message({
                    type: 'info',
                    message: response.rd
                })
            }
        },
        convertSelectedValue_MockData(data, convertValue) {
            const selectedValue = data
                ?.filter((item) => item.value == convertValue)
                .map((item) => item.value)
                .pop()
            return selectedValue
        },
        updateCheckboxValue(property, dataValue) {
            this.selectedValue[property] =
                dataValue === 0 || dataValue === null ? false : true
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped>
::v-deep .el-input.is-disabled .el-input__inner,
::v-deep .el-textarea.is-disabled .el-textarea__inner,
::v-deep .el-input.is-disabled .el-input__inner {
    background-color: #eee !important;
    color: black !important;
}
::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
    border-color: black !important;
    background-color: #eee !important;
}

.table-responsive {
    overflow-x: auto;
    min-height: 0.01%;
}
.table {
    white-space: nowrap;
}
table td {
    padding: 0.4rem;
    vertical-align: middle;
}
.centered-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.row {
    margin: 0 auto;
}
hr {
    border: 1px solid #80808036;
}

.title {
    font-weight: 700;
    font-size: 13px;
}
.title span {
    color: red;
}

.custom-date-picker {
    height: 32px;
}
.custom-date-picker::placeholder {
    color: gainsboro;

    font-size: 13px;
    background-color: transparent;
    appearance: none;
}

.row {
    margin: 0 auto;
}
.layout-btn {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

/* .custom-disabled-input {
    position: relative;
}

.custom-disabled-input::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #dcdfe6;
    border-color: #e4e7ed;
    color: red;
    cursor: not-allowed;
    pointer-events: none;
} */
.btn-close {
    display: flex;
    justify-content: flex-end;
    margin-top: 100px;
}
.custom-disabled-input {
    color: red !important;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}

.btn-delete {
    background-color: var(--orange);
}
</style>
