<template>
    <div>
        <CustomBreadcrumb :title="'Tra cứu'" content="Tra cứu lớp học" />
        <CustomContentUI>
            <div class="row">
                <div class="col-4">
                    <label>Đơn vị quản lý</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="
                                this.authUser.role === 5 ||
                                this.authUser.role === 4
                            "
                            :data="this.dataChonDonVi_Store"
                            :placeholder="'Chọn đơn vị'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenDonVi', 'maDonVi']"
                            v-model="selectedValue.selectedValueUnitEducation"
                            @input="getDataChonTruongHoc"
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Cấp học</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="this.authUser.role === 5"
                            :data="getDataESelect.ESelectGradeLevel"
                            :placeholder="'Chọn cấp học'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenTruongHoc', 'value']"
                            v-model="selectedValue.selectedValueGradeLevel"
                            @input="getDataChonTruongHoc"
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Khối học</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectSchoolBlock"
                            :placeholder="'Chọn khối học'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['title', 'value']"
                            v-model="selectedValue.selectedValueSchoolBlock"
                        />
                    </div>
                </div>
                <div class="col-4">
                    <label>Trường học</label>
                    <div>
                        <ESelect
                            :disabled="this.authUser.role === 5"
                            :reset="resetESelectSchool"
                            @reset-completed="handleResetSchoolCompleted"
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :data="getDataESelect.ESelectSchool"
                            :placeholder="'Chọn trường học'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['title', 'value']"
                            v-model="selectedValue.selectedValueSchool"
                        />
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-4">
                    <label>Tên lớp học</label>

                    <CustomInput
                        placeholder="Tên lớp học"
                        v-model="selectedValue.input_tenLopHoc"
                        :disabled="false"
                        :clearable="false"
                        :show-close-icon="true"
                        size="small"
                    >
                    </CustomInput>
                </div>
                <div class="col-4">
                    <label> Giáo viên chủ nhiệm</label>

                    <CustomInput
                        placeholder="Tên GVCN"
                        v-model="selectedValue.input_tenGVCN"
                        :disabled="false"
                        :clearable="false"
                        :show-close-icon="true"
                        size="small"
                    >
                    </CustomInput>
                </div>

                <div class="col-4">
                    <label>Năm học<span class="span">*</span></label>
                    <ESelectYear
                        v-model="selectedValue.selectedValueYear"
                        placeholder="Chọn năm"
                        size="small"
                        :no-data-text="'Không có bản ghi nào'"
                        :no-match-text="'Không tìm thấy bản ghi nào'"
                    ></ESelectYear>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-4">
                    <label>Tìm kiếm nâng cao</label>

                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectAdvancedSearch"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueAdvancedSearch"
                        @input="handleTimKiemNangCao"
                    />
                </div>
                <div class="col-4">
                    <label>Nội dung hiển thị <span class="span">*</span></label>

                    <ESelectSearch
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectDisplayContent"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['title', 'key', 'value']"
                        v-model="selectedValue.selectedValueDisplayContent"
                    />
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-2" v-if="hienThiNangCao.lopChuyen">
                    <label>Lớp chuyên</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectSpecializedClass"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueSpecializedClass"
                    />
                </div>
                <div class="col-2" v-if="hienThiNangCao.lopBanTru">
                    <label>Lớp bán trú</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectBoardingClass"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueBoardingClass"
                    />
                </div>
                <div class="col-2" v-if="hienThiNangCao.coHocSinhHocNghe">
                    <label>Lớp có học sinh học nghề</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectClassWithApprenticeStudents"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="
                            selectedValue.selectedValueClassWithApprenticeStudents
                        "
                    />
                </div>
                <div class="col-2" v-if="hienThiNangCao.lopGhep">
                    <label>Lớp ghép</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectCompoundClass"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueCompoundClass"
                    />
                </div>
                <div class="col-2" v-if="hienThiNangCao.maLopGhep">
                    <label>Mã lớp ghép</label>
                    <el-input
                        placeholder="Nhập"
                        size="small"
                        v-model="selectedValue.input_maLopGhep"
                    ></el-input>
                </div>
                <div class="col-2" v-if="hienThiNangCao.lopVnen">
                    <label>Lớp VNEN</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectClassVNEN"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueClassVNEN"
                    />
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-2" v-if="hienThiNangCao.lopCoTreKhuyetTat">
                    <label>Lớp có trẻ khuyết tật</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="
                            getDataESelect.ESelectClassWithChildrenWithDisabilities
                        "
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="
                            selectedValue.selectedValueClassWithChildrenWithDisabilities
                        "
                    />
                </div>
                <div class="col-2" v-if="hienThiNangCao.lopChuyenBiet">
                    <label>Lớp chuyên biệt</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectSpecialClass"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueSpecialClass"
                    />
                </div>
                <div class="col-2" v-if="hienThiNangCao.soBuoiTrenTuan">
                    <label>Số buổi học trên tuần</label>
                    <el-input
                        placeholder="Số buổi học trên tuần"
                        size="small"
                        v-model="selectedValue.input_souoiHocTrenTuan"
                    ></el-input>
                </div>
                <div class="col-2" v-if="hienThiNangCao.moetCode">
                    <label>Mã định danh</label>
                    <el-input
                        placeholder="Nhập ..."
                        size="small"
                        v-model="selectedValue.input_maDinhDanh"
                    ></el-input>
                </div>
                <div class="col-2" v-if="hienThiNangCao.maNn1">
                    <label>Ngoại ngữ 1</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="this.dataChonNgoaingu_Store"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['constantTitle', 'mappingCode']"
                        v-model="selectedValue.selectedValueForeignLanguage1"
                    />
                </div>
                <div class="col-2" v-if="hienThiNangCao.maNn2">
                    <label>Ngoại ngữ 2</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="this.dataChonNgoaingu_Store"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['constantTitle', 'mappingCode']"
                        v-model="selectedValue.selectedValueForeignLanguage2"
                    />
                </div>
            </div>

            <div class="btn-search">
                <CustomButton
                    label="Tìm kiếm"
                    class="btn-default bg-search"
                    size="small"
                    @click="handleSearch"
                />
                <CustomButton
                    class="btn-default"
                    label="Xuất Excel"
                    size="small"
                    @click="handleDownLoadExcel"
                    style="background-color: #1e6050"
                />
            </div>
        </CustomContentUI>

        <div
            class="layout-btn"
            :style="{
                'max-width': leftBarWidth()
            }"
        >
            <div>
                <chonSoLuongBanGhi @chonXongSoLuong="ChonSoLuongBanGhi($event)" />
            </div>
            <div>
                <CustomButton
                    label="Xóa"
                    size="small"
                    class="btn-default btn-delete"
                    @click="handleDelete"
                />
                <CustomButton
                    label="Xóa mã định danh"
                    size="small"
                    class="btn-default btn-delete"
                    @click="handleXoaMaDinhDanh"
                />
            </div>
        </div>
        <div
            class="layout-table"
            :style="{
                'max-width': leftBarWidth()
            }"
        >
            <table class="table table-bordered table-hover centered-table">
                <thead></thead>
                <tbody></tbody>
            </table>
        </div>
        <CustomTable :max-width="leftBarWidth()">
            <template v-slot:header>
                <tr>
                    <th class="text-thead">
                        <input
                            type="checkbox"
                            v-model="selectAllChecked"
                            @change="handleSelectAll"
                            :disabled="tableData.length === 0"
                        />
                    </th>
                    <th class="text-thead">STT</th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(1)">
                        Mã trường
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(2)">
                        Trường học
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(3)">
                        Mã lớp
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(4)">
                        Mã định danh
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(5)">
                        Tên lớp
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(6)">
                        Khối học/Nhóm lớp
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(7)">
                        Giáo viên chủ nhiệm
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(8)">
                        Buổi học
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(9)">
                        Học ngoại ngữ 1
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(10)"
                    >
                        Học ngoại ngữ 2
                    </th>

                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(12)"
                    >
                        Phân ban
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(13)"
                    >
                        Lớp chuyên
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(14)"
                    >
                        Học sinh học nghề
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(15)"
                    >
                        Lớp ghép
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(16)"
                    >
                        Ghép vào lớp
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(17)"
                    >
                        Lớp bán trú
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(18)"
                    >
                        Số buổi học trên tuần
                    </th>

                    <th class="text-thead">Thao tác</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21" class="text-tbody">
                            Không có bản ghi nào.
                        </td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td class="text-tbody">
                        <input
                            type="checkbox"
                            :checked="item.isChecked"
                            @change="toggleCheckbox(item)"
                        />
                    </td>
                    <td class="text-tbody">
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-tbody" v-if="defaultDisplayTable.includes(1)">
                        {{ item.maTruongHoc }}
                    </td>
                    <td
                        class="text-tbody"
                        style="text-align: left"
                        v-if="defaultDisplayTable.includes(2)"
                    >
                        {{ item.tenTruongHoc }}
                    </td>
                    <td class="text-tbody" v-if="defaultDisplayTable.includes(3)">
                        <span
                            class="modal-dislabel"
                            @click="openModalDislabelChiTietLopHoc(item.maLopHoc)"
                            >{{ item.maLopHoc }}</span
                        >
                    </td>
                    <td class="text-tbody" v-if="defaultDisplayTable.includes(4)">
                        {{ item.moetCode }}
                    </td>
                    <td
                        class="text-tbody text-left"
                        v-if="defaultDisplayTable.includes(5)"
                    >
                        <span
                            class="modal-dislabel"
                            @click="openModalDislabelChiTietLopHoc(item.maLopHoc)"
                            >{{ item.tenLopHoc }}</span
                        >
                    </td>
                    <td
                        class="text-tbody text-left"
                        v-if="defaultDisplayTable.includes(6)"
                    >
                        {{ item.khoiHoc }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(7)"
                        style="text-align: left"
                    >
                        {{ item.tenGiaoVienCn }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(8)"
                        style="text-align: left"
                    >
                        {{ item.buoiHoc }}
                    </td>
                    <td class="text-tbody" v-if="defaultDisplayTable.includes(9)">
                        {{ item.maNn1 }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(10)"
                    >
                        {{ item.maNn2 }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(12)"
                    >
                        {{ getPhanBan(item.phanBan) }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(13)"
                    >
                        {{ item.lopChuyen }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(14)"
                    >
                        {{ item.coHocSinhHocNghe }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(15)"
                    >
                        {{ item.lopGhep }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(16)"
                    >
                        {{ item.maLopGhep }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(17)"
                    >
                        {{ item.lopBanTru }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(18)"
                    >
                        {{ item.soBuoiTrenTuan }}
                        <span v-if="item.soBuoiTrenTuan">buổi/tuần</span>
                    </td>
                    <td>
                        <CustomButton
                            label=""
                            size="small"
                            icon="el-icon-edit-outline"
                            class="btn-default btn-update"
                            @click="openModalChiTietLopHoc(item.maLopHoc)"
                        />
                    </td>
                </tr>
            </template>
        </CustomTable>

        <CustomPagination
            v-show="total_rows > 0"
            :tongbanghi="total_rows"
            :soluonghienthi="soLuongBanGhiHienThi"
            :batdau="trangbatdau"
            @pageChange="layLai($event)"
        >
        </CustomPagination>

        <ModalChiTietLopHoc
            :chiTietLopHocModalVisible="chiTietLopHocModalVisible"
            :closeModalChitietLopHoc="closeModalChitietLopHoc"
            :id_modalChiTietLopHoc="id_modalChiTietLopHoc"
            @handleSearch="handleSearch"
        />
        <DislabelModalChiTietLopHoc
            :id_dislabelModalChiTietLopHoc="id_dislabelModalChiTietLopHoc"
            :dislabelChiTietLopHocModalVisible="dislabelChiTietLopHocModalVisible"
            :closeDislabelModalChitietLopHoc="closeDislabelModalChitietLopHoc"
        />
    </div>
</template>
<script>
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'
import chonSoLuongBanGhi from '@/components/chonSoLuongBanGhi.vue'
import ESelectYear from '@/components/ESelectYear.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import ESelect from '@/components/ESelect.vue'
import ESelectSearch from '@/components/ESelectSearch.vue'
import CustomButton from '@/components/CustomButton.vue'
import ModalChiTietLopHoc from './ModalChiTietLopHoc.vue'
import DislabelModalChiTietLopHoc from './DislabelModalChiTietLopHoc.vue'
import {
    ESelectDisplayContent_MockData_TraCuulophoc,
    ESelectSchoolBlock_MockData,
    ESelectAdvancedSearch_MockData_Tracuulophoc,
    ESlectYesOfNo_MockData,
    ESelectTrueOfFalse,
    ESelectDivision_MockData
} from '@/mock_data'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { mapState } from 'vuex'
import axios from 'axios'
import { convertCapHocList, currentYear, filterCapHocList } from '@/utils'
import CustomContentUI from '@/components/CustomContentUI.vue'
import CustomInput from '@/components/CustomInput.vue'
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'KetChuyenDuLieu',
    components: {
        ESelect,
        ESelectSearch,
        CustomButton,
        CustomBreadcrumb,
        ESelectYear,
        CustomPagination,
        chonSoLuongBanGhi,
        ModalChiTietLopHoc,
        DislabelModalChiTietLopHoc,
        CustomContentUI,
        CustomInput,
        CustomTable
    },
    data() {
        return {
            defaultDisplayTable: ESelectDisplayContent_MockData_TraCuulophoc.map(
                (item) => item.value
            ),
            selectAllChecked: false,
            selectedItems: [],
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            soLuongBanGhiHienThi: 25,
            trangbatdau: false,
            chiTietLopHocModalVisible: false,
            id_modalChiTietLopHoc: '',
            dislabelChiTietLopHocModalVisible: false,
            id_dislabelModalChiTietLopHoc: '',
            hienThiNangCao: {
                lopChuyen: false,
                lopBanTru: false,
                coHocSinhHocNghe: false,
                lopGhep: false,
                maLopGhep: false,
                lopVnen: false,
                lopCoTreKhuyetTat: false,
                lopChuyenBiet: false,
                soBuoiTrenTuan: false,
                moetCode: false,
                maNn1: false,
                maNn2: false
            },

            resetESelectSchool: false,

            tableData: [],
            request_Data: {
                capHoc: [],
                colExcels: [],
                dataSearchList: [],
                keySearch: '',
                khoiHoc: [],
                listMaDonViReq: [],
                listMaTruongHocReq: [],
                maNhomLopMn: '',
                namHoc: '',
                start: 0,
                limit: 25,
                tenGvcn: ''
            },

            getDataESelect: {
                ESelectUnitEducation: [], //donvi
                ESelectGradeLevel: [], //caphoc
                ESelectSchoolBlock: ESelectSchoolBlock_MockData, //khoihoc/nhomlop
                ESelectSchool: [], //truonghoc
                // input_tenLopHoc
                //input_tenGVCN
                ESelectPreschoolGroup: [], //nhomtremannon
                //namHoc
                ESelectAdvancedSearch:
                    ESelectAdvancedSearch_MockData_Tracuulophoc, //timkiemnangcao
                ESelectDisplayContent:
                    ESelectDisplayContent_MockData_TraCuulophoc, //noidunghienthi,
                ESelectSpecializedClass: ESlectYesOfNo_MockData, //lopchuyen
                ESelectBoardingClass: ESlectYesOfNo_MockData, //lopbantru
                ESelectClassWithApprenticeStudents: ESelectTrueOfFalse, //lopcohocsinhhocnghe
                ESelectCompoundClass: ESlectYesOfNo_MockData, //lopghep
                //input_maLopGhep
                ESelectClassVNEN: ESlectYesOfNo_MockData, //lopVNEN
                ESelectClassWithChildrenWithDisabilities: ESelectTrueOfFalse, //lopcotrekhuyettat
                ESelectSpecialClass: ESelectTrueOfFalse, //lopchuyenbiet
                //input_soBuoiHocTrenTuan
                //input_maDinhDanh
                ESelectForeignLanguage1: [], //ngoaingu1
                ESelectForeignLanguage2: [] //ngoaingu2
            },

            selectedValue: {
                selectedValueUnitEducation: [], //donvi
                selectedValueGradeLevel: [], //caphoc
                selectedValueSchoolBlock: [], //khoihoc/nhomlop
                selectedValueSchool: [], //truonghoc
                input_tenLopHoc: '', //tenLophoc
                input_tenGVCN: null, //input_tenGVCN
                selectedValuePreschoolGroup: [], //nhomtremannon
                selectedValueYear: '', //namHoc
                selectedValueAdvancedSearch: [], //timkiemnangcao
                selectedValueDisplayContent:
                    ESelectDisplayContent_MockData_TraCuulophoc, //noidunghienthi
                selectedValueSpecializedClass: [], //lopchuyen
                selectedValueBoardingClass: [], //lopbantru
                selectedValueClassWithApprenticeStudents: [], //lopcohocsinhhocnghe
                selectedValueCompoundClass: [], //lopghep
                input_maLopGhep: '', //input_maLopGhep
                selectedValueClassVNEN: [], //lopVNEN
                selectedValueClassWithChildrenWithDisabilities: [], //lopcotrekhuyettat
                selectedValueSpecialClass: [], //lopchuyenbiet
                input_souoiHocTrenTuan: '', //input_soBuoiHocTrenTuan
                input_maDinhDanh: '', //input_maDinhDanh
                selectedValueForeignLanguage1: [], //ngoaingu1
                selectedValueForeignLanguage2: [] //ngoaingu2
            }
        }
    },

    methods: {
        handleTimKiemNangCao(value) {
            const selectedValues = value.map((item) => item.value)

            for (const key in this.hienThiNangCao) {
                this.hienThiNangCao[key] = selectedValues.includes(key)
            }
        },
        toggleCheckbox(item) {
            this.selectAllChecked = false
            item.isChecked = !item.isChecked
            if (item.isChecked) {
                this.selectedItems.push(item) // Thêm item vào mảng selectedItems khi checkbox được chọn
            } else {
                const index = this.selectedItems.indexOf(item)
                if (index > -1) {
                    this.selectedItems.splice(index, 1) // Xóa item khỏi mảng selectedItems khi checkbox bị bỏ chọn
                }
            }
        },

        handleSelectAll() {
            this.tableData.forEach((item) => {
                item.isChecked = this.selectAllChecked // Đặt trạng thái của tất cả các checkbox bằng với checkbox "checkboxAll"
                if (this.selectAllChecked && !this.selectedItems.includes(item)) {
                    this.selectedItems.push(item) // Thêm tất cả các item vào mảng selectedItems khi chọn tất cả
                } else if (!this.selectAllChecked) {
                    this.selectedItems = [] // Xóa tất cả các item khỏi mảng selectedItems khi bỏ chọn tất cả
                }
            })
        },
        getPhanBan(e) {
            const foundItem = ESelectDivision_MockData.find(
                (item) => item.value === e
            )
            return foundItem ? foundItem.title : ''
        },
        openModalChiTietLopHoc(item) {
            this.id_modalChiTietLopHoc = item
            this.chiTietLopHocModalVisible = true
        },
        closeModalChitietLopHoc() {
            this.chiTietLopHocModalVisible = false
        },
        openModalDislabelChiTietLopHoc(item) {
            this.id_dislabelModalChiTietLopHoc = item
            this.dislabelChiTietLopHocModalVisible = true
        },
        closeDislabelModalChitietLopHoc() {
            this.dislabelChiTietLopHocModalVisible = false
        },
        handleResetSchoolCompleted() {
            this.resetESelectSchool = false
        },

        ChonSoLuongBanGhi(e) {
            this.total_rows = 0
            this.soLuongBanGhiHienThi = e.soluong
        },
        checkTruocKhiTim() {
            this.trangbatdau = !this.trangbatdau
        },
        layLai(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        async getDataChonTruongHoc() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const maDonVi = this.convertToValueArray(
                this.selectedValue.selectedValueUnitEducation
            )

            const capHoc = this.convertToValueArray(
                this.selectedValue.selectedValueGradeLevel
            )

            const request_Header = {
                token: this.authToken
            }

            const request_Data = {
                capHoc: capHoc,
                maDonVi: maDonVi
            }

            let response = await sendRequest(
                Api.internal_api.dm_School,
                request_Data,
                request_Header,
                null
            )

            if (response.statusResponse == 0) {
                loading.close()
                this.getDataESelect.ESelectSchool = response.rows.map((item) => ({
                    title: `${item.tenTruongHoc} - [${item.maTruongHoc}]`,
                    value: item.maTruongHoc
                }))
                loading.close()
            } else {
                loading.close()
            }
        },
        async handleDelete() {
            if (this.selectedItems.length === 0) {
                this.$message({
                    type: 'warning',
                    message: 'Vui lòng chọn ít nhất 1 lớp học'
                })
                return
            }

            const listMa = this.selectedItems.map((item) => item.maLopHoc)

            const namHoc = this.selectedValue.selectedValueYear
            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                listMa: listMa,

                namHoc: namHoc || currentYear
            }

            this.$confirm('Xác nhận xóa các bản ghi đã chọn?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    })
                    const response = await sendRequest(
                        Api.traCuu.traCuuLopHoc.xoaLopHoc,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        loading.close()
                        this.tableData = this.tableData.filter(
                            (item) => !item.isChecked
                        )

                        this.selectedItems = []
                        await this.handleSearch()
                        this.$message({
                            type: 'success',
                            message: 'Xóa thành công'
                        })
                    } else {
                        loading.close()
                        this.$message({
                            type: 'error',
                            message: response.rd
                        })
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    })
                })
        },
        async handleXoaMaDinhDanh() {
            if (this.selectedItems.length === 0) {
                this.$message({
                    type: 'warning',
                    message: 'Vui lòng chọn ít nhất 1 lớp học'
                })
                return
            }
            const lstMaLopHocs = this.selectedItems.map((item) => item.maLopHoc)

            const namHoc = this.selectedValue.selectedValueYear
            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                loaiDuLieu: 2,
                lstMaLopHocs: lstMaLopHocs,
                namHoc: namHoc || currentYear
            }

            this.$confirm(
                'Xác nhận xóa mã định danh các bản ghi đã chọn?',
                'Thông báo',
                {
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy',
                    type: 'warning'
                }
            )
                .then(async () => {
                    const response = await sendRequest(
                        Api.traCuu.traCuuHocSinh.xoaMaDinhDanhLenBo,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        this.tableData = this.tableData.filter(
                            (item) => !item.isChecked
                        )

                        this.selectedItems = []
                        this.selectAllChecked = false
                        this.$message({
                            type: 'success',
                            message: 'Xóa Mã định danh Bộ GĐ&ĐT thành công'
                        })
                        this.selectAllChecked = false
                        await this.handleSearch()
                    } else {
                        this.$message({
                            type: 'info',
                            message: response.rd
                        })
                    }
                })
                .catch(() => {})
        },
        handleDownLoadExcel() {
            const listMaDonViReq = this.convertToValueArray(
                this.selectedValue.selectedValueUnitEducation
            )
            const listMaTruongHocReq = this.convertToValueArray(
                this.selectedValue.selectedValueSchool
            )
            const capHoc = this.convertToValueArray(
                this.selectedValue.selectedValueGradeLevel
            ).map((num) => Number(num))
            const khoiHoc = this.convertToValueArray(
                this.selectedValue.selectedValueSchoolBlock
            )

            const namHoc = this.selectedValue.selectedValueYear || currentYear
            const noidunghienthi = this.selectedValue.selectedValueDisplayContent
            const defaultDisplayTable = noidunghienthi.map((item) => item.value)
            this.defaultDisplayTable = defaultDisplayTable
            const colExcels = noidunghienthi.map((item) => ({
                key: item.key,
                stt: item.value,
                name: item.title
            }))

            this.$confirm('Xác nhận tải xuống?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const request_Data = {
                        start: 0,
                        limit: 50000,
                        capHoc: capHoc,
                        khoiHoc: khoiHoc,
                        listMaDonViReq: listMaDonViReq,
                        listMaTruongHocReq: listMaTruongHocReq,
                        namHoc: namHoc,
                        tenGvcn: this.selectedValue.input_tenGVCN,
                        maNhomLopMn: '',
                        colExcels: colExcels,
                        keySearch: this.selectedValue.input_tenLopHoc
                    }
                    const domain = process.env.VUE_APP_BACKEND_BASE_URL
                    const response = await axios.post(
                        `${domain}/csdlgd-admin/ExportExcel/SreachLopHoc2`,
                        request_Data,
                        {
                            headers: {
                                token: this.authToken,
                                'Content-Type': 'application/json'
                            },
                            responseType: 'blob' // Set responseType là 'blob'
                        }
                    )

                    const blob = new Blob([response.data])
                    const blobUrl = window.URL.createObjectURL(blob)

                    const downloadLink = document.createElement('a')
                    downloadLink.href = blobUrl
                    downloadLink.download = 'DanhSachLopHoc.xlsx'
                    downloadLink.style.display = 'none'

                    document.body.appendChild(downloadLink)
                    downloadLink.click()

                    window.URL.revokeObjectURL(blobUrl)
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    })
                })
        },
        async handleSearch() {
            const listMaDonViReq = this.convertToValueArray(
                this.selectedValue.selectedValueUnitEducation
            )
            const listMaTruongHocReq = this.convertToValueArray(
                this.selectedValue.selectedValueSchool
            )
            const capHoc = this.convertToValueArray(
                this.selectedValue.selectedValueGradeLevel
            ).map((num) => Number(num))
            const khoiHoc = this.convertToValueArray(
                this.selectedValue.selectedValueSchoolBlock
            )

            const namHoc = this.selectedValue.selectedValueYear || currentYear
            const noidunghienthi = this.selectedValue.selectedValueDisplayContent
            const defaultDisplayTable = noidunghienthi.map((item) => item.value)
            this.defaultDisplayTable = defaultDisplayTable
            const colExcels = noidunghienthi.map((item) => ({
                key: item.key,
                stt: item.value,
                name: item.title
            }))

            const timkiemnangcao = this.convertToValueArray(
                this.selectedValue.selectedValueAdvancedSearch
            )

            const keyMappings_dataSreachList = {
                lopChuyen: 'selectedValueSpecializedClass',
                lopBanTru: 'selectedValueBoardingClass',
                coHocSinhHocNghe: 'selectedValueClassWithApprenticeStudents',
                lopGhep: 'selectedValueCompoundClass',
                maLopGhep: 'input_maLopGhep',
                lopVnen: 'selectedValueClassVNEN',
                lopCoTreKhuyetTat:
                    'selectedValueClassWithChildrenWithDisabilities',
                lopChuyenBiet: 'selectedValueSpecialClass',
                soBuoiTrenTuan: 'input_souoiHocTrenTuan',
                moetCode: 'input_maDinhDanh',
                maNn1: 'selectedValueForeignLanguage1',
                maNn2: 'selectedValueForeignLanguage2'
                // Thêm các ánh xạ key - giá trị tương ứng ở đây
            }

            const dataSearchList = timkiemnangcao
                .map((key) => {
                    const valueKey = keyMappings_dataSreachList[key]
                    const inputValue = this.selectedValue[valueKey]
                    if (valueKey && inputValue) {
                        let value
                        if (Array.isArray(inputValue)) {
                            value = inputValue.map((item) => item.value)
                            return {
                                key: key,
                                values: value
                            }
                        } else if (typeof inputValue === 'object') {
                            value = inputValue.value
                        } else {
                            value = inputValue
                        }
                        return {
                            key: key,
                            value: value
                        }
                    }
                    return null
                })
                .filter((item) => item !== null)
            const start = this.start
            const limit = this.limit
            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                capHoc: capHoc,
                colExcels: colExcels,
                dataSearchList: dataSearchList,
                keySearch: this.selectedValue.input_tenLopHoc,
                khoiHoc: khoiHoc,
                listMaDonViReq: listMaDonViReq,
                listMaTruongHocReq: listMaTruongHocReq,
                maNhomLopMn: null,
                namHoc: namHoc,
                start: start,
                limit: limit,
                tenGvcn: this.selectedValue.input_tenGVCN
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const response = await sendRequest(
                Api.traCuu.traCuuLopHoc.danhSachLopHoc,
                request_Data,
                request_Header,
                null
            )
            if (response.rc == 0) {
                loading.close()
                this.$message({
                    type: 'success',
                    message: 'Danh sách tìm kiếm'
                })
                this.tableData = response.tableData.map((item) => {
                    return { ...item, isChecked: false }
                })
                this.total_rows = response.totalRows
            } else {
                loading.close()
                this.$message({
                    type: 'warning',
                    message: response.rd
                })
                this.tableData = response.tableData
                this.total_rows = response.totalRows
            }
        },
        convertToValueArray(array) {
            return array.map((item) => item.value)
        },
        leftBarWidth() {
            if (this.isCollapseLeftBar) {
                return 'calc(100vw - 105px)'
            } else {
                return 'calc(100vw - 340px)'
            }
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        },
        dataChonNgoaingu_Store() {
            return JSON.parse(localStorage.getItem('data_ChonNgoaingu'))
        },
        ...mapState({
            dataTo_KetChuyenDuLieu: (state) =>
                state.data_Navigate_KetChuyenDuLieu.traCuuLopHoc
        }),
        ...mapState(['isCollapseLeftBar'])
    },
    watch: {
        'authUser.role': {
            immediate: true,
            handler(newRole, oldRole) {
                if (newRole === 5) {
                    this.selectedValue.selectedValueUnitEducation = [
                        { value: this.authUser.ma_don_vi }
                    ]
                    const convertedList = convertCapHocList(
                        this.authUser.capHocList
                    )
                    this.selectedValue.selectedValueGradeLevel = convertedList
                    this.selectedValue.selectedValueSchool = [
                        { value: this.authUser.ma_truong_hoc }
                    ]
                }
                if (newRole === 4) {
                    this.selectedValue.selectedValueUnitEducation = [
                        { value: this.authUser.ma_don_vi }
                    ]
                }
            }
        },
        dataTo_KetChuyenDuLieu: {
            immediate: true,
            handler(newValues) {
                if (
                    newValues?.maTruongHoc &&
                    newValues &&
                    this.authUser.role !== 5
                ) {
                    this.selectedValue.selectedValueYear =
                        newValues?.namHoc.toString()
                    this.selectedValue.selectedValueSchool = [
                        { value: newValues.maTruongHoc }
                    ]
                }
            }
        },

        'selectedValue.selectedValueUnitEducation': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        },
        'selectedValue.selectedValueGradeLevel': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        },
        'selectedValue.selectedValueSchoolBlock': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        }
    },
    mounted() {
        this.getDataESelect.ESelectGradeLevel = filterCapHocList(
            this.authUser.capHocList
        )
        this.handleSearch()
        this.getDataChonTruongHoc()
    }
}
</script>
<style scoped>
.layout-btn-add {
    padding: 20px 10px 5px 10px;
    margin: 0 auto;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
}

.modal-dislabel {
    color: #409eff;
}
.modal-dislabel:hover {
    text-decoration: underline;
    cursor: pointer;
}

.btn-search {
    margin-top: 20px;
    justify-content: center;
    display: flex;
    margin-bottom: 10px;
}
.layout-btn {
    padding: 20px 10px 5px 10px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.bg-search,
.excel {
    background-color: var(--teal);
}
.btn-add {
    background-color: var(--light-green);
}

.btn-update {
    background-color: var(--yellow);
}
.btn-delete {
    background-color: var(--orange);
}
.text-left {
    text-align: left;
}
</style>
