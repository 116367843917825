<template>
    <el-dialog
        :visible="themMoiModalVisible"
        @close="closeModalThemMoi"
        width="60%"
    >
        <span slot="title" class="custom-dialog-title">
            THÊM CHỈ SỐ ĐÁNH GIÁ PHÁT TRIỂN
            <hr />
        </span>
        <div class="row form">
            <div class="col-md-12">
                <div class="row d-flex align-items-center justify-content-center">
                    <div class="col-md-3">
                        <label
                            >Lĩnh vực ĐG&PT (<span class="span">*</span>)</label
                        >
                    </div>
                    <div class="col-md-6">
                        <el-select
                            v-model="selectedValue.selectedValue_linhVuc"
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            :no-data-text="'Không có bản ghi nào'"
                        >
                            <el-option
                                v-for="item in ESelect_linhVuc"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            />
                        </el-select>
                        <div class="error-message" v-if="errors.linhVuc">
                            {{ errors.linhVuc }}
                        </div>
                    </div>
                </div>
                <div
                    class="row d-flex align-items-center justify-content-center mt-4"
                >
                    <div class="col-md-3">
                        <label>Mã chỉ số(<span class="span">*</span>)</label>
                    </div>
                    <div class="col-md-6">
                        <el-input
                            placeholder="Nhập mã chỉ số..."
                            v-model="selectedValue.input_maChiSo"
                            size="small"
                            style="width: 100%"
                            @blur="validateMaChiSo"
                            @focus="validateMaChiSo"
                        >
                        </el-input>
                        <div class="error-message" v-if="errors.maChiSo">
                            {{ errors.maChiSo }}
                        </div>
                    </div>
                </div>
                <div
                    class="row d-flex align-items-center justify-content-center mt-4"
                >
                    <div class="col-md-3">
                        <label>Tên chỉ số(<span class="span">*</span>)</label>
                    </div>
                    <div class="col-md-6">
                        <el-input
                            placeholder="Nhập tên lĩnh vực ..."
                            v-model="selectedValue.input_tenChiSo"
                            size="small"
                            style="width: 100%"
                            @blur="validateTenChiSo"
                            @focus="validateTenChiSo"
                        >
                        </el-input>
                        <div class="error-message" v-if="errors.tenChiSo">
                            {{ errors.tenChiSo }}
                        </div>
                    </div>
                </div>
                <div
                    class="row d-flex align-items-center justify-content-center mt-4"
                >
                    <div class="col-md-3">
                        <label>Cấp học(<span class="span">*</span>)</label>
                    </div>
                    <div class="col-md-6">
                        <el-select
                            v-model="selectedValue.selectedValue_capHoc"
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            :no-data-text="'Không có bản ghi nào'"
                            @input="handleChangeCapHoc"
                        >
                            <el-option
                                v-for="item in ESelect_capHoc"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            />
                        </el-select>
                        <div class="error-message" v-if="errors.capHoc">
                            {{ errors.capHoc }}
                        </div>
                    </div>
                </div>
                <div
                    class="row d-flex align-items-center justify-content-center mt-4"
                >
                    <div class="col-md-3">
                        <label>Nhóm tuổi (<span class="span">*</span>)</label>
                    </div>
                    <div class="col-md-6">
                        <el-select
                            v-model="selectedValue.selectedValue_nhomTuoi"
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                            :no-data-text="'Không có bản ghi nào'"
                        >
                            <el-option
                                v-for="item in ESelect_nhomTuoi"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            />
                        </el-select>
                        <div class="error-message" v-if="errors.nhomTuoi">
                            {{ errors.nhomTuoi }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="layout-btn">
            <div>
                <CustomButton
                    label="Đóng"
                    size="small"
                    type="info"
                    @click="closeModalThemMoi"
                />
            </div>
            <div style="margin-left: 10px">
                <CustomButton
                    label="Thêm mới"
                    size="small"
                    type="success"
                    @click="handleThemMoi"
                />
            </div>
        </div>
    </el-dialog>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import { mapState } from 'vuex'
import sendRequest from '@/services'
import { validateInput } from '@/utils'
import { ESelectSchoolBlock_LinhVucDanhGiaPhatTrien } from '@/mock_data/index'
import Api from '@/constants/Api'
export default {
    name: 'ModalThemMoiTruongHoc',
    props: {
        themMoiModalVisible: {
            type: Boolean,
            required: true
        },
        closeModalThemMoi: {
            type: Function,
            required: true
        },
        ESelect_linhVuc: {
            type: Array,
            required: true
        },
        handleSearch: Function
    },

    components: { CustomButton },
    data() {
        return {
            errors: {
                linhVuc: null,
                maChiSo: null,
                tenChiSo: null,
                capHoc: null,
                nhomTuoi: null
            },
            submitted: false, // Thêm biến submitted

            selectedValue: {
                selectedValue_linhVuc: '',
                input_maChiSo: '',
                input_tenChiSo: '',
                selectedValue_capHoc: '',
                selectedValue_nhomTuoi: ''
            },

            ESelect_capHoc: [
                {
                    title: 'Chọn cấp học',
                    value: ''
                },
                {
                    title: 'Nhà trẻ',
                    value: '4'
                },
                {
                    title: 'Mẫu giáo',
                    value: '5'
                }
            ],
            ESelect_nhomTuoi: []
        }
    },

    methods: {
        validateLinhVuc() {
            if (this.submitted) {
                if (this.selectedValue.selectedValue_linhVuc === '') {
                    this.errors.linhVuc = 'Vui lòng chọn Lĩnh vực ĐT&PT'
                } else {
                    this.errors.linhVuc = ''
                }
            }
        },
        validateMaChiSo() {
            if (this.submitted) {
                this.errors.maChiSo = validateInput(
                    'text',
                    this.selectedValue.input_maChiSo
                )
            }
        },
        validateTenChiSo() {
            if (this.submitted) {
                this.errors.tenChiSo = validateInput(
                    'text',
                    this.selectedValue.input_tenChiSo
                )
            }
        },
        validateCapHoc() {
            if (this.submitted) {
                if (this.selectedValue.selectedValue_capHoc === '') {
                    this.errors.capHoc = 'Vui lòng chọn cấp học'
                } else {
                    this.errors.capHoc = ''
                }
            }
        },
        validateNhomTuoi() {
            if (this.submitted) {
                if (this.selectedValue.selectedValue_nhomTuoi === '') {
                    this.errors.nhomTuoi = 'Vui lòng chọn Nhóm tuổi'
                } else {
                    this.errors.nhomTuoi = ''
                }
            }
        },
        handleChangeCapHoc() {
            const change_capHoc = this.selectedValue.selectedValue_capHoc
            if (change_capHoc == 4) {
                this.ESelect_nhomTuoi =
                    ESelectSchoolBlock_LinhVucDanhGiaPhatTrien.slice(1, 4)
            }
            if (change_capHoc == 5) {
                this.ESelect_nhomTuoi =
                    ESelectSchoolBlock_LinhVucDanhGiaPhatTrien.slice(4, 7)
            }
            console.log()
        },
        handleThemMoi() {
            this.submitted = true
            this.validateLinhVuc()
            this.validateMaChiSo()
            this.validateTenChiSo()
            this.validateCapHoc()
            this.validateNhomTuoi()
            // Kiểm tra nếu không có lỗi thì tiếp tục thực hiện hành động xác thực
            if (
                !this.errors.linhVuc &&
                !this.errors.maChiSo &&
                !this.errors.tenChiSo &&
                !this.errors.capHoc &&
                !this.errors.nhomTuoi
            ) {
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })

                this.$confirm('Xác nhận thêm mới ?', 'Thông báo', {
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy',
                    type: 'warning'
                })
                    .then(async () => {
                        const request_Header = {
                            token: this.authToken
                        }
                        const request_Data = {
                            ma: this.selectedValue.input_maChiSo,
                            ten: this.selectedValue.input_tenChiSo,
                            capHoc: this.selectedValue.selectedValue_capHoc,
                            khoiHoc: this.selectedValue.selectedValue_nhomTuoi,

                            maLinhVuc: this.selectedValue.selectedValue_linhVuc
                        }
                        const response = await sendRequest(
                            Api.superAdmin.chiSoDanhGiaPhatTrien
                                .themMoiChiSoDanhGiaPhatTrien,
                            request_Data,

                            request_Header
                        )
                        if (response.code == 200) {
                            this.$message({
                                type: 'success',
                                message: 'Thêm mới thành công'
                            })
                            this.closeModalThemMoi()
                            this.$emit('handleSearch')
                        } else {
                            loading.close()
                            this.$message({
                                type: 'error',
                                message: response.message
                            })
                        }
                    })
                    .catch(() => {
                        loading.close()
                    })
            }
        }
    },
    watch: {
        'selectedValue.selectedValue_linhVuc': function (newValue) {
            this.validateLinhVuc()
        },
        'selectedValue.input_maChiSo': function (newValue) {
            this.validateMaChiSo()
        },
        'selectedValue.input_tenChiSo': function (newValue) {
            this.validateTenChiSo()
        },
        'selectedValue.selectedValue_capHoc': function (newValue) {
            this.validateCapHoc()
        },
        'selectedValue.selectedValue_nhomTuoi': function (newValue) {
            this.validateNhomTuoi()
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped>
hr {
    border: 1px solid green;
}
.form label {
    font-weight: bold;
}
.form .span {
    color: red;
}
.form .error-message {
    font-style: italic;
    color: red;
    margin-top: 5px;
}
.custom-date-picker {
    height: 32px;
}
.custom-date-picker::placeholder {
    color: gainsboro;

    font-size: 13px;
    background-color: transparent;
    appearance: none;
}
.layout-btn {
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
}
</style>
