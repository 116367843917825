<template>
    <el-dialog
        :visible="chinhSuaModalVisible"
        @close="closeModalChinhSua"
        width="80%"
    >
        <span slot="title" class="custom-dialog-title">
            CHỈNH SỬA MÔN HỌC
            <hr />
        </span>
        <div class="row form">
            <div class="col-md-3">
                <label>Mã môn học (<span class="span">*</span>)</label>
                <div>
                    <el-input
                        placeholder="Nhập mã môn học ..."
                        v-model="selectedValue.input_maMonHoc"
                        size="small"
                        style="width: 100%"
                        @blur="validateMaMonHoc"
                        @focus="validateMaMonHoc"
                        :disabled="true"
                    >
                    </el-input>
                    <div class="error-message" v-if="errors.maMonHoc">
                        {{ errors.maMonHoc }}
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <label>Tên môn học (<span class="span">*</span>)</label>
                <div>
                    <el-input
                        placeholder="Nhập mã môn học ..."
                        v-model="selectedValue.input_tenMonHoc"
                        size="small"
                        style="width: 100%"
                        @blur="validateTenMonHoc"
                        @focus="validateTenMonHoc"
                    >
                    </el-input>
                    <div class="error-message" v-if="errors.tenMonHoc">
                        {{ errors.tenMonHoc }}
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <label>Tên viết tắt (<span class="span">*</span>)</label>
                <div>
                    <el-input
                        placeholder="Nhập mã môn học ..."
                        v-model="selectedValue.input_tenVietTat"
                        size="small"
                        style="width: 100%"
                        @blur="validateTenVietTat"
                        @focus="validateTenVietTat"
                    >
                    </el-input>
                    <div class="error-message" v-if="errors.tenVietTat">
                        {{ errors.tenVietTat }}
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <label>Loại đánh giá (<span class="span">*</span>)</label>
                <div>
                    <el-select
                        v-model="selectedValue.selectedValue_danhGia"
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                        :no-data-text="'Không có bản ghi nào'"
                        @input="handleChange_danhGia"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelect_danhGia"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        />
                    </el-select>
                    <div class="error-message" v-if="errors.danhGia">
                        {{ errors.danhGia }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-5 form">
            <div class="col-md-3">
                <label>Trạng thái</label>
                <div class="mt-2">
                    <el-select
                        v-model="selectedValue.selectedValue_trangThai"
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                        :no-data-text="'Không có bản ghi nào'"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelect_trangThai"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-md-3">
                <label>Cấp học (<span class="span">*</span>)</label>
                <div class="mt-2">
                    <el-checkbox-group
                        v-model="selectedValue.checkboxList_capHoc"
                    >
                        <el-checkbox label="Cấp 1"></el-checkbox>
                        <el-checkbox label="Cấp 2"></el-checkbox>
                        <el-checkbox label="Cấp 3"></el-checkbox>
                    </el-checkbox-group>
                </div>
            </div>
            <div class="col-md-3">
                <label>Tính chất môn </label>
                <div class="mt-2 row">
                    <div class="col-md-6">
                        <el-checkbox v-model="selectedValue.checkbox_tiengDanToc"
                            >Tiếng dân tộc</el-checkbox
                        >
                        <el-checkbox v-model="selectedValue.checkbox_ngoaiNgu"
                            >Ngoại ngữ</el-checkbox
                        >
                        <el-checkbox v-model="selectedValue.checkbox_mienGiam"
                            >Miễn giảm</el-checkbox
                        >
                    </div>
                    <div class="col-md-6">
                        <el-checkbox v-model="selectedValue.checkbox_thucHanh"
                            >Thực hành</el-checkbox
                        >
                        <el-checkbox v-model="selectedValue.checkbox_monNghe"
                            >Môn nghề</el-checkbox
                        >
                    </div>
                </div>
            </div>
            <div
                v-if="selectedValue.checkbox_monNghe === true"
                class="col-md-3 mt-4"
            >
                <el-select
                    v-model="selectedValue.selectedValue_nhomNghe"
                    collapse-tags
                    clearable
                    filterable
                    placeholder="Chọn"
                    size="small"
                    style="width: 100%"
                    :no-data-text="'Không có bản ghi nào'"
                >
                    <el-option
                        v-for="item in getDataESelect.ESelect_nhomNghe"
                        :key="item.value"
                        :label="item.title"
                        :value="item.value"
                    />
                </el-select>
            </div>
        </div>
        <div class="layout-btn">
            <div>
                <CustomButton
                    label="Đóng"
                    size="small"
                    type="info"
                    @click="closeModalChinhSua"
                />
            </div>
            <div style="margin-left: 10px">
                <CustomButton
                    label="Chỉnh sửa"
                    size="small"
                    type="success"
                    @click="handleChinhSua"
                />
            </div>
        </div>
    </el-dialog>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import { mapState } from 'vuex'
import sendRequest from '@/services'
import { validateInput } from '@/utils'
import {
    ESelectEvaluate_MonHoc,
    ESelectCupationalGroup_MonHoc
} from '@/mock_data/index'
import Api from '@/constants/Api'
export default {
    name: 'ModalThemMoiTruongHoc',
    props: {
        chinhSuaModalVisible: {
            type: Boolean,
            required: true
        },
        closeModalChinhSua: {
            type: Function,
            required: true
        },
        dataModalChinhSua: { Object, require: true },
        handleSearch: Function
    },

    components: { CustomButton },
    data() {
        return {
            errors: {
                maMonHoc: null,
                tenMonHoc: null,
                tenVietTat: null,
                danhGia: null
            },
            submitted: false, // Thêm biến submitted
            getDataESelect: {
                ESelect_danhGia: ESelectEvaluate_MonHoc,
                ESelect_nhomNghe: ESelectCupationalGroup_MonHoc,
                ESelect_trangThai: [
                    {
                        title: 'Đang hoạt động',
                        value: 1
                    },
                    {
                        title: 'Ngưng hoạt động',
                        value: 0
                    }
                ]
            },
            selectedValue: {
                input_maMonHoc: '',
                input_tenMonHoc: '',
                input_tenVietTat: '',
                selectedValue_danhGia: '',
                selectedValue_trangThai: '',
                checkboxList_capHoc: [],
                checkbox_tiengDanToc: false,
                checkbox_ngoaiNgu: false,
                checkbox_mienGiam: false,
                checkbox_thucHanh: false,
                checkbox_monNghe: false,
                selectedValue_nhomNghe: ''
            }
        }
    },

    methods: {
        handleChange_danhGia() {
            this.validateDanhGia()
        },
        validateDanhGia() {
            if (this.submitted) {
                if (this.selectedValue.selectedValue_danhGia === '') {
                    this.errors.danhGia = 'Vui lòng chọn đánh giá'
                } else {
                    this.errors.danhGia = ''
                }
            }
        },
        validateMaMonHoc() {
            if (this.submitted) {
                this.errors.maMonHoc = validateInput(
                    'text',
                    this.selectedValue.input_maMonHoc
                )
            }
        },
        validateTenMonHoc() {
            if (this.submitted) {
                this.errors.tenMonHoc = validateInput(
                    'text',
                    this.selectedValue.input_tenMonHoc
                )
            }
        },
        validateTenVietTat() {
            if (this.submitted) {
                this.errors.tenVietTat = validateInput(
                    'text',
                    this.selectedValue.input_tenVietTat
                )
            }
        },

        handleChinhSua() {
            const ma = this.selectedValue.input_maMonHoc
            const ten = this.selectedValue.input_tenMonHoc
            const tenVietTat = this.selectedValue.input_tenVietTat
            const trangThai = this.selectedValue.selectedValue_trangThai
            const selectedValues_checkbox_capHoc =
                this.selectedValue.checkboxList_capHoc.map((item) => {
                    return item.replace('Cấp ', '')
                })

            const caphoc = selectedValues_checkbox_capHoc.join('')

            const tiengDanToc = this.convertBooleanToNumber(
                this.selectedValue.checkbox_tiengDanToc
            )
            const ngoaiNgu = this.convertBooleanToNumber(
                this.selectedValue.checkbox_ngoaiNgu
            )
            const monNghe = this.convertBooleanToNumber(
                this.selectedValue.checkbox_monNghe
            )
            const mienGiam = this.convertBooleanToNumber(
                this.selectedValue.checkbox_mienGiam
            )
            const thucHanh = this.convertBooleanToNumber(
                this.selectedValue.checkbox_thucHanh
            )
            const loaiDanhGia = this.selectedValue.selectedValue_danhGia
            const nhomNghe = this.selectedValue.selectedValue_nhomNghe
            this.submitted = true
            this.validateMaMonHoc()
            this.validateTenMonHoc()
            this.validateTenVietTat()
            this.validateDanhGia()
            if (caphoc === '') {
                this.$message({
                    message: 'Vui lòng chọn cấp học',
                    type: 'error'
                })
                return
            }
            // Kiểm tra nếu không có lỗi thì tiếp tục thực hiện hành động xác thực
            if (
                !this.errors.maMonHoc &&
                !this.errors.tenMonHoc &&
                !this.errors.tenVietTat &&
                !this.errors.danhGia
            ) {
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })

                this.$confirm('Xác nhận chỉnh sửa ?', 'Thông báo', {
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy',
                    type: 'warning'
                })
                    .then(async () => {
                        const request_Header = {
                            token: this.authToken
                        }
                        const request_Data = {
                            ma: ma,
                            ten: ten,
                            tenVietTat: tenVietTat,
                            tiengDanToc: tiengDanToc,
                            ngoaiNgu: ngoaiNgu,
                            monNghe: monNghe,
                            trangThai: trangThai,
                            nhomNghe: nhomNghe,
                            loaiDanhGia: loaiDanhGia,
                            mienGiam: mienGiam,
                            thucHanh: thucHanh,
                            capHoc: caphoc
                        }
                        const response = await sendRequest(
                            Api.superAdmin.monHoc.chinhSuaMonHoc,
                            request_Data,

                            request_Header
                        )
                        if (response.code == 200) {
                            this.$message({
                                type: 'success',
                                message: 'Chỉnh sửa thành công'
                            })
                            this.closeModalChinhSua()
                            this.$emit('handleSearch')
                        } else {
                            loading.close()
                            this.$message({
                                type: 'error',
                                message: response.message
                            })
                        }
                    })
                    .catch(() => {
                        loading.close()
                    })
            }
        },
        convertCapHocStringToArray(capHocString) {
            const capHocArray = capHocString
                .split('')
                .map((item) => 'Cấp ' + item)
            return capHocArray
        },
        convertNumberToBoolean(value) {
            return value === 1 ? true : false
        },
        convertBooleanToNumber(value) {
            return value ? 1 : 0
        }
    },
    watch: {
        dataModalChinhSua(newValue) {
            this.selectedValue.input_maMonHoc = newValue.maMonHoc
            this.selectedValue.input_tenMonHoc = newValue.tenMonHoc
            this.selectedValue.input_tenVietTat = newValue.tenVietTat
            this.selectedValue.selectedValue_trangThai = newValue.trangThai
            this.selectedValue.selectedValue_danhGia =
                newValue.loaiDanhGia.toString()
            this.selectedValue.checkboxList_capHoc =
                this.convertCapHocStringToArray(newValue.capHoc)
            this.selectedValue.checkbox_tiengDanToc = this.convertNumberToBoolean(
                newValue.tiengDanToc
            )
            this.selectedValue.checkbox_ngoaiNgu = this.convertNumberToBoolean(
                newValue.ngoaiNgu
            )
            this.selectedValue.checkbox_mienGiam = this.convertNumberToBoolean(
                newValue.mienGiam
            )
            this.selectedValue.checkbox_thucHanh = this.convertNumberToBoolean(
                newValue.thucHanh
            )
            this.selectedValue.checkbox_monNghe = this.convertNumberToBoolean(
                newValue.monNghe
            )
            this.selectedValue.selectedValue_nhomNghe =
                newValue.nhomNghe.toString()
        },
        'selectedValue.input_maMonHoc': function (newValue) {
            this.validateMaMonHoc()
        },
        'selectedValue.input_tenMonHoc': function (newValue) {
            this.validateTenMonHoc()
        },
        'selectedValue.input_tenVietTat': function (newValue) {
            this.validateTenVietTat()
        },

        'selectedValue.selectedValue_danhGia': function (newValue) {
            this.validateDanhGia()
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped>
::v-deep .el-input.is-disabled .el-input__inner {
    color: black !important;
}
hr {
    border: 1px solid green;
}
.form label {
    font-weight: bold;
}
.form .span {
    color: red;
}
.form .error-message {
    font-style: italic;
    color: red;
    margin-top: 5px;
}
.custom-date-picker {
    height: 32px;
}
.custom-date-picker::placeholder {
    color: gainsboro;

    font-size: 13px;
    background-color: transparent;
    appearance: none;
}
.layout-btn {
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
}
</style>
