<template>
    <div>
        <div
            class="header"
            :class="{
                'fixed-header': isFixedHeader
            }"
        >
            <div class="header-layout">
                <div class="header-left">
                    <div :style="{ width: isCollapseLeftBar ? '64px' : '300px' }">
                        <a class="logo" v-if="!isCollapseLeftBar">
                            <div class="logo-content">
                                <div>
                                    <img
                                        src="../assets/img/logo_new.png"
                                        class="img-logo-main"
                                    />
                                </div>
                                <div class="logo-text">
                                    Hệ thống cơ sở <br />
                                    dữ liệu ngành
                                </div>
                            </div>
                        </a>
                        <a v-else>
                            <div>
                                <img
                                    src="../assets/img/logo_new.png"
                                    style="width: 100%"
                                />
                            </div>
                        </a>

                        <!-- <button @click="toggleCollapse">
                            <div class="border">
                                <span v-if="!isCollapseLeftBar">
                                    <i class="el-icon-d-arrow-left"></i>
                                </span>
                                <span v-else>
                                    <i class="el-icon-d-arrow-right"></i>
                                </span>
                            </div>
                        </button> -->
                    </div>
                    <div class="organization-info">
                        Sở giáo dục đào tạo Điện Biên
                    </div>
                </div>
                <div class="header-right">
                    <el-menu
                        :default-active="activeIndex"
                        class="el-menu-demo"
                        mode="horizontal"
                        background-color="#fff"
                        text-color="#ac593d"
                        active-text-color="#ffd04b"
                    >
                        <el-submenu index="2">
                            <template slot="title">
                                <i class="el-icon-s-custom icon"></i>

                                <span class="">{{
                                    authUser?.display_name
                                }}</span></template
                            >
                            <el-menu-item>
                                <a
                                    class="pdf"
                                    href="https://csdl.dienbien.edu.vn/img/Dienbien_Huong_Dan.pdf"
                                    target="_blank"
                                    >Hướng dẫn
                                </a>
                            </el-menu-item>
                            <el-menu-item @click="openModalLichSuDangNhap"
                                >Lịch sử đăng nhập</el-menu-item
                            >
                            <el-menu-item @click="openModalDoiMatKhau"
                                >Đổi mật khẩu</el-menu-item
                            >
                            <el-menu-item @click="handleLogout">
                                Đăng xuất
                            </el-menu-item>
                        </el-submenu>
                    </el-menu>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="Câu hỏi thường gặp"
                        placement="bottom"
                    >
                        ><i
                            class="el-icon-question icon-question"
                            @click="openModalQuestion"
                        ></i>
                    </el-tooltip>
                </div>
            </div>
        </div>
        <ModalLichSuDangNhap
            :lichSuDangNhapModalVisible="lichSuDangNhapModalVisible"
            :closeModalLichSuDangNhap="closeModalLichSuDangNhap"
        />
        <ModalDoiMatKhau
            :doiMatKhauModalVisible="doiMatKhauModalVisible"
            :closeModalDoiMatKhau="closeModalDoiMatKhau"
            @handleLogout="handleLogout"
        />
        <el-dialog
            :visible="questionModalVisible"
            @close="closeModalquestion"
            :fullscreen="true"
        >
            <span slot="title">
                Các câu hỏi thường gặp
                <hr />
            </span>
            <el-collapse v-model="activeNames">
                <el-collapse-item
                    v-for="(item, index) in collapseData"
                    :key="item.id"
                    :name="item.id"
                >
                    <div slot="title" class="custom-title">
                        {{ index + 1 }}. {{ item.cauHoi }}
                    </div>
                    <div class="layout-collapase">
                        <div v-html="item.traLoi"></div>
                    </div>
                </el-collapse-item>
            </el-collapse>
            <div class="close-ques">
                <CustomButton
                    label="Đóng"
                    size="Default"
                    class="btn-close btn-default"
                    @click="closeModalquestion"
                />
            </div>
        </el-dialog>
    </div>
</template>
<script>
import 'element-ui/lib/theme-chalk/index.css'
import { mapMutations, mapState } from 'vuex'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomButton from './CustomButton.vue'
import ModalLichSuDangNhap from './ModalLichSuDangNhap.vue'
import ModalDoiMatKhau from './ModalDoiMatKhau.vue'
import axios from 'axios'

export default {
    name: 'HeaderComponent',
    components: { CustomButton, ModalLichSuDangNhap, ModalDoiMatKhau },
    data() {
        return {
            isFixedHeader: false,
            lichSuDangNhapModalVisible: false,
            doiMatKhauModalVisible: false,
            activeIndex: '1',

            collapseData: [],
            activeNames: ['1'],
            questionModalVisible: false,
            currentDomain: null
        }
    },

    methods: {
        openFilePDF() {},
        openModalLichSuDangNhap() {
            this.lichSuDangNhapModalVisible = true
        },
        closeModalLichSuDangNhap() {
            this.lichSuDangNhapModalVisible = false
        },
        openModalDoiMatKhau() {
            this.doiMatKhauModalVisible = true
        },
        closeModalDoiMatKhau() {
            this.doiMatKhauModalVisible = false
        },
        generateString(length) {
            let characters =
                'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
            let result = ''
            const charactersLength = characters.length
            for (let i = 0; i < length; i++) {
                result += characters.charAt(
                    Math.floor(Math.random() * charactersLength)
                )
            }
            return result
        },
        objectToQueryString(obj) {
            let str = []
            for (let p in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, p)) {
                    str.push(p + '=' + obj[p])
                }
            }
            return str.join('&')
        },
        handleLogout() {
            if (this.authUser.ssoDienBien === true) {
                // axios
                //     .get('https://login.dienbien.gov.vn/oidc/logout', {
                //         params: {
                //             id_token_hint: this.authUser.ssoAccessToken,
                //             post_logout_redirect_uri:
                //                 'https://csdl.dienbien.edu.vn/sso-logout'
                //         }
                //     })
                //     .then((response) => {
                //         console.log(response.data)
                //     })
                //     .catch((error) => {
                //         console.error(error)
                //     })
                localStorage.clear()
                sessionStorage.clear()
                // window.location.reload()
                let params = {
                    id_token_hint: this.authUser.ssoAccessToken,
                    post_logout_redirect_uri:
                        'https://csdl.dienbien.edu.vn/sso-logout'
                }

                let query = this.objectToQueryString(params)
                let urlLogout =
                    'https://login.dienbien.gov.vn/oidc/logout?' + query
                window.location.href = urlLogout
            } else {
                localStorage.clear()
                this.fullscreenLoading = true
                setTimeout(() => {
                    this.fullscreenLoading = false
                    this.$router.push('/login')
                }, 1000)
            }
        },
        toggleCollapse() {
            this.$store.commit('setIsCollapse', !this.isCollapseLeftBar)
        },
        openModalQuestion() {
            this.questionModalVisible = true
        },
        closeModalquestion() {
            this.questionModalVisible = false
        },

        handleScroll() {
            const header = document.querySelector('.header')
            const headerHeight = header.offsetHeight
            const scrollTop =
                window.pageYOffset || document.documentElement.scrollTop

            this.isFixedHeader = scrollTop > headerHeight
        },

        async getDataDanhSachCauHoi() {
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    start: 0,
                    limit: 1000
                }
                const response = await sendRequest(
                    Api.superAdmin.quanLyCauHoiThuongGap.danhSach,
                    null,
                    request_Header,
                    request_Params
                )
                this.collapseData = response.rows
            } catch (error) {
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        }
    },

    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),

        ...mapState(['activeThemeIcon']),

        isCollapsed() {
            return this.leftBarCollapsed
        },
        activeThemeIcon() {
            return this.$store.state.activeThemeIcon
        },
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        },

        ...mapState(['isCollapseLeftBar'])
    },
    mounted() {
        let currentDomain = window.location.hostname
        this.currentDomain = currentDomain

        window.addEventListener('scroll', this.handleScroll)
        this.getDataDanhSachCauHoi()
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll)
    }
}
</script>
<style scoped>
.header {
    background-color: #6aab9c;
    position: fixed;
    top: 0;
    height: 60px;

    z-index: 1001;
    width: 100%;
    transition: top 0.2s ease-in-out;
}

.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.header .header-layout {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #a4a175;
}
.border {
    background-color: #fff;
    padding: 3px 9px 3px 7px;
    border-radius: 50%;
}
.header .header-left {
    background: #fff;
    float: left;
    height: 60px;

    position: relative;
    text-align: left;

    z-index: 1;
    transition: all 0.2s ease-in-out;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: space-between;
}

.logo-content {
    display: flex;
    align-items: center;
}
.logo-text {
    margin-left: 10px;
    text-transform: uppercase;
    color: #ac593d;
    font-weight: 700;
}
.header .header-left .logo {
    display: inline-block;
}

.header .header-left .logo img {
    max-height: 70px;
    object-fit: cover;
    width: auto;
}
.organization-info {
    text-transform: uppercase;

    padding: 17.5px 30px;
    color: #ac593d;
    font-weight: 700;
    border-left: 0.5px solid #a4a175;
}
.header .header-left button {
    border: none;
    background: none;
    padding: 0;
    margin: 5px 10px 5px 10px;
}
.header-left .logo.logo-small {
    display: none;
}
.header-left .logo.logo.logo-small img {
    width: 30px;
    height: 30px;
}
.header-right {
    padding-right: 20px;
    display: flex;
    align-items: center;
}
.icon-question {
    color: #ac593d;
}
.img-logo-main {
    margin-top: 5px;
}
::v-deep .el-icon-d-arrow-left:before,
.el-icon-d-arrow-right::before {
    color: #1e6050;
    font-weight: 700;
}
::v-deep .el-collapse-item__header {
    margin: 10px !important;
    border-radius: 4px !important;
    background-color: #f5f5f5 !important;
    border-color: #ddd !important;
}
.custom-title {
    font-size: 18px;
    color: blue;
    font-weight: 700;
    font-style: italic;
}
.layout-collapase {
    padding: 10px;
}
.btn-close,
.btn-close:hover {
    background-color: var(--orange);
    color: var(--btn-text-color);
}

.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.close-ques {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}
.pdf {
    color: #ac593d;
    text-decoration: none;
}
@media (max-width: 768px) {
    .header .header-left button {
        display: none;
    }
    div[style] {
        width: 100% !important;
    }
    .organization-info {
        display: none;
    }
    .logo-text {
        display: none;
    }
}
</style>
