<template>
    <el-dialog
        :visible="themMoiModalVisible"
        @close="closeModalThemMoi"
        width="60%"
    >
        <span slot="title" class="custom-dialog-title">
            THÊM LÝ DO BỎ HỌC
            <hr />
        </span>
        <div class="row form">
            <div class="col-md-12">
                <div class="row d-flex align-items-center justify-content-center">
                    <div class="col-md-3">
                        <label>Mã lý do (<span class="span">*</span>)</label>
                    </div>
                    <div class="col-md-6">
                        <el-input
                            placeholder="Nhập mã lý do ..."
                            v-model="selectedValue.input_maLyDo"
                            size="small"
                            style="width: 100%"
                            @blur="validateMaLyDo"
                            @focus="validateMaLyDo"
                        >
                        </el-input>
                        <div class="error-message" v-if="errors.maLyDo">
                            {{ errors.maLyDo }}
                        </div>
                    </div>
                </div>
                <div
                    class="row d-flex align-items-center justify-content-center mt-4"
                >
                    <div class="col-md-3">
                        <label>Tên lý do(<span class="span">*</span>)</label>
                    </div>
                    <div class="col-md-6">
                        <el-input
                            placeholder="Nhập tên lý do ..."
                            v-model="selectedValue.input_tenLyDo"
                            size="small"
                            style="width: 100%"
                            @blur="validateTenLyDo"
                            @focus="validateTenLyDo"
                        >
                        </el-input>
                        <div class="error-message" v-if="errors.tenLyDo">
                            {{ errors.tenLyDo }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="layout-btn">
            <div>
                <CustomButton
                    label="Đóng"
                    size="small"
                    type="info"
                    @click="closeModalThemMoi"
                />
            </div>
            <div style="margin-left: 10px">
                <CustomButton
                    label="Thêm mới"
                    size="small"
                    type="success"
                    @click="handleThemMoi"
                />
            </div>
        </div>
    </el-dialog>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import { mapState } from 'vuex'
import sendRequest from '@/services'
import { validateInput } from '@/utils'

import Api from '@/constants/Api'
export default {
    name: 'ModalThemMoiTruongHoc',
    props: {
        themMoiModalVisible: {
            type: Boolean,
            required: true
        },
        closeModalThemMoi: {
            type: Function,
            required: true
        },

        handleSearch: Function
    },

    components: { CustomButton },
    data() {
        return {
            errors: {
                maLyDo: null,
                tenLyDo: null,
                loaiDanToc: null
            },
            submitted: false, // Thêm biến submitted

            selectedValue: {
                input_maLyDo: '',
                input_tenLyDo: ''
            }
        }
    },

    methods: {
        validateMaLyDo() {
            if (this.submitted) {
                this.errors.maLyDo = validateInput(
                    'text',
                    this.selectedValue.input_maLyDo
                )
            }
        },
        validateTenLyDo() {
            if (this.submitted) {
                this.errors.tenLyDo = validateInput(
                    'text',
                    this.selectedValue.input_tenLyDo
                )
            }
        },

        handleThemMoi() {
            this.submitted = true
            this.validateMaLyDo()
            this.validateTenLyDo()

            // Kiểm tra nếu không có lỗi thì tiếp tục thực hiện hành động xác thực
            if (!this.errors.maLyDo && !this.errors.tenLyDo) {
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })

                this.$confirm('Xác nhận thêm mới ?', 'Thông báo', {
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy',
                    type: 'warning'
                })
                    .then(async () => {
                        const request_Header = {
                            token: this.authToken
                        }
                        const request_Data = {
                            ma: this.selectedValue.input_maLyDo,
                            ten: this.selectedValue.input_tenLyDo,
                            trangThai: null
                        }
                        const response = await sendRequest(
                            Api.superAdmin.lyDoBoHoc.themMoiLyDoBoHoc,
                            request_Data,

                            request_Header
                        )
                        if (response.code == 200) {
                            this.$message({
                                type: 'success',
                                message: 'Thêm mới thành công'
                            })
                            this.closeModalThemMoi()
                            this.$emit('handleSearch')
                        } else {
                            loading.close()
                            this.$message({
                                type: 'error',
                                message: response.message
                            })
                        }
                    })
                    .catch(() => {
                        loading.close()
                    })
            }
        }
    },
    watch: {
        'selectedValue.input_maLyDo': function (newValue) {
            this.validateMaLyDo()
        },
        'selectedValue.input_tenLyDo': function (newValue) {
            this.validateTenLyDo()
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped>
hr {
    border: 1px solid green;
}
.form label {
    font-weight: bold;
}
.form .span {
    color: red;
}
.form .error-message {
    font-style: italic;
    color: red;
    margin-top: 5px;
}
.custom-date-picker {
    height: 32px;
}
.custom-date-picker::placeholder {
    color: gainsboro;

    font-size: 13px;
    background-color: transparent;
    appearance: none;
}
.layout-btn {
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
}
</style>
