<template>
    <div>
        <CustomBreadcrumb
            :title="'TIẾP NHẬN DỮ LIỆU QLNT'"
            :content="'LỊCH SỬ TIẾP NHẬN DỮ LIỆU'"
        />

        <CustomContentUI>
            <div class="row">
                <div class="col-4">
                    <label>Đơn vị quản lý</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="
                                this.authUser.role === 5 ||
                                this.authUser.role === 4
                            "
                            :data="this.dataChonDonVi_Store"
                            :placeholder="'Chọn đơn vị quản lý'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenDonVi', 'maDonVi']"
                            :value="selectedValue.selectedValueUnitEducation"
                            @change="
                                handleESelectChange(
                                    'ESelectUnitEducation',
                                    ...$event
                                )
                            "
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Cấp học</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectGradeLevel"
                            :placeholder="'Chọn cấp học'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenTruongHoc', 'value']"
                            :value="selectedValue.selectedValueGradeLevel"
                            @change="
                                handleESelectChange(
                                    'ESelectGradeLevel',
                                    ...$event
                                )
                            "
                        />
                    </div>
                </div>
                <div class="col-4">
                    <label>Trường học <sup style="color: red">*</sup></label>
                    <div>
                        <ESelect
                            :reset="resetESelectSchool"
                            @reset-completed="handleResetCompleted"
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="this.authUser.role === 5"
                            :data="getDataESelect.ESelectSchool"
                            :placeholder="'Chọn trường học'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['title', 'value']"
                            :value="selectedValue.selectedValueSchool"
                            @change="
                                handleESelectChange(
                                    'ESelectSchool_MockData',
                                    ...$event
                                )
                            "
                        />
                        <div v-if="err_maTruong_Change.length > 0" class="error">
                            {{ err_maTruong_Change }}
                        </div>
                    </div>
                </div>

                <div class="col-2">
                    <label>Năm học <sup style="color: red">*</sup></label>
                    <div>
                        <ESelectYear
                            v-model="selectedValue.selectedValueSchoolYear"
                            placeholder="Chọn năm"
                            size="small"
                            :no-data-text="'Không có bản ghi nào'"
                            :no-match-text="'Không tìm thấy bản ghi nào'"
                        >
                        </ESelectYear>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 20px">
                <div class="col-2">
                    <label>Ngày nhận dữ liệu</label>
                    <div>
                        <el-date-picker
                            style="width: 100%"
                            size="small"
                            type="date"
                            placeholder="Chọn ngày"
                            v-model="selectedValue.selectedValueDate"
                            format="dd/MM/yyyy"
                        >
                        </el-date-picker>
                    </div>
                </div>
                <div class="col-2">
                    <label class="label">Mã lần đẩy</label>
                    <div>
                        <el-input
                            placeholder="Nhập ..."
                            v-model="selectedValue.inputPasCode"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="col-2">
                    <label>Đối tác</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            v-model="selectedValue.selectedValuePartner"
                            :data="
                                transformESelect(getDataESelect.ESelectPartner)
                            "
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['title', 'value']"
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Trạng thái</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            v-model="selectedValue.selectedValueSyncState"
                            :data="getDataESelect.ESelectSyncState_MockData"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['tenTrangThai', 'value']"
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Loại đồng bộ</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            v-model="selectedValue.selectedValueSynchronouStype"
                            :data="getDataESelect.ESelectSynchronouStype_MockData"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['title', 'value']"
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Loại đợt</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            v-model="selectedValue.selectedValueBatchType"
                            :data="getDataESelect.ESelectBatchType_MockData"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['tenLoaiDot', 'value']"
                        />
                    </div>
                </div>
            </div>
            <div class="layout-btn-search">
                <CustomButton
                    label="Tìm kiếm"
                    size="small"
                    class="btn-default bg-search"
                    @click="handleSearch"
                />
            </div>
        </CustomContentUI>
        <div
            class="layout-options"
            :style="{
                'max-width': leftBarWidth()
            }"
        >
            <div>
                <chonSoLuongBanGhi @chonXongSoLuong="ChonSoLuongBanGhi($event)" />
            </div>
        </div>
        <CustomTable :maxWidth="leftBarWidth()" :columns="tableColumns">
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="11" class="text-tbody">
                            Không có bản ghi nào.
                        </td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td class="text-tbody">
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>
                    <td class="text-tbody" style="text-align: left">
                        {{ item.reqMessId }}
                    </td>
                    <td class="text-tbody">{{ item.tenDoitac }}</td>
                    <td class="text-tbody" style="text-align: left">
                        {{ item.tenTruongHoc }}
                    </td>
                    <td class="text-tbody">{{ item.maTruongHoc }}</td>
                    <td class="text-tbody">
                        {{ item.timeDay }}
                    </td>
                    <td class="text-tbody">{{ item.timePhanHoi }}</td>
                    <td class="text-tbody">
                        {{ getLoaiDuLieu(item.loaiDuLieu) }}
                    </td>
                    <td class="text-tbody">
                        {{ item.namHoc }} - {{ item.namHoc + 1 }}
                    </td>
                    <td class="text-tbody">
                        {{ getLoaiDot(item.loaiDot) }}
                    </td>

                    <td class="text-tbody">
                        <CustomButton
                            label=""
                            icon="el-icon-info"
                            class="btn-info"
                            @click="ModalChitiet(item.maGiaoDich)"
                            size="small"
                        />
                    </td>
                </tr>
            </template>
        </CustomTable>
        <CustomPagination
            v-show="total_rows > 0"
            :tongbanghi="total_rows"
            :soluonghienthi="soLuongBanGhiHienThi"
            :batdau="trangbatdau"
            @pageChange="layLai($event)"
        >
        </CustomPagination>

        <!-- ************ Modal Chi tiết ************ -->
        <el-dialog
            :visible.sync="chiTietModalVisible"
            width="100%"
            height="100%"
            @close="closeModalChitiet"
            :fullscreen="true"
        >
            <div slot="title" class="custom-dialog-title">
                {{ getDot(parseInt(tableDataModal_Chitiet.loaiDot)) }}: TRƯỜNG
                {{ tableDataModal_Chitiet.tenTruongHoc }}
                (MÃ:
                {{ tableDataModal_Chitiet.maTruongHoc }}) - Năm học:{{
                    tableDataModal_Chitiet.namHoc
                }}-{{ tableDataModal_Chitiet.namHoc + 1 }}
            </div>
            <hr />
            <div style="padding-left: 20px; padding-right: 20px">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="dflex">
                            <div style="color: #606266">
                                {{ tableDataModal_Chitiet.maDoiTac }}
                            </div>
                        </div>
                        <div class="dflex">
                            <div class="span">Mã lần đẩy:</div>
                            <div>{{ tableDataModal_Chitiet.reqMessId }}</div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="dflex">
                            <div class="span">Duration time phản hồi:</div>
                            <div>{{ tableDataModal_Chitiet.timePhanHoi }}</div>
                        </div>
                        <div class="dflex">
                            <div class="span">Dữ liệu đẩy lên:</div>
                            <div>
                                <CustomButton
                                    label="Xem chi tiết"
                                    size="mini"
                                    class="btn-info"
                                    @click="
                                        ModalDulieuDaylen_Chitiet(
                                            tableDataModal_Chitiet.maGiaoDich
                                        )
                                    "
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="dflex">
                            <div class="span">Đợt đồng bộ:</div>
                            <div>
                                {{
                                    getLoaiDuLieu(
                                        tableDataModal_Chitiet.loaiDuLieu
                                    )
                                }}
                            </div>
                        </div>
                        <div class="dflex">
                            <div class="span">Dữ liệu nhận được:</div>

                            <div>
                                <CustomButton
                                    label="Xem chi tiết"
                                    size="mini"
                                    class="btn-info"
                                    @click="
                                        ModalDulieuNhanDuoc_Chitiet(
                                            tableDataModal_Chitiet.maGiaoDich
                                        )
                                    "
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="layout-table-modal">
                    <table
                        class="table table-bordered table-hover centered-table"
                    >
                        <thead>
                            <tr>
                                <th class="text-thead">STT</th>
                                <th class="text-thead">Tên dữ liệu</th>
                                <th class="text-thead">Nhận thành công</th>
                                <th class="text-thead">Nhận thất bại</th>
                                <th class="text-thead">Kết quả nhận</th>
                                <th class="text-thead">Đồng bộ thành công</th>
                                <th class="text-thead">Đồng bộ thất bại</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="tableData.length === 0">
                                <tr>
                                    <td colspan="11" class="text-tbody">
                                        Không có bản ghi nào.
                                    </td>
                                </tr>
                            </template>
                            <tr
                                v-for="(
                                    item, key
                                ) in tableDataModal_Chitiet.listDetail"
                                :key="key"
                            >
                                <td class="text-tbody">
                                    {{ key + 1 }}
                                </td>
                                <td class="text-tbody" style="text-align: left">
                                    {{ getTableName(item.tableName) }}
                                </td>
                                <td class="text-tbody">
                                    {{ item.numRecordRecievedSuccess }}
                                </td>
                                <td class="text-tbody">
                                    {{ item.numRecordRecievedError }}
                                </td>
                                <td class="text-tbody">
                                    <span v-if="item.numRecordRecievedError != 0">
                                        <CustomButton
                                            label=""
                                            size="small"
                                            type="primary"
                                            icon="el-icon-info"
                                            @click="
                                                ModalKetQuaNhan_ChiTiet(
                                                    item.id,
                                                    item.subId
                                                )
                                            "
                                        />
                                    </span>
                                    <span></span>
                                </td>
                                <td class="text-tbody">
                                    {{ item.numRecordSyncSuccess }}
                                </td>
                                <td class="text-tbody">
                                    {{ item.numRecordSyncError }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div slot="footer">
                    <hr />
                    <div class="layout-btn-close">
                        <CustomButton
                            label="Đóng"
                            size="small"
                            class="btn-close"
                            @click="closeModalChitiet"
                        />
                    </div>
                </div>
            </div>
        </el-dialog>

        <!-- ************ Modal Dữ liệu đẩy lên - Chi tiết ************ -->
        <el-dialog
            :visible.sync="duLieuDayLen_ChitietModalVisible"
            width="50%"
            @close="closeModalDuLieuDayLen_Chitiet"
            class="custom-dialog"
        >
            <span slot="title" class="custom-dialog-title">
                Chi tiết (Dữ liệu đẩy lên)
            </span>

            <textarea
                class="language-json myTextArea"
                style="width: 100%; margin: 0 auto; height: 50vh"
                v-model="data_DuLieuDayLenChiTiet"
                id="myTextArea"
                cols="50"
                rows="10"
            ></textarea>
        </el-dialog>

        <!-- ************ Modal Dữ liệu Nhận được - Chi tiết ************ -->
        <el-dialog
            :visible.sync="dulieuNhanDuoc_ChitietModalVisible"
            width="50%"
            @close="closeModalDulieuNhanDuoc_ChitietVisible"
            class="custom-dialog"
        >
            <span slot="title" class="custom-dialog-title">
                Chi tiết (Dữ liệu nhận được)
            </span>

            <textarea
                class="form-control"
                v-model="data_DuLieuNhanDuocChiTiet"
                style="height: 300px"
                rows="3"
            ></textarea>
        </el-dialog>

        <!-- ************ Modal Kết quả nhận -  Chi tiết ************ -->
        <el-dialog
            :visible.sync="ketQuaNhan_ChitietModalVisible"
            width="60%"
            @close="closeModalKetQuaNhan_Chitiet"
            class="custom-dialog"
        >
            <span slot="title" class="custom-dialog-title"> Chi tiết </span>
            <hr />
            <span>
                <div class="layout-table-modal">
                    <table
                        class="table table-bordered table-hover centered-table"
                    >
                        <thead>
                            <tr>
                                <th class="text-thead">STT</th>
                                <th class="text-thead">Mã học sinh</th>
                                <th class="text-thead">Trạng thái</th>
                                <th class="text-thead">Mô tả</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, i) in data_KetQuaNhan_Chitiet"
                                :key="i"
                            >
                                <td class="text-tbody">{{ i + 1 }}</td>
                                <td class="text-tbody">
                                    <div>{{ item.MaHocSinh }}</div>
                                </td>
                                <td class="text-tbody">
                                    {{
                                        parseInt(item.Status) === 1
                                            ? 'Thành công'
                                            : 'Thất bại'
                                    }}
                                </td>
                                <td class="text-tbody">
                                    {{ item.Message }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </span>
            <hr />
        </el-dialog>
    </div>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import ESelectYear from '@/components/ESelectYear.vue'
import chonSoLuongBanGhi from '@/components/chonSoLuongBanGhi.vue'
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import CustomTable from '@/components/CustomTable.vue'
import CustomContentUI from '@/components/CustomContentUI.vue'
import moment from 'moment'
import Api from '@/constants/Api'

import {
    ESelectBatchType_MockData,
    ESelectGradeLevel_MockData,
    ESelectSyncState_MockData,
    ESelectSynchronouStype_MockData,
    TableName_LichSuTiepNhanDuLieu
} from '@/mock_data'
import sendRequest from '@/services'
import { mapState } from 'vuex'
import { convertCapHocList, currentYear, filterCapHocList } from '@/utils'
import { validateField } from '@/utils'
export default {
    name: 'lichSuTiepNhanDuLieu',
    components: {
        ESelect,
        CustomButton,
        ESelectYear,
        CustomTable,
        CustomBreadcrumb,
        chonSoLuongBanGhi,
        CustomPagination,
        CustomContentUI
    },
    data() {
        return {
            resetESelectSchool: false,
            tableColumns: [
                { key: 'stt', label: 'STT' },
                { key: 'reqMessId', label: 'Mã lần đẩy' },
                { key: 'tenDoitac', label: 'Đối tác' },
                { key: 'tenTruongHoc', label: 'Trường học' },
                { key: 'maTruongHoc', label: 'Mã trường' },
                { key: 'timeDay', label: 'Thời gian nhận' },
                { key: 'timePhanHoi', label: 'Thời gian xử lí' },
                { key: 'loaiDuLieu', label: 'Loại đồng bộ' },
                { key: 'namHoc', label: 'Năm đồng bộ' },
                { key: 'loaiDot', label: 'Loại đợt' },
                { key: 'chiTiet', label: 'Chi tiết' }
            ],
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            soLuongBanGhiHienThi: 25,
            trangbatdau: false,

            tableData: [],
            tableDataModal_Chitiet: {},

            data_KetQuaNhan_Chitiet: [],
            data_DuLieuDayLenChiTiet: {},
            data_DuLieuNhanDuocChiTiet: {},
            chiTietModalVisible: false,
            ketQuaNhan_ChitietModalVisible: false,
            duLieuDayLen_ChitietModalVisible: false,
            dulieuNhanDuoc_ChitietModalVisible: false,
            request_Data: {
                capHoc: [],
                loaiDayDuLieu: '',
                loaiDot: '',
                maDoiTacCha: '',
                maDonVi: [],
                maGiaoDich: '',
                maTruongHoc: [],
                namDayDulieu: '',
                status: '',
                timeDayEnd: '',
                timeDayStart: ''
            },
            request_Params: {
                start: null,
                limit: null
            },
            request_Header: {
                Authorization: ''
            },

            getDataESelect: {
                ESelectUnitEducation: [], //donvi
                ESelectGradeLevel: [], //caphoc
                ESelectSchool: [], //truonghoc
                ESelectShoolYear_MockData: [], //namhoc
                //ngaynhandulieu
                //malanday
                ESelectPartner: [], //doitac
                ESelectSyncState_MockData: ESelectSyncState_MockData, //trangthai
                ESelectSynchronouStype_MockData: ESelectSynchronouStype_MockData, //loaidongbo
                ESelectBatchType_MockData: ESelectBatchType_MockData //loaidot
            },

            selectedValue: {
                selectedValueUnitEducation: [], //donvi
                selectedValueGradeLevel: [], //caphoc
                selectedValueSchool: [], //truonghoc
                selectedValueSchoolYear: '', //namhoc
                selectedValueDate: null, //ngaynhandulieu
                inputPasCode: '', //malanday
                selectedValuePartner: [], //doitac
                selectedValueSyncState: [], //trangthai
                selectedValueSynchronouStype: [], //loaidongbo
                selectedValueBatchType: [] //loaidot
            },
            err_maTruong_Change: ''
        }
    },
    methods: {
        ChonSoLuongBanGhi(e) {
            this.total_rows = 0
            this.soLuongBanGhiHienThi = e.soluong
        },
        checkTruocKhiTim() {
            this.trangbatdau = !this.trangbatdau
        },
        layLai(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        handleResetCompleted() {
            this.resetESelectSchool = false
        },
        closeModalKetQuaNhan_Chitiet() {
            this.ketQuaNhan_ChitietModalVisible = false
        },
        closeModalChitiet() {
            this.chiTietModalVisible = false
        },
        closeModalDuLieuDayLen_Chitiet() {
            this.duLieuDayLen_ChitietModalVisible = false
        },
        closeModalDulieuNhanDuoc_ChitietVisible() {
            this.dulieuNhanDuoc_ChitietModalVisible = false
        },
        transformESelect(data) {
            return data.map((item) => ({
                title: item,
                value: item
            }))
        },
        getTableName(name) {
            const foundItem = TableName_LichSuTiepNhanDuLieu.find(
                (item) => item.value === name
            )
            return foundItem ? foundItem.name : ''
        },
        getLoaiDuLieu(value) {
            const selectedItem = ESelectSynchronouStype_MockData.find(
                (option) => option.value === value
            )
            return selectedItem
                ? selectedItem.title
                : 'Không tìm thấy giá trị tương ứng'
        },
        getLoaiDot(value) {
            const selectedItem = ESelectBatchType_MockData.find(
                (option) => option.value === value
            )
            return selectedItem
                ? selectedItem.tenLoaiDot
                : 'Không tìm thấy giá trị tương ứng'
        },
        getDot(loai) {
            var res = loai
            switch (loai) {
                case 1:
                    res = 'Đẩy thông tin đầu năm'
                    break
                case 2:
                    res = 'Đẩy thông tin phát sinh'
                    break
                case 3:
                    res = 'Đẩy thông tin cuối năm'
                    break
                case 4:
                    res = 'Đồng bộ dữ liệu cũ'
                    break
                default:
                    res = loai
            }
            return res
        },
        async ModalChitiet(id) {
            this.chiTietModalVisible = true // mở modal
            try {
                const request_Params = {
                    id: id
                }
                const request_Header = {
                    token: this.authToken
                }
                const response = await sendRequest(
                    Api.tiepNhanDuLieuQLNT.lichSuTiepNhanDuLieu.chiTiet,
                    null,
                    request_Header,
                    request_Params
                )
                this.tableDataModal_Chitiet = response.item
            } catch (error) {
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },
        async ModalDulieuDaylen_Chitiet(id) {
            this.duLieuDayLen_ChitietModalVisible = true
            try {
                const request_Params = {
                    id: id,
                    type: 1
                }
                const request_Header = {
                    token: this.authToken
                }

                const response = await sendRequest(
                    Api.tiepNhanDuLieuQLNT.lichSuTiepNhanDuLieu
                        .ketQuaNhan_Chitiet,
                    null,
                    request_Header,
                    request_Params
                )
                const convertJsonParse = JSON.parse(response.item.detail)
                this.data_DuLieuDayLenChiTiet = JSON.stringify(
                    convertJsonParse,
                    null,
                    2
                )
            } catch (error) {
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },
        async ModalDulieuNhanDuoc_Chitiet(id) {
            this.dulieuNhanDuoc_ChitietModalVisible = true
            try {
                const request_Params = {
                    id: id,
                    type: 2
                }
                const request_Header = {
                    token: this.authToken
                }
                const response = await sendRequest(
                    Api.tiepNhanDuLieuQLNT.lichSuTiepNhanDuLieu
                        .ketQuaNhan_Chitiet,
                    null,
                    request_Header,
                    request_Params
                )
                const convertJsonParse = JSON.parse(response.item.detail)
                this.data_DuLieuNhanDuocChiTiet = JSON.stringify(
                    convertJsonParse,
                    null,
                    2
                )
            } catch (error) {
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },
        async ModalKetQuaNhan_ChiTiet(id, subId) {
            this.ketQuaNhan_ChitietModalVisible = true
            try {
                const request_Params = {
                    id: id,
                    subId: subId,
                    type: 3
                }
                const request_Header = {
                    token: this.authToken
                }
                const response = await sendRequest(
                    Api.tiepNhanDuLieuQLNT.lichSuTiepNhanDuLieu
                        .ketQuaNhan_Chitiet,
                    null,
                    request_Header,
                    request_Params
                )

                this.data_KetQuaNhan_Chitiet = JSON.parse(response.item.detail)
            } catch (error) {
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },

        handleESelectChange(source, ...selectedOptions) {
            switch (source) {
                case 'ESelectUnitEducation':
                    this.selectedValue.selectedValueUnitEducation =
                        selectedOptions
                    this.resetESelectSchool = true
                    this.selectedValue.selectedValueSchool = []
                    this.getDataESelectSchool()
                    break
                case 'ESelectGradeLevel':
                    this.selectedValue.selectedValueGradeLevel = selectedOptions
                    if (this.authUser.role !== 5) {
                        this.resetESelectSchool = true
                        this.selectedValue.selectedValueSchool = []
                        this.getDataESelectSchool()
                    }
                    break
                case 'ESelectSchoolYear_MockData':
                    this.selectedValue.selectedValueSchoolYear = selectedOptions
                    break
                case 'ESelectSchool_MockData':
                    this.selectedValue.selectedValueSchool = selectedOptions
                    break
            }
        },
        async getDataESelectSchool() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const maDonVi = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueUnitEducation,
                    'selectedValueUnitEducation'
                )

                const capHoc = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueGradeLevel,
                    'selectedValueGradeLevel'
                )

                const request_Header = {
                    ...this.request_Header,
                    token: this.authToken
                }

                const request_Data = {
                    capHoc: capHoc,
                    maDonVi: maDonVi
                }
                const response = await sendRequest(
                    Api.internal_api.dm_School,
                    request_Data,
                    request_Header,
                    null
                )

                this.getDataESelect.ESelectSchool = response.rows.map((item) => ({
                    title: `${item.tenTruongHoc} - [${item.maTruongHoc}]`,
                    value: item.maTruongHoc
                }))

                loading.close()
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },
        async handleSearch() {
            const maTruong_Change = this.customhandleESelectedChange(
                this.selectedValue.selectedValueSchool,
                'selectedValueSchool'
            )

            const fieldsToValidate = [
                {
                    name: 'err_maTruong_Change',
                    value: maTruong_Change,
                    errorField: 'err_maTruong_Change',
                    errorMessage: 'Vui lòng chọn trường học'
                }
            ]
            let isValid = true

            for (const field of fieldsToValidate) {
                isValid = validateField(this, field, isValid)
            }

            if (isValid) {
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading ...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })
                try {
                    const maDonVi_Change = this.customhandleESelectedChange(
                        this.selectedValue.selectedValueUnitEducation,
                        'selectedValueUnitEducation'
                    )
                    const capHoc_Change = this.customhandleESelectedChange(
                        this.selectedValue.selectedValueGradeLevel,
                        'selectedValueGradeLevel'
                    )

                    const namHoc_Change = parseInt(
                        this.selectedValue.selectedValueSchoolYear
                    )

                    const timeDay_Change = this.selectedValue.selectedValueDate
                        ? moment(this.selectedValue.selectedValueDate).format(
                              'DD/MM/YYYY'
                          )
                        : ''

                    const maGiaoDich_Change = this.selectedValue.inputPasCode
                    const maDoiTacCha_Change =
                        this.selectedValue.selectedValuePartner.value || ''

                    const trangThai_Change =
                        this.selectedValue.selectedValueSyncState.value !==
                        undefined
                            ? this.selectedValue.selectedValueSyncState.value
                            : ''

                    const loaiDayDuLieu_Change =
                        this.selectedValue.selectedValueSynchronouStype.value !==
                        undefined
                            ? this.selectedValue.selectedValueSynchronouStype
                                  .value
                            : ''

                    const loaiDot_Change =
                        this.selectedValue.selectedValueBatchType.value !==
                        undefined
                            ? this.selectedValue.selectedValueBatchType.value
                            : ''
                    const start = this.start
                    const limit = this.limit

                    const request_Data = {
                        ...this.request_Data,
                        capHoc: capHoc_Change,
                        loaiDayDuLieu: loaiDayDuLieu_Change,
                        loaiDot: loaiDot_Change,
                        maDoiTacCha: maDoiTacCha_Change,
                        maDonVi: maDonVi_Change,
                        maGiaoDich: maGiaoDich_Change,
                        maTruongHoc: maTruong_Change,
                        namDayDulieu: namHoc_Change || currentYear,
                        status: trangThai_Change,
                        timeDayEnd: timeDay_Change,
                        timeDayStart: timeDay_Change,
                        start: start,
                        limit: limit
                    }

                    const request_Header = {
                        token: this.authToken
                    }

                    const response = await sendRequest(
                        Api.tiepNhanDuLieuQLNT.lichSuTiepNhanDuLieu
                            .lichSuTiepNhanDuLieu,
                        request_Data,
                        request_Header,
                        null
                    )

                    if (response.rc == 0) {
                        loading.close()

                        if (response.rows.length === 0) {
                            this.$message({
                                type: 'error',
                                message: 'Không tìm thấy dữ liệu tra cứu'
                            })
                        }
                        if (response.rows.length !== 0) {
                            this.$message({
                                type: 'success',
                                message: 'Danh sách dữ liệu'
                            })
                        }

                        this.total_rows = response.total

                        this.tableData = response.rows
                    }
                } catch (error) {
                    loading.close()
                    if (error.response?.data.rd) {
                        this.$message({
                            type: 'error',
                            message: error.response?.data.rd
                        })
                    }
                }
            }
        },
        async getDataESelectPartner() {
            try {
                const request_Data = {
                    ...this.request_Data,
                    trangThai: 1
                }
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    ...this.request_Params,
                    start: 0,
                    limit: 9999
                }

                const response = await sendRequest(
                    Api.tiepNhanDuLieuQLNT.lichSuTiepNhanDuLieu.heThongDoiTac,
                    request_Data,
                    request_Header,
                    request_Params
                )
                this.getDataESelect.ESelectPartner = response.rows
            } catch (error) {
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },
        customhandleESelectedChange(options, valueType) {
            switch (valueType) {
                case 'selectedValueUnitEducation':
                case 'selectedValueSchool':
                    return options.map((option) => option.value)
                case 'selectedValueGradeLevel':
                    return options.map((option) => Number(option.value))
                case 'selectedValueStatus':
                    return options.map((option) => option.value).join('')
            }
        },
        leftBarWidth() {
            if (this.isCollapseLeftBar) {
                return 'calc(100vw - 90px)'
            } else {
                return 'calc(100vw - 340px)'
            }
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        },
        ...mapState(['isCollapseLeftBar']),
        ...mapState({
            dataTo_KetChuyenDuLieu: (state) =>
                state.data_Navigate_KetChuyenDuLieu.lichSuTiepNhanDuLieu
        })
    },
    watch: {
        'authUser.role': {
            immediate: true,
            handler(newRole, oldRole) {
                if (newRole === 5) {
                    this.selectedValue.selectedValueUnitEducation = [
                        { value: this.authUser.ma_don_vi }
                    ]
                    const convertedList = convertCapHocList(
                        this.authUser.capHocList
                    )
                    this.selectedValue.selectedValueGradeLevel = convertedList
                    this.selectedValue.selectedValueSchool = [
                        { value: this.authUser.ma_truong_hoc }
                    ]
                }
                if (newRole === 4) {
                    this.selectedValue.selectedValueUnitEducation = [
                        { value: this.authUser.ma_don_vi }
                    ]
                }
                this.getDataESelect.ESelectGradeLevel = filterCapHocList(
                    this.authUser.capHocList
                )
            }
        },
        dataTo_KetChuyenDuLieu: {
            immediate: true,
            handler(newValues) {
                if (newValues?.maTruongHoc && newValues) {
                    this.selectedValue.inputPasCode = newValues?.id
                    this.selectedValue.selectedValueSchoolYear =
                        newValues?.namHoc.toString()
                    this.selectedValue.selectedValueSchool = [
                        { value: newValues.maTruongHoc }
                    ]
                }
            }
        }
    },
    mounted() {
        this.getDataESelectPartner()
        this.getDataESelectSchool()
    }
}
</script>
<style scoped>
.layout-btn-search {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 5px;
}
.layout-btn-close {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 5px;
}

.layout-options {
    background: #fff;
    padding: 20px 10px 5px 10px;
    border-radius: 4px 4px 0px 0px;

    margin: 0 auto;
}

.custom-dialog-title {
    font-size: 22px;
    text-align: center;
    display: flex;
    margin: 0;
    padding: 0;
}
hr {
    border: 1px solid #a4bfa4;
}
.custom-footer {
    display: flex;
    justify-content: space-evenly;
}

.custom-footer .span {
    margin-right: 10px;
    color: black;
    font-size: 15px;
    font-weight: bold;
}
.custom-footer .dflex {
    display: flex;
    align-items: center;

    padding: 0px 0px 4px 0px;
}
.layout-table-modal {
    padding: 50px 0 0 0;
    max-height: 800px;
    overflow-x: auto;
    overflow-y: auto;
}
.margin-top-150 {
    margin-top: 150px;
}
.custom-input {
    height: 400px; /* Đặt chiều cao tùy ý */
    overflow-x: auto;
    overflow-y: auto;
}
.row {
    text-align: left !important;
}
.btn-info {
    background-color: var(--teal);
    font-weight: 600;
    color: #fff;
}
.btn-close {
    background-color: var(--orange);
    font-weight: 600;
    color: #fff;
}
.bg-search {
    background-color: var(--teal);
    color: #fff;
}
.error {
    padding-top: 5px;
    color: red;
}
::v-deep .el-dialog__body {
    padding: 0px 0px 0px 0px;
}
::v-deep .el-tag.el-tag--info {
    color: black;
}
</style>
