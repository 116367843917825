<template>
    <el-dialog
        :visible="chinhSuaVisible"
        @close="closeModalChinhSua"
        width="80%"
        class="custom-dialog"
    >
        <span slot="title" class="custom-dialog-title">Chỉnh sửa thông tin </span>
        <div class="row">
            <div class="col-4">
                <label class="label">Đơn vị quản lý</label>
                <div>
                    <el-select
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        clearable
                        v-model="selectedValue.selectedValueUnitEducation"
                        placeholder="Chọn"
                        size="small"
                        @change="getDataChonTruongHoc"
                    >
                        <el-option
                            v-for="item in this.dataChonDonVi_Store"
                            :key="item.maDonVi"
                            :label="item.tenDonVi"
                            :value="item.maDonVi"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="col-2">
                <label class="label">Cấp học</label>
                <div>
                    <el-select
                        clearable
                        size="small"
                        v-model="selectedValue.selectedValueGradeLevel"
                        collapse-tags
                        style="width: 100%"
                        placeholder="Chọn"
                        @change="getDataChonTruongHoc"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelectGradeLevel_MockData"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label class="label">Trường học<span class="span">*</span></label>
                <div>
                    <el-select
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        clearable
                        v-model="selectedValue.selectedValueSchool"
                        placeholder="Chọn"
                        size="small"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelectSchool"
                            :key="item.maTruongHoc"
                            :label="item.tenTruongHoc"
                            :value="item.maTruongHoc"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label class="label">Đối tác<span class="span">*</span></label>
                <div>
                    <el-select
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        clearable
                        v-model="selectedValue.selectedValuePartner"
                        placeholder="Chọn"
                        size="small"
                    >
                        <el-option
                            v-for="item in this.ESelectPartner"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <div class="layout-btn">
            <CustomButton
                label="Đóng"
                size="small"
                @click="closeModalChinhSua"
                class="btn-default btn-delete"
            />
            <CustomButton
                label="Chỉnh sửa"
                class="btn-default btn-update"
                size="small"
                @click="handleChinhSua"
            />
        </div>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
import ESelect from '@/components/ESelect.vue'
import CustomButton from '@/components/CustomButton.vue'
import { ESelectGradeLevel_MockData_TraCuuTruongHoc } from '@/mock_data'
import sendRequest from '@/services'
import Api from '@/constants/Api'
export default {
    name: 'ModalDoiMauKhau',
    props: {
        chinhSuaVisible: {
            type: Boolean,
            required: true
        },

        closeModalChinhSua: {
            type: Function,
            required: true
        },
        dataChinhSua: { type: Object, required: true },
        handleSearch: Function,
        ESelectPartner: {
            type: Array,
            required: true
        }
    },
    components: { CustomButton },
    data() {
        return {
            getDataESelect: {
                ESelectUnitEducation: [], //donvi
                ESelectGradeLevel_MockData:
                    ESelectGradeLevel_MockData_TraCuuTruongHoc, //caphoc,
                ESelectSchool: [] //truonghoc,
            },
            selectedValue: {
                selectedValueUnitEducation: [], //donvi
                selectedValueGradeLevel: [], //caphoc
                selectedValueSchool: [], //truonghoc,
                selectedValuePartner: [] //doitac
            },
            change_maTruongHoc: '',
            id: 0
        }
    },
    watch: {
        async dataChinhSua(newValue) {
            this.id = newValue.id
            this.selectedValue.selectedValueUnitEducation = newValue.maDonVi

            this.change_maTruongHoc = newValue.maTruongHoc
            if (newValue.maTruongHoc) {
                this.selectedValue.selectedValueGradeLevel = []
            }
            this.selectedValue.selectedValuePartner = newValue.maDoiTac
            await this.getDataChonTruongHoc()

            console.log(this.selectedValue.selectedValueUnitEducation)
        }
    },
    methods: {
        async handleChinhSua() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const request_Header = {
                token: this.authToken
            }

            const request_Data = {
                id: this.id,
                maDoiTac: this.selectedValue.selectedValuePartner,
                maTruongHoc: this.selectedValue.selectedValueSchool
            }
            this.$confirm('Xác nhận chỉnh sửa đối tác của trường?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const response = await sendRequest(
                        Api.heThong.quanLyHeThongDoiTacTruong
                            .chinhSuaDoiTacTruong,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        loading.close()

                        this.$message({
                            type: 'success',
                            message: 'Chỉnh sửa thành công'
                        })
                        this.closeModalChinhSua()
                        this.$emit('handleSearch')
                    } else {
                        loading.close()
                        this.$message({
                            type: 'warning',
                            message: response.rd
                        })
                    }
                })
                .catch(() => {
                    loading.close()
                    this.$message({
                        type: 'info',
                        message: 'Hủy'
                    })
                })
        },
        async getDataChonTruongHoc() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const maDonVi =
                    [].concat(this.selectedValue.selectedValueUnitEducation) || []

                const capHoc =
                    []
                        .concat(this.selectedValue.selectedValueGradeLevel)
                        .map((str) => Number(str)) || []

                const request_Header = {
                    token: this.authToken
                }

                const request_Data = {
                    capHoc: capHoc,
                    maDonVi: maDonVi
                }

                let response = await sendRequest(
                    Api.internal_api.dm_School,
                    request_Data,
                    request_Header,
                    null
                )

                if (response.statusResponse == 0) {
                    loading.close()
                    const dataTruongHoc = response.rows.map((item) => ({
                        ...item,
                        tenTruongHoc: `${item.tenTruongHoc} - [${item.maTruongHoc}]`
                    }))
                    this.getDataESelect.ESelectSchool = dataTruongHoc
                    const foundTruongHoc = dataTruongHoc.find(
                        (item) => item.maTruongHoc === this.change_maTruongHoc
                    )

                    if (foundTruongHoc) {
                        this.selectedValue.selectedValueSchool =
                            foundTruongHoc.maTruongHoc
                    } else {
                        this.selectedValue.selectedValueSchool = ''
                    }
                }
            } catch (error) {
                loading.close()
            }
        },
        convertToValueArray(array) {
            return array.map((item) => item.value)
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        }
    }
}
</script>
<style scoped>
.row span,
.span span {
    color: red;
}
.label {
    font-weight: bold;
}
.layout-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.bg-search,
.btn-info {
    background-color: var(--teal);
}
.btn-add {
    background-color: var(--light-green);
}

.btn-update {
    background-color: var(--yellow);
}
.btn-delete {
    background-color: var(--orange);
}
</style>
