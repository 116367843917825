<template>
    <el-dialog
        :visible="addNewModalVisible"
        @close="closeModalAddNew"
        width="50%"
    >
        <span slot="title" class="custom-dialog-title">
            THÊM MẬT KHẨU KẾT CHUYỂN DỮ LIỆU
            <hr />
        </span>

        <div class="row">
            <div class="col-8">
                <label>Đơn vị quản lý</label>
                <div>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="this.dataChonDonVi_Store"
                        :placeholder="'Chọn đơn vị'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        :value="selectedValue.selectedValueUnitEducation"
                        @change="
                            handleESelectChange('ESelectUnitEducation', ...$event)
                        "
                    />
                </div>
            </div>
            <div class="col-4">
                <label>Cấp học</label>
                <div>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectGradeLevel_MockData"
                        :placeholder="'Chọn cấp học'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenTruongHoc', 'value']"
                        :value="selectedValue.selectedValueGradeLevel"
                        @change="
                            handleESelectChange(
                                'ESelectGradeLevel_MockData',
                                ...$event
                            )
                        "
                    />
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-8">
                <label>Trường học <span class="span">*</span></label>
                <div>
                    <ESelect
                        :reset="resetESelectSchool"
                        @reset-completed="handleResetCompleted"
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectSchool"
                        :placeholder="'Chọn trường học'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['tenTruongHoc', 'maTruongHoc']"
                        v-model="selectedValue.selectedValueSchool"
                    />
                </div>
            </div>
            <div class="col-4">
                <label>Tên đăng nhập <span class="span">*</span></label>
                <el-input
                    placeholder="Tên đăng nhập"
                    v-model="inputUserName_addNew"
                    size="small"
                ></el-input>
            </div>
        </div>
        <div class="row mt-3 mb-5">
            <div class="col-8">
                <label>Mật khẩu<span class="span">*</span></label>
                <el-input
                    placeholder="Mật khẩu"
                    v-model="inputPassword_addNew"
                    size="small"
                    show-password
                ></el-input>
            </div>
        </div>

        <hr />
        <div class="layout-table-modal">
            <CustomButton
                label="Đóng"
                size="small"
                @click="closeModalAddNew"
                class="btn-default btn-close-modal"
            />
            <CustomButton
                label="Thêm mới"
                size="small"
                @click="handleAddNew"
                class="btn-default btn-add"
            />
        </div>
    </el-dialog>
</template>
<script>
import ESelect from '@/components/ESelect.vue'
import CustomButton from '@/components/CustomButton.vue'
import Api from '@/constants/Api'
import sendRequest from '@/services'
import { mapState } from 'vuex'
import { ESelectGradeLevel_MockData } from '@/mock_data'
export default {
    props: {
        addNewModalVisible: {
            type: Boolean,
            required: true
        },

        closeModalAddNew: {
            type: Function,
            required: true
        },
        handleSearch: Function
    },

    components: { ESelect, CustomButton },
    data() {
        return {
            resetESelectSchool: false,
            getDataESelect: {
                ESelectUnitEducation: [], //donvi
                ESelectGradeLevel_MockData: ESelectGradeLevel_MockData, //caphoc
                ESelectSchool: [] //truonghoc
            },

            selectedValue: {
                selectedValueUnitEducation: [], //donvi
                selectedValueGradeLevel: [], //caphoc
                selectedValueSchool: [] //truonghoc
            },
            inputUserName_addNew: '',
            inputPassword_addNew: ''
        }
    },
    watch: {
        addNewModalVisible(newValue) {
            if (newValue === true) {
                this.getDataESelectSchool()
            }
        }
    },
    methods: {
        handleResetCompleted() {
            this.resetESelectSchool = false
        },
        handleESelectChange(source, ...selectedOptions) {
            switch (source) {
                case 'ESelectUnitEducation':
                    this.selectedValue.selectedValueUnitEducation =
                        selectedOptions
                    console.log(this.selectedValue.selectedValueUnitEducation)
                    this.resetESelectSchool = true
                    this.selectedValue.selectedValueSchool = []
                    this.getDataESelectSchool()
                    break
                case 'ESelectGradeLevel_MockData':
                    this.selectedValue.selectedValueGradeLevel = selectedOptions

                    this.resetESelectSchool = true
                    this.selectedValue.selectedValueSchool = []
                    this.getDataESelectSchool()
                    break
            }
        },
        async getDataESelectSchool() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const maDonVi = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueUnitEducation,
                    'selectedValueUnitEducation'
                )

                const capHoc = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueGradeLevel,
                    'selectedValueGradeLevel'
                )

                const request_Header = {
                    token: this.authToken
                }

                const request_Data = {
                    capHoc: capHoc,
                    maDonVi: maDonVi
                }
                const response = await sendRequest(
                    Api.internal_api.dm_School,
                    request_Data,
                    request_Header,
                    null
                )
                this.getDataESelect.ESelectSchool = response.rows.map((item) => ({
                    ...item,
                    tenTruongHoc: `${item.tenTruongHoc} - [${item.maTruongHoc}]`
                }))

                loading.close()
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },
        async handleAddNew() {
            const maTruong = this.selectedValue.selectedValueSchool.value

            const userName = this.inputUserName_addNew
            const passWord = this.inputPassword_addNew
            if (maTruong === undefined) {
                this.$message({
                    message: 'Vui lòng chọn Trường học',
                    type: 'error'
                })
                return
            }
            if (userName === '') {
                this.$message({
                    message: 'Vui lòng Nhập tên đăng nhập',
                    type: 'error'
                })
                return
            }
            if (passWord === '') {
                this.$message({
                    message: 'Vui lòng Nhập mật khẩu',
                    type: 'error'
                })
                return
            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const request_Header = {
                token: this.authToken
            }

            const request_Data = {
                maTruong: maTruong,
                matKhau: passWord,
                tenDangNhap: userName
            }

            this.$confirm('Xác nhận thêm mới?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const response = await sendRequest(
                        Api.ketChuyenDuLieuLenBo.matKhauKetChuyen
                            .themMoiMatKhauKetChuyen,
                        request_Data,
                        request_Header,
                        null
                    )

                    if (response.code == 200) {
                        loading.close()
                        this.$message({
                            message: 'Thêm mới thành công',
                            type: 'success'
                        })
                        this.closeModalAddNew()
                        this.$emit('handleSearch')
                    } else {
                        loading.close()
                        this.$message({
                            message: response.message,
                            type: 'error'
                        })
                    }
                })
                .catch((error) => {
                    loading.close()
                    if (error.response?.data.rd) {
                        this.$message({
                            type: 'error',
                            message: error.response?.data.rd
                        })
                    }
                })
        },
        customhandleESelectedChange(options, valueType) {
            switch (valueType) {
                case 'selectedValueUnitEducation':
                case 'selectedValueGradeLevel':
                case 'selectedValueSchool':
                    return options.map((option) => option.value)
            }
        }
    },
    created() {
        this.inputUserName_addNew = ''
        this.inputPassword_addNew = ''
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        },
        ...mapState(['isCollapseLeftBar'])
    }
}
</script>
<style scoped>
label .span {
    color: red;
}
.layout-table-modal {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}
.btn-add {
    background-color: var(--light-green);
}

.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.btn-close-modal {
    background-color: var(--orange);
}
</style>
