var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CustomBreadcrumb',{attrs:{"title":'TIẾP NHẬN DỮ LIỆU QLNT',"content":'BÁO CÁO TÌNH HÌNH NỘP DỮ LIỆU CẤP SỞ'}}),_c('CustomContentUI',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('label',[_vm._v("Đơn vị quản lý")]),_c('div',[_c('ESelect',{staticStyle:{"width":"100%"},attrs:{"no-match-text":"Không tìm thấy bản ghi nào","no-data-text":"danh sách lựa chọn trống","clearable":true,"disabled":false,"data":this.dataChonDonVi_Store,"placeholder":'Chọn đơn vị',"filterable":true,"multiple":true,"collapse-tags":true,"fields":['tenDonVi', 'maDonVi'],"value":_vm.selectedValue.selectedValueUnitEducation},on:{"change":function($event){return _vm.handleESelectChange(
                                'ESelectUnitEducation',
                                ...$event
                            )}}})],1)]),_c('div',{staticClass:"col-2"},[_c('label',[_vm._v("Cấp học "),_c('span',[_vm._v("*")])]),_c('div',[_c('ESelect',{staticStyle:{"width":"100%"},attrs:{"no-match-text":"Không tìm thấy bản ghi nào","no-data-text":"danh sách lựa chọn trống","clearable":true,"disabled":false,"data":_vm.getDataESelect.ESelectGradeLevel_MockData,"placeholder":'Chọn cấp học',"filterable":true,"multiple":true,"collapse-tags":true,"fields":['tenTruongHoc', 'value'],"value":_vm.selectedValue.selectedValueGradeLevel},on:{"change":function($event){return _vm.handleESelectChange(
                                'ESelectGradeLevel_MockData',
                                ...$event
                            )}}})],1)]),_c('div',{staticClass:"col-2"},[_c('label',[_vm._v("Năm học")]),_c('div',[_c('ESelectYear',{attrs:{"placeholder":"Chọn năm","size":"small","no-data-text":'Không có bản ghi nào',"no-match-text":'Không tìm thấy bản ghi nào'},model:{value:(_vm.selectedValue.selectedValueSchoolYear),callback:function ($$v) {_vm.$set(_vm.selectedValue, "selectedValueSchoolYear", $$v)},expression:"selectedValue.selectedValueSchoolYear"}})],1)]),_c('div',{staticClass:"col-2 mt-1"},[_c('label'),_c('div',[_c('CustomButton',{staticClass:"bg-search btn-default",attrs:{"label":"Tìm kiếm","size":"small"},on:{"click":_vm.handleSearch}})],1)])])]),_c('div',{staticClass:"layout-options",style:({
            'max-width': _vm.leftBarWidth()
        })},[_c('div',[_c('chonSoLuongBanGhi',{on:{"chonXongSoLuong":function($event){return _vm.ChonSoLuongBanGhi($event)}}})],1)]),_c('CustomTable',{attrs:{"maxWidth":_vm.leftBarWidth(),"columns":_vm.tableColumns},scopedSlots:_vm._u([{key:"body",fn:function(){return [(_vm.tableData.length === 0)?[_c('tr',[_c('td',{staticClass:"text-tbody",attrs:{"colspan":"11"}},[_vm._v(" Không có bản ghi nào. ")])])]:_vm._e(),_vm._l((_vm.tableData),function(item,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-tbody"},[_vm._v(_vm._s(item.stt))]),_c('td',{staticClass:"text-tbody",staticStyle:{"text-align":"left"}},[_vm._v(" "+_vm._s(item.tenDonVi)+" ")]),_c('td',{staticClass:"text-tbody"},[_vm._v(" "+_vm._s(item.totalCanbo)+" ")]),_c('td',{staticClass:"text-tbody"},[_vm._v(" "+_vm._s(item.totalHocsinh)+" ")]),_c('td',{staticClass:"text-tbody"},[_vm._v(" "+_vm._s(item.totalHsTotNghep)+" ")]),_c('td',{staticClass:"text-tbody"},[_vm._v(" "+_vm._s(item.totalKQCanam)+" ")]),_c('td',{staticClass:"text-tbody"},[_vm._v(" "+_vm._s(item.totalKQHocKi1)+" ")]),_c('td',{staticClass:"text-tbody"},[_vm._v(" "+_vm._s(item.totalKQHocki2)+" ")]),_c('td',{staticClass:"text-tbody"},[_vm._v(" "+_vm._s(item.totalLopDon)+" ")]),_c('td',{staticClass:"text-tbody"},[_vm._v(" "+_vm._s(item.totalLopGhep)+" ")]),_c('td',{staticClass:"text-tbody"},[_vm._v(" "+_vm._s(item.totalTruongHoc)+" ")])])})]},proxy:true}])}),_c('CustomPagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.total_rows > 0),expression:"total_rows > 0"}],attrs:{"tongbanghi":_vm.total_rows,"soluonghienthi":_vm.soLuongBanGhiHienThi,"batdau":_vm.trangbatdau},on:{"pageChange":function($event){return _vm.layLai($event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }