<template>
    <el-dialog
        :visible="phanCongGiangDayModalVisible"
        @close="closeModalPhanCongGiangDay"
        :fullscreen="true"
        class="custom-dialog"
    >
        <span slot="title" class="custom-dialog-title"
            >Phân công giảng dạy : {{ data_modalPhanCongGiangDay.tenCanBo }} ({{
                data_modalPhanCongGiangDay.maGiaoVien
            }})
        </span>

        <div class="row">
            <div class="col-md-6">
                <table class="table table-bordered table-hover centered-table">
                    <thead>
                        <tr>
                            <th class="text-thead" rowspan="2">STT</th>
                            <th class="text-thead" colspan="2">Học kỳ I</th>
                            <th class="text-thead" rowspan="2">Thao tác</th>
                        </tr>
                        <tr>
                            <th class="text-thead">Lớp học <span> *</span></th>
                            <th class="text-thead">Môn học <span> *</span></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-if="tableData_HocKyI.length === 0">
                            <td colspan="6">
                                <div class="layout-add">
                                    <div class="title">Danh sách trống</div>
                                    <div>
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            content="Thêm mới"
                                            placement="top"
                                        >
                                            <CustomButton
                                                label=""
                                                icon="el-icon-circle-plus-outline"
                                                @click="addNewEmptyItem_hocKyI"
                                                class="btn-add btn-default"
                                                size="mini"
                                                style="font-size: 24px"
                                            />
                                        </el-tooltip>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr
                            v-for="(item, index) in tableData_HocKyI"
                            :key="index"
                        >
                            <td class="text-tbody">{{ index + 1 }}</td>

                            <td class="text-tbody">
                                <el-select
                                    :disabled="item.isItemDisabled"
                                    size="small"
                                    style="width: 100%"
                                    v-model="item.maLopHoc"
                                    filterable
                                    collapse-tags
                                    placeholder="Chọn"
                                    no-data-text="Không có dữ liệu"
                                    no-match-text="Không có dữ liệu phù hợp"
                                    @change="handleChange_LopHoc(item)"
                                >
                                    <el-option
                                        v-for="(item, i) in ESelect_LopHoc"
                                        :key="i"
                                        :label="item.name"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </td>
                            <td class="text-tbody">
                                <el-input
                                    v-if="item.isItemDisabled"
                                    size="small"
                                    placeholder="Please input"
                                    v-model="item.tenMonHoc"
                                    :disabled="true"
                                >
                                </el-input>
                                <el-select
                                    v-else
                                    size="small"
                                    style="width: 100%"
                                    v-model="item.maMonHoc"
                                    filterable
                                    collapse-tags
                                    placeholder="Chọn"
                                    no-data-text="Không có dữ liệu"
                                    no-match-text="Không có dữ liệu phù hợp"
                                    @change="handleChange_MonHoc(item)"
                                >
                                    <el-option
                                        v-for="(item, i) in ESelect_MonHoc"
                                        :key="i"
                                        :label="item.name"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </td>
                            <td class="text-tbody">
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    content="Chỉnh sửa"
                                    placement="top"
                                >
                                    <CustomButton
                                        label=""
                                        icon="el-icon-edit-outline"
                                        class="btn-update btn-default"
                                        size="small"
                                        @click="() => EditItem(item)"
                                    />
                                </el-tooltip>

                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    content="Xóa"
                                    placement="top"
                                >
                                    <CustomButton
                                        label=""
                                        icon="el-icon-delete"
                                        class="btn-delete btn-default"
                                        size="small"
                                        @click="handleDelete(item)"
                                    />
                                </el-tooltip>

                                <el-tooltip
                                    v-if="index === tableData_HocKyI.length - 1"
                                    class="item"
                                    effect="dark"
                                    content="Thêm"
                                    placement="top"
                                >
                                    <CustomButton
                                        label=""
                                        icon="el-icon-plus"
                                        class="btn-add btn-default"
                                        size="small"
                                        @click="addNewItem(item)"
                                    />
                                </el-tooltip>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-6">
                <table class="table table-bordered table-hover centered-table">
                    <thead>
                        <tr>
                            <th class="text-thead" rowspan="2">STT</th>
                            <th class="text-thead" colspan="2">Học kỳ II</th>
                            <th class="text-thead" rowspan="2">Thao tác</th>
                        </tr>
                        <tr>
                            <th class="text-thead">Lớp học <span> *</span></th>
                            <th class="text-thead">Môn học <span> *</span></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-if="tableData_HocKyII.length === 0">
                            <td colspan="6">
                                <div class="layout-add">
                                    <div class="title">Danh sách trống</div>
                                    <div>
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            content="Thêm mới"
                                            placement="top"
                                        >
                                            <CustomButton
                                                label=""
                                                icon="el-icon-circle-plus-outline"
                                                size="mini"
                                                @click="addNewEmptyItem_hocKyII"
                                                class="btn-add btn-default"
                                                style="font-size: 24px"
                                            />
                                        </el-tooltip>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr
                            v-for="(item, index) in tableData_HocKyII"
                            :key="index"
                        >
                            <td class="text-tbody">{{ index + 1 }}</td>

                            <td class="text-tbody">
                                <el-select
                                    :disabled="item.isItemDisabled"
                                    size="small"
                                    style="width: 100%"
                                    v-model="item.maLopHoc"
                                    filterable
                                    collapse-tags
                                    placeholder="Chọn"
                                    no-data-text="Không có dữ liệu"
                                    no-match-text="Không có dữ liệu phù hợp"
                                    @change="handleChange_LopHoc(item)"
                                >
                                    <el-option
                                        v-for="(item, i) in ESelect_LopHoc"
                                        :key="i"
                                        :label="item.name"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </td>
                            <td class="text-tbody">
                                <el-input
                                    v-if="item.isItemDisabled"
                                    size="small"
                                    placeholder="Please input"
                                    v-model="item.tenMonHoc"
                                    :disabled="true"
                                >
                                </el-input>
                                <el-select
                                    v-else
                                    size="small"
                                    style="width: 100%"
                                    v-model="item.maMonHoc"
                                    filterable
                                    collapse-tags
                                    placeholder="Chọn"
                                    no-data-text="Không có dữ liệu"
                                    no-match-text="Không có dữ liệu phù hợp"
                                    @change="handleChange_MonHoc(item)"
                                >
                                    <el-option
                                        v-for="(item, i) in ESelect_MonHoc"
                                        :key="i"
                                        :label="item.name"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </td>
                            <td class="text-tbody">
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    content="Chỉnh sửa"
                                    placement="top"
                                >
                                    <CustomButton
                                        label=""
                                        icon="el-icon-edit-outline"
                                        class="btn-update btn-default"
                                        size="small"
                                        @click="() => EditItem(item)"
                                    />
                                </el-tooltip>

                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    content="Xóa"
                                    placement="top"
                                >
                                    <CustomButton
                                        label=""
                                        icon="el-icon-delete"
                                        class="btn-delete btn-default"
                                        size="small"
                                        @click="handleDelete(item)"
                                    />
                                </el-tooltip>

                                <el-tooltip
                                    v-if="index === tableData_HocKyII.length - 1"
                                    class="item"
                                    effect="dark"
                                    content="Thêm"
                                    placement="top"
                                >
                                    <CustomButton
                                        label=""
                                        icon="el-icon-plus"
                                        class="btn-add btn-default"
                                        size="small"
                                        @click="addNewItem(item)"
                                    />
                                </el-tooltip>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div style="display: flex; justify-content: flex-end">
            <CustomButton
                label="Đóng"
                size="small"
                @click="resetModalPhanCongGiangDay"
                class="btn-delete btn-default"
            />
        </div>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
import CustomButton from '@/components/CustomButton.vue'
import sendRequest from '@/services'
import Api from '@/constants/Api'
export default {
    name: 'ModalLstQuaTrinhLuong',
    props: {
        phanCongGiangDayModalVisible: {
            type: Boolean,
            required: true
        },

        closeModalPhanCongGiangDay: {
            type: Function,
            required: true
        },
        data_modalPhanCongGiangDay: {
            type: Object,
            required: true
        },
        selectedValueYear: {
            type: String,
            required: true
        }
    },
    components: { CustomButton },
    data() {
        return {
            tableData_HocKyI: [],

            tableData_HocKyII: [],
            ESelect_LopHoc: [],
            maLopHoc_change: '',

            ESelect_MonHoc: [],

            edit: false,
            create: false,
            dataCreate_hocKyI: {},
            dataCreate_hocKyII: {}
        }
    },
    watch: {
        async phanCongGiangDayModalVisible(newValues) {
            if (newValues === true) {
                await this.getData_PhanCongGiangDay()
                await this.getDataESelect_LopHoc()
            }
        }
    },
    methods: {
        resetModalPhanCongGiangDay() {
            this.closeModalPhanCongGiangDay()
            this.tableData_HocKyI = []
            this.tableData_HocKyII = []
            this.ESelect_LopHoc = []
            this.maLopHoc_change = ''

            this.ESelect_MonHoc = []
        },
        addNewEmptyItem_hocKyI() {
            this.create = true
            const emptyItem = {
                maLopHoc: '',
                maMonHoc: '',
                isItemDisabled: false,
                lopMonId: this.dataCreate_hocKyI.lopMonId,
                maGiaoVien: this.dataCreate_hocKyI.maGiaoVien,
                hocKy: this.dataCreate_hocKyI.hocKy,
                maTruong: this.dataCreate_hocKyI.maTruong
            }

            this.tableData_HocKyI.push(emptyItem)
        },
        addNewEmptyItem_hocKyII() {
            this.create = true
            const emptyItem = {
                maLopHoc: '',
                maMonHoc: '',
                isItemDisabled: false,
                lopMonId: this.dataCreate_hocKyII.lopMonId,
                maGiaoVien: this.dataCreate_hocKyII.maGiaoVien,
                hocKy: this.dataCreate_hocKyII.hocKy,
                maTruong: this.dataCreate_hocKyII.maTruong
            }

            this.tableData_HocKyII.push(emptyItem)
        },
        addNewItem(item) {
            this.create = true
            const newItem = Object.assign({}, item) // hoặc {...selectedItem}

            for (const key in newItem) {
                if (
                    Object.prototype.hasOwnProperty.call(newItem, key) &&
                    key !== 'isItemDisabled' &&
                    key !== 'hocKy' &&
                    key !== 'lopMonId' &&
                    key !== 'maGiaoVien'
                ) {
                    newItem[key] = ''
                    newItem.isItemDisabled = false
                }
            }

            this.tableData_HocKyI.push(newItem)
            this.tableData_HocKyII.push(newItem)
        },
        handleDelete(item) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                id: item.lopMonGiaoVienId,
                maLopHoc: item.maLopHoc,
                maMonHoc: item.maMonHoc
            }
            this.$confirm('Xác nhận xóa thông tin?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const response = await sendRequest(
                        Api.traCuu.traCuuCanBoGiaoVien.deletePhanCongGiangDay,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        loading.close()
                        this.$message({
                            type: 'success',
                            message: 'Xóa dữ liệu thành công'
                        })
                        this.getData_PhanCongGiangDay()
                    } else {
                        loading.close()
                    }
                })
                .catch(() => {
                    loading.close()
                    this.$message({
                        type: 'info',
                        message: 'Hủy'
                    })
                })
        },
        async getData_PhanCongGiangDay() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    maGiaoVien: this.data_modalPhanCongGiangDay.maGiaoVien,
                    namHoc: this.selectedValueYear
                }
                const response = await sendRequest(
                    Api.traCuu.traCuuCanBoGiaoVien.danhSachPhanCongGiangDay,
                    null,
                    request_Header,
                    request_Params
                )
                if (response.rc == 0) {
                    loading.close()
                    this.tableData_HocKyI = response.rows
                        .filter((item) => item.hocKy === 1)
                        .map((item) => ({
                            ...item,
                            isItemDisabled: true
                        }))
                    this.tableData_HocKyII = response.rows
                        .filter((item) => item.hocKy === 2)
                        .map((item) => ({
                            ...item,
                            isItemDisabled: true
                        }))
                    this.dataCreate_hocKyI = response.rows.reduce(
                        (accumulator, currentItem) => {
                            accumulator.lopMonId = currentItem.lopMonId
                            accumulator.maGiaoVien = currentItem.maGiaoVien
                            accumulator.hocKy = 1
                            accumulator.maTruong =
                                this.data_modalPhanCongGiangDay.maTruong
                            return accumulator
                        },
                        {}
                    )
                    this.dataCreate_hocKyII = response.rows.reduce(
                        (accumulator, currentItem) => {
                            accumulator.lopMonId = currentItem.lopMonId
                            accumulator.maGiaoVien = currentItem.maGiaoVien
                            accumulator.hocKy = 2
                            accumulator.maTruong =
                                this.data_modalPhanCongGiangDay.maTruong
                            return accumulator
                        },
                        {}
                    )
                } else {
                    loading.close()
                }
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },
        async updatePhanCongGiangDay(item) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                hocKy: item.hocKy,
                id: item.lopMonGiaoVienId,
                lopMonId: item.lopMonId,
                maGiaoVien: item.maGiaoVien,
                maLopHoc: this.maLopHoc_change || item.maLopHoc,
                maMonHoc: item.maMonHoc
            }
            this.$confirm('Xác nhận chỉnh sửa thông tin?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const response = await sendRequest(
                        Api.traCuu.traCuuCanBoGiaoVien.updatePhanCongGiangDay,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        loading.close()

                        this.getData_PhanCongGiangDay()
                        this.$message({
                            type: 'success',
                            message: 'Chỉnh sửa dữ liệu thành công'
                        })
                        this.maLopHoc_change = ''
                        this.edit = false
                    } else {
                        loading.close()
                    }
                })
                .catch(() => {
                    loading.close()
                    this.$message({
                        type: 'info',
                        message: 'Hủy'
                    })
                })
        },
        async createPhanCongGiangDay(item) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            if (this.maLopHoc_change === '') {
                this.$message({
                    type: 'error',
                    message: 'Vui lòng chọn lớp học trước'
                })
                loading.close()
                return
            }
            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                hocKy: item.hocKy,

                lopMonId: item.lopMonId,
                maGiaoVien: item.maGiaoVien,
                maLopHoc: this.maLopHoc_change || item.maLopHoc,
                maMonHoc: item.maMonHoc,
                maTruong: this.data_modalPhanCongGiangDay.maTruongHoc
            }

            this.$confirm('Xác nhận thêm mới?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const response = await sendRequest(
                        Api.traCuu.traCuuCanBoGiaoVien.createPhanCongGiangDay,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        loading.close()

                        this.getData_PhanCongGiangDay()
                        this.$message({
                            type: 'success',
                            message: 'Thêm mới thành công'
                        })
                        this.maLopHoc_change = ''

                        this.create = false
                    } else {
                        loading.close()
                    }
                })
                .catch(() => {
                    loading.close()
                    this.$message({
                        type: 'info',
                        message: 'Hủy'
                    })
                })
        },
        async handleChange_MonHoc(item) {
            if (this.edit === true) {
                await this.updatePhanCongGiangDay(item)
            }
            if (this.create === true) {
                await this.createPhanCongGiangDay(item)
            }
        },
        async EditItem(selectedItem) {
            this.edit = true
            selectedItem.isItemDisabled = false

            this.tableData_HocKyI.forEach((item) => {
                if (item !== selectedItem) {
                    item.isItemDisabled = true
                }
            })

            this.getDataESelect_MonHoc(selectedItem)
        },
        async getDataESelect_MonHoc(selectedItem) {
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    maLopHoc: selectedItem.maLopHoc || this.maLopHoc_change
                }
                const response = await sendRequest(
                    Api.traCuu.traCuuCanBoGiaoVien.danhSachMonHoc,
                    null,
                    request_Header,
                    request_Params
                )
                if (response.rc == 0) {
                    this.ESelect_MonHoc = response.rows.map((item) => ({
                        name: item.tenMonHoc,
                        value: item.maMonHoc
                    }))
                }
            } catch (error) {
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },
        async handleChange_LopHoc(item) {
            this.maLopHoc_change = item.maLopHoc
            item.maMonHoc = ''
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    maLopHoc: item.maLopHoc
                }
                const response = await sendRequest(
                    Api.traCuu.traCuuCanBoGiaoVien.danhSachMonHoc,
                    null,
                    request_Header,
                    request_Params
                )
                if (response.rc == 0) {
                    loading.close()
                    this.ESelect_MonHoc = response.rows.map((item) => ({
                        name: item.tenMonHoc,
                        value: item.maMonHoc
                    }))
                }
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },
        async getDataESelect_LopHoc() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    maTruong: this.data_modalPhanCongGiangDay.maTruongHoc,
                    namHoc: this.selectedValueYear
                }
                const response = await sendRequest(
                    Api.traCuu.traCuuCanBoGiaoVien.danhSachLopHoc,
                    null,
                    request_Header,
                    request_Params
                )
                if (response.rc == 0) {
                    loading.close()
                    this.ESelect_LopHoc = response.rows.map((item) => ({
                        name: item.tenLopHoc,
                        value: item.maLopHoc
                    }))
                } else {
                    loading.close()
                }
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonNgoaiNgu_Store() {
            return JSON.parse(localStorage.getItem('data_ChonNgoaingu'))
        },
        dataChonTrinhDoNgoaiNgu_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrinhDoNgoaiNgu'))
        }
    }
}
</script>
<style scoped>
::v-deep .el-input.is-disabled .el-input__inner,
::v-deep .el-textarea.is-disabled .el-textarea__inner,
::v-deep .el-input.is-disabled .el-input__inner {
    background-color: #eee !important;
    color: black !important;
}
::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
    border-color: black !important;
    background-color: #eee !important;
}
table thead tr {
    background: rgb(228, 235, 245);
}
.table {
    white-space: nowrap;
}
.centered-table {
    margin-left: auto;
    margin-right: auto;
}

.centered-table th,
.centered-table td {
    text-align: center;
    vertical-align: middle;
}
.table-bordered {
    background-color: #fff;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}
.table-hover tbody tr:hover {
    background-color: #f5f5f5;
}

.text-thead {
    font-size: 13px;
    text-align: center; /* Canh giữa nội dung trong cột */
    vertical-align: middle; /* Căn giữa theo chiều dọc */
}
.text-thead span {
    color: red;
}
.text-tbody {
    font-size: 13px;
}
.btn-close {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
.layout-add {
    display: flex;
    align-items: center;
    justify-content: center;
}
.layout-add .title {
    margin-right: 10px;
}

.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.bg-search {
    background-color: var(--teal);
}
.btn-add,
.btn-import,
.btn-unlock {
    background-color: var(--light-green);
}

.btn-update {
    background-color: var(--yellow);
}
.btn-delete,
.btn-lock {
    background-color: var(--orange);
}
</style>
