<template>
    <div>
        <CustomContentUI>
            <div class="row d-flex align-items-center">
                <div class="col-sm-4">
                    <ESelectYear
                        v-model="year"
                        placeholder="Chọn năm"
                        size="small"
                        :no-data-text="'Không có bản ghi nào'"
                        :no-match-text="'Không tìm thấy bản ghi nào'"
                        :width="'100%'"
                    ></ESelectYear>
                </div>
                <div class="col-sm-4">
                    <CustomButton
                        class="bg-search btn-default"
                        label="Tìm kiếm"
                        size="small"
                        @click="handleSearch"
                    />
                </div>
            </div>
        </CustomContentUI>

        <CustomTable :columns="tableColumns">
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td class="text-tbody text-center" colspan="6">
                            Không có bản ghi nào.
                        </td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td class="text-tbody text-center">
                        {{ index + 1 }}
                    </td>
                    <td class="text-tbody text-left">
                        {{ item.tenPhong }}
                    </td>

                    <td class="text-tbody text-center">
                        <span v-if="item.status === 0" class="text-lock">
                            Chưa chốt
                        </span>
                        <span v-if="item.status === 1" class="text-unlock">
                            Đã chốt
                        </span>
                    </td>
                    <td class="text-tbody text-center">
                        <span v-if="item.status === 1">
                            <CustomButton
                                class="btn-default bg-search"
                                label="Mở"
                                size="small"
                                @click="handleUnLock(item)"
                            />
                        </span>
                        <span v-if="item.status === 0">
                            <CustomButton
                                class="btn-default btn-delete"
                                label="Khóa"
                                size="small"
                                @click="handleLock(item)"
                            />
                        </span>
                    </td>
                </tr>
            </template>
        </CustomTable>

        <CustomPagination
            v-show="total_rows > 0"
            :tongbanghi="total_rows"
            :soluonghienthi="soLuongBanGhiHienThi"
            :batdau="trangbatdau"
            @pageChange="layLai($event)"
        >
        </CustomPagination>
    </div>
</template>
<script>
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomPagination from '@/components/CustomPagination.vue'
import { mapState } from 'vuex'

import CustomButton from '@/components/CustomButton.vue'
import { currentYear } from '@/utils/index'
import ESelectYear from '@/components/ESelectYear.vue'
import CustomTable from '@/components/CustomTable.vue'
import CustomContentUI from '@/components/CustomContentUI.vue'

export default {
    name: 'ChotTheoDonVi',
    components: {
        CustomButton,
        CustomPagination,
        ESelectYear,
        CustomTable,
        CustomContentUI
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            soLuongBanGhiHienThi: 25,
            trangbatdau: false,

            tableData: [],
            tableColumns: [
                { key: '1', label: 'STT' },
                { key: '2', label: 'Đơn vị' },
                { key: '3', label: 'Trạng thái' },
                { key: '4', label: 'Thao tác' }
            ],

            year: ''
        }
    },
    methods: {
        checkTruocKhiTim() {
            this.trangbatdau = !this.trangbatdau
        },
        layLai(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        async handleToggleLock(item, trangThai) {
            try {
                const request_Header = {
                    token: this.authToken
                }

                const request_Data = {
                    namHoc: item.namHoc,
                    trangThai: trangThai,
                    roleId: item.roleId,

                    maSo: this.authUser.ma_so,
                    maDonVi: item.maPhong,
                    maPhongs: [item.maPhong]
                }

                const response = await sendRequest(
                    Api.heThong.chotDuLieuBaoCao.chotTheoPhong.moKhoa,
                    request_Data,
                    request_Header
                )

                if (response.rc == 0) {
                    this.handleSearch()
                }
            } catch (error) {
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },

        async handleUnLock(item) {
            await this.handleToggleLock(item, 0)
        },

        async handleLock(item) {
            await this.handleToggleLock(item, 1)
        },
        async handleSearch() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Data = {
                    limit: 99,

                    maSo: this.authUser.ma_so,
                    namHoc: this.year || currentYear,
                    start: 0
                }
                const response = await sendRequest(
                    Api.heThong.chotDuLieuBaoCao.chotTheoPhong.danhSach,
                    request_Data,
                    request_Header
                )

                loading.close()
                this.tableData = response.rows
                console.log(this.tableData)
                this.total_rows = response.total
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        }
    },

    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    },
    mounted() {}
}
</script>

<style scoped>
.text-left {
    text-align: left;
}

.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.bg-search {
    background-color: var(--teal);
}

.btn-update {
    background-color: var(--yellow);
}

.text-unlock {
    color: #1e6050;
}
.text-lock {
    color: var(--orange);
}
.btn-delete {
    background-color: var(--orange);
}
::v-deep .el-input.is-disabled .el-input__inner {
    color: black;
}
</style>
