<template>
    <div class="school">
        <div class="header" style="display: flex; justify-content: space-between">
            <div class="left col-2">
                <div class="icon">
                    <i class="el-icon-s-home"></i>
                </div>
                <div class="title">Lớp học</div>
            </div>
            <!-- <div class="" style="display: flex" v-show="dataESelectLopHoc">
                <div class="col-8">
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="dataESelectLopHoc"
                        :placeholder="'Chọn đơn vị quản lý'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['title', 'value']"
                        v-model="selectedValue"
                    />
                </div>
                <div class="col-4">
                    <CustomButton
                        @click="handleThongKe"
                        label="Thống kê"
                        size="small"
                        type="success"
                    />
                </div>
            </div> -->

            <div class="right">
                <div>
                    <i class="el-icon-timer"></i>
                </div>
                <div class="title">
                    Thời gian cập nhật:
                    <span class="span"
                        >{{
                            dataLopHoc?.lastUpdate || dataLopHocUpdate?.lastUpdate
                        }}
                    </span>
                    <CustomButton
                        label="Chi tiết"
                        size="small"
                        type="success"
                        class="custom-btn"
                        @click="handleNavigate"
                    />
                </div>
            </div>
        </div>

        <div class="layout-card">
            <div class="row">
                <div
                    class="col-md-4 mb-4"
                    v-show="findLopHocName(dataLopHoc || dataLopHocUpdate, 'MN')"
                >
                    <div class="card">
                        <div class="card-header">
                            <div class="title">
                                LỚP HỌC
                                {{
                                    findLopHocName(
                                        dataLopHoc || dataLopHocUpdate,
                                        'MN'
                                    )
                                }}
                            </div>
                        </div>
                        <div class="card-body">
                            <StackedColumnChart
                                :data_StackedColumnChart="
                                    filterLopHoc(
                                        dataLopHoc || dataLopHocUpdate,
                                        'MN'
                                    )
                                "
                                :xaxis_categories="xaxisCategories.mauGiao"
                            />
                        </div>
                    </div>
                </div>
                <div
                    class="col-md-4 mb-4"
                    v-show="findLopHocName(dataLopHoc || dataLopHocUpdate, 'TH')"
                >
                    <div class="card">
                        <div class="card-header">
                            <div class="title">
                                LỚP HỌC
                                {{
                                    findLopHocName(
                                        dataLopHoc || dataLopHocUpdate,
                                        'TH'
                                    )
                                }}
                            </div>
                        </div>
                        <div class="card-body">
                            <StackedColumnChart
                                :data_StackedColumnChart="
                                    filterLopHoc(
                                        dataLopHoc || dataLopHocUpdate,
                                        'TH'
                                    )
                                "
                                :xaxis_categories="xaxisCategories.cap1"
                            />
                        </div>
                    </div>
                </div>
                <div
                    class="col-md-4 mb-4"
                    v-show="
                        findLopHocName(dataLopHoc || dataLopHocUpdate, 'THCS')
                    "
                >
                    <div class="card">
                        <div class="card-header">
                            <div class="title">
                                LỚP HỌC
                                {{
                                    findLopHocName(
                                        dataLopHoc || dataLopHocUpdate,
                                        'THCS'
                                    )
                                }}
                            </div>
                        </div>
                        <div class="card-body">
                            <StackedColumnChart
                                :data_StackedColumnChart="
                                    filterLopHoc(
                                        dataLopHoc || dataLopHocUpdate,
                                        'THCS'
                                    )
                                "
                                :xaxis_categories="xaxisCategories.cap2"
                            />
                        </div>
                    </div>
                </div>
                <div
                    class="col-md-4 mb-4"
                    v-show="
                        findLopHocName(dataLopHoc || dataLopHocUpdate, 'THPT')
                    "
                >
                    <div class="card">
                        <div class="card-header">
                            <div class="title">
                                LỚP HỌC
                                {{
                                    findLopHocName(
                                        dataLopHoc || dataLopHocUpdate,
                                        'THPT'
                                    )
                                }}
                            </div>
                        </div>
                        <div class="card-body">
                            <StackedColumnChart
                                :data_StackedColumnChart="
                                    filterLopHoc(
                                        dataLopHoc || dataLopHocUpdate,
                                        'THPT'
                                    )
                                "
                                :xaxis_categories="xaxisCategories.cap3"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import StackedColumnChart from '@/components/StackedColumnChart.vue'
import ESelect from '@/components/ESelect.vue'
import CustomButton from '@/components/CustomButton.vue'
import { Router } from '@/constants/Router'
import sendRequest from '@/services'
import Api from '@/constants/Api'
export default {
    name: 'ClassRoleSchool',
    components: {
        CustomButton,
        // ESelect,
        StackedColumnChart
    },
    data() {
        return {
            selectedValue: [],
            xaxisCategories: {
                mauGiao: [
                    '3-12 tháng',
                    '13-24 tháng',
                    '25-36 tháng',
                    '3-4 tuổi',
                    '4-5 tuổi',
                    '5-6 tuổi'
                ],
                cap1: ['Khối 1', 'Khối 2', 'Khối 3', 'Khối 4', 'Khối 5'],
                cap2: ['Khối 6', 'Khối 7', 'Khối 8', 'Khối 9'],
                cap3: ['Khối 10', 'Khối 11', 'Khối 12']
            },
            dataLopHocUpdate: {} || []
        }
    },
    props: {
        dataLopHoc: {
            type: Object,
            required: true
        },
        dataESelectLopHoc: {
            type: Array,
            default: function () {
                return [] // hoặc giá trị mặc định của bạn cho mảng
            }
        },
        selectedValueSchoolYear: {
            type: String
        }
    },

    methods: {
        async handleThongKe() {
            const maTruongs = this.selectedValue.map((item) => item.value)
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Data = {
                    namHoc: this.selectedValueSchoolYear,
                    maTruongs: maTruongs
                }
                const response = await sendRequest(
                    Api.roleSchool.dataLopHoc,
                    request_Data,
                    request_Header
                )
                this.dataLopHocUpdate = response.item
            } catch (error) {
                console.log(error)
            }
        },
        handleNavigate() {
            this.$router.push(Router.traCuu.traCuuLopHoc)
        },
        filterLopHoc(data, name) {
            return data.toanTruong?.filter((item) => item.name === name) || []
        },
        findLopHocName(dataLopHoc, maLop) {
            const lopHoc = dataLopHoc.toanTruong?.find(
                (lop) => lop.name === maLop
            )

            return lopHoc ? lopHoc.name : ''
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>

<style scoped>
.school {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 15px;
    padding-left: 6px;
    padding-right: 6px;
}

.header {
    padding: 12px 0px 12px 0px;
}
.header .left,
.header .right {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #186ab2;
    font-weight: 700;
    text-transform: uppercase;
    margin-right: 10px;
}
.header .layout-icon {
    margin-right: 8px;
}
.header .title .span {
    color: #333;
    font-weight: 600;
}
.header .left .icon {
    margin-right: 5px;
}

.header .right .icon {
    font-size: 18px;
    color: #000000;
    font-weight: 700;
    margin-right: 5px;
}

.layout-card {
    padding: 0px 10px 0px 10px;
}
.layout-card .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f2f3f8;
    height: 25px;
    color: black;
    padding: 5px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.layout-card .card-header .title {
    margin: -5px 0px 0px 10px;
    color: #000000;
    font-weight: bold;
    font-size: 15px;
}
.layout-card .card-body {
    padding: 5px;
    background: white;
    border: #f2f3f8 2px solid;
    height: 300px;
}
.custom-btn {
    font-weight: 700;
    color: #fff;
    background-color: #6aab9c;
}
</style>
