<template>
    <el-dialog
        :visible="modalChuyenTruongVisible"
        @close="onCloseModal"
        width="40%"
    >
        <div slot="title" class="header-modal">
            Chuyển trường - Học sinh {{ data_modalChuyenTruong.hoTen }}
        </div>
        <hr />
        <div class="row">
            <div class="col-sm-3">
                <el-radio disabled v-model="radio" :label="1"
                    >Trường chuyển đến</el-radio
                >
            </div>
        </div>
        <div class="row layout mt-4">
            <div class="col-sm-3">Tỉnh thành</div>
            <div class="col-sm-9">
                <ESelect
                    :reset="resetESelectProvince"
                    @reset-completed="handleResetESelectProvince"
                    style="width: 100%"
                    no-match-text="Không tìm thấy bản ghi nào"
                    no-data-text="danh sách lựa chọn trống"
                    :clearable="true"
                    :data="ESelectProvince"
                    :placeholder="'Chọn'"
                    :filterable="true"
                    :multiple="false"
                    :collapse-tags="true"
                    :fields="['tenTinhThanh', 'maTinhThanh']"
                    v-model="selectedValue.selectedValueProvince"
                    @change="handleChangeTinhThanh"
                />
            </div>
        </div>

        <div class="row layout mt-4">
            <div class="col-sm-3">Đơn vị quản lý</div>
            <div class="col-sm-9">
                <ESelect
                    :reset="resetESelectUnitEducation"
                    @reset-completed="handleResetESelectUnitEducation"
                    style="width: 100%"
                    no-match-text="Không tìm thấy bản ghi nào"
                    no-data-text="danh sách lựa chọn trống"
                    :clearable="true"
                    :disabled="maTinhThanh === undefined || maTinhThanh === ''"
                    :data="getDataESelect.ESelectUnitEducation"
                    :placeholder="'Chọn'"
                    :filterable="true"
                    :multiple="false"
                    :collapse-tags="true"
                    :fields="['tenDonVi', 'maDonVi']"
                    v-model="selectedValue.selectedValueUnitEducation"
                    @input="getDataChonTruongHoc"
                />
            </div>
        </div>

        <div class="row layout mt-4">
            <div class="col-sm-3">Cấp học</div>
            <div class="col-sm-9">
                <ESelect
                    :reset="resetESelectGradeLevel"
                    @reset-completed="handleResetESelectGradeLevel"
                    style="width: 100%"
                    no-match-text="Không tìm thấy bản ghi nào"
                    no-data-text="danh sách lựa chọn trống"
                    :clearable="true"
                    :disabled="maTinhThanh === undefined || maTinhThanh === ''"
                    :data="getDataESelect.ESelectGradeLevel"
                    :placeholder="'Chọn'"
                    :filterable="true"
                    :multiple="false"
                    :collapse-tags="true"
                    :fields="['tenTruongHoc', 'value']"
                    v-model="selectedValue.selectedValueGradeLevel"
                    @input="getDataChonTruongHoc"
                />
            </div>
        </div>

        <div class="row layout mt-4">
            <div class="col-sm-3">Trường học <span class="error">*</span></div>
            <div class="col-sm-9">
                <ESelect
                    :reset="resetESelectSchool"
                    @reset-completed="handleResetSchoolCompleted"
                    style="width: 100%"
                    no-match-text="Không tìm thấy bản ghi nào"
                    no-data-text="danh sách lựa chọn trống"
                    :clearable="true"
                    :disabled="maTinhThanh === undefined || maTinhThanh === ''"
                    :data="getDataESelect.ESelectSchool"
                    :placeholder="'Chọn'"
                    :filterable="true"
                    :multiple="false"
                    :collapse-tags="true"
                    :fields="['title', 'value']"
                    v-model="selectedValue.selectedValueSchool"
                    @change="handleChangeTruongHoc"
                />
                <span
                    v-if="
                        err_maTruongHoc.length > 0 &&
                        (maTinhThanh !== undefined || maTinhThanh !== '')
                    "
                    class="error"
                    >{{ err_maTruongHoc }}</span
                >
            </div>
        </div>
        <div
            class="row layout mt-4"
            v-if="
                dataModal.capHoc == 1 ||
                dataModal.capHoc == 2 ||
                dataModal.capHoc == 3
            "
        >
            <div class="col-sm-3">Khối học</div>
            <div class="col-sm-9">
                <el-select
                    filterable
                    style="width: 100%"
                    :no-data-text="'Không có bản ghi nào'"
                    :no-match-text="'Không tìm thấy bản ghi nào'"
                    clearable
                    v-model="selectedValue.selectedValueSchoolBlock"
                    placeholder="Chọn"
                    size="small"
                    :disabled="true"
                >
                    <el-option
                        v-for="item in getDataESelect.ESelectSchoolBlock"
                        :key="item.value"
                        :label="item.title"
                        :value="item.value"
                    />
                </el-select>
            </div>
        </div>
        <div
            v-if="dataModal.capHoc == 4 || dataModal.capHoc == 5"
            class="row layout mt-4"
        >
            <div class="col-sm-3">Khối học <span>*</span></div>
            <div class="col-sm-9">
                <el-select
                    filterable
                    style="width: 100%"
                    :no-data-text="'Không có bản ghi nào'"
                    :no-match-text="'Không tìm thấy bản ghi nào'"
                    clearable
                    v-model="selectedValue.selectedValueAgeGroup"
                    placeholder="Chọn"
                    size="small"
                    :disabled="true"
                >
                    <el-option
                        v-for="item in getDataESelect.ESelectAgeGroup"
                        :key="item.value"
                        :label="item.title"
                        :value="item.value"
                    />
                </el-select>
            </div>
        </div>
        <div class="row layout mt-4">
            <div class="col-sm-3">Lớp học <span class="error">*</span></div>
            <div class="col-sm-9">
                <el-select
                    filterable
                    style="width: 100%"
                    :no-data-text="'Không có bản ghi nào'"
                    :no-match-text="'Không tìm thấy bản ghi nào'"
                    clearable
                    v-model="selectedValue.selectedValueClass"
                    placeholder="Chọn"
                    size="small"
                    :disabled="
                        dataModal.capHoc === 4 ||
                        dataModal.capHoc === 5 ||
                        maTinhThanh === undefined ||
                        maTinhThanh === ''
                    "
                >
                    <el-option
                        v-for="item in getDataESelect.ESelectClass"
                        :key="item.maLopHoc"
                        :label="item.tenLopHoc"
                        :value="item.maLopHoc"
                    />
                </el-select>
                <span
                    v-if="
                        err_maLopHoc.length > 0 &&
                        (maTinhThanh !== undefined || maTinhThanh !== '')
                    "
                    class="error"
                    >{{ err_maLopHoc }}</span
                >
            </div>
        </div>
        <div class="row layout mt-4">
            <div class="col-sm-3">Ngày chuyển <span class="error">*</span></div>
            <div class="col-sm-9">
                <el-date-picker
                    v-model="selectedValue.date"
                    type="date"
                    placeholder="Ngày chuyển"
                    style="width: 100%"
                    format="dd/MM/yyyy"
                    size="small"
                >
                </el-date-picker>
                <span
                    v-if="
                        err_date.length > 0 &&
                        (maTinhThanh !== undefined || maTinhThanh !== '')
                    "
                    class="error"
                    >{{ err_date }}</span
                >
            </div>
        </div>
        <div class="row layout mt-4">
            <div class="col-sm-3">Lý do chuyển trường</div>
            <div class="col-sm-9">
                <CustomInput
                    v-model="selectedValue.selectedValue_lyDoChuyenTruong"
                    placeholder="Nhập ..."
                    :disabled="false"
                    :clearable="false"
                    size="small"
                    type="textarea"
                >
                </CustomInput>
            </div>
        </div>

        <span slot="footer">
            <CustomButton
                @click="onCloseModal"
                label="Đóng"
                size="small"
                class="btn-default btn-close-modal"
            />
            <CustomButton
                label="Xác nhận"
                size="small"
                class="btn-default btn-add"
                @click="handleChuyenTruong"
            />
        </span>
    </el-dialog>
</template>

<script>
import moment from 'moment'
import ESelect from '@/components/ESelect.vue'
import {
    ESelectAgeGroup_MockData,
    ESelectSchoolBlock_MockData
} from '@/mock_data'

import CustomButton from '@/components/CustomButton.vue'
import { mapState } from 'vuex'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomInput from '@/components/CustomInput.vue'
import { convertToFormattedDate, filterCapHocList, validateField } from '@/utils'

export default {
    name: 'ModalChitiet',
    components: { CustomInput, ESelect, CustomButton },
    props: {
        data_modalChuyenTruong: {
            type: Object,
            required: true
        },
        modalChuyenTruongVisible: {
            type: Boolean,
            required: true
        },

        closeModalChuyenTruong: {
            type: Function,
            required: true
        },
        id_chuyenTruong: {
            type: Number,
            required: true
        },
        ESelectProvince: {
            type: Array
        }
    },
    data() {
        return {
            radio: 1,
            resetESelectProvince: false,
            resetESelectSchool: false,
            resetESelectGradeLevel: false,
            resetESelectUnitEducation: false,

            idHsTheoNam: 0,
            dataModal: {},
            khoiHoc: '',
            maTruong: '',
            namHoc: '',
            maNhomTuoiMn: '',
            tenLopHoc: '',

            getDataESelect: {
                ESelectProvince: [],
                ESelectUnitEducation: [], //donvi
                ESelectGradeLevel: [], //caphoc
                ESelectSchoolBlock: ESelectSchoolBlock_MockData, //khoihoc/nhomlop
                ESelectSchool: [], //truonghoc

                ESelectAgeGroup: ESelectAgeGroup_MockData, //nhomtuoi
                ESelectClass: [] //lophoc
            },
            selectedValue: {
                selectedValueProvince: [],
                selectedValueUnitEducation: '', //donvi
                selectedValueGradeLevel: '', //caphoc
                selectedValueSchoolBlock: '', //khoihoc/nhomlop
                selectedValueSchool: [], //truonghoc
                selectedValue_lyDoChuyenTruong: '',
                date: '',

                selectedValueAgeGroup: '', //nhomtuoi
                selectedValueClass: '' //lophoc
            },
            maTinhThanh: '',

            err_maTruongHoc: '',
            err_date: '',
            err_maLopHoc: ''
        }
    },

    watch: {
        async id_chuyenTruong(newValue) {
            if (this.modalChuyenTruongVisible === true) {
                this.idHsTheoNam = newValue
                await this.getDataModalThongTinHocSinh()

                await this.handleDataModal()
            }
        },
        maTinhThanh: {
            handler(newVal) {
                if (newVal === undefined || newVal === '') {
                    this.resetESelectUnitEducation = true
                    this.selectedValue.selectedValueUnitEducation = ''
                    this.resetESelectGradeLevel = true
                    this.selectedValue.selectedValueGradeLevel = ''
                    this.resetESelectSchool = true
                    this.selectedValue.selectedValueSchool = ''
                    this.selectedValue.selectedValueAgeGroup = ''

                    this.selectedValue.selectedValueClass = ''
                } else {
                    this.getDataChonTruongHoc()
                }
            },
            deep: true
        },

        'selectedValue.selectedValueUnitEducation': {
            handler(newVal, oldVal) {
                if (oldVal !== newVal) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        },
        'selectedValue.selectedValueGradeLevel': {
            handler(newVal, oldVal) {
                if (oldVal !== newVal) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        }
    },
    mounted() {
        this.getDataESelect.ESelectGradeLevel = filterCapHocList(
            this.authUser.capHocList
        )
        this.getCurrentDate()

        // this.getDataChonTruongHoc()
    },
    methods: {
        onCloseModal() {
            this.resetData()
            this.closeModalChuyenTruong()
        },
        resetData() {
            this.radio = 1
            this.selectedValue.selectedValueProvince = ''

            this.resetESelectProvince = true

            this.maTinhThanh = ''
            this.err_maTruongHoc = ''
            this.err_date = ''
            this.err_maLopHoc = ''
        },

        getCurrentDate() {
            this.selectedValue.date = new Date()
        },
        handleResetESelectProvince() {
            this.resetESelectProvince = false
        },
        handleResetSchoolCompleted() {
            this.resetESelectSchool = false
        },
        handleResetESelectUnitEducation() {
            this.resetESelectUnitEducation = false
        },
        handleResetESelectGradeLevel() {
            this.resetESelectGradeLevel = false
        },
        convertToValueArray(array) {
            return array.map((item) => item.value)
        },
        async handleChuyenTruong() {
            try {
                const maHocSinh = this.data_modalChuyenTruong.maHocSinh

                const maLopHoc = this.selectedValue.selectedValueClass

                const maTruongHoc = this.selectedValue.selectedValueSchool?.value
                const formattedDate = convertToFormattedDate(
                    this.selectedValue.date
                )
                const namHoc = moment(formattedDate, 'DD/MM/YYYY').format('YYYY')
                const fieldsToValidate = [
                    {
                        name: 'err_maTruongHoc',
                        value: maTruongHoc,
                        errorField: 'err_maTruongHoc',
                        errorMessage: 'Vui lòng chọn trường học'
                    },
                    {
                        name: 'err_maLopHoc',
                        value: maLopHoc,
                        errorField: 'err_maLopHoc',
                        errorMessage: 'Vui lòng chọn lớp học'
                    },
                    {
                        name: 'err_date',
                        value: this.selectedValue.date,
                        errorField: 'err_date',
                        errorMessage: 'Vui lòng chọn ngày chuyển trường'
                    }
                ]
                let isValid = true

                for (const field of fieldsToValidate) {
                    isValid = validateField(this, field, isValid)
                }
                if (isValid) {
                    const loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    })
                    const request_Header = {
                        token: this.authToken
                    }
                    const request_Data = {
                        maHocSinh: maHocSinh,

                        maLopHoc: maLopHoc,

                        maTruongHoc: maTruongHoc,
                        namHoc: namHoc,
                        ngayChuyen: formattedDate,
                        lyDo: this.selectedValue.selectedValue_lyDoChuyenTruong
                    }
                    const response = await sendRequest(
                        Api.traCuu.traCuuHocSinh.chuyenTruong,
                        request_Data,
                        request_Header
                    )
                    if (response.rc === 0) {
                        loading.close()
                        this.$notify({
                            title: 'Chuyển trường thành công',

                            type: 'success'
                        })
                        this.onCloseModal()
                    } else {
                        this.$notify({
                            title: 'Chuyển trường thành lỗi',

                            type: 'error'
                        })
                        loading.close()
                    }
                }
            } catch (error) {
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },

        async handleChangeTinhThanh() {
            this.maTinhThanh = this.selectedValue.selectedValueProvince?.value

            const maTinhThanh = this.selectedValue.selectedValueProvince?.value

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    maTinhThanh: maTinhThanh,
                    check: true,
                    start: 0,
                    limit: 9999
                }
                const response = await sendRequest(
                    Api.internal_api.dm_DonVi,
                    null,
                    request_Header,
                    request_Params
                )
                if (response.code === 200) {
                    loading.close()
                    this.getDataESelect.ESelectUnitEducation = response.rows
                }
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },

        async getDataChonTruongHoc() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const maDonVi = this.selectedValue.selectedValueUnitEducation?.value

            const capHoc = this.selectedValue.selectedValueGradeLevel?.value

            const maSo = this.selectedValue.selectedValueProvince?.value

            const request_Header = {
                token: this.authToken
            }

            const request_Data = {
                capHoc: capHoc ? [capHoc] : [],
                maDonVi: maDonVi ? [maDonVi] : []
            }

            let response = await sendRequest(
                Api.internal_api.dm_School,
                request_Data,
                request_Header,
                null
            )

            if (response.statusResponse == 0) {
                loading.close()
                this.getDataESelect.ESelectSchool = response.rows.map((item) => ({
                    title: `${item.tenTruongHoc} - [${item.maTruongHoc}]`,
                    value: item.maTruongHoc
                }))
            }
            loading.close()
        },

        async handleChangeTruongHoc() {
            await this.getDataESelectLopHoc()
        },
        async handleSelectChange_khoiHoc() {
            await this.getDataESelectLopHoc()
        },

        async handleSelectChange_nhomTuoi() {
            await this.getDataESelectLopHoc()
        },
        handleDataModal() {
            const newValue = this.dataModal
            this.khoiHoc = newValue.khoiHoc

            this.maTruong = newValue.maTruongHoc
            // this.selectedValue.selectedValueSchool = {
            //     value: newValue.maTruongHoc
            // }
            this.selectedValue
            this.namHoc = newValue.namHoc
            this.maNhomTuoiMn = newValue.maNhomTuoiMn
            this.tenLopHoc = newValue.tenLopHoc

            if (
                this.khoiHoc !== undefined &&
                this.maTruong !== undefined &&
                this.namHoc !== undefined
            ) {
                this.getDataESelectLopHoc()
            }

            const capHocMapping = {
                1: [
                    { value: 1, title: 'Khối 1' },
                    { value: 2, title: 'Khối 2' },
                    { value: 3, title: 'Khối 3' },
                    { value: 4, title: 'Khối 4' },
                    { value: 5, title: 'Khối 5' }
                ],
                2: [
                    { value: 6, title: 'Khối 6' },
                    { value: 7, title: 'Khối 7' },
                    { value: 8, title: 'Khối 8' },
                    { value: 9, title: 'Khối 9' }
                ],
                3: [
                    { value: 10, title: 'Khối 10' },
                    { value: 11, title: 'Khối 11' },
                    { value: 12, title: 'Khối 12' }
                ],
                4: [
                    {
                        value: 13,
                        title: 'Nhà trẻ'
                    },
                    {
                        value: 16,
                        title: 'Mẫu giáo'
                    }
                ],
                5: [
                    {
                        value: 13,
                        title: 'Nhà trẻ'
                    },
                    {
                        value: 16,
                        title: 'Mẫu giáo'
                    }
                ],
                45: [
                    {
                        value: 13,
                        title: 'Nhà trẻ'
                    },
                    {
                        value: 16,
                        title: 'Mẫu giáo'
                    }
                ]
            }

            this.getDataESelect.ESelectSchoolBlock =
                capHocMapping[newValue.capHoc] || []
            this.selectedValue.selectedValueAgeGroup = newValue.maNhomTuoiMn

            this.convertSelectedValue_MockData(
                this.selectedValue,
                'selectedValueSchoolBlock',
                this.getDataESelect.ESelectSchoolBlock,
                newValue.khoiHoc
            )
        },

        async getDataModalThongTinHocSinh() {
            if (this.idHsTheoNam !== 0) {
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    idHsTheoNam: this.idHsTheoNam
                }
                const response = await sendRequest(
                    Api.traCuu.traCuuHocSinh.chiTietHocSinh,
                    null,
                    request_Header,
                    request_Params
                )
                this.dataModal = response
            }
        },

        async getDataESelectLopHoc() {
            const selectedValueSchoolBlock =
                this.selectedValue.selectedValueSchoolBlock
            const selectedValueAgeGroup = this.selectedValue.selectedValueAgeGroup
            const selectedValueSchool =
                this.selectedValue.selectedValueSchool?.value

            const convertKhoiHoc = selectedValueSchoolBlock
                ? selectedValueSchoolBlock
                : this.khoiHoc
            const convertMaNhomTuoiMn = selectedValueAgeGroup
                ? selectedValueAgeGroup
                : this.maNhomTuoiMn
            const convertMaTruong = selectedValueSchool
                ? selectedValueSchool
                : this.maTruong

            const request_Header = {
                token: this.authToken
            }
            if (convertKhoiHoc !== null) {
                const request_Params = {
                    khoiHoc: convertKhoiHoc,
                    maTruong: convertMaTruong,
                    namHoc: this.namHoc,
                    maNhomTuoiMn: convertMaNhomTuoiMn
                }

                const response = await sendRequest(
                    Api.traCuu.traCuuHocSinh.danhSachLopHoc_HocSinh,
                    null,
                    request_Header,
                    request_Params
                )
                if (response.rc == 0) {
                    this.getDataESelect.ESelectClass = response.tableData

                    const convertSelectValue_LopHoc =
                        this.getDataESelect.ESelectClass?.filter(
                            (item) => item.tenLopHoc === this.tenLopHoc
                        )
                            .map((item) => item.maLopHoc)
                            .pop()
                    this.selectedValue.selectedValueClass =
                        convertSelectValue_LopHoc
                }
                if (response.rc != 0) {
                    this.$message({
                        type: 'warning',
                        message: response.rd
                    })
                    this.selectedValue.selectedValueClass = ''
                    this.getDataESelect.ESelectClass = response.tableData
                }
            }
        },

        convertSelectedValue_MockData(
            selectedValueArray,
            selectedValueKey,
            dataESelectArray,
            newValue
        ) {
            const convertedValue = dataESelectArray
                ?.filter((item) => item.value === newValue)
                .map((item) => item.value)
                .pop()

            selectedValueArray[selectedValueKey] = convertedValue
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped>
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.bg-search,
.excel {
    background-color: var(--teal);
}
.btn-add {
    background-color: var(--light-green);
}

.btn-up {
    background-color: var(--yellow);
}
.btn-update {
    background-color: var(--yellow);
}
.btn-delete {
    background-color: var(--orange);
}
.btn-close-modal {
    background-color: var(--orange);
}
.layout,
.header-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
}
hr {
    border: 1px solid #8080807d;
    margin-bottom: 30px;
}

::v-deep .el-dialog__header {
    padding: 20px 20px 0px !important;
}
::v-deep .el-dialog__body {
    padding: 0px 20px 20px 20px !important;
}
::v-deep .el-radio__input.is-disabled .el-radio__inner,
.el-radio__input.is-disabled.is-checked .el-radio__inner {
    background: gray;
}

::v-deep .el-radio__input.is-checked .el-radio__inner {
    border-color: gray;
}
::v-deep .el-radio__input.is-disabled + span.el-radio__label {
    color: black;
    font-weight: 600;
}
::v-deep .el-input.is-disabled .el-input__inner {
    background-color: #eee !important;
    color: black !important;
}
/* :v--deep .el-input .el-select__tags.is-disabled {
    background-color: #eee !important;
    color: black !important;
} */
</style>
