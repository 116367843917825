<template>
    <div>
        <CustomBreadcrumb
            :title="'TIẾP NHẬN DỮ LIỆU QLNT'"
            :content="'BÁO CÁO TÌNH HÌNH NỘP DỮ LIỆU THEO TRƯỜNG HỌC'"
        />
        <div class="content">
            <div class="row">
                <div class="col-4">
                    <label>Đơn vị quản lý</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="
                                this.authUser.role === 5 ||
                                this.authUser.role === 4
                            "
                            :data="this.dataChonDonVi_Store"
                            :placeholder="'Chọn đơn vị'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenDonVi', 'maDonVi']"
                            :value="selectedValue.selectedValueUnitEducation"
                            @change="
                                handleESelectChange(
                                    'ESelectUnitEducation',
                                    ...$event
                                )
                            "
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Cấp học</label>
                    <div>
                        <ESelect
                            :disabled="false"
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :data="getDataESelect.ESelectGradeLevel"
                            :placeholder="'Chọn cấp học'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenTruongHoc', 'value']"
                            :value="selectedValue.selectedValueGradeLevel"
                            @change="
                                handleESelectChange(
                                    'ESelectGradeLevel',
                                    ...$event
                                )
                            "
                        />
                    </div>
                </div>
                <div class="col-4">
                    <label>Trường học <sup style="color: red">*</sup></label>
                    <div>
                        <ESelect
                            :reset="resetESelectSchool"
                            @reset-completed="handleResetCompleted"
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="this.authUser.role === 5"
                            :data="getDataESelect.ESelectSchool"
                            :placeholder="'Chọn trường học'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenTruongHoc', 'maTruongHoc']"
                            :value="selectedValue.selectedValueSchool"
                            @change="
                                handleESelectChange(
                                    'ESelectSchool_MockData',
                                    ...$event
                                )
                            "
                        />
                    </div>
                    <div
                        v-if="err_maTruongHocList_Change.length > 0"
                        class="error"
                    >
                        {{ err_maTruongHocList_Change }}
                    </div>
                </div>

                <div class="col-2">
                    <label>Năm học <sup style="color: red">*</sup></label>
                    <div>
                        <ESelectYear
                            v-model="selectedValue.selectedValueSchoolYear"
                            placeholder="Chọn năm"
                            size="small"
                            :no-data-text="'Không có bản ghi nào'"
                            :no-match-text="'Không tìm thấy bản ghi nào'"
                        ></ESelectYear>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 10px">
                <div class="col-2">
                    <label>Học kỳ</label>
                    <div>
                        <ESelectOne
                            :clearable="true"
                            :disabled="false"
                            :value="selectedValue.selectedValueSemester"
                            :data="getDataESelect.ESelectSemester_MockData"
                            placeholder="Chọn học kỳ"
                            :filterable="true"
                            :collapseTags="false"
                            :fields="['tenHocKy', 'value']"
                            :no-data-text="'Không có bản ghi nào'"
                            :no-match-text="'Không tìm thấy bản ghi nào'"
                            @change="
                                handleESlectOneChange(
                                    'ESelectSemester_MockData',
                                    $event
                                )
                            "
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label
                        >Loại dữ liệu
                        <sup style="color: red">*</sup>
                    </label>
                    <div>
                        <ESelectOne
                            :clearable="true"
                            :disabled="false"
                            :value="selectedValue.selectedValueDataType"
                            :data="getDataESelect.ESelectDataType_MockData"
                            placeholder="Chọn dữ liệu"
                            :filterable="true"
                            :collapseTags="false"
                            :fields="['tenDulieu', 'value']"
                            :no-data-text="'Không có bản ghi nào'"
                            :no-match-text="'Không tìm thấy bản ghi nào'"
                            @change="
                                handleESlectOneChange(
                                    'ESelectDataType_MockData',
                                    $event
                                )
                            "
                        />
                        <div v-if="err_dataTypeTable.length > 0" class="error">
                            {{ err_dataTypeTable }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="layout-btn">
                <div>
                    <CustomButton
                        label="Tìm kiếm"
                        size="small"
                        class="bg-search btn-default"
                        @click="handleSearch"
                    />
                </div>
                <div style="margin-left: 10px">
                    <CustomButton
                        label="Xuất Excel"
                        size="small"
                        class="btn-excel btn-default"
                        @click="handleExportExcel"
                        :disabled="tableData.length === 0"
                    />
                </div>
            </div>
        </div>
        <div
            class="layout-options"
            :style="{
                'max-width': leftBarWidth()
            }"
        >
            <div>
                <chonSoLuongBanGhi @chonXongSoLuong="ChonSoLuongBanGhi($event)" />
            </div>
        </div>
        <div
            class="layout-table"
            :style="{
                'max-width': leftBarWidth()
            }"
        >
            <!-- table Tất cả -->
            <table
                v-if="dataTypeTable == -1"
                class="table table-bordered table-hover centered-table"
            >
                <thead>
                    <tr>
                        <th class="text-thead" rowspan="3">STT</th>
                        <th class="text-thead" rowspan="3">Mã trường</th>
                        <th class="text-thead" rowspan="3">Trường học</th>
                        <th class="text-thead" rowspan="3">Đối tác</th>
                        <th class="text-thead" rowspan="3">Cấp học</th>
                        <th class="text-thead" rowspan="3">Đơn vị quản lý</th>
                        <th class="text-thead" colspan="6">Cán bộ - Giáo viên</th>
                        <th class="text-thead" colspan="5">Lớp</th>
                        <th class="text-thead" colspan="3">Học sinh</th>
                        <th class="text-thead" colspan="5">Kết quả học tập</th>
                    </tr>
                    <tr>
                        <th class="text-thead" rowspan="2">
                            Tổng số CB-GV trên QLNT
                        </th>
                        <th class="text-thead" rowspan="2">
                            Tổng số CB-GV đã nộp
                        </th>
                        <th class="text-thead" rowspan="2">Ngày nộp cuối</th>
                        <th class="text-thead" colspan="3">Đề án ngoại ngữ</th>
                        <th class="text-thead" rowspan="2">
                            Tổng số lớp trên QLNT
                        </th>
                        <th class="text-thead" colspan="3">Số lớp đã nộp</th>
                        <th class="text-thead" rowspan="2">Ngày nộp cuối</th>
                        <th class="text-thead" rowspan="2">
                            Tổng số HS trên QLNT
                        </th>
                        <th class="text-thead" rowspan="2">Tổng số HS đã nộp</th>
                        <th class="text-thead" rowspan="2">Ngày nộp cuối</th>
                        <th class="text-thead" colspan="3">
                            Số HS đã nộp có KQHT
                        </th>
                        <th class="text-thead" rowspan="2">
                            Số HS Tốt nghiệp
                            <sup style="color: red">(1)</sup>
                        </th>
                        <th class="text-thead" rowspan="2">Ngày nộp cuối</th>
                    </tr>
                    <tr>
                        <th class="text-thead">Giáo viên ngoại ngữ</th>
                        <th class="text-thead">GV được BDNCNL Ngoại ngữ</th>
                        <th class="text-thead">GV được BDNCNL Sư phạm</th>
                        <th class="text-thead">Tổng</th>
                        <th class="text-thead">Số lớp ghép</th>
                        <th class="text-thead">Số lớp đơn</th>
                        <th class="text-thead">Học kỳ I</th>
                        <th class="text-thead">Học kỳ II</th>
                        <th class="text-thead">Cả năm</th>
                    </tr>
                </thead>

                <tbody>
                    <template v-if="tableData.length === 0">
                        <tr>
                            <td colspan="26" class="text-tbody">
                                Không có bản ghi nào.
                            </td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td class="text-tbody">
                            {{ (start / limit) * limit + index + 1 }}
                        </td>
                        <td class="text-tbody">
                            {{ item.maTruong }}
                        </td>
                        <td class="text-tbody text-left">
                            {{ item.tenTruong }}
                        </td>
                        <td class="text-tbody">
                            {{ item.maDoiTac }}
                        </td>
                        <td class="text-tbody">
                            <div v-if="item.capHoc == 1">Tiểu học</div>
                            <div v-else-if="item.capHoc == 2">THCS</div>
                            <div v-else-if="item.capHoc == 3">THPT</div>
                            <div v-else-if="item.capHoc == 4">Nhà trẻ</div>
                            <div v-else-if="item.capHoc == 5">Mẫu giáo</div>
                            <div v-else-if="item.capHoc == 6">GDTX</div>
                            <div v-else-if="item.capHoc == 45">Mầm non</div>
                            <div v-else-if="item.capHoc == 12">
                                Trường liên cấp TH+THCS
                            </div>
                            <div v-else-if="item.capHoc == 13">
                                Trường liên cấp TH+THPT
                            </div>
                            <div v-else-if="item.capHoc == 23">
                                Trường liên THCS+THPT
                            </div>
                            <div v-else-if="item.capHoc == 123">
                                Trường liên cấp TH+THCS+THPT
                            </div>
                        </td>
                        <td class="text-tbody">
                            {{ item.tenDonVi }}
                        </td>
                        <td class="text-tbody">
                            {{ item.dlCanBo.tongSoBanGhiTrenQlnt }}
                        </td>
                        <td class="text-tbody">
                            {{ item.dlCanBo.tongSoBanGhiDaNop }}
                        </td>
                        <td class="text-tbody">
                            {{ item.dlCanBo.ngayNopCuoi }}
                        </td>
                        <td class="text-tbody">
                            {{ item.dlDeAnNgoaiNgu.soGvNgoaiNgu }}
                        </td>
                        <td class="text-tbody">
                            {{ item.dlDeAnNgoaiNgu.soGvCoQuaTrinhBdncnlnn }}
                        </td>
                        <td class="text-tbody">
                            {{ item.dlDeAnNgoaiNgu.soGvCoQuaTrinhBdncnlsp }}
                        </td>
                        <td class="text-tbody">
                            {{ item.dlLopHoc.tongSoBanGhiTrenQlnt }}
                        </td>
                        <td class="text-tbody">
                            {{ item.dlLopHoc.tongSoBanGhiDaNop }}
                        </td>
                        <td class="text-tbody">
                            {{ item.dlLopHoc.soLopGhep }}
                        </td>
                        <td class="text-tbody">
                            {{ item.dlLopHoc.soLopDon }}
                        </td>
                        <td class="text-tbody">
                            {{ item.dlLopHoc.ngayNopCuoi }}
                        </td>
                        <td class="text-tbody">
                            {{ item.dlHocSinh.tongSoBanGhiTrenQlnt }}
                        </td>
                        <td class="text-tbody">
                            {{ item.dlHocSinh.tongSoBanGhiDaNop }}
                        </td>
                        <td class="text-tbody">
                            {{ item.dlHocSinh.ngayNopCuoi }}
                        </td>
                        <td class="text-tbody">
                            {{ getSoHocSinhTheoHocKy(item.dlKqht, 1) }}
                        </td>
                        <td class="text-tbody">
                            {{ getSoHocSinhTheoHocKy(item.dlKqht, 2) }}
                        </td>
                        <td class="text-tbody">
                            {{ getSoHocSinhTheoHocKy(item.dlKqht, 3) }}
                        </td>
                        <td class="text-tbody">
                            {{ getSoHocSinhTotNghiep(item.dlKqht) }}
                        </td>
                        <td class="text-tbody">
                            {{ getNgayNopCuoi(item.dlKqht) }}
                        </td>
                    </tr>
                </tbody>
            </table>

            <!-- table Cán bộ -->
            <table
                v-if="dataTypeTable == 2"
                class="table table-bordered table-hover centered-table"
            >
                <thead>
                    <tr>
                        <th class="text-thead">STT</th>
                        <th class="text-thead">Mã trường</th>
                        <th class="text-thead">Trường học</th>
                        <th class="text-thead">Đối tác</th>
                        <th class="text-thead">Cấp học</th>
                        <th class="text-thead">Đơn vị</th>
                        <th class="text-thead">Tổng số CB-GV đã nộp từ QLNT</th>
                        <th class="text-thead">
                            Số CB-GV đang làm việc đã nộp năm học trước
                        </th>
                        <th class="text-thead">Số CB-GV đang làm việc đã nộp</th>
                        <th class="text-thead">Ngày nộp cuối</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="tableData.length === 0">
                        <tr>
                            <td colspan="11" class="text-tbody">
                                Không có bản ghi nào.
                            </td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td class="text-tbody">
                            {{ (currentPage - 1) * limit + index + 1 }}
                        </td>
                        <td class="text-tbody">{{ item.maTruong }}</td>
                        <td class="text-tbody text-left">{{ item.tenTruong }}</td>
                        <td class="text-tbody">{{ item.maDoiTac }}</td>
                        <td class="text-tbody">
                            <div v-if="item.capHoc == 1">Tiểu học</div>
                            <div v-else-if="item.capHoc == 2">THCS</div>
                            <div v-else-if="item.capHoc == 3">THPT</div>
                            <div v-else-if="item.capHoc == 4">Nhà trẻ</div>
                            <div v-else-if="item.capHoc == 5">Mẫu giáo</div>
                            <div v-else-if="item.capHoc == 6">GDTX</div>
                            <div v-else-if="item.capHoc == 45">Mầm non</div>
                            <div v-else-if="item.capHoc == 12">
                                Trường liên cấp TH+THCS
                            </div>
                            <div v-else-if="item.capHoc == 13">
                                Trường liên cấp TH+THPT
                            </div>
                            <div v-else-if="item.capHoc == 23">
                                Trường liên THCS+THPT
                            </div>
                            <div v-else-if="item.capHoc == 123">
                                Trường liên cấp TH+THCS+THPT
                            </div>
                        </td>
                        <td class="text-tbody">{{ item.tenDonVi }}</td>
                        <td class="text-tbody">
                            {{ item.tongSoBanGhiTrenQlnt }}
                        </td>
                        <td class="text-tbody">
                            {{ item.tongSoBanGhiDaNopNamTruoc }}
                        </td>
                        <td class="text-tbody">{{ item.tongSoBanGhiDaNop }}</td>
                        <td class="text-tbody">{{ item.ngayNopCuoi }}</td>
                    </tr>
                </tbody>
            </table>

            <!-- table Lớp học -->
            <table
                v-if="dataTypeTable == 3"
                class="table table-bordered table-hover centered-table"
            >
                <thead>
                    <tr>
                        <th rowspan="2" class="text-thead">STT</th>
                        <th rowspan="2" class="text-thead">Mã trường</th>
                        <th rowspan="2" class="text-thead">Trường học</th>
                        <th rowspan="2" class="text-thead">Đối tác</th>
                        <th rowspan="2" class="text-thead">Cấp học</th>
                        <th rowspan="2" class="text-thead">Đơn vị</th>
                        <th rowspan="2" class="text-thead">
                            Tổng số lớp trên QLNT
                        </th>
                        <th colspan="4" class="text-thead">Số lớp đã nộp</th>
                        <th rowspan="2" class="text-thead">Ngày nộp cuối</th>
                    </tr>
                    <tr>
                        <th class="text-thead">Tổng năm học trước</th>
                        <th class="text-thead">Tổng</th>
                        <th class="text-thead">Số lớp ghép</th>
                        <th class="text-thead">Số lớp đơn</th>
                    </tr>
                </thead>

                <tbody>
                    <template v-if="tableData.length === 0">
                        <tr>
                            <td colspan="12" class="text-tbody">
                                Không có bản ghi nào.
                            </td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td class="text-tbody">
                            {{ (start / limit) * limit + index + 1 }}
                        </td>
                        <td class="text-tbody">{{ item.maTruong }}</td>
                        <td class="text-tbody text-left">{{ item.tenTruong }}</td>
                        <td class="text-tbody">{{ item.maDoiTac }}</td>
                        <td class="text-tbody">{{ item.capHoc }}</td>
                        <td class="text-tbody">{{ item.tenDonVi }}</td>
                        <td class="text-tbody">
                            {{ item.tongSoBanGhiTrenQlnt }}
                        </td>
                        <td class="text-tbody">
                            {{ item.tongSoBanGhiDaNopNamTruoc }}
                        </td>
                        <td class="text-tbody">
                            {{ item.tongSoBanGhiDaNop }}
                        </td>
                        <td class="text-tbody">
                            {{ item.soLopGhep }}
                        </td>
                        <td class="text-tbody">
                            {{ item.soLopDon }}
                        </td>
                        <td class="text-tbody">
                            {{ item.ngayNopCuoi }}
                        </td>
                    </tr>
                </tbody>
            </table>

            <!-- table Học sinh -->
            <table
                v-if="dataTypeTable == 4"
                class="table table-bordered table-hover centered-table"
            >
                <thead>
                    <tr>
                        <th class="text-thead">STT</th>
                        <th class="text-thead">Mã trường</th>
                        <th class="text-thead">Đối tác</th>
                        <th class="text-thead">Trường học</th>
                        <th class="text-thead">Cấp học</th>
                        <th class="text-thead">Đơn vị</th>
                        <th class="text-thead">Tổng số HS đang học trên QLNT</th>
                        <th class="text-thead">
                            Số HS đang học đã nộp năm học trước
                        </th>
                        <th class="text-thead">Số HS đang học đã nộp</th>
                        <th class="text-thead">Ngày nộp cuối</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="tableData.length === 0">
                        <tr>
                            <td colspan="11" class="text-tbody">
                                Không có bản ghi nào.
                            </td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td class="text-tbody">
                            {{ (start / limit) * limit + index + 1 }}
                        </td>
                        <td class="text-tbody">
                            {{ item.maTruong }}
                        </td>
                        <td class="text-tbody">
                            {{ item.maDoiTac }}
                        </td>
                        <td class="text-tbody text-left">
                            {{ item.tenTruong }}
                        </td>
                        <td class="text-tbody">
                            <div v-if="item.capHoc == 1">Tiểu học</div>
                            <div v-else-if="item.capHoc == 2">THCS</div>
                            <div v-else-if="item.capHoc == 3">THPT</div>
                            <div v-else-if="item.capHoc == 4">Nhà trẻ</div>
                            <div v-else-if="item.capHoc == 5">Mẫu giáo</div>
                            <div v-else-if="item.capHoc == 6">GDTX</div>
                            <div v-else-if="item.capHoc == 45">Mầm non</div>
                            <div v-else-if="item.capHoc == 12">
                                Trường liên cấp TH+THCS
                            </div>
                            <div v-else-if="item.capHoc == 13">
                                Trường liên cấp TH+THPT
                            </div>
                            <div v-else-if="item.capHoc == 23">
                                Trường liên THCS+THPT
                            </div>
                            <div v-else-if="item.capHoc == 123">
                                Trường liên cấp TH+THCS+THPT
                            </div>
                        </td>
                        <td class="text-tbody">
                            {{ item.tenDonVi }}
                        </td>
                        <td class="text-tbody">
                            {{ item.tongSoBanGhiTrenQlnt }}
                        </td>
                        <td class="text-tbody">
                            {{ item.tongSoBanGhiDaNopNamTruoc }}
                        </td>
                        <td class="text-tbody">
                            {{ item.tongSoBanGhiDaNop }}
                        </td>
                        <td class="text-tbody">
                            {{ item.ngayNopCuoi }}
                        </td>
                    </tr>
                </tbody>
            </table>

            <!-- table Kết quả học tập -->
            <table
                v-if="dataTypeTable == 5"
                class="table table-bordered table-hover centered-table"
            >
                <thead>
                    <tr>
                        <th class="text-thead">STT</th>
                        <th class="text-thead">Mã trường</th>
                        <th class="text-thead">Trường học</th>
                        <th class="text-thead">Đối tác</th>
                        <th class="text-thead">Cấp học</th>
                        <th class="text-thead">Đơn vị</th>
                        <th class="text-thead">Tổng số HS đang học trên QLNT</th>
                        <th class="text-thead">Số HS đã nộp</th>
                        <th class="text-thead">Số HS đã nộp có KQHT</th>
                        <th class="text-thead">
                            Số HS tốt nghiệp <sup style="color: red">(1)</sup>
                        </th>
                        <th class="text-thead">Ngày nộp cuối</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="tableData.length === 0">
                        <tr>
                            <td colspan="11" class="text-tbody">
                                Không có bản ghi nào.
                            </td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td class="text-tbody">
                            {{ (start / limit) * limit + index + 1 }}
                        </td>
                        <td class="text-tbody">
                            {{ item.maTruong }}
                        </td>
                        <td class="text-tbody text-left">
                            {{ item.tenTruong }}
                        </td>
                        <td class="text-tbody">
                            {{ item.maDoiTac }}
                        </td>
                        <td class="text-tbody">
                            <div v-if="item.capHoc == 1">Tiểu học</div>
                            <div v-else-if="item.capHoc == 2">THCS</div>
                            <div v-else-if="item.capHoc == 3">THPT</div>
                            <div v-else-if="item.capHoc == 4">Nhà trẻ</div>
                            <div v-else-if="item.capHoc == 5">Mẫu giáo</div>
                            <div v-else-if="item.capHoc == 6">GDTX</div>
                            <div v-else-if="item.capHoc == 45">Mầm non</div>
                            <div v-else-if="item.capHoc == 12">
                                Trường liên cấp TH+THCS
                            </div>
                            <div v-else-if="item.capHoc == 13">
                                Trường liên cấp TH+THPT
                            </div>
                            <div v-else-if="item.capHoc == 23">
                                Trường liên THCS+THPT
                            </div>
                            <div v-else-if="item.capHoc == 123">
                                Trường liên cấp TH+THCS+THPT
                            </div>
                        </td>
                        <td class="text-tbody">
                            {{ item.tenDonVi }}
                        </td>
                        <td class="text-tbody">
                            {{ item.tongSoBanGhiTrenQlnt }}
                        </td>
                        <td class="text-tbody">
                            {{ item.tongSoHocSinhDaNop }}
                        </td>
                        <td class="text-tbody">
                            {{ item.tongSoBanGhiDaNop }}
                        </td>
                        <td class="text-tbody">
                            {{ item.tongSoHocSinhTotNghiep }}
                        </td>
                        <td class="text-tbody">
                            {{ item.ngayNopCuoi }}
                        </td>
                    </tr>
                </tbody>
            </table>

            <!-- table Phòng học -->
            <table
                v-if="dataTypeTable == 14"
                class="table table-bordered table-hover centered-table"
            >
                <thead>
                    <tr>
                        <th class="text-thead">STT</th>
                        <th class="text-thead">Mã trường</th>
                        <th class="text-thead">Trường học</th>
                        <th class="text-thead">Đối tác</th>
                        <th class="text-thead">Cấp học</th>
                        <th class="text-thead">Đơn vị</th>
                        <th class="text-thead">Ngày nộp cuối</th>
                        <th class="text-thead">Ký hiệu</th>
                        <th class="text-thead">Tình trạng</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="tableData.length === 0">
                        <tr>
                            <td colspan="11" class="text-tbody">
                                Không có bản ghi nào.
                            </td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td class="text-tbody">
                            {{ (start / limit) * limit + index + 1 }}
                        </td>
                        <td class="text-tbody">
                            {{ item.maTruong }}
                        </td>
                        <td class="text-tbody text-left">
                            {{ item.tenTruong }}
                        </td>
                        <td class="text-tbody">
                            {{ item.maDoiTac }}
                        </td>
                        <td class="text-tbody">
                            <div v-if="item.capHoc == 1">Tiểu học</div>
                            <div v-else-if="item.capHoc == 2">THCS</div>
                            <div v-else-if="item.capHoc == 3">THPT</div>
                            <div v-else-if="item.capHoc == 4">Nhà trẻ</div>
                            <div v-else-if="item.capHoc == 5">Mẫu giáo</div>
                            <div v-else-if="item.capHoc == 6">GDTX</div>
                            <div v-else-if="item.capHoc == 45">Mầm non</div>
                            <div v-else-if="item.capHoc == 12">
                                Trường liên cấp TH+THCS
                            </div>
                            <div v-else-if="item.capHoc == 13">
                                Trường liên cấp TH+THPT
                            </div>
                            <div v-else-if="item.capHoc == 23">
                                Trường liên THCS+THPT
                            </div>
                            <div v-else-if="item.capHoc == 123">
                                Trường liên cấp TH+THCS+THPT
                            </div>
                        </td>
                        <td class="text-tbody">
                            {{ item.tenDonVi }}
                        </td>
                        <td class="text-tbody">
                            {{ item.ngayNopCuoi }}
                        </td>
                        <td class="text-tbody">
                            {{ item.kyHieuBieuMau }}
                        </td>
                        <td class="text-tbody">
                            <div v-html="item.duLieuLoi"></div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <!-- table Đề án ngoại ngữ -->
            <table
                v-if="dataTypeTable == 16"
                class="table table-bordered table-hover centered-table"
            >
                <thead>
                    <tr>
                        <th class="text-thead">STT</th>
                        <th class="text-thead">Mã trường</th>
                        <th class="text-thead">Trường học</th>
                        <th class="text-thead">Đối tác</th>
                        <th class="text-thead">Cấp học</th>
                        <th class="text-thead">Đơn vị</th>
                        <th class="text-thead">GV ngoại ngữ</th>
                        <th class="text-thead">GV được BDNCNL Ngoại ngữ</th>
                        <th class="text-thead">GV được BDNCNL Sư phạm</th>
                        <th class="text-thead">Ngày cuối nộp</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="tableData.length === 0">
                        <tr>
                            <td colspan="11" class="text-tbody">
                                Không có bản ghi nào.
                            </td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td class="text-tbody">
                            {{ (start / limit) * limit + index + 1 }}
                        </td>
                        <td class="text-tbody">
                            {{ item.maTruong }}
                        </td>
                        <td class="text-tbody text-left">
                            {{ item.tenTruong }}
                        </td>
                        <td class="text-tbody">
                            {{ item.maDoiTac }}
                        </td>
                        <td class="text-tbody">
                            <div v-if="item.capHoc == 1">Tiểu học</div>
                            <div v-else-if="item.capHoc == 2">THCS</div>
                            <div v-else-if="item.capHoc == 3">THPT</div>
                            <div v-else-if="item.capHoc == 4">Nhà trẻ</div>
                            <div v-else-if="item.capHoc == 5">Nhà trẻ</div>
                            <div v-else-if="item.capHoc == 45">Mầm non</div>
                            <div v-else-if="item.capHoc == 12">
                                Trường liên cấp TH+THCS
                            </div>
                            <div v-else-if="item.capHoc == 13">
                                Trường liên cấp TH+THPT
                            </div>
                            <div v-else-if="item.capHoc == 23">
                                Trường liên THCS+THPT
                            </div>
                            <div v-else-if="item.capHoc == 123">
                                Trường liên cấp TH+THCS+THPT
                            </div>
                        </td>
                        <td class="text-tbody">
                            {{ item.tenDonVi }}
                        </td>
                        <td class="text-tbody">
                            {{ item.soGvNgoaiNgu }}
                        </td>
                        <td class="text-tbody">
                            {{ item.soGvCoQuaTrinhBdncnlnn }}
                        </td>
                        <td class="text-tbody">
                            {{ item.soGvCoQuaTrinhBdncnlsp }}
                        </td>
                        <td class="text-tbody">
                            {{ item.ngayNopCuoi }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="layout-note">
            <CustomPagination
                v-show="total_rows > 0"
                :tongbanghi="total_rows"
                :soluonghienthi="soLuongBanGhiHienThi"
                :batdau="trangbatdau"
                @pageChange="layLai($event)"
            >
            </CustomPagination>

            <div class="table-note">
                <p class="note"><b>Ghi chú:</b></p>
                <ul class="note-list">
                    <li class="note-item">
                        <p class="note-text green">
                            Mầm non:
                            <span class="note-description"
                                >Số trẻ hoàn thành chương trình mầm non (Dữ liệu
                                nộp học kỳ 2).</span
                            >
                        </p>
                    </li>
                    <li class="note-item">
                        <p class="note-text green">
                            Tiểu học:
                            <span class="note-description"
                                >Số học sinh được đánh giá hoàn thành chương trình
                                tiểu học (Dữ liệu nộp Học kỳ 2 - Giao đoạn cuối
                                học kỳ 2).</span
                            >
                        </p>
                    </li>
                    <li class="note-item">
                        <p class="note-text green">
                            THCS,THPT:
                            <span class="note-description"
                                >Số học sinh được xét tốt nghiệp (Dữ liệu nộp học
                                kỳ cuối năm).</span
                            >
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import ESelect from '@/components/ESelect.vue'
import ESelectOne from '@/components/ESelectOne.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelectYear from '@/components/ESelectYear.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import chonSoLuongBanGhi from '@/components/chonSoLuongBanGhi.vue'
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'
import { ESelectSemester_MockData, ESelectDataType_MockData } from '@/mock_data'
import { convertCapHocList, currentYear } from '@/utils'
import { mapState } from 'vuex'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { validateField, filterCapHocList } from '@/utils'
export default {
    name: 'capTruongBCTinhHinhNopDuLieu',
    components: {
        ESelect,
        CustomButton,
        ESelectYear,
        ESelectOne,
        CustomPagination,
        chonSoLuongBanGhi,
        CustomBreadcrumb
    },
    data() {
        return {
            resetESelectSchool: false,

            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            soLuongBanGhiHienThi: 25,
            trangbatdau: false,
            request_Header: {
                Authorization: ''
            },
            request_Data: {
                capHocList: [],
                hocKy: '',
                loaiDuLieu: null,
                maDonViList: [],
                maTruongHocList: [],
                namHoc: null,
                start: null,
                limit: null
            },
            tableData: [],
            getDataESelect: {
                ESelectUnitEducation: [], //donvi
                ESelectGradeLevel: [], //caphoc
                ESelectSchool: [], //truonghoc
                ESelectShoolYear_MockData: [], //namhoc
                ESelectSemester_MockData: ESelectSemester_MockData, //hocki
                ESelectDataType_MockData: ESelectDataType_MockData //loaidulieu
            },

            selectedValue: {
                selectedValueUnitEducation: [], //donvi
                selectedValueGradeLevel: [], //caphoc
                selectedValueSchool: [], //truonghoc
                selectedValueSchoolYear: [], //namhoc
                selectedValueSemester: {
                    title: 'Tất cả',
                    value: ''
                }, //hocki
                selectedValueDataType: [] //loaidulieu
            },
            dataTypeTable: null, // hiển thị table theo kiểu dữ liệu
            err_maTruongHocList_Change: '',
            err_dataTypeTable: ''
        }
    },
    methods: {
        handleResetCompleted() {
            this.resetESelectSchool = false
        },

        handleESlectOneChange(source, selectedOptions) {
            switch (source) {
                case 'ESelectSemester_MockData':
                    this.selectedValue.selectedValueSemester = selectedOptions

                    break
                case 'ESelectDataType_MockData':
                    this.selectedValue.selectedValueDataType = selectedOptions
                    this.dataTypeTable =
                        this.selectedValue.selectedValueDataType.value // hiển thị table theo kiểu dữ liệu
                    this.tableData = [] // reset lại tableData mỗi lần select thay đổi
                    this.total_rows = 0
                    break
            }
        },
        handleESelectChange(source, ...selectedOptions) {
            switch (source) {
                case 'ESelectUnitEducation':
                    this.selectedValue.selectedValueUnitEducation =
                        selectedOptions
                    this.resetESelectSchool = true
                    this.selectedValue.selectedValueSchool = []
                    this.getDataESelectSchool()
                    break
                case 'ESelectGradeLevel':
                    this.selectedValue.selectedValueGradeLevel = selectedOptions
                    if (this.authUser.role !== 5) {
                        this.resetESelectSchool = true
                        this.selectedValue.selectedValueSchool = []
                        this.getDataESelectSchool()
                    }
                    break
                case 'ESelectSchool_MockData':
                    this.selectedValue.selectedValueSchool = selectedOptions
                    break
            }
        },
        async getDataESelectSchool() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const maDonVi = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueUnitEducation,
                    'selectedValueUnitEducation'
                )

                const capHoc = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueGradeLevel,
                    'selectedValueGradeLevel'
                )

                const request_Header = {
                    ...this.request_Header,
                    token: this.authToken
                }

                const request_Data = {
                    capHoc: capHoc,
                    maDonVi: maDonVi
                }
                const response = await sendRequest(
                    Api.internal_api.dm_School,
                    request_Data,
                    request_Header,
                    null
                )
                if (response) {
                    loading.close()
                    this.getDataESelect.ESelectSchool = response.rows.map(
                        (item) => ({
                            ...item,
                            tenTruongHoc: `${item.tenTruongHoc} - [${item.maTruongHoc}]`
                        })
                    )
                }
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },
        async handleSearch() {
            const maDonViList_Change = this.customhandleESelectedChange(
                this.selectedValue.selectedValueUnitEducation,
                'selectedValueUnitEducation'
            )
            const capHocList_Change = this.customhandleESelectedChange(
                this.selectedValue.selectedValueGradeLevel,
                'selectedValueGradeLevel'
            )
            const maTruongHocList_Change = this.customhandleESelectedChange(
                this.selectedValue.selectedValueSchool,
                'selectedValueSchool'
            )

            const hocKy = this.selectedValue.selectedValueSemester.value
            const loaiDuLieu_Change =
                this.selectedValue.selectedValueDataType.value
            const namHoc_Change = this.selectedValue.selectedValueSchoolYear

            const limit = this.limit
            const start = this.start

            const fieldsToValidate = [
                {
                    name: 'err_maTruongHocList_Change',
                    value: maTruongHocList_Change,
                    errorField: 'err_maTruongHocList_Change',
                    errorMessage: 'Vui lòng chọn trường học'
                },
                {
                    name: 'err_dataTypeTable',
                    value: this.dataTypeTable,
                    errorField: 'err_dataTypeTable',
                    errorMessage: 'Vui lòng chọn loại dữ liệu'
                }
            ]
            let isValid = true

            for (const field of fieldsToValidate) {
                isValid = validateField(this, field, isValid)
            }

            if (isValid) {
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading ...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })
                try {
                    const request_Data = {
                        ...this.request_Data,
                        maSo: this.authUser.province,
                        capHocList: capHocList_Change,
                        hocKy: hocKy,
                        loaiDuLieu: loaiDuLieu_Change,
                        maDonViList: maDonViList_Change,
                        maTruongHocList: maTruongHocList_Change,
                        namHoc: namHoc_Change || currentYear,
                        start: start,
                        limit: limit
                    }
                    const request_Header = {
                        token: this.authToken
                    }

                    const response = await sendRequest(
                        Api.tiepNhanDuLieuQLNT.baoCaoTinhHinhNopDuLieu.capTruong,
                        request_Data,
                        request_Header,
                        null
                    )

                    if (response.rc == 0) {
                        setTimeout(() => {
                            loading.close()
                            this.$message({
                                message: 'Danh sách dữ liệu',
                                type: 'success'
                            })
                        }, 1000)
                    } else {
                        this.$message({
                            message: 'Danh sách dữ liệu trống',
                            type: 'error'
                        })
                    }
                    this.tableData = response.tableData
                    this.total_rows = response.totalRows
                } catch (error) {
                    loading.close()
                    if (error.response?.data.rd) {
                        this.$message({
                            type: 'error',
                            message: error.response?.data.rd
                        })
                    }
                }
            }
        },
        handleExportExcel() {
            const maDonViList_Change = this.customhandleESelectedChange(
                this.selectedValue.selectedValueUnitEducation,
                'selectedValueUnitEducation'
            )
            const capHocList_Change = this.customhandleESelectedChange(
                this.selectedValue.selectedValueGradeLevel,
                'selectedValueGradeLevel'
            )
            const maTruongHocList_Change = this.customhandleESelectedChange(
                this.selectedValue.selectedValueSchool,
                'selectedValueSchool'
            )

            const hocKy = this.selectedValue.selectedValueSemester.value
            const loaiDuLieu_Change =
                this.selectedValue.selectedValueDataType.value
            const namHoc_Change = this.selectedValue.selectedValueSchoolYear

            const limit = this.limit
            const start = this.start

            const request_Data = {
                capHocList: capHocList_Change,
                hocKy: hocKy,
                loaiDuLieu: loaiDuLieu_Change,
                maDonViList: maDonViList_Change,
                maTruongHocList: maTruongHocList_Change,
                namHoc: namHoc_Change || currentYear
            }
            const request_Header = {
                token: this.authToken
            }
            const fieldsToValidate = [
                {
                    name: 'err_maTruongHocList_Change',
                    value: maTruongHocList_Change,
                    errorField: 'err_maTruongHocList_Change',
                    errorMessage: 'Vui lòng chọn trường học'
                },
                {
                    name: 'err_dataTypeTable',
                    value: this.dataTypeTable,
                    errorField: 'err_dataTypeTable',
                    errorMessage: 'Vui lòng chọn loại dữ liệu'
                }
            ]
            let isValid = true

            for (const field of fieldsToValidate) {
                isValid = validateField(this, field, isValid)
            }

            if (isValid) {
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading ...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })
                this.$confirm('Xác nhận tải xuống Excel?', 'Thông báo', {
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy',
                    type: 'warning'
                })
                    .then(async () => {
                        const response = await sendRequest(
                            Api.tiepNhanDuLieuQLNT.cauHinhLichDongBo.exportExcel,
                            request_Data,
                            request_Header
                        )
                        if (response.rc == 0) {
                            const domain =
                                process.env
                                    .VUE_APP_BACKEND_BASE_URL_DOWNLOAD_REPORT

                            const fileUrl = response.urlContext

                            window.open(
                                `${domain}https://dongbo.csdl.edu.vn${fileUrl}`,
                                '_blank'
                            )
                        }
                        if (response.rc == 1) {
                            this.$message({
                                type: 'error',
                                message: 'Có lỗi xảy ra, vui lòng thử lại'
                            })
                        }
                        loading.close()
                    })
                    .catch((error) => {
                        loading.close()
                        if (error.response?.data.rd) {
                            this.$message({
                                type: 'error',
                                message: error.response?.data.rd
                            })
                        }
                    })
            }
        },

        ChonSoLuongBanGhi(e) {
            this.total_rows = 0
            this.soLuongBanGhiHienThi = e.soluong
        },
        checkTruocKhiTim() {
            this.trangbatdau = !this.trangbatdau
        },
        layLai(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },

        customhandleESelectedChange(options, valueType) {
            switch (valueType) {
                case 'selectedValueUnitEducation':
                case 'selectedValueSchool':
                    return options.map((option) => option.value)
                case 'selectedValueGradeLevel':
                    return options.map((option) => Number(option.value))
            }
        },
        getCustomCapHoc(capHoc) {
            switch (capHoc) {
                case 1:
                    return 'Tiểu học'
                case 2:
                    return 'THCS'
                case 3:
                    return 'THPT'
                case 4:
                    return 'Nhà trẻ'
                case 5:
                    return 'Mẫu giáo'
                case 6:
                    return 'GDTX'
                case 45:
                    return 'Mầm non'
                case 12:
                    return 'Trường liên cấp TH+THCS'
                case 13:
                    return 'Trường liên cấp TH+THPT'
                case 23:
                    return 'Trường liên THCS+THPT'
                case 123:
                    return 'Trường liên cấp TH+THCS+THPT'
                default:
                    return ''
            }
        },
        getSoHocSinhTheoHocKy(e, a) {
            let hocKy = a
            let duLieu = e
            let result = ''

            for (let i = 0; i < duLieu.length; i++) {
                if (duLieu[i].hocKi == hocKy) {
                    result = duLieu[i].tongSoBanGhiDaNop
                }
            }
            return result
        },
        getSoHocSinhTotNghiep(e) {
            let duLieu = e
            let result = null
            for (let i = duLieu.length - 1; i >= 0; i--) {
                if (duLieu[i].hocKi == 3 && duLieu[i].tongSoHocSinhTotNghiep) {
                    result = duLieu[i].tongSoHocSinhTotNghiep
                    return result
                }
                if (duLieu[i].hocKi == 2 && duLieu[i].tongSoHocSinhTotNghiep) {
                    result = duLieu[i].tongSoHocSinhTotNghiep
                    return result
                }
                if (duLieu[i].hocKi == 1 && duLieu[i].tongSoHocSinhTotNghiep) {
                    result = duLieu[i].tongSoHocSinhTotNghiep
                    return result
                }
            }
            return result
        },
        getNgayNopCuoi(e) {
            let duLieu = e
            let result = null
            for (let i = duLieu.length - 1; i >= 0; i--) {
                if (duLieu[i].hocKi == 3 && duLieu[i].ngayNopCuoi) {
                    result = duLieu[i].ngayNopCuoi
                    return result
                }
                if (duLieu[i].hocKi == 2 && duLieu[i].ngayNopCuoi) {
                    result = duLieu[i].ngayNopCuoi

                    return result
                }
                if (duLieu[i].hocKi == 1 && duLieu[i].ngayNopCuoi) {
                    result = duLieu[i].ngayNopCuoi
                    return result
                }
            }
            return result
        },
        leftBarWidth() {
            if (this.isCollapseLeftBar) {
                return 'calc(100vw - 100px)'
            } else {
                return 'calc(100vw - 340px)'
            }
        }
    },
    watch: {
        'authUser.role': {
            immediate: true,
            handler(newRole, oldRole) {
                if (newRole === 5) {
                    this.selectedValue.selectedValueUnitEducation = [
                        { value: this.authUser.ma_don_vi }
                    ]
                    const convertedList = convertCapHocList(
                        this.authUser.capHocList
                    )
                    this.selectedValue.selectedValueGradeLevel = convertedList
                    this.selectedValue.selectedValueSchool = [
                        { value: this.authUser.ma_truong_hoc }
                    ]
                }
                if (newRole === 4) {
                    this.selectedValue.selectedValueUnitEducation = [
                        { value: this.authUser.ma_don_vi }
                    ]
                }

                this.getDataESelect.ESelectGradeLevel = filterCapHocList(
                    this.authUser.capHocList
                )
            }
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        },
        ...mapState(['isCollapseLeftBar'])
    },
    mounted() {
        this.getDataESelectSchool()
    }
}
</script>
<style scoped>
.content {
    background: #fff;
    padding: 10px 10px 10px 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}
.content label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px;
}
.content .title {
    margin-bottom: 5px;
    font-size: 18px;
    color: #212529;
}
.layout-btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 5px;
}
.layout-options {
    background: #fff;
    padding: 20px 10px 5px 10px;
    border-radius: 4px 4px 0px 0px;

    margin: 0 auto;
}
.layout-table {
    background: #fff;
    padding: 20px 10px 5px 10px;
    border-radius: 4px;

    margin: 0 auto;
    overflow-x: auto;
}
.layout-note {
    padding: 20px 10px 5px 30px;
    border-radius: 4px 4px 0px 0px;
    margin: 0 auto;
}
.hide-td {
    display: none;
}

table thead tr {
    background: #6aab9c;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
}
.table {
    white-space: nowrap;
}
.centered-table {
    margin-left: auto;
    margin-right: auto;
}

.table-bordered {
    background-color: #fff;
}

.table-bordered th,
.table-bordered td {
    text-align: center;
    border-width: 0 0 1px 1px;
    border-style: solid;
    border-color: #dedede;
}
.table-bordered th {
    padding: 9px 7px;
}
.table-bordered td {
    padding: 10px;
}
.table-hover tbody tr:hover {
    background-color: #f5f5f5;
}

.text-thead {
    font-size: 15px;
    text-align: center;
    vertical-align: middle;
}
.text-tbody {
    font-size: 15px;
}
.table-note {
    margin-top: 20px;
}

.note {
    /* Kiểu cho phần mở đầu ghi chú */
    font-weight: bold;
    color: #e14a02;
}

.note-list {
    /* Kiểu cho danh sách ghi chú */
    font-weight: bold;
    font-size: 14px;
}

.note-item {
    /* Kiểu cho từng mục ghi chú */
    font-size: 13px;
}

.note-text {
    /* Kiểu cho văn bản ghi chú */
    font-size: 13px;
    color: #325434;
}

.note-description {
    /* Kiểu cho mô tả ghi chú */
    font-size: 13px;
    color: #325434;
    font-weight: 400;
}
.text-left {
    text-align: left;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.bg-search {
    background-color: var(--teal);
}

.btn-excel,
.btn-excel:hover {
    background-color: var(--dark-green);
}

.error {
    padding-top: 5px;
    color: red;
}
::v-deep .el-tag.el-tag--info {
    color: black;
}
</style>
