<template>
    <el-dialog
        :visible="disabledModalVisible"
        @close="closeDisabledModal"
        width="60%"
        class="custom-dialog"
        :fullscreen="true"
    >
        <span slot="title" class="custom-dialog-title"
            >Thông tin Hồ sơ học sinh
        </span>

        <div class="row">
            <div class="col-12 col-sm-6 col-lg-2">
                <CardInfo
                    :moetCode="dataModal.maHocSinh"
                    :avatar="dataModal.avatar"
                />
            </div>
            <div class="col-12 col-sm-6 col-lg-5">
                <div
                    class="row d-flex align-items-center"
                    v-if="
                        dataModal.capHoc == 1 ||
                        dataModal.capHoc == 2 ||
                        dataModal.capHoc == 3
                    "
                >
                    <div class="col-4 title">Khối học <span>*</span></div>
                    <div class="col-8">
                        <el-select
                            style="width: 100%"
                            disabled
                            :no-data-text="'Không có bản ghi nào'"
                            :no-match-text="'Không tìm thấy bản ghi nào'"
                            clearable
                            v-model="selectedValue.selectedValueSchoolBlock"
                            placeholder=""
                            size="small"
                            @change="handleSelectChange_khoiHoc"
                            class="select"
                        >
                            <el-option
                                v-for="item in getDataESelect.ESelectSchoolBlock"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            />
                        </el-select>
                    </div>
                </div>
                <div
                    v-if="dataModal.capHoc == 4 || dataModal.capHoc == 5"
                    class="row d-flex align-items-center mt-3"
                >
                    <div class="col-4 title">Khối học <span>*</span></div>
                    <div class="col-8">
                        <el-select
                            style="width: 100%"
                            disabled
                            :no-data-text="'Không có bản ghi nào'"
                            :no-match-text="'Không tìm thấy bản ghi nào'"
                            clearable
                            v-model="selectedValue.selectedValueAgeGroup"
                            placeholder=""
                            size="small"
                            @change="handleSelectChange_nhomTuoi"
                            class="select"
                        >
                            <el-option
                                v-for="item in getDataESelect.ESelectAgeGroup"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            />
                        </el-select>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Lớp học <span>*</span></div>
                    <div class="col-8">
                        <el-select
                            style="width: 100%"
                            disabled
                            :no-data-text="'Không có bản ghi nào'"
                            :no-match-text="'Không tìm thấy bản ghi nào'"
                            clearable
                            v-model="selectedValue.selectedValueClass"
                            placeholder=""
                            size="small"
                        >
                            <el-option
                                v-for="item in getDataESelect.ESelectClass"
                                :key="item.maLopHoc"
                                :label="item.tenLopHoc"
                                :value="item.maLopHoc"
                            />
                        </el-select>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Họ tên<span>*</span></div>
                    <div class="col-8">
                        <el-input
                            :disabled="true"
                            placeholder=""
                            v-model="selectedValue.input_hoTen"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Ngày sinh <span>*</span></div>
                    <div class="col-8">
                        <date-picker
                            :disabled="true"
                            :config="configDatePicker"
                            placeholder=""
                            v-model="selectedValue.date_ngaySinh"
                            class="custom-date-picker"
                        >
                        </date-picker>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Giới tính <span>*</span></div>
                    <div class="col-8">
                        <el-select
                            style="width: 100%"
                            disabled
                            :no-data-text="'Không có bản ghi nào'"
                            :no-match-text="'Không tìm thấy bản ghi nào'"
                            clearable
                            v-model="selectedValue.selectedValueGender"
                            placeholder=""
                            size="small"
                        >
                            <el-option
                                v-for="item in getDataESelect.ESelectGender"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            />
                        </el-select>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Trạng thái HS <span>*</span></div>
                    <div class="col-8">
                        <el-select
                            style="width: 100%"
                            disabled
                            :no-data-text="'Không có bản ghi nào'"
                            :no-match-text="'Không tìm thấy bản ghi nào'"
                            clearable
                            v-model="selectedValue.selectedValueStatus"
                            placeholder=""
                            size="small"
                        >
                            <el-option
                                v-for="item in getDataESelect.ESelectStatus"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            />
                        </el-select>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Ngày trạng thái <span>*</span></div>
                    <div class="col-8">
                        <date-picker
                            :disabled="true"
                            :config="configDatePicker"
                            placeholder=""
                            v-model="selectedValue.date_ngayTrangThai"
                            class="custom-date-picker"
                        >
                        </date-picker>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Tôn giáo</div>
                    <div class="col-8">
                        <el-select
                            :disabled="true"
                            style="width: 100%"
                            :no-data-text="'Không có bản ghi nào'"
                            :no-match-text="'Không tìm thấy bản ghi nào'"
                            clearable
                            v-model="selectedValue.selectedValueReligion"
                            placeholder=""
                            size="small"
                        >
                            <el-option
                                v-for="item in this.dataChonTonGiao_Store"
                                :key="item.mappingCode"
                                :label="item.constantTitle"
                                :value="item.mappingCode"
                            />
                        </el-select>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Dân tộc <span>*</span></div>
                    <div class="col-8">
                        <el-select
                            style="width: 100%"
                            disabled
                            :no-data-text="'Không có bản ghi nào'"
                            :no-match-text="'Không tìm thấy bản ghi nào'"
                            clearable
                            v-model="selectedValue.selectedValueEthnicDirectory"
                            placeholder=""
                            size="small"
                        >
                            <el-option
                                v-for="item in this.dataChonDanToc_Store"
                                :key="item.mappingCode"
                                :label="item.constantTitle"
                                :value="item.mappingCode"
                            />
                        </el-select>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">
                        Dân tộc theo giấy khai sinh <span>*</span>
                    </div>
                    <div class="col-8">
                        <el-input
                            placeholder=""
                            v-model="selectedValue.input_danTocTheoGiayKhaiSinh"
                            size="small"
                            :disabled="true"
                        ></el-input>
                    </div>
                </div>

                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Mã định danh công dân</div>
                    <div class="col-8">
                        <el-input
                            placeholder=""
                            v-model="selectedValue.input_maDinhDanhCongDan"
                            size="small"
                            :disabled="true"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Mã định danh bộ GD&ĐT</div>
                    <div class="col-8">
                        <el-input
                            placeholder=""
                            v-model="selectedValue.input_maDinhDanhBoGDDT"
                            size="small"
                            :disabled="true"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Ngày vào trường</div>
                    <div class="col-8">
                        <el-input
                            placeholder=""
                            v-model="selectedValue.date_ngayVaoTruong"
                            size="small"
                            :disabled="true"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">
                        Thời điểm chuyển trường/bỏ học của học sinh
                    </div>
                    <div class="col-8">
                        <el-input
                            placeholder=""
                            v-model="selectedValue.input_thoiDiemRoiTruong"
                            size="small"
                            :disabled="true"
                        ></el-input>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-5">
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-12 title" style="color: blue">
                        Thông tin hộ khẩu, quê quán
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Quê quán</div>
                    <div class="col-8">
                        <el-input
                            :disabled="true"
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 4 }"
                            placeholder=""
                            v-model="selectedValue.input_queQuan"
                        >
                        </el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Địa chỉ thường trú</div>
                    <div class="col-8">
                        <el-input
                            :disabled="true"
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 4 }"
                            placeholder=""
                            v-model="selectedValue.input_diaChiThuongTru"
                        >
                        </el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-12 title" style="color: blue">
                        Thông tin khác
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Nơi sinh</div>
                    <div class="col-8">
                        <el-input
                            :disabled="true"
                            placeholder=""
                            v-model="selectedValue.input_noiSinh"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Chỗ ở hiện nay</div>
                    <div class="col-8">
                        <el-input
                            :disabled="true"
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 4 }"
                            placeholder=""
                            v-model="selectedValue.input_choOHienNay"
                        >
                        </el-input>
                    </div>
                </div>
                <!-- <div class="row d-flex align-items-center">
                    <div class="col-4 title">Mã học sinh</div>
                    <div class="col-8">
                        <el-input
                            placeholder=""
                            v-model="selectedValue.input_maHocSinh"
                            size="small"
                            :disabled="true"
                        ></el-input>
                    </div>
                </div> -->
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Nhóm máu</div>
                    <div class="col-8">
                        <el-select
                            :disabled="true"
                            style="width: 100%"
                            :no-data-text="'Không có bản ghi nào'"
                            :no-match-text="'Không tìm thấy bản ghi nào'"
                            clearable
                            v-model="selectedValue.selectedValueBloodGroup"
                            placeholder=""
                            size="small"
                        >
                            <el-option
                                v-for="item in this.dataChonNhommau_Store"
                                :key="item.mappingCode"
                                :label="item.constantTitle"
                                :value="item.mappingCode"
                            />
                        </el-select>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Thành phần gia đình</div>
                    <div class="col-8">
                        <el-select
                            :disabled="true"
                            style="width: 100%"
                            :no-data-text="'Không có bản ghi nào'"
                            :no-match-text="'Không tìm thấy bản ghi nào'"
                            clearable
                            v-model="selectedValue.selectedValueFamilyMember"
                            placeholder=""
                            size="small"
                        >
                            <el-option
                                v-for="item in this
                                    .dataChonThanhphangiadinh_Store"
                                :key="item.mappingCode"
                                :label="item.constantTitle"
                                :value="item.mappingCode"
                            />
                        </el-select>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Số điện thoại liên hệ</div>
                    <div class="col-8">
                        <el-input
                            :disabled="true"
                            placeholder=""
                            v-model="selectedValue.input_soDienThoai"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Email</div>
                    <div class="col-8">
                        <el-input
                            :disabled="true"
                            placeholder=""
                            v-model="selectedValue.input_email"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Sổ đăng bộ</div>
                    <div class="col-8">
                        <el-input
                            :disabled="true"
                            placeholder=""
                            v-model="selectedValue.input_soDangBo"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Thứ tự</div>
                    <div class="col-8">
                        <el-input
                            :disabled="true"
                            placeholder=""
                            v-model="selectedValue.input_thuTu"
                            size="small"
                        ></el-input>
                    </div>
                </div>
                <!-- <div class="row d-flex align-items-center mt-3">
                    <div class="col-4 title">Thôn xóm</div>
                    <div class="col-8">
                        <el-input
                            :disabled="true"
                            placeholder=""
                            v-model="selectedValue.input_thonXom"
                            size="small"
                        ></el-input>
                    </div>
                </div> -->
            </div>
        </div>
        <div style="margin-top: 30px">
            <el-tabs type="card">
                <el-tab-pane label="I. Thông tin cá nhân">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-lg-4">
                            <div class="row d-flex align-items-center mt-3">
                                <div class="col-5 title">Khu vực</div>
                                <div class="col-7">
                                    <el-select
                                        :disabled="true"
                                        style="width: 100%"
                                        :no-data-text="'Không có bản ghi nào'"
                                        :no-match-text="'Không tìm thấy bản ghi nào'"
                                        clearable
                                        v-model="selectedValue.selectedValueArea"
                                        placeholder=""
                                        size="small"
                                    >
                                        <el-option
                                            v-for="item in this
                                                .dataChonKhuvuc_Store"
                                            :key="item.mappingCode"
                                            :label="item.constantTitle"
                                            :value="item.mappingCode"
                                        />
                                    </el-select>
                                </div>
                            </div>
                            <div class="row d-flex align-items-center mt-3">
                                <div class="col-5 title">Khuyết tật</div>

                                <div class="col-7">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="selectedValue.checkbox_khuyetTat"
                                    />
                                </div>
                            </div>
                            <div class="row d-flex align-items-center mt-3">
                                <div class="col-5 title">Loại khuyết tật</div>
                                <div class="col-7">
                                    <el-select
                                        :disabled="true"
                                        style="width: 100%"
                                        :no-data-text="'Không có bản ghi nào'"
                                        :no-match-text="'Không tìm thấy bản ghi nào'"
                                        clearable
                                        v-model="
                                            selectedValue.selectedValueTypeOfDisability
                                        "
                                        placeholder=""
                                        size="small"
                                    >
                                        <el-option
                                            v-for="item in this
                                                .dataChonLoaiKhuyettat_Store"
                                            :key="item.mappingCode"
                                            :label="item.constantTitle"
                                            :value="item.mappingCode"
                                        />
                                    </el-select>
                                </div>
                            </div>
                            <div
                                v-if="
                                    dataModal.capHoc == 2 || dataModal.capHoc == 3
                                "
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-5 title">Khuyết tật không ĐG</div>

                                <div class="col-7">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_khuyetTatKhongDG
                                        "
                                    />
                                </div>
                            </div>
                            <div class="row d-flex align-items-center mt-3">
                                <div class="col-5 title">
                                    Đối tượng chính sách
                                </div>
                                <div class="col-7">
                                    <el-select
                                        :disabled="true"
                                        style="width: 100%"
                                        :no-data-text="'Không có bản ghi nào'"
                                        :no-match-text="'Không tìm thấy bản ghi nào'"
                                        clearable
                                        v-model="
                                            selectedValue.selectedValuePolicyObject
                                        "
                                        placeholder=""
                                        size="small"
                                    >
                                        <el-option
                                            v-for="item in this
                                                .dataChonDoituongchinhsach_Store"
                                            :key="item.mappingCode"
                                            :label="item.constantTitle"
                                            :value="item.mappingCode"
                                        />
                                    </el-select>
                                </div>
                            </div>
                            <div class="row d-flex align-items-center mt-3">
                                <div class="col-5 title">Đối tượng ưu tiên</div>
                                <div class="col-7">
                                    <el-select
                                        :disabled="true"
                                        style="width: 100%"
                                        :no-data-text="'Không có bản ghi nào'"
                                        :no-match-text="'Không tìm thấy bản ghi nào'"
                                        clearable
                                        v-model="
                                            selectedValue.selectedValuePriorityObject
                                        "
                                        placeholder=""
                                        size="small"
                                    >
                                        <el-option
                                            v-for="item in this
                                                .dataChonDoituonguutien_Store"
                                            :key="item.mappingCode"
                                            :label="item.constantTitle"
                                            :value="item.mappingCode"
                                        />
                                    </el-select>
                                </div>
                            </div>
                            <div
                                v-if="
                                    dataModal.capHoc == 1 ||
                                    dataModal.capHoc == 2 ||
                                    dataModal.capHoc == 3
                                "
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-5 title">Học CTGD của Bộ</div>

                                <div class="col-7">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="selectedValue.checkbox_hocCTGD"
                                    />
                                </div>
                            </div>
                            <div
                                v-if="dataModal.capHoc == 1"
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-5 title">
                                    Đã học lớp mẫu giáo 5 tuổi
                                </div>

                                <div class="col-7">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_hocLopMauGiao5tuoi
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                v-if="
                                    dataModal.capHoc == 2 || dataModal.capHoc == 3
                                "
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-5 title">
                                    Hướng nghiệp dậy nghề
                                </div>
                                <div class="col-7">
                                    <el-select
                                        :disabled="true"
                                        style="width: 100%"
                                        :no-data-text="'Không có bản ghi nào'"
                                        :no-match-text="'Không tìm thấy bản ghi nào'"
                                        clearable
                                        v-model="
                                            selectedValue.selectedValueVocationalTraining
                                        "
                                        placeholder=""
                                        size="small"
                                    >
                                        <el-option
                                            v-for="item in this
                                                .dataChonHuongnghiepdaynghe_Store"
                                            :key="item.mappingCode"
                                            :label="item.constantTitle"
                                            :value="item.mappingCode"
                                        />
                                    </el-select>
                                </div>
                            </div>
                            <div
                                v-if="
                                    dataModal.capHoc == 1 ||
                                    dataModal.capHoc == 2 ||
                                    dataModal.capHoc == 3
                                "
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-5 title">
                                    Học sinh biết bơi kỳ I
                                </div>

                                <div class="col-7">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_hocSinhBietBoiKy1
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                v-if="
                                    dataModal.capHoc == 1 ||
                                    dataModal.capHoc == 2 ||
                                    dataModal.capHoc == 3
                                "
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-5 title">
                                    Học sinh biết bơi kỳ II
                                </div>

                                <div class="col-7">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_hocSinhBietBoiKy2
                                        "
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4">
                            <div
                                v-if="
                                    dataModal.capHoc == 4 || dataModal.capHoc == 5
                                "
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-5 title">HS tuyển mới</div>

                                <div class="col-7">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_hocSinhTuyenMoi
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                v-if="
                                    dataModal.capHoc == 4 || dataModal.capHoc == 5
                                "
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-5 title">Trẻ lớp bán trú</div>

                                <div class="col-7">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_hocSinhBanTru
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                v-if="
                                    dataModal.capHoc == 4 || dataModal.capHoc == 5
                                "
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-5 title">Học 2 buổi</div>

                                <div class="col-7">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="selectedValue.checkbox_hoc2buoi"
                                    />
                                </div>
                            </div>
                            <div
                                v-if="
                                    dataModal.capHoc == 4 || dataModal.capHoc == 5
                                "
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-5 title">
                                    Học sinh biết bơi kỳ I
                                </div>

                                <div class="col-7">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_hocSinhBietBoiKy1
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                v-if="
                                    dataModal.capHoc == 4 || dataModal.capHoc == 5
                                "
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-5 title">
                                    Học sinh biết bơi kỳ II
                                </div>

                                <div class="col-7">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_hocSinhBietBoiKy2
                                        "
                                    />
                                </div>
                            </div>

                            <div
                                v-if="
                                    dataModal.capHoc == 2 || dataModal.capHoc == 3
                                "
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-5 title">CMTND/TCC</div>

                                <div class="col-7">
                                    <el-input
                                        :disabled="true"
                                        placeholder=""
                                        v-model="selectedValue.input_CMTND_TCC"
                                        size="small"
                                    ></el-input>
                                </div>
                            </div>
                            <div
                                v-if="
                                    dataModal.capHoc == 2 || dataModal.capHoc == 3
                                "
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-5 title">Ngày cấp</div>
                                <div class="col-7">
                                    <date-picker
                                        :config="configDatePicker"
                                        placeholder=""
                                        v-model="selectedValue.date_ngayCap"
                                        class="custom-date-picker"
                                    >
                                    </date-picker>
                                </div>
                            </div>
                            <div
                                v-if="
                                    dataModal.capHoc == 2 || dataModal.capHoc == 3
                                "
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-5 title">Nơi cấp</div>

                                <div class="col-7">
                                    <el-input
                                        :disabled="true"
                                        placeholder=""
                                        v-model="selectedValue.input_noiCap"
                                        size="small"
                                    ></el-input>
                                </div>
                            </div>
                            <div
                                v-if="
                                    dataModal.capHoc == 1 ||
                                    dataModal.capHoc == 2 ||
                                    dataModal.capHoc == 3
                                "
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-5 title">Đội viên</div>

                                <div class="col-7">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="selectedValue.checkbox_doiVien"
                                    />
                                </div>
                            </div>
                            <div
                                v-if="
                                    dataModal.capHoc == 2 || dataModal.capHoc == 3
                                "
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-5 title">Đoàn viên</div>

                                <div class="col-7">
                                    <el-checkbox
                                        v-model="selectedValue.checkbox_doanVien"
                                    />
                                </div>
                            </div>
                            <div
                                v-if="
                                    dataModal.capHoc == 1 ||
                                    dataModal.capHoc == 2 ||
                                    dataModal.capHoc == 3
                                "
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-5 title">Học tiếng dân tộc</div>

                                <div class="col-7">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_hocTiengDanToc
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                v-if="
                                    dataModal.capHoc == 1 ||
                                    dataModal.capHoc == 2 ||
                                    dataModal.capHoc == 3
                                "
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-5 title">Học tiếng song ngữ</div>

                                <div class="col-7">
                                    <el-checkbox
                                        v-model="
                                            selectedValue.checkbox_hocTiengSongNgu
                                        "
                                        :disabled="true"
                                    />
                                </div>
                            </div>
                            <div
                                v-if="
                                    dataModal.capHoc == 1 ||
                                    dataModal.capHoc == 2 ||
                                    dataModal.capHoc == 3
                                "
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-5 title">Loại nhập học</div>
                                <div class="col-7">
                                    <el-select
                                        :disabled="true"
                                        style="width: 100%"
                                        :no-data-text="'Không có bản ghi nào'"
                                        :no-match-text="'Không tìm thấy bản ghi nào'"
                                        clearable
                                        v-model="
                                            selectedValue.selectedValueAdmissionType
                                        "
                                        placeholder=""
                                        size="small"
                                    >
                                        <el-option
                                            v-for="item in this
                                                .dataChonLoainhaphoc_Store"
                                            :key="item.mappingCode"
                                            :label="item.constantTitle"
                                            :value="item.mappingCode"
                                        />
                                    </el-select>
                                </div>
                            </div>
                            <div
                                v-if="
                                    dataModal.capHoc == 1 ||
                                    dataModal.capHoc == 2 ||
                                    dataModal.capHoc == 3
                                "
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-5 title">
                                    Số buổi học trên tuần
                                </div>

                                <div class="col-7">
                                    <el-input
                                        :disabled="true"
                                        placeholder=""
                                        v-model="
                                            selectedValue.input_soBuoiHocTrenTuan
                                        "
                                        size="small"
                                    ></el-input>
                                </div>
                            </div>
                            <div
                                v-if="
                                    dataModal.capHoc == 1 ||
                                    dataModal.capHoc == 2 ||
                                    dataModal.capHoc == 3
                                "
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-5 title">Học sinh lớp B.Trú</div>

                                <div class="col-7">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_hocSinhLopBTru
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                v-if="
                                    dataModal.capHoc == 1 ||
                                    dataModal.capHoc == 2 ||
                                    dataModal.capHoc == 3
                                "
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-5 title">Học bán trú</div>
                                <div class="col-7">
                                    <el-select
                                        :disabled="true"
                                        style="width: 100%"
                                        :no-data-text="'Không có bản ghi nào'"
                                        :no-match-text="'Không tìm thấy bản ghi nào'"
                                        clearable
                                        v-model="
                                            selectedValue.selectedValueBoardingSchool
                                        "
                                        placeholder=""
                                        size="small"
                                    >
                                        <el-option
                                            v-for="item in this
                                                .dataChonHocbantru_Store"
                                            :key="item.mappingCode"
                                            :label="item.constantTitle"
                                            :value="item.mappingCode"
                                        />
                                    </el-select>
                                </div>
                            </div>

                            <div
                                v-if="dataModal.capHoc == 1"
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-5 title">
                                    Học sinh có nhu cầu hỗ trợ ngoại ngữ
                                </div>

                                <div class="col-7">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_hocSinhCoNhuCauHoTroNgoaiNgu
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                v-if="dataModal.capHoc == 1"
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-5 title">
                                    Tăng cường tiếng Việt
                                </div>

                                <div class="col-7">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_tangCuongTiengViet
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                v-if="dataModal.capHoc == 1"
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-5 title">
                                    Học sinh Dân tộc trợ giảng
                                </div>

                                <div class="col-7">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_hocSinhDanTocTroGiang
                                        "
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4">
                            <div
                                v-if="
                                    dataModal.capHoc == 1 ||
                                    dataModal.cap == 2 ||
                                    dataModal.capHoc == 3
                                "
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-7 title">
                                    Học sinh B.Trú D.Nuôi
                                </div>

                                <div class="col-5">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_hocSinhBTruDNuoi
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                v-if="
                                    dataModal.capHoc == 1 ||
                                    dataModal.cap == 2 ||
                                    dataModal.capHoc == 3
                                "
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-7 title">
                                    Học sinh N.Trú D.Nuôi
                                </div>

                                <div class="col-5">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_hocSinhNTruDNuoi
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                v-if="
                                    dataModal.capHoc == 1 ||
                                    dataModal.cap == 2 ||
                                    dataModal.capHoc == 3
                                "
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-7 title">Học sinh bán trú</div>

                                <div class="col-5">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_hocSinhBanTru
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                v-if="
                                    dataModal.capHoc == 1 ||
                                    dataModal.cap == 2 ||
                                    dataModal.capHoc == 3
                                "
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-7 title">Học sinh nội trú</div>

                                <div class="col-5">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_hocSinhNoiTru
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                v-if="
                                    dataModal.capHoc == 1 ||
                                    dataModal.cap == 2 ||
                                    dataModal.capHoc == 3
                                "
                                class="row d-flex align-items-center mt-3"
                            >
                                <div class="col-7 title">
                                    Học sinh PTDT bán trú
                                </div>

                                <div class="col-5">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_hocSinhPTDTBanTru
                                        "
                                    />
                                </div>
                            </div>
                            <div class="row d-flex align-items-center mt-3">
                                <div class="col-7 title">Có mẹ dân tộc</div>

                                <div class="col-5">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_coMeDanToc
                                        "
                                    />
                                </div>
                            </div>
                            <div class="row d-flex align-items-center mt-3">
                                <div class="col-7 title">Có cha dân tộc</div>

                                <div class="col-5">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_coChaDanToc
                                        "
                                    />
                                </div>
                            </div>
                            <div class="row d-flex align-items-center mt-3">
                                <div class="col-7 title">
                                    Phụ huynh có Smartphone
                                </div>

                                <div class="col-5">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_phuHuynhCoSmartPhone
                                        "
                                    />
                                </div>
                            </div>
                            <div class="row d-flex align-items-center mt-3">
                                <div class="col-7 title">
                                    Phụ huynh có Máy tính Internet
                                </div>

                                <div class="col-5">
                                    <el-checkbox
                                        :disabled="true"
                                        v-model="
                                            selectedValue.checkbox_phuHuynhCoInternet
                                        "
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="II. Thông tin liên hệ gia đình">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-lg-4">
                            <div class="row d-flex align-items-center mt-3">
                                <div class="col-4 title">Họ tên bố</div>

                                <div class="col-8">
                                    <el-input
                                        :disabled="true"
                                        placeholder=""
                                        v-model="selectedValue.input_hoTenBo"
                                        size="small"
                                    ></el-input>
                                </div>
                            </div>
                            <div class="row d-flex align-items-center mt-3">
                                <div class="col-4 title">Nghề nghiệp bố</div>

                                <div class="col-8">
                                    <el-input
                                        :disabled="true"
                                        placeholder=""
                                        v-model="selectedValue.input_ngheNghiepBo"
                                        size="small"
                                    ></el-input>
                                </div>
                            </div>
                            <div class="row d-flex align-items-center mt-3">
                                <div class="col-4 title">Năm sinh bố</div>

                                <div class="col-8">
                                    <date-picker
                                        :disabled="true"
                                        :config="configDatePicker"
                                        placeholder=""
                                        v-model="selectedValue.date_namSinhBo"
                                        class="custom-date-picker"
                                    >
                                    </date-picker>
                                </div>
                            </div>
                            <div class="row d-flex align-items-center mt-3">
                                <div class="col-4 title">Nơi làm việc bố</div>

                                <div class="col-8">
                                    <el-input
                                        :disabled="true"
                                        placeholder=""
                                        v-model="selectedValue.input_noiLamViecBo"
                                        size="small"
                                    ></el-input>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-sm-6 col-lg-4">
                            <div class="row d-flex align-items-center mt-3">
                                <div class="col-4 title">Họ tên mẹ</div>

                                <div class="col-8">
                                    <el-input
                                        :disabled="true"
                                        placeholder=""
                                        v-model="selectedValue.input_hoTenMe"
                                        size="small"
                                    ></el-input>
                                </div>
                            </div>
                            <div class="row d-flex align-items-center mt-3">
                                <div class="col-4 title">Nghề nghiệp mẹ</div>

                                <div class="col-8">
                                    <el-input
                                        :disabled="true"
                                        placeholder=""
                                        v-model="selectedValue.input_ngheNghiepMe"
                                        size="small"
                                    ></el-input>
                                </div>
                            </div>
                            <div class="row d-flex align-items-center mt-3">
                                <div class="col-4 title">Năm sinh mẹ</div>

                                <div class="col-8">
                                    <date-picker
                                        :disabled="true"
                                        :config="configDatePicker"
                                        placeholder=""
                                        v-model="selectedValue.date_namSinhMe"
                                        class="custom-date-picker"
                                    >
                                    </date-picker>
                                </div>
                            </div>
                            <div class="row d-flex align-items-center mt-3">
                                <div class="col-4 title">Nơi làm việc mẹ</div>

                                <div class="col-8">
                                    <el-input
                                        :disabled="true"
                                        placeholder=""
                                        v-model="selectedValue.input_noiLamViecMe"
                                        size="small"
                                    ></el-input>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4">
                            <div class="row d-flex align-items-center mt-3">
                                <div class="col-6 title">
                                    Họ tên người giám hộ
                                </div>

                                <div class="col-6">
                                    <el-input
                                        :disabled="true"
                                        placeholder=""
                                        v-model="
                                            selectedValue.input_hoTenNguoiGiamHo
                                        "
                                        size="small"
                                    ></el-input>
                                </div>
                            </div>
                            <div class="row d-flex align-items-center mt-3">
                                <div class="col-6 title">
                                    Nghề nghiệp người giám hộ
                                </div>

                                <div class="col-6">
                                    <el-input
                                        :disabled="true"
                                        placeholder=""
                                        v-model="
                                            selectedValue.input_ngheNghiepNguoiGiamHo
                                        "
                                        size="small"
                                    ></el-input>
                                </div>
                            </div>
                            <div class="row d-flex align-items-center mt-3">
                                <div class="col-6 title">
                                    Năm sinh người giám hộ
                                </div>

                                <div class="col-6">
                                    <date-picker
                                        :disabled="true"
                                        :config="configDatePicker"
                                        placeholder=""
                                        v-model="
                                            selectedValue.date_namSinhNguoiGiamHo
                                        "
                                        class="custom-date-picker"
                                    >
                                    </date-picker>
                                </div>
                            </div>
                            <div class="row d-flex align-items-center mt-3">
                                <div class="col-6 title">
                                    Nơi làm người giám hộ
                                </div>

                                <div class="col-6">
                                    <el-input
                                        :disabled="true"
                                        placeholder=""
                                        v-model="
                                            selectedValue.input_noiLamViecNguoiGiamHo
                                        "
                                        size="small"
                                    ></el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
            <div class="layout-btn">
                <CustomButton
                    label="Đóng"
                    size="small"
                    @click="handleCloseModal"
                    class="btn-default btn-delete"
                />
            </div>
        </div>
    </el-dialog>
</template>

<script>
import CardInfo from '@/components/CardInfo.vue'
import {
    ESelectStatus_MockData_Tracuuhocsinh,
    ESelectGender_MockData_ModalChiTietHocSinh,
    ESelectAgeGroup_MockData
} from '@/mock_data'
import datePicker from 'vue-bootstrap-datetimepicker'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import CustomButton from '@/components/CustomButton.vue'
import { mapState } from 'vuex'
import sendRequest from '@/services'
import Api from '@/constants/Api'
export default {
    name: 'ModalChitiet',
    components: {
        datePicker,
        CustomButton,
        CardInfo
    },
    props: {
        disabledModalVisible: {
            type: Boolean,
            required: true
        },

        closeDisabledModal: {
            type: Function,
            required: true
        },
        dataHocSinhTheoNamId_disabled: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            configDatePicker: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
                showTodayButton: true,
                locale: 'vi'
            },
            idHsTheoNam: 0,
            dataModal: {},
            khoiHoc: '',
            maTruong: '',
            namHoc: '',
            maNhomTuoiMn: '',
            tenLopHoc: '',

            getDataESelect: {
                ESelectSchoolBlock: [], //khoihoc
                ESelectAgeGroup: ESelectAgeGroup_MockData, //nhomtuoi
                ESelectClass: [], //lophoc
                ESelectGender: ESelectGender_MockData_ModalChiTietHocSinh, //gioitinh
                ESelectStatus: ESelectStatus_MockData_Tracuuhocsinh, //trangThaiHoc
                ESelectEthnicDirectory: [], //dantoc
                ESelectReligion: [], //tongiao
                ESelectBloodGroup: [], //nhomMau
                ESelectFamilyMember: [], //thanhPhanGiaDinh

                // <!-- ************  ************ -->
                ESelectArea: [], //khuvuc
                ESelectTypeOfDisability: [], //loaikhuyetat
                ESelectPolicyObject: [], //doituongchinhsach
                ESelectPriorityObject: [], //doituonguutien
                ESelectVocationalTraining: [], //huongnghiep/daynghe
                ESelectAdmissionType: [], //loainhaphoc
                ESelectBoardingSchool: [] //hocbantru
            },
            selectedValue: {
                selectedValueSchoolBlock: '', //khoihoc
                selectedValueAgeGroup: '', //nhomtuoi
                selectedValueClass: '', //lophoc
                selectedValueGender: '', //gioitinh
                selectedValueStatus: '', //trangthai HS
                selectedValueEthnicDirectory: '', //dantoc
                selectedValueReligion: '', //tongiao
                selectedValueBloodGroup: '', //nhommau
                selectedValueFamilyMember: '', //thanhphangiadinh
                selectedValueArea: '', //khuvuc
                selectedValueTypeOfDisability: '', //loaikhuyettat
                selectedValuePolicyObject: '', //doituongchinhsach
                selectedValuePriorityObject: '', //doituonguutien
                selectedValueVocationalTraining: '', //huongnghiepdaynghe
                selectedValueAdmissionType: '', //loainhaphoc
                selectedValueBoardingSchool: '', //hocbantru
                input_hoTen: '',
                date_ngaySinh: '',
                date_ngayTrangThai: '',
                input_soDienThoai: '',
                input_email: '',
                input_soDangBo: '',
                input_thuTu: '',
                input_maDinhDanhCongDan: '',
                input_danTocTheoGiayKhaiSinh: '',
                input_maDinhDanhBoGDDT: '',
                input_thoiDiemRoiTruong: '',
                input_maHocSinh: '',
                input_noiSinh: '',
                input_thonXom: '',
                input_queQuan: '',
                input_choOHienNay: '',
                input_diaChiThuongTru: '',
                // <!-- ************  ************ -->

                checkbox_khuyetTat: false,
                checkbox_khuyetTatKhongDG: false,
                checkbox_hocCTGD: false,
                checkbox_hocLopMauGiao5tuoi: false,
                checkbox_hocSinhBietBoiKy1: false,
                checkbox_hocSinhBietBoiKy2: false,
                checkbox_hocSinhTuyenMoi: false,
                checkbox_treLopBanTru: false,
                checkbox_hoc2buoi: false,
                checkbox_hocSinhCoNhuCauHoTroNgoaiNgu: false,
                checkbox_tangCuongTiengViet: false,
                checkbox_hocSinhDanTocTroGiang: false,

                input_CMTND_TCC: '',
                date_ngayCap: '',
                input_noiCap: '',
                input_soBuoiHocTrenTuan: '',
                checkbox_doiVien: false,
                checkbox_doanVien: false,
                checkbox_hocTiengDanToc: false,
                checkbox_hocTiengSongNgu: false,
                checkbox_hocSinhLopBTru: false,
                checkbox_hocSinhBTruDNuoi: false,
                checkbox_hocSinhNTruDNuoi: false,
                checkbox_hocSinhBanTru: false,
                checkbox_hocSinhNoiTru: false,
                checkbox_hocSinhPTDTBanTru: false,
                checkbox_coMeDanToc: false,
                checkbox_coChaDanToc: false,
                checkbox_phuHuynhCoSmartPhone: false,
                checkbox_phuHuynhCoInternet: false,
                // <!-- ************  ************ -->
                input_hoTenBo: '',
                input_ngheNghiepBo: '',
                date_namSinhBo: '',
                input_noiLamViecBo: '',
                input_hoTenMe: '',
                input_ngheNghiepMe: '',
                date_namSinhMe: '',
                date_ngayVaoTruong: '',
                input_noiLamViecMe: '',
                input_hoTenNguoiGiamHo: '',
                input_ngheNghiepNguoiGiamHo: '',
                date_namSinhNguoiGiamHo: '',
                input_noiLamViecNguoiGiamHo: ''
            }
        }
    },

    watch: {
        async dataHocSinhTheoNamId_disabled(newValue) {
            if (this.disabledModalVisible == true) {
                this.idHsTheoNam = newValue
                await this.getDataModalThongTinHocSinh()
                await this.handleDataModal()
            }
        }
    },

    methods: {
        handleCloseModal() {
            this.closeDisabledModal()
        },
        async handleSelectChange_khoiHoc() {
            await this.getDataESelectLopHoc(
                this.selectedValue.selectedValueSchoolBlock,
                this.selectedValue.selectedValueAgeGroup // Pass the selectedValueAgeGroup as well
            )
        },

        async handleSelectChange_nhomTuoi() {
            await this.getDataESelectLopHoc(
                this.selectedValue.selectedValueSchoolBlock,
                this.selectedValue.selectedValueAgeGroup // Pass the selectedValueAgeGroup as well
            )
        },
        handleDataModal() {
            const newValue = this.dataModal
            this.khoiHoc = newValue.khoiHoc

            this.maTruong = newValue.maTruongHoc
            this.namHoc = newValue.namHoc
            this.maNhomTuoiMn = newValue.maNhomTuoiMn
            this.tenLopHoc = newValue.tenLopHoc

            if (
                this.khoiHoc !== undefined &&
                this.maTruong !== undefined &&
                this.namHoc !== undefined
            ) {
                this.getDataESelectLopHoc()
            }

            const capHocMapping = {
                1: [
                    { value: 1, title: 'Khối 1' },
                    { value: 2, title: 'Khối 2' },
                    { value: 3, title: 'Khối 3' },
                    { value: 4, title: 'Khối 4' },
                    { value: 5, title: 'Khối 5' }
                ],
                2: [
                    { value: 6, title: 'Khối 6' },
                    { value: 7, title: 'Khối 7' },
                    { value: 8, title: 'Khối 8' },
                    { value: 9, title: 'Khối 9' }
                ],
                3: [
                    { value: 10, title: 'Khối 10' },
                    { value: 11, title: 'Khối 11' },
                    { value: 12, title: 'Khối 12' }
                ],
                4: [
                    {
                        value: 15,
                        title: 'Nhà trẻ'
                    },
                    {
                        value: 17,
                        title: 'Mẫu giáo'
                    }
                ],
                5: [
                    {
                        value: 15,
                        title: 'Nhà trẻ'
                    },
                    {
                        value: 17,
                        title: 'Mẫu giáo'
                    }
                ],
                45: [
                    {
                        value: 15,
                        title: 'Nhà trẻ'
                    },
                    {
                        value: 17,
                        title: 'Mẫu giáo'
                    }
                ]
            }

            this.getDataESelect.ESelectSchoolBlock =
                capHocMapping[newValue.capHoc] || []
            this.selectedValue.selectedValueAgeGroup = newValue.maNhomTuoiMn

            this.convertSelectedValue_MockData(
                this.selectedValue,
                'selectedValueSchoolBlock',
                this.getDataESelect.ESelectSchoolBlock,
                newValue.khoiHoc
            )

            this.convertSelectedValue_MockData(
                this.selectedValue,
                'selectedValueStatus',
                this.getDataESelect.ESelectStatus,
                newValue.trangThai
            )

            const convertSelectedValue_MaDanToc =
                this.convertSelectedValue_dataStore(
                    this.dataChonDanToc_Store,
                    newValue.maDanToc
                )

            this.selectedValue.selectedValueEthnicDirectory =
                convertSelectedValue_MaDanToc

            const convertSelectedValue_MaTonGiao =
                this.convertSelectedValue_dataStore(
                    this.dataChonTonGiao_Store,
                    newValue.maTonGiao
                )
            this.selectedValue.selectedValueReligion =
                convertSelectedValue_MaTonGiao

            const convertSelectedValue_NhomMau =
                this.convertSelectedValue_dataStore(
                    this.dataChonNhommau_Store,
                    newValue.nhomMau
                )
            this.selectedValue.selectedValueBloodGroup =
                convertSelectedValue_NhomMau

            const convertSelectedValue_ThanhPhanGiaDinh =
                this.convertSelectedValue_dataStore(
                    this.dataChonThanhphangiadinh_Store,
                    newValue.tphanGdinh
                )
            this.selectedValue.selectedValueFamilyMember =
                convertSelectedValue_ThanhPhanGiaDinh

            const convertSelectedValue_KhuVuc =
                this.convertSelectedValue_dataStore(
                    this.dataChonKhuvuc_Store,
                    newValue.maKhuVuc
                )
            this.selectedValue.selectedValueArea = convertSelectedValue_KhuVuc

            const convertSelectedValue_LoaiKhuyetTat =
                this.convertSelectedValue_dataStore(
                    this.dataChonLoaiKhuyettat_Store,
                    newValue.maLoaiKhuyetTat
                )
            this.selectedValue.selectedValueTypeOfDisability =
                convertSelectedValue_LoaiKhuyetTat

            const convertSelectedValue_DoiTuongChinhSach =
                this.convertSelectedValue_dataStore(
                    this.dataChonDoituongchinhsach_Store,
                    newValue.maDtuongCsach
                )
            this.selectedValue.selectedValuePolicyObject =
                convertSelectedValue_DoiTuongChinhSach

            const convertSelectedValue_DoiTuongUuTien =
                this.convertSelectedValue_dataStore(
                    this.dataChonDoituonguutien_Store,
                    newValue.dtuongUuTien
                )
            this.selectedValue.selectedValuePriorityObject =
                convertSelectedValue_DoiTuongUuTien

            const convertSelectedValue_DoiTuongHuongNghiepDayNghe =
                this.convertSelectedValue_dataStore(
                    this.dataChonHuongnghiepdaynghe_Store,
                    newValue.maLopHuongNghiepDayNghe
                )
            this.selectedValue.selectedValueVocationalTraining =
                convertSelectedValue_DoiTuongHuongNghiepDayNghe

            const convertSelectedValue_LoaiNhapHoc =
                this.convertSelectedValue_dataStore(
                    this.dataChonLoainhaphoc_Store,
                    newValue.loaiNhapHoc
                )
            this.selectedValue.selectedValueAdmissionType =
                convertSelectedValue_LoaiNhapHoc

            const convertSelectedValue_HocBanTru =
                this.convertSelectedValue_dataStore(
                    this.dataChonHocbantru_Store,
                    newValue.maHocBanTru
                )
            this.selectedValue.selectedValueBoardingSchool =
                convertSelectedValue_HocBanTru

            this.selectedValue.input_hoTen = newValue.hoTen
            this.selectedValue.date_ngaySinh = newValue.ngaySinh
            this.selectedValue.date_ngayTrangThai = newValue.ngayTrangThai
            this.selectedValue.input_soDienThoai = newValue.soDthoaiDdong
            this.selectedValue.input_email = newValue.email
            this.selectedValue.input_soDangBo = newValue.soDangBo
            this.selectedValue.input_thuTu = newValue.thuTu

            this.selectedValue.input_maDinhDanhCongDan = newValue.citizenId
            this.selectedValue.input_maDinhDanhBoGDDT = newValue.moetCode
            this.selectedValue.input_thoiDiemRoiTruong =
                newValue.thoiDiemRoiTruong
            this.selectedValue.input_danTocTheoGiayKhaiSinh =
                newValue.input_danTocTheoGiayKhaiSinh
            this.selectedValue.input_maHocSinh = newValue.maHocSinh
            this.selectedValue.input_noiSinh = newValue.noiSinh
            this.selectedValue.input_thonXom = newValue.maTthonTmtru
            this.selectedValue.input_queQuan = newValue.queQuan
            this.selectedValue.input_choOHienNay = newValue.dchiTmtru
            this.selectedValue.input_diaChiThuongTru = newValue.dchiThtru
            this.selectedValue.input_CMTND_TCC = newValue.soCmnd
            this.selectedValue.date_ngayCap = newValue.ngayCapCmnd
            this.selectedValue.input_noiCap = newValue.noiCapCmnd
            this.selectedValue.input_soBuoiHocTrenTuan = `${newValue.soBuoiTrenTuan} buổi học/tuần - [${newValue.soBuoiTrenTuan}]`
            this.selectedValue.input_hoTenBo = newValue.hoTenBo
            this.selectedValue.input_ngheNghiepBo = newValue.ngheNghiepBo
            this.selectedValue.date_namSinhBo = newValue.ngaySinhBo

            this.selectedValue.input_noiLamViecBo = newValue.noiLamViecBo
            this.selectedValue.input_hoTenMe = newValue.hoTenMe
            this.selectedValue.input_ngheNghiepMe = newValue.ngheNghiepMe
            this.selectedValue.date_namSinhMe = newValue.ngaySinhMe
            this.selectedValue.input_noiLamViecMe = newValue.noiLamViecMe
            this.selectedValue.input_hoTenNguoiGiamHo = newValue.hoTenNguoiGiamHo
            this.selectedValue.input_ngheNghiepNguoiGiamHo =
                newValue.ngheNghiepNguoiGiamHo
            this.selectedValue.date_namSinhNguoiGiamHo =
                newValue.ngaySinhNguoiGiamHo
            this.selectedValue.input_noiLamViecNguoiGiamHo =
                newValue.noiLamViecNguoiGiamHo

            this.selectedValue.selectedValueGender =
                newValue.gioiTinh === 0 ? 0 : 1

            this.selectedValue.checkbox_khuyetTatKhongDG =
                newValue.khuyetTatKhongDanhGia
            this.selectedValue.checkbox_hocCTGD = newValue.hocCtgdCuaBo
            this.selectedValue.checkbox_hocLopMauGiao5tuoi = newValue.hocLopMg5T
            this.selectedValue.checkbox_hocSinhTuyenMoi = newValue.tuyenMoiMn
            this.selectedValue.checkbox_hocSinhCoNhuCauHoTroNgoaiNgu =
                newValue.nhuCauHtroNngu
            this.selectedValue.checkbox_tangCuongTiengViet =
                newValue.tangCuongTviet
            this.selectedValue.checkbox_hocSinhDanTocTroGiang =
                newValue.hocSinhDanTocTroGiang
            this.selectedValue.checkbox_hocSinhBietBoiKy1 = newValue.isBietBoiKy1
            this.selectedValue.checkbox_hocSinhBietBoiKy2 = newValue.isBietBoiKy2
            this.selectedValue.checkbox_hocSinhBanTru = newValue.hocSinhBanTru
            this.selectedValue.checkbox_hocSinhNoiTru = newValue.hocSinhNoiTru
            this.selectedValue.checkbox_hocSinhBTruDNuoi =
                newValue.hocSinhBanTruDanNuoi
            this.selectedValue.checkbox_hocSinhNTruDNuoi =
                newValue.hocSinhNoiTruDanNuoi
            this.selectedValue.checkbox_hocSinhPTDTBanTru =
                newValue.hocSinhPtdtBanTru
            this.selectedValue.checkbox_coMeDanToc = newValue.meDanToc
            this.selectedValue.checkbox_coChaDanToc = newValue.chaDanToc
            this.selectedValue.checkbox_phuHuynhCoSmartPhone =
                newValue.isPhCoSmartPhone
            this.selectedValue.checkbox_phuHuynhCoInternet =
                newValue.isPhCoMayTinhInternet
            this.selectedValue.checkbox_khuyetTat =
                newValue.khuyetTat === 0 ? false : true
            this.selectedValue.checkbox_doanVien =
                newValue.doanVien === 0 ? false : true
            this.selectedValue.checkbox_doiVien =
                newValue.doiVien === 0 ? false : true
            this.selectedValue.checkbox_hocTiengDanToc =
                newValue.hocSinhTiengDanToc
            this.selectedValue.checkbox_hocTiengSongNgu = newValue.hocSongNgu
        },

        async getDataModalThongTinHocSinh() {
            if (this.idHsTheoNam !== 0) {
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    idHsTheoNam: this.idHsTheoNam
                }
                const response = await sendRequest(
                    Api.traCuu.traCuuHocSinh.chiTietHocSinh,
                    null,
                    request_Header,
                    request_Params
                )
                this.dataModal = response
            }
        },

        async getDataESelectLopHoc(
            selectedValueSchoolBlock,
            selectedValueAgeGroup
        ) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const convertKhoiHoc = selectedValueSchoolBlock
                ? selectedValueSchoolBlock
                : this.khoiHoc
            const convertMaNhomTuoiMn = selectedValueAgeGroup
                ? selectedValueAgeGroup
                : this.maNhomTuoiMn
            const request_Header = {
                token: this.authToken
            }
            if (convertKhoiHoc !== null) {
                const request_Params = {
                    khoiHoc: convertKhoiHoc,
                    maTruong: this.maTruong,
                    namHoc: this.namHoc,
                    maNhomTuoiMn: convertMaNhomTuoiMn
                }

                const response = await sendRequest(
                    Api.traCuu.traCuuHocSinh.danhSachLopHoc_HocSinh,
                    null,
                    request_Header,
                    request_Params
                )
                if (response.rc == 0) {
                    loading.close()
                    this.getDataESelect.ESelectClass = response.tableData

                    const convertSelectValue_LopHoc =
                        this.getDataESelect.ESelectClass?.filter(
                            (item) => item.tenLopHoc === this.tenLopHoc
                        )
                            .map((item) => item.maLopHoc)
                            .pop()
                    this.selectedValue.selectedValueClass =
                        convertSelectValue_LopHoc
                }
                if (response.rc != 0) {
                    loading.close()
                    this.$message({
                        type: 'warning',
                        message: response.rd
                    })
                    this.selectedValue.selectedValueClass = ''
                    this.getDataESelect.ESelectClass = response.tableData
                }
            }
        },
        convertSelectedValue_dataStore(dataStore, mappingCode) {
            return dataStore
                ?.filter((item) => item.mappingCode == mappingCode)
                .map((item) => item.mappingCode)
                .pop()
        },
        convertSelectedValue_MockData(
            selectedValueArray,
            selectedValueKey,
            dataESelectArray,
            newValue
        ) {
            const convertedValue = dataESelectArray
                ?.filter((item) => item.value === newValue)
                .map((item) => item.value)
                .pop()

            selectedValueArray[selectedValueKey] = convertedValue
        }
    },
    computed: {
        convertedValue_checkbox_KhuyetTat() {
            return this.selectedValue.checkbox_khuyetTat ? 1 : 0
        },
        convertedValue_checkbox_DoiVien() {
            return this.selectedValue.checkbox_doiVien ? 1 : 0
        },
        convertedValue_checkbox_DoanVien() {
            return this.selectedValue.checkbox_doanVien ? 1 : 0
        },
        convertedValue_checkbox_HocSinhLopBanTru() {
            return this.selectedValue.checkbox_hocSinhBanTru ? 1 : 0
        },
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDanToc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDantoc'))
        },
        dataChonTonGiao_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTongiao'))
        },
        dataChonNhommau_Store() {
            return JSON.parse(localStorage.getItem('data_ChonNhommau'))
        },
        dataChonThanhphangiadinh_Store() {
            return JSON.parse(localStorage.getItem('data_ChonThanhphangiadinh'))
        },
        dataChonKhuvuc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonKhuvuc'))
        },
        dataChonLoaiKhuyettat_Store() {
            return JSON.parse(localStorage.getItem('data_ChonLoaikhuyettat'))
        },
        dataChonDoituongchinhsach_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDoituongchinhsach'))
        },
        dataChonDoituonguutien_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDoituonguutien'))
        },
        dataChonHuongnghiepdaynghe_Store() {
            return JSON.parse(localStorage.getItem('data_ChonHuongnghiepdaynghe'))
        },
        dataChonLoainhaphoc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonLoainhaphoc'))
        },
        dataChonHocbantru_Store() {
            return JSON.parse(localStorage.getItem('data_ChonHocbantru'))
        }
    }
}
</script>
<style scoped>
::v-deep .el-input.is-disabled .el-input__inner,
::v-deep .el-textarea.is-disabled .el-textarea__inner,
::v-deep .el-input.is-disabled .el-input__inner {
    background-color: #eee !important;
    color: black !important;
}
::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
    border-color: black !important;
    background-color: #eee !important;
}
.title {
    font-weight: 700;
    font-size: 12px;
}
.title span {
    color: red;
}

.custom-date-picker {
    height: 32px;
}
.custom-date-picker::placeholder {
    color: gainsboro;

    font-size: 13px;
    background-color: transparent;
    appearance: none;
}

.row {
    margin: 0 auto;
}
.layout-btn {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}

.btn-delete {
    background-color: var(--orange);
}
</style>
