<template>
    <el-dialog
        :visible="chiTietTruongHocModalVisible"
        @close="resetFrom"
        width="90%"
        class="custom-dialog"
    >
        <span slot="title" class="custom-dialog-title">
            Chỉnh sửa Thông tin trường học
            <hr />
        </span>
        <div class="row">
            <div class="col-3">
                <label>Tỉnh thành <span class="span">*</span></label>
                <div>
                    <el-select
                        disabled
                        v-model="selectedValue.selectedValue_tinhThanh"
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelect_tinhThanh"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label>Quận huyện <span class="span">*</span></label>
                <div>
                    <el-select
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        v-model="selectedValue.selectedValue_quanHuyen"
                        size="small"
                        style="width: 100%"
                        @input="handleSelectChange_quanHuyen"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelect_quanHuyen"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label>Phường xã <span class="span">*</span></label>
                <div>
                    <el-select
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        v-model="selectedValue.selectedValue_phuongXa"
                        size="small"
                        style="width: 100%"
                        @input="handleSelectChange_PhuongXa"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelect_phuongXa"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label>Tổ thôn</label>
                <div>
                    <el-select
                        v-model="selectedValue.selectedValue_toThon"
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        size="small"
                        :no-data-text="'Không có bản ghi nào'"
                        :no-match-text="'Không tìm thấy bản ghi nào'"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelect_toThon"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-3">
                <label>Đơn vị quản lý <span class="span">*</span></label>
                <div>
                    <el-select
                        v-model="selectedValue.selectedValue_donViquanLy"
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelect_donViQuanLy"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label>Mã trường học</label>
                <div>
                    <el-input
                        :disabled="true"
                        placeholder="Nhập ..."
                        v-model="selectedValue.input_maTruongHoc"
                        size="small"
                    ></el-input>
                </div>
            </div>
            <div class="col-3">
                <label>Tên trường học <span class="span">*</span></label>
                <div>
                    <el-input
                        placeholder="Nhập ..."
                        v-model="selectedValue.input_tenTruongHoc"
                        size="small"
                    ></el-input>
                </div>
            </div>
            <div class="col-3">
                <label>Cấp học <span class="span">*</span></label>
                <div>
                    <ESelect
                        :key="selectedValue.selectedValue_capHoc.length"
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelect_capHoc"
                        :placeholder="'Chọn cấp học'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenTruongHoc', 'value']"
                        v-model="selectedValue.selectedValue_capHoc"
                    />
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-3">
                <label>Nhóm cấp học</label>
                <div>
                    <el-select
                        v-model="selectedValue.selectedValue_nhomCapHoc"
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelect_nhomCapHoc"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label>Loại hình đào tạo <span class="span">*</span></label>
                <div>
                    <el-select
                        v-model="selectedValue.selectedValue_loaiHinhDaoTao"
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelect_loaiHinhDaoTao"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label>Loại hình trường<span class="span">*</span></label>
                <div>
                    <el-select
                        v-model="selectedValue.selectedValue_loaiHinhTruong"
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in this.dataChonLoaihinhtruong_Store"
                            :key="item.mappingCode"
                            :label="item.constantTitle"
                            :value="item.mappingCode"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label>Ngày thành lập</label>
                <div>
                    <date-picker
                        :config="configDatePicker"
                        placeholder="Chọn ngày"
                        v-model="selectedValue.date_ngayThanhLap"
                        class="custom-date-picker"
                    >
                    </date-picker>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-3">
                <label>Chuẩn quốc gia <span class="span">*</span></label>
                <div>
                    <el-select
                        v-model="selectedValue.selectedValue_chuanQuocGia"
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in this.dataChonDatChuanQG_Store"
                            :key="item.mappingCode"
                            :label="item.constantTitle"
                            :value="item.mappingCode"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label>Website </label>
                <div>
                    <el-input
                        placeholder="Nhập"
                        v-model="selectedValue.input_webSite"
                        size="small"
                    ></el-input>
                </div>
            </div>
            <div class="col-3">
                <label>Tên hiệu trưởng</label>
                <div>
                    <el-input
                        placeholder="Nhập"
                        v-model="selectedValue.input_tenHieuTruong"
                        size="small"
                    ></el-input>
                </div>
            </div>
            <div class="col-3">
                <label>Email</label>
                <div>
                    <el-input
                        placeholder="Nhập"
                        v-model="selectedValue.input_email"
                        size="small"
                    ></el-input>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-3">
                <label>Fax</label>
                <div>
                    <el-input
                        placeholder="Nhập"
                        v-model="selectedValue.input_fax"
                        size="small"
                    ></el-input>
                </div>
            </div>
            <div class="col-3">
                <label>Địa chỉ</label>
                <div>
                    <el-input
                        placeholder="Nhập"
                        v-model="selectedValue.input_diaChi"
                        size="small"
                    ></el-input>
                </div>
            </div>

            <div class="col-3">
                <label>Trạng thái</label>
                <div>
                    <el-select
                        v-model="selectedValue.selectedValue_trangThai"
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelect_trangThai"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
        </div>
        <div class="layout-btn">
            <div>
                <CustomButton
                    label="Đóng"
                    size="small"
                    class="btn-close btn-default"
                    @click="resetFrom"
                />
            </div>
            <div style="margin-left: 10px">
                <CustomButton
                    label="Chỉnh sửa"
                    size="small"
                    class="btn-update btn-default"
                    @click="handleUpdate"
                />
            </div>
        </div>
    </el-dialog>
</template>
<script>
import moment from 'moment'
import datePicker from 'vue-bootstrap-datetimepicker'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import ESelect from '@/components/ESelect.vue'
import CustomButton from '@/components/CustomButton.vue'
import { mapState } from 'vuex'
import sendRequest from '@/services'
import {
    ESelectGradeGroup_MockData,
    ESelectTypeOfEducation_MockData,
    ESelectStatus_MockData_TraCuuTruongHoc,
    ESelectGradeLevel_MockData
} from '@/mock_data'
import Api from '@/constants/Api'
import { filterCapHocList } from '@/utils'
export default {
    name: 'ModalChitietLopHoc',
    props: {
        chiTietTruongHocModalVisible: {
            type: Boolean,
            required: true
        },

        closeModalTruongHoc: {
            type: Function,
            required: true
        },
        data_updateTruongHoc: {
            type: Object,
            required: true
        },
        handleSearch: Function
    },

    components: { CustomButton, ESelect, datePicker },
    data() {
        return {
            configDatePicker: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
                showTodayButton: true,
                locale: 'vi'
            },
            maTthanh: '',
            maQhuyen: '',
            maQhuyen_change: '',
            maPxa: '',
            maPxa_change: '',
            maDonVi: '',
            dataUpdate: {},
            getDataESelect: {
                ESelect_tinhThanh: [], //tinhthanh
                ESelect_quanHuyen: [], //quanhuyen
                ESelect_phuongXa: [], //phuongxa
                ESelect_toThon: [], //tothon
                ESelect_donViQuanLy: [], //donviquanly

                ESelect_capHoc: [], //caphoc
                ESelect_nhomCapHoc: ESelectGradeGroup_MockData, //nhomcaphoc
                ESelect_loaiHinhDaoTao: ESelectTypeOfEducation_MockData, //loaihinhdaotao
                ESelect_loaiHinhTruong: [], //loaihinhtruong
                ESelect_chuanQuocGia: [], //chuanquocgia
                ESelect_trangThai: ESelectStatus_MockData_TraCuuTruongHoc //trangthaihoatdong
            },
            selectedValue: {
                selectedValue_tinhThanh: '',
                selectedValue_quanHuyen: '',
                selectedValue_phuongXa: '',
                selectedValue_toThon: '',
                selectedValue_donViquanLy: '',
                input_maTruongHoc: '',
                input_tenTruongHoc: '',
                selectedValue_capHoc: [],
                selectedValue_nhomCapHoc: [],
                selectedValue_loaiHinhDaoTao: [],
                selectedValue_loaiHinhTruong: [],
                date_ngayThanhLap: '',
                selectedValue_chuanQuocGia: [],
                input_webSite: '',
                input_tenHieuTruong: '',
                input_email: '',
                input_fax: '',
                input_diaChi: '',
                selectedValue_trangThai: []
            }
        }
    },
    watch: {
        async data_updateTruongHoc(newValue) {
            this.maTthanh = newValue.maTthanh
            this.maQhuyen = newValue.maQhuyen
            this.maPxa = newValue.maPxa
            this.maDonVi = newValue.maDonVi
            this.selectedValue.selectedValue_toThon = newValue.maTthon

            this.dataUpdate = newValue
            await this.getDataTinhThanh()
            await this.getDataQuanHuyen()
            await this.getDataPhuongXa()
            await this.getDonViQuanLy()
            await this.getDataToThon()

            this.selectedValue.input_maTruongHoc = newValue.maTruongHoc
            this.selectedValue.input_tenTruongHoc = newValue.tenTruongHoc

            const newValueCapHoc = ESelectGradeLevel_MockData.filter((item) =>
                newValue.capHoc.includes(item.value)
            ).map(({ tenTruongHoc, value }) => ({
                title: tenTruongHoc,
                value
            }))
            this.selectedValue.selectedValue_capHoc = [...newValueCapHoc]

            this.selectedValue.selectedValue_nhomCapHoc = this.findValueInArray(
                ESelectGradeGroup_MockData,
                newValue.maNhomCapHoc
            )
            this.selectedValue.selectedValue_loaiHinhDaoTao =
                this.findValueInArray(
                    ESelectTypeOfEducation_MockData,
                    newValue.loaiHinhDaoTao
                )
            this.selectedValue.selectedValue_loaiHinhTruong =
                this.dataChonLoaihinhtruong_Store?.find(
                    (item) => item.mappingCode == newValue.loaiHinhTruong
                )?.mappingCode
            this.selectedValue.date_ngayThanhLap = newValue.ngayThanhLap

            this.selectedValue.selectedValue_chuanQuocGia =
                this.dataChonDatChuanQG_Store?.find(
                    (item) => item.mappingCode == newValue.chuanQuocGia
                )?.mappingCode
            this.selectedValue.input_webSite = newValue.website
            this.selectedValue.input_tenHieuTruong = newValue.tenHieuTruong
            this.selectedValue.input_email = newValue.email
            this.selectedValue.input_fax = newValue.fax
            this.selectedValue.input_diaChi = newValue.diaChi
            this.selectedValue.selectedValue_trangThai = this.findValueInArray(
                ESelectStatus_MockData_TraCuuTruongHoc,
                newValue.trangThai
            )
            this.getDataESelect.ESelect_capHoc = filterCapHocList(
                this.authUser.capHocList
            )
        }
    },
    methods: {
        resetFrom() {
            // this.selectedValue.selectedValue_toThon = ''
            // this.selectedValue.selectedValue_tinhThanh = ''
            // this.selectedValue.selectedValue_quanHuyen = ''
            // this.selectedValue.selectedValue_phuongXa = ''
            // this.selectedValue.selectedValue_donViquanLy = ''
            // this.selectedValue.input_maTruongHoc = ''
            // this.selectedValue.input_tenTruongHoc = ''
            // this.selectedValue.selectedValue_capHoc = ''
            // this.selectedValue.selectedValue_nhomCapHoc = ''
            // this.selectedValue.selectedValue_loaiHinhDaoTao = ''
            // this.selectedValue.selectedValue_loaiHinhTruong = ''
            // this.selectedValue.date_ngayThanhLap = ''

            // this.selectedValue.selectedValue_chuanQuocGia = ''
            // this.selectedValue.input_webSite = ''
            // this.selectedValue.input_tenHieuTruong = ''
            // this.selectedValue.input_email = ''
            // this.selectedValue.input_fax = ''
            // this.selectedValue.input_diaChi = ''
            // this.selectedValue.selectedValue_trangThai = ''
            this.closeModalTruongHoc()
        },
        handleSelectChange_quanHuyen() {
            this.getDataPhuongXa(this.selectedValue.selectedValue_quanHuyen)
            this.getDataToThon(this.selectedValue.selectedValue_quanHuyen)
        },
        handleSelectChange_PhuongXa() {
            this.getDataToThon(this.selectedValue.selectedValue_phuongXa)
        },
        async getDataTinhThanh() {
            const request_Header = {
                token: this.authToken
            }
            const request_Params = {
                start: 0,
                limit: 100
            }
            const response = await sendRequest(
                Api.traCuu.traCuuTruongHoc.getDataTinhThanh,
                null,
                request_Header,
                request_Params
            )
            const dataTinhThanh = this.convertDataToNewFormat(
                response.rows,
                'tenTinhThanh',
                'maTinhThanh'
            )

            this.getDataESelect.ESelect_tinhThanh = dataTinhThanh
            this.selectedValue.selectedValue_tinhThanh = this.findValueInArray(
                dataTinhThanh,
                this.maTthanh
            )
        },
        async getDataQuanHuyen() {
            const request_Header = {
                token: this.authToken
            }
            const request_Params = {
                start: 0,
                limit: 999,
                maTinhThanh: this.maTthanh
            }
            const response = await sendRequest(
                Api.traCuu.traCuuTruongHoc.getDataQuanHuyen,
                null,
                request_Header,
                request_Params
            )

            const dataQuanHuyen = this.convertDataToNewFormat(
                response.rows,
                'tenQuanHuyen',
                'maQuanHuyen'
            )

            this.getDataESelect.ESelect_quanHuyen = dataQuanHuyen
            this.selectedValue.selectedValue_quanHuyen = this.findValueInArray(
                dataQuanHuyen,
                this.maQhuyen
            )
        },
        async getDataPhuongXa(maQhuyen_change) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const maQhuyen =
                maQhuyen_change !== undefined ? maQhuyen_change : this.maQhuyen
            const request_Header = {
                token: this.authToken
            }
            const request_Params = {
                start: 0,
                limit: 999,
                maTinhThanh: this.maTthanh,
                maQuanHuyen: maQhuyen
            }
            const response = await sendRequest(
                Api.traCuu.traCuuTruongHoc.getDataPhuongXa,
                null,
                request_Header,
                request_Params
            )
            const dataPhuongXa = this.convertDataToNewFormat(
                response.rows,
                'tenPhuongXa',
                'maPhuongXa'
            )
            if (response.code == 200) {
                loading.close()
                this.getDataESelect.ESelect_phuongXa = dataPhuongXa
                this.selectedValue.selectedValue_phuongXa = this.findValueInArray(
                    dataPhuongXa,
                    this.maPxa
                )
            } else if (response.code !== 200) {
                loading.close()
            }
        },
        async getDataToThon(maQhuyen_change, maPxa_change) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const maQhuyen =
                maQhuyen_change !== undefined ? maQhuyen_change : this.maQhuyen
            const maPxa = maPxa_change !== undefined ? maPxa_change : this.maPxa
            const request_Header = {
                token: this.authToken
            }
            const request_Params = {
                limit: 999,
                maPhuongXa: maPxa,

                maQuanHuyen: maQhuyen,

                maTinhThanh: this.maTthanh,
                start: 0
            }
            const response = await sendRequest(
                Api.traCuu.traCuuTruongHoc.getToThon,
                null,
                request_Header,
                request_Params
            )
            if (response.code == 200) {
                loading.close()
                this.getDataESelect.ESelect_toThon = this.convertDataToNewFormat(
                    response.rows,
                    'tenToThon',
                    'maToThon'
                )
            } else if (response.code !== 200) {
                loading.close()
            }
        },
        async getDonViQuanLy() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const request_Header = {
                token: this.authToken
            }
            const request_Params = {
                start: 0,
                limit: 999,
                maTinhThanh: this.maTthanh
            }
            const response = await sendRequest(
                Api.traCuu.traCuuTruongHoc.getDonViQuanLy,
                null,
                request_Header,
                request_Params
            )
            const dataDonVi = this.convertDataToNewFormat(
                response.rows,
                'tenDonVi',
                'maDonVi'
            )
            if (response.code == 200) {
                loading.close()
                this.getDataESelect.ESelect_donViQuanLy = dataDonVi
                this.selectedValue.selectedValue_donViquanLy =
                    this.findValueInArray(dataDonVi, this.maDonVi)
            } else if (response.code !== 200) {
                loading.close()
            }
        },

        findValueInArray(dataArray, targetValue) {
            const foundItem = dataArray?.find((item) => item.value == targetValue)
            return foundItem ? foundItem.value : null
        },
        convertDataToNewFormat(dataArray, titlePropertyName, valuePropertyName) {
            return dataArray?.map((item) => ({
                title: `${item[titlePropertyName]} - [${item[valuePropertyName]}]`,
                value: item[valuePropertyName]
            }))
        },
        async handleUpdate() {
            const request_Header = {
                token: this.authToken
            }

            const convertCapHoc = this.selectedValue.selectedValue_capHoc?.map(
                (item) => item.value
            )

            const request_Data = {
                capHoc: this.dataUpdate.capHoc,
                capHocs: convertCapHoc,
                chiBoDang: this.dataUpdate.chiBoDang,
                chuanQuocGia: this.selectedValue.selectedValue_chuanQuocGia,
                diaChi: this.selectedValue.input_diaChi,
                email: this.selectedValue.input_email,
                fax: this.selectedValue.input_fax,
                idPhong: this.dataUpdate.idPhong,
                idSo: this.dataUpdate.idSo,
                loaiHinhDaoTao: this.selectedValue.selectedValue_loaiHinhDaoTao,
                loaiHinhTruong: this.selectedValue.selectedValue_loaiHinhTruong,
                ma: this.selectedValue.input_maTruongHoc,
                maDoiTac: this.dataUpdate.maDoiTac,
                maDonVi: this.selectedValue.selectedValue_donViquanLy,
                maNhomCapHoc: this.selectedValue.selectedValue_nhomCapHoc,
                maPhuongXa: this.selectedValue.selectedValue_phuongXa,
                maQhuyen: this.selectedValue.selectedValue_quanHuyen,
                maQuanHuyen: this.selectedValue.selectedValue_quanHuyen,
                maTinhThanh: this.selectedValue.selectedValue_tinhThanh,
                maTruongHoc: this.selectedValue.input_maTruongHoc,
                maTthanh: this.selectedValue.selectedValue_tinhThanh,
                maTthon: this.selectedValue.selectedValue_toThon,
                ngayCapNhat: this.dataUpdate.ngayCapNhat,
                ngayThanhLap: this.convertDate(
                    this.selectedValue.date_ngayThanhLap
                ),
                sdt: this.dataUpdate.sdt,
                sdtHieuTruong: this.dataUpdate.sdtHieuTruong,
                ten: this.selectedValue.input_tenTruongHoc,
                tenDonVi: this.dataUpdate.tenDonVi,
                tenHieuTruong: this.selectedValue.input_tenHieuTruong,
                tenPhuongXa: this.dataUpdate.tenPhuongXa,
                tenQuanHuyen: this.dataUpdate.tenQuanHuyen,
                tenTinhThanh: this.dataUpdate.tenTinhThanh,
                tenToThon: this.dataUpdate.tenToThon,
                tenTruongHoc: this.selectedValue.input_tenTruongHoc,
                trangThai: this.selectedValue.selectedValue_trangThai,
                vungBienGioi: this.dataUpdate.vungBienGioi,
                vungKhoKhan: this.dataUpdate.vungKhoKhan,
                website: this.selectedValue.input_webSite
            }

            this.$confirm('Xác nhận cập nhật bản ghi đã chọn?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const response = await sendRequest(
                        Api.traCuu.traCuuTruongHoc.updateTruongHoc,
                        request_Data,
                        request_Header,
                        null
                    )
                    if (response.code == 200) {
                        this.$emit('handleSearch')
                        this.closeModalTruongHoc()
                    } else if (response.code !== 200) {
                        this.$message({
                            type: 'error',
                            message: response.message || response.rd
                        })
                    }
                })
                .catch(() => {})
        },
        convertDate(input_date) {
            const dateObject = moment(input_date, 'DD/MM/YYYY', true) // Sử dụng 'true' để bật chế độ strict parsing
            if (dateObject.isValid()) {
                return dateObject.toISOString()
            } else {
                return null // Hoặc trả về giá trị mặc định khi đầu vào không hợp lệ
            }
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonLoaihinhtruong_Store() {
            return JSON.parse(localStorage.getItem('data_ChonLoaihinhtruong'))
        },
        dataChonDatChuanQG_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDatChuanQG'))
        }
    }
}
</script>
<style>
hr {
    border: 1px solid green;
}
label {
    font-size: 14px;
    font-weight: 500;
}
label .span {
    color: red;
}
.custom-date-picker {
    height: 32px;
}
.custom-date-picker::placeholder {
    color: gainsboro;
    font-size: 13px;
    background-color: transparent;
    appearance: none;
}
.layout-btn {
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
}
.btn-close,
.btn-close:hover {
    background-color: var(--orange);
    color: var(--btn-text-color);
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.btn-update {
    color: var(--btn-text-color);
    background-color: var(--yellow);
}
.btn-update:hover {
    color: var(--btn-text-color);
    background-color: var(--yellow);
}
</style>
