<template>
    <div>
        <CustomBreadcrumb
            :title="'TIẾP NHẬN DỮ LIỆU QLNT'"
            :content="'BÁO CÁO TÌNH HÌNH NỘP DỮ LIỆU CẤP SỞ'"
        />
        <CustomContentUI>
            <div class="row">
                <div class="col-4">
                    <label>Đơn vị quản lý</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="this.dataChonDonVi_Store"
                            :placeholder="'Chọn đơn vị'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenDonVi', 'maDonVi']"
                            :value="selectedValue.selectedValueUnitEducation"
                            @change="
                                handleESelectChange(
                                    'ESelectUnitEducation',
                                    ...$event
                                )
                            "
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Cấp học <span>*</span></label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectGradeLevel_MockData"
                            :placeholder="'Chọn cấp học'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenTruongHoc', 'value']"
                            :value="selectedValue.selectedValueGradeLevel"
                            @change="
                                handleESelectChange(
                                    'ESelectGradeLevel_MockData',
                                    ...$event
                                )
                            "
                        />
                    </div>
                </div>

                <div class="col-2">
                    <label>Năm học</label>
                    <div>
                        <ESelectYear
                            v-model="selectedValue.selectedValueSchoolYear"
                            placeholder="Chọn năm"
                            size="small"
                            :no-data-text="'Không có bản ghi nào'"
                            :no-match-text="'Không tìm thấy bản ghi nào'"
                        ></ESelectYear>
                    </div>
                </div>
                <div class="col-2 mt-1">
                    <label> </label>
                    <div>
                        <CustomButton
                            label="Tìm kiếm"
                            size="small"
                            class="bg-search btn-default"
                            @click="handleSearch"
                        />
                    </div>
                </div>
            </div>
        </CustomContentUI>

        <div
            class="layout-options"
            :style="{
                'max-width': leftBarWidth()
            }"
        >
            <div>
                <chonSoLuongBanGhi @chonXongSoLuong="ChonSoLuongBanGhi($event)" />
            </div>
        </div>

        <CustomTable :maxWidth="leftBarWidth()" :columns="tableColumns">
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="11" class="text-tbody">
                            Không có bản ghi nào.
                        </td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td class="text-tbody">{{ item.stt }}</td>
                    <td class="text-tbody" style="text-align: left">
                        {{ item.tenDonVi }}
                    </td>

                    <td class="text-tbody">
                        {{ item.totalCanbo }}
                    </td>

                    <td class="text-tbody">
                        {{ item.totalHocsinh }}
                    </td>

                    <td class="text-tbody">
                        {{ item.totalHsTotNghep }}
                    </td>

                    <td class="text-tbody">
                        {{ item.totalKQCanam }}
                    </td>

                    <td class="text-tbody">
                        {{ item.totalKQHocKi1 }}
                    </td>

                    <td class="text-tbody">
                        {{ item.totalKQHocki2 }}
                    </td>

                    <td class="text-tbody">
                        {{ item.totalLopDon }}
                    </td>

                    <td class="text-tbody">
                        {{ item.totalLopGhep }}
                    </td>

                    <td class="text-tbody">
                        {{ item.totalTruongHoc }}
                    </td>
                </tr>
            </template>
        </CustomTable>
        <CustomPagination
            v-show="total_rows > 0"
            :tongbanghi="total_rows"
            :soluonghienthi="soLuongBanGhiHienThi"
            :batdau="trangbatdau"
            @pageChange="layLai($event)"
        >
        </CustomPagination>
    </div>
</template>
<script>
import ESelect from '@/components/ESelect.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelectYear from '@/components/ESelectYear.vue'
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'
import chonSoLuongBanGhi from '@/components/chonSoLuongBanGhi.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import {
    ESelectGradeLevel_MockData,
    ESelectDisplayContent_MockData
} from '@/mock_data'
import { mapState } from 'vuex'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { currentYear } from '@/utils'
import CustomContentUI from '@/components/CustomContentUI.vue'
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'capSoBCTinhHinhNopDuLieu',
    components: {
        ESelect,
        CustomButton,
        ESelectYear,
        CustomBreadcrumb,
        chonSoLuongBanGhi,
        CustomPagination,
        CustomContentUI,
        CustomTable
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            soLuongBanGhiHienThi: 25,
            trangbatdau: false,

            request_Data: {
                capHocList: [],
                maDonViList: [],
                dsMaTruong: [],
                maSo: null,
                namHoc: null
            },
            request_Params: {
                start: null,
                limit: null
            },
            request_Header: {
                Authorization: ''
            },
            tableColumns: [
                { key: 'stt', label: 'STT' },
                { key: 'tenDonViQuanLy', label: 'Tên đơn vị quản lý' },
                { key: 'tongSoCanBo', label: 'Tổng số cán bộ' },
                { key: 'tongSoHS', label: 'Tổng số HS' },
                { key: 'tongSoHSTotNghiep', label: 'Tổng số HS tốt nghiệp' },
                { key: 'tongSoKQCaNam', label: 'Tổng số KQ cả năm' },
                { key: 'tongSoKQHKI', label: 'Tổng số KQ HK I' },
                { key: 'tongSoKQHKII', label: 'Tổng số KQ HK II' },
                { key: 'tongSoLopDon', label: 'Tổng số lớp đơn' },
                { key: 'tongSoLopGhep', label: 'Tổng số lớp ghép' },
                { key: 'tongSoTruongHoc', label: 'Tổng số trường học' }
            ],
            tableData: [],
            hiddenColumns: [],
            getDataESelect: {
                ESelectUnitEducation: [], //donvi
                ESelectGradeLevel_MockData: ESelectGradeLevel_MockData, //caphoc

                ESelectShoolYear: null, //namhoc
                ESelectDisplayContent: ESelectDisplayContent_MockData //noidunghienthi
            },

            selectedValue: {
                selectedValueUnitEducation: [], //donvu
                selectedValueGradeLevel: [], //caphoc

                selectedValueSchoolYear: [], //namhoc
                selectedValueDisplayContent: [] //noidunghienthi
            }
        }
    },
    methods: {
        ChonSoLuongBanGhi(e) {
            this.total_rows = 0
            this.soLuongBanGhiHienThi = e.soluong
        },
        checkTruocKhiTim() {
            this.trangbatdau = !this.trangbatdau
        },
        layLai(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },

        handleESelectChange(source, ...selectedOptions) {
            switch (source) {
                case 'ESelectUnitEducation':
                    this.selectedValue.selectedValueUnitEducation =
                        selectedOptions
                    break
                case 'ESelectGradeLevel_MockData':
                    this.selectedValue.selectedValueGradeLevel = selectedOptions
                    break
            }
        },

        async handleSearch() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const maDonViList_Update = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueUnitEducation,
                    'selectedValueUnitEducation'
                )
                const capHocList_Update = this.customhandleESelectedChange(
                    this.selectedValue.selectedValueGradeLevel,
                    'selectedValueGradeLevel'
                )

                const namHoc_Update = this.selectedValue.selectedValueSchoolYear
                if (capHocList_Update.length === 0) {
                    this.$message({
                        message: 'Vui lòng chọn cấp học',
                        type: 'error'
                    })
                    loading.close()
                    return
                }
                const start = this.start
                const limit = this.limit
                const request_Header = {
                    token: this.authToken
                }
                const request_Data = {
                    ...this.request_Data,
                    start: start,
                    limit: limit,
                    capHocList: capHocList_Update,
                    maDonViList: maDonViList_Update,
                    namHoc: namHoc_Update || currentYear,
                    maSo: this.authUser.province
                }

                const response = await sendRequest(
                    Api.tiepNhanDuLieuQLNT.baoCaoTinhHinhNopDuLieu.capSo,
                    request_Data,
                    request_Header
                )

                if (response.rc == 0) {
                    setTimeout(() => {
                        loading.close()
                        this.$message({
                            message: 'Danh sách dữ liệu',
                            type: 'success'
                        })
                    }, 1000)
                } else {
                    loading.close()
                    this.$message({
                        message: 'Danh sách dữ liệu trống',
                        type: 'error'
                    })
                }

                this.tableData = response.tableData
                this.total_rows = response.totalRows
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        },

        customhandleESelectedChange(options, valueType) {
            switch (valueType) {
                case 'selectedValueUnitEducation':
                    return options.map((option) => option.value)
                case 'selectedValueGradeLevel':
                    return options.map((option) => Number(option.value))
            }
        },
        leftBarWidth() {
            if (this.isCollapseLeftBar) {
                return 'calc(100vw - 100px)'
            } else {
                return 'calc(100vw - 340px)'
            }
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        },
        ...mapState(['isCollapseLeftBar'])
    },
    created() {
        this.selectedValue.selectedValueDisplayContent =
            this.getDataESelect.ESelectDisplayContent
    }
}
</script>
<style scoped>
.layout-options {
    border-radius: 4px 4px 0px 0px;
    padding: 20px 10px 5px 10px;

    background-color: #fff;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}

.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.bg-search {
    background-color: var(--teal);
}
</style>
