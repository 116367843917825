<template>
    <div></div>
</template>
<script>
import Api from '@/constants/Api'
import sendRequest from '@/services'
import store from '@/store'
import axios from 'axios'

export default {
    name: 'LoginSSO',
    components: {},
    data() {
        return {
            login: {},
            authorizationCode: null
        }
    },

    async mounted() {
        console.log('mounted login sso...')
        console.log(this.$route.query.code)
        this.authorizationCode = this.$route.query.code
        const apiPath =
            'https://apimoet.dtsgroup.com.vn/csdlgd-user/auth/loginSsoDienBien'

        axios
            .get(apiPath, {
                params: {
                    authorizationCode: this.authorizationCode
                }
            })
            .then((response) => {
                console.log('Response from API:', response.data)
                if (response.data.rc === 0) {
                    this.token = response.data.token
                    store.commit('SET_AUTH_USER', response.data)
                    store.commit('SET_AUTH_TOKEN', response.data.token)
                    this.$router.push('/')
                    window.location.reload()
                } else if (response.rc !== 0) {
                    this.errorMessage = response.rd
                    this.$router.push('/login')
                    window.location.reload()
                }
            })
            .catch((error) => {
                console.error('Error calling API:', error)
            })
    },
    methods: {}
}
</script>
