<template>
    <div>
        <div class="row content">
            <div class="col-6">
                <label>Tiêu chí báo cáo <span class="span">*</span></label>
                <div>
                    <ESelect
                        :key="selectedValue.selectedValue_TieuChiBaoCao?.index"
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelect_TieuChiBaoCao"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['name', 'index']"
                        v-model="selectedValue.selectedValue_TieuChiBaoCao"
                        @input="handleChange_TieuChiBaoCao"
                    />
                </div>
            </div>
            <div v-if="name !== ''" class="name col-4">
                <h4>
                    Bạn đang sử dụng mẫu báo cáo: <span>{{ name }}</span>
                </h4>
            </div>
            <div class="col-12 mt-3">
                <draggable
                    class="list-group"
                    tag="div"
                    v-model="this.selectedValue.selectedValue_TieuChiBaoCao"
                    v-bind="dragOptions"
                    @start="drag = true"
                    @end="drag = false"
                >
                    <transition-group type="transition">
                        <li
                            class="list-group-item"
                            v-for="(item, index) in this.selectedValue
                                .selectedValue_TieuChiBaoCao"
                            :key="item.value"
                        >
                            <span>{{ index + 1 }}: {{ item.title }}</span>
                        </li>
                    </transition-group>
                </draggable>
            </div>
            <div class="col-md-12 mt-3">
                <el-card class="box-card">
                    <el-collapse v-model="activeNames">
                        <el-collapse-item title="Bộ lọc dữ liệu" name="1">
                            <div class="row">
                                <div class="mt-3 col-md-12">
                                    <label>Tiêu chí</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelectAdvancedSearch
                                            "
                                            :placeholder="'Chọn nội dung'"
                                            :filterable="true"
                                            :multiple="true"
                                            :collapse-tags="true"
                                            :fields="['name', 'nameCol']"
                                            v-model="
                                                selectedValue.selectedValueAdvancedSearch
                                            "
                                            @input="handleTimKiemNangCao"
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-4 mt-3"
                                    v-if="hienThiNangCao.maDonVi"
                                >
                                    <label>Đơn vị quản lý</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="
                                                this.authUser.role === 5 ||
                                                this.authUser.role === 4
                                            "
                                            :data="this.dataChonDonVi_Store"
                                            :placeholder="'Chọn đơn vị'"
                                            :filterable="true"
                                            :multiple="true"
                                            :collapse-tags="true"
                                            :fields="['tenDonVi', 'maDonVi']"
                                            v-model="
                                                selectedValue.selectedValue_DonVi
                                            "
                                            @input="getDataChonTruongHoc"
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-2 mt-3"
                                    v-if="hienThiNangCao.capHoc"
                                >
                                    <label>Cấp học</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="getDataESelect.ESelect_CapHoc"
                                            :placeholder="'Chọn cấp học'"
                                            :filterable="true"
                                            :multiple="true"
                                            :collapse-tags="true"
                                            :fields="['tenTruongHoc', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_CapHoc
                                            "
                                            @input="getDataChonTruongHoc"
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-4 mt-3"
                                    v-if="hienThiNangCao.maTruongHoc"
                                >
                                    <label>Trường học</label>
                                    <div>
                                        <ESelect
                                            :reset="resetESelectSchool"
                                            @reset-completed="
                                                handleResetSchoolCompleted
                                            "
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="this.authUser.role === 5"
                                            :data="
                                                getDataESelect.ESelect_TruongHoc
                                            "
                                            :placeholder="'Chọn trường học'"
                                            :filterable="true"
                                            :multiple="true"
                                            :collapse-tags="true"
                                            :fields="['title', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_TruongHoc
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-2 mt-3"
                                    v-if="hienThiNangCao.namHoc"
                                >
                                    <label>Năm học</label>
                                    <ESelectYear
                                        v-model="
                                            selectedValue.selectedValue_NamHoc
                                        "
                                        placeholder="Chọn năm"
                                        size="small"
                                        :no-data-text="'Không có bản ghi nào'"
                                        :no-match-text="'Không tìm thấy bản ghi nào'"
                                    ></ESelectYear>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.nhomMau"
                                >
                                    <label>Nhóm máu</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="this.dataChonNhommau_Store"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="true"
                                            :collapse-tags="true"
                                            :fields="[
                                                'constantTitle',
                                                'mappingCode'
                                            ]"
                                            v-model="
                                                selectedValue.selectedValue_NhomMau
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.khuyetTat"
                                >
                                    <label>Khuyết tật</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_KhuyetTat
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_KhuyetTat
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.maDtuongCsach"
                                >
                                    <label>Diện chính sách</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                this
                                                    .dataChonDoituongchinhsach_Store
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="true"
                                            :collapse-tags="true"
                                            :fields="[
                                                'constantTitle',
                                                'mappingCode'
                                            ]"
                                            v-model="
                                                selectedValue.selectedValue_DienChinhSach
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.tphanGdinh"
                                >
                                    <label>Thành phần gia đình</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                this
                                                    .dataChonThanhphangiadinh_Store
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="true"
                                            :collapse-tags="true"
                                            :fields="[
                                                'constantTitle',
                                                'mappingCode'
                                            ]"
                                            v-model="
                                                selectedValue.selectedValue_ThanhPhanGiaDinh
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.dtuongUuTien"
                                >
                                    <label>Diện ưu tiên</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                this.dataChonDoituonguutien_Store
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="true"
                                            :collapse-tags="true"
                                            :fields="[
                                                'constantTitle',
                                                'mappingCode'
                                            ]"
                                            v-model="
                                                selectedValue.selectedValue_DienUuTien
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.doiVien"
                                >
                                    <label>Đội viên</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="getDataESelect.ESelect_DoiVien"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_DoiVien
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.dangVien"
                                >
                                    <label>Đoàn viên</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_DoanVien
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_DoanVien
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.dangVien"
                                >
                                    <label>Đảng viên</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_DangVien
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_DangVien
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.maLoaiKhuyetTat"
                                >
                                    <label>Loại khuyết tật</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                this.dataChonLoaiKhuyettat_Store
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="true"
                                            :collapse-tags="true"
                                            :fields="[
                                                'constantTitle',
                                                'mappingCode'
                                            ]"
                                            v-model="
                                                selectedValue.selectedValue_LoaiKhuyetTat
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.maKhuVuc"
                                >
                                    <label>Khu vực</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="this.dataChonKhuvuc_Store"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="true"
                                            :collapse-tags="true"
                                            :fields="[
                                                'constantTitle',
                                                'mappingCode'
                                            ]"
                                            v-model="
                                                selectedValue.selectedValue_KhuVuc
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.loaiNhapHoc"
                                >
                                    <label>Loại nhập học</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="this.dataChonLoainhaphoc_Store"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="true"
                                            :collapse-tags="true"
                                            :fields="[
                                                'constantTitle',
                                                'mappingCode'
                                            ]"
                                            v-model="
                                                selectedValue.selectedValue_LoaiNhapHoc
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.hocKnsSkss"
                                >
                                    <label>Học kỹ năng skss</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                this.getDataESelect
                                                    .ESelect_HocKnsSkss
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_HocKnsSkss
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.khoiHoc"
                                >
                                    <label>Khối học</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="this.dataChonKhoiHoc_Store"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="true"
                                            :collapse-tags="true"
                                            :fields="[
                                                'constantTitle',
                                                'mappingCode'
                                            ]"
                                            v-model="
                                                selectedValue.selectedValue_KhoiHoc
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.trangThai"
                                >
                                    <label>Trạng thái</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_TrangThai
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="true"
                                            :collapse-tags="true"
                                            :fields="['title', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_TrangThai
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.htHoTro"
                                >
                                    <label>Hỗ trợ học tập</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_HoTroHocTap
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="true"
                                            :collapse-tags="true"
                                            :fields="['title', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_HoTroHocTap
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.thoiDiemRoiTruong"
                                >
                                    <label>Thời điểm rời trường</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_ThoiDiemRoiTruong
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="true"
                                            :collapse-tags="true"
                                            :fields="['title', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_ThoiDiemRoiTruong
                                            "
                                        />
                                    </div>
                                </div>

                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.kiemTraSucKhoeDinhDuong"
                                >
                                    <label
                                        >Kiểm tra sức khỏe dinh dưỡng
                                        <span class="span">*</span></label
                                    >
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_KiemTraSucKhoeDinhDuong
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_KiemTraSucKhoeDinhDuong
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.tinhTrangDinhDuong"
                                >
                                    <label
                                        >Tình trạng dinh dưỡng
                                        <span class="span">*</span></label
                                    >
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                this
                                                    .dataChonTinhTrangDinhDuong_Store
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="true"
                                            :collapse-tags="true"
                                            :fields="[
                                                'constantTitle',
                                                'mappingCode'
                                            ]"
                                            v-model="
                                                selectedValue.selectedValue_TinhTrangDinhDuong
                                            "
                                        />
                                    </div>
                                </div>

                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.chaDanToc"
                                >
                                    <label>Cha dân tộc</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_ChaDanToc
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_ChaDanToc
                                            "
                                        />
                                    </div>
                                </div>

                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.meDanToc"
                                >
                                    <label>Mẹ dân tộc </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_MeDanToc
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_MeDanToc
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.maLyDoThoiHoc"
                                >
                                    <label>Lý do thôi học</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="this.dataChonLyDoThoiHoc_Store"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="true"
                                            :collapse-tags="true"
                                            :fields="[
                                                'constantTitle',
                                                'mappingCode'
                                            ]"
                                            v-model="
                                                selectedValue.selectedValue_LyDoThoiHoc
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.hocSinhTiengDanToc"
                                >
                                    <label>Học tiếng dân tộc </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_HocTiengDanToc
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_HocTiengDanToc
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.hocSongNgu"
                                >
                                    <label>Học song ngữ </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_HocSongNgu
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_HocSongNgu
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.hocSinhBanTruDanNuoi"
                                >
                                    <label>Học bán trú dân nuôi </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_HocBanTruDanNuoi
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_HocBanTruDanNuoi
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.hocSinhNoiTruDanNuoi"
                                >
                                    <label>Học nội trú dân nuôi </label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_HocNoiTruDanNuoi
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_HocNoiTruDanNuoi
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.khuyetTatKhongDanhGia"
                                >
                                    <label>Khuyết tật không đánh giá</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_KhuyetTatKhongDanhGia
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_KhuyetTatKhongDanhGia
                                            "
                                        />
                                    </div>
                                </div>

                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.hoNgheo"
                                >
                                    <label>Hộ nghèo</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="getDataESelect.ESelect_HoNgheo"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_HoNgheo
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.htroNhaO"
                                >
                                    <label>Hỗ trợ nhà ở</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_HoTroNhaO
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_HoTroNhaO
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.capTienHangThang"
                                >
                                    <label>Được cấp tiền hàng tháng</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_DuocCapTienHangThang
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_DuocCapTienHangThang
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.capGao"
                                >
                                    <label>Được cấp gạo</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_DuocCapGao
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_DuocCapGao
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.hocCtgdCuaBo"
                                >
                                    <label>Học chương trình GD của Bộ</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_HocChuongTrinhGDCuaBo
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_HocChuongTrinhGDCuaBo
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.htChiPhiHocTap"
                                >
                                    <label>Được hỗ trợ chi phí học tập</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_DuocHoTroChiPhiHocTap
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_DuocHoTroChiPhiHocTap
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.hocSinhBanTru"
                                >
                                    <label>Học sinh bán trú</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_HocSinhBanTru
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_HocSinhBanTru
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.hocSinhNoiTru"
                                >
                                    <label>Học sinh nội trú</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_HocSinhNoiTru
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_HocSinhNoiTru
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.hocSinhPtdtBanTru"
                                >
                                    <label>Học sinh PTDT bán trú</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_HocSinhPDDTBanTru
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_HocSinhPDDTBanTru
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.chuyenSangHocNghe"
                                >
                                    <label>Chuyển sang học nghề</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_ChuyenSangHocNghe
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_ChuyenSangHocNghe
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.bietBoiKy1"
                                >
                                    <label>Biết bơi kỳ 1</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_BietBoiKy1
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_BietBoiKy1
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.bietBoiKy2"
                                >
                                    <label>Biết bơi kỳ 2</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_BietBoiKy2
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_BietBoiKy2
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="
                                        hienThiNangCao.meBiBenhTatTrongTkMangThai
                                    "
                                >
                                    <label
                                        >Mẹ bị bệnh trong thời kỳ mang thai</label
                                    >
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="
                                                getDataESelect.ESelect_MeBiBenhTrongThoiKyMangThai
                                            "
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_MeBiBenhTrongThoiKyMangThai
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.canThi"
                                >
                                    <label>Cận thị</label>
                                    <div>
                                        <ESelect
                                            style="width: 100%"
                                            no-match-text="Không tìm thấy bản ghi nào"
                                            no-data-text="danh sách lựa chọn trống"
                                            :clearable="true"
                                            :disabled="false"
                                            :data="getDataESelect.ESelect_CanThi"
                                            :placeholder="'Chọn'"
                                            :filterable="true"
                                            :multiple="false"
                                            :collapse-tags="true"
                                            :fields="['name', 'value']"
                                            v-model="
                                                selectedValue.selectedValue_CanThi
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-3 mt-3"
                                    v-if="hienThiNangCao.chiSoBMI"
                                >
                                    <label>Chỉ số BMI</label>
                                    <div>
                                        <el-input
                                            placeholder="Nhập"
                                            v-model="selectedValue.input_ChiSoBMI"
                                            size="small"
                                        >
                                            <template slot="prepend"
                                                >Cân nặng/(C.cao)</template
                                            >
                                        </el-input>
                                    </div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </el-card>
            </div>
        </div>
        <div class="btn-search">
            <CustomButton
                label="Tìm kiếm"
                size="small"
                class="btn-default bg-search"
                @click="handleSearch"
            />
        </div>
        <el-card class="box-card mt-4">
            <div class="layout-btn-card">
                <div>
                    <chonSoLuongBanGhi
                        @chonXongSoLuong="ChonSoLuongBanGhi($event)"
                    />
                </div>
                <div>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="Xuất Excel"
                        placement="top-start"
                    >
                        <CustomButton
                            style="
                                background-color: #6aab9c;
                                color: #fff;
                                font-weight: 600;
                            "
                            size="small"
                            label="Tải xuống"
                            @click="handleTaiXuong"
                        />
                    </el-tooltip>

                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="Lưu lại nội dung hiển thị và bộ lọc dữ liệu"
                        placement="top-start"
                    >
                        <CustomButton
                            size="small"
                            label="Lưu Mẫu"
                            style="
                                background-color: #ffa554;
                                color: #fff;
                                font-weight: 600;
                            "
                            @click="openModalLuuMau"
                        />
                    </el-tooltip>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="Sử dụng nội dung hiển thị và bộ lọc dữ liệu có sẵn"
                        placement="top-start"
                    >
                        <CustomButton
                            style="
                                background-color: #1e6050;
                                color: #fff;
                                font-weight: 600;
                            "
                            size="small"
                            label="Tải mẫu"
                            @click="openModalTaiMau"
                        />
                    </el-tooltip>
                </div>
            </div>

            <CustomTable :maxWidth="leftBarWidth()" :columns="tableHeader">
                <template v-if="tableRows.length === 0">
                    <tr>
                        <td colspan="36" class="text-tbody">
                            Không có bản ghi nào.
                        </td>
                    </tr>
                </template>
                <tr v-for="(row, rowIndex) in tableRows" :key="rowIndex">
                    <td
                        class="text-tbody text-left"
                        v-for="(col, colIndex) in row.col"
                        :key="colIndex"
                    >
                        {{ col.value }}
                    </td>
                </tr>
            </CustomTable>
            <CustomPagination
                v-show="total_rows > 0"
                :tongbanghi="total_rows"
                :soluonghienthi="soLuongBanGhiHienThi"
                :batdau="trangbatdau"
                @pageChange="layLai($event)"
            >
            </CustomPagination>
        </el-card>
        <el-dialog
            :visible="luuMauModalVisible"
            @close="closeModalLuuMau"
            width="50%"
        >
            <span slot="title">
                Lưu mẫu
                <hr />
            </span>
            <div class="dialog">
                <label>Tên mẫu:<span class="span">*</span></label>
                <div class="mt-1">
                    <el-input
                        placeholder="Nhập tên mẫu"
                        v-model="selectedValue.input_luuMau"
                        size="small"
                    ></el-input>
                </div>
            </div>
            <div class="layout-btn">
                <CustomButton
                    label="Đóng"
                    size="small"
                    @click="closeModalLuuMau"
                    style="
                        background-color: #e14a02;
                        color: #fff;
                        font-weight: 600;
                    "
                />
                <CustomButton
                    label="Lưu lại"
                    size="small"
                    style="
                        background-color: #ffa554;
                        color: #fff;
                        font-weight: 600;
                    "
                    @click="handleLuuMau"
                />
            </div>
        </el-dialog>
        <el-dialog
            :visible="taiMauModalVisible"
            @close="closeModalTaiMau"
            width="80%"
        >
            <span slot="title">
                Mẫu có sẵn
                <hr />
            </span>
            <table class="table table-bordered table-hover centered-table">
                <thead>
                    <tr>
                        <th class="text-thead">STT</th>
                        <th class="text-thead">Tên mẫu</th>
                        <th class="text-thead">Mã</th>
                        <th class="text-thead">Thời gian tạo</th>
                        <th class="text-thead">Thao tác</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="tableDataTaiMau.length === 0">
                        <tr>
                            <td class="text-tbody text-center" colspan="9">
                                Không có bản ghi nào.
                            </td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in tableDataTaiMau" :key="index">
                        <td class="text-tbody text-center">{{ index + 1 }}</td>
                        <td class="text-tbody">{{ item.ten }}</td>
                        <td class="text-tbody text-center">{{ item.id }}</td>
                        <td class="text-tbody text-center">
                            {{ item.timeCreate }}
                        </td>
                        <td class="text-tbody text-center">
                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="Áp dụng"
                                placement="top-start"
                            >
                                <CustomButton
                                    label=""
                                    icon="el-icon-check"
                                    size="small"
                                    class="icon"
                                    type="primary"
                                    @click="handleApDung(item)"
                                />
                            </el-tooltip>
                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="Xóa"
                                placement="top-start"
                            >
                                <CustomButton
                                    type="danger"
                                    label=""
                                    icon="el-icon-delete"
                                    size="small"
                                    class="icon"
                                    @click="handleDeleteTaiMau(item.id)"
                                />
                            </el-tooltip>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="layout-btn">
                <CustomButton
                    label="Đóng"
                    size="small"
                    @click="closeModalTaiMau"
                    type="info"
                />
                <CustomButton label="Import" size="small" type="primary" />
            </div>
        </el-dialog>
    </div>
</template>
<script>
import ESelect from '@/components/ESelect.vue'
import draggable from 'vuedraggable'
import CustomButton from '@/components/CustomButton.vue'
import ESelectYear from '@/components/ESelectYear.vue'
import chonSoLuongBanGhi from '@/components/chonSoLuongBanGhi.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import { convertCapHocList, currentYear, filterCapHocList } from '@/utils'
import {
    ESelectGradeLevel_MockData,
    ESelectReportingCriteria_HocSinh_TieuChi,
    ESlectYesOfNo_MockData,
    ESelectTrueOfFalse,
    ESelectStatus_MockData_Tracuuhocsinh,
    ESelectReportingCriteria_HocSinh_TieuChiBaoCao,
    ESelect_HoTroHocTap,
    ESelect_ThoiDiemChuyenTruong
} from '@/mock_data'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { mapState } from 'vuex'
import axios from 'axios'
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'BaoCaoHocSinh',
    props: {
        ESelect_DoiTac: {
            type: Array
        }
    },
    components: {
        ESelect,
        CustomButton,
        draggable,
        ESelectYear,
        CustomPagination,
        chonSoLuongBanGhi,
        CustomTable
    },
    data() {
        return {
            activeNames: ['1'],
            activeTab: 0,

            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            soLuongBanGhiHienThi: 25,
            trangbatdau: false,
            luuMauModalVisible: false,
            taiMauModalVisible: false,
            resetESelectSchool: false,
            hienThiNangCao: {
                maDonVi: false,
                capHoc: false,
                maTruongHoc: false,
                namHoc: false,
                nhomMau: false,
                khuyetTat: false,
                maDtuongCsach: false,
                dtuongUuTien: false,
                doiVien: false,
                dangVien: false,
                maLoaiKhuyetTat: false,
                maKhuVuc: false,
                loaiNhapHoc: false,
                hocKnsSkss: false,
                khoiHoc: false,
                trangThai: false,
                htHoTro: false,
                thoiDiemRoiTruong: false,
                maMonHoc: false,
                maDanToc: false,
                maLyDoThoiHoc: false,
                kiemTraSucKhoeDinhDuong: false,
                tinhTrangDinhDuong: false,
                hinhThucDaoTao: false,
                maChuyenNganhDaoTao: false,
                trinhDoVanHoa: false,
                trinhDoTinHoc: false,
                trinhDoNgoaiNgu: false,
                trinhDoLyLuanChinhTri: false,
                trinhDoQuanLyNhaNuoc: false,
                trinhDoQuanLyGiaoDuc: false,
                maNgach: false,
                dayNhomLop: false,
                dayNhomLopKiemNhiem: false,
                monDayChinh: false,
                monDayKiemNhiem: false,
                nhiemVuKiemNhiem: false,
                chaDanToc: false,
                heSo: false,
                vuotKhung: false,
                monNgoaiNguChinh: false,
                chuyenNganhDaoTaoChinh: false,
                meDanToc: false,
                danhGiaVienChuc: false,
                tphanGdinh: false,
                hocSinhTiengDanToc: false,
                hocSongNgu: false,
                hocSinhBanTruDanNuoi: false,
                hocSinhNoiTruDanNuoi: false,
                khuyetTatKhongDanhGia: false,
                hoNgheo: false,
                htroNhaO: false,
                capTienHangThang: false,
                capGao: false,
                hocCtgdCuaBo: false,
                htChiPhiHocTap: false,
                hocSinhBanTru: false,
                hocSinhNoiTru: false,
                hocSinhPtdtBanTru: false,
                chuyenSangHocNghe: false,
                bietBoiKy1: false,
                bietBoiKy2: false,
                meBiBenhTatTrongTkMangThai: false,
                canThi: false,
                chiSoBMI: false
            },
            name: '',
            tableRows: [],
            tableHeader: [],
            tableDataTaiMau: [],
            getDataESelect: {
                ESelectAdvancedSearch: ESelectReportingCriteria_HocSinh_TieuChi,

                ESelect_TieuChiBaoCao:
                    ESelectReportingCriteria_HocSinh_TieuChiBaoCao,
                ESelect_TieuChi: ESelectReportingCriteria_HocSinh_TieuChi,
                ESelect_DonVi: [],
                ESelect_CapHoc: ESelectGradeLevel_MockData,

                ESelect_TruongHoc: [],

                ESelect_NamHoc: [],
                ESelect_TrangThai: ESelectStatus_MockData_Tracuuhocsinh,
                ESelect_KhuyetTat: ESlectYesOfNo_MockData,
                ESelect_LoaiHinhTruong: [],
                ESelect_LoaiHopDong: [],
                ESelect_DoiVien: ESlectYesOfNo_MockData,
                ESelect_DoanVien: ESlectYesOfNo_MockData,
                ESelect_DangVien: ESlectYesOfNo_MockData,
                ESelect_TrinhDoChuyenMon: [],
                ESelect_KhuVuc: [],
                ESelect_HocKnsSkss: ESlectYesOfNo_MockData,
                ESelect_DanhGiaChuanNgheNghiep: [],

                ESelect_HoTroHocTap: ESelect_HoTroHocTap,
                ESelect_ThoiDiemRoiTruong: ESelect_ThoiDiemChuyenTruong,
                ESelect_MonDay: [],
                ESelect_DanToc: [],
                ESelect_KiemTraSucKhoeDinhDuong: ESlectYesOfNo_MockData,
                ESelect_TrinhDoDaoTao: [],
                ESelect_HinhThucDaoTao: [],
                ESelect_ChuyenNganhDaoTao: [],
                ESelect_TrinhDoVanHoa: [],
                ESelect_TrinhDoTinHoc: [],
                ESelect_TrinhDoNgoaiNgu: [],
                ESelect_TrinhDoLyLuanChinhTri: [],
                ESelect_TrinhDoQuanLyNhaNuoc: [],
                ESelect_TrinhDoQuanLyGiaoDuc: [],
                ESelect_MaNgach: [],
                ESelect_DayNhomLop: [],
                ESelect_DayNhomLopKiemNghiem: [],
                ESelect_MonDayKiemNghiem: [],
                ESelect_NhiemVuKiemNghiem: [],

                ESelect_MonNgoaiNguChinh: [],
                ESelect_ChuyenNganhDaoTaoChinh: [],
                ESelect_ChaDanToc: ESelectTrueOfFalse,
                ESelect_MeDanToc: ESelectTrueOfFalse,
                ESelect_DanhGiaVienChuc: [],
                ESelect_ThanhPhanGiaDinh: [],
                ESelect_HocTiengDanToc: ESelectTrueOfFalse,
                ESelect_HocSongNgu: ESelectTrueOfFalse,
                ESelect_HocBanTruDanNuoi: ESelectTrueOfFalse,
                ESelect_HocNoiTruDanNuoi: ESelectTrueOfFalse,
                ESelect_KhuyetTatKhongDanhGia: ESelectTrueOfFalse,
                ESelect_HoNgheo: ESelectTrueOfFalse,
                ESelect_HoTroNhaO: ESelectTrueOfFalse,
                ESelect_DuocCapTienHangThang: ESelectTrueOfFalse,
                ESelect_DuocCapGao: ESelectTrueOfFalse,
                ESelect_HocChuongTrinhGDCuaBo: ESelectTrueOfFalse,
                ESelect_DuocHoTroChiPhiHocTap: ESelectTrueOfFalse,
                ESelect_HocSinhBanTru: ESelectTrueOfFalse,
                ESelect_HocSinhNoiTru: ESelectTrueOfFalse,
                ESelect_HocSinhPDDTBanTru: ESelectTrueOfFalse,
                ESelect_ChuyenSangHocNghe: ESelectTrueOfFalse,
                ESelect_BietBoiKy1: ESelectTrueOfFalse,
                ESelect_BietBoiKy2: ESelectTrueOfFalse,
                ESelect_MeBiBenhTrongThoiKyMangThai: ESelectTrueOfFalse,
                ESelect_CanThi: ESelectTrueOfFalse
            },

            selectedValue: {
                selectedValueAdvancedSearch: [], //timkiemnangcao

                selectedValue_TieuChiBaoCao: [],

                selectedValue_DonVi: [],
                selectedValue_CapHoc: [],

                selectedValue_TruongHoc: [],

                selectedValue_NamHoc: [],
                selectedValue_NhomMau: [],
                selectedValue_KhuyetTat: [],
                selectedValue_DienChinhSach: [],
                selectedValue_DienUuTien: [],
                selectedValue_DoiVien: [],
                selectedValue_DoanVien: [],
                selectedValue_DangVien: [],
                selectedValue_LoaiKhuyetTat: [],
                selectedValue_KhuVuc: [],
                selectedValue_LoaiNhapHoc: [],
                selectedValue_HocKnsSkss: [],
                selectedValue_KhoiHoc: [],
                selectedValue_TrangThai: [],
                selectedValue_HoTroHocTap: [],
                selectedValue_ThoiDiemRoiTruong: [],
                selectedValue_MonDay: [],
                selectedValue_DanToc: [],
                selectedValue_KiemTraSucKhoeDinhDuong: [],
                selectedValue_TinhTrangDinhDuong: [],
                selectedValue_HinhThucDaoTao: [],
                selectedValue_ChuyenNganhDaoTao: [],
                selectedValue_TrinhDoVanHoa: [],
                selectedValue_TrinhDoTinHoc: [],
                selectedValue_TrinhDoNgoaiNgu: [],
                selectedValue_TrinhDoLyLuanChinhTri: [],
                selectedValue_TrinhDoQuanLyNhaNuoc: [],
                selectedValue_TrinhDoQuanLyGiaoDuc: [],
                selectedValue_MaNgach: [],
                selectedValue_DayNhomLop: [],
                selectedValue_DayNhomLopKiemNghiem: [],
                selectedValue_MonDayChinh: [],
                selectedValue_MonDayKiemNghiem: [],
                selectedValue_NhiemVuKiemNghiem: [],
                selectedValue_ChaDanToc: [],
                input_HeSoLuong: '',
                input_VuotKhung: '',
                selectedValue_MonNgoaiNguChinh: [],
                selectedValue_ChuyenNganhDaoTaoChinh: [],
                selectedValue_MeDanToc: [],
                selectedValue_LyDoThoiHoc: [],
                selectedValue_HocTiengDanToc: [],
                selectedValue_HocSongNgu: [],
                selectedValue_HocBanTruDanNuoi: [],
                selectedValue_HocNoiTruDanNuoi: [],

                selectedValue_KhuyetTatKhongDanhGia: [],
                selectedValue_HoNgheo: [],
                selectedValue_HoTroNhaO: [],
                selectedValue_DuocCapTienHangThang: [],
                selectedValue_DuocCapGao: [],
                selectedValue_HocChuongTrinhGDCuaBo: [],
                selectedValue_DuocHoTroChiPhiHocTap: [],
                selectedValue_HocSinhBanTru: [],
                selectedValue_HocSinhNoiTru: [],
                selectedValue_HocSinhPDDTBanTru: [],
                selectedValue_ChuyenSangHocNghe: [],
                selectedValue_BietBoiKy1: [],
                selectedValue_BietBoiKy2: [],
                selectedValue_MeBiBenhTrongThoiKyMangThai: [],
                selectedValue_CanThi: [],
                input_ChiSoBMI: '',

                input_luuMau: ''
            }
        }
    },
    methods: {
        openModalLuuMau() {
            this.luuMauModalVisible = true
        },
        closeModalLuuMau() {
            this.luuMauModalVisible = false
        },
        openModalTaiMau() {
            this.taiMauModalVisible = true
            this.getDataTableTaiMau()
        },
        closeModalTaiMau() {
            this.taiMauModalVisible = false
        },
        ChonSoLuongBanGhi(e) {
            this.total_rows = 0
            this.soLuongBanGhiHienThi = e.soluong
        },
        checkTruocKhiTim() {
            this.trangbatdau = !this.trangbatdau
        },
        layLai(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        handleResetSchoolCompleted() {
            this.resetESelectSchool = false
        },
        handleTimKiemNangCao(value) {
            const selectedValues = value.map((item) => item.value)

            for (const key in this.hienThiNangCao) {
                this.hienThiNangCao[key] = selectedValues.includes(key)
            }
        },
        processAdvancedSearch(selectedValue) {
            const timkiemnangcao = this.convertToValueArray(
                selectedValue.selectedValueAdvancedSearch
            )

            const keyMappings_dataSreachList = {
                maDonVi: 'selectedValue_DonVi',
                capHoc: 'selectedValue_CapHoc',
                maTruongHoc: 'selectedValue_TruongHoc',

                nhomMau: 'selectedValue_NhomMau',
                khuyetTat: 'selectedValue_KhuyetTat',
                maDtuongCsach: 'selectedValue_DienChinhSach',
                tphanGdinh: 'selectedValue_ThanhPhanGiaDinh',
                dtuongUuTien: 'selectedValue_DienUuTien',
                doiVien: 'selectedValue_DoiVien',
                doanVien: 'selectedValue_DoanVien',
                dangVien: 'selectedValue_DangVien',
                maLoaiKhuyetTat: 'selectedValue_LoaiKhuyetTat',
                maKhuVuc: 'selectedValue_KhuVuc',
                loaiNhapHoc: 'selectedValue_LoaiNhapHoc',
                hocKnsSkss: 'selectedValue_HocKnsSkss',
                khoiHoc: 'selectedValue_KhoiHoc',
                trangThai: 'selectedValue_TrangThai',
                htHoTro: 'selectedValue_HoTroHocTap',
                thoiDiemRoiTruong: 'selectedValue_ThoiDiemRoiTruong',
                maMonHoc: 'selectedValue_MonDay',
                maDanToc: 'selectedValue_DanToc',
                kiemTraSucKhoeDinhDuong: 'selectedValue_KiemTraSucKhoeDinhDuong',
                tinhTrangDinhDuong: 'selectedValue_TinhTrangDinhDuong',
                hinhThucDaoTao: 'selectedValue_HinhThucDaoTao',
                maChuyenNganhDaoTao: 'selectedValue_ChuyenNganhDaoTao',
                trinhDoVanHoa: 'selectedValue_TrinhDoVanHoa',
                trinhDoTinHoc: 'selectedValue_TrinhDoTinHoc',
                trinhDoNgoaiNgu: 'selectedValue_TrinhDoNgoaiNgu',
                trinhDoLyLuanChinhTri: 'selectedValue_TrinhDoLyLuanChinhTri',
                trinhDoQuanLyNhaNuoc: 'selectedValue_TrinhDoQuanLyNhaNuoc',
                trinhDoQuanLyGiaoDuc: 'selectedValue_TrinhDoQuanLyGiaoDuc',
                maNgach: 'selectedValue_MaNgach',
                dayNhomLop: 'selectedValue_DayNhomLop',
                dayNhomLopKiemNhiem: 'selectedValue_DayNhomLopKiemNghiem',
                monDayChinh: 'selectedValue_MonDayChinh',
                monDayKiemNhiem: 'selectedValue_MonDayKiemNghiem',
                nhiemVuKiemNhiem: 'selectedValue_NhiemVuKiemNghiem',
                chaDanToc: 'selectedValue_ChaDanToc',
                heSo: 'input_HeSoLuong',
                vuotKhung: 'input_VuotKhung',
                monNgoaiNguChinh: 'selectedValue_MonNgoaiNguChinh',
                chuyenNganhDaoTaoChinh: 'selectedValue_ChuyenNganhDaoTaoChinh',
                meDanToc: 'selectedValue_MeDanToc',
                maLyDoThoiHoc: 'selectedValue_LyDoThoiHoc',
                hocSinhTiengDanToc: 'selectedValue_HocTiengDanToc',

                hocSongNgu: 'selectedValue_HocSongNgu',
                hocSinhBanTruDanNuoi: 'selectedValue_HocBanTruDanNuoi',
                hocSinhNoiTruDanNuoi: 'selectedValue_HocNoiTruDanNuoi',

                khuyetTatKhongDanhGia: 'selectedValue_KhuyetTatKhongDanhGia',
                hoNgheo: 'selectedValue_HoNgheo',
                htroNhaO: 'selectedValue_HoTroNhaO',
                capTienHangThang: 'selectedValue_DuocCapTienHangThang',
                capGao: 'selectedValue_DuocCapGao',
                hocCtgdCuaBo: 'selectedValue_HocChuongTrinhGDCuaBo',
                htChiPhiHocTap: 'selectedValue_DuocHoTroChiPhiHocTap',
                hocSinhBanTru: 'selectedValue_HocSinhBanTru',
                hocSinhNoiTru: 'selectedValue_HocSinhNoiTru',
                hocSinhPtdtBanTru: 'selectedValue_HocSinhPDDTBanTru',
                chuyenSangHocNghe: 'selectedValue_ChuyenSangHocNghe',
                bietBoiKy1: 'selectedValue_BietBoiKy1',
                bietBoiKy2: 'selectedValue_BietBoiKy2',
                meBiBenhTatTrongTkMangThai:
                    'selectedValue_MeBiBenhTrongThoiKyMangThai',
                canThi: 'selectedValue_CanThi',
                chiSoBMI: 'input_ChiSoBMI'
            }

            const dataSearchList = timkiemnangcao
                .map((key) => {
                    const valueKey = keyMappings_dataSreachList[key]
                    const inputValue = selectedValue[valueKey]

                    if (valueKey && inputValue) {
                        let value

                        if (Array.isArray(inputValue)) {
                            value = inputValue.map((item) => item.value)
                            return {
                                nameSreach: key,
                                values: value
                            }
                        } else if (typeof inputValue === 'object') {
                            value = inputValue.value
                        } else {
                            value = inputValue
                        }

                        return {
                            nameSreach: key,
                            value: value
                        }
                    }

                    return null
                })
                .filter((item) => item !== null)

            const result = dataSearchList
                .map((item) => {
                    const foundCriteria =
                        ESelectReportingCriteria_HocSinh_TieuChi.find(
                            (criteria) => criteria.nameCol === item.nameSreach
                        )

                    return foundCriteria
                        ? { name: foundCriteria.name, ...item }
                        : null
                })
                .filter((item) => item !== null)

            return result
        },
        async getDataChonTruongHoc() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const maDonVi = this.convertToValueArray(
                this.selectedValue.selectedValue_DonVi
            )

            const capHoc = this.convertToValueArray(
                this.selectedValue.selectedValue_CapHoc
            )

            const request_Header = {
                token: this.authToken
            }

            const request_Data = {
                capHoc: capHoc,
                maDonVi: maDonVi
            }

            let response = await sendRequest(
                Api.internal_api.dm_School,
                request_Data,
                request_Header,
                null
            )

            if (response.statusResponse == 0) {
                loading.close()
                this.getDataESelect.ESelect_TruongHoc = response.rows.map(
                    (item) => ({
                        title: `${item.tenTruongHoc} - [${item.maTruongHoc}]`,
                        value: item.maTruongHoc
                    })
                )
                loading.close()
            } else {
                loading.close()
            }
        },
        async getDataTableTaiMau() {
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Data = {
                    name: '',
                    type: 2
                }
                const response = await sendRequest(
                    Api.baoCao.baoCaoDong.baoCaoCanBo.taiMau,
                    request_Data,
                    request_Header
                )

                this.tableDataTaiMau = response.rows.slice(0, 10)
            } catch (error) {
                console.log(error)
            }
        },
        async handleApDung(item) {
            const request = JSON.parse(item.request)

            const colList = request.colList.map((item) => ({
                title: item.name,
                value: item.index
            }))

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            this.$confirm('Xác nhận áp dụng mẫu đã chọn?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    this.selectedValue.selectedValue_TieuChiBaoCao = colList
                    this.name = request.name
                    loading.close()
                    this.$nextTick(() => {
                        this.closeModalTaiMau()
                        this.$message({
                            type: 'success',
                            message: 'Áp dụng thành công'
                        })
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Hủy'
                    })
                })
        },
        handleDeleteTaiMau(id) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            this.$confirm('Xác nhận xóa?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const request_Header = {
                        token: this.authToken
                    }
                    const request_Data = {}

                    const response = await sendRequest(
                        Api.baoCao.baoCaoDong.baoCaoCanBo.xoaMau(id),
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        loading.close()
                        this.$message({
                            type: 'success',
                            message: 'Xóa thành công'
                        })
                        this.getDataTableTaiMau()
                    } else {
                        loading.close()
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Hủy'
                    })
                })
        },
        async handleSearch() {
            const loading = this.$loading({
                lock: true,
                text: 'Đang tổng hợp',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const selectedValue = this.selectedValue
            let requestList = this.selectedValue.selectedValue_NamHoc

            if (Array.isArray(requestList) && requestList.length === 0) {
                requestList = currentYear
            }

            const convertRequestList = [
                {
                    name: 'Năm học',
                    nameSreach: 'namHoc',
                    value: Number(requestList)
                }
            ]

            const requestLists = this.processAdvancedSearch(selectedValue)

            const colList = this.selectedValue.selectedValue_TieuChiBaoCao

            const converColList = colList
                .map((itemNew) => {
                    const matchingItem =
                        ESelectReportingCriteria_HocSinh_TieuChiBaoCao.find(
                            (itemOld) => itemOld.name === itemNew.title
                        )

                    return matchingItem || null
                })
                .filter(Boolean)

            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Data = {
                    start: this.start,
                    limit: this.limit,
                    requestList: convertRequestList,
                    requestLists: requestLists,
                    colList: converColList
                }
                const response = await sendRequest(
                    Api.baoCao.baoCaoDong.baoCaoHocSinh.danhSach,
                    request_Data,
                    request_Header
                )
                this.tableRows = response.tableRows
                this.tableHeader = response.tableHeaders.map((item) => ({
                    ...item,
                    key: item.index,
                    label: item.value
                }))
                this.total_rows = response.total
                loading.close()
            } catch (error) {
                loading.close()
                console.log(error)
            }
        },
        async handleTaiXuong() {
            const selectedValue = this.selectedValue
            let requestList = this.selectedValue.selectedValue_NamHoc

            if (Array.isArray(requestList) && requestList.length === 0) {
                requestList = currentYear
            }

            const convertRequestList = [
                {
                    name: 'Năm học',
                    nameSreach: 'namHoc',
                    value: Number(requestList)
                }
            ]

            const requestLists = this.processAdvancedSearch(selectedValue)

            const colList = this.selectedValue.selectedValue_TieuChiBaoCao

            const converColList = colList
                .map((itemNew) => {
                    const matchingItem =
                        ESelectReportingCriteria_HocSinh_TieuChiBaoCao.find(
                            (itemOld) => itemOld.name === itemNew.title
                        )

                    return matchingItem || null
                })
                .filter(Boolean)

            this.$confirm('Xác nhận tải xuống?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const domain = process.env.VUE_APP_BACKEND_BASE_URL
                    const request_Data = {
                        start: this.start,
                        limit: this.limit,
                        requestList: convertRequestList,
                        requestLists: requestLists,
                        colList: converColList
                    }
                    const response = await axios.post(
                        `${domain}/csdlgd-report-fetch/BaoCaoDongExcel/exportHocSinh`,
                        request_Data,
                        {
                            headers: {
                                token: this.authToken,
                                'Content-Type': 'application/json'
                            },
                            responseType: 'blob'
                        }
                    )

                    const blob = new Blob([response.data])
                    const blobUrl = window.URL.createObjectURL(blob)

                    const downloadLink = document.createElement('a')
                    downloadLink.href = blobUrl
                    downloadLink.download = 'BaoCaoHocSinh.xlsx'
                    downloadLink.style.display = 'none'

                    document.body.appendChild(downloadLink)
                    downloadLink.click()

                    window.URL.revokeObjectURL(blobUrl)
                })
                .catch(() => {
                    this.$message({
                        type: 'Hủy',
                        message: 'Delete canceled'
                    })
                })
        },
        async handleLuuMau() {
            if (this.selectedValue.input_luuMau === '') {
                this.$message({
                    type: 'error',
                    message: 'Vui lòng bổ sung tên mẫu'
                })
                return
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const selectedValue = this.selectedValue
            let requestList = this.selectedValue.selectedValue_NamHoc

            if (Array.isArray(requestList) && requestList.length === 0) {
                requestList = currentYear
            }

            const convertRequestList = [
                {
                    name: 'Năm học',
                    nameSreach: 'namHoc',
                    value: Number(requestList)
                }
            ]

            const requestLists = this.processAdvancedSearch(selectedValue)

            const colList = this.selectedValue.selectedValue_TieuChiBaoCao

            const converColList = colList
                .map((itemNew) => {
                    const matchingItem =
                        ESelectReportingCriteria_HocSinh_TieuChiBaoCao.find(
                            (itemOld) => itemOld.name === itemNew.title
                        )

                    return matchingItem || null
                })
                .filter(Boolean)

            const selectedConditions =
                this.selectedValue.selectedValueAdvancedSearch
            const hienThiBoLoc = {}
            ESelectReportingCriteria_HocSinh_TieuChi.forEach(
                (item) => (hienThiBoLoc[item.nameCol] = false)
            )

            for (const condition of selectedConditions) {
                const matchingItem =
                    ESelectReportingCriteria_HocSinh_TieuChi.find(
                        (item) => item.nameCol === condition.value
                    )

                if (matchingItem && matchingItem.status) {
                    hienThiBoLoc[matchingItem.nameCol] = true
                }
            }
            const isAllFalse = Object.values(hienThiBoLoc).every(
                (value) => value === false
            )
            const convertHienThiBoLoc = isAllFalse ? {} : hienThiBoLoc

            this.$confirm('Xác nhận lưu mẫu?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const request_Header = {
                        token: this.authToken
                    }
                    const request_Data = {
                        start: this.start,
                        limit: this.limit,
                        requestList: convertRequestList,
                        requestLists: requestLists,
                        colList: converColList,
                        name: this.selectedValue.input_luuMau,
                        type: 2,
                        hienThiBoLoc: convertHienThiBoLoc
                    }
                    const response = await sendRequest(
                        Api.baoCao.baoCaoDong.baoCaoCanBo.luuMau,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        loading.close()
                        this.closeModalLuuMau()
                        this.handleSearch()
                        this.$message({
                            type: 'success',
                            message: 'Mẫu đã được lưu lại'
                        })
                    } else {
                        loading.close()
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Hủy'
                    })
                })
        },
        handleChange_TieuChiBaoCao() {
            this.name = ''
        },
        convertToValueArray(array) {
            return array.map((item) => item.value)
        },
        leftBarWidth() {
            if (this.isCollapseLeftBar) {
                return 'calc(100vw - 85px)'
            } else {
                return 'calc(100vw - 450px)'
            }
        }
    },
    created() {
        this.selectedValue.selectedValue_TieuChiBaoCao = [
            { title: 'Năm học', value: 3 }
        ]
    },
    computed: {
        ...mapState(['isCollapseLeftBar']),
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        },
        dataChonNhommau_Store() {
            return JSON.parse(localStorage.getItem('data_ChonNhommau'))
        },
        dataChonDoituongchinhsach_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDoituongchinhsach'))
        },
        dataChonLoaiKhuyettat_Store() {
            return JSON.parse(localStorage.getItem('data_ChonLoaikhuyettat'))
        },
        dataChonLoaiHinhTruong_Store() {
            return JSON.parse(localStorage.getItem('data_ChonLoaihinhtruong'))
        },
        dataChonLoaiHopDong_Store() {
            return JSON.parse(localStorage.getItem('data_ChonHinhthuchopdong'))
        },
        dataChonTrinhDoChuyenMonNghiepVu_Store() {
            return JSON.parse(
                localStorage.getItem('data_ChonTrinhdochuyenmonnghiepvu')
            )
        },
        dataChonTinhTrangDinhDuong_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTinhTrangDinhDuong'))
        },
        dataChonKhuvuc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonKhuvuc'))
        },
        dataChonLoainhaphoc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonLoainhaphoc'))
        },
        dataChonKhoiHoc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonKhoiHoc'))
        },
        dataChonMondayGV_Store() {
            return JSON.parse(localStorage.getItem('data_ChonMondaygiaovien'))
        },
        dataChonDantoc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDantoc'))
        },
        dataChonTrinhdodaotao_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrinhdodaotao'))
        },
        dataChonHinhthucdaotao_Store() {
            return JSON.parse(localStorage.getItem('data_ChonHinhthucdaotao'))
        },
        dataChonChuyenNganhdaotao_Store() {
            return JSON.parse(localStorage.getItem('data_ChonNganhDaoTao'))
        },
        dataChonTrinhDoVanHoa_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrinhDoVanHoa'))
        },
        dataChonTrinhDoTinHoc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrinhDoTinHoc'))
        },
        dataChonTrinhDoNgoaingu_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrinhDoNgoaiNgu'))
        },
        dataChonTrinhDoLLCT_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrinhDoLLCT'))
        },
        dataChonTrinhDoQLNN_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrinhDoQLNN'))
        },
        dataChonTrinhDoQLGD_Store() {
            return JSON.parse(localStorage.getItem('data_ChonTrinhDoQLGD'))
        },
        dataChonMaNganh_Store() {
            return JSON.parse(localStorage.getItem('data_ChonNganhCC'))
        },
        dataChonMonDay_Store() {
            return JSON.parse(localStorage.getItem('data_ChonMondaygiaovien'))
        },
        dataChonNhiemVuKiemNghiem_Store() {
            return JSON.parse(localStorage.getItem('data_ChonNhiemvukiemnghiem'))
        },
        dataChonNgoainguChinh_Store() {
            return JSON.parse(localStorage.getItem('data_ChonNgoaingu'))
        },
        dataChonChuyenNganhDaoTaoChinh_Store() {
            return JSON.parse(localStorage.getItem('data_ChonChuyenMon'))
        },
        dataChonDGVienChuc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDGVienChuc'))
        },
        dataChonThanhphangiadinh_Store() {
            return JSON.parse(localStorage.getItem('data_ChonThanhphangiadinh'))
        },
        dataChonDoituonguutien_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDoituonguutien'))
        },
        dataChonLyDoThoiHoc_Store() {
            return JSON.parse(localStorage.getItem('data_ChonLyDoThoiHoc'))
        },
        dragOptions() {
            return {
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost'
            }
        }
    },
    watch: {
        'selectedValue.selectedValue_DonVi': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length && this.authUser.role !== 5) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        },
        'selectedValue.selectedValue_CapHoc': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length && this.authUser.role !== 5) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        },
        'authUser.role': {
            immediate: true,
            handler(newRole, oldRole) {
                if (newRole === 5) {
                    this.selectedValue.selectedValue_DonVi = [
                        {
                            value: this.authUser.ma_don_vi
                        }
                    ]

                    const convertedList = convertCapHocList(
                        this.authUser.capHocList
                    )
                    this.selectedValue.selectedValue_CapHoc = convertedList
                    this.selectedValue.selectedValue_TruongHoc = [
                        {
                            value: this.authUser.ma_truong_hoc
                        }
                    ]
                }
                if (newRole === 4) {
                    this.selectedValue.selectedValueUnitEducation = [
                        {
                            value: this.authUser.ma_don_vi
                        }
                    ]
                }
                this.getDataESelect.ESelectGradeLevel = filterCapHocList(
                    this.authUser.capHocList
                )
            }
        }
    },
    mounted() {
        this.getDataChonTruongHoc()
    }
}
</script>
<style scoped>
.content label {
    color: black;
    font-weight: 700;
}
.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
.list-group {
    min-height: 20px;
    padding: 5px;
    border: 1px solid gainsboro;
}

.list-group-item {
    cursor: move;
    display: inline-flex !important;
    padding: 4px 10px;
}
.list-group-item span {
    font-size: 13px;
    font-weight: 500;
}
.list-group-item i {
    cursor: pointer;
}
::v-deep .el-collapse-item__header,
.name h4 {
    color: blue !important;
    font-style: italic;
    font-size: 13px;
    font-weight: 700;
    cursor: pointer;
}
.name {
    margin-top: 35px;
}
.name span {
    color: red;
}
.btn-search {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.layout-btn-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.icon {
    font-size: 14px;
}
.dialog .span {
    color: red;
}
.dialog label {
    color: #333;
    font-size: 14px;
    font-weight: 600;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.bg-search {
    background-color: var(--teal);
}
::v-deep .el-tag.el-tag--info {
    color: black;
}
</style>
