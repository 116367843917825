<template>
    <div class="main-wrapper" :class="{ active: activeThemeIcon === 'dark' }">
        <Header v-if="!navigation" />
        <div class="layout">
            <LeftBar v-if="!navigation" />
            <div class="home">
                <div class="page">
                    <router-view />
                </div>
                <back-to-top
                    :visibleoffset="visibleoffset"
                    :bottom="'20px'"
                    :right="'20px'"
                    :scrollFn="scrollFn"
                    :class="{
                        'vue-back-to-top--is-footer': isBackTopFooter
                    }"
                    @scrolled="scrolled"
                >
                    <CustomButton
                        icon="el-icon-top"
                        label=""
                        size="medium"
                        type="warning"
                    />
                </back-to-top>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Header from './components/Header.vue'
import LeftBar from './components/LeftBar.vue'
import BackToTop from 'vue-backtotop'
import CustomButton from './components/CustomButton.vue'
export default {
    name: 'app',
    components: {
        Header,
        LeftBar,
        BackToTop,
        CustomButton
    },
    data() {
        return {
            navigation: null,
            isBackTopFooter: false,
            visibleoffset: 600,

            inactivityTimer: null
        }
    },
    created() {
        this.checkRoute(), this.checkRoute(), (this.scrollIndentBackTop = 0)
        this.scrollHeight = 0
    },
    computed: {
        ...mapState(['activeThemeIcon'])
    },
    methods: {
        checkRoute() {
            if (
                this.$route.name === 'Login' ||
                this.$route.name === 'Sign-up' ||
                this.$route.name === 'Forgot-password'
            ) {
                this.navigation = true
                return
            } else {
                this.navigation = false
            }
        },
        scrollFn(eventObject) {
            let diff = this.scrollHeight - window.pageYOffset
            this.isBackTopFooter = diff < this.scrollIndentBackTop - 40 - 15
        },
        scrolled() {
            console.log('Scrolled !')
        },
        resetTimer() {
            const user = JSON.parse(localStorage.getItem('user'))
            const timeoutUser = user?.timeoutUser

            const timeoutInMs = (timeoutUser || 0) * 60 * 1000

            clearTimeout(this.inactivityTimer)

            this.inactivityTimer = setTimeout(() => {
                if (this.$route.path !== '/login') {
                    localStorage.clear()
                    this.$router.push({ path: '/login' })
                }
            }, timeoutInMs)
        }
    },

    mounted() {
        this.scrollHeight =
            Math.max(
                document.body.scrollHeight,
                document.documentElement.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.offsetHeight,
                document.body.clientHeight,
                document.documentElement.clientHeight
            ) - window.innerHeight
        const footerElement = document.querySelector('.footer')
        if (footerElement) {
            this.scrollIndentBackTop = footerElement.clientHeight / 2
        }

        window.addEventListener('mousemove', this.resetTimer)
        window.addEventListener('click', this.resetTimer)
        window.addEventListener('scroll', this.resetTimer)
        window.addEventListener('keydown', this.resetTimer)

        this.resetTimer()
    },
    beforeDestroy() {
        window.removeEventListener('mousemove', this.resetTimer)
        window.removeEventListener('click', this.resetTimer)
        window.removeEventListener('scroll', this.resetTimer)
        window.removeEventListener('keydown', this.resetTimer)

        clearTimeout(this.inactivityTimer)
    },
    watch: {
        $route() {
            this.checkRoute()
        },
        visibleoffset(newVal, oldVal) {
            document.body.style.height = parseInt(newVal) + 2000 + 'px'
        }
    }
}
</script>
<style>
.main-wrapper {
    font-family: 'Montserrat', sans-serif;
}
.layout {
    display: flex;
    /* height: 100vh; */
}

.home {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.page {
    flex: 1;
    padding: 5rem 2.15rem;
    background-color: #f3f5f7;
    overflow: auto;
}

@media (max-width: 768px) {
    .home .page {
        padding: 0;
    }
    .main-wrapper .home {
        flex: initial;
        width: 100%;
    }
}
</style>
