<template>
    <div class="school">
        <div class="header">
            <div class="left">
                <div>
                    <i class="el-icon-s-unfold icon"></i>
                </div>
                <div class="title">Phổ điểm</div>
            </div>
        </div>

        <div class="layout-card">
            <div class="row">
                <div class="col-md-6 mb-4">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">Phổ điểm học kì I</div>
                            <div>
                                <CustomButton
                                    type="text"
                                    icon="el-icon-refresh-right"
                                    label=""
                                    size="medium"
                                    @click="
                                        handleRefresh_DataBieuDoPhoDiemHKI_PhoDiem
                                    "
                                />
                            </div>
                        </div>
                        <div class="card-body">
                            <LineChart
                                :data_LineChart="dataBieuDoPhoDiemHKI_PhoDiem"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mb-4">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">Phổ điểm học kì II</div>
                            <div>
                                <CustomButton
                                    type="text"
                                    icon="el-icon-refresh-right"
                                    label=""
                                    size="medium"
                                    @click="
                                        handleRefresh_DataBieuDoPhoDiemHKII_PhoDiem
                                    "
                                />
                            </div>
                        </div>
                        <div class="card-body">
                            <LineChart
                                :data_LineChart="dataBieuDoPhoDiemHKII_PhoDiem"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 mb-6">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">
                                ĐÁNH GIÁ HỌC SINH HỌC KỲ I (THCS,THPT) TT58
                            </div>
                            <div>
                                <CustomButton
                                    type="text"
                                    icon="el-icon-refresh-right"
                                    label=""
                                    size="medium"
                                    @click="
                                        handleRefresh_DataBieuDoDanhGiaHocSinhKyI_PhoDiem
                                    "
                                />
                            </div>
                        </div>
                        <div class="card-body">
                            <StackedColumnChart
                                :data_StackedColumnChart="
                                    dataBieuDoDanhGiaHocSinhKyI_PhoDiem
                                "
                                :xaxis_categories="xaxisCategories.danhGiaHocSinh"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mb-6">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">
                                ĐÁNH GIÁ HỌC SINH HỌC KỲ II (THCS,THPT) TT58
                            </div>
                            <div>
                                <CustomButton
                                    type="text"
                                    icon="el-icon-refresh-right"
                                    label=""
                                    size="medium"
                                    @click="
                                        handleRefresh_DataBieuDoDanhGiaHocSinhKyII_PhoDiem
                                    "
                                />
                            </div>
                        </div>
                        <div class="card-body">
                            <StackedColumnChart
                                :data_StackedColumnChart="
                                    customColorStackedColumnChart(
                                        dataBieuDoDanhGiaHocSinhKyII_PhoDiem
                                    )
                                "
                                :xaxis_categories="xaxisCategories.danhGiaHocSinh"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6 mb-6">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">
                                ĐÁNH GIÁ HỌC SINH CẢ NĂM (THCS,THPT) TT58
                            </div>
                            <div>
                                <CustomButton
                                    type="text"
                                    icon="el-icon-refresh-right"
                                    label=""
                                    size="medium"
                                    @click="
                                        handleRefresh_DataBieuDoDanhGiaHocSinhKyCaNam_PhoDiem
                                    "
                                />
                            </div>
                        </div>
                        <div class="card-body">
                            <StackedColumnChart
                                :data_StackedColumnChart="
                                    customColorStackedColumnChart(
                                        dataBieuDoDanhGiaHocSinhCaNam_PhoDiem
                                    )
                                "
                                :xaxis_categories="xaxisCategories.danhGiaHocSinh"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LineChart from '@/components/LineChart.vue'
import StackedColumnChart from '@/components/StackedColumnChart.vue'

import 'element-ui/lib/theme-chalk/index.css'
import CustomButton from '@/components/CustomButton.vue'
import { customColorStackedColumnChart } from '@/utils'
export default {
    name: 'gradeScale',
    components: {
        CustomButton,
        StackedColumnChart,
        LineChart
    },
    data() {
        return {
            xaxisCategories: {
                danhGiaHocSinh: ['Giỏi', 'Khá', 'TB', 'Yếu', 'Kém', 'Khác'],
                gioiTinh: ['Nam', 'Nữ'],
                loaiHinhDaoTao: ['Công lập', 'Tư Thục', 'Dân lập'],
                khuVuc: ['B.Giới-H.Đảo', 'Đô thị', 'Đồng bằng', 'M.Núi-V.Sâu']
            }
        }
    },
    props: {
        dataBieuDoPhoDiemHKI_PhoDiem: {
            type: Array,
            required: true
        },
        refresh_dataBieuDoPhoDiemHKI_PhoDiem: Function,

        dataBieuDoPhoDiemHKII_PhoDiem: {
            type: Array,
            required: true
        },
        refresh_dataBieuDoPhoDiemHKII_PhoDiem: Function,

        dataBieuDoDanhGiaHocSinhKyI_PhoDiem: {
            type: Array,
            required: true
        },
        refresh_dataBieuDoDanhGiaHocSinhKyI_PhoDiem: Function,

        dataBieuDoDanhGiaHocSinhKyII_PhoDiem: {
            type: Array,
            required: true
        },
        refresh_dataBieuDoDanhGiaHocSinhKyII_PhoDiem: Function,

        dataBieuDoDanhGiaHocSinhCaNam_PhoDiem: {
            type: Array,
            required: true
        },
        refresh_dataBieuDoDanhGiaHocSinhKyCaNam_PhoDiem: Function
    },
    methods: {
        customColorStackedColumnChart,
        handleRefreshDataWithLoading(emitterFunction) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            emitterFunction()
            loading.close()
        },
        handleRefresh_DataBieuDoPhoDiemHKI_PhoDiem() {
            this.handleRefreshDataWithLoading(() => {
                this.$emit('refresh_dataBieuDoPhoDiemHKI_PhoDiem')
            })
        },
        handleRefresh_DataBieuDoPhoDiemHKII_PhoDiem() {
            this.handleRefreshDataWithLoading(() => {
                this.$emit('refresh_dataBieuDoPhoDiemHKII_PhoDiem')
            })
        },
        handleRefresh_DataBieuDoDanhGiaHocSinhKyI_PhoDiem() {
            this.handleRefreshDataWithLoading(() => {
                this.$emit('refresh_dataBieuDoDanhGiaHocSinhKyI_PhoDiem')
            })
        },
        handleRefresh_DataBieuDoDanhGiaHocSinhKyII_PhoDiem() {
            this.handleRefreshDataWithLoading(() => {
                this.$emit('refresh_dataBieuDoDanhGiaHocSinhKyII_PhoDiem')
            })
        },
        handleRefresh_DataBieuDoDanhGiaHocSinhKyCaNam_PhoDiem() {
            this.handleRefreshDataWithLoading(() => {
                this.$emit('refresh_dataBieuDoDanhGiaHocSinhKyCaNam_PhoDiem')
            })
        }
    }
}
</script>

<style scoped>
.school {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 15px;
    padding-left: 6px;
    padding-right: 6px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 10px 12px 10px;
}
.header .left {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #2e8bc0;
    font-weight: 700;
    text-transform: uppercase;
}
.header .left .icon {
    font-size: 18px;
    color: #000000;
    font-weight: 700;
    margin-right: 5px;
}
.header .right {
    margin-left: 6px;
}

.layout-card {
    padding: 0px 10px 20px 10px;
}
.layout-card .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f2f3f8;
    height: 50px;
    color: black;
    padding: 5px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.layout-card .card-header .title {
    margin: -5px 0px 0px 10px;
    color: #000000;
    font-weight: 700;
    font-size: 15px;
}
.layout-card .card-body {
    padding: 5px;
    background: white;
    border: #f2f3f8 2px solid;
    height: 300px;
}

.custom-btn {
    font-weight: 700;
    color: #fff;
    background-color: #6aab9c;
}
::v-deep .el-icon-s-unfold:before {
    font-size: 18px;
    color: #2e8bc0;
}
</style>
