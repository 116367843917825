<template>
    <el-dialog
        :visible="chinhSuaModalVisible"
        @close="closeModalChinhSua"
        width="60%"
    >
        <span slot="title" class="custom-dialog-title">
            CHỈNH SỬA LÝ DO BỎ HỌC
            <hr />
        </span>
        <div class="row form">
            <div class="col-md-12">
                <div class="row d-flex align-items-center justify-content-center">
                    <div class="col-md-3">
                        <label
                            >Mã lý do bỏ học (<span class="span">*</span>)</label
                        >
                    </div>
                    <div class="col-md-6">
                        <el-input
                            :disabled="true"
                            placeholder="Nhập mã đối tượng  ..."
                            v-model="selectedValue.input_maLyDo"
                            size="small"
                            style="width: 100%"
                            @blur="validateMaLyDo"
                            @focus="validateMaLyDo"
                        >
                        </el-input>
                        <div class="error-message" v-if="errors.maLyDo">
                            {{ errors.maLyDo }}
                        </div>
                    </div>
                </div>
                <div
                    class="row d-flex align-items-center justify-content-center mt-4"
                >
                    <div class="col-md-3">
                        <label
                            >Tên lý do bỏ học (<span class="span">*</span>)</label
                        >
                    </div>
                    <div class="col-md-6">
                        <el-input
                            placeholder="Nhập tên đối tượng ..."
                            v-model="selectedValue.input_tenLyDo"
                            size="small"
                            style="width: 100%"
                            @blur="validateTenLyDo"
                            @focus="validateTenLyDo"
                        >
                        </el-input>
                        <div class="error-message" v-if="errors.tenLyDo">
                            {{ errors.tenLyDo }}
                        </div>
                    </div>
                </div>

                <div
                    class="row d-flex align-items-center justify-content-center mt-4"
                >
                    <div class="col-md-3">
                        <label>Trạng thái </label>
                    </div>
                    <div class="col-md-6">
                        <el-select
                            v-model="selectedValue.selectedValue_trangThai"
                            collapse-tags
                            clearable
                            filterable
                            placeholder="Chọn"
                            size="small"
                            style="width: 100%"
                        >
                            <el-option
                                v-for="item in ESelect_trangThai"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            />
                        </el-select>
                    </div>
                </div>
            </div>
        </div>
        <div class="layout-btn">
            <div>
                <CustomButton
                    label="Đóng"
                    size="small"
                    type="info"
                    @click="closeModalChinhSua"
                />
            </div>
            <div style="margin-left: 10px">
                <CustomButton
                    label="Chỉnh sửa"
                    size="small"
                    type="success"
                    @click="handleChinhSua"
                />
            </div>
        </div>
    </el-dialog>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import { mapState } from 'vuex'
import sendRequest from '@/services'
import { validateInput } from '@/utils'

import Api from '@/constants/Api'
export default {
    name: 'ModalThemMoiTruongHoc',
    props: {
        chinhSuaModalVisible: {
            type: Boolean,
            required: true
        },
        closeModalChinhSua: {
            type: Function,
            required: true
        },
        dataModalChinhSua: {
            type: Object,
            required: true
        },
        handleSearch: Function
    },

    components: { CustomButton },
    data() {
        return {
            errors: {
                maLyDo: null,
                tenLyDo: null
            },
            submitted: false, // Thêm biến submitted

            ESelect_trangThai: [
                {
                    title: 'Đang hoạt động',
                    value: 1
                },
                {
                    title: 'Ngưng hoạt động',
                    value: 0
                }
            ],
            selectedValue: {
                input_maLyDo: '',
                input_tenLyDo: '',

                selectedValue_trangThai: ''
            }
        }
    },

    methods: {
        validateMaLyDo() {
            if (this.submitted) {
                this.errors.maLyDo = validateInput(
                    'text',
                    this.selectedValue.input_maLyDo
                )
            }
        },
        validateTenLyDo() {
            if (this.submitted) {
                this.errors.tenLyDo = validateInput(
                    'text',
                    this.selectedValue.input_tenLyDo
                )
            }
        },

        handleChinhSua() {
            this.submitted = true
            this.validateMaLyDo()
            this.validateTenLyDo()

            // Kiểm tra nếu không có lỗi thì tiếp tục thực hiện hành động xác thực
            if (!this.errors.maLyDo && !this.errors.tenLyDo) {
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })

                this.$confirm('Xác nhận chỉnh sửa ?', 'Thông báo', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                })
                    .then(async () => {
                        const request_Header = {
                            token: this.authToken
                        }
                        const request_Data = {
                            ma: this.selectedValue.input_maLyDo,
                            ten: this.selectedValue.input_tenLyDo,
                            trangThai: this.selectedValue.selectedValue_trangThai
                        }
                        const response = await sendRequest(
                            Api.superAdmin.lyDoBoHoc.chinhSuaLyDoBoHoc,
                            request_Data,

                            request_Header
                        )
                        if (response.code == 200) {
                            this.$message({
                                type: 'success',
                                message: 'Chỉnh sửa thành công'
                            })
                            this.closeModalChinhSua()
                            this.$emit('handleSearch')
                        } else {
                            loading.close()
                            this.$message({
                                type: 'error',
                                message: response.message
                            })
                        }
                    })
                    .catch(() => {
                        loading.close()
                    })
            }
        }
    },
    watch: {
        dataModalChinhSua(newValue) {
            this.selectedValue.input_maLyDo = newValue.maLyDoBoHoc
            this.selectedValue.input_tenLyDo = newValue.tenLyDoBoHoc

            this.selectedValue.selectedValue_trangThai = newValue.trangThai
        },
        'selectedValue.input_maLyDo': function (newValue) {
            this.validateMaLyDo()
        },
        'selectedValue.input_tenLyDo': function (newValue) {
            this.validateTenLyDo()
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped>
hr {
    border: 1px solid green;
}
.form label {
    font-weight: bold;
}
.form .span {
    color: red;
}
.form .error-message {
    font-style: italic;
    color: red;
    margin-top: 5px;
}
.custom-date-picker {
    height: 32px;
}
.custom-date-picker::placeholder {
    color: gainsboro;

    font-size: 13px;
    background-color: transparent;
    appearance: none;
}
.layout-btn {
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
}
</style>
