export const ESelectUnitEducation_MockData = [
    {
        maDonVi: '227',
        tenDonVi: 'Phòng Giáo dục và Đào tạo Thành phố Việt Trì'
    },
    {
        maDonVi: '228',
        tenDonVi: 'Phòng Giáo dục và Đào tạo Thị xã Phú Thọ'
    },
    {
        maDonVi: '230',
        tenDonVi: 'Phòng Giáo dục và Đào tạo Huyện Đoan Hùng'
    },
    {
        maDonVi: '231',
        tenDonVi: 'Phòng Giáo dục và Đào tạo Huyện Hạ Hoà'
    },
    {
        maDonVi: '232',
        tenDonVi: 'Phòng Giáo dục và Đào tạo Huyện Thanh Ba'
    }
]

export const ESelectGradeLevel_MockData = [
    { tenTruongHoc: 'Cấp 1', value: '1' },
    { tenTruongHoc: 'Cấp 2', value: '2' },
    { tenTruongHoc: 'Cấp 3', value: '3' },
    { tenTruongHoc: 'Nhà trẻ', value: '4' },
    { tenTruongHoc: 'Mẫu giáo', value: '5' },
    { tenTruongHoc: 'Mầm non', value: '45' },
    { tenTruongHoc: 'GDTX', value: '6' }
] // chọn cấp học

export const ESelectGradeLevel_MockData_TraCuuTruongHoc = [
    { title: 'Cấp 1', value: '1' },
    { title: 'Cấp 2', value: '2' },
    { title: 'Cấp 3', value: '3' },
    { title: 'Nhà trẻ', value: '4' },
    { title: 'Mẫu giáo', value: '5' },
    { title: 'Mầm non', value: '45' },
    { title: 'GDTX', value: '6' }
] // chọn cấp học

export const ESelectSchool_MockData = [
    {
        maTruongHoc: '241',
        tenTruongHoc: 'Mầm non Họa Mi'
    },
    {
        maTruongHoc: '242',
        tenTruongHoc: 'Mầm non Hoa sữa'
    },
    {
        maTruongHoc: '243',
        tenTruongHoc: 'Mầm non Kim ngọc'
    },
    {
        maTruongHoc: '244',
        tenTruongHoc: 'Mầm non Tuổi thơ'
    },
    {
        maTruongHoc: '245',
        tenTruongHoc: 'Mầm non Võ Miếu 1'
    }
]

export const ESelectDisplayContent_MockData = [
    {
        tenNoiDung: 'Tên đơn vị',
        value: '1'
    },
    {
        tenNoiDung: 'Tổng số cán bộ',
        value: '2'
    },
    {
        tenNoiDung: 'Tổng số học sinh',
        value: '3'
    },
    {
        tenNoiDung: 'TỔng số học sinh tốt nghiệp',
        value: '4'
    },
    {
        tenNoiDung: 'Tổng số kết quả cả năm',
        value: '5'
    },
    {
        tenNoiDung: 'Tổng số kết quả học kì I',
        value: '6'
    },
    {
        tenNoiDung: 'Tổng số kết quả học kì II',
        value: '7'
    },
    {
        tenNoiDung: 'Tổng số lớp đơn',
        value: '8'
    },
    {
        tenNoiDung: 'Tổng số lớp ghép',
        value: '9'
    },
    {
        tenNoiDung: 'Tổng số trường học',
        value: '10'
    }
]
export const ESelectDisplayContent_MockData_TraCuuhocsinh = [
    {
        key: 'maTruongHoc',
        title: 'Mã trường',
        value: 1
    },
    {
        key: 'tenTruongHoc',
        title: 'Tên trường',
        value: 2
    },
    {
        key: 'khoiHoc',
        title: 'Khối học/Nhóm lớp',
        value: 3
    },
    {
        key: 'tenLopHoc',
        title: 'Lớp học',
        value: 4
    },
    {
        key: 'maHocSinh',
        title: 'Mã học sinh',
        value: 5
    },

    {
        key: 'moetCode',
        title: 'Mã định danh',
        value: 6
    },
    {
        key: 'hoTen',
        title: 'Họ tên',
        value: 7
    },
    {
        key: 'ngaySinh',
        title: 'Ngày sinh',
        value: 8
    },
    {
        key: 'gioiTinh',
        title: 'Giới tính',
        value: 9
    },
    {
        key: 'maDanToc',
        title: 'Dân tộc',
        value: 10
    },
    {
        key: 'trangThai',
        title: 'Trạng thái',
        value: 11
    },
    {
        key: 'soCmnd',
        title: 'CMTND/TCC',
        value: 12
    },
    {
        key: 'ngayCapCmnd',
        title: 'Ngày cấp CMTND/TCC',
        value: 13
    },
    {
        key: 'noiCapCmnd',
        title: 'Nơi cấp CMTND/TCC',
        value: 14
    },
    {
        key: 'sdt',
        title: 'Số điện thoại',
        value: 15
    },
    {
        key: 'email',
        title: 'Email',
        value: 16
    },
    {
        key: 'soDienThoaiMe',
        title: 'Số điện thoại mẹ',
        value: 17
    },
    {
        key: 'dchiThtru',
        title: 'Địa chỉ thường trú',
        value: 18
    },
    {
        key: 'dchiTmtru',
        title: 'Địa chỉ tạm trú',
        value: 19
    },
    {
        key: 'noiSinh',
        title: 'Nơi sinh',
        value: 20
    },
    {
        key: 'hoNgheo',
        title: 'Hộ nghèo',
        value: 21
    },
    {
        key: 'maDtuongCsach',
        title: 'Đối tượng chính sách',
        value: 22
    },
    {
        key: 'soDienThoaiBo',
        title: 'Số điện thoại bố',
        value: 23
    },
    {
        key: 'hoTenBo',
        title: 'Họ tên bố',
        value: 24
    },
    {
        key: 'hoTenMe',
        title: 'Họ tên mẹ',
        value: 25
    },
    {
        key: 'citizenId',
        title: 'Mã định danh công dân',
        value: 26
    },

    { key: 'isKhamSkDky', title: 'Khám sức khỏe định kỳ', value: 28 },

    { key: 'chieuCao', title: 'Chiều cao kì I', value: 33 },

    { key: 'chieuCaoKy2', title: 'Chiều cao kì II', value: 34 },

    { key: 'canNang', title: 'Cân nặng kì I', value: 35 },

    { key: 'canNangKy2', title: 'Cân nặng kì II', value: 36 },

    { key: 'benhMat', title: 'Bệnh về mắt', value: 37 },

    { key: 'isBietBoiKy1', title: 'Biết bơi kỳ I', value: 38 },

    { key: 'isBietBoiKy2', title: 'Biết bơi kỳ II', value: 39 }
]
export const ESelectAdvancedSearch_MockData = [
    {
        value: 'ngaySinh',
        name: 'Ngày sinh'
    },
    {
        value: 'noiSinh',
        name: 'Nơi sinh'
    },
    {
        value: 'maDanToc',
        name: 'Dân tộc'
    },
    {
        value: 'maTonGiao',
        name: 'Tôn giáo'
    },
    {
        value: 'dchiThtru',
        name: 'Địa chỉ thường trú'
    },
    {
        value: 'dchiTmtru',
        name: 'Địa chỉ tạm trú'
    },
    {
        value: 'maKhuVuc',
        name: 'Khu vực'
    },
    {
        value: 'loaiNhapHoc',
        name: 'Loại nhập học'
    },
    {
        value: 'maLyDoThoiHoc',
        name: 'Lý do thôi học'
    },
    {
        value: 'doiVien',
        name: 'Đội viên'
    },
    {
        value: 'doanVien',
        name: 'Đoàn viên'
    },
    {
        value: 'khuyetTat',
        name: 'Khuyết tật'
    },
    {
        value: 'lopGhep',
        name: 'Lớp ghép'
    },
    {
        value: 'lopChuyen',
        name: 'Lớp chuyên'
    },
    {
        value: 'danTocThieuSo',
        name: 'Dân tộc thiểu số'
    },
    {
        value: 'hoNgheo',
        name: 'Hộ nghèo'
    },
    {
        value: 'moetCode',
        name: 'Mã định danh'
    },
    {
        value: 'maDtuongCsach',
        name: 'Đối tượng chính sách'
    },
    {
        value: 'isKhamSkDky',
        name: 'Khám sức khoẻ định kỳ'
    },
    {
        value: 'benhMat',
        name: 'Bệnh về mắt'
    },
    {
        value: 'bietBoiKy1',
        name: 'Biết bơi kỳ I'
    },
    {
        value: 'bietBoiKy2',
        name: 'Biết bơi kỳ II'
    }
]

export const ESelectArea_MockData = [
    {
        value: '01',
        name: 'Biên giới - hải đảo'
    },
    {
        value: '02',
        name: 'Đô thị'
    },
    {
        value: '03',
        name: 'Đồng bằng'
    },
    {
        value: '04',
        name: 'Miền núi - vùng sâu'
    }
]
export const ESelectLearningType_MockData = [
    {
        value: 1,
        name: 'Tuyển mới'
    },
    {
        value: 3,
        name: 'Chuyển đến từ trường khác'
    }
]
export const ESelectreasonForLeavingSchool_MockData = [
    {
        value: '01',
        name: 'Có hoàn cảnh kinh tế gia đình khó khăn'
    },
    {
        value: '02',
        name: 'Học lực yếu kém'
    },
    {
        value: '03',
        name: 'Xa trường, đi lại khó khăn'
    },
    {
        value: '04',
        name: 'Ảnh hưởng thiên tai, dịch bệnh'
    },
    {
        value: '05',
        name: 'Do kỳ thị'
    },
    {
        value: '07',
        name: 'Bảo lưu kết quả'
    },
    {
        value: '08',
        name: 'Chuyển sang học nghề'
    },
    {
        value: '06',
        name: 'Nguyên nhân khác'
    }
]
export const ESlectYesOfNo_MockData = [
    {
        value: 1,
        name: 'Có'
    },
    {
        value: 0,
        name: 'Không'
    }
]
export const ESelectTrueOfFalse = [
    {
        value: true,
        name: 'Có'
    },
    {
        value: false,
        name: 'Không'
    }
]
export const ESelectEyeDisease_MockData = [
    {
        name: 'Bình thường',
        value: '00'
    },
    {
        name: 'Cận thị',
        value: '01'
    },
    {
        name: 'Viễn thị',
        value: '02'
    },
    {
        name: 'Loạn thị',
        value: '03'
    },
    {
        name: 'Đục thủy tinh thể',
        value: '04'
    },
    {
        name: 'Thoái hóa điểm',
        value: '05'
    },
    {
        name: 'Bệnh khô mắt',
        value: '06'
    },
    {
        name: 'Bệnh khác về mắt',
        value: '07'
    },
    {
        name: 'Bệnh lác',
        value: '08'
    }
]
export const ESelectSemester_MockData = [
    {
        tenHocKy: 'Học Kỳ 1',
        value: '1'
    },
    {
        tenHocKy: 'Học Kỳ 2',
        value: '2'
    },
    {
        tenHocKy: 'Cả năm',
        value: '3'
    }
] //học kì
export const ESelectSemester_MockData_BaoCaoDonVi = [
    {
        tenHocKy: 'Học Kỳ 1',
        value: 1
    },
    {
        tenHocKy: 'Học Kỳ 2',
        value: 2
    },
    {
        tenHocKy: 'Cả năm',
        value: 3
    }
]
export const ESelectSemester_MockData_TraCuuKQHTTT22 = [
    {
        tenHocKy: 'Học Kỳ 1',
        value: 1
    },
    {
        tenHocKy: 'Học Kỳ 2',
        value: 2
    },
    {
        tenHocKy: 'Cả năm',
        value: 3
    }
]

export const ESelectSemester_MockData_KetChuyenDuLieu = [
    {
        tenHocKy: 'Học Kỳ 1',
        value: 1
    },
    {
        tenHocKy: 'Học Kỳ 2',
        value: 2
    },
    {
        tenHocKy: 'Cả năm',
        value: 3
    }
] //học kì

export const ESelectSemester_MockData_TraCuuKQHT = [
    {
        name: 'I',
        value: '1'
    },
    {
        name: 'II',
        value: '2'
    },
    {
        name: 'Cả năm',
        value: '3'
    }
] //học kì
export const ESelectAgeGroup_MockData = [
    {
        value: '01',
        title: '3-12 tháng'
    },
    {
        value: '02',
        title: '13-24 tháng'
    },
    {
        value: '03',
        title: '25-36 tháng'
    },
    {
        value: '04',
        title: '3-4 tuổi'
    },
    {
        value: '05',
        title: '4-5 tuổi'
    },
    {
        value: '06',
        title: '5-6 tuổi'
    }
]

export const ESelectDataType_MockData = [
    {
        tenDulieu: 'Tất cả',
        value: -1
    },
    {
        tenDulieu: 'Cán bộ',
        value: 2
    },
    {
        tenDulieu: 'Lớp học',
        value: 3
    },
    {
        tenDulieu: 'Học sinh',
        value: 4
    },
    {
        tenDulieu: 'Kết quả học tập',
        value: 5
    },
    {
        tenDulieu: 'Phòng học',
        value: 14
    },
    {
        tenDulieu: 'Đề án ngoại ngữ',
        value: 16
    }
] // chọn loại dữ liệu

export const ESelectPartner_MockData = [
    {
        tenDoitac: 'VIETTEL'
    },
    {
        tenDoitac: 'SMAS'
    },
    {
        tenDoitac: 'IMPORT'
    },
    {
        tenDoitac: 'VNPT'
    },
    {
        tenDoitac: 'TSDS'
    }
] // đối tác

export const ESelectSyncState_MockData = [
    { tenTrangThai: 'Đang xử lý', value: 0 },
    {
        tenTrangThai: 'Đã nhận',
        value: 1
    },
    {
        tenTrangThai: 'Xảy ra lỗi',
        value: 2
    },
    {
        tenTrangThai: 'Đã đồng bộ',
        value: 3
    },
    {
        tenTrangThai: 'Lỗi đồng bộ',
        value: 4
    }
] //trạng thái đồng bộ

export const ESelectSynchronouStype_MockData = [
    {
        value: 1,
        title: 'Sửa học sinh theo lô'
    },
    {
        value: 2,
        title: 'Sửa giáo viên theo lô'
    },
    {
        value: 3,
        title: 'Đẩy thông tin đầu năm'
    },
    {
        value: 4,
        title: 'Đẩy thông tin học sinh biến động'
    },
    {
        value: 5,
        title: 'Đẩy thông tin điểm'
    },
    {
        value: 6,
        title: 'Đẩy thông tin cuối năm'
    },
    {
        value: 8,
        title: 'Đẩy dữ liệu đầu năm (*)'
    },
    {
        value: 9,
        title: 'Đẩy dữ liệu EQMS giữa năm'
    }
] // loại đồng bộ

export const ESelectBatchType_MockData = [
    {
        tenLoaiDot: 'Đẩy thông tin đầu năm',
        value: 1
    },
    {
        tenLoaiDot: 'Đẩy thông tin phát sinh',
        value: 2
    },
    {
        tenLoaiDot: 'Đẩy thông tin cuối năm',
        value: 3
    },
    {
        tenLoaiDot: 'Đồng bộ dữ liệu cũ',
        value: 4
    }
] // loại đợt

export const ESelectUnitType_MockData = [
    {
        tenLoaiDonVi: 'Phòng',
        maDonVi: '2'
    },
    {
        tenLoaiDonVi: 'Trường',
        maDonVi: '3'
    }
] // loại đơn vị phòng/trường

export const ESelectChooseDataType_MockData = [
    {
        tenDulieu: 'Trường học',
        value: '1'
    },
    {
        tenDulieu: 'Cán bộ',
        value: '2'
    },
    {
        tenDulieu: 'Học sinh',
        value: '3'
    },
    {
        tenDulieu: 'Lớp học',
        value: '4'
    },
    {
        tenDulieu: 'KQ học tập giữa kỳ',
        value: '5'
    },
    {
        tenDulieu: 'KQ học tập cuối kỳ',
        value: '6'
    },
    {
        tenDulieu: 'ĐG chuẩn nghề nghiệp',
        value: '7'
    }
]

export const ESelectStage_MockData = [
    {
        tenGiaiDoan: 'Giao đoạn cuối kỳ I - [CK1]',
        value: 'CK1'
    },

    {
        tenGiaiDoan: 'Giao đoạn cuối kỳ II - [CK2]',
        value: 'CK2'
    },
    {
        tenGiaiDoan: 'Giao đoạn cuối kỳ I - [GK1]',
        value: 'GK1'
    },
    {
        tenGiaiDoan: 'Giao đoạn cuối kỳ II - [GK2]',
        value: 'GK2'
    }
] // giai đoạn

export const ESelectBusinessPartner_MockData = [
    { tenDoitac: 'SMAS', value: '1' },
    { tenDoitac: 'SMAS thử nghiệm', value: '2' }
] // đối tác 2

export const ESelectDataKind_MockData = [
    {
        id: 1,
        name: 'Trường học'
    },
    {
        id: 2,
        name: 'Cán bộ'
    },
    {
        id: 3,
        name: 'Lớp học'
    },
    {
        id: 4,
        name: 'Học sinh'
    },
    {
        id: 5,
        name: 'KQ học tập'
    },

    {
        id: 7,
        name: 'HT c.trình MN'
    },
    {
        id: 8,
        name: 'Xét tốt nghiệp'
    },
    {
        id: 9,
        name: 'Nhập điểm thi lại'
    },

    {
        id: 14,
        name: 'ĐG chuẩn nghề nghiệp'
    }
] // Loại dữ liệu 2

export const ESelectStatus_MockData = [
    {
        id: '0',
        name: 'Chờ đẩy'
    },
    {
        id: '1',
        name: 'Đang đẩy'
    },
    {
        id: '2',
        name: 'Đẩy thành công'
    },
    {
        id: '3',
        name: 'Đẩy lỗi'
    },
    {
        id: '4',
        name: 'Cần đẩy lại'
    },
    {
        id: '5',
        name: 'Thiếu mật khẩu kết chuyển'
    },
    {
        id: '6',
        name: 'Tài khoản kết chuyển không chính xác'
    },
    {
        id: '7',
        name: 'Content không đúng'
    },
    {
        id: '9',
        name: 'Chờ tổng hợp'
    },
    {
        id: '10',
        name: 'Đang tổng hợp'
    },
    {
        id: '11',
        name: 'Tổng hợp xong'
    },
    {
        id: '12',
        name: 'Tổng hợp lỗi'
    },
    {
        id: '13',
        name: 'Hoàn thành'
    }
] // trạng thái

export const BaoCaoEMIS_MockData = [
    {
        category: 'Báo cáo đầu năm',
        children: [
            {
                maBaoCao: 'bieu-01-mn-dn',
                tenBaoCao: 'Báo cáo thống kê giáo dục mầm non đầu năm'
            },
            {
                maBaoCao: 'bieu-02-th-dn',
                tenBaoCao: 'Báo cáo thống kê giáo dục tiểu học đầu năm'
            },
            {
                maBaoCao: 'bieu-04a-thpt-dn',
                tenBaoCao: 'Báo cáo thống kê giáo dục trung học phổ thông đầu năm'
            },
            {
                maBaoCao: 'bieu-05-gdtx-dn',
                tenBaoCao:
                    'Báo cáo thống kê giáo dục giáo dục thường xuyên đầu năm'
            }
        ]
    },
    {
        category: 'Báo cáo cuối năm',
        children: [
            {
                maBaoCao: 'bieu-01-mn-cn',
                tenBaoCao: 'Báo cáo thống kê giáo dục mầm non cuối năm'
            },
            {
                maBaoCao: 'bieu-02-th-cn',
                tenBaoCao: 'Báo cáo thống kê giáo dục tiểu học cuối năm'
            },
            {
                maBaoCao: 'bieu-04a-thpt-cn',
                tenBaoCao:
                    'Báo cáo thống kê giáo dục trung học phổ thông cuối năm'
            },
            {
                maBaoCao: 'bieu-05-gdtx-cn',
                tenBaoCao:
                    'Báo cáo thống kê giáo dục giáo dục thường xuyên cuối năm'
            }
        ]
    }
]

export const ESelectReport_MockData = [
    {
        tenBaoCao: 'Báo cáo cộng dồn',
        value: '0'
    },
    {
        tenBaoCao: 'Báo cáo lặp dòng',
        value: '1'
    }
] // loại báo cáo

export const NopThongKeTheoBieuMau_MockData = [
    {
        tableHeaders: [],
        tableData: [
            {
                id: 11501,
                tenBaoCao: 'test',
                trangThai: 1,
                countSchoolDone: 0,
                countSchoolTotal: 1,
                truongHocNopList: [
                    {
                        id: 194521,
                        maTruongHoc: '25228510',
                        ngayNopDl: null,
                        tenTruongHoc: 'Trường THCS Hùng vương',
                        trangThai: 0
                    }
                ]
            },
            {
                id: 11502,
                tenBaoCao: 'Thử',
                trangThai: 1,
                countSchoolDone: 0,
                countSchoolTotal: 1,
                truongHocNopList: [
                    {
                        id: 194522,
                        maTruongHoc: '25228511',
                        ngayNopDl: null,
                        tenTruongHoc:
                            'Trường Phổ thông chất lượng cao Hùng Vương',
                        trangThai: 0
                    }
                ]
            },
            {
                id: 11503,
                tenBaoCao: 'xuân thủy',
                trangThai: 0,
                countSchoolDone: 0,
                countSchoolTotal: 1,
                truongHocNopList: [
                    {
                        id: 194523,
                        maTruongHoc: '25228513',
                        ngayNopDl: null,
                        tenTruongHoc: 'Trường THCS Xuân Thủy',
                        trangThai: 0
                    }
                ]
            }
        ]
    }
] // Nộp thống kê theo biểu mẫu

export const BaoCaoDonVi_MockData = [
    {
        category: 'THỐNG KÊ NHÀ TRẺ',
        children: [
            {
                maBaoCao: 'A.1.1',
                tenBaoCao: 'DANH SÁCH TRƯỜNG HỌC NT'
            },
            {
                maBaoCao: 'A.2.1',
                tenBaoCao: 'THỐNG KÊ SỐ NHÓM - LỚP NT'
            },
            {
                maBaoCao: 'A.3.1',
                tenBaoCao:
                    'THỐNG KÊ SỐ LƯỢNG CÁN BỘ QUẢN LÝ, GIÁO VIÊN, NHÂN VIÊN NT'
            },
            {
                maBaoCao: 'A.4.1',
                tenBaoCao: 'THỐNG KÊ CÁN BỘ THEO CHẾ ĐỘ LAO ĐỘNG NT'
            }
        ]
    },
    {
        category: 'THỐNG KÊ MẪU GIÁO',
        children: [
            {
                maBaoCao: 'A.1.2',
                tenBaoCao: 'DANH SÁCH TRƯỜNG HỌC MG'
            },
            {
                maBaoCao: 'A.2.2',
                tenBaoCao: 'THỐNG KÊ SỐ NHÓM - LỚP MG'
            },
            {
                maBaoCao: 'A.3.2',
                tenBaoCao:
                    'THỐNG KÊ SỐ LƯỢNG CÁN BỘ QUẢN LÝ, GIÁO VIÊN, NHÂN VIÊN MG'
            },
            {
                maBaoCao: 'A.4.2',
                tenBaoCao: 'THỐNG KÊ CÁN BỘ THEO CHẾ ĐỘ LAO ĐỘNG MG'
            }
        ]
    },
    {
        category: 'THỐNG KÊ TIỂU HỌC',
        children: [
            {
                maBaoCao: 'B.1.1',
                tenBaoCao: 'DANH SÁCH TRƯỜNG HỌC TH'
            },
            {
                maBaoCao: 'B.2.1',
                tenBaoCao: 'THỐNG KÊ SỐ NHÓM - LỚP TH'
            },
            {
                maBaoCao: 'B.3.1',
                tenBaoCao:
                    'THỐNG KÊ SỐ LƯỢNG CÁN BỘ QUẢN LÝ, GIÁO VIÊN, NHÂN VIÊN TH'
            },
            {
                maBaoCao: 'B.4.1',
                tenBaoCao: 'THỐNG KÊ CÁN BỘ THEO CHẾ ĐỘ LAO ĐỘNG TH'
            }
        ]
    },
    {
        category: 'THỐNG KÊ TRUNG HỌC CƠ SỞ',
        children: [
            {
                maBaoCao: 'D.1.1',
                tenBaoCao: 'DANH SÁCH TRƯỜNG HỌC THCS'
            },
            {
                maBaoCao: 'D.2.1',
                tenBaoCao: 'THỐNG KÊ SỐ NHÓM - LỚP THCS'
            },
            {
                maBaoCao: 'D.3.1',
                tenBaoCao:
                    'THỐNG KÊ SỐ LƯỢNG CÁN BỘ QUẢN LÝ, GIÁO VIÊN, NHÂN VIÊN THCS'
            },
            {
                maBaoCao: 'D.4.1',
                tenBaoCao: 'THỐNG KÊ CÁN BỘ THEO CHẾ ĐỘ LAO ĐỘNG THCS'
            }
        ]
    },
    {
        category: 'THỐNG KÊ TRUNG HỌC PHỔ THÔNG',
        children: [
            {
                maBaoCao: 'E.1.1',
                tenBaoCao: 'DANH SÁCH TRƯỜNG HỌC THPT'
            },
            {
                maBaoCao: 'E.2.1',
                tenBaoCao: 'THỐNG KÊ SỐ NHÓM - LỚP THPT'
            },
            {
                maBaoCao: 'E.3.1',
                tenBaoCao:
                    'THỐNG KÊ SỐ LƯỢNG CÁN BỘ QUẢN LÝ, GIÁO VIÊN, NHÂN VIÊN NT'
            },
            {
                maBaoCao: 'E.4.1',
                tenBaoCao: 'THỐNG KÊ CÁN BỘ THEO CHẾ ĐỘ LAO ĐỘNG NT'
            }
        ]
    },
    {
        category: 'THỐNG KÊ GDTX',
        children: [
            {
                maBaoCao: 'G.1.1',
                tenBaoCao: 'DANH SÁCH TRƯỜNG HỌC NT GDTX'
            },
            {
                maBaoCao: 'G.2.1',
                tenBaoCao: 'THỐNG KÊ SỐ NHÓM - LỚP NT'
            },
            {
                maBaoCao: 'G.3.1',
                tenBaoCao:
                    'THỐNG KÊ SỐ LƯỢNG CÁN BỘ QUẢN LÝ, GIÁO VIÊN, NHÂN VIÊN NT'
            },
            {
                maBaoCao: 'A.4.1',
                tenBaoCao: 'THỐNG KÊ CÁN BỘ THEO CHẾ ĐỘ LAO ĐỘNG NT'
            }
        ]
    }
] //Báo cáo đơn vị

export const BaoCaoEQMS_MockData = [
    {
        category: 'Báo cáo giữa năm',
        children: [
            {
                maBaoCao: 'eqms-giua-nam-ck1',
                tenBaoCao:
                    'Báo cáo thống kê kết quả học tập của trường tiểu học cuối kì 1'
            },
            {
                maBaoCao: 'eqms-giua-nam-ck2',
                tenBaoCao:
                    'Báo cáo thống kê kết quả học tập của trường tiểu học cuối kì 2'
            }
        ]
    }
]

export const ESelectStatus_MockData_CapTruong = [
    {
        tenTrangThai: 'Đang hoạt động',
        value: '1'
    },
    {
        tenTrangThai: 'Ngừng hoạt động',
        value: '0'
    }
] // dùng trong màn CapTruong_CauHinhLichDongBo

const permission = [
    {
        stt: 1,
        checked: true,
        icon: 'fa fas fa-chart-line',
        name: 'Tổng quan',
        hasChildren: false,
        path: '/dashboard'
    },
    {
        stt: 5,
        checked: true,
        icon: 'fas fa-sync-alt',
        name: 'Tiếp nhận dữ liệu QLNT',
        path: '/synchronous-management',

        hasChildren: true,
        children: [
            {
                checked: true,

                name: 'Cấu hình lịch đồng bộ',
                path: '/Configure-sync-calendar',

                hasChildren: true,
                children: [
                    {
                        checked: true,

                        name: 'Cấp sở',
                        path: '/Configure-sync-calendar?type=1'
                    },
                    {
                        checked: true,

                        name: 'Cấp trường',
                        path: '/Configure-sync-calendar?type=2'
                    }
                ]
            },
            {
                checked: true,
                name: 'BC tình hình nộp dữ liệu',
                path: '/report-sync-status',
                hasChildren: true,
                children: [
                    {
                        checked: true,
                        name: 'Cấp sở',
                        path: '/report-sync-status?type=1'
                    },
                    {
                        checked: true,
                        name: 'Cấp trường',
                        path: '/report-sync-status?type=2'
                    }
                ]
            },
            {
                checked: true,

                name: 'Lịch sử tiếp nhận dữ liệu',
                path: '/Data-transfer-schedule'
            }
        ]
    },
    {
        stt: 7,
        checked: true,

        name: 'Kết chuyển dữ liệu lên Bộ',
        path: '/System-management',

        hasChildren: true,
        children: [
            {
                checked: true,
                icon: 'fa fa-arrow-right',
                name: 'Mật khẩu kết chuyển',
                path: '/pass-data-transfer'
            },
            {
                checked: true,
                icon: 'fa fa-arrow-right',
                name: 'Cấu hình duyệt/đẩy dữ liệu lên Bộ',
                path: '/config-moet-transfer-permission'
            }
        ]
    },
    {
        stt: 8,
        checked: true,

        name: 'Báo cáo',
        path: '/report',
        hasChildren: true,
        children: [
            {
                checked: true,

                name: 'Báo cáo EMIS',
                path: '/Report-to-the-ministry'
            },
            {
                checked: true,

                name: 'Báo cáo đơn vị',
                path: '/Report-to-the-department'
            },
            {
                checked: true,

                name: 'Báo cáo động',
                path: '/report-emis'
            },
            {
                checked: true,

                name: 'Báo cáo EQMS',
                path: '/Report-eqms'
            },
            {
                checked: true,

                name: 'Nộp thống kê theo biểu mẫu',
                path: '/Submit_statistical_sample'
            }
        ]
    },
    {
        stt: 9,
        checked: true,
        icon: 'fas fa-search',
        name: 'Tra cứu',
        path: '/Specific-data',

        hasChildren: true,
        children: [
            {
                checked: true,
                icon: 'fa fa-arrow-right',
                name: 'Tra cứu học sinh',
                path: '/Look-up-students'
            },
            {
                checked: true,
                icon: 'fa fa-arrow-right',
                name: 'Tra cứu Cán bộ, Giáo viên',
                path: '/Look-up-officials'
            }
        ]
    },
    {
        stt: 11,
        checked: true,
        icon: 'fas fa-cog',
        name: 'Hệ thống',
        path: '/system',

        hasChildren: true,
        children: [
            {
                checked: true,
                icon: 'fa fa-arrow-right',
                name: 'Quản lý người dùng',
                path: '/user-management'
            },
            {
                checked: true,
                icon: 'fa fa-arrow-right',
                name: 'Quản lý vai trò',
                path: '/role_management',
                roleList: [2]
            },

            {
                children: [
                    {
                        checked: true,
                        icon: 'fa fa-arrow-right',
                        name: 'Sáp nhập trường học',
                        path: ' /sap-nhap-truong'
                    },
                    {
                        checked: 'true',
                        icon: 'fa fa-arrow-right',
                        name: 'Giải thể trường học',
                        path: ' /giai-the-truong'
                    }
                ],
                checked: 'true',
                icon: 'fa fa-arrow-right',
                name: 'Sáp nhập và giải thể trường học'
            },
            {
                checked: true,
                icon: 'fa fa-arrow-right',
                name: 'Import kiểm định chất lượng',
                path: '/import-kiem-dinh-chat-luong'
            },
            {
                checked: true,
                icon: 'fa fa-arrow-right',
                name: 'Tổng hợp tuyển sinh',
                path: '/tong-hop-tuyen-sinh',
                roleList: [2]
            }
        ]
    }
]

export const dataBaoCaoEQMS_1_MockData = {
    maBaoCao: 'eqms-giua-nam-ck1-khoi1',
    capHoc: 1,
    hocKy: 1,
    maGiaiDoan: 'CK1',
    idBaoCao: '60642fe95362d4ce5aa5e22e',
    type: 'baocaodong',
    roles: [1, 2, 3, 4, 5, 20, 40, 2022, 2028, 2029],
    capHocList: [1],
    tenBaoCao:
        'Báo cáo thống kê kết quả học tập khối 1 của trường tiểu học cuối kì 1 (TT27)'
}
export const dataBaoCaoEQMS_2_MockData = {
    maBaoCao: 'eqms-cuoi-nam-ck2-khoi1',
    capHoc: 1,
    hocKy: 2,
    maGiaiDoan: 'CK2',
    idBaoCao: '609e40d55362d41840d53a14',
    type: 'baocaodong',
    roles: [1, 2, 3, 4, 5, 20, 40, 2022, 2028, 2029],
    capHocList: [1],
    tenBaoCao:
        'Báo cáo thống kê kết quả học tập khối 1 của trường tiểu học cuối kì 2 (TT27)'
}

export const ESelectGradeLevel_MockData_BaoCaoEQMS = [
    {
        tenTruongHoc: 'Nhóm nhà trẻ độc lập',
        value: '04'
    },
    {
        tenTruongHoc: 'Lớp mẫu giáo độc lập',
        value: '05'
    },
    {
        tenTruongHoc: 'Nhóm nhà trẻ, mẫu giáo độc lập',
        value: '19'
    }
]
export const ESelectSchoolBlock_MockData = [
    { value: 1, title: 'Khối 1' },
    { value: 2, title: 'Khối 2' },
    { value: 3, title: 'Khối 3' },
    { value: 4, title: 'Khối 4' },
    { value: 5, title: 'Khối 5' },
    { value: 6, title: 'Khối 6' },
    { value: 7, title: 'Khối 7' },
    { value: 8, title: 'Khối 8' },
    { value: 9, title: 'Khối 9' },
    { value: 10, title: 'Khối 10' },
    { value: 11, title: 'Khối 11' },
    { value: 12, title: 'Khối 12' },
    { value: 13, title: 'Trẻ từ 3-12 tháng' },
    { value: 14, title: 'Trẻ từ 13-24 tháng' },
    { value: 15, title: 'Trẻ từ 25-36 tháng' },
    { value: 16, title: 'Trẻ từ 3-4 tuổi' },
    { value: 17, title: 'Trẻ từ 4-5 tuổi' },
    { value: 18, title: 'Trẻ từ 5-6 tuổi' }
]

export const ESelectSchoolBlock_LinhVucDanhGiaPhatTrien = [
    { value: '', title: 'Tất cả' },
    { value: '13', title: 'Trẻ từ 3-12 tháng' },
    { value: '14', title: 'Trẻ từ 13-24 tháng' },
    { value: '15', title: 'Trẻ từ 25-36 tháng' },
    { value: '16', title: 'Trẻ từ 3-4 tuổi' },
    { value: '17', title: 'Trẻ từ 4-5 tuổi' },
    { value: '18', title: 'Trẻ từ 5-6 tuổi' }
]

export const ESelectGender_MockData = [
    {
        title: 'Nam',
        value: 1
    },
    {
        title: 'Nữ',
        value: 0
    }
]
export const ESelectTypeOfCadre_MockData = [
    {
        title: 'Cán bộ quản lý',
        value: 1
    },
    {
        title: 'Giáo viên',
        value: 2
    },
    {
        title: 'Nhân viên',
        value: 3
    }
]

export const ESelectStatus_MockData_Tracuuhocsinh = [
    {
        title: 'Đang học',
        value: 1
    },
    {
        title: 'Chuyển khỏi trường',
        value: 3
    },
    {
        title: 'Bỏ học',
        value: 4
    }
]

// export const ESelectDisplayContent_MockData_TraCuuhocsinh = [
//     {
//         name: 'Mã trường',
//         value: 1
//     },
//     {
//         name: 'Trường học',
//         value: 2
//     },
//     {
//         name: 'Khối học/Nhóm lớp',
//         value: 3
//     },
//     {
//         name: 'Lớp học',
//         value: 4
//     },
//     {
//         name: 'Mã học sinh',
//         value: 5
//     },
//     {
//         name: 'Mã dịnh danh bộ GD&ĐT',
//         value: 6
//     },
//     {
//         name: 'Họ tên',
//         value: 7
//     },
//     {
//         name: 'Mã trường',
//         value: 8
//     },
//     {
//         name: 'Ngày sinh',
//         value: 9
//     },
//     {
//         name: 'Giới tính',
//         value: 10
//     },
//     {
//         name: 'Dân tộc',
//         value: 11
//     },
//     {
//         name: 'Trạng thái',
//         value: 12
//     },
//     {
//         name: '  CMTND/TCC',
//         value: 13
//     },
//     {
//         name: ' Ngày cấp CMTND/TCC',
//         value: 14
//     },
//     {
//         name: ' Nơi cấp CMTND/TCC',
//         value: 15
//     },
//     {
//         name: 'SĐT liên hệ',
//         value: 16
//     },
//     {
//         name: 'Email',
//         value: 17
//     },
//     {
//         name: 'Số điện thoại mẹ',
//         value: 18
//     },
//     {
//         name: 'Địa chỉ thường trú',
//         value: 19
//     },
//     {
//         name: 'Địa chỉ tạm trú',
//         value: 20
//     },
//     {
//         name: 'Nơi sinh',
//         value: 21
//     },
//     {
//         name: 'Hộ nghèo',
//         value: 22
//     },
//     {
//         name: 'Đối tượng chính sách',
//         value: 23
//     },
//     {
//         name: 'Số điện thoại bố',
//         value: 24
//     },
//     {
//         name: 'Họ tên bố',
//         value: 25
//     },
//     {
//         name: 'Họ tên mẹ',
//         value: 26
//     },
//     {
//         name: 'Mã định danh công dân',
//         value: 27
//     },
//     {
//         name: 'Khám sức khỏe định kỳ',
//         value: 28
//     },
//     {
//         name: 'Chiều cao kì 1',
//         value: 29
//     },
//     {
//         name: 'Chiều cao kì 2',
//         value: 30
//     },
//     {
//         name: 'Cân nặng kì 1',
//         value: 31
//     },
//     {
//         name: 'Cân nặng kì 2',
//         value: 32
//     },

//     {
//         name: '  Bệnh về mắt',
//         value: 33
//     },
//     {
//         name: ' Biết bơi kỳ 1',
//         value: 34
//     },
//     {
//         name: ' Biết bơi kỳ 2',
//         value: 35
//     }
// ]

export const ESelectSchoolBlock3_MockData = [
    {
        value: 1,
        title: 'Khối 1'
    },
    {
        value: 2,
        title: 'Khối 2'
    },
    {
        value: 3,
        title: 'Khối 3'
    },
    {
        value: 4,
        title: 'Khối 4'
    },
    {
        value: 5,
        title: 'Khối 5'
    },
    {
        value: 6,
        title: 'Khối 6'
    },
    {
        value: 7,
        title: 'Khối 7'
    },
    {
        value: 8,
        title: 'Khối 8'
    },
    {
        value: 9,
        title: 'Khối 9'
    },
    {
        value: 10,
        title: 'Khối 10'
    },
    {
        value: 11,
        title: 'Khối 11'
    },
    {
        value: 12,
        title: 'Khối 12'
    }
]

export const ESelectClass_MockData = [
    {
        value: 10,
        title: 'Lớp 10'
    },
    {
        value: 11,
        title: 'Lớp 11'
    },
    {
        value: 12,
        title: 'Lớp 12'
    }
]

export const ESelectGender_MockData_ModalChiTietHocSinh = [
    {
        title: 'Nam',
        value: 1
    },
    {
        title: 'Nữ',
        value: 0
    }
]
export const ESelectGender_BaoCaoDong = [
    {
        title: 'Nam - [1]',
        value: 1
    },
    {
        title: 'Nữ - [0]',
        value: 0
    }
]

export const ESelectDisplayContent_MockData_TraCuulophoc = [
    {
        key: 'maTruongHoc',
        title: 'Mã trường',
        value: 1
    },
    {
        key: 'tenTruongHoc',
        title: 'Tên trường',
        value: 2
    },
    {
        key: 'maLopHoc',
        title: 'Mã lớp',
        value: 3,
        $isDisabled: true
    },
    {
        key: 'moetCode',
        title: 'Mã định danh',
        value: 4
    },
    {
        key: 'tenLopHoc',
        title: 'Tên lớp học',
        value: 5
    },
    {
        key: 'khoiHoc',
        title: 'Khối học/Nhóm lớp',
        value: 6
    },
    {
        key: 'tenGiaoVienCn',
        title: 'Giáo viên chủ nhiệm',
        value: 7
    },
    {
        key: 'buoiHoc',
        title: 'Buổi học',
        value: 8
    },
    {
        key: 'maNn1',
        title: 'Học ngoại ngữ 1',
        value: 9
    },
    {
        key: 'maNn2',
        title: 'Học ngoại ngữ 2',
        value: 10
    },

    {
        key: 'phanBan',
        title: 'Phân ban',
        value: 12
    },
    {
        key: 'lopChuyen',
        title: 'Lớp chuyên',
        value: 13
    },
    {
        key: 'coHocSinhHocNghe',
        title: 'Học sinh học nghề',
        value: 14
    },
    {
        key: 'lopGhep',
        title: 'Lớp ghép',
        value: 15
    },
    {
        key: 'maLopGhep',
        title: 'Ghép vào lớp',
        value: 16
    },
    {
        key: 'lopBanTru',
        title: 'Lớp bán trú',
        value: 17
    },
    {
        key: 'soBuoiTrenTuan',
        title: 'Số buổi học trên tuần',
        value: 18
    }
]

export const ESelectAdvancedSearch_MockData_Tracuulophoc = [
    {
        value: 'lopChuyen',
        name: 'Lớp chuyên'
    },
    {
        value: 'lopBanTru',
        name: 'Lớp bán trú'
    },
    {
        value: 'coHocSinhHocNghe',
        name: 'Có học sinh học nghề'
    },
    {
        value: 'lopGhep',
        name: 'Lớp ghép'
    },
    {
        value: 'maLopGhep',
        name: 'Mã lớp ghép'
    },
    {
        value: 'lopVnen',
        name: 'Lớp VNEN'
    },
    {
        value: 'lopCoTreKhuyetTat',
        name: 'Lớp có trẻ khuyết tật'
    },
    {
        value: 'lopChuyenBiet',
        name: 'Lớp chuyên biệt'
    },
    {
        value: 'soBuoiTrenTuan',
        name: 'Số buổi học trên tuần'
    },
    {
        value: 'moetCode',
        name: 'Mã định danh'
    },
    {
        value: 'maNn1',
        name: 'Ngoại ngữ 1'
    },
    {
        value: 'maNn2',
        name: 'Ngoại ngữ 2'
    }
]
export const ESelectAdvancedSearch_MockData_TraCuuKQHT = [
    { value: 'khamSkDky', name: 'Khám sức khỏe định kỳ' },
    { value: 'hthanhCtrinhLhoc', name: 'HT c.trình lớp học' },
    { value: 'hoanThanhChuongTrinhMamMon', name: 'HT c.trình MN' },
    { value: 'hoanThanhChuongTrinhTieuHoc', name: 'HT c.trình TH' },
    { value: 'lamQuenThoc', name: 'Làm quen tin học' },
    { value: 'lenLop', name: 'Lên lớp' },
    { value: 'phoiIchayHhap', name: 'Có bệnh về ỉa chảy, hô hấp' },
    { value: 'suyDduongTheTcoi', name: 'Suy dinh dưỡng thể thấp còi' },
    { value: 'theoDoiBdoCcao', name: 'Theo dõi biểu đồ chiều cao' },
    { value: 'theoDoiBdoCnang', name: 'Theo dõi biểu đồ cân nặng' },
    { value: 'maHocLuc', name: 'Học lực' },
    { value: 'maHanhKiem', name: 'Hạnh kiểm' },
    { value: 'maDanhHieu', name: 'Danh hiệu' },
    { value: 'renLuyenLai', name: 'Rèn luyện lại' },
    { value: 'soNgayNghi', name: 'Số ngày nghỉ' },
    { value: 'thiLai', name: 'Thi lại' },
    { value: 'totNghiep', name: 'Tốt nghiệp' },
    { value: 'maLoaiTotNghiep', name: 'Loại tốt nghiệp' },
    { value: 'duXetTotNghiep', name: 'Dự xét tốt nghiệp' },
    { value: 'moetCode', name: 'Mã định danh bộ GD&ĐT' },
    { value: 'trangThai', name: 'Trạng thái' },
    { value: 'danhGiaKqGiaoDuc', name: 'Đánh giá kết quả giáo dục' },
    { value: 'tenLopHoc', name: 'Tên lớp học' },

    { value: 'isSuyDduongTheNheCan', name: 'Suy dinh dưỡng thể nhẹ cân' },

    { value: 'isSuyDduongTheCoiCoc', name: 'Suy dinh dưỡng thể còi cọc' },

    { value: 'beoPhi', name: 'Béo phì' }
]
export const ESelectComplete_MockData_TraCuuKQHT = [
    { value: true, name: 'Hoàn thành' },
    { value: false, name: 'Không hoàn thành' }
]

export const ESelectectESelectAcademicAbility_MockData = [
    {
        name: 'Giỏi',
        value: 'G'
    },
    {
        name: 'Khá',
        value: 'K'
    },
    {
        name: 'Trung bình',
        value: 'TB'
    },
    {
        name: 'Yếu',
        value: 'Y'
    },
    {
        name: 'Kém',
        value: 'KEM'
    }
]
export const ESelectTitle_MockData = [
    {
        name: 'Vượt trội',
        value: 'VUOTTROI'
    },
    {
        name: 'Xuất sắc',
        value: 'XUATSAC'
    },
    {
        name: 'Giỏi',
        value: 'GIOI'
    },
    {
        name: 'Tiêu biểu',
        value: 'TIEUBIEU'
    },
    {
        name: 'Tiên tiến',
        value: 'TT'
    }
]
export const ESelectStatus_MockData_TraCuuKQHT = [
    {
        name: 'Đang học',
        value: '1'
    },
    {
        name: 'Chuyển khỏi trường',
        value: '3'
    },
    {
        name: 'Bỏ học',
        value: '4'
    }
]
export const ESelectDisplayContent_MockData_TraCuuKQHT = [
    { key: 'maTruongHoc', title: 'Mã trường', value: 1 },
    { key: 'tenTruongHoc', title: 'Trường học', value: 2 },
    { key: 'capHoc', title: 'Cấp học', value: 3 },
    { key: 'khoiHoc', title: 'Khối học/Nhóm tuổi', value: 4 },
    { key: 'tenLopHoc', title: 'Tên lớp học', value: 5 },
    { key: 'tenHocSinh', title: 'Họ và tên', value: 6 },
    { key: 'maHocSinh', title: 'Mã học sinh', value: 7 },
    { key: 'moetCode', title: 'Mã định danh Bộ GD&ĐT', value: 8 },
    { key: 'trangThai', title: 'Trạng thái', value: 9 },
    { key: 'diemTongKet', title: 'Điểm tổng kết', value: 10 },
    { key: 'namHoc', title: 'Năm học', value: 11 },
    { key: 'hocKy', title: 'Học kỳ', value: 12 },
    { key: 'giaiDoan', title: 'Giai đoạn', value: 13 },
    { key: 'maHocLuc', title: 'Học lực', value: 14 },
    { key: 'maHanhKiem', title: 'Hạnh kiểm', value: 15 },
    { key: 'maDanhHieu', title: 'Danh hiệu', value: 16 },
    { key: 'hthanhCtrinhLhoc', title: 'HT c.trình lớp học', value: 17 },
    { key: 'hoanThanhChuongTrinhMamMon', title: 'HT c.trình MN', value: 18 },
    { key: 'hoanThanhChuongTrinhTieuHoc', title: 'HT c.trình TH', value: 19 },
    { key: 'kthuongCnam', title: 'Khen thưởng cuối năm', value: 20 },
    { key: 'kthuongDxuat', title: 'Khen thưởng đột xuất', value: 21 },
    { key: 'lenLop', title: 'Lên lớp', value: 22 },
    { key: 'renLuyenLai', title: 'Rèn luyện lại', value: 23 },
    { key: 'thiLai', title: 'Thi lại', value: 24 },
    { key: 'duXetTotNghiep', title: 'Dự xét tốt nghiệp', value: 25 },
    { key: 'totNghiep', title: 'Tốt nghiệp', value: 26 },
    { key: 'maLoaiTotNghiep', title: 'Loại tốt nghiệp', value: 27 },
    { key: 'soNgayNghi', title: 'Số ngày nghỉ', value: 28 },
    { key: 'kqHocTap', title: 'Kết quả học tập', value: 29 },
    { key: 'kqRenLuyen', title: 'Kết quả rèn luyện', value: 30 },
    {
        key: 'isKhamSkDky',
        title: 'Khám sức khỏe định kỳ',
        value: 41
    },
    { key: 'TvDgtx', title: 'Đánh giá Tiếng Việt', value: 37 },
    { key: 'LoaiChungChiNghe', title: 'Loại chứng chỉ nghề', value: 38 },
    { key: 'khenThuongCapHuyen', title: 'Khen thưởng cấp huyện', value: 39 },
    {
        key: 'isSuyDduongTheNheCan',
        title: 'Suy dinh dưỡng thể nhẹ cân',
        value: 42
    },
    {
        key: 'isSuyDduongTheCoiCoc',
        title: 'Suy dinh dưỡng thể còi cọc',
        value: 43
    },
    { key: 'beoPhi', title: 'Béo phì', value: 44 },
    { key: 'suyDduongTheTcoi', title: 'Suy dinh dưỡng thể thấp còi', value: 45 },
    { key: 'theoDoiBdoCcao', title: 'Theo dõi biểu đồ chiều cao', value: 46 },
    { key: 'theoDoiBdoCnang', title: 'Theo dõi biểu đồ cân nặng', value: 47 },
    { key: 'phoiIchayHhap', title: 'Có bệnh về ỉa chảy, hô hấp', value: 48 }
]
export const ESelectevaluateEducationalOutcomes_TraCuuKQHT = [
    { name: 'Hoàn thành xuất sắc', value: 'XS' },
    { name: 'Hoàn thành tốt', value: 'T' },
    { name: 'Hoàn thành ', value: 'HT' },
    { name: 'Chưa hoàn thành', value: 'CHT' }
]
export const ESelectConduct_MockData = [
    {
        name: 'Tốt',
        value: 'T'
    },
    {
        name: 'Khá',
        value: 'K'
    },
    {
        name: 'Trung bình',
        value: 'TB'
    },
    {
        name: 'Yếu',
        value: 'Y'
    }
]
export const ESelectAdvancedSearch_MockData_TraCuuCanBoGiaoVien = [
    { value: 'ngaySinh', name: 'Ngày sinh' },

    { value: 'maTonGiao', name: 'Tôn giáo' },

    { value: 'maDanToc', name: 'Dân tộc' },

    { value: 'chucVu', name: 'Chức vụ' },

    // { value: 'dchiThtru', name: 'Địa chỉ thường trú' },

    { value: 'maKhuVuc', name: 'Khu vực' },

    { value: 'loaiHopDong', name: 'Hình thức hợp đồng' },

    { value: 'dangVien', name: 'Đảng viên' },

    { value: 'doTuoi', name: 'Độ tuổi' },

    { value: 'bacLuong', name: 'Bậc lương' },

    { value: 'heSoLuong', name: 'Hệ số lương' },

    { value: 'moetCode', name: 'Mã định danh' },

    { value: 'maMonHoc', name: 'Môn dạy' },

    // { value: 'soDienThoaiDiDong', name: 'Số điện thoại ' },

    { value: 'trinhDoDaoTaoCnChinh', name: 'Trình độ đào tạo' },

    { value: 'dgKqChuanNgheNghiep', name: 'Đánh giá chuẩn nghề nghiệp' }
]
export const ESelectAge_MockData = [
    {
        value: '10',
        title: 'Dưới 20 tuổi'
    },
    {
        value: '20',
        title: 'Từ 20-29 tuổi'
    },
    {
        value: '30',
        title: 'Từ 30-39 tuổi'
    },
    {
        value: '40',
        title: 'Từ 40-49 tuổi'
    },
    {
        value: '50',
        title: 'Từ 50-54 tuổi'
    },
    {
        value: '55',
        title: 'Từ 55-59 tuổi'
    },
    {
        value: '60',
        title: 'Trên 60 tuôi'
    }
]
export const ESelectlessonsPerWeek_MockData = [
    {
        title: '5 buổi học/tuần - [5]',
        value: 5
    },
    {
        title: '6 buổi học/tuần - [6]',
        value: 6
    },
    {
        title: '7 buổi học/tuần - [7]',
        value: 7
    },
    {
        title: '8 buổi học/tuần - [8]',
        value: 8
    },
    {
        title: '9 buổi học/tuần - [9]',
        value: 9
    },
    {
        title: '10 buổi học/tuần - [10]',
        value: 10
    }
]
export const ESelectDivision_MockData = [
    {
        title: 'Ban cơ bản - [3]',
        value: 3
    },
    {
        title: 'Ban tự nhiên - [2]',
        value: 2
    },
    {
        title: 'Ban xã hội - [1]',
        value: 1
    }
]

export const ESelectClassType_MockData = [
    {
        title: 'Phổ cập giáo dục tiểu học - [01]',
        value: '01'
    },
    {
        title: 'Bổ túc THCS - [02]',
        value: '02'
    },
    {
        title: 'Bổ túc THPT - [03]',
        value: '03'
    }
]

export const ESelectSetOfTextbooks_MockData = [
    {
        title: 'Của Bộ (NXB Giáo dục Việt Nam) - [01]',
        value: '01'
    },
    {
        title: 'Của NXB trong nước - [02]',
        value: '02'
    },
    {
        title: 'Của NXB quốc tế - [03]',
        value: '03'
    }
]

export const ESelectForeignLanguageProgram_MockData = [
    {
        constantTitle: '7 năm - [02]',
        mappingCode: '02'
    },
    {
        constantTitle: '10 năm - [01]',
        mappingCode: '01'
    },
    {
        constantTitle: 'Khác - [03]',
        mappingCode: '03'
    }
]

export const ESelectNumberOfForeignLanguageLessons_MockData = [
    {
        constantTitle: 'Học 2 tiết/tuần - [01]',
        mappingCode: '01'
    },
    {
        constantTitle: 'Học trên 4 tiết/tuần - [04]',
        mappingCode: '04'
    },
    {
        constantTitle: 'Học 3 tiết/tuần - [02]',
        mappingCode: '02'
    },
    {
        constantTitle: 'Học 4 tiết/tuần - [03]',
        mappingCode: '03'
    }
]

export const ESelectLearningForm_MockData = [
    {
        constantTitle: 'Tự học có hướng dẫn - [03]',
        mappingCode: '03'
    },
    {
        constantTitle: 'Học tập trung định kỳ - [01]',
        mappingCode: '01'
    },
    {
        constantTitle: 'Vừa làm vừa học - [02]',
        mappingCode: '02'
    }
]
export const ESelectDisplayContent_MockData_TraCuuCanBoGiaoVien = [
    {
        key: 'tenTruongHoc',
        title: 'Trường học',
        value: 0
    },
    {
        key: 'maTruongHoc',
        title: 'Mã trường',
        value: 1
    },
    {
        key: 'maGiaoVien',
        title: 'Mã cán bộ',
        value: 2
    },
    {
        key: 'moetCode',
        title: 'Mã định danh bộ GD&ĐT',
        value: 3
    },
    {
        key: 'tenCanBo',
        title: 'Họ tên',
        value: 4
    },
    {
        key: 'ngaySinh',
        title: 'Ngày sinh',
        value: 5
    },
    {
        key: 'gioiTinh',
        title: 'Giới tính',
        value: 6
    },
    {
        key: 'trangThai',
        title: 'Trạng thái',
        value: 7
    },
    {
        key: 'maDanToc',
        title: 'Dân tộc',
        value: 8
    },
    {
        key: 'loaiCanBo',
        title: 'Loại cán bộ',
        value: 9
    },
    {
        key: 'chucVu',
        title: 'Nhóm chức vụ',
        value: 10
    },
    {
        key: 'loaiHopDong',
        title: 'Hình thức hợp đồng',
        value: 11
    },
    {
        key: 'maTrinhDoChuyenMonCaoNhat',
        title: 'T.Độ chuyên môn nghiệp vụ',
        value: 12
    },
    {
        key: 'maMonHoc',
        title: 'Môn dạy',
        value: 13
    },
    // {
    //     key: 'soDienThoaiDiDong',
    //     title: 'Số điện thoại',
    //     value: 14
    // },
    {
        key: 'queQuan',
        title: 'Quê quán',
        value: 16
    },
    {
        key: 'noiSinh',
        title: 'Nơi sinh',
        value: 17
    },
    {
        key: 'namSinh',
        title: 'Năm sinh',
        value: 18
    },
    {
        key: 'soCmnd',
        title: 'Số CMTND/TCC',
        value: 19
    },
    {
        key: 'hinhThucDaoTao',
        title: 'Hình thức đào tạo',
        value: 20
    },
    {
        key: 'doTuoi',
        title: 'Độ tuổi',
        value: 21
    },
    {
        key: 'trinhDoDaoTaoCnChinh',
        title: 'Trình độ đào tạo',
        value: 22
    },
    {
        key: 'dgKqChuanNgheNghiep',
        title: 'Đánh giá chuẩn nghề nghiệp',
        value: 23
    }
]

export const ESelectTypeOfCadre_TraCuuCanBoGV_MockData = [
    {
        constantTitle: 'Nhân viên - [3]',
        mappingCode: '3'
    },
    {
        constantTitle: 'Giáo viên dạy nghề - [6]',
        mappingCode: '6'
    },
    {
        constantTitle: 'Giáo viên - [2]',
        mappingCode: '2'
    },
    {
        constantTitle: 'Cán bộ quản lý - [1]',
        mappingCode: '1'
    }
]

export const ESelectwage_TraCuuCanBoGV_MockData = [
    {
        constantTitle: 'Bậc 1 - [1]',
        mappingCode: '1'
    },
    {
        constantTitle: 'Bậc 9 - [9]',
        mappingCode: '9'
    },
    {
        constantTitle: 'Bậc 11 - [11]',
        mappingCode: '11'
    },
    {
        constantTitle: 'Bậc 2 - [2]',
        mappingCode: '2'
    },
    {
        constantTitle: 'Bậc 4 - [4]',
        mappingCode: '4'
    },
    {
        constantTitle: 'Bậc 6 - [6]',
        mappingCode: '6'
    },
    {
        constantTitle: 'Bậc 7 - [7]',
        mappingCode: '7'
    },
    {
        constantTitle: 'Bậc 3 - [3]',
        mappingCode: '3'
    },
    {
        constantTitle: 'Bậc 12 - [12]',
        mappingCode: '12'
    },
    {
        constantTitle: 'Bậc 8 - [8]',
        mappingCode: '8'
    },
    {
        constantTitle: 'Bậc 5 - [5]',
        mappingCode: '5'
    },
    {
        constantTitle: 'Bậc 10 - [10]',
        mappingCode: '10'
    }
]

export const ESlectMainLevel_MockData = [
    {
        constantTitle: 'Trung cấp - [7]',
        mappingCode: '7'
    },
    {
        constantTitle: 'Đại học - [16]',
        mappingCode: '16'
    },
    {
        constantTitle: 'Tiến sĩ - [1]',
        mappingCode: '1'
    },
    {
        constantTitle: 'Cao đẳng - [13]',
        mappingCode: '13'
    },
    {
        constantTitle: 'Thạc sĩ - [3]',
        mappingCode: '3'
    }
]
export const ESelectGradeGroup_MockData = [
    { title: 'Mầm non', value: '01' },
    { title: 'Mẫu giáo', value: '02' },
    { title: 'Nhà trẻ', value: '03' },
    { title: 'Nhóm nhà trẻ độc lập', value: '04' },
    { title: 'Lớp mẫu giáo độc lập', value: '05' },
    { title: 'Tiểu học', value: '06' },
    { title: 'THCS', value: '07' },
    { title: 'THPT', value: '08' },
    { title: 'PTCS', value: '09' },
    { title: 'Trung học', value: '10' },
    { title: 'Phổ thông', value: '11' },
    { title: 'GDTX Tỉnh', value: '12' },
    { title: 'GDTX Huyện', value: '13' },
    { title: 'TTKTTHHN', value: '14' },
    { title: 'Lớp nhô', value: '15' },
    { title: 'MN-TH', value: '16' },
    { title: 'MN-PT', value: '17' },
    { title: 'MN-PTCS', value: '18' },
    { title: 'Nhóm trẻ, lớp mẫu giáo độc lập', value: '19' }
]
export const ESelectTypeOfEducation_MockData = [
    {
        title: 'Công lập - [1]',
        value: 1
    },
    {
        title: 'Tư thục - [2]',
        value: 2
    },
    {
        title: 'GDTX - [3]',
        value: 3
    },
    {
        title: 'Ngoài công lập - [4]',
        value: 4
    },
    {
        title: 'Dân lập - [5]',
        value: 5
    }
]
export const ESelectStatus_MockData_TraCuuTruongHoc = [
    {
        title: 'Chọn',
        value: ''
    },
    {
        title: 'Đang hoạt động',
        value: '1'
    },
    {
        title: 'Ngừng hoạt động',
        value: '0'
    },
    {
        title: 'Đã xóa',
        value: '-1'
    }
]
export const ESelectNameTable_LoaiDoiTuong = [
    {
        title: 'Tất cả',
        value: ''
    },

    {
        title: 'Giáo viên',
        value: '1'
    },
    {
        title: 'Lớp học',
        value: '2'
    },

    {
        title: 'Trường học',
        value: '3'
    },
    {
        title: 'Học sinh',
        value: '4'
    }
]
export const ESelectStatus_SuperAdmin = [
    {
        title: 'Tất cả',
        value: ''
    },

    {
        title: 'Đang hoạt động',
        value: '1'
    },
    {
        title: 'Ngưng hoạt động',
        value: '0'
    }
]
export const ESelectEvaluate_MonHoc = [
    {
        title: 'Chọn hình thức đánh giá',
        value: ''
    },

    {
        title: 'Đánh giá',
        value: '1'
    },
    {
        title: 'Chấm điểm',
        value: '2'
    },
    {
        title: 'Kết hợp đánh giá và Chấm điểm',
        value: '3'
    }
]
export const ESelectSchoolLevel_MonHoc = [
    {
        title: 'Tất cả',
        value: ''
    },
    {
        title: 'Cấp 1',
        value: '1'
    },

    {
        title: 'Cấp 2',
        value: '2'
    },
    {
        title: 'Cấp 3',
        value: '3'
    }
]
export const ESelectStatus_QuanLyNguoiDung = [
    {
        title: 'Đang hoạt động',
        value: 1
    },
    {
        title: 'Ngưng hoạt động',
        value: 0
    }
]
export const ESelectRole = [
    {
        title: 'Sở',
        value: 2
    },
    {
        title: 'Phòng',
        value: 4
    }
]

export const permission_ioc = [
    {
        _id: {
            timestamp: 1581479979,
            machineIdentifier: 1518083,
            processIdentifier: 5140,
            counter: 1415616,
            date: '2020-02-12T03:59:39.000+0000',
            time: 1581479979000,
            timeSecond: 1581479979
        },
        stt: 1,
        checked: true,
        icon: 'fa fas fa-chart-line',
        name: 'Tổng quan',
        hasChildren: false,
        path: '/dashboard'
    },
    {
        _id: {
            timestamp: 1581480506,
            machineIdentifier: 1518083,
            processIdentifier: 5140,
            counter: 1415623,
            date: '2020-02-12T04:08:26.000+0000',
            time: 1581480506000,
            timeSecond: 1581480506
        },
        stt: 5,
        checked: true,
        icon: 'fas fa-sync-alt',
        name: 'Tiếp nhận dữ liệu QLNT',
        path: '/synchronous-management',
        roleList: [
            2, 3, 4, 5, 20, 40, 2018, 2021, 2013, 2028, 2029, 2030, 2033, 2034,
            2037, 2041, 2043, 2045, 2049, 2048
        ],
        hasChildren: true,
        children: [
            {
                path: '/Configure-sync-calendar',
                children: [
                    {
                        path: '/Configure-sync-calendar?type=1',
                        icon: 'fa fa-arrow-right',
                        name: 'Cấp sở',
                        checked: true,
                        roleList: [1, 2, 2028, 2029, 2030, 2033, 2034]
                    },
                    {
                        path: '/Configure-sync-calendar?type=2',
                        icon: 'fa fa-arrow-right',
                        name: 'Cấp trường',
                        checked: true,
                        roleList: [1, 2, 3, 4, 2028, 2029, 2030, 2033, 2034]
                    }
                ],
                hasChildren: true,
                icon: 'fa fa-arrow-right',
                name: 'Cấu hình lịch đồng bộ',
                checked: true,
                whitelist: [
                    {
                        maSoList: ['25'],
                        maPhongList: ['238'],
                        roleList: [4, 40],
                        desc: 'Phòng Giáo dục và Đào tạo Huyện Thanh Sơn - Phú Thọ. Quyền giống sở'
                    },
                    {
                        maSoList: ['79'],
                        maPhongList: ['766'],
                        roleList: [4, 40],
                        desc: 'Phòng Giáo dục và Đào tạo Quận Tân Bình - TPHCM. Quyền giống sở'
                    }
                ],
                roleList: [
                    1, 2, 3, 4, 2028, 2029, 2030, 2033, 2034, 2037, 2041, 2043,
                    2045, 2049, 2048, 2021
                ]
            },
            {
                path: '/report-sync-status',
                children: [
                    {
                        path: '/report-sync-status?type=1',
                        maSoList: [],
                        icon: 'fa fa-arrow-right',
                        name: 'Cấp sở',
                        checked: true,
                        roleList: [
                            2, 2018, 20, 2021, 2028, 2029, 2030, 2033, 2034
                        ]
                    },
                    {
                        path: '/report-sync-status?type=2',
                        maSoList: [],
                        icon: 'fa fa-arrow-right',
                        name: 'Cấp trường',
                        checked: true,
                        roleList: [
                            2, 2018, 20, 5, 4, 2021, 2028, 2029, 2030, 2033, 2034
                        ]
                    }
                ],
                hasChildren: true,
                icon: 'fa fa-arrow-right',
                name: 'BC tình hình nộp dữ liệu',
                checked: true,
                roleList: [
                    2, 2018, 20, 5, 4, 2021, 2028, 2029, 2030, 2033, 2034, 2037,
                    2038, 2041, 2043, 2045, 2049, 2048
                ]
            },
            {
                path: '/Data-transfer-schedule',
                icon: 'fa fa-arrow-right',
                name: 'Lịch sử tiếp nhận dữ liệu',
                checked: true,
                roleList: [
                    2, 3, 4, 5, 20, 40, 2018, 2021, 2013, 2028, 2029, 2030, 2033,
                    2034, 2037, 2038, 2041, 2042, 2043, 2045, 2049, 2048
                ]
            }
        ]
    },
    {
        _id: {
            timestamp: 1581480604,
            machineIdentifier: 1518083,
            processIdentifier: 5140,
            counter: 1415624,
            date: '2020-02-12T04:10:04.000+0000',
            time: 1581480604000,
            timeSecond: 1581480604
        },
        stt: 7,
        checked: true,
        icon: 'fas fa-cogs',
        name: 'Kết chuyển dữ liệu lên Bộ',
        path: '/System-management',
        roleList: [
            2, 3, 4, 5, 20, 40, 2018, 2021, 2028, 2029, 2030, 2033, 2037, 2041,
            2043, 2045, 2049
        ],
        hasChildren: true,
        children: [
            {
                path: '/compare-moet',
                maSoList: ['10'],
                icon: 'fa fa-arrow-right',
                name: 'So sánh dữ liệu MOET',
                checked: true,
                roleList: [
                    2, 2018, 2021, 2028, 2029, 2030, 2033, 2037, 2038, 2041, 2043,
                    2045, 2049
                ]
            },
            {
                path: '/pass-data-transfer',
                icon: 'fa fa-arrow-right',
                name: 'Mật khẩu kết chuyển',
                checked: true,
                whitelist: [
                    {
                        maSoList: ['25'],
                        maPhongList: ['238'],
                        roleList: [4, 40],
                        desc: 'Phòng Giáo dục và Đào tạo Huyện Thanh Sơn - Phú Thọ. Quyền giống sở'
                    },
                    {
                        maSoList: ['79'],
                        maPhongList: ['766'],
                        roleList: [4, 40],
                        desc: 'Phòng Giáo dục và Đào tạo Quận Tân Bình - TPHCM. Quyền giống sở'
                    }
                ],
                roleList: [
                    2, 2018, 2021, 2028, 2033, 2037, 2041, 2043, 2045, 2049
                ]
            },
            {
                path: '/config-moet-transfer-permission',
                maSoList: [
                    '10',
                    '19',
                    '25',
                    '30',
                    '45',
                    '08',
                    '26',
                    '52',
                    '64',
                    '86',
                    '62',
                    '91',
                    '20',
                    '38',
                    '56',
                    '92',
                    '33',
                    '34',
                    '54',
                    '36'
                ],
                icon: 'fa fa-arrow-right',
                name: 'Cấu hình duyệt/đẩy dữ liệu lên Bộ',
                checked: true,
                roleList: [2, 2033, 2037, 2041, 2029, 2043, 2045, 2049]
            },
            {
                path: '/Request-data-by-school',
                icon: 'fa fa-arrow-right',
                name: 'Kết chuyển dữ liệu',
                checked: true,
                whitelist: [
                    {
                        maSoList: ['25'],
                        maPhongList: ['238'],
                        roleList: [4, 40],
                        desc: 'Phòng Giáo dục và Đào tạo Huyện Thanh Sơn - Phú Thọ. Quyền giống sở'
                    },
                    {
                        maSoList: ['79'],
                        maPhongList: ['766'],
                        roleList: [4, 40],
                        desc: 'Phòng Giáo dục và Đào tạo Quận Tân Bình - TPHCM. Quyền giống sở'
                    }
                ],
                roleList: [
                    2, 3, 4, 5, 20, 40, 2018, 2021, 2028, 2030, 2033, 2037, 2038,
                    2041, 2029, 2043, 2045, 2049
                ]
            },
            {
                path: '/moet-transfer-history',
                icon: 'fa fa-arrow-right',
                name: 'Lịch sử đẩy dữ liệu lên Bộ',
                checked: true,
                roleList: [
                    2, 3, 4, 5, 20, 40, 2018, 2021, 2028, 2029, 2030, 2033, 2037,
                    2038, 2041, 2042, 2043, 2045, 2049
                ]
            }
        ]
    },
    {
        _id: {
            timestamp: 1582714601,
            machineIdentifier: 1090991,
            processIdentifier: 14164,
            counter: 13314145,
            date: '2020-02-26T10:56:41.000+0000',
            time: 1582714601000,
            timeSecond: 1582714601
        },
        stt: 8,
        checked: true,
        icon: 'fas fa-file-excel',
        name: 'Báo cáo',
        path: '/report',
        hasChildren: true,
        children: [
            {
                path: '/Report-to-the-ministry',
                icon: 'fa fa-arrow-right',
                name: 'Báo cáo EMIS',
                checked: true,
                roleList: [
                    2, 3, 4, 5, 20, 40, 2014, 2016, 2017, 2018, 2019, 2020, 2021,
                    2022, 2024, 2028, 2029, 2030, 2033, 2034, 2036, 2037, 2038,
                    2039, 2042, 2043, 2045, 2046, 2049
                ]
            },
            {
                path: '/Report-to-the-department',
                icon: 'fa fa-arrow-right',
                name: 'Báo cáo đơn vị',
                checked: true,
                roleList: [
                    1, 2, 4, 5, 6, 20, 40, 2012, 2014, 2017, 2018, 2019, 2020,
                    2021, 2022, 2024, 2028, 2029, 2030, 2033, 2036, 2037, 2038,
                    2039, 2042, 2043, 2045, 2046, 2049
                ]
            },
            {
                path: '/report-emis',
                icon: 'fa fa-arrow-right',
                name: 'Báo cáo động',
                checked: true,
                whitelist: [
                    {
                        maSoList: ['25'],
                        maPhongList: ['238'],
                        roleList: [4, 40],
                        desc: 'Phòng Giáo dục và Đào tạo Huyện Thanh Sơn - Phú Thọ. Quyền giống sở'
                    },
                    {
                        maSoList: ['79'],
                        maPhongList: ['766'],
                        roleList: [4, 40],
                        desc: 'Phòng Giáo dục và Đào tạo Quận Tân Bình - TPHCM. Quyền giống sở'
                    }
                ],
                roleList: [
                    2, 4, 5, 2018, 20, 2019, 2020, 2021, 2022, 2024, 2028, 2029,
                    2030, 2033, 2036, 2037, 2038, 2039, 2042, 2043, 2045, 2046,
                    2049
                ]
            },
            {
                path: '/Report-eqms',
                capHocList: [1],
                icon: 'fa fa-arrow-right',
                name: 'Báo cáo EQMS',
                checked: true,
                roleList: [
                    2, 3, 4, 5, 20, 40, 2017, 2018, 2019, 2020, 2021, 2022, 2024,
                    2028, 2029, 2030, 2033, 2036, 2037, 2038, 2039, 2042, 2043,
                    2045, 2046, 2049
                ]
            },
            {
                path: '/Submit_statistical_sample',
                icon: 'fa fa-arrow-right',
                name: 'Nộp thống kê theo biểu mẫu',
                checked: true,
                roleList: [
                    2, 3, 4, 5, 20, 40, 2017, 2018, 2019, 2020, 2021, 2022, 2024,
                    2028, 2029, 2030, 2033, 2035, 2036, 2037, 2038, 2039, 2042,
                    2043, 2045, 2046, 2049
                ]
            },
            {
                path: '/analytic-indicator-manager-2',
                maSoList: ['10', '19', '45'],
                icon: 'fa fa-arrow-right',
                name: 'Báo cáo biểu mẫu động',
                checked: true,
                roleList: [2, 4, 5, 20, 2046, 2049, 2021]
            }
        ],
        roleList: [
            2, 3, 4, 5, 20, 40, 2017, 2018, 2019, 2020, 2021, 2022, 2024, 2028,
            2029, 2030, 2033, 2035, 2037, 2036, 2038, 2039, 2042, 2043, 2045,
            2046, 2049
        ]
    },
    {
        _id: {
            timestamp: 1581482291,
            machineIdentifier: 1518083,
            processIdentifier: 5140,
            counter: 1415629,
            date: '2020-02-12T04:38:11.000+0000',
            time: 1581482291000,
            timeSecond: 1581482291
        },
        stt: 9,
        checked: true,
        icon: 'fas fa-search',
        name: 'Tra cứu',
        path: '/Specific-data',
        roleList: [
            2, 3, 4, 5, 20, 40, 2017, 2018, 2020, 2021, 2022, 2024, 2028, 2029,
            2030, 2033, 2036, 2037, 2038, 2039, 2041, 2042, 2043, 2045, 2049
        ],
        hasChildren: true,
        children: [
            {
                checked: true,
                icon: 'fa fa-arrow-right',
                name: 'Tra cứu trường học',
                path: '/category/schools',
                roleList: [
                    2, 2018, 2020, 2021, 2022, 2024, 2028, 2029, 2030, 2033, 2037,
                    4, 2039, 2041, 2042, 2043, 2045, 2049
                ]
            },
            {
                checked: true,
                icon: 'fa fa-arrow-right',
                name: 'Tra cứu lớp học',
                path: '/Look-up-classes',
                roleList: [
                    1, 2, 4, 5, 6, 20, 40, 2012, 2017, 2018, 2020, 2021, 2022,
                    2024, 2028, 2029, 2030, 2033, 2036, 2037, 2038, 2039, 2041,
                    2042, 2043, 2045, 2049
                ]
            },
            {
                checked: true,
                icon: 'fa fa-arrow-right',
                name: 'Tra cứu học sinh',
                path: '/Look-up-students',
                roleList: [
                    1, 2, 4, 5, 6, 20, 40, 2012, 2017, 2018, 2020, 2021, 2022,
                    2024, 2028, 2029, 2030, 2033, 2036, 2037, 2038, 2039, 2041,
                    2042, 2043, 2045, 2049
                ]
            },
            {
                checked: true,
                icon: 'fa fa-arrow-right',
                name: 'Tra cứu Cán bộ, Giáo viên',
                path: '/Look-up-officials',
                roleList: [
                    1, 2, 4, 5, 6, 20, 40, 2012, 2017, 2018, 2020, 2021, 2022,
                    2024, 2028, 2029, 2030, 2033, 2036, 2037, 2038, 2039, 2041,
                    2042, 2043, 2045, 2049
                ]
            },
            {
                checked: true,
                icon: 'fa fa-arrow-right',
                name: 'Tra cứu kết quả học tập',
                path: '/Look-up-academi-results',
                roleList: [
                    1, 2, 4, 5, 6, 20, 40, 2012, 2017, 2018, 2020, 2021, 2022,
                    2024, 2028, 2029, 2030, 2033, 2036, 2037, 2038, 2039, 2041,
                    2042, 2043, 2045, 2049
                ]
            },
            {
                checked: true,
                icon: 'fa fa-arrow-right',
                name: 'Thông tin y tế học sinh',
                path: '/Student-medical-information',
                roleList: [
                    1, 2, 4, 5, 6, 20, 40, 2012, 2017, 2018, 2020, 2021, 2022,
                    2024, 2028, 2029, 2030, 2033, 2036, 2037, 2038, 2039, 2041,
                    2042, 2043, 2045, 2049
                ]
            },
            {
                checked: true,
                icon: 'fa fa-arrow-right',
                name: 'Tra cứu kết quả học tập TT22',
                path: '/Look-up-academi-results-Circulars-22',
                maSoList: [],
                roleList: [
                    1, 2, 4, 5, 6, 20, 40, 2012, 2017, 2018, 2020, 2021, 2022,
                    2024, 2028, 2029, 2030, 2033, 2036, 2037, 2038, 2039, 2041,
                    2042, 2043, 2045, 2049
                ]
            }
        ]
    },
    {
        _id: {
            timestamp: 1581482471,
            machineIdentifier: 1518083,
            processIdentifier: 5140,
            counter: 1415630,
            date: '2020-02-12T04:41:11.000+0000',
            time: 1581482471000,
            timeSecond: 1581482471
        },
        stt: 11,
        checked: true,
        icon: 'fas fa-cog',
        name: 'Hệ thống',
        path: '/system',
        roleList: [
            1, 2, 4, 6, 20, 40, 2012, 100, 2028, 2033, 2037, 2029, 2041, 2043,
            2049
        ],
        hasChildren: true,
        children: [
            {
                path: '/user-management',
                icon: 'fa fa-arrow-right',
                name: 'Quản lý người dùng',
                checked: true,
                roleList: [
                    1, 2, 4, 6, 20, 40, 2012, 100, 2028, 2033, 2037, 2029, 2041,
                    2043
                ]
            },
            {
                path: '/role_management',
                icon: 'fa fa-arrow-right',
                name: 'Quản lý vai trò',
                checked: true,
                roleList: [2]
            },
            {
                path: '/update-school-partners',
                icon: 'fa fa-arrow-right',
                name: 'Quản lý hệ thống đối tác trường',
                checked: true,
                blacklist: [
                    {
                        maSoList: ['20'],
                        desc: 'SGD Lạng Sơn'
                    }
                ],
                roleList: [2, 2033, 2037, 2029, 2043, 2049]
            },
            {
                path: '/submit-data-sso',
                icon: 'fa fa-arrow-right',
                name: 'Nộp dữ liệu SSO',
                checked: true,
                roleList: [2, 6, 2033, 2037, 2041, 2043, 2049]
            },
            {
                path: '/data-synchronization',
                icon: 'fa fa-arrow-right',
                name: 'Tổng hợp dữ liệu báo cáo',
                checked: true,
                roleList: [2, 3, 4, 2041, 2043, 2049]
            }
        ]
    },
    {
        stt: 105,
        checked: true,
        icon: 'fa fas fa-chart-line',
        name: 'Báo cáo động (Mới)',
        hasChildren: true,
        path: '/analytic-template-edit',
        children: [
            {
                stt: 100,
                checked: true,
                icon: 'fa fas fa-chart-line',
                name: 'Chỉnh sửa template báo cáo',
                hasChildren: false,
                path: '/analytic-template-edit',
                hidden: true
            },
            {
                stt: 101,
                checked: true,
                icon: 'fa fas fa-chart-line',
                name: 'Quản lý DS Báo cáo',
                hasChildren: false,
                path: '/analytic-indicator-manager'
            },
            {
                stt: 102,
                checked: true,
                icon: 'fa fas fa-chart-line',
                name: 'Quản lý tiêu chí BC động',
                hasChildren: false,
                path: '/Report-management-criteria'
            },
            {
                stt: 103,
                checked: true,
                icon: 'fa fas fa-chart-line',
                name: 'Nhập liệu tiêu chí ngoài',
                hasChildren: false,
                path: '/dynamic-report-external-indicator'
            },
            {
                stt: 104,
                checked: true,
                icon: 'fa fas fa-chart-line',
                name: 'DS báo cáo đặc thù',
                hasChildren: false,
                path: '/specific-reports'
            }
        ]
    }
]

export const ESelectDataSynchronization_HKI = [
    {
        name: 'Trường học',
        key: '1'
    },
    {
        name: 'Cán bộ',
        key: '2'
    },
    {
        name: 'Học sinh',
        key: '4'
    },
    {
        name: 'Lớp học',
        key: '3'
    },

    {
        name: 'KQ học tập cuối kỳ',
        key: '52'
    },
    {
        name: 'ĐG chuẩn nghề nghiệp',
        key: '14'
    }
]

export const ESelectDataSynchronization_HKII = [
    {
        name: 'KQ học tập cuối kỳ',
        key: '52'
    },
    {
        name: 'HT c.trình MN',
        key: '7'
    }
]

export const ESelectDataSynchronization_CN = [
    {
        name: 'Nhập điểm thi lại',
        key: '9'
    },
    {
        name: 'KQ học tập cả năm',
        key: '52'
    },
    {
        name: 'Xét tốt nghiệp',
        key: '8'
    }
]
export const ESelectDataSynchronization_ALL = [
    {
        name: 'Trường học',
        key: '1'
    },
    {
        name: 'Cán bộ',
        key: '2'
    },
    {
        name: 'Học sinh',
        key: '4'
    },
    {
        name: 'Lớp học',
        key: '3'
    },

    {
        name: 'KQ học tập cuối kỳ',
        key: '52'
    },
    {
        name: 'ĐG chuẩn nghề nghiệp',
        key: '14'
    },

    {
        name: 'HT c.trình MN',
        key: '7'
    },
    {
        name: 'Nhập điểm thi lại',
        key: '9'
    },

    {
        name: 'Xét tốt nghiệp',
        key: '8'
    }
]
export const ESelectStaffGroup = [
    {
        title: 'Cán bộ quản lý - [1]',
        value: 1
    },
    {
        title: 'Giáo viên - [2]',
        value: 2
    },
    {
        title: 'Nhân viên - [3]',
        value: 3
    }
]

export const ESelectReportingCriteria_CanBo = [
    { name: 'Đơn vị quản lý', nameCol: 'maDonVi', index: 0, status: true },
    { name: 'Cấp dạy', nameCol: 'capHoc', index: 1, status: true },
    { name: 'Trường học', nameCol: 'maTruongHoc', index: 2, status: true },
    { name: 'Năm học', nameCol: 'namHoc', index: 3, status: true },
    { name: 'Trạng thái', nameCol: 'trangThai', index: 4, status: true },
    { name: 'Giới tính', nameCol: 'gioiTinh', index: 5, status: true },
    {
        name: 'Loại hình trường',
        nameCol: 'loaiHinhDaoTao',
        index: 6,
        status: true
    },
    { name: 'Loại hợp đồng', nameCol: 'loaiHopDong', index: 7, status: true },
    { name: 'Nhóm cán bộ', nameCol: 'loaiCanBo', index: 8, status: true },
    {
        name: 'Trình độ chuyên môn',
        nameCol: 'trinhDoChuyenMonCaoNhat',
        index: 9,
        status: true
    },
    { name: 'Khu vực', nameCol: 'maKhuVuc', index: 10, status: true },
    { name: 'Độ tuổi', nameCol: 'doTuoi', index: 11, status: true },
    {
        name: 'Đánh giá chuẩn nghề nghiệp',
        nameCol: 'dgKqChuanNgheNghiep',
        index: 12,
        status: true
    },
    {
        name: 'Dân tộc thiểu số',
        nameCol: 'danTocThieuSo',
        index: 13,
        status: true
    },
    {
        name: 'Giáo viên phụ trách đội',
        nameCol: 'chuyenTrachDoi',
        index: 14,
        status: true
    },
    {
        status: true,
        index: 28,
        name: 'Tham gia bồi dưỡng thường xuyên',
        nameCol: 'thamGiaBdTxuyen'
    },
    { name: 'Môn dạy', nameCol: 'maMonHoc', index: 15, status: true },
    { name: 'Dân tộc', nameCol: 'maDanToc', index: 16, status: true },

    {
        name: 'Trình độ giáo viên',
        nameCol: 'trinhDoGvien',
        index: 18,
        status: true
    },
    {
        name: 'Trình độ đào tạo',
        nameCol: 'trinhDoDaoTao',
        index: 19,
        status: true
    },
    {
        name: 'Hình thức đào tạo',
        nameCol: 'hinhThucDaoTao',
        index: 20,
        status: true
    },
    {
        name: 'Chuyên ngành đào tạo',
        nameCol: 'maChuyenNganhDaoTao',
        index: 21,
        status: true
    },
    {
        name: 'Trình độ văn hóa',
        nameCol: 'trinhDoVanHoa',
        index: 22,
        status: true
    },
    {
        name: 'Trình độ tin học',
        nameCol: 'trinhDoTinHoc',
        index: 23,
        status: true
    },
    {
        name: 'Trình độ ngoại ngữ',
        nameCol: 'trinhDoNgoaiNgu',
        index: 24,
        status: true
    },
    {
        name: 'Trình độ lý luận chính trị',
        nameCol: 'trinhDoLyLuanChinhTri',
        index: 25,
        status: true
    },
    {
        name: 'Trình độ quản lý nhà nước',
        nameCol: 'trinhDoQuanLyNhaNuoc',
        index: 26,
        status: true
    },
    {
        name: 'Trình độ quản lý giáo dục',
        nameCol: 'trinhDoQuanLyGiaoDuc',
        index: 27,
        status: true
    },

    { name: 'Mã ngạch', nameCol: 'maNgach', index: 29, status: true },
    {
        name: 'Dạy nhóm lớp',
        nameCol: 'dayNhomLop',
        index: 30,
        status: true
    },
    {
        name: 'Dạy nhóm lớp kiêm nhiệm',
        nameCol: 'dayNhomLopKiemNhiem',
        index: 31,
        status: true
    },
    {
        name: 'Môn dạy chính',
        nameCol: 'monDayChinh',
        index: 32,
        status: true
    },
    {
        name: 'Môn dạy kiêm nhiệm',
        nameCol: 'monDayKiemNhiem',
        index: 33,
        status: true
    },
    {
        name: 'Nhiệm vụ kiêm nhiệm',
        nameCol: 'nhiemVuKiemNhiem',
        index: 34,
        status: true
    },
    {
        name: 'Bậc lương',
        nameCol: 'bacLuong',
        index: 35,
        status: true
    },
    {
        name: 'Hệ số lương',
        nameCol: 'heSo',
        index: 36,
        status: true
    },
    {
        name: 'Vượt khung',
        nameCol: 'vuotKhung',
        index: 37,
        status: true
    },
    {
        name: 'Môn ngoại ngữ chính',
        nameCol: 'monNgoaiNguChinh',
        index: 38,
        status: true
    },
    {
        name: 'Chuyên ngành đào tạo chính',
        nameCol: 'chuyenNganhDaoTaoChinh',
        index: 39,
        status: true
    },
    {
        name: 'Trình độ đào tạo chuyên ngành chính',
        nameCol: 'trinhDoDaoTaoCnChinh',
        index: 40,
        status: true
    },
    {
        name: 'Đánh giá viên chức',
        nameCol: 'danhGiaVienChuc',
        index: 41,
        status: true
    },
    {
        name: 'Thành phần gia đình',
        nameCol: 'thanhPhanGiaDinh',
        index: 42,
        status: true
    },
    {
        name: 'Đối tác',
        nameCol: 'maDoiTac',
        index: 43,
        status: true
    }
]

export const ESelectReportingCriteria_CanBo_TieuChiBaoCao = [
    { name: 'Đơn vị quản lý', nameCol: 'maDonVi', index: 0, status: true },
    { name: 'Cấp dạy', nameCol: 'capHoc', index: 1, status: true },
    { name: 'Trường học', nameCol: 'maTruongHoc', index: 2, status: true },
    { name: 'Năm học', nameCol: 'namHoc', index: 3, status: true },
    { name: 'Trạng thái', nameCol: 'trangThai', index: 4, status: true },
    { name: 'Giới tính', nameCol: 'gioiTinh', index: 5, status: true },
    {
        name: 'Loại hình trường',
        nameCol: 'loaiHinhDaoTao',
        index: 6,
        status: true
    },
    { name: 'Loại hợp đồng', nameCol: 'loaiHopDong', index: 7, status: true },
    { name: 'Nhóm cán bộ', nameCol: 'loaiCanBo', index: 8, status: true },
    {
        name: 'Trình độ chuyên môn',
        nameCol: 'trinhDoChuyenMonCaoNhat',
        index: 9,
        status: true
    },
    { name: 'Khu vực', nameCol: 'maKhuVuc', index: 10, status: true },
    { name: 'Độ tuổi', nameCol: 'doTuoi', index: 11, status: true },
    {
        name: 'Đánh giá chuẩn nghề nghiệp',
        nameCol: 'dgKqChuanNgheNghiep',
        index: 12,
        status: true
    },
    {
        name: 'Dân tộc thiểu số',
        nameCol: 'danTocThieuSo',
        index: 13,
        status: true
    },
    {
        name: 'Giáo viên phụ trách đội',
        nameCol: 'chuyenTrachDoi',
        index: 14,
        status: true
    },
    { name: 'Môn dạy', nameCol: 'maMonHoc', index: 15, status: true },
    { name: 'Dân tộc', nameCol: 'maDanToc', index: 16, status: true },
    { name: 'Chức vụ', nameCol: 'chucVu', index: 17, status: true },
    {
        name: 'Trình độ giáo viên',
        nameCol: 'trinhDoGvien',
        index: 18,
        status: true
    },
    {
        name: 'Trình độ đào tạo',
        nameCol: 'trinhDoDaoTao',
        index: 19,
        status: true
    },
    {
        name: 'Hình thức đào tạo',
        nameCol: 'hinhThucDaoTao',
        index: 20,
        status: true
    },
    {
        name: 'Chuyên ngành đào tạo',
        nameCol: 'maChuyenNganhDaoTao',
        index: 21,
        status: true
    },
    {
        name: 'Trình độ văn hóa',
        nameCol: 'trinhDoVanHoa',
        index: 22,
        status: true
    },
    {
        name: 'Trình độ tin học',
        nameCol: 'trinhDoTinHoc',
        index: 23,
        status: true
    },
    {
        name: 'Trình độ ngoại ngữ',
        nameCol: 'trinhDoNgoaiNgu',
        index: 24,
        status: true
    },
    {
        name: 'Trình độ lý luận chính trị',
        nameCol: 'trinhDoLyLuanChinhTri',
        index: 25,
        status: true
    },
    {
        name: 'Trình độ quản lý nhà nước',
        nameCol: 'trinhDoQuanLyNhaNuoc',
        index: 26,
        status: true
    },
    {
        name: 'Trình độ quản lý giáo dục',
        nameCol: 'trinhDoQuanLyGiaoDuc',
        index: 27,
        status: true
    },
    {
        name: 'Tham gia bồi dưỡng thường xuyên',
        nameCol: 'thamGiaBdTxuyen',
        index: 28,
        status: true
    },
    { name: 'Mã ngạch', nameCol: 'maNgach', index: 29, status: true }
]
export const ESelectReportingCriteria_HocSinh_TieuChiBaoCao = [
    { name: 'Đơn vị quản lý', nameCol: 'maDonVi', index: 0, status: true },
    { name: 'Cấp dạy', nameCol: 'capHoc', index: 1, status: true },
    { name: 'Trường học', nameCol: 'maTruongHoc', index: 2, status: true },
    { name: 'Năm học', nameCol: 'namHoc', index: 3, status: true },
    { name: 'Nhóm máu', nameCol: 'nhomMau', index: 4, status: true },
    { name: 'Khuyết tật', nameCol: 'khuyetTat', index: 5, status: true },
    { name: 'Diện chính sách', nameCol: 'maDtuongCsach', index: 6, status: true },
    {
        name: 'Thành phần gia đình',
        nameCol: 'tphanGdinh',
        index: 7,
        status: true
    },
    { name: 'Diện ưu tiên', nameCol: 'dtuongUuTien', index: 8, status: true },
    { name: 'Đội viên', nameCol: 'doiVien', index: 9, status: true },
    { name: 'Đoàn viên', nameCol: 'doanVien', index: 10, status: true },
    { name: 'Đảng viên', nameCol: 'dangVien', index: 11, status: true },
    {
        name: 'Loại khuyết tật',
        nameCol: 'maLoaiKhuyetTat',
        index: 12,
        status: true
    },
    { name: 'Khu vực', nameCol: 'maKhuVuc', index: 13, status: true },
    { name: 'Loại nhập học', nameCol: 'loaiNhapHoc', index: 14, status: true },
    { name: 'Học kỹ năng skss', nameCol: 'hocKnsSkss', index: 15, status: true },
    { name: 'Khối học', nameCol: 'khoiHoc', index: 16, status: true },
    { name: 'Trạng thái', nameCol: 'trangThai', index: 17, status: true },
    { name: 'Hỗ trợ học tập', nameCol: 'htHoTro', index: 18, status: true },
    {
        name: 'Thời điểm rời trường',
        nameCol: 'thoiDiemRoiTruong',
        index: 19,
        status: true
    },
    {
        name: 'Kiểm tra sức khỏe dinh dưỡng',
        nameCol: 'kiemTraSucKhoeDinhDuong',
        index: 20,
        status: true
    },
    {
        name: 'Tình trạng dinh dưỡng',
        nameCol: 'tinhTrangDinhDuong',
        index: 21,
        status: true
    },
    { name: 'Cha dân tộc', nameCol: 'chaDanToc', index: 22, status: true },
    { name: 'Mẹ dân tộc', nameCol: 'meDanToc', index: 23, status: true },
    { name: 'Lý do thôi học', nameCol: 'maLyDoThoiHoc', index: 24, status: true },
    {
        name: 'Học tiếng dân tộc',
        nameCol: 'hocSinhTiengDanToc',
        index: 25,
        status: true
    },
    { name: 'Học song ngữ', nameCol: 'hocSongNgu', index: 26, status: true },
    {
        name: 'Học bán trú dân nuôi',
        nameCol: 'hocSinhBanTruDanNuoi',
        index: 27,
        status: true
    },
    {
        name: 'Học nội trú dân nuôi',
        nameCol: 'hocSinhNoiTruDanNuoi',
        index: 28,
        status: true
    },
    {
        name: 'Khuyết tật không đánh giá',
        nameCol: 'khuyetTatKhongDanhGia',
        index: 29,
        status: true
    }
]

export const ESelectReportingCriteria_HocSinh_TieuChi = [
    {
        name: 'Đơn vị quản lý',
        nameCol: 'maDonVi',
        index: 0
    },
    {
        name: 'Cấp dạy',
        nameCol: 'capHoc',
        index: 1
    },
    {
        name: 'Trường học',
        nameCol: 'maTruongHoc',
        index: 2
    },
    {
        name: 'Năm học',
        nameCol: 'namHoc',
        index: 3
    },
    {
        name: 'Nhóm máu',
        nameCol: 'nhomMau',
        index: 4
    },
    {
        name: 'Khuyết tật',
        nameCol: 'khuyetTat',
        index: 5
    },
    {
        name: 'Diện chính sách',
        nameCol: 'maDtuongCsach',
        index: 6
    },
    {
        name: 'Thành phần gia đình',
        nameCol: 'tphanGdinh',
        index: 7
    },
    {
        name: 'Diện ưu tiên',
        nameCol: 'dtuongUuTien',
        index: 8
    },
    {
        name: 'Đội viên',
        nameCol: 'doiVien',
        index: 9
    },
    {
        name: 'Đoàn viên',
        nameCol: 'doanVien',
        index: 10
    },
    {
        name: 'Đảng viên',
        nameCol: 'dangVien',
        index: 11
    },
    {
        name: 'Loại khuyết tật',
        nameCol: 'maLoaiKhuyetTat',
        index: 12
    },
    {
        name: 'Khu vực',
        nameCol: 'maKhuVuc',
        index: 13
    },
    {
        name: 'Loại nhập học',
        nameCol: 'loaiNhapHoc',
        index: 14
    },
    {
        name: 'Học kỹ năng skss',
        nameCol: 'hocKnsSkss',
        index: 15
    },
    {
        name: 'Khối học',
        nameCol: 'khoiHoc',
        index: 16
    },
    {
        name: 'Trạng thái',
        nameCol: 'trangThai',
        index: 17
    },
    {
        name: 'Hỗ trợ học tập',
        nameCol: 'htHoTro',
        index: 18
    },
    {
        name: 'Thời điểm rời trường',
        nameCol: 'thoiDiemRoiTruong',
        index: 19
    },
    {
        name: 'Kiểm tra sức khỏe dinh dưỡng',
        nameCol: 'kiemTraSucKhoeDinhDuong',
        index: 20
    },
    {
        name: 'Tình trạng dinh dưỡng',
        nameCol: 'tinhTrangDinhDuong',
        index: 21
    },

    {
        name: 'Cha dân tộc',
        nameCol: 'chaDanToc',
        index: 22
    },
    {
        name: 'Mẹ dân tộc',
        nameCol: 'meDanToc',
        index: 23
    },
    {
        name: 'Lý do thôi học',
        nameCol: 'maLyDoThoiHoc',
        index: 24
    },
    {
        name: 'Học tiếng dân tộc',
        nameCol: 'hocSinhTiengDanToc',
        index: 25
    },
    {
        name: 'Học song ngữ',
        nameCol: 'hocSongNgu',
        index: 26
    },
    {
        name: 'Học bán trú dân nuôi',
        nameCol: 'hocSinhBanTruDanNuoi',
        index: 27
    },
    {
        name: 'Học nội trú dân nuôi',
        nameCol: 'hocSinhNoiTruDanNuoi',
        index: 28
    },
    {
        name: 'Khuyết tật không đánh giá',
        nameCol: 'khuyetTatKhongDanhGia',
        index: 29
    },
    {
        name: 'Hộ nghèo',
        nameCol: 'hoNgheo',
        index: 30
    },
    {
        name: 'Hỗ trợ nhà ở',
        nameCol: 'htroNhaO',
        index: 31
    },
    {
        name: 'Được cấp tiền hàng tháng',
        nameCol: 'capTienHangThang',
        index: 32
    },
    {
        name: 'Được cấp gạo',
        nameCol: 'capGao',
        index: 33
    },
    {
        name: 'Học chương trình GD của Bộ',
        nameCol: 'hocCtgdCuaBo',
        index: 34
    },
    {
        name: 'Được hỗ trợ chi phí học tập',
        nameCol: 'htChiPhiHocTap',
        index: 35
    },
    {
        name: 'Học sinh bán trú',
        nameCol: 'hocSinhBanTru',
        index: 36
    },
    {
        name: 'Học sinh nội trú',
        nameCol: 'hocSinhNoiTru',
        index: 37
    },
    {
        name: 'Học sinh PTDT bán trú',
        nameCol: 'hocSinhPtdtBanTru',
        index: 38
    },
    {
        name: 'Chuyển sang học nghề',
        nameCol: 'chuyenSangHocNghe',
        index: 39
    },
    {
        name: 'Biết bơi kỳ 1',
        nameCol: 'bietBoiKy1',
        index: 40
    },
    {
        name: 'Biết bơi kỳ 2',
        nameCol: 'bietBoiKy2',
        index: 41
    },
    {
        name: 'Mẹ bị bệnh trong thời kỳ mang thai',
        nameCol: 'meBiBenhTatTrongTkMangThai',
        index: 42
    },
    {
        name: 'Cận thị',
        nameCol: 'canThi',
        index: 43
    },
    {
        name: 'Chỉ số BMI',
        nameCol: 'chiSoBMI',
        index: 44
    }
]
export const ESelectReportingCriteria_TruongHoc_TieuChiBaoCao = [
    { name: 'Đơn vị quản lý', nameCol: 'maDonVi', index: 0, status: true },
    { name: 'Cấp dạy', nameCol: 'capHoc', index: 1, status: true },
    { name: 'Trường học', nameCol: 'maTruongHoc', index: 2, status: true },
    { name: 'Năm học', nameCol: 'namHoc', index: 3, status: true },
    {
        name: 'Loại hình đào tạo',
        nameCol: 'loaiHinhDaoTao',
        index: 4,
        status: true
    },
    {
        name: 'Loại hình trường',
        nameCol: 'loaiHinhTruong',
        index: 5,
        status: true
    },
    { name: 'Chuẩn quốc gia', nameCol: 'chuanQuocGia', index: 6, status: true },
    { name: 'Vùng khó khăn', nameCol: 'maVungKhoKhan', index: 7, status: true },
    {
        name: 'Chương trình giáo dục cơ bản',
        nameCol: 'chuongTrinhGiaoDucCoBan',
        index: 8,
        status: true
    },
    {
        name: 'Môi trường phù hợp với học sinh khuyết tật',
        nameCol: 'coHaTangTlhtPhuHopHskt',
        index: 9,
        status: true
    },
    {
        name: 'Lớp không chuyên',
        nameCol: 'coLopKhongChuyen',
        index: 10,
        status: true
    },
    {
        name: 'Công tác tư vấn học đường',
        nameCol: 'congTacTuVanHocDuong',
        index: 11,
        status: true
    },
    {
        name: 'Công trình vệ sinh',
        nameCol: 'congTrinhVeSinh',
        index: 12,
        status: true
    },
    {
        name: 'Giáo dục vệ sinh đôi tay',
        nameCol: 'ctGdvsDoiTay',
        index: 13,
        status: true
    },
    {
        name: 'Đạt chất lượng tối thiểu',
        nameCol: 'datChatLuongToiThieu',
        index: 14,
        status: true
    },
    {
        name: 'Dạy nghề phổ thông',
        nameCol: 'dayNghePhoThong',
        index: 15,
        status: true
    },
    { name: 'Điện lưới', nameCol: 'dienLuoi', index: 16, status: true },
    { name: 'Học 2 buổi/ngày', nameCol: 'hoc2BuoiNgay', index: 17, status: true },
    {
        name: 'Học sinh bán trú',
        nameCol: 'hocSinhBanTru',
        index: 18,
        status: true
    },
    {
        name: 'Học sinh nội trú',
        nameCol: 'hocSinhNoiTru',
        index: 19,
        status: true
    },
    {
        name: 'Học sinh khuyết tật',
        nameCol: 'hocSinhKhuyetTat',
        index: 20,
        status: true
    },
    {
        name: 'Khai tác Internet dạy học',
        nameCol: 'khaiThacInternetDayHoc',
        index: 21,
        status: true
    },
    {
        name: 'Kỹ năng sống giáo dục xã hội',
        nameCol: 'kyNangSongGdxh',
        index: 22,
        status: true
    },
    {
        name: 'Nguồn nước sạch',
        nameCol: 'nguonNuocSach',
        index: 23,
        status: true
    },
    {
        name: 'Sử dụng máy tính dạy học',
        nameCol: 'suDungMayTinhDayHoc',
        index: 24,
        status: true
    },
    { name: 'Trường quốc tế', nameCol: 'truongQuocTe', index: 25, status: true },
    {
        name: 'Vùng đặc biệt khó khăn',
        nameCol: 'vungDacBietKhoKhan',
        index: 26,
        status: true
    },
    {
        name: 'Trung tâm hướng nghiệp',
        nameCol: 'ttGdtxHuongNghiep',
        index: 27,
        status: true
    },
    {
        name: 'Trung tâm giáo dục thường xuyên',
        nameCol: 'ttGdtxHuyen',
        index: 28,
        status: true
    },
    {
        name: 'Trung tâm ngoại ngữ có vốn nước ngoài',
        nameCol: 'ttNnCoVonNuocNgoai',
        index: 29,
        status: true
    }
]
export const ESelectReportingCriteria_TruongHoc_TieuChi = [
    {
        name: 'Đơn vị quản lý',
        nameCol: 'maDonVi',
        index: 0
    },
    {
        name: 'Cấp dạy',
        nameCol: 'capHoc',
        index: 1
    },
    {
        name: 'Trường học',
        nameCol: 'maTruongHoc',
        index: 2
    },
    {
        name: 'Năm học',
        nameCol: 'namHoc',
        index: 3
    },
    {
        name: 'Loại hình đào tạo',
        nameCol: 'loaiHinhDaoTao',
        index: 4
    },
    {
        name: 'Loại hình trường',
        nameCol: 'loaiHinhTruong',
        index: 5
    },
    {
        name: 'Chuẩn quốc gia',
        nameCol: 'chuanQuocGia',
        index: 6
    },
    {
        name: 'Vùng khó khăn',
        nameCol: 'maVungKhoKhan',
        index: 7
    },

    {
        name: 'Chương trình giáo dục cơ bản',
        nameCol: 'chuongTrinhGiaoDucCoBan',
        index: 8
    },
    {
        name: 'Môi trường phù hợp hs khuyết tật',
        nameCol: 'coHaTangTlhtPhuHopHskt',
        index: 9
    },
    {
        name: 'Có lớp không chuyên',
        nameCol: 'coLopKhongChuyen',
        index: 10
    },
    {
        name: 'Công trình vệ sinh',
        nameCol: 'congTrinhVeSinh',
        index: 11
    },
    {
        name: 'Giáo dục vệ sinh đôi tay',
        nameCol: 'ctGdvsDoiTay',
        index: 12
    },
    {
        name: 'Đạt chất lượng tối thiểu',
        nameCol: 'datChatLuongToiThieu',
        index: 13
    },
    {
        name: 'Dạy nghề phổ thông',
        nameCol: 'dayNghePhoThong',
        index: 14
    },
    {
        name: 'Điện lưới',
        nameCol: 'dienLuoi',
        index: 15
    },
    {
        name: 'Học 2 buổi/ngày',
        nameCol: 'hoc2BuoiNgay',
        index: 16
    },
    {
        name: 'Học sinh bán trú',
        nameCol: 'hocSinhBanTru',
        index: 17
    },
    {
        name: 'Học sinh nội trú',
        nameCol: 'hocSinhNoiTru',
        index: 18
    },
    {
        name: 'Học sinh khuyết tật',
        nameCol: 'hocSinhKhuyetTat',
        index: 19
    },
    {
        name: 'Khai thác Internet dạy học',
        nameCol: 'khaiThacInternetDayHoc',
        index: 20
    },
    {
        name: 'Kỹ năng sống giáo dục xã hội',
        nameCol: 'kyNangSongGdxh',
        index: 21
    },
    {
        name: 'Trường quốc tế',
        nameCol: 'truongQuocTe',
        index: 22
    },
    {
        name: 'Vùng đặc biệt khó khăn',
        nameCol: 'vungDacBietKhoKhan',
        index: 23
    },
    {
        name: 'Trung tâm hướng nghiệp',
        nameCol: 'ttGdtxHuongNghiep',
        index: 24
    },
    {
        name: 'Trung tâm ngoại ngữ có vốn nước ngoài',
        nameCol: 'ttNnCoVonNuocNgoai',
        index: 25
    }
]
export const ESelect_HoTroHocTap = [
    {
        title: 'Miễn học phí - [2]',
        value: 2
    },
    {
        title: 'Khác - [1]',
        value: 1
    },
    {
        title: 'Giảm học phí - [3]',
        value: 3
    }
]
export const ESelect_ThoiDiemChuyenTruong = [
    {
        title: 'Học kỳ 1 - [1]',
        value: 1
    },
    {
        title: 'Học kỳ 2 - [2]',
        value: 2
    }
]

export const ESelectReportingCriteria_Can1Bo = {
    maDonVi: false,
    capHoc: false,
    maTruongHoc: false,
    namHoc: false,
    trangThai: false,
    gioiTinh: false,
    loaiHinhDaoTao: false,
    loaiHopDong: false,
    loaiCanBo: false,
    trinhDoChuyenMonCaoNhat: false,
    maKhuVuc: false,
    doTuoi: false,
    dgKqChuanNgheNghiep: false,
    danTocThieuSo: false,
    chuyenTrachDoi: false,
    maMonHoc: false,
    maDanToc: false,
    chucVu: false,
    trinhDoGvien: false,
    trinhDoDaoTao: false,
    hinhThucDaoTao: false,
    maChuyenNganhDaoTao: false,
    trinhDoVanHoa: false,
    trinhDoTinHoc: false,
    trinhDoNgoaiNgu: false,
    trinhDoLyLuanChinhTri: false,
    trinhDoQuanLyNhaNuoc: false,
    trinhDoQuanLyGiaoDuc: false,
    thamGiaBdTxuyen: false,
    maNgach: false,
    dayNhomLop: false,
    dayNhomLopKiemNhiem: false,
    monDayChinh: false,
    monDayKiemNhiem: false,
    nhiemVuKiemNhiem: false,
    bacLuong: false,
    heSo: false,
    vuotKhung: false,
    monNgoaiNguChinh: false,
    chuyenNganhDaoTaoChinh: false,
    trinhDoDaoTaoCnChinh: false,
    danhGiaVienChuc: false,
    thanhPhanGiaDinh: false,
    maDoiTac: false
}

export const TableName_LichSuTiepNhanDuLieu = [
    {
        name: 'Thông tin lớp học',
        value: 'LopHoc'
    },
    {
        name: 'Thông tin học sinh',
        value: 'HocSinhTheoNam'
    },
    {
        name: 'Thông tin CBQL, Giáo viên, Nhân viên',
        value: 'CanBoTheoNam'
    },
    {
        name: 'Điểm tổng kết môn',
        value: 'DiemTongKetMon'
    },
    {
        name: 'Tổng kết học sinh',
        value: 'TongKetHocSinh'
    },
    {
        name: 'Điểm tổng kết môn sau thi lại',
        value: 'DiemTongKetMonThiLai'
    },
    {
        name: 'Tổng kết học sinh sau thi lại',
        value: 'TongKetHocSinhThiLai'
    },
    {
        name: 'Thông tin trường học',
        value: 'TruongHocNam'
    },
    {
        name: 'Thông tin điểm trường',
        value: 'DiemTruong'
    },
    {
        name: 'Cơ sở vật chất',
        value: 'CoSoVatChat'
    },
    {
        name: 'Ngân sách',
        value: 'NganSach'
    },
    {
        name: 'Báo cáo EQMS',
        value: 'EQMS'
    },
    {
        name: 'Thí sinh tự do',
        value: 'ThiSinhTuDo'
    },
    {
        name: 'Xét tốt nghiệp học sinh',
        value: 'XetTotNghiepHocSinh'
    }
]

export const ESelectProfessionalQualifications_TraCuuCBGV = [
    { mappingCode: '7', constantTitle: 'Trung cấp - [7]' },
    { mappingCode: '16', constantTitle: 'Đại học - [16]' },
    { mappingCode: '1', constantTitle: 'Tiến sĩ - [1]' },
    { mappingCode: '13', constantTitle: 'Cao đẳng - [13]' },
    { mappingCode: '3', constantTitle: 'Thạc sĩ - [3]' }
]

export const ESelectLesson = [
    {
        title: 'Sáng, tối - [5]',
        value: 5
    },
    {
        title: 'Sáng, chiều - [4]',
        value: 4
    },
    {
        title: 'Tối - [3]',
        value: 3
    },
    {
        title: 'Sáng - [1]',
        value: 1
    },
    {
        title: 'Sáng, chiều, tối - [7]',
        value: 7
    },
    {
        title: 'Chiều, tối - [6]',
        value: 6
    },
    {
        title: 'Chiều - [2]',
        value: 2
    }
]

export const ESelectUnitType_DonVi = [
    {
        title: 'Chọn loại đơn vị',
        value: ''
    },
    {
        title: 'Sở',
        value: 1
    },
    {
        title: 'Phòng',
        value: 2
    }
]

export const ESelectCupationalGroup_MonHoc = [
    {
        title: 'Chọn nhóm nghề ',
        value: ''
    },
    {
        title: 'Nhóm nghề nông lâm',
        value: '1'
    },
    {
        title: 'Nhóm nghề tiểu thủ Công nghiệp',
        value: '2'
    },
    {
        title: 'Nhóm nghề dịch vụ',
        value: '3'
    },
    {
        title: 'Nhóm nghề tin học ứng dụng',
        value: '5'
    },
    {
        title: 'Nhóm nghề khác',
        value: '4'
    }
]

export const ESelectNation_DanToc = [
    {
        title: 'Tất cả',
        value: ''
    },
    {
        title: 'Thiểu số',
        value: '1'
    },
    {
        title: 'Đa số',
        value: '2'
    }
]
