<template>
    <el-dialog
        :visible="chinhSuaVisible"
        @close="closeModalChinhSua"
        width="80%"
        class="custom-dialog"
    >
        <span slot="title" class="custom-dialog-title"
            >Chỉnh sửa thông tin - {{ dataChinhSua.hoTen }}
        </span>
        <div class="row">
            <div class="col-3">
                <label>Tên tài khoản <span>*</span></label>
                <el-input
                    size="small"
                    placeholder="Nhập ..."
                    v-model="selectedValue.input_tenTaiKhoan"
                ></el-input>
            </div>
            <div class="col-3">
                <label>Tên đăng nhập <span>*</span></label>
                <el-input
                    :disabled="true"
                    size="small"
                    placeholder="Nhập ..."
                    v-model="selectedValue.input_tenDangNhap"
                ></el-input>
            </div>
            <div class="col-3">
                <label>Email <span>*</span></label>
                <el-input
                    size="small"
                    placeholder="Nhập ..."
                    v-model="selectedValue.input_email"
                ></el-input>
            </div>
            <div class="col-3">
                <label> Nhập Số điện thoại <span>*</span></label>
                <el-input
                    size="small"
                    placeholder="Nhập ..."
                    v-model="selectedValue.input_soDienThoai"
                ></el-input>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-3">
                <label>Vai trò</label>
                <div>
                    <el-select
                        filterable
                        clearable
                        v-model="selectedValue.selectedValue_vaiTro"
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                        @change="handleChange_Vaitro"
                    >
                        <el-option
                            v-for="item in this.ESelectRole"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>

            <div v-if="change_vaiTro === 20 || change_vaiTro === 2" class="col-3">
                <label>Đơn vị quản lý <span>*</span></label>
                <div>
                    <el-select
                        :disabled="change_vaiTro !== 2"
                        filterable
                        clearable
                        v-model="selectedValue.selectedValue_donViQuanLy"
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in ESelect_tinhThanh"
                            :key="item.maDonVi"
                            :label="item.tenDonVi"
                            :value="item.maDonVi"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div v-if="change_vaiTro !== 2 || change_vaiTro !== 20" class="col-3">
                <label>Đơn vị <span>*</span></label>
                <div>
                    <el-select
                        filterable
                        clearable
                        v-model="selectedValue.selectedValue_donVi"
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                        @change="getDataChonTruongHoc"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelectEducationDepartment"
                            :key="item.maDonVi"
                            :label="item.tenDonVi"
                            :value="item.maDonVi"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label
                    >Cấp học
                    <span v-if="change_vaiTro == 5" class="error">*</span></label
                >
                <div>
                    <el-select
                        filterable
                        clearable
                        size="small"
                        v-model="selectedValue.selectedValue_capHoc"
                        multiple
                        collapse-tags
                        style="width: 100%"
                        placeholder="Chọn"
                        @change="getDataChonTruongHoc"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelectGradeLevel"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div v-if="change_vaiTro == 5" class="col-3">
                <label
                    >Trường học
                    <span v-if="change_vaiTro == 5" class="error">*</span></label
                >
                <div>
                    <el-select
                        filterable
                        clearable
                        size="small"
                        v-model="selectedValue.selectedValue_truongHoc"
                        style="width: 100%"
                        placeholder="Chọn"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelectSchool"
                            :key="item.maTruongHoc"
                            :label="item.tenTruongHoc"
                            :value="item.maTruongHoc"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <div class="layout-btn">
            <CustomButton
                label="Đóng"
                size="small"
                @click="closeModalChinhSua"
                class="btn-default btn-delete"
            />
            <CustomButton
                label="Chỉnh sửa"
                class="btn-default btn-update"
                size="small"
                @click="handleChinhSua"
            />
        </div>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
import CustomButton from '@/components/CustomButton.vue'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import { filterCapHocList } from '@/utils'
export default {
    name: 'ModalDoiMauKhau',
    props: {
        chinhSuaVisible: {
            type: Boolean,
            required: true
        },

        closeModalChinhSua: {
            type: Function,
            required: true
        },
        dataChinhSua: { type: Object, required: true },
        handleSearch: Function,
        ESelectRole: { type: Array, required: true }, //vaitro
        ESelect_tinhThanh: { type: Array }
    },
    components: { CustomButton },
    data() {
        return {
            getDataESelect: {
                ESelectGradeLevel: [], //caphoc,
                ESelectSchool: [] //truonghoc
            },

            selectedValue: {
                input_tenTaiKhoan: '',
                input_tenDangNhap: '',
                input_email: '',
                input_soDienThoai: '',
                selectedValue_vaiTro: '',
                selectedValue_donViQuanLy: '',
                selectedValue_donVi: '',
                selectedValue_capHoc: [],
                selectedValue_truongHoc: ''
            },
            change_vaiTro: '',
            change_maTruongHoc: '',
            dataHandleUpdate: {}
        }
    },
    watch: {
        async dataChinhSua(newValue) {
            this.dataHandleUpdate = newValue
            this.selectedValue.input_tenTaiKhoan = newValue.hoTen
            this.selectedValue.input_tenDangNhap = newValue.username
            this.selectedValue.input_email = newValue.email
            this.selectedValue.input_soDienThoai = newValue.sdt
            this.selectedValue.selectedValue_vaiTro = newValue.role
            this.handleChange_Vaitro()
            this.selectedValue.selectedValue_donVi = newValue.maDonVi
            this.selectedValue.selectedValue_capHoc = newValue.capHocList?.map(
                (number) => number.toString()
            )
            this.selectedValue.selectedValue_donViQuanLy = newValue.maTinhThanh

            this.change_maTruongHoc = newValue.maTruongHoc
            await this.getDataChonTruongHoc()
        }
    },
    methods: {
        async getDataESelect_PhongGiaoDuc() {
            const request_Header = {
                token: this.authToken
            }
            const request_Params = {
                limit: 9999,

                maTinhThanh: this.authUser.province,
                start: 0
            }
            const response = await sendRequest(
                Api.internal_api.dm_DonVi,
                null,
                request_Header,
                request_Params
            )
            this.getDataESelect.ESelectEducationDepartment = response.rows.map(
                (item) => ({
                    tenDonVi: `${item.tenDonVi} - [${item.maDonVi}]`,
                    maDonVi: item.maDonVi
                })
            )
        },
        async handleChange_Vaitro() {
            this.change_vaiTro = this.selectedValue.selectedValue_vaiTro
            this.selectedValue.selectedValue_capHoc = []
            this.selectedValue.selectedValue_donVi = ''
            this.selectedValue.selectedValue_truongHoc = ''
            this.selectedValue.selectedValue_donViQuanLy = this.authUser.province

            await this.getDataChonTruongHoc()
        },
        async getDataChonTruongHoc() {
            const maDonVi = this.selectedValue.selectedValue_donVi
                ? [this.selectedValue.selectedValue_donVi]
                : []

            const capHoc = this.selectedValue.selectedValue_capHoc.map((item) =>
                parseInt(item)
            )

            const request_Header = {
                token: this.authToken
            }

            const request_Data = {
                capHoc: capHoc,
                maDonVi: maDonVi
            }

            if (this.change_vaiTro == 5) {
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })
                const response = await sendRequest(
                    Api.internal_api.dm_School,
                    request_Data,
                    request_Header,
                    null
                )
                if (response.statusResponse == 0) {
                    loading.close()
                    const dataTruongHoc = response.rows.map((item) => ({
                        ...item,
                        tenTruongHoc: `${item.tenTruongHoc} - [${item.maTruongHoc}]`
                    }))
                    this.getDataESelect.ESelectSchool = dataTruongHoc
                    const foundTruongHoc = dataTruongHoc.find(
                        (item) => item.maTruongHoc === this.change_maTruongHoc
                    )

                    if (foundTruongHoc) {
                        this.selectedValue.selectedValue_truongHoc =
                            foundTruongHoc.maTruongHoc
                    } else {
                        this.selectedValue.selectedValue_truongHoc = ''
                    }
                } else {
                    loading.close()
                }
            }
        },
        handleChinhSua() {
            const request_Header = {
                token: this.authToken
            }

            const request_Data = {
                capHocList: [2],

                email: this.selectedValue.input_email,
                hoTen: this.selectedValue.input_tenTaiKhoan,
                isSso: this.dataHandleUpdate.isSso,
                limit: null,
                maDonVi: this.selectedValue.selectedValue_donVi,
                maDonViCha: this.selectedValue.selectedValue_donVi,
                maQuanHuyen: this.selectedValue.selectedValue_donVi,
                maTinhThanh: this.selectedValue.selectedValue_donViQuanLy,
                maTruongHoc: this.selectedValue.selectedValue_truongHoc,
                parentRole: this.selectedValue.selectedValue_vaiTro,
                password: null,
                role: this.selectedValue.selectedValue_vaiTro,
                roleName: null,

                sdt: this.selectedValue.input_soDienThoai,
                start: null,
                tenDonVi: this.dataHandleUpdate.tenDonVi,
                trangThai: 1,

                username: this.dataHandleUpdate.username
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            this.$confirm('Xác nhận  chỉnh sửa?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const response = await sendRequest(
                        Api.heThong.quanLyNguoiDung.chinhSuaNguoiDung,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        loading.close()
                        this.$message({
                            type: 'success',
                            message: 'Chỉnh sửa thành công'
                        })
                        this.$emit('handleSearch')
                        this.closeModalChinhSua()
                    }
                })
                .catch((error) => {
                    loading.close()
                    if (error.response?.data.rd) {
                        this.$message({
                            type: 'error',
                            message: error.response?.data.rd
                        })
                    }
                })
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        }
    },
    mounted() {
        this.getDataESelect.ESelectGradeLevel = filterCapHocList(
            this.authUser.capHocList
        )
        this.getDataESelect_PhongGiaoDuc()
    }
}
</script>
<style scoped>
.row span {
    color: red;
}
.layout-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}

.btn-update {
    background-color: var(--yellow);
}
.btn-delete {
    background-color: var(--orange);
}
.error {
    color: red;
}
::v-deep .el-input.is-disabled .el-input__inner {
    color: black !important;
}
</style>
