import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import '../public/css/color.css'

locale.use(lang)
Vue.config.productionTip = false
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

const storedUser = localStorage.getItem('user')
if (storedUser) {
    store.commit('RESTORE_AUTH_USER', JSON.parse(storedUser))
}
const storeToken = localStorage.getItem('token')
if (storeToken) {
    store.commit('RESTORE_AUTH_TOKEN', storeToken)
}
const storeDataChonDonVi = localStorage.getItem('data_ChonDonVi')
if (storeDataChonDonVi) {
    store.commit('RESTORE_DATA_CHONDONVI', storeDataChonDonVi)
}
const storeDataChonDanToc = localStorage.getItem('data_ChonDantoc')
if (storeDataChonDanToc) {
    store.commit('RESTORE_DATA_CHONDANTOC', storeDataChonDanToc)
}

const storeDataChonTonGiao = localStorage.getItem('data_ChonTongiao')
if (storeDataChonTonGiao) {
    store.commit('RESTORE_DATA_CHONTONGIAO', storeDataChonTonGiao)
}

const storeDataChonNhommau = localStorage.getItem('data_ChonNhommau')
if (storeDataChonNhommau) {
    store.commit('RESTORE_DATA_CHONNHOMMAU', storeDataChonNhommau)
}

const storeDataChonThanhPhanGiaDinh = localStorage.getItem(
    'data_ChonThanhphangiadinh'
)
if (storeDataChonThanhPhanGiaDinh) {
    store.commit(
        'RESTORE_DATA_CHONTHANHPHANGIADINH',
        storeDataChonThanhPhanGiaDinh
    )
}

const storeDataChonKhuvuc = localStorage.getItem('data_ChonKhuvuc')
if (storeDataChonKhuvuc) {
    store.commit('RESTORE_DATA_CHONKHUVUC', storeDataChonKhuvuc)
}

const storeDataChonLoaikhuyettat = localStorage.getItem('data_ChonLoaikhuyettat')
if (storeDataChonLoaikhuyettat) {
    store.commit('RESTORE_DATA_CHONLOAIKHUYETTAT', storeDataChonLoaikhuyettat)
}

const storeDataChonDoituongchinhsach = localStorage.getItem(
    'data_ChonDoituongchinhsach'
)
if (storeDataChonDoituongchinhsach) {
    store.commit(
        'RESTORE_DATA_CHONDOITUONGCHINHSACH',
        storeDataChonDoituongchinhsach
    )
}

const storeDataChonDoituonguutien = localStorage.getItem(
    'data_ChonDoituonguutien'
)
if (storeDataChonDoituonguutien) {
    store.commit('RESTORE_DATA_CHONDOITUONGUUTIEN', storeDataChonDoituonguutien)
}

const storeDataChonHuongnghiepdaynghe = localStorage.getItem(
    'data_ChonHuongnghiepdaynghe'
)
if (storeDataChonHuongnghiepdaynghe) {
    store.commit(
        'RESTORE_DATA_CHONHUONGNGHIEPDAYNGHE',
        storeDataChonHuongnghiepdaynghe
    )
}

const storeDataChonLoainhaphoc = localStorage.getItem('data_ChonLoainhaphoc')
if (storeDataChonLoainhaphoc) {
    store.commit('RESTORE_DATA_CHONLOAINHAPHOC', storeDataChonLoainhaphoc)
}

const storeDataChonHocbantru = localStorage.getItem('data_ChonHocbantru')
if (storeDataChonHocbantru) {
    store.commit('RESTORE_DATA_CHONHOCBANTRU', storeDataChonHocbantru)
}

const storeDataChonNgoaingu = localStorage.getItem('data_ChonNgoaingu')
if (storeDataChonNgoaingu) {
    store.commit('RESTORE_DATA_CHONNGOAINGU', storeDataChonNgoaingu)
}

const storeDataChonLopdaotaoboiduong = localStorage.getItem(
    'data_ChonLopdaotaoboiduong'
)
if (storeDataChonLopdaotaoboiduong) {
    store.commit(
        'RESTORE_DATA_CHONLOPDAOTAOBOIDUONG',
        storeDataChonLopdaotaoboiduong
    )
}

const storeDataChonTrinhdochuyenmonnghiepvu = localStorage.getItem(
    'data_ChonTrinhdochuyenmonnghiepvu'
)
if (storeDataChonTrinhdochuyenmonnghiepvu) {
    store.commit(
        'RESTORE_DATA_CHONTRINHDOCHUYENMONNGHIEPVU',
        storeDataChonTrinhdochuyenmonnghiepvu
    )
}

const storeDataChonTrangthaicanbo = localStorage.getItem(
    'data_ChonTrangthaicanbo'
)
if (storeDataChonTrangthaicanbo) {
    store.commit('RESTORE_DATA_CHONTRANGTHAICANBO', storeDataChonTrangthaicanbo)
}

const storeDataChonLoaicanbo = localStorage.getItem('data_ChonLoaicanbo')
if (storeDataChonLoaicanbo) {
    store.commit('RESTORE_DATA_CHONLOAICANBO', storeDataChonLoaicanbo)
}

const storeDataChonMondaygiaovien = localStorage.getItem(
    'data_ChonMondaygiaovien'
)
if (storeDataChonMondaygiaovien) {
    store.commit('RESTORE_DATA_CHONMONDAYGIAOVIEN', storeDataChonMondaygiaovien)
}

const storeDataChonHinhthuchopdong = localStorage.getItem(
    'data_ChonHinhthuchopdong'
)
if (storeDataChonHinhthuchopdong) {
    store.commit('RESTORE_DATA_CHONHINHTHUCHOPDONG', storeDataChonHinhthuchopdong)
}

const storeDataChonTrinhdodaotao = localStorage.getItem('data_ChonTrinhdodaotao')
if (storeDataChonTrinhdodaotao) {
    store.commit('RESTORE_DATA_CHONTRINHDODAOTAO', storeDataChonTrinhdodaotao)
}

const storeDataChonChuannghenghiep = localStorage.getItem(
    'data_ChonChuannghenghiep'
)
if (storeDataChonChuannghenghiep) {
    store.commit('RESTORE_DATA_CHONCHUANNGHENGHIEP', storeDataChonChuannghenghiep)
}

const storeDataChonNhiemVuKiemNghiem = localStorage.getItem(
    'data_ChonNhiemvukiemnghiem'
)
if (storeDataChonNhiemVuKiemNghiem) {
    store.commit(
        'RESTORE_DATA_CHONNHIEMVUKIEMNGHIEM',
        storeDataChonNhiemVuKiemNghiem
    )
}

const storeDataChonNganhCC = localStorage.getItem('data_ChonNganhCC')
if (storeDataChonNganhCC) {
    store.commit('RESTORE_DATA_CHONNGANHCC', storeDataChonNganhCC)
}

const storeDataChonBoiDuongTX = localStorage.getItem('data_ChonBoiDuongTX')
if (storeDataChonBoiDuongTX) {
    store.commit('RESTORE_DATA_CHONBOIDUONGTX', storeDataChonBoiDuongTX)
}

const storeDataChonTrinhdoLLCT = localStorage.getItem('data_ChonTrinhDoLLCT')
if (storeDataChonTrinhdoLLCT) {
    store.commit('RESTORE_DATA_CHONTRINHDOLLCT', storeDataChonTrinhdoLLCT)
}

const storeDataChonTrinhdoQLGD = localStorage.getItem('data_ChonTrinhDoQLGD')
if (storeDataChonTrinhdoQLGD) {
    store.commit('RESTORE_DATA_CHONTRINHDOQLGD', storeDataChonTrinhdoQLGD)
}

const storeDataChonBoiDuongNghiepVu = localStorage.getItem(
    'data_ChonBoiDuongNghiepVu'
)
if (storeDataChonBoiDuongNghiepVu) {
    store.commit(
        'RESTORE_DATA_CHONBOIDUONGNGHIEPVU',
        storeDataChonBoiDuongNghiepVu
    )
}

const storeDataChonBoiDuongCBQLCotCan = localStorage.getItem(
    'data_ChonBoiDuongCBQLCotCan'
)
if (storeDataChonBoiDuongCBQLCotCan) {
    store.commit(
        'RESTORE_DATA_CHONBOIDUONGCBQLCOTCAN',
        storeDataChonBoiDuongCBQLCotCan
    )
}

const storeDataChonBoiDuongThaySach = localStorage.getItem(
    'data_ChonBoiDuongThaySach'
)
if (storeDataChonBoiDuongThaySach) {
    store.commit(
        'RESTORE_DATA_CHONBOIDUONGTHAYSACH',
        storeDataChonBoiDuongThaySach
    )
}

const storeDataChonTrinhDoNgoaingu = localStorage.getItem(
    'data_ChonTrinhDoNgoaiNgu'
)
if (storeDataChonTrinhDoNgoaingu) {
    store.commit('RESTORE_DATA_CHONTrinhDoNgoaiNgu', storeDataChonTrinhDoNgoaingu)
}

const storeDataChonNhomCChiNNgu = localStorage.getItem('data_ChonNhomCChiNNgu')
if (storeDataChonNhomCChiNNgu) {
    store.commit('RESTORE_DATA_CHONNHOMCCHINNGU', storeDataChonNhomCChiNNgu)
}

const storeDataChonLoaiCChiNNgu = localStorage.getItem('data_ChonLoaiCChiNNgu')
if (storeDataChonLoaiCChiNNgu) {
    store.commit('RESTORE_DATA_CHONLOAICCHINNGU', storeDataChonLoaiCChiNNgu)
}

const storeDataChonKhungNLucNNgu = localStorage.getItem('data_ChonKhungNLucNNgu')
if (storeDataChonKhungNLucNNgu) {
    store.commit('RESTORE_DATA_CHONKHUNGNLUCNNGU', storeDataChonKhungNLucNNgu)
}

const storeDataChonTrinhDoTinHoc = localStorage.getItem('data_ChonTrinhDoTinHoc')
if (storeDataChonTrinhDoTinHoc) {
    store.commit('RESTORE_DATA_CHONTRINHDOTINHOC', storeDataChonTrinhDoTinHoc)
}

const storeDataChonChuyenMon = localStorage.getItem('data_ChonChuyenMon')
if (storeDataChonChuyenMon) {
    store.commit('RESTORE_DATA_CHONCHUYENMON', storeDataChonChuyenMon)
}

const storeDataChonCChiTiengDTocTSo = localStorage.getItem(
    'data_ChonCChiTiengDTocTSo'
)
if (storeDataChonCChiTiengDTocTSo) {
    store.commit(
        'RESTORE_DATA_CHONCCHITIENGDTOCTSO',
        storeDataChonCChiTiengDTocTSo
    )
}

const storeDataChonDGVienChuc = localStorage.getItem('data_ChonDGVienChuc')
if (storeDataChonDGVienChuc) {
    store.commit('RESTORE_DATA_CHONDGVIENCHUC', storeDataChonDGVienChuc)
}

const storeDataChonDanhGiaGVGioi = localStorage.getItem('data_ChonDanhGiaGVGioi')
if (storeDataChonDanhGiaGVGioi) {
    store.commit('RESTORE_DATA_CHONDANHGIAGVGIOI', storeDataChonDanhGiaGVGioi)
}

const storeDataChonDanhGiaGVCNGioi = localStorage.getItem('data_ChonGVCNGioi')
if (storeDataChonDanhGiaGVCNGioi) {
    store.commit('RESTORE_DATA_CHONDANHGIAGVCNGIOI', storeDataChonDanhGiaGVCNGioi)
}

const storeDataChonTongPhuTrachDoiGioi = localStorage.getItem(
    'data_ChonTongPhuTrachDoiGioi'
)
if (storeDataChonTongPhuTrachDoiGioi) {
    store.commit(
        'RESTORE_DATA_CHONTONGPHUTRACHDOIGIOI',
        storeDataChonTongPhuTrachDoiGioi
    )
}

const storeDataChonNhomCanBo = localStorage.getItem('data_ChonNhomcanbo')
if (storeDataChonNhomCanBo) {
    store.commit('RESTORE_DATA_CHONNHOMCANBO', storeDataChonNhomCanBo)
}

const storeDataChonLoaihinhtruong = localStorage.getItem(
    'data_ChonLoaihinhtruong'
)
if (storeDataChonLoaihinhtruong) {
    store.commit('RESTORE_DATA_CHONLOAIHINHTRUONG', storeDataChonLoaihinhtruong)
}

const storeDataChonDatChuanQG = localStorage.getItem('data_ChonDatChuanQG')
if (storeDataChonDatChuanQG) {
    store.commit('RESTORE_DATA_CHONDATCHUANQG', storeDataChonDatChuanQG)
}

const storeDataChonGiaiDoanKQHT = localStorage.getItem('data_ChonGiaiDoanKqht')
if (storeDataChonGiaiDoanKQHT) {
    store.commit('RESTORE_DATA_CHONGIAIDOANKQHT', storeDataChonGiaiDoanKQHT)
}

const storeDataChonLoaiTotNghiep = localStorage.getItem('data_ChonLoaitotnghiep')
if (storeDataChonLoaiTotNghiep) {
    store.commit('RESTORE_DATA_CHONLOAITOTNGHIEP', storeDataChonLoaiTotNghiep)
}

const storeDataChonHinhthucdaotao = localStorage.getItem(
    'data_ChonHinhthucdaotao'
)
if (storeDataChonHinhthucdaotao) {
    store.commit('RESTORE_DATA_CHONHINHTHUCDAOTAO', storeDataChonHinhthucdaotao)
}

const storeDataChonVanbangchungchi = localStorage.getItem(
    'data_ChonVanbangchungchi'
)
if (storeDataChonVanbangchungchi) {
    store.commit('RESTORE_DATA_CHONVANBANGCHUNGCHI', storeDataChonVanbangchungchi)
}

const storeDataChonKhoadaotaosupham = localStorage.getItem(
    'data_ChonKhoadaotaosupham'
)
if (storeDataChonKhoadaotaosupham) {
    store.commit(
        'RESTORE_DATA_CHONKHOADAOTAOSUPHAM',
        storeDataChonKhoadaotaosupham
    )
}

const storeDataChonDonviboiduong = localStorage.getItem('data_ChonDonviboiduong')
if (storeDataChonDonviboiduong) {
    store.commit('RESTORE_DATA_CHONDONVIBOIDUONG', storeDataChonDonviboiduong)
}

const storeDataChonDonvitochuc = localStorage.getItem('data_ChonDonvitochuc')
if (storeDataChonDonvitochuc) {
    store.commit('RESTORE_DATA_CHONDONVITOCHUC', storeDataChonDonvitochuc)
}

const storeDataChonThoiluongboiduong = localStorage.getItem(
    'data_ChonThoiluongboiduong'
)
if (storeDataChonThoiluongboiduong) {
    store.commit(
        'RESTORE_DATA_CHONTHOILUONGBOIDUONG',
        storeDataChonThoiluongboiduong
    )
}

const storeDataChonLoaihinhboiduong = localStorage.getItem(
    'data_ChonLoaihinhboiduong'
)
if (storeDataChonLoaihinhboiduong) {
    store.commit(
        'RESTORE_DATA_CHONLOAIHINHBOIDUONG',
        storeDataChonLoaihinhboiduong
    )
}

const storeDataChonHinhthuc = localStorage.getItem('data_ChonHinhthuc')
if (storeDataChonHinhthuc) {
    store.commit('RESTORE_DATA_CHONHINHTHUC', storeDataChonHinhthuc)
}

const storeDataChonKetQuaSuPham = localStorage.getItem('data_ChonKetquasupham')
if (storeDataChonKetQuaSuPham) {
    store.commit('RESTORE_DATA_CHONKETQUASUPHAM', storeDataChonKetQuaSuPham)
}

const storeDataChonKyLuatGV = localStorage.getItem('data_ChonKyluatGV')
if (storeDataChonKyLuatGV) {
    store.commit('RESTORE_DATA_CHONKYLUATGV', storeDataChonKyLuatGV)
}

const storeDataChonTietHoc = localStorage.getItem('data_ChonTietHoc')
if (storeDataChonTietHoc) {
    store.commit('RESTORE_DATA_CHONTIETHOC', storeDataChonTietHoc)
}

const storeDataChonNganhDaoTao = localStorage.getItem('data_ChonNganhDaoTao')
if (storeDataChonNganhDaoTao) {
    store.commit('RESTORE_DATA_CHONNGANHDAOTAO', storeDataChonNganhDaoTao)
}

const storeDataChonTrinhDoVanHoa = localStorage.getItem('data_ChonTrinhDoVanHoa')
if (storeDataChonTrinhDoVanHoa) {
    store.commit('RESTORE_DATA_CHONTRINHDOVANHOA', storeDataChonTrinhDoVanHoa)
}

const storeDataChonTrinhDoQLNN = localStorage.getItem('data_ChonTrinhDoQLNN')
if (storeDataChonTrinhDoQLNN) {
    store.commit('RESTORE_DATA_CHONTRINHDOQLNN', storeDataChonTrinhDoQLNN)
}

const storeDataChonKhoi = localStorage.getItem('data_ChonKhoiHoc')
if (storeDataChonKhoi) {
    store.commit('RESTORE_DATA_CHONKHOI', storeDataChonKhoi)
}

const storeDataChonTinhTrangDinhDuong = localStorage.getItem(
    'data_ChonTinhTrangDinhDuong'
)
if (storeDataChonTinhTrangDinhDuong) {
    store.commit(
        'RESTORE_DATA_CHONTINHTRANGDINHDUONG',
        storeDataChonTinhTrangDinhDuong
    )
}

const storeDataChonLyDoThoiHoc = localStorage.getItem('data_ChonLyDoThoiHoc')
if (storeDataChonLyDoThoiHoc) {
    store.commit('RESTORE_DATA_CHONLYDOTHOIHOC', storeDataChonLyDoThoiHoc)
}
// console.log = function () {}

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app')
