<template>
    <el-dialog
        :visible="chinhSuaVisible"
        @close="closeModalChinhSua"
        class="custom-dialog"
        width="85%"
    >
        <span slot="title" class="custom-dialog-title"
            >Chỉnh sửa thông tin cấu hình
            <hr />
        </span>
        <div class="row">
            <div class="col-3">
                <label class="label">Số ngày cần đổi mật khẩu</label>
                <div>
                    <el-input
                        size="small"
                        placeholder="Nhập ..."
                        v-model="numDayChangePass"
                        type="number"
                    >
                    </el-input>
                </div>
            </div>
            <div class="col-3">
                <label class="label">Số lần tối đa được phép nhập mk sai</label>
                <div>
                    <el-input
                        size="small"
                        placeholder="Nhập ..."
                        v-model="numEnterPassFail"
                        type="number"
                    >
                    </el-input>
                </div>
            </div>
            <div class="col-3">
                <label class="label">Số ngày lưu lịch sử</label>
                <el-input
                    size="small"
                    placeholder="Nhập ..."
                    v-model="numDaySaveHis"
                    type="number"
                >
                </el-input>
            </div>
            <div class="col-3">
                <label class="label">Thời gian chờ ( phút )</label>
                <el-input
                    size="small"
                    placeholder="Nhập ..."
                    v-model="timeoutUser"
                    type="number"
                >
                </el-input>
            </div>
            <div class="col-12 mt-3">
                <div class="action">
                    <div>
                        <label class="label" style="margin-bottom: 0"
                            >Danh sách các IP</label
                        >
                    </div>
                    <div>
                        <span
                            style="margin-right: 10px"
                            v-if="selectedIps.length > 0"
                        >
                            Đã chọn {{ selectedIps.length }} bản ghi
                        </span>
                        <CustomButton
                            label="Xóa IP đã chọn"
                            size="small"
                            class="btn-default btn-delete"
                            @click="removeSelectedIps"
                        />
                    </div>
                </div>

                <el-table
                    :data="blockedIpList"
                    border
                    style="width: 100%"
                    @selection-change="handleSelectionChange"
                    empty-text="Không có bản ghi nào"
                    max-height="300px"
                >
                    <el-table-column
                        type="selection"
                        width="50"
                        v-model="selectedIps"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="ip"
                        label="Địa chỉ IP"
                        width="*"
                        header-align="center"
                    >
                        <template slot-scope="scope">
                            <el-input
                                size="small"
                                v-model="blockedIpList[scope.$index]"
                                placeholder="Nhập IP..."
                            />
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <div class="layout-btn">
            <CustomButton
                label="Đóng"
                size="small"
                class="btn-default btn-delete"
                @click="closeModalChinhSua"
            />
            <CustomButton
                label="Chỉnh sửa"
                class="btn-default btn-update"
                size="small"
                @click="handleUpdate"
            />
        </div>
    </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
import CustomButton from '@/components/CustomButton.vue'
import sendRequest from '@/services'
import Api from '@/constants/Api'

export default {
    name: 'ModalDoiMauKhau',
    props: {
        chinhSuaVisible: {
            type: Boolean,
            required: true
        },
        closeModalChinhSua: {
            type: Function,
            required: true
        },
        data_chinhSua: {
            type: Object,
            required: true
        },
        getDataTable: Function
    },
    components: { CustomButton },
    watch: {
        async data_chinhSua(newValue) {
            this.numDayChangePass = newValue?.numDayChangePass
            this.numEnterPassFail = newValue?.numEnterPassFail
            this.numDaySaveHis = newValue?.numDaySaveHis
            this.blockedIpList = newValue?.blockedIpList
            this.timeoutUser = this.authUser?.timeoutUser
        }
    },
    data() {
        return {
            numDayChangePass: '',
            numEnterPassFail: '',
            numDaySaveHis: '',
            blockedIpList: [],
            selectedIps: [],
            timeoutUser: null
        }
    },

    methods: {
        async handleUpdate() {
            this.$confirm('Xác nhận chỉnh sửa thông tin?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const newIps = this.selectedIps.filter(
                        (ip) => !this.blockedIpList.includes(ip)
                    )

                    const resultIps =
                        newIps.length > 0 ? newIps : this.blockedIpList
                    const request_Header = {
                        token: this.authToken
                    }
                    const request_Data = {
                        blockedIpList: resultIps,
                        numDaySaveHis: this.numDaySaveHis,
                        numDayChangePass: this.numDayChangePass,
                        numEnterPassFail: this.numEnterPassFail
                    }
                    const response = await sendRequest(
                        Api.heThong.cauHinhDiaChiIp.chinhSua,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        this.$message({
                            type: 'success',
                            message: 'Chỉnh sửa thành công'
                        })
                        this.closeModalChinhSua()
                        this.$emit('getDataTable')
                    } else {
                        this.$message({
                            type: 'warning',
                            message: response.rd
                        })
                    }
                })
                .catch(() => {})
        },
        handleSelectionChange(selectedRows) {
            this.selectedIps = selectedRows
        },
        removeSelectedIps() {
            this.blockedIpList = this.blockedIpList.filter(
                (ip) => !this.selectedIps.includes(ip)
            )
            this.selectedIps = []
        }
    },

    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    },
    mounted() {}
}
</script>

<style scoped>
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.btn-delete {
    background-color: var(--orange);
}
.action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.label {
    font-weight: 600;
}
::v-deep .el-table th.el-table__cell {
    background: #6aab9c;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
}
</style>
