<template>
    <div class="school">
        <div class="header">
            <div class="left">
                <div>
                    <el-icon name="s-custom" class="icon"></el-icon>
                </div>
                <div class="title">Học sinh</div>
            </div>
            <div class="right">
                <CustomButton
                    label="Chi tiết"
                    size="small"
                    class="custom-btn"
                    @click="handleNaviagte"
                />
            </div>
        </div>

        <div class="layout-card">
            <div class="row">
                <div class="col-md-4 mb-4">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">Tổng quan</div>
                            <div>
                                <CustomButton
                                    type="text"
                                    icon="el-icon-refresh-right"
                                    label=""
                                    size="medium"
                                    @click="
                                        handleRefresh_DataBieuDoTongQuan_HocSinh
                                    "
                                />
                            </div>
                        </div>
                        <div class="card-body">
                            <PieChart
                                :data_PieChart="
                                    dataBieuDoTongQuan_HocSinh.slice(0, 5)
                                "
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">Trạng thái</div>
                            <div>
                                <CustomButton
                                    type="text"
                                    icon="el-icon-refresh-right"
                                    label=""
                                    size="medium"
                                    @click="
                                        handleRefresh_DataBieuDoTrangThai_HocSinh
                                    "
                                />
                            </div>
                        </div>
                        <div class="card-body">
                            <StackedColumnChart
                                :data_StackedColumnChart="
                                    customColorStackedColumnChart(
                                        convert_dataBieuDoTrangThai_HocSinh
                                    )
                                "
                                :xaxis_categories="xaxisCategories.trangThai"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">Giới tính</div>
                            <div>
                                <CustomButton
                                    type="text"
                                    icon="el-icon-refresh-right"
                                    label=""
                                    size="medium"
                                    @click="
                                        handleRefresh_DataBieuDoGioiTinh_HocSinh
                                    "
                                />
                            </div>
                        </div>
                        <div class="card-body">
                            <StackedColumnChart
                                :data_StackedColumnChart="
                                    customColorStackedColumnChart(
                                        dataBieuDoGioiTinh_HocSinh
                                    )
                                "
                                :xaxis_categories="xaxisCategories.gioiTinh"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">Loại hình đào tạo</div>
                            <div>
                                <CustomButton
                                    type="text"
                                    icon="el-icon-refresh-right"
                                    label=""
                                    size="medium"
                                    @click="
                                        handleRefresh_DataBieuDoLoaiHinhDaoTao_HocSinh
                                    "
                                />
                            </div>
                        </div>
                        <div class="card-body">
                            <StackedColumnChart
                                :data_StackedColumnChart="
                                    customColorStackedColumnChart(
                                        dataBieuDoLoaiHinhDaoTao_HocSinh
                                    )
                                "
                                :xaxis_categories="xaxisCategories.loaiHinhDaoTao"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">Khu vực</div>
                            <div>
                                <CustomButton
                                    type="text"
                                    icon="el-icon-refresh-right"
                                    label=""
                                    size="medium"
                                    @click="
                                        handleRefresh_DataBieuDoKhuVuc_HocSinh
                                    "
                                />
                            </div>
                        </div>
                        <div class="card-body">
                            <StackedColumnChart
                                :data_StackedColumnChart="
                                    customColorStackedColumnChart(
                                        dataBieuDoKhuVuc_HocSinh
                                    )
                                "
                                :xaxis_categories="xaxisCategories.khuVuc"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Router } from '@/constants/Router'
import CustomButton from '@/components/CustomButton.vue'
import PieChart from '@/components/PieChart.vue'
import StackedColumnChart from '@/components/StackedColumnChart.vue'
import { Icon } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import { customColorStackedColumnChart } from '@/utils'

export default {
    name: 'schoolC',
    components: {
        'el-icon': Icon,
        CustomButton,
        PieChart,
        StackedColumnChart
    },
    data() {
        return {
            xaxisCategories: {
                trangThai: ['Đang học', 'Chuyển trường'],
                gioiTinh: ['Nam', 'Nữ'],
                loaiHinhDaoTao: ['Công lập', 'Tư Thục', 'Dân lập'],
                khuVuc: ['B.Giới-H.Đảo', 'Đô thị', 'Đồng bằng', 'M.Núi-V.Sâu']
            },
            convert_dataBieuDoTrangThai_HocSinh: [],
            convert_dataBieuDoGioiTinh_HocSinh: []
        }
    },
    props: {
        dataBieuDoTongQuan_HocSinh: {
            type: Array,
            required: true
        },
        refresh_dataBieuDoTongQuan_HocSinh: Function,

        dataBieuDoTrangThai_HocSinh: {
            type: Array,
            required: true
        },
        refresh_dataBieuDoTrangThai_HocSinh: Function,

        dataBieuDoGioiTinh_HocSinh: {
            type: Array,
            required: true
        },
        refresh_dataBieuDoGioiTinh_HocSinh: Function,

        dataBieuDoLoaiHinhDaoTao_HocSinh: {
            type: Array,
            required: true
        },
        refresh_dataBieuDoLoaiHinhDaoTao_HocSinh: Function,

        dataBieuDoKhuVuc_HocSinh: {
            type: Array,
            required: true
        },
        refresh_dataBieuDoKhuVuc_HocSinh: Function
    },
    watch: {
        dataBieuDoTrangThai_HocSinh(newValue) {
            this.convert_dataBieuDoTrangThai_HocSinh = newValue.map((item) => {
                item.data.splice(1, 1)
                return item
            })
        }
    },
    methods: {
        customColorStackedColumnChart,
        handleRefreshDataWithLoading(emitterFunction) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            emitterFunction()
            loading.close()
        },
        handleRefresh_DataBieuDoTongQuan_HocSinh() {
            this.handleRefreshDataWithLoading(() => {
                this.$emit('refresh_dataBieuDoTongQuan_HocSinh')
            })
        },
        handleRefresh_DataBieuDoTrangThai_HocSinh() {
            this.handleRefreshDataWithLoading(() => {
                this.$emit('refresh_dataBieuDoTrangThai_HocSinh')
            })
        },
        handleRefresh_DataBieuDoGioiTinh_HocSinh() {
            this.handleRefreshDataWithLoading(() => {
                this.$emit('refresh_dataBieuDoGioiTinh_HocSinh')
            })
        },
        handleRefresh_DataBieuDoLoaiHinhDaoTao_HocSinh() {
            this.handleRefreshDataWithLoading(() => {
                this.$emit('refresh_dataBieuDoLoaiHinhDaoTao_HocSinh')
            })
        },
        handleRefresh_DataBieuDoKhuVuc_HocSinh() {
            this.handleRefreshDataWithLoading(() => {
                this.$emit('refresh_dataBieuDoKhuVuc_HocSinh')
            })
        },
        handleNaviagte() {
            this.$router.push(Router.traCuu.traCuuHocSinh)
        }
    }
}
</script>

<style scoped>
.school {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 15px;
    padding-left: 6px;
    padding-right: 6px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 10px 12px 10px;
}
.header .left {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #2e8bc0;
    font-weight: 700;
    text-transform: uppercase;
}
.header .left .icon {
    font-size: 18px;
    color: #000000;
    font-weight: 700;
    margin-right: 5px;
}
.header .right {
    margin-left: 6px;
}

.layout-card {
    padding: 0px 10px 20px 10px;
}
.layout-card .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f2f3f8;
    height: 25px;
    color: black;
    padding: 5px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.layout-card .card-header .title {
    margin: -5px 0px 0px 10px;
    color: #000000;
    font-weight: 700;
    font-size: 15px;
}
.layout-card .card-body {
    padding: 5px;
    background: white;
    border: #f2f3f8 2px solid;
    height: 300px;
}
.custom-btn {
    font-weight: 700;
    color: #fff;
    background-color: #6aab9c;
}
::v-deep .el-icon-s-custom:before {
    font-size: 18px;
    color: #2e8bc0;
}
</style>
