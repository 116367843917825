<template>
    <div class="home">
        <div class="select-dashboard">
            <div class="row">
                <div class="col-md-2 col-6">
                    <ESelectYear
                        v-model="selectedValue.selectedValueSchoolYear"
                        placeholder="Chọn năm"
                        size="small"
                        :no-data-text="'Không có bản ghi nào'"
                        :no-match-text="'Không tìm thấy bản ghi nào'"
                        style="width: 100%"
                    >
                    </ESelectYear>
                </div>
                <div class="col-md-2 col-6">
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="Thống kê dữ liệu DashBoard"
                        placement="top"
                    >
                        <CustomButton
                            label="Thống kê"
                            size="small"
                            type="success"
                            @click="handleThongKe"
                        />
                    </el-tooltip>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="Tổng hợp lại dữ liệu"
                        placement="top"
                    >
                        <CustomButton
                            label="Tổng hợp"
                            size="small"
                            type="primary"
                            @click="handleTongHop"
                        />
                    </el-tooltip>
                </div>
            </div>
        </div>

        <Card :dataThongKeTangGiam="dataThongKeTangGiam" />

        <School :dataTruongHoc="dataTruongHoc" />

        <Class
            :dataLopHoc="dataLopHoc"
            :dataESelectLopHoc="dataESelectLopHoc"
            :selectedValueSchoolYear="selectedValue.selectedValueSchoolYear"
        />

        <ListOfSchoolLocations
            :selectedValueSchoolYear="selectedValue.selectedValueSchoolYear"
        />

        <EducatorProfile :dataCanBoGiaoVien="dataCanBoGiaoVien" />

        <Student :dataHocSinh="dataHocSinh" />
    </div>
</template>
<script>
import Card from './Card.vue'
import School from './School.vue'
import Class from './Class.vue'
import EducatorProfile from './EducatorProfile.vue'
import Student from './Student.vue'
import ListOfSchoolLocations from './ListOfSchoolLocations/index.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelectYear from '@/components/ESelectYear.vue'
import Api from '@/constants/Api'
import sendRequest from '@/services'
import { mapState } from 'vuex'
import { currentYear } from '@/utils'
export default {
    name: 'RoleSchool',
    components: {
        ESelectYear,
        CustomButton,
        Card,
        School,
        Class,
        EducatorProfile,
        Student,
        ListOfSchoolLocations
    },
    data() {
        return {
            request_Data: {
                maTruongs: [],
                namHoc: null
            },
            dataESelectLopHoc: [],
            selectedValue: {
                selectedValueSchoolYear: ''
            },
            dataThongKeTangGiam: {},
            dataTruongHoc: {},
            dataLopHoc: {},
            dataCanBoGiaoVien: {},
            dataHocSinh: {}
        }
    },
    methods: {
        async handleThongKe() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const namHoc_Update =
                this.selectedValue.selectedValueSchoolYear || currentYear
            const maTruongs_Update = [this.authUser.ma_truong_hoc]
            try {
                const request_Data_ThongKeTangGiam_Update = {
                    ...this.request_Data,
                    namHoc: namHoc_Update,
                    maTruongs: maTruongs_Update
                }
                this.request_Data = request_Data_ThongKeTangGiam_Update

                const request_Data_LopHoc_Update = {
                    ...this.request_Data,
                    namHoc: namHoc_Update,
                    maTruongs: maTruongs_Update
                }
                this.request_Data = request_Data_LopHoc_Update

                const request_Data_CanBoGiaoVien_Update = {
                    ...this.request_Data,
                    namHoc: namHoc_Update,
                    maTruongs: maTruongs_Update,
                    maSo: this.authUser.ma_so
                }
                this.request_Data = request_Data_CanBoGiaoVien_Update

                const request_Data_HocSinh_Update = {
                    ...this.request_Data,
                    namHoc: namHoc_Update,
                    maTruongs: maTruongs_Update,
                    maSo: this.authUser.ma_so
                }
                this.request_Data = request_Data_HocSinh_Update

                await this.getDataThongKeTangGiam()
                await this.getDataLopHoc()

                await this.getDataCanBoGiaoVien()
                await this.getDataHocSinh()
                await this.getDataDiemTruong()
                loading.close()
            } catch (error) {
                loading.close()
            }
        },
        async handleTongHop() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })

            const request_Header = {
                token: this.authToken
            }
            this.$confirm('Xác nhận Tổng hợp?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })

                .then(async () => {
                    const request_Data = {
                        namHoc: this.selectedValue.selectedValueSchoolYear,
                        maTruongHoc: this.authUser.ma_truong_hoc
                    }

                    const response = await sendRequest(
                        Api.roleSchool.tongHop,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        this.$message({
                            type: 'success',
                            message:
                                'Hệ thống đang tiến hành tổng hợp lại các báo cáo và hồ sơ tra cứu. Thông tin mới nhất từ QLNT nộp lên sẽ hiển thị trong vài phút tới'
                        })
                    }

                    loading.close()
                })
                .catch((error) => {
                    loading.close()
                    if (error.response?.data.rd) {
                        this.$message({
                            type: 'error',
                            message: error.response?.data.rd
                        })
                    }
                })
        },

        async getDataThongKeTangGiam() {
            try {
                const request_Header = {
                    token: this.authToken
                }

                const response = await sendRequest(
                    Api.roleSchool.card.thongKe,
                    this.request_Data,
                    request_Header
                )
                this.dataThongKeTangGiam = response.item
                this.dataTruongHoc = response.item
            } catch (error) {
                console.log(error)
            }
        },
        async getDataLopHoc() {
            try {
                const request_Header = {
                    token: this.authToken
                }

                const response = await sendRequest(
                    Api.roleSchool.dataLopHoc,
                    this.request_Data,
                    request_Header
                )

                this.dataLopHoc = response.item
            } catch (error) {
                console.log(error)
            }
        },
        async getDataCanBoGiaoVien() {
            try {
                const request_Header = {
                    token: this.authToken
                }

                const response = await sendRequest(
                    Api.roleSchool.dataCanBoGiaoVien,
                    this.request_Data,
                    request_Header
                )

                this.dataCanBoGiaoVien = response.item
            } catch (error) {
                console.log(error)
            }
        },
        async getDataHocSinh() {
            try {
                const request_Header = {
                    token: this.authToken
                }

                const response = await sendRequest(
                    Api.roleSchool.dataHocSinh,
                    this.request_Data,
                    request_Header
                )

                this.dataHocSinh = response.item
            } catch (error) {
                console.log(error)
            }
        },
        async getDataDiemTruong() {
            try {
                const request_Header = {
                    token: this.authToken
                }

                const response = await sendRequest(
                    Api.roleSchool.dataDiemTruong,
                    this.request_Data,
                    request_Header
                )

                this.dataESelectLopHoc = response.rows?.map((i) => ({
                    title: ` ${i.tenDiemTruong} - [${i.maDiemTruong}]`,
                    value: i.maDiemTruong
                }))
            } catch (error) {
                console.log(error)
            }
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    },
    mounted() {
        this.handleThongKe()
    }
}
</script>
<style scoped>
.content {
    height: 400px;
    overflow-y: scroll;
}

.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
}

.back-to-top.show {
    display: block;
}
/* CSS select dashboard */
.select-dashboard {
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 15px;
}

.select-dashboard .row {
    margin-left: 6px;
    margin-right: 6px;
}

.select-dashboard .col-12,
.select-dashboard .col-6 {
    padding: 12px 6px 12px 6px;
}

.custom-btn {
    background-color: #2e93fa;
    color: white;
}
</style>
