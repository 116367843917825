<template>
    <div>
        <CustomBreadcrumb :title="'HỆ THỐNG'" content="QUẢN LÝ VAI TRÒ" />

        <div class="layout-btn">
            <div>
                <CustomButton
                    label="Thêm mới"
                    size="small"
                    class="btn-default btn-add"
                    @click="handleOpenModalThemMoi"
                />
            </div>
        </div>
        <CustomTable :columns="tableColumns">
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="11" class="text-tbody">
                            Không có bản ghi nào.
                        </td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td class="text-tbody text-center">{{ index + 1 }}</td>

                    <td class="text-tbody text-left">{{ item.roleName }}</td>
                    <td class="text-tbody text-left">
                        {{ getNameRole(item.parentRole) }}
                    </td>
                    <td class="text-tbody text-left">{{ item.description }}</td>
                    <td class="text-tbody text-center">
                        <span v-if="item.builtIn === true">
                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="Vai trò mặc định. Không thể chỉnh sửa"
                                placement="left"
                            >
                                <el-button
                                    class="btn-default btn-update"
                                    size="small"
                                    icon="el-icon-edit-outline"
                                >
                                </el-button>
                            </el-tooltip>
                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="Vai trò mặc định. Không thể xóa"
                                placement="left"
                            >
                                <el-button
                                    class="btn-default btn-delete"
                                    size="small"
                                    icon="el-icon-delete"
                                >
                                </el-button>
                            </el-tooltip>
                        </span>
                        <span v-if="item.builtIn === false">
                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="Chỉnh sửa"
                                placement="left"
                            >
                                <el-button
                                    class="btn-default btn-update"
                                    size="small"
                                    icon="el-icon-edit-outline"
                                    @click="handleOpenModalChinhSua(item)"
                                >
                                </el-button>
                            </el-tooltip>
                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="Xóa"
                                placement="left"
                            >
                                <el-button
                                    class="btn-default btn-delete"
                                    size="small"
                                    icon="el-icon-delete"
                                    @click="handleDeleteRole(item.roleId)"
                                >
                                </el-button>
                            </el-tooltip>
                        </span>
                    </td>
                </tr>
            </template>
        </CustomTable>
        <ModalThemMoi
            :themMoiVisible="themMoiVisible"
            :closeModalThemMoi="closeModalThemMoi"
            @getDataTableRole="getDataTableRole"
        />
        <ModalChinhSua
            :closeModalChinhSua="closeModalChinhSua"
            :chinhSuaVisible="chinhSuaVisible"
            :data_chinhSua="data_chinhSua"
            @getDataTableRole="getDataTableRole"
        />
    </div>
</template>
<script>
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'
import ModalThemMoi from './ModalThemMoi.vue'
import CustomButton from '@/components/CustomButton.vue'
import ModalChinhSua from './ModalChinhSua.vue'
import { mapState } from 'vuex'

import Api from '@/constants/Api'
import sendRequest from '@/services'
import CustomTable from '@/components/CustomTable.vue'
export default {
    name: 'QuanLyVaiTro',
    components: {
        CustomButton,
        CustomBreadcrumb,
        ModalThemMoi,
        ModalChinhSua,
        CustomTable
    },
    data() {
        return {
            themMoiVisible: false,
            chinhSuaVisible: false,
            data_chinhSua: {},
            tableData: [],
            tableColumns: [
                { key: '1', label: 'STT' },
                { key: '2', label: 'Tên vai trò' },
                { key: '3', label: 'Vai trò gốc' },
                { key: '4', label: 'Mô tả' },

                { key: '5', label: 'Thao tác' }
            ],
            list_role: [
                {
                    name: 'Super Admin',
                    value: 1
                },
                {
                    name: 'Admin Sở',
                    value: 2
                },
                {
                    name: 'Phòng',
                    value: 4
                },
                {
                    name: 'Trường',
                    value: 5
                }
            ]
        }
    },

    methods: {
        handleOpenModalThemMoi() {
            this.themMoiVisible = true
        },
        closeModalThemMoi() {
            this.themMoiVisible = false
        },
        handleOpenModalChinhSua(item) {
            this.data_chinhSua = item
            this.chinhSuaVisible = true
        },
        closeModalChinhSua() {
            this.chinhSuaVisible = false
        },
        getNameRole(parentRole) {
            const role = this.list_role.find((role) => role.value === parentRole)
            return role ? role.name : ''
        },
        handleDeleteRole(roleId) {
            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                roleId: roleId
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            this.$confirm('Xác nhận Xóa ?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const response = await sendRequest(
                        Api.heThong.quanLyVaiTro.xoaRole,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        loading.close()

                        this.$message({
                            type: 'success',
                            message: 'Xóa thành công'
                        })
                        await this.getDataTableRole()
                    } else {
                        loading.close()
                        this.$message({
                            type: 'warning',
                            message: response.rd
                        })
                    }
                })
                .catch(() => {
                    loading.close()
                    this.$message({
                        type: 'info',
                        message: 'Hủy'
                    })
                })
        },
        async getDataTableRole() {
            const request_Header = {
                token: this.authToken
            }

            const response = await sendRequest(
                Api.heThong.quanLyVaiTro.getRole,
                null,
                request_Header
            )
            this.tableData = response
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        }
    },
    mounted() {
        this.getDataTableRole()
    }
}
</script>

<style scoped>
.text-left {
    text-align: left;
}

.layout-btn {
    padding: 20px 10px 5px 10px;
    background: #fff;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
}
.text-center {
    text-align: center;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.bg-search,
.btn-info {
    background-color: var(--teal);
}
.btn-add {
    background-color: var(--light-green);
}

.btn-update {
    background-color: var(--yellow);
}
.btn-delete {
    background-color: var(--orange);
}
</style>
