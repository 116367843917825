<template>
    <div>
        <CustomBreadcrumb :title="'DANH MỤC'" content="MÔN HỌC" />
        <div class="content">
            <div class="row d-flex align-items-center">
                <div class="col-md-2">
                    <label>Tên môn học:</label>
                    <el-input
                        placeholder="Nhập tên môn học ..."
                        v-model="selectedValue.input_tenMonHoc"
                        size="small"
                    >
                    </el-input>
                </div>
                <div class="col-md-2">
                    <label>Mã môn học:</label>
                    <el-input
                        placeholder="Nhập mã môn học ..."
                        v-model="selectedValue.input_maMonHoc"
                        size="small"
                    >
                    </el-input>
                </div>
                <div class="col-md-2">
                    <label>Trạng thái:</label>
                    <div>
                        <ESelectOne
                            style="width: 100%"
                            :clearable="false"
                            :disabled="false"
                            v-model="selectedValue.selectedValue_trangThai"
                            :data="getDataESelect.ESelect_TrangThai"
                            placeholder="Chọn "
                            :filterable="true"
                            :collapseTags="false"
                            :fields="['title', 'value']"
                            :no-data-text="'Không có bản ghi nào'"
                            :no-match-text="'Không tìm thấy bản ghi nào'"
                        />
                    </div>
                </div>
                <div class="col-md-2">
                    <label>Cấp học:</label>
                    <div>
                        <ESelectOne
                            style="width: 100%"
                            :clearable="false"
                            :disabled="false"
                            v-model="selectedValue.selectedValue_capHoc"
                            :data="getDataESelect.ESelect_CapHoc"
                            placeholder="Chọn "
                            :filterable="true"
                            :collapseTags="false"
                            :fields="['title', 'value']"
                            :no-data-text="'Không có bản ghi nào'"
                            :no-match-text="'Không tìm thấy bản ghi nào'"
                        />
                    </div>
                </div>
                <div class="col-md-2">
                    <label></label>
                    <div>
                        <CustomButton
                            label="Tìm kiếm"
                            size="small"
                            type="success"
                            @click="handleSearch"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="layout-btn">
            <div class="title">Danh sách môn học</div>

            <div class="">
                <CustomButton
                    icon="el-icon-upload"
                    label="Import"
                    size="small"
                    type="success"
                    @click="handleOpenModalImport"
                />
                <CustomButton
                    icon="el-icon-plus"
                    label="Thêm mới"
                    size="small"
                    type="primary"
                    @click="handleOpenModalThemMoi"
                />
            </div>
        </div>
        <div class="layout-table">
            <table class="table table-bordered table-hover centered-table">
                <thead>
                    <tr>
                        <th class="text-thead">STT</th>
                        <th class="text-thead">Mã môn học</th>
                        <th class="text-thead">Tên môn học</th>
                        <th class="text-thead">Ngoại ngữ</th>
                        <th class="text-thead">Tiếng dân tộc</th>

                        <th class="text-thead">Trạng thái</th>
                        <th class="text-thead">Thao tác</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="tableData.length === 0">
                        <tr>
                            <td class="text-tbody text-center" colspan="6">
                                Không có bản ghi nào.
                            </td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td class="text-tbody text-center">
                            {{ (currentPage - 1) * limit + index + 1 }}
                        </td>
                        <td class="text-tbody">
                            {{ item.maMonHoc }}
                        </td>
                        <td class="text-tbody">
                            {{ item.tenMonHoc }}
                        </td>

                        <td class="text-tbody text-center">
                            <span v-if="item.ngoaiNgu === 1">
                                <img
                                    src="../../../assets/img/icon_check.png"
                                    class="img-icon"
                                />
                            </span>
                            <span v-if="item.ngoaiNgu !== 1">
                                <img
                                    src="../../../assets/img/icon_close.png"
                                    class="img-icon"
                                />
                            </span>
                        </td>
                        <td class="text-tbody text-center">
                            <span v-if="item.tiengDanToc === 1">
                                <img
                                    src="../../../assets/img/icon_check.png"
                                    class="img-icon"
                                />
                            </span>
                            <span v-if="item.tiengDanToc !== 1">
                                <img
                                    src="../../../assets/img/icon_close.png"
                                    class="img-icon"
                                />
                            </span>
                        </td>
                        <td class="text-tbody text-center">
                            <span
                                v-if="item.trangThai === 1"
                                class="text-status-on"
                                >Đang hoạt động</span
                            >
                            <span
                                v-if="item.trangThai === 0"
                                class="text-status-off"
                                >Ngưng hoạt động</span
                            >
                        </td>
                        <td class="text-tbody text-center">
                            <CustomButton
                                size="small"
                                label=""
                                icon="el-icon-edit"
                                type="warning"
                                @click="handleOpenModalChinhSua(item)"
                            />
                            <CustomButton
                                size="small"
                                label=""
                                icon="el-icon-delete"
                                type="danger"
                                @click="handleDelete(item)"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <CustomPagination
            v-show="total_rows > 0"
            :tongbanghi="total_rows"
            :soluonghienthi="soLuongBanGhiHienThi"
            :batdau="trangbatdau"
            @pageChange="layLai($event)"
        >
        </CustomPagination>
        <ModalThemMoi
            :themMoiModalVisible="themMoiModalVisible"
            :closeModalThemMoi="closeModalThemMoi"
            @handleSearch="handleSearch"
        />
        <ModalChinhSua
            :chinhSuaModalVisible="chinhSuaModalVisible"
            :closeModalChinhSua="closeModalChinhSua"
            @handleSearch="handleSearch"
            :dataModalChinhSua="dataModalChinhSua"
        />
        <ModalImport
            :importModalVisible="importModalVisible"
            :closeModalImport="closeModalImport"
        />
    </div>
</template>
<script>
import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomPagination from '@/components/CustomPagination.vue'
import { mapState } from 'vuex'
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelectOne from '@/components/ESelectOne.vue'
import {
    ESelectStatus_SuperAdmin,
    ESelectSchoolLevel_MonHoc
} from '@/mock_data/index'
import ModalThemMoi from './ModalThemMoi.vue'
import ModalChinhSua from './ModalChinhSua.vue'
import ModalImport from './ModalImport.vue'
export default {
    name: 'MonHoc',
    components: {
        CustomBreadcrumb,
        ESelectOne,
        CustomButton,
        CustomPagination,
        ModalThemMoi,
        ModalChinhSua,
        ModalImport
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            soLuongBanGhiHienThi: 25,
            trangbatdau: false,

            tableData: [],

            themMoiModalVisible: false,
            chinhSuaModalVisible: false,
            dataModalChinhSua: {},
            importModalVisible: false,
            getDataESelect: {
                ESelect_TrangThai: ESelectStatus_SuperAdmin, //trangthai
                ESelect_CapHoc: ESelectSchoolLevel_MonHoc //caphoc
            },

            selectedValue: {
                input_tenMonHoc: '', //tendonvi
                input_maMonHoc: '', //madonvi
                selectedValue_trangThai: '', //trangthai
                selectedValue_capHoc: ''
            }
        }
    },
    methods: {
        handleOpenModalThemMoi() {
            this.themMoiModalVisible = true
        },
        closeModalThemMoi() {
            this.themMoiModalVisible = false
        },
        handleOpenModalChinhSua(item) {
            this.dataModalChinhSua = item
            this.chinhSuaModalVisible = true
        },
        closeModalChinhSua() {
            this.chinhSuaModalVisible = false
        },
        handleOpenModalImport() {
            this.importModalVisible = true
        },
        closeModalImport() {
            this.importModalVisible = false
        },
        checkTruocKhiTim() {
            this.trangbatdau = !this.trangbatdau
        },
        layLai(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },

        handleDelete(item) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            this.$confirm('Xác nhận xóa bản ghi đã chọn?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const request_Header = {
                        token: this.authToken
                    }
                    const request_Params = {
                        ma: item.maMonHoc
                    }
                    const response = await sendRequest(
                        Api.superAdmin.monHoc.xoaMonHoc,
                        null,
                        request_Header,
                        request_Params
                    )
                    if (response.code == 200) {
                        this.$message({
                            type: 'success',
                            message: 'Xóa thành công'
                        })
                        this.handleSearch()
                    } else {
                        loading.close()
                        this.$message({
                            type: 'error',
                            message: 'Thất bại'
                        })
                    }
                })
                .catch(() => {
                    loading.close()
                })
        },

        async handleSearch() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    start: this.start,
                    limit: this.limit,
                    ma: this.selectedValue.input_maMonHoc,
                    ten: this.selectedValue.input_tenMonHoc,
                    capHoc: this.selectedValue.selectedValue_capHoc?.value,
                    trangThai: this.selectedValue.selectedValue_trangThai?.value
                }
                const response = await sendRequest(
                    Api.superAdmin.monHoc.danhSach,
                    null,
                    request_Header,
                    request_Params
                )

                loading.close()
                this.tableData = response.rows
                this.total_rows = response.total
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        }
    },
    created() {
        this.selectedValue.selectedValue_trangThai = {
            title: 'Tất cả',
            value: ''
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    },
    mounted() {
        this.handleSearch()
    }
}
</script>

<style scoped>
.content {
    background: #fff;
    padding: 10px 10px 10px 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}
.content label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px;
}
.content .span {
    color: red;
}
.content .title {
    margin-bottom: 5px;
    font-size: 18px;
    color: #212529;
}
.layout-btn {
    padding: 20px 10px 5px 10px;
    justify-content: space-between;
    display: flex;
    background: #fff;
}

.layout-btn-search {
    margin-top: 15px;
    margin-bottom: 5px;
}
.layout-table {
    background: #fff;
    padding: 20px 10px 5px 10px;
    border-radius: 4px;

    overflow-x: auto;
}
table thead tr {
    background: rgb(228, 235, 245);
}
.table {
    white-space: nowrap;
}
.centered-table {
    margin-left: auto;
    margin-right: auto;
}

.centered-table th {
    text-align: center;
}
.table-bordered {
    background-color: #fff;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}
.table-hover tbody tr:hover {
    background-color: #f5f5f5;
}

.text-thead {
    font-size: 13px;

    vertical-align: middle; /* Căn giữa theo chiều dọc */
}
.text-tbody {
    font-size: 13px;
    vertical-align: middle;
}
.text-center {
    text-align: center;
}
.text-status-on {
    color: blue;
    font-weight: 700;
}
.text-status-off {
    color: red;
    font-weight: 700;
}
.img-icon {
    height: 20px;
    object-fit: cover;
}
</style>
