export const Router = {
    auth: {
        login: '/login',
        signup: '/sign-up',
        forgotpassword: 'forgot-password'
    },
    home: {
        home: '/'
    },
    tiepNhanDuLieuQLNT: {
        lichSuTiepNhanDuLieu: '/Data-transfer-schedule'
    },
    ketChuyenDuLieuLenBo: {
        matKhauKetChuyen: '/pass-data-transfer',
        cauHinhDuyetDayDuLieuLenBo: '/config-moet-transfer-permission',
        ketChuyenDuLieu: '/Request-data-by-school',
        lichSuDayDuLieuLenBo: '/moet-transfer-history'
    },
    traCuu: {
        traCuuHocSinh: '/Look-up-students',
        traCuuCanBoGV: '/Look-up-officials',
        traCuuLopHoc: '/Look-up-classes',
        traCuuKetQuaHocTap: '/Look-up-academi-results',
        traCuuTruongHoc: '/category/schools'
    }
}
