<template>
    <el-dialog
        :visible="disabledTruongHocModalVisible"
        @close="closeModalDisabledTruongHoc"
        width="90%"
        class="custom-dialog"
    >
        <span slot="title" class="custom-dialog-title">
            Thông tin trường học
            <hr />
        </span>
        <div class="row">
            <div class="col-3">
                <label>Tỉnh thành <span class="span">*</span></label>
                <div>
                    <el-select
                        disabled
                        v-model="selectedValue.selectedValue_tinhThanh"
                        placeholder="Select"
                        size="small"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelect_tinhThanh"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label>Quận huyện <span class="span">*</span></label>
                <div>
                    <el-select
                        disabled
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        v-model="selectedValue.selectedValue_quanHuyen"
                        size="small"
                        style="width: 100%"
                        @input="handleSelectChange_quanHuyen"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelect_quanHuyen"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label>Phường xã <span class="span">*</span></label>
                <div>
                    <el-select
                        disabled
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        v-model="selectedValue.selectedValue_phuongXa"
                        size="small"
                        style="width: 100%"
                        @input="handleSelectChange_PhuongXa"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelect_phuongXa"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label>Tổ thôn</label>
                <div>
                    <el-select
                        disabled
                        v-model="selectedValue.selectedValue_toThon"
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        size="small"
                        :no-data-text="'Không có bản ghi nào'"
                        :no-match-text="'Không tìm thấy bản ghi nào'"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelect_toThon"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-3">
                <label>Đơn vị quản lý <span class="span">*</span></label>
                <div>
                    <el-select
                        disabled
                        v-model="selectedValue.selectedValue_donViquanLy"
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelect_donViQuanLy"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label>Mã trường học <span class="span">*</span></label>
                <div>
                    <el-input
                        :disabled="true"
                        placeholder="Please input"
                        v-model="selectedValue.input_maTruongHoc"
                        size="small"
                    ></el-input>
                </div>
            </div>
            <div class="col-3">
                <label>Tên trường học<span class="span">*</span></label>
                <div>
                    <el-input
                        :disabled="true"
                        placeholder="Please input"
                        v-model="selectedValue.input_tenTruongHoc"
                        size="small"
                    ></el-input>
                </div>
            </div>
            <div class="col-3">
                <label>Cấp học <span class="span">*</span></label>
                <div>
                    <el-select
                        disabled
                        size="small"
                        clearable
                        filterable
                        v-model="selectedValue.selectedValue_capHoc"
                        multiple
                        placeholder="Chọn"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelect_capHoc"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-3">
                <label>Nhóm cấp học</label>
                <div>
                    <el-select
                        disabled
                        v-model="selectedValue.selectedValue_nhomCapHoc"
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelect_nhomCapHoc"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label>Loại hình đào tạo <span class="span">*</span></label>
                <div>
                    <el-select
                        disabled
                        v-model="selectedValue.selectedValue_loaiHinhDaoTao"
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelect_loaiHinhDaoTao"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label>Loại hình trường<span class="span">*</span></label>
                <div>
                    <el-select
                        disabled
                        v-model="selectedValue.selectedValue_loaiHinhTruong"
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in this.dataChonLoaihinhtruong_Store"
                            :key="item.mappingCode"
                            :label="item.constantTitle"
                            :value="item.mappingCode"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label>Ngày thành lập</label>
                <div>
                    <date-picker
                        :disabled="true"
                        :config="configDatePicker"
                        placeholder="Chọn ngày"
                        v-model="selectedValue.date_ngayThanhLap"
                        class="custom-date-picker"
                    >
                    </date-picker>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-3">
                <label>Chuẩn quốc gia <span class="span">*</span></label>
                <div>
                    <el-select
                        disabled
                        v-model="selectedValue.selectedValue_chuanQuocGia"
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in this.dataChonDatChuanQG_Store"
                            :key="item.mappingCode"
                            :label="item.constantTitle"
                            :value="item.mappingCode"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-3">
                <label>Website </label>
                <div>
                    <el-input
                        :disabled="true"
                        placeholder="Nhập"
                        v-model="selectedValue.input_webSite"
                        size="small"
                    ></el-input>
                </div>
            </div>
            <div class="col-3">
                <label>Tên hiệu trưởng</label>
                <div>
                    <el-input
                        :disabled="true"
                        placeholder="Nhập"
                        v-model="selectedValue.input_tenHieuTruong"
                        size="small"
                    ></el-input>
                </div>
            </div>
            <div class="col-3">
                <label>Email <span class="span">*</span></label>
                <div>
                    <el-input
                        :disabled="true"
                        placeholder="Nhập"
                        v-model="selectedValue.input_email"
                        size="small"
                    ></el-input>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-3">
                <label>Fax</label>
                <div>
                    <el-input
                        :disabled="true"
                        placeholder="Nhập"
                        v-model="selectedValue.input_fax"
                        size="small"
                    ></el-input>
                </div>
            </div>
            <div class="col-3">
                <label>Địa chỉ</label>
                <div>
                    <el-input
                        :disabled="true"
                        placeholder="Nhập"
                        v-model="selectedValue.input_diaChi"
                        size="small"
                    ></el-input>
                </div>
            </div>

            <div class="col-3">
                <label>Trạng thái</label>
                <div>
                    <el-select
                        disabled
                        v-model="selectedValue.selectedValue_trangThai"
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Chọn"
                        size="small"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelect_trangThai"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
        </div>
        <div class="layout-btn">
            <div>
                <CustomButton
                    label="Đóng"
                    size="small"
                    class="btn-default btn-delete"
                    @click="closeModalDisabledTruongHoc"
                />
            </div>
        </div>
    </el-dialog>
</template>
<script>
import datePicker from 'vue-bootstrap-datetimepicker'

import CustomButton from '@/components/CustomButton.vue'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import { mapState } from 'vuex'
import sendRequest from '@/services'
import {
    ESelectGradeLevel_MockData_TraCuuTruongHoc,
    ESelectGradeGroup_MockData,
    ESelectTypeOfEducation_MockData,
    ESelectStatus_MockData_TraCuuTruongHoc
} from '@/mock_data'
import Api from '@/constants/Api'
export default {
    name: 'DisabledModalTruongHoc',
    props: {
        disabledTruongHocModalVisible: {
            type: Boolean,
            required: true
        },

        closeModalDisabledTruongHoc: {
            type: Function,
            required: true
        },
        data_chiTietTruongHoc: {
            type: Object,
            required: true
        }
    },

    components: { datePicker, CustomButton },
    data() {
        return {
            configDatePicker: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
                showTodayButton: true,
                locale: 'vi'
            },
            maTthanh: '',
            maQhuyen: '',
            maQhuyen_change: '',
            maPxa: '',
            maPxa_change: '',
            maDonVi: '',
            dataUpdate: {},
            getDataESelect: {
                ESelect_tinhThanh: [], //tinhthanh
                ESelect_quanHuyen: [], //quanhuyen
                ESelect_phuongXa: [], //phuongxa
                ESelect_toThon: [], //tothon
                ESelect_donViQuanLy: [], //donviquanly

                ESelect_capHoc: ESelectGradeLevel_MockData_TraCuuTruongHoc, //caphoc
                ESelect_nhomCapHoc: ESelectGradeGroup_MockData, //nhomcaphoc
                ESelect_loaiHinhDaoTao: ESelectTypeOfEducation_MockData, //loaihinhdaotao
                ESelect_loaiHinhTruong: [], //loaihinhtruong
                ESelect_chuanQuocGia: [], //chuanquocgia
                ESelect_trangThai: ESelectStatus_MockData_TraCuuTruongHoc //trangthaihoatdong
            },
            selectedValue: {
                selectedValue_tinhThanh: '',
                selectedValue_quanHuyen: '',
                selectedValue_phuongXa: '',
                selectedValue_toThon: '',
                selectedValue_donViquanLy: '',
                input_maTruongHoc: '',
                input_tenTruongHoc: '',
                selectedValue_capHoc: [],
                selectedValue_nhomCapHoc: [],
                selectedValue_loaiHinhDaoTao: [],
                selectedValue_loaiHinhTruong: [],
                date_ngayThanhLap: '',
                selectedValue_chuanQuocGia: [],
                input_webSite: '',
                input_tenHieuTruong: '',
                input_email: '',
                input_fax: '',
                input_diaChi: '',
                selectedValue_trangThai: []
            }
        }
    },
    watch: {
        async disabledTruongHocModalVisible(newValue) {
            if (newValue === true) {
                await this.getDataTinhThanh()
                await this.getDataQuanHuyen()
                await this.getDataPhuongXa()
                await this.getDonViQuanLy()
                await this.getDataToThon()
            }
        },

        async data_chiTietTruongHoc(newValue) {
            this.maTthanh = newValue.maTthanh
            this.maQhuyen = newValue.maQhuyen
            this.maPxa = newValue.maPxa
            this.maDonVi = newValue.maDonVi
            this.dataUpdate = newValue

            this.selectedValue.input_maTruongHoc = newValue.maTruongHoc
            this.selectedValue.input_tenTruongHoc = newValue.tenTruongHoc
            this.selectedValue.selectedValue_capHoc = [newValue.capHoc].flatMap(
                (item) => item.split('')
            )
            this.selectedValue.selectedValue_nhomCapHoc = this.findValueInArray(
                ESelectGradeGroup_MockData,
                newValue.maNhomCapHoc
            )
            this.selectedValue.selectedValue_loaiHinhDaoTao =
                this.findValueInArray(
                    ESelectTypeOfEducation_MockData,
                    newValue.loaiHinhDaoTao
                )
            this.selectedValue.selectedValue_loaiHinhTruong =
                this.dataChonLoaihinhtruong_Store.find(
                    (item) => item.mappingCode == newValue.loaiHinhTruong
                )?.mappingCode
            this.selectedValue.date_ngayThanhLap = newValue.ngayThanhLap
            this.selectedValue.selectedValue_chuanQuocGia =
                this.dataChonDatChuanQG_Store.find(
                    (item) => item.mappingCode == newValue.chuanQuocGia
                )?.mappingCode
            this.selectedValue.input_webSite = newValue.website
            this.selectedValue.input_tenHieuTruong = newValue.tenHieuTruong
            this.selectedValue.input_email = newValue.email
            this.selectedValue.input_fax = newValue.fax
            this.selectedValue.input_diaChi = newValue.diaChi
            this.selectedValue.selectedValue_trangThai = this.findValueInArray(
                ESelectStatus_MockData_TraCuuTruongHoc,
                newValue.trangThai
            )
        }
    },
    methods: {
        handleSelectChange_quanHuyen() {
            this.getDataPhuongXa(this.selectedValue.selectedValue_quanHuyen)
            this.getDataToThon(this.selectedValue.selectedValue_quanHuyen)
        },
        handleSelectChange_PhuongXa() {
            this.getDataToThon(this.selectedValue.selectedValue_phuongXa)
        },
        async getDataTinhThanh() {
            const request_Header = {
                token: this.authToken
            }
            const request_Params = {
                start: 0,
                limit: 100
            }
            const response = await sendRequest(
                Api.traCuu.traCuuTruongHoc.getDataTinhThanh,
                null,
                request_Header,
                request_Params
            )
            const dataTinhThanh = this.convertDataToNewFormat(
                response.rows,
                'tenTinhThanh',
                'maTinhThanh'
            )
            this.getDataESelect.ESelect_tinhThanh = dataTinhThanh
            this.selectedValue.selectedValue_tinhThanh = this.findValueInArray(
                dataTinhThanh,
                this.maTthanh
            )
        },
        async getDataQuanHuyen() {
            const request_Header = {
                token: this.authToken
            }
            const request_Params = {
                start: 0,
                limit: 999,
                maTinhThanh: this.maTthanh
            }
            const response = await sendRequest(
                Api.traCuu.traCuuTruongHoc.getDataQuanHuyen,
                null,
                request_Header,
                request_Params
            )
            const dataQuanHuyen = this.convertDataToNewFormat(
                response.rows,
                'tenQuanHuyen',
                'maQuanHuyen'
            )
            this.getDataESelect.ESelect_quanHuyen = dataQuanHuyen
            this.selectedValue.selectedValue_quanHuyen = this.findValueInArray(
                dataQuanHuyen,
                this.maQhuyen
            )
        },
        async getDataPhuongXa(maQhuyen_change) {
            const maQhuyen =
                maQhuyen_change !== undefined ? maQhuyen_change : this.maQhuyen
            const request_Header = {
                token: this.authToken
            }
            const request_Params = {
                start: 0,
                limit: 999,
                maTinhThanh: this.maTthanh,
                maQuanHuyen: maQhuyen
            }
            const response = await sendRequest(
                Api.traCuu.traCuuTruongHoc.getDataPhuongXa,
                null,
                request_Header,
                request_Params
            )
            const dataPhuongXa = this.convertDataToNewFormat(
                response.rows,
                'tenPhuongXa',
                'maPhuongXa'
            )
            this.getDataESelect.ESelect_phuongXa = dataPhuongXa
            this.selectedValue.selectedValue_phuongXa = this.findValueInArray(
                dataPhuongXa,
                this.maPxa
            )
        },
        async getDataToThon(maQhuyen_change, maPxa_change) {
            const maQhuyen =
                maQhuyen_change !== undefined ? maQhuyen_change : this.maQhuyen
            const maPxa = maPxa_change !== undefined ? maPxa_change : this.maPxa
            const request_Header = {
                token: this.authToken
            }
            const request_Params = {
                limit: 999,
                maPhuongXa: maPxa,

                maQuanHuyen: maQhuyen,

                maTinhThanh: this.maTthanh,
                start: 0
            }
            const response = await sendRequest(
                Api.traCuu.traCuuTruongHoc.getToThon,
                null,
                request_Header,
                request_Params
            )
            this.getDataESelect.ESelect_toThon = response.rows
            // console.log(response)
        },
        async getDonViQuanLy() {
            const request_Header = {
                token: this.authToken
            }
            const request_Params = {
                start: 0,
                limit: 999,
                maTinhThanh: this.maTthanh
            }
            const response = await sendRequest(
                Api.traCuu.traCuuTruongHoc.getDonViQuanLy,
                null,
                request_Header,
                request_Params
            )
            const dataDonVi = this.convertDataToNewFormat(
                response.rows,
                'tenDonVi',
                'maDonVi'
            )
            this.getDataESelect.ESelect_donViQuanLy = dataDonVi
            this.selectedValue.selectedValue_donViquanLy = this.findValueInArray(
                dataDonVi,
                this.maDonVi
            )
        },

        findValueInArray(dataArray, targetValue) {
            const foundItem = dataArray.find((item) => item.value == targetValue)
            return foundItem ? foundItem.value : null
        },
        convertDataToNewFormat(dataArray, titlePropertyName, valuePropertyName) {
            return dataArray.map((item) => ({
                title: `${item[titlePropertyName]} - [${item[valuePropertyName]}]`,
                value: item[valuePropertyName]
            }))
        },

        convertDate(input_date) {
            const parts = input_date.split('/')
            const day = parseInt(parts[0], 10)
            const month = parseInt(parts[1], 10)
            const year = parseInt(parts[2], 10)

            // Tạo đối tượng Date từ ngày, tháng và năm
            const dateObject = new Date(year, month - 1, day)

            // Lấy chuỗi ISO của đối tượng Date và trả về
            return dateObject.toISOString()
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonLoaihinhtruong_Store() {
            return JSON.parse(localStorage.getItem('data_ChonLoaihinhtruong'))
        },
        dataChonDatChuanQG_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDatChuanQG'))
        }
    },
    mounted() {
        // this.getDataTinhThanh()
        // this.getDataQuanHuyen()
        // this.getDataPhuongXa()
        // this.getDonViQuanLy()
    }
}
</script>
<style scoped>
::v-deep .el-input.is-disabled .el-input__inner,
::v-deep .el-tag.el-tag--info {
    background-color: #eee !important;
    color: black !important;
}
hr {
    border: 1px solid green;
}
label {
    font-size: 14px;
    font-weight: 500;
}
label .span {
    color: red;
}

.custom-date-picker {
    height: 32px;
}
.custom-date-picker::placeholder {
    color: gainsboro;

    font-size: 13px;
    background-color: transparent;
    appearance: none;
}
.layout-btn {
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}

.btn-delete {
    background-color: var(--orange);
}
::v-deep .form-control:disabled,
.form-control[readonly] {
    font-size: 14px;

    background-color: #eee !important;
    color: black !important;
}
</style>
