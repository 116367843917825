<template>
    <div>
        <el-dialog
            :visible="themYeuCauModalVisible"
            @close="closeModalThemYeuCau"
            :fullscreen="true"
        >
            <span slot="title" class="custom-dialog-title">
                Thêm yêu cầu mới
                <hr />
            </span>

            <div class="content">
                <div class="row">
                    <div class="col-4">
                        <label
                            >Đơn vị quản lý
                            <span style="color: red">*</span></label
                        >
                        <div>
                            <ESelect
                                style="width: 100%"
                                no-match-text="Không tìm thấy bản ghi nào"
                                no-data-text="danh sách lựa chọn trống"
                                :clearable="true"
                                :disabled="false"
                                :data="this.dataChonDonVi_Store"
                                :placeholder="'Chọn đơn vị'"
                                :filterable="true"
                                :multiple="true"
                                :collapse-tags="true"
                                :fields="['tenDonVi', 'maDonVi']"
                                v-model="selectedValue.selectedValueUnitEducation"
                                @change="getDataChonTruongHoc"
                            />
                            <span
                                v-if="err_donViNopList.length > 0"
                                class="error"
                                >{{ err_donViNopList }}</span
                            >
                        </div>
                    </div>
                    <div class="col-4">
                        <label>Cấp học <span style="color: red">*</span></label>
                        <div>
                            <ESelect
                                style="width: 100%"
                                no-match-text="Không tìm thấy bản ghi nào"
                                no-data-text="danh sách lựa chọn trống"
                                :clearable="true"
                                :disabled="false"
                                :data="getDataESelect.ESelectGradeLevel_MockData"
                                :placeholder="'Chọn cấp học'"
                                :filterable="true"
                                :multiple="true"
                                :collapse-tags="true"
                                :fields="['tenTruongHoc', 'value']"
                                v-model="selectedValue.selectedValueGradeLevel"
                                @change="getDataChonTruongHoc"
                            />
                            <span
                                v-if="err_capHocNopList.length > 0"
                                class="error"
                                >{{ err_capHocNopList }}</span
                            >
                        </div>
                    </div>
                    <div class="col-4">
                        <label
                            >Trường học <span style="color: red">*</span></label
                        >
                        <div>
                            <ESelect
                                :reset="resetESelectSchool"
                                @reset-completed="handleResetSchoolCompleted"
                                style="width: 100%"
                                no-match-text="Không tìm thấy bản ghi nào"
                                no-data-text="danh sách lựa chọn trống"
                                :clearable="true"
                                :disabled="false"
                                :data="getDataESelect.ESelectSchool"
                                :placeholder="'Chọn trường học'"
                                :filterable="true"
                                :multiple="true"
                                :collapse-tags="true"
                                :fields="['title', 'value']"
                                v-model="selectedValue.selectedValueSchool"
                            />
                            <span
                                v-if="err_maTruongHocList.length > 0"
                                class="error"
                                >{{ err_maTruongHocList }}</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="row mt-4 d-flex justify-content-center align-items-center"
            >
                <div class="col-3">
                    <label>Tên báo cáo <span style="color: red">*</span></label>
                    <div>
                        <el-input
                            placeholder="Nhập tên báo cáo ..."
                            v-model="selectedValue.selectedNameReport"
                            size="small"
                        ></el-input>
                        <span v-if="err_tenBaoCao.length > 0" class="error">{{
                            err_tenBaoCao
                        }}</span>
                    </div>
                </div>

                <div class="col-3">
                    <label>Loại báo cáo <span style="color: red">*</span></label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectReportModal_MockData"
                            :placeholder="'Chọn loại báo cáo'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['tenBaoCao', 'value']"
                            v-model="selectedValue.selectedValueReportModal"
                        />
                        <span v-if="err_loaiBaoCao.length > 0" class="error">{{
                            err_loaiBaoCao
                        }}</span>
                    </div>
                </div>
            </div>

            <div
                class="row mt-4 d-flex justify-content-center align-items-center"
            >
                <div class="col-sm-6">
                    <label
                        >Thời gian áp dụng
                        <span style="color: red">*</span></label
                    >
                </div>
            </div>
            <div class="row d-flex justify-content-center align-items-center">
                <div class="col-6">
                    <div class="row">
                        <div class="col-sm-5">
                            <el-date-picker
                                v-model="selectedValue.selectedValueStartDate"
                                type="date"
                                placeholder="Ngày bắt đầu"
                                style="width: 100%"
                                format="dd/MM/yyyy"
                                size="small"
                                @input="updateEndDate"
                            >
                            </el-date-picker>
                            <span
                                v-if="err_ngayApDung.length > 0"
                                class="error"
                                >{{ err_ngayApDung }}</span
                            >
                        </div>
                        <div class="col-sm-2">
                            <CustomButton
                                label="Đến"
                                size="small"
                                style="width: 100%"
                                class="btn-date"
                            />
                        </div>
                        <div class="col-sm-5">
                            <el-date-picker
                                v-model="selectedValue.selectedValueEndDate"
                                type="date"
                                placeholder="Ngày kết thúc"
                                style="width: 100%"
                                format="dd/MM/yyyy"
                                size="small"
                            >
                            </el-date-picker>
                            <span
                                v-if="err_ngayKetThuc.length > 0"
                                class="error"
                                >{{ err_ngayKetThuc }}</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="layout-btn">
                <CustomButton
                    label="Đóng"
                    size="small"
                    @click="closeModalThemYeuCau"
                    style="
                        color: #fff;
                        font-weight: 600;
                        background-color: #e14a02;
                    "
                />
                <CustomButton
                    label="Thêm mới"
                    size="small"
                    @click="handleAddRequest"
                    style="
                        color: #fff;
                        font-weight: 600;
                        background-color: #6aab9c;
                    "
                />
            </div>
        </el-dialog>
        <!-- ************ Modal Add File ************ -->
        <el-dialog
            :visible.sync="themFileBaoCaoModalVisible"
            @close="closeModalThemFileBaoCao"
            class="custom-dialog"
            :fullscreen="true"
        >
            <span slot="title">
                THÊM FILE CHO BÁO CÁO
                <span class="title">
                    {{ dataModalThemFileBaoCao.tenBaoCao }}
                    <hr /></span
            ></span>

            <div class="row d-flex justify-content-center align-items-center">
                <div class="">
                    <label
                        class="btn btn-default button-file btn-file layout-file"
                        tabindex="0"
                        id="lblFile"
                    >
                        <i class="fa fa-cloud-upload"></i>
                        Chọn File (<span style="color: red">*</span>)
                        <div v-if="!tenFile">
                            <small>
                                <em>Tổng dung lượng không quá 10MB</em>
                            </small>
                            <br />
                            <small>
                                <em>Định dạng file hợp lệ: *.xlsx </em>
                            </small>
                        </div>
                        <div v-if="tenFile">
                            <small>
                                <em
                                    >File đã chọn:
                                    <b>{{ tenFile }}</b>
                                </em>
                            </small>
                            <br />
                        </div>
                        <input
                            ref="file_upload"
                            multiple="false"
                            name="file"
                            style="display: none"
                            type="file"
                            @change="onFileChange"
                            aria-invalid="false"
                        />
                    </label>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-12">
                    <h6>Hướng dẫn thêm file biểu mẫu hợp lệ:</h6>
                    <div class="text-center">
                        <div style="">
                            <img
                                src="../../../assets/img/excelupdate.png"
                                alt="Hình ảnh"
                                width="80%"
                                title="Hình ảnh hướng dẫn"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="layout-btn">
                <CustomButton
                    label="Đóng"
                    size="small"
                    style="
                        color: #fff;
                        font-weight: 600;
                        background-color: #e14a02;
                    "
                    @click="closeModalThemFileBaoCao"
                />
                <CustomButton
                    label="Thêm File mới"
                    size="small"
                    @click="handleAddFile(dataModalThemFileBaoCao.id)"
                    style="
                        color: #fff;
                        font-weight: 600;
                        background-color: #6aab9c;
                    "
                />
            </div>
        </el-dialog>
    </div>
</template>
<script>
import moment from 'moment'
import ESelect from '@/components/ESelect.vue'
import { mapState } from 'vuex'
import CustomButton from '@/components/CustomButton.vue'
import { ESelectGradeLevel_MockData, ESelectReport_MockData } from '@/mock_data'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { validateField } from '@/utils'
export default {
    name: 'ModalLstQuaTrinhLuong',
    props: {
        themYeuCauModalVisible: {
            type: Boolean,
            required: true
        },

        closeModalThemYeuCau: {
            type: Function,
            required: true
        }
    },
    watch: {
        themYeuCauModalVisible(newValue) {
            if (newValue === true) {
                this.getDataChonTruongHoc()
            }
        }
    },
    components: { CustomButton, ESelect },
    data() {
        return {
            themFileBaoCaoModalVisible: false,
            dataModalThemFileBaoCao: {},
            selected_file: null,
            tenFile: '',
            resetESelectSchool: false,
            getDataESelect: {
                ESelectUnitEducation: [], //donvi
                ESelectGradeLevel_MockData: ESelectGradeLevel_MockData, //caphoc
                ESelectSchool: [], //truonghoc
                ESelectReportModal_MockData: ESelectReport_MockData
            },

            selectedValue: {
                selectedValueUnitEducation: [], //donvi
                selectedValueGradeLevel: [], //caphoc
                selectedValueSchool: [], //truonghoc
                selectedNameReport: '', //tenbaocao
                selectedValueReportModal: [], //loaibaocao
                selectedValueStartDate: '', //ngaybatdau
                selectedValueEndDate: '' //ngayketthuc
            },

            err_donViNopList: '',

            err_capHocNopList: '',
            err_maTruongHocList: '',
            err_loaiBaoCao: '',

            err_tenBaoCao: '',

            err_ngayApDung: '',

            err_ngayKetThuc: ''
        }
    },

    methods: {
        resetErr() {
            this.err_capHocNopList = ''
            this.err_donViNopList = ''
            this.err_loaiBaoCao = ''
            this.err_maTruongHocList = ''
            this.err_ngayApDung = ''
            this.err_ngayKetThuc = ''
            this.err_tenBaoCao = ''
        },
        handleResetSchoolCompleted() {
            this.resetESelectSchool = false
        },
        closeModalThemFileBaoCao() {
            this.themFileBaoCaoModalVisible = false
        },
        updateEndDate() {
            if (this.selectedValue.selectedValueStartDate) {
                const newEndDate = moment(
                    this.selectedValue.selectedValueStartDate
                ).add(10, 'days')
                this.selectedValue.selectedValueEndDate = newEndDate
            }
        },
        async handleAddFile(id) {
            const selected_file = this.selected_file
            if (!selected_file) {
                this.$message({
                    message: 'File chưa chọn',
                    type: 'warning'
                })
                return
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const userId = this.authUser.username

                const roleId = this.authUser.role

                const maSo = this.authUser.ma_so
                const request_Header = {
                    'Content-Type': 'multipart/form-data',
                    token: this.authToken
                }
                await this.$confirm('Bạn có muốn thêm File', 'Xác nhận', {
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy',
                    type: 'warning'
                })
                const formData = new FormData()
                formData.append('file', selected_file)
                formData.append('id', id)
                formData.append('userId', userId)
                formData.append('roleId', roleId)
                formData.append('maSo', maSo)
                formData.append('maDonVi', maSo)

                const response = await sendRequest(
                    Api.baoCao.nopThongKeTheoBieuMau.themMoiFile,
                    formData,
                    request_Header
                )

                if (response.rc == 0) {
                    setTimeout(() => {
                        loading.close()
                        this.$message({
                            message: 'Thêm mới File thành công',
                            type: 'success'
                        })
                    }, 1000)
                    this.openModalConFirm()
                    loading.close()
                }
            } catch (error) {
                loading.close()
                console.log(error)
            }
        },
        onFileChange(e) {
            const files = e.target.files
            this.tenFile = ''

            this.selected_file = files[0]

            if (files && files[0]) {
                this.file_name = e.target.files[0].name
                this.tenFile = this.file_name
            }
        },
        async handleAddRequest() {
            const donViNopList = this.convertToValueArray(
                this.selectedValue.selectedValueUnitEducation
            )

            const capHocNopList = this.convertToValueArray(
                this.selectedValue.selectedValueGradeLevel
            ).map((item) => Number(item))
            const maTruongHocList = this.convertToValueArray(
                this.selectedValue.selectedValueSchool
            )
            const loaiBaoCao = parseInt(
                this.selectedValue.selectedValueReportModal.value
            )
            const tenBaoCao = this.selectedValue.selectedNameReport
            const ngayApDung = moment(
                this.selectedValue.selectedValueStartDate
            ).format('DD/MM/YYYY HH:mm:ss')

            const ngayKetThuc = moment(
                this.selectedValue.selectedValueEndDate
            ).format('DD/MM/YYYY HH:mm:ss')

            const fieldsToValidate = [
                {
                    name: 'err_donViNopList',
                    value: donViNopList,
                    errorField: 'err_donViNopList',
                    errorMessage: 'Vui lòng chọn đơn vị quản lý'
                },
                {
                    name: 'err_capHocNopList',
                    value: capHocNopList,
                    errorField: 'err_capHocNopList',
                    errorMessage: 'Vui lòng chọn cấp học'
                },
                {
                    name: 'err_maTruongHocList',
                    value: maTruongHocList,
                    errorField: 'err_maTruongHocList',
                    errorMessage: 'Vui lòng chọn trường học'
                },
                {
                    name: 'err_loaiBaoCao',
                    value: this.selectedValue.selectedValueReportModal.value,
                    errorField: 'err_loaiBaoCao',
                    errorMessage: 'Vui lòng chọn loại báo cáo'
                },
                {
                    name: 'err_tenBaoCao',
                    value: tenBaoCao,
                    errorField: 'err_tenBaoCao',
                    errorMessage: 'Vui lòng nhập tên báo cáo '
                },
                {
                    name: 'err_ngayApDung',
                    value: this.selectedValue.selectedValueStartDate,
                    errorField: 'err_ngayApDung',
                    errorMessage: 'Vui lòng chọn ngày bắt đầu'
                },
                {
                    name: 'err_ngayKetThuc',
                    value: this.selectedValue.selectedValueEndDate,
                    errorField: 'err_ngayKetThuc',
                    errorMessage: 'Vui lòng chọn ngày kết thúc'
                }
            ]
            let isValid = true

            for (const field of fieldsToValidate) {
                isValid = validateField(this, field, isValid)
            }
            if (isValid) {
                await this.$confirm(
                    'Bạn có chắc chắn muốn thêm mới không?',
                    'Xác nhận',
                    {
                        confirmButtonText: 'Đồng ý',
                        cancelButtonText: 'Hủy',
                        type: 'warning'
                    }
                )
                const request_Header = {
                    token: this.authToken
                }
                const request_Data = {
                    capHocNopList: capHocNopList,
                    donViNopList: donViNopList,
                    loaiBaoCao: loaiBaoCao,
                    maTruongHocList: maTruongHocList,
                    ngayApDung: ngayApDung,
                    ngayKetThuc: ngayKetThuc,
                    tenBaoCao: tenBaoCao
                }
                const response = await sendRequest(
                    Api.baoCao.nopThongKeTheoBieuMau.themMoiBieuMau,
                    request_Data,
                    request_Header,
                    null
                )
                if (response.rc == 0) {
                    this.$message({
                        message: 'Thêm mới thành công',
                        type: 'success'
                    })
                    this.themFileBaoCaoModalVisible = true
                    this.dataModalThemFileBaoCao = response.item
                    this.resetErr()
                } else {
                    this.$message.error('Thêm mới thất bại')
                }
            }
        },
        async getDataChonTruongHoc() {
            const maDonVi = this.convertToValueArray(
                this.selectedValue.selectedValueUnitEducation
            )

            const capHoc = this.convertToValueArray(
                this.selectedValue.selectedValueGradeLevel
            )?.map(Number)
            if (capHoc.length !== 0 || maDonVi.length !== 0) {
                this.resetESelectSchool = true
            }
            const request_Header = {
                token: this.authToken
            }

            const request_Data = {
                capHoc: capHoc,
                maDonVi: maDonVi
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            let response = await sendRequest(
                Api.internal_api.dm_School,
                request_Data,
                request_Header,
                null
            )

            if (response.statusResponse == 0) {
                loading.close()
                this.getDataESelect.ESelectSchool = response.rows.map((item) => ({
                    title: `${item.tenTruongHoc} - [${item.maTruongHoc}]`,
                    value: item.maTruongHoc
                }))
            }
        },
        openModalConFirm() {
            this.$confirm(
                'Import file thành công. Tiến hành làm mới dữ liệu?',
                'Warning',
                {
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy',
                    type: 'warning'
                }
            )
                .then(() => {
                    location.reload()
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Hủy'
                    })
                })
        },
        convertToValueArray(array) {
            return array.map((item) => item.value)
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        }
    }
}
</script>
<style scoped>
hr {
    border: 1px solid #c0c4cc;
}
.layout-file {
    background-color: var(--teal);
    border-radius: 8px;
    padding: 5px 5px 5px 5px;
}
.input-group-addon {
    padding: 8px 19px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
}
.layout-btn {
    margin-top: 130px;
    display: flex;
    justify-content: flex-end;
}
.layout-btn {
    display: flex;
    justify-content: flex-end;
}
.title {
    font-size: 24px;
    font-weight: 500;
}
.col-sm-5 {
    max-width: 45%;
    flex: 0 0 45%;
}
.col-sm-2 {
    max-width: 10%;
    flex: 0 0 10%;
    padding-left: 0px;
    padding-right: 0px;
}
.btn-date {
    background-color: #80808073;
    color: #fff;
}
</style>
