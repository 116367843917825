<template>
    <div class="school">
        <div class="header">
            <div class="left">
                <div>
                    <el-icon name="s-custom" class="icon"></el-icon>
                </div>
                <div class="title">Học sinh</div>
            </div>
            <div class="right">
                <div>
                    <i class="el-icon-timer"></i>
                </div>
                <div>
                    Thời gian cập nhật:
                    <span class="time">{{ dataHocSinh?.lastUpdate }}</span>
                    <CustomButton
                        label="Chi tiết"
                        size="small"
                        class="custom-btn"
                        @click="handleNavigate"
                    />
                </div>
            </div>
        </div>

        <div class="layout-card" v-show="dataHocSinh !== null">
            <div class="row">
                <div class="col-md-4 mb-4">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">
                                Tổng quan
                                <span class="span">
                                    (Đang học:

                                    {{ getTotalHocSinh() }} / Tổng số
                                    {{ dataHocSinh?.toanTruong?.tongSoHocSinh }}
                                    )</span
                                >
                            </div>
                        </div>
                        <div class="card-body">
                            <PieChartCustom
                                :data_PieChart="dataHocSinh?.toanTruong?.tongQuan"
                                :xaxis_categories="xaxisCategories.gioiTinh"
                            />
                        </div>
                    </div>
                </div>

                <div
                    class="col-md-4 mb-4"
                    v-show="findHocSinhName(dataHocSinh, 'MN')"
                >
                    <div class="card">
                        <div class="card-header">
                            <div class="title">
                                HỌC SINH {{ findHocSinhName(dataHocSinh, 'MN') }}
                            </div>
                        </div>
                        <div class="card-body">
                            <StackedColumnChart
                                :data_StackedColumnChart="hocSinhTheoCap('MN')"
                                :xaxis_categories="xaxisCategories.mauGiao"
                            />
                        </div>
                    </div>
                </div>
                <div
                    class="col-md-4 mb-4"
                    v-show="findHocSinhName(dataHocSinh, 'TH')"
                >
                    <div class="card">
                        <div class="card-header">
                            <div class="title">
                                HỌC SINH {{ findHocSinhName(dataHocSinh, 'TH') }}
                            </div>
                        </div>
                        <div class="card-body">
                            <StackedColumnChart
                                :data_StackedColumnChart="hocSinhTheoCap('TH')"
                                :xaxis_categories="xaxisCategories.cap1"
                            />
                        </div>
                    </div>
                </div>
                <div
                    class="col-md-4 mb-4"
                    v-show="findHocSinhName(dataHocSinh, 'THCS')"
                >
                    <div class="card">
                        <div class="card-header">
                            <div class="title">
                                HỌC SINH
                                {{ findHocSinhName(dataHocSinh, 'THCS') }}
                            </div>
                        </div>
                        <div class="card-body">
                            <StackedColumnChart
                                :data_StackedColumnChart="hocSinhTheoCap('THCS')"
                                :xaxis_categories="xaxisCategories.cap2"
                            />
                        </div>
                    </div>
                </div>
                <div
                    class="col-md-4 mb-4"
                    v-show="findHocSinhName(dataHocSinh, 'THPT')"
                >
                    <div class="card">
                        <div class="card-header">
                            <div class="title">
                                HỌC SINH
                                {{ findHocSinhName(dataHocSinh, 'THPT') }}
                            </div>
                        </div>
                        <div class="card-body">
                            <StackedColumnChart
                                :data_StackedColumnChart="hocSinhTheoCap('THPT')"
                                :xaxis_categories="xaxisCategories.cap3"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Router } from '@/constants/Router'
import CustomButton from '@/components/CustomButton.vue'

import StackedColumnChart from '@/components/StackedColumnChart.vue'
import PieChartCustom from '@/components/PieChartCustom.vue'

export default {
    name: 'educatorProfile',
    components: {
        CustomButton,

        StackedColumnChart,
        PieChartCustom
    },
    data() {
        return {
            xaxisCategories: {
                gioiTinh: ['Nam', 'Nữ'],
                mauGiao: [
                    '3-12 tháng',
                    '13-24 tháng',
                    '25-36 tháng',
                    '3-4 tuổi',
                    '4-5 tuổi',
                    '5-6 tuổi'
                ],
                cap1: ['Khối 1', 'Khối 2', 'Khối 3', 'Khối 4', 'Khối 5'],
                cap2: ['Khối 6', 'Khối 7', 'Khối 8', 'Khối 9'],
                cap3: ['Khối 10', 'Khối 11', 'Khối 12']
            }
        }
    },
    props: {
        dataHocSinh: {
            required: true
        }
    },

    methods: {
        handleNavigate() {
            this.$router.push(Router.traCuu.traCuuHocSinh)
        },
        hocSinhTheoCap(name) {
            const array = this.dataHocSinh?.toanTruong?.hocSinhTheoCap.find(
                (item) => item.name === name
            )
            if (array) {
                return array.data.filter((arr) => arr.name)
            }
            return []
        },
        findHocSinhName(dataLopHoc, maLop) {
            const lopHoc = dataLopHoc?.toanTruong?.hocSinhTheoCap.find(
                (lop) => lop.name === maLop
            )
            return lopHoc ? lopHoc.name : ''
        },
        getTotalHocSinh() {
            return this.dataHocSinh?.toanTruong?.tongQuan?.reduce(
                (accumulator, currentValue) => {
                    return accumulator + currentValue
                },
                0
            )
        }
    }
}
</script>

<style scoped>
.school {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 15px;
    padding-left: 6px;
    padding-right: 6px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 10px 12px 10px;
}
.header .left,
.header .right {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #186ab2;
    font-weight: 700;
    text-transform: uppercase;
}
.header .right .time {
    color: #333;
}
.header .layout-icon {
    margin-right: 8px;
}
.header .title .span {
    color: #333;
    font-weight: 600;
}
.header .left .icon {
    margin-right: 5px;
}
.header .right .icon {
    font-size: 18px;
    color: #000000;
    font-weight: 700;
    margin-right: 5px;
}

.header .right {
    margin-left: 6px;
}

.layout-card {
    padding: 0px 10px 20px 10px;
}
.layout-card .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f2f3f8;
    height: 25px;
    color: black;
    padding: 5px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.layout-card .card-header .title {
    margin: -5px 0px 0px 10px;
    color: #000000;
    font-weight: 700;
    font-size: 15px;
}
.layout-card .card-header .title .span {
    color: #2e93fa;
}
.layout-card .card-body {
    padding: 5px;
    background: white;
    border: #f2f3f8 2px solid;
    height: 300px;
}
.custom-btn {
    font-weight: 700;
    color: #fff;
    background-color: #6aab9c;
}
::v-deep .el-icon-school:before {
    font-size: 18px;
    color: #2e8bc0;
    content: '\e756';
}
</style>
