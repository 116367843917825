<template>
    <el-dialog
        :visible="editModalVisible"
        @close="closeModalEdit"
        class="custom-dialog"
        width="50%"
    >
        <span slot="title" class="custom-dialog-title">
            CHỈNH SỬA TÀI KHOẢN KẾT CHUYỂN LÊN BỘ
            <hr />
        </span>

        <div class="row">
            <div class="col-6">
                <label class="custom-title">Trường học</label>
                <el-input
                    placeholder="Tên trường học"
                    v-model="inputTruongHoc_edit"
                    :disabled="true"
                    size="small"
                >
                </el-input>
            </div>
            <div class="col-6">
                <label class="custom-title">Tên đăng nhập</label>
                <el-input
                    placeholder="Tên đăng nhập"
                    v-model="inputUserName_edit"
                    size="small"
                ></el-input>
            </div>
            <div class="col-6 mt-3">
                <label class="custom-title">Mật khẩu</label>
                <el-input
                    placeholder="Mật khẩu"
                    v-model="inputPassword_edit"
                    size="small"
                    show-password
                ></el-input>
            </div>
        </div>
        <div style="margin-top: 70px"></div>
        <hr />
        <div class="layout-table-modal">
            <CustomButton
                label="Đóng"
                size="small"
                @click="closeModalEdit"
                class="btn-default btn-close-modal"
            />
            <CustomButton
                label="Chỉnh sửa"
                size="small"
                type="warning"
                @click="handleEdit"
                class="btn-default btn-update"
            />
        </div>
    </el-dialog>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import Api from '@/constants/Api'
import sendRequest from '@/services'

import { mapState } from 'vuex'

export default {
    props: {
        editModalVisible: {
            type: Boolean,
            required: true
        },

        closeModalEdit: {
            type: Function,
            required: true
        },
        dataModalEdit: {
            type: Object,
            required: true
        },
        handleSearch: Function
    },

    components: { CustomButton },
    data() {
        return {
            inputTruongHoc_edit: '',
            inputUserName_edit: '',
            inputPassword_edit: ''
        }
    },
    watch: {
        dataModalEdit(newValue) {
            this.inputTruongHoc_edit = newValue.tenTruong
            this.inputUserName_edit = newValue.tenDangNhap
            this.inputPassword_edit = newValue.matKhau
        }
    },
    methods: {
        async handleEdit() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })

            const maTruong = this.dataModalEdit.maTruong
            const userName = this.inputUserName_edit
            const passWord = this.inputPassword_edit
            const request_Header = {
                token: this.authToken
            }

            const request_Data = {
                maTruong: maTruong,
                matKhauKetChuyen: passWord,
                tenDangNhap: userName
            }

            this.$confirm('Xác nhận chỉnh sửa?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const response = await sendRequest(
                        Api.ketChuyenDuLieuLenBo.matKhauKetChuyen
                            .editMatKhauKetChuyen,
                        request_Data,
                        request_Header,
                        null
                    )
                    if (response.statusResponse == 0) {
                        loading.close()
                        this.$message({
                            message: 'Thay đổi thành công',
                            type: 'success'
                        })

                        this.closeModalEdit()
                        this.$emit('handleSearch')
                    } else {
                        this.$message({
                            message: 'Thay đổi thất bại',
                            type: 'error'
                        })
                    }
                })
                .catch((error) => {
                    loading.close()
                    if (error.response?.data.rd) {
                        this.$message({
                            type: 'error',
                            message: error.response?.data.rd
                        })
                    }
                })
        }
    },

    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped>
::v-deep .el-input.is-disabled .el-input__inner {
    background-color: #eee !important;
    color: black !important;
}
.layout-table-modal {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}
.custom-title {
    font-weight: 700;
    color: black;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}

.btn-update {
    background-color: var(--yellow);
}
.btn-close-modal {
    background-color: var(--orange);
}
</style>
