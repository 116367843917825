const METHOD = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE'
}

const Api = {
    auth: {
        login: {
            method: METHOD.POST,
            url: 'csdlgd-user/auth/login'
        },
        register: {
            method: METHOD.POST,
            url: 'auth/register'
        },
        getMaCaptCha: {
            method: METHOD.POST,
            url: 'csdlgd-user/auth/getCaptcha'
        },
        loginSSo: {
            method: METHOD.GET,
            url: 'csdlgd-user/adminUser/getSSOHash'
        },
        tokenSSo: {
            method: METHOD.GET,
            url: 'csdlgd-user/adminUser/getSsoAccessToken'
        }
    },
    internal_api: {
        dm_DonVi: {
            method: METHOD.GET,
            url: 'internal-api/dmDonVi/filter'
        },
        dm_School: {
            method: METHOD.POST,
            url: 'internal-api/dmTruongHoc/filterSchool'
        }
    },
    thongKeTangGiam: {
        method: METHOD.POST,
        url: 'csdlgd-report-api/dasboard/thongketanggiam'
    },
    bieuDoTruongHoc: {
        bieuDoTongQuan: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/truongTron'
        },
        bieuDoChatLuongDaoTao: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/truonghocchatluong'
        },
        bietDoLoaiHinhDaoTao: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/truonghocloaihinhdaotao'
        }
    },
    bieuDoHocSinh: {
        bieuDoTongQuan: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/hocsinh'
        },
        bieuDoTrangThai: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/hocsinhtrangthai'
        },
        bieuDoGioiTinh: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/hocsinhgioitinh'
        },
        bieuDoLoaiHinhDaoTao: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/hocsinhloaihinhdaotao'
        },
        bieuDoKhuVuc: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/hocsinhkhuvuc'
        }
    },
    bieuDoCanBoGiaoVienNhanVien: {
        bieuDoTongQuan: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/canbogvcaphoc'
        },
        bieuDoTrinhDoChinh: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/canbogvtrinhdochinh'
        },
        bieuDoGioiTinh: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/canbogvgioitinh'
        },
        bieuDoLoaiHopDong: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/canbogvloaiHD'
        }
    },
    bieuDoPhanLoaiCanBo: {
        bieuDoTongQuan: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/phanLoaiCanBo'
        },
        bieuDoDoTuoi: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/canbogvloaidotuoi'
        },
        bieuDoLoaiHinhDaoTao: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/canbogvloaihinhdaotao'
        },
        bieuDoTrangThai: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/canbogvtrangthai'
        }
    },
    bieuDoPhoDiem: {
        bieuDoDanhGiaHocSinh: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/danhgiahocsinh'
        },
        bieuDoPhoDiemHocKy: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/ketquahoctap'
        }
    },
    bieuDoThongKeTheoNam: {
        bieuDoLopHocTheoNam: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/ioc-dashboard/lop-hoc-theo-nam'
        },
        bieuDoGiaoVienTheoNam: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/ioc-dashboard/gv-theo-nam'
        },
        bieuDoHocSinhTheoNam: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/ioc-dashboard/hs-theo-nam'
        },
        bieuDoHocSinhHocLuc: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/ioc-dashboard/hs-theo-nam-hoc-luc'
        },
        bieuDoGiaoVienTrinhDo: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/ioc-dashboard/gv-nam-trinh-do'
        }
    },
    tiepNhanDuLieuQLNT: {
        cauHinhLichDongBo: {
            capSo: {
                method: METHOD.GET,
                url: 'csdlgd-admin/yeuCauDongBo/list'
            },
            toggleStatus: {
                method: METHOD.POST,
                url: 'csdlgd-admin/yeuCauDongBo/toggleStatus'
            },
            themMoiNgay: {
                method: METHOD.POST,
                url: 'csdlgd-admin/yeuCauDongBo'
            },
            thayDoiNgay: {
                method: METHOD.PUT,
                url: 'csdlgd-admin/yeuCauDongBo'
            },
            capTruong: {
                method: METHOD.POST,
                url: 'csdlgd-admin/yeuCauDuLieuTruong/chdbdl'
            },
            toggleStatus_CapTruong: {
                method: METHOD.PUT,
                url: 'csdlgd-admin/yeuCauDuLieuTruong/chdbdl'
            },
            thayDoiNgay_CapTruong: {
                method: METHOD.PUT,
                url: 'csdlgd-admin/yeuCauDuLieuTruong/chdbdl'
            },
            themMoiLichDongBo_CapTruong: {
                method: METHOD.POST,
                url: 'csdlgd-admin/yeuCauDuLieuTruong/chdbdl-truong'
            },
            exportExcel: {
                method: METHOD.POST,
                url: 'csdlgd-report-api/bao-cao-tinh-hinh-nop-du-lieu/excel'
            }
        },
        baoCaoTinhHinhNopDuLieu: {
            capSo: {
                method: METHOD.POST,
                url: 'csdlgd-report-api/bao-cao-tinh-hinh-nop-du-lieu/theo-don-vi'
            },
            capTruong: {
                method: METHOD.POST,
                url: 'csdlgd-report-api/bao-cao-tinh-hinh-nop-du-lieu'
            }
        },
        lichSuTiepNhanDuLieu: {
            heThongDoiTac: {
                method: METHOD.GET,
                url: 'internal-api/heThongDoiTac/getMaDoiTacCha'
            },
            lichSuTiepNhanDuLieu: {
                method: METHOD.POST,
                url: 'csdlgd-report-api/history-syncdata/school'
            },
            chiTiet: {
                method: METHOD.GET,
                url: 'csdlgd-report-api/history-syncdata/school'
            },
            ketQuaNhan_Chitiet: {
                method: METHOD.GET,
                url: 'csdlgd-report-api/history-syncdata/school/detail-response'
            }
        }
    },
    ketChuyenDuLieuLenBo: {
        matKhauKetChuyen: {
            danhSachMatKetChuyen: {
                method: METHOD.POST,
                url: 'csdlgd-push/KetChuyenDL/filterMatKhauKc'
            },
            themMoiMatKhauKetChuyen: {
                method: METHOD.POST,
                url: 'csdlgd-push/KetChuyenDL/createMKKC'
            },
            editMatKhauKetChuyen: {
                method: METHOD.POST,
                url: 'csdlgd-push/KetChuyenDL/updatePassword'
            },
            importFile: {
                method: METHOD.POST,
                url: 'csdlgd-push/KetChuyenDL/importMatKhau'
            }
        },
        cauHinhDuyetDayDuLieuLenBo: {
            danhSachCauHinhDayDuLieuLenBo: {
                method: METHOD.POST,
                url: 'csdlgd-admin/duyetDayDuLieu/danhSach'
            },
            xoaDuLieu: (id) => {
                return {
                    method: METHOD.POST,
                    url: `csdlgd-admin/duyetDayDuLieu/delete/${id}`
                }
            },
            duyetDayDuLieu: {
                method: METHOD.POST,
                url: 'csdlgd-admin/duyetDayDuLieu/taoCauHinh'
            }
        },
        ketChuyenDuLieu: {
            danhSachKetChuyenDuLieu: {
                method: METHOD.POST,
                url: 'csdlgd-admin/yeuCauDuLieuTruong/search2'
            },
            xuatExcel: {
                method: METHOD.POST,
                url: 'csdlgd-admin/ketChuyenDuLieu/excel'
            },
            xoaDuLieu: {
                method: METHOD.POST,
                url: 'csdlgd-admin/DataTruong/delete'
            },
            dayDuLieu: {
                method: METHOD.POST,
                url: 'csdlgd-admin/dayLenBo/taoYeuCau'
            },
            tuChoi: {
                method: METHOD.POST,
                url: 'csdlgd-admin/duyetDay/updateDdlSo'
            },
            dayLenBo: {
                method: METHOD.POST,
                url: 'csdlgd-admin/duyetDay/updateDdlPhong'
            },
            dayLenPhong: {
                method: METHOD.POST,
                url: 'csdlgd-admin/duyetDay/updateDdlTruong'
            }
        },
        lichSuKetChuyen: {
            duLieuDoiTac: {
                method: METHOD.GET,
                url: 'internal-api/heThongDoiTac/filter'
            },
            danhSachLichSuKetChuyen: {
                method: METHOD.POST,
                url: 'csdlgd-push/LichSuDay/DanhSachTruong'
            },
            updateDayLai: {
                method: METHOD.POST,
                url: 'csdlgd-admin/dayLenBo/updateDayLai'
            },
            xuatExcel: {
                method: METHOD.POST,
                url: 'csdlgd-push/Export/dayDuLieuLenBo'
            },
            chiTietLoiDongBo: {
                method: METHOD.POST,
                url: 'csdlgd-push/LichSuDay/detail'
            },
            danhMucLoi: {
                method: METHOD.GET,
                url: 'csdlgd-admin/MoetErrorDev'
            }
        }
    },
    baoCao: {
        baoCaoEMIS: {
            danhSachBaoCao: {
                method: METHOD.GET,
                url: 'csdlgd-report-api/danhSachBaoCao'
            }
        },
        baoCaoEQMS: {
            danhSachBaoCao: {
                method: METHOD.GET,
                url: 'csdlgd-report-api/danhSachBaoCao'
            }
        },
        baoCaoChoSo: {
            danhSachBaoCao: {
                method: METHOD.GET,
                url: 'csdlgd-report-api/danhSachBaoCao'
            },
            xemBaoCao: {
                method: METHOD.POST,
                url: 'csdlgd-report-api/download-excel/get-report-link'
            }
        },
        baoCaoDong: {
            duLieuDoiTac: {
                method: METHOD.GET,
                url: 'internal-api/heThongDoiTac/filter'
            },
            baoCaoCanBo: {
                danhSach: {
                    method: METHOD.POST,
                    url: 'csdlgd-report-fetch/BaoCaoGiaoVien/sreach'
                },
                xuatExcel: {
                    method: METHOD.POST,
                    url: 'csdlgd-report-fetch/BaoCaoDongExcel/exportGiaoVien'
                },
                luuMau: {
                    method: METHOD.POST,
                    url: 'csdlgd-report-fetch/template/save'
                },
                taiMau: {
                    method: METHOD.POST,
                    url: 'csdlgd-report-fetch/template/search'
                },
                xoaMau: (id) => {
                    return {
                        method: METHOD.POST,
                        url: `csdlgd-report-fetch/template/delete/${id}`
                    }
                }
            },
            baoCaoHocSinh: {
                danhSach: {
                    method: METHOD.POST,
                    url: 'csdlgd-report-fetch/BaoCaoHocSinh/sreach'
                },
                xuatExcel: {
                    method: METHOD.POST,
                    url: 'csdlgd-report-fetch/BaoCaoDongExcel/exportHocSinh'
                },
                luuMau: {
                    method: METHOD.POST,
                    url: 'csdlgd-report-fetch/template/save'
                },
                taiMau: {
                    method: METHOD.POST,
                    url: 'csdlgd-report-fetch/template/search'
                },
                xoaMau: (id) => {
                    return {
                        method: METHOD.POST,
                        url: `csdlgd-report-fetch/template/delete/${id}`
                    }
                }
            },
            baoCaoTruongHoc: {
                danhSach: {
                    method: METHOD.POST,
                    url: 'csdlgd-report-fetch/BaoCaoTruongHoc/search'
                },
                xuatExcel: {
                    method: METHOD.POST,
                    url: 'csdlgd-report-fetch/BaoCaoDongExcel/exportTruongHoc'
                },
                luuMau: {
                    method: METHOD.POST,
                    url: 'csdlgd-report-fetch/template/save'
                },
                taiMau: {
                    method: METHOD.POST,
                    url: 'csdlgd-report-fetch/template/search'
                },
                xoaMau: (id) => {
                    return {
                        method: METHOD.POST,
                        url: `csdlgd-report-fetch/template/delete/${id}`
                    }
                }
            }
        },
        convertBase64: {
            method: METHOD.GET,
            url: 'csdlgd-report-api/download-excel/get-base64'
        },
        nopThongKeTheoBieuMau: {
            danhSachBieuMau: {
                method: METHOD.POST,
                url: 'csdlgd-admin/nop-bieu-mau/filter'
            },
            themMoiBieuMau: {
                method: METHOD.POST,
                url: 'csdlgd-admin/nop-bieu-mau/create'
            },
            themMoiFile: {
                method: METHOD.POST,
                url: 'csdlgd-admin/nop-bieu-mau/upload'
            },
            thaoTacDuLieu: {
                method: METHOD.POST,
                url: 'csdlgd-admin/nop-bieu-mau/action'
            },
            tongHopDuLieu: {
                method: METHOD.POST,
                url: 'csdlgd-admin/nop-bieu-mau/tongHop-download'
            },
            updateNopBieuMau: {
                method: METHOD.POST,
                url: 'csdlgd-admin/nop-bieu-mau/update'
            },
            getDuLieu: {
                method: METHOD.GET,
                url: 'csdlgd-admin/nop-bieu-mau/findOne'
            },
            uploadFileRoleTruong: {
                method: METHOD.POST,
                url: 'csdlgd-admin/nop-bieu-mau/truong-nop-bc'
            },
            fileDaNop: {
                method: METHOD.POST,
                url: 'csdlgd-admin/nop-bieu-mau/download-truong'
            }
        }
    },
    traCuu: {
        danhMuc: {
            method: METHOD.POST,
            url: 'csdlgd-admin/sme/getDanhSachSme'
        },
        traCuuHocSinh: {
            danhSachTraCuuHocSinh: {
                method: METHOD.POST,
                url: 'csdlgd-admin/hocSinh/search3'
            },
            xoaHocSinh: {
                method: METHOD.POST,
                url: 'csdlgd-admin/hocSinh/delete'
            },
            dayDuLieu: {
                method: METHOD.POST,
                url: 'csdlgd-admin/dayLenBo/taoYeuCau'
            },
            xoaMaDinhDanhLenBo: {
                method: METHOD.POST,
                url: 'csdlgd-admin/DataTruong/deleteMoetCode'
            },
            chiTietHocSinh: {
                method: METHOD.GET,
                url: 'csdlgd-admin/hocSinh/infor'
            },
            danhSachLopHoc_HocSinh: {
                method: METHOD.GET,
                url: 'csdlgd-admin/lopHoc/findGroupMaLopHoc'
            },
            updateHocSinh: {
                method: METHOD.POST,
                url: 'csdlgd-admin/hocSinh/update'
            },
            chuyenTruong: {
                method: METHOD.POST,
                url: 'csdlgd-admin/chuyen-truong/internal/hoc-sinh'
            }
        },
        thongTinYTeHocSinh: {
            danhSachLopHoc: {
                method: METHOD.POST,
                url: 'csdlgd-admin/lopHoc/search2'
            },
            danhSachThongTinYTe: {
                method: METHOD.POST,
                url: 'csdlgd-report-api/download-excel/get-report-thong-tin-suc-khoe'
            }
        },
        traCuuKQHTTT22: {
            downLoadExcel: {
                method: METHOD.POST,
                url: 'csdlgd-admin/export-excel/hoc-sinh-tt26'
            }
        },
        traCuuLopHoc: {
            danhSachLopHoc: {
                method: METHOD.POST,
                url: 'csdlgd-admin/lopHoc/search3'
            },
            xoaLopHoc: {
                method: METHOD.POST,
                url: 'csdlgd-admin/lopHoc/delete'
            },
            thongTinChiTietLophoc: {
                method: METHOD.GET,
                url: 'csdlgd-admin/lopHoc/infor'
            },
            danhSachGiaoVienChuNhiem: {
                method: METHOD.GET,
                url: 'csdlgd-admin/giaoVien/danhSachGvcn'
            },
            updateLopHoc: {
                method: METHOD.POST,
                url: 'csdlgd-admin/lopHoc/update'
            },
            diemTruongTheoNam: {
                method: METHOD.GET,
                url: 'csdlgd-admin/diemTruongTheoNam/findData'
            },
            xuatExcel: {
                method: METHOD.POST,
                url: 'csdlgd-admin/ExportExcel/SreachLopHoc2'
            }
        },
        traCuuCanBoGiaoVien: {
            danhSachCanBoGv: {
                method: METHOD.POST,
                url: 'csdlgd-admin/giaoVien/search3'
            },
            xoaCanBoGv: {
                method: METHOD.POST,
                url: 'csdlgd-admin/giaoVien/delete'
            },
            chitietCanboGv: {
                method: METHOD.GET,
                url: 'csdlgd-admin/giaoVien/infor'
            },
            updateCanBoGv: {
                method: METHOD.POST,
                url: 'csdlgd-admin/giaoVien/update'
            },
            getLstQuaTrinh: {
                method: METHOD.POST,
                url: 'csdlgd-admin/giaoVien/lstQuaTrinh'
            },
            danhSachLopHoc: {
                method: METHOD.GET,
                url: 'csdlgd-admin/lopMon/byMaGiaoVien'
            },
            danhSachMonHoc: {
                method: METHOD.GET,
                url: 'csdlgd-admin/lopMon/byMaLopHoc'
            },
            danhSachPhanCongGiangDay: {
                method: METHOD.GET,
                url: 'csdlgd-admin/lopMonGiaoVien/danhSach'
            },
            updatePhanCongGiangDay: {
                method: METHOD.POST,
                url: 'csdlgd-admin/lopMonGiaoVien/update'
            },
            createPhanCongGiangDay: {
                method: METHOD.POST,
                url: 'csdlgd-admin/lopMonGiaoVien/create'
            },
            deletePhanCongGiangDay: {
                method: METHOD.POST,
                url: 'csdlgd-admin/lopMonGiaoVien/delete'
            },
            chuyenTruong: {
                method: METHOD.POST,
                url: 'csdlgd-admin/chuyen-truong/internal/giao-vien'
            }
        },
        traCuuTruongHoc: {
            danhSachTruongHoc: {
                method: METHOD.GET,
                url: 'internal-api/dmTruongHoc/filter'
            },
            xoaTruongHoc: {
                method: METHOD.DELETE,
                url: 'internal-api/dmTruongHoc/delete'
            },
            layDiemTruongHoc: {
                method: METHOD.POST,
                url: 'csdlgd-admin/diemTruongTheoNam/finds'
            },
            xoaDiemTruongHoc: {
                method: METHOD.POST,
                url: 'csdlgd-admin/diemTruongTheoNam/delelte-list'
            },
            getDataTinhThanh: {
                method: METHOD.GET,
                url: 'internal-api/dmTinhThanh/filter'
            },
            getDataQuanHuyen: {
                method: METHOD.GET,
                url: 'internal-api/dmQuanHuyen/filter'
            },
            getDataPhuongXa: {
                method: METHOD.GET,
                url: 'internal-api/dmPhuongXa/filter'
            },
            getDonViQuanLy: {
                method: METHOD.GET,
                url: 'internal-api/dmDonVi/filter'
            },
            getToThon: {
                method: METHOD.GET,
                url: 'internal-api/dmToThon/filter'
            },
            updateTruongHoc: {
                method: METHOD.PUT,
                url: 'internal-api/dmTruongHoc/update'
            },
            createTruongHoc: {
                method: METHOD.POST,
                url: 'internal-api/dmTruongHoc/create'
            },
            importFile: {
                method: METHOD.POST,
                url: 'internal-api/dmTruongHoc/import'
            },
            createDiemTruong: {
                method: METHOD.POST,
                url: 'csdlgd-admin/diemTruongTheoNam/create'
            },
            updateDiemTruong: {
                method: METHOD.POST,
                url: 'csdlgd-admin/diemTruongTheoNam/update'
            },
            deleteDiemTruong: {
                method: METHOD.POST,
                url: 'csdlgd-admin/diemTruongTheoNam/delelte-list'
            }
        },
        traCuuKQHT: {
            danhSachTraCuuKQHT: {
                method: METHOD.POST,
                url: 'csdlgd-admin/kqht-hs/filters3'
            },
            xoaKQHT: {
                method: METHOD.POST,
                url: 'csdlgd-admin/kqht-hs/delete'
            },
            chiTietKQHT: {
                method: METHOD.GET,
                url: 'csdlgd-admin/kqht-hs'
            },
            xemDiem: {
                method: METHOD.POST,
                url: 'csdlgd-admin/kqht-hs/diemChiTiet'
            }
        },
        thongTinDiemDanh: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/bc-diem-danh/bao-cao'
        }
    },
    heThong: {
        quanLyNguoiDung: {
            vaiTro: {
                method: METHOD.GET,
                url: 'csdlgd-user/adminRole/getRole'
            },
            danhSachQuanLyNguoiDung: {
                method: METHOD.POST,
                url: 'csdlgd-user/adminUser/search'
            },
            thayDoiTrangThai: {
                method: METHOD.PUT,
                url: 'csdlgd-user/adminUser/trangThai'
            },
            thayDoiMatKhau: {
                method: METHOD.PUT,
                url: 'csdlgd-user/adminUser/password'
            },
            themMoiNguoiDung: {
                method: METHOD.POST,
                url: 'csdlgd-user/adminUser'
            },
            chinhSuaNguoiDung: {
                method: METHOD.PUT,
                url: 'csdlgd-user/adminUser'
            }
        },
        quanLyVaiTro: {
            getRole: {
                method: METHOD.GET,
                url: 'csdlgd-user/adminRole/getRole'
            },
            themMoiRole: {
                method: METHOD.POST,
                url: 'csdlgd-user/adminRole/createRole'
            },
            xoaRole: {
                method: METHOD.POST,
                url: 'csdlgd-user/adminRole/deleteRole'
            },
            getDataChinhSua: {
                method: METHOD.POST,
                url: 'csdlgd-user/adminRole/listFunction'
            },
            updateDataChinhSua: {
                method: METHOD.POST,
                url: 'csdlgd-user/adminRole/updateFunction'
            }
        },
        quanLyHeThongDoiTacTruong: {
            getDanhSachDoiTac: {
                method: METHOD.GET,
                url: 'internal-api/heThongDoiTac/filter'
            },
            danhSachHeThongDoiTacTruong: {
                method: METHOD.POST,
                url: 'csdlgd-admin/heThongDoiTacTruong/danhSach'
            },
            xoaDanhSach: {
                method: METHOD.POST,
                url: 'csdlgd-admin/heThongDoiTacTruong/deleteMoreDoiTacTruong'
            },
            themMoiDanhSach: {
                method: METHOD.POST,
                url: 'csdlgd-admin/heThongDoiTacTruong/createDoiTacTruong'
            },
            chinhSuaDoiTacTruong: {
                method: METHOD.POST,
                url: 'csdlgd-admin/heThongDoiTacTruong/updateDoiTacTruong'
            }
        },
        tongHopDuLieuBaoCao: {
            kiemTraQuaTrinhTongHop: {
                method: METHOD.POST,
                url: 'csdlgd-report-fetch/refetch/tonghop-dulieu-check'
            },
            tongHop: {
                method: METHOD.POST,
                url: 'csdlgd-report-fetch/refetch/dasboartBc'
            }
        },
        chotDuLieuBaoCao: {
            chotTheoPhong: {
                danhSach: {
                    method: METHOD.POST,
                    url: 'csdlgd-admin/chotDuLieu/danhSachTheoPhong'
                },
                moKhoa: {
                    method: METHOD.POST,
                    url: 'csdlgd-admin/chotDuLieu/updateTheoPhong'
                }
            },
            chotTheoTruong: {
                danhSach: {
                    method: METHOD.POST,
                    url: 'csdlgd-admin/chotDuLieu/danhSach'
                },
                moKhoa: {
                    method: METHOD.POST,
                    url: 'csdlgd-admin/chotDuLieu/update'
                }
            }
        },
        sapNhapGiaiThe: {
            sapNhap: {
                method: METHOD.POST,
                url: 'csdlgd-admin/sap-nhap-truong/sap-nhap'
            },
            giaiThe: {
                method: METHOD.GET,
                url: 'csdlgd-admin/sap-nhap-truong/giai-the'
            },
            huyGiaiThe: {
                method: METHOD.GET,
                url: 'csdlgd-admin/sap-nhap-truong/huy-giai-the'
            }
        },
        cauHinhDiaChiIp: {
            danhSach: {
                method: METHOD.GET,
                url: 'csdlgd-admin/security-config'
            },
            chinhSua: {
                method: METHOD.PUT,
                url: 'csdlgd-admin/security-config'
            }
        },
        lichSuTruyCap: {
            danhSach: {
                method: METHOD.GET,
                url: 'csdlgd-admin/admin-action'
            }
        }
    },
    superAdmin: {
        getDonViCha: {
            method: METHOD.GET,
            url: 'internal-api/dmDonVi/filter'
        },
        getTinhThanh: {
            method: METHOD.GET,
            url: 'internal-api/dmTinhThanh/filter'
        },
        getQuanHuyen: {
            method: METHOD.GET,
            url: 'internal-api/dmQuanHuyen/filter'
        },
        getPhuongXa: {
            method: METHOD.GET,
            url: 'internal-api/dmPhuongXa/filter'
        },
        getToThon: {
            method: METHOD.GET,
            url: 'internal-api/dmToThon/filter'
        },

        donVi: {
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/dmDonVi/filter'
            },
            xoaDonVi: {
                method: METHOD.DELETE,
                url: 'internal-api/dmDonVi/delete'
            },
            themDonVi: {
                method: METHOD.POST,
                url: 'internal-api/dmDonVi/create'
            },
            suaDonVi: {
                method: METHOD.PUT,
                url: 'internal-api/dmDonVi/update'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'internal-api/dmDonVi/import'
            }
        },
        monHoc: {
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/dmMonHoc/filter'
            },
            themMonHoc: {
                method: METHOD.POST,
                url: 'internal-api/dmMonHoc/create'
            },
            chinhSuaMonHoc: {
                method: METHOD.PUT,
                url: 'internal-api/dmMonHoc/update'
            },
            xoaMonHoc: {
                method: METHOD.DELETE,
                url: 'internal-api/dmMonHoc/delete'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'internal-api/dmMonHoc/import'
            }
        },
        tinhThanh: {
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/dmTinhThanh/filter'
            },
            themTinhThanh: {
                method: METHOD.POST,
                url: 'internal-api/dmTinhThanh/create'
            },
            chinhSuaTinhThanh: {
                method: METHOD.PUT,
                url: 'internal-api/dmTinhThanh/update'
            },
            xoaTinhThanh: {
                method: METHOD.DELETE,
                url: 'internal-api/dmTinhThanh/delete'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'internal-api/dmTinhThanh/import'
            }
        },
        quanHuyen: {
            getTinhThanh: {
                method: METHOD.GET,
                url: 'internal-api/dmTinhThanh/filter'
            },
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/dmQuanHuyen/filter'
            },
            themQuanHuyen: {
                method: METHOD.POST,
                url: 'internal-api/dmQuanHuyen/create'
            },
            chinhSuaQuanHuyen: {
                method: METHOD.PUT,
                url: 'internal-api/dmQuanHuyen/update'
            },
            xoaQuanHuyen: {
                method: METHOD.DELETE,
                url: 'internal-api/dmQuanHuyen/delete'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'internal-api/dmQuanHuyen/import'
            }
        },
        phuongXa: {
            getTinhThanh: {
                method: METHOD.GET,
                url: 'internal-api/dmTinhThanh/filter'
            },
            getQuanHuyen: {
                method: METHOD.GET,
                url: 'internal-api/dmQuanHuyen/filter'
            },
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/dmPhuongXa/filter'
            },
            themPhuongXa: {
                method: METHOD.POST,
                url: 'internal-api/dmPhuongXa/create'
            },
            chinhSuaPhuongXa: {
                method: METHOD.PUT,
                url: 'internal-api/dmPhuongXa/update'
            },
            xoaPhuongXa: {
                method: METHOD.DELETE,
                url: 'internal-api/dmPhuongXa/delete'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'internal-api/dmPhuongXa/import'
            }
        },
        toThon: {
            getTinhThanh: {
                method: METHOD.GET,
                url: 'internal-api/dmTinhThanh/filter'
            },
            getQuanHuyen: {
                method: METHOD.GET,
                url: 'internal-api/dmQuanHuyen/filter'
            },
            getPhuongXa: {
                method: METHOD.GET,
                url: 'internal-api/dmPhuongXa/filter'
            },
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/dmToThon/filter'
            },
            themToThon: {
                method: METHOD.POST,
                url: 'internal-api/dmToThon/create'
            },
            chinhSuaToThon: {
                method: METHOD.PUT,
                url: 'internal-api/dmToThon/update'
            },
            xoaToThon: {
                method: METHOD.DELETE,
                url: 'internal-api/dmToThon/delete'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'internal-api/dmToThon/import'
            }
        },
        danToc: {
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/dmDanToc/filter'
            },
            themDanToc: {
                method: METHOD.POST,
                url: 'internal-api/dmDanToc/create'
            },
            chinhSuaDanToc: {
                method: METHOD.PUT,
                url: 'internal-api/dmDanToc/update'
            },
            xoaDanToc: {
                method: METHOD.DELETE,
                url: 'internal-api/dmDanToc/delete'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'internal-api/dmDanToc/import'
            }
        },
        tonGiao: {
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/dmTonGiao/filter'
            },
            themTonGiao: {
                method: METHOD.POST,
                url: 'internal-api/dmTonGiao/create'
            },
            chinhSuaTonGiao: {
                method: METHOD.PUT,
                url: 'internal-api/dmTonGiao/update'
            },
            xoaTonGiao: {
                method: METHOD.DELETE,
                url: 'internal-api/dmTonGiao/delete'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'internal-api/dmTonGiao/import'
            }
        },
        chuyenNganhDaoTao: {
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/dmCNganhDTao/filter'
            },
            themChuyenNganhDaoTao: {
                method: METHOD.POST,
                url: 'internal-api/dmCNganhDTao/create'
            },
            chinhSuaChuyenNganhDaoTao: {
                method: METHOD.PUT,
                url: 'internal-api/dmCNganhDTao/update'
            },
            xoaChuyenNganhDaoTao: {
                method: METHOD.DELETE,
                url: 'internal-api/dmCNganhDTao/delete'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'internal-api/dmCNganhDTao/import'
            }
        },
        doiTuongChinhSach: {
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/dmDTuongCSach/filter'
            },
            themDoiTuongChinhSach: {
                method: METHOD.POST,
                url: 'internal-api/dmDTuongCSach/create'
            },
            chinhSuaDoiTuongChinhSach: {
                method: METHOD.PUT,
                url: 'internal-api/dmDTuongCSach/update'
            },
            xoaDoiTuongChinhSach: {
                method: METHOD.DELETE,
                url: 'internal-api/dmDTuongCSach/delete'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'internal-api/dmDTuongCSach/import'
            }
        },
        lyDoBoHoc: {
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/dmLyDoBoHoc/filter'
            },
            themMoiLyDoBoHoc: {
                method: METHOD.POST,
                url: 'internal-api/dmLyDoBoHoc/create'
            },
            chinhSuaLyDoBoHoc: {
                method: METHOD.PUT,
                url: 'internal-api/dmLyDoBoHoc/update'
            },
            xoaLyDoBoHoc: {
                method: METHOD.DELETE,
                url: 'internal-api/dmLyDoBoHoc/delete'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'internal-api/dmLyDoBoHoc/import'
            }
        },
        loaiDoiTuong: {
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/dmLoaiDoiTuong/filter'
            },
            themMoiLoaiDoiTuong: {
                method: METHOD.POST,
                url: 'internal-api/dmLoaiDoiTuong/create'
            },
            chinhSuaLoaiDoiTuong: {
                method: METHOD.PUT,
                url: 'internal-api/dmLoaiDoiTuong/update'
            },
            xoaLoaiDoiTuong: {
                method: METHOD.DELETE,
                url: 'internal-api/dmLoaiDoiTuong/delete'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'internal-api/dmLoaiDoiTuong/import'
            }
        },
        linhVucDanhGiaPhatTrien: {
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/dmLinhVucDanhGiaSuPT/filter'
            },
            themMoiLinhVucDanhGiaPhatTrien: {
                method: METHOD.POST,
                url: 'internal-api/dmLinhVucDanhGiaSuPT/create'
            },
            chinhSuaLinhVucDanhGiaPhatTrien: {
                method: METHOD.PUT,
                url: 'internal-api/dmLinhVucDanhGiaSuPT/update'
            },
            xoaLinhVucDanhGiaPhatTrien: {
                method: METHOD.DELETE,
                url: 'internal-api/dmLinhVucDanhGiaSuPT/delete'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'internal-api/dmLinhVucDanhGiaSuPT/import'
            }
        },
        chiSoDanhGiaPhatTrien: {
            getLinhVucDaoTaoPhatTrien: {
                method: METHOD.GET,
                url: 'internal-api/dmLinhVucDanhGiaSuPT/filter'
            },
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/dmChiSoDanhGiaSuPT/filter'
            },
            themMoiChiSoDanhGiaPhatTrien: {
                method: METHOD.POST,
                url: 'internal-api/dmChiSoDanhGiaSuPT/create'
            },
            chinhSuaChiSoDanhGiaPhatTrien: {
                method: METHOD.PUT,
                url: 'internal-api/dmChiSoDanhGiaSuPT/update'
            },
            xoaChiSoDanhGiaPhatTrien: {
                method: METHOD.DELETE,
                url: 'internal-api/dmChiSoDanhGiaSuPT/delete'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'internal-api/dmChiSoDanhGiaSuPT/import'
            }
        },

        chuanChieuCaoCanNang: {
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/dmChuanChieuCaoCanNang/filter'
            },
            themMoiChuanChieuCaoCanNang: {
                method: METHOD.POST,
                url: 'internal-api/dmChuanChieuCaoCanNang/create'
            },
            chinhSuaChuanChieuCaoCanNang: {
                method: METHOD.PUT,
                url: 'internal-api/dmChuanChieuCaoCanNang/update'
            },
            xoaChuanChieuCaoCanNang: {
                method: METHOD.DELETE,
                url: 'internal-api/dmChuanChieuCaoCanNang/delete'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'internal-api/dmChuanChieuCaoCanNang/import'
            }
        },

        quanLyDoiTac: {
            danhSach: {
                method: METHOD.GET,
                url: 'internal-api/heThongDoiTac/filter'
            },
            themMoiQuanLyDoiTac: {
                method: METHOD.POST,
                url: 'internal-api/heThongDoiTac/create'
            },
            chinhSuaQuanLyDoiTac: {
                method: METHOD.PUT,
                url: 'internal-api/heThongDoiTac/update'
            },
            xoaQuanLyDoiTac: {
                method: METHOD.DELETE,
                url: 'internal-api/heThongDoiTac/delete'
            }
        },
        quanLyCauHoiThuongGap: {
            danhSach: {
                method: METHOD.GET,
                url: 'csdlgd-admin/CauHoi/danhSach'
            },
            themCauHoi: {
                method: METHOD.POST,
                url: 'csdlgd-admin/CauHoi/create'
            },
            suaCauHoi: {
                method: METHOD.POST,
                url: 'csdlgd-admin/CauHoi/update'
            },
            xoaCauHoi: {
                method: METHOD.POST,
                url: 'csdlgd-admin/CauHoi/delete'
            }
        },
        quanLyGoiCuocCA: {
            danhSach: {
                method: METHOD.POST,
                url: 'csdlgd-admin/chung-thu-so/filter'
            },
            themMoiQuanLyGoiCuocCA: {
                method: METHOD.POST,
                url: 'csdlgd-admin/chung-thu-so/insert-cts'
            },
            chinhSuaQuanLyGoiCuocCA: {
                method: METHOD.POST,
                url: 'csdlgd-admin/chung-thu-so/update-by-id'
            },
            xoaQuanLyGoiCuocCA: {
                method: METHOD.POST,
                url: 'csdlgd-admin/chung-thu-so/delete-by-magv'
            },
            importExcel: {
                method: METHOD.POST,
                url: 'csdlgd-admin/chung-thu-so/import-exel'
            }
        }
    },
    user: {
        lichSuDangNhap: {
            danhSach: {
                method: METHOD.POST,
                url: 'internal-api/lichSuDangNhap/theoTenDangNhap'
            }
        },
        doiMatKhau: {
            method: METHOD.PUT,
            url: 'csdlgd-user/adminUser/changeSelfPasswordV2'
        }
    },
    roleSchool: {
        card: {
            thongKe: {
                method: METHOD.POST,
                url: 'csdlgd-report-api/dasboard/truong/truonghoc'
            }
        },
        dataLopHoc: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/truong/lophoc'
        },
        dataCanBoGiaoVien: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/truong/canbogiaovien'
        },
        dataHocSinh: {
            method: METHOD.POST,
            url: 'csdlgd-report-api/dasboard/truong/thongtinhocsinh'
        },
        dataDiemTruong: {
            method: METHOD.POST,
            url: 'csdlgd-admin/diemTruongTheoNam/finds'
        },
        tongHop: {
            method: METHOD.POST,
            url: 'csdlgd-report-fetch/refetch/dasboartBc'
        }
    }
}

export default Api
