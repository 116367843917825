<template>
    <div>
        <CustomBreadcrumb :title="'BÁO CÁO'" content="TỔNG HỢP DỮ LIỆU BÁO CÁO" />
        <CustomContentUI>
            <div class="row">
                <div class="col-4">
                    <label>Đơn vị quản lý</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="
                                this.authUser.role === 5 ||
                                this.authUser.role === 4
                            "
                            :data="this.dataChonDonVi_Store"
                            :placeholder="'Chọn đơn vị'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenDonVi', 'maDonVi']"
                            v-model="selectedValue.selectedValueUnitEducation"
                            @change="getDataChonTruongHoc"
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Cấp học</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectGradeLevel"
                            :placeholder="'Chọn cấp học'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenTruongHoc', 'value']"
                            v-model="selectedValue.selectedValueGradeLevel"
                            @change="getDataChonTruongHoc"
                        />
                    </div>
                </div>
                <div class="col-3">
                    <label>Trường học <span class="err">*</span></label>
                    <div>
                        <ESelect
                            :reset="resetESelectSchool"
                            @reset-completed="handleResetSchoolCompleted"
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectSchool"
                            :placeholder="'Chọn trường học'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['tenTruongHoc', 'maTruongHoc']"
                            v-model="selectedValue.selectedValueSchool"
                        />
                        <span v-if="err_maTruongHoc.length > 0" class="error">{{
                            err_maTruongHoc
                        }}</span>
                    </div>
                </div>
                <div class="col-3">
                    <label>Năm học <span class="err">*</span></label>
                    <div>
                        <ESelectYear
                            v-model="selectedValue.selectedValueYear"
                            placeholder="Chọn năm"
                            size="small"
                            :no-data-text="'Không có bản ghi nào'"
                            :no-match-text="'Không tìm thấy bản ghi nào'"
                        ></ESelectYear>
                    </div>
                </div>
            </div>
            <div class="layout-btn-search">
                <CustomButton
                    label="Kiểm tra quá trình tổng hợp"
                    class="btn-default btn-add"
                    size="small"
                    @click="handleKiemTraQuaTrinhTongHop"
                />
                <CustomButton
                    label=" Tổng hợp"
                    class="btn-default btn-info"
                    size="small"
                    @click="handleTongHop"
                />
            </div>
        </CustomContentUI>
    </div>
</template>
<script>
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'
import ESelectYear from '@/components/ESelectYear.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import { mapState } from 'vuex'
import { ESelectGradeLevel } from '@/mock_data'
import Api from '@/constants/Api'
import sendRequest from '@/services'
import { convertCapHocList, currentYear, filterCapHocList } from '@/utils'
import { validateField } from '@/utils'
import CustomContentUI from '@/components/CustomContentUI.vue'
export default {
    name: 'TongHopDuLieuBaoCao',
    components: {
        ESelect,
        CustomButton,
        CustomBreadcrumb,
        ESelectYear,
        CustomContentUI
    },
    data() {
        return {
            resetESelectSchool: false,
            tableData: [],
            getDataESelect: {
                ESelectUnitEducation: [], //donvi
                ESelectGradeLevel: [], //caphoc
                ESelectSchool: [], //truonghoc,
                ESelectYear: [] //namhoc
            },
            selectedValue: {
                selectedValueUnitEducation: [], //donvi
                selectedValueGradeLevel: [], //caphoc
                selectedValueSchool: [], //truonghoc,
                selectedValueYear: [] //namhoc
            },

            err_maTruongHoc: ''
        }
    },

    methods: {
        handleResetSchoolCompleted() {
            this.resetESelectSchool = false
        },

        async handleKiemTraQuaTrinhTongHop() {
            try {
                const maTruongHoc = this.selectedValue.selectedValueSchool.value
                const fieldsToValidate = [
                    {
                        name: 'err_maTruongHoc',
                        value: maTruongHoc,
                        errorField: 'err_maTruongHoc',
                        errorMessage: 'Vui lòng chọn trường học'
                    }
                ]
                let isValid = true

                for (const field of fieldsToValidate) {
                    isValid = validateField(this, field, isValid)
                }
                if (isValid) {
                    const request_Header = {
                        token: this.authToken
                    }
                    const request_Data = {
                        namHoc:
                            this.selectedValue.selectedValueYear || currentYear,
                        maTruongHoc: maTruongHoc
                    }
                    const response = await sendRequest(
                        Api.heThong.tongHopDuLieuBaoCao.kiemTraQuaTrinhTongHop,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        this.$message({
                            type: 'success',
                            message: response.rd
                        })
                    } else {
                        this.$message({
                            type: 'warning',
                            message: response.rd
                        })
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async handleTongHop() {
            const maTruongHoc = this.selectedValue.selectedValueSchool.value

            if (maTruongHoc === undefined) {
                this.$message({
                    type: 'warning',
                    message: 'Vui lòng bổ sung trường học'
                })
                return
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                namHoc: this.selectedValue.selectedValueYear || currentYear,
                maTruongHoc: maTruongHoc
            }
            this.$confirm(
                'Xác nhận tổng hợp lại dữ liệu của trường học đã chọn?',
                'Thông báo',
                {
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy',
                    type: 'warning'
                }
            )
                .then(async () => {
                    const response = await sendRequest(
                        Api.heThong.tongHopDuLieuBaoCao.tongHop,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        loading.close()

                        this.$message({
                            type: 'success',
                            message:
                                'Hệ thống đang tiến hành tổng hợp lại các báo cáo và hồ sơ tra cứu. Thông tin mới nhất từ QLNT nộp lên sẽ hiển thị trong vài phút tới.'
                        })
                    } else {
                        loading.close()
                        this.$message({
                            type: 'info',
                            message: response.rd
                        })
                    }
                })
                .catch(() => {
                    loading.close()
                })
        },
        async getDataChonTruongHoc() {
            const maDonVi = this.convertToValueArray(
                this.selectedValue.selectedValueUnitEducation
            )

            const capHoc = this.convertToValueArray(
                this.selectedValue.selectedValueGradeLevel
            )

            const request_Header = {
                token: this.authToken
            }

            const request_Data = {
                capHoc: capHoc,
                maDonVi: maDonVi
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            let response = await sendRequest(
                Api.internal_api.dm_School,
                request_Data,
                request_Header,
                null
            )

            if (response.statusResponse == 0) {
                loading.close()
                this.getDataESelect.ESelectSchool = response.rows.map((item) => ({
                    ...item,
                    tenTruongHoc: `${item.tenTruongHoc} - [${item.maTruongHoc}]`
                }))
            }
        },
        convertToValueArray(array) {
            return array.map((item) => item.value)
        }
    },
    watch: {
        'authUser.role': {
            immediate: true,
            handler(newRole, oldRole) {
                if (newRole === 5) {
                    this.selectedValue.selectedValueUnitEducation = [
                        { value: this.authUser.ma_don_vi }
                    ]
                    const convertedList = convertCapHocList(
                        this.authUser.capHocList
                    )
                    this.selectedValue.selectedValueGradeLevel = convertedList
                    this.selectedValue.selectedValueSchool = {
                        value: this.authUser.ma_truong_hoc
                    }
                }
                if (newRole === 4) {
                    this.selectedValue.selectedValueUnitEducation = [
                        { value: this.authUser.ma_don_vi }
                    ]
                }
            }
        },

        'selectedValue.selectedValueUnitEducation': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        },
        'selectedValue.selectedValueGradeLevel': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        }
    },
    mounted() {
        this.getDataESelect.ESelectGradeLevel = filterCapHocList(
            this.authUser.capHocList
        )
        this.getDataChonTruongHoc()
    }
}
</script>

<style scoped>
.layout-btn-search {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.layout-btn {
    padding: 20px 10px 5px 10px;
    background: #fff;
    display: flex;
    justify-content: space-between;
}
.text-center {
    text-align: center;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.bg-search,
.btn-info {
    background-color: #ffa554;
}
.btn-add {
    background-color: #6aab9c;
}
.err {
    color: red;
}
.error {
    color: red;
    margin-top: 5px;
}
</style>
