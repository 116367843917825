<template>
    <el-dialog
        :visible="themMoiVisible"
        @close="closeModal"
        width="80%"
        class="custom-dialog"
    >
        <span slot="title" class="custom-dialog-title"
            >Thêm mới thông tin vai trò
            <hr />
        </span>
        <div class="row">
            <div class="col-4">
                <label>Tên vai trò: <span>*</span></label>
                <div>
                    <el-input
                        size="small"
                        placeholder="Nhập ..."
                        v-model="selectedValue.input_tenVaiTro"
                    ></el-input>
                </div>
            </div>
            <div class="col-4">
                <label>Vai trò gốc: <span>*</span></label>
                <div>
                    <el-select
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        clearable
                        v-model="selectedValue.selectedValue_vaiTroGoc"
                        placeholder="Chọn"
                        size="small"
                    >
                        <el-option
                            v-for="item in getDataESelect.ESelectRole"
                            :key="item.value"
                            :label="item.title"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="col-4">
                <label>Mô tả: <span>*</span></label>
                <div>
                    <el-input
                        size="small"
                        placeholder="Nhập ..."
                        v-model="selectedValue.input_moTa"
                    ></el-input>
                </div>
            </div>
        </div>
        <div class="layout-btn">
            <CustomButton
                label="Đóng"
                size="small"
                class="btn-default btn-delete"
                @click="closeModal"
            />
            <CustomButton
                label="Thêm mới"
                class="btn-default btn-add"
                size="small"
                @click="handleThemMoi"
            />
        </div>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
import CustomButton from '@/components/CustomButton.vue'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { ESelectRole } from '@/mock_data'
export default {
    name: 'ModalDoiMauKhau',
    props: {
        themMoiVisible: {
            type: Boolean,
            required: true
        },

        closeModalThemMoi: {
            type: Function,
            required: true
        },
        getDataTableRole: Function
    },
    components: { CustomButton },

    data() {
        return {
            getDataESelect: {
                ESelectRole: ESelectRole
            },
            selectedValue: {
                input_tenVaiTro: '',
                selectedValue_vaiTroGoc: '',
                input_moTa: ''
            }
        }
    },

    methods: {
        async handleThemMoi() {
            const roleName = this.selectedValue.input_tenVaiTro
            const description = this.selectedValue.input_moTa
            const parentRole = this.selectedValue.selectedValue_vaiTroGoc

            if (!roleName) {
                this.$message.warning('Vui lòng bổ sung vai trò')
                return
            }

            if (!parentRole) {
                this.$message.warning('Vui lòng bổ sung vai trò gốc')
                return
            }

            if (!description) {
                this.$message.warning('Vui lòng bổ sung mô tả')
                return
            }

            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                roleName: roleName,
                parentRole: parentRole,
                description: description,
                maSo: this.authUser.ma_so
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            this.$confirm('Xác nhận thêm vai trò mới?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const response = await sendRequest(
                        Api.heThong.quanLyVaiTro.themMoiRole,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        loading.close()

                        this.$message({
                            type: 'success',
                            message: 'Thêm mới thành công'
                        })
                        this.closeModalThemMoi()
                        this.$emit('getDataTableRole')
                    } else {
                        loading.close()
                        this.$message({
                            type: 'warning',
                            message: response.rd
                        })
                    }
                })
                .catch(() => {
                    loading.close()
                })
        },
        closeModal() {
            this.selectedValue.input_moTa = ''
            this.selectedValue.selectedValue_vaiTroGoc = ''
            this.selectedValue.input_tenVaiTro = ''

            this.closeModalThemMoi()
        }
    },

    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        }
    }
}
</script>
<style scoped>
.row span {
    color: red;
}
.layout-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
hr {
    border: 1px solid #7ca33f;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}

.btn-add {
    background-color: var(--light-green);
}

.btn-update {
    background-color: var(--yellow);
}
.btn-delete {
    background-color: var(--orange);
}
</style>
