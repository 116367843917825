<template>
    <div>
        <CustomBreadcrumb :title="'HỆ THỐNG'" content="CÁC CÂU HỎI THƯỜNG GẶP" />
        <div class="content">
            <div class="row d-flex align-items-center">
                <div class="col-md-3">
                    <label>Tìm kiếm:</label>
                    <el-input
                        placeholder="Nhập nội dung câu hỏi ..."
                        v-model="selectedValue.input_noiDungCauHoi"
                        size="small"
                    >
                    </el-input>
                </div>

                <div class="col-md-2">
                    <div style="margin-top: 25px">
                        <CustomButton
                            label="Thêm câu hỏi mới"
                            size="small"
                            type="success"
                            @click="openModalThemMoiCauHoi"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <el-collapse v-model="activeNames">
                <el-collapse-item
                    v-for="(item, index) in collapseData"
                    :key="item.id"
                    :name="item.id"
                >
                    <div slot="title" class="custom-title">
                        {{ index + 1 }}. {{ item.cauHoi }}
                    </div>
                    <div class="layout-collapase">
                        <div v-html="item.traLoi"></div>
                    </div>
                    <div class="layout-collapase">
                        <CustomButton
                            label="Chỉnh sửa"
                            type="warning"
                            size="medium"
                            @click="openModalChinhSuaCauHoi(item)"
                        />
                        <CustomButton
                            label="Xóa"
                            type="danger"
                            size="medium"
                            @click="handleDelete(item)"
                        />
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <ModalThemMoi
            :themMoiModalVisible="themMoiModalVisible"
            :closeModalThemMoi="closeModalThemMoi"
            @getDataDanhSachCauHoi="getDataDanhSachCauHoi"
        />
        <ModalChinhSua
            @getDataDanhSachCauHoi="getDataDanhSachCauHoi"
            :chinhSuaModalVisible="chinhSuaModalVisible"
            :dataModalChinhSua="dataModalChinhSua"
            :closeModalChinhSua="closeModalChinhSua"
        />
    </div>
</template>
<script>
import sendRequest from '@/services'
import Api from '@/constants/Api'
import axios from 'axios'
import ModalThemMoi from './ModalThemMoi.vue'
import ModalChinhSua from './ModalChinhSua.vue'
import { mapState } from 'vuex'
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'
import CustomButton from '@/components/CustomButton.vue'

export default {
    name: 'CacCauHoiThuongGap',
    components: {
        CustomBreadcrumb,
        CustomButton,
        ModalThemMoi,
        ModalChinhSua
    },
    data() {
        return {
            collapseData: [],
            activeNames: ['1'],
            selectedValue: {
                input_noiDungCauHoi: ''
            },
            themMoiModalVisible: false,
            chinhSuaModalVisible: false,
            dataModalChinhSua: {}
        }
    },
    methods: {
        openModalThemMoiCauHoi() {
            this.themMoiModalVisible = true
        },
        closeModalThemMoi() {
            this.themMoiModalVisible = false
        },
        openModalChinhSuaCauHoi(item) {
            this.dataModalChinhSua = item
            this.chinhSuaModalVisible = true
        },
        closeModalChinhSua() {
            this.chinhSuaModalVisible = false
        },
        handleDelete(item) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })

            const request_Data = item.id

            this.$confirm('Xác nhận xóa bản ghi đã chọn?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(() => {
                    axios
                        .post(
                            'https://apimoet.dtsgroup.com.vn/csdlgd-admin/CauHoi/delete',
                            request_Data,
                            {
                                headers: {
                                    'Content-Type': 'application/json',
                                    token: this.authToken
                                }
                            }
                        )
                        .then((response) => {
                            loading.close()

                            if (response.data.rc == 0) {
                                this.$message({
                                    type: 'success',
                                    message: 'Xóa thành công'
                                })
                                this.getDataDanhSachCauHoi()
                            } else {
                                loading.close()
                                this.$message({
                                    type: 'error',
                                    message: 'Thất bại'
                                })
                            }
                        })
                        .catch((error) => {
                            loading.close()
                        })
                })
                .catch(() => {
                    loading.close()
                })
        },

        async getDataDanhSachCauHoi() {
            try {
                const request_Header = {
                    token: this.authToken
                }
                const request_Params = {
                    start: 0,
                    limit: 1000
                }
                const response = await sendRequest(
                    Api.superAdmin.quanLyCauHoiThuongGap.danhSach,
                    null,
                    request_Header,
                    request_Params
                )
                this.collapseData = response.rows
            } catch (error) {
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }
        }
    },

    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    },
    mounted() {
        this.getDataDanhSachCauHoi()
    }
}
</script>

<style scoped>
.content {
    background: #fff;
    padding: 10px 10px 10px 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}
.content label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px;
}
.content .span {
    color: red;
}
::v-deep .el-collapse-item__header {
    margin: 10px !important;
    border-radius: 4px !important;
    background-color: #f5f5f5 !important;
    border-color: #ddd !important;
}
.content .custom-title {
    font-size: 18px;
    color: blue;
    font-weight: 700;
    font-style: italic;
}
.content .layout-collapase {
    padding: 10px;
}
</style>
