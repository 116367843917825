<template>
    <div>
        <CustomBreadcrumb :title="'HỆ THỐNG'" content="CHỐT DỮ LIỆU BÁO CÁO" />

        <el-tabs type="border-card" @tab-click="handleClick">
            <el-tab-pane label="Chốt theo đơn vị">
                <ChotTheoDonVi />
            </el-tab-pane>
            <el-tab-pane label="Chốt theo trường học">
                <ChotTheoTruong />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'

import ChotTheoDonVi from './ChotTheoDonVi.vue'
import ChotTheoTruong from './ChotTheoTruong.vue'
import { mapState } from 'vuex'
import sendRequest from '@/services'
import Api from '@/constants/Api'

export default {
    name: 'BaoCaoDong',
    components: {
        CustomBreadcrumb,
        ChotTheoDonVi,
        ChotTheoTruong
    },
    data() {
        return {
            activeTab: 0, // Index của tab đầu tiên được chọn mặc định
            ESelect_DoiTac: []
        }
    },
    methods: {
        handleClick(tab, event) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            if (tab.index) {
                this.activeTab = tab.index
                loading.close()
            }
        }
    },
    mounted() {},
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped></style>
