import Vue from 'vue'
import VueRouter from 'vue-router'

// <!-- ************ Auth ************ -->

import Login from '@/views/Login.vue'
import LoginSSO from '@/views/LoginSSO.vue'
// <!-- ************ Trang chủ ************ -->
import DashBoard from '@/views/DashBoard/index.vue'

// TiepNhanDuLieuQLNT
import CapSo_CauHinhLichDongBo from '@/views/TiepNhanDuLieuQLNT/CauHinhLichDongBo/CapSo_CauHinhLichDongBo/index.vue'
import CapTruong_CauHinhLichDongBo from '@/views/TiepNhanDuLieuQLNT/CauHinhLichDongBo/CapTruong_CauHinhLichDongBo/index.vue'
import CapSo_BCTinhHinhNopDuLieu from '@/views/TiepNhanDuLieuQLNT/BCTinhHinhNopDuLieu/CapSo_BCTinhHinhNopDuLieu'
import CapTruong_BCTinhHinhNopDuLieu from '@/views/TiepNhanDuLieuQLNT/BCTinhHinhNopDuLieu/CapTruong_BCTinhHinhNopDuLieu'
import LichSuTiepNhanDuLieu from '@/views/TiepNhanDuLieuQLNT/LichSuTiepNhanDuLieu/index.vue'

//KetChuyenDuLieuLenBo
import MatKhauKetChuyen from '@/views/KetChuyenDuLieuLenBo/MatKhauKetChuyen/index.vue'
import CauHinhDuyetDayDuLieuLenBo from '@/views/KetChuyenDuLieuLenBo/CauHinhDuyetDayDuLieuLenBo'
import KetChuyenDuLieu from '@/views/KetChuyenDuLieuLenBo/KetChuyenDuLieu/index.vue'
import LichSuDayDuLieuLenBo from '@/views/KetChuyenDuLieuLenBo/LichSuDayDuLieuLenBo'

//BaoCao
import BaoCaoEMIS from '@/views/BaoCao/BaoCaoEMIS'
import BaoCaoDonVi from '@/views/BaoCao/BaoCaoDonVi/index.vue'
import BaoCaoDong from '@/views/BaoCao/BaoCaoDong/index.vue'
import BaoCaoEQMS from '@/views/BaoCao/BaoCaoEQMS.vue'
import NopThongKeTheoBieuMau from '@/views/BaoCao/NopThongKeTheoBieuMau/index.vue'

//TraCuu
import TraCuuHocSinh from '@/views/TraCuu/TraCuuHocSinh/index.vue'
import TraCuuCanBoGiaoVien from '@/views/TraCuu/TraCuuCanBoGiaoVien/index.vue'
import TraCuuLopHoc from '@/views/TraCuu/TraCuuLopHoc/index.vue'
import TraCuuKetQuaHocTap from '@/views/TraCuu/TraCuuKetQuaHocTap/index.vue'
import ThongTinYTeHocSinh from '@/views/TraCuu/ThongTinYTeHocSinh.vue'
import TraCuuKetQuaHocTapTT22 from '@/views/TraCuu/TraCuuKetQuaHocTapTT22.vue'
import TraCuuTruongHoc from '@/views/TraCuu/TraCuuTruongHoc/index.vue'
import ThongTinDiemDanh from '@/views/TraCuu/ThongTinDiemDanh.vue'

//HeThong
import QuanLyNguoiDung from '@/views/HeThong/QuanLyNguoiDung/index.vue'
import QuanLyVaiTro from '@/views/HeThong/QuanLyVaiTro/index.vue'
import QuanLyHeThongDoiTacTruong from '@/views/HeThong/QuanLyHeThongDoiTacTruong/index.vue'
import TongHopDuLieuBaoCao from '@/views/HeThong/TongHopDuLieuBaoCao/index.vue'
import SapNhapTruong from '@/views/HeThong/SapNhapVaGiaiThe/SapNhap/index.vue'
import GiaiThe from '@/views/HeThong/SapNhapVaGiaiThe/GiaiThe/index.vue'
import ChotDuLieuBaoCao from '@/views/HeThong/ChotDuLieuBaoCao/index.vue'
import CauHinhDiaChiIp from '@/views/HeThong/CauHinhDiaChiIp/index.vue'
import LichSuTruyCap from '@/views/HeThong/LichSuTruyCap/index.vue'

//Super Admin
import DonVi from '@/views/SuperAdmin/DonVi/donvi.vue'
import MonHoc from '@/views/SuperAdmin/MonHoc/monHoc.vue'
import TinhThanh from '@/views/SuperAdmin/TinhThanh/index.vue'
import QuanHuyen from '@/views/SuperAdmin/QuanHuyen/index.vue'
import PhuongXa from '@/views/SuperAdmin/PhuongXa/index.vue'
import ToThon from '@/views/SuperAdmin/ToThon/index.vue'
import DanToc from '@/views/SuperAdmin/DanToc/danToc.vue'
import TonGiao from '@/views/SuperAdmin/TonGiao/tonGiao.vue'
import ChuyenNganhDaoTao from '@/views/SuperAdmin/ChuyenNganhDaoTao/chuyenNganhDaoTao.vue'
import DoiTuongChinhSach from '@/views/SuperAdmin/DoiTuongChinhSach/doiTuongChinhSach.vue'
import LyDoBoHoc from '@/views/SuperAdmin/LyDoBoHoc/lyDoBoHoc.vue'
import LoaiDoiTuong from '@/views/SuperAdmin/LoaiDoiTuong/loaiDoiTuong.vue'
import LinhVucDanhGiaPhatTrien from '@/views/SuperAdmin/LinhVucDanhGiaPhatTrien/linhVucDanhGiaPhatTrien.vue'
import ChiSoDanhGiaPhatTrien from '@/views/SuperAdmin/ChiSoDanhGiaVaPhatTrien/chiSoDanhGiaPhatTrien.vue'
import ChuanChieuCaoCanNang from '@/views/SuperAdmin/ChuanChieuCaoCanNang/chuanChieuCaoCanNang.vue'

import QuanLyDoiTac from '@/views/SuperAdmin/QuanLyDoiTac/quanLyDoiTac.vue'
import QuanLyCauHoiThuongGap from '@/views/SuperAdmin/QuanLyCauHoiThuongGap/quanLyCauHoiThuongGap.vue'
import QuanLyGoiCuocCA from '@/views/SuperAdmin/QuanLyGoiCuocCA/quanLyGoiCuocCA.vue'

Vue.use(VueRouter)

const routes = [
    // <!-- ************ Auth ************ -->
    {
        path: '/sso-login',
        name: 'LoginSSO',
        component: LoginSSO,
        meta: {
            title: 'LoginSSO'
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: 'Login'
        }
    },

    // <!-- ************ Trang chủ ************ -->
    {
        path: '/dashboard',
        name: 'DashBoard',
        component: DashBoard, // router trang chủ
        meta: {
            title: 'Dash Board',
            requiresAuth: true
        }
    },
    {
        path: '/',
        redirect: '/dashboard',
        meta: {
            title: 'Redirect'
        }
    },

    // <!-- ************ TiepNhanDuLieuQLNT ************ -->

    //  CauHinhLichDongBo
    {
        path: '/Configure-sync-calendar',
        name: 'ConfigureSyncCalendar',
        component: {
            render() {
                // Lấy giá trị type từ route
                const type = this.$route.query.type || 1

                if (type == 1) {
                    return <CapSo_CauHinhLichDongBo />
                } else if (type == 2) {
                    return <CapTruong_CauHinhLichDongBo />
                } else {
                    return <div>Thành phần không tồn tại</div>
                }
            }
        },
        meta: {
            title: ''
        }
    },

    //BCTinhHinhNopDuLieu
    {
        path: '/report-sync-status',
        name: 'ReportSyncStatus',
        component: {
            render() {
                // Lấy giá trị type từ route
                const type = this.$route.query.type || 1

                if (type == 1) {
                    return <CapSo_BCTinhHinhNopDuLieu />
                } else if (type == 2) {
                    return <CapTruong_BCTinhHinhNopDuLieu />
                } else {
                    return <div>Thành phần không tồn tại</div>
                }
            }
        },
        meta: {
            title: ''
        }
    },

    //LichSuTiepNhanDuLieu
    {
        path: '/Data-transfer-schedule',
        name: 'DataTransferSchedule',
        component: LichSuTiepNhanDuLieu,
        meta: {
            title: 'Lịch sử tiếp nhận dữ liệu'
        }
    },
    // <!-- ************ TiepNhanDuLieuQLNT ************ -->

    // <!-- ************ KetChuyenDuLieuLenBo ************ -->

    //MatKhauKetChuyen
    {
        path: '/pass-data-transfer',
        name: 'PassDataTransfer',
        component: MatKhauKetChuyen,
        meta: {
            title: 'Mật khẩu kết chuyển'
        }
    },

    //CauHinhDuyetDayDuLieuLenBo
    {
        path: '/config-moet-transfer-permission',
        name: 'ConfigMoetTransferPermission',
        component: CauHinhDuyetDayDuLieuLenBo,
        meta: {
            title: 'Cấu hình duyệt/đẩy dữ liệu lên Bộ'
        }
    },

    //KetChuyenDuLieu
    {
        path: '/Request-data-by-school',
        name: 'RequestDataBySchool',
        component: KetChuyenDuLieu,
        meta: {
            title: 'Kết chuyển dữ liệu'
        }
    },

    //LichSuDayDuLieuLenBo
    {
        path: '/moet-transfer-history',
        name: 'MoetTransferHistory',
        component: LichSuDayDuLieuLenBo,
        meta: {
            title: 'Lịch sử đẩy dữ liệu lên Bộ'
        }
    },

    // <!-- ************ KetChuyenDuLieuLenBo ************ -->

    // <!-- ************ BaoCao ************ -->

    //BaoCaoEMIS
    {
        path: '/Report-to-the-ministry',
        name: 'ReportToTheMinistry',
        component: BaoCaoEMIS,
        meta: {
            title: 'Báo Cáo EMIS'
        }
    },
    //BaoCaoDonVi
    {
        path: '/Report-to-the-department',
        name: 'ReportToTheDepartment',
        component: BaoCaoDonVi,
        meta: {
            title: 'Báo Cáo Đơn vị'
        }
    },
    //BaoCaoDong
    {
        path: '/report-emis',
        name: 'ReportEmis',
        component: BaoCaoDong,
        meta: {
            title: 'Báo Cáo Động'
        }
    },
    //BaoCaoEQMS
    {
        path: '/Report-eqms',
        name: 'ReportEQMS',
        component: BaoCaoEQMS,
        meta: {
            title: 'Báo Cáo EQMS'
        }
    },

    //NopThongKeTheoKieuMau
    {
        path: '/Submit_statistical_sample',
        name: 'SubmitStatisticalSample',
        component: NopThongKeTheoBieuMau,
        meta: {
            title: 'Nộp thống kê theo kiểu mẫu'
        }
    },
    // <!-- ************ BaoCao ************ -->

    // <!-- ************ TraCuu ************ -->
    //TraCuuHocSinh
    {
        path: '/Look-up-students',
        name: 'LookupStudents',
        component: TraCuuHocSinh,
        meta: {
            title: 'Tra cứu học sinh'
        }
    },
    //TraCuuCanBoGiaoVien
    {
        path: '/Look-up-officials',
        name: 'LookupOfficials',
        component: TraCuuCanBoGiaoVien,
        meta: {
            title: 'Tra cứu Cán bộ, giáo viên'
        }
    },
    //TraCuuLopHoc
    {
        path: '/Look-up-classes',
        name: 'LookupClasses',
        component: TraCuuLopHoc,
        meta: {
            title: 'Tra cứu Lớp học'
        }
    },
    //TraCuuKetQuaHocTap
    {
        path: '/Look-up-academi-results',
        name: 'LookupAcademiResults',
        component: TraCuuKetQuaHocTap,
        meta: {
            title: 'Tra cứu kết quả học tập'
        }
    },
    //ThongTinYTeHocSinh
    {
        path: '/Student-medical-information',
        name: 'StudentMedicalInformation',
        component: ThongTinYTeHocSinh,
        meta: {
            title: 'Thông tin y tế HS'
        }
    },

    //TraCuuKetQuaHocTapTT22
    {
        path: '/Look-up-academi-results-Circulars-22',
        name: 'LookupAcademi',
        component: TraCuuKetQuaHocTapTT22,
        meta: {
            title: 'Tra cứu KQHTTT22'
        }
    },
    //TraCuuTruongHoc
    {
        path: '/category/schools',
        name: 'CategorySchools',
        component: TraCuuTruongHoc,
        meta: {
            title: 'Tra cứu Trường Học'
        }
    },
    //ThongTinDiemDanh
    {
        path: '/lookup_attendance',
        name: 'LookupAttendance',
        component: ThongTinDiemDanh,
        meta: {
            title: 'Thông tin điểm danh'
        }
    },

    // <!-- ************ HeThong ************ -->
    //QuanLyNguoiDung
    {
        path: '/user-management',
        name: 'UserManagement',
        component: QuanLyNguoiDung,
        meta: {
            title: 'Quản lí người dùng'
        }
    },
    //quanlyvaitro
    {
        path: '/role_management',
        name: 'RoleManagement',
        component: QuanLyVaiTro,
        meta: {
            title: 'Quản lí vai trò'
        }
    },
    //quanlyhethongdoitactruong
    {
        path: '/update-school-partners',
        name: 'UpdateSchoolPartners',
        component: QuanLyHeThongDoiTacTruong,
        meta: {
            title: 'Quản lí hệ thống đối tác trường'
        }
    },

    //tonghopdulieubaocao
    {
        path: '/data-synchronization',
        name: 'DataSynchronization',
        component: TongHopDuLieuBaoCao,
        meta: {
            title: 'Tổng hợp dữ liệu báo cáo'
        }
    },

    {
        path: '/sap-nhap-truong',
        name: 'SapNhapTruong',
        component: SapNhapTruong,
        meta: {
            title: 'Sáp nhập trường'
        }
    },
    {
        path: '/giai-the-truong',
        name: 'GiaiTheTruong',
        component: GiaiThe,
        meta: {
            title: 'Giải thể'
        }
    },

    {
        path: '/Lock-data-report',
        name: 'LockDataReport',
        component: ChotDuLieuBaoCao,
        meta: {
            title: 'Chốt dữ liệu báo cáo'
        }
    },
    {
        path: '/config_api',
        name: 'CauHinhDiaChiIp',
        component: CauHinhDiaChiIp,
        meta: {
            title: 'Cấu hình địa chỉ Ip'
        }
    },
    {
        path: '/access_history',
        name: 'LichSuTruyCap',
        component: LichSuTruyCap,
        meta: {
            title: 'Lịch sử truy cập'
        }
    },

    // <!-- ************ Super Admin ************ -->
    //danhmuc
    {
        path: '/category/unit',
        name: 'CategoryUnit',
        component: DonVi,
        meta: {
            title: 'Đơn vị'
        }
    },

    //monhoc
    {
        path: '/category/subjects',
        name: 'CategorySubjects',
        component: MonHoc,
        meta: {
            title: 'Môn học'
        }
    },
    //tinhthanh
    {
        path: '/category/city',
        name: 'CategoryCity',
        component: TinhThanh,
        meta: {
            title: 'Tỉnh thành'
        }
    },
    //quanhuyen
    {
        path: '/category/district',
        name: 'CategoryDistrict',
        component: QuanHuyen,
        meta: {
            title: 'Quận Huyện'
        }
    },
    //phuongxa
    {
        path: '/category/wards',
        name: 'CategoryWards',
        component: PhuongXa,
        meta: {
            title: 'Phường Xã'
        }
    },
    //toThon
    {
        path: '/category/village',
        name: 'CategoryVillage',
        component: ToThon,
        meta: {
            title: 'Tổ Thôn'
        }
    },
    //dantoc
    {
        path: '/category/nation',
        name: 'CategoryNation',
        component: DanToc,
        meta: {
            title: 'Dân Tộc'
        }
    },
    //tongiao
    {
        path: '/category/religion',
        name: 'CategoryReligion',
        component: TonGiao,
        meta: {
            title: 'Tôn Giáo'
        }
    },
    //chuyennganhdaotao
    {
        path: '/category/specialized-training',
        name: 'CategorySpecializedTraining',
        component: ChuyenNganhDaoTao,
        meta: {
            title: 'Chuyên ngành đào tạo'
        }
    },

    //doituongchinhsach
    {
        path: '/category/policy-object',
        name: 'CategoryPolicyObject',
        component: DoiTuongChinhSach,
        meta: {
            title: 'Đối tượng chính sách'
        }
    },

    //lydobohoc
    {
        path: '/category/reason-for-dropping-out',
        name: 'CategoryReasonDorDroppingOut',
        component: LyDoBoHoc,
        meta: {
            title: 'Lý Do Bỏ Học'
        }
    },
    //loaidoituong
    {
        path: '/category/object-type',
        name: 'CategoryObjectType',
        component: LoaiDoiTuong,
        meta: {
            title: 'Loại Đối Tượng'
        }
    },

    //linhvucdanhgiavaphattrien
    {
        path: '/category/field-of-development-evaluation',
        name: 'CategoryFieldOfDevelopmentEvaluation',
        component: LinhVucDanhGiaPhatTrien,
        meta: {
            title: 'Lĩnh Vực ĐG&PT'
        }
    },

    //chisodanhgiavaphattrien
    {
        path: '/category/index-of-development-evaluation',
        name: 'CategoryIndexOfDevelopmentEvaluation',
        component: ChiSoDanhGiaPhatTrien,
        meta: {
            title: 'Chỉ Số ĐG&PT'
        }
    },

    //chuanchieucaocangnang
    {
        path: '/category/standard-weight-height',
        name: 'CategoryStandardWeightHeight',
        component: ChuanChieuCaoCanNang,
        meta: {
            title: 'Chuẩn chiều cao, cân nặng'
        }
    },

    // <!-- ************ Hệ thống ************ -->

    //quanlydoitac
    {
        path: '/Partner-management',
        name: 'PartnerManagement',
        component: QuanLyDoiTac,
        meta: {
            title: 'Quản Lý Đối Tác'
        }
    },
    //caucauhoithuonggap
    {
        path: '/Question-management',
        name: 'Question-management',
        component: QuanLyCauHoiThuongGap,
        meta: {
            title: 'Các Câu Hỏi Thường Gặp'
        }
    },
    //quanlygoicuocCA
    {
        path: '/goi-cuoc-giao-vien',
        name: 'GoiCuocGiaoVien',
        component: QuanLyGoiCuocCA,
        meta: {
            title: 'Gói Cước Giáo Viên'
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = 'ĐIỆN BIÊN CSDLN'

    const token = localStorage.getItem('token')

    if (token) {
        setTimeout(() => {
            localStorage.clear()
            next('/login')
        }, 7200000)
    } else {
        next()
    }
    // if (!token && to.path !== '/login') {
    //     next('/login')
    // } else {
    //     next()
    // }
    // if (!token && to.path.startsWith('/sso-login')) {
    //     next()
    // } else {
    //     // Nếu có token hoặc không ở trang bắt đầu bằng /sso-login, chuyển hướng đến /login
    //     next('/login')
    // }
    if (
        to.path.startsWith('/sso-login') ||
        to.fullPath.startsWith('https://login.dienbien.gov.vn')
    ) {
        next()
    } else if (token) {
        // Nếu đã có token, chuyển hướng đến path /
        next()
    } else {
        // Trường hợp còn lại, chuyển hướng đến /login
        next('/login')
    }
})
export default router
