<template>
    <el-dialog
        :visible="importModalVisible"
        @close="closeModalImport"
        class="custom-dialog"
        width="60%"
    >
        <span slot="title" class="custom-dialog-title">
            IMPORT THÔNG TIN MẬT KHẨU KẾT CHUYỂN
            <hr />
        </span>

        <div class="modal-body clearfix">
            <div class="row">
                <div class="col-lg-3">
                    <label class="pull-right" style="font-weight: 700"
                        >Chọn File</label
                    >
                </div>
                <div class="col-lg-9">
                    <div
                        style="
                            display: inline-flex;
                            position: relative;

                            border-radius: 4px;
                        "
                        role="alert"
                    >
                        <div
                            v-if="selectedFile"
                            style="position: absolute; top: 0; right: 0"
                        >
                            <a
                                href="#"
                                title="Xóa file đã chọn"
                                class="xoafile"
                                @click.prevent="clearFile()"
                                >x</a
                            >
                        </div>
                        <label class="btn btn-default btn-file">
                            <i class="el-icon-upload"></i>
                            <span> Chọn file</span>

                            <input
                                ref="file_upload"
                                multiple="false"
                                name="file"
                                style="display: none"
                                type="file"
                                @change="handleFileChange"
                                aria-invalid="false"
                            />
                        </label>
                        <label
                            v-if="selectedFile"
                            class="btn btn-default btn-file"
                        >
                            <span>
                                <CustomButton
                                    :label="`File đã chọn: ${selectedFile.name}`"
                                    size="small"
                                />
                            </span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-lg-3">
                    <label class="pull-right" style="font-weight: 700"
                        >Vui lòng chọn file mẫu</label
                    >
                </div>
                <div class="col-lg-9">
                    <label>
                        <a
                            tittle="download file template"
                            href="https://dongbo.csdl.edu.vn/exportExcel/Template_Import_mat_khau_ket_chuyen_len_Bo.xlsx"
                        >
                            <b>
                                <i style="color: var(--teal)"
                                    >File template mẫu.</i
                                >
                            </b>
                        </a>
                    </label>
                    <br />
                    <span>
                        <b>Ghi chú:</b>
                        <i
                            ><span style="color: red"
                                >Vui lòng lựa chọn tệp dạng *.xlsx</span
                            ></i
                        ></span
                    >
                </div>
            </div>
        </div>
        <div class="layout-btn-import">
            <CustomButton
                label="Đóng"
                size="small"
                @click="closeModalImport"
                class="btn-default btn-close-modal"
            />
            <CustomButton
                label="Nhập"
                size="small"
                @click="handleImport"
                class="btn-default btn-import"
            />
        </div>
    </el-dialog>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import Api from '@/constants/Api'
import sendRequest from '@/services'
import { mapState } from 'vuex'
export default {
    props: {
        importModalVisible: {
            type: Boolean,
            required: true
        },

        closeModalImport: {
            type: Function,
            required: true
        }
    },

    components: { CustomButton },
    data() {
        return {
            selectedFile: null
        }
    },

    methods: {
        handleFileChange(event) {
            const file = event.target.files[0]
            if (file && file.name.endsWith('.xlsx')) {
                this.selectedFile = file
            } else {
                this.clearFile()

                this.selectedFile = null
                alert(
                    'Vui lòng chọn file excel. Vui lòng chọn tệp dạng .xlsx file.'
                )
            }
        },
        clearFile() {
            // Xóa giá trị file đã chọn
            this.selectedFile = null

            // Xóa giá trị input file để cho phép người dùng chọn lại file
            const fileInput = this.$refs.fileInput
            if (fileInput) {
                fileInput.value = ''
            }
        },
        async handleImport() {
            if (this.selectedFile === null) {
                this.$message({
                    type: 'error',
                    message: 'Vui lòng thêm file import'
                })
                return
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })

            const request_Header = {
                'Content-Type': 'multipart/form-data',
                token: this.authToken
            }

            this.$confirm('Xác nhận Import File?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const formData = new FormData()
                    formData.append('file', this.selectedFile)

                    const response = await sendRequest(
                        Api.ketChuyenDuLieuLenBo.matKhauKetChuyen.importFile,
                        formData,
                        request_Header,
                        null
                    )
                    if (response.code == 200) {
                        loading.close()
                        this.$message({
                            type: 'success',
                            message: 'Import thành công'
                        })
                    } else {
                        loading.close()
                        this.$message({
                            type: 'error',
                            message: response.message
                        })
                    }
                })
                .catch((error) => {
                    loading.close()
                    if (error.response?.data.rd) {
                        this.$message({
                            type: 'error',
                            message: error.response?.data.rd
                        })
                    }
                })
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped>
.title-file {
    font-size: 14px;
    color: black;
}
.layout-btn-file {
    padding: 5px 10px 5px 10px;
    cursor: pointer;
    background-color: #87cefa;
    border-radius: 4px;
}
.xoafile {
    color: black;
    border: 1px solid black;
    padding: 0 4px;
    margin: 0;
    border-radius: 50%;
    background: snow;
    font-weight: 500;
}
.layout-btn-import {
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.btn-file {
    background-color: var(--teal);
}
.btn-import {
    background-color: var(--light-green);
}

.btn-close-modal {
    background-color: var(--orange);
}
</style>
