<template>
    <el-dialog :visible="updateVisible" @close="closeModalUpdate" width="50%">
        <span slot="title"
            >CHỈNH SỬA CẤU HÌNH NĂM HỌC {{ selectedValueSchoolYear }} -
            {{ parseInt(selectedValueSchoolYear) + 1 }}
            <hr />
        </span>
        <span>Thời gian đẩy dữ liệu:</span>

        <div class="input-group input-daterange mt-2">
            <el-date-picker
                v-model="startDate"
                type="date"
                placeholder="Ngày bắt đầu"
                style="width: 45%"
                format="dd/MM/yyyy"
            >
            </el-date-picker>
            <div class="input-group-addon">đến</div>
            <el-date-picker
                v-model="endDate"
                type="date"
                placeholder="Ngày kết thúc"
                style="width: 45%"
                format="dd/MM/yyyy"
            >
            </el-date-picker>
        </div>
        <div class="layout-btn">
            <CustomButton
                label="Đóng"
                class="btn-close btn-default"
                @click="closeModalUpdate"
                size="small"
            />
            <CustomButton
                label="Chỉnh sửa"
                class="btn-update btn-default"
                size="small"
                @click="handleUpdate"
            />
        </div>
    </el-dialog>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import Api from '@/constants/Api'
import sendRequest from '@/services'
import moment from 'moment'
import { mapState } from 'vuex'
export default {
    props: {
        updateVisible: {
            type: Boolean,
            required: true
        },

        closeModalUpdate: {
            type: Function,
            required: true
        },
        selectedValueSchoolYear: {
            type: String,
            default: null // Cho phép giá trị null
        },
        dataModalUpdate: {
            type: Object,
            required: true
        },
        getDataTable: Function
    },
    components: { CustomButton },
    watch: {
        dataModalUpdate(newValue) {
            this.startDate = moment(newValue.dauNamTuNgay, 'DD/MM/YYYY').toDate()

            this.endDate = moment(newValue.dauNamToiNgay, 'DD/MM/YYYY').toDate()
            this.ids = newValue.id
        }
    },
    data() {
        return {
            startDate: '',
            endDate: '',
            ids: ''
        }
    },

    methods: {
        handleUpdate() {
            let formattedStartDate = ''
            let formattedEndDate = ''

            if (this.startDate) {
                formattedStartDate = moment(this.startDate).format('DD/MM/YYYY')
            }

            if (this.endDate) {
                formattedEndDate = moment(this.endDate).format('DD/MM/YYYY')
            }
            if (formattedStartDate === '' && formattedEndDate === '') {
                this.$message({
                    type: 'error',
                    message: 'Vui lòng bổ sung thời hạn tiếp nhận dữ liệu'
                })
                return
            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                namHoc: this.selectedValueSchoolYear,
                denNgay: formattedEndDate,
                idSo: this.authUser.ma_so,
                ids: [this.ids],

                status: null,
                tuNgay: formattedStartDate
            }
            this.$confirm('Xác nhận thay đổi dữ liệu?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })
                .then(async () => {
                    const response = await sendRequest(
                        Api.tiepNhanDuLieuQLNT.cauHinhLichDongBo.thayDoiNgay,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        loading.close()
                        this.$message({
                            type: 'success',
                            message:
                                'Chỉnh sửa cấu hình năm học cấp sở thành công'
                        })
                        this.closeModalUpdate()
                        this.$emit('getDataTable')
                    }
                })
                .catch((error) => {
                    loading.close()
                    if (error.response?.data.rd) {
                        this.$message({
                            type: 'error',
                            message: error.response?.data.rd
                        })
                    }
                })
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        })
    }
}
</script>
<style scoped>
hr {
    border: 1px solid #ababc0;
}

.input-group-addon {
    padding: 10px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.btn-add {
    background-color: var(--teal);
}
.btn-import,
.btn-unlock {
    background-color: var(--light-green);
}

.btn-update {
    background-color: var(--yellow);
}
.btn-close,
.btn-lock {
    background-color: var(--orange);
}
.btn-warning {
    background: var(--btn-warning-bg);
}

.btn-info {
    background-color: var(--btn-info-bg);
}

.btn-light {
    background-color: var(--btn-light-bg);
}
.btn-unlock,
.btn-lock {
    margin-right: 10px;
}
</style>
