<template>
    <div>
        <CustomBreadcrumb :title="'Tra cứu'" content="Tra cứu kết quả học tập" />
        <CustomContentUI>
            <div class="row">
                <div class="col-4">
                    <label>Đơn vị quản lý</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="
                                this.authUser.role === 5 ||
                                this.authUser.role === 4
                            "
                            :data="this.dataChonDonVi_Store"
                            :placeholder="'Chọn đơn vị'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenDonVi', 'maDonVi']"
                            v-model="selectedValue.selectedValueUnitEducation"
                            @change="getDataChonTruongHoc"
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Cấp học</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="this.authUser.role === 5"
                            :data="getDataESelect.ESelectGradeLevel"
                            :placeholder="'Chọn cấp học'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenTruongHoc', 'value']"
                            v-model="selectedValue.selectedValueGradeLevel"
                            @change="getDataChonTruongHoc"
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Khối học/Nhóm học</label>
                    <div>
                        <ESelect
                            :reset="resetESelectSchoolBlock"
                            @reset-completed="handleResetSchoolBlockCompleted"
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectSchoolBlock"
                            :placeholder="'Chọn khối học'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['title', 'value']"
                            v-model="selectedValue.selectedValueSchoolBlock"
                        />
                    </div>
                </div>
                <div class="col-4">
                    <label>Trường học</label>
                    <div>
                        <ESelect
                            :reset="resetESelectSchool"
                            @reset-completed="handleResetSchoolCompleted"
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="this.authUser.role === 5"
                            :data="getDataESelect.ESelectSchool"
                            :placeholder="'Chọn trường học'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['title', 'value']"
                            v-model="selectedValue.selectedValueSchool"
                        />
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-4">
                    <label>Từ khóa</label>

                    <CustomInput
                        placeholder="Tên/mã/số CCCD..."
                        size="small"
                        v-model="selectedValue.input_tuKhoa"
                        :disabled="false"
                        :clearable="false"
                        :show-close-icon="true"
                    >
                    </CustomInput>
                </div>
                <div class="col-4">
                    <label>Giai đoạn</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="this.dataChonGiaiDoan_Store"
                            :placeholder="'Chọn '"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['constantTitle', 'mappingCode']"
                            v-model="selectedValue.selectedValueStage"
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Học kỳ</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            no-match-text="Không tìm thấy bản ghi nào"
                            no-data-text="danh sách lựa chọn trống"
                            :clearable="true"
                            :disabled="false"
                            :data="getDataESelect.ESelectSemester"
                            :placeholder="'Chọn '"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['tenHocKy', 'value']"
                            v-model="selectedValue.selectedValueSemester"
                        />
                    </div>
                </div>
                <div class="col-2">
                    <label>Năm học<span class="span">*</span></label>
                    <div>
                        <ESelectYear
                            v-model="selectedValue.selectedValueYear"
                            placeholder="Chọn năm"
                            size="small"
                            :no-data-text="'Không có bản ghi nào'"
                            :no-match-text="'Không tìm thấy bản ghi nào'"
                        >
                        </ESelectYear>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-4">
                    <label>Tìm kiếm nâng cao</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectAdvancedSearch"
                        :placeholder="'Chọn nội dung'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueAdvancedSearch"
                        @input="handleTimKiemNangCao"
                    />
                </div>
                <div class="col-4">
                    <label>Nội dung hiển thị <span class="span">*</span></label>

                    <ESelectSearch
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectDisplayContent"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['title', 'key', 'value']"
                        v-model="selectedValue.selectedValueDisplayContent"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-2 mt-3" v-if="hienThiNangCao.khamSkDky">
                    <label>Khám sức khỏe định kỳ</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectNutritionalHealthCheck"
                        :placeholder="'Chọn nội dung'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="
                            selectedValue.selectedValueNutritionalHealthCheck
                        "
                    />
                </div>
                <div class="col-2 mt-3" v-if="hienThiNangCao.hthanhCtrinhLhoc">
                    <label>HT c.trình lớp học</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectCompleteTheClassProgram"
                        :placeholder="'Chọn nội dung'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="
                            selectedValue.selectedValueCompleteTheClassProgram
                        "
                    />
                </div>
                <div
                    class="col-2 mt-3"
                    v-if="hienThiNangCao.hoanThanhChuongTrinhMamMon"
                >
                    <label>HT c.trình MN</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectCompletePreschoolProgram"
                        :placeholder="'Chọn nội dung'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="
                            selectedValue.selectedValueCompletePreschoolProgram
                        "
                    />
                </div>
                <div
                    class="col-2 mt-3"
                    v-if="hienThiNangCao.hoanThanhChuongTrinhTieuHoc"
                >
                    <label>HT c.trình TH</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectCompletePrimaryEducation"
                        :placeholder="'Chọn nội dung'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="
                            selectedValue.selectedValueCompletePrimaryEducation
                        "
                    />
                </div>
                <div class="col-2 mt-3" v-if="hienThiNangCao.lamQuenThoc">
                    <label>Làm quen tin học</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectGetAcquaintedWithInformatics"
                        :placeholder="'Chọn nội dung'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="
                            selectedValue.selectedValueGetAcquaintedWithInformatics
                        "
                    />
                </div>
                <div class="col-2 mt-3" v-if="hienThiNangCao.lenLop">
                    <label>Lên lớp</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectGoToClass"
                        :placeholder="'Chọn nội dung'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueGoToClass"
                    />
                </div>
                <div class="col-2 mt-3" v-if="hienThiNangCao.phoiIchayHhap">
                    <label>Có bệnh về ỉa chảy, h.hấp</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="
                            getDataESelect.ESelectHaveDiarrheaAndRespiratoryDisease
                        "
                        :placeholder="'Chọn nội dung'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="
                            selectedValue.selectedValueHaveDiarrheaAndRespiratoryDisease
                        "
                    />
                </div>
                <div class="col-2 mt-3" v-if="hienThiNangCao.suyDduongTheTcoi">
                    <label>Suy d.dưỡng thể thấp còi</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectMalnutritionStunting"
                        :placeholder="'Chọn nội dung'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueMalnutritionStunting"
                    />
                </div>
                <div class="col-2 mt-3" v-if="hienThiNangCao.theoDoiBdoCcao">
                    <label>Theo dõi biểu đồ c.cao</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectFollowTheHeightChart"
                        :placeholder="'Chọn nội dung'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueFollowTheHeightChart"
                    />
                </div>
                <div class="col-2 mt-3" v-if="hienThiNangCao.theoDoiBdoCnang">
                    <label>Theo dõi biểu đồ c.nặng</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectWeightChartTracking"
                        :placeholder="'Chọn nội dung'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueWeightChartTracking"
                    />
                </div>
                <div class="col-2 mt-3" v-if="hienThiNangCao.maHocLuc">
                    <label>Học lực</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectAcademicAbility"
                        :placeholder="'Chọn nội dung'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueAcademicAbility"
                    />
                </div>
                <div class="col-2 mt-3" v-if="hienThiNangCao.maHanhKiem">
                    <label>Hạnh kiểm</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectConduct"
                        :placeholder="'Chọn nội dung'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueConduct"
                    />
                </div>
                <div class="col-2 mt-3" v-if="hienThiNangCao.maDanhHieu">
                    <label>Danh hiệu</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectTitle"
                        :placeholder="'Chọn nội dung'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueTitle"
                    />
                </div>
                <div class="col-2 mt-3" v-if="hienThiNangCao.renLuyenLai">
                    <label>Rèn luyện lại</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectReTraining"
                        :placeholder="'Chọn nội dung'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueReTraining"
                    />
                </div>
                <div class="col-2 mt-3" v-if="hienThiNangCao.soNgayNghi">
                    <label>Số ngày nghỉ</label>
                    <el-input
                        v-model="selectedValue.input_soNgayNghi"
                        placeholder="Nhập..."
                        size="small"
                        type="number"
                    >
                    </el-input>
                </div>
                <div class="col-2 mt-3" v-if="hienThiNangCao.thiLai">
                    <label>Thi lại</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectRetest"
                        :placeholder="'Chọn nội dung'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueRetest"
                    />
                </div>
                <div class="col-2 mt-3" v-if="hienThiNangCao.totNghiep">
                    <label>Tốt nghiệp</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectGraduate"
                        :placeholder="'Chọn nội dung'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueGraduate"
                    />
                </div>
                <div class="col-2 mt-3" v-if="hienThiNangCao.maLoaiTotNghiep">
                    <label>Loại tốt nghiệp</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="this.dataChonLoaiTotNghiep_Store"
                        :placeholder="'Chọn nội dung'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['constantTitle', 'mappingCode']"
                        v-model="selectedValue.selectedValueGraduationType"
                    />
                </div>
                <div class="col-2 mt-3" v-if="hienThiNangCao.duXetTotNghiep">
                    <label>Dự xét tốt nghiệp</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectGraduationConsideration"
                        :placeholder="'Chọn nội dung'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="
                            selectedValue.selectedValueGraduationConsideration
                        "
                    />
                </div>
                <div class="col-2 mt-3" v-if="hienThiNangCao.moetCode">
                    <label>Mã định danh bộ GD&ĐT</label>
                    <el-input
                        v-model="selectedValue.input_maDinhDanhboGDDT"
                        placeholder="Nhập Mã định danh bộ GD&ĐT ..."
                        size="small"
                    >
                    </el-input>
                </div>
                <div class="col-2 mt-3" v-if="hienThiNangCao.trangThai">
                    <label>Trạng thái</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectStatus"
                        :placeholder="'Chọn nội dung'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueStatus"
                    />
                </div>
                <div class="col-2 mt-3" v-if="hienThiNangCao.danhGiaKqGiaoDuc">
                    <label>Đánh giá kết quả Giáo dục</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectevaluateEducationalOutcomes"
                        :placeholder="'Chọn nội dung'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueEducationalOutcomes"
                    />
                </div>
                <div class="col-2 mt-3" v-if="hienThiNangCao.tenLopHoc">
                    <label>Tên lớp học</label>
                    <el-input
                        v-model="selectedValue.input_tenLopHoc"
                        placeholder="Nhập  ..."
                        size="small"
                    >
                    </el-input>
                </div>
                <div
                    class="col-2 mt-3"
                    v-if="hienThiNangCao.isSuyDduongTheNheCan"
                >
                    <label>Suy d.dưỡng thể nhẹ cân</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectUnderweightMalnutrition"
                        :placeholder="'Chọn nội dung'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="
                            selectedValue.selectedValueUnderweightMalnutrition
                        "
                    />
                </div>
                <div
                    class="col-2 mt-3"
                    v-if="hienThiNangCao.isSuyDduongTheCoiCoc"
                >
                    <label>Suy d.dưỡng thể còi cọc</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectMalnutritionStuntedBody"
                        :placeholder="'Chọn nội dung'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="
                            selectedValue.selectedValueMalnutritionStuntedBody
                        "
                    />
                </div>
                <div class="col-2 mt-3" v-if="hienThiNangCao.beoPhi">
                    <label>Béo phì</label>
                    <ESelect
                        style="width: 100%"
                        no-match-text="Không tìm thấy bản ghi nào"
                        no-data-text="danh sách lựa chọn trống"
                        :clearable="true"
                        :disabled="false"
                        :data="getDataESelect.ESelectFat"
                        :placeholder="'Chọn nội dung'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="selectedValue.selectedValueFat"
                    />
                </div>
            </div>

            <div class="btn-search">
                <CustomButton
                    label="Tìm kiếm"
                    class="btn-default bg-search"
                    size="small"
                    @click="handleSearch"
                />
                <CustomButton
                    label="Xuất Excel"
                    class="btn-default"
                    size="small"
                    @click="handleExportExcel"
                    style="background-color: #1e6050"
                />
            </div>
        </CustomContentUI>

        <div
            class="layout-btn"
            :style="{
                'max-width': leftBarWidth()
            }"
        >
            <div>
                <chonSoLuongBanGhi @chonXongSoLuong="ChonSoLuongBanGhi($event)" />
            </div>
            <div>
                <CustomButton
                    label="Xóa"
                    size="small"
                    class="btn-default btn-delete"
                    @click="handleDelete"
                />
            </div>
        </div>

        <CustomTable>
            <template v-slot:header>
                <tr>
                    <th class="text-thead">
                        <input
                            type="checkbox"
                            v-model="selectAllChecked"
                            @change="handleSelectAll"
                            :disabled="tableData.length === 0"
                        />
                    </th>
                    <th class="text-thead">STT</th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(1)">
                        Mã trường
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(2)">
                        Trường học
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(3)">
                        Cấp học
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(4)">
                        Khối học/Nhóm tuổi
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(5)">
                        Tên lớp học
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(6)">
                        Họ và tên
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(7)">
                        Mã học sinh
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(8)">
                        Mã định danh Bộ GD&ĐT
                    </th>
                    <th class="text-thead" v-if="defaultDisplayTable.includes(9)">
                        Trạng thái
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(10)"
                    >
                        Điểm tổng kết
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(11)"
                    >
                        Năm học
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(12)"
                    >
                        Học kỳ
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(13)"
                    >
                        Giai đoạn
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(14)"
                    >
                        Học lực
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(15)"
                    >
                        Hạnh kiểm
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(16)"
                    >
                        Danh hiệu
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(17)"
                    >
                        HT c.trình lớp học
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(18)"
                    >
                        HT c.trình MN
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(19)"
                    >
                        HT c.trình TH
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(20)"
                    >
                        Khen thưởng cuối năm
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(21)"
                    >
                        Khen thưởng đột xuất
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(22)"
                    >
                        Lên lớp
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(23)"
                    >
                        Rèn luyện lại
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(24)"
                    >
                        Thi lại
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(25)"
                    >
                        Dự xét tốt nghiệp
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(26)"
                    >
                        Tốt nghiệp
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(27)"
                    >
                        Loại tốt nghiệp
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(28)"
                    >
                        Số ngày nghỉ
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(29)"
                    >
                        Kết quả học tập
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(30)"
                    >
                        Kết quả rèn luyện
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(41)"
                    >
                        Khám sức khỏe định kỳ
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(37)"
                    >
                        Đánh giá Tiếng Việt
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(38)"
                    >
                        Loại chứng chỉ nghề
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(39)"
                    >
                        Khen thưởng cấp huyện
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(42)"
                    >
                        Suy dinh dưỡng thể nhẹ cân
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(43)"
                    >
                        Suy dinh dưỡng thể còi cọc
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(44)"
                    >
                        Béo phì
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(45)"
                    >
                        Suy dinh dưỡng thể thấp còi
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(46)"
                    >
                        Theo dõi biểu đồ chiều cao
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(47)"
                    >
                        Theo dõi biểu đồ cân nặng
                    </th>
                    <th
                        class="text-thead"
                        v-if="defaultDisplayTable.includes(48)"
                    >
                        Có bệnh về ỉa chảy, hô hấp
                    </th>
                    <!-- <th class="text-thead">Tùy chọn</th> -->
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="36" class="text-tbody">
                            Không có bản ghi nào.
                        </td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td class="text-tbody">
                        <input
                            type="checkbox"
                            :checked="item.isChecked"
                            @change="toggleCheckbox(item)"
                        />
                    </td>
                    <td class="text-tbody">
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-tbody" v-if="defaultDisplayTable.includes(1)">
                        {{ item.maTruongHoc }}
                    </td>
                    <td class="text-tbody" v-if="defaultDisplayTable.includes(2)">
                        {{ item.tenTruongHoc }}
                    </td>
                    <td class="text-tbody" v-if="defaultDisplayTable.includes(3)">
                        {{ item.capHoc }}
                    </td>
                    <td class="text-tbody" v-if="defaultDisplayTable.includes(4)">
                        {{ item.khoiHoc }}
                    </td>
                    <td class="text-tbody" v-if="defaultDisplayTable.includes(5)">
                        {{ item.tenLopHoc }}
                    </td>
                    <td
                        class="text-tbody"
                        style="text-align: left"
                        v-if="defaultDisplayTable.includes(6)"
                    >
                        <span
                            class="text-info"
                            @click="handleOpenModalChitietKQHT(item.tongKetHsId)"
                        >
                            {{ item.tenHocSinh }}
                        </span>
                    </td>
                    <td class="text-tbody" v-if="defaultDisplayTable.includes(7)">
                        <span
                            class="text-info"
                            @click="handleOpenModalChitietKQHT(item.tongKetHsId)"
                        >
                            {{ item.maHocSinh }}
                        </span>
                    </td>
                    <td class="text-tbody" v-if="defaultDisplayTable.includes(8)">
                        {{ item.moetCode }}
                    </td>
                    <td class="text-tbody" v-if="defaultDisplayTable.includes(9)">
                        {{ item.trangThai }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(10)"
                    >
                        {{ item.diemTongKet }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(11)"
                    >
                        {{ item.namHoc }}-{{ item.namHoc + 1 }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(12)"
                    >
                        {{ getCustomHocKy(item.hocKy) }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(13)"
                    >
                        {{ item.giaiDoan }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(14)"
                    >
                        {{ item.maHocLuc }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(15)"
                    >
                        {{ item.maHanhKiem }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(16)"
                    >
                        {{ item.maDanhHieu }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(17)"
                    >
                        {{ item.hthanhCtrinhLhoc }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(18)"
                    >
                        {{ item.hoanThanhChuongTrinhMamMon }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(19)"
                    >
                        {{ item.hoanThanhChuongTrinhTieuHoc }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(20)"
                    >
                        {{ item.kthuongCnam }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(21)"
                    >
                        {{ item.kthuongDxuat }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(22)"
                    >
                        {{ item.lenLop }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(23)"
                    >
                        {{ item.renLuyenLai }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(24)"
                    >
                        {{ item.thiLai }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(25)"
                    >
                        {{ item.duXetTotNghiep }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(26)"
                    >
                        {{ item.totNghiep }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(27)"
                    >
                        {{ item.maLoaiTotNghiep }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(28)"
                    >
                        {{ item.soNgayNghi }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(29)"
                    >
                        {{ item.kqHocTap }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(30)"
                    >
                        {{ item.kqRenLuyen }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(31)"
                    >
                        {{ item.khamSkDky }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(37)"
                    >
                        <!-- Đánh giá Tiếng Việt -->
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(38)"
                    >
                        {{ item.LoaiChungChiNghe }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(39)"
                    >
                        {{ item.khenThuongCapHuyen }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(42)"
                    >
                        {{ item.isSuyDduongTheNheCan }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(43)"
                    >
                        {{ item.isSuyDduongTheCoiCoc }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(44)"
                    >
                        {{ item.beoPhi === false ? 'Không' : 'Có' }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(45)"
                    >
                        {{ item.suyDduongTheTcoi }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(46)"
                    >
                        {{ item.theoDoiBdoCcao }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(47)"
                    >
                        {{ item.theoDoiBdoCnang }}
                    </td>
                    <td
                        class="text-tbody"
                        v-if="defaultDisplayTable.includes(48)"
                    >
                        {{ item.phoiIchayHhap }}
                    </td>
                    <!-- <td class="text-tbody">
                            <CustomButton
                                size="small"
                                class="btn-default btn-info"
                                @click="handleOpenModalXemDiem(item)"
                                label="Xem điểm"
                            />
                        </td> -->
                </tr>
            </template>
        </CustomTable>
        <CustomPagination
            v-show="total_rows > 0"
            :tongbanghi="total_rows"
            :soluonghienthi="soLuongBanGhiHienThi"
            :batdau="trangbatdau"
            @pageChange="layLai($event)"
        >
        </CustomPagination>
        <ModalChiTietKQHT
            :chiTietKQHTModalVisible="chiTietKQHTModalVisible"
            :closeModalChitietKQHT="closeModalChitietKQHT"
            :id_modal="id_modal"
        />
        <ModalXemDiem
            :xemDiemModalVisible="xemDiemModalVisible"
            :closeModalXemDiem="closeModalXemDiem"
            :dataModalXemDiem="dataModalXemDiem"
        />
    </div>
</template>
<script>
import axios from 'axios'
import CustomBreadcrumb from '@/components/CustomBreadcrumb.vue'
import ESelectYear from '@/components/ESelectYear.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import ESelectSearch from '@/components/ESelectSearch.vue'
import chonSoLuongBanGhi from '@/components/chonSoLuongBanGhi.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import Api from '@/constants/Api'
import ModalChiTietKQHT from './ModalChiTietKQHT.vue'
import ModalXemDiem from './ModalXemDiem.vue'
import {
    ESlectYesOfNo_MockData,
    ESelectTrueOfFalse,
    ESelectComplete_MockData_TraCuuKQHT,
    ESelectSchoolBlock_MockData,
    ESelectSemester_MockData,
    ESelectAdvancedSearch_MockData_TraCuuKQHT,
    ESelectDisplayContent_MockData_TraCuuKQHT,
    ESelectectESelectAcademicAbility_MockData,
    ESelectConduct_MockData,
    ESelectTitle_MockData,
    ESelectevaluateEducationalOutcomes_TraCuuKQHT,
    ESelectStatus_MockData_TraCuuKQHT
} from '@/mock_data'
import sendRequest from '@/services'
import { mapState } from 'vuex'
import { convertCapHocList, currentYear, filterCapHocList } from '@/utils'
import CustomContentUI from '@/components/CustomContentUI.vue'
import CustomInput from '@/components/CustomInput.vue'
import CustomTable from '@/components/CustomTable.vue'

export default {
    name: 'TraCuuTruongHoc',
    components: {
        CustomBreadcrumb,
        ESelect,
        ESelectSearch,
        ESelectYear,
        CustomButton,
        CustomPagination,
        chonSoLuongBanGhi,
        ModalChiTietKQHT,
        ModalXemDiem,
        CustomContentUI,
        CustomInput,
        CustomTable
    },
    data() {
        return {
            defaultDisplayTable: ESelectDisplayContent_MockData_TraCuuKQHT.slice(
                0,
                16
            ).map((item) => item.value),
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            selectAllChecked: false,
            selectedItems: [],
            soLuongBanGhiHienThi: 25,
            trangbatdau: false,

            chiTietKQHTModalVisible: false,
            id_modal: 0,
            resetESelectSchool: false,
            resetESelectSchoolBlock: false,

            xemDiemModalVisible: false,
            dataModalXemDiem: {},
            hienThiNangCao: {
                khamSkDky: false,
                hthanhCtrinhLhoc: false,
                hoanThanhChuongTrinhMamMon: false,
                hoanThanhChuongTrinhTieuHoc: false,
                lamQuenThoc: false,
                lenLop: false,
                phoiIchayHhap: false,
                suyDduongTheTcoi: false,
                theoDoiBdoCcao: false,
                theoDoiBdoCnang: false,
                maHocLuc: false,
                maHanhKiem: false,
                maDanhHieu: false,
                renLuyenLai: false,
                soNgayNghi: false,
                thiLai: false,
                totNghiep: false,
                maLoaiTotNghiep: false,
                duXetTotNghiep: false,
                moetCode: false,
                trangThai: false,
                danhGiaKqGiaoDuc: false,
                tenLopHoc: false,
                isSuyDduongTheNheCan: false,
                isSuyDduongTheCoiCoc: false,
                beoPhi: false
            },

            tableData: [],

            getDataESelect: {
                ESelectUnitEducation: [], //donvi
                ESelectGradeLevel: [], //caphoc
                ESelectSchoolBlock: ESelectSchoolBlock_MockData, //khoihoc/nhomlop
                ESelectSchool: [], //truonghoc
                ESelectStage: [], //giaidoan
                ESelectSemester: ESelectSemester_MockData, //hocky
                ESelectAdvancedSearch: ESelectAdvancedSearch_MockData_TraCuuKQHT, //timkiemnangcao
                ESelectDisplayContent: ESelectDisplayContent_MockData_TraCuuKQHT, //noidunghienthi

                ESelectNutritionalHealthCheck: ESelectTrueOfFalse, //kiemtrasuckhoe
                ESelectCompleteTheClassProgram:
                    ESelectComplete_MockData_TraCuuKQHT, //hoanthanhchuongtrinhlophoc
                ESelectCompletePreschoolProgram:
                    ESelectComplete_MockData_TraCuuKQHT, //hoanthanhchuongtrinhmamnon
                ESelectCompletePrimaryEducation:
                    ESelectComplete_MockData_TraCuuKQHT, //hoanthanhchuongtrinhtieuhoc
                ESelectGetAcquaintedWithInformatics: ESelectTrueOfFalse, //lamquentinhoc
                ESelectGoToClass: ESelectTrueOfFalse, //lenlop

                ESelectHaveDiarrheaAndRespiratoryDisease: ESelectTrueOfFalse, //cobenhveiachayvahohap
                ESelectMalnutritionStunting: ESelectTrueOfFalse, //suydinhduongthapcoi
                ESelectFollowTheHeightChart: ESelectTrueOfFalse, //theodoibieudochieucao
                ESelectWeightChartTracking: ESelectTrueOfFalse, //theodoibieudocannang
                ESelectAcademicAbility: ESelectectESelectAcademicAbility_MockData, //hocluc
                ESelectConduct: ESelectConduct_MockData, //hanhkiem

                ESelectTitle: ESelectTitle_MockData, //danhhieu
                ESelectReTraining: ESelectTrueOfFalse, //renluyenlai
                ESelectRetest: ESelectTrueOfFalse, //thilai
                ESelectGraduate: ESelectTrueOfFalse, //totnghiep
                ESelectGraduationType: [], //loaitotnghiep

                ESelectGraduationConsideration: ESelectTrueOfFalse, //duxettotnghiep
                ESelectStatus: ESelectStatus_MockData_TraCuuKQHT, //trangthai
                ESelectevaluateEducationalOutcomes:
                    ESelectevaluateEducationalOutcomes_TraCuuKQHT, //danhgiaketquagiaoduc
                ESelectVietnameseLanguageReview: [], //danhgiatiengviet
                ESelectUnderweightMalnutrition: ESelectTrueOfFalse, //suydinhduongthenhecan

                ESelectMalnutritionStuntedBody: ESelectTrueOfFalse, //suydinhduongthecoicoc
                ESelectFat: ESelectTrueOfFalse //beophi
            },

            selectedValue: {
                selectedValueUnitEducation: [], //donvi
                selectedValueGradeLevel: [], //caphoc
                selectedValueSchoolBlock: [], //khoihoc/nhomlop
                selectedValueSchool: [], //truonghoc
                input_tuKhoa: '', //tukhoa
                selectedValueStage: [], //giaidoan
                selectedValueSemester: [], //hocky
                selectedValueYear: '',

                selectedValueAdvancedSearch: [], //timkiemnangcao
                selectedValueDisplayContent:
                    ESelectDisplayContent_MockData_TraCuuKQHT.slice(0, 16), //noidunghienthi

                selectedValueNutritionalHealthCheck: [], //kiemtrasuckhoe
                selectedValueCompleteTheClassProgram: [], //hoanthanhchuongtrinhlophoc
                selectedValueCompletePreschoolProgram: [], //hoanthanhchuongtrinhmamnon
                selectedValueCompletePrimaryEducation: [], //hoanthanhchuongtrinhtieuhoc
                selectedValueGetAcquaintedWithInformatics: [], //lamquentinhoc
                selectedValueGoToClass: [], //lenlop

                selectedValueHaveDiarrheaAndRespiratoryDisease: [], //cobenhveiachayvahohap
                selectedValueMalnutritionStunting: [], //suydinhduongthapcoi
                selectedValueFollowTheHeightChart: [], //theodoibieudochieucao
                selectedValueWeightChartTracking: [], //theodoibieudocannang
                selectedValueAcademicAbility: [], //hocluc
                selectedValueConduct: [], //hanhkiem

                selectedValueTitle: [], //danhhieu
                selectedValueReTraining: [], //renluyenlai
                input_soNgayNghi: null, //songaynghi
                selectedValueRetest: [], //thilai
                selectedValueGraduate: [], //totnghiep
                selectedValueGraduationType: [], //loaitotnghiep

                selectedValueGraduationConsideration: [], //duxettotnghiep
                input_maDinhDanhboGDDT: '', // madinhdanhboGDDT
                selectedValueStatus: [], //trangthai
                selectedValueEducationalOutcomes: [], //danhgiaketquagiaoduc
                selectedValueVietnameseLanguageReview: [], //danhgiatiengviet
                input_tenLopHoc: '', //tenlophoc
                selectedValueUnderweightMalnutrition: [], //suydinhduongthenhecan

                selectedValueMalnutritionStuntedBody: [], //suydinhduongthecoicoc
                selectedValueFat: [] //beophi
            }
        }
    },
    methods: {
        handleTimKiemNangCao(value) {
            const selectedValues = value.map((item) => item.value)

            for (const key in this.hienThiNangCao) {
                this.hienThiNangCao[key] = selectedValues.includes(key)
            }
        },

        ChonSoLuongBanGhi(e) {
            this.total_rows = 0
            this.soLuongBanGhiHienThi = e.soluong
        },
        checkTruocKhiTim() {
            this.trangbatdau = !this.trangbatdau
        },
        layLai(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        toggleCheckbox(item) {
            this.selectAllChecked = false
            item.isChecked = !item.isChecked
            if (item.isChecked) {
                this.selectedItems.push(item) // Thêm item vào mảng selectedItems khi checkbox được chọn
            } else {
                const index = this.selectedItems.indexOf(item)
                if (index > -1) {
                    this.selectedItems.splice(index, 1) // Xóa item khỏi mảng selectedItems khi checkbox bị bỏ chọn
                }
            }
        },

        handleSelectAll() {
            this.tableData.forEach((item) => {
                item.isChecked = this.selectAllChecked // Đặt trạng thái của tất cả các checkbox bằng với checkbox "checkboxAll"
                if (this.selectAllChecked && !this.selectedItems.includes(item)) {
                    this.selectedItems.push(item) // Thêm tất cả các item vào mảng selectedItems khi chọn tất cả
                } else if (!this.selectAllChecked) {
                    this.selectedItems = [] // Xóa tất cả các item khỏi mảng selectedItems khi bỏ chọn tất cả
                }
            })
        },
        handleOpenModalChitietKQHT(id) {
            this.id_modal = id
            this.chiTietKQHTModalVisible = true
        },
        closeModalChitietKQHT() {
            this.chiTietKQHTModalVisible = false
        },
        handleOpenModalXemDiem(item) {
            this.dataModalXemDiem = item
            this.xemDiemModalVisible = true
        },
        closeModalXemDiem() {
            this.xemDiemModalVisible = false
        },
        handleResetSchoolCompleted() {
            this.resetESelectSchool = false
        },
        handleResetSchoolBlockCompleted() {
            this.resetESelectSchoolBlock = false
        },
        getCustomHocKy(hocKyValue) {
            const hocKy = ESelectSemester_MockData.find(
                (item) => item.value == hocKyValue
            )
            return hocKy ? hocKy.tenHocKy : hocKyValue // Nếu không tìm thấy hocKy, trả về giá trị hocKy ban đầu
        },
        async handleDelete() {
            const listMa = this.selectedItems.map((item) => item.tongKetHsId)
            if (listMa.length === 0) {
                this.$message({
                    type: 'warning',
                    message: 'Vui lòng chọn bản ghi cần xóa trong bảng'
                })
                return
            }
            const request_Header = {
                token: this.authToken
            }
            const request_Data = {
                listMa: listMa,
                namHoc:
                    this.selectedValue.selectedValueYear ||
                    new Date().getFullYear()
            }

            this.$confirm(
                `Xác nhận xóa ${listMa.length} bản ghi đã chọn? `,
                'Thông báo',
                {
                    confirmButtonText: 'Đồng ý',
                    cancelButtonText: 'Hủy',
                    type: 'warning'
                }
            )
                .then(async () => {
                    const loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    })
                    const response = await sendRequest(
                        Api.traCuu.traCuuKQHT.xoaKQHT,
                        request_Data,
                        request_Header
                    )
                    if (response.rc == 0) {
                        loading.close()
                        this.tableData = this.tableData.filter(
                            (item) => !item.isChecked
                        )

                        this.selectedItems = []
                        await this.handleSearch()
                        this.$message({
                            type: 'success',
                            message: `Xóa thành công ${response.countDeleted} bản ghi`
                        })
                    } else {
                        loading.close()
                        this.$message({
                            type: 'info',
                            message: response.rd
                        })
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    })
                })
        },
        async handleExportExcel() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const noidunghienthi = this.selectedValue.selectedValueDisplayContent
            const defaultDisplayTable = noidunghienthi.map((item) => item.value)
            this.defaultDisplayTable = defaultDisplayTable
            const colExcels = noidunghienthi.map((item) => ({
                key: item.key,
                stt: item.value,
                name: item.title
            }))

            const timkiemnangcao = this.convertToValueArray(
                this.selectedValue.selectedValueAdvancedSearch
            )

            const keyMappings_dataSreachList = {
                khamSkDky: 'selectedValueNutritionalHealthCheck',
                hthanhCtrinhLhoc: 'selectedValueCompleteTheClassProgram',
                hoanThanhChuongTrinhMamMon:
                    'selectedValueCompletePreschoolProgram',
                hoanThanhChuongTrinhTieuHoc:
                    'selectedValueCompletePrimaryEducation',
                lamQuenThoc: 'selectedValueGetAcquaintedWithInformatics',
                lenLop: 'selectedValueGoToClass',
                phoiIchayHhap: 'selectedValueHaveDiarrheaAndRespiratoryDisease',
                suyDduongTheTcoi: 'selectedValueMalnutritionStunting',
                theoDoiBdoCcao: 'selectedValueFollowTheHeightChart',
                theoDoiBdoCnang: 'selectedValueWeightChartTracking',
                maHocLuc: 'selectedValueAcademicAbility',
                maHanhKiem: 'selectedValueConduct',
                maDanhHieu: 'selectedValueTitle',
                renLuyenLai: 'selectedValueReTraining',
                soNgayNghi: 'input_soNgayNghi',
                thiLai: 'selectedValueRetest',
                totNghiep: 'selectedValueGraduate',
                maLoaiTotNghiep: 'selectedValueGraduationType',
                duXetTotNghiep: 'selectedValueGraduationConsideration',
                moetCode: 'input_maDinhDanhboGDDT',
                trangThai: 'selectedValueStatus',
                danhGiaKqGiaoDuc: 'selectedValueEducationalOutcomes',
                tenLopHoc: 'input_tenLopHoc',
                isSuyDduongTheNheCan: 'selectedValueUnderweightMalnutrition',
                isSuyDduongTheCoiCoc: 'selectedValueMalnutritionStuntedBody',
                beoPhi: 'selectedValueFat'
                // Thêm các ánh xạ key - giá trị tương ứng ở đây
            }

            const dataSearchList = timkiemnangcao
                .map((key) => {
                    const valueKey = keyMappings_dataSreachList[key]
                    const inputValue = this.selectedValue[valueKey]
                    if (valueKey && inputValue) {
                        let value
                        if (Array.isArray(inputValue)) {
                            value = inputValue.map((item) => item.value)
                            return {
                                key: key,
                                values: value
                            }
                        } else if (typeof inputValue === 'object') {
                            value = inputValue.value
                        } else {
                            value = inputValue
                        }
                        return {
                            key: key,
                            value: value
                        }
                    }
                    return null
                })
                .filter((item) => item !== null)
            const capHocs = this.convertToValueArray(
                this.selectedValue.selectedValueGradeLevel
            ).map(Number)
            const giaiDoan = this.selectedValue.selectedValueStage.value ?? ''
            const hocKy =
                parseInt(this.selectedValue.selectedValueSemester.value) || ''
            const keyword = this.selectedValue.input_tuKhoa ?? ''
            const khoiHocs = this.convertToValueArray(
                this.selectedValue.selectedValueSchoolBlock
            ).map(Number)
            const maPhongs = this.convertToValueArray(
                this.selectedValue.selectedValueUnitEducation
            )

            const maTruongHocs = this.convertToValueArray(
                this.selectedValue.selectedValueSchool
            )
            const namHoc =
                this.selectedValue.selectedValueYear || new Date().getFullYear()

            this.$confirm('Xác nhận tải xuống?', 'Thông báo', {
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                type: 'warning'
            })

                .then(async () => {
                    const requestData = {
                        start: 0,
                        limit: 50000,
                        capHocs: capHocs,
                        khoiHocs: khoiHocs,
                        hocKy: hocKy,
                        keyword: keyword,
                        giaiDoan: giaiDoan,
                        maTruongHocs: maTruongHocs,
                        namHoc: namHoc,
                        colExcels: colExcels,
                        maPhongs: maPhongs,
                        dataSreachList: dataSearchList
                    }
                    const domain = process.env.VUE_APP_BACKEND_BASE_URL
                    const response = await axios.post(
                        `${domain}/csdlgd-admin/ExportExcel/exportTraCuuKQHTHocSinh`,
                        requestData,
                        {
                            headers: {
                                token: this.authToken,
                                'Content-Type': 'application/json'
                            },
                            responseType: 'blob' // Set responseType là 'blob'
                        }
                    )

                    const blob = new Blob([response.data])
                    const blobUrl = window.URL.createObjectURL(blob)

                    const downloadLink = document.createElement('a')
                    downloadLink.href = blobUrl
                    downloadLink.download = 'DanhSachTraCuuKQHT.xlsx'
                    downloadLink.style.display = 'none'

                    document.body.appendChild(downloadLink)
                    downloadLink.click()

                    window.URL.revokeObjectURL(blobUrl)
                    loading.close()
                })
                .catch((error) => {
                    loading.close()
                    if (error.response?.data.rd) {
                        this.$message({
                            type: 'error',
                            message: error.response?.data.rd
                        })
                    }
                })
        },
        async handleSearch() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            try {
                const noidunghienthi =
                    this.selectedValue.selectedValueDisplayContent
                const defaultDisplayTable = noidunghienthi.map(
                    (item) => item.value
                )
                this.defaultDisplayTable = defaultDisplayTable
                const colExcels = noidunghienthi.map((item) => ({
                    key: item.key,
                    stt: item.value,
                    name: item.title
                }))

                const timkiemnangcao = this.convertToValueArray(
                    this.selectedValue.selectedValueAdvancedSearch
                )

                const keyMappings_dataSreachList = {
                    khamSkDky: 'selectedValueNutritionalHealthCheck',
                    hthanhCtrinhLhoc: 'selectedValueCompleteTheClassProgram',
                    hoanThanhChuongTrinhMamMon:
                        'selectedValueCompletePreschoolProgram',
                    hoanThanhChuongTrinhTieuHoc:
                        'selectedValueCompletePrimaryEducation',
                    lamQuenThoc: 'selectedValueGetAcquaintedWithInformatics',
                    lenLop: 'selectedValueGoToClass',
                    phoiIchayHhap:
                        'selectedValueHaveDiarrheaAndRespiratoryDisease',
                    suyDduongTheTcoi: 'selectedValueMalnutritionStunting',
                    theoDoiBdoCcao: 'selectedValueFollowTheHeightChart',
                    theoDoiBdoCnang: 'selectedValueWeightChartTracking',
                    maHocLuc: 'selectedValueAcademicAbility',
                    maHanhKiem: 'selectedValueConduct',
                    maDanhHieu: 'selectedValueTitle',
                    renLuyenLai: 'selectedValueReTraining',
                    soNgayNghi: 'input_soNgayNghi',
                    thiLai: 'selectedValueRetest',
                    totNghiep: 'selectedValueGraduate',
                    maLoaiTotNghiep: 'selectedValueGraduationType',
                    duXetTotNghiep: 'selectedValueGraduationConsideration',
                    moetCode: 'input_maDinhDanhboGDDT',
                    trangThai: 'selectedValueStatus',
                    danhGiaKqGiaoDuc: 'selectedValueEducationalOutcomes',
                    tenLopHoc: 'input_tenLopHoc',
                    isSuyDduongTheNheCan: 'selectedValueUnderweightMalnutrition',
                    isSuyDduongTheCoiCoc: 'selectedValueMalnutritionStuntedBody',
                    beoPhi: 'selectedValueFat'
                    // Thêm các ánh xạ key - giá trị tương ứng ở đây
                }

                const dataSearchList = timkiemnangcao
                    .map((key) => {
                        const valueKey = keyMappings_dataSreachList[key]
                        const inputValue = this.selectedValue[valueKey]
                        if (valueKey && inputValue) {
                            let value
                            if (Array.isArray(inputValue)) {
                                value = inputValue.map((item) => item.value)
                                return {
                                    key: key,
                                    values: value
                                }
                            } else if (typeof inputValue === 'object') {
                                value = inputValue.value
                            } else {
                                value = inputValue
                            }
                            return {
                                key: key,
                                value: value
                            }
                        }
                        return null
                    })
                    .filter((item) => item !== null)
                const capHocs = this.convertToValueArray(
                    this.selectedValue.selectedValueGradeLevel
                ).map(Number)
                const giaiDoan = this.selectedValue.selectedValueStage.value ?? ''
                const hocKy =
                    parseInt(this.selectedValue.selectedValueSemester.value) || ''
                const keyword = this.selectedValue.input_tuKhoa ?? ''
                const khoiHocs = this.convertToValueArray(
                    this.selectedValue.selectedValueSchoolBlock
                ).map(Number)
                const maPhongs = this.convertToValueArray(
                    this.selectedValue.selectedValueUnitEducation
                )

                const maTruongHocs = this.convertToValueArray(
                    this.selectedValue.selectedValueSchool
                )
                const namHoc = this.selectedValue.selectedValueYear

                const statr = this.start
                const limit = this.limit
                const request_Header = {
                    token: this.authToken
                }
                const request_Data = {
                    colExcels: colExcels,
                    dataSearchList: dataSearchList,
                    capHocs: capHocs,
                    giaiDoan: giaiDoan,
                    hocKy: hocKy,
                    keyword: keyword,
                    khoiHocs: khoiHocs,
                    limit: limit,
                    maPhongs: maPhongs,
                    maTruongHocs: maTruongHocs,
                    namHoc: namHoc || currentYear,
                    start: statr
                }

                const response = await sendRequest(
                    Api.traCuu.traCuuKQHT.danhSachTraCuuKQHT,
                    request_Data,
                    request_Header
                )
                if (response.rc == 0) {
                    loading.close()
                    this.$message({
                        type: 'success',
                        message: 'Danh sách tìm kiếm'
                    })
                    this.tableData = response.tableData.map((item) => {
                        return { ...item, isChecked: false }
                    })
                    this.total_rows = response.totalRows
                }
                if (response.rc != 0) {
                    loading.close()
                    this.$message({
                        type: 'warning',
                        message: response.rd
                    })
                    this.tableData = response.tableData
                    this.total_rows = response.totalRows
                }
            } catch (error) {
                loading.close()
                if (error.response?.data.rd) {
                    this.$message({
                        type: 'error',
                        message: error.response?.data.rd
                    })
                }
            }

            // console.log(response)
        },
        async getDataChonTruongHoc() {
            const maDonVi = this.convertToValueArray(
                this.selectedValue.selectedValueUnitEducation
            )

            const capHoc = this.convertToValueArray(
                this.selectedValue.selectedValueGradeLevel
            )

            // Định nghĩa ánh xạ giữa capHoc và mảng kết quả
            const capHocMapping = {
                1: [1, 2, 3, 4, 5],
                2: [6, 7, 8, 9],
                3: [10, 11, 12],
                4: [13, 14, 15],
                5: [16, 17, 18],
                45: [13, 14, 15, 16, 17, 18],
                6: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
            }

            let result = []
            if (capHoc.length > 0) {
                result = capHoc
                    .flatMap((key) => capHocMapping[key] || [])
                    .map((value) =>
                        ESelectSchoolBlock_MockData.find(
                            (item) => item.value === value
                        )
                    )
            } else {
                this.resetESelectSchoolBlock = true
                this.getDataESelect.ESelectSchoolBlock = []
                this.selectedValue.selectedValueSchoolBlock = []
                result = [...ESelectSchoolBlock_MockData]
            }

            // Gán mảng kết quả vào this.getDataESelect.ESelectSchoolBlock
            this.getDataESelect.ESelectSchoolBlock = result

            const request_Header = {
                token: this.authToken
            }

            const request_Data = {
                capHoc: capHoc,
                maDonVi: maDonVi
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            let response = await sendRequest(
                Api.internal_api.dm_School,
                request_Data,
                request_Header,
                null
            )

            if (response.statusResponse == 0) {
                loading.close()
                this.getDataESelect.ESelectSchool = response.rows.map((item) => ({
                    title: `${item.tenTruongHoc} - [${item.maTruongHoc}]`,
                    value: item.maTruongHoc
                }))
            } else {
                loading.close()
            }
        },
        convertToValueArray(array) {
            return array.map((item) => item.value)
        },
        leftBarWidth() {
            if (this.isCollapseLeftBar) {
                return 'calc(100vw - 105px)'
            } else {
                return 'calc(100vw - 340px)'
            }
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),
        ...mapState({
            authToken: (state) => state.auth.token
        }),
        dataChonDonVi_Store() {
            return JSON.parse(localStorage.getItem('data_ChonDonVi'))
        },
        dataChonGiaiDoan_Store() {
            return JSON.parse(localStorage.getItem('data_ChonGiaiDoanKqht'))
        },
        dataChonLoaiTotNghiep_Store() {
            return JSON.parse(localStorage.getItem('data_ChonLoaitotnghiep'))
        },
        ...mapState(['isCollapseLeftBar']),
        ...mapState({
            dataTo_KetChuyenDuLieu: (state) =>
                state.data_Navigate_KetChuyenDuLieu.traCuuKQHT
        })
    },
    watch: {
        'authUser.role': {
            immediate: true,
            handler(newRole, oldRole) {
                if (newRole === 5) {
                    this.selectedValue.selectedValueUnitEducation = [
                        { value: this.authUser.ma_don_vi }
                    ]
                    const convertedList = convertCapHocList(
                        this.authUser.capHocList
                    )
                    this.selectedValue.selectedValueGradeLevel = convertedList
                    this.selectedValue.selectedValueSchool = [
                        { value: this.authUser.ma_truong_hoc }
                    ]
                }
                if (newRole === 4) {
                    this.selectedValue.selectedValueUnitEducation = [
                        { value: this.authUser.ma_don_vi }
                    ]
                }
            }
        },
        dataTo_KetChuyenDuLieu: {
            immediate: true,
            handler(newValues) {
                if (
                    newValues?.maTruongHoc &&
                    newValues &&
                    this.authUser.role !== 5
                ) {
                    this.selectedValue.selectedValueYear =
                        newValues?.namHoc.toString()
                    this.selectedValue.selectedValueSchool = [
                        { value: newValues.maTruongHoc }
                    ]
                }
            }
        },
        'selectedValue.selectedValueUnitEducation': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        },
        'selectedValue.selectedValueGradeLevel': {
            handler(newVal, oldVal) {
                if (oldVal.length !== newVal.length) {
                    this.resetESelectSchool = true
                }
            },
            deep: true
        }
    },
    mounted() {
        this.getDataESelect.ESelectGradeLevel = filterCapHocList(
            this.authUser.capHocList
        )
        this.handleSearch()
        this.getDataChonTruongHoc()
    }
}
</script>

<style scoped>
.layout-btn-add {
    padding: 20px 10px 5px 10px;
    margin: 0 auto;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
}
.layout-btn-search {
    margin-top: 15px;
    margin-bottom: 5px;
}
.btn-search {
    margin-top: 20px;
    justify-content: center;
    display: flex;
}
.layout-btn {
    padding: 20px 10px 5px 10px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}
.text-info {
    color: #337ab7;
    cursor: pointer;
}
.btn-default {
    font-weight: var(--btn-font-weight);
    color: var(--btn-text-color);
}
.bg-search,
.excel {
    background-color: var(--teal);
}
.btn-add {
    background-color: var(--light-green);
}
.btn-up {
    background-color: var(--yellow);
}
.btn-update {
    background-color: var(--yellow);
}
.btn-delete {
    background-color: var(--orange);
}
.btn-info {
    background-color: var(--teal);
}
</style>
