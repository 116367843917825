<template>
    <div>
        <div :class="{ 'fixed-container': true, collapsed: isCollapsed }">
            <div class="circle-container" @click="toggleCollapse">
                <i v-if="isCollapsed === true" class="el-icon-d-arrow-right"></i>
                <i v-else class="el-icon-d-arrow-left"></i>
            </div>
        </div>
        <el-menu
            :default-active="activeMenu"
            :collapse="isCollapseLeftBar"
            class="el-menu-vertical-demo"
        >
            <template v-for="item in permission">
                <el-submenu
                    mode="horizontal"
                    v-if="item.hasChildren"
                    :key="item.path"
                    :index="item.path"
                    @click="navigateRouter(item.path)"
                >
                    <template slot="title">
                        <i :class="item.iconUI"></i>

                        <span
                            @click="navigateRouter(item.path)"
                            v-loading.fullscreen.lock="fullscreenLoading"
                            >{{ item.name }}</span
                        >
                    </template>
                    <template v-for="(child, index) in item.children">
                        <el-submenu
                            v-if="child.hasChildren"
                            :key="index"
                            :index="child.path"
                        >
                            <template slot="title"> {{ child.name }}</template>
                            <el-menu-item
                                v-for="grandchild in child.children"
                                :key="grandchild.path"
                                :index="grandchild.path"
                                @click="navigateRouter(grandchild.path)"
                            >
                                {{ grandchild.name }}
                            </el-menu-item>
                        </el-submenu>
                        <el-menu-item
                            v-else
                            :key="child.path"
                            :index="child.path"
                            @click="navigateRouter(child.path)"
                        >
                            {{ child.name }}
                        </el-menu-item>
                    </template>
                </el-submenu>
                <el-menu-item v-else :key="item.path" :index="item.path">
                    <i v-if="item.icon" :class="item.icon"></i>
                    <span @click="navigateRouter(item.path)">
                        {{ item.name }}
                    </span>
                </el-menu-item>
            </template>
        </el-menu>
    </div>
</template>

<script>
import 'element-ui/lib/theme-chalk/index.css'
import { mapState } from 'vuex'

export default {
    name: 'LeftBar',
    components: {},
    data() {
        return {
            isCollapsed: false,
            dataLeftBar: [],
            permission: [],
            dataMenu: [],
            selectedItem: null,
            fullscreenLoading: false,
            activeMenu: null
        }
    },
    computed: {
        ...mapState({
            authUser: (state) => state.auth.user
        }),

        ...mapState(['isCollapseLeftBar'])
    },

    methods: {
        toggleCollapse() {
            this.isCollapsed = !this.isCollapsed
            this.$store.commit('setIsCollapse', !this.isCollapseLeftBar)
        },
        navigateRouter(path) {
            if (this.$route.path !== path) {
                const route = this.$router.resolve({ path })

                if (route.resolved.matched.length > 0) {
                    this.$router.push(path)
                } else {
                    console.log(`Đường dẫn ${path} không tồn tại`)
                }
            }
        },

        getIconElementUi(index) {
            const icons = [
                'el-icon-menu',
                'el-icon-document',
                'el-icon-set-up',
                'el-icon-sell',
                'el-icon-s-grid',
                'el-icon-search',
                'el-icon-menu'
            ]
            return icons[index % icons.length]
        },

        handleRouteChange(to) {
            const path = to.path

            this.activeMenu = path

            const fullPath = `${to.path}?${Object.entries(to.query)
                .map(([key, value]) => `${key}=${value}`)
                .join('&')}`
            if (fullPath && to.query.type) {
                this.activeMenu = fullPath
            }
        }
    },
    watch: {
        $route(to) {
            this.handleRouteChange(to)
        },
        authUser: {
            handler(newValue) {
                if (newValue && Array.isArray(newValue.permission)) {
                    this.permission = newValue.permission.map((item, index) => ({
                        ...item,
                        iconUI: this.getIconElementUi(index)
                    }))
                }
            },
            immediate: true
        }
    },
    mounted() {
        this.handleRouteChange(this.$route)
    }
}
</script>

<style scoped>
.el-menu-vertical-demo {
    overflow-y: auto;

    background: #545c64;

    height: calc(100vh - 60px);
    z-index: 1001;
    position: sticky;
    top: 60px;
    overflow-x: hidden;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 300px;

    position: sticky;
    top: 60px;
    overflow-y: auto;

    z-index: 1001;

    height: calc(100vh - 60px);
    overflow-x: hidden;
}

.menu-title {
    color: black;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.btn-icon {
    text-align: center;
    margin-left: 4px;
}
.menu-logout {
    padding: 13px 20px 13px 20px;
    display: flex;
    align-items: center;
}
.logout {
    font-size: 13px;

    font-weight: 100;
    padding: 5px 0px 2px 14px;
}

.menu-logout:hover {
    cursor: pointer;
    background-color: #8080807d;
}

::v-deep .el-menu-item {
    font-weight: 700;
    color: #fff;
    font-size: 16px;
}

::v-deep .el-menu {
    border-right: 0px;
    background-color: #1e6050;
}
::v-deep .el-menu-item:active,
.el-menu-item.is-active {
    color: #1e6050;
    font-weight: 700;
    background-color: #fbfaf7;
    font-size: 16px;
}

::v-deep .el-submenu__title {
    color: #fff;
    font-weight: 700;
    font-size: 16px;
}
::v-deep .el-submenu__title i {
    font-weight: bold;
    color: #fff;
}

::v-deep .el-submenu__title:hover,
.el-menu-item:hover,
.el-submenu .el-menu-item:hover {
    background-color: #6aab9c;
    color: #fff;
    font-weight: 700;
}
/* ::v-deep .el-icon-d-arrow-left:before,
.el-icon-d-arrow-right:before {
    font-weight: bold;
    color: #6aab9c;
} */
.fixed-container {
    position: fixed;
    top: 62px;
    left: 265px;
    z-index: 1002;
    background-color: #f3f5f7;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
}

.circle-container {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #6aab9c;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    color: #fff;
    font-weight: 900;
}
.collapsed {
    left: 30px !important;
}
@media (max-width: 768px) {
    .el-menu-vertical-demo {
        display: none;
    }
}
</style>
