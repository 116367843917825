<!-- CustomTable.vue -->
<template>
    <div :style="{ 'max-width': maxWidth }" class="layout-table">
        <table class="table table-bordered table-hover centered-table">
            <thead>
                <template v-if="columns && columns.length > 0">
                    <tr>
                        <th
                            v-for="column in columns"
                            :key="column.key"
                            :class="column.class"
                        >
                            {{ column.label }}
                        </th>
                    </tr>
                </template>
                <template v-else>
                    <slot name="header"></slot>
                </template>
            </thead>
            <tbody>
                <slot name="body"></slot>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        maxWidth: {
            type: String,
            default: '100%'
        },
        columns: {
            type: Array
        }
    }
}
</script>

<style scoped>
.layout-table {
    background: #fff;
    padding: 20px 10px 5px 10px;
    border-radius: 4px;

    margin: 0 auto;
    overflow-x: auto;
}

table thead tr {
    background: #6aab9c;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
}
.table {
    white-space: nowrap;
}
.centered-table {
    margin-left: auto;
    margin-right: auto;
}

.table-bordered {
    background-color: #fff;
}

.table-bordered th,
.table-bordered td {
    text-align: center;
    vertical-align: middle;
    border-width: 0 0 1px 1px;
    border-style: solid;
    border-color: #dedede;
}
.table-bordered th {
    padding: 9px 7px;
    font-size: 15px;
}
.table-bordered td {
    padding: 10px;
    font-size: 14px;
}
.table-hover tbody tr:hover {
    background-color: #f5f5f5 !important;
}

.text-thead {
    font-size: 15px;
    text-align: center;
    vertical-align: middle;
}
.text-tbody {
    font-size: 15px;
    vertical-align: middle;
    color: #333333;
}
.text-left {
    text-align: left;
}
.table-hover tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

/* Chọn mọi hàng lẻ trong tbody và làm nền trắng */
.table-hover tbody tr:nth-child(odd) {
    background-color: #fff;
}

/* Thêm border-bottom cho mỗi hàng thứ 5 */
.table-hover tbody tr:nth-child(5n) {
    border-bottom: 2px solid #dedede;
}
.text-center {
    text-align: center;
}
</style>
