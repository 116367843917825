<template>
    <div class="school">
        <div class="header">
            <div class="left">
                <div>
                    <el-icon name="school" class="icon"></el-icon>
                </div>
                <div class="title">Trường học</div>
            </div>
            <div class="right">
                <CustomButton
                    label="Chi tiết"
                    size="small"
                    @click="handleNaviagte"
                    class="custom-btn"
                />
            </div>
        </div>

        <div class="layout-card">
            <div class="row">
                <div class="col-md-4 mb-4">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">Tổng quan</div>
                            <div>
                                <CustomButton
                                    type="text"
                                    icon="el-icon-refresh-right"
                                    label=""
                                    size="medium"
                                    @click="
                                        handleRefresh_DataBieuDoTongQuan_TruongHoc
                                    "
                                />
                            </div>
                        </div>
                        <div class="card-body">
                            <PieChart
                                :data_PieChart="dataBieuDoTongQuan_TruongHoc"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">Chất lượng đào tạo</div>
                            <div>
                                <CustomButton
                                    type="text"
                                    icon="el-icon-refresh-right"
                                    label=""
                                    size="medium"
                                    @click="
                                        handleRefresh_DataBieuDoChatLuongDaoTao_TruongHoc
                                    "
                                />
                            </div>
                        </div>
                        <div class="card-body">
                            <StackedColumnChart
                                :data_StackedColumnChart="
                                    customColorStackedColumnChart(
                                        dataBieuDoChatLuongDaoTao_TruongHoc
                                    )
                                "
                                :xaxis_categories="
                                    xaxisCategories.chatLuongDaoTao
                                "
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <div class="card">
                        <div class="card-header">
                            <div class="title">Loại hình đào tạo</div>
                            <div>
                                <CustomButton
                                    type="text"
                                    icon="el-icon-refresh-right"
                                    label=""
                                    size="medium"
                                    @click="
                                        handleRefresh_DataBieuDoLoaiHinhDaoTao_TruongHoc
                                    "
                                />
                            </div>
                        </div>
                        <div class="card-body">
                            <StackedColumnChart
                                :data_StackedColumnChart="
                                    customColorStackedColumnChart(
                                        dataBieuDoLoaiHinhDaoTao_TruongHoc
                                    )
                                "
                                :xaxis_categories="xaxisCategories.loaiHinhDaoTao"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import PieChart from '@/components/PieChart.vue'
import StackedColumnChart from '@/components/StackedColumnChart.vue'
import { Icon } from 'element-ui'
import { Router } from '@/constants/Router'
import 'element-ui/lib/theme-chalk/index.css'
import { customColorStackedColumnChart } from '@/utils'
export default {
    name: 'schoolC',
    components: {
        'el-icon': Icon,
        CustomButton,
        PieChart,
        StackedColumnChart
    },
    data() {
        return {
            xaxisCategories: {
                chatLuongDaoTao: [
                    'C.Lượng tối thiểu',
                    'Chuẩn mức 1',
                    'Chuẩn mức 2'
                ],
                loaiHinhDaoTao: ['Công lập', 'Tư thục', 'Dân lập']
            }
        }
    },
    props: {
        dataBieuDoTongQuan_TruongHoc: {
            type: Array,
            required: true
        },
        refresh_dataBieuDoTongQuan_TruongHoc: Function,

        dataBieuDoChatLuongDaoTao_TruongHoc: {
            type: Array,
            required: true
        },
        refresh_dataBieuDoChatLuongDaoTao_TruongHoc: Function,

        dataBieuDoLoaiHinhDaoTao_TruongHoc: {
            type: Array,
            required: true
        },
        refresh_dataBieuDoLoaiHinhDaoTao_TruongHoc: Function
    },
    methods: {
        customColorStackedColumnChart,
        handleRefresh_DataBieuDoTongQuan_TruongHoc() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            this.$emit('refresh_dataBieuDoTongQuan_TruongHoc')
            loading.close()
        },
        handleRefresh_DataBieuDoChatLuongDaoTao_TruongHoc() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            this.$emit('refresh_dataBieuDoChatLuongDaoTao_TruongHoc')
            loading.close()
        },
        handleRefresh_DataBieuDoLoaiHinhDaoTao_TruongHoc() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading ...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            this.$emit('refresh_dataBieuDoLoaiHinhDaoTao_TruongHoc')
            loading.close()
        },
        handleNaviagte() {
            this.$router.push(Router.traCuu.traCuuTruongHoc)
        }
    }
}
</script>

<style scoped>
.school {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 15px;
    padding-left: 6px;
    padding-right: 6px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 10px 12px 10px;
}
.header .left {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #2e8bc0;
    font-weight: 700;
    text-transform: uppercase;
}
.header .left .icon {
    font-size: 18px;
    color: #000000;
    font-weight: 700;
    margin-right: 5px;
}
.header .right {
    margin-left: 6px;
}

.layout-card {
    padding: 0px 10px 20px 10px;
}
.layout-card .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f2f3f8;
    height: 25px;
    color: black;
    padding: 5px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.layout-card .card-header .title {
    margin: -5px 0px 0px 10px;
    color: #000000;
    font-weight: 700;
    font-size: 15px;
}
.layout-card .card-body {
    padding: 5px;
    background: white;
    border: #f2f3f8 2px solid;
    height: 300px;
}
.custom-btn {
    font-weight: 700;
    color: #fff;
    background-color: #6aab9c;
}
::v-deep .el-icon-school:before {
    font-size: 18px;
    color: #2e8bc0;
    content: '\e756';
}
</style>
